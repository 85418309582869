/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestDetail,
    RequestDetailFromJSON,
    RequestDetailFromJSONTyped,
    RequestDetailToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface RequestDetailResponse
 */
export interface RequestDetailResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof RequestDetailResponse
     */
    msg: string;
    /**
     * 
     * @type {RequestDetail}
     * @memberof RequestDetailResponse
     */
    entry: RequestDetail;
}

export function RequestDetailResponseFromJSON(json: any): RequestDetailResponse {
    return RequestDetailResponseFromJSONTyped(json, false);
}

export function RequestDetailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestDetailResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': RequestDetailFromJSON(json['entry']),
    };
}

export function RequestDetailResponseToJSON(value?: RequestDetailResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': RequestDetailToJSON(value.entry),
    };
}


