import styled from 'styled-components';
import colors from 'common/common.colors';

export const Description = styled.p`
  white-space: pre-wrap;
  font-size: ${({ theme }) => theme.basic.fontSizes.normal};
  padding-top: 5px;
`;

export const EmailTemplate = styled.p`
  white-space: pre-wrap;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  font-weight: ${({ theme }) => theme.basic.fontWeights.light};
  padding-top: 5px;
  padding-left: 25px;
  font-style: italic;
  line-height: 18px;
  a:link {
    color: ${colors.basic.dodgerBlue};
  }
`;

export const RequestLinks = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: ${({ theme }) => theme.basic.fontWeights.light};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 0px 5px 25px;
`;

export const RequestTitle = styled.div`
  font-family: ${({ theme }) => theme.basic.fontWeights.semibold};
  font-size: 14px;
`;

export const RequestLink = styled.a`
  text-decoration: none;
  font-family: ${({ theme }) => theme.basic.fontWeights.light};
  font-size: 14px;
  color: #0091ff;
  &:hover {
    text-decoration: underline;
  }
`;

export const RequestInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
