/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShareEvidence
 */
export interface ShareEvidence {
    /**
     * 
     * @type {Array<string>}
     * @memberof ShareEvidence
     */
    evidenceIds: Array<string>;
    /**
     * Expiration date of the shared evidence
     * @type {Date}
     * @memberof ShareEvidence
     */
    expirationDate?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShareEvidence
     */
    targetEmails: Array<string>;
}

export function ShareEvidenceFromJSON(json: any): ShareEvidence {
    return ShareEvidenceFromJSONTyped(json, false);
}

export function ShareEvidenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareEvidence {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'evidenceIds': json['evidence_ids'],
        'expirationDate': !exists(json, 'expiration_date') ? undefined : (new Date(json['expiration_date'])),
        'targetEmails': json['target_emails'],
    };
}

export function ShareEvidenceToJSON(value?: ShareEvidence | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'evidence_ids': value.evidenceIds,
        'expiration_date': value.expirationDate === undefined ? undefined : (value.expirationDate.toISOString()),
        'target_emails': value.targetEmails,
    };
}


