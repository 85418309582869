import CompanyHeader from './CompanyHeader';
import NoData from '../common/NoData';
import React, { useState } from 'react';
import RequestModal from '../CompletedStandards/RequestModal';
import RequestsList from '../common/RequestsList';
import Switcher from '../common/Switcher';
import qs from 'qs';
import { CompanyPageContextType } from 'pages/CompanyPage';
import { CompletedStandards } from '../CompletedStandards';
import { Content } from 'common/common.styles';
import { PageContextConsumer } from 'pages/Page';
import { PreviewModal } from '../SendRequest/Modals';
import { withTranslation } from 'react-i18next';
import {
  CompletedStandardType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import {
  FORM_PREVIEW_MODAL_ID,
  REQUEST_COMPLETED_STANDARD_MODAL_ID,
} from 'common/common.constants';
import formatRoute, { SEND_REQUEST } from '../../routes';

const Company = ({ t }: TranslatePropsType) => {
  const [standard, setStandard] = useState<CompletedStandardType | undefined>(
    undefined,
  );

  return (
    <PageContextConsumer>
      {({
        data: {
          currentSwitcherOption,
          data,
          data: { company, requests, completedStandards },
          error,
          form,
          hasData,
          previewForm,
          user,
          language,
        },
        actions: {
          appealCredits,
          getPreview,
          initRequestsForm,
          loadQuestions,
          putQuestion,
          sendRequest,
          setValue,
          showModal,
          switchView,
        },
      }: CompanyPageContextType) => (
        <div>
          <PreviewModal
            loadQuestions={loadQuestions}
            modalId={FORM_PREVIEW_MODAL_ID}
            previewForm={previewForm}
            putQuestion={putQuestion}
            user={user}
            language={language}
          />

          {standard && (
            <RequestModal
              company={company}
              appealCredits={appealCredits}
              error={error}
              form={form}
              handleSubmit={() => sendRequest(form, standard.id)}
              modalId={REQUEST_COMPLETED_STANDARD_MODAL_ID}
              setValue={setValue}
              standard={standard}
              user={user}
            />
          )}

          <CompanyHeader data={data} />

          <Content>
            {hasData && (
              <>
                <Switcher
                  currentSwitcherOption={currentSwitcherOption}
                  options={[
                    {
                      key: 'sent',
                      title: t('Company.sent'),
                      count: requests.out.length,
                    },
                    {
                      key: 'received',
                      title: t('Company.received'),
                      count: requests.in.length,
                    },
                    {
                      key: 'available',
                      title: t('Company.available'),
                      count: completedStandards.length,
                    },
                  ]}
                  switchView={switchView}
                />
                {
                  {
                    sent: (
                      <RequestsList
                        data={requests.out || []}
                        user={user}
                        language={language}
                      />
                    ),
                    received: (
                      <RequestsList
                        data={requests.in || []}
                        user={user}
                        language={language}
                      />
                    ),
                    available: (
                      <CompletedStandards
                        data={completedStandards || []}
                        onPreview={(formId: Uuid) => {
                          getPreview(formId);
                          showModal(FORM_PREVIEW_MODAL_ID);
                        }}
                        onRequest={(
                          completedStandard: CompletedStandardType,
                        ) => {
                          setStandard(completedStandard);
                          initRequestsForm(company);
                          showModal(REQUEST_COMPLETED_STANDARD_MODAL_ID);
                        }}
                      />
                    ),
                  }[currentSwitcherOption]
                }
              </>
            )}
            {!hasData && (
              <NoData
                buttonText={t('Company.noDataButton')}
                text={
                  company.tags.length > 0
                    ? t('Company.noRequests', { company: company.info.name })
                    : t('Company.noData', { company: company.info.name })
                }
                path={`${formatRoute(SEND_REQUEST)}?${qs.stringify({
                  domain: company.info.domain,
                })}`}
              />
            )}
          </Content>
        </div>
      )}
    </PageContextConsumer>
  );
};

export default withTranslation()(Company);
