import { FormDefinitionDraftCategoryStatsType } from '../../../common/common.types';
import { findIndex } from 'ramda';

/**
 * For 'Hello (1).(1)' returns ['Hello (1).', 1],
 * for 'Hello 1.(3).3.()' returns null.
 */
export const tryParseEndingNumber = (name: string): [string, number] | null => {
  const res = /\(\d+\)$/.exec(name);
  if (res && res[0]) {
    const maybeNum = parseInt(res[0].substring(1, res[0].length - 1), 10);
    // we don't check maybeNum == NaN here because a valid number format
    // is already guaranteed by the regex above.
    return [name.substr(0, res.index), maybeNum];
  } else {
    return null;
  }
};

const containsCategoryWithName = (
  categories: readonly FormDefinitionDraftCategoryStatsType[],
  name: string,
) => categories.some(({ name: c }) => c === name);

const getFirstAvailableCategoryName = (
  categories: readonly FormDefinitionDraftCategoryStatsType[],
  categoryName: string,
): string => {
  const existingCategoryIndex = findIndex(
    ({ name }) => name === categoryName,
    categories,
  );

  if (existingCategoryIndex === -1) {
    return categoryName;
  }

  const res = tryParseEndingNumber(categoryName);

  // Increment the number until we find some unique.
  const prefix = res ? res[0] : `${categoryName} `;
  let i = res ? res[1] : 1;
  let newCategoryName = prefix;
  while (
    containsCategoryWithName(categories, (newCategoryName = `${prefix}(${i})`))
  ) {
    i++;
  }
  return newCategoryName;
};

export default getFirstAvailableCategoryName;
