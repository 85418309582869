import i18n from '../../../i18n';
import route, { REQUESTS } from '../../../routes';
import { ApiCallActionType } from '../../store.types';
import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import {
  RequestStatusEnum,
  RequestVrmViewStatusEnum,
  Uuid,
} from '../../../common/common.types';

export interface FormsModifyRequestCallParamsType {
  id: Uuid;
  data: {
    message?: string;
    return_assessment_to_vendor_message?: string;
    return_assessment_to_vendor_additional_message?: string;
    status?: RequestStatusEnum;
    vrm_view_status?: RequestVrmViewStatusEnum;
    risk_note?: string;
  };
}

export type FormsPutRequestFunctionType = (
  requestId: Uuid,
  status: RequestStatusEnum,
  sendToMessage?: {
    reason: string;
    additionalInformation: string;
  },
) => void;

export type FormsPutRequestActionType = ApiCallActionType<
  '@forms/API/PUT_REQUEST',
  FormsModifyRequestCallParamsType
>;

export const putRequest = (
  requestId: Uuid,
  status: RequestStatusEnum,
  sendToMessage: {
    reason: string;
    additionalInformation: string;
  },
): FormsPutRequestActionType => ({
  type: '@forms/API/PUT_REQUEST',
  payload: {
    callParams: {
      id: requestId,
      data: {
        status,
        return_assessment_to_vendor_message:
          sendToMessage && sendToMessage.reason ? sendToMessage.reason : '',
        return_assessment_to_vendor_additional_message:
          sendToMessage && sendToMessage.additionalInformation
            ? sendToMessage.additionalInformation
            : '',
      },
    },
    endpointPath: ['requests', 'modify'],
    selector: null,
    postActions: [
      () => call(() => trackEvent('Request', `Change status -> ${status}`)),
      () => put(push(route(REQUESTS))),
      () => put(flash(i18n.t('common:responses.formSent'))),
    ],
  },
});
