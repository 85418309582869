import { ApiCallActionPayloadType } from '../../store.types';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ParsingMessageSeverityEnum } from '../../../generated/models/ParsingMessage';
import { inc, prop, evolve, always } from 'ramda';
import { put, all } from 'redux-saga/effects';
import {
  addParsedFiles,
  saveStandard,
  setParseError,
  toggleStandardSelection,
} from '.';
import { StandardStateType } from '../reducer';

const { ERROR } = ParsingMessageSeverityEnum;

export type UploadStandardFunctionType = (formData: FormData) => void;

export type UploadStandardActionType = OptimisticActionType<
  '@standard/API/POST',
  ApiCallActionPayloadType<FormData, never, never, StandardStateType>
>;

export const uploadStandard = (
  formData: FormData,
): UploadStandardActionType => ({
  type: '@standard/API/POST',
  payload: {
    endpointPath: ['standards', 'upload'],
    callParams: formData,
    expectedStateChange: {
      'standard.uploadingCount': inc,
      'standard.parseErrorsCount': 0,
      'standard.parsedFiles': [],
    },
    selector: null,
    errorSelector: (data, state) =>
      evolve({ uploadingCount: always(0) })(state.standard),
    postActions: [(payload: any) => postActionsResolver(payload, formData)],
  },
});

const postActionsResolver = (payload: any, formData: FormData) => {
  const { parsed_files } = payload;

  const hasErrors =
    parsed_files.filter(
      (parsedFile: any) =>
        parsedFile.parsing_messages.filter(
          (parsingMessage: any) => parsingMessage.severity === ERROR,
        ).length,
    ).length > 0;

  if (hasErrors) {
    return all([put(addParsedFiles(parsed_files)), put(setParseError())]);
  }

  const {
    definition: { id: definitionId },
    standard: { id },
  } = payload;

  return all([
    put(addParsedFiles(parsed_files)),
    put(saveStandard(definitionId, prop('name')(formData.get('file') as File))),
    put(toggleStandardSelection({ id })),
  ]);
};
