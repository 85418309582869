import { FormCreatorStateType } from '../formCreator.types';
import { FormDefinitionSummaryDraftExtendedType } from '../../../common/common.types';
import { sort } from 'ramda';
import { createSelector } from 'reselect';
import comparePos from '../utils/comparePos';

export const getCurrentForm = (state: FormCreatorStateType) =>
  state.currentForm;

export const getCategories = createSelector(
  (currentForm: FormDefinitionSummaryDraftExtendedType) =>
    currentForm.categories,
  sort(comparePos),
);

export const areQuestionsDeletable = ({ currentForm }: FormCreatorStateType) =>
  Boolean(currentForm && currentForm.questions.length > 1);
