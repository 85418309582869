import styled, { css } from 'styled-components';
import chroma from 'chroma-js';
import colors from 'common/common.colors';
import hex2rgba from 'utils/hex2rgba';

export const scoreScale = chroma
  .scale([colors.elements.score.start, colors.elements.score.end])
  .mode('lrgb')
  .domain([0, 100]);

interface BarType {
  color: string;
  hasStripes?: boolean;
  width: number;
}

export const Bar = styled.div<BarType>`
  padding-left: ${({ width }) => `${width + 5}px`};
  position: relative;
  line-height: 1.4;
  min-width: 127px;
  ${({ theme }) => theme.fonts.bold.mini}

  > span {
    ${({ theme }) => theme.basic.fontWeights.regular}
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    height: 14px;
    width: ${({ width }) => `${width}px`};
    background: ${({ color, hasStripes, theme }) =>
      hasStripes
        ? css`repeating-linear-gradient(-45deg, ${color}, ${color} 5px, ${hex2rgba(
            color,
            0.5,
          )} 5px, ${hex2rgba(color, 0.5)} 8px)`
        : color};
  }
`;

export const BarWrapper = styled.div<{ align: 'baseline' | 'center' }>`
  position: relative;
  display: flex;
  align-items: ${({ align }) => align};
  color: ${({ theme: { atlas } }) => atlas.text.secondary};

  @media print {
    break-inside: avoid;
  }
`;
