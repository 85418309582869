import { differenceInDays } from 'date-fns';
import React from 'react';
import DueDateText from './DueDateText';
import AcceptedDateText from './AcceptedDateText';
import { calculateKind } from './utils';
import {
  DueDateWrapper,
  Progress,
  ProgressWrapper,
  Separator,
  Text,
} from './DueDateList.styles';
import { LanguageType } from 'common/common.types';

export interface DueDateListType {
  dueDate: Date;
  acceptedDate?: Date;
  language: LanguageType;
}

export const DueDateList = ({
  dueDate,
  acceptedDate,
  language,
}: DueDateListType) => {
  const days = differenceInDays(dueDate, new Date());
  const state = days >= 0 ? 'positive' : 'negative';
  const calculatedKind = calculateKind(days);

  return (
    <DueDateWrapper>
      <Separator />

      <ProgressWrapper state={state}>
        <Progress state={state} color={calculatedKind} kind={calculatedKind} />
      </ProgressWrapper>

      <Text state={state}>
        {acceptedDate ? (
          <AcceptedDateText
            align={state === 'positive' ? 'left' : 'right'}
            dueDate={dueDate}
            acceptedDate={acceptedDate}
            language={language}
          />
        ) : (
          <DueDateText
            align={state === 'positive' ? 'left' : 'right'}
            dueDate={dueDate}
          />
        )}
      </Text>
    </DueDateWrapper>
  );
};

export default DueDateList;
