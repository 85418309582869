import baseTheme from '../light';

export default {
  ...baseTheme,
  nav: {
    ...baseTheme.nav,
    button: {
      bg: '#4885D1',
      text: 'white',
    },
    icon: {
      color: '#5B99E8',
    },
    logo: {
      src: '/whitelabel/bd/BD-Logo.svg',
      width: '200px',
      marginRight: '20px',
      marginLeft: '20px',
    },
  },
  header: {
    backgroundImage: '/whitelabel/bd/bg@3x.png',
  },
};
