import { Uuid } from '../../../common/common.types';

export interface FormsResetActionType {
  type: '@forms/RESET';
  payload: {
    fetchedFormId?: Uuid;
  };
}

export const resetForm = (fetchedFormId?: Uuid): FormsResetActionType => ({
  type: '@forms/RESET',
  payload: {
    fetchedFormId,
  },
});
