import React from 'react';
import { Text, Wrapper } from './NoData.styles';
import { Button } from '../Button';
import { Link } from 'react-router-dom';

interface NoDataPropsType {
  buttonText: string;
  text: string;
  path: string;
}

const NoData = ({ buttonText, text, path }: NoDataPropsType) => (
  <Wrapper>
    <Text>{text}</Text>

    <Link to={path}>
      <Button size="mini">{buttonText}</Button>
    </Link>
  </Wrapper>
);

export default NoData;
