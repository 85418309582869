/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionDetailBase,
    QuestionDetailBaseFromJSON,
    QuestionDetailBaseFromJSONTyped,
    QuestionDetailBaseToJSON,
    Standard,
    StandardFromJSON,
    StandardFromJSONTyped,
    StandardToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnrichedMappingResult
 */
export interface EnrichedMappingResult {
    /**
     * 
     * @type {string}
     * @memberof EnrichedMappingResult
     */
    algorithm: string;
    /**
     * 
     * @type {number}
     * @memberof EnrichedMappingResult
     */
    score: number;
    /**
     * 
     * @type {QuestionDetailBase}
     * @memberof EnrichedMappingResult
     */
    sourceQuestion?: QuestionDetailBase;
    /**
     * 
     * @type {Standard}
     * @memberof EnrichedMappingResult
     */
    sourceStandard?: Standard;
    /**
     * 
     * @type {QuestionDetailBase}
     * @memberof EnrichedMappingResult
     */
    targetQuestion?: QuestionDetailBase;
    /**
     * 
     * @type {Standard}
     * @memberof EnrichedMappingResult
     */
    targetStandard?: Standard;
}

export function EnrichedMappingResultFromJSON(json: any): EnrichedMappingResult {
    return EnrichedMappingResultFromJSONTyped(json, false);
}

export function EnrichedMappingResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnrichedMappingResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'algorithm': json['algorithm'],
        'score': json['score'],
        'sourceQuestion': !exists(json, 'source_question') ? undefined : QuestionDetailBaseFromJSON(json['source_question']),
        'sourceStandard': !exists(json, 'source_standard') ? undefined : StandardFromJSON(json['source_standard']),
        'targetQuestion': !exists(json, 'target_question') ? undefined : QuestionDetailBaseFromJSON(json['target_question']),
        'targetStandard': !exists(json, 'target_standard') ? undefined : StandardFromJSON(json['target_standard']),
    };
}

export function EnrichedMappingResultToJSON(value?: EnrichedMappingResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'algorithm': value.algorithm,
        'score': value.score,
        'source_question': QuestionDetailBaseToJSON(value.sourceQuestion),
        'source_standard': StandardToJSON(value.sourceStandard),
        'target_question': QuestionDetailBaseToJSON(value.targetQuestion),
        'target_standard': StandardToJSON(value.targetStandard),
    };
}


