import { DueDateKindType } from 'common/common.types';

export const calculateKind = (days: number): DueDateKindType => {
  if (days >= 5) {
    return 'okay';
  } else if (days >= 3) {
    return 'close';
  } else if (days >= 1) {
    return 'almost';
  } else if (days <= -5) {
    return 'danger';
  } else if (days <= -3) {
    return 'warning';
  } else if (days <= 0) {
    return 'notice';
  } else {
    return 'okay';
  }
};

export const calculateCaptions = (days: number) => {
  let dueDate = 'due';
  let howMuch = 'today';
  let time = 'days';

  if (days > 0) {
    dueDate = 'dueIn';
    howMuch = Math.abs(days).toString();
  } else if (days < 0) {
    dueDate = 'overdueBy';
    howMuch = Math.abs(days).toString();
  } else {
    time = '';
  }

  return { dueDate, howMuch, time };
};
