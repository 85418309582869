import * as R from 'ramda';
import { CompaniesActionType } from './actions';
import { CompanyGradeEnum } from '../../generated/models/Company';
import { getVisualisationData } from 'utils/filters/companies.filters';
import { parseCompanyDetail } from 'utils/parsers';
import {
  AutocompleteCompanyType,
  CompanyCreditsType,
  CompanyDetailType,
  CompanySettingType,
  ListedCompanyType,
  TagType,
  UserType,
} from '../../common/common.types';

export interface VisualisationByGradeDataType {
  [key: string]: {
    qtyAbs: number;
    qtyRel: number;
    companies: ListedCompanyType[];
  };
}

export interface VisualisationRowDataType {
  legend: string;
  byGrade: VisualisationByGradeDataType;
}

export interface FilterVisualisationType {
  tagged: VisualisationRowDataType[];
  unsorted: VisualisationRowDataType;
}

export interface CompaniesFilterSelectionType {
  tagName?: string;
  grade?: CompanyGradeEnum;
  hover: boolean;
}

export interface CompaniesFilterType {
  tags: TagType[];
  rows: FilterVisualisationType;
  selection: CompaniesFilterSelectionType & { result: ListedCompanyType[] };
  result: ListedCompanyType[];
  total: number;
}

export interface CompaniesStateType {
  autocomplete: AutocompleteCompanyType[];
  credits: CompanyCreditsType;
  details: CompanyDetailType;
  filter: CompaniesFilterType;
  list: ListedCompanyType[];
  listLimit: number;
  myTags: TagType[];
  users: UserType[];
  settings: CompanySettingType;
}

const initialState: CompaniesStateType = {
  autocomplete: [],
  credits: {
    list: [],
    history: [],
    summary: null,
  },
  details: parseCompanyDetail({}),
  myTags: [],
  list: [],
  listLimit: 0,
  filter: {
    tags: [],
    rows: {
      tagged: [],
      unsorted: { legend: 'Unsorted', byGrade: {} },
    },
    result: [],
    selection: {
      tagName: undefined,
      grade: undefined,
      result: [],
      hover: false,
    },
    total: 0,
  },
  users: [],
  settings: {},
};

export const companies = (
  state = initialState,
  action: CompaniesActionType,
): CompaniesStateType => {
  let tags: TagType[] = [];

  switch (action.type) {
    case '@companies/ADD_TAG_TO_FILTER':
    case '@companies/REFRESH_FILTER':
    case '@companies/REMOVE_TAG_FROM_FILTER':
    case '@companies/RESET_TAG_FILTER':
      if (action.type === '@companies/ADD_TAG_TO_FILTER') {
        tags = [...state.filter.tags, action.payload];
      }
      if (action.type === '@companies/REMOVE_TAG_FROM_FILTER') {
        tags = R.reject(tag => tag.id === action.payload.id, state.filter.tags);
      }
      if (action.type === '@companies/RESET_TAG_FILTER') {
        tags = [];
      }

      const { filtered, total, rows: filterRows } = getVisualisationData(
        state.list,
        tags,
      );

      return {
        ...state,
        filter: {
          ...state.filter,
          tags,
          rows: filterRows,
          selection: {
            tagName: undefined,
            grade: undefined,
            hover: false,
            result: filtered,
          },
          result: filtered,
          total,
        },
      };

    case '@companies/CLEAR_AUTOCOMPLETE':
      return { ...state, autocomplete: initialState.autocomplete };

    case '@companies/SET_FILTER_SELECTION':
      const {
        filter: { rows, result },
      } = state;

      const {
        payload: { tagName, grade: selectedGrade, hover },
      } = action;

      const selectionResult =
        tagName && selectedGrade && !hover
          ? R.pathOr(
              [],
              ['byGrade', selectedGrade, 'companies'],
              R.find(R.propEq('legend', tagName), [
                ...rows.tagged,
                rows.unsorted,
              ]),
            )
          : result;

      return {
        ...state,
        filter: {
          ...state.filter,
          selection: {
            tagName,
            grade: selectedGrade,
            hover,
            result: selectionResult,
          },
          total: selectionResult.length,
        },
      };

    default:
      return state;
  }
};
