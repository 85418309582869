/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResponseTypeDefinition
 */
export interface ResponseTypeDefinition {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof ResponseTypeDefinition
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof ResponseTypeDefinition
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ResponseTypeDefinition
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTypeDefinition
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTypeDefinition
     */
    label: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseTypeDefinition
     */
    options: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResponseTypeDefinition
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTypeDefinition
     */
    responseType: ResponseTypeDefinitionResponseTypeEnum;
}

export function ResponseTypeDefinitionFromJSON(json: any): ResponseTypeDefinition {
    return ResponseTypeDefinitionFromJSONTyped(json, false);
}

export function ResponseTypeDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseTypeDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'hash': json['hash'],
        'id': json['id'],
        'label': json['label'],
        'options': json['options'],
        'placeholder': json['placeholder'],
        'responseType': json['response_type'],
    };
}

export function ResponseTypeDefinitionToJSON(value?: ResponseTypeDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'hash': value.hash,
        'id': value.id,
        'label': value.label,
        'options': value.options,
        'placeholder': value.placeholder,
        'response_type': value.responseType,
    };
}

/**
* @export
* @enum {string}
*/
export enum ResponseTypeDefinitionResponseTypeEnum {
    EVIDENCE = 'EVIDENCE',
    MULTISELECT = 'MULTI_SELECT',
    NORESPONSE = 'NO_RESPONSE',
    NUMBER = 'NUMBER',
    PERCENTAGE = 'PERCENTAGE',
    SINGLESELECT = 'SINGLE_SELECT',
    TEXT = 'TEXT'
}


