import React from 'react';
import { Marginator } from 'common/common.styles';
import { SendRequestErrorType } from 'store/requests/requests.types';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { TranslatePropsType } from 'common/common.types';
import {
  AddButton,
  Button,
  CreditRequirement,
  NoMoreCredits,
  SubmitWrapper,
  ButtonColorType,
} from './Submit.styles';
import { RequestsSendFormRedirectEnum } from '../../store/requests/actions';

interface SubmitPropsType extends TranslatePropsType {
  appealCredits: () => void;
  balance: number;
  cost: number;
  error: SendRequestErrorType;
  handleSubmit: (
    overrideRedirectType?: RequestsSendFormRedirectEnum.LINK,
    sendNotification?: boolean,
  ) => void;
  isFormValid: boolean;
  id?: string;
  isGeneratingLink?: boolean;
  itemsCount: number;
  displayInfo?: boolean;
  color?: ButtonColorType;
  disableAction?: boolean;
  isCompanyLink?: boolean;
}

const Submit = ({
  appealCredits,
  balance,
  cost,
  error,
  handleSubmit,
  isFormValid,
  id,
  isGeneratingLink,
  itemsCount,
  displayInfo = true,
  disableAction,
  color,
  isCompanyLink,
  t,
}: SubmitPropsType) => {
  const hasSomeCredits = balance > 0;
  const hasEnoughCredits = cost <= balance;
  const translationKey = isGeneratingLink ? 'generate' : 'send';
  const submitText = isCompanyLink ? 'submit_company' : 'submit';

  return (
    <SubmitWrapper>
      {!hasSomeCredits && displayInfo && (
        <NoMoreCredits>{t('noMoreCredits') as string}</NoMoreCredits>
      )}

      {(!!error || !hasEnoughCredits) && displayInfo && (
        <NoMoreCredits>
          {
            t('notEnoughCredits', {
              count: error ? error.balance : balance,
              cost: error ? error.cost : cost,
            }) as string
          }
        </NoMoreCredits>
      )}

      <Button
        color={color}
        data-cy="sq-submit"
        data-pendo={`send-questionnaire-${id}`}
        disabled={
          !isFormValid ||
          !hasSomeCredits ||
          !!error ||
          !hasEnoughCredits ||
          disableAction
        }
        onClick={() =>
          isGeneratingLink
            ? handleSubmit(RequestsSendFormRedirectEnum.LINK, false)
            : handleSubmit()
        }
        size="medium"
      >
        {t(`${translationKey}.${submitText}`, { count: itemsCount }) as string}
      </Button>

      {displayInfo && (
        <CreditRequirement>
          {ReactHtmlParser(
            t(`${translationKey}.creditRequirementHTML`, { count: cost }),
          )}
        </CreditRequirement>
      )}

      {(!hasSomeCredits || !!error || !hasEnoughCredits) && displayInfo && (
        <Marginator margin={{ top: 7 }}>
          <AddButton onClick={appealCredits}>
            {t('addMore') as string}
          </AddButton>
        </Marginator>
      )}
    </SubmitWrapper>
  );
};

export default withTranslation('SendRequest')(Submit);
