import {
  QuestionDefinitionSummaryType,
  FormDefinitionValidationType,
  FormCreatorValidatedQuestionType,
  FormCreatorValidatedResponseType,
  Uuid,
  QuestionDefinitionInvalidEntityErrorSummary,
  ResponseDefinitionInvalidEntityErrorSummary,
  FixableFieldEnum,
} from '../../../common/common.types';
import { all, any, pipe, filter, flatten, map } from 'ramda';
import { QuestionError } from '../validationError.types';

/**
 * Returns summary validation state for a question taking into account all
 * its responses.
 *
 * @param errors Errors of the question
 * @param responses Responses of the question with validation data included
 * @param fixAttempted Additional data about attempted fixes on the question
 */
const getSummaryValidationStatus = (
  errors: QuestionError[],
  responses: FormCreatorValidatedResponseType[],
  fixAttempted: boolean | Record<FixableFieldEnum, true>,
) => {
  if (errors.length === 0 && responses.every(r => r.errors.length === 0)) {
    return 'normal';
  } else if (
    (errors.length > 0 && !fixAttempted) ||
    responses.some(r => r.errors.length > 0 && !r.fixAttempted)
  ) {
    return 'invalid';
  } else {
    return 'fixAttempted';
  }
};

const filterErrorsByEntityUuid = <
  P extends keyof ErrorType,
  ErrorType extends
    | QuestionDefinitionInvalidEntityErrorSummary
    | ResponseDefinitionInvalidEntityErrorSummary
>(
  propertyUuid: P,
  errorSummaries: ErrorType[],
  uuid: Uuid,
): ErrorType['errors'] =>
  pipe(
    filter<ErrorType, 'array'>(
      element => ((element[propertyUuid] as unknown) as Uuid) === uuid,
    ),
    map(({ errors }) => errors),
    // We have to do this to choose the correct variant
    // of flatten
    <T>(x: T[][]): T[] => flatten<T>(x),
  )(errorSummaries);

/**
 * Merges all relevant validation data into the specified question
 * @param validation All validation data
 * @param question Question that should be enhanced with validation data
 *        related to it.
 */
export const enhanceQuestionWithValidationData = (
  validation: FormDefinitionValidationType,
) => (
  question: QuestionDefinitionSummaryType,
): FormCreatorValidatedQuestionType => {
  const errors = filterErrorsByEntityUuid(
    'questionUuid',
    validation.invalidQuestions,
    question.wizardId,
  );

  const fixAttempted = validation.attemptedFixes[question.wizardId] || false;

  const responses = question.responses.map(
    (response): FormCreatorValidatedResponseType => ({
      ...response,
      errors: filterErrorsByEntityUuid(
        'responseUuid',
        validation.invalidResponses,
        response.uniqueId,
      ),
      fixAttempted: validation.attemptedFixes[response.uniqueId] || false,
    }),
  );

  const fixAttemptedInAllResponses = all(
    response => Boolean(response.fixAttempted),
    responses,
  );

  const hasResponseErrors = any(
    response => response.errors.length > 0,
    responses,
  );
  const hasErrors = errors.length > 0 || hasResponseErrors;

  return {
    ...question,
    errors,
    fixAttempted,
    fixAttemptedInAllResponses,
    hasErrors,
    hasResponseErrors,
    responses,
    summaryValidationStatus: getSummaryValidationStatus(
      errors,
      responses,
      fixAttempted,
    ),
  };
};

export const isFieldFixAttempted = (
  fixAttempted: boolean | Record<FixableFieldEnum, true>,
  field: string,
): boolean =>
  Boolean(
    typeof fixAttempted === 'object' ? fixAttempted[field] : fixAttempted,
  );
