/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";

/**
 * 
 * @export
 * @interface ResponseFillingHistory
 */
export interface ResponseFillingHistory {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof ResponseFillingHistory
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof ResponseFillingHistory
     */
    updated_at: Date;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    action: ResponseFillingHistoryActionEnum;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    company_id: string;
    /**
     * 
     * @type {object}
     * @memberof ResponseFillingHistory
     */
    data?: any;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    response_id: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseFillingHistory
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    user_id: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    value: string;
}




/**
* @export
* @enum {string}
*/
export enum ResponseFillingHistoryActionEnum {
    CLEARAFTERCOPY = 'CLEAR_AFTER_COPY',
    COPYFROMFORM = 'COPY_FROM_FORM',
    COPYFROMRESPONSE = 'COPY_FROM_RESPONSE',
    FILLMASTERFORM = 'FILL_MASTER_FORM',
    MANUALANSWERLOOKUPLENSES = 'MANUAL_ANSWER_LOOKUP_LENSES',
    MANUALAUTOCOMPLETE = 'MANUAL_AUTOCOMPLETE',
    MANUALBATCHERASE = 'MANUAL_BATCH_ERASE',
    MANUALINPUT = 'MANUAL_INPUT',
    MANUALMERGEUPLOADEDFILE = 'MANUAL_MERGE_UPLOADED_FILE',
    MAPPINGFUZZY = 'MAPPING_FUZZY',
    MAPPINGFUZZYBATCH = 'MAPPING_FUZZY_BATCH',
    MAPPINGSTATIC = 'MAPPING_STATIC',
    PROPAGATEFROMMASTERFORM = 'PROPAGATE_FROM_MASTER_FORM'
}


