import styled from 'styled-components';

export const Wrapper = styled.div`
  border-left: 1px solid ${({ theme }) => theme.atlas.grey[200]};
  height: 130px;
  padding-top: 20px;
  margin-left: 75px;
`;

export const Bar = styled.div<{ color: 'blue' | 'green'; width: number }>`
  color: ${({ color, theme: { atlas } }) =>
    color === 'blue' ? atlas.text.secondary : atlas.teal.primary};
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  padding-left: ${({ width }) => `${width + 10}px`};
  position: relative;
  line-height: 1;
  margin-bottom: 13px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${({ color, theme: { atlas } }) =>
      color === 'blue' ? atlas.blue.a100 : atlas.teal.primary};
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    height: 14px;
    width: ${({ width }) => `${width}px`};
  }
`;

export const Link = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
