import { ApiCallActionType } from '../../store.types';
import companiesApi from '../../../api/new/companiesApi';
import { put } from 'redux-saga/effects';
import i18n from '../../../i18n';
import { flash } from '../../flashes/actions';

export type UploadLogoFunctionType = ((...args: any[]) => void) | undefined;

export interface UploadLogoParamsType {
  file: File;
}

export type UploadLogoActionType = ApiCallActionType<
  '@companies/API/UPLOAD_LOGO',
  UploadLogoParamsType
>;

export const uploadLogo = (file: File): any => ({
  type: '@companies/API/UPLOAD_LOGO',
  payload: {
    endpointPath: () => companiesApi.postCompanyCurrentLogoResource({ file }),
    callParams: { file },
    selector: () => null,
    postActions: [() => put(flash(i18n.t('common:responses.logoSuccess')))],
  },
});
