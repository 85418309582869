import styled, { keyframes } from 'styled-components';

export const Logo = styled.path`
  fill: #549aa0;
`;

const pathAnimation = keyframes`
  to {
    stroke-dashoffset: 325%;
  }
`;

const opacityAnimation = keyframes`
  50% {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
`;

export const AnimatedPath = styled.path`
  fill: none;
  stroke: #c9c9c9;
  opacity: 1;
  stroke-width: 5;
  stroke-dashoffset: 25%;
  stroke-dasharray: 60% 40%;
  animation: ${pathAnimation} 3s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite,
    ${opacityAnimation} 4.3s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
`;
