import atlas from './atlas-palette';
import colors from '../common.colors';
import hex2rgba from 'utils/hex2rgba';
import { css } from 'styled-components';
import {
  fontBold,
  fontLight,
  fontRegular,
  fontSemibold,
  getFontSize,
  monospaceFontRegular,
} from '../common.fonts';
import {
  RequestFindingRemediationPlanEnum,
  RequestFindingRiskLevelEnum,
} from 'generated/models/RequestFinding';

const lightTheme = {
  atlas,
  basic: {
    colors: {
      active: colors.basic.lightBlue,
      activeHover: colors.basic.lightBlueHover,
      atlas: '#6cabae',
      ssc: '#549BA1',
      atlasHover: '#ecf3f4',
      black: colors.basic.black,
      blue: '#0594fe',
      background: '#f5f5f5',
      chart: '#e5bd29',
      dark: colors.basic.dark,
      disabled: '#A8ADAF',
      error: colors.basic.error,
      errorBackground: colors.basic.errorBackground,
      errorBackgroundSolid: colors.basic.errorBackgroundSolid,
      errorHoverBackground: colors.basic.errorHoverBackground,
      extraDark: '#222324',
      gray: colors.basic.gray,
      grayDark: colors.basic.grayDark,
      grayLight: colors.basic.grayLight,
      info: colors.basic.info,
      light: colors.basic.white,
      lightBlueBackground: '#eef5f6',
      lightBorder: '#eeeeee',
      lighterBorder: '#FAFAFA',
      orange: '#fcab10',
      orangeHover: '#E0980E',
      darkerBorder: '#dedfe0',
      teal: '#579AA0',
      notification: '#f57d7e',
      notificationButton: '#7C8487',
      notificationHover: css`rgba(247, 124, 124, 0.15)`,
      headingHover: css`rgba(143, 193, 196, 0.15)`,
      success: colors.basic.success,
      warning: colors.basic.warning,
    },
    animations: {
      fast: 'all 0.1s ease-in-out',
      normal: 'all 0.3s ease-in-out',
      slow: 'all 0.5s ease-in-out',
    },
    fontSizes: {
      tiny: getFontSize('tiny'),
      micro: getFontSize('micro'),
      mini: getFontSize('mini'),
      small: getFontSize('small'),
      normal: getFontSize('normal'),
      medium: getFontSize('medium'),
      large: getFontSize('large'),
      big: getFontSize('big'),
      bigger: getFontSize('bigger'),
      huge: getFontSize('huge'),
      massive: getFontSize('massive'),
    },
    fontWeights: {
      bold: css`
        font-weight: 400;
        font-family: 'Proxima Nova Bold';
      `,
      light: css`
        font-weight: 400;
        font-family: 'Proxima Nova Light';
      `,
      regular: css`
        font-weight: 400;
        font-family: 'Proxima Nova';
      `,
      semibold: css`
        font-weight: 400;
        font-family: 'Proxima Nova Semibold';
      `,
    },
  },
  cookies: {
    primary: '#f1d600',
    hover: '#fffc26',
  },
  fonts: {
    monospace: {
      regular: {
        tiny: monospaceFontRegular('tiny'),
        micro: monospaceFontRegular('micro'),
        mini: monospaceFontRegular('mini'),
        xsmall: monospaceFontRegular('xsmall'),
        small: monospaceFontRegular('small'),
        normal: monospaceFontRegular('normal'),
        medium: monospaceFontRegular('medium'),
        large: monospaceFontRegular('large'),
        big: monospaceFontRegular('big'),
        bigger: monospaceFontRegular('bigger'),
        huge: monospaceFontRegular('huge'),
        massive: monospaceFontRegular('massive'),
      },
    },
    light: {
      tiny: fontLight('tiny'),
      micro: fontLight('micro'),
      mini: fontLight('mini'),
      xsmall: fontLight('xsmall'),
      small: fontLight('small'),
      normal: fontLight('normal'),
      medium: fontLight('medium'),
      large: fontLight('large'),
      big: fontLight('big'),
      bigger: fontLight('bigger'),
      huge: fontLight('huge'),
      massive: fontLight('massive'),
    },
    regular: {
      tiny: fontRegular('tiny'),
      micro: fontRegular('micro'),
      mini: fontRegular('mini'),
      xsmall: fontRegular('xsmall'),
      small: fontRegular('small'),
      normal: fontRegular('normal'),
      medium: fontRegular('medium'),
      large: fontRegular('large'),
      big: fontRegular('big'),
      bigger: fontRegular('bigger'),
      huge: fontRegular('huge'),
      massive: fontRegular('massive'),
    },
    semibold: {
      tiny: fontSemibold('tiny'),
      micro: fontSemibold('micro'),
      mini: fontSemibold('mini'),
      xsmall: fontSemibold('xsmall'),
      small: fontSemibold('small'),
      normal: fontSemibold('normal'),
      medium: fontSemibold('medium'),
      large: fontSemibold('large'),
      big: fontSemibold('big'),
      bigger: fontSemibold('bigger'),
      huge: fontSemibold('huge'),
      massive: fontSemibold('massive'),
    },
    bold: {
      tiny: fontBold('tiny'),
      micro: fontBold('micro'),
      mini: fontBold('mini'),
      xsmall: fontBold('xsmall'),
      small: fontBold('small'),
      normal: fontBold('normal'),
      medium: fontBold('medium'),
      large: fontBold('large'),
      big: fontBold('big'),
      bigger: fontBold('bigger'),
      huge: fontBold('huge'),
      massive: fontBold('massive'),
    },
  },
  inputs: {
    certainty: {
      colors: {
        yes: atlas.skyblue,
        no: atlas.coral,
        na: atlas.text.secondary,
      },
      hover: {
        border: atlas.grey[600],
        text: atlas.grey[600],
        yes: atlas.teal[200],
        no: hex2rgba(atlas.coral, 0.3),
        na: atlas.teal[200],
      },
    },
    creatableSelect: {
      border: colors.elements.text.enabled.border,
      borderHover: colors.elements.text.enabled.borderHover,
      color: atlas.text.secondary,
      focusBorder: '#007eff',
      focusBoxShadow: hex2rgba('#007eff', 0.1),
      hoverBackground: atlas.teal[100],
      hoverColor: atlas.text.primary,
      selectedBackground: atlas.teal.primary,
      selectedColor: colors.basic.white,
    },
    datepicker: {
      border: colors.elements.text.enabled.border,
      borderHover: colors.elements.text.enabled.borderHover,
      selectedBackground: atlas.teal.primary,
      selectedColor: colors.basic.white,
      hoverBackground: atlas.teal[100],
      hoverColor: atlas.text.primary,
    },
    dropdown: {
      background: colors.basic.white,
      borderColor: hex2rgba(colors.basic.dark, 0.3),
      color: colors.basic.dark,
      hoverBackground: atlas.teal[100],
      hoverColor: atlas.text.primary,
      selectedBackground: atlas.teal.primary,
      selectedColor: colors.basic.white,
    },
    maturity: {
      default: {
        background: 'transparent',
        border: atlas.grey[600],
        text: atlas.grey[600],
      },
    },
    multiselect: {
      default: {
        hover: '#e5eff1',
      },
    },
    radio: {
      default: {
        background: 'transparent',
        border: atlas.grey[600],
        text: atlas.grey[600],
      },
      disabled: {
        background: 'transparent',
        border: 'transparent',
        text: atlas.grey[400],
      },
      hover: {
        background: atlas.teal[200],
        border: atlas.grey[600],
        text: atlas.grey[600],
      },
      selected: {
        background: atlas.skyblue,
        border: atlas.skyblue,
        text: atlas.white,
      },
    },
    text: {
      action: {
        color: colors.elements.text.action.color,
        hover: colors.elements.text.action.hover,
      },
      enabled: {
        basic: {
          background: colors.elements.text.enabled.background,
          border: colors.elements.text.enabled.border,
          borderHover: colors.elements.text.enabled.borderHover,
          color: colors.elements.text.enabled.color,
          placeholder: colors.elements.text.enabled.placeholder,
        },
        filled: {
          background: colors.elements.text.filled.background,
          border: colors.elements.text.filled.background,
          borderHover: colors.elements.text.filled.borderHover,
          color: colors.elements.text.enabled.color,
          placeholder: colors.elements.text.enabled.placeholder,
        },
      },
      disabled: {
        basic: {
          background: colors.elements.text.disabled.background,
          border: colors.elements.text.disabled.border,
          color: colors.elements.text.disabled.color,
          placeholder: colors.elements.text.disabled.placeholder,
        },
        filled: {
          background: colors.elements.text.disabledFilled.background,
          border: colors.elements.text.disabledFilled.background,
          color: colors.elements.text.disabled.color,
          placeholder: colors.elements.text.disabled.placeholder,
        },
      },
    },
    textarea: {
      borderHover: colors.elements.text.enabled.borderHover,
      colors: {
        border: colors.elements.textarea.border,
        placeholder: colors.elements.textarea.placeholder,
      },
      enabled: {
        background: colors.elements.text.enabled.background,
        border: colors.elements.text.enabled.border,
        borderHover: colors.elements.text.enabled.borderHover,
        color: colors.elements.text.enabled.color,
        placeholder: colors.elements.text.enabled.placeholder,
      },
      disabled: {
        background: colors.elements.text.disabled.background,
        border: colors.elements.text.disabled.border,
        color: colors.elements.text.disabled.color,
        placeholder: colors.elements.text.disabled.placeholder,
      },
    },
    select: {
      colors: {
        border: '#333',
        disabled: colors.basic.gray,
        option: colors.basic.grayLight,
        optionSelected: colors.basic.green,
      },
    },
  },
  findings: {
    riskLevel: {
      [RequestFindingRiskLevelEnum.NONE]: '#E5E8E8',
      [RequestFindingRiskLevelEnum.LOW]: atlas.grade.atlas.b,
      [RequestFindingRiskLevelEnum.MEDIUM]: atlas.grade.atlas.c,
      [RequestFindingRiskLevelEnum.HIGH]: atlas.grade.atlas.d,
      [RequestFindingRiskLevelEnum.CRITICAL]: '#E74C3C',
    },
    remediationPlan: {
      [RequestFindingRemediationPlanEnum.ACCEPT]: atlas.teal['900'],
      [RequestFindingRemediationPlanEnum.REMEDIATE]: atlas.blue['800'],
      [RequestFindingRemediationPlanEnum.COMPLETE]: atlas.blue['800'],
    },
  },
  formCreator: {
    validationOverlay: '#c4c4c4',
  },
  history: {
    answerColor: '#5E7B8B',
  },
  messages: {
    bubbles: {
      sent: '#eee',
      received: '#79AEB3',
      internal: '#cee9eb',
    },
  },
  uploadArea: {
    background: '#40939A',
    progressBackground: '#3E6D71',
    completed: '#3EA5AB',
    transparentLight: (transparency: number) =>
      `rgba(255, 255, 255, ${transparency})`,
    transparentDark: (transparency: number) => `rgba(0, 0, 0, ${transparency})`,
    error: `#6E6E6E`,
  },
  request: {
    barBackground: '#F6F6F6',
    borderColor: '#e2e2e2',
    boxShadow: '0 2px 5px rgba(37, 38, 94, 0.1)',
    link: '#333',
    subinfo: '#787993',
    noSpecified: '#c4c4c4',
  },
  tabs: {
    inactive: '#EBEBEB',
  },
  questionnaire: {
    checkbox: {
      selected: {
        background: colors.elements.checkbox.questionnaire.selected.background,
        text: colors.elements.checkbox.questionnaire.selected.text,
      },
      active: {
        background: colors.elements.checkbox.questionnaire.active.background,
        text: colors.elements.checkbox.questionnaire.active.text,
      },
      disabled: {
        background: colors.elements.checkbox.questionnaire.active.background,
        text: colors.elements.checkbox.questionnaire.active.text,
      },
    },
  },
  questionMark: {
    background: '#919191',
  },
  stepper: {
    heading: '#253237',
  },
  sendRequest: {
    uploadBar: {
      background: '#EEF5F5',
    },
  },
  nav: {
    leftBackground: '#44494a',
    button: {
      bg: '#6cabae',
      text: 'white',
    },
    icon: {
      color: atlas.teal[700],
    },
    logo: {
      height: '40px',
      src: '/atlas-logo.svg',
    },
  },
  header: {
    backgroundColor: '#5798A0',
    backgroundImage: 'angle-abstract-bg-blasticpsd.png',
  },
  upsell: {
    quantityInput: {
      focus: {
        background: atlas.teal[100],
        border: atlas.teal[500],
      },
      default: {
        background: atlas.grey[50],
        border: atlas.grey[400],
      },
    },
    creditsBundle: {
      border: {
        default: atlas.grey[100],
      },
      priceWrapper: {
        background: {
          normal: atlas.teal[100],
          smallDiscount: '#E83D5910',
          steepDiscount: '#F8F0D2',
        },
      },
      price: {
        background: {
          normal: atlas.teal[500],
          smallDiscount: '#E83D59',
          steepDiscount: '#DAB21E',
        },
        textColor: 'white',
      },
    },
    separator: {
      border: atlas.grey[500],
    },
  },
};

export default lightTheme;
// export type LightThemeType = typeof lightTheme;
