import { CompanyInternalStatusEnum } from 'generated/models/Company';
import { CompanySimpleInternalStatusEnum } from 'generated/models/CompanySimple';

export const isFullFledgedCompany = (
  internalStatus: CompanyInternalStatusEnum | CompanySimpleInternalStatusEnum,
): boolean => {
  if (internalStatus === CompanyInternalStatusEnum.NORMAL) {
    return true;
  }
  return false;
};
