import { NavigationContextEnum } from '../../../common/common.types';

export interface UserSwitchContextActionType {
  type: '@user/SWITCH_CONTEXT';
  payload: {
    navigationContext: NavigationContextEnum;
  };
}

export const switchContext = (
  navigationContext: NavigationContextEnum,
): UserSwitchContextActionType => ({
  type: '@user/SWITCH_CONTEXT',
  payload: {
    navigationContext,
  },
});
