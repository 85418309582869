import {
  FormDefinitionDraftCategoryStatsType,
  QuestionDefinitionSummaryType,
} from '../../../common/common.types';
import { findIndex, sort } from 'ramda';

const DEFAULT_SEPARATOR = '.';
const FIRST_CHAR_CODE = 'A'.charCodeAt(0);
const LAST_CHAR_CODE = 'Z'.charCodeAt(0);

export const indexToLetters = (n: number) => {
  n++;
  const base = LAST_CHAR_CODE - FIRST_CHAR_CODE + 1;

  const codeArray = [];
  let remainder: number;
  while (n > 0) {
    remainder = n % base;
    if (remainder === 0) {
      codeArray.push(String.fromCharCode(LAST_CHAR_CODE));
      n = Math.floor(n / base) - 1;
    } else {
      codeArray.push(String.fromCharCode(FIRST_CHAR_CODE + (remainder - 1)));
      n = Math.floor(n / base);
    }
  }
  return codeArray.reverse().join('');
};

/**
 * For 'ASDF.BSD.42.5' returns ['ASDF.BSD.42.', 5],
 * for 'ASDF.42.BS' returns null.
 */
const tryParseEndingNumber = (origCode: string): [string, number] | null => {
  const res = /\d+$/.exec(origCode);
  if (res && res[0]) {
    const maybeNum = parseInt(res[0], 10);
    // we don't check maybeNum == NaN here because a valid number format
    // is already guaranteed by the regex above.
    return [origCode.substr(0, res.index), maybeNum];
  } else {
    return null;
  }
};

const containsQuestionWithCode = (
  questions: readonly QuestionDefinitionSummaryType[],
  code: string,
) => questions.some(({ code: c }) => c === code);

const getFirstAvailableQuestionCode = (
  categories: readonly FormDefinitionDraftCategoryStatsType[],
  questions: readonly QuestionDefinitionSummaryType[],
  categoryName: string,
  customStartCode?: string,
): string => {
  // Get the the first candidate for code prefix + number
  const [prefix, startingNum] = ((): [string, number] => {
    //
    // A. Check wheter user provided custom starter code override

    const maybeParsedCustomStartCode = customStartCode
      ? tryParseEndingNumber(customStartCode)
      : null;
    if (maybeParsedCustomStartCode) {
      return maybeParsedCustomStartCode;
    }

    // B. Adding to the end of an existing category

    const existingCategoryIndex = findIndex(
      ({ name }) => name === categoryName,
      categories,
    );
    if (existingCategoryIndex !== -1) {
      const categoryMembers = questions.filter(
        ({ category }: QuestionDefinitionSummaryType) =>
          category === categoryName,
      );
      const categoryMembersSorted = sort(
        (
          { pos: posA }: QuestionDefinitionSummaryType,
          { pos: posB }: QuestionDefinitionSummaryType,
        ) => posA - posB,
        categoryMembers,
      );
      if (categoryMembersSorted.length > 0) {
        const lastItemCode =
          categoryMembersSorted[categoryMembersSorted.length - 1].code.trim() ||
          // When code is empty, fallback to letters prefix
          indexToLetters(existingCategoryIndex);

        const res = tryParseEndingNumber(lastItemCode);

        return res || [lastItemCode + DEFAULT_SEPARATOR, 1];
      } else {
        return [indexToLetters(existingCategoryIndex) + DEFAULT_SEPARATOR, 1];
      }
    }

    // C. (FALLBACK/DEFAULT) Adding to the end of a non-existing category

    return [indexToLetters(categories.length) + DEFAULT_SEPARATOR, 1];
  })();

  // Increment the number until we find some unique.
  let i = startingNum;
  let code;
  while (containsQuestionWithCode(questions, (code = `${prefix}${i}`))) {
    i++;
  }
  return code;
};

export default getFirstAvailableQuestionCode;
