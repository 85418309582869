import * as R from 'ramda';
import i18n from '../../../i18n';
import { CreditsChangeSummaryMeta } from '../../../generated/models/CreditsChangeSummaryMeta';
import {
  NotificationEnum,
  RequestSimpleType,
  Uuid,
} from '../../../common/common.types';
import { RequestModifyListCallParamsType } from './archiveRequest';
import { RequestSummaryStatusEnum } from '../../../generated/models/RequestSummary';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { getInfo } from '../../user/actions';
import { setSettings } from '../../settings/actions';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { RequestsStateType } from '../requests.types';

export type RequestDeleteFunctionType = (ids: Uuid[]) => void;

export type RequestDeleteActionType = ApiCallActionType<
  '@requests/API/DELETE_REQUEST',
  RequestModifyListCallParamsType,
  never,
  never,
  RequestsStateType,
  CreditsChangeSummaryMeta
>;

const { CANCELED } = RequestSummaryStatusEnum;

export const deleteRequest = (ids: Uuid[]): RequestDeleteActionType => ({
  type: '@requests/API/DELETE_REQUEST',
  payload: {
    callParams: {
      request_ids: ids,
      status: CANCELED,
    },
    endpointPath: ['requests', 'modifyList'],
    selector: (data, state) =>
      R.pipe(
        R.assocPath<RequestSimpleType[], RequestsStateType>(
          ['sent', 'unsorted'],
          state.requests.sent.unsorted.filter(r => !R.contains(r.id, ids)),
        ),
        R.assocPath(
          ['sent', 'categories', 'created'],
          (() => {
            const { created } = state.requests.sent.categories;
            const requests = created.requests.filter(
              r => !R.contains(r.id, ids),
            );
            return {
              ...created,
              selected: [],
              count: requests.length,
              requests,
            };
          })(),
        ),
      )(state.requests),
    postActions: [
      () => call(() => trackEvent('Request', `Change status -> ${CANCELED}`)),
      () =>
        put(
          flash(
            i18n.t('common:responses.requestDeleted', { count: ids.length }),
          ),
        ),
      (payload, state, meta) => {
        if (
          meta &&
          meta.credits_change_summary &&
          meta.credits_change_summary.balance_diff > 0
        ) {
          return put(
            flash(
              i18n.t('common:responses.creditReturned', {
                count: meta.credits_change_summary.balance_diff,
              }),
              'info',
            ),
          );
        }
        return null;
      },
      () =>
        put(
          setSettings({
            dismissedNotifications: {
              [NotificationEnum.GRANTED]: new Date().getTime(),
            },
          }),
        ),
      () => put(getInfo()),
    ],
  },
});
