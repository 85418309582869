/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionMetadataBlockStats,
    QuestionMetadataBlockStatsFromJSON,
    QuestionMetadataBlockStatsFromJSONTyped,
    QuestionMetadataBlockStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionMetadataBlocks
 */
export interface QuestionMetadataBlocks {
    /**
     * 
     * @type {QuestionMetadataBlockStats}
     * @memberof QuestionMetadataBlocks
     */
    attachments: QuestionMetadataBlockStats;
    /**
     * 
     * @type {QuestionMetadataBlockStats}
     * @memberof QuestionMetadataBlocks
     */
    comments: QuestionMetadataBlockStats;
    /**
     * 
     * @type {QuestionMetadataBlockStats}
     * @memberof QuestionMetadataBlocks
     */
    history: QuestionMetadataBlockStats;
    /**
     * 
     * @type {QuestionMetadataBlockStats}
     * @memberof QuestionMetadataBlocks
     */
    sscData: QuestionMetadataBlockStats;
}

export function QuestionMetadataBlocksFromJSON(json: any): QuestionMetadataBlocks {
    return QuestionMetadataBlocksFromJSONTyped(json, false);
}

export function QuestionMetadataBlocksFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionMetadataBlocks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachments': QuestionMetadataBlockStatsFromJSON(json['attachments']),
        'comments': QuestionMetadataBlockStatsFromJSON(json['comments']),
        'history': QuestionMetadataBlockStatsFromJSON(json['history']),
        'sscData': QuestionMetadataBlockStatsFromJSON(json['ssc_data']),
    };
}

export function QuestionMetadataBlocksToJSON(value?: QuestionMetadataBlocks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachments': QuestionMetadataBlockStatsToJSON(value.attachments),
        'comments': QuestionMetadataBlockStatsToJSON(value.comments),
        'history': QuestionMetadataBlockStatsToJSON(value.history),
        'ssc_data': QuestionMetadataBlockStatsToJSON(value.sscData),
    };
}


