/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Company } from "./Company";
// @ts-ignore
import { Team } from "./Team";
// @ts-ignore
import { User } from "./User";

/**
 * 
 * @export
 * @interface UserDetail
 */
export interface UserDetail {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof UserDetail
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof UserDetail
     */
    updated_at: Date;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    company_id: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    display_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    full_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    id: string;
    /**
     * Has the user ever signed into the system?
     * @type {boolean}
     * @memberof UserDetail
     */
    is_active: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    last_name: string;
    /**
     * 
     * @type {Company}
     * @memberof UserDetail
     */
    company: Company;
    /**
     * - `NORMAL` indicates that user is fully initialized and can perform all actions
     * - `MERGING` indicates that entities that belong to the company where user was before are being
     *   merged into their current company
     * - `DELETED` indicates that the user was deleted - it is not used now
     * @type {string}
     * @memberof UserDetail
     */
    internal_status: UserDetailInternalStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDetail
     */
    roles: Array<UserDetailRolesEnum>;
    /**
     * Teams this user belongs to
     * @type {Array<Team>}
     * @memberof UserDetail
     */
    teams: Array<Team>;
}




/**
* @export
* @enum {string}
*/
export enum UserDetailInternalStatusEnum {
    DELETED = 'DELETED',
    MERGING = 'MERGING',
    NORMAL = 'NORMAL',
    INVITED = 'INVITED'
}
/**
* @export
* @enum {string}
*/
export enum UserDetailRolesEnum {
    CUSTOMERADMIN = 'CUSTOMER_ADMIN',
    GUEST = 'GUEST',
    NORMAL = 'NORMAL',
    SALES = 'SALES',
    SYSTEMADMIN = 'SYSTEM_ADMIN'
}


