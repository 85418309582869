import { CompanyGradeEnum } from 'generated/models/Company';

export default (score: number): CompanyGradeEnum => {
  if (score >= 90 && score <= 100) {
    return CompanyGradeEnum.A;
  } else if (score >= 80 && score < 90) {
    return CompanyGradeEnum.B;
  } else if (score >= 70 && score < 80) {
    return CompanyGradeEnum.C;
  } else if (score >= 60 && score < 70) {
    return CompanyGradeEnum.D;
  } else if (score >= 0 && score < 60) {
    return CompanyGradeEnum.F;
  }
  return CompanyGradeEnum.Empty;
};
