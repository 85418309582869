/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResponseDefinitionEntityError
 */
export interface ResponseDefinitionEntityError {
    /**
     * 
     * @type {string}
     * @memberof ResponseDefinitionEntityError
     */
    code: ResponseDefinitionEntityErrorCodeEnum;
}

export function ResponseDefinitionEntityErrorFromJSON(json: any): ResponseDefinitionEntityError {
    return ResponseDefinitionEntityErrorFromJSONTyped(json, false);
}

export function ResponseDefinitionEntityErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseDefinitionEntityError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
    };
}

export function ResponseDefinitionEntityErrorToJSON(value?: ResponseDefinitionEntityError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
    };
}

/**
* @export
* @enum {string}
*/
export enum ResponseDefinitionEntityErrorCodeEnum {
    DUPLICATEDOPTIONVALUE = 'DUPLICATED_OPTION_VALUE',
    EMPTYOPTIONVALUE = 'EMPTY_OPTION_VALUE',
    LESSTHANTWOOPTIONSINRESPONSE = 'LESS_THAN_TWO_OPTIONS_IN_RESPONSE'
}


