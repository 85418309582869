/* eslint-disable */
// tslint:disable
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    ExceptionResponse,
    ScheduledRequestCreate,
    ScheduledRequestInternalCreate,
    ScheduledRequestResponse,
    ScheduledRequestUpdate,
    ScheduledRequestsListResponse,
    ScheduledRequestCreateToJSON,
    ScheduledRequestInternalCreateToJSON,
    ScheduledRequestResponseFromJSON,
    ScheduledRequestUpdateToJSON,
    ScheduledRequestsListResponseFromJSON,
} from '../models';

export interface DeleteScheduledRequestDetailResourceRequest {
    scheduledRequestId: string;
}

export interface GetScheduledRequestDetailResourceRequest {
    scheduledRequestId: string;
}

export interface PostScheduledRequestSkipNextScheduledSendRequest {
    scheduledRequestId: string;
}

export interface PostScheduledRequestsInternalResourceRequest {
    payload: ScheduledRequestInternalCreate;
}

export interface PostScheduledRequestsResourceRequest {
    payload: ScheduledRequestCreate;
}

export interface PutScheduledRequestDetailResourceRequest {
    scheduledRequestId: string;
    payload: ScheduledRequestUpdate;
}

/**
 * no description
 */
export class ScheduledRequestsApi extends BaseAPI {

    /**
     */
    deleteScheduledRequestDetailResource = ({ scheduledRequestId }: DeleteScheduledRequestDetailResourceRequest): Observable<ScheduledRequestResponse> => {
        throwIfNullOrUndefined(scheduledRequestId, 'deleteScheduledRequestDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<ScheduledRequestResponse>({
            parser: jsonValue => ScheduledRequestResponseFromJSON(jsonValue),
            path: '/atlas/scheduled_requests/{scheduled_request_id}'.replace('{scheduled_request_id}', encodeURI(scheduledRequestId)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     */
    getScheduledRequestDetailResource = ({ scheduledRequestId }: GetScheduledRequestDetailResourceRequest): Observable<ScheduledRequestResponse> => {
        throwIfNullOrUndefined(scheduledRequestId, 'getScheduledRequestDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<ScheduledRequestResponse>({
            parser: jsonValue => ScheduledRequestResponseFromJSON(jsonValue),
            path: '/atlas/scheduled_requests/{scheduled_request_id}'.replace('{scheduled_request_id}', encodeURI(scheduledRequestId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return list of user\'s scheduled requests
     */
    getScheduledRequestsResource = (): Observable<ScheduledRequestsListResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<ScheduledRequestsListResponse>({
            parser: jsonValue => ScheduledRequestsListResponseFromJSON(jsonValue),
            path: '/atlas/scheduled_requests',
            method: 'GET',
            headers,
        });
    };

    /**
     */
    postScheduledRequestSkipNextScheduledSend = ({ scheduledRequestId }: PostScheduledRequestSkipNextScheduledSendRequest): Observable<ScheduledRequestResponse> => {
        throwIfNullOrUndefined(scheduledRequestId, 'postScheduledRequestSkipNextScheduledSend');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<ScheduledRequestResponse>({
            parser: jsonValue => ScheduledRequestResponseFromJSON(jsonValue),
            path: '/atlas/scheduled_requests/{scheduled_request_id}/actions/skip_next'.replace('{scheduled_request_id}', encodeURI(scheduledRequestId)),
            method: 'POST',
            headers,
        });
    };

    /**
     * Send new internal scheduled request(s)
     */
    postScheduledRequestsInternalResource = ({ payload }: PostScheduledRequestsInternalResourceRequest): Observable<ScheduledRequestsListResponse> => {
        throwIfNullOrUndefined(payload, 'postScheduledRequestsInternalResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<ScheduledRequestsListResponse>({
            parser: jsonValue => ScheduledRequestsListResponseFromJSON(jsonValue),
            path: '/atlas/scheduled_requests/internal',
            method: 'POST',
            headers,
            body: ScheduledRequestInternalCreateToJSON(payload),
        });
    };

    /**
     * Send new scheduled request(s)
     */
    postScheduledRequestsResource = ({ payload }: PostScheduledRequestsResourceRequest): Observable<ScheduledRequestsListResponse> => {
        throwIfNullOrUndefined(payload, 'postScheduledRequestsResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<ScheduledRequestsListResponse>({
            parser: jsonValue => ScheduledRequestsListResponseFromJSON(jsonValue),
            path: '/atlas/scheduled_requests',
            method: 'POST',
            headers,
            body: ScheduledRequestCreateToJSON(payload),
        });
    };

    /**
     * Update single scheduled request
     */
    putScheduledRequestDetailResource = ({ scheduledRequestId, payload }: PutScheduledRequestDetailResourceRequest): Observable<ScheduledRequestResponse> => {
        throwIfNullOrUndefined(scheduledRequestId, 'putScheduledRequestDetailResource');
        throwIfNullOrUndefined(payload, 'putScheduledRequestDetailResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<ScheduledRequestResponse>({
            parser: jsonValue => ScheduledRequestResponseFromJSON(jsonValue),
            path: '/atlas/scheduled_requests/{scheduled_request_id}'.replace('{scheduled_request_id}', encodeURI(scheduledRequestId)),
            method: 'PUT',
            headers,
            body: ScheduledRequestUpdateToJSON(payload),
        });
    };

}
