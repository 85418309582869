/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormProxiesCreate
 */
export interface FormProxiesCreate {
    /**
     * 
     * @type {string}
     * @memberof FormProxiesCreate
     */
    emailBody?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormProxiesCreate
     */
    proxyRoles: Array<FormProxiesCreateProxyRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof FormProxiesCreate
     */
    userEmail: string;
}

export function FormProxiesCreateFromJSON(json: any): FormProxiesCreate {
    return FormProxiesCreateFromJSONTyped(json, false);
}

export function FormProxiesCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormProxiesCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailBody': !exists(json, 'email_body') ? undefined : json['email_body'],
        'proxyRoles': json['proxy_roles'],
        'userEmail': json['user_email'],
    };
}

export function FormProxiesCreateToJSON(value?: FormProxiesCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_body': value.emailBody,
        'proxy_roles': value.proxyRoles,
        'user_email': value.userEmail,
    };
}

/**
* @export
* @enum {string}
*/
export enum FormProxiesCreateProxyRolesEnum {
    VENDORPROXY = 'VENDOR_PROXY',
    VRMPROXY = 'VRM_PROXY'
}


