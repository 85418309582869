import Radio from './Radio';
import React from 'react';
import Select from './Select';
import { ResponseInputPropsType } from '../Response';

const MAX_OPTIONS_IN_RADIO = 8;

const AdaptableSelect = ({
  actions,
  data,
  data: { options = [] },
}: ResponseInputPropsType) =>
  options.length > MAX_OPTIONS_IN_RADIO ? (
    <Select actions={actions} data={data} />
  ) : (
    <Radio actions={actions} data={data} />
  );

export default AdaptableSelect;
