import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import { findIndex, propEq } from 'ramda';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';

export type MoveCategoryFunctionType = (params: MoveCategoryParamsType) => void;

export type MoveCategoryActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/MOVE_CATEGORY'
>;

export interface MoveCategoryParamsType {
  categoryName: string;
}

export const moveCategory = ({
  categoryName,
}: MoveCategoryParamsType): MoveCategoryActionType => {
  return {
    type: '@formCreator/EVENTS/MOVE_CATEGORY',
    payload: {
      events: ({ formCreator }) => {
        const sourceIndex = findIndex(
          propEq('name', categoryName),
          formCreator.currentForm!.categories,
        );
        let putBefore = true;
        let targetId = '';
        if (sourceIndex === formCreator.currentForm!.categories.length - 1) {
          targetId = formCreator.currentForm!.categories[sourceIndex - 1].name;
          putBefore = false;
        } else {
          targetId = formCreator.currentForm!.categories[sourceIndex + 1].name;
        }
        return [
          {
            action: FormEditEventActionCodeEnum.MOVECATEGORY,
            moved_category: categoryName,
            target_category: targetId,
            before: putBefore,
          },
        ];
      },
      selector: ({ formCreator }) => formCreator,
    },
  };
};
