import React from 'react';
import Recurrence from './Recurrence';
import SendDate from './SendDate';
import { LanguageType, RecurrenceItemType } from 'common/common.types';
import { SetFormValueFunctionType } from 'store/requests/actions';
import { Wrapper } from './Schedule.styles';

interface SchedulePropsType {
  recurrence: RecurrenceItemType;
  sendDate: Date;
  setValue: SetFormValueFunctionType;
  language: LanguageType;
}

const Schedule = ({
  recurrence,
  sendDate,
  setValue,
  language,
}: SchedulePropsType) => (
  <Wrapper>
    <SendDate sendDate={sendDate} setValue={setValue} />

    <Recurrence
      recurrence={recurrence}
      sendDate={sendDate}
      setValue={setValue}
      language={language}
    />
  </Wrapper>
);

export default Schedule;
