import { pickBy } from 'ramda';
import formatRoute from '../routes';

const updatePathParams = (
  path: string,
  currentParams: object,
  toUpdate: object,
): { state: object; path: string } => {
  const newParams = {
    ...window.history.state,
    ...toUpdate,
  };
  const omitUndefined = pickBy(val => val, {
    ...currentParams,
    ...toUpdate,
  });

  return {
    state: newParams,
    path: formatRoute(path, omitUndefined),
  };
};

export default updatePathParams;
