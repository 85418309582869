import RcTooltip from 'rc-tooltip';
import React, { ReactFragment } from 'react';

interface TooltipPropsType {
  children: React.ReactNode;
  overlay: React.ReactNode;
  placement?: string;
  mouseEnterDelay?: number;
  visible?: boolean;
}

const Tooltip = ({
  children,
  overlay,
  placement,
  mouseEnterDelay = 0,
  visible,
}: TooltipPropsType) => {
  // need to split this into two parts, because setting visible as undefined
  // is not the same as simply not providing the prop at all (undefined would
  // convert to false and tooltips that don't use visible would not show)
  const overlayFragment = overlay as ReactFragment;
  if (visible !== undefined) {
    return (
      <RcTooltip
        overlay={overlayFragment}
        placement={placement || 'bottom'}
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
        mouseEnterDelay={mouseEnterDelay}
        visible={visible}
      >
        {children}
      </RcTooltip>
    );
  }
  return (
    <RcTooltip
      overlay={overlayFragment}
      placement={placement || 'bottom'}
      arrowContent={<div className="rc-tooltip-arrow-inner" />}
      mouseEnterDelay={mouseEnterDelay}
    >
      {children}
    </RcTooltip>
  );
};

export default Tooltip;
