import { UserInfo } from 'generated/models/UserInfo';
import config, { Env } from '../config';

export default function({ user: { id, email, company } }: UserInfo) {
  if (config.env === Env.PROD) {
    // @ts-ignore
    window.pendo.initialize({
      visitor: {
        id: email,
      },

      account: {
        id: company.id,
        name: company.name,
        creationDate: company.created_at,
        domain: company.domain,
      },
    });
  }
}
