import { format } from 'date-fns';
import { contains } from 'ramda';
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import withUrl from 'utils/url/withUrl';
import { BarWrapper } from '../../common/Bar/Bar.styles';
import { Button } from '../../common/Button/Button.styles';
import { Flex } from 'common/common.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormDetailFillingTypeEnum } from 'generated/models/FormDetail';
import { FormsRenameFormFunctionType } from 'store/forms/actions';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { trackEvent } from 'utils/analytics';
import { withTranslation } from 'react-i18next';
import {
  Action,
  ClickerWrapper,
  Info,
  RightWrapper,
  TextInput,
  Version,
} from './Bar.styles';
import {
  DATE_FORMAT_BASED_ON_LOCALE,
  FILLING_TYPE_WITH_DESCRIPTION,
} from 'common/common.constants';
import {
  LanguageType,
  RequestType,
  SharedFormType,
  StandardType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import getLocale from 'utils/getLocale';
import { DropdownsEnum } from './FormHeader';
import { FormProxyShowProxyModalFunctionType } from 'store/formProxy/actions';
import RequestTitle from './RequestTitle';
import RequestStatus from './RequestStatus';
import { StateType } from '../../../store/store.types';
import { getLanguagePreference } from '../../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface BarPropsType extends TranslatePropsType {
  createdAt: Date;
  currentDropdown?: DropdownsEnum;
  'data-cy'?: string;
  displayName: string;
  fillingType: FormDetailFillingTypeEnum;
  formId: Uuid;
  initialNameEditing?: boolean;
  isAutocompleteVisible: boolean;
  isHistoryVisible: boolean;
  isProxyVisible: boolean;
  isReportVisible: boolean;
  isVendor: boolean;
  isUploadedFromUser: boolean;
  isVRM: boolean;
  navigate: (url: string) => void;
  renameForm: FormsRenameFormFunctionType;
  request?: RequestType;
  sharedFormData?: SharedFormType;
  showProxyModal: FormProxyShowProxyModalFunctionType;
  standard: StandardType;
  toggleDropdown: (dropdown: DropdownsEnum) => void;
  language: LanguageType;
}

const Bar = ({
  createdAt,
  currentDropdown,
  'data-cy': dataCy,
  displayName,
  fillingType,
  formId,
  initialNameEditing = false,
  isAutocompleteVisible,
  isHistoryVisible,
  isProxyVisible,
  isReportVisible,
  isVendor,
  isUploadedFromUser,
  isVRM,
  navigate,
  renameForm,
  request,
  sharedFormData,
  showProxyModal,
  standard: { version },
  toggleDropdown,
  language,
  t,
}: BarPropsType) => {
  const [isNameEditing, setNameEditing] = useState(initialNameEditing);
  const [editedName, setEditedName] = useState(displayName);

  const isRequest =
    request && fillingType === FormDetailFillingTypeEnum.REQUEST;
  const isSnapshot = fillingType === FormDetailFillingTypeEnum.SNAPSHOT;

  const onNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(value);
  };

  const getSnapshotOptions = ({
    owner: { name: owner },
    sharedBy: { company, displayName: sentBy },
  }: SharedFormType) => ({
    createdAt: format(createdAt, DATE_FORMAT_BASED_ON_LOCALE[language.value], {
      locale: getLocale(language),
    }),
    company: company.name,
    context: company.name ? 'company' : 'user',
    sentBy: sentBy ? sentBy : owner,
    owner,
  });

  const handleClick = () => {
    setNameEditing(false);
    renameForm(editedName, formId);
  };

  return (
    <BarWrapper border noVerticalPadding data-cy={dataCy}>
      <Info>
        {isRequest && (
          <RequestTitle isVRM={isVRM} navigate={navigate} request={request!} />
        )}

        <Flex>
          {isNameEditing ? (
            <Flex>
              <TextInput
                type={'text'}
                onChange={onNameChange}
                value={editedName}
              />

              <Button disabled={editedName === ''} onClick={handleClick}>
                {t('rename') as string}
              </Button>
            </Flex>
          ) : (
            <strong>
              {
                t('displayName', {
                  displayName,
                  context: fillingType,
                }) as string
              }
            </strong>
          )}

          {isUploadedFromUser && !isNameEditing && (
            <ClickerWrapper onClick={() => setNameEditing(true)}>
              <FontAwesomeIcon
                data-tip={t('renameTip') as string}
                icon={faEdit as IconProp}
              />
            </ClickerWrapper>
          )}

          <Version>{version}</Version>
        </Flex>

        {contains(fillingType, FILLING_TYPE_WITH_DESCRIPTION) && (
          <div data-cy="questionnaireFillingTypeDesc">
            {ReactHtmlParser(
              t(
                `fillingTypeDescHTML.${fillingType}`,
                isSnapshot && sharedFormData
                  ? getSnapshotOptions(sharedFormData)
                  : undefined,
              ),
            )}
          </div>
        )}
      </Info>

      <RightWrapper>
        {isRequest && <RequestStatus isVendor={isVendor} request={request!} />}

        <Action
          active={currentDropdown === DropdownsEnum.DETAILS}
          onClick={() => toggleDropdown(DropdownsEnum.DETAILS)}
          data-cy="questionnaireDetails"
        >
          {t('actions.details') as string}
        </Action>

        {isProxyVisible && request && (
          <Action
            onClick={() => showProxyModal(request)}
            data-cy="questionnaireProxy"
          >
            {t('actions.proxy') as string}
          </Action>
        )}

        {isAutocompleteVisible && (
          <Action
            active={currentDropdown === DropdownsEnum.AUTOCOMPLETE}
            onClick={() => toggleDropdown(DropdownsEnum.AUTOCOMPLETE)}
            data-cy="questionnaireAutoComplete"
          >
            {t('actions.autocomplete') as string}
          </Action>
        )}

        <a
          href={withUrl(`forms/${formId}/downloads/attachments`)}
          onClick={() => trackEvent('Form', 'Download')}
          data-cy="questionnaireDownload"
        >
          <Action>{t('actions.download') as string}</Action>
        </a>

        {isHistoryVisible && (
          <Action
            active={currentDropdown === DropdownsEnum.HISTORY}
            onClick={() => toggleDropdown(DropdownsEnum.HISTORY)}
            data-cy="questionnaireHistory"
          >
            {t('actions.history') as string}
          </Action>
        )}

        {isReportVisible && request && (
          <Action
            active={currentDropdown === DropdownsEnum.REPORT}
            onClick={() => toggleDropdown(DropdownsEnum.REPORT)}
            data-cy="questionnaireReport"
          >
            {t('actions.report') as string}
          </Action>
        )}
      </RightWrapper>
    </BarWrapper>
  );
};

const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation('FormHeader')(Bar));
