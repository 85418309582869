/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface SleepData
 */
export interface SleepData {
    /**
     * 
     * @type {string}
     * @memberof SleepData
     */
    queue?: SleepDataQueueEnum;
    /**
     * 
     * @type {number}
     * @memberof SleepData
     */
    seconds: number;
}




/**
* @export
* @enum {string}
*/
export enum SleepDataQueueEnum {
    Default = 'default',
    Email = 'email',
    Mlengine = 'mlengine',
    Setup = 'setup'
}


