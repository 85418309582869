import Page, { PageActionsBaseType, PageBaseType, PagePropsType } from './Page';
import React from 'react';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import { showModal } from '../utils/Modal';
import { resetModals } from '../utils/Modal/store/actions/resetModals';
import {
  FormGetQuestionsParamsType,
  FormsGetPreviewFunctionType,
  FormsLoadQuestionsFunctionType,
  FormsPutQuestionFunctionType,
  getPreview,
  loadQuestions,
  putQuestion,
} from 'store/forms/actions';
import {
  FormType,
  UserType,
  StandardBranchType,
  Uuid,
  LanguageType,
} from 'common/common.types';

import {
  deleteStandard,
  StandardsDeleteFunctionType,
  StandardsDeleteParamsType,
  renameStandard,
  RenameStandardFunctionType,
  RenameStandardParamsType,
  resetStandards,
  uploadStandard,
  setFetchedStatuses,
} from 'store/standard/actions';

import { StandardStateType } from 'store/standard/reducer';

import {
  createEmptyForm,
  CreateEmptyFormFunctionType,
} from 'store/formCreator/actions';

import { UploadCustomStandard } from 'components/UploadCustomStandard';

/**
 * Statuses of updated standards that are fetched after rename/delete
 * operations
 */
const FETCHED_STATUSES: StandardBranchType[] = ['custom'];

export interface UploadCustomStandardPageContextType extends PageBaseType {
  data: {
    previewForm: FormType;
    standards: StandardStateType;
    user: UserType;
    language: LanguageType;
  };
  actions: PageActionsBaseType & {
    createEmptyForm: CreateEmptyFormFunctionType;
    deleteStandard: StandardsDeleteFunctionType;
    getPreview: FormsGetPreviewFunctionType;
    hideModal: (modalId: string) => void;
    loadQuestions: FormsLoadQuestionsFunctionType;
    putQuestion: FormsPutQuestionFunctionType;
    renameStandard: RenameStandardFunctionType;
    showModal: (modalId: string) => void;
    uploadStandard: (formData: FormData) => void;
  };
}

const UploadCustomStandardPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      previewForm: state.forms.currentForm,
      standards: state.standard,
      user: state.user.currentUser,
      language: state.settings.languagePreference,
    })}
    dispatches={(dispatch: Dispatch<ActionType>) => ({
      reset: () => {
        dispatch(resetStandards());
        dispatch(resetModals());
      },
      actions: {
        createEmptyForm: (standardName?: string) => {
          dispatch(createEmptyForm(standardName));
        },
        deleteStandard: (params: StandardsDeleteParamsType) => {
          dispatch(setFetchedStatuses(FETCHED_STATUSES));
          dispatch(deleteStandard(params));
        },
        getPreview: (id: Uuid) => dispatch(getPreview(id)),
        loadQuestions: (params: FormGetQuestionsParamsType) =>
          dispatch(loadQuestions(params)),
        putQuestion: (
          formId: Uuid,
          questionId: Uuid,
          responseId: Uuid,
          value: string,
          category: string,
        ) => {
          dispatch(
            putQuestion(formId, questionId, responseId, value, category),
          );
        },
        renameStandard: (params: RenameStandardParamsType) => {
          dispatch(setFetchedStatuses(FETCHED_STATUSES));
          dispatch(renameStandard(params));
        },
        showModal: (modalId: string) => dispatch(showModal(modalId)),
        uploadStandard: (formData: FormData) =>
          dispatch(uploadStandard(formData)),
      },
    })}
  >
    <UploadCustomStandard />
  </Page>
);

export default UploadCustomStandardPage;
