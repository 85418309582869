import { FormDefinitionsStateType } from './formDefinitions.types';
import { FormDefinitionsActionType } from './actions';
import {
  defaultSortBy,
  sortStandards,
} from 'utils/filters/formDefinitions.sorters';
import { symmetricDifference } from 'ramda';

import { filterStandardsByStatus } from '../../utils/filters/formDefinitions.filters';

export const initialState: FormDefinitionsStateType = {
  standards: {
    data: [],
    selected: [],
    filter: { sortBy: defaultSortBy, byStatus: [] },
  },
};

const formDefinitionsReducer = (
  state: FormDefinitionsStateType = initialState,
  action: FormDefinitionsActionType,
): FormDefinitionsStateType => {
  switch (action.type) {
    case '@formDefinitions/RESET_STANDARDS':
      return {
        ...state,
        standards: {
          data: [],
          selected: [],
          filter: { sortBy: defaultSortBy, byStatus: [] },
        },
      };
    case '@formDefinitions/SET_SORT':
      return sortStandards(action.payload.sortBy)(state);
    case '@formDefinitions/TOGGLE_FORM_DEFINITION_SELECTION':
      const {
        payload: { formDefinitionIds },
      } = action;
      const standardFormDefinitionArray = state.standards;
      const selectedFormDefinitions = state.standards.selected;
      return {
        ...state,
        standards: {
          ...standardFormDefinitionArray,
          selected: symmetricDifference(
            selectedFormDefinitions,
            formDefinitionIds,
          ),
        },
      };
    case '@formDefinitions/CLEAR_FORM_DEFINITION_SELECTION':
      const standardFormDefinition = state.standards;
      return {
        ...state,
        standards: {
          ...standardFormDefinition,
          selected: [],
        },
      };
    case '@formDefinitions/SET_FILTER_STATUS': {
      return filterStandardsByStatus(action.payload.query)(state);
    }
    default:
      return state;
  }
};

export default formDefinitionsReducer;
