import React, { PureComponent } from 'react';
import withHeaderVisibility from './HeaderVisibility';
import { BackgroundPlaceholder, Wrapper } from './HideOnScroll.styles';
import { ToggleHeaderFunctionType } from 'store/ui/actions/toggleHeader';
import { HEADER_HEIGHT } from 'common/common.styles';

interface HideOnScrollPropsType {
  isHeaderVisible: boolean;
  toggleHeader: ToggleHeaderFunctionType;
  children?: React.ReactNode;
}

class HideOnScroll extends PureComponent<HideOnScrollPropsType> {
  previousScroll: number;

  componentDidMount(): void {
    this.previousScroll = window.scrollY;
    window.addEventListener('scroll', this.checkScroll);
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.checkScroll);
  }

  hide = () => {
    const { isHeaderVisible, toggleHeader } = this.props;

    if (isHeaderVisible) {
      toggleHeader(false);
    }
  };

  show = () => {
    const { isHeaderVisible, toggleHeader } = this.props;

    if (!isHeaderVisible) {
      toggleHeader(true);
    }
  };

  checkScroll = () => {
    if (
      this.previousScroll > window.scrollY &&
      window.scrollY <= HEADER_HEIGHT
    ) {
      this.show();
    } else if (this.previousScroll < window.scrollY && window.scrollY > 0) {
      this.hide();
    }
    this.previousScroll = window.scrollY;
  };

  public render() {
    const { children, isHeaderVisible } = this.props;

    return (
      <div>
        <BackgroundPlaceholder />

        <Wrapper scrolled={!isHeaderVisible} onMouseEnter={this.show}>
          {children}
        </Wrapper>
      </div>
    );
  }
}

export default withHeaderVisibility(HideOnScroll);
