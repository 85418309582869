import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.atlas.grey['50']};
  border-radius: 5px;
  padding: 15px;
  margin-top: 8px;
`;

export const Link = styled(RouterLink)`
  text-decoration-line: underline;
  color: ${({ theme }) => theme.atlas.teal.primary};
  ${({ theme }) => theme.basic.fontWeights.bold}
`;
