import {
  Email,
  IconWrapper,
  ReInvite,
  Revoke,
  Role,
  Roles,
  UserIcon,
  UserInfo,
  Wrapper,
} from './Recipient.styles';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType, UserType } from 'common/common.types';

export interface RecipientPropsType extends TranslatePropsType {
  canAnswer: boolean;
  canReview: boolean;
  onReInvite?: () => void;
  onRevoke?: () => void;
  user: UserType;
}

const Recipient = ({
  canAnswer,
  canReview,
  onReInvite,
  onRevoke,
  user,
  t,
}: RecipientPropsType) => (
  <Wrapper isActive={user.isActive}>
    <IconWrapper>
      <UserIcon />
    </IconWrapper>
    <UserInfo>
      <div>{user.isActive ? user.displayName : user.email}</div>
      <Email>{user.isActive ? user.email : (t('inactive') as string)}</Email>
    </UserInfo>
    {onReInvite && (
      <ReInvite onClick={onReInvite}>{t('reInvite') as string}</ReInvite>
    )}
    <Roles>
      <Role>
        {canAnswer && (t('canAnswer') as string)}
        {canAnswer && canReview && ' & '}
        {canReview && (t('canReview') as string)}
      </Role>
      {onRevoke && <Revoke onClick={onRevoke}>{t('revoke') as string}</Revoke>}
    </Roles>
  </Wrapper>
);

export default withTranslation('FormProxy')(Recipient);
