import { datadogRum } from '@datadog/browser-rum';
import config from '../config';

const setupDatadogRum = () => {
  datadogRum.init({
    applicationId: 'c1f20848-1595-4ebc-9b02-da5d7e5f490c',
    clientToken: 'pub9e3c17a2cec3eb0efeb58d25b8989153',
    site: 'ddog-gov.com',
    service: 'atlas',
    env: config.env,
    sessionSampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  });
};

export default setupDatadogRum;
