import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 415px;
  margin-right: 50px;
`;

export const Note = styled.div`
  padding-top: 8px;
  ${({ theme }) => theme.fonts.regular.mini}
`;

export const ErrorNote = styled.div`
  padding-top: 8px;
  color: red;
  ${({ theme }) => theme.fonts.regular.mini}
`;

export const TextNote = styled.span`
  ${({ theme }) => theme.fonts.regular.mini}
`;
