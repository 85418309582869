import Checkbox from '../common/Checkbox';
import Controls from './Controls';
import React, { Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Request from './Request';
import RequestSorter from './RequestSorter';
import VirtualizedList from 'utils/virtualizer/src/VirtualizedList';
import colors from 'common/common.colors';
import { Badge } from '../common/Badge';
import { CheckboxContainer } from '../common/Checkbox/Checkbox.styles';
import { REQUEST_HEIGHT } from './Request/Request.styles';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { getCategoryTitleTranslationKey } from 'utils/getCategoryTitleTranslationKey';
import { values } from 'ramda';
import { withTranslation } from 'react-i18next';
import {
  ActionsWrapper,
  Categories,
  CollapsibleContent,
  CountWrapper,
  ExpandIconWrapper,
  Icon,
  Title,
  TriggerWrapper,
} from './List.styles';
import {
  REQUESTS_CATEGORIES_ORDER,
  RequestsInOutType,
} from 'store/requests/requests.types';
import {
  LanguageType,
  RequestControlsStatusType,
  RequestSimpleType,
  RequestDirectionType,
  ToggleEnum,
  TranslatePropsType,
  UserType,
  Uuid,
} from 'common/common.types';
import {
  RequestArchiveFunctionType,
  RequestClearNotificationsFunctionType,
  RequestDeleteFunctionType,
  RequestMarkReadFunctionType,
  RequestsSetSortFunctionType,
  RequestsToggleCategoriesFunctionType,
  RequestsToggleSelectionsFunctionType,
} from 'store/requests/actions';
import { FormProxyShowProxyModalFunctionType } from 'store/formProxy/actions';
import { FormProxy } from '../FormProxy';
import { StatusEnum } from 'generated/models/RequestSimple';
import useFlag from '../../utils/hooks/useFlag';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface RequestsPropsType extends TranslatePropsType {
  actions: {
    archiveRequest: RequestArchiveFunctionType;
    clearNotifications: RequestClearNotificationsFunctionType;
    copyToClipboard: (text: string) => void;
    deleteRequest: RequestDeleteFunctionType;
    markRead: RequestMarkReadFunctionType;
    setSort: RequestsSetSortFunctionType;
    showProxyModal: FormProxyShowProxyModalFunctionType;
    toggleCategories: RequestsToggleCategoriesFunctionType;
    toggleSelection: RequestsToggleSelectionsFunctionType;
  };
  data: {
    controlsStatus: RequestControlsStatusType;
    direction: RequestDirectionType;
    requests: RequestsInOutType;
    sortBy: any;
    user: UserType;
    language: LanguageType;
  };
}

interface TriggerType {
  actions: {
    toggleAllSelection: () => void;
  };
  data: {
    category: string;
    count: number;
    isOpen: boolean;
    selectedCount: number;
  };
}

const List = ({
  actions: {
    archiveRequest,
    clearNotifications,
    copyToClipboard,
    deleteRequest,
    markRead,
    setSort,
    showProxyModal,
    toggleCategories,
    toggleSelection,
  },
  data: {
    controlsStatus,
    direction,
    requests: { categoriesOpened, categories },
    sortBy,
    user,
    language,
  },
  t,
}: RequestsPropsType) => {
  const isSent = direction === 'sent';

  const Trigger = ({
    actions: { toggleAllSelection },
    data: { category, count, isOpen, selectedCount },
  }: TriggerType) => {
    const handleTriggerClick = () => {
      if (count) {
        toggleCategories({ ...categoriesOpened, [category]: !isOpen });
      }
    };

    const handleCheckboxChange = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      toggleAllSelection();
    };

    return (
      <TriggerWrapper
        count={count}
        onClick={handleTriggerClick}
        data-cy={`requests-${category}-header`}
        data-test="category-header"
      >
        <ActionsWrapper>
          <ExpandIconWrapper isOpen={isOpen} count={count}>
            <Icon icon={faChevronRight as IconProp} open={isOpen} />
          </ExpandIconWrapper>

          <CheckboxContainer onClick={handleCheckboxChange}>
            <Checkbox
              onChange={handleCheckboxChange}
              isIndeterminate={selectedCount > 0 && selectedCount < count}
              isChecked={count > 0 && count === selectedCount}
              secondaryColor="teal"
            />
          </CheckboxContainer>

          <Title data-test="category-title">
            {t(getCategoryTitleTranslationKey(category, direction)) as string}
          </Title>
        </ActionsWrapper>

        <CountWrapper data-test="selected-amount">
          {ReactHtmlParser(t('Requests.selectedHTML', { selectedCount }))}

          <Badge color={colors.elements.requests[category].color}>
            {categories[category].count}
          </Badge>
        </CountWrapper>
      </TriggerWrapper>
    );
  };

  const allExpanded = values(categoriesOpened).filter(c => !c).length === 0;

  const toggleAllCategories = () =>
    toggleCategories(
      REQUESTS_CATEGORIES_ORDER.reduce((result, current) => {
        if (categories[current].count) {
          result[current] = !allExpanded;
        }
        return result;
      }, {}),
    );
  const hideSscData = useFlag(user, [ToggleEnum.HIDE_SSC_DATA]);
  return (
    <>
      <Controls
        actions={{
          ...(isSent ? { archiveRequest, deleteRequest } : {}),
          clearNotifications,
          markRead,
          toggleAllCategories,
        }}
        data={{
          allExpanded,
          controlsStatus,
        }}
      />

      <FormProxy />

      <Categories>
        {REQUESTS_CATEGORIES_ORDER.map(category => {
          const { count, requests: allRequests, selected } = categories[
            category
          ];
          const requests = allRequests.filter(
            request => request.meta.isVisible,
          );
          const isIndeterminate =
            selected.length > 0 && selected.length < count;
          const toggleRequestSelection = (id: Uuid) =>
            toggleSelection(category, [id]);
          const toggleAllSelection = () =>
            toggleSelection(
              category,
              isIndeterminate ? selected : requests.map(request => request.id),
            );
          const selection = new Set(selected);

          return (
            <Fragment key={`fragment-${category}`}>
              <Trigger
                actions={{
                  toggleAllSelection,
                }}
                data={{
                  category,
                  count: count,
                  isOpen: categoriesOpened[category],
                  selectedCount: selected.length,
                }}
              />

              {count > 0 && (
                <CollapsibleContent
                  data-cy={`requests-${category}`}
                  data-test="requests-category"
                  isOpen={categoriesOpened[category]}
                >
                  <RequestSorter
                    setSort={setSort}
                    sortBy={sortBy}
                    showScore={isSent}
                  />

                  {categoriesOpened[category] && (
                    <VirtualizedList
                      overScan={5}
                      items={requests}
                      renderItem={(request: RequestSimpleType) => (
                        <Request
                          key={`request-list-${request.id}`}
                          data={{
                            hideSscData,
                            isSelectable: true,
                            isSelected: selection.has(request.id),
                            request,
                            reverseTransceiver: !isSent,
                            showGrade: isSent,
                            showScore: isSent,
                            language,
                          }}
                          actions={{
                            ...(isSent ? { archiveRequest } : {}),
                            ...(isSent && request.status === StatusEnum.CREATED
                              ? { deleteRequest }
                              : {}),
                            clearNotifications,
                            copyToClipboard,
                            markRead,
                            toggleRequestSelection,
                            showProxyModal,
                          }}
                        />
                      )}
                      itemHeight={REQUEST_HEIGHT}
                    />
                  )}
                </CollapsibleContent>
              )}
            </Fragment>
          );
        })}
      </Categories>
    </>
  );
};

export default withTranslation()(List);
