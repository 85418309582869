import React from 'react';
import RecurrenceOption from './RecurrenceOption';
import Select from '../../common/Select';
import { LabelSpaced } from '../Stepper.styles';
import { SCHEDULE_RECURRENCES } from 'common/common.constants';
import { SetFormValueFunctionType } from 'store/requests/actions';
import { Wrapper } from './Reminder.styles';
import { withTranslation } from 'react-i18next';
import {
  LanguageType,
  RecurrenceItemType,
  TranslatePropsType,
} from 'common/common.types';
import { StateType } from '../../../store/store.types';
import { getLanguagePreference } from '../../../store/settings/settings.selectors';
import { connect } from 'react-redux';

interface ScheduleRecurringPropsType extends TranslatePropsType {
  recurrence: RecurrenceItemType;
  sendDate: Date;
  setValue: SetFormValueFunctionType;
  language: LanguageType;
}

const Recurrence = ({
  recurrence,
  sendDate,
  setValue,
  language,
  t,
}: ScheduleRecurringPropsType) => {
  return (
    <Wrapper>
      <LabelSpaced>{t('setRecurrence') as string}</LabelSpaced>

      <Select
        formatOptionLabel={RecurrenceOption(sendDate, language, t)}
        onChange={(value: RecurrenceItemType) => setValue('recurrence', value)}
        options={SCHEDULE_RECURRENCES}
        value={recurrence}
      />
    </Wrapper>
  );
};
const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});
export default connect(
  mapStateToProps,
  null,
)(withTranslation('SendRequest')(Recurrence));
