import RadioOption from './RadioOption';
import React, { ChangeEvent } from 'react';
import isGenericLabel from 'utils/isGenericLabel';
import { Label } from '../../../Form.styles';
import { ResponseInputPropsType } from '../../Response';
import {
  LongRadioLabel,
  MaturityLabel,
  OptionWrapper,
  RadioLabel,
} from './Radio.styles';
import {
  getLabelType,
  getOptions,
  LabelTypeEnum,
} from '../../utils/radioComponentUtils';
import { normalizeOption } from '../../utils/normalizeOption';

type LabelComponentsDict = { [key in LabelTypeEnum]: any };

const LABEL_COMPONENTS_ASSIGNMENT: LabelComponentsDict = {
  YES_NO_NA: RadioLabel,
  NUMBER: MaturityLabel,
  DEFAULT: RadioLabel,
  LONG: LongRadioLabel,
};

const Radio = ({
  actions,
  data: {
    hasBeenTouched,
    id,
    isEditable,
    isRequired,
    label,
    options: rawOptions,
    value,
  },
}: ResponseInputPropsType) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (actions.handleChange) {
      const {
        target: { checked, value: newValue },
      } = event;

      actions.handleChange(checked ? newValue : '');
    }
  };

  const normalizedValue = normalizeOption(value);
  const labelType = getLabelType(rawOptions || []);
  const options = getOptions(rawOptions || []);
  const isYesNoNA = labelType === LabelTypeEnum.YES_NO_NA;
  const LabelComponent = LABEL_COMPONENTS_ASSIGNMENT[labelType];

  return (
    <div>
      {label && !isGenericLabel(label) && <Label>{label}</Label>}

      {options.map(({ option, normalizedOption }) => {
        if (!option) {
          return null;
        }

        const isSelected = normalizedOption === normalizedValue;

        return (
          <LabelComponent
            data-cy={`option-${option}`}
            key={option}
            disabled={!isEditable || false}
            hasBeenTouched={hasBeenTouched}
            hasValue={!!value}
            isSelected={isSelected}
            isYesNoNA={isYesNoNA}
            normalizedValue={normalizedOption}
          >
            <RadioOption
              checked={isSelected}
              handleChange={handleChange}
              id={id}
              isEditable={isEditable}
              isRequired={isRequired}
              option={option}
            />

            <OptionWrapper data-checked={`${isSelected}`}>
              {option}
            </OptionWrapper>
          </LabelComponent>
        );
      })}
    </div>
  );
};

export default Radio;
