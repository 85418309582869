import styled, { css } from 'styled-components/macro';
import {
  faSortAmountDown,
  faSortAmountUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ColumnType {
  clickable?: boolean;
  width?: number;
  selected?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  background-color: white;
`;

export const Column = styled.div<ColumnType>`
  align-items: center;
  color: ${({ theme }) => theme.basic.colors.black};
  display: flex;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  line-height: 1;
  ${({ width }) =>
    !width
      ? css`
          flex-grow: 1;
        `
      : ''};
  padding: 18px 16px;
  ${({ width }) => (width ? `width: ${width}px` : null)};
  opacity: ${({ selected }) => (selected ? 1 : 0.7)};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

  &:hover {
    opacity: 1;
  }
`;

interface SortIconProps {
  direction: 'ASC' | 'DESC';
}

export const SortIcon: any = styled(FontAwesomeIcon).attrs(
  (props: SortIconProps) => ({
    icon: props.direction === 'ASC' ? faSortAmountUp : faSortAmountDown,
  }),
)<SortIconProps>`
  margin-left: 8px;
`;

export const CheckboxHeader = styled.div`
  margin-top: 6px;
`;
