import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TranslatePropsType, Uuid } from 'common/common.types';
import formatRoute, { FORM, FRAMEWORKS_DASHBOARD } from '../../../routes';
import { Link, Wrapper } from './UploadGoBack.styles';
import { Trans, withTranslation } from 'react-i18next';
/* tslint:disable */
import { LocationDescriptorObject } from 'history';
import { FormRouteStateType } from 'pages/FormPage';
import { StaticContext } from 'react-router';

// extend type for more states
export type UploadGoBackStateType = {
  origin: 'form';
  formId: Uuid;
  standardName: string;
  openUpload: boolean;
};

export const getLinkFormTo = (
  formId: Uuid,
  standardName: string,
  openUpload: boolean,
): LocationDescriptorObject<UploadGoBackStateType> => {
  const state: UploadGoBackStateType = {
    origin: 'form',
    formId,
    standardName,
    openUpload,
  };

  return { pathname: formatRoute(FRAMEWORKS_DASHBOARD, {}), state };
};

const getLinkAutocompleteTo = (
  id: Uuid,
): LocationDescriptorObject<FormRouteStateType> => ({
  pathname: formatRoute(FORM, { id }),
  state: { openAutocomplete: true },
});

type UploadGoBackPropsType = TranslatePropsType &
  RouteComponentProps<{}, StaticContext, UploadGoBackStateType | undefined>;

const UploadGoBack = ({ location: { state }, t }: UploadGoBackPropsType) => {
  if (!state || state.origin !== 'form') {
    return null;
  }
  const { formId, standardName } = state as UploadGoBackStateType;

  return (
    <Trans i18nKey="continueAutocomplete" t={t} parent={Wrapper}>
      Continue autocompleting
      <Link to={getLinkAutocompleteTo(formId)}>
        <>{{ standardName }}</>
      </Link>
    </Trans>
  );
};

export default withTranslation('EvidenceLocker')(withRouter(UploadGoBack));
