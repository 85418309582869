import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import { Content } from 'common/common.styles';
import { RequestSimpleType, TranslatePropsType } from 'common/common.types';
import { PageHeader } from '../common/PageHeader';
import NewVendorLandingHeader from './NewVendorLandingHeader';
import * as routes from '../../routes';
import InformationBoard from './InformationBoard';
import {
  InformationBoardActionElement,
  NewVendorLandingWrapper,
  Row,
  StyledLink,
} from './NewVendorLanding.styles';
import { PageContext } from 'pages/Page';
import { NewVendorLandingPageContext } from 'pages/NewVendorLandingPage';

const NewVendorLanding = ({ t }: TranslatePropsType) => {
  const {
    data: { requests, user },
  } = useContext(PageContext) as NewVendorLandingPageContext;

  const getRequestURLToRedirect = (): string => {
    // First sort the requests by received time.
    const sortedByReceivedTime = requests.unsorted.sort(
      (item1: RequestSimpleType, item2: RequestSimpleType) => {
        return item1.requestedAt.getTime() - item2.requestedAt.getTime();
      },
    );

    // Find the first chronologically sorted request that has the current user's email id as receiver.
    const foundReceiverEmailID = sortedByReceivedTime.find(
      (item: RequestSimpleType) => item.meta.receiver === user.email,
    );

    if (foundReceiverEmailID) {
      return `/form/${foundReceiverEmailID.form.id}`;
    } else if (sortedByReceivedTime.length > 1) {
      // No request with receiver matching with the current user's email id, so fall back to the whatever the first
      // received request by this user's company.
      return `/form/${sortedByReceivedTime[0].form.id}`;
    }

    // If no requests were to be found, redirect the user to received page.
    return routes.REQUESTS_RECEIVED;
  };

  const getReactSVGComponent = (src: string) => (
    <ReactSVG
      style={{ display: 'inline-block' }}
      svgStyle={{
        width: 500,
        height: 308,
        marginTop: 34,
        marginBottom: 34,
        marginLeft: 30,
        marginRight: 30,
      }}
      src={src}
      key={src}
    />
  );

  const pageContents = [
    // Row 1
    [
      <InformationBoard
        title="atlasIntroductionTitle"
        content="atlasIntroductionDescription"
        key="key_atlasIntroductionTitle"
      />,
      <InformationBoard
        title="secureYourDataTitle"
        content="secureYourDataDescription"
        key="key_secureYourDataTitle"
      />,
      <InformationBoard
        title="aboutTitle"
        content="aboutDescription"
        key="key_aboutTitle"
      />,
    ],
    // Row 2
    [
      <InformationBoard
        title="simplifyYourAssessmentTitle"
        content="simplifyYourAssessmentDescription"
        width={533}
        actionElement={
          <StyledLink to={getRequestURLToRedirect()}>
            <InformationBoardActionElement highlight={true}>
              {t('simplifyYourAssessmentActionElementText') as string}
            </InformationBoardActionElement>
          </StyledLink>
        }
        key="key_simplifyYourAssessmentTitle"
      />,
      getReactSVGComponent('sample_questionnaire_screenshot.svg'),
    ],
    // Row 3
    [
      user.isNormal
        ? [
            getReactSVGComponent('sample_answers_evidences_screenshot.svg'),
            <InformationBoard
              title="automateQuestionnaireTitle"
              content="automateQuestionnaireDescription"
              width={533}
              actionElement={
                <StyledLink to={routes.FRAMEWORKS_DASHBOARD}>
                  <InformationBoardActionElement>
                    {t('automateQuestionnaireActionElementText') as string}
                  </InformationBoardActionElement>
                </StyledLink>
              }
              key="key_automateQuestionnaireTitle"
            />,
          ]
        : [],
    ],
    // Row 4
    [
      <InformationBoard
        title="manageQuestionnaireTitle"
        content="manageQuestionnaireDescription"
        width={533}
        actionElement={
          <StyledLink to={routes.REQUESTS_RECEIVED}>
            <InformationBoardActionElement>
              {t('manageQuestionnaireActionElementText') as string}
            </InformationBoardActionElement>
          </StyledLink>
        }
        key="key_manageQuestionnaireTitle"
      />,
      getReactSVGComponent('sample_queue_screenshot.svg'),
    ],
  ];

  return (
    <>
      <PageHeader heading={<NewVendorLandingHeader />} />
      <Content>
        <NewVendorLandingWrapper>
          {pageContents.map((eachRow: JSX.Element[], index: number) => (
            <Row key={`newVendorLandingPage_${index}`}>
              {eachRow.map(eachElement => eachElement)}
            </Row>
          ))}
        </NewVendorLandingWrapper>
      </Content>
    </>
  );
};

export default withTranslation('NewVendorLanding')(NewVendorLanding);
