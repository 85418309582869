import { Name, Option, State, StateIcon } from '../Requests/SortBy.styles';
import {
  SORT_OPTIONS,
  SortType,
  reverseDirection,
} from 'utils/filters/formDefinitions.sorters';
import {
  faSortAmountDown,
  faSortAmountUp,
} from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from '../common/Dropdown';
import React, { MouseEvent } from 'react';
import { FormDefinitionsSetSortFunctionType } from 'store/formDefinitions/actions';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface SortByPropsType extends TranslatePropsType {
  data: {
    sortBy: SortType;
  };
  actions: {
    setSort: FormDefinitionsSetSortFunctionType;
  };
}

const SortBy = ({
  data: {
    sortBy: { by, direction },
  },
  actions: { setSort },
  t,
}: SortByPropsType) => {
  const handleClick = (event: MouseEvent<HTMLElement>, index: number) => {
    const option = SORT_OPTIONS[index];

    setSort({
      by: option,
      direction: by === option ? reverseDirection(direction) : 'ASC',
    });
  };

  let icon = faSortAmountDown as IconProp;
  if (direction === 'ASC') {
    icon = faSortAmountUp as IconProp;
  }

  return (
    <Dropdown color="transparent" size="medium" text={t('sortBy') as string}>
      {() =>
        SORT_OPTIONS.map((option, index) => (
          <Option
            key={`sortBy-menu-${option}`}
            isSelected={by === option}
            onClick={event => handleClick(event, index)}
          >
            <State>{by === option && <StateIcon icon={icon} />}</State>
            <Name>{t(`sortOptions.${option}`) as string}</Name>
          </Option>
        ))
      }
    </Dropdown>
  );
};

export default withTranslation('FormDefinitions')(SortBy);
