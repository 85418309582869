import { pathOr } from 'ramda';
import { QuestionBlockType, Uuid } from '../../../common/common.types';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { put } from 'redux-saga/effects';
import { updateFilterStats } from './updateFilterStats';

export type FormsSetBlockSeenFunctionType = (
  questionId: Uuid,
  block: QuestionBlockType,
) => void;

export interface FormsSetBlockSeenCallParamsType {
  formId: Uuid;
  question_id: Uuid;
  block: QuestionBlockType | 'all';
}

export type FormsSetBlockSeenActionType = OptimisticActionType<
  '@forms/API/SET_BLOCK_SEEN',
  ApiCallActionPayloadType<FormsSetBlockSeenCallParamsType>
>;

export const setBlockSeen = (
  questionId: Uuid,
  block: QuestionBlockType,
): FormsSetBlockSeenActionType => ({
  type: '@forms/API/SET_BLOCK_SEEN',
  payload: {
    callParams: state => ({
      block,
      formId: pathOr(undefined, ['forms', 'currentForm', 'id'], state),
      question_id: questionId,
    }),
    endpointPath: ['forms', 'setBlockSeen'],
    expectedStateChange: {
      [`forms.currentForm.questions.${questionId}.blocks.meta.${block}.new_count`]: 0,
    },
    selector: null,
    postActions: [
      (_, state) => {
        const formId = pathOr<Uuid | undefined>(
          undefined,
          ['forms', 'currentForm', 'id'],
          state,
        );

        return formId ? put(updateFilterStats(formId)) : null;
      },
    ],
  },
});
