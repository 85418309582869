import styled from 'styled-components';
import Label from '../../common/typography/Label';
import { CancelButton } from '../../common/ConfirmModal/ConfirmModal.styles';
import { SHADOW } from '../../common/Bar/Bar.styles';

export const TYPICAL_HEIGHT = 41;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.basic.colors.light};
  border-top: 1px ${({ theme }) => theme.basic.colors.darkerBorder} solid;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  ${SHADOW}
  ${({ theme }) => theme.fonts.regular.small}
`;

export const Issues = styled.div`
  display: flex;
  padding: 10px 15px 10px 25px;

  > span + span {
    margin-left: 10px;
  }
`;

export const Status = styled.div`
  border-left: 1px ${({ theme }) => theme.basic.colors.darkerBorder} solid;
  padding: 10px 25px 10px 15px;

  > span + span {
    margin-left: 10px;
  }
`;

export const IssuesEdited = styled.span`
  color: ${({ theme }) => theme.atlas.blue['800']};
  ${({ theme }) => theme.fonts.bold.small}
`;

export const IssuesLeft = styled.span`
  color: ${({ theme }) => theme.atlas.red.primary};
  ${({ theme }) => theme.fonts.bold.small}
`;

export const SwitchWrapper = styled.span`
  display: flex;

  > ${Label} {
    margin-left: 10px;
  }
`;

export const SpinnerWrapper = styled.span`
  margin-right: 5px;
  ${({ theme }) => theme.fonts.bold.normal}
`;

export const AllIssuesFixed = styled.div`
  padding: 10px 25px;
  text-align: center;
  width: 100%;
`;

export const PublishButton = styled(CancelButton)`
  display: inline;
  color: ${({ theme }) => theme.atlas.teal.primary};
  ${({ theme }) => theme.fonts.semibold.small}
`;
