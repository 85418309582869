import React from 'react';
import { Chevron, Wrapper } from './FormPreviewCategoryHeader.styles';
import { Flex } from 'common/common.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TranslatePropsType } from 'common/common.types';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface FormPreviewCategoryHeaderPropsType extends TranslatePropsType {
  name: string;
  isOpen?: boolean;
  isSingle?: boolean;
}

const FormPreviewCategoryHeader = ({
  name,
  isOpen,
  isSingle,
  t,
}: FormPreviewCategoryHeaderPropsType) =>
  isSingle && !name ? null : (
    <Wrapper>
      <Flex>
        <Chevron isOpen={isOpen}>
          <FontAwesomeIcon icon={faChevronDown as IconProp} />
        </Chevron>

        <span>{name ? name : (t('Form.defaultCategory') as string)}</span>
      </Flex>
    </Wrapper>
  );

export default withTranslation()(FormPreviewCategoryHeader);
