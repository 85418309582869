import { ApiCallActionType } from '../../store.types';
import {
  DelegationNotificationsDirectionType,
  Uuid,
} from '../../../common/common.types';
import { getFormWithoutReset } from './getFormWithoutReset';
import { put } from 'redux-saga/effects';

export interface FormsClearDelegationNotificationsCallParamsType {
  formId: Uuid;
  params: { direction: DelegationNotificationsDirectionType };
}

export type FormsClearDelegationNotificationsFunctionType = (
  direction: DelegationNotificationsDirectionType,
  formId: Uuid,
) => void;

export type FormsClearDelegationNotificationsActionType = ApiCallActionType<
  '@forms/API/CLEAR_DELEGATION_NOTIFICATIONS',
  FormsClearDelegationNotificationsCallParamsType
>;

export const clearDelegationNotifications = (
  direction: DelegationNotificationsDirectionType,
  formId: Uuid,
): FormsClearDelegationNotificationsActionType => ({
  type: '@forms/API/CLEAR_DELEGATION_NOTIFICATIONS',
  payload: {
    callParams: {
      formId,
      params: {
        direction,
      },
    },
    endpointPath: ['forms', 'delegations', 'notifications', 'delete'],
    selector: null,
    postActions: [() => put(getFormWithoutReset(formId))],
  },
});
