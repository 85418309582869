import Actions from './Actions';
import React from 'react';
import SearchMappings from './SearchMappings';
import TabView from '../common/Tabs';
import withUrl from 'utils/url/withUrl';
import { BarWrapperVertical } from '../common/Bar/Bar.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Section } from './Utils.styles';
import { PageContextConsumer } from 'pages/Page';
import { TranslatePropsType } from 'common/common.types';
import { UtilsPageContextType } from 'pages/UtilsPage';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Translation, withTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const ClearQuestions = () => (
  <Section>
    <h2>
      <Translation ns={'components'}>
        {t => <>{t('Utils.clearQuestions.heading')}</>}
      </Translation>
    </h2>

    <p style={{ marginTop: 20 }}>
      <Translation ns={'components'}>
        {t => <> {t('Utils.clearQuestions.description')}</>}
      </Translation>
    </p>

    <Link href={withUrl('form-fillings/clear-answers', '')} target="_blank">
      <FontAwesomeIcon icon={faExclamationCircle as IconProp} color={'#fff'} />

      <span style={{ paddingRight: 26 }}>
        <Translation ns={'components'}>
          {t => <>{t('Utils.clearQuestions.action')}</>}
        </Translation>
      </span>
    </Link>
  </Section>
);

const ExportActions = () => (
  <Section>
    <h2 style={{ marginBottom: 20 }}>
      <Translation ns={'components'}>
        {t => <>{t('Utils.exportActions')}</>}
      </Translation>
    </h2>
    <Actions />
  </Section>
);

const QuestionMappings = () => (
  <Section>
    <h2 style={{ marginBottom: 20 }}>
      <Translation ns={'components'}>
        {t => <>{t('Utils.questionsMappings')}</>}
      </Translation>
    </h2>

    <SearchMappings />
  </Section>
);

const Utils = ({ t }: TranslatePropsType) => (
  <PageContextConsumer>
    {({
      data: {
        user: { isSystemAdmin },
      },
    }: UtilsPageContextType) => {
      const tabs = [];

      if (isSystemAdmin) {
        tabs.push(
          {
            label: t('Utils.exportActionsLabel'),
            component: <ExportActions />,
          },
          {
            label: t('Utils.questionsMappingLabel'),
            component: <QuestionMappings />,
          },
        );
      }

      tabs.push({
        label: t('Utils.clearQuestionsLabel'),
        component: <ClearQuestions />,
      });

      return (
        <TabView
          tabs={tabs}
          heading={
            <BarWrapperVertical>
              <h2>{t('Utils.captionHeading') as string}</h2>
              <p>{t('Utils.caption') as string}</p>
            </BarWrapperVertical>
          }
        />
      );
    }}
  </PageContextConsumer>
);

export default withTranslation('components')(Utils);
