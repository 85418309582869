import styled, { css } from 'styled-components';
import { alwaysShowScrollbar } from 'common/common.styles';

export const Row = styled.div`
  display: flex;

  div {
    flex-grow: 1;
    padding: 5px 10px;
  }

  div:first-child {
    display: flex;
    flex-direction: row;
    width: 90px;
    flex: 0 0 90px;
  }

  div:last-child {
    text-align: center;
    width: 80px;
    flex: 0 0 80px;
  }
`;

export const IssueListHeadingRow = styled(Row)`
  background: ${({ theme }) => theme.atlas.grey['50']};
  text-transform: uppercase;
  ${({ theme }) => theme.fonts.bold.micro}
`;

export const IssueSeverityName = styled.span`
  text-transform: uppercase;
  margin-left: 5px;
`;

export const IssueFindings = styled.div`
  ${({ theme }) => theme.basic.fontWeights.bold}
`;

export const IssueListRow = styled(Row)<{ hasFindings: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.atlas.grey['100']};
  ${({ theme }) => theme.fonts.light.xsmall}

  ${({ hasFindings }) =>
    !hasFindings &&
    css`
      div:nth-child(2),
      ${IssueFindings} {
        opacity: 0.65;
      }
    `}
`;

export const Scrollable = styled.div`
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  height: calc(100% - 80px);
  max-height: 500px;
`;

export const FactorHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.atlas.grey['100']};
  padding: 5px 10px;
`;

export const FactorScore = styled.div`
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 15px;
  ${({ theme }) => theme.fonts.regular.small}
`;

export const FactorName = styled.div`
  text-transform: uppercase;
  ${({ theme }) => theme.fonts.light.small}
`;

export const FactorDescription = styled.div`
  ${({ theme }) => theme.fonts.light.mini}
`;

export const Factors = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  flex: 1;
  ${alwaysShowScrollbar}

  > * {
    margin-bottom: 24px;
  }
`;
