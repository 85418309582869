import React from 'react';
import { BarWrapper } from '../common/Bar/Bar.styles';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType, ToggleEnum, UserType } from 'common/common.types';
import { Controls, TitleWrapper } from '../Requests/Header.styles';
import SelectTeam from './SelectTeam';
import { Team } from 'generated/new/models';
import useFlag from 'utils/hooks/useFlag';

interface HeaderType extends TranslatePropsType {
  companyName: string;
  selectTeam: (team: Team) => void;
  selectedTeam?: Team;
  teams: Team[];
  user: UserType;
}

const Header = ({
  companyName,
  selectTeam,
  selectedTeam,
  teams,
  t,
  user,
}: HeaderType) => {
  const hasInternalAutocomplete = useFlag(user, [
    ToggleEnum.INTERNAL_AUTOCOMPLETE,
  ]);

  const answersEvidenceLabel = t('answersEvidence') as string;
  const answersEvidenceCaptionLabel = t('answersEvidenceCaption', {
    team: selectedTeam ? selectedTeam.name : companyName,
  });

  return (
    <BarWrapper>
      <TitleWrapper>
        <h2 data-test="page-header">{answersEvidenceLabel}</h2>
        <p>{answersEvidenceCaptionLabel}</p>
      </TitleWrapper>

      {teams.length > 0 && hasInternalAutocomplete && (
        <div>
          <Controls>
            <SelectTeam
              companyName={companyName}
              selectTeam={selectTeam}
              selectedTeam={selectedTeam}
              teams={teams}
            />
          </Controls>
        </div>
      )}
    </BarWrapper>
  );
};

export default withTranslation('EvidenceLocker')(Header);
