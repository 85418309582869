import * as en_US from './en-US';
import * as ja_JP from './ja-JP';
import config, { Env } from '../config';
import i18n, { InitOptions, ReactOptions } from 'i18next';

const shouldThrowOnMissingTranslation =
  config.env === Env.DEV || config.env === Env.DEMO;

const reactOptions: ReactOptions = {
  wait: false,
  bindI18n: 'languageChanged loaded',
  bindI18nStore: 'added removed',
  nsMode: 'default',
};

const initParams: InitOptions = {
  interpolation: {
    skipOnVariables: false,
  },
  debug: false,
  lng: 'en-US',
  fallbackLng: 'en-US',
  saveMissing: shouldThrowOnMissingTranslation,
  missingKeyHandler: (lngs: any, namespace: any, key: any, res: any) => {
    if (shouldThrowOnMissingTranslation) {
      throw new Error(
        `Missing translation for namespace '${namespace}', key '${key}', resource '${res}'`,
      );
    }
  },
  ns: ['common', 'components', 'FilterBar', 'navigation'],
  defaultNS: 'components',
  resources: {
    'en-US': {
      Attachments: en_US.Attachments,
      Autocomplete: en_US.Autocomplete,
      AutosavedInfo: en_US.AutosavedInfo,
      CategoryReview: en_US.Review,
      CookiesBanner: en_US.CookiesBanner,
      CreditsCTA: en_US.CreditsCTA,
      CreditsSelfCheckout: en_US.CreditsSelfCheckout,
      CreditsOverview: en_US.CreditsOverview,
      CreditsManagement: en_US.CreditsManagement,
      Download: en_US.Download,
      Delegator: en_US.Delegator,
      EvidenceLocker: en_US.EvidenceLocker,
      FilterBar: en_US.FilterBar,
      FAQ: en_US.FaqPage,
      FormAttachments: en_US.FormAttachments,
      FormCreator: en_US.FormCreator,
      FormDefinitions: en_US.FormDefinitions,
      FormFooter: en_US.FormFooter,
      FormHeader: en_US.FormHeader,
      FormHistory: en_US.History,
      FormNewFromExisting: en_US.FormNewFromExisting,
      FormDefinitionPreview: en_US.FormDefinitionPreview,
      FormProxy: en_US.FormProxy,
      FormReport: en_US.Report,
      GeneralError: en_US.GeneralError,
      Grade: en_US.Grade,
      Insights: en_US.Insights,
      History: en_US.History,
      Login: en_US.Login,
      Messages: en_US.Messages,
      Notifications: en_US.Notifications,
      Numeric: en_US.Numeric,
      ReviewerInstructions: en_US.ReviewerInstructions,
      Sales: en_US.Sales,
      Settings: en_US.Settings,
      SendRequest: en_US.SendRequest,
      Cobranding: en_US.Cobranding,
      FirstContact: en_US.FirstContact,
      SscData: en_US.SscData,
      QuestionMappings: en_US.QuestionMappings,
      ToggleAllButton: en_US.ToggleAllButton,
      ToggleAllDropdown: en_US.ToggleAllDropdown,
      UploadCustomStandard: en_US.UploadCustomStandard,
      ViewStatus: en_US.ViewStatus,
      NewVendorLanding: en_US.NewVendorLanding,
      common: en_US.Common,
      components: en_US.Components,
      navigation: en_US.Navigation,
    },
    'ja-JP': {
      Attachments: ja_JP.Attachments,
      Autocomplete: ja_JP.Autocomplete,
      AutosavedInfo: ja_JP.AutosavedInfo,
      CategoryReview: ja_JP.Review,
      CookiesBanner: ja_JP.CookiesBanner,
      CreditsCTA: ja_JP.CreditsCTA,
      CreditsSelfCheckout: ja_JP.CreditsSelfCheckout,
      CreditsOverview: ja_JP.CreditsOverview,
      CreditsManagement: ja_JP.CreditsManagement,
      Delegator: ja_JP.Delegator,
      EvidenceLocker: ja_JP.EvidenceLocker,
      FilterBar: ja_JP.FilterBar,
      FAQ: ja_JP.FaqPage,
      FormAttachments: ja_JP.FormAttachments,
      FormCreator: ja_JP.FormCreator,
      FormDefinitions: ja_JP.FormDefinitions,
      FormFooter: ja_JP.FormFooter,
      FormHeader: ja_JP.FormHeader,
      FormHistory: ja_JP.History,
      FormNewFromExisting: ja_JP.FormNewFromExisting,
      FormDefinitionPreview: ja_JP.FormDefinitionPreview,
      FormProxy: ja_JP.FormProxy,
      FormReport: ja_JP.Report,
      GeneralError: ja_JP.GeneralError,
      Grade: ja_JP.Grade,
      Insights: ja_JP.Insights,
      History: ja_JP.History,
      Login: ja_JP.Login,
      Messages: ja_JP.Messages,
      Notifications: ja_JP.Notifications,
      Numeric: ja_JP.Numeric,
      ReviewerInstructions: ja_JP.ReviewerInstructions,
      Sales: ja_JP.Sales,
      Settings: ja_JP.Settings,
      SendRequest: ja_JP.SendRequest,
      Cobranding: ja_JP.Cobranding,
      FirstContact: ja_JP.FirstContact,
      SscData: ja_JP.SscData,
      QuestionMappings: ja_JP.QuestionMappings,
      ToggleAllButton: ja_JP.ToggleAllButton,
      ToggleAllDropdown: ja_JP.ToggleAllDropdown,
      UploadCustomStandard: ja_JP.UploadCustomStandard,
      ViewStatus: ja_JP.ViewStatus,
      NewVendorLanding: ja_JP.NewVendorLanding,
      common: ja_JP.Common,
      components: ja_JP.Components,
      navigation: ja_JP.Navigation,
    },
  },
  react: reactOptions,
};

i18n.init(initParams);

export default i18n;
