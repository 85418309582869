import { HistoryTypeEnum } from 'common/common.types';
import Attachment from './Attachment';
import Comment from './Comment';
import SscData from './SscData';
import Response from './Response';

export default (type: HistoryTypeEnum) => {
  const { ATTACHMENT, COMMENT, RESPONSE, SSCDATA } = HistoryTypeEnum;

  switch (type) {
    case ATTACHMENT:
      return Attachment;

    case COMMENT:
      return Comment;

    case SSCDATA:
      return SscData;

    default:
    case RESPONSE:
      return Response;
  }
};
