/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface ScheduledRequestCreate
 */
export interface ScheduledRequestCreate {
    /**
     * 
     * @type {Date}
     * @memberof ScheduledRequestCreate
     */
    due_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof ScheduledRequestCreate
     */
    email_body?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledRequestCreate
     */
    notify?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ScheduledRequestCreate
     */
    occurrences?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledRequestCreate
     */
    recurrence_type?: ScheduledRequestCreateRecurrenceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ScheduledRequestCreate
     */
    recurrence_units?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledRequestCreate
     */
    reminder_days?: number;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledRequestCreate
     */
    send_date: Date;
    /**
     * Source team UUID of the sender. This is an optional field if the sender does not
     * want to send the scheduled request from one of their teams. Note that, in this case,
     * the request becomes visible through out the company wide.
     * @type {string}
     * @memberof ScheduledRequestCreate
     */
    source_team?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledRequestCreate
     */
    standard_ids: Array<string>;
    /**
     * Target to who to share to.
     * @type {Array<string>}
     * @memberof ScheduledRequestCreate
     */
    targets: Array<string>;
}




/**
* @export
* @enum {string}
*/
export enum ScheduledRequestCreateRecurrenceTypeEnum {
    DAILY = 'DAILY',
    HOURLY = 'HOURLY',
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY',
    YEARLY = 'YEARLY'
}


