import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ReactSVG from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Title } from './TabContentTitle.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface TabContentTitlePropsType {
  icon: IconDefinition | 'ssc';
  title: string;
}

const TabContentTitle = ({ icon, title }: TabContentTitlePropsType) => (
  <Title>
    {icon === 'ssc' ? (
      <ReactSVG
        style={{ display: 'inline-block' }}
        svgStyle={{ width: 14, height: 14, marginBottom: -2 }}
        src={'ssc-tab.svg'}
      />
    ) : (
      <FontAwesomeIcon icon={icon as IconProp} />
    )}

    {ReactHtmlParser(title)}
  </Title>
);

export default TabContentTitle;
