import Audit, { AuditInputPropsType } from './Audit';
import React from 'react';
import uuid from 'utils/uuid';
import { SscDataAuditType } from 'common/common.types';
import { any, filter, keys, pipe } from 'ramda';

const keyIn = (itemKey: string, diff: { [key: string]: string[] }): string[] =>
  pipe(keys, diffKeys =>
    filter(diffKey => any(m => m === itemKey, diff[diffKey]), diffKeys),
  )(diff);

const SscData = ({
  change: { createdAt, value, id },
  t,
}: AuditInputPropsType) => {
  const {
    diff: {
      addedFactors,
      addedIssues,
      removedFactors,
      removedIssues,
      updatedFactors,
      updatedIssues,
    },
    factors,
  } = value as SscDataAuditType;
  const factorsDiff = { addedFactors, removedFactors, updatedFactors };
  const issuesDiff = { addedIssues, removedIssues, updatedIssues };

  return (
    <Audit title={t('sscData.title')} date={createdAt}>
      {factors.map(factor => (
        <div key={`audit-factor-${uuid()}`}>
          {keyIn(factor.key, factorsDiff).map(key => (
            <div key={`${key}-${factor.key}-${uuid()}`}>
              {t(`sscData.${key}`, factor) as string}
            </div>
          ))}

          {factor.issues.map(issue =>
            keyIn(issue.type, issuesDiff).map(key => (
              <div key={`${key}-${factor.key}-${issue.type}-${uuid()}`}>
                {t(`sscData.${key}`, issue) as string}
              </div>
            )),
          )}
        </div>
      ))}
    </Audit>
  );
};

export default SscData;
