import { ProxySummary } from '../../../generated/models/ProxySummary';
import { ApiCallActionType } from '../../store.types';
import { FormProxyStateType } from '../formProxy.reducer';
import { Uuid } from '../../../common/common.types';

export interface FormProxyNotifyProxyCallParamsType {
  formId: Uuid;
  proxyId: Uuid;
  emailBody: string;
}

export type FormProxyNotifyProxyFunctionType = (
  formId: Uuid,
  proxyId: Uuid,
  emailBody: string,
) => void;

export type FormProxyNotifyProxyActionType = ApiCallActionType<
  '@formProxy/API/NOTIFY_PROXY',
  FormProxyNotifyProxyCallParamsType,
  ProxySummary[],
  never,
  FormProxyStateType
>;

export const notifyProxy = (
  formId: Uuid,
  proxyId: Uuid,
  emailBody: string,
): FormProxyNotifyProxyActionType => ({
  type: '@formProxy/API/NOTIFY_PROXY',
  payload: {
    callParams: { formId, proxyId, emailBody },
    endpointPath: ['forms', 'proxies', 'notify'],
    selector: null,
  },
});
