import styled, { css } from 'styled-components';
import { HorizontallyJustified } from 'common/common.styles';

export const Bar = styled.div`
  background-color: ${({ theme }) => theme.basic.colors.light};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${({ theme }) =>
    css`
      border-right: 1px solid ${theme.basic.colors.darkerBorder};
      border-bottom: 1px solid ${theme.basic.colors.darkerBorder};
      border-left: 1px solid ${theme.basic.colors.darkerBorder};
    `}
`;

export const Main = styled(HorizontallyJustified)`
  height: 58px;
  padding: 0 24px;
`;

interface ButtonType {
  isActive?: boolean;
  width?: number;
}

export const Button = styled.div<ButtonType>`
  ${({ theme }) => theme.fonts.bold.small}
  align-items: center;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.atlas.teal.primary : 'transparent'};
  border-radius: 6px;
  border: 1px solid
    ${({
      isActive,
      theme: {
        atlas: {
          teal: { primary },
        },
        basic: {
          colors: { dark },
        },
      },
    }) => (isActive ? primary : dark)};
  color: ${({ isActive, theme: { atlas } }) =>
    isActive ? atlas.white : atlas.text.primary};
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  transform: translate3d(0, 0, 0);
  width: ${({ width }) => (width ? `${width}px` : `76px`)};

  &:hover {
    color: ${({ theme }) => theme.basic.colors.light};
    background-color: ${({ theme }) => theme.atlas.teal[800]};
  }
`;

export const FilteringResult = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 8px;
  ${({ theme }) => theme.fonts.regular.normal}

  > * {
    margin: 0 16px 0 0;
  }

  > p span {
    color: ${({ theme }) => theme.basic.colors.teal};
    ${({ theme }) => theme.fonts.bold.normal}
  }
`;

export const FilterBarBottomWrapper = styled.div<{ isVisible: boolean }>`
  transition: ${({ theme }) => theme.basic.animations.normal};
  background-color: ${({ theme }) => theme.basic.colors.light};
  border-top: 1px ${({ theme }) => theme.basic.colors.darkerBorder} solid;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: ${({ isVisible }) => (isVisible ? 36 : 0)}px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  padding: 0 24px;
  position: relative;
  width: 100%;
`;

export const RedDot = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.basic.colors.error};
  border-radius: 6px;
`;

export const NotificationAlert = styled(RedDot)`
  position: absolute;
  top: 11px;
  left: 90px;
`;
