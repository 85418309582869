export interface LoadingStartActionType {
  type: '@app/LOADING_START';
  payload: {
    itemsToLoadCount: number;
    text?: string;
  };
}

export const startLoading = (
  itemsToLoadCount: number,
  text?: string,
): LoadingStartActionType => ({
  type: '@app/LOADING_START',
  payload: {
    itemsToLoadCount,
    text,
  },
});
