import React from 'react';
import Response from '../Response';
import Review from './Review';
import { DelegationActionsType } from 'pages/FormPage';
import { QuestionDelegator } from '../Delegator';
import { QuestionTabs } from './';
import { values } from 'ramda';
import { withTranslation } from 'react-i18next';
import {
  countRequiredEmptyResponses,
  isQuestionFilled,
  isRespondable,
} from '../utils';
import {
  FormsCreateMessageFunctionType,
  FormsCreateQuestionAttachmentFunctionType,
  FormsCreateResponseAttachmentFunctionType,
  FormsDeleteQuestionAttachmentFunctionType,
  FormsGetQuestionMappingsFunctionType,
  FormsPutQuestionFunctionType,
  FormsRateMappingFunctionType,
  FormsSetBlockSeenFunctionType,
  FormsSwitchQuestionFunctionType,
  FormsUpdateQuestionReviewStatusFunctionType,
} from 'store/forms/actions';
import {
  FormMetaType,
  FormPermissionsType,
  PromptInviteDelegationType,
  QuestionType,
  TranslatePropsType,
  FormAssignee,
  UserType,
  Uuid,
  LanguageType,
} from 'common/common.types';
import {
  Label,
  LabelWrapper,
  LeftWrapper,
  NotVisible,
  Required,
  Responses,
  RightWrapper,
  Square,
  Wrapper,
} from './Question.styles';
import { FormDetailFillingTypeEnum } from 'generated/models/FormDetail';

export interface QuestionPropsType extends TranslatePropsType {
  actions: DelegationActionsType & {
    createMessage?: FormsCreateMessageFunctionType;
    createQuestionAttachment?: FormsCreateQuestionAttachmentFunctionType;
    createResponseAttachment?: FormsCreateResponseAttachmentFunctionType;
    copyToClipboard?: (text: string) => void;
    deleteQuestionAttachment?: FormsDeleteQuestionAttachmentFunctionType;
    getQuestionMappings?: FormsGetQuestionMappingsFunctionType;
    onInviteUserClick?: (
      e: React.MouseEvent<HTMLElement>,
      prompt: PromptInviteDelegationType,
    ) => void;
    putQuestion?: FormsPutQuestionFunctionType;
    rateMapping?: FormsRateMappingFunctionType;
    setBlockSeen?: FormsSetBlockSeenFunctionType;
    switchQuestionTab?: FormsSwitchQuestionFunctionType;
    updateQuestionReviewStatus?: FormsUpdateQuestionReviewStatusFunctionType;
  };
  data: {
    assignees?: FormAssignee[];
    formFillingType: FormDetailFillingTypeEnum;
    formOwner: string;
    formSource?: string;
    meta: FormMetaType;
    permissions: FormPermissionsType;
    question: QuestionType;
    user: UserType;
    language: LanguageType;
  };
}

const Question = ({
  actions: {
    createMessage,
    createQuestionAttachment,
    createResponseAttachment,
    copyToClipboard,
    deleteQuestionAttachment,
    getQuestionMappings,
    onInviteUserClick,
    putQuestion,
    rateMapping,
    setBlockSeen,
    switchQuestionTab,
    updateQuestionReviewStatus,
  },
  data: {
    assignees,
    formFillingType,
    formOwner,
    formSource,
    permissions,
    permissions: {
      canSendDelegationInvitation,
      isDelegable,
      isResponseEditable,
      isReviewWritable,
    },
    question,
    question: {
      attachments,
      category,
      code,
      history,
      messages,
      meta: { areResponsesVisible },
      isVisible,
      responses,
      reviewStatus,
      sscData,
    },
    user,
    language,
  },
  t,
}: QuestionPropsType) => {
  const responsesArray = values(responses);
  const isRequired = countRequiredEmptyResponses(responsesArray) !== 0;
  const hasResponse = isRespondable(responsesArray);
  const isFilled = isQuestionFilled(responsesArray, isRequired) || !hasResponse;

  if (!isVisible) {
    return null;
  }

  const handleAttachmentDelete = (
    id: Uuid,
    questionId: Uuid,
    formId: Uuid,
    responseId?: Uuid,
  ) => {
    if (deleteQuestionAttachment) {
      deleteQuestionAttachment(id, questionId, formId, responseId, category);
    }
  };

  return (
    <Wrapper data-cy={`question-${question.id}`} data-test="question-container">
      {isReviewWritable && updateQuestionReviewStatus && hasResponse && (
        <Review
          currentStatus={reviewStatus}
          formId={question.formId}
          questionId={question.id}
          updateQuestionReviewStatus={updateQuestionReviewStatus}
        />
      )}

      <LeftWrapper
        hasBackground={isFilled}
        hasResponse={hasResponse}
        data-test="question-left-part"
      >
        <Square hasBackground={isFilled}>
          {isDelegable && hasResponse && onInviteUserClick && assignees && (
            <QuestionDelegator
              assignees={assignees}
              canInvite={canSendDelegationInvitation}
              question={question}
              onInviteUserClick={onInviteUserClick}
            />
          )}

          {isRequired && (
            <Required>{t('common:labels.required') as string}</Required>
          )}

          <LabelWrapper data-test="question-name">
            <Label>{code}</Label>

            {question.question}
          </LabelWrapper>
        </Square>

        <Responses>
          {areResponsesVisible ? (
            responsesArray.map(response => (
              <Response
                key={`${question.id}-${response.id}`}
                data={{
                  category,
                  formId: question.formId,
                  isEditable: isResponseEditable,
                  questionId: question.id,
                  response,
                  language,
                }}
                actions={{
                  createResponseAttachment,
                  deleteQuestionAttachment: handleAttachmentDelete,
                  putQuestion,
                  setBlockSeen,
                }}
              />
            ))
          ) : (
            <NotVisible data-test="not-visible">
              {t('components:Requests.responsesNotVisible') as string}
            </NotVisible>
          )}
        </Responses>
      </LeftWrapper>

      {switchQuestionTab && hasResponse && (
        <RightWrapper hasBackground={false} data-test="question-right-part">
          <QuestionTabs
            attachments={values(attachments)}
            copyToClipboard={copyToClipboard}
            createMessage={createMessage}
            createQuestionAttachment={createQuestionAttachment}
            deleteQuestionAttachment={handleAttachmentDelete}
            formFillingType={formFillingType}
            formOwner={formOwner}
            formSource={formSource}
            getQuestionMappings={getQuestionMappings}
            history={history}
            messages={values(messages)}
            permissions={permissions}
            putQuestion={putQuestion}
            question={question}
            rateMapping={rateMapping}
            setBlockSeen={setBlockSeen}
            sscData={sscData}
            switchQuestionTab={switchQuestionTab}
            user={user}
            language={language}
          />
        </RightWrapper>
      )}
    </Wrapper>
  );
};

export default withTranslation(['common', 'components'])(Question);
