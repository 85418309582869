import styled, { css } from 'styled-components/macro';
import hex2rgba from 'utils/hex2rgba';

export const ActionsMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.atlas.white};
  box-shadow: 5px 5px 5px 0px rgba(150, 150, 150, 0.25);
  border: 1px solid ${({ theme }) => theme.atlas.grey[100]};
  border-radius: 4px;
`;

export const StyledHR = styled.hr`
  border: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.atlas.grey[200]};
  margin-left: 10px;
  margin-right: 15px;
`;

interface StyledOptionProps {
  enabled: boolean;
}

export const StyledOption = styled.p<StyledOptionProps>`
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 29px;
  line-height: 20px;
  letter-spacing: 0.25px;
  ${({ theme }) => theme.fonts.regular.small}

  ${({ enabled }) =>
    enabled
      ? css`
          color: ${({ theme }) => theme.basic.colors.dark};
        `
      : css`
          color: ${({ theme }) => hex2rgba(theme.basic.colors.dark, 0.3)};
        `};

  &:hover {
    ${({ enabled }) =>
      enabled
        ? css`
            background: ${({ theme }) =>
              hex2rgba(theme.atlas.teal.primary, 0.1)};
            cursor: pointer;
            color: black;
          `
        : css`
            cursor: default;
          `}
  }
`;
