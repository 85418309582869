import React, { useContext } from 'react';
import { Button, Link, Text, Wrapper } from './Autocomplete.styles';
import { Trans, withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { getLinkFormTo } from '../../../EvidenceLocker/Frameworks/UploadGoBack';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PageContext } from 'pages/Page';
import { FormPageContextType } from 'pages/FormPage';
import { SharedFormPageContextType } from 'pages/SharedFormPage';

type MatchesNotFoundPropsType = TranslatePropsType & RouteComponentProps;

const MatchesNotFound = ({
  history: { push },
  t,
}: MatchesNotFoundPropsType) => {
  const {
    data: {
      currentForm: { displayName, id },
    },
  } = useContext(PageContext) as
    | FormPageContextType
    | SharedFormPageContextType;

  return (
    <Wrapper>
      <Text>{t('matchesNotFound') as string}</Text>

      <Trans i18nKey="uploadMoreAnswers" parent={Text} t={t}>
        Upload more answers to your
        <Link to={getLinkFormTo(id, displayName, false)}>
          answer repository
        </Link>
      </Trans>

      <Button onClick={() => push(getLinkFormTo(id, displayName, true))}>
        {t('uploadEvidences') as string}
      </Button>
    </Wrapper>
  );
};

export default withTranslation('Autocomplete')(withRouter(MatchesNotFound));
