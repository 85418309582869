import * as settings from '../../settings/actions';
import { FormDetail } from '../../../generated/models/FormDetail';
import update from 'immutability-helper';
import { contains } from 'ramda';
import { parseFramework } from 'utils/parsers';
import { answersEvidenceParams } from '../utils';
import { put } from 'redux-saga/effects';
import { ApiCallActionType } from '../../store.types';
import { UserStateType } from '../user.types';
import { StandardStatusEnum } from '../../../generated/models';
import { Uuid } from '../../../common/common.types';

export interface UserGetDashboardUploadParamsType {
  team_id?: Uuid;
}

export type UserGetDashboardUploadActionType = ApiCallActionType<
  '@user/API/GET_DASHBOARD_UPLOAD',
  UserGetDashboardUploadParamsType,
  FormDetail[],
  never,
  UserStateType
>;

export const getDashboardUpload = (
  teamId?: Uuid,
): UserGetDashboardUploadActionType => ({
  type: '@user/API/GET_DASHBOARD_UPLOAD',
  payload: {
    callParams: answersEvidenceParams(teamId),
    endpointPath: ['user', 'getDashboardUpload'],
    preActions: [
      put(
        settings.getSettings(['hiddenFiles', 'hiddenFrameworks', 'showHidden']),
      ),
    ],
    selector: (data, state) => ({
      frameworks: [
        ...state.user.frameworks,
        ...data
          .filter(
            f =>
              !contains(
                f.id,
                state.user.frameworks.map(a => a.id),
              ),
          )
          .map((f: FormDetail) =>
            update(
              parseFramework(
                update(f, {
                  standard: {
                    name: { $set: f.name },
                    status: { $set: StandardStatusEnum.CUSTOM },
                  },
                }),
              ),
              {
                standard: { deletable: { $set: true } },
              },
            ),
          ),
      ],
    }),
  },
});
