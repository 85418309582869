import * as settings from '../../settings/actions';
import { ApiCallActionType } from '../../store.types';
import { FormDetail } from '../../../generated/models/FormDetail';
import { getDashboardUpload } from './getDashboardUpload';
import { parseFramework } from 'utils/parsers';
import { put } from 'redux-saga/effects';
import { UserStateType } from '../user.types';
import { answersEvidenceParams } from '../utils';
import { Uuid } from '../../../common/common.types';

export interface UserGetDashboardParamsType {
  team_id?: Uuid;
}

export type UserGetDashboardActionType = ApiCallActionType<
  '@user/API/GET_DASHBOARD',
  UserGetDashboardParamsType,
  FormDetail[],
  never,
  UserStateType
>;

export const getDashboard = (teamId?: Uuid): UserGetDashboardActionType => ({
  type: '@user/API/GET_DASHBOARD',
  payload: {
    callParams: answersEvidenceParams(teamId),
    endpointPath: ['user', 'getDashboard'],
    preActions: [
      put(
        settings.getSettings(['hiddenFiles', 'hiddenFrameworks', 'showHidden']),
      ),
    ],
    selector: (data): Partial<UserStateType> => ({
      frameworks: data.map(parseFramework),
    }),
    postActions: [() => put(getDashboardUpload(teamId))],
  },
});
