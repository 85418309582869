/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoFillStats
 */
export interface AutoFillStats {
    /**
     * Total number of auto-fillable questions (both those that need overwriting and
     * those that don't)
     * @type {number}
     * @memberof AutoFillStats
     */
    compatibleQuestionsCount: number;
    /**
     * Total number of auto-fillable responses in questions (both those that need
     * overwriting and those that don't)
     * @type {number}
     * @memberof AutoFillStats
     */
    compatibleResponsesCount: number;
    /**
     * Number of questions with at least one response that can be automatically filled
     * without overwriting existing answers
     * @type {number}
     * @memberof AutoFillStats
     */
    fillableQuestionsCount: number;
    /**
     * Number of responses that can be automatically filled without overwriting
     * existing answers
     * @type {number}
     * @memberof AutoFillStats
     */
    fillableResponsesCount: number;
    /**
     * Number of questions in which at least one auto-fillable answer was found, but
     * would require overwriting an existing answer
     * @type {number}
     * @memberof AutoFillStats
     */
    rewritableQuestionsCount: number;
    /**
     * Number of auto-fillable responses that would require overwriting an existing
     * answer
     * @type {number}
     * @memberof AutoFillStats
     */
    rewritableResponsesCount: number;
}

export function AutoFillStatsFromJSON(json: any): AutoFillStats {
    return AutoFillStatsFromJSONTyped(json, false);
}

export function AutoFillStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoFillStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'compatibleQuestionsCount': json['compatible_questions_count'],
        'compatibleResponsesCount': json['compatible_responses_count'],
        'fillableQuestionsCount': json['fillable_questions_count'],
        'fillableResponsesCount': json['fillable_responses_count'],
        'rewritableQuestionsCount': json['rewritable_questions_count'],
        'rewritableResponsesCount': json['rewritable_responses_count'],
    };
}

export function AutoFillStatsToJSON(value?: AutoFillStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'compatible_questions_count': value.compatibleQuestionsCount,
        'compatible_responses_count': value.compatibleResponsesCount,
        'fillable_questions_count': value.fillableQuestionsCount,
        'fillable_responses_count': value.fillableResponsesCount,
        'rewritable_questions_count': value.rewritableQuestionsCount,
        'rewritable_responses_count': value.rewritableResponsesCount,
    };
}


