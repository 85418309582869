import styled from 'styled-components';
import { QuestionDetailBaseVrmReviewStatusEnum } from 'generated/models/QuestionDetailBase';
import { VRMReviewStatsStatusEnum } from 'generated/models/VRMReviewStats';

const { PENDING, FLAGGED } = QuestionDetailBaseVrmReviewStatusEnum;

export interface CurrentStatusType {
  currentStatus?:
    | QuestionDetailBaseVrmReviewStatusEnum
    | VRMReviewStatsStatusEnum;
}

export const Wrapper = styled.div<CurrentStatusType>`
  width: 127px;
  height: 100%;
  border-right: 3px solid
    ${({ theme: { atlas }, currentStatus }) =>
      currentStatus === PENDING
        ? atlas.white
        : currentStatus === FLAGGED
        ? atlas.yellow.primary
        : atlas.teal.primary};
  position: absolute;
  top: 0;
  bottom: 0;
  left: -135px;
`;

export const Box = styled.div<CurrentStatusType>`
  width: 100%;
  height: 130px;
  border-bottom: 3px solid
    ${({ theme: { atlas }, currentStatus }) =>
      currentStatus === PENDING
        ? atlas.white
        : currentStatus === FLAGGED
        ? atlas.yellow.primary
        : atlas.teal.primary};
  background: ${({ theme }) => theme.atlas.white};
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 16px;
    bottom: 16px;
    left: 25px;
    width: 1px;
    background: ${({ theme }) => theme.atlas.grey[600]};
  }
`;
