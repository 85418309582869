import LoadMore from '../../common/LoadMore';
import React from 'react';
import { QUESTIONS_LOAD_STEP } from 'common/common.constants';

interface CategoryPropsType {
  actions: {
    loadMore: (offset: number, limit: number) => void;
    renderItem: (itemData: any, index: number) => JSX.Element;
  };
  data: {
    data: any[];
    loadStep?: number;
    hasMore: boolean;
    totalQuestionCount: any[];
  };
}

const Category = ({
  actions: { loadMore, renderItem },
  data: { data, hasMore, loadStep = QUESTIONS_LOAD_STEP, totalQuestionCount },
}: CategoryPropsType) => {
  return (
    <>
      {renderItem && data.map(renderItem)}
      <LoadMore
        hasMore={hasMore}
        onLoadMore={() => loadMore(totalQuestionCount.length, loadStep)}
      />
    </>
  );
};

export default Category;
