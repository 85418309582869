import { Uuid } from '../../../common/common.types';
import { call } from 'redux-saga/effects';
import { trackEvent } from 'utils/analytics';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { RequestDetail, RequestUpdate } from '../../../generated/models';
import update from 'immutability-helper';

export type FormsPutMessageFunctionType = (
  requestId: Uuid,
  message: string,
) => void;

export type FormsPutMessageActionType = OptimisticActionType<
  '@forms/API/PUT_MESSAGE',
  ApiCallActionPayloadType<
    {
      id: Uuid;
      data: RequestUpdate;
    },
    RequestDetail,
    never,
    FormsStateType
  >
>;

export const putMessage = (
  id: Uuid,
  message: string,
): FormsPutMessageActionType => ({
  type: '@forms/API/PUT_MESSAGE',
  payload: {
    callParams: { id, data: { message } },
    endpointPath: ['requests', 'modify'],
    expectedStateChange: { 'forms.currentForm.request.message': message },
    selector: ({ message: savedMessage }, { forms }) =>
      update(forms, {
        currentForm: {
          request: {
            message: { $set: savedMessage },
          },
        },
      }),
    postActions: [() => call(() => trackEvent('Form', 'Message'))],
  },
});
