import styled from 'styled-components';

interface MessageOwnerType {
  isMine: boolean;
  isPosting?: boolean;
  isInternal?: boolean;
}

export const MessageWrapper = styled.div<MessageOwnerType>`
  float: ${({ isMine }) => (isMine ? 'right' : 'left')};
  opacity: ${({ isPosting }) => (isPosting ? '.5' : '1')};
  min-height: 0;
`;

export const Metadata = styled.div<MessageOwnerType>`
  ${({ theme }) => theme.fonts.light.small}
  color: ${({ theme }) => theme.basic.colors.gray}
  padding-bottom: 5px;
  text-align: ${({ isMine }) => (isMine ? 'right' : 'left')};
`;

export const Bubble = styled.div<MessageOwnerType>`
  position: relative;
  padding: 8px 10px;
  border-radius: 8px;
  margin-bottom: 16px;
  max-width: 80%;
  float: ${({ isMine }) => (isMine ? 'right' : 'left')};
  border-top-right-radius: ${({ isMine }) => (isMine ? '0' : '8px')};
  border-top-left-radius: ${({ isMine }) => (isMine ? '8px' : '0')};
  background: ${({
    isMine,
    isInternal,
    theme: {
      messages: {
        bubbles: { received, sent, internal },
      },
    },
  }) => (isMine ? sent : isInternal ? internal : received)};
  color: ${({ isMine, isInternal }) =>
    isMine || isInternal ? '#000' : '#fff'};
`;
