import React from 'react';
import Tooltip from '../Tooltip';
import img from './defaultGrade.png';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

const DefaultGrade = ({ t }: TranslatePropsType) => {
  const label = t('tooltipText') as string;
  return (
    <Tooltip overlay={<span>{label}</span>}>
      <img src={img} alt={t('alt') as string} />
    </Tooltip>
  );
};

export default withTranslation('Grade')(DefaultGrade);
