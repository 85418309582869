import Accordion, { AccordionSection } from '../../common/Accordion';
import Category from './Category';
import CategoryHeader from './CategoryHeader';
import React, { useContext } from 'react';
import { DEFAULT_QUESTION_BLOCKS } from 'common/common.constants';
import { FormPageContextType } from 'pages/FormPage';
import { PageContext } from 'pages/Page';
import { Question } from '../Question';
import { SharedFormPageContextType } from 'pages/SharedFormPage';
import { Wrapper } from './Categories.styles';
import { PromptInviteDelegationType, QuestionType } from 'common/common.types';
import {
  getFiltersCount,
  getSortedQuestionsForCategory,
  getTotalQuestionCount,
} from '../utils';

interface FormSectionsPropsType {
  handleInviteUserClick: (
    e: React.MouseEvent<HTMLElement>,
    prompt: PromptInviteDelegationType,
  ) => void;
}

const Categories = ({ handleInviteUserClick }: FormSectionsPropsType) => {
  const {
    actions: {
      assignCategoryDelegation,
      assignQuestionDelegation,
      copyToClipboard,
      createMessage,
      createQuestionAttachment,
      createResponseAttachment,
      deleteQuestionAttachment,
      getQuestionMappings,
      loadQuestions,
      putQuestion,
      rateMapping,
      setBlockSeen,
      switchQuestionTab,
      unassignCategoryDelegation,
      unassignQuestionDelegation,
      updateQuestionReviewStatus,
      updateCategoryReviewStatus,
    },
    data: {
      assignees,
      currentForm: {
        categories,
        fillingType: formFillingType,
        id,
        meta,
        owner: { name: formOwner },
        permissions,
        permissions: {
          canSendDelegationInvitation,
          isDelegable,
          isReviewWritable,
        },
        questions,
        request,
      },
      filters,
      user,
      language,
    },
  } = useContext(PageContext) as
    | FormPageContextType
    | SharedFormPageContextType;

  const formSource = request && request.source.name;
  const getData = (categoryName: string, categoryTotalCount: number) => {
    const data: any[] = getSortedQuestionsForCategory(categoryName)(questions);
    const hasMore = data.length < categoryTotalCount;
    const totalQuestionCount: any[] = getTotalQuestionCount(categoryName)(
      questions,
    );
    return { data, hasMore, totalQuestionCount };
  };

  const hasFilters = getFiltersCount(filters) > 0;

  return (
    <Wrapper>
      {categories && (
        <Accordion sectionNames={categories.map(({ name }) => name)}>
          {categories.map((category, index) => (
            <AccordionSection
              heading={
                <CategoryHeader
                  assignCategoryDelegation={assignCategoryDelegation}
                  onInviteUserClick={handleInviteUserClick}
                  unassignCategoryDelegation={unassignCategoryDelegation}
                  updateCategoryReviewStatus={updateCategoryReviewStatus}
                  assignees={assignees}
                  canSendDelegationInvitation={canSendDelegationInvitation}
                  category={category}
                  formId={id}
                  hasFilters={hasFilters}
                  isDelegable={isDelegable}
                  isReviewable={isReviewWritable}
                />
              }
              id={index}
              topOffset={207}
              wrapperElement={document.documentElement}
              key={category.name}
            >
              <Category
                data={getData(
                  category.name,
                  category.questionsTotalVisibleCount,
                )}
                actions={{
                  loadMore: (offset, limit) => {
                    loadQuestions({
                      id,
                      blocks: DEFAULT_QUESTION_BLOCKS,
                      category: category.name,
                      limit,
                      offset,
                    });
                  },
                  renderItem: (question: QuestionType) => (
                    <Question
                      key={`question-${question.id}`}
                      actions={{
                        assignQuestionDelegation,
                        copyToClipboard,
                        createMessage,
                        createQuestionAttachment,
                        createResponseAttachment,
                        deleteQuestionAttachment,
                        getQuestionMappings,
                        onInviteUserClick: handleInviteUserClick,
                        putQuestion,
                        setBlockSeen,
                        switchQuestionTab,
                        rateMapping,
                        unassignQuestionDelegation,
                        updateQuestionReviewStatus,
                      }}
                      data={{
                        assignees,
                        formFillingType,
                        formOwner,
                        formSource,
                        permissions,
                        question,
                        meta,
                        user,
                        language,
                      }}
                    />
                  ),
                }}
              />
            </AccordionSection>
          ))}
        </Accordion>
      )}
    </Wrapper>
  );
};

export default Categories;
