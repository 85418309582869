import React from 'react';
import { FormsUpdateQuestionReviewStatusFunctionType } from 'store/forms/actions';
import { QuestionDetailBaseVrmReviewStatusEnum } from 'generated/models/QuestionDetailBase';
import Radios from './Radios';
import { Uuid } from 'common/common.types';

interface ReviewPropsType {
  currentStatus: QuestionDetailBaseVrmReviewStatusEnum;
  formId: Uuid;
  questionId: Uuid;
  updateQuestionReviewStatus: FormsUpdateQuestionReviewStatusFunctionType;
}

const Review = ({
  currentStatus,
  formId,
  questionId,
  updateQuestionReviewStatus,
}: ReviewPropsType) => {
  const handleChange = (value: QuestionDetailBaseVrmReviewStatusEnum) =>
    updateQuestionReviewStatus(questionId, formId, value);

  return (
    <Radios
      handleChange={handleChange}
      recordId={questionId}
      currentStatus={currentStatus}
    />
  );
};

export default Review;
