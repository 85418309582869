export interface StripeCreateSessionParamsType {
  price: number;
  quantity: number;
}

export type StripeCreateSessionFunctionType = (
  price: number,
  quantity: number,
) => void;

export interface StripeCreateSessionActionType {
  type: '@stripe/CREATE_SESSION';
  payload: StripeCreateSessionParamsType;
}

export const createSession = (
  price: number,
  quantity: number,
): StripeCreateSessionActionType => ({
  type: '@stripe/CREATE_SESSION',
  payload: { price, quantity },
});
