import React from 'react';
import { components } from 'react-select';
import { OptionProps } from 'react-select/lib/components/Option';
import { ResponseOptionDescription } from './StructuredEditor.styles';
import { ResponseItemType } from 'store/formCreator/conditions/structuredCondition.types';

const StructuredResponseOption = (props: OptionProps<ResponseItemType>) => {
  const { label, description } = props.data as ResponseItemType;

  return (
    <components.Option {...props}>
      <div>{label}</div>
      {description && (
        <ResponseOptionDescription isSelected={props.isSelected}>
          {description}
        </ResponseOptionDescription>
      )}
    </components.Option>
  );
};

export default React.memo(StructuredResponseOption);
