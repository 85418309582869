import Modal from 'utils/Modal/Modal';
import React from 'react';
import StandardListItem from '../StandardListItem';
import { Button } from '../../common/Button';
import { contains } from 'ramda';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType, StandardType, Uuid } from 'common/common.types';
import {
  StandardsDeleteFunctionType,
  RenameStandardFunctionType,
  ToggleStandardSelectionFunctionType,
} from 'store/standard/actions';
import {
  Footer,
  Scroller,
  StandardsSelectedText,
} from './StandardsModal.styles';
import { StandardStatusEnum } from 'generated/models/Standard';

interface StandardsModalPropsType extends TranslatePropsType {
  deleteStandard?: StandardsDeleteFunctionType;
  hideModal: () => void;
  modalId: string;
  onPreview: (formId: Uuid) => void;
  renameStandard?: RenameStandardFunctionType;
  selectedStandards: Uuid[];
  standards: StandardType[];
  title: string | JSX.Element;
  toggleStandard: ToggleStandardSelectionFunctionType;
}

const StandardsModal = ({
  deleteStandard,
  hideModal,
  modalId,
  onPreview,
  renameStandard,
  selectedStandards,
  standards,
  title,
  toggleStandard,
  t,
}: StandardsModalPropsType) => {
  const modalProps = {
    modalId: modalId,
    title: title,
  };

  return (
    <Modal {...modalProps}>
      <Scroller data-cy="standards-modal">
        {standards.map(
          ({ code, id, name, previewFormId, status, version, year }) => {
            const isSystemBranch = status === StandardStatusEnum.SYSTEM;
            const isCustomBranch = status === StandardStatusEnum.CUSTOM;

            const sliProps = {
              code: code,
              isCustom: isCustomBranch,
              isSelected: contains(id, selectedStandards),
              name: isSystemBranch ? [name, version, year].join(' ') : name,
              onDelete: () => {
                if (deleteStandard) {
                  return deleteStandard({ id });
                }
                return null;
              },
              onPreview: () => {
                if (previewFormId) {
                  onPreview(previewFormId);
                }
                return null;
              },
              onSave: (newName: string) => {
                if (renameStandard) {
                  renameStandard({ id, name: newName });
                }
                return null;
              },
              onToggle: () => {
                toggleStandard({ id });
              },
            };

            return (
              <StandardListItem
                {...sliProps}
                key={`standard-list-item-${id}`}
              />
            );
          },
        )}
      </Scroller>

      <Footer>
        <StandardsSelectedText data-cy="standards-modal-selected-count">
          {
            t('standardsSelected', {
              count: selectedStandards.length,
            }) as string
          }
        </StandardsSelectedText>

        <span data-cy="standards-modal-button-done">
          <Button onClick={hideModal}>{t('done') as string}</Button>
        </span>
      </Footer>
    </Modal>
  );
};

export default withTranslation('SendRequest')(StandardsModal);
