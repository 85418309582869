import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { isHeaderVisible } from 'store/ui/ui.selectors';

export interface HeaderVisibilityProps {
  isHeaderVisible?: boolean;
}

const withHeaderVisibility = (ComposedComponent: ComponentType<any>) => {
  class HeaderVisibility extends React.PureComponent<any> {
    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapState = (state: any) => ({
    isHeaderVisible: isHeaderVisible(state.ui),
  });

  return connect(mapState)(HeaderVisibility);
};

export default withHeaderVisibility;
