import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Tooltip from '../../../common/Tooltip';
import { format, differenceInDays } from 'date-fns';
import { DATE_FORMAT_BASED_ON_LOCALE } from 'common/common.constants';
import { Wrapper, Sub } from './DueDate.styles';
import { LanguageType, TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { StateType } from '../../../../store/store.types';
import { getLanguagePreference } from '../../../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import getLocale from 'utils/getLocale';
interface DueDatePropsType extends TranslatePropsType {
  dueDate: Date;
  requested: Date;
  acceptedDate?: Date;
  language: LanguageType;
}

const DueDate = ({
  dueDate,
  requested,
  acceptedDate,
  language,
  t,
}: DueDatePropsType) => {
  const days = acceptedDate
    ? differenceInDays(dueDate, acceptedDate)
    : differenceInDays(dueDate, new Date());
  const usableDate = acceptedDate
    ? format(
        new Date(acceptedDate),
        DATE_FORMAT_BASED_ON_LOCALE[language.value],
        { locale: getLocale(language) },
      )
    : format(new Date(dueDate), DATE_FORMAT_BASED_ON_LOCALE[language.value], {
        locale: getLocale(language),
      });

  const sublines = {
    onTime: t('common:dueDate.onTime'), // already accepted
    today: t('common:dueDate.today'),
    earlier: t('common:dueDate.daysEarlyWithCount', {
      count: Math.abs(days),
    }), // already accepted
    left: t('common:dueDate.daysLeftWithCount', {
      count: Math.abs(days),
    }),
    late: t('common:dueDate.daysLateWithCount', {
      count: Math.abs(days),
    }),
  };

  const subLine = () => {
    const key = days > 0 ? 'left' : 'late';
    const isToday = days === 0;
    return isToday ? sublines.today : sublines[`${key}`];
  };

  return (
    <Tooltip
      overlay={ReactHtmlParser(
        t('Request.requestedAt', {
          requestedAt: format(
            requested,
            DATE_FORMAT_BASED_ON_LOCALE[language.value],
            { locale: getLocale(language) },
          ),
          dueDate: format(
            dueDate,
            DATE_FORMAT_BASED_ON_LOCALE[language.value],
            { locale: getLocale(language) },
          ),
        }),
      )}
    >
      <Wrapper>
        {acceptedDate && <Sub>{t('common:dueDate.received') as string}</Sub>}
        <strong>
          {days === 0 ? (t('common:dueDate.today') as string) : usableDate}
        </strong>

        <Sub warning={days < 0}>{subLine()}</Sub>
      </Wrapper>
    </Tooltip>
  );
};
const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});
export default connect(
  mapStateToProps,
  null,
)(withTranslation(['components', 'common'])(DueDate));
