/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { FormDefinition } from "./FormDefinition";
// @ts-ignore
import { FormDefinitionDraftCategoryStats } from "./FormDefinitionDraftCategoryStats";
// @ts-ignore
import { QuestionDefinitionSummary } from "./QuestionDefinitionSummary";
// @ts-ignore
import { Standard } from "./Standard";

/**
 * 
 * @export
 * @interface FormDefinitionSummaryExtended
 */
export interface FormDefinitionSummaryExtended {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof FormDefinitionSummaryExtended
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof FormDefinitionSummaryExtended
     */
    updated_at: Date;
    /**
     * Status for form definition initialization (creation).
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    creation_status: FormDefinitionSummaryExtendedCreationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    owner_id: string;
    /**
     * ID of my own preview form (if available)
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    preview_form_id?: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    standard_id: string;
    /**
     * 
     * @type {number}
     * @memberof FormDefinitionSummaryExtended
     */
    with_condition_count: number;
    /**
     * 
     * @type {Array<FormDefinitionDraftCategoryStats>}
     * @memberof FormDefinitionSummaryExtended
     */
    categories?: Array<FormDefinitionDraftCategoryStats>;
    /**
     * 
     * @type {Array<QuestionDefinitionSummary>}
     * @memberof FormDefinitionSummaryExtended
     */
    questions?: Array<QuestionDefinitionSummary>;
    /**
     * 
     * @type {Standard}
     * @memberof FormDefinitionSummaryExtended
     */
    standard: Standard;
}




/**
* @export
* @enum {string}
*/
export enum FormDefinitionSummaryExtendedCreationStatusEnum {
    FINISHEDFAIL = 'FINISHED_FAIL',
    FINISHEDSUCCESS = 'FINISHED_SUCCESS',
    INPROGRESS = 'IN_PROGRESS',
    INQUEUE = 'IN_QUEUE'
}


