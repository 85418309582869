import {
  QuestionDetailBase,
  QuestionDetailBaseVrmReviewStatusEnum,
} from '../../../generated/models/QuestionDetailBase';
import update from 'immutability-helper';
import { remapReviewedCategories } from '../utils';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { VRMReviewStatsMeta } from '../../../generated/models/VRMReviewStatsMeta';
import { FormsStateType } from '../forms.reducer';
import { CategoryType, Uuid } from '../../../common/common.types';
import { put } from 'redux-saga/effects';
import { updateFilterStats } from './updateFilterStats';

export type FormsUpdateQuestionReviewStatusFunctionType = (
  questionId: Uuid,
  formId: Uuid,
  status: QuestionDetailBaseVrmReviewStatusEnum,
) => void;

export interface FormsUpdateQuestionReviewStatusCallParamsType {
  questionId: Uuid;
  formId: Uuid;
  status: QuestionDetailBaseVrmReviewStatusEnum;
}

export type FormsUpdateQuestionReviewStatusActionType = OptimisticActionType<
  '@forms/API/UPDATE_QUESTION_REVIEW_STATUS',
  ApiCallActionPayloadType<
    FormsUpdateQuestionReviewStatusCallParamsType,
    QuestionDetailBase,
    never,
    FormsStateType,
    VRMReviewStatsMeta
  >
>;

export const updateQuestionReviewStatus = (
  questionId: Uuid,
  formId: Uuid,
  status: QuestionDetailBaseVrmReviewStatusEnum,
): FormsUpdateQuestionReviewStatusActionType => ({
  type: '@forms/API/UPDATE_QUESTION_REVIEW_STATUS',
  payload: {
    callParams: { questionId, formId, status },
    endpointPath: ['forms', 'questions', 'setReviewStatus'],
    expectedStateChange: {
      [`forms.currentForm.questions.${questionId}.reviewStatus`]: status,
    },
    selector: (data, state, meta: VRMReviewStatsMeta) =>
      update(state.forms, {
        currentForm: {
          questions: {
            [questionId]: { reviewStatus: { $set: data.vrm_review_status } },
          },
          categories: {
            $apply: (categories: CategoryType[]) =>
              remapReviewedCategories(meta.category_review_stats, categories),
          },
        },
      }),
    postActions: [() => put(updateFilterStats(formId))],
  },
});
