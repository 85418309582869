import EditableView from './EditableView';
import React from 'react';
import ReadOnlyView from './ReadOnlyView';
import { FormPageContextType } from 'pages/FormPage';
import { PageContextConsumer } from 'pages/Page';
import { SharedFormPageContextType } from 'pages/SharedFormPage';
import { SpaceAround, Wrapper } from './FormAttachments.styles';
import { values } from 'ramda';

const FormAttachments = () => (
  <PageContextConsumer>
    {({
      actions: {
        deleteAttachment,
        postAttachment,
        putMessage,
        updateVrmViewStatus,
      },
      data: {
        currentForm: {
          attachments,
          id: formId,
          permissions: {
            isVRM,
            isFormAttachmentDeletable,
            isFormAttachmentReadable,
            isFormAttachmentWritable,
            isFormCommentWritable,
            isResponseReadAskable,
          },
          request,
        },
        user,
      },
    }: FormPageContextType | SharedFormPageContextType) => {
      if (!request) {
        return null;
      }

      const {
        id: requestId,
        isVrmStatusChangeable,
        message,
        meta: { receiverCompanyOrTeam, senderCompanyOrTeam },
        vrmViewStatus,
      } = request;

      const hasAttachments = values(attachments).length > 0;
      const hasMessage = !!message;
      const hasBackground = isVRM && (hasAttachments || hasMessage);
      const isViewStatusEditable =
        isVrmStatusChangeable && isResponseReadAskable;

      if (!isVRM && !hasAttachments && !hasMessage) {
        return null;
      }

      return (
        <SpaceAround>
          <Wrapper grey={isVRM} hasBackground={hasBackground}>
            {isVRM && isFormAttachmentReadable ? (
              <EditableView
                attachments={attachments}
                companyName={receiverCompanyOrTeam}
                deleteAttachment={
                  isFormAttachmentDeletable ? deleteAttachment : undefined
                }
                formId={formId}
                formMessage={message}
                isViewStatusEditable={isViewStatusEditable}
                isVRM={isVRM}
                postAttachment={
                  isFormAttachmentWritable ? postAttachment : undefined
                }
                putMessage={isFormCommentWritable ? putMessage : undefined}
                requestId={requestId}
                updateVrmViewStatus={updateVrmViewStatus}
                vrmViewStatus={vrmViewStatus}
              />
            ) : (
              <ReadOnlyView
                attachments={attachments}
                companyName={senderCompanyOrTeam}
                isVRM={isVRM}
                formMessage={message}
              />
            )}
          </Wrapper>
        </SpaceAround>
      );
    }}
  </PageContextConsumer>
);
export default FormAttachments;
