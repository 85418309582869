import * as R from 'ramda';
import { FormsFiltersType } from 'store/forms/forms.reducer';
import { ResponseTypeDefinitionResponseTypeEnum } from 'generated/models/ResponseTypeDefinition';
import { SUPPORTED_FILTERS } from 'utils/filters/forms.filters';
import {
  faComment,
  faHistory,
  faPaperclip,
  faMagic,
} from '@fortawesome/free-solid-svg-icons';
import {
  FormPermissionsType,
  MessageType,
  PromptInviteDelegationType,
  QuestionsDict,
  QuestionTabEnum,
  QuestionTabType,
  ResponseType,
} from 'common/common.types';
import { FormsGetFormCallParams } from 'store/forms/actions';

const { NORESPONSE } = ResponseTypeDefinitionResponseTypeEnum;

export const countRequiredEmptyResponses = (responses: ResponseType[]) =>
  responses.reduce(
    (acc, response) => acc + (response.isRequired && !response.value ? 1 : 0),
    0,
  );

export const isRespondable = (responses: ResponseType[]) =>
  R.any(
    (response: ResponseType) => response.definition.responseType !== NORESPONSE,
  )(responses);

export const isQuestionFilled = (
  responses: ResponseType[],
  isRequired: boolean,
): boolean => {
  let emptiesNotRequired = 0;

  return (
    responses.filter(response => {
      emptiesNotRequired += !response.isFilled && !isRequired ? 1 : 0;
      return isRequired || responses.length - emptiesNotRequired === 0;
    }).length === 0
  );
};

export const messagesToSortedArray = (messages: MessageType[]) =>
  R.sort(
    (a, b) =>
      R.prop('createdAt', b).getTime() - R.prop('createdAt', a).getTime(),
    messages,
  );

export const renameKeys = R.curry((keysMap: any, obj: any) =>
  R.reduce(
    (acc, key) => R.assoc(keysMap[key] || key, obj[key], acc),
    {},
    R.keys(obj),
  ),
);

export const getUiFilters = (
  hideSscData: boolean,
  hasNewlyAutofilled: boolean,
  isDelegable: boolean,
  isReviewReadable: boolean,
  isAutocompletable: boolean,
) => ({
  questionStates: ['not_filled', 'filled', 'required'],
  responseType: ['yes', 'no', 'na'],
  relatedInformation: [
    'comments',
    ...(hideSscData ? [] : ['ssc_data']),
    'attachments',
  ],
  new: [
    'comments_new',
    ...(hideSscData ? [] : ['ssc_data_new']),
    'attachments_new',
    'newAnswer',
    ...(hasNewlyAutofilled ? ['newly_autocompleted'] : []),
    'responses_new',
  ],
  reviewStates: isReviewReadable
    ? ['vrm_review_pending', 'vrm_review_flagged', 'vrm_review_done']
    : [],
  assignedTo: isDelegable ? ['unassigned', 'assignees'] : [],
  autocompletion: isAutocompletable
    ? ['autocomplete_done', 'autocomplete_suggestions_available']
    : [],
});

export const getFiltersCount = ({ assignees, simple }: FormsFiltersType) =>
  R.add(
    Object.keys(assignees).filter(id => assignees[id].isEnabled).length,
    Object.keys(simple).filter(filterName => simple[filterName].isEnabled)
      .length,
  );

export const isFilterSupported = (filterName: string) =>
  R.contains(filterName, SUPPORTED_FILTERS);

export const QUESTION_TAB_OPTIONS: (
  permissions: FormPermissionsType,
) => {
  [key: string]: QuestionTabType;
} = (permissions: FormPermissionsType) => ({
  attachments: {
    label: QuestionTabEnum.ATTACHMENTS,
    icon: faPaperclip,
    enabled: permissions.isQuestionAttachmentReadable,
  },
  comments: {
    label: QuestionTabEnum.COMMENTS,
    icon: faComment,
    enabled: permissions.isQuestionCommentReadable,
  },
  history: {
    label: QuestionTabEnum.HISTORY,
    icon: faHistory,
    enabled: permissions.isResponseHistoryReadable,
  },
  ssc_data: {
    label: QuestionTabEnum.SSC_DATA,
    icon: QuestionTabEnum.SSC_DATA,
    enabled: permissions.isSscScoreReadable,
  },
  lookup_lens: {
    label: QuestionTabEnum.LOOKUP_LENS,
    icon: faMagic,
    enabled: permissions.isLookupLensesReadable,
  },
});

export const getEnabledFiltersKeys = (
  branch: string,
  filters: FormsFiltersType,
) => Object.keys(filters[branch]).filter(f => filters[branch][f].isEnabled);

export const getFiltersCallParam = (
  filters: FormsFiltersType,
): Partial<FormsGetFormCallParams> => ({
  assigneeIds: getEnabledFiltersKeys('assignees', filters),
  filters: getEnabledFiltersKeys('simple', filters),
});

export const getSortedQuestionsForCategory = (categoryName: string) => (
  questions: QuestionsDict,
): any =>
  R.compose(
    R.sortBy(R.prop('pos')),
    R.filter((c: any) => {
      return c.category === categoryName && c.isVisible === true;
    }),
    R.values,
  )(questions);

export const getTotalQuestionCount = (categoryName: string) => (
  questions: QuestionsDict,
): any => {
  return R.compose(
    R.sortBy(R.prop('pos')),
    R.filter((c: any) => {
      return c.category === categoryName;
    }),
    R.values,
  )(questions);
};

export const getPromptConfirmKey = (
  promptProps: PromptInviteDelegationType,
): string => {
  return Object.keys(promptProps).filter(key => promptProps[key])[0] || '';
};
