import { RequestDirectionType, Uuid } from '../../../common/common.types';
import { RequestsCategoryNameType } from '../requests.types';

export type RequestsToggleSelectionsFunctionType = (
  category: RequestsCategoryNameType,
  requestIds: Uuid[],
) => void;

export interface RequestsToggleSelectionActionType {
  type: '@requests/TOGGLE_SELECTION';
  payload: {
    category: RequestsCategoryNameType;
    direction: RequestDirectionType;
    requestIds: Uuid[];
  };
}

export const toggleRequestSelection = (
  category: RequestsCategoryNameType,
  direction: RequestDirectionType,
  requestIds: Uuid[],
): RequestsToggleSelectionActionType => ({
  type: '@requests/TOGGLE_SELECTION',
  payload: {
    category,
    direction,
    requestIds,
  },
});
