import styled from 'styled-components';

export const Button = styled.div`
  ${({ theme }) => theme.fonts.bold.small}
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  right: 35px;
  top: -28px;
`;
