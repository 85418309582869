import styled from 'styled-components';
import hex2rgba from 'utils/hex2rgba';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => hex2rgba(theme.atlas.grey[100], 0.95)};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 16px;

  > * {
    margin-top: 16px;
  }

  > :nth-child(1) {
    color: ${({ theme }) => theme.basic.colors.atlas};
  }
`;

export const Heading = styled.h3`
  margin-top: 24px;
`;
