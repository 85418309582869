import {
  HorizontallyJustified,
  alwaysShowScrollbar,
  TextButton,
} from 'common/common.styles';
import { Closer as CloserWrapper } from '../../common/Overlay';
import styled from 'styled-components';
import { ChildrenWrapper } from '../../common/Dropdown/Dropdown.styles';

const COLUMN_WIDTH = 500;

export const Content = styled.div<{ columns: number }>`
  position: relative;
  min-width: ${({ columns }) => COLUMN_WIDTH + (columns - 1) * 400}px;
  max-width: ${({ columns }) => columns * COLUMN_WIDTH}px;
  max-height: 535px;
  padding: 15px 0 0;
  display: flex;
  flex-flow: column wrap;
`;

export const Closer = styled(CloserWrapper)`
  top: 10px;
`;

export const Section = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  max-width: ${COLUMN_WIDTH}px;
  min-width: 400px;
`;

export const Header = styled(HorizontallyJustified)`
  line-height: 18px;
  margin-bottom: 10px;
`;

export const Title = styled.span`
  ${({ theme }) => theme.fonts.bold.small}
`;

export const CheckboxGroup = styled.div`
  padding-left: 4px;
`;

export const Scrollable = styled.div`
  min-height: 0;
  max-height: 240px;
  min-width: 0;
  max-width: 385px;
  overflow: hidden;
  overflow-y: scroll;
  padding-left: 4px;

  ${alwaysShowScrollbar}
`;

export const AnchorLeft = styled(ChildrenWrapper)`
  left: 0;
`;

export const AnchorRight = styled(ChildrenWrapper)`
  right: 20px;
`;

export const ClearAll = styled(TextButton)`
  position: relative;
  margin-top: -10px;
  margin-right: 15px;
  align-items: flex-end;
  margin-bottom: 7px;
`;
