/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MappingRequest,
    MappingRequestFromJSON,
    MappingRequestFromJSONTyped,
    MappingRequestToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface MappingRequestResponse
 */
export interface MappingRequestResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof MappingRequestResponse
     */
    msg: string;
    /**
     * 
     * @type {MappingRequest}
     * @memberof MappingRequestResponse
     */
    entry: MappingRequest;
}

export function MappingRequestResponseFromJSON(json: any): MappingRequestResponse {
    return MappingRequestResponseFromJSONTyped(json, false);
}

export function MappingRequestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MappingRequestResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': MappingRequestFromJSON(json['entry']),
    };
}

export function MappingRequestResponseToJSON(value?: MappingRequestResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': MappingRequestToJSON(value.entry),
    };
}


