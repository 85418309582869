import React from 'react';
import { Text, Wrapper } from './Autocomplete.styles';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';

const AllQuestionsFilled = ({ t }: TranslatePropsType) => (
  <Wrapper>
    <Text>{t('allQuestionsFilled') as string}</Text>
  </Wrapper>
);

export default withTranslation('Autocomplete')(AllQuestionsFilled);
