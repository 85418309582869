/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PurgeTestRequestsData
 */
export interface PurgeTestRequestsData {
    /**
     * Start of the time range, in which the requests that were created after this
     * timestamp will be deleted.
     * @type {Date}
     * @memberof PurgeTestRequestsData
     */
    from?: Date;
    /**
     * Requests that were sent by this user and received by `target_user` will be deleted
     * @type {string}
     * @memberof PurgeTestRequestsData
     */
    sourceUserId: string;
    /**
     * Standard id of the requests that needs to be deleted. If this is not provided,
     * the API will still pursue to delete ALL requests that were sent and received between
     * the `source_user` and `target_user` respectively.
     * @type {string}
     * @memberof PurgeTestRequestsData
     */
    standardId?: string;
    /**
     * Current request status
     * @type {string}
     * @memberof PurgeTestRequestsData
     */
    status?: PurgeTestRequestsDataStatusEnum;
    /**
     * Requests that were received by this user and sent by `source_user` will be deleted
     * @type {string}
     * @memberof PurgeTestRequestsData
     */
    targetUserId: string;
    /**
     * End of the time range, in which the requests that were created before this
     * timestamp will be deleted.
     * @type {Date}
     * @memberof PurgeTestRequestsData
     */
    to?: Date;
}

export function PurgeTestRequestsDataFromJSON(json: any): PurgeTestRequestsData {
    return PurgeTestRequestsDataFromJSONTyped(json, false);
}

export function PurgeTestRequestsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurgeTestRequestsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'sourceUserId': json['source_user_id'],
        'standardId': !exists(json, 'standard_id') ? undefined : json['standard_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'targetUserId': json['target_user_id'],
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
    };
}

export function PurgeTestRequestsDataToJSON(value?: PurgeTestRequestsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from === undefined ? undefined : (value.from.toISOString()),
        'source_user_id': value.sourceUserId,
        'standard_id': value.standardId,
        'status': value.status,
        'target_user_id': value.targetUserId,
        'to': value.to === undefined ? undefined : (value.to.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum PurgeTestRequestsDataStatusEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}


