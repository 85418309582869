import { UserActionType } from './actions';
import { UserStateType } from './user.types';
import { getInitialState } from './utils';
import update from 'immutability-helper';

const initialState: UserStateType = getInitialState({});

const user = (
  state: UserStateType = initialState,
  action: UserActionType,
): UserStateType => {
  switch (action.type) {
    case '@user/SELECT_FILE':
      return update(state, {
        fileSelected: { $set: action.payload.file },
      });

    case '@user/SELECT_FRAMEWORK':
      return update(state, {
        frameworkSelected: { $set: action.payload.framework },
      });

    case '@user/SELECT_TEAM':
      return update(state, {
        currentUser: {
          selectedTeam: { $set: action.payload.team },
        },
      });

    case '@user/SWITCH_CONTEXT':
      return update(state, {
        currentUser: {
          navigationContext: { $set: action.payload.navigationContext },
        },
      });

    case '@user/RESET_DASHBOARD':
      return update(state, {
        frameworks: { $set: initialState.frameworks },
      });

    case '@user/RESET_FIRST_CONTACT_INFO':
      return update(state, {
        firstContact: { $set: initialState.firstContact },
      });

    default:
      return state;
  }
};

export default user;
