import styled, { css } from 'styled-components';
import hex2rgba from 'utils/hex2rgba';

export const Item = styled.span<{ selected: boolean }>`
  ${({ theme }) => theme.fonts.bold.small}
  color: ${({ selected, theme }) =>
    selected
      ? theme.basic.colors.teal
      : hex2rgba(theme.basic.colors.teal, 0.3)};
  cursor: pointer;

  &:hover {
    ${({ selected, theme }) =>
      selected
        ? null
        : css`
            color: ${hex2rgba(theme.basic.colors.teal, 0.6)};
          `}
  }
`;

export const Wrapper = styled.div`
  & > ${Item} + ${Item} {
    &::before {
      content: ' | ';
      color: ${({ theme }) => theme.atlas.text.secondary};
    }
  }
`;
