import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatusFlagType, Uuid } from 'common/common.types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Closer,
  FlashContainer,
  FlashToast,
  Text,
} from './FlashMessage.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface FlashMessageType {
  id: Uuid;
  message: string;
  removeFlash: (id: Uuid) => void;
  status: StatusFlagType;
  style?: any;
}

const FlashMessage = ({
  id,
  message,
  removeFlash,
  status,
  style = null,
}: FlashMessageType) => {
  const faTimesProp = faTimes as IconProp;
  return (
    <FlashContainer style={style}>
      <FlashToast status={status}>
        <Text>{message}</Text>

        <Closer onClick={() => removeFlash(id)}>
          <FontAwesomeIcon icon={faTimesProp} color={'#fff'} />
        </Closer>
      </FlashToast>
    </FlashContainer>
  );
};

export default FlashMessage;
