import { ApiCallActionType } from '../../store.types';
import {
  DelegationNotificationsDirectionType,
  Uuid,
} from '../../../common/common.types';
import { getFormWithoutReset } from './getFormWithoutReset';
import { put } from 'redux-saga/effects';

export interface FormsSendDelegationNotificationsCallParamsType {
  formId: Uuid;
  params: {
    direction: DelegationNotificationsDirectionType;
  };
}

export type FormsSendDelegationNotificationsFunctionType = (
  direction: DelegationNotificationsDirectionType,
  formId: Uuid,
) => void;

export type FormsSendDelegationNotificationsActionType = ApiCallActionType<
  '@forms/API/SEND_DELEGATION_NOTIFICATIONS',
  FormsSendDelegationNotificationsCallParamsType
>;

export const sendDelegationNotifications = (
  direction: DelegationNotificationsDirectionType,
  formId: Uuid,
): FormsSendDelegationNotificationsActionType => ({
  type: '@forms/API/SEND_DELEGATION_NOTIFICATIONS',
  payload: {
    callParams: {
      formId,
      params: {
        direction,
      },
    },
    endpointPath: ['forms', 'delegations', 'notifications', 'send'],
    selector: null,
    postActions: [() => put(getFormWithoutReset(formId))],
  },
});
