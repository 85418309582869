import colors from 'common/common.colors';
import styled, { css } from 'styled-components';
import { ButtonColorType, ButtonSizeType } from './Button';

interface ButtonType {
  color?: ButtonColorType;
  disabled?: boolean;
  hasShadow?: boolean;
  linkLike?: boolean;
  margin?: string;
  size?: ButtonSizeType;
  textColor?: string;
  /**
   * Use this so that parent container can handle mouse-leave event even when the button is disabled
   */
  pointerEventsNoneForDisabled?: boolean;
}

export const Button = styled.button<ButtonType>`
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  border: none;
  position: relative;
  white-space: nowrap;

  ${({ theme }) => theme.fonts.light.normal}

  ${({ color }: { color?: ButtonColorType }) => {
    if (color === 'transparent') {
      return css`
        border: 1px solid #a5adb0;
      `;
    } else {
      return null;
    }
  }};

  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.4 : 1)};

  box-shadow: ${({ hasShadow }: { hasShadow?: boolean }) =>
    hasShadow ? '3px 3px 12px rgba(0, 0, 0, 0.15)' : 'none'};

  background-color: ${({ color }: { color?: ButtonColorType }) =>
    color
      ? color.substring(0, 1) === '#'
        ? color
        : colors.elements.button[color].background
      : '#f6f6f6'};

  color: ${({
    color,
    textColor,
  }: {
    color?: ButtonColorType;
    textColor?: string;
  }) => {
    return color
      ? color.substring(0, 1) === '#'
        ? textColor || '#000'
        : colors.elements.button[color].color
      : '#000';
  }};

  ${({ disabled }: { disabled?: boolean }) => {
    if (disabled) {
      return css`
        background-color: #a5adb0;
        color: #fff;
        cursor: not-allowed;
      `;
    } else {
      return null;
    }
  }};

  ${({ pointerEventsNoneForDisabled }) =>
    pointerEventsNoneForDisabled
      ? css`
          &[disabled] {
            pointer-events: none;
          }
        `
      : null}

  ${({ size }: { size?: ButtonSizeType }) => {
    switch (size) {
      case 'mini':
        return css`
          padding: 0 8px;
          border-radius: 7px;
          line-height: 30px;
          height: 30px;
          ${({ theme }) => theme.fonts.regular.mini}
        `;

      case 'small':
        return css`
          padding: 0 8px;
          border-radius: 7px;
          line-height: 34px;
          height: 34px;
          ${({ theme }) => theme.fonts.regular.xsmall}
        `;

      case 'big':
        return css`
          padding: 15px 30px;
          border-radius: 6px;
          height: 46px;
          ${({ theme }) => theme.fonts.bold.normal}
        `;

      case 'large':
        return css`
          padding: 20px 40px 20px 40px;
          border-radius: 38px;
          line-height: initial;
          height: auto;
          ${({ theme }) => theme.fonts.regular.large}
        `;

      case 'mlarge':
        return css`
          border: 1px solid ${({ theme }) => theme.atlas.teal.primary};
          padding: 0px 24px 0px 14px;
          height: 59px;
          border-radius: 6px;

          /* &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(
                270.34deg,
                #ffffff 0.1%,
                rgba(255, 255, 255, 0) 99.84%
              ),
              #f7fafa;
          } */
        `;

      default:
      case 'medium':
        return css`
          padding: 0 12px;
          border-radius: 7px;
          line-height: 36px;
          height: 36px;
          ${({ theme }) => theme.fonts.semibold.small}
        `;
    }
  }};

  ${({ linkLike }) => {
    if (linkLike) {
      return css`
        text-decoration: underline;
        padding-left: 0;
        padding-right: 0;
      `;
    } else {
      return null;
    }
  }};

  margin: ${({ margin }) => (margin ? margin : 0)};

  span {
    display: initial;
    padding: 4px 10px;
    position: relative;
    top: -1px;
    left: 2px;
  }
`;
