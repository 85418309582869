import i18n from '../../../i18n';
import { FormShortSummary } from '../../../generated/models/FormShortSummary';
import { assocPath } from 'ramda';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { Uuid } from '../../../common/common.types';

export interface FormsRenameFormCallParamsType {
  name: string;
  formId: Uuid;
}

export type FormsRenameFormFunctionType = (name: string, formId: Uuid) => void;

export type FormsRenameFormActionType = ApiCallActionType<
  '@forms/API/RENAME_FORM',
  {
    name: string;
    formId: Uuid;
  },
  FormShortSummary,
  never,
  FormsStateType
>;

export const renameForm = (
  name: string,
  formId: Uuid,
): FormsRenameFormActionType => ({
  type: '@forms/API/RENAME_FORM',
  payload: {
    callParams: { name, formId },
    endpointPath: ['forms', 'rename'],
    selector: (data, state) =>
      assocPath<string, FormsStateType>(
        ['currentForm', 'displayName'],
        data.display_name,
      )(state.forms),
    postActions: [
      () => call(() => trackEvent('Form', `Rename -> ${name}`)),
      () => put(flash(i18n.t('common:responses.formRenamed'))),
    ],
  },
});
