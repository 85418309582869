import { pickBy } from 'ramda';
import { ValueLabelType } from 'common/common.types';
import { isValid, startOfDay, endOfDay, subDays } from 'date-fns';

export const REQUEST_FILTERS: FilterByType[] = [
  'EMAILS',
  'COMPANIES',
  'STANDARDS',
  'DATE',
  'INTERNAL',
  'WITH_REVISIONS',
];

export const INSIGHTS_FILTERS: FilterByType[] = [
  'COMPANIES',
  'STANDARDS',
  'STATUS',
  'DATE',
];

export type FilterByType =
  | 'COMPANIES'
  | 'DATE'
  | 'EMAILS'
  | 'STANDARDS'
  | 'STATUS'
  | 'INTERNAL'
  | 'EXTERNAL'
  | 'WITH_REVISIONS';

export interface FilterOptionType extends ValueLabelType<string> {
  count?: number;
}

export interface FilterOptionsDescriptionType {
  plain: string;
  html: string;
}

export const defaultFilterDescription = {
  plain: '',
  html: '',
};

export type FilterOptionsType = { [key in FilterByType]?: FilterOptionType[] };

export const defaultFilterOption: FilterOptionType[] = [];

export const defaultFilterOptions: FilterOptionsType = {
  COMPANIES: defaultFilterOption,
  DATE: defaultFilterOption,
  EMAILS: defaultFilterOption,
  STANDARDS: defaultFilterOption,
  STATUS: defaultFilterOption,
  INTERNAL: defaultFilterOption,
  WITH_REVISIONS: defaultFilterOption,
};

export type FilterItemType =
  | FilterLookForType
  | FilterDateType
  | FilterTypeBase;

export interface FilterTypeBase {
  isEnabled: boolean | string;
}

export interface FilterLookForType extends FilterTypeBase {
  lookFor: string[];
}

export interface FilterLookForStringType extends FilterTypeBase {
  lookFor: string;
}

export enum FilterDateIntervalType {
  ALL = 'ALL',
  CUSTOM = 'CUSTOM',
  LAST7DAYS = 'LAST7DAYS',
  LAST30DAYS = 'LAST30DAYS',
}

export const DATE_FILTERS: FilterDateIntervalType[] = [
  FilterDateIntervalType.ALL,
  FilterDateIntervalType.LAST7DAYS,
  FilterDateIntervalType.LAST30DAYS,
  FilterDateIntervalType.CUSTOM,
];

export interface FilterDateType {
  interval: FilterDateIntervalType;
  isEnabled: boolean | string;
  from?: string;
  to?: string;
}

export interface FilterType {
  COMPANIES: FilterLookForType;
  DATE: FilterDateType;
  EMAILS: FilterLookForType;
  STANDARDS: FilterLookForType;
  STATUS: FilterLookForType;
  INTERNAL: FilterTypeBase;
  EXTERNAL: FilterTypeBase;
  WITH_REVISIONS: FilterLookForStringType;
}

const defaultFilterItemBase = { isEnabled: false };

const defaultFilterItem = { ...defaultFilterItemBase, lookFor: [] };

const defaultStringFilterItem = { ...defaultFilterItemBase, lookFor: '' };

const defaultDateFilterItem = {
  interval: FilterDateIntervalType.ALL,
  isEnabled: false,
  from: undefined,
  to: undefined,
};

export const defaultFilter: FilterType = {
  COMPANIES: defaultFilterItem,
  DATE: defaultDateFilterItem,
  EMAILS: defaultFilterItem,
  STANDARDS: defaultFilterItem,
  STATUS: defaultFilterItem,
  INTERNAL: defaultFilterItemBase,
  EXTERNAL: defaultFilterItemBase,
  WITH_REVISIONS: defaultStringFilterItem,
};

const ensureCorrectFormat = ({
  isEnabled = false,
  lookFor = [],
}: Partial<FilterLookForType>): FilterLookForType => ({
  isEnabled: isEnabled === true || isEnabled === 'true',
  lookFor: Array.isArray(lookFor) ? lookFor : [],
});

const ensureLookForStringFormat = ({
  isEnabled = false,
  lookFor = '',
}: Partial<FilterLookForStringType>): FilterLookForStringType => ({
  isEnabled: isEnabled === true || isEnabled === 'true',
  lookFor: typeof lookFor === 'string' ? lookFor : '',
});

const ensureBooleanFormat = ({
  isEnabled = false,
}: FilterTypeBase): FilterTypeBase => ({
  isEnabled: isEnabled === true || isEnabled === 'true',
});

const ensureCorrectDateFormat = ({
  interval,
  isEnabled = false,
  from,
  to,
}: Partial<FilterDateType>): FilterDateType => {
  const correctedInterval =
    interval === FilterDateIntervalType.LAST7DAYS ||
    interval === FilterDateIntervalType.LAST30DAYS ||
    interval === FilterDateIntervalType.CUSTOM
      ? interval
      : FilterDateIntervalType.ALL;

  return {
    interval: correctedInterval,
    isEnabled:
      correctedInterval !== FilterDateIntervalType.ALL &&
      (isEnabled === true || isEnabled === 'true'),
    from: from && isValid(new Date(from)) ? from : undefined,
    to: to && isValid(new Date(to)) ? to : undefined,
  };
};

export const correctFilters = ({
  COMPANIES = defaultFilterItem,
  DATE = defaultDateFilterItem,
  EMAILS = defaultFilterItem,
  STANDARDS = defaultFilterItem,
  STATUS = defaultFilterItem,
  INTERNAL = defaultFilterItemBase,
  EXTERNAL = defaultFilterItemBase,
  WITH_REVISIONS = defaultStringFilterItem,
}: Partial<FilterType> = defaultFilter): FilterType => ({
  COMPANIES: ensureCorrectFormat(COMPANIES),
  DATE: ensureCorrectDateFormat(DATE),
  EMAILS: ensureCorrectFormat(EMAILS),
  STANDARDS: ensureCorrectFormat(STANDARDS),
  STATUS: ensureCorrectFormat(STATUS),
  INTERNAL: ensureBooleanFormat(INTERNAL),
  EXTERNAL: ensureBooleanFormat(EXTERNAL),
  WITH_REVISIONS: ensureLookForStringFormat(WITH_REVISIONS),
});

export const isLookForFilter = (
  item: FilterItemType,
): item is FilterLookForType =>
  (item as FilterLookForType).lookFor !== undefined;

export const isDateFilter = (item: FilterItemType): item is FilterDateType =>
  (item as FilterDateType).from !== undefined ||
  (item as FilterDateType).to !== undefined ||
  (item as FilterDateType).interval !== undefined;

export const isFilterEnabled = (item: FilterItemType, key: FilterByType) =>
  item.isEnabled === true || item.isEnabled === 'true';

export const omitDisabled = (filters: FilterType): FilterType =>
  pickBy(isFilterEnabled, filters);

export const getDateFilters = ({
  interval,
  isEnabled,
  from,
  to,
}: FilterDateType) => {
  if (isEnabled) {
    if (interval === FilterDateIntervalType.LAST7DAYS) {
      return {
        sent_time_from: startOfDay(subDays(new Date(), 7)).toISOString(),
        sent_time_to: endOfDay(new Date()).toISOString(),
      };
    } else if (interval === FilterDateIntervalType.LAST30DAYS) {
      return {
        sent_time_from: startOfDay(subDays(new Date(), 30)).toISOString(),
        sent_time_to: endOfDay(new Date()).toISOString(),
      };
    } else if (interval === FilterDateIntervalType.CUSTOM && (from || to)) {
      return {
        sent_time_from: from,
        sent_time_to: to,
      };
    }
  }
  return undefined;
};
