/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionConditionStructuredItem
 */
export interface QuestionConditionStructuredItem {
    /**
     * 
     * @type {string}
     * @memberof QuestionConditionStructuredItem
     */
    operator: QuestionConditionStructuredItemOperatorEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionConditionStructuredItem
     */
    parentQuestionUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionConditionStructuredItem
     */
    responseUuid: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionConditionStructuredItem
     */
    value: string;
}

export function QuestionConditionStructuredItemFromJSON(json: any): QuestionConditionStructuredItem {
    return QuestionConditionStructuredItemFromJSONTyped(json, false);
}

export function QuestionConditionStructuredItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionConditionStructuredItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operator': json['operator'],
        'parentQuestionUuid': !exists(json, 'parent_question_uuid') ? undefined : json['parent_question_uuid'],
        'responseUuid': json['response_uuid'],
        'value': json['value'],
    };
}

export function QuestionConditionStructuredItemToJSON(value?: QuestionConditionStructuredItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operator': value.operator,
        'parent_question_uuid': value.parentQuestionUuid,
        'response_uuid': value.responseUuid,
        'value': value.value,
    };
}

/**
* @export
* @enum {string}
*/
export enum QuestionConditionStructuredItemOperatorEnum {
    CONTAINS = 'CONTAINS',
    ENDSWITH = 'ENDS_WITH',
    EQUALTO = 'EQUAL_TO',
    GREATERTHAN = 'GREATER_THAN',
    GREATERTHANOREQUALTO = 'GREATER_THAN_OR_EQUAL_TO',
    GREATERTHANORLESSTHAN = 'GREATER_THAN_OR_LESS_THAN',
    LESSTHAN = 'LESS_THAN',
    LESSTHANOREQUALTO = 'LESS_THAN_OR_EQUAL_TO',
    NOTCONTAINS = 'NOT_CONTAINS',
    NOTENDSWITH = 'NOT_ENDS_WITH',
    NOTEQUALTO = 'NOT_EQUAL_TO',
    NOTSTARTSWITH = 'NOT_STARTS_WITH',
    STARTSWITH = 'STARTS_WITH'
}


