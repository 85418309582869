import styled from 'styled-components';
import Input from '../../common/Input';
import InputWithLabelWrapper from '../../common/Input/InputWithLabelWrapper';
import { CompactSelect } from '../../common/Select';
import OrigValidationInfo from '../ValidationInfo';

const CONTENT_LEFT_PADDING = '45px';

export const Wrapper = styled.div<{ hasSecondaryOffset: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.atlas.grey[50]};
  padding-bottom: ${({ hasSecondaryOffset }) =>
    hasSecondaryOffset ? 48 : 10}px;
`;

export const ResponseHeader = styled.div`
  display: flex;
  padding-top: 15px;
  padding-left: ${CONTENT_LEFT_PADDING};
`;

export const StyledSelect = styled(CompactSelect).attrs({ bold: true })`
  width: 160px;
`;

export const DescriptionWrapper = styled(InputWithLabelWrapper)`
  padding-left: 16px;
  flex: 1;
`;

export const DescriptionInput = styled(Input).attrs({
  height: 32,
})`
  min-width: 170px;
  flex: 1;
`;

export const ResponseComponentWrapper = styled.div`
  margin-top: 10px;
  padding-left: ${CONTENT_LEFT_PADDING};
`;

export const AddSecondaryResponse = styled.div`
  position: absolute;
  right: 30px;
  bottom: 6px;
  width: 225px;
  height: 38px;
`;

export const ValidationInfo = styled(OrigValidationInfo)`
  margin: 10px 10px 0;
`;
