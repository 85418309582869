import { takeEvery, select, put, call } from 'redux-saga/effects';
import { ActionType, StateType } from '../../store.types';
import { EventSequencerActionBaseType } from './eventSequencer.types';
import isCallable from 'is-callable';
import { FormCreatorEventCallParamsType } from '../event.types';
import { EventRegisterSummary } from '../../../generated/models/EventRegisterSummary';
import { FormCreatorStateType } from '../formCreator.types';
import api from '../../../api';
import {
  enqueueAndApplyEvents,
  sendingStarted,
  sendSuccess,
  sendError,
  sendingFinished,
} from './actions';
import callEndpoint, {
  ApiCallResult,
  ApiGeneralStatusEnum,
} from 'utils/callEndpoint';
import {
  isInErrorState,
  isConflictDialogVisible,
  isOtherError,
} from './eventSequencer.selectors';
import { showModal } from 'utils/Modal';
import { FORM_CREATOR_CONFLICT_MODAL_ID } from '../../../common/common.constants';
import { getErrorMessage } from 'utils/getErrorMessage';
import { flash } from '../../../store/flashes/actions';

const getFormCreatorState = () =>
  select(({ formCreator }: StateType) => formCreator);

const sendEvents = (params: FormCreatorEventCallParamsType) =>
  callEndpoint<FormCreatorEventCallParamsType, EventRegisterSummary>(
    api.definitions.drafts.postEvent,
    params,
  );

function* maybeSendEventsSaga(actionType: string) {
  let formCreator: FormCreatorStateType = yield getFormCreatorState();

  if (formCreator.eventSequencer.isWaitingForResponse) {
    return;
  }

  while (
    formCreator.eventSequencer.waitingEvents.length > 0 &&
    !isInErrorState(formCreator.eventSequencer)
  ) {
    const eventsForSending = formCreator.eventSequencer.waitingEvents;
    // sets isWaitingForResponse and clears waiting events
    yield put(sendingStarted());
    formCreator = yield getFormCreatorState();

    const result: ApiCallResult<EventRegisterSummary> = yield call(sendEvents, {
      draft_id: formCreator.currentForm ? formCreator.currentForm.id : 0,
      event_token: formCreator.eventSequencer.nextEventToken || '',
      events: eventsForSending,
    });

    switch (result.generalStatus) {
      case ApiGeneralStatusEnum.SUCCESS:
        // save token,
        yield put(sendSuccess(result.data, new Date()));
        break;

      case ApiGeneralStatusEnum.ERROR: {
        const { status, msg } = result;
        yield put(sendError({ status, msg }));
        formCreator = yield getFormCreatorState();
        if (isConflictDialogVisible(formCreator.eventSequencer)) {
          yield put(showModal(FORM_CREATOR_CONFLICT_MODAL_ID));
        }
        if (isOtherError(formCreator.eventSequencer)) {
          const errorMsg = getErrorMessage(status, actionType, msg);
          yield put(flash(errorMsg, 'error'));
        }
        break;
      }
    }

    // get updated local state to asses further sending
    formCreator = yield getFormCreatorState();
  }

  // sets isWaitingForResponse to false
  yield put(sendingFinished());
}

function* handleEvent<Action extends string>(
  action: EventSequencerActionBaseType<Action>,
) {
  const { payload, type } = action;

  const state: StateType = yield select(s => s);

  const { events, selector } = isCallable(payload) ? payload(state) : payload;

  const newEvents = isCallable(events) ? events(state) : events;

  // this will save new waiting events into the queue and apply selector
  yield put(enqueueAndApplyEvents(newEvents, selector(state)));

  yield call(maybeSendEventsSaga, type);
}

export function* watchEventSaga() {
  yield takeEvery((action: ActionType) => {
    return new RegExp('/EVENTS/').test(action.type);
  }, handleEvent);
}
