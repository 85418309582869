import styled, { css } from 'styled-components';
import { Section } from './PdfReport.styles';
import { ColumnKeyType } from './FindingsTable';

export const Row = styled.div`
  @media print {
    break-inside: avoid;
  }

  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.atlas.grey['100']};
  }
`;

export const Cell = styled(Section)`
  padding: 15px 17px;
`;

export const Badge = styled.div<{ column: ColumnKeyType; value: any }>`
  width: 100%;
  height: 30px;
  border-radius: 4px;
  line-height: 30px;
  text-align: center;
  ${({ theme, column, value }) =>
    theme.findings[column] && theme.findings[column][value]
      ? css`
          color: ${theme.basic.colors.light};
          background: ${theme.findings[column][value]};
        `
      : null};
  ${({ theme }) => theme.fonts.bold.small}
`;
