/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    ResponseFillingHistory,
    ResponseFillingHistoryFromJSON,
    ResponseFillingHistoryFromJSONTyped,
    ResponseFillingHistoryToJSON,
    ResponseTypeDefinition,
    ResponseTypeDefinitionFromJSON,
    ResponseTypeDefinitionFromJSONTyped,
    ResponseTypeDefinitionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResponseFilling
 */
export interface ResponseFilling {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof ResponseFilling
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof ResponseFilling
     */
    updatedAt: Date;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof ResponseFilling
     */
    attachments: Array<Attachment>;
    /**
     * 
     * @type {ResponseTypeDefinition}
     * @memberof ResponseFilling
     */
    definition: ResponseTypeDefinition;
    /**
     * 
     * @type {string}
     * @memberof ResponseFilling
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseFilling
     */
    isFilled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseFilling
     */
    isRequired: boolean;
    /**
     * 
     * @type {ResponseFillingHistory}
     * @memberof ResponseFilling
     */
    mappingHistory?: ResponseFillingHistory;
    /**
     * 
     * @type {number}
     * @memberof ResponseFilling
     */
    pos: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseFilling
     */
    value: string;
}

export function ResponseFillingFromJSON(json: any): ResponseFilling {
    return ResponseFillingFromJSONTyped(json, false);
}

export function ResponseFillingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseFilling {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'attachments': ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'definition': ResponseTypeDefinitionFromJSON(json['definition']),
        'id': json['id'],
        'isFilled': json['is_filled'],
        'isRequired': json['is_required'],
        'mappingHistory': !exists(json, 'mapping_history') ? undefined : ResponseFillingHistoryFromJSON(json['mapping_history']),
        'pos': json['pos'],
        'value': json['value'],
    };
}

export function ResponseFillingToJSON(value?: ResponseFilling | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'attachments': ((value.attachments as Array<any>).map(AttachmentToJSON)),
        'definition': ResponseTypeDefinitionToJSON(value.definition),
        'id': value.id,
        'is_filled': value.isFilled,
        'is_required': value.isRequired,
        'mapping_history': ResponseFillingHistoryToJSON(value.mappingHistory),
        'pos': value.pos,
        'value': value.value,
    };
}


