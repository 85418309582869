import * as ra from '../store/requests/actions';
import urlize from '../utils/url/urlize';
import axios from 'axios';
import withUrl from '../utils/url/withUrl';
import {
  FormsDeleteFindingCallParamsType,
  FormsExportFindingsToPdfCallParamsType,
  FormsModifyRequestCallParamsType,
  FormsGetRequestCallParamsType,
  FormsDownloadFindingsPdfCallParamsType,
} from '../store/forms/actions';
import { RequestsCreate, RequestsCreateToJSON } from '../generated/new';

export default {
  findings: {
    startExportToPdf: ({
      request_id,
      // prettier-ignore
      /* tslint:disable */
      ...data
    }: /* tslint:enable */
    FormsExportFindingsToPdfCallParamsType) =>
      axios.post(
        withUrl(`requests/${request_id}/findings/downloads/pdf_export`),
        data,
      ),
    checkExportToPdfStatus: ({
      request_id,
      export_uuid,
    }: FormsDownloadFindingsPdfCallParamsType) =>
      axios.get(
        withUrl(
          `requests/${request_id}/findings/downloads/pdf_export/${export_uuid}/check`,
        ),
      ),
    delete: ({ requestId, findingId }: FormsDeleteFindingCallParamsType) =>
      axios.delete(withUrl(`requests/${requestId}/findings/${findingId}`)),
  },
  insights: {
    startExportToPdf: (data: ra.RequestsExportInsightsToPdfCallParamsType) =>
      axios.post(withUrl('requests/downloads/pdf_export'), data),
    checkExportToPdfStatus: ({
      export_uuid,
    }: ra.RequestsDownloadInsightsPdfCallParamsType) =>
      axios.get(withUrl(`requests/downloads/pdf_export/${export_uuid}/check`)),
  },
  getRequest: ({ id }: FormsGetRequestCallParamsType) =>
    axios.get(withUrl('requests', id)),
  getRequests: (params: ra.GetRequestsCallParamsType) =>
    axios.get(withUrl(`requests?${urlize(params)}`)),
  modify: ({ id, data }: FormsModifyRequestCallParamsType) =>
    axios.put(withUrl('requests', id), data),
  modifyList: (data: ra.RequestModifyListCallParamsType) =>
    axios.put(withUrl('requests'), data),
  send: (data: RequestsCreate) =>
    axios.post(withUrl('requests'), RequestsCreateToJSON(data)),
  stats: (params: ra.GetRequestsCallParamsType) =>
    axios.get(withUrl(`requests/stats?${urlize(params)}`)),
  scheduler: {
    deleteNextRequest: ({ id }: ra.RequestDeleteScheduleCallParamsType) =>
      axios.post(withUrl(`scheduled_requests/${id}/actions/skip_next`)),
    deleteRequest: ({ id }: ra.RequestDeleteScheduleCallParamsType) =>
      axios.delete(withUrl(`scheduled_requests/${id}`)),
    getRequests: () => axios.get(withUrl('scheduled_requests')),
  },
};
