import styled from 'styled-components';

export const MainWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.upsell.separator.border}
  border-bottom: 1px solid ${({ theme }) => theme.upsell.separator.border}
  margin: 26px 0;
  padding: 33px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProductText = styled.div`
  ${({ theme }) => theme.fonts.semibold.normal}
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
  flex: 2;
`;

type ColumnWrapperType = {
  minWidth?: number;
};

export const ColumnWrapper = styled.div<ColumnWrapperType>`
  display: flex;
  flex-direction: column;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '100px')};
  text-align: right;
`;

export const TitleColumnWrapper = styled(ColumnWrapper)`
  min-width: 180px;
`;

export const QuantityColumnWrapper = styled(ColumnWrapper)`
  min-width: 280px;
  display: flex;
  align-items: flex-end;
`;

export const ColumnSecondaryText = styled.span`
  ${({ theme }) => theme.fonts.regular.small}
`;

export const ColumnSaveText = styled(ColumnSecondaryText)`
  color: ${({ theme }) => theme.atlas.grade.ssc.a};
`;

export const ColumnCrossedText = styled(ColumnSecondaryText)`
  text-decoration: line-through;
  color: ${({ theme }) => theme.basic.colors.grayLight};
`;

export const ColumnTitle = styled(ColumnSecondaryText)`
  ${({ theme }) => theme.fonts.regular.normal}
  margin-bottom: 13px;
`;

export const ColumnPrimaryText = styled.span`
  color: ${({ theme }) => theme.atlas.grade.ssc.a};
  ${({ theme }) => theme.fonts.regular.normal}
`;

export const ColumnTotalText = styled.span`
  ${({ theme }) => theme.fonts.regular.normal}
`;

export const TextInput = styled.input`
  width: 121px;
  height: 50px;
  border-radius: 3px;
  ${({ theme }) => theme.fonts.regular.normal}
  background-color: ${({ theme }) =>
    theme.upsell.quantityInput.default.background};
  border: 2px solid ${({ theme }) => theme.upsell.quantityInput.default.border};
  padding: 14px;
  text-align: right;

  &:focus {
    background-color: ${({ theme }) =>
      theme.upsell.quantityInput.focus.background};
    border: 2px solid ${({ theme }) => theme.upsell.quantityInput.focus.border};
  }
`;

export const ContentRow = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
