import { ApiCallActionType } from '../../store.types';
import { call, put } from 'redux-saga/effects';
import { getAssigneeKey, isAssigneeKeyEmail } from '../utils';
import { getAssignees, getFormWithoutReset } from '.';
import { trackEvent } from 'utils/analytics';
import { startLoading } from '../../app/actions';
import { IdOrEmailType, Uuid } from '../../../common/common.types';

export interface FormsAssignFormDelegationCallParamsType {
  formId: Uuid;
  params: {
    assignee_id?: Uuid;
    assignee_email?: string;
  };
}

export type FormsAssignFormDelegationFunctionType = (
  formId: Uuid,
  assignee: IdOrEmailType,
) => void;

export type FormsAssignFormDelegationActionType = ApiCallActionType<
  '@forms/API/ASSIGN_FORM_DELEGATION',
  FormsAssignFormDelegationCallParamsType
>;

export const assignFormDelegation = (
  formId: Uuid,
  assignee: IdOrEmailType,
): FormsAssignFormDelegationActionType => ({
  type: '@forms/API/ASSIGN_FORM_DELEGATION',
  payload: {
    callParams: {
      formId,
      params: {
        [getAssigneeKey(assignee)]: assignee.payload,
      },
    },
    endpointPath: ['forms', 'delegations', 'form', 'put'],
    selector: null,
    preActions: [put(startLoading(isAssigneeKeyEmail(assignee) ? 3 : 2))],
    postActions: [
      () => (isAssigneeKeyEmail(assignee) ? put(getAssignees(formId)) : null),
      () => put(getFormWithoutReset(formId)),
      () => call(() => trackEvent('Delegation', 'Add - Form')),
    ],
  },
});
