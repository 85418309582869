import ScrolledToBottom from '../../../common/ScrolledToBottom';
import styled, { css } from 'styled-components';
import { Button } from '../../Filter/FilterBar.styles';
import { alwaysShowScrollbar } from 'common/common.styles';

const scrollable = css`
  padding: 0 10px;
  min-height: 100px;
  max-height: 100%;
  flex: 1;
  overflow: hidden;
`;

interface HasContentType {
  hasContent: boolean;
}

export const ScrollableBottom = styled(ScrolledToBottom)<HasContentType>`
  ${scrollable}
  overflow-y: ${({ hasContent }) => (hasContent ? 'scroll' : 'hidden')};
  ${({ hasContent }) => hasContent && alwaysShowScrollbar}
  margin-bottom: 10px;
`;

export const Scrollable = styled.div<HasContentType>`
  ${scrollable}
  overflow-y: ${({ hasContent }) => (hasContent ? 'scroll' : 'hidden')};
  ${({ hasContent }) => hasContent && alwaysShowScrollbar}
`;

export const InnerFlex = styled.div`
  display: flex;
  flex-direction: column-reverse;
  min-height: 0;
`;

export const Bottom = styled.div`
  padding-right: 10px;
`;

export const BottomCentered = styled.div`
  text-align: center;

  p {
    margin: 8px 0 10px;
    color: ${({ theme }) => theme.atlas.grey[500]};
    ${({ theme }) => theme.fonts.light.small}
  }

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.atlas.teal.primary};
    ${({ theme }) => theme.fonts.semibold.normal}
  }
`;

export const Textarea = styled.textarea`
  ${({ theme }) => theme.fonts.regular.small}
  width: 78%;
  height: 40px;
  padding: 10px;
  float: left;
  margin-right: 2%;
  resize: none;
`;

export const Submit = styled(Button)`
  float: right;
  width: 20%;
  height: 40px;
`;
