import React, { useCallback } from 'react';
import { RequestsSetFilterFunctionType } from 'store/requests/actions';
import { Wrapper } from './Filters.styles';
import { assocPath, reject } from 'ramda';
import {
  defaultFilter,
  FilterByType,
  FilterLookForType,
  FilterOptionsType,
  FilterType,
  isDateFilter,
  isFilterEnabled,
  isLookForFilter,
} from 'utils/filters/requests.filters';
import FiltersDate from './Filters.Date';
import FiltersDefault from './Filters.Default';
import FiltersLookFor from './Filters.LookFor';
import FiltersStatus from './Filters.Status';
import { TextButton } from 'common/common.styles';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';

interface FiltersPropsType extends TranslatePropsType {
  allowedFilters: FilterByType[];
  filterBy: FilterType;
  filterOptions: FilterOptionsType;
  isClearable?: boolean;
  isGrouped?: boolean;
  isSent: boolean;
  setFilter: RequestsSetFilterFunctionType;
}

const filtersComponent = {
  DATE: FiltersDate,
  DEFAULT: FiltersDefault,
  LOOKFOR: FiltersLookFor,
  STATUS: FiltersStatus,
};

const Filters = ({
  allowedFilters,
  filterBy,
  filterOptions,
  isClearable = false,
  isGrouped = true,
  setFilter,
  t,
}: FiltersPropsType) => {
  const options = allowedFilters.filter(option =>
    isFilterEnabled(filterBy[option], option),
  );

  const clearFilters = useCallback(() => setFilter(defaultFilter), [setFilter]);

  const handleDelete = (option: FilterByType, itemLookFor?: string) => {
    let filterValue = {};
    if (isLookForFilter(filterBy[option])) {
      const lookFor = itemLookFor
        ? reject(
            val => val === itemLookFor,
            (filterBy[option] as FilterLookForType).lookFor,
          )
        : [];
      filterValue = { isEnabled: lookFor.length > 0, lookFor };
    } else if (isDateFilter(filterBy[option])) {
      filterValue = { isEnabled: false, from: undefined, to: undefined };
    }
    setFilter(assocPath([option], filterValue, filterBy));
  };

  return (
    <Wrapper>
      {options.map((option: FilterByType) => {
        const Component = isGrouped
          ? filtersComponent.DEFAULT
          : filtersComponent[option] || filtersComponent.LOOKFOR;

        return (
          <Component
            key={`chip-${option}`}
            filterBy={filterBy[option]}
            filterOptions={filterOptions[option]!}
            handleDelete={handleDelete}
            option={option}
          />
        );
      })}
      {isClearable && (
        <TextButton color="teal" onClick={clearFilters} size="small">
          {t('Requests.clearAllFilters') as string}
        </TextButton>
      )}
    </Wrapper>
  );
};

export default withTranslation('components')(Filters);
