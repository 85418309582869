/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    Standard,
    StandardFromJSON,
    StandardFromJSONTyped,
    StandardToJSON,
} from './';

/**
 * 
 * @export
 * @interface RequestMeta
 */
export interface RequestMeta {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof RequestMeta
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof RequestMeta
     */
    updatedAt: Date;
    /**
     * The day when the VRM accepted the request. This will be set internally and         only when the VRM status changes from in progress to accepted
     * @type {Date}
     * @memberof RequestMeta
     */
    acceptedDate?: Date;
    /**
     * User can view form data
     * @type {boolean}
     * @memberof RequestMeta
     */
    canViewData: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestMeta
     */
    code: string;
    /**
     * 
     * @type {Date}
     * @memberof RequestMeta
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RequestMeta
     */
    formId: string;
    /**
     * 
     * @type {string}
     * @memberof RequestMeta
     */
    formName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestMeta
     */
    formRoles: Array<RequestMetaFormRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof RequestMeta
     */
    historyCount: number;
    /**
     * 
     * @type {string}
     * @memberof RequestMeta
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof RequestMeta
     */
    isInternal: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestMeta
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof RequestMeta
     */
    revisionCount: number;
    /**
     * 
     * @type {string}
     * @memberof RequestMeta
     */
    riskNote: string;
    /**
     * 
     * @type {Standard}
     * @memberof RequestMeta
     */
    standard: Standard;
    /**
     * Current request status
     * @type {string}
     * @memberof RequestMeta
     */
    status: RequestMetaStatusEnum;
    /**
     * Status of the request after it will be unarchived
     * @type {string}
     * @memberof RequestMeta
     */
    statusBeforeArchived: RequestMetaStatusBeforeArchivedEnum;
    /**
     * When the request was first opened by target (vendor).
     * @type {Date}
     * @memberof RequestMeta
     */
    targetOpenedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof RequestMeta
     */
    wasScheduled: boolean;
}

export function RequestMetaFromJSON(json: any): RequestMeta {
    return RequestMetaFromJSONTyped(json, false);
}

export function RequestMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'acceptedDate': !exists(json, 'accepted_date') ? undefined : (new Date(json['accepted_date'])),
        'canViewData': json['can_view_data'],
        'code': json['code'],
        'dueDate': !exists(json, 'due_date') ? undefined : (new Date(json['due_date'])),
        'formId': json['form_id'],
        'formName': json['form_name'],
        'formRoles': json['form_roles'],
        'historyCount': json['history_count'],
        'id': json['id'],
        'isInternal': json['is_internal'],
        'message': json['message'],
        'revisionCount': json['revision_count'],
        'riskNote': json['risk_note'],
        'standard': StandardFromJSON(json['standard']),
        'status': json['status'],
        'statusBeforeArchived': json['status_before_archived'],
        'targetOpenedAt': !exists(json, 'target_opened_at') ? undefined : (new Date(json['target_opened_at'])),
        'wasScheduled': json['was_scheduled'],
    };
}

export function RequestMetaToJSON(value?: RequestMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'accepted_date': value.acceptedDate === undefined ? undefined : (value.acceptedDate.toISOString()),
        'can_view_data': value.canViewData,
        'code': value.code,
        'due_date': value.dueDate === undefined ? undefined : (value.dueDate.toISOString()),
        'form_id': value.formId,
        'form_name': value.formName,
        'form_roles': value.formRoles,
        'history_count': value.historyCount,
        'id': value.id,
        'is_internal': value.isInternal,
        'message': value.message,
        'revision_count': value.revisionCount,
        'risk_note': value.riskNote,
        'standard': StandardToJSON(value.standard),
        'status': value.status,
        'status_before_archived': value.statusBeforeArchived,
        'target_opened_at': value.targetOpenedAt === undefined ? undefined : (value.targetOpenedAt.toISOString()),
        'was_scheduled': value.wasScheduled,
    };
}

/**
* @export
* @enum {string}
*/
export enum RequestMetaFormRolesEnum {
    OWNER = 'OWNER',
    VENDOR = 'VENDOR',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY',
    OTHER = 'OTHER'
}
/**
* @export
* @enum {string}
*/
export enum RequestMetaStatusEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}
/**
* @export
* @enum {string}
*/
export enum RequestMetaStatusBeforeArchivedEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}


