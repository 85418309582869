/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    FormShareBase,
    FormShareBaseFromJSON,
    FormShareBaseFromJSONTyped,
    FormShareBaseToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormShareMetadata
 */
export interface FormShareMetadata {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof FormShareMetadata
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof FormShareMetadata
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof FormShareMetadata
     */
    code: string;
    /**
     * When will the share expire
     * @type {Date}
     * @memberof FormShareMetadata
     */
    expirationDate: Date;
    /**
     * 
     * @type {string}
     * @memberof FormShareMetadata
     */
    formId: string;
    /**
     * 
     * @type {string}
     * @memberof FormShareMetadata
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FormShareMetadata
     */
    ownerId: string;
    /**
     * ID of the user that was sharing
     * @type {string}
     * @memberof FormShareMetadata
     */
    sharedById: string;
    /**
     * 
     * @type {number}
     * @memberof FormShareMetadata
     */
    status: number;
    /**
     * 
     * @type {Company}
     * @memberof FormShareMetadata
     */
    owner: Company;
    /**
     * 
     * @type {User}
     * @memberof FormShareMetadata
     */
    sharedBy: User;
    /**
     * 
     * @type {Array<User>}
     * @memberof FormShareMetadata
     */
    targetUsers: Array<User>;
}

export function FormShareMetadataFromJSON(json: any): FormShareMetadata {
    return FormShareMetadataFromJSONTyped(json, false);
}

export function FormShareMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormShareMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'code': json['code'],
        'expirationDate': (new Date(json['expiration_date'])),
        'formId': json['form_id'],
        'id': json['id'],
        'ownerId': json['owner_id'],
        'sharedById': json['shared_by_id'],
        'status': json['status'],
        'owner': CompanyFromJSON(json['owner']),
        'sharedBy': UserFromJSON(json['shared_by']),
        'targetUsers': ((json['target_users'] as Array<any>).map(UserFromJSON)),
    };
}

export function FormShareMetadataToJSON(value?: FormShareMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'code': value.code,
        'expiration_date': (value.expirationDate.toISOString()),
        'form_id': value.formId,
        'id': value.id,
        'owner_id': value.ownerId,
        'shared_by_id': value.sharedById,
        'status': value.status,
        'owner': CompanyToJSON(value.owner),
        'shared_by': UserToJSON(value.sharedBy),
        'target_users': ((value.targetUsers as Array<any>).map(UserToJSON)),
    };
}


