import qs from 'qs';
import { ApiCallActionPayloadType } from '../../store.types';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { PdfExportSummary } from '../../../generated/models/PdfExportSummary';
import { downloadInsightsPdf } from './downloadInsightsPdf';
import { put } from 'redux-saga/effects';
import { FilterType } from 'utils/filters/requests.filters';
import { RequestsStateType } from '../requests.types';

export interface RequestsExportInsightsToPdfCallParamsType {
  page_query?: string;
  pdf_params?: string;
}

export type RequestsExportInsightsToPdfFunctionType = (
  filterBy: FilterType,
) => void;

export type RequestsExportInsightsToPdfActionType = OptimisticActionType<
  '@requests/API/EXPORT_INSIGHTS_TO_PDF',
  ApiCallActionPayloadType<
    RequestsExportInsightsToPdfCallParamsType,
    PdfExportSummary,
    never,
    RequestsStateType
  >
>;

export const exportInsightsToPdf = (
  filterBy: FilterType,
): RequestsExportInsightsToPdfActionType => ({
  type: '@requests/API/EXPORT_INSIGHTS_TO_PDF',
  payload: {
    endpointPath: ['requests', 'insights', 'startExportToPdf'],
    callParams: {
      page_query: qs.stringify({ filterBy }),
    },
    expectedStateChange: {
      'requests.insightsExporting': true,
    },
    selector: null,
    postActions: [
      ({ export_uuid }: PdfExportSummary) =>
        put(downloadInsightsPdf(export_uuid)),
    ],
  },
});
