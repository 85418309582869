/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormShortSummary,
    FormShortSummaryFromJSON,
    FormShortSummaryFromJSONTyped,
    FormShortSummaryToJSON,
    QuestionDetailBase,
    QuestionDetailBaseFromJSON,
    QuestionDetailBaseFromJSONTyped,
    QuestionDetailBaseToJSON,
    ResponsePropagationInstruction,
    ResponsePropagationInstructionFromJSON,
    ResponsePropagationInstructionFromJSONTyped,
    ResponsePropagationInstructionToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionMapping
 */
export interface QuestionMapping {
    /**
     * 
     * @type {FormShortSummary}
     * @memberof QuestionMapping
     */
    form: FormShortSummary;
    /**
     * 
     * @type {Array<ResponsePropagationInstruction>}
     * @memberof QuestionMapping
     */
    matches: Array<ResponsePropagationInstruction>;
    /**
     * 
     * @type {QuestionDetailBase}
     * @memberof QuestionMapping
     */
    question: QuestionDetailBase;
    /**
     * 
     * @type {number}
     * @memberof QuestionMapping
     */
    questionCount: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionMapping
     */
    score?: number;
    /**
     * 
     * @type {Date}
     * @memberof QuestionMapping
     */
    updatedAt: Date;
}

export function QuestionMappingFromJSON(json: any): QuestionMapping {
    return QuestionMappingFromJSONTyped(json, false);
}

export function QuestionMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form': FormShortSummaryFromJSON(json['form']),
        'matches': ((json['matches'] as Array<any>).map(ResponsePropagationInstructionFromJSON)),
        'question': QuestionDetailBaseFromJSON(json['question']),
        'questionCount': json['question_count'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'updatedAt': (new Date(json['updated_at'])),
    };
}

export function QuestionMappingToJSON(value?: QuestionMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form': FormShortSummaryToJSON(value.form),
        'matches': ((value.matches as Array<any>).map(ResponsePropagationInstructionToJSON)),
        'question': QuestionDetailBaseToJSON(value.question),
        'question_count': value.questionCount,
        'score': value.score,
        'updated_at': (value.updatedAt.toISOString()),
    };
}


