import List from '../SendRequest/Standards/List';
import React from 'react';
import Upload from '../SendRequest/Standards/Upload';
import { Heading } from './Sales.styles';
import { Marginator } from 'common/common.styles';
import { ParsedFileType, TranslatePropsType, Uuid } from 'common/common.types';
import { StandardStatusBranchStateType } from 'store/standard/reducer';
import { withTranslation } from 'react-i18next';
import {
  StandardsDeleteFunctionType,
  RenameStandardFunctionType,
  ToggleStandardSelectionFunctionType,
} from 'store/standard/actions';

interface YourStandardsPropsType extends TranslatePropsType {
  deleteStandard: StandardsDeleteFunctionType;
  handlePreview: (formId: Uuid) => void;
  hasParsingErrors: boolean;
  parsedFiles: ParsedFileType[];
  renameStandard: RenameStandardFunctionType;
  standards: StandardStatusBranchStateType;
  toggleStandard: ToggleStandardSelectionFunctionType;
  uploadStandard: (formData: FormData) => void;
  uploadingCount: number;
}

const YourStandards = ({
  deleteStandard,
  handlePreview,
  hasParsingErrors,
  parsedFiles,
  renameStandard,
  standards: { all, selected },
  toggleStandard,
  uploadStandard,
  uploadingCount,
  t,
}: YourStandardsPropsType) => (
  <div>
    <Heading>{t('yourStandards') as string}</Heading>

    <List
      deleteStandard={deleteStandard}
      handleToggle={toggleStandard}
      onPreview={handlePreview}
      renameStandard={renameStandard}
      selectedStandards={selected}
      standards={all}
    />

    <Marginator margin={{ top: 50 }}>
      <Heading>{t('uploadNew') as string}</Heading>

      <Upload
        hasParsingErrors={hasParsingErrors}
        isProcessing={uploadingCount > 0}
        onUpload={uploadStandard}
        parsedFiles={parsedFiles}
        showInstructions={false}
      />
    </Marginator>
  </div>
);

export default withTranslation('Sales')(YourStandards);
