import React from 'react';
import { differenceInDays } from 'date-fns';
import { Sub, Wrapper } from './DueDateText.styles';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface DueDateTextPropsType extends TranslatePropsType {
  align?: 'right' | 'left' | 'center';
  dueDate: Date;
}

const DueDateText = ({ align, dueDate, t }: DueDateTextPropsType) => {
  const days = differenceInDays(dueDate, new Date());

  return (
    <Wrapper align={align}>
      <strong>
        {days === 0
          ? (t('common:dueDate.today') as string)
          : (t('common:dueDate.dayWithCount', {
              count: Math.abs(days),
            }) as string)}
      </strong>

      <Sub>
        {days !== 0 &&
          ((days > 0
            ? (t('common:dueDate.left') as string)
            : t('common:dueDate.late')) as string)}
      </Sub>
    </Wrapper>
  );
};

export default withTranslation(['common'])(DueDateText);
