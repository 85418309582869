import React from 'react';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { Button, ButtonsWrapper, Content, Heading } from './Prompt.styles';
import { Backdrop, Closer, CloseIcon } from '../Overlay.styles';

interface PromptPropsType extends TranslatePropsType {
  children: React.ReactNode;
  confirmText: string;
  'data-cy'?: string;
  heading: string;
  isButtonDisabled?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const Prompt = ({
  children,
  confirmText,
  'data-cy': dataCy,
  heading,
  isButtonDisabled,
  onClose,
  onSubmit,
  t,
}: PromptPropsType) => {
  const handleClickOutside = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = () => {
    if (!isButtonDisabled) {
      onSubmit();
      onClose();
    }
  };

  const label = t('labels.cancel');
  return (
    <Backdrop onClick={handleClickOutside}>
      <Content data-cy={dataCy}>
        <Closer onClick={onClose}>
          <CloseIcon />
        </Closer>

        <Heading>{heading}</Heading>

        {children}

        <ButtonsWrapper>
          <Button isPrimary onClick={handleSubmit} disabled={isButtonDisabled}>
            {confirmText}
          </Button>

          <Button onClick={onClose}>{label}</Button>
        </ButtonsWrapper>
      </Content>
    </Backdrop>
  );
};

export default withTranslation('common')(Prompt);
