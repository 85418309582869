/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface DefinitionUpdate
 */
export interface DefinitionUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof DefinitionUpdate
     */
    confirm?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefinitionUpdate
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DefinitionUpdate
     */
    required_questions?: Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof DefinitionUpdate
     */
    responses?: Array<object>;
    /**
     * Status of the standard:
     * 
     * - `SYSTEM` standards are accessible to all companies.
     *     They should be owned by Company with internal_status = SYSTEM.
     * - `CUSTOM` standards are created by individual companies.
     *     Only owner of the standard can send request for this standard.
     * - `TEMPORARY` standard is created when user uploads new form definition.
     *     When it is confirmed by the user it becomes CUSTOM standard.
     *     Standards of this type are not shown anywhere and requests for this type cannot
     *     be sent.
     * - `DEPRECATED` standard is a deleted standard. Standards of this type are not
     *     shown anywhere and requests for this type cannot be sent.
     * - `DRAFT` standard is a standard whose form definition is currently being created
     *     in the form creator. Request from this standard cannot be (yet) sent.
     * @type {string}
     * @memberof DefinitionUpdate
     */
    status?: DefinitionUpdateStatusEnum;
}




/**
* @export
* @enum {string}
*/
export enum DefinitionUpdateStatusEnum {
    CUSTOM = 'CUSTOM',
    DEPRECATED = 'DEPRECATED',
    DRAFT = 'DRAFT',
    SYSTEM = 'SYSTEM',
    TEMPORARY = 'TEMPORARY'
}


