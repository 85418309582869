import React, { ChangeEvent } from 'react';
import { Input } from './Radio.styles';
import { Uuid } from 'common/common.types';

export interface RadioOptionType {
  checked: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  id: Uuid;
  isEditable: boolean;
  isRequired?: boolean;
  option: string;
}

const RadioOption = ({
  checked,
  handleChange,
  id,
  isEditable,
  isRequired,
  option,
}: RadioOptionType) => (
  <Input
    disabled={!isEditable}
    name={`option-${id}`}
    onChange={handleChange}
    required={isRequired || false}
    type="checkbox"
    value={option}
    checked={checked}
  />
);

export default RadioOption;
