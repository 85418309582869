import React from 'react';
import { Control, Icon, Name, Wrapper } from './Controls.styles';
import { withTranslation } from 'react-i18next';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  FormDefinitionControlsStatusType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import {
  DeleteStandardFromFormDefinitionsParamsType,
  DeleteDraftFromFormDefinitionsParamsType,
  DeleteDraftFromFormDefinitionsFunctionType,
} from 'store/formDefinitions/actions';
import { StandardStatusEnum } from 'generated/models/Standard';
import { CountWrapper } from '../FormDefinitionsList.styles';
import { Badge } from '../../common/Badge';
import ReactHtmlParser from 'react-html-parser';
import colors from 'common/common.colors';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type PermittedRequestControlsActions = DeleteDraftFromFormDefinitionsFunctionType;

export interface ControlsPropsType extends TranslatePropsType {
  actions: {
    deleteFromFormDefinitions: (
      status: StandardStatusEnum,
      params:
        | DeleteStandardFromFormDefinitionsParamsType
        | DeleteDraftFromFormDefinitionsParamsType,
    ) => void;
  };
  data: {
    controlsStatus: FormDefinitionControlsStatusType;
    selected: Uuid[];
    draftCount: number;
    selectedCount: number;
  };
}

const Controls = ({
  actions: { deleteFromFormDefinitions },
  data: {
    controlsStatus: { canDelete },
    selected,
    selectedCount,
    draftCount,
  },
  t,
}: ControlsPropsType) => {
  return (
    <Wrapper>
      <Control
        disabled={!canDelete}
        onClick={() =>
          canDelete &&
          deleteFromFormDefinitions(StandardStatusEnum.DRAFT, {
            id: selected,
          })
        }
      >
        <Icon icon={faTrashAlt as IconProp} />

        <Name>{t('delete') as string}</Name>
      </Control>
      <CountWrapper data-test="selected-amount">
        {ReactHtmlParser(t('selectedDraftHTML', { selectedCount }))}

        <Badge color={colors.elements.requests.created.color}>
          {draftCount}
        </Badge>
      </CountWrapper>
    </Wrapper>
  );
};

export default withTranslation(['FormDefinitions', 'SendRequest'])(Controls);
