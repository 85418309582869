import React from 'react';
import { LanguageType, RecurrenceItemType } from 'common/common.types';
import { Label, Description } from './RecurrenceOption.styles';
import { TFunction } from 'i18next';
import { getScheduleRecurrenceText } from './utils';

const RecurrenceOption = (
  sendDate: Date,
  language: LanguageType,
  t: TFunction,
) => ({ label, type, unit }: RecurrenceItemType) => {
  return (
    <div>
      <Label isRecurrent={type !== null || unit !== null}>
        {t(label) as string}
      </Label>

      {type && unit && (
        <Description>
          {getScheduleRecurrenceText(sendDate, unit, type, language, t)}
        </Description>
      )}
    </div>
  );
};

export default RecurrenceOption;
