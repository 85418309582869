import { assoc, intersection } from 'ramda';
import { Uuid } from 'common/common.types';

export type SupportedFilterOptionType =
  | 'attachments'
  | 'attachments_new'
  | 'comments'
  | 'comments_new'
  | 'filled'
  | 'na'
  | 'newly_autocompleted'
  | 'no'
  | 'not_filled'
  | 'responses_new'
  | 'required'
  | 'ssc_data'
  | 'ssc_data_new'
  | 'vrm_review_flagged'
  | 'vrm_review_pending'
  | 'yes'
  | 'unassigned'
  | 'vrm_review_done'
  | 'autocomplete_done'
  | 'autocomplete_suggestions_available';

export const SUPPORTED_FILTERS: SupportedFilterOptionType[] = [
  'attachments',
  'attachments_new',
  'comments',
  'comments_new',
  'filled',
  'na',
  'newly_autocompleted',
  'no',
  'not_filled',
  'responses_new',
  'required',
  'ssc_data',
  'ssc_data_new',
  'unassigned',
  'vrm_review_done',
  'vrm_review_flagged',
  'vrm_review_pending',
  'yes',
  'autocomplete_done',
  'autocomplete_suggestions_available',
];

export type SupportedFilterBranchType = 'assignees' | 'simple';

export interface FormsFilterItemType {
  count: number;
  isEnabled: boolean;
}

export type FormsAddRemoveFunctionType = (
  branch: SupportedFilterBranchType,
  filterName: string | number,
  formId?: Uuid,
  ids?: Uuid[],
) => void;

export interface FormsEnableFiltersItemType {
  branch: SupportedFilterBranchType;
  filterName: string;
}

export type FormsSimpleFiltersType = {
  [key in SupportedFilterOptionType]: FormsFilterItemType;
};

export type FormsAssigneesFilterType = {
  count: number;
  label: string;
  id: Uuid;
  isEnabled: boolean;
};

export type FormsAssigneesFilterDict = {
  [key: number]: FormsAssigneesFilterType;
};

// @ts-ignore
export const FormsFiltersInitialState: FormsSimpleFiltersType = SUPPORTED_FILTERS.reduce(
  (acc, filterName) => assoc(filterName, { count: 0, isEnabled: false }, acc),
  {},
);

export const validateFilters = (filters: SupportedFilterOptionType[]) => {
  if (filters && Array.isArray(filters) && filters.length > 0) {
    return intersection(filters, SUPPORTED_FILTERS);
  } else {
    return undefined;
  }
};
