import React from 'react';
import { Attachment } from './FormAttachments.styles';
import { AttachmentsListWrapper } from './AttachmentsList.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Remove } from '../Question/Attachments/Attachment.styles';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { trackEvent } from 'utils/analytics';
import { values } from 'ramda';
import { withTranslation } from 'react-i18next';
import { AttachmentsDict, TranslatePropsType, Uuid } from 'common/common.types';
import getEvidenceURL from 'utils/getEvidenceUrl';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface AttachmentsListPropsType extends TranslatePropsType {
  attachments: AttachmentsDict;
  deleteAttachment?: (id: Uuid) => void;
  isVRM: boolean;
}

const AttachmentsList = ({
  attachments,
  deleteAttachment,
  isVRM,
  t,
}: AttachmentsListPropsType) => (
  <AttachmentsListWrapper isInline={!isVRM}>
    {values(attachments).map(({ id, displayName, name }) => (
      <Attachment key={`form-attachment-${id}`} strong={!isVRM} inline={!isVRM}>
        <a
          data-test="download-attachment"
          href={`${getEvidenceURL(id)}`}
          onClick={() => trackEvent(t('event.key'), t('event.action'))}
          title={name}
        >
          {displayName}
        </a>

        {deleteAttachment && (
          <Remove
            data-test="remove-attachment"
            onClick={() => deleteAttachment(id)}
          >
            <FontAwesomeIcon icon={faTimes as IconProp} />
          </Remove>
        )}
      </Attachment>
    ))}
  </AttachmentsListWrapper>
);

export default withTranslation('FormAttachments')(AttachmentsList);
