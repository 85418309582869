import { ApiCallActionType } from '../../store.types';
import { Uuid } from '../../../common/common.types';
import { PdfExportCheck } from '../../../generated/models/PdfExportCheck';
import update from 'immutability-helper';
import { call, put } from 'redux-saga/effects';
import delay from 'utils/delayPromise';
import withUrl from 'utils/url/withUrl';
import { RequestsStateType } from '../requests.types';

export interface RequestsDownloadInsightsPdfCallParamsType {
  export_uuid: Uuid;
}

export type RequestsDownloadInsightsPdfActionType = ApiCallActionType<
  '@requests/API/DOWNLOAD_INSIGHTS_PDF',
  RequestsDownloadInsightsPdfCallParamsType,
  PdfExportCheck,
  never,
  RequestsStateType
>;

/**
 * Action for monitoring the progress of asynchronous report PDF generating on server.
 *
 * Do not call the action directly, use exportInsightsToPdf.
 *
 * The action work only in co-operation with exportInsightsToPdf.
 */
export const downloadInsightsPdf = (
  exportUuid: Uuid,
): RequestsDownloadInsightsPdfActionType => ({
  type: '@requests/API/DOWNLOAD_INSIGHTS_PDF',
  payload: {
    endpointPath: ['requests', 'insights', 'checkExportToPdfStatus'],
    callParams: { export_uuid: exportUuid },
    selector: (
      { export_finished },
      { requests },
    ): Partial<RequestsStateType> => {
      if (!export_finished) {
        // Not interested in the results anymore
        return requests;
      }
      return update(requests, {
        insightsExporting: { $set: false },
      });
    },
    postActions: ({ export_finished }: PdfExportCheck, { requests }) => {
      if (export_finished) {
        window.location.href = withUrl(
          `requests/downloads/pdf_export/${exportUuid}`,
        );
        return [];
      }
      return [
        () => call(delay, 2000),
        () => put(downloadInsightsPdf(exportUuid)),
      ];
    },
  },
});
