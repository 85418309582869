/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnrichedMappingResult,
    EnrichedMappingResultFromJSON,
    EnrichedMappingResultFromJSONTyped,
    EnrichedMappingResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnrichedMappingResultWithDepth
 */
export interface EnrichedMappingResultWithDepth {
    /**
     * 
     * @type {number}
     * @memberof EnrichedMappingResultWithDepth
     */
    depth: number;
    /**
     * 
     * @type {EnrichedMappingResult}
     * @memberof EnrichedMappingResultWithDepth
     */
    result: EnrichedMappingResult;
}

export function EnrichedMappingResultWithDepthFromJSON(json: any): EnrichedMappingResultWithDepth {
    return EnrichedMappingResultWithDepthFromJSONTyped(json, false);
}

export function EnrichedMappingResultWithDepthFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnrichedMappingResultWithDepth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'depth': json['depth'],
        'result': EnrichedMappingResultFromJSON(json['result']),
    };
}

export function EnrichedMappingResultWithDepthToJSON(value?: EnrichedMappingResultWithDepth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'depth': value.depth,
        'result': EnrichedMappingResultToJSON(value.result),
    };
}


