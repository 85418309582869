import styled from 'styled-components';

export const Scroller = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 90vw;
  max-width: 970px;
  max-height: 550px;
  padding-left: 32px;
`;

export const Footer = styled.div`
  width: 100%;
  height: 77px;
  border: 1px solid ${({ theme }) => theme.basic.colors.darkerBorder};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const StandardsSelectedText = styled.div`
  color: ${({ theme }) => theme.basic.colors.extraDark};
  ${({ theme }) => theme.fonts.regular.small}
  position: absolute;
  top: 30px;
  left: 42px;
`;
