import { AutocompleteExecution } from '../../../generated/new';

export interface FormsAutocompleteProgressUpdatedActionType {
  type: '@forms/AUTOCOMPLETE_PROGRESS_UPDATED';
  payload: AutocompleteExecution;
}

export const autocompleteProgressUpdated = (
  payload: AutocompleteExecution,
): FormsAutocompleteProgressUpdatedActionType => ({
  type: '@forms/AUTOCOMPLETE_PROGRESS_UPDATED',
  payload,
});
