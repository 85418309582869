/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface IssueType
 */
export interface IssueType {
    /**
     * 
     * @type {string}
     * @memberof IssueType
     */
    factor: string;
    /**
     * 
     * @type {string}
     * @memberof IssueType
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof IssueType
     */
    severity: IssueTypeSeverityEnum;
    /**
     * 
     * @type {string}
     * @memberof IssueType
     */
    short_description: string;
    /**
     * 
     * @type {string}
     * @memberof IssueType
     */
    title: string;
}




/**
* @export
* @enum {string}
*/
export enum IssueTypeSeverityEnum {
    High = 'high',
    Info = 'info',
    Low = 'low',
    Medium = 'medium',
    Positive = 'positive'
}


