import Hexagon from '../../common/Hexagon';
import React from 'react';
import colors from 'common/common.colors';
import { CompaniesFilterFunctionsType } from '../Companies';
import { CompanyGradeEnum } from 'generated/models/Company';
import { OpacitySetter } from 'common/common.styles';
import { TranslatePropsType } from 'common/common.types';
import { X_VALUES_POSITION } from './Visualisation';
import { withTranslation } from 'react-i18next';
import {
  BigText,
  HexagonPositioner,
  HexagonsWrapper,
  Result,
  ResultNumber,
} from './Visualisation.styles';
import {
  CompaniesFilterType,
  VisualisationByGradeDataType,
} from 'store/companies/companies.reducer';

const getHexOpacity = (
  selectionTag: string | undefined,
  selectionGrade: CompanyGradeEnum | undefined,
  grade: CompanyGradeEnum,
  legend: string,
  hover: boolean,
) => {
  if (!selectionTag || !selectionGrade) {
    return hover ? 0 : 1;
  }

  if (selectionTag === legend && selectionGrade === grade) {
    return hover ? 0 : 1;
  }

  return hover ? 1 : 0;
};

interface VisualisationHexagonType extends TranslatePropsType {
  byGrade: VisualisationByGradeDataType;
  filter: CompaniesFilterType;
  filterFunctions: CompaniesFilterFunctionsType;
  grade: CompanyGradeEnum;
  legend: string;
  y: number;
}

const VisualisationHexagon = ({
  byGrade,
  filter,
  filterFunctions: { setSelection },
  grade,
  legend,
  t,
  y,
}: VisualisationHexagonType) => {
  const bigTextLabel = t(
    `${byGrade[grade].qtyAbs === 1 ? 'singular' : 'plural'}.company`,
  );

  return (
    <HexagonsWrapper>
      <HexagonPositioner
        xy={{ x: X_VALUES_POSITION[grade], y: y }}
        size={byGrade[grade].qtyRel < 14 ? 24 : byGrade[grade].qtyRel + 4}
      >
        <Hexagon
          color={colors.elements.companiesVisualisation.background}
          size={byGrade[grade].qtyRel < 14 ? 24 : byGrade[grade].qtyRel + 4}
          strokeWidth={Math.sqrt(byGrade[grade].qtyRel * 0.06)}
          strokeColor={colors.elements.companiesVisualisation.background}
        />
      </HexagonPositioner>

      <OpacitySetter
        opacity={getHexOpacity(
          filter.selection.tagName,
          filter.selection.grade,
          grade,
          legend,
          true,
        )}
      >
        <HexagonPositioner
          xy={{ x: X_VALUES_POSITION[grade], y: y }}
          size={byGrade[grade].qtyRel < 14 ? 14 : byGrade[grade].qtyRel}
        >
          <Hexagon
            color={colors.elements.companiesVisualisation.background}
            size={byGrade[grade].qtyRel < 14 ? 14 : byGrade[grade].qtyRel}
            strokeWidth={Math.sqrt(byGrade[grade].qtyRel * 0.06)}
            strokeColor={colors.elements.companiesVisualisation.inactiveBorder}
          />
        </HexagonPositioner>
      </OpacitySetter>

      <OpacitySetter
        opacity={getHexOpacity(
          filter.selection.tagName,
          filter.selection.grade,
          grade,
          legend,
          false,
        )}
      >
        <HexagonPositioner
          onClick={(e: any) => {
            setSelection(legend, grade);
            e.stopPropagation();
          }}
          xy={{ x: X_VALUES_POSITION[grade], y: y }}
          size={byGrade[grade].qtyRel < 14 ? 14 : byGrade[grade].qtyRel}
        >
          <Hexagon
            color={colors.grades[grade]}
            size={byGrade[grade].qtyRel < 14 ? 14 : byGrade[grade].qtyRel}
            strokeWidth={Math.sqrt(byGrade[grade].qtyRel * 0.06)}
            strokeColor={'#fff'}
          />

          <Result
            data-test="companiesNumberGrade"
            className={'onHover'}
            x={X_VALUES_POSITION[grade]}
            size={byGrade[grade].qtyRel < 14 ? 14 : byGrade[grade].qtyRel}
          >
            <ResultNumber>{byGrade[grade].qtyAbs}</ResultNumber>

            <br />

            <BigText>{bigTextLabel}</BigText>
          </Result>
        </HexagonPositioner>
      </OpacitySetter>
    </HexagonsWrapper>
  );
};

export default withTranslation('common')(VisualisationHexagon);
