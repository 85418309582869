import { Uuid } from '../../../common/common.types';

export interface ToggleStandardSelectionParamsType {
  id: Uuid;
}

export type ToggleStandardSelectionFunctionType = (
  params: ToggleStandardSelectionParamsType,
) => void;

export interface ToggleStandardSelectionActionType {
  type: '@standard/TOGGLE_SELECTION';
  payload: ToggleStandardSelectionParamsType;
}

export const toggleStandardSelection = (
  params: ToggleStandardSelectionParamsType,
) => ({
  type: '@standard/TOGGLE_SELECTION',
  payload: params,
});
