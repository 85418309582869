import {
  Name,
  Option,
  Separator,
  State,
  StateIcon,
} from '../Requests/SortBy.styles';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from '../common/Dropdown';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownState } from '../common/Dropdown/Dropdown';
import { Team } from 'generated/new/models';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const SEPARATOR = null;

interface SelectTeamPropsType {
  companyName: string;
  selectTeam: (team?: Team) => void;
  selectedTeam?: Team;
  teams: Team[];
}

const SelectTeam = ({
  companyName,
  selectTeam,
  selectedTeam,
  teams,
}: SelectTeamPropsType) => {
  const [t] = useTranslation('EvidenceLocker');
  const extendedTeams = useMemo(() => [undefined, SEPARATOR, ...teams], [
    teams,
  ]);
  const dropdownText = selectedTeam
    ? selectedTeam.name
    : (t('selectTeam') as string);

  const faCheckProp = faCheck as IconProp;

  return (
    <Dropdown color="transparent" size="medium" text={dropdownText}>
      {({ close }: DropdownState) => (
        <>
          {extendedTeams.map((option, index) =>
            option === SEPARATOR ? (
              <Separator key={`selectTeam-separator-${index}`} />
            ) : (
              <Option
                key={`selectTeam-menu-${option ? option.id : 'all'}`}
                isSelected={selectedTeam === option}
                onClick={() => {
                  selectTeam(option);
                  close();
                }}
              >
                <State>
                  {selectedTeam === option && <StateIcon icon={faCheckProp} />}
                </State>
                <Name>
                  {option
                    ? option.name
                    : (t('allTeams', { companyName }) as string)}
                </Name>
              </Option>
            ),
          )}
        </>
      )}
    </Dropdown>
  );
};

export default SelectTeam;
