/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UUIDConvResult
 */
export interface UUIDConvResult {
    /**
     * 
     * @type {string}
     * @memberof UUIDConvResult
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof UUIDConvResult
     */
    env: UUIDConvResultEnvEnum;
    /**
     * 
     * @type {number}
     * @memberof UUIDConvResult
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UUIDConvResult
     */
    uuid: string;
}

export function UUIDConvResultFromJSON(json: any): UUIDConvResult {
    return UUIDConvResultFromJSONTyped(json, false);
}

export function UUIDConvResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): UUIDConvResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'env': json['env'],
        'id': json['id'],
        'uuid': json['uuid'],
    };
}

export function UUIDConvResultToJSON(value?: UUIDConvResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'env': value.env,
        'id': value.id,
        'uuid': value.uuid,
    };
}

/**
* @export
* @enum {string}
*/
export enum UUIDConvResultEnvEnum {
    Demo = 'demo',
    Dev = 'dev',
    InitializationTest = 'initialization-test',
    Jenkins = 'jenkins',
    Local = 'local',
    Prod = 'prod',
    Production = 'production',
    Qa = 'qa',
    Test = 'test'
}


