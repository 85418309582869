import Insights from '../../src/components/Insights/Insights';
import Page, {
  PageActionsBaseType,
  PageBaseType,
  PagePropsType,
  UpdateQueryType,
} from './Page';
import React from 'react';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import {
  CompanyType,
  LanguageType,
  UserStatsType,
  Uuid,
} from 'common/common.types';
import {
  RequestsInOutType,
  RequestsStatsType,
} from 'store/requests/requests.types';
import {
  exportInsightsToPdf,
  getRequests,
  getStats,
  RequestsExportInsightsToPdfFunctionType,
  RequestsSetFilterFunctionType,
} from 'store/requests/actions';
import { startLoading } from 'store/app/actions';
import { FilterType, omitDisabled } from '../utils/filters/requests.filters';
import {
  exportToCsvPath,
  hasSentRequests,
} from 'store/requests/requests.selectors';

export interface InsightsPageContextType extends PageBaseType {
  data: {
    company: CompanyType;
    exportToCsvPath: string;
    hasData: boolean;
    isExporting: boolean;
    requests: RequestsInOutType;
    stats: RequestsStatsType;
    userStats: UserStatsType;
    language: LanguageType;
  };
  actions: PageActionsBaseType & {
    exportToPdf: RequestsExportInsightsToPdfFunctionType;
    getStats: (limit: number) => void;
    setFilter: RequestsSetFilterFunctionType;
    switchView: (companyId: Uuid) => void;
  };
}

const DIRECTION = 'sent';

const InsightsPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      company: state.user.currentUser.company,
      exportToCsvPath: exportToCsvPath(state, DIRECTION),
      hasData: hasSentRequests(state),
      isExporting: state.requests.insightsExporting,
      requests: state.requests[DIRECTION],
      requestsQuantity: state.user.currentUser.stats.requests.sent,
      stats: state.requests.stats,
      userStats: state.user.currentUser.stats,
      language: state.settings.languagePreference,
    })}
    dispatches={(
      dispatch: Dispatch<ActionType>,
      _: PagePropsType,
      updateQuery: UpdateQueryType,
    ) => ({
      getData: ({
        queryParams: { filterBy },
      }: {
        queryParams: { filterBy: FilterType };
      }) => {
        const insightFilters: FilterType = {
          ...filterBy,
          EXTERNAL: { isEnabled: true },
        };
        dispatch(startLoading(2));
        dispatch(getRequests(DIRECTION, insightFilters, false));
        dispatch(getStats(DIRECTION, insightFilters));
      },
      actions: {
        exportToPdf: (filterBy: FilterType) =>
          dispatch(exportInsightsToPdf(filterBy)),
        setFilter: (filterBy: FilterType) => {
          updateQuery({ filterBy: omitDisabled(filterBy) });
          dispatch(getRequests(DIRECTION, filterBy, false));
          dispatch(getStats(DIRECTION, filterBy));
        },
      },
    })}
  >
    {<Insights />}
  </Page>
);

export default InsightsPage;
