import i18n from '../../../i18n';
import { DeleteIntervalEnum, Uuid } from '../../../common/common.types';
import { assocPath } from 'ramda';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { RequestsStateType } from '../requests.types';

export type RequestDeleteScheduledFunctionType = (
  id: Uuid,
  type: DeleteIntervalEnum,
) => void;

export interface RequestDeleteScheduleCallParamsType {
  id: Uuid;
}

export type RequestDeleteScheduledActionType = ApiCallActionType<
  '@requests/API/DELETE_SCHEDULED_REQUEST',
  RequestDeleteScheduleCallParamsType,
  never,
  never,
  RequestsStateType
>;

export const deleteScheduledRequest = (
  id: Uuid,
): RequestDeleteScheduledActionType => ({
  type: '@requests/API/DELETE_SCHEDULED_REQUEST',
  payload: {
    callParams: { id },
    endpointPath: ['requests', 'scheduler', 'deleteRequest'],
    selector: (data, state) =>
      assocPath(
        ['scheduled'],
        state.requests.scheduled.filter(r => r.id !== id),
        state.requests,
      ),
    postActions: [
      () => call(() => trackEvent('Request', 'Deleted scheduled request')),
      () => put(flash(i18n.t('common:responses.scheduledRequestDeleted'))),
    ],
  },
});
