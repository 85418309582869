import styled from 'styled-components';

export const Strong = styled.strong`
  position: relative;
  display: block;
`;

type SubProps = {
  warning?: boolean;
};

export const Sub = styled.div<SubProps>`
  color: ${({ theme, warning = false }) =>
    warning ? theme.basic.colors.error : theme.basic.colors.gray};
  ${({ theme }) => theme.basic.fontWeights.regular};
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
