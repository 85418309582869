/* eslint-disable */
// tslint:disable
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, COLLECTION_FORMATS } from '../runtime';
import {
    CompaniesListResponse,
    CompanyDetailResponse,
    CompanyLogoResponse,
    CompanyPartnerResponse,
    CompanyPartnersListResponse,
    CompanySearchSummaryResponse,
    CreditsDetailResponse,
    CreditsToCompany,
    EnrichedMappingResultWithDepthsListResponse,
    ExceptionResponse,
    FormDefinitionsListResponse,
    FormDetailsListResponse,
    SettingsDictionaryResponse,
    StandardsListResponse,
    TagToCompany,
    TagsListResponse,
    TeamsListResponse,
    UsersListResponse,
    CompaniesListResponseFromJSON,
    CompanyDetailResponseFromJSON,
    CompanyLogoResponseFromJSON,
    CompanyPartnerResponseFromJSON,
    CompanyPartnersListResponseFromJSON,
    CompanySearchSummaryResponseFromJSON,
    CreditsDetailResponseFromJSON,
    CreditsToCompanyToJSON,
    EnrichedMappingResultWithDepthsListResponseFromJSON,
    FormDefinitionsListResponseFromJSON,
    FormDetailsListResponseFromJSON,
    SettingsDictionaryResponseFromJSON,
    StandardsListResponseFromJSON,
    TagToCompanyToJSON,
    TagsListResponseFromJSON,
    TeamsListResponseFromJSON,
    UsersListResponseFromJSON,
} from '../models';

export interface DeleteCompanyLogoResourceRequest {
    companyId: string;
}

export interface DeleteCompanyTagDetailResourceRequest {
    companyId: string;
    tagId: string;
}

export interface GetCompaniesListResourceRequest {
    searchQuery?: string;
}

export interface GetCompanyCreditsResourceRequest {
    companyId: string;
}

export interface GetCompanyCurrentDashboardResourceRequest {
    teamId?: string;
    excludeTeamData?: boolean;
}

export interface GetCompanyCurrentDetailResourceRequest {
    showInternalDetails?: boolean;
}

export interface GetCompanyCurrentFormsListResourceRequest {
    type?: Array<GetCompanyCurrentFormsListResourceTypeEnum>;
    teamId?: string;
    excludeTeamData?: boolean;
    limit?: number;
}

export interface GetCompanyCurrentMappingsSearchResourceRequest {
    text: string;
    maxHops?: number;
    maxDiff?: number;
}

export interface GetCompanyCurrentPartnerResourceRequest {
    partnerId: string;
}

export interface GetCompanyCurrentPartnersListResourceRequest {
    limit?: number;
}

export interface GetCompanyCurrentSettingsResourceRequest {
    keys?: Array<string>;
}

export interface GetCompanyCurrentStandardsListResourceRequest {
    status?: Array<GetCompanyCurrentStandardsListResourceStatusEnum>;
    ignorePreviewUninitialized?: boolean;
    sort?: GetCompanyCurrentStandardsListResourceSortEnum;
    limit?: number;
    hp?: boolean;
}

export interface GetCompanyCurrentUsersListResourceRequest {
    searchQuery?: string;
    limit?: number;
    activeOnly?: boolean;
}

export interface GetCompanyDashboardResourceRequest {
    companyId: string;
    teamId?: string;
    excludeTeamData?: boolean;
}

export interface GetCompanyDetailResourceRequest {
    companyId: string;
    showInternalDetails?: boolean;
}

export interface GetCompanyDraftsDetailResourceRequest {
    companyId: string;
}

export interface GetCompanyFormsListResourceRequest {
    companyId: string;
    type?: Array<GetCompanyFormsListResourceTypeEnum>;
    teamId?: string;
    excludeTeamData?: boolean;
    limit?: number;
}

export interface GetCompanyLogoResourceRequest {
    companyId: string;
}

export interface GetCompanyMappingsSearchResourceRequest {
    companyId: string;
    text: string;
    maxHops?: number;
    maxDiff?: number;
}

export interface GetCompanyPartnerResourceRequest {
    companyId: string;
    partnerId: string;
}

export interface GetCompanyPartnersListResourceRequest {
    companyId: string;
    limit?: number;
}

export interface GetCompanySearchByDomainOrEmailResourceRequest {
    domain?: string;
    email?: string;
    includeHistoryWith?: string;
}

export interface GetCompanySettingsResourceRequest {
    companyId: string;
    keys?: Array<string>;
}

export interface GetCompanyStandardsListResourceRequest {
    companyId: string;
    status?: Array<GetCompanyStandardsListResourceStatusEnum>;
    ignorePreviewUninitialized?: boolean;
    sort?: GetCompanyStandardsListResourceSortEnum;
    limit?: number;
    hp?: boolean;
}

export interface GetCompanyTagsResourceRequest {
    companyId: string;
}

export interface GetCompanyTeamsResourceRequest {
    companyId: string;
}

export interface GetCompanyUsersListResourceRequest {
    companyId: string;
    searchQuery?: string;
    limit?: number;
    activeOnly?: boolean;
}

export interface PostCompanyCreditsResourceRequest {
    companyId: string;
    payload: CreditsToCompany;
}

export interface PostCompanyCurrentCreditsResourceRequest {
    payload: CreditsToCompany;
}

export interface PostCompanyCurrentLogoResourceRequest {
    file: Blob;
}

export interface PostCompanyLogoResourceRequest {
    companyId: string;
    file: Blob;
}

export interface PostCompanyTagsResourceRequest {
    companyId: string;
    payload: TagToCompany;
}

export interface PutCompanySettingsResourceRequest {
    companyId: string;
}

/**
 * no description
 */
export class CompaniesApi extends BaseAPI {

    /**
     * Delete Company Logo
     */
    deleteCompanyCurrentLogoResource = (): Observable<void> => {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<void>({
            path: '/atlas/companies/current/logo',
            method: 'DELETE',
            headers,
        });
    };

    /**
     * Delete Company Logo
     */
    deleteCompanyLogoResource = ({ companyId }: DeleteCompanyLogoResourceRequest): Observable<void> => {
        throwIfNullOrUndefined(companyId, 'deleteCompanyLogoResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<void>({
            path: '/atlas/companies/{company_id}/logo'.replace('{company_id}', encodeURI(companyId)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * Unassign single tag from given company
     */
    deleteCompanyTagDetailResource = ({ companyId, tagId }: DeleteCompanyTagDetailResourceRequest): Observable<TagsListResponse> => {
        throwIfNullOrUndefined(companyId, 'deleteCompanyTagDetailResource');
        throwIfNullOrUndefined(tagId, 'deleteCompanyTagDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<TagsListResponse>({
            parser: jsonValue => TagsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/tags/{tag_id}'.replace('{company_id}', encodeURI(companyId)).replace('{tag_id}', encodeURI(tagId)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * Return list of companies
     */
    getCompaniesListResource = ({ searchQuery }: GetCompaniesListResourceRequest): Observable<CompaniesListResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (searchQuery != null) { query['search_query'] = searchQuery; }

        return this.request<CompaniesListResponse>({
            parser: jsonValue => CompaniesListResponseFromJSON(jsonValue),
            path: '/atlas/companies',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return credits records for given company
     */
    getCompanyCreditsResource = ({ companyId }: GetCompanyCreditsResourceRequest): Observable<CreditsDetailResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyCreditsResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<CreditsDetailResponse>({
            parser: jsonValue => CreditsDetailResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/credits'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return credits records for given company
     */
    getCompanyCurrentCreditsResource = (): Observable<CreditsDetailResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<CreditsDetailResponse>({
            parser: jsonValue => CreditsDetailResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/credits',
            method: 'GET',
            headers,
        });
    };

    /**
     * Return list of master forms belonging to the given company
     */
    getCompanyCurrentDashboardResource = ({ teamId, excludeTeamData }: GetCompanyCurrentDashboardResourceRequest): Observable<FormDetailsListResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (teamId != null) { query['team_id'] = teamId; }
        if (excludeTeamData != null) { query['exclude_team_data'] = excludeTeamData; }

        return this.request<FormDetailsListResponse>({
            parser: jsonValue => FormDetailsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/dashboard',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return given company info
     */
    getCompanyCurrentDetailResource = ({ showInternalDetails }: GetCompanyCurrentDetailResourceRequest): Observable<CompanyDetailResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (showInternalDetails != null) { query['show_internal_details'] = showInternalDetails; }

        return this.request<CompanyDetailResponse>({
            parser: jsonValue => CompanyDetailResponseFromJSON(jsonValue),
            path: '/atlas/companies/current',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    getCompanyCurrentDraftsDetailResource = (): Observable<FormDefinitionsListResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<FormDefinitionsListResponse>({
            parser: jsonValue => FormDefinitionsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/drafts',
            method: 'GET',
            headers,
        });
    };

    /**
     * Return forms belonging to given company
     */
    getCompanyCurrentFormsListResource = ({ type, teamId, excludeTeamData, limit }: GetCompanyCurrentFormsListResourceRequest): Observable<FormDetailsListResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (type != null) { query['type'] = type.join(COLLECTION_FORMATS['csv']); }
        if (teamId != null) { query['team_id'] = teamId; }
        if (excludeTeamData != null) { query['exclude_team_data'] = excludeTeamData; }
        if (limit != null) { query['limit'] = limit; }

        return this.request<FormDetailsListResponse>({
            parser: jsonValue => FormDetailsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/forms',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Get the logo of a company as base64 image
     */
    getCompanyCurrentLogoResource = (): Observable<CompanyLogoResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<CompanyLogoResponse>({
            parser: jsonValue => CompanyLogoResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/logo',
            method: 'GET',
            headers,
        });
    };

    /**
     * Leftover endpoint that should be removed
     */
    getCompanyCurrentMappingsSearchResource = ({ text, maxHops, maxDiff }: GetCompanyCurrentMappingsSearchResourceRequest): Observable<EnrichedMappingResultWithDepthsListResponse> => {
        throwIfNullOrUndefined(text, 'getCompanyCurrentMappingsSearchResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'text': text,
        };

        if (maxHops != null) { query['max_hops'] = maxHops; }
        if (maxDiff != null) { query['max_diff'] = maxDiff; }

        return this.request<EnrichedMappingResultWithDepthsListResponse>({
            parser: jsonValue => EnrichedMappingResultWithDepthsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/mappings',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    getCompanyCurrentPartnerResource = ({ partnerId }: GetCompanyCurrentPartnerResourceRequest): Observable<CompanyPartnerResponse> => {
        throwIfNullOrUndefined(partnerId, 'getCompanyCurrentPartnerResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<CompanyPartnerResponse>({
            parser: jsonValue => CompanyPartnerResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/partner/{partner_id}'.replace('{partner_id}', encodeURI(partnerId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return list of companies that I exchanged some requests with
     */
    getCompanyCurrentPartnersListResource = ({ limit }: GetCompanyCurrentPartnersListResourceRequest): Observable<CompanyPartnersListResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (limit != null) { query['limit'] = limit; }

        return this.request<CompanyPartnersListResponse>({
            parser: jsonValue => CompanyPartnersListResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/partners',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return company settings
     */
    getCompanyCurrentSettingsResource = ({ keys }: GetCompanyCurrentSettingsResourceRequest): Observable<SettingsDictionaryResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (keys != null) { query['keys'] = keys.join(COLLECTION_FORMATS['csv']); }

        return this.request<SettingsDictionaryResponse>({
            parser: jsonValue => SettingsDictionaryResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/settings',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return list of standards belonging to the given company
     */
    getCompanyCurrentStandardsListResource = ({ status, ignorePreviewUninitialized, sort, limit, hp }: GetCompanyCurrentStandardsListResourceRequest): Observable<StandardsListResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (status != null) { query['status'] = status.join(COLLECTION_FORMATS['csv']); }
        if (ignorePreviewUninitialized != null) { query['ignore_preview_uninitialized'] = ignorePreviewUninitialized; }
        if (sort != null) { query['sort'] = sort; }
        if (limit != null) { query['limit'] = limit; }
        if (hp != null) { query['hp'] = hp; }

        return this.request<StandardsListResponse>({
            parser: jsonValue => StandardsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/standards',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    getCompanyCurrentTeamsResource = (): Observable<TeamsListResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<TeamsListResponse>({
            parser: jsonValue => TeamsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/teams',
            method: 'GET',
            headers,
        });
    };

    /**
     * Return list of users that belong to the given company
     */
    getCompanyCurrentUsersListResource = ({ searchQuery, limit, activeOnly }: GetCompanyCurrentUsersListResourceRequest): Observable<UsersListResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (searchQuery != null) { query['search_query'] = searchQuery; }
        if (limit != null) { query['limit'] = limit; }
        if (activeOnly != null) { query['active_only'] = activeOnly; }

        return this.request<UsersListResponse>({
            parser: jsonValue => UsersListResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/users',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return list of master forms belonging to the given company
     */
    getCompanyDashboardResource = ({ companyId, teamId, excludeTeamData }: GetCompanyDashboardResourceRequest): Observable<FormDetailsListResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyDashboardResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (teamId != null) { query['team_id'] = teamId; }
        if (excludeTeamData != null) { query['exclude_team_data'] = excludeTeamData; }

        return this.request<FormDetailsListResponse>({
            parser: jsonValue => FormDetailsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/dashboard'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return given company info
     */
    getCompanyDetailResource = ({ companyId, showInternalDetails }: GetCompanyDetailResourceRequest): Observable<CompanyDetailResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (showInternalDetails != null) { query['show_internal_details'] = showInternalDetails; }

        return this.request<CompanyDetailResponse>({
            parser: jsonValue => CompanyDetailResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    getCompanyDraftsDetailResource = ({ companyId }: GetCompanyDraftsDetailResourceRequest): Observable<FormDefinitionsListResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyDraftsDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<FormDefinitionsListResponse>({
            parser: jsonValue => FormDefinitionsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/drafts'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return forms belonging to given company
     */
    getCompanyFormsListResource = ({ companyId, type, teamId, excludeTeamData, limit }: GetCompanyFormsListResourceRequest): Observable<FormDetailsListResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyFormsListResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (type != null) { query['type'] = type.join(COLLECTION_FORMATS['csv']); }
        if (teamId != null) { query['team_id'] = teamId; }
        if (excludeTeamData != null) { query['exclude_team_data'] = excludeTeamData; }
        if (limit != null) { query['limit'] = limit; }

        return this.request<FormDetailsListResponse>({
            parser: jsonValue => FormDetailsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/forms'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Get the logo of a company as base64 image
     */
    getCompanyLogoResource = ({ companyId }: GetCompanyLogoResourceRequest): Observable<CompanyLogoResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyLogoResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<CompanyLogoResponse>({
            parser: jsonValue => CompanyLogoResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/logo'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Leftover endpoint that should be removed
     */
    getCompanyMappingsSearchResource = ({ companyId, text, maxHops, maxDiff }: GetCompanyMappingsSearchResourceRequest): Observable<EnrichedMappingResultWithDepthsListResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyMappingsSearchResource');
        throwIfNullOrUndefined(text, 'getCompanyMappingsSearchResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'text': text,
        };

        if (maxHops != null) { query['max_hops'] = maxHops; }
        if (maxDiff != null) { query['max_diff'] = maxDiff; }

        return this.request<EnrichedMappingResultWithDepthsListResponse>({
            parser: jsonValue => EnrichedMappingResultWithDepthsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/mappings'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    getCompanyPartnerResource = ({ companyId, partnerId }: GetCompanyPartnerResourceRequest): Observable<CompanyPartnerResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyPartnerResource');
        throwIfNullOrUndefined(partnerId, 'getCompanyPartnerResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<CompanyPartnerResponse>({
            parser: jsonValue => CompanyPartnerResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/partner/{partner_id}'.replace('{company_id}', encodeURI(companyId)).replace('{partner_id}', encodeURI(partnerId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return list of companies that I exchanged some requests with
     */
    getCompanyPartnersListResource = ({ companyId, limit }: GetCompanyPartnersListResourceRequest): Observable<CompanyPartnersListResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyPartnersListResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (limit != null) { query['limit'] = limit; }

        return this.request<CompanyPartnersListResponse>({
            parser: jsonValue => CompanyPartnersListResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/partners'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    getCompanySearchByDomainOrEmailResource = ({ domain, email, includeHistoryWith }: GetCompanySearchByDomainOrEmailResourceRequest): Observable<CompanySearchSummaryResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (domain != null) { query['domain'] = domain; }
        if (email != null) { query['email'] = email; }
        if (includeHistoryWith != null) { query['include_history_with'] = includeHistoryWith; }

        return this.request<CompanySearchSummaryResponse>({
            parser: jsonValue => CompanySearchSummaryResponseFromJSON(jsonValue),
            path: '/atlas/companies/search',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return company settings
     */
    getCompanySettingsResource = ({ companyId, keys }: GetCompanySettingsResourceRequest): Observable<SettingsDictionaryResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanySettingsResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (keys != null) { query['keys'] = keys.join(COLLECTION_FORMATS['csv']); }

        return this.request<SettingsDictionaryResponse>({
            parser: jsonValue => SettingsDictionaryResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/settings'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return list of standards belonging to the given company
     */
    getCompanyStandardsListResource = ({ companyId, status, ignorePreviewUninitialized, sort, limit, hp }: GetCompanyStandardsListResourceRequest): Observable<StandardsListResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyStandardsListResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (status != null) { query['status'] = status.join(COLLECTION_FORMATS['csv']); }
        if (ignorePreviewUninitialized != null) { query['ignore_preview_uninitialized'] = ignorePreviewUninitialized; }
        if (sort != null) { query['sort'] = sort; }
        if (limit != null) { query['limit'] = limit; }
        if (hp != null) { query['hp'] = hp; }

        return this.request<StandardsListResponse>({
            parser: jsonValue => StandardsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/standards'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return list of tags assigned to the company
     */
    getCompanyTagsResource = ({ companyId }: GetCompanyTagsResourceRequest): Observable<TagsListResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyTagsResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<TagsListResponse>({
            parser: jsonValue => TagsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/tags'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    getCompanyTeamsResource = ({ companyId }: GetCompanyTeamsResourceRequest): Observable<TeamsListResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyTeamsResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<TeamsListResponse>({
            parser: jsonValue => TeamsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/teams'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return list of users that belong to the given company
     */
    getCompanyUsersListResource = ({ companyId, searchQuery, limit, activeOnly }: GetCompanyUsersListResourceRequest): Observable<UsersListResponse> => {
        throwIfNullOrUndefined(companyId, 'getCompanyUsersListResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (searchQuery != null) { query['search_query'] = searchQuery; }
        if (limit != null) { query['limit'] = limit; }
        if (activeOnly != null) { query['active_only'] = activeOnly; }

        return this.request<UsersListResponse>({
            parser: jsonValue => UsersListResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/users'.replace('{company_id}', encodeURI(companyId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Add credits to company
     */
    postCompanyCreditsResource = ({ companyId, payload }: PostCompanyCreditsResourceRequest): Observable<CreditsDetailResponse> => {
        throwIfNullOrUndefined(companyId, 'postCompanyCreditsResource');
        throwIfNullOrUndefined(payload, 'postCompanyCreditsResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<CreditsDetailResponse>({
            parser: jsonValue => CreditsDetailResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/credits'.replace('{company_id}', encodeURI(companyId)),
            method: 'POST',
            headers,
            body: CreditsToCompanyToJSON(payload),
        });
    };

    /**
     * Add credits to company
     */
    postCompanyCurrentCreditsResource = ({ payload }: PostCompanyCurrentCreditsResourceRequest): Observable<CreditsDetailResponse> => {
        throwIfNullOrUndefined(payload, 'postCompanyCurrentCreditsResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<CreditsDetailResponse>({
            parser: jsonValue => CreditsDetailResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/credits',
            method: 'POST',
            headers,
            body: CreditsToCompanyToJSON(payload),
        });
    };

    /**
     * Upload Company Logo
     */
    postCompanyCurrentLogoResource = ({ file }: PostCompanyCurrentLogoResourceRequest): Observable<void> => {
        throwIfNullOrUndefined(file, 'postCompanyCurrentLogoResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const formData = new FormData();
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<void>({
            path: '/atlas/companies/current/logo',
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * Upload Company Logo
     */
    postCompanyLogoResource = ({ companyId, file }: PostCompanyLogoResourceRequest): Observable<void> => {
        throwIfNullOrUndefined(companyId, 'postCompanyLogoResource');
        throwIfNullOrUndefined(file, 'postCompanyLogoResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const formData = new FormData();
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<void>({
            path: '/atlas/companies/{company_id}/logo'.replace('{company_id}', encodeURI(companyId)),
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * Assign tag to company
     */
    postCompanyTagsResource = ({ companyId, payload }: PostCompanyTagsResourceRequest): Observable<TagsListResponse> => {
        throwIfNullOrUndefined(companyId, 'postCompanyTagsResource');
        throwIfNullOrUndefined(payload, 'postCompanyTagsResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<TagsListResponse>({
            parser: jsonValue => TagsListResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/tags'.replace('{company_id}', encodeURI(companyId)),
            method: 'POST',
            headers,
            body: TagToCompanyToJSON(payload),
        });
    };

    /**
     * Update company settings
     */
    putCompanyCurrentSettingsResource = (): Observable<SettingsDictionaryResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<SettingsDictionaryResponse>({
            parser: jsonValue => SettingsDictionaryResponseFromJSON(jsonValue),
            path: '/atlas/companies/current/settings',
            method: 'PUT',
            headers,
        });
    };

    /**
     * Update company settings
     */
    putCompanySettingsResource = ({ companyId }: PutCompanySettingsResourceRequest): Observable<SettingsDictionaryResponse> => {
        throwIfNullOrUndefined(companyId, 'putCompanySettingsResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<SettingsDictionaryResponse>({
            parser: jsonValue => SettingsDictionaryResponseFromJSON(jsonValue),
            path: '/atlas/companies/{company_id}/settings'.replace('{company_id}', encodeURI(companyId)),
            method: 'PUT',
            headers,
        });
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetCompanyCurrentFormsListResourceTypeEnum {
    MASTER = 'MASTER',
    UPLOAD = 'UPLOAD',
    REQUEST = 'REQUEST',
    MAPPINGSPLIT = 'MAPPING_SPLIT',
    SNAPSHOT = 'SNAPSHOT',
    PREVIEW = 'PREVIEW',
    DELETED = 'DELETED',
    UPLOADMERGED = 'UPLOAD_MERGED'
}
/**
 * @export
 * @enum {string}
 */
export enum GetCompanyCurrentStandardsListResourceStatusEnum {
    System = 'system',
    Custom = 'custom',
    Draft = 'draft'
}
/**
 * @export
 * @enum {string}
 */
export enum GetCompanyCurrentStandardsListResourceSortEnum {
    Name = 'name',
    MostRecent = 'most_recent',
    MostUsed = 'most_used'
}
/**
 * @export
 * @enum {string}
 */
export enum GetCompanyFormsListResourceTypeEnum {
    MASTER = 'MASTER',
    UPLOAD = 'UPLOAD',
    REQUEST = 'REQUEST',
    MAPPINGSPLIT = 'MAPPING_SPLIT',
    SNAPSHOT = 'SNAPSHOT',
    PREVIEW = 'PREVIEW',
    DELETED = 'DELETED',
    UPLOADMERGED = 'UPLOAD_MERGED'
}
/**
 * @export
 * @enum {string}
 */
export enum GetCompanyStandardsListResourceStatusEnum {
    System = 'system',
    Custom = 'custom',
    Draft = 'draft'
}
/**
 * @export
 * @enum {string}
 */
export enum GetCompanyStandardsListResourceSortEnum {
    Name = 'name',
    MostRecent = 'most_recent',
    MostUsed = 'most_used'
}
