import styled from 'styled-components';

export const RequestedAccess = styled.span`
  ${({ theme }) => theme.fonts.regular.mini}
`;

export const NotificationText = styled.div`
  ${({ theme }) => theme.fonts.bold.small}
`;

export const NotificationWrapper = styled.div`
  padding: 15px 20px;
  margin: 20px auto;
  width: 90%;
  background: ${({ theme }) => theme.atlas.grey[100]};
  line-height: 28px;
  border-left: 10px solid ${({ theme }) => theme.basic.colors.orange};
  display: flex;
  justify-content: space-between;
`;
