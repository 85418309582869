import styled, { css } from 'styled-components';
import { Flex } from 'common/common.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faTimes,
  faTrashAlt,
  faPen,
} from '@fortawesome/free-solid-svg-icons';

export const ActionsWrapper = styled(Flex)`
  flex: 0 0 85px;
  width: 85px;
  justify-content: flex-end;
`;

export const Action = styled.div<{ opacity?: number }>`
  margin-right: 4px;
  width: 20px;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.atlas.text.secondary};
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  ${({ opacity }) =>
    opacity
      ? css`
          opacity: ${opacity};
        `
      : null}
`;

export const DeleteIcon: any = styled(FontAwesomeIcon).attrs(() => ({
  icon: faTimes,
}))``;

export const DeleteTrashIcon: any = styled(FontAwesomeIcon).attrs(() => ({
  icon: faTrashAlt,
}))``;

export const DuplicateIcon: any = styled(FontAwesomeIcon).attrs(() => ({
  icon: faCopy,
}))``;

export const EditIcon: any = styled(FontAwesomeIcon).attrs(() => ({
  icon: faPen,
}))``;

// TODO: create actions as component
