/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface AutocompleteExecution
 */
export interface AutocompleteExecution {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof AutocompleteExecution
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof AutocompleteExecution
     */
    updatedAt: Date;
    /**
     * Owner's company ID
     * @type {string}
     * @memberof AutocompleteExecution
     */
    companyId: string;
    /**
     * Associated form
     * @type {string}
     * @memberof AutocompleteExecution
     */
    formId: string;
    /**
     * 
     * @type {string}
     * @memberof AutocompleteExecution
     */
    id: string;
    /**
     * List of questions that has been autofilled.
     * @type {Array<string>}
     * @memberof AutocompleteExecution
     */
    questionsAutofilled: Array<string>;
    /**
     * Total number of questions processed.
     * @type {number}
     * @memberof AutocompleteExecution
     */
    questionsProcessed: number;
    /**
     * List of questions that has some suggestions.
     * @type {Array<string>}
     * @memberof AutocompleteExecution
     */
    questionsSuggested: Array<string>;
    /**
     * Total number of questions to be processed.
     * @type {number}
     * @memberof AutocompleteExecution
     */
    questionsTotal: number;
    /**
     * Status for autocomplete execution
     * @type {string}
     * @memberof AutocompleteExecution
     */
    status: AutocompleteExecutionStatusEnum;
    /**
     * User who has triggered the autocomplete
     * @type {string}
     * @memberof AutocompleteExecution
     */
    userId: string;
}

export function AutocompleteExecutionFromJSON(json: any): AutocompleteExecution {
    return AutocompleteExecutionFromJSONTyped(json, false);
}

export function AutocompleteExecutionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutocompleteExecution {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'companyId': json['company_id'],
        'formId': json['form_id'],
        'id': json['id'],
        'questionsAutofilled': json['questions_autofilled'],
        'questionsProcessed': json['questions_processed'],
        'questionsSuggested': json['questions_suggested'],
        'questionsTotal': json['questions_total'],
        'status': json['status'],
        'userId': json['user_id'],
    };
}

export function AutocompleteExecutionToJSON(value?: AutocompleteExecution | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'company_id': value.companyId,
        'form_id': value.formId,
        'id': value.id,
        'questions_autofilled': value.questionsAutofilled,
        'questions_processed': value.questionsProcessed,
        'questions_suggested': value.questionsSuggested,
        'questions_total': value.questionsTotal,
        'status': value.status,
        'user_id': value.userId,
    };
}

/**
* @export
* @enum {string}
*/
export enum AutocompleteExecutionStatusEnum {
    FINISHEDFAIL = 'FINISHED_FAIL',
    FINISHEDSUCCESS = 'FINISHED_SUCCESS',
    INPROGRESS = 'IN_PROGRESS',
    INQUEUE = 'IN_QUEUE'
}


