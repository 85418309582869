import React from 'react';
import { Percentage, Wrapper, Totals } from './Completion.styles';

type CompletionPropsType = {
  completion: number;
  questionsFilledCount: number;
  questionsCount: number;
};

const Completion = ({
  completion,
  questionsFilledCount,
  questionsCount,
}: CompletionPropsType) => (
  <Wrapper>
    <Percentage>{`${completion}%`}</Percentage>
    <Totals>{`(${questionsFilledCount}/${questionsCount})`}</Totals>
  </Wrapper>
);

export default Completion;
