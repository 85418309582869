import { contains, evolve } from 'ramda';

import { StandardType } from 'common/common.types';
import { FormNewFromExistingStateType } from 'store/formNewFromExisting/formNewFromExisting.types';

export const SORT_OPTIONS: SortByType[] = ['NAME', 'UPDATED'];

export type SortByType = 'NAME' | 'UPDATED';

export type SortDirectionType = 'ASC' | 'DESC';

export const reverseDirection = (direction: SortDirectionType) =>
  direction === 'ASC' ? 'DESC' : 'ASC';

export interface SortType {
  by: SortByType;
  direction: SortDirectionType;
}

const comparatorFunction = ({ by, direction }: SortType) => (
  a: StandardType,
  b: StandardType,
) => {
  const comparators = {
    NAME_ASC: a.name.localeCompare(b.name),
    NAME_DESC: b.name.localeCompare(a.name),
    UPDATED_ASC: a.updatedAt.getTime() - b.updatedAt.getTime(),
    UPDATED_DESC: b.updatedAt.getTime() - a.updatedAt.getTime(),
  };
  return comparators[`${by}_${direction}`] || 0;
};

export const sortStandardsArray = (sortBy: SortType) => (
  standards: StandardType[],
) => standards.sort(comparatorFunction(sortBy));

const ensureCorrectFormat = ({
  by = 'UPDATED',
  direction = 'DESC',
}: SortType): SortType => ({
  by: contains(by, SORT_OPTIONS) ? by : 'UPDATED',
  direction: direction === 'DESC' ? 'DESC' : 'ASC',
});

export const sortStandards = ({
  by = 'UPDATED',
  direction = 'DESC',
}: SortType) => (formNewFromExisting: FormNewFromExistingStateType) => {
  const sortBy = ensureCorrectFormat({ by, direction });

  return evolve(
    {
      standards: {
        filter: {
          sortBy: () => sortBy,
        },
        data: sortStandardsArray(sortBy),
      },
    },
    formNewFromExisting,
  );
};

export const defaultSortBy: SortType = {
  by: 'UPDATED',
  direction: 'DESC',
};
