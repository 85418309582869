import {
  FormDefinitionValidationType,
  Uuid,
  FixableFieldEnum,
} from '../../../common/common.types';
import update from 'immutability-helper';

const ifProblemAddToAttemptedFixes = (
  kind: 'response' | 'question',
  uuid: Uuid,
  specificField?: FixableFieldEnum,
) => (validation: FormDefinitionValidationType) => {
  const shouldBeSaved =
    (kind === 'question' &&
      validation.invalidQuestions.some(e => e.questionUuid === uuid)) ||
    (kind === 'response' &&
      validation.invalidResponses.some(e => e.responseUuid === uuid));

  const origAttemptedFix = validation.attemptedFixes[uuid];
  let attemptedFix: true | Record<FixableFieldEnum, true>;
  if (typeof origAttemptedFix === 'object') {
    // If there are some details about a specific fixed ID, add it there
    attemptedFix = specificField
      ? {
          ...origAttemptedFix,
          [specificField]: true,
        }
      : origAttemptedFix;
  } else {
    attemptedFix = specificField
      ? // tslint:disable-next-line: no-object-literal-type-assertion
        ({ [specificField as FixableFieldEnum]: true } as Record<
          FixableFieldEnum,
          true
        >)
      : true;
  }

  return shouldBeSaved
    ? update(validation, {
        attemptedFixes: {
          [uuid]: { $set: attemptedFix },
        },
      })
    : validation;
};

export default ifProblemAddToAttemptedFixes;
