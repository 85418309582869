import styled from 'styled-components';
import { Inside, Square } from './Question.styles';
import { alwaysShowScrollbar } from 'common/common.styles';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 49.5%;
`;

export const Content = styled.div`
  height: 100%;
  flex-basis: 91%;
`;

export const Tabs = styled.div`
  flex-basis: 9%;
`;

export const ContentWrapper = styled(Inside)`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  padding: 20px 14px 20px 24px;
`;

export const ContentContainer = styled(Square)`
  ${({ theme }) => theme.fonts.regular.small}
  height: 100%;
  min-height: 320px;
`;

export const ScrollWrapper = styled.div`
  min-height: 0;
  overflow: hidden;
  overflow-y: auto;
  flex: 1;

  ${alwaysShowScrollbar}
`;
