import React, { useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { TranslatePropsType } from 'common/common.types';
import { PageContext } from 'pages/Page';
import { FormFindingsPageContextType } from 'pages/FormFindingsPage';
import {
  FirstPage,
  Generated,
  Header,
  HeaderLogo,
  Logo,
  Page,
  PageCount,
  Section,
  Sections,
  SubTitle,
  Text,
  Title,
  TitleSecond,
} from './PdfReport.styles';
import Loading from '../common/Loading';
import { DATE_FORMAT_BASED_ON_LOCALE } from 'common/common.constants';
import FindingsTable from './FindingsTable';
import Document from './Document';
import getLocale from 'utils/getLocale';
import { StateType } from '../../store/store.types';
import { getLanguagePreference } from '../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import i18next from 'i18next';

const PdfReport = ({ t }: TranslatePropsType) => {
  const {
    data: { currentForm, language },
    queryParams: { includeRiskNote, lang },
  } = useContext(PageContext) as FormFindingsPageContextType;

  /*
   If lang is set override the user setting

     The pdf generation is made in the backend, using this component
     with a BOT account that doesn't have a language setting so we
     need to be able to set the language using a query parameter

   */

  useEffect(() => {
    if (lang) {
      i18next.changeLanguage(lang);
    }
  }, [lang]);

  if (!currentForm || !currentForm.request) {
    return <Loading />;
  }

  const {
    displayName,
    request: {
      findings,
      riskNote,
      target: { name: companyName },
    },
  } = currentForm;

  const generatedAt = format(
    new Date(),
    DATE_FORMAT_BASED_ON_LOCALE[language.value],
    { locale: getLocale(language) },
  );

  const header = (
    <Header>
      <HeaderLogo />
      <p>{t('remediationReportHeader', { companyName }) as string}</p>
      <PageCount />
    </Header>
  );

  return (
    <Document header={header}>
      <FirstPage>
        <div>
          <Logo />
        </div>
        <Title>{t('remediationReport') as string}</Title>
        <TitleSecond>
          {t('remediationReportSecond', { companyName }) as string}
        </TitleSecond>
        <SubTitle>{displayName}</SubTitle>
        <Generated>
          {t('remediationReportGenerated', { generatedAt }) as string}
        </Generated>
      </FirstPage>

      <Page>
        {includeRiskNote && (
          <div>
            <Sections>
              <Section>{t('notes') as string}</Section>
            </Sections>
            <Text>{riskNote}</Text>
          </div>
        )}
        {findings && findings.length > 0 && (
          <FindingsTable findings={findings} />
        )}
      </Page>
    </Document>
  );
};

const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation('FormReport')(PdfReport));
