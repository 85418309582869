import styled from 'styled-components';
import { Section as WhiteSection } from '../Utils/Utils.styles';
import { Flex } from 'common/common.styles';
import {
  Assignable,
  Name as AssignableName,
  Wrapper,
} from '../Form/Delegator/Assignees.styles';

export const PaddedFlex = styled(Flex)`
  margin-bottom: 60px;
`;

export const CenteredFlex = styled(PaddedFlex)`
  align-items: center;
`;

export const Key = styled.div`
  flex: 0 0 35%;
  padding-right: 30px;
`;

export const Value = styled.div`
  flex: 0 0 40%;
  position: relative;
  display: flex;
  align-items: center;

  span {
    cursor: pointer;
    padding-left: 10px;
  }
`;

export const CobrandingWrapper = styled(Value)`
  flex: 0 0 65%;
`;

export const Section = styled(WhiteSection)`
  h4 {
    ${({ theme }) => theme.fonts.semibold.normal}
    margin: 50px 0 10px;
  }
`;

export const Emailable = styled(Assignable)`
  max-width: 400px;
`;

export const EmailableWrapper = styled(Wrapper)`
  width: 400px;
  right: initial;
  top: 60px;
`;

export const Name = styled(AssignableName)`
  width: 400px;
`;

export const Beta = styled.span`
  margin-left: 20px;
  text-transform: uppercase;
  ${({ theme }) => theme.fonts.bold.normal}
`;

export const UploadError = styled.p`
  color: ${({ theme }) => theme.basic.colors.error};
  font-size: 14px;
  font-style: italic;
`;
