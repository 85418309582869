import styled, { css } from 'styled-components';
import colors from 'common/common.colors';
import hex2rgba from 'utils/hex2rgba';

export interface WrapperFactoryProps {
  hasBackground: boolean;
  hasResponse?: boolean;
}

export const Wrapper = styled.div`
  ${({ theme }) => theme.fonts.regular.normal}
  display: flex;
  align-items: stretch;
  margin: 40px 0;
  position: relative;

  &:first-child {
    margin-top: 20px;
  }
`;

const WrapperFactory = styled.div<WrapperFactoryProps>`
  padding: 12px;
  border: 1px solid;
  border-color: ${({ hasBackground, theme, hasResponse }) =>
    hasResponse
      ? hasBackground
        ? theme.basic.colors.light
        : hex2rgba(theme.basic.colors.dark, 0.1)
      : 'transparent'};
  border-radius: 5px;
  background-color: ${({ hasBackground, theme }) =>
    hasBackground ? theme.basic.colors.light : 'transparent'};
  ${({ hasResponse }) =>
    hasResponse
      ? {
          flexBasis: '49.5%',
          minHeight: '320px',
        }
      : {
          flexBasis: '100%',
          minHeight: '0px',
        }}
`;

export const LeftWrapper = styled(WrapperFactory)`
  margin: 0 0.5% 0 0;
  display: flex;
  flex-direction: column;
  align-content: stretch;
`;

export const RightWrapper = styled(WrapperFactory)`
  margin: 0 0 0 0.5%;
  flex-basis: 45%;
`;

export const Responses = styled.div``;

export const NotVisible = styled.div`
  ${({ theme }) => theme.fonts.bold.mini}
  padding: 20px 35px;
  color: ${({ theme }) => theme.basic.colors.atlas};
`;

export interface SquareType {
  hasBackground?: boolean;
  isRequired?: boolean;
}

export const Square = styled.div<SquareType>`
  position: relative;

  & > div {
    background-color: ${({ hasBackground }) =>
      hasBackground ? colors.basic.white : 'transparent'};
  }

  ${({ isRequired }) =>
    isRequired
      ? css`
          &.required {
            border: 1px solid ${colors.basic.red};
            border-radius: 5px;
            border-bottom-width: 0;
          }

          &.required:first-child:not(:last-child) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          &.required:not(:first-child) {
            border-top: none;
            border-radius: 0;
          }

          &.required:last-child {
            border-bottom-width: 1px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          &:before,
          &:after {
            content: '';
            height: 2px;
            display: block;
            position: absolute;
            background-color: ${({ theme }) => theme.basic.colors.background};
            top: initial;
            bottom: -1px;
            width: 92%;
            left: 4%;
            right: 4%;
          }

          &:before {
            bottom: initial;
            top: -1px;
          }
        `
      : ''}
`;

export const Inside = styled.div`
  padding: 20px 24px;
  height: 100%;
  border-radius: 5px;
`;

export const LabelWrapper = styled(Inside)`
  white-space: pre-wrap;
`;

export const Label = styled.span`
  ${({ theme }) => theme.fonts.bold.small}
  display: block;
  padding-bottom: 15px;
`;

export const Required = styled.div`
  ${({ theme }) => theme.fonts.regular.small}
  position: absolute;
  top: 30px;
  right: 0;
  color: ${colors.basic.red};
`;
