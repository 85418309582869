import { ApiCallActionType } from '../../store.types';
import { call, put } from 'redux-saga/effects';
import { getInfo } from '../../user/actions';
import { trackEvent } from 'utils/analytics';
import { RequestsFormType, RequestsStateType } from '../requests.types';
import { startLoading } from '../../app/actions';
import { sendRequestPostActions } from './sendRequestPostActions';
import {
  getCommonRequestCallParams,
  updateSendRequestStateError,
  getEffectiveSourceTeam,
} from '../utils';
import { Uuid } from '../../../common/common.types';
import { RequestsCreate } from '../../../generated/new';

export enum RequestsSendFormRedirectEnum {
  INTERNAL = 'InternalQuestionnaires',
  LINK = 'LinkQuestionnaires',
  NONE = 'None',
  SENT = 'SendQuestionnaires',
  SCHEDULED = 'ScheduledQuestionnaires',
}

export type RequestsSendFormFunctionType = (
  form: RequestsFormType,
  standardIds: Uuid[],
  redirectType?: RequestsSendFormRedirectEnum,
) => void;

export type RequestsSendFormActionType = ApiCallActionType<
  '@requests/API/SEND_FORM',
  RequestsCreate,
  never,
  any,
  RequestsStateType
>;

export const sendForm = (
  form: RequestsFormType,
  standardIds: Uuid[],
  redirectType?: RequestsSendFormRedirectEnum,
): RequestsSendFormActionType => ({
  type: '@requests/API/SEND_FORM',
  payload: {
    endpointPath: ['requests', 'send'],
    callParams: state => ({
      ...getCommonRequestCallParams(form, standardIds),
      sourceTeam: getEffectiveSourceTeam(
        form.sourceTeam,
        state.user.currentUser,
      ),
      targets: form.targets.map(target => target.value),
    }),
    selector: null,
    errorSelector: updateSendRequestStateError,
    preActions: [put(startLoading(1))],
    postActions: [
      () => call(() => trackEvent('Request', 'Create')),
      ...sendRequestPostActions(redirectType),
      () => put(startLoading(1)),
      () => put(getInfo()),
    ],
  },
});
