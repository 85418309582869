import * as R from 'ramda';
import React from 'react';
import TagFilter from './TagFilter';
import Visualisation from './Visualisation';
import { CompaniesFilterFunctionsType } from '../Companies';
import { CompaniesFilterType } from 'store/companies/companies.reducer';
import { TagType } from 'common/common.types';
import {
  Container,
  VisualisationContainer,
  TagFilterContainer,
} from './CompaniesFilter.styles';

export interface CompaniesFilterPropsType {
  allTags: TagType[];
  filter: CompaniesFilterType;
  filterFunctions: CompaniesFilterFunctionsType;
}

const CompaniesFilter = ({
  allTags,
  filter,
  filterFunctions,
}: CompaniesFilterPropsType) => (
  <Container>
    <TagFilterContainer>
      <TagFilter
        filterFunctions={filterFunctions}
        tags={filter.tags}
        allTags={R.difference(allTags, filter.tags)}
        result={filter.total}
      />
    </TagFilterContainer>

    <VisualisationContainer>
      <Visualisation filterFunctions={filterFunctions} filter={filter} />
    </VisualisationContainer>
  </Container>
);

export default CompaniesFilter;
