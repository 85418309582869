import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 56px;
  text-align: center;
  width: 560px;
  color: ${({ theme }) => theme.atlas.text.primary};

  & > * {
    margin-bottom: 24px;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

export const Heading = styled.h1`
  ${({ theme }) => theme.fonts.bold.large}
`;

export const Message = styled.p`
  ${({ theme }) => theme.fonts.regular.normal}
`;

export const RefreshLink = styled.button`
  text-decoration: underline;
  padding: 0;
  font-size: inherit;
  border: none;
  background: none;
  cursor: pointer;

  color: ${({ theme }) => theme.basic.colors.atlas};
  ${({ theme }) => theme.basic.fontWeights.bold}
`;
