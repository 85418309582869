/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreditHistoryRecord,
    CreditHistoryRecordFromJSON,
    CreditHistoryRecordFromJSONTyped,
    CreditHistoryRecordToJSON,
    CreditRecord,
    CreditRecordFromJSON,
    CreditRecordFromJSONTyped,
    CreditRecordToJSON,
    CreditsSummary,
    CreditsSummaryFromJSON,
    CreditsSummaryFromJSONTyped,
    CreditsSummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreditsDetail
 */
export interface CreditsDetail {
    /**
     * 
     * @type {Array<CreditRecord>}
     * @memberof CreditsDetail
     */
    activeList: Array<CreditRecord>;
    /**
     * 
     * @type {Array<CreditHistoryRecord>}
     * @memberof CreditsDetail
     */
    chargingHistory?: Array<CreditHistoryRecord>;
    /**
     * 
     * @type {CreditsSummary}
     * @memberof CreditsDetail
     */
    summary: CreditsSummary;
}

export function CreditsDetailFromJSON(json: any): CreditsDetail {
    return CreditsDetailFromJSONTyped(json, false);
}

export function CreditsDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditsDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeList': ((json['active_list'] as Array<any>).map(CreditRecordFromJSON)),
        'chargingHistory': !exists(json, 'charging_history') ? undefined : ((json['charging_history'] as Array<any>).map(CreditHistoryRecordFromJSON)),
        'summary': CreditsSummaryFromJSON(json['summary']),
    };
}

export function CreditsDetailToJSON(value?: CreditsDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active_list': ((value.activeList as Array<any>).map(CreditRecordToJSON)),
        'charging_history': value.chargingHistory === undefined ? undefined : ((value.chargingHistory as Array<any>).map(CreditHistoryRecordToJSON)),
        'summary': CreditsSummaryToJSON(value.summary),
    };
}


