import React from 'react';
import { useTranslation } from 'react-i18next';

import { TeamsItemType } from 'common/common.types';
import { QuestionIcon, EmailWrapper, Divider } from './RecipientLabel.styles';
import Tooltip from '../../../common/Tooltip';

type LabelProps = {
  innerProps: any;
  data: TeamsItemType;
};

type TooltipProps = {
  data: TeamsItemType;
};

const TooltipOverlay = ({ data }: TooltipProps) => {
  const { t } = useTranslation('SendRequest');
  return (
    <span>
      <strong>{`${t('internal.internalBusinessUnit') as string} ${
        data.label
      }`}</strong>
      <div>{t('internal.contains') as string}:</div>
      <ul>
        {data.members.map(member => (
          <EmailWrapper key={`${data.value}-${member}`}>
            <a href={`mailto:${member}`}>{member}</a>
          </EmailWrapper>
        ))}
      </ul>
    </span>
  );
};

const RecipientLabel = ({ innerProps, data }: LabelProps) => (
  <div {...innerProps}>
    <span>{data.label}</span>
    <Tooltip placement="top" overlay={<TooltipOverlay data={data} />}>
      <QuestionIcon />
    </Tooltip>
    <Divider />
  </div>
);

export default RecipientLabel;
