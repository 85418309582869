import { SortType } from 'utils/filters/requests.sorters';

export type ArchivedRequestsSetSortFunctionType = (sortBy: SortType) => void;

export interface ArchivedRequestsSetSortActionType {
  type: '@requests/SET_SORT_ARCHIVED';
  payload: {
    sortBy: SortType;
  };
}

export const setSortArchived = (
  sortBy: SortType,
): ArchivedRequestsSetSortActionType => ({
  type: '@requests/SET_SORT_ARCHIVED',
  payload: {
    sortBy,
  },
});

// ArchivedRequestsType
