import { createSelector } from 'reselect';
import { FormCreatorStateType } from '../formCreator.types';
import { ValueLabelType } from '../../../common/common.types';

export interface IssueSelectOption extends ValueLabelType<string> {
  description: string;
}

export interface FactorWithIssuesSelectOption {
  label: string;
  options: IssueSelectOption[];
}

export const getFactorsWithIssuesAsSelectOptions = createSelector(
  (formCreator: FormCreatorStateType) => formCreator.factorsWithIssues,
  factorsWithIssues =>
    factorsWithIssues.map(
      ({ title: factorTitle, issues }): FactorWithIssuesSelectOption => ({
        label: factorTitle,
        options: issues.map(({ key, description, title: issueTitle }) => ({
          label: issueTitle,
          description,
          value: key,
        })),
      }),
    ),
);
