/* eslint-disable */
// tslint:disable
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, COLLECTION_FORMATS } from '../runtime';
import {
    ExceptionResponse,
    PdfExportCheckResponse,
    PdfExportSummaryResponse,
    RequestDetailResponse,
    RequestDetailWithCreditsChangeSummaryResponse,
    RequestDetailsListWithCreditsChangeSummaryResponse,
    RequestDetailsListWithRequestFiltersStatsResponse,
    RequestFindingDeletedResponse,
    RequestFindingResponse,
    RequestFindingUpdateInsert,
    RequestFindingsListResponse,
    RequestStartPdfExport,
    RequestStatusStatsResponse,
    RequestUpdate,
    RequestsCreate,
    RequestsInternalCreate,
    RequestsUpdate,
    SenderInfoExceptionResponse,
    PdfExportCheckResponseFromJSON,
    PdfExportSummaryResponseFromJSON,
    RequestDetailResponseFromJSON,
    RequestDetailWithCreditsChangeSummaryResponseFromJSON,
    RequestDetailsListWithCreditsChangeSummaryResponseFromJSON,
    RequestDetailsListWithRequestFiltersStatsResponseFromJSON,
    RequestFindingDeletedResponseFromJSON,
    RequestFindingResponseFromJSON,
    RequestFindingUpdateInsertToJSON,
    RequestFindingsListResponseFromJSON,
    RequestStartPdfExportToJSON,
    RequestStatusStatsResponseFromJSON,
    RequestUpdateToJSON,
    RequestsCreateToJSON,
    RequestsInternalCreateToJSON,
    RequestsUpdateToJSON,
} from '../models';

export interface DeleteRequestFindingDetailResourceRequest {
    requestId: string;
    findingId: string;
}

export interface GetRequestDetailResourceRequest {
    requestId: string;
}

export interface GetRequestDownloadsPdfExportDetailCheckResourceRequest {
    exportUuid: string;
}

export interface GetRequestDownloadsPdfExportDetailResourceRequest {
    exportUuid: string;
}

export interface GetRequestFindingDetailResourceRequest {
    requestId: string;
    findingId: string;
}

export interface GetRequestFindingsCsvExportResourceRequest {
    requestId: string;
    includeRiskNote?: boolean;
}

export interface GetRequestFindingsDownloadsPdfExportDetailCheckResourceRequest {
    requestId: string;
    exportUuid: string;
}

export interface GetRequestFindingsDownloadsPdfExportDetailResourceRequest {
    requestId: string;
    exportUuid: string;
}

export interface GetRequestFindingsListResourceRequest {
    requestId: string;
}

export interface GetRequestsDownloadsCsvExportResourceRequest {
    sourceId: string;
    formRoles?: Array<GetRequestsDownloadsCsvExportResourceFormRolesEnum>;
    targetId?: Array<string>;
    companyId?: Array<string>;
    sourceUserEmail?: Array<string>;
    targetUserEmail?: Array<string>;
    userEmail?: Array<string>;
    sourceTeamIds?: Array<string>;
    targetTeamIds?: Array<string>;
    sentTimeFrom?: string;
    sentTimeTo?: string;
    status?: Array<GetRequestsDownloadsCsvExportResourceStatusEnum>;
    standardId?: Array<string>;
    onlyInternal?: boolean;
    onlyExternal?: boolean;
    withRevisions?: number;
    limit?: number;
}

export interface GetRequestsListResourceRequest {
    formRoles?: Array<GetRequestsListResourceFormRolesEnum>;
    sourceId?: Array<string>;
    targetId?: Array<string>;
    companyId?: Array<string>;
    sourceUserEmail?: Array<string>;
    targetUserEmail?: Array<string>;
    userEmail?: Array<string>;
    sourceTeamIds?: Array<string>;
    targetTeamIds?: Array<string>;
    sentTimeFrom?: string;
    sentTimeTo?: string;
    status?: Array<GetRequestsListResourceStatusEnum>;
    standardId?: Array<string>;
    onlyInternal?: boolean;
    onlyExternal?: boolean;
    withRevisions?: number;
    limit?: number;
}

export interface GetRequestsStatsResourceRequest {
    formRoles?: Array<GetRequestsStatsResourceFormRolesEnum>;
    sourceId?: Array<string>;
    targetId?: Array<string>;
    companyId?: Array<string>;
    sourceUserEmail?: Array<string>;
    targetUserEmail?: Array<string>;
    userEmail?: Array<string>;
    sourceTeamIds?: Array<string>;
    targetTeamIds?: Array<string>;
    sentTimeFrom?: string;
    sentTimeTo?: string;
    status?: Array<GetRequestsStatsResourceStatusEnum>;
    standardId?: Array<string>;
    onlyInternal?: boolean;
    onlyExternal?: boolean;
    withRevisions?: number;
    limit?: number;
}

export interface PostRequestDownloadsPdfExportResourceRequest {
    payload: RequestStartPdfExport;
}

export interface PostRequestFindingsDownloadsPdfExportResourceRequest {
    requestId: string;
    payload: RequestStartPdfExport;
}

export interface PostRequestFindingsListResourceRequest {
    requestId: string;
    payload: RequestFindingUpdateInsert;
}

export interface PostRequestsInternalListResourceRequest {
    payload: RequestsInternalCreate;
}

export interface PostRequestsListResourceRequest {
    payload: RequestsCreate;
}

export interface PutRequestDetailResourceRequest {
    requestId: string;
    payload: RequestUpdate;
}

export interface PutRequestFindingDetailResourceRequest {
    requestId: string;
    findingId: string;
    payload: RequestFindingUpdateInsert;
}

export interface PutRequestsListResourceRequest {
    payload: RequestsUpdate;
}

/**
 * no description
 */
export class RequestsApi extends BaseAPI {

    /**
     * Delete finding with given id
     */
    deleteRequestFindingDetailResource = ({ requestId, findingId }: DeleteRequestFindingDetailResourceRequest): Observable<RequestFindingDeletedResponse> => {
        throwIfNullOrUndefined(requestId, 'deleteRequestFindingDetailResource');
        throwIfNullOrUndefined(findingId, 'deleteRequestFindingDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<RequestFindingDeletedResponse>({
            parser: jsonValue => RequestFindingDeletedResponseFromJSON(jsonValue),
            path: '/atlas/requests/{request_id}/findings/{finding_id}'.replace('{request_id}', encodeURI(requestId)).replace('{finding_id}', encodeURI(findingId)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * Return single request detail
     */
    getRequestDetailResource = ({ requestId }: GetRequestDetailResourceRequest): Observable<RequestDetailResponse> => {
        throwIfNullOrUndefined(requestId, 'getRequestDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<RequestDetailResponse>({
            parser: jsonValue => RequestDetailResponseFromJSON(jsonValue),
            path: '/atlas/requests/{request_id}'.replace('{request_id}', encodeURI(requestId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Check if PDF export is finished
     */
    getRequestDownloadsPdfExportDetailCheckResource = ({ exportUuid }: GetRequestDownloadsPdfExportDetailCheckResourceRequest): Observable<PdfExportCheckResponse> => {
        throwIfNullOrUndefined(exportUuid, 'getRequestDownloadsPdfExportDetailCheckResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<PdfExportCheckResponse>({
            parser: jsonValue => PdfExportCheckResponseFromJSON(jsonValue),
            path: '/atlas/requests/downloads/pdf_export/{export_uuid}/check'.replace('{export_uuid}', encodeURI(exportUuid)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return result (PDF file) of previously initiated the PDF export
     */
    getRequestDownloadsPdfExportDetailResource = ({ exportUuid }: GetRequestDownloadsPdfExportDetailResourceRequest): Observable<void> => {
        throwIfNullOrUndefined(exportUuid, 'getRequestDownloadsPdfExportDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<void>({
            path: '/atlas/requests/downloads/pdf_export/{export_uuid}'.replace('{export_uuid}', encodeURI(exportUuid)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return finding with given id
     */
    getRequestFindingDetailResource = ({ requestId, findingId }: GetRequestFindingDetailResourceRequest): Observable<RequestFindingResponse> => {
        throwIfNullOrUndefined(requestId, 'getRequestFindingDetailResource');
        throwIfNullOrUndefined(findingId, 'getRequestFindingDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<RequestFindingResponse>({
            parser: jsonValue => RequestFindingResponseFromJSON(jsonValue),
            path: '/atlas/requests/{request_id}/findings/{finding_id}'.replace('{request_id}', encodeURI(requestId)).replace('{finding_id}', encodeURI(findingId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return CSV with requests using given criteria
     */
    getRequestFindingsCsvExportResource = ({ requestId, includeRiskNote }: GetRequestFindingsCsvExportResourceRequest): Observable<void> => {
        throwIfNullOrUndefined(requestId, 'getRequestFindingsCsvExportResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (includeRiskNote != null) { query['include_risk_note'] = includeRiskNote; }

        return this.request<void>({
            path: '/atlas/requests/{request_id}/findings/downloads/csv_export'.replace('{request_id}', encodeURI(requestId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Check if PDF export is finished
     */
    getRequestFindingsDownloadsPdfExportDetailCheckResource = ({ requestId, exportUuid }: GetRequestFindingsDownloadsPdfExportDetailCheckResourceRequest): Observable<PdfExportCheckResponse> => {
        throwIfNullOrUndefined(requestId, 'getRequestFindingsDownloadsPdfExportDetailCheckResource');
        throwIfNullOrUndefined(exportUuid, 'getRequestFindingsDownloadsPdfExportDetailCheckResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<PdfExportCheckResponse>({
            parser: jsonValue => PdfExportCheckResponseFromJSON(jsonValue),
            path: '/atlas/requests/{request_id}/findings/downloads/pdf_export/{export_uuid}/check'.replace('{request_id}', encodeURI(requestId)).replace('{export_uuid}', encodeURI(exportUuid)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return result (PDF file) of previously initiated the PDF export
     */
    getRequestFindingsDownloadsPdfExportDetailResource = ({ requestId, exportUuid }: GetRequestFindingsDownloadsPdfExportDetailResourceRequest): Observable<void> => {
        throwIfNullOrUndefined(requestId, 'getRequestFindingsDownloadsPdfExportDetailResource');
        throwIfNullOrUndefined(exportUuid, 'getRequestFindingsDownloadsPdfExportDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<void>({
            path: '/atlas/requests/{request_id}/findings/downloads/pdf_export/{export_uuid}'.replace('{request_id}', encodeURI(requestId)).replace('{export_uuid}', encodeURI(exportUuid)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return findings associated with request
     */
    getRequestFindingsListResource = ({ requestId }: GetRequestFindingsListResourceRequest): Observable<RequestFindingsListResponse> => {
        throwIfNullOrUndefined(requestId, 'getRequestFindingsListResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<RequestFindingsListResponse>({
            parser: jsonValue => RequestFindingsListResponseFromJSON(jsonValue),
            path: '/atlas/requests/{request_id}/findings'.replace('{request_id}', encodeURI(requestId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return CSV with requests using given criteria
     */
    getRequestsDownloadsCsvExportResource = ({ sourceId, formRoles, targetId, companyId, sourceUserEmail, targetUserEmail, userEmail, sourceTeamIds, targetTeamIds, sentTimeFrom, sentTimeTo, status, standardId, onlyInternal, onlyExternal, withRevisions, limit }: GetRequestsDownloadsCsvExportResourceRequest): Observable<void> => {
        throwIfNullOrUndefined(sourceId, 'getRequestsDownloadsCsvExportResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'source_id': sourceId,
        };

        if (formRoles != null) { query['form_roles'] = formRoles.join(COLLECTION_FORMATS['csv']); }
        if (targetId != null) { query['target_id'] = targetId.join(COLLECTION_FORMATS['csv']); }
        if (companyId != null) { query['company_id'] = companyId.join(COLLECTION_FORMATS['csv']); }
        if (sourceUserEmail != null) { query['source_user_email'] = sourceUserEmail.join(COLLECTION_FORMATS['csv']); }
        if (targetUserEmail != null) { query['target_user_email'] = targetUserEmail.join(COLLECTION_FORMATS['csv']); }
        if (userEmail != null) { query['user_email'] = userEmail.join(COLLECTION_FORMATS['csv']); }
        if (sourceTeamIds != null) { query['source_team_ids'] = sourceTeamIds.join(COLLECTION_FORMATS['csv']); }
        if (targetTeamIds != null) { query['target_team_ids'] = targetTeamIds.join(COLLECTION_FORMATS['csv']); }
        if (sentTimeFrom != null) { query['sent_time_from'] = (sentTimeFrom as any).toISOString(); }
        if (sentTimeTo != null) { query['sent_time_to'] = (sentTimeTo as any).toISOString(); }
        if (status != null) { query['status'] = status.join(COLLECTION_FORMATS['csv']); }
        if (standardId != null) { query['standard_id'] = standardId.join(COLLECTION_FORMATS['csv']); }
        if (onlyInternal != null) { query['only_internal'] = onlyInternal; }
        if (onlyExternal != null) { query['only_external'] = onlyExternal; }
        if (withRevisions != null) { query['with_revisions'] = withRevisions; }
        if (limit != null) { query['limit'] = limit; }

        return this.request<void>({
            path: '/atlas/requests/downloads/csv_export',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return requests using given criteria
     */
    getRequestsListResource = ({ formRoles, sourceId, targetId, companyId, sourceUserEmail, targetUserEmail, userEmail, sourceTeamIds, targetTeamIds, sentTimeFrom, sentTimeTo, status, standardId, onlyInternal, onlyExternal, withRevisions, limit }: GetRequestsListResourceRequest): Observable<RequestDetailsListWithRequestFiltersStatsResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (formRoles != null) { query['form_roles'] = formRoles.join(COLLECTION_FORMATS['csv']); }
        if (sourceId != null) { query['source_id'] = sourceId.join(COLLECTION_FORMATS['csv']); }
        if (targetId != null) { query['target_id'] = targetId.join(COLLECTION_FORMATS['csv']); }
        if (companyId != null) { query['company_id'] = companyId.join(COLLECTION_FORMATS['csv']); }
        if (sourceUserEmail != null) { query['source_user_email'] = sourceUserEmail.join(COLLECTION_FORMATS['csv']); }
        if (targetUserEmail != null) { query['target_user_email'] = targetUserEmail.join(COLLECTION_FORMATS['csv']); }
        if (userEmail != null) { query['user_email'] = userEmail.join(COLLECTION_FORMATS['csv']); }
        if (sourceTeamIds != null) { query['source_team_ids'] = sourceTeamIds.join(COLLECTION_FORMATS['csv']); }
        if (targetTeamIds != null) { query['target_team_ids'] = targetTeamIds.join(COLLECTION_FORMATS['csv']); }
        if (sentTimeFrom != null) { query['sent_time_from'] = (sentTimeFrom as any).toISOString(); }
        if (sentTimeTo != null) { query['sent_time_to'] = (sentTimeTo as any).toISOString(); }
        if (status != null) { query['status'] = status.join(COLLECTION_FORMATS['csv']); }
        if (standardId != null) { query['standard_id'] = standardId.join(COLLECTION_FORMATS['csv']); }
        if (onlyInternal != null) { query['only_internal'] = onlyInternal; }
        if (onlyExternal != null) { query['only_external'] = onlyExternal; }
        if (withRevisions != null) { query['with_revisions'] = withRevisions; }
        if (limit != null) { query['limit'] = limit; }

        return this.request<RequestDetailsListWithRequestFiltersStatsResponse>({
            parser: jsonValue => RequestDetailsListWithRequestFiltersStatsResponseFromJSON(jsonValue),
            path: '/atlas/requests',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return stats about requests for the dashboard
     */
    getRequestsStatsResource = ({ formRoles, sourceId, targetId, companyId, sourceUserEmail, targetUserEmail, userEmail, sourceTeamIds, targetTeamIds, sentTimeFrom, sentTimeTo, status, standardId, onlyInternal, onlyExternal, withRevisions, limit }: GetRequestsStatsResourceRequest): Observable<RequestStatusStatsResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (formRoles != null) { query['form_roles'] = formRoles.join(COLLECTION_FORMATS['csv']); }
        if (sourceId != null) { query['source_id'] = sourceId.join(COLLECTION_FORMATS['csv']); }
        if (targetId != null) { query['target_id'] = targetId.join(COLLECTION_FORMATS['csv']); }
        if (companyId != null) { query['company_id'] = companyId.join(COLLECTION_FORMATS['csv']); }
        if (sourceUserEmail != null) { query['source_user_email'] = sourceUserEmail.join(COLLECTION_FORMATS['csv']); }
        if (targetUserEmail != null) { query['target_user_email'] = targetUserEmail.join(COLLECTION_FORMATS['csv']); }
        if (userEmail != null) { query['user_email'] = userEmail.join(COLLECTION_FORMATS['csv']); }
        if (sourceTeamIds != null) { query['source_team_ids'] = sourceTeamIds.join(COLLECTION_FORMATS['csv']); }
        if (targetTeamIds != null) { query['target_team_ids'] = targetTeamIds.join(COLLECTION_FORMATS['csv']); }
        if (sentTimeFrom != null) { query['sent_time_from'] = (sentTimeFrom as any).toISOString(); }
        if (sentTimeTo != null) { query['sent_time_to'] = (sentTimeTo as any).toISOString(); }
        if (status != null) { query['status'] = status.join(COLLECTION_FORMATS['csv']); }
        if (standardId != null) { query['standard_id'] = standardId.join(COLLECTION_FORMATS['csv']); }
        if (onlyInternal != null) { query['only_internal'] = onlyInternal; }
        if (onlyExternal != null) { query['only_external'] = onlyExternal; }
        if (withRevisions != null) { query['with_revisions'] = withRevisions; }
        if (limit != null) { query['limit'] = limit; }

        return this.request<RequestStatusStatsResponse>({
            parser: jsonValue => RequestStatusStatsResponseFromJSON(jsonValue),
            path: '/atlas/requests/stats',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Start exporting PDF file with current stats/insights
     */
    postRequestDownloadsPdfExportResource = ({ payload }: PostRequestDownloadsPdfExportResourceRequest): Observable<PdfExportSummaryResponse> => {
        throwIfNullOrUndefined(payload, 'postRequestDownloadsPdfExportResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<PdfExportSummaryResponse>({
            parser: jsonValue => PdfExportSummaryResponseFromJSON(jsonValue),
            path: '/atlas/requests/downloads/pdf_export',
            method: 'POST',
            headers,
            body: RequestStartPdfExportToJSON(payload),
        });
    };

    /**
     * Start exporting PDF file with findings and given form
     */
    postRequestFindingsDownloadsPdfExportResource = ({ requestId, payload }: PostRequestFindingsDownloadsPdfExportResourceRequest): Observable<PdfExportSummaryResponse> => {
        throwIfNullOrUndefined(requestId, 'postRequestFindingsDownloadsPdfExportResource');
        throwIfNullOrUndefined(payload, 'postRequestFindingsDownloadsPdfExportResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<PdfExportSummaryResponse>({
            parser: jsonValue => PdfExportSummaryResponseFromJSON(jsonValue),
            path: '/atlas/requests/{request_id}/findings/downloads/pdf_export'.replace('{request_id}', encodeURI(requestId)),
            method: 'POST',
            headers,
            body: RequestStartPdfExportToJSON(payload),
        });
    };

    /**
     * Append finding to single request
     */
    postRequestFindingsListResource = ({ requestId, payload }: PostRequestFindingsListResourceRequest): Observable<RequestFindingResponse> => {
        throwIfNullOrUndefined(requestId, 'postRequestFindingsListResource');
        throwIfNullOrUndefined(payload, 'postRequestFindingsListResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<RequestFindingResponse>({
            parser: jsonValue => RequestFindingResponseFromJSON(jsonValue),
            path: '/atlas/requests/{request_id}/findings'.replace('{request_id}', encodeURI(requestId)),
            method: 'POST',
            headers,
            body: RequestFindingUpdateInsertToJSON(payload),
        });
    };

    /**
     * Send new internal request(s)
     */
    postRequestsInternalListResource = ({ payload }: PostRequestsInternalListResourceRequest): Observable<RequestDetailsListWithCreditsChangeSummaryResponse> => {
        throwIfNullOrUndefined(payload, 'postRequestsInternalListResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<RequestDetailsListWithCreditsChangeSummaryResponse>({
            parser: jsonValue => RequestDetailsListWithCreditsChangeSummaryResponseFromJSON(jsonValue),
            path: '/atlas/requests/internal',
            method: 'POST',
            headers,
            body: RequestsInternalCreateToJSON(payload),
        });
    };

    /**
     * Send new request(s)
     */
    postRequestsListResource = ({ payload }: PostRequestsListResourceRequest): Observable<RequestDetailsListWithCreditsChangeSummaryResponse> => {
        throwIfNullOrUndefined(payload, 'postRequestsListResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<RequestDetailsListWithCreditsChangeSummaryResponse>({
            parser: jsonValue => RequestDetailsListWithCreditsChangeSummaryResponseFromJSON(jsonValue),
            path: '/atlas/requests',
            method: 'POST',
            headers,
            body: RequestsCreateToJSON(payload),
        });
    };

    /**
     * Update single request
     */
    putRequestDetailResource = ({ requestId, payload }: PutRequestDetailResourceRequest): Observable<RequestDetailWithCreditsChangeSummaryResponse> => {
        throwIfNullOrUndefined(requestId, 'putRequestDetailResource');
        throwIfNullOrUndefined(payload, 'putRequestDetailResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<RequestDetailWithCreditsChangeSummaryResponse>({
            parser: jsonValue => RequestDetailWithCreditsChangeSummaryResponseFromJSON(jsonValue),
            path: '/atlas/requests/{request_id}'.replace('{request_id}', encodeURI(requestId)),
            method: 'PUT',
            headers,
            body: RequestUpdateToJSON(payload),
        });
    };

    /**
     * Update finding with specified id
     */
    putRequestFindingDetailResource = ({ requestId, findingId, payload }: PutRequestFindingDetailResourceRequest): Observable<RequestFindingResponse> => {
        throwIfNullOrUndefined(requestId, 'putRequestFindingDetailResource');
        throwIfNullOrUndefined(findingId, 'putRequestFindingDetailResource');
        throwIfNullOrUndefined(payload, 'putRequestFindingDetailResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<RequestFindingResponse>({
            parser: jsonValue => RequestFindingResponseFromJSON(jsonValue),
            path: '/atlas/requests/{request_id}/findings/{finding_id}'.replace('{request_id}', encodeURI(requestId)).replace('{finding_id}', encodeURI(findingId)),
            method: 'PUT',
            headers,
            body: RequestFindingUpdateInsertToJSON(payload),
        });
    };

    /**
     * Update more requests
     */
    putRequestsListResource = ({ payload }: PutRequestsListResourceRequest): Observable<RequestDetailsListWithCreditsChangeSummaryResponse> => {
        throwIfNullOrUndefined(payload, 'putRequestsListResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<RequestDetailsListWithCreditsChangeSummaryResponse>({
            parser: jsonValue => RequestDetailsListWithCreditsChangeSummaryResponseFromJSON(jsonValue),
            path: '/atlas/requests',
            method: 'PUT',
            headers,
            body: RequestsUpdateToJSON(payload),
        });
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetRequestsDownloadsCsvExportResourceFormRolesEnum {
    VENDOR = 'VENDOR',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY'
}
/**
 * @export
 * @enum {string}
 */
export enum GetRequestsDownloadsCsvExportResourceStatusEnum {
    CREATED = 'CREATED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS',
    ACCEPTED = 'ACCEPTED',
    CANCELED = 'CANCELED',
    ARCHIVED = 'ARCHIVED',
    UNARCHIVED = 'UNARCHIVED'
}
/**
 * @export
 * @enum {string}
 */
export enum GetRequestsListResourceFormRolesEnum {
    VENDOR = 'VENDOR',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY'
}
/**
 * @export
 * @enum {string}
 */
export enum GetRequestsListResourceStatusEnum {
    CREATED = 'CREATED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS',
    ACCEPTED = 'ACCEPTED',
    CANCELED = 'CANCELED',
    ARCHIVED = 'ARCHIVED',
    UNARCHIVED = 'UNARCHIVED'
}
/**
 * @export
 * @enum {string}
 */
export enum GetRequestsStatsResourceFormRolesEnum {
    VENDOR = 'VENDOR',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY'
}
/**
 * @export
 * @enum {string}
 */
export enum GetRequestsStatsResourceStatusEnum {
    CREATED = 'CREATED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS',
    ACCEPTED = 'ACCEPTED',
    CANCELED = 'CANCELED',
    ARCHIVED = 'ARCHIVED',
    UNARCHIVED = 'UNARCHIVED'
}
