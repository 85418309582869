import React, { useCallback } from 'react';
import Question from '../../Question';
import { withTranslation } from 'react-i18next';
import {
  CategoryHeader,
  TitleText,
  PaddingBelowWrapper,
  TitleInput,
  Wrapper,
  HeaderAction,
  ContentWrapper,
} from './Category.styles';
import HeaderWarningIcon from '../../HeaderWarningIcon';
import {
  AddCategory as AddQuestionTitle,
  PlusIcon,
} from '../Categories.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../common/Button';
import { faPlus, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FormCreatorContextActionsType } from 'pages/FormCreatorPage';
import { TranslatePropsType, FormCreatorRenderType } from 'common/common.types';
import { DeleteTrashIcon, EditIcon } from '../../Actions/Actions.styles';
import { ArrowIcon } from '../../QuestionTile/QuestionTile.styles';
import { OpenedQuestionType } from 'store/formCreator/formCreator.types';
import { SwitchQuestionConditionTypeFunctionType } from 'store/formCreator/actions';
import { FactorWithIssuesSelectOption } from 'store/formCreator/selectors/issues';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CategoryPropsType extends TranslatePropsType {
  actions: FormCreatorContextActionsType;
  areQuestionsDeletable: boolean;
  category: FormCreatorRenderType;
  factorsWithIssues: FactorWithIssuesSelectOption[];
  isDeletable: boolean;
  isOpen?: boolean;
  hasConditionsToggle: boolean;
  hasIssuesToggle: boolean;
  onClickDelete: (categoryName: string) => void;
  openedQuestion?: OpenedQuestionType;
  renderData: FormCreatorRenderType[];
  showOnlyErrors: boolean;
  style?: React.CSSProperties;
  switchQuestionConditionType: SwitchQuestionConditionTypeFunctionType;
}

const Category = ({
  actions,
  actions: { addQuestion, renameCategory, setOpenedQuestion },
  areQuestionsDeletable,
  category: { hasErrors, name, questions },
  factorsWithIssues,
  isDeletable,
  isOpen = true,
  hasConditionsToggle,
  hasIssuesToggle,
  onClickDelete,
  openedQuestion,
  renderData,
  showOnlyErrors,
  style,
  switchQuestionConditionType,
  t,
}: CategoryPropsType) => {
  const handleNameChange = useCallback(
    (newName: string) => {
      if (newName !== name) {
        renameCategory(name, newName);
      }
    },
    [name, renameCategory],
  );

  const handleHeaderClick = useCallback(
    (event: React.MouseEvent) => {
      setOpenedQuestion(
        isOpen
          ? undefined
          : {
              categoryName: name,
              questionUuid: undefined,
            },
      );
    },
    [setOpenedQuestion, isOpen, name],
  );

  const dontPropagate = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  const handleClickDelete = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onClickDelete(name);
    },
    [name, onClickDelete],
  );

  const openedQuestionUuid = openedQuestion && openedQuestion.questionUuid;

  return (
    // We use this padding wrapper to emulate margin-bottom because
    // react-virtualized doesn't support margins between items.
    <PaddingBelowWrapper style={style}>
      <Wrapper isOpen={isOpen} data-test="form-category">
        <CategoryHeader onClick={handleHeaderClick}>
          <HeaderWarningIcon visible={hasErrors} />
          <ArrowIcon open={isOpen}>
            <FontAwesomeIcon icon={faChevronDown as IconProp} />
          </ArrowIcon>
          {isOpen ? (
            <TitleInput
              onBlur={handleNameChange}
              value={name}
              height={30}
              onClick={dontPropagate}
            />
          ) : (
            <TitleText>{name}</TitleText>
          )}
          {!isOpen && (
            <HeaderAction isOpen={isOpen}>
              <EditIcon />
            </HeaderAction>
          )}
          {isDeletable && (
            <HeaderAction
              isOpen={isOpen}
              onClick={handleClickDelete}
              title={t('delete') as string}
            >
              <DeleteTrashIcon data-test="delete-category" />
            </HeaderAction>
          )}
        </CategoryHeader>
        {isOpen && (
          <ContentWrapper>
            {questions
              .filter(q => q.hasErrors || !showOnlyErrors)
              .map(q => (
                <Question
                  actions={actions}
                  hasConditionsToggle={hasConditionsToggle}
                  hasIssuesToggle={hasIssuesToggle}
                  isDeletable={areQuestionsDeletable}
                  isOpen={q.wizardId === openedQuestionUuid}
                  factorsWithIssues={factorsWithIssues}
                  key={q.wizardId}
                  question={q}
                  renderData={renderData}
                  switchQuestionConditionType={switchQuestionConditionType}
                />
              ))}

            <Button
              dataTest="add-question"
              size="mlarge"
              onClick={() => addQuestion(name)}
              color="transparent"
            >
              <PlusIcon>
                <FontAwesomeIcon icon={faPlus as IconProp} />
              </PlusIcon>
              <AddQuestionTitle>{t('addQuestion') as string}</AddQuestionTitle>
            </Button>
          </ContentWrapper>
        )}
      </Wrapper>
    </PaddingBelowWrapper>
  );
};

export default React.memo(withTranslation('FormCreator')(Category));
