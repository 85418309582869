import React from 'react';
import { ResponseStatus } from 'components/ResponseStatus';

const MaintenancePage = () => {
  return (
    <ResponseStatus
      heading={'We are under maintenanance'}
      caption={
        'We will be performing maintenance on the Atlas Platform this weekend from Friday, October 13 23:00 UTC to Monday, October 16 01:00 UTC (Friday 07:00 PM to Sunday 09:00 PM Eastern). During this time, the Questionnaires will be unavailable'
      }
    />
  );
};

export default MaintenancePage;
