/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionMapping,
    QuestionMappingFromJSON,
    QuestionMappingFromJSONTyped,
    QuestionMappingToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionMappingsListResponse
 */
export interface QuestionMappingsListResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof QuestionMappingsListResponse
     */
    msg: string;
    /**
     * 
     * @type {Array<QuestionMapping>}
     * @memberof QuestionMappingsListResponse
     */
    entries: Array<QuestionMapping>;
}

export function QuestionMappingsListResponseFromJSON(json: any): QuestionMappingsListResponse {
    return QuestionMappingsListResponseFromJSONTyped(json, false);
}

export function QuestionMappingsListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionMappingsListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entries': ((json['entries'] as Array<any>).map(QuestionMappingFromJSON)),
    };
}

export function QuestionMappingsListResponseToJSON(value?: QuestionMappingsListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entries': ((value.entries as Array<any>).map(QuestionMappingToJSON)),
    };
}


