import { call } from 'redux-saga/effects';
import { ApiCallActionType } from '../../store.types';
import { LOGIN } from '../../../routes';
import { removeSentryUser } from 'utils/sentryConfig';
import { getInitialState } from '../utils';
import { UserStateType } from '../user.types';

export type UserLogoutActionType = ApiCallActionType<
  '@user/API/LOGOUT',
  {},
  never,
  never,
  UserStateType
>;

export const logout = (): UserLogoutActionType => ({
  type: '@user/API/LOGOUT',
  payload: {
    endpointPath: ['user', 'logout'],
    callParams: {},
    selector: (): UserStateType => getInitialState({}),
    postActions: [
      () => removeSentryUser(),
      () =>
        call(() => {
          document.location.assign(LOGIN);
        }),
    ],
  },
});
