import { Store } from 'redux';
import { ErrorActionType } from 'store/common/actions/error';
import uuid from '../uuid';
import { optimisticUpdate } from './actions/optimisticUpdate';
import { realityCheck } from './actions/realityCheck';
import { OptimisticActionType } from './optimistic.types';

const optimisticMiddleware = () => (store: Store) => (
  next: (...params: any[]) => void,
) => (action: OptimisticActionType<never> & ErrorActionType) => {
  if (action.type === '@store/ERROR' && action.transactionId) {
    next(realityCheck(action.transactionId));
    return next(action);
  }

  if (
    action.payload &&
    (action as OptimisticActionType<ErrorActionType>).payload
      .expectedStateChange &&
    !action.transactionId
  ) {
    const transactionId = uuid();
    next(
      optimisticUpdate(
        transactionId,
        (action as OptimisticActionType<any>).payload.expectedStateChange,
      ),
    );
    return next({ ...action, transactionId });
  }
  return next(action);
};

export default optimisticMiddleware;
