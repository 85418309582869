import { FormCollaboratorType, FormProxyDetailType } from 'common/common.types';
import { parseUser } from './user.parsers';
import { Collaborator } from 'generated/models/Collaborator';
import { ProxyWithFlags } from 'generated/models';

export const parseProxyDetail = (data: ProxyWithFlags): FormProxyDetailType => {
  const {
    can_be_notified = false,
    can_be_revoked = false,
    proxy: { id, form_role },
  } = data;

  return {
    id,
    canBeNotified: can_be_notified,
    canBeRevoked: can_be_revoked,
    formRole: form_role,
  };
};

export const parseCollaborator = (data: Collaborator): FormCollaboratorType => {
  const {
    can_answer = false,
    can_review = false,
    can_be_revoked = false,
    proxy_detail,
    user,
  } = data;

  const parsedUser = parseUser({ user });

  return {
    id: parsedUser.id,
    canAnswer: can_answer,
    canReview: can_review,
    canBeRevoked: can_be_revoked,
    isProxy: proxy_detail.is_proxy,
    // endpoint returns personalised list of proxies
    // flag value (canReview etc.) is set based on a user who called endpoint
    proxies: proxy_detail.proxies
      .filter(p => p.can_be_revoked)
      .map(parseProxyDetail),
    user: parsedUser,
  };
};
