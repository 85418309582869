import React, { useState } from 'react';
import ReactSVG from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

import {
  EmailTemplate,
  Description,
  RequestLinks,
  RequestLink,
  RequestTitle,
  RequestInfoWrapper,
} from './FirstContact.styles';

import {
  CloseButton,
  ContentWrapper,
  CTA,
  CTAButton,
  LogoWrapper,
  Wrapper,
} from '../Cobranding/Cobranding.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export enum FirstContactEmailTemplateEnum {
  NO_EMAIL_TEMPLATE = 0,
  SIGN_UP_EMAIL_TEMPLATE = 1,
  LINK_CREATED_EMAIL_TEMPLATE = 2,
}

export interface FirstContactProps {
  emailTemplateType?: FirstContactEmailTemplateEnum;
  requestLinks?: string[][];
  closeDialog: () => void;
  copyToClipboard: (text: string, firstContact?: boolean) => void;
}

interface FirstContactViewProps {
  emailTemplateType: FirstContactEmailTemplateEnum;
  requestLinks?: string[][];
  copyToClipboard: (text: string, firstContact?: boolean) => void;
}

const FirstContactView = ({
  emailTemplateType,
  copyToClipboard,
  requestLinks,
}: FirstContactViewProps) => {
  const [emailTemplateState, updateEmailTemplateState] = useState(
    FirstContactEmailTemplateEnum.NO_EMAIL_TEMPLATE,
  );

  const { t } = useTranslation('FirstContact');

  const strippedHtml = t('signUpEmailTemplate').replace(/<[^>]+>/g, '');

  const getEmailTemplate = () => {
    switch (emailTemplateState) {
      case FirstContactEmailTemplateEnum.SIGN_UP_EMAIL_TEMPLATE:
        return (
          <>
            <EmailTemplate>
              {ReactHtmlParser(t('signUpEmailTemplate') as string)}
            </EmailTemplate>
            <CTAButton
              data-pendo="copy-lead-in-template"
              onClick={() =>
                copyToClipboard(ReactHtmlParser(strippedHtml), true)
              }
            >
              {t('copyClipboard') as string}
            </CTAButton>
          </>
        );
      case FirstContactEmailTemplateEnum.LINK_CREATED_EMAIL_TEMPLATE:
        return (
          <>
            <EmailTemplate>
              {ReactHtmlParser(t('linkCreatedEmailTemplate') as string)}
            </EmailTemplate>
            <RequestLinks>
              {requestLinks && requestLinks.length > 1
                ? requestLinks.map((eachElement: string[]) => (
                    <RequestInfoWrapper>
                      <RequestTitle>{eachElement[0]}</RequestTitle>
                      <RequestLink href={eachElement[1]} target={'_blank'}>
                        {eachElement[1]}
                      </RequestLink>
                    </RequestInfoWrapper>
                  ))
                : null}
            </RequestLinks>
            <CTAButton
              data-pendo="copy-lead-in-template"
              onClick={() =>
                copyToClipboard(ReactHtmlParser(strippedHtml), true)
              }
            >
              {t('copyClipboard') as string}
            </CTAButton>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <>
        <CTA>{ReactHtmlParser(t('firstContact') as string)}</CTA>
        <Description>
          {ReactHtmlParser(t('firstContactInfo') as string)}
        </Description>
      </>
      {emailTemplateState !==
      FirstContactEmailTemplateEnum.NO_EMAIL_TEMPLATE ? (
        getEmailTemplate()
      ) : (
        <CTAButton
          data-pendo="view-lead-in-template"
          onClick={() => updateEmailTemplateState(emailTemplateType)}
        >
          {t('viewTemplate') as string}
        </CTAButton>
      )}
    </>
  );
};

const FirstContact = ({
  emailTemplateType,
  requestLinks,
  closeDialog,
  copyToClipboard,
}: FirstContactProps) => (
  <Wrapper>
    <LogoWrapper>
      <div>
        <ReactSVG
          style={{
            width: 200,
          }}
          src="/atlas-product-logo.svg"
        />
      </div>
    </LogoWrapper>
    <ContentWrapper>
      <FirstContactView
        emailTemplateType={
          emailTemplateType
            ? emailTemplateType
            : FirstContactEmailTemplateEnum.SIGN_UP_EMAIL_TEMPLATE
        }
        requestLinks={requestLinks}
        copyToClipboard={copyToClipboard}
      />
    </ContentWrapper>
    <CloseButton
      data-test="modal-close-button"
      data-pendo="dismiss-lead-in-template"
      className="close-button"
      onClick={() => closeDialog()}
    >
      <FontAwesomeIcon icon={faTimes as IconProp} />
    </CloseButton>
  </Wrapper>
);

export default FirstContact;
