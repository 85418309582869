import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  background: ${({ theme }) => theme.basic.colors.black};
  bottom: 0;
  left: 0;
  right: 0;
  color: ${({ theme }) => theme.basic.colors.light};
  padding: 1em 1.8em;
  z-index: 999999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
`;

export const LearnMore = styled.a`
  opacity: 0.8;
  padding-left: 5px;

  &:hover {
    text-decoration: none;
    opacity: 1;
  }
`;

export const Button = styled.button`
  color: ${({ theme }) => theme.basic.colors.black};
  background-color: ${({ theme }) => theme.cookies.primary};
  border-color: transparent;
  width: 140px;
  height: 40px;
  font-size: 1em;
  ${({ theme }) => theme.basic.fontWeights.bold};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.cookies.hover};
  }
`;
