import { ApiCallActionType } from '../../store.types';
import { Evidence } from '../../../generated/models/Evidence';
import { parseFile } from 'utils/parsers';
import { UserStateType } from '../user.types';
import { Uuid } from '../../../common/common.types';
import { answersEvidenceParams } from '../utils';

export interface UserGetFilesParamsType {
  team_id?: Uuid;
}

export type UserGetFilesActionType = ApiCallActionType<
  '@user/API/GET_FILES',
  UserGetFilesParamsType,
  Evidence[],
  never,
  UserStateType
>;

export const getFiles = (teamId?: Uuid): UserGetFilesActionType => ({
  type: '@user/API/GET_FILES',
  payload: {
    callParams: answersEvidenceParams(teamId),
    endpointPath: ['user', 'files', 'get'],
    selector: data => ({
      files: data.map((file: Evidence) =>
        parseFile({
          ...file,
          is_uploading: false,
        }),
      ),
    }),
  },
});
