import styled from 'styled-components/macro';

export const Root = styled.div`
  line-height: 10px;

  svg {
    width: 20px;
    height: 20px;
  }
`;
