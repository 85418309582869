import i18n from '../../../i18n';
import { Attachment } from '../../../generated/models/Attachment';
import update from 'immutability-helper';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { hydrateFilters } from '.';
import { parseAttachment } from 'utils/parsers';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { Uuid } from '../../../common/common.types';

export type FormsCreateResponseAttachmentFunctionType = (
  formData: FormData,
  formId: Uuid,
  questionId: Uuid,
  responseId: Uuid,
) => void;

export interface FormsCreateResponseAttachmentCallParamsType {
  formData: FormData;
  formId: Uuid;
  questionId: Uuid;
  responseId: Uuid;
}

export type FormsCreateResponseAttachmentActionType = ApiCallActionType<
  '@forms/API/CREATE_RESPONSE_ATTACHMENT',
  FormsCreateResponseAttachmentCallParamsType,
  Attachment,
  never,
  FormsStateType
>;

export const createResponseAttachment = (
  formData: FormData,
  formId: Uuid,
  questionId: Uuid,
  responseId: Uuid,
): FormsCreateResponseAttachmentActionType => ({
  type: '@forms/API/CREATE_RESPONSE_ATTACHMENT',
  payload: {
    callParams: { formData, formId, questionId, responseId },
    endpointPath: ['forms', 'questions', 'addResponseAttachment'],
    selector: (data, { forms }) => {
      const attachment = parseAttachment(data, () => responseId);

      return update(forms, {
        currentForm: {
          questions: {
            [questionId]: {
              attachments: { $merge: { [data.id]: attachment } },
              responses: {
                [responseId]: {
                  attachments: { $merge: { [data.id]: attachment } },
                },
              },
            },
          },
        },
      });
    },
    postActions: [
      () => put(hydrateFilters(formId)),
      () => call(() => trackEvent('Attachment', 'Add - Response')),
      () => put(flash(i18n.t('common:responses.fileCreateSuccess'))),
    ],
  },
});
