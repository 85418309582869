import styled from 'styled-components';

export const Heading = styled.div`
  ${({ theme }) => theme.basic.fontWeights.bold};
`;

export const Input = styled.input`
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  min-height: 38px;
  padding: 2px 8px;
  margin: 5px 0;
  ${({ theme }) => theme.fonts.regular.normal}
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.atlas.grey[300]};
  }
`;

export const Content = styled.div`
  margin: 25px 45px;
  min-width: 540px;
`;

export const Footer = styled.div`
  padding: 25px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.basic.colors.darkerBorder};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;

  > button + button {
    margin-left: 25px;
  }
`;

export const RadioOptions = styled.div`
  margin: 25px;
  display: flex;
  flex-direction: column;

  > label + label {
    margin-top: 20px;
  }
`;
