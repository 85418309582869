import styled from 'styled-components/macro';

export const ColumnsWrapper = styled.div`
  display: flex;
  padding: 20px;
`;

export const Column = styled.div`
  width: 25%;
`;

export const ColumnHeading = styled.h3`
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.bold.normal}
  margin-bottom: 30px;
`;
