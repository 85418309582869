import React, { useState, useEffect } from 'react';
import { TextContainer } from './Textarea.styles';
import { VisualState } from './Textarea.types';

interface TextareaProps {
  className?: string;
  height?: number;
  isClearable?: boolean;
  isDisabled?: boolean;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  placeholder?: string;
  suffix?: string;
  value?: string;
  visualState?: VisualState;
}

const Textarea = ({
  className,
  isDisabled = false,
  onBlur,
  onChange,
  placeholder,
  value,
  visualState = 'normal',
}: TextareaProps) => {
  const [currentValue, setValue] = useState(value || '');

  useEffect(() => {
    setValue(value || '');
  }, [value]);

  const handleChange = ({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
    const change = target.value;
    setValue(change);

    if (onChange) {
      onChange(change);
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur(currentValue);
    }
  };

  return (
    <TextContainer
      className={className}
      disabled={isDisabled}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={placeholder}
      value={currentValue}
      visualState={visualState}
    />
  );
};

export default Textarea;
