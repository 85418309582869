import styled from 'styled-components';

const MAX_BAR_WIDTH = 224;

const getPx = (percentage: number) => `${percentage * MAX_BAR_WIDTH - 1}px`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 18px;
  margin-left: 75px;
`;

export const Summary = styled.div`
  display: flex;
  width: ${`${MAX_BAR_WIDTH}px`};
  margin-left: 20px;
  margin-top: 5px;

  div {
    margin-left: 1px;

    &:first-of-type {
      border-radius: 2px 0px 0px 2px;
      margin-left: 0;
      padding-left: 1px;
    }

    &:last-of-type {
      background: ${({ theme }) => theme.atlas.teal.primary};
      border-radius: 0px 2px 2px 0px;
    }
  }
`;

export const Bar = styled.div<{ color: string; percentage: number }>`
  background: ${({ color }) => color};
  width: ${({ percentage }) => getPx(percentage)};
  height: 7px;
`;
