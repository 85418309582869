import { assocPath, compose, prepend } from 'ramda';
import { parseStandard } from 'utils/parsers';
import { ApiCallActionType } from '../../store.types';
import { StandardStateType } from '../reducer';
import { StandardType, Uuid } from '../../../common/common.types';

export interface SaveStandardCallParamsType {
  id: Uuid;
  reqData: {
    confirm: true;
    name: string;
  };
}

export type SaveStandardFunctionType = (id: Uuid, name: string) => void;

export type SaveStandardActionType = ApiCallActionType<
  '@standard/API/PUT',
  SaveStandardCallParamsType,
  any,
  never,
  StandardStateType
>;

const removeExtension = (name: string): string => {
  return name.replace(/\.[^/.]+$/, '');
};

export const saveStandard = (
  id: Uuid,
  name: string,
): SaveStandardActionType => ({
  type: '@standard/API/PUT',
  payload: {
    endpointPath: ['standards', 'save'],
    callParams: {
      id,
      reqData: {
        confirm: true,
        name: removeExtension(name),
      },
    },
    selector: (data, state) => {
      return compose(
        assocPath<StandardType[], StandardStateType>(
          ['all', 'mostRecent'],
          prepend(parseStandard(data.standard), state.standard.all.mostRecent),
        ),
        assocPath(
          ['all', 'all'],
          prepend(parseStandard(data.standard), state.standard.all.all),
        ),
      )(state.standard);
    },
  },
});
