import React from 'react';
import {
  Button,
  ProgressionWrapper,
  Text,
  Wrapper,
} from './Autocomplete.styles';
import { Trans, withTranslation } from 'react-i18next';
import MatchIntro from './MatchIntro';
import { TranslatePropsType } from 'common/common.types';
import { FormsStartAutocompleteFunctionType } from 'store/forms/actions';

interface MatchesFoundPropsType extends TranslatePropsType {
  matchingQuestionsCount: number;
  startAutocomplete: FormsStartAutocompleteFunctionType;
}

const MatchesFound = ({
  matchingQuestionsCount,
  startAutocomplete,
  t,
}: MatchesFoundPropsType) => (
  <Wrapper>
    <MatchIntro />

    <ProgressionWrapper>
      <Trans
        i18nKey="matchesFound"
        count={matchingQuestionsCount}
        parent={Text}
        t={t}
      >
        We found matching answers for <strong>count question</strong>.
      </Trans>

      <Button onClick={startAutocomplete}>{t('autocomplete') as string}</Button>
    </ProgressionWrapper>
  </Wrapper>
);

export default withTranslation('Autocomplete')(MatchesFound);
