import {
  QuestionDefinitionSummaryType,
  FormDefinitionDraftCategoryStatsType,
} from '../../../common/common.types';
import update from 'immutability-helper';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';
import { map } from 'ramda';
import getFirstAvailableCategoryName from '../utils/getFirstAvailableCategoryName';

export type RenameCategoryFunctionType = (
  oldName: string,
  newName: string,
) => void;

export type RenameCategoryActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/RENAME_CATEGORY'
>;

export const renameCategory = (
  oldName: string,
  newName: string,
): RenameCategoryActionType => {
  let tempNewName = newName;

  return {
    type: '@formCreator/EVENTS/RENAME_CATEGORY',
    payload: {
      events: ({ formCreator }) => {
        tempNewName = getFirstAvailableCategoryName(
          formCreator.currentForm!.categories,
          newName,
        );
        return [
          {
            action: FormEditEventActionCodeEnum.UPDATECATEGORY,
            old_name: oldName,
            new_name: tempNewName,
          },
        ];
      },
      selector: ({ formCreator }) =>
        update(formCreator, {
          currentForm: {
            categories: {
              $apply: (categories: FormDefinitionDraftCategoryStatsType[]) =>
                categories.map(category =>
                  category.name === oldName
                    ? { ...category, name: tempNewName }
                    : category,
                ),
            },
            questions: {
              $apply: map((question: QuestionDefinitionSummaryType) =>
                question.category === oldName
                  ? { ...question, category: tempNewName }
                  : question,
              ),
            },
          },
          openedQuestion: {
            $apply: openedQuestion =>
              openedQuestion
                ? update(openedQuestion, { $set: { categoryName: newName } })
                : undefined,
          },
        }),
    },
  };
};
