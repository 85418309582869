import { ApiCallActionType } from '../../store.types';
import { Uuid } from '../../../common/common.types';
import formsApi from '../../../api/new/formsApi';
import { FormCreate, FormDetail } from '../../../generated/new/models';
import formatRoute, { FORM } from '../../../routes';
import { withHashUrl } from 'utils/url/withHashUrl';
import { put } from 'redux-saga/effects';
import { startLoading } from '../../app/actions';

export type FormsGetMasterActionType = ApiCallActionType<
  '@forms/API/GET_MASTER_FORM',
  FormCreate,
  FormDetail,
  null
>;

export const getMasterForm = (id: Uuid): FormsGetMasterActionType => ({
  type: '@forms/API/GET_MASTER_FORM',
  payload: {
    callParams: { standardId: id },
    endpointPath: () =>
      formsApi.postFormsMasterResource({ payload: { standardId: id } }),
    selector: null,
    preActions: [put(startLoading(2))],
    postActions: (data: any) => {
      window.location.href = withHashUrl(formatRoute(FORM, { id: data.id }));
      return [];
    },
  },
});
