import { EventSequencerStateType } from './eventSequencer.types';

export const isInErrorState = ({ error }: EventSequencerStateType) =>
  typeof error !== 'undefined';

export const isConflictDialogVisible = ({ error }: EventSequencerStateType) =>
  typeof error !== 'undefined' && error.type === 'conflict';

export const isOtherError = ({ error }: EventSequencerStateType) =>
  typeof error !== 'undefined' && error.type === 'other';
