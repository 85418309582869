import React, { useState } from 'react';
import { Assignees } from '.';
import { SharedFormPageContextType } from 'pages/SharedFormPage';
import { PageContextConsumer } from 'pages/Page';
import { contains } from 'ramda';
import {
  getAssigneesIds,
  getQuestionAssigneesTooltip,
  getHasAssignees,
} from './utils';
import { withTranslation } from 'react-i18next';
import { FormPageContextType } from 'pages/FormPage';
import {
  faUser,
  faPlus,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import {
  FormAssignee,
  PromptInviteDelegationType,
  QuestionType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import {
  AssignTo,
  AssigneeRow,
  ChevronIcon,
  Multiple,
  Person,
  PlusIcon,
  RelativeContainer,
  Single,
  UserIcon,
  Wrapper,
} from './Delegator.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface QuestionDelegatorPropsType extends TranslatePropsType {
  assignees: FormAssignee[];
  canInvite: boolean;
  onInviteUserClick: (
    e: React.MouseEvent<HTMLElement>,
    prompt: PromptInviteDelegationType,
  ) => void;
  question: QuestionType;
}

const QuestionDelegator = ({
  assignees,
  canInvite,
  onInviteUserClick,
  question,
  question: { id, formId, delegations },
  t,
}: QuestionDelegatorPropsType) => {
  const [isDelegatorOpen, setDelegatorOpen] = useState(false);
  const assigneesIds = getAssigneesIds(delegations);
  const hasAssignees = getHasAssignees(delegations);

  const handleInviteUserClick = (e: React.MouseEvent<HTMLElement>) => {
    onInviteUserClick(e, { question });

    setDelegatorOpen(false);
  };

  return (
    <PageContextConsumer>
      {({
        actions: { assignQuestionDelegation, unassignQuestionDelegation },
      }: FormPageContextType | SharedFormPageContextType) => {
        const handleAssignClick = (
          e: React.MouseEvent<HTMLElement>,
          userId: Uuid,
        ) => {
          if (assignQuestionDelegation && !contains(userId, assigneesIds)) {
            assignQuestionDelegation(
              { type: 'id', payload: userId },
              id,
              formId,
            );
          }
        };

        const handleUnassignClick = (e: React.MouseEvent<HTMLElement>) => {
          if (unassignQuestionDelegation && hasAssignees) {
            unassignQuestionDelegation(id, formId);
          }
        };

        return (
          <Wrapper isOpened={isDelegatorOpen} data-cy="question-delegator">
            <RelativeContainer>
              <AssigneeRow
                hasAssignees={hasAssignees}
                onClick={() => setDelegatorOpen(!isDelegatorOpen)}
                data-test="assign-to-question"
              >
                {hasAssignees ? (
                  <Person title={getQuestionAssigneesTooltip(delegations)}>
                    {t('assignedTo') as string}

                    {delegations.length === 1 ? (
                      <Single>{delegations[0].assignee.displayName}</Single>
                    ) : (
                      <Multiple>{t('multiple') as string}</Multiple>
                    )}

                    <ChevronIcon icon={faChevronDown as IconProp} />
                  </Person>
                ) : (
                  <AssignTo>{t('assignTo') as string}</AssignTo>
                )}

                <UserIcon icon={faUser as IconProp} />

                {!hasAssignees && <PlusIcon icon={faPlus as IconProp} />}
              </AssigneeRow>

              {isDelegatorOpen && (
                <Assignees
                  assign={handleAssignClick}
                  assignees={assignees}
                  assigneesIds={assigneesIds}
                  canInvite={canInvite}
                  hasRemove={hasAssignees}
                  invite={handleInviteUserClick}
                  setDelegatorOpen={setDelegatorOpen}
                  unassign={handleUnassignClick}
                />
              )}
            </RelativeContainer>
          </Wrapper>
        );
      }}
    </PageContextConsumer>
  );
};

export default withTranslation('Delegator')(QuestionDelegator);
