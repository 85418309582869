import React from 'react';
import { Symbol, TextInput } from './PercentageResponse.styles';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { ResponseComponentPropsType } from '../Response';
import { HorizontallyCentered } from 'common/common.styles';

interface PercentageResponsePropsType
  extends ResponseComponentPropsType,
    TranslatePropsType {}

const PercentageResponse = ({ t }: PercentageResponsePropsType) => (
  <HorizontallyCentered>
    <TextInput
      isDisabled
      placeholder={t('percentagePlaceholder') as string}
      value={''}
    />
    <Symbol>%</Symbol>
  </HorizontallyCentered>
);

export default withTranslation('FormCreator')(PercentageResponse);
