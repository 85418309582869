export interface ToggleHeaderActionType {
  type: '@ui/TOGGLE_HEADER';
  payload: {
    isVisible: boolean;
  };
}

export type ToggleHeaderFunctionType = (isVisible: boolean) => void;

export const toggleHeader = (isVisible: boolean): ToggleHeaderActionType => ({
  type: '@ui/TOGGLE_HEADER',
  payload: {
    isVisible,
  },
});
