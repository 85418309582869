import uuid from '../uuid';
import { ParsedFileDetails } from 'generated/models/ParsedFileDetails';
import { ParsedFileType, ParsingMessageType } from 'common/common.types';
import {
  ParsingMessage,
  ParsingMessageSeverityEnum,
} from 'generated/models/ParsingMessage';

export const parseParsingMessage = (
  parsingMessage: ParsingMessage,
): ParsingMessageType => {
  const {
    severity = ParsingMessageSeverityEnum.INFO,
    text = '',
    text_id,
  } = parsingMessage;

  return {
    id: uuid(),
    severity,
    message: text,
    messageKey: text_id,
  };
};

export const parseParsedFile = (
  parsedFile: ParsedFileDetails,
): ParsedFileType => {
  const { file_name = '', parsing_messages = [] } = parsedFile;

  return {
    fileName: file_name,
    formId: null,
    parsingMessages: parsing_messages.map(message =>
      parseParsingMessage(message),
    ),
  };
};
