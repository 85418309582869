import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import hex2rgba from 'utils/hex2rgba';

export const UserIcon = styled(FontAwesomeIcon)`
  margin: 0 0 0 5px;
`;

export const PlusIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.basic.colors.darkGray};
  margin: 0 0 0 1px;
  padding: 2px;
`;

export const ChevronIcon = styled(FontAwesomeIcon)`
  padding: 1px;
`;

export const AssigneeRow = styled.div<{ hasAssignees: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${UserIcon} {
    color: ${({
      theme: {
        basic: { colors },
      },
      hasAssignees,
    }) => (hasAssignees ? colors.darkGray : colors.gray)};
  }
`;

export const AssignTo = styled.div`
  transition: all 0.1s;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Wrapper = styled.div<{ isOpened: boolean }>`
  position: absolute;
  right: 0;
  cursor: pointer;
  ${({ theme }) => theme.fonts.regular.small}

  &:hover ${UserIcon} {
    color: ${({ theme }) => theme.basic.colors.darkGray};
  }

  &:hover ${AssignTo} {
    opacity: 1;
  }

  ${AssignTo} {
    opacity: ${({ isOpened }) => (isOpened ? 1 : 0)};
  }
`;

export const WrapperAlwaysOn = styled.div`
  cursor: pointer;
  ${({ theme }) => theme.basic.fontWeights.normal}
  margin: 12px 30px 0 0;
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const Single = styled.span`
  ${({ theme }) => theme.fonts.semibold.small}
  display: inline-block;
  text-decoration: underline;
  padding: 0 4px;
  text-overflow: ellipsis;
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
`;

export const Multiple = styled.span`
  padding: 0 4px;
  ${({ theme }) => theme.fonts.semibold.small}
  color: ${({ theme }) => hex2rgba(theme.basic.colors.dark, 0.5)};
`;

export const Assignee = styled.div`
  text-align: right;
  padding: 0 5px;

  &::first-letter {
    text-transform: uppercase;
  }

  ${Single}, ${Multiple} {
    padding: 0;
  }
`;

export const Person = styled.div`
  display: flex;
  align-items: center;
`;
