import { ApiCallActionType } from '../../store.types';
import { TaggedType, Uuid } from '../../../common/common.types';
import { call, put } from 'redux-saga/effects';
import { getCompanies } from './getCompanies';
import { getCompany } from './getCompany';
import { getTags } from './getTags';
import { trackEvent } from 'utils/analytics';

export interface CompaniesRemoveTagCallParamsType {
  tagId: Uuid;
  companyId: Uuid;
}

export type CompaniesRemoveTagActionType = ApiCallActionType<
  '@companies/API/REMOVE_TAG',
  CompaniesRemoveTagCallParamsType,
  any,
  never,
  any
>;

export const removeTag = (
  tagged: TaggedType,
): CompaniesRemoveTagActionType => ({
  type: '@companies/API/REMOVE_TAG',
  payload: {
    endpointPath: ['companies', 'tags', 'remove'],
    callParams: { tagId: tagged.tag.id, companyId: tagged.targetId },
    selector: (data: any) => ({ detail: { tags: [data] } }),
    postActions: [
      () => call(() => trackEvent('Tag', 'Remove')),
      () => put(getTags()),
      () => put(getCompany(tagged.targetId)),
      () => put(getCompanies({ shouldRefreshFilter: false })),
    ],
  },
});
