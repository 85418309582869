/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    FormShortSummary,
    FormShortSummaryFromJSON,
    FormShortSummaryFromJSONTyped,
    FormShortSummaryToJSON,
    QuestionDetailBase,
    QuestionDetailBaseFromJSON,
    QuestionDetailBaseFromJSONTyped,
    QuestionDetailBaseToJSON,
    RequestSummary,
    RequestSummaryFromJSON,
    RequestSummaryFromJSONTyped,
    RequestSummaryToJSON,
    Standard,
    StandardFromJSON,
    StandardFromJSONTyped,
    StandardToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormDetail
 */
export interface FormDetail {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof FormDetail
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof FormDetail
     */
    updatedAt: Date;
    /**
     * Description of the form
     * @type {string}
     * @memberof FormDetail
     */
    description: string;
    /**
     * Name of the form displayed in the GUI (usually corresponds to the standard name)
     * @type {string}
     * @memberof FormDetail
     */
    displayName: string;
    /**
     * - `MASTER` form represents form with preferred responses to existing standards.
     *   It is considered as part of the knowledge base.
     *   Every company has exactly one MASTER form for:
     *   - every Standard - SYSTEM
     *   - every Standard - CUSTOM - if they have received request with it
     * 
     * - `UPLOAD` form represents uploaded file from which questions and responses have been
     *   extracted. It is considered as part of the knowledge base.
     * 
     * - `REQUEST` form represents form associated with Request.W
     *   It is always associated with some standard.
     * 
     * - `MAPPING_SPLIT` form is deprecated. **DO NOT USE.**
     *   It used to represent temporary form that was created for the purpose of mapping.
     * 
     * - `SNAPSHOT` form represents read only version of some other form.
     *   It is created when MASTER form is shared or for storing SSC Issues
     * 
     * - `PREVIEW` form is used in Send Request flow to see how the form will look like for Vendor
     *   Every company has exactly one PREVIEW form for:
     *   - every Standard - SYSTEM
     *   - every Standard - CUSTOM - they own
     * 
     * - `DELETED` form is deleted form.
     *   It should not be shown to users and not used for anything
     * 
     * - `UPLOAD_MERGED` form represents form that was uploaded and then was merged.
     *   It starts as form of type UPLOAD. If user uploaded in the past exactly the same form then
     *   responses from this form are used to update the first form. After that it changes its
     *   status to UPLOAD_MERGED
     * @type {string}
     * @memberof FormDetail
     */
    fillingType: FormDetailFillingTypeEnum;
    /**
     * Indicates an association with an evidence locker entity
     * @type {boolean}
     * @memberof FormDetail
     */
    hasEvidenceLockerEntity: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormDetail
     */
    id: string;
    /**
     * Name of the form
     * @type {string}
     * @memberof FormDetail
     */
    name: string;
    /**
     * ID of the owner company (usually the vendor company)
     * @type {string}
     * @memberof FormDetail
     */
    ownerId: string;
    /**
     * ID of the related standard
     * @type {string}
     * @memberof FormDetail
     */
    standardId: string;
    /**
     * Number of form-level attachments
     * @type {number}
     * @memberof FormDetail
     */
    attachmentCount: number;
    /**
     * True if the current user is allowed to add and remove form-level attachments.
     * 
     * **DEPRECATED:** Use `permissions.ATTACHMENT_FORM_*` permissions instead.
     * @type {boolean}
     * @memberof FormDetail
     */
    attachmentModifiable: boolean;
    /**
     * List of form-level attachments
     * @type {Array<Attachment>}
     * @memberof FormDetail
     */
    attachments: Array<Attachment>;
    /**
     * Status for form filling initialization (creation).
     * 
     * - `EMPTY` - The form_filling is currently not in our system yet and is waiting to be put into
     *   queue so a worker can take care of form_filling creation process.
     * - `FINISHED_SUCCESS` - The form_filling has been created with a successful status.
     * - `IN_QUEUE` - The form_filling is currently waiting in the queue to be taken by a worker.
     * - `IN_PROGRESS` - The form_filling is currently being worked on by a worker.
     * - `FINISHED_FAIL` - The form_filling has been finished but the creation process failed.
     * @type {string}
     * @memberof FormDetail
     */
    initializationStatus: FormDetailInitializationStatusEnum;
    /**
     * True if the form can be edited and filled with answers by the current user
     * @type {boolean}
     * @memberof FormDetail
     */
    isEditable: boolean;
    /**
     * True if the current user's company is owner of this form
     * @type {boolean}
     * @memberof FormDetail
     */
    isOwner: boolean;
    /**
     * True if the form can be submitted (e.g. all required fields are filled)
     * @type {boolean}
     * @memberof FormDetail
     */
    isSubmittable: boolean;
    /**
     * ID of form into which this form was merged.
     * @type {string}
     * @memberof FormDetail
     */
    mergedToFormId?: string;
    /**
     * Company that owns the form (usually the vendor company)
     * @type {Company}
     * @memberof FormDetail
     */
    owner: Company;
    /**
     * Percentage of filled responses. It includes all required responses and all responses in
     * questions without a single required response. It doesn't include optional responses in
     * questions with one or more required responses.
     * @type {number}
     * @memberof FormDetail
     */
    percentageFilled: number;
    /**
     * List of permissions related to this form for the current user
     * @type {Array<string>}
     * @memberof FormDetail
     */
    permissions: Array<FormDetailPermissionsEnum>;
    /**
     * Number of questions in the form
     * @type {number}
     * @memberof FormDetail
     */
    questionCount: number;
    /**
     * Number of questions in the form without taking visibility into account
     * @type {number}
     * @memberof FormDetail
     */
    questionCountIgnoreVisibility: number;
    /**
     * List of questions in the form (might be `null` depending from witch endpoint this model
     * is fetched)
     * @type {Array<QuestionDetailBase>}
     * @memberof FormDetail
     */
    questions?: Array<QuestionDetailBase>;
    /**
     * Number of questions that have all responses filled
     * @type {number}
     * @memberof FormDetail
     */
    questionsCompletelyFilled: number;
    /**
     * Number of questions that are either completely or partially filled
     * @type {number}
     * @memberof FormDetail
     */
    questionsFilled: number;
    /**
     * Number of questions that have at least one response and not all responses filled
     * @type {number}
     * @memberof FormDetail
     */
    questionsPartiallyFilled: number;
    /**
     * Associated form filling request
     * @type {RequestSummary}
     * @memberof FormDetail
     */
    request?: RequestSummary;
    /**
     * ID of the associated form filling request
     * @type {string}
     * @memberof FormDetail
     */
    requestId?: string;
    /**
     * Number of responses in the form
     * @type {number}
     * @memberof FormDetail
     */
    responseCount: number;
    /**
     * Number of responses in the form without taking visibility into account
     * @type {number}
     * @memberof FormDetail
     */
    responseCountIgnoreVisibility: number;
    /**
     * Number of responses filled in the form
     * @type {number}
     * @memberof FormDetail
     */
    responsesFilled: number;
    /**
     * Normalized score between 0 and 100 computed for the current answers
     * @type {number}
     * @memberof FormDetail
     */
    score: number;
    /**
     * Maximal achievable score that corresponds to normalized score == 100
     * @type {number}
     * @memberof FormDetail
     */
    scoreLimit: number;
    /**
     * Current score that is used in computation of normalized score
     * @type {number}
     * @memberof FormDetail
     */
    scoreRaw: number;
    /**
     * True if the score should be visible (usually for VRM)
     * @type {boolean}
     * @memberof FormDetail
     */
    scoreVisible: boolean;
    /**
     * Standard related to the form
     * @type {Standard}
     * @memberof FormDetail
     */
    standard: Standard;
    /**
     * Number of questions with at least one attachment
     * @type {number}
     * @memberof FormDetail
     */
    withAttachmentCount: number;
    /**
     * Number of questions with at least one comment
     * @type {number}
     * @memberof FormDetail
     */
    withCommentCount: number;
    /**
     * Number of questions with N/A answer
     * @type {number}
     * @memberof FormDetail
     */
    withNaCount: number;
    /**
     * Number of questions with NO answer
     * @type {number}
     * @memberof FormDetail
     */
    withNoCount: number;
    /**
     * Number of question with at least one required response (not necessarily answered)
     * @type {number}
     * @memberof FormDetail
     */
    withRequiredCount: number;
    /**
     * Number of questions with at least one required response that have all their required
     * responses answered
     * @type {number}
     * @memberof FormDetail
     */
    withRequiredFilledCount: number;
    /**
     * Number of questions with at least one SSC issue found
     * @type {number}
     * @memberof FormDetail
     */
    withSscIssueCount: number;
    /**
     * Number of questions with VRM review status == DONE
     * @type {number}
     * @memberof FormDetail
     */
    withVrmReviewStatusDoneCount: number;
    /**
     * Number of questions with VRM review status == FLAGGED
     * @type {number}
     * @memberof FormDetail
     */
    withVrmReviewStatusFlaggedCount: number;
    /**
     * Number of questions with VRM review status == PENDING
     * @type {number}
     * @memberof FormDetail
     */
    withVrmReviewStatusPendingCount: number;
    /**
     * Number of questions with YES answer
     * @type {number}
     * @memberof FormDetail
     */
    withYesCount: number;
}

export function FormDetailFromJSON(json: any): FormDetail {
    return FormDetailFromJSONTyped(json, false);
}

export function FormDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'description': json['description'],
        'displayName': json['display_name'],
        'fillingType': json['filling_type'],
        'hasEvidenceLockerEntity': json['has_evidence_locker_entity'],
        'id': json['id'],
        'name': json['name'],
        'ownerId': json['owner_id'],
        'standardId': json['standard_id'],
        'attachmentCount': json['attachment_count'],
        'attachmentModifiable': json['attachment_modifiable'],
        'attachments': ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'initializationStatus': json['initialization_status'],
        'isEditable': json['is_editable'],
        'isOwner': json['is_owner'],
        'isSubmittable': json['is_submittable'],
        'mergedToFormId': !exists(json, 'merged_to_form_id') ? undefined : json['merged_to_form_id'],
        'owner': CompanyFromJSON(json['owner']),
        'percentageFilled': json['percentage_filled'],
        'permissions': json['permissions'],
        'questionCount': json['question_count'],
        'questionCountIgnoreVisibility': json['question_count_ignore_visibility'],
        'questions': !exists(json, 'questions') ? undefined : ((json['questions'] as Array<any>).map(QuestionDetailBaseFromJSON)),
        'questionsCompletelyFilled': json['questions_completely_filled'],
        'questionsFilled': json['questions_filled'],
        'questionsPartiallyFilled': json['questions_partially_filled'],
        'request': !exists(json, 'request') ? undefined : RequestSummaryFromJSON(json['request']),
        'requestId': !exists(json, 'request_id') ? undefined : json['request_id'],
        'responseCount': json['response_count'],
        'responseCountIgnoreVisibility': json['response_count_ignore_visibility'],
        'responsesFilled': json['responses_filled'],
        'score': json['score'],
        'scoreLimit': json['score_limit'],
        'scoreRaw': json['score_raw'],
        'scoreVisible': json['score_visible'],
        'standard': StandardFromJSON(json['standard']),
        'withAttachmentCount': json['with_attachment_count'],
        'withCommentCount': json['with_comment_count'],
        'withNaCount': json['with_na_count'],
        'withNoCount': json['with_no_count'],
        'withRequiredCount': json['with_required_count'],
        'withRequiredFilledCount': json['with_required_filled_count'],
        'withSscIssueCount': json['with_ssc_issue_count'],
        'withVrmReviewStatusDoneCount': json['with_vrm_review_status_done_count'],
        'withVrmReviewStatusFlaggedCount': json['with_vrm_review_status_flagged_count'],
        'withVrmReviewStatusPendingCount': json['with_vrm_review_status_pending_count'],
        'withYesCount': json['with_yes_count'],
    };
}

export function FormDetailToJSON(value?: FormDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'description': value.description,
        'display_name': value.displayName,
        'filling_type': value.fillingType,
        'has_evidence_locker_entity': value.hasEvidenceLockerEntity,
        'id': value.id,
        'name': value.name,
        'owner_id': value.ownerId,
        'standard_id': value.standardId,
        'attachment_count': value.attachmentCount,
        'attachment_modifiable': value.attachmentModifiable,
        'attachments': ((value.attachments as Array<any>).map(AttachmentToJSON)),
        'initialization_status': value.initializationStatus,
        'is_editable': value.isEditable,
        'is_owner': value.isOwner,
        'is_submittable': value.isSubmittable,
        'merged_to_form_id': value.mergedToFormId,
        'owner': CompanyToJSON(value.owner),
        'percentage_filled': value.percentageFilled,
        'permissions': value.permissions,
        'question_count': value.questionCount,
        'question_count_ignore_visibility': value.questionCountIgnoreVisibility,
        'questions': value.questions === undefined ? undefined : ((value.questions as Array<any>).map(QuestionDetailBaseToJSON)),
        'questions_completely_filled': value.questionsCompletelyFilled,
        'questions_filled': value.questionsFilled,
        'questions_partially_filled': value.questionsPartiallyFilled,
        'request': RequestSummaryToJSON(value.request),
        'request_id': value.requestId,
        'response_count': value.responseCount,
        'response_count_ignore_visibility': value.responseCountIgnoreVisibility,
        'responses_filled': value.responsesFilled,
        'score': value.score,
        'score_limit': value.scoreLimit,
        'score_raw': value.scoreRaw,
        'score_visible': value.scoreVisible,
        'standard': StandardToJSON(value.standard),
        'with_attachment_count': value.withAttachmentCount,
        'with_comment_count': value.withCommentCount,
        'with_na_count': value.withNaCount,
        'with_no_count': value.withNoCount,
        'with_required_count': value.withRequiredCount,
        'with_required_filled_count': value.withRequiredFilledCount,
        'with_ssc_issue_count': value.withSscIssueCount,
        'with_vrm_review_status_done_count': value.withVrmReviewStatusDoneCount,
        'with_vrm_review_status_flagged_count': value.withVrmReviewStatusFlaggedCount,
        'with_vrm_review_status_pending_count': value.withVrmReviewStatusPendingCount,
        'with_yes_count': value.withYesCount,
    };
}

/**
* @export
* @enum {string}
*/
export enum FormDetailFillingTypeEnum {
    DELETED = 'DELETED',
    MAPPINGSPLIT = 'MAPPING_SPLIT',
    MASTER = 'MASTER',
    PREVIEW = 'PREVIEW',
    REQUEST = 'REQUEST',
    SNAPSHOT = 'SNAPSHOT',
    UPLOAD = 'UPLOAD',
    UPLOADMERGED = 'UPLOAD_MERGED'
}
/**
* @export
* @enum {string}
*/
export enum FormDetailInitializationStatusEnum {
    EMPTY = 'EMPTY',
    FINISHEDFAIL = 'FINISHED_FAIL',
    FINISHEDSUCCESS = 'FINISHED_SUCCESS',
    INPROGRESS = 'IN_PROGRESS',
    INQUEUE = 'IN_QUEUE'
}
/**
* @export
* @enum {string}
*/
export enum FormDetailPermissionsEnum {
    ANSWERLOOKUPLENSESREAD = 'ANSWER_LOOKUP_LENSES_READ',
    ATTACHMENTFORMDELETE = 'ATTACHMENT_FORM_DELETE',
    ATTACHMENTFORMREAD = 'ATTACHMENT_FORM_READ',
    ATTACHMENTFORMWRITE = 'ATTACHMENT_FORM_WRITE',
    ATTACHMENTQUESTIONDELETE = 'ATTACHMENT_QUESTION_DELETE',
    ATTACHMENTQUESTIONREAD = 'ATTACHMENT_QUESTION_READ',
    ATTACHMENTQUESTIONWRITE = 'ATTACHMENT_QUESTION_WRITE',
    COMMENTFORMREAD = 'COMMENT_FORM_READ',
    COMMENTFORMWRITE = 'COMMENT_FORM_WRITE',
    COMMENTQUESTIONREAD = 'COMMENT_QUESTION_READ',
    COMMENTQUESTIONWRITE = 'COMMENT_QUESTION_WRITE',
    FORMACCEPT = 'FORM_ACCEPT',
    FORMAUTOCOMPLETE = 'FORM_AUTOCOMPLETE',
    FORMAUTOFILL = 'FORM_AUTOFILL',
    FORMREAD = 'FORM_READ',
    FORMSCOREREAD = 'FORM_SCORE_READ',
    FORMSUBMIT = 'FORM_SUBMIT',
    FORMUPDATESEEN = 'FORM_UPDATE_SEEN',
    OTHER = 'OTHER',
    OWNER = 'OWNER',
    PROXYDELETE = 'PROXY_DELETE',
    PROXYREAD = 'PROXY_READ',
    PROXYWRITE = 'PROXY_WRITE',
    RESPONSEHISTORYREAD = 'RESPONSE_HISTORY_READ',
    RESPONSEHISTORYREADSINCEFIRSTSUBMIT = 'RESPONSE_HISTORY_READ_SINCE_FIRST_SUBMIT',
    RESPONSEREAD = 'RESPONSE_READ',
    RESPONSEREADAPPROVE = 'RESPONSE_READ_APPROVE',
    RESPONSEREADASK = 'RESPONSE_READ_ASK',
    RESPONSEWRITE = 'RESPONSE_WRITE',
    REVIEWINSTRUCTIONSREAD = 'REVIEW_INSTRUCTIONS_READ',
    SSCSCORESREAD = 'SSC_SCORES_READ',
    UPDATEDUEDATE = 'UPDATE_DUE_DATE',
    VENDOR = 'VENDOR',
    VENDORDELEGATIONASSIGN = 'VENDOR_DELEGATION_ASSIGN',
    VENDORDELEGATIONINVITE = 'VENDOR_DELEGATION_INVITE',
    VENDORDELEGATIONREAD = 'VENDOR_DELEGATION_READ',
    VENDORDELEGATIONUNASSIGN = 'VENDOR_DELEGATION_UNASSIGN',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY',
    VRMREVIEWREAD = 'VRM_REVIEW_READ',
    VRMREVIEWWRITE = 'VRM_REVIEW_WRITE',
    VRMRISKREPORTDELETE = 'VRM_RISK_REPORT_DELETE',
    VRMRISKREPORTREAD = 'VRM_RISK_REPORT_READ',
    VRMRISKREPORTWRITE = 'VRM_RISK_REPORT_WRITE'
}


