import arrayToIdDict from '../arrayToIdDict';
import { FormHistoryType, FormType, SharedFormType } from 'common/common.types';
import { FormsFiltersInitialState } from '../filters/forms.filters';
import { FormsStateType } from 'store/forms/forms.reducer';
import {
  AssigneeCountMetadata,
  FormDetailFillingTypeEnum,
  FormDetailInitializationStatusEnum,
  FormDetailPermissionsEnum as p,
  FormFilterStats,
  FormHistory,
  FormMetadata,
} from 'generated/models';
import { StateType } from 'store/store.types';
import { getUpdatedQuestionsIds } from 'store/forms/forms.selectors';
import { assocPath, isNil, keys, merge } from 'ramda';
import {
  parseAssignees,
  parseAttachment,
  parseCategories,
  parseCompany,
  parseDelegationNotifications,
  parseMappingRequest,
  parseRequest,
  parseStandard,
  parseUser,
} from './';
import hasPermissions from '../arrayHasSome';
import { EMPTY_ID } from 'common/common.constants';
import { AutocompleteExecutionFromJSON } from 'generated/new/models';

const { UPLOAD } = FormDetailFillingTypeEnum;

export const parseSimpleFilterStats = (
  filtersData: FormFilterStats,
  state?: FormsStateType,
) => {
  if (state) {
    const filters = merge(filtersData, {
      newly_autocompleted: getUpdatedQuestionsIds(state).length,
    });

    return keys(filters).reduce(
      (newFilters, filterName) =>
        assocPath([filterName, 'count'], filters[filterName], newFilters),
      state.filters.simple,
    );
  }

  return FormsFiltersInitialState;
};

export const parseFilterStats = (
  filtersData: FormFilterStats,
  assigneesData?: AssigneeCountMetadata[],
  state?: FormsStateType,
) => ({
  assignees: parseAssignees(assigneesData, state),
  simple: parseSimpleFilterStats(filtersData, state),
});

export const parseForm = (data: FormMetadata, state?: StateType): FormType => {
  const {
    attachments = [],
    created_at = 0,
    description = '',
    display_name = '',
    filling_type,
    id = EMPTY_ID,
    score_visible = false,
    is_editable = false,
    is_submittable = false,
    initialization_status = FormDetailInitializationStatusEnum.EMPTY,
    name = '',
    percentage_filled = 0,
    permissions = [],
    question_count = 0,
    questions_completely_filled = 0,
    questions_filled = 0,
    questions_partially_filled = 0,
    response_count = 0,
    responses_filled = 0,
    score = 0,
    updated_at = 0,
  } = data.form;
  const { autocomplete_executions = [], mapping_requests = [] } = data;

  const hasPermission = (...requiredPermissions: p[]) =>
    hasPermissions(permissions, ...requiredPermissions);

  const request = data.form.request
    ? parseRequest(data.form.request)
    : undefined;
  const standard = parseStandard(data.form.standard || {});

  if (request) {
    request.standard = standard;
  }

  const {
    questions_filtered_count = question_count,
    delegation_notification_stats = [],
  } = data;

  const {
    fillable_questions_count,
    fillable_responses_count,
  } = data.autofill_stats;

  const hasCategories = Boolean(data.categories && data.categories.length > 0);
  const hasCurrentForm = state && state.forms.currentForm;
  const hasAutofillStats =
    hasCurrentForm && state!.forms.currentForm!.autofillStats;

  const updatedQuestionsCount = hasAutofillStats
    ? state!.forms.currentForm!.autofillStats.updatedQuestionsCount
    : 0;

  const updatedQuestionsIds = hasAutofillStats
    ? state!.forms.currentForm!.autofillStats.updatedQuestionsIds
    : [];

  return {
    attachments: arrayToIdDict(attachments, parseAttachment),
    autocompleteExecutions: autocomplete_executions.map(
      AutocompleteExecutionFromJSON,
    ),
    autofillStats: {
      fillableQuestionsCount: fillable_questions_count,
      fillableResponsesCount: fillable_responses_count,
      updatedQuestionsCount,
      updatedQuestionsIds,
    },
    categories: hasCategories ? parseCategories(data.categories!) : [],
    createdAt: new Date(created_at),
    delegationNotifications: parseDelegationNotifications(
      delegation_notification_stats,
    ),
    description,
    displayName: display_name,
    fillingType: filling_type,
    id,
    isScoreVisible: score_visible,
    isSubmittable: is_submittable,
    initializationStatus: initialization_status,
    meta: {
      hasCategories,
      isEditable: is_editable,
      isUploadedFromUser: filling_type === UPLOAD,
      permissions,
      questionsLeftCount: question_count - questions_completely_filled,
      wasAlreadyOpenedByVendor: Boolean(
        request && !isNil(request.targetOpenedAt),
      ),
    },
    mappingRequests: mapping_requests.map(parseMappingRequest),
    name,
    owner: parseCompany(data.form.owner || {}),
    percentageFilled: percentage_filled,
    permissions: {
      canSendDelegationInvitation: hasPermission(p.VENDORDELEGATIONINVITE),
      canInviteProxy: hasPermission(p.VRM, p.VRMPROXY, p.VENDOR, p.VENDORPROXY),
      isDelegable: hasPermission(p.VENDORDELEGATIONASSIGN),
      isFormAttachmentDeletable: hasPermission(p.ATTACHMENTFORMDELETE),
      isFormAttachmentReadable: hasPermission(p.ATTACHMENTFORMREAD),
      isFormAttachmentWritable: hasPermission(p.ATTACHMENTFORMWRITE),
      isFormAutocompletable: hasPermission(p.FORMAUTOCOMPLETE),
      isFormAutofillable: hasPermission(p.FORMAUTOFILL),
      isFormCommentReadable: hasPermission(p.COMMENTFORMREAD),
      isFormCommentWritable: hasPermission(p.COMMENTFORMWRITE),
      isFormSubmittable: hasPermission(p.FORMSUBMIT),
      isFormReviewable: hasPermission(p.FORMACCEPT),
      isLookupLensesReadable: hasPermission(p.ANSWERLOOKUPLENSESREAD),
      isQuestionAttachmentDeletable: hasPermission(p.ATTACHMENTQUESTIONDELETE),
      isQuestionAttachmentReadable: true,
      isQuestionAttachmentWritable: hasPermission(p.ATTACHMENTQUESTIONWRITE),
      isQuestionCommentReadable: hasPermission(p.COMMENTQUESTIONREAD),
      isQuestionCommentWritable: hasPermission(p.COMMENTQUESTIONWRITE),
      isResponseEditable: hasPermission(p.RESPONSEWRITE),
      isResponseReadAskable: hasPermission(p.RESPONSEREADASK),
      isResponseReadApprovable: hasPermission(p.RESPONSEREADAPPROVE),
      isResponseReadable: hasPermission(p.RESPONSEREAD),
      isResponseHistoryReadable: hasPermission(p.RESPONSEHISTORYREAD),
      isReviewInstructionsReadable: hasPermission(p.REVIEWINSTRUCTIONSREAD),
      isReviewReadable: hasPermission(p.VRMREVIEWREAD),
      isReviewWritable: hasPermission(p.VRMREVIEWWRITE),
      isRiskReportReadable: hasPermission(p.VRMRISKREPORTREAD),
      isRiskReportWritable: hasPermission(p.VRMRISKREPORTWRITE),
      isRiskReportDeletable: hasPermission(p.VRMRISKREPORTDELETE),
      isSscScoreReadable: hasPermission(p.SSCSCORESREAD),
      isVRM: hasPermission(p.VRM, p.VRMPROXY),
      isVendor: hasPermission(p.VENDOR, p.VENDORPROXY),
    },
    questionsCount: question_count,
    questionsFilteredCount: questions_filtered_count,
    questions: {},
    questionsFilledCount: questions_filled,
    questionsPartiallyFilledCount: questions_partially_filled,
    request,
    responsesCount: response_count,
    responsesFilledCount: responses_filled,
    score: score,
    standard,
    updatedAt: new Date(updated_at),
  };
};

export const parseFormHistory = (payload: FormHistory): FormHistoryType => {
  const { action, data, created_at = 0, user } = payload;

  return {
    action,
    createdAt: new Date(created_at),
    from: data ? data.from : undefined,
    to: data ? data.to : undefined,
    user: parseUser({ user }),
  };
};

export const parseSharedForm = (data: FormMetadata): SharedFormType => {
  const { form, share } = data;

  return {
    formName: form.name,
    owner: parseCompany(form.owner),
    sharedBy: parseUser(share ? { user: share.shared_by } : {}),
  };
};
