import Page, { PageActionsBaseType, PageBaseType, PagePropsType } from './Page';
import React from 'react';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import { startLoading } from 'store/app/actions';
import { getForm } from 'store/forms/actions';
import * as selectors from 'store/forms/forms.selectors';
import { getLanguagePreference } from 'store/settings/settings.selectors';
import { PdfReport } from 'components/FormFindings';
import { FormType, LanguageType } from 'common/common.types';

export interface FormFindingsContextDataType {
  currentForm?: FormType;
  language: LanguageType;
}

export interface FormFindingsPageContextType extends PageBaseType {
  actions: PageActionsBaseType;
  data: FormFindingsContextDataType;
}

const FormFindingsPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      currentForm: selectors.getCurrentForm(state.forms),
      language: getLanguagePreference(state.settings),
    })}
    dispatches={(dispatch: Dispatch<ActionType>) => ({
      getData: ({ routeParams: { id } }: { routeParams: { id: string } }) => {
        dispatch(startLoading(1));
        dispatch(getForm({ id }));
      },
      actions: {},
    })}
  >
    <PdfReport />
  </Page>
);

export default FormFindingsPage;
