import * as R from 'ramda';
import { FormsStateType, ReportSavingType } from './forms.reducer';
import {
  MappingType,
  UploadType,
  FormAssignee,
  ParsedFileType,
  Uuid,
  AutocompleteDialogStateType,
  FormHistoryType,
  UploadedFileNamesType,
} from '../../common/common.types';
import { createSelector, defaultMemoize } from 'reselect';
import { FormHistoryActionEnum } from '../../generated/models';

export const getCurrentForm = (state: FormsStateType) => state.currentForm;

export const getCurrentFormHistory = (state: FormsStateType) =>
  state.currentFormHistory;

/**
 * Go through the form history, find all status changes, sort them by date and
 * return the date of the last status change (if found).
 */
export const getHistoryLastFormStatusChangeDate = defaultMemoize(
  (history: FormHistoryType[]): Date | undefined => {
    const sortedChanges = R.pipe<
      FormHistoryType[],
      FormHistoryType[],
      FormHistoryType[]
    >(
      R.filter<FormHistoryType>(
        ({ action, to }) =>
          action === FormHistoryActionEnum.CHANGEDSTATE &&
          typeof to !== 'undefined',
      ),
      R.sort(
        ({ createdAt: a }, { createdAt: b }) =>
          // Descending
          b.getTime() - a.getTime(),
      ),
    )(history);

    return sortedChanges.length > 0 ? sortedChanges[0].createdAt : undefined;
  },
);

export const getAttachmentModalState = (state: FormsStateType) =>
  state.attachmentModal;

export const getUploadedCount = (state: FormsStateType): number =>
  state.uploads.filter(({ hasError, isUploading }) => !hasError && !isUploading)
    .length;

export const getUploadingCount = (state: FormsStateType) =>
  state.uploads.length;

export const getNonFailedUploadingCount = (state: FormsStateType): number =>
  state.uploads.filter(({ hasError }) => !hasError).length;

export const getUpload = (
  fileId: string,
  state: FormsStateType,
): UploadType | undefined => R.find(({ id }) => id === fileId, state.uploads);

const getMappings = (state: FormsStateType): MappingType[] =>
  state.uploads
    .filter(upload => !R.isNil(upload.mapping))
    .map(upload => upload.mapping!);

export const isAllMapped = (state: FormsStateType): boolean => {
  const mappings = getMappings(state);

  return mappings.filter((u: MappingType) => !u.hasError).length > 0
    ? R.all(mapping => !R.isNil(mapping.questionsUpdated), mappings)
    : false;
};

export const questionsUpdated = (state: FormsStateType): number => {
  const mappings = getMappings(state);
  return mappings.reduce(
    (sum: number, mapping) =>
      sum + (mapping.questionsUpdated ? mapping.questionsUpdated : 0),
    0,
  );
};

export const isUploadingOrMapping = (state: FormsStateType): boolean =>
  getNonFailedUploadingCount(state) > 0 && !isAllMapped(state);

export const isFormValid = ({ currentForm }: FormsStateType): boolean =>
  !(currentForm && currentForm.questions) ? false : currentForm.isSubmittable;

export const getUploadedFileNames = ({
  uploads,
}: FormsStateType): UploadedFileNamesType => ({
  success: R.map((u: UploadType) => u.fileName)(
    R.filter(u => !u.isUploading && !u.hasError, uploads),
  ),
  error: R.map((u: UploadType) => u.fileName)(
    R.filter(u => !u.isUploading && u.hasError, uploads),
  ),
});

export const getUpdatedQuestionsIds = ({
  uploads,
  currentForm,
}: FormsStateType): Uuid[] => {
  const uploadsIds = [].concat.apply(
    [],
    uploads.map((upload: UploadType) =>
      R.propOr([], 'updatedQuestionsIds')(upload.mapping),
    ),
  );

  const autofillIds =
    currentForm &&
    [].concat.apply(
      [],
      R.propOr([], 'updatedQuestionsIds')(currentForm.autofillStats),
    );

  return uploadsIds.concat(autofillIds);
};

export const isReportSaving = ({
  activeRequestCount,
}: ReportSavingType): boolean => activeRequestCount > 0;

export const getAssignees = createSelector(
  ({ assignees }: FormsStateType) => assignees,
  (assignees): FormAssignee[] => {
    const nestedAssignees = assignees.reduce(
      (accumulator, user: FormAssignee, index) => {
        // If UserType.isActive push to accumulator[0] if not then to accumulator[1]
        accumulator[user.isActive ? 0 : 1].push(user);
        return accumulator;
      },
      [[], []] as [FormAssignee[], FormAssignee[]],
    );

    nestedAssignees.forEach(subSet =>
      // Sorting results alphabetically by `displayName`
      subSet.sort((user, nextUser) =>
        user.displayName
          .toLowerCase()
          .localeCompare(nextUser.displayName.toLowerCase()),
      ),
    );

    return R.flatten<FormAssignee>(nestedAssignees);
  },
);

export const getParsedUploads = createSelector(
  ({ uploads }: FormsStateType) => uploads,
  (uploads): ParsedFileType[] =>
    uploads.map(
      (upload): ParsedFileType => ({
        fileName: upload.fileName,
        formId: upload.formId,
        hasError: upload.hasError,
        parsingMessages: upload.parsing.parsingMessages,
        status: upload.status,
      }),
    ),
);

export const isAutocompleteDialogOpened = (
  dialog: AutocompleteDialogStateType,
): boolean => dialog.type.startsWith('OPENED_');
