/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyLogo
 */
export interface CompanyLogo {
    /**
     * 
     * @type {string}
     * @memberof CompanyLogo
     */
    logo: string;
}

export function CompanyLogoFromJSON(json: any): CompanyLogo {
    return CompanyLogoFromJSONTyped(json, false);
}

export function CompanyLogoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyLogo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logo': json['logo'],
    };
}

export function CompanyLogoToJSON(value?: CompanyLogo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logo': value.logo,
    };
}


