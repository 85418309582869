import { ToggleHeaderActionType } from './actions/toggleHeader';

export interface UiStateType {
  isHeaderVisible: boolean;
}

const initialState = {
  isHeaderVisible: true,
};

const ui = (
  state = initialState,
  action: ToggleHeaderActionType,
): UiStateType => {
  if (action.type === '@ui/TOGGLE_HEADER') {
    return {
      ...state,
      isHeaderVisible: action.payload.isVisible,
    };
  }

  return state;
};

export default ui;
