import FilterBarBottom from './FilterBarBottom';
import FiltersDropdown from './FiltersDropdown';
import React, {
  useRef,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { FormPageContextType } from 'pages/FormPage';
import { PageContext } from 'pages/Page';
import { SharedFormPageContextType } from 'pages/SharedFormPage';
import { ToggleAllButton, ToggleAllDropdown } from '.';
import {
  getFiltersCount,
  QUESTION_TAB_OPTIONS,
  getEnabledFiltersKeys,
} from '../utils';
import { useOnClickOutside } from '../../../utils/useOnClickOutside';
import { any, filter, lt, values } from 'ramda';
import { withTranslation } from 'react-i18next';
import {
  Horizontal,
  TextButton,
  VerticallyCentered,
} from 'common/common.styles';
import {
  PromptInviteDelegationType,
  QuestionTabEnum,
  ToggleEnum,
  TranslatePropsType,
} from 'common/common.types';
import {
  Bar,
  Button,
  FilterBarBottomWrapper,
  FilteringResult,
  Main,
  NotificationAlert,
} from './FilterBar.styles';
import FormDelegator from '../Delegator/FormDelegator';
import { SupportedFilterBranchType } from '../../../utils/filters/forms.filters';
import useFlag from '../../../utils/hooks/useFlag';

interface FilterBarPropsType extends TranslatePropsType {
  handleInviteUserClick: (
    e: React.MouseEvent<HTMLElement>,
    prompt: PromptInviteDelegationType,
  ) => void;
  hasNewlyAutofilled: boolean;
  initialState?: boolean;
}

const FilterBar = ({
  hasNewlyAutofilled,
  handleInviteUserClick,
  initialState = false,
  t,
}: FilterBarPropsType) => {
  const ref = useRef(null);

  const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(initialState);
  const [isToggleAllDropdownOpen, setToggleAllDropdownOpen] = useState(
    initialState,
  );
  const [visibleQuestionsCount, setVisibleQuestionsCount] = useState(0);
  const [isRequiredFilterEnabled, setIsRequiredFilterEnabled] = useState(false);

  useOnClickOutside(ref, () => {
    setFilterDropdownOpen(false);
    setToggleAllDropdownOpen(false);
  });

  const {
    actions: {
      addFilter,
      clearFilters,
      removeFilter,
      switchQuestionTab,
      updateResults,
    },
    data: {
      user,
      assignees,
      canUpdateView,
      currentForm: {
        id,
        permissions,
        permissions: {
          isFormAutocompletable,
          isDelegable,
          isReviewReadable,
          isSscScoreReadable,
        },
        questions,
        questionsFilteredCount,
        questionsCount,
      },
      filters,
      filters: { simple: simpleFilters },
      preferredTab,
    },
  } = useContext(PageContext) as
    | FormPageContextType
    | SharedFormPageContextType;

  useEffect(() => {
    const count = Object.values(questions).reduce((acc, curr) => {
      if (curr.isVisible) {
        return acc + 1;
      }
      return acc;
    }, 0);

    setVisibleQuestionsCount(count);
  }, [questions, simpleFilters]);

  useEffect(() => {
    const filtersRequired = ['yes', 'no', 'na', 'vrm_review_flagged'];
    const enabledFilters = getEnabledFiltersKeys('simple', filters);
    const isEnabled = enabledFilters.some(curr =>
      filtersRequired.includes(curr),
    );

    setIsRequiredFilterEnabled(isEnabled);
  }, [questions, simpleFilters, filters]);

  const hideSscData = useFlag(user, [ToggleEnum.HIDE_SSC_DATA]);
  permissions.isSscScoreReadable = !hideSscData && isSscScoreReadable;

  const handleUpdateFilters = useCallback(() => {
    updateResults();
  }, [updateResults]);

  const handleToggleAll = () => {
    setFilterDropdownOpen(false);
    setToggleAllDropdownOpen(!isToggleAllDropdownOpen);
  };

  const handleFilterOpen = () => {
    setFilterDropdownOpen(!isFilterDropdownOpen);
    setToggleAllDropdownOpen(false);
  };

  const handleToggleAndClose = (tab: QuestionTabEnum) => {
    switchQuestionTab(tab);
    setToggleAllDropdownOpen(false);
  };

  const handleClearAll = () => {
    if (!!filtersCount) {
      setFilterDropdownOpen(false);
      clearFilters();
    }
  };

  const handleCheckboxChange = (
    branch: SupportedFilterBranchType,
    name: string,
  ) => {
    filters[branch][name].isEnabled
      ? removeFilter(branch, name)
      : addFilter(branch, name);
  };

  const hasNewData = any(lt(0), [
    simpleFilters.attachments_new.count,
    simpleFilters.comments_new.count,
    hideSscData ? 0 : simpleFilters.ssc_data_new.count,
    simpleFilters.responses_new.count,
  ]);
  const filtersCount = getFiltersCount(filters);
  const questionTabOptions = QUESTION_TAB_OPTIONS(permissions);
  const dropdownOptions = filter(
    option => option.enabled,
    values(questionTabOptions),
  );

  return (
    <Bar ref={ref}>
      <FiltersDropdown
        filters={filters}
        filtersCount={filtersCount}
        handleCheckboxChange={handleCheckboxChange}
        handleClearAll={handleClearAll}
        handleFilterOpen={handleFilterOpen}
        hasNewData={hasNewData}
        hasNewlyAutofilled={hasNewlyAutofilled}
        hideSscData={hideSscData || !isSscScoreReadable}
        isAutocompletable={isFormAutocompletable}
        isDelegable={isDelegable}
        isFilterDropdownOpen={isFilterDropdownOpen}
        isReviewReadable={isReviewReadable}
      />

      <ToggleAllDropdown
        isOpen={isToggleAllDropdownOpen}
        handleClick={handleToggleAndClose}
        options={dropdownOptions}
      />

      <Main>
        <VerticallyCentered>
          <Horizontal>
            <Button
              data-test="filters-dropdown"
              isActive={!!filtersCount}
              onClick={handleFilterOpen}
            >
              {t('filter') as string}
            </Button>

            {hasNewData && <NotificationAlert />}

            <FilteringResult data-cy="filtering-result">
              {!!filtersCount && (
                <p>
                  {ReactHtmlParser(
                    t('resultHTML', {
                      count:
                        isRequiredFilterEnabled && !!filtersCount
                          ? visibleQuestionsCount
                          : questionsFilteredCount,
                      all: questionsCount,
                    }),
                  )}
                </p>
              )}

              {canUpdateView && !!filtersCount && (
                <span style={{ paddingTop: 2 }}>
                  <TextButton color="active" onClick={handleUpdateFilters}>
                    {t('updateFilters') as string}
                  </TextButton>
                </span>
              )}
            </FilteringResult>
          </Horizontal>
        </VerticallyCentered>

        <VerticallyCentered>
          <Horizontal>
            {isDelegable && assignees && (
              <FormDelegator
                assignees={assignees}
                formId={id}
                onInviteUserClick={handleInviteUserClick}
              />
            )}
            <ToggleAllButton
              handleClick={handleToggleAll}
              isOpen={isToggleAllDropdownOpen}
              preferredTab={questionTabOptions[preferredTab]}
              questions={values(questions)}
            />
          </Horizontal>
        </VerticallyCentered>
      </Main>

      <FilterBarBottomWrapper isVisible={!!filtersCount}>
        <FilterBarBottom
          data={{
            filters,
            filtersCount,
          }}
          actions={{
            addFilter,
            removeFilter,
            clearFilters,
          }}
        />
      </FilterBarBottomWrapper>
    </Bar>
  );
};

export default withTranslation('FilterBar')(FilterBar);
