import { ResponseDefinitionType, Uuid } from '../../../common/common.types';
import { createResponseDefinition } from '../utils';
import update from 'immutability-helper';
import { ResponseTypeDefinitionResponseTypeEnum } from '../../../generated/models/ResponseTypeDefinition';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import {
  EventSequencerActionBaseType,
  EventSequencerActionPayloadType,
} from '../eventSequencer/eventSequencer.types';
import ifProblemAddToAttemptedFixes from '../utils/ifProblemAddToAttemptedFixes';
import { UpdateConditionStructured } from '../event.types';
import encodeStructuredCondition from '../utils/encodeStructuredCondition';
import { countBy, identity } from 'ramda';

export type UpdateResponseDefinitionFunctionType = (
  params: UpdateResponseDefinitionParamsType,
) => void;

export type UpdateResponseDefinitionActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/UPDATE_RESPONSE'
>;

export interface UpdateResponseDefinitionParamsType {
  questionId: Uuid;
  responseUuid: Uuid;
  isRequired?: boolean;
  responseType: ResponseTypeDefinitionResponseTypeEnum;
  options: string[];
  placeholder: string;
  label: string;
}

const getSimpleRenames = (
  optionsA: string[],
  optionsB: string[],
): Array<[string, string]> => {
  if (optionsA.length !== optionsB.length) {
    return [];
  }
  const renames: Array<[string, string]> = [];
  const optionCounts = countBy(identity, optionsA);
  optionsA.forEach((a, index) => {
    if (
      a !== optionsB[index] &&
      // Don't update condition references if the original was empty
      a !== '' &&
      // Don't update condition references if it is duplicate option
      optionCounts[a] === 1
    ) {
      renames.push([a, optionsB[index]]);
    }
  });
  return renames;
};

const NO_CHANGE: EventSequencerActionPayloadType = {
  events: [],
  selector: ({ formCreator }) => formCreator,
};

export const updateResponseDefinition = ({
  responseType,
  options,
  questionId,
  responseUuid,
  placeholder,
  label,
  isRequired,
}: UpdateResponseDefinitionParamsType): UpdateResponseDefinitionActionType => ({
  type: '@formCreator/EVENTS/UPDATE_RESPONSE',
  payload: ({ formCreator, formCreator: { currentForm } }) => {
    if (!currentForm) {
      return NO_CHANGE;
    }
    const { questions } = currentForm;

    const questionIndex = questions.findIndex(
      ques => ques.wizardId === questionId,
    );
    if (questionIndex === -1) {
      return NO_CHANGE;
    }
    const responseIndex = questions[questionIndex].responses.findIndex(
      resp => resp.uniqueId === responseUuid,
    );
    if (responseIndex === -1) {
      return NO_CHANGE;
    }

    const oldOptions =
      questions[questionIndex].responses[responseIndex].definition.options;

    const newResponseDef: ResponseDefinitionType = createResponseDefinition(
      responseType,
      options,
      label,
      placeholder,
    );

    const renames = getSimpleRenames(oldOptions, options);
    const conditionUpdates: UpdateConditionStructured[] = [];

    const newQuestions = questions.map((q, i) => {
      // Update the response definition required by this action
      if (i === questionIndex) {
        q = update(q, {
          responses: {
            [responseIndex]: {
              isRequired: {
                $apply: (oldIsRequired: boolean) =>
                  typeof isRequired !== 'undefined'
                    ? isRequired
                    : oldIsRequired,
              },
              definition: {
                $set: newResponseDef,
              },
            },
          },
        });
      }

      // Update condition references if the response update caused option
      // renames
      if (
        renames.length > 0 &&
        q.condition &&
        q.condition.type === 'structured'
      ) {
        let someRenamesApplied = false;
        // Apply renames to the condition lines
        const conditionLines = q.condition.payload.items.map(conditionLine => {
          if (conditionLine.responseUuid === responseUuid) {
            const rename = renames.find(
              ([from, _to]) => from === conditionLine.value,
            );
            if (rename) {
              const [, newValue] = rename;
              someRenamesApplied = true;
              return { ...conditionLine, value: newValue };
            }
            return conditionLine;
          } else {
            return conditionLine;
          }
        });
        if (someRenamesApplied) {
          conditionUpdates.push({
            action: FormEditEventActionCodeEnum.UPDATECONDITIONSTRUCTURED,
            question_uuid: q.wizardId,
            structured: encodeStructuredCondition({
              ...q.condition.payload,
              items: conditionLines,
            }),
          });
          q = update(q, {
            condition: {
              payload: {
                items: { $set: conditionLines },
              },
            },
          });
        }
      }
      return q;
    });

    return {
      events: [
        {
          action: FormEditEventActionCodeEnum.UPDATERESPONSE,
          question_uuid: questionId,
          response_uuid: responseUuid,
          is_required: isRequired,
          definition: {
            label,
            options,
            placeholder,
            response_type: responseType,
          },
        },
        ...conditionUpdates,
      ],
      selector: () =>
        update(formCreator, {
          currentForm: {
            questions: { $set: newQuestions },
          },
          validation: {
            $apply: ifProblemAddToAttemptedFixes('response', responseUuid),
          },
        }),
    };
  },
});
