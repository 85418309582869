import AttachmentsList from './AttachmentsList';
import React from 'react';
import { InnerFlex, Message } from './FormAttachments.styles';
import { withTranslation } from 'react-i18next';
import { AttachmentsDict, TranslatePropsType } from 'common/common.types';

interface ReadOnlyView extends TranslatePropsType {
  attachments: AttachmentsDict;
  companyName: string;
  isVRM: boolean;
  formMessage: string;
}

const ReadOnlyView = ({
  attachments,
  companyName,
  isVRM,
  formMessage,
  t,
}: ReadOnlyView) => (
  <>
    {t('titleVendor', { companyName })}

    <InnerFlex>
      {!isVRM && formMessage && (
        <Message data-test="read-only-message">{formMessage}</Message>
      )}

      <AttachmentsList
        data-test="download-attachment"
        attachments={attachments}
        isVRM={isVRM}
      />
    </InnerFlex>
  </>
);

export default withTranslation('FormAttachments')(ReadOnlyView);
