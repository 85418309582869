import styled from 'styled-components';
import hex2rgba from 'utils/hex2rgba';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const Backdrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => hex2rgba(theme.atlas.grey[300], 0.6)};
`;

export const Closer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const faTimesProp = faTimes as IconProp;
export const CloseIcon = styled(FontAwesomeIcon).attrs({ icon: faTimesProp })`
  width: 16px !important;
  height: 16px;
`;
