import Bar from './Bar';
import DelegationNotifications from './DelegationNotifications';
import DelegationNotificationsPrompt from './DelegationNotificationsPrompt';
import Dropdown from './Dropdown';
import FilterBar from '../Filter/FilterBar';
import FormAttachments from '../FormAttachments';
import React, { ReactElement, useContext, useState, useEffect } from 'react';
import ViewStatusNotification from '../ViewStatus/ViewStatusNotification';
import withHeaderVisibility from '../../Layout/HeaderVisibility';
import AnimatedSticky from '../../common/AnimatedSticky';
import { Autocomplete } from './Autocomplete';
import { Background } from '../../common/PageHeader';
import { Details } from './Details';
import { FormPageContextType } from 'pages/FormPage';
import { History as FormHistory } from './History';
import { PageContext } from 'pages/Page';
import { Prompt } from '../../common/Overlay';
import { SharedFormPageContextType } from 'pages/SharedFormPage';
import { withTranslation } from 'react-i18next';
import {
  DelegationNotificationsDirectionType,
  PromptInviteDelegationType,
  TranslatePropsType,
} from 'common/common.types';
import {
  Content,
  HEADER_HEIGHT,
  Marginator,
  RelativeBlock,
} from 'common/common.styles';
import Report from './Report';

export enum DropdownsEnum {
  AUTOCOMPLETE = 'autocomplete',
  DETAILS = 'details',
  HISTORY = 'history',
  REPORT = 'report',
}

interface FormHeaderPropsType extends TranslatePropsType {
  handleInviteUserClick: (
    e: React.MouseEvent<HTMLElement>,
    prompt: PromptInviteDelegationType,
  ) => void;
  initialCurrentDropdown?: DropdownsEnum;
  initialNotificationPromptDirection?: DelegationNotificationsDirectionType;
  isAutocompleteVisible: boolean;
  isHeaderVisible?: boolean;
  isHistoryVisible: boolean;
  isProxyVisible: boolean;
}

const FormHeader = ({
  handleInviteUserClick,
  initialCurrentDropdown,
  initialNotificationPromptDirection,
  isAutocompleteVisible,
  isHeaderVisible,
  isHistoryVisible,
  isProxyVisible,
  t,
}: FormHeaderPropsType) => {
  const {
    actions: {
      addFinding,
      clearAnswers,
      clearDelegationNotifications,
      deleteAttachment,
      deleteFinding,
      exportFindingsToCsvPath,
      exportFindingsToPdf,
      getFindings,
      navigate,
      postAttachment,
      putMessage,
      renameForm,
      sendDelegationNotifications,
      setCurrentDropdown,
      showProxyModal,
      startAutocomplete,
      updateRiskNote,
      updateVrmViewStatus,
      updateDueDate,
    },
    data: {
      autocomplete: { dialog: autocompleteState },
      areFindingsExporting,
      currentDropdown,
      currentForm: {
        autofillStats,
        createdAt,
        displayName,
        delegationNotifications,
        fillingType,
        id,
        isScoreVisible,
        meta: { isEditable, isUploadedFromUser },
        percentageFilled,
        permissions: {
          isVRM,
          isVendor,
          isResponseEditable,
          isResponseReadApprovable,
          isRiskReportReadable,
          isRiskReportWritable,
        },
        questionsCount,
        questionsFilledCount,
        request,
        score,
        standard,
        updatedAt,
      },
      currentHistory,
      lastFormStatusChangeDate,
      reportSaving,
      upload,
      user,
      sharedFormData,
      language,
    },
  } = useContext(PageContext) as
    | FormPageContextType
    | SharedFormPageContextType;

  useEffect(() => {
    setCurrentDropdown(initialCurrentDropdown);
    // We want to set the inital dropdown only when the component is created.
    // eslint-disable-next-line
  }, []);

  const [
    notificationPromptDirection,
    setNotificationPromptDirection,
  ] = useState(initialNotificationPromptDirection);

  const hasNewlyAutofilled =
    (upload && upload.updatedQuestionsIds.length > 0) ||
    autofillStats.updatedQuestionsCount > 0;

  const showFormAttachments =
    !!postAttachment && !!deleteAttachment && !!putMessage;

  const showViewStatusNotification =
    isResponseReadApprovable && request && request.isVrmStatusChangeable;

  const isReportVisible = isRiskReportReadable && isRiskReportWritable;

  const toggleDropdown = (dropdown: DropdownsEnum) => {
    if (dropdown === currentDropdown) {
      closeDropdown();
    } else {
      setCurrentDropdown(dropdown);
    }
  };

  const closeDropdown = () => {
    setCurrentDropdown(undefined);
  };

  const openPrompt = (direction: DelegationNotificationsDirectionType) => {
    setNotificationPromptDirection(direction);
  };

  const closePrompt = () => {
    setNotificationPromptDirection(undefined);
  };

  const makeTypicalDropdown = (children: React.ReactNode) =>
    currentDropdown !== undefined ? (
      <Dropdown handleClose={closeDropdown} titleKey={currentDropdown}>
        {children}
      </Dropdown>
    ) : null;

  const currentDropdownContent = ((): ReactElement | null => {
    switch (currentDropdown) {
      case DropdownsEnum.AUTOCOMPLETE:
        return makeTypicalDropdown(
          <Autocomplete
            handleClose={closeDropdown}
            state={autocompleteState}
            startAutocomplete={startAutocomplete}
          />,
        );
      case DropdownsEnum.DETAILS:
        return makeTypicalDropdown(
          <Details
            clearAnswers={() => clearAnswers(id)}
            createdAt={createdAt}
            isResponseEditable={isResponseEditable}
            isScoreVisible={isScoreVisible}
            isVendor={isVendor}
            isVRM={isVRM}
            lastFormStatusChangeDate={lastFormStatusChangeDate}
            percentageFilled={percentageFilled}
            questionsCount={questionsCount}
            questionsFilledCount={questionsFilledCount}
            request={request}
            score={score}
            updatedOn={updatedAt}
            user={user}
            updateDueDate={updateDueDate}
            language={language}
          />,
        );
      case DropdownsEnum.HISTORY:
        return makeTypicalDropdown(
          <FormHistory
            data={currentHistory}
            isVRM={isVRM}
            language={language}
          />,
        );
      case DropdownsEnum.REPORT:
        return request ? (
          <Report
            addFinding={addFinding}
            areFindingsExporting={areFindingsExporting}
            companyName={request.target.name}
            deleteFinding={deleteFinding}
            exportFindingsToCsvPath={exportFindingsToCsvPath}
            exportFindingsToPdf={exportFindingsToPdf}
            findings={request.findings}
            formId={id}
            getFindings={getFindings}
            handleClose={closeDropdown}
            reportSaving={reportSaving}
            requestId={request.id}
            riskNote={request.riskNote}
            standardName={displayName}
            titleKey={currentDropdown}
            updateRiskNote={n => updateRiskNote(request.id, n)}
          />
        ) : null;
      default:
        return null;
    }
  })();

  return (
    <>
      <AnimatedSticky
        style={{
          top: isHeaderVisible ? HEADER_HEIGHT : 0,
          zIndex: 1002,
        }}
      >
        <Background>
          <Marginator margin={{ top: 30, left: 15, right: 15 }}>
            <RelativeBlock>
              <Bar
                createdAt={createdAt}
                currentDropdown={currentDropdown}
                data-cy="form-header"
                displayName={displayName}
                fillingType={fillingType}
                formId={id}
                isAutocompleteVisible={isAutocompleteVisible}
                isHistoryVisible={isHistoryVisible}
                isProxyVisible={isProxyVisible}
                isReportVisible={isReportVisible}
                isVendor={isVendor}
                isUploadedFromUser={isUploadedFromUser}
                isVRM={isVRM}
                navigate={navigate}
                renameForm={renameForm}
                request={request}
                sharedFormData={sharedFormData}
                showProxyModal={showProxyModal}
                standard={standard}
                toggleDropdown={toggleDropdown}
                language={language}
              />

              {currentDropdown !== undefined && currentDropdownContent}
            </RelativeBlock>
          </Marginator>
        </Background>
      </AnimatedSticky>

      {showFormAttachments && (
        <Content padding="0 15px">
          <FormAttachments />
        </Content>
      )}

      <AnimatedSticky
        style={{
          top: isHeaderVisible ? 100 + HEADER_HEIGHT : 100,
          zIndex: 1001,
        }}
      >
        <Content padding="0 15px">
          <FilterBar
            hasNewlyAutofilled={hasNewlyAutofilled}
            handleInviteUserClick={handleInviteUserClick}
          />
        </Content>
      </AnimatedSticky>

      {isEditable &&
        Object.keys(delegationNotifications).map(
          (key: DelegationNotificationsDirectionType) =>
            delegationNotifications[key].count > 0 && (
              <AnimatedSticky
                style={{
                  top: isHeaderVisible ? 168 + HEADER_HEIGHT : 168,
                  zIndex: 1000,
                }}
                key={key}
              >
                <Content padding="0 15px">
                  <DelegationNotifications
                    clearDelegationNotifications={clearDelegationNotifications}
                    direction={key}
                    formId={id}
                    questionsCount={delegationNotifications[key].count}
                    usersCount={delegationNotifications[key].users.length}
                    openPrompt={() => openPrompt(key)}
                  />
                </Content>
              </AnimatedSticky>
            ),
        )}

      {showViewStatusNotification && (
        <Content padding="0 15px">
          <ViewStatusNotification
            request={request!}
            updateStatus={updateVrmViewStatus}
          />
        </Content>
      )}

      {isEditable && notificationPromptDirection !== undefined && (
        <Prompt
          data-cy="delegation-prompt"
          confirmText={t('send') as string}
          heading={t(`finishDelegationHeading.${notificationPromptDirection}`)}
          onClose={closePrompt}
          onSubmit={() =>
            sendDelegationNotifications(notificationPromptDirection, id)
          }
        >
          <DelegationNotificationsPrompt
            users={delegationNotifications[notificationPromptDirection].users}
          />
        </Prompt>
      )}
    </>
  );
};

export default withHeaderVisibility(withTranslation('FormHeader')(FormHeader));
