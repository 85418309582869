import companiesApi from '../../../api/new/companiesApi';
import i18n from '../../../i18n';
import { flash } from '../../flashes/actions';
import { put } from 'redux-saga/effects';
import { getSettings } from './getSettings';

export const removeLogo = (): any => ({
  type: '@companies/API/REMOVE_LOGO',
  payload: {
    callParams: null,
    endpointPath: () => companiesApi.deleteCompanyCurrentLogoResource(),
    selector: null,
    postActions: [
      () => put(flash(i18n.t('common:responses.logoDelete'))),
      () => put(getSettings()),
    ],
  },
});
