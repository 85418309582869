import { assoc } from 'ramda';
import { ApiCallActionType } from '../../store.types';
import { RequestsStateType } from '../requests.types';
import { getFilterCallParams, GetRequestsCallParamsType } from './getRequests';
import { RequestStatusStats } from '../../../generated/models/RequestStatusStats';
import { correctFilters, FilterType } from 'utils/filters/requests.filters';
import { RequestDirectionType } from '../../../common/common.types';

export type GetStatsActionType = ApiCallActionType<
  '@requests/API/GET',
  GetRequestsCallParamsType,
  RequestStatusStats,
  never,
  RequestsStateType
>;

export const getStats = (
  direction: RequestDirectionType,
  filterBy?: FilterType,
): GetStatsActionType => {
  const isSent = direction === 'sent';
  const filters = correctFilters(filterBy);
  return {
    type: '@requests/API/GET',
    payload: {
      endpointPath: ['requests', 'stats'],
      callParams: state => getFilterCallParams(state, filters, isSent, false),
      selector: (data, state) => assoc('stats', data)(state.requests),
    },
  };
};
