/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface AssigneeCountMetadata
 */
export interface AssigneeCountMetadata {
    /**
     * Number of questions (out of the visible/filtered ones) assigned to the user
     * @type {number}
     * @memberof AssigneeCountMetadata
     */
    count: number;
    /**
     * Assignee - a user to whom questions are assigned
     * @type {User}
     * @memberof AssigneeCountMetadata
     */
    user: User;
}

export function AssigneeCountMetadataFromJSON(json: any): AssigneeCountMetadata {
    return AssigneeCountMetadataFromJSONTyped(json, false);
}

export function AssigneeCountMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssigneeCountMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'user': UserFromJSON(json['user']),
    };
}

export function AssigneeCountMetadataToJSON(value?: AssigneeCountMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'user': UserToJSON(value.user),
    };
}


