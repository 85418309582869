import { RequestsFormType } from '../requests.types';

export interface RequestsSetFormValueActionType<
  T extends keyof RequestsFormType = any
> {
  type: '@requests/SET_FORM_VALUE';
  payload: {
    field: T;
    value: RequestsFormType[T];
  };
}

export type SetFormValueFunctionType = <T extends keyof RequestsFormType>(
  field: T,
  value: RequestsFormType[T],
) => void;

export const setFormValue = <T extends keyof RequestsFormType>(
  field: T,
  value: RequestsFormType[T],
): RequestsSetFormValueActionType<T> => ({
  type: '@requests/SET_FORM_VALUE',
  payload: {
    field,
    value,
  },
});
