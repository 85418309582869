import chroma from 'chroma-js';
import styled, { css } from 'styled-components';
import colors from 'common/common.colors';

export interface PercentageType {
  percentage: number;
}

const {
  elements: { completion },
  basic: { white, black },
} = colors;

const scale = chroma.scale([completion.incomplete, completion.finished]);

export const Completion = styled.div<PercentageType>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ percentage }) => (percentage! < 5 ? 5 : percentage)}%;
  background-color: ${({ percentage }) => scale(percentage / 100).hex()};
`;

export const Graph = styled.div<PercentageType>`
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: #f6f6f6;
  color: ${({ percentage }) => (percentage > 80 ? white : black)};
`;

export const FrameworkBody = styled.div<PercentageType>`
  width: 100%;
  padding: 15px 30px 15px 15px;
  height: 100%;
  ${({ theme }) => theme.fonts.bold.normal}
  ${({ percentage }) => {
    if (percentage && percentage === 100) {
      return css`
        background-color: ${completion.finished};
        color: ${white};
      `;
    } else {
      return css`
        background-color: ${white};
        color: ${black};
      `;
    }
  }};
`;

export const Year = styled.p`
  ${({ theme }) => theme.fonts.regular.small}
`;

export const Percentage = styled.span`
  ${({ theme }) => theme.fonts.bold.big}
  display: block;
  position: relative;
`;

export const Questions = styled.span`
  ${({ theme }) => theme.fonts.light.small}
  display: block;
  position: relative;
`;

export const Label = styled.label`
  cursor: pointer;
`;
