import { ApiCallActionPayloadType } from '../../store.types';
import { put } from 'redux-saga/effects';
import { getStandards } from './getStandards';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { assocPath, dec, inc } from 'ramda';
import { StandardStateType } from '../reducer';
import { Uuid } from '../../../common/common.types';

export type TransferStandardsFunctionType = (
  params: TransferStandardsParamsType,
) => void;

export interface TransferStandardsParamsType {
  companyId: Uuid;
  standardIds: Uuid[];
}

export interface TransferStandardsCallParamsType {
  company_ids: Uuid[];
  standard_ids: Uuid[];
}

export type TransferStandardsActionType = OptimisticActionType<
  '@standard/API/TRANSFER',
  ApiCallActionPayloadType<
    TransferStandardsCallParamsType,
    any,
    never,
    StandardStateType
  >
>;

export const transferStandards = ({
  companyId,
  standardIds,
}: TransferStandardsParamsType): TransferStandardsActionType => ({
  type: '@standard/API/TRANSFER',
  payload: {
    endpointPath: ['standards', 'transfer'],
    callParams: { company_ids: [companyId], standard_ids: standardIds },
    expectedStateChange: {
      'standard.transferingCount': inc,
    },
    selector: (data, state) =>
      assocPath<number, StandardStateType>(
        ['transferingCount'],
        dec(state.standard.transferingCount),
      )(state.standard),
    postActions: [
      () =>
        put(
          getStandards({
            status: 'custom',
            ownerId: companyId,
          }),
        ),
    ],
  },
});
