/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProxyWithFlags,
    ProxyWithFlagsFromJSON,
    ProxyWithFlagsFromJSONTyped,
    ProxyWithFlagsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProxyCollaborationDetail
 */
export interface ProxyCollaborationDetail {
    /**
     * Does the user have access to the form only via proxying?
     * @type {boolean}
     * @memberof ProxyCollaborationDetail
     */
    isProxy: boolean;
    /**
     * Detail of proxies - their roles and ids.
     * @type {Array<ProxyWithFlags>}
     * @memberof ProxyCollaborationDetail
     */
    proxies: Array<ProxyWithFlags>;
}

export function ProxyCollaborationDetailFromJSON(json: any): ProxyCollaborationDetail {
    return ProxyCollaborationDetailFromJSONTyped(json, false);
}

export function ProxyCollaborationDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProxyCollaborationDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isProxy': json['is_proxy'],
        'proxies': ((json['proxies'] as Array<any>).map(ProxyWithFlagsFromJSON)),
    };
}

export function ProxyCollaborationDetailToJSON(value?: ProxyCollaborationDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_proxy': value.isProxy,
        'proxies': ((value.proxies as Array<any>).map(ProxyWithFlagsToJSON)),
    };
}


