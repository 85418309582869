import styled from 'styled-components';
import colors from '../../../.././common/common.colors';

export const CompletedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Spacer = styled.hr`
  opacity: 0.5;
  margin: 10px 0 20px;
`;

export interface DueDateWrapperType {
  isPastDue?: boolean;
}

export const DateWrapper = styled.div`
  padding-bottom: 10px;

  &:last-of-type {
    padding-bottom: 0;
  }

  p {
    color: ${colors.basic.orange};
    display: inline;
  }

  span {
    display: inline-block;
    width: 130px;
    color: ${colors.basic.grayLight};
  }
`;

export const Label = styled.p`
  ${({ theme }) => theme.fonts.bold.small}
  margin-bottom: 10px;
`;

export const ValidationScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.basic.fontWeights.bold};

  span {
    padding-left: 7px;
  }
`;

export const TextStyle = styled.div`

  padding: 5px;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
}

`;

export const DueDateWrapper = styled.div<DueDateWrapperType>`
  color: ${({ isPastDue }) =>
    isPastDue ? colors.requestDueDates.danger : ''}};
  display: inline;
`;
