import React, { PureComponent } from 'react';
import { AccordionSectionPropsType } from './AccordionSection';
import { indexOf } from 'ramda';

interface AccordionPropsType {
  children: React.ReactNode;
  sectionNames?: string[];
}

interface AccordionStateType {
  selected: number | null;
}

class Accordion extends PureComponent<AccordionPropsType, AccordionStateType> {
  constructor(props: AccordionPropsType) {
    super(props);

    this.state = {
      selected: 0,
    };
  }

  componentDidUpdate(prevProps: AccordionPropsType) {
    const { children, sectionNames } = this.props;
    const { selected } = this.state;
    if (
      React.Children.count(children) !==
      React.Children.count(prevProps.children)
    ) {
      const previousSelectionIndex =
        sectionNames && prevProps.sectionNames
          ? indexOf(prevProps.sectionNames[selected || 0], sectionNames)
          : 0;
      this.setState({
        selected: previousSelectionIndex > 0 ? previousSelectionIndex : 0,
      });
    }
  }

  selectSection = (selected: number) => {
    const { state } = this;

    if (selected === state.selected) {
      this.setState({ ...state, selected: null });
    } else {
      this.setState({ ...state, selected });
    }
  };

  render() {
    const { selected } = this.state;

    const isSingle = React.Children.count(this.props.children) === 1;

    const children = React.Children.map(
      this.props.children,
      (child: React.ReactElement<AccordionSectionPropsType>, index: number) => {
        return React.cloneElement(child, {
          isOpen: index === selected,
          handleClick: this.selectSection,
          isSingle,
        });
      },
    );

    return <div>{children}</div>;
  }
}

export default Accordion;
