import React from 'react';
import { Button, Heading } from '../Upsell.styles';
import { FAQ } from '../../../routes';
import { Link } from 'react-router-dom';
import { Marginator } from 'common/common.styles';
import { Wrapper } from './CreditsCTA.styles';
import { withTranslation } from 'react-i18next';
import { CompanyCreditsType, TranslatePropsType } from 'common/common.types';

interface CreditsCTAPropsType extends TranslatePropsType {
  credits: CompanyCreditsType;
  onClick: () => void;
}

const CreditsCTA = ({
  credits: { summary },
  onClick,
  t,
}: CreditsCTAPropsType) => {
  const { remaining } = summary!;

  return (
    <Wrapper>
      <Heading>
        {remaining > 1
          ? (t('almostOut') as string)
          : (t('alwaysAvailable') as string)}
      </Heading>

      <Button data-cy="header-addMoreCredits" onClick={onClick}>
        {t('addMore') as string}
      </Button>

      <Marginator margin={{ top: 5, bottom: -10 }}>
        <Link to={`${FAQ}?scrollToCredits`}>{t('viewDetails') as string}</Link>
      </Marginator>
    </Wrapper>
  );
};

export default withTranslation('CreditsCTA')(CreditsCTA);
