import { StandardType } from 'common/common.types';
import { OptionConfigType, Options, ActionTooltip } from './ActionsMenu';
import { isNil } from 'ramda';

export const HEIGHTS: { [key in Options]: number } = {
  REAL_OPTION: 30,
  HORIZONTAL_LINE: 1,
};

export const OPTION_WIDTH = 218;

export const calculateTotalOptionsHeight = (options: OptionConfigType[]) => {
  return options.reduce((acc, option) => {
    return acc + HEIGHTS[option.type];
  }, 0);
};

export enum ActionMenuActionIdEnum {
  PREVIEW = 'preview',
  SEND = 'send',
  PUBLISH = 'publish',
  UNPUBLISH = 'unpublish',
  RENAME = 'rename',
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
  DELETE = 'delete',
}

export type ClickHandlerMapType = {
  [key in ActionMenuActionIdEnum]?: () => void;
};

export type TooltipMapType = {
  [key in ActionMenuActionIdEnum]?: ActionTooltip;
};

export const createOptionsWithClickHandlersAndTooltips = (
  referenceOptions: OptionConfigType[],
  clickHandlerMap: ClickHandlerMapType,
  tooltipMap: TooltipMapType,
): OptionConfigType[] => {
  const mappedOptions: OptionConfigType[] = referenceOptions.map(refOption => {
    switch (refOption.type) {
      case Options.HORIZONTAL_LINE:
        return {
          type: Options.HORIZONTAL_LINE,
        };
      case Options.REAL_OPTION:
        return {
          type: Options.REAL_OPTION,
          config: {
            ...refOption.config,
            enabled: clickHandlerMap[refOption.config.id]
              ? refOption.config.enabled
              : false,
            clickHandler: clickHandlerMap[refOption.config.id],
            tooltip: tooltipMap[refOption.config.id]
              ? tooltipMap[refOption.config.id]
              : refOption.config.tooltip,
          },
        };
      default:
        throw new Error('Invalid option');
    }
  });

  return mappedOptions;
};

export const getSystemOptions = (
  standard: StandardType,
): OptionConfigType[] => {
  return [
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.PREVIEW,
        clickHandler: () => console.log('Preview clicked'),
        translationKey: 'preview',
        enabled: !isNil(standard?.previewFormId),
        ...(isNil(standard?.previewFormId)
          ? {
              tooltip: {
                translationKey: 'cantPreview',
              },
            }
          : {}),
      },
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.UNPUBLISH,
        clickHandler: () => console.log('Unpublish clicked'),
        translationKey: 'unpublish',
        enabled: false,
        tooltip: {
          translationKey: 'cantUnpublishStandard',
        },
      },
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.SEND,
        clickHandler: () => console.log('Send Questionnaire clicked'),
        translationKey: 'sendQuestionnaire',
        enabled: true,
      },
    },
    {
      type: Options.HORIZONTAL_LINE,
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.RENAME,
        clickHandler: () => console.log('Rename clicked'),
        translationKey: 'rename',
        enabled: false,
        tooltip: {
          translationKey: 'cantRenameStandard',
        },
      },
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.EDIT,
        clickHandler: () => console.log('Edit clicked'),
        translationKey: 'edit',
        enabled: false,
        tooltip: {
          translationKey: 'cantEditStandard',
        },
      },
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.DUPLICATE,
        clickHandler: () => console.log('Duplicate clicked'),
        translationKey: 'duplicate',
        enabled: true,
      },
    },
    {
      type: Options.HORIZONTAL_LINE,
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.DELETE,
        clickHandler: () => console.log('Delete clicked'),
        translationKey: 'delete',
        enabled: false,
        tooltip: {
          translationKey: 'cantDeleteStandard',
        },
      },
    },
  ];
};

export const getCustomOptions = (
  standard: StandardType,
): OptionConfigType[] => {
  return [
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.PREVIEW,
        clickHandler: () => console.log('Preview clicked'),
        translationKey: 'preview',
        enabled: !isNil(standard?.previewFormId),
        ...(isNil(standard?.previewFormId)
          ? {
              tooltip: {
                translationKey: 'cantPreview',
              },
            }
          : {}),
      },
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.UNPUBLISH,
        clickHandler: () => console.log('Unpublish clicked'),
        translationKey: 'unpublish',
        enabled: true,
      },
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.SEND,
        clickHandler: () => console.log('Send Questionnaire clicked'),
        translationKey: 'sendQuestionnaire',
        enabled: true,
      },
    },
    {
      type: Options.HORIZONTAL_LINE,
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.RENAME,
        clickHandler: () => console.log('Rename clicked'),
        translationKey: 'rename',
        enabled: true,
      },
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.EDIT,
        clickHandler: () => console.log('Edit clicked'),
        translationKey: 'edit',
        enabled: false,
        tooltip: {
          translationKey: 'cantEditPublished',
        },
      },
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.DUPLICATE,
        clickHandler: () => console.log('Duplicate clicked'),
        translationKey: 'duplicate',
        enabled: true,
      },
    },
    {
      type: Options.HORIZONTAL_LINE,
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.DELETE,
        clickHandler: () => console.log('Delete clicked'),
        translationKey: 'delete',
        enabled: true,
      },
    },
  ];
};

export const getDraftOptions = (standard: StandardType): OptionConfigType[] => {
  return [
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.PREVIEW,
        clickHandler: () => console.log('Preview clicked'),
        translationKey: 'preview',
        enabled: !isNil(standard?.previewFormId),
        ...(isNil(standard?.previewFormId)
          ? {
              tooltip: {
                translationKey: 'cantPreview',
              },
            }
          : {}),
      },
    },
    {
      type: Options.HORIZONTAL_LINE,
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.RENAME,
        clickHandler: () => console.log('Rename clicked'),
        translationKey: 'rename',
        enabled: true,
      },
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.EDIT,
        clickHandler: () => console.log('Edit clicked'),
        translationKey: 'edit',
        enabled: true,
      },
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.DUPLICATE,
        clickHandler: () => console.log('Duplicate clicked'),
        translationKey: 'duplicate',
        enabled: true,
      },
    },
    {
      type: Options.HORIZONTAL_LINE,
    },
    {
      type: Options.REAL_OPTION,
      config: {
        id: ActionMenuActionIdEnum.DELETE,
        clickHandler: () => console.log('Delete clicked'),
        translationKey: 'delete',
        enabled: true,
      },
    },
  ];
};

// TODO: add DRAFT_EDIT_DELETE_DISALBED when someone is currently editing (and tooltips)
