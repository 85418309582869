import {
  FormDefinitionDraftCategoryStatsType,
  QuestionDefinitionSummaryType,
  Uuid,
} from '../../../common/common.types';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import update from 'immutability-helper';
import { reject } from 'ramda';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';

export type DeleteCategoryFunctionType = (categoryName: string) => void;

export type DeleteCategoryActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/DELETE_CATEGORY'
>;

export const deleteCategory = (
  categoryName: string,
): DeleteCategoryActionType => ({
  type: '@formCreator/EVENTS/DELETE_CATEGORY',
  payload: {
    events: [
      {
        action: FormEditEventActionCodeEnum.DELETECATEGORY,
        target_category: categoryName,
      },
    ],
    selector: ({ formCreator, formCreator: { currentForm } }) => {
      const removedUuids: Uuid[] = [];
      if (currentForm) {
        currentForm.questions.forEach(q => {
          if (q.category === categoryName) {
            removedUuids.push(q.wizardId);
            q.responses.forEach(r => {
              removedUuids.push(r.uniqueId);
            });
          }
        });
      }

      return update(formCreator, {
        currentForm: {
          categories: {
            $apply: reject(
              (category: FormDefinitionDraftCategoryStatsType) =>
                category.name === categoryName,
            ),
          },
          questions: {
            $apply: reject(
              (question: QuestionDefinitionSummaryType) =>
                question.category === categoryName,
            ),
          },
        },
        validation: {
          attemptedFixes: { $unset: removedUuids },
        },
      });
    },
  },
});
