import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import {
  InformationBoardWrapper,
  InformationBoardTitle,
  InformationBoardContent,
} from './NewVendorLanding.styles';

export interface InformationBoardType extends TranslatePropsType {
  title: string;
  content: string;
  actionElement?: JSX.Element;
  width?: number;
}

const InformationBoard = ({
  title,
  content,
  actionElement,
  width,
  t,
}: InformationBoardType) => {
  return (
    <InformationBoardWrapper width={width}>
      {title ? (
        <InformationBoardTitle>
          {ReactHTMLParser(t(title))}
        </InformationBoardTitle>
      ) : null}
      {content ? (
        <InformationBoardContent>
          {ReactHTMLParser(t(content))}
        </InformationBoardContent>
      ) : null}
      {actionElement ? actionElement : null}
    </InformationBoardWrapper>
  );
};

export default withTranslation('NewVendorLanding')(InformationBoard);
