import Loading from 'components/common/Loading';
import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import getEvidenceUrl from '../utils/getEvidenceUrl';
import { StateType } from 'store/store.types';
import { TranslatePropsType } from 'common/common.types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

interface EvidenceRedirectorPropsType
  extends TranslatePropsType,
    RouteComponentProps<{ id: string }> {
  isAuthenticated: boolean;
}

const EvidenceRedirector = ({
  isAuthenticated,
  match: {
    params: { id },
  },
  t,
}: EvidenceRedirectorPropsType) => {
  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = getEvidenceUrl(id);
    }
  });

  if (isAuthenticated) {
    return (
      <div style={{ textAlign: 'center', margin: 50 }}>
        {ReactHtmlParser(t('fileDownload', { link: getEvidenceUrl(id) }))}
      </div>
    );
  } else {
    return <Loading />;
  }
};

const mapState = ({ user }: StateType) => ({
  isAuthenticated: user.currentUser.isAuthenticated,
});

export default connect(mapState)(
  withTranslation('common')(withRouter(EvidenceRedirector)),
);
