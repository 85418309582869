import Asset from '../Asset';
import Icon from './Icon';
import Loader from 'react-loader-spinner';
import React, { memo } from 'react';
import colors from 'common/common.colors';
import { BodyWrapper, Extension } from './File.styles';
import { Link } from '../../Form/Question/Attachments/Attachment.styles';
import { TranslatePropsType, FileType, Uuid } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { textTruncate } from 'utils/multiLineEllipsis';

interface FilePropsType extends TranslatePropsType {
  deleteFile: (fileId: Uuid) => void;
  file: FileType;
  hideFile: (fileId: Uuid) => void;
  isHidden: boolean;
  selectFile: (file: FileType) => void;
  showHidden: boolean;
  unhideFile: (fileId: Uuid) => void;
}

const File = ({
  deleteFile,
  file,
  file: {
    id,
    name,
    extension,
    fileSize,
    isUploading,
    url,
    hasEvidenceLockerEntity,
  },
  hideFile,
  isHidden,
  selectFile,
  showHidden,
  unhideFile,
  t,
}: FilePropsType) => {
  const handleHideClick = () => (isHidden ? unhideFile(id) : hideFile(id));

  const handleDelete = () => {
    if (window.confirm(t('areYouSureToDelete', { filename: name }))) {
      deleteFile(id);
    }
  };

  const handleShareClick = () => selectFile(file);

  const uploadLabel = isUploading ? (t('uploading') as string) : fileSize;

  return (
    <div onClick={e => e.stopPropagation()}>
      <Asset
        showHidden={showHidden}
        isHidden={isHidden}
        hasEvidenceLockerEntity={hasEvidenceLockerEntity}
        body={
          <BodyWrapper>
            <Link href={url}>{textTruncate(name, 70)}</Link>

            <br />

            <span>{uploadLabel}</span>

            <div />
          </BodyWrapper>
        }
        handleDelete={handleDelete}
        handleHide={handleHideClick}
        handleShare={handleShareClick}
        key={name}
        left={
          <Extension extension={extension}>
            <Icon extension={extension} />

            {isUploading && (
              <div>
                <Loader
                  color={colors.basic.gray200}
                  height={30}
                  type={'Oval'}
                  width={30}
                />
              </div>
            )}
          </Extension>
        }
      />
    </div>
  );
};

export default memo(withTranslation('EvidenceLocker')(File));
