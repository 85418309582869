import getEvidenceUrl from '../getEvidenceUrl';
import getSuffix from '../getSuffix';
import { Evidence } from 'generated/models/Evidence';
import { FileType } from 'common/common.types';
import { EMPTY_ID } from 'common/common.constants';

type EvidenceType = Evidence & { is_uploading: boolean };

export const parseFile = (file: EvidenceType): FileType => {
  const {
    file_size = 0,
    id = EMPTY_ID,
    in_locker = false,
    name = '',
    owner_id = EMPTY_ID,
    updated_at,
    uploader_id = EMPTY_ID,
    is_uploading = false,
    has_evidence_locker_entity = false,
  } = file;

  return {
    extension: getSuffix(name),
    fileSize: `${Math.round(file_size / 1024)}kB`,
    id,
    inLocker: in_locker,
    isUploading: is_uploading,
    hasEvidenceLockerEntity: has_evidence_locker_entity,
    name,
    ownerId: owner_id,
    updatedAt: new Date(updated_at),
    uploaderId: uploader_id,
    url: getEvidenceUrl(id),
  };
};
