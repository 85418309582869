import React, { useCallback, useState, FocusEvent } from 'react';
import {
  FormCollaboratorType,
  FormProxyDetailType,
  MailingListItemType,
  RequestType,
  TranslatePropsType,
} from 'common/common.types';
import {
  FormProxyGrantAccessFunctionType,
  FormProxyNotifyProxyFunctionType,
  FormProxyRevokeAccessFunctionType,
} from 'store/formProxy/actions';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import route, { FORM } from '../../routes';
import { withHashUrl } from 'utils/url/withHashUrl';
import {
  CopyButton,
  CopyIcon,
  InputLabel,
  InviteWrapper,
  Label,
  LinkWrapper,
  ListWrapper,
  MultiEmail,
  Note,
} from './Recipients.styles';
import Input from '../common/Input';
import { Flex } from 'common/common.styles';
import { Button } from '../common/Button';
import { parseEmail } from 'utils/parseEmail';
import Recipient from './Recipient';
import {
  ContextEnum,
  getContext,
  getProxyRoles,
  grantAccessOrNotify,
} from './utils';
import Textarea from '../common/Textarea';

export interface RecipientsPropsType extends TranslatePropsType {
  collaborators: FormCollaboratorType[];
  copyLink: (link: string) => void;
  grantAccess: FormProxyGrantAccessFunctionType;
  notifyProxy: FormProxyNotifyProxyFunctionType;
  request: RequestType;
  revokeAccess: FormProxyRevokeAccessFunctionType;
}

const extractEmails = (text: string) => parseEmail(text) || [];

const Recipients = ({
  collaborators,
  copyLink,
  grantAccess,
  notifyProxy,
  request: {
    form: { id: formId },
    meta: { isVRM, isVendor },
    standard: { name },
  },
  revokeAccess,
  t,
}: RecipientsPropsType) => {
  const context: ContextEnum = getContext(isVRM, isVendor);
  const tOptions = { context };
  const formURL = withHashUrl(route(FORM, { id: formId }));

  const [emailBody, setEmailBody] = useState('');

  const [receivers, setReceivers] = useState<MailingListItemType[]>([]);

  const onShare = useCallback(() => {
    const roles = getProxyRoles(context);

    receivers.forEach(receiver => {
      const [rolesToGrant, proxyToNotify] = grantAccessOrNotify(
        roles,
        receiver.value,
        collaborators,
      );

      proxyToNotify.forEach(proxyId => notifyProxy(formId, proxyId, emailBody));
      grantAccess(formId, receiver.value, emailBody, rolesToGrant, name, false);
    });
  }, [
    collaborators,
    context,
    emailBody,
    formId,
    grantAccess,
    name,
    notifyProxy,
    receivers,
  ]);

  const onReceiversChange = (options: MailingListItemType[]) => {
    const emails: MailingListItemType[] = [];

    options.forEach((option: MailingListItemType) => {
      extractEmails(option.value).forEach((value: string) => {
        emails.push({ value, label: value });
      });
    });

    setReceivers(emails);
  };

  const onReceiversChangeSingle = (option: MailingListItemType) => {
    setReceivers([
      { value: extractEmails(option.value)[0], label: option.value },
    ]);
  };

  const onReceiversBlur = (val: string) => {
    setReceivers(prevReceivers =>
      prevReceivers.concat(
        extractEmails(val).map((value: string) => ({ value, label: value })),
      ),
    );
  };

  // TODO: uncomment, when an issue resolved on backend
  // const onReInvite = useCallback(
  //   (receiver: string, roles: FormRoleEnum[]) =>
  //     grantAccess(formId, receiver, '', roles, name),
  //   [formId, grantAccess, name],
  // );

  const onRevoke = useCallback(
    (proxies: FormProxyDetailType[]) =>
      proxies.forEach(proxy => revokeAccess(formId, proxy.id)),
    [formId, revokeAccess],
  );

  const onCopyLink = useCallback(() => copyLink(formURL), [copyLink, formURL]);

  return (
    <React.Fragment>
      <LinkWrapper>
        <Label>{t('link') as string}</Label>
        <Flex>
          <Input value={formURL} isReadOnly isReadOnlySelectable />
          <CopyButton onClick={onCopyLink}>
            <>
              <CopyIcon />
              {t('copyLink') as string}
            </>
          </CopyButton>
        </Flex>
      </LinkWrapper>
      {collaborators.length > 0 && (
        <div>
          <Label>{t('accessList', tOptions) as string}</Label>
          <ListWrapper>
            {collaborators.map(
              ({
                id: userId,
                canAnswer,
                canReview,
                canBeRevoked,
                proxies,
                user,
              }: FormCollaboratorType) => (
                <Recipient
                  key={`user-${userId}`}
                  canAnswer={canAnswer}
                  canReview={canReview}
                  onRevoke={canBeRevoked ? () => onRevoke(proxies) : undefined}
                  user={user}
                />
              ),
            )}
          </ListWrapper>
        </div>
      )}
      <InviteWrapper data-test="invite-proxy">
        <Label>{t('invite', tOptions) as string}</Label>
        <Flex alignItems="center" data-test="emails-input">
          <MultiEmail
            onChange={onReceiversChangeSingle}
            onBlur={(event: FocusEvent<HTMLInputElement>) =>
              onReceiversBlur(event.target.value)
            }
            options={[]}
            value={receivers}
            isMulti={false}
          />
          <InputLabel>{t('inviteLabel', tOptions) as string}</InputLabel>
        </Flex>
        <Note>{ReactHtmlParser(t('inviteNote', tOptions))}</Note>
        <Label>{t('emailBody') as string}</Label>
        <Textarea onBlur={setEmailBody} value={emailBody} />
        <Button
          color="teal"
          size="big"
          disabled={receivers.length === 0}
          onClick={onShare}
        >
          {t('share') as string}
        </Button>
      </InviteWrapper>
    </React.Fragment>
  );
};

export default withTranslation('FormProxy')(Recipients);
