import { FrameworkType } from 'common/common.types';
import { parseStandard } from './standard.parsers';
import { EMPTY_ID } from 'common/common.constants';
import { FormDetail } from 'generated/models';

export const parseFramework = (framework: FormDetail): FrameworkType => {
  const {
    id = EMPTY_ID,
    name = '',
    description = '',
    display_name = '',
    standard,
    questions_filled = 0,
    question_count = 0,
    has_evidence_locker_entity = false,
    owner_id = '',
  } = framework;

  const percentageCompleted =
    Math.round((questions_filled / question_count) * 100) || 0;

  return {
    id,
    name,
    description,
    displayName: display_name,
    questionsCount: question_count,
    questionsFilledCount: questions_filled,
    ownerId: owner_id,
    percentageCompleted,
    standard: parseStandard(standard),
    hasEvidenceLockerEntity: has_evidence_locker_entity,
  };
};
