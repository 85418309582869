import styled from 'styled-components';

export const Wrapper = styled.section`
  background: ${({ theme }) => theme.basic.colors.lightBlueBackground};
  display: flex;
  width: 100%;
  ${({ theme }) => theme.fonts.regular.small}
`;

export const LogoWrapper = styled.div`
  width: 95px;
  height: 150px;
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  padding: 22px 24px;
  display: flex;
  flex-direction: column;
`;

export const CTA = styled.h3`
  font-weight: ${({ theme }) => theme.basic.fontWeights.bold};
  font-size: ${({ theme }) => theme.basic.fontSizes.medium};
`;

export const Description = styled.p`
  white-space: pre-wrap;
  font-size: ${({ theme }) => theme.basic.fontSizes.normal};
  padding-top: 5px;
`;

export const CloseButton = styled.div`
  color: ${({ theme }) => theme.basic.colors.extraDark};
  cursor: pointer;
  padding: 15px 15px 0px 15px;
  font-size: ${({ theme }) => theme.basic.fontSizes.normal};
`;

type CTAButtonProps = {
  small?: boolean;
  blue?: boolean;
};

export const CTAButton = styled.div<CTAButtonProps>`
  cursor: pointer;
  background: ${({ theme, blue }) =>
    blue ? theme.basic.colors.blue : '#ffffff'};
  color: ${({ theme, blue }) =>
    blue ? '#ffffff' : theme.basic.colors.grayDark};
  width: ${({ small }) => (small ? '80px' : '200px')};
  align-self: flex-end;
  text-align: center;
  position: relative;
  font-weight: ${({ theme, small }) =>
    small ? theme.basic.fontWeights.regular : theme.basic.fontWeights.bold};
  font-size: ${({ theme }) => theme.basic.fontSizes.normal};
  padding: 12.5px 0;
  margin-top: ${({ small }) => (small ? '0px' : '20px')};
  margin-left: 20px;
  display: inline;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const UploadWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const UploadReccomendations = styled.div`
  width: 100%;
`;

export const UploadLandingWrapper = styled.div`
  margin: 25px 0;
`;

export const Reccomendations = styled.ul`
  margin-top: 10px;
  font-size: ${({ theme }) => theme.basic.fontSizes.normal};
  padding-left: ${({ theme }) => theme.basic.fontSizes.medium};
`;
