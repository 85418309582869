import api from '../../api';
import config from '../../config';
import { StripeCreateSessionActionType } from './actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { error as errorAction } from '../app/actions/error';
import { getActionDomain } from 'utils/getActionDomain';
import { captureError } from 'utils/sentryConfig';

function* stripeCall(action: StripeCreateSessionActionType) {
  const {
    payload: { price, quantity },
    type,
  } = action;
  const domain = getActionDomain(type);

  try {
    const {
      data: { payload, entry, entries },
    } = yield call(api.stripe.post, { price, quantity });

    const entryOrEntries = entry || entries || payload;

    if (entryOrEntries.session_id) {
      // @ts-ignore
      const stripe = window.Stripe(config.stripeKey);
      stripe.redirectToCheckout({ sessionId: entryOrEntries.session_id });
    } else {
      const data = 'Error creating stripe session.';

      yield put(errorAction({ domain, data, meta: {} }));
    }
  } catch (e) {
    captureError(e, action);

    yield put(errorAction({ domain, data: e.message, meta: {} }));
  }
}

export function* watchStripeCallSaga() {
  yield takeEvery('@stripe/CREATE_SESSION', stripeCall);
}
