import Chip from './Chip';
import React from 'react';
import { FilterFunctionsType } from 'pages/FormPage';
import { FormsFiltersType } from 'store/forms/forms.reducer';
import { TextButton, VerticallyCentered } from 'common/common.styles';
import { TranslatePropsType } from 'common/common.types';
import { Wrapper } from './FilterBarBottom.styles';
import { withTranslation } from 'react-i18next';

interface FilterBarBottomPropsType extends TranslatePropsType {
  data: {
    filters: FormsFiltersType;
    filtersCount: number;
  };
  actions: FilterFunctionsType;
}

const FilterBarBottom = ({
  data: {
    filters: { assignees, simple },
    filtersCount,
  },
  actions: { clearFilters, removeFilter },
  t,
}: FilterBarBottomPropsType) => (
  <Wrapper>
    {Object.keys(simple).map(filterName =>
      simple[filterName].isEnabled ? (
        <VerticallyCentered key={`chip-${filterName}`}>
          <Chip
            label={t(`filters.${filterName}`)}
            onDelete={() => removeFilter('simple', filterName)}
          />
        </VerticallyCentered>
      ) : null,
    )}

    {Object.keys(assignees).map(id =>
      assignees[id].isEnabled ? (
        <VerticallyCentered key={`chip-${id}`}>
          <Chip
            label={assignees[id]!.label}
            onDelete={() => removeFilter('assignees', id.toString())}
          />
        </VerticallyCentered>
      ) : null,
    )}

    {!!filtersCount && (
      <TextButton size="mini" color="teal" onClick={clearFilters}>
        {t('clearAllFilters') as string}
      </TextButton>
    )}
  </Wrapper>
);

export default withTranslation('FilterBar')(FilterBarBottom);
