import React from 'react';
import PageHeader from './PageHeader';
import { BarWrapper } from '../Bar/Bar.styles';

interface SimplePageHeaderPropsType {
  title: string;
}

const SimplePageHeader = ({ title }: SimplePageHeaderPropsType) => (
  <PageHeader
    heading={
      <BarWrapper>
        <h2>{title}</h2>
      </BarWrapper>
    }
  />
);

export default SimplePageHeader;
