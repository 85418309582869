import Loading, { AtlasSpinner } from '../common/Loading';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Form } from './';
import { FormDetailInitializationStatusEnum } from 'generated/models/FormDetail';
import { FormPage410 } from 'pages';
import { FormPageContextType } from 'pages/FormPage';
import { PageContextConsumer } from 'pages/Page';
import { ResponseStatus } from '../ResponseStatus';
import { SharedFormPageContextType } from 'pages/SharedFormPage';
import { TranslatePropsType } from 'common/common.types';
import { isEmpty } from 'ramda';
import { withTranslation } from 'react-i18next';
import { UnreachableCaseError } from 'utils/errors';

const {
  EMPTY,
  INPROGRESS,
  INQUEUE,
  FINISHEDFAIL,
  FINISHEDSUCCESS,
} = FormDetailInitializationStatusEnum;

const FormContextWrapper = ({ t }: TranslatePropsType) => (
  <PageContextConsumer>
    {(context: FormPageContextType | SharedFormPageContextType) => {
      const {
        actions,
        data,
        data: { currentForm, resourceGone },
      } = context;

      if (resourceGone) {
        return <FormPage410 resourceGone={resourceGone} />;
      }

      if (!currentForm || isEmpty(currentForm)) {
        return <Loading />;
      } else {
        switch (currentForm.initializationStatus) {
          case FINISHEDFAIL:
            return (
              <ResponseStatus
                caption={t('Form.failedCaption')}
                heading={ReactHtmlParser(t('Form.failed'))}
              />
            );

          case FINISHEDSUCCESS:
            return <Form actions={actions} data={data} />;

          case EMPTY:
          case INPROGRESS:
          case INQUEUE:
            return (
              <ResponseStatus
                caption={t('Form.initializingCaption')}
                heading={ReactHtmlParser(
                  t('Form.initializingHTML', { name: currentForm.displayName }),
                )}
                showIcon={false}
              >
                <AtlasSpinner />
              </ResponseStatus>
            );

          default:
            throw new UnreachableCaseError(currentForm.initializationStatus);
        }
      }
    }}
  </PageContextConsumer>
);

export default withTranslation()(FormContextWrapper);
