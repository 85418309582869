import {
  FixableFieldEnum,
  QuestionDefinitionSummaryType,
  Uuid,
} from '../../../common/common.types';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';
import update from 'immutability-helper';
import ifProblemAddToAttemptedFixes from '../utils/ifProblemAddToAttemptedFixes';

export type UpdateQuestionTextFunctionType = (
  questionId: Uuid,
  text: string,
) => void;

export type UpdateQuestionTextActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/UPDATE_QUESTION_TEXT'
>;

export const updateQuestionText = (
  questionId: Uuid,
  text: string,
): UpdateQuestionTextActionType => ({
  type: '@formCreator/EVENTS/UPDATE_QUESTION_TEXT',
  payload: {
    events: [
      {
        action: FormEditEventActionCodeEnum.UPDATEQUESTION,
        question_uuid: questionId,
        question: text,
      },
    ],
    selector: ({ formCreator }) =>
      update(formCreator, {
        currentForm: {
          questions: {
            $apply: (questions: QuestionDefinitionSummaryType[]) =>
              questions.map(q =>
                q.wizardId === questionId ? { ...q, question: text } : q,
              ),
          },
        },
        validation: {
          $apply: ifProblemAddToAttemptedFixes(
            'question',
            questionId,
            FixableFieldEnum.TEXT,
          ),
        },
      }),
  },
});
