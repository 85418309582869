/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { QuestionConditionStructuredItem } from "./QuestionConditionStructuredItem";

/**
 * 
 * @export
 * @interface QuestionConditionStructured
 */
export interface QuestionConditionStructured {
    /**
     * 
     * @type {Array<QuestionConditionStructuredItem>}
     * @memberof QuestionConditionStructured
     */
    items: Array<QuestionConditionStructuredItem>;
    /**
     * 
     * @type {string}
     * @memberof QuestionConditionStructured
     */
    operator: QuestionConditionStructuredOperatorEnum;
}




/**
* @export
* @enum {string}
*/
export enum QuestionConditionStructuredOperatorEnum {
    AND = 'AND',
    OR = 'OR'
}


