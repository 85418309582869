import companies from './companies.api';
import evidences from './evidences.api';
import definitions from './definitions.api';
import forms from './forms.api';
import requests from './requests.api';
import settings from './settings.api';
import standards from './standards.api';
import stripe from './stripe.api';
import support from './support.api';
import user from './user.api';
import metadata from './metadata.api';

export default {
  companies,
  evidences,
  definitions,
  forms,
  requests,
  settings,
  standards,
  stripe,
  support,
  user,
  metadata,
};
