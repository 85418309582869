import { QuestionTabEnum, Uuid } from '../../../common/common.types';

export type FormsSwitchQuestionFunctionType = (
  tab: QuestionTabEnum,
  questionId?: Uuid,
) => void;

export interface FormsSwitchQuestionTabActionType {
  type: '@forms/QUESTION_SWITCH_TAB';
  payload: {
    questionId?: Uuid;
    tab: QuestionTabEnum;
  };
}

export const switchQuestionTab = (
  tab: QuestionTabEnum,
  questionId?: Uuid,
): FormsSwitchQuestionTabActionType => ({
  type: '@forms/QUESTION_SWITCH_TAB',
  payload: {
    questionId,
    tab,
  },
});
