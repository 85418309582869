import React from 'react';
import { withTranslation } from 'react-i18next';
import { FORM_PROXY_MODAL_ID } from 'common/common.constants';
import { Content, Heading, MyModal } from './FormProxy.styles';
import {
  FormCollaboratorType,
  RequestType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import { connect } from 'react-redux';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import * as actions from 'store/formProxy/actions';
import InlineLoading from '../common/InlineLoading';
import Recipients from './Recipients';
import { copyTextToClipboard } from 'utils/copyTextToClipboard';
import i18n from 'i18next';
import { flash } from 'store/flashes/actions';
import { ProxyShortSummaryFormRoleEnum } from 'generated/models';
import { getCollaborators } from 'store/formProxy/formProxy.selectors';

export interface FormProxyOwnPropsType {
  onClose?: () => void;
  onGrantAccess?: (userId: Uuid) => void;
  onRevoke?: () => void;
}

export interface FormProxyPropsType
  extends FormProxyOwnPropsType,
    TranslatePropsType {
  collaborators: FormCollaboratorType[];
  copyLink: (link: string) => void;
  grantAccess: actions.FormProxyGrantAccessFunctionType;
  notifyProxy: actions.FormProxyNotifyProxyFunctionType;
  request?: RequestType;
  resetModal: actions.FormProxyResetProxyModalFunctionType;
  revokeAccess: actions.FormProxyRevokeAccessFunctionType;
}

const FormProxy = ({
  collaborators,
  copyLink,
  grantAccess,
  notifyProxy,
  request,
  resetModal,
  revokeAccess,
  t,
}: FormProxyPropsType) => {
  const modalProps = {
    modalId: FORM_PROXY_MODAL_ID,
    onClose: resetModal,
    title: request && (
      <Heading>
        {t('title', { standard: request.standard.name }) as string}
      </Heading>
    ),
  };

  return (
    <MyModal {...modalProps}>
      <Content>
        {request ? (
          <Recipients
            collaborators={collaborators}
            copyLink={copyLink}
            grantAccess={grantAccess}
            notifyProxy={notifyProxy}
            request={request}
            revokeAccess={revokeAccess}
          />
        ) : (
          <InlineLoading />
        )}
      </Content>
    </MyModal>
  );
};

const mapStateToProps = ({ formProxy, user }: StateType) => ({
  collaborators: getCollaborators(formProxy),
  request: formProxy.request,
});

const mapDispatchToProps = (
  dispatch: Dispatch<ActionType>,
  { onClose, onGrantAccess, onRevoke }: FormProxyOwnPropsType,
) => ({
  copyLink: (link: string) => {
    copyTextToClipboard(link);
    dispatch(flash(i18n.t('common:responses.copySuccess')));
  },
  grantAccess: (
    formId: Uuid,
    userEmail: string,
    emailBody: string,
    proxyRoles: ProxyShortSummaryFormRoleEnum[],
    standardName: string,
    skipNotification?: boolean,
  ) =>
    dispatch(
      actions.grantAccess(
        formId,
        userEmail,
        emailBody,
        proxyRoles,
        standardName,
        skipNotification,
        onGrantAccess,
      ),
    ),
  notifyProxy: (formId: Uuid, proxyId: Uuid, emailBody: string) => {
    dispatch(actions.notifyProxy(formId, proxyId, emailBody));
  },
  resetModal: () => {
    dispatch(actions.resetProxyModal());
    if (onClose) {
      onClose();
    }
  },
  revokeAccess: (formId: Uuid, proxyId: Uuid) =>
    dispatch(actions.revokeAccess(formId, proxyId, onRevoke)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('FormProxy')(FormProxy));
