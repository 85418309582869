import { AnchorRight, ClearAll, Closer, Content } from './FilterBy.styles';
import { CloseIcon } from '../../common/Overlay';
import { Dropdown } from '../../common/Dropdown';
import { DropdownState } from '../../common/Dropdown/Dropdown';
import FilterStatus from './FilterBy.Status';
import React, { useCallback } from 'react';

import { StandardStatusType, TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { FormDefinitionsFilterByFunctionType } from '../../../store/formDefinitions/actions';

export interface FilterByPropsType extends TranslatePropsType {
  data: {
    isClearable?: boolean;
    byStatus: StandardStatusType[];
  };
  actions: {
    setFilterByStatus: FormDefinitionsFilterByFunctionType;
  };
}

const FilterBy = ({
  data: { isClearable = false, byStatus },
  actions: { setFilterByStatus },
  t,
}: FilterByPropsType) => {
  const clearFilters = useCallback(() => setFilterByStatus([]), [
    setFilterByStatus,
  ]);

  const isFilterSet = byStatus.length !== 0;

  return (
    <Dropdown
      color={isFilterSet ? 'teal' : 'transparent'}
      isRelative={false}
      size="medium"
      text={t('Requests.filterBy')}
      childrenWrapper={AnchorRight}
    >
      {({ close }: DropdownState) => (
        <>
          <Content columns={1}>
            <Closer onClick={close}>
              <CloseIcon />
            </Closer>

            <FilterStatus
              action={setFilterByStatus}
              isClearable={isClearable}
              filters={byStatus}
            />
          </Content>
          {isClearable && (
            <ClearAll color="teal" onClick={clearFilters} size="small">
              {t('common:labels.clearAll') as string}
            </ClearAll>
          )}
        </>
      )}
    </Dropdown>
  );
};

export default withTranslation(['components', 'common'])(FilterBy);
