import { ApiCallActionPayloadType } from '../../store.types';
import update from 'immutability-helper';
import { FormCreatorStateType } from '../formCreator.types';
import { FormDefinitionValidateActionSummary } from '../../../generated/models/FormDefinitionValidateActionSummary';
import { parseFormDefinitionValidateActionSummary } from 'utils/parsers/formDefinition.parsers';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { Uuid } from '../../../common/common.types';
import { EMPTY_ID } from '../../../common/common.constants';

export type ValidateFormFunctionType = () => void;

export interface ValidateFormCallParamsType {
  draft_id?: Uuid;
}

export type ValidateFormActionType = OptimisticActionType<
  '@formCreator/API/VALIDATE_FORM',
  ApiCallActionPayloadType<
    ValidateFormCallParamsType,
    FormDefinitionValidateActionSummary,
    never,
    FormCreatorStateType
  >
>;

export const validateForm = (): ValidateFormActionType => ({
  type: '@formCreator/API/VALIDATE_FORM',
  payload: {
    endpointPath: ['definitions', 'drafts', 'validate'],
    callParams: state => ({
      draft_id: state.formCreator.currentForm
        ? state.formCreator.currentForm.id
        : EMPTY_ID,
    }),
    expectedStateChange: {
      'formCreator.validation.isLoading': true,
    },
    selector: (data, state): Partial<FormCreatorStateType> => {
      return update(state.formCreator, {
        validation: {
          $set: parseFormDefinitionValidateActionSummary(data),
        },
      });
    },
  },
});
