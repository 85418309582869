import styled from 'styled-components';

export const InputWrapper = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;

  &:not(:first-of-type) {
    margin-top: 8px;
  }
`;

export const ButtonWrapper = styled.div`
  align-self: flex-start;
`;

export const SelectResponseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${InputWrapper} + ${ButtonWrapper} {
    margin-top: 10px;
  }
`;

export const ButtonText = styled.span`
  color: ${({ theme }) => theme.atlas.teal.primary};
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
`;
