import React, { KeyboardEvent } from 'react';
import uuid from 'utils/uuid';
import { ContentContainer, ContentWrapper } from '../QuestionTabs.styles';
import { Message } from '.';
import { TabCommonType } from '../QuestionTabs';
import { TabContentTitle } from '../';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { messagesToSortedArray } from '../../utils';
import { withTranslation } from 'react-i18next';
import {
  FormsCreateMessageFunctionType,
  FormsSetBlockSeenFunctionType,
} from 'store/forms/actions';
import {
  Bottom,
  InnerFlex,
  Submit,
  ScrollableBottom,
  Textarea,
} from './Messages.styles';
import {
  LanguageType,
  MessageType,
  QuestionTabEnum,
  QuestionType,
  TranslatePropsType,
  UserType,
  Uuid,
} from 'common/common.types';
import { FormDetailFillingTypeEnum } from 'generated/models/FormDetail';

interface MessagesPropsType extends TranslatePropsType, TabCommonType {
  createMessage?: FormsCreateMessageFunctionType;
  formFillingType: FormDetailFillingTypeEnum;
  formId: Uuid;
  formOwner: string;
  formSource?: string;
  messages: MessageType[];
  question: QuestionType;
  setBlockSeen?: FormsSetBlockSeenFunctionType;
  user: UserType;
  language: LanguageType;
}

const Messages = ({
  createMessage,
  formFillingType,
  formId,
  formOwner,
  formSource,
  hasNotifications,
  messages,
  question,
  setBlockSeen,
  t,
  user,
  language,
}: MessagesPropsType) => {
  const inputId = uuid();
  const to = user.company.name === formOwner ? formSource : formOwner;
  const title = to
    ? (t('title', { to, context: formFillingType }) as string)
    : (t('title') as string);

  const handleSubmit = () => {
    const input: any = document.getElementById(inputId);

    if (createMessage && input && input.value.trim() !== '') {
      createMessage(question.id, input.value.trim(), uuid(), user, formId);
      input.value = '';
      if (setBlockSeen && hasNotifications) {
        setBlockSeen(question.id, QuestionTabEnum.COMMENTS);
      }
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <ContentContainer hasBackground={!!messages.length}>
      <ContentWrapper>
        <TabContentTitle icon={faComment} title={title} />

        <ScrollableBottom hasContent={!!messages.length}>
          <InnerFlex data-cy="question-tab-messages-list">
            {messagesToSortedArray(messages).map(message => (
              <Message
                key={`message-${message.id || message.postingId}`}
                message={message}
                language={language}
              />
            ))}
          </InnerFlex>
        </ScrollableBottom>

        {createMessage && (
          <Bottom>
            <Textarea
              data-cy="question-tab-messages-text-area"
              placeholder={t('type') as string}
              id={inputId}
              onKeyPress={handleKeyPress}
            />

            <Submit
              data-cy="question-tab-messages-submit"
              isActive
              onClick={handleSubmit}
            >
              {t('send') as string}
            </Submit>
          </Bottom>
        )}
      </ContentWrapper>
    </ContentContainer>
  );
};

export default withTranslation('Messages')(Messages);
