import { FormCreatorStateType } from '../../../../store/formCreator/formCreator.types';
import { EventDescriptionType } from '../../../../store/formCreator/event.types';

export interface EnqueueAndApplyEventsActionType {
  type: '@formCreator/EVENT_SEQ_ENQUEUE_AND_APPLY_EVENTS';
  payload: {
    newEvents: EventDescriptionType[];
    newState: FormCreatorStateType;
  };
}

export const enqueueAndApplyEvents = (
  newEvents: EventDescriptionType[],
  newState: FormCreatorStateType,
): EnqueueAndApplyEventsActionType => ({
  type: '@formCreator/EVENT_SEQ_ENQUEUE_AND_APPLY_EVENTS',
  payload: {
    newEvents,
    newState,
  },
});
