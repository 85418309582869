import { findIndex } from 'ramda';
import update from 'immutability-helper';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';

import { FormDefinitionsStandardsType } from '../formDefinitions.types';
import { StandardStatusEnum } from '../../../generated/models/Standard';
import { Uuid } from '../../../common/common.types';

export type PublishDraftFromFormDefinitionsFunctionType = (
  params: PublishDraftFromFormDefinitionsCallParamsType,
) => void;

export interface PublishDraftFromFormDefinitionsCallParamsType {
  draft_id: Uuid;
}

export type PublishDraftFromFormDefinitionsActionType = OptimisticActionType<
  '@formDefinitions/API/PUBLISH_DRAFT',
  ApiCallActionPayloadType<PublishDraftFromFormDefinitionsCallParamsType>
>;

export const publishDraftFromFormDefinitions = (
  params: PublishDraftFromFormDefinitionsCallParamsType,
): PublishDraftFromFormDefinitionsActionType => ({
  type: '@formDefinitions/API/PUBLISH_DRAFT',
  payload: {
    endpointPath: ['definitions', 'drafts', 'publish'],
    callParams: params,
    selector: null,
    expectedStateChange: {
      'formDefinitions.standards': (state: FormDefinitionsStandardsType) => {
        const dataIndex = findIndex(
          ({ definitionId }) => definitionId === params.draft_id,
          state.data,
        );

        return update(state, {
          data: {
            [dataIndex]: {
              status: { $set: StandardStatusEnum.CUSTOM },
              isUnpublishable: { $set: true },
            },
          },
        });
      },
    },
  },
});
