import React from 'react';
import colors from 'common/common.colors';
import {
  FactorDescription,
  FactorHeading,
  FactorName,
  FactorScore,
  IssueFindings,
  IssueListHeadingRow,
  IssueListRow,
  IssueSeverityName,
} from './SscData.styles';
import { Hexagon } from '../../../Companies/CompanyHeader/CompanyHeader.styles';
import { withTranslation } from 'react-i18next';
import {
  SeverityTypeEnum,
  SscDataFactorType,
  TranslatePropsType,
} from 'common/common.types';
import Severity from '../../../common/Severity';

const SscFactor = ({
  description,
  grade,
  issues,
  score,
  title: factorName,
  t,
}: SscDataFactorType & TranslatePropsType) => (
  <div key={factorName}>
    <FactorHeading>
      <Hexagon
        color={colors.grades[grade]}
        filled={false}
        size={20}
        text={grade}
      />

      <FactorScore>{score}</FactorScore>

      <div>
        <FactorName>{factorName}</FactorName>
        <FactorDescription>{description}</FactorDescription>
      </div>
    </FactorHeading>

    {issues.length > 0 && (
      <IssueListHeadingRow>
        <div>{t('severity') as string}</div>

        <div>{t('issue') as string}</div>

        <div>{t('findings') as string}</div>
      </IssueListHeadingRow>
    )}

    {issues.map(({ title, severity, issueCount, detailUrl }, i) => (
      <IssueListRow
        key={title}
        hasFindings={Boolean(issueCount > 0 && detailUrl)}
      >
        <div>
          {severity !== SeverityTypeEnum.UNKNOWN && (
            <>
              <Severity size={12} type={severity} />
              <IssueSeverityName>{severity}</IssueSeverityName>
            </>
          )}
        </div>

        <div>
          {issueCount > 0 && detailUrl ? (
            <a href={detailUrl} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          ) : (
            title
          )}
        </div>

        <IssueFindings>{issueCount}</IssueFindings>
      </IssueListRow>
    ))}
  </div>
);

export default withTranslation('SscData')(SscFactor);
