import styled from 'styled-components/macro';
import hex2rgba from '../hex2rgba';

export const Background = styled.div<{ backdropColor?: string; order: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${({ order }) => 100000 + order * 100};
  background-color: ${({ backdropColor, theme }) =>
    hex2rgba(theme.basic.colors[backdropColor || 'dark'], 0.4)};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalPane = styled.div`
  background-color: ${({ theme }) => theme.basic.colors.light};
  box-shadow: 0 4px 9px
    ${({ theme }) => hex2rgba(theme.basic.colors.black, 0.2)};
`;

export const Header = styled.div`
  min-height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.basic.colors.darkerBorder};
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const HeaderContent = styled.div`
  ${({ theme }) => theme.fonts.semibold.normal}
  color: ${({ theme }) => theme.basic.colors.extraDark};
  padding: 22px 0 17px 42px;
  flex: 1;
  line-height: 20px;
`;

export const CloseButton = styled.div`
  color: ${({ theme }) => theme.basic.colors.extraDark};
  cursor: pointer;
  padding: 22px 22px 0 22px;
  font-size: ${({ theme }) => theme.basic.fontSizes.large};
`;
