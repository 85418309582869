import * as P from './pages';
import formatRoute, * as R from './routes';
import LoadUserData from './components/LoadUserData';
import Loading from './components/common/Loading';
import React from 'react';
import { withHeader, withoutHeader } from './components/Layout';
import store from './store/index';
import withRole from './components/Utils/Role';
import { FirstRun } from '../src/components/FirstRun';
import { Redirect, Route, Switch } from 'react-router-dom';
import { EMPTY_ID } from './common/common.constants';
import { UserDetailRolesEnum } from './generated/models/UserDetail';
import { NavigationContextEnum as NavContext } from './common/common.types';

const requestsRedirect = () => {
  const { id, navigationContext } = store.getState().user.currentUser;

  if (id === EMPTY_ID) {
    return <Loading />;
  }

  return (
    <Redirect
      to={formatRoute(
        navigationContext === NavContext.ANSWER
          ? R.REQUESTS_RECEIVED
          : R.REQUESTS_SENT,
        {},
      )}
    />
  );
};

const Router = () => {
  return (
    <Switch>
      <LoadUserData>
        <Switch>
          <Route path={R.HOMEPAGE} component={P.HomeRedirectPageType} exact />
          <Route
            path={R.DOWNLOAD_EVIDENCE}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withHeader(P.EvidenceDownloadPage, {}, { hideHeader: true }),
            )}
          />
          <Route
            path={R.FIRST_RUN}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withoutHeader(FirstRun),
            )}
            exact
          />
          <Route
            path={R.NEW_VENDOR_LANDING_PAGE}
            component={withoutHeader(P.NewVendorLandingPage)}
            exact
          />
          <Route
            path={R.REQUESTS_ARCHIVE}
            component={withHeader(P.RequestsArchivePage)}
          />
          <Route path={R.REQUESTS} render={requestsRedirect} exact />
          <Route
            path={R.REQUESTS_RECEIVED}
            component={withHeader(
              P.RequestsPage,
              { navContext: NavContext.ANSWER },
              { direction: 'received' },
            )}
            exact
          />
          <Route
            path={R.REQUESTS_SENT}
            component={withHeader(
              P.RequestsPage,
              { navContext: NavContext.SEND },
              { direction: 'sent' },
            )}
            exact
          />
          <Route
            path={R.FRAMEWORKS_DASHBOARD}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withHeader(P.EvidenceLockerPage, {
                navContext: NavContext.ANSWER,
              }),
            )}
          />
          <Route
            path={R.COMPANIES}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withoutHeader(P.CompaniesPage),
            )}
            exact
          />
          <Route
            path={R.COMPANY}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withoutHeader(P.CompanyPage),
            )}
            exact
          />
          <Route path={R.FAQ} component={withHeader(P.FAQPage)} />
          <Route
            path={R.FORM}
            component={withHeader(P.FormPage, {}, { hideHeader: true })}
            exact
          />
          <Route path={R.FORM_FINDINGS} component={P.FormFindingsPage} exact />
          <Route
            path={R.FORM_CREATOR}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withHeader(
                P.FormCreatorPage,
                { navContext: NavContext.SEND },
                { hideHeader: true },
              ),
            )}
            exact
          />
          <Route
            path={R.FORM_DEFINITION_PREVIEW}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withHeader(P.FormDefinitionPreviewPage, {
                navContext: NavContext.SEND,
              }),
            )}
            exact
          />
          <Route
            path={R.FORM_DEFINITIONS}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withHeader(P.FormDefinitionsPage, {
                navContext: NavContext.SEND,
              }),
            )}
            exact
          />
          <Route
            path={R.FORM_NEW_FROM_EXISTING}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withHeader(P.FormNewFromExisting, {
                navContext: NavContext.SEND,
              }),
            )}
            exact
          />
          <Route
            path={R.UPLOAD_CUSTOM_STANDARD}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withHeader(P.UploadCustomStandardPage, {
                navContext: NavContext.SEND,
              }),
            )}
            exact
          />
          <Route
            path={R.QUESTIONNAIRE}
            exact
            render={({
              match: {
                params: { id },
              },
            }) => <Redirect to={R.default(R.FORM, { id })} />}
          />
          <Route
            path={R.SEND_REQUEST}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withHeader(P.SendRequestPage),
            )}
          />
          <Route
            path={R.INSIGHTS}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withHeader(P.InsightsPage, {
                navContext: NavContext.SEND,
              }),
            )}
            exact
          />
          <Route
            path={R.SALES}
            component={withRole(UserDetailRolesEnum.SALES)(
              withHeader(P.SalesPage),
            )}
            exact
          />
          <Route
            path={R.SETTINGS}
            component={withRole(UserDetailRolesEnum.NORMAL)(
              withHeader(P.SettingsPage),
            )}
            exact
          />
          <Route
            path={R.SHARED_FORM}
            component={withHeader(P.SharedFormPage)}
            exact
          />
          <Route path={R.P403} component={withoutHeader(P.Page403)} />
          <Route path={R.UTILS} component={withHeader(P.UtilsPage)} />
          <Switch>
            <Redirect from={R.EVIDENCE} to={R.ATTACHMENT} />
            <Route
              path={R.ATTACHMENT}
              component={withoutHeader(P.EvidenceRedirector, {
                navContext: NavContext.ANSWER,
              })}
              exact
            />
          </Switch>
          <Route component={withoutHeader(P.Page404)} />
        </Switch>
      </LoadUserData>
    </Switch>
  );
};

export default Router;
