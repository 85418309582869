import React from 'react';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import { FlashType, Uuid } from 'common/common.types';
import { connect } from 'react-redux';
import { removeFlashAction } from 'store/flashes/actions';
import FlashMessage from './FlashMessage';
import { Flashes } from '../Layout/Layout.styles';

interface FlashMessagesPropsType {
  flashes: FlashType[];
  removeFlash: (id: Uuid) => void;
}

const FlashMessages = ({ flashes, removeFlash }: FlashMessagesPropsType) => {
  if (flashes.length === 0) {
    return null;
  }

  return (
    <Flashes>
      {flashes.map((flash: FlashType) => (
        <FlashMessage
          key={`flash-${flash.id}`}
          id={flash.id}
          message={flash.message}
          removeFlash={removeFlash}
          status={flash.status}
        />
      ))}
    </Flashes>
  );
};

const mapStateToProps = (state: StateType) => ({
  flashes: state.flashes,
});

const mapDispatchToProps = (dispatch: Dispatch<ActionType>) => ({
  removeFlash: (id: Uuid) => dispatch(removeFlashAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessages);
