/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface VRMReviewStats
 */
export interface VRMReviewStats {
    /**
     * 
     * @type {string}
     * @memberof VRMReviewStats
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof VRMReviewStats
     */
    scope: VRMReviewStatsScopeEnum;
    /**
     * 
     * @type {string}
     * @memberof VRMReviewStats
     */
    status?: VRMReviewStatsStatusEnum;
}




/**
* @export
* @enum {string}
*/
export enum VRMReviewStatsScopeEnum {
    SINGLESTATUS = 'SINGLE_STATUS',
    MULTIPLESTATUSES = 'MULTIPLE_STATUSES',
    NOQUESTIONS = 'NO_QUESTIONS'
}
/**
* @export
* @enum {string}
*/
export enum VRMReviewStatsStatusEnum {
    DONE = 'DONE',
    FLAGGED = 'FLAGGED',
    PENDING = 'PENDING'
}


