/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface CategoryDelegationStats
 */
export interface CategoryDelegationStats {
    /**
     * 
     * @type {User}
     * @memberof CategoryDelegationStats
     */
    assignee?: User;
    /**
     * 
     * @type {string}
     * @memberof CategoryDelegationStats
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDelegationStats
     */
    quantity: CategoryDelegationStatsQuantityEnum;
    /**
     * 
     * @type {string}
     * @memberof CategoryDelegationStats
     */
    scope: CategoryDelegationStatsScopeEnum;
}

export function CategoryDelegationStatsFromJSON(json: any): CategoryDelegationStats {
    return CategoryDelegationStatsFromJSONTyped(json, false);
}

export function CategoryDelegationStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryDelegationStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee': !exists(json, 'assignee') ? undefined : UserFromJSON(json['assignee']),
        'category': !exists(json, 'category') ? undefined : json['category'],
        'quantity': json['quantity'],
        'scope': json['scope'],
    };
}

export function CategoryDelegationStatsToJSON(value?: CategoryDelegationStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee': UserToJSON(value.assignee),
        'category': value.category,
        'quantity': value.quantity,
        'scope': value.scope,
    };
}

/**
* @export
* @enum {string}
*/
export enum CategoryDelegationStatsQuantityEnum {
    MULTIPLEUSERS = 'MULTIPLE_USERS',
    SINGLEUSER = 'SINGLE_USER',
    NOUSER = 'NO_USER'
}
/**
* @export
* @enum {string}
*/
export enum CategoryDelegationStatsScopeEnum {
    ALL = 'ALL',
    SOME = 'SOME',
    NONE = 'NONE'
}


