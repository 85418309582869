/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SleepData
 */
export interface SleepData {
    /**
     * 
     * @type {string}
     * @memberof SleepData
     */
    queue?: SleepDataQueueEnum;
    /**
     * 
     * @type {number}
     * @memberof SleepData
     */
    seconds: number;
}

export function SleepDataFromJSON(json: any): SleepData {
    return SleepDataFromJSONTyped(json, false);
}

export function SleepDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SleepData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'queue': !exists(json, 'queue') ? undefined : json['queue'],
        'seconds': json['seconds'],
    };
}

export function SleepDataToJSON(value?: SleepData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'queue': value.queue,
        'seconds': value.seconds,
    };
}

/**
* @export
* @enum {string}
*/
export enum SleepDataQueueEnum {
    Default = 'default',
    Email = 'email',
    Mlengine = 'mlengine',
    Setup = 'setup'
}


