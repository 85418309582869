import styled from 'styled-components';
import Textarea from '../../common/Textarea';

export const Wrapper = styled.div`
  padding-right: 28px;
  padding-bottom: 10px;
`;

export const ConditionTextBox = styled(Textarea)`
  height: 89px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.atlas.text.secondary} !important;
  ${({ theme }) => theme.fonts.monospace.regular.small}
`;
