/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DelegationNotificationStatusChangeStats,
    DelegationNotificationStatusChangeStatsFromJSON,
    DelegationNotificationStatusChangeStatsFromJSONTyped,
    DelegationNotificationStatusChangeStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface DelegationNotificationsStatusChange
 */
export interface DelegationNotificationsStatusChange {
    /**
     * 
     * @type {string}
     * @memberof DelegationNotificationsStatusChange
     */
    formId: string;
    /**
     * 
     * @type {DelegationNotificationStatusChangeStats}
     * @memberof DelegationNotificationsStatusChange
     */
    stats: DelegationNotificationStatusChangeStats;
}

export function DelegationNotificationsStatusChangeFromJSON(json: any): DelegationNotificationsStatusChange {
    return DelegationNotificationsStatusChangeFromJSONTyped(json, false);
}

export function DelegationNotificationsStatusChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DelegationNotificationsStatusChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formId': json['form_id'],
        'stats': DelegationNotificationStatusChangeStatsFromJSON(json['stats']),
    };
}

export function DelegationNotificationsStatusChangeToJSON(value?: DelegationNotificationsStatusChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_id': value.formId,
        'stats': DelegationNotificationStatusChangeStatsToJSON(value.stats),
    };
}


