/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyDetail,
    CompanyDetailFromJSON,
    CompanyDetailFromJSONTyped,
    CompanyDetailToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyDetailResponse
 */
export interface CompanyDetailResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof CompanyDetailResponse
     */
    msg: string;
    /**
     * 
     * @type {CompanyDetail}
     * @memberof CompanyDetailResponse
     */
    entry: CompanyDetail;
}

export function CompanyDetailResponseFromJSON(json: any): CompanyDetailResponse {
    return CompanyDetailResponseFromJSONTyped(json, false);
}

export function CompanyDetailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDetailResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': CompanyDetailFromJSON(json['entry']),
    };
}

export function CompanyDetailResponseToJSON(value?: CompanyDetailResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': CompanyDetailToJSON(value.entry),
    };
}


