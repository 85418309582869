import {
  QuestionDefinitionSummaryType,
  Uuid,
  Condition,
  FixableFieldEnum,
} from '../../../common/common.types';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';
import update from 'immutability-helper';
import { EventDescriptionType } from '../event.types';
import encodeStructuredCondition from '../utils/encodeStructuredCondition';
import ifProblemAddToAttemptedFixes from '../utils/ifProblemAddToAttemptedFixes';
import structuredToText from '../conditions/structuredToText';

export type UpdateQuestionConditionFunctionType = (
  questionId: Uuid,
  condition: Condition | null,
) => void;

export type UpdateQuestionConditionActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/UPDATE_CONDITION'
>;

export const updateQuestionCondition = (
  questionId: Uuid,
  condition: Condition | null,
): UpdateQuestionConditionActionType => {
  const event: EventDescriptionType = condition
    ? condition.type === 'text'
      ? {
          action: FormEditEventActionCodeEnum.UPDATECONDITIONTEXT,
          question_uuid: questionId,
          text: condition.payload,
        }
      : {
          action: FormEditEventActionCodeEnum.UPDATECONDITIONSTRUCTURED,
          question_uuid: questionId,
          structured: encodeStructuredCondition(condition.payload),
        }
    : // Remove the condition
      {
        action: FormEditEventActionCodeEnum.UPDATECONDITIONTEXT,
        question_uuid: questionId,
        text: '',
      };

  return {
    type: '@formCreator/EVENTS/UPDATE_CONDITION',
    payload: {
      events: [event],
      selector: ({ formCreator, formCreator: { currentForm } }) => {
        if (!currentForm) {
          return formCreator;
        }

        return update(formCreator, {
          currentForm: {
            questions: {
              $apply: (questions: QuestionDefinitionSummaryType[]) =>
                questions.map(q =>
                  q.wizardId === questionId
                    ? {
                        ...q,
                        condition: condition || undefined,
                        conditionBackup:
                          condition && condition.type === 'structured'
                            ? {
                                structured: condition.payload,
                                text: structuredToText(
                                  currentForm.questions,
                                  condition.payload,
                                ),
                              }
                            : !condition
                            ? undefined
                            : q.conditionBackup,
                      }
                    : q,
                ),
            },
          },
          validation: {
            $apply: ifProblemAddToAttemptedFixes(
              'question',
              questionId,
              FixableFieldEnum.CONDITION,
            ),
          },
        });
      },
    },
  };
};
