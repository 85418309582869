import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 15px;

  h3 {
    max-width: 250px;
    white-space: pre-wrap;
  }
`;
