import axios from 'axios';
import { P403, getSignInUrl } from '../routes';

export const setupAxios = () => {
  axios.defaults.withCredentials = true;
  axios.defaults.headers.get.Pragma = 'no-cache';
  axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';
  axios.interceptors.response.use(
    response => response,
    error => {
      onRequestError(error);
      return Promise.reject(error);
    },
  );
};

export const onRequestError = (error: {
  response?: {
    status: number;
  };
}) => {
  if (error.response === undefined) {
    return;
  }

  const response = error.response;

  console.error('response error', response);

  if (response.status === 401) {
    window.location.href = getSignInUrl();
  }

  if (response.status === 403) {
    window.location.href = `#${P403}`;
  }
};
