import Chart from './Chart';
import React, { useMemo } from 'react';
import ReactHTMLParser from 'react-html-parser';
import { format } from 'date-fns';
import { DATE_FORMAT_BASED_ON_LOCALE } from 'common/common.constants';
import { keys, pathOr } from 'ramda';
import { withTranslation } from 'react-i18next';
import { CompanyName, List, Wrapper } from './CreditsOverview.styles';
import {
  CompanyCreditsType,
  LanguageType,
  TranslatePropsType,
} from 'common/common.types';
import { StateType } from '../../../store/store.types';
import { getLanguagePreference } from '../../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import getLocale from 'utils/getLocale';

interface GroupedCreditItemsDict {
  [key: string]: {
    count: number;
    isExpired: boolean;
  };
}

interface CreditsOverviewPropsType extends TranslatePropsType {
  big?: boolean;
  companyName: string;
  credits: CompanyCreditsType;
  language: LanguageType;
}

const CreditsOverview = ({
  big,
  companyName,
  credits: { summary, list: rawList },
  language,
  t,
}: CreditsOverviewPropsType) => {
  const { expired, expiring, fresh, remaining, used } = summary!;
  const hasActiveCredits = remaining > 0;

  const list = useMemo<GroupedCreditItemsDict>(
    () =>
      rawList.reduce((credits, { remaining: count, expiresAt, isExpired }) => {
        if (hasActiveCredits && !isExpired) {
          const date = format(
            expiresAt,
            DATE_FORMAT_BASED_ON_LOCALE[language.value],
            { locale: getLocale(language) },
          );
          credits[date] = {
            isExpired,
            count: pathOr(0, [date, 'count'], credits) + count,
          };
        }
        return credits;
      }, {}),
    [hasActiveCredits, rawList, language],
  );

  const segments = useMemo(() => {
    const result = [
      { color: 'transparent', key: 'creditsUsed', value: used },
      { color: '#E5BD00', key: 'creditsExpiring', value: expiring },
      { color: '#4ABA00', key: 'creditsFresh', value: fresh },
    ];

    if (!hasActiveCredits) {
      result.push({ color: '#F08F00', key: 'creditsExpired', value: expired });
    }

    return result;
  }, [expired, expiring, fresh, used, hasActiveCredits]);

  return (
    <Wrapper big={big}>
      <CompanyName>{companyName}</CompanyName>

      <Chart segments={segments} />

      <List data-test="creditsExpiration">
        {keys(list).map(date => (
          <li key={date}>
            {ReactHTMLParser(
              t(list[date].isExpired ? 'expiredHTML' : 'expiringHTML', {
                count: list[date].count,
                date,
              }),
            )}
          </li>
        ))}
      </List>
    </Wrapper>
  );
};
const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});
export default connect(
  mapStateToProps,
  null,
)(withTranslation('CreditsOverview')(CreditsOverview));
