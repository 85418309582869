import styled, { css } from 'styled-components';
import { RadioSizeType, RadioVersionType } from './Radio';

interface LabelPropsType {
  isSelected: boolean;
  size: RadioSizeType;
  version: RadioVersionType;
}

const RADIO_SIZES = {
  small: {
    checkmark: 14,
    inside: 8,
  },
  normal: {
    checkmark: 16,
    inside: 10,
  },
};

const selectedStyle = (size: RadioSizeType) => css`
  &:after {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.basic.colors.atlas};
    border-radius: 50%;
    width: ${RADIO_SIZES[size].inside}px;
    height: ${RADIO_SIZES[size].inside}px;
    left: 5px;
    top: 5px;
  }

  &:before {
    border-color: ${({ theme }) => theme.basic.colors.atlas};
  }
`;

export const Label = styled.label<LabelPropsType>`
  display: block;
  padding-left: 17px;
  position: relative;
  cursor: pointer;
  line-height: 20px;
  color: ${({ theme, version }) =>
    version === 'regular' ? theme.atlas.text.primary : theme.basic.colors.dark};

  ${({ isSelected, size, theme, version }) =>
    isSelected
      ? theme.fonts[version === 'regular' ? 'bold' : version][size]
      : theme.fonts[version][size]}

  &:hover {
    ${({ size }) => selectedStyle(size)}
  }

  &:before {
    position: absolute;
    background: ${({ theme }) => theme.atlas.white};
    border: 2px solid ${({ theme: { atlas } }) => atlas.grey[300]};
    border-radius: 50%;
    content: '';
    display: block;
    width: ${({ size }) => RADIO_SIZES[size].checkmark}px;
    height: ${({ size }) => RADIO_SIZES[size].checkmark}px;
    top: 0;
    left: 0;
  }

  ${({ isSelected, size }) => isSelected && selectedStyle(size)}
`;

export const RadioInput = styled.input.attrs({
  type: 'radio',
})`
  opacity: 0;
`;
