export interface FormsFinishReportSavingActionType {
  type: '@forms/FINISH_REPORT_SAVING';
  payload: { lastSave: Date };
}

export const finishReportSaving = (): FormsFinishReportSavingActionType => {
  const lastSave = new Date();
  return {
    type: '@forms/FINISH_REPORT_SAVING',
    payload: { lastSave },
  };
};
