import React, { useContext } from 'react';
import PageHeader from '../common/PageHeader';
import SettingsForm from './SettingsForm';
import { Content, Wrapper } from 'common/common.styles';
import { Section } from './Settings.styles';
import { SettingsPageContextType } from 'pages/SettingsPage';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { PageContext } from 'pages/Page';
import Loading from 'components/common/Loading';

const Settings = ({ t }: TranslatePropsType) => {
  const {
    data: { companyName, emailables, settings, user },
    actions: { saveSettings, removeLogo },
  } = useContext(PageContext) as SettingsPageContextType;

  const loaded = Object.keys(settings).length;

  return loaded ? (
    <>
      <PageHeader title={t('name') as string} />
      <Wrapper grey>
        <Content>
          <Section>
            <h2>{t('companySettings') as string}</h2>

            <p>{t('companySettingsDesc', { companyName }) as string}</p>

            <h4>{t('notificationEmails') as string}</h4>

            <SettingsForm
              companyName={companyName}
              emailables={emailables}
              saveSettings={saveSettings}
              settings={settings}
              user={user}
              removeLogo={removeLogo}
            />
          </Section>
        </Content>
      </Wrapper>
    </>
  ) : (
    <Loading />
  );
};

export default withTranslation('Settings')(Settings);
