import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FilterHeading = styled.div`
  ${({ theme }) => theme.fonts.semibold.large}
  padding-left: 8px;
`;

export const TagInput = styled.div`
  ${({ theme }) => theme.fonts.semibold.small}
  height: 28px;
  margin-top: 8px;
  border-radius: 28px;
  border: 1px solid #5d5d5d;
  padding-left: 8px;
  width: 100%;
`;

export const Result = styled.div``;

export const ResultNumber = styled.span`
  ${({ theme }) => theme.fonts.semibold.massive}
  line-height: 30px;
`;

export const BigText = styled.span`
  margin-top: -4px;
  ${({ theme }) => theme.fonts.semibold.large}
`;

export const SelectedTags = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
`;
