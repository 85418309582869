import { CompanyGradeEnum } from '../../../generated/models/Company';

export interface CompaniesSetFilterSelectionActionType {
  type: '@companies/SET_FILTER_SELECTION';
  payload: {
    tagName?: string;
    grade?: CompanyGradeEnum;
    hover: boolean;
  };
}

export const setFilterSelection = (
  tagName?: string,
  grade?: CompanyGradeEnum,
  hover?: boolean,
): CompaniesSetFilterSelectionActionType => ({
  type: '@companies/SET_FILTER_SELECTION',
  payload: {
    tagName,
    grade,
    hover: hover || false,
  },
});
