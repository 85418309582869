import Autocomplete from '../../common/Autocomplete';
import React from 'react';
import Tag from '../../common/Tag';
import { CompaniesFilterFunctionsType } from '../Companies';
import { TagType, TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import {
  BigText,
  Container,
  FilterHeading,
  Result,
  ResultNumber,
  SelectedTags,
  TagInput,
} from './TagFilter.styles';

interface TagFilterPropsType extends TranslatePropsType {
  allTags: TagType[];
  result: number;
  tags: TagType[];
  filterFunctions: CompaniesFilterFunctionsType;
}

const TagFilter = ({
  allTags,
  filterFunctions,
  result,
  t,
  tags,
}: TagFilterPropsType) => {
  const title = t('TagFilter.title');
  const bigTextLabel = t(
    `common:${result === 1 ? 'singular' : 'plural'}.company`,
  );

  return (
    <Container>
      <div>
        <FilterHeading>{title}</FilterHeading>

        <TagInput data-test="tagSelector">
          <Autocomplete
            suggestions={allTags.map(({ id, name }: TagType) => ({ id, name }))}
            onAdd={tagName => filterFunctions.add(tagName)}
            placeholder={t('TagFilter.placeholder')}
          />
        </TagInput>

        <SelectedTags>
          {tags.map((tag: TagType) => (
            <Tag
              editable={true}
              key={tag.id}
              tag={tag}
              remove={(item: TagType) => filterFunctions.remove(item.name)}
            />
          ))}
        </SelectedTags>
      </div>

      <Result>
        <ResultNumber data-test="companiesNumberFiltered">
          {result}
        </ResultNumber>

        <br />

        <BigText>{bigTextLabel}</BigText>
      </Result>
    </Container>
  );
};

export default withTranslation(['components', 'common'])(TagFilter);
