/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    EvidenceLockerAssociatedEntitySummary,
    EvidenceLockerAssociatedEntitySummaryFromJSON,
    EvidenceLockerAssociatedEntitySummaryFromJSONTyped,
    EvidenceLockerAssociatedEntitySummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface EvidenceLockerEntity
 */
export interface EvidenceLockerEntity {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof EvidenceLockerEntity
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof EvidenceLockerEntity
     */
    updatedAt: Date;
    /**
     * Summary of the entity that's associated with this evidence entity.
     * @type {EvidenceLockerAssociatedEntitySummary}
     * @memberof EvidenceLockerEntity
     */
    associatedEntityData?: EvidenceLockerAssociatedEntitySummary;
    /**
     * Category of this entity
     * @type {string}
     * @memberof EvidenceLockerEntity
     */
    category: string;
    /**
     * ID of the evidence locker entity
     * @type {string}
     * @memberof EvidenceLockerEntity
     */
    id: string;
    /**
     * Import type of this evidence entity
     * @type {string}
     * @memberof EvidenceLockerEntity
     */
    importType: EvidenceLockerEntityImportTypeEnum;
    /**
     * Domain of the user who imported this entity
     * @type {string}
     * @memberof EvidenceLockerEntity
     */
    importerDomain: string;
    /**
     * Email ID of the user who imported this entity
     * @type {string}
     * @memberof EvidenceLockerEntity
     */
    importerEmail: string;
    /**
     * View permission of this entity
     * @type {string}
     * @memberof EvidenceLockerEntity
     */
    permission: EvidenceLockerEntityPermissionEnum;
    /**
     * Validity start date
     * @type {Date}
     * @memberof EvidenceLockerEntity
     */
    validityEnd: Date;
    /**
     * Validity start date
     * @type {Date}
     * @memberof EvidenceLockerEntity
     */
    validityStart: Date;
}

export function EvidenceLockerEntityFromJSON(json: any): EvidenceLockerEntity {
    return EvidenceLockerEntityFromJSONTyped(json, false);
}

export function EvidenceLockerEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvidenceLockerEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'associatedEntityData': !exists(json, 'associated_entity_data') ? undefined : EvidenceLockerAssociatedEntitySummaryFromJSON(json['associated_entity_data']),
        'category': json['category'],
        'id': json['id'],
        'importType': json['import_type'],
        'importerDomain': json['importer_domain'],
        'importerEmail': json['importer_email'],
        'permission': json['permission'],
        'validityEnd': (new Date(json['validity_end'])),
        'validityStart': (new Date(json['validity_start'])),
    };
}

export function EvidenceLockerEntityToJSON(value?: EvidenceLockerEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'associated_entity_data': EvidenceLockerAssociatedEntitySummaryToJSON(value.associatedEntityData),
        'category': value.category,
        'id': value.id,
        'import_type': value.importType,
        'importer_domain': value.importerDomain,
        'importer_email': value.importerEmail,
        'permission': value.permission,
        'validity_end': (value.validityEnd.toISOString()),
        'validity_start': (value.validityStart.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum EvidenceLockerEntityImportTypeEnum {
    ATLAS = 'ATLAS',
    EXTERNALLINK = 'EXTERNAL_LINK',
    UPLOAD = 'UPLOAD'
}
/**
* @export
* @enum {string}
*/
export enum EvidenceLockerEntityPermissionEnum {
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC'
}


