/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanyTagBase,
    CompanyTagBaseFromJSON,
    CompanyTagBaseFromJSONTyped,
    CompanyTagBaseToJSON,
    TagBase,
    TagBaseFromJSON,
    TagBaseFromJSONTyped,
    TagBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyTagDetail
 */
export interface CompanyTagDetail {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof CompanyTagDetail
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof CompanyTagDetail
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyTagDetail
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyTagDetail
     */
    ownerId: string;
    /**
     * 
     * @type {TagBase}
     * @memberof CompanyTagDetail
     */
    tag: TagBase;
    /**
     * 
     * @type {string}
     * @memberof CompanyTagDetail
     */
    targetCompanyId: string;
    /**
     * 
     * @type {Company}
     * @memberof CompanyTagDetail
     */
    targetCompany: Company;
}

export function CompanyTagDetailFromJSON(json: any): CompanyTagDetail {
    return CompanyTagDetailFromJSONTyped(json, false);
}

export function CompanyTagDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyTagDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'id': json['id'],
        'ownerId': json['owner_id'],
        'tag': TagBaseFromJSON(json['tag']),
        'targetCompanyId': json['target_company_id'],
        'targetCompany': CompanyFromJSON(json['target_company']),
    };
}

export function CompanyTagDetailToJSON(value?: CompanyTagDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'id': value.id,
        'owner_id': value.ownerId,
        'tag': TagBaseToJSON(value.tag),
        'target_company_id': value.targetCompanyId,
        'target_company': CompanyToJSON(value.targetCompany),
    };
}


