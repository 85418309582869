import React, { useState, useContext } from 'react';
import { Assignees } from '.';
import { withTranslation } from 'react-i18next';
import { PageContext, PageBaseType } from 'pages/Page';
import { faUser, faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  FormAssignee,
  PromptInviteDelegationType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import {
  AssignTo,
  AssigneeRow,
  PlusIcon,
  RelativeContainer,
  UserIcon,
  WrapperAlwaysOn,
} from './Delegator.styles';
import { FormPageContextType } from 'pages/FormPage';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
interface FormDelegatorPropsType extends TranslatePropsType {
  assignees: FormAssignee[];
  formId: Uuid;
  onInviteUserClick: (
    e: React.MouseEvent<HTMLElement>,
    prompt: PromptInviteDelegationType,
  ) => void;
}

const FormDelegator = ({
  assignees,
  formId,
  onInviteUserClick,
  t,
}: FormDelegatorPropsType) => {
  const [isDelegatorOpen, setDelegatorOpen] = useState(false);
  const formPageContext = useContext<PageBaseType>(PageContext);
  const {
    actions: { assignFormDelegation, unassignFormDelegation },
    data: {
      currentForm: form,
      currentForm: {
        permissions: { canSendDelegationInvitation },
      },
    },
  } = formPageContext as FormPageContextType;

  const handleAssignClick = (
    event: React.MouseEvent<HTMLElement>,
    userId: Uuid,
  ) => {
    event.stopPropagation();

    if (assignFormDelegation) {
      assignFormDelegation(formId, { type: 'id', payload: userId });
    }
  };

  const handleUnassignClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (unassignFormDelegation) {
      unassignFormDelegation(formId);
    }
  };

  const handleRowClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setDelegatorOpen(!isDelegatorOpen);
  };

  const handleInviteUserClick = (event: React.MouseEvent<HTMLElement>) => {
    onInviteUserClick(event, { form });

    setDelegatorOpen(false);
  };

  return (
    <WrapperAlwaysOn data-cy="form-delegator">
      <RelativeContainer>
        <AssigneeRow
          hasAssignees={false}
          onClick={handleRowClick}
          data-test="assign-to-questionnaire"
        >
          <AssignTo>{t('assignTo') as string}</AssignTo>

          <UserIcon icon={faUser as IconProp} />

          <PlusIcon icon={faPlus as IconProp} />
        </AssigneeRow>

        {isDelegatorOpen && (
          <Assignees
            assign={handleAssignClick}
            assignees={assignees}
            assigneesIds={[]}
            canInvite={canSendDelegationInvitation}
            hasRemove
            invite={handleInviteUserClick}
            setDelegatorOpen={setDelegatorOpen}
            unassign={handleUnassignClick}
          />
        )}
      </RelativeContainer>
    </WrapperAlwaysOn>
  );
};

export default withTranslation('Delegator')(FormDelegator);
