import styled, { css } from 'styled-components';
import {
  Horizontal,
  allChildrenFlexed,
  spacingBetweenChildren,
} from 'common/common.styles';
import { DIALOG_PADDING_PX } from './Report.styles';

export const HeaderCell = styled.div`
  background-color: ${({ theme }) => theme.basic.colors.background};
  color: ${({ theme }) => theme.atlas.text.secondary};
  padding: 12px 10px 7px 10px;
  text-transform: uppercase;
  ${({ theme }) => theme.fonts.bold.mini}

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }
`;

export const Cell = styled.div`
  padding: 16px 10px;
  ${({ theme }) => theme.fonts.regular.xsmall}
  ${spacingBetweenChildren(6)}
`;

export const RightCell = styled(Cell)`
  padding-right: 0;
  flex: 1;
`;

export const RowActions = styled.div`
  padding: 7px 0;
`;

export const IconButton = styled.span<{ disabled: boolean }>`
  display: inline-block;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme }) => theme.atlas.text.secondary};
  padding: 8px;
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.4;
          pointer-events: none;
        `
      : null}
`;

export const FlexedDiv = styled.div`
  flex: 1;
`;

export const Wrapper = styled.div`
  // height: 320px;
  flex: 1;
  overflow-y: auto;
  min-height: 90px;

  // !!! Context aware props... could be moved away...
  margin-left: -${DIALOG_PADDING_PX}px;
  margin-right: -${DIALOG_PADDING_PX}px;
  margin-bottom: 0;
`;

export const Header = styled(Horizontal)`
  position: sticky;
  top: 0;
  border-bottom: 1px solid ${({ theme }) => theme.basic.colors.darkerBorder};
  ${allChildrenFlexed}
`;

export const Body = styled.div`
  color: ${({ theme }) => theme.atlas.text.primary};
  padding: 0 10px;
`;

export const Row = styled(Horizontal)`
  & + & {
    border-top: 1px solid ${({ theme }) => theme.basic.colors.darkerBorder};
  }
  ${allChildrenFlexed}
`;
