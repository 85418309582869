import Explanation from '../../common/Explanation';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import VisualisationHexagon from './VisualisationHexagon';
import { CompanyGradeEnum } from 'generated/models/Company';
import { CompaniesFilterFunctionsType } from '../Companies';
import { HorizontalLine, LegendText } from './Visualisation.styles';
import { OpacitySetter } from 'common/common.styles';
import { TranslatePropsType } from 'common/common.types';
import { X_VALUES } from './Visualisation';
import { withTranslation } from 'react-i18next';
import {
  CompaniesFilterType,
  VisualisationByGradeDataType,
} from 'store/companies/companies.reducer';

export interface RowType extends TranslatePropsType {
  byGrade: VisualisationByGradeDataType;
  filter: CompaniesFilterType;
  filterFunctions: CompaniesFilterFunctionsType;
  legend: string;
  y: number;
}

const Row = ({ byGrade, filter, filterFunctions, legend, t, y }: RowType) => {
  const legendOpacityWhenSelectionActive = (selectedTag: string) =>
    selectedTag === legend ? 1 : 0.2;

  const getLegendOpacity = () =>
    filter.selection.tagName
      ? legendOpacityWhenSelectionActive(filter.selection.tagName)
      : 1;

  return (
    <div>
      <HorizontalLine y={y} />

      <OpacitySetter opacity={getLegendOpacity()}>
        <LegendText y={y}>
          {`${legend} `}
          {legend === 'Unsorted' && (
            <Explanation>
              {ReactHtmlParser(t('CompanyFilterRow.explanation'))}
            </Explanation>
          )}
        </LegendText>
      </OpacitySetter>

      {X_VALUES.map((grade: CompanyGradeEnum) =>
        byGrade[grade] && byGrade[grade].qtyAbs > 0 ? (
          <VisualisationHexagon
            y={y}
            legend={legend}
            byGrade={byGrade}
            filter={filter}
            filterFunctions={filterFunctions}
            grade={grade}
            key={`hex-${legend}-${grade}`}
          />
        ) : null,
      )}
    </div>
  );
};

export default withTranslation()(Row);
