import styled, { css } from 'styled-components';
import colors from 'common/common.colors';

export type ButtonColorType = 'teal' | 'orange' | 'tealInverted';

type ButtonSizeType = 'medium' | 'big' | 'huge';

interface ButtonType {
  size?: ButtonSizeType;
  color?: ButtonColorType;
}

export const Button = styled.button<ButtonType>`
  border: 2px solid ${colors.basic.teal};
  border-radius: 6px;
  cursor: pointer;
  letter-spacing: 0.25px;

  ${({ color = 'teal' }) => css`
    color: ${colors.elements.button[color].color};
    background-color: ${colors.elements.button[color].background};

    &:hover {
      background-color: ${colors.elements.button[color].hover};
      color: ${colors.basic.white};
    }
  `}

  ${({ theme, size }) => {
    switch (size) {
      case 'big':
        return css`
          padding: 15px 30px;
          ${theme.fonts.bold.normal}
        `;
      case 'huge':
        return css`
          padding: 20px 70px;
          ${theme.fonts.bold.large}
        `;
      default:
      case 'medium':
        return css`
          padding: 10px 20px;
          ${theme.fonts.bold.normal}
        `;
    }
  }}

  &:disabled {
    opacity: 0.3;
    cursor: initial;
    background-color: ${({ theme }) => theme.basic.colors.disabled};
    color: ${colors.basic.white};
    border: 0;
  }
`;

export const SubmitWrapper = styled.div`
  text-align: right;
`;

export const NoMoreCredits = styled.h3`
  color: ${({ theme }) => theme.atlas.text.primary};
  line-height: 1.34;
  padding-bottom: 7px;
  max-width: 450px;
  margin: 0 0 0 auto;
  ${({ theme }) => theme.fonts.bold.normal}
`;

export const AddButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.atlas.teal.primary};
  cursor: pointer;
  text-decoration: underline;
  ${({ theme }) => theme.fonts.bold.normal}

  &:hover {
    text-decoration: none;
  }
`;

export const CreditRequirement = styled.div`
  margin-top: 7px;
  ${({ theme }) => theme.fonts.regular.small}
`;
