export default {
  blue: {
    100: '#E8ECEF',
    200: '#CFD9DE',
    300: '#B9C6CE',
    400: '#A2B3BD',
    500: '#8AA0AD',
    600: '#738E9D',
    700: '#5D7B8C',
    800: '#45687C',
    900: '#2D556B',
    a100: '#0F2E3F',
    a200: '#091B25',
    a300: '#03080C',
    a400: '#03080C',
  },
  coral: '#F77C7C',
  grade: {
    atlas: {
      a: '#84BA5F',
      b: '#E5D06E',
      c: '#F0AF54',
      d: '#F16B4D',
      f: '#B43F3F',
    },
    ssc: {
      a: '#41B104',
      b: '#E1B405',
      c: '#EE8404',
      d: '#EF3A1A',
      f: '#AB0002',
    },
  },
  grey: {
    50: '#F5F5F5',
    100: '#E9EAEB',
    200: '#D3D6D7',
    300: '#BEC1C3',
    400: '#A8ADAF',
    500: '#92989B',
    600: '#7C8487',
    700: '#666F73',
    800: '#515B5F',
    900: '#3B464B',
    a100: '#12191B',
  },
  red: {
    600: '#f9c6c6',
    700: '#f8b4b5',
    800: '#f8abab',
    900: '#f7a0a0',
    primary: '#f67071',
  },
  skyblue: '#87C8CC',
  teal: {
    100: '#EEF5F5',
    200: '#DDEBEC',
    300: '#CDE1E3',
    400: '#BBD7D9',
    500: '#ABCCCF',
    600: '#9AC2C6',
    700: '#89B8BD',
    800: '#79AEB3',
    900: '#68A4AA',
    a100: '#487F84',
    a200: '#3A666A',
    a300: '#2C4D51',
    a400: '#1E3537',
    primary: '#579AA0',
  },
  text: {
    primary: '#333333',
    secondary: '#5D5D5D',
  },
  white: '#FFFFFF',
  yellow: {
    100: '#FFF7E7',
    200: '#FEEECF',
    300: '#FEE6B7',
    400: '#FEDD9F',
    500: '#FED587',
    600: '#FDCD70',
    700: '#FDC458',
    800: '#FDBC40',
    900: '#FCB328',
    a100: '#E0980E',
    a200: '#C6870C',
    a300: '#AD760A',
    a400: '#936509',
    primary: '#FCAB10',
  },
};
