import styled from 'styled-components';

export const SwitcherWrapper = styled.div`
  padding-bottom: 20px;
`;

export const Buttons = styled.div`
  > button {
    display: inline-block;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &:only-child {
      border-radius: 4px 4px 4px 4px;
    }
  }
`;

export const Heading = styled.div`
  ${({ theme }) => theme.fonts.light.large}
`;
