import { StatusFlagType, FlashType } from '../../../common/common.types';
import uuid from 'utils/uuid';

export interface FlashActionType {
  type: '@flashes/FLASH';
  payload: FlashType;
}

export const flash = (
  message: string = '',
  status: StatusFlagType = 'success',
): FlashActionType => ({
  type: '@flashes/FLASH',
  payload: {
    id: uuid(),
    status,
    message,
  },
});
