/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    QuestionConditionStructured,
    QuestionConditionStructuredFromJSON,
    QuestionConditionStructuredFromJSONTyped,
    QuestionConditionStructuredToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormEditEvent
 */
export interface FormEditEvent {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof FormEditEvent
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof FormEditEvent
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof FormEditEvent
     */
    action: number;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    actionCode: FormEditEventActionCodeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FormEditEvent
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormEditEvent
     */
    before?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    code?: string;
    /**
     * 
     * @type {object}
     * @memberof FormEditEvent
     */
    definition?: object;
    /**
     * 
     * @type {number}
     * @memberof FormEditEvent
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof FormEditEvent
     */
    isRequired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    movedCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    newName?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    oldName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormEditEvent
     */
    options?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    question?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    questionUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    responseType?: FormEditEventResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    responseUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    reviewInstructions?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormEditEvent
     */
    sscIssues?: Array<string>;
    /**
     * 
     * @type {QuestionConditionStructured}
     * @memberof FormEditEvent
     */
    structured?: QuestionConditionStructured;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    targetCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    targetQuestion?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    text?: string;
}

export function FormEditEventFromJSON(json: any): FormEditEvent {
    return FormEditEventFromJSONTyped(json, false);
}

export function FormEditEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormEditEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'action': json['action'],
        'actionCode': json['action_code'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'before': !exists(json, 'before') ? undefined : json['before'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'definition': !exists(json, 'definition') ? undefined : json['definition'],
        'id': json['id'],
        'isRequired': !exists(json, 'is_required') ? undefined : json['is_required'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'movedCategory': !exists(json, 'moved_category') ? undefined : json['moved_category'],
        'newName': !exists(json, 'new_name') ? undefined : json['new_name'],
        'oldName': !exists(json, 'old_name') ? undefined : json['old_name'],
        'options': !exists(json, 'options') ? undefined : json['options'],
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'question': !exists(json, 'question') ? undefined : json['question'],
        'questionUuid': !exists(json, 'question_uuid') ? undefined : json['question_uuid'],
        'responseType': !exists(json, 'response_type') ? undefined : json['response_type'],
        'responseUuid': !exists(json, 'response_uuid') ? undefined : json['response_uuid'],
        'reviewInstructions': !exists(json, 'review_instructions') ? undefined : json['review_instructions'],
        'sscIssues': !exists(json, 'ssc_issues') ? undefined : json['ssc_issues'],
        'structured': !exists(json, 'structured') ? undefined : QuestionConditionStructuredFromJSON(json['structured']),
        'targetCategory': !exists(json, 'target_category') ? undefined : json['target_category'],
        'targetQuestion': !exists(json, 'target_question') ? undefined : json['target_question'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function FormEditEventToJSON(value?: FormEditEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'action': value.action,
        'action_code': value.actionCode,
        'active': value.active,
        'before': value.before,
        'category': value.category,
        'code': value.code,
        'definition': value.definition,
        'id': value.id,
        'is_required': value.isRequired,
        'label': value.label,
        'moved_category': value.movedCategory,
        'new_name': value.newName,
        'old_name': value.oldName,
        'options': value.options,
        'placeholder': value.placeholder,
        'question': value.question,
        'question_uuid': value.questionUuid,
        'response_type': value.responseType,
        'response_uuid': value.responseUuid,
        'review_instructions': value.reviewInstructions,
        'ssc_issues': value.sscIssues,
        'structured': QuestionConditionStructuredToJSON(value.structured),
        'target_category': value.targetCategory,
        'target_question': value.targetQuestion,
        'text': value.text,
    };
}

/**
* @export
* @enum {string}
*/
export enum FormEditEventActionCodeEnum {
    ADDRESPONSE = 'ADD_RESPONSE',
    UPDATERESPONSE = 'UPDATE_RESPONSE',
    DELETERESPONSE = 'DELETE_RESPONSE',
    ADDQUESTION = 'ADD_QUESTION',
    UPDATEQUESTION = 'UPDATE_QUESTION',
    MOVEQUESTION = 'MOVE_QUESTION',
    DELETEQUESTION = 'DELETE_QUESTION',
    UPDATECATEGORY = 'UPDATE_CATEGORY',
    MOVECATEGORY = 'MOVE_CATEGORY',
    DELETECATEGORY = 'DELETE_CATEGORY',
    UPDATECONDITIONTEXT = 'UPDATE_CONDITION_TEXT',
    UPDATECONDITIONSTRUCTURED = 'UPDATE_CONDITION_STRUCTURED'
}
/**
* @export
* @enum {string}
*/
export enum FormEditEventResponseTypeEnum {
    EVIDENCE = 'EVIDENCE',
    MULTISELECT = 'MULTI_SELECT',
    NORESPONSE = 'NO_RESPONSE',
    NUMBER = 'NUMBER',
    PERCENTAGE = 'PERCENTAGE',
    SINGLESELECT = 'SINGLE_SELECT',
    TEXT = 'TEXT'
}


