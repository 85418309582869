import {
  RequestDirectionType,
  RequestType,
  RequestSimpleType,
} from '../../../common/common.types';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { assoc, pathOr } from 'ramda';
import { RequestModifyListCallParamsType } from './archiveRequest';
import updateRequestsStateChange from 'utils/updateRequestsStateChange';

export type RequestMarkReadFunctionType = (params: {
  requests: RequestSimpleType[];
  read: boolean;
}) => void;

export interface RequestMarkReadParamsType {
  requests: RequestType[];
  direction: RequestDirectionType;
  read: boolean;
}

export type RequestMarkReadActionType = OptimisticActionType<
  '@requests/API/MARK_READ',
  ApiCallActionPayloadType<RequestModifyListCallParamsType>
>;

export const markRead = (
  params?: RequestMarkReadParamsType,
): RequestMarkReadActionType => ({
  type: '@requests/API/MARK_READ',
  payload: {
    callParams: state => ({
      request_ids: params
        ? params.requests.map(request => request.id)
        : [pathOr(-1, ['forms', 'currentForm', 'request', 'id'], state)],
      read: params ? params.read : true,
    }),
    expectedStateChange: (() => {
      if (params) {
        const requestIds = new Set(params.requests.map(r => r.id));
        const directionPath = `requests.${params.direction}`;
        const remapRequest = (request: RequestType) =>
          requestIds.has(request.id)
            ? assoc('isRead', params.read, request)
            : request;

        return updateRequestsStateChange(
          directionPath,
          params.requests,
          remapRequest,
        );
      }
      return {};
    })(),
    endpointPath: ['requests', 'modifyList'],
    selector: null,
  },
});
