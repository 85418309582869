import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { RequestFinding } from '../../../generated/models/RequestFinding';
import { RequestFindingType, Uuid } from '../../../common/common.types';
import { reject } from 'ramda';
import { put } from 'redux-saga/effects';
import { finishReportSaving } from './finishReportSaving';

export interface FormsDeleteFindingCallParamsType {
  requestId: Uuid;
  findingId: Uuid;
}

export type FormsDeleteFindingFunctionType = (
  params: FormsDeleteFindingCallParamsType,
) => void;

export type FormsDeleteFindingActionType = OptimisticActionType<
  '@forms/API/DELETE_FINDING',
  ApiCallActionPayloadType<
    FormsDeleteFindingCallParamsType,
    RequestFinding,
    never,
    FormsStateType
  >
>;

export const deleteFinding = (
  params: FormsDeleteFindingCallParamsType,
): FormsDeleteFindingActionType => ({
  type: '@forms/API/DELETE_FINDING',
  payload: {
    callParams: params,
    endpointPath: ['requests', 'findings', 'delete'],
    expectedStateChange: {
      'forms.currentForm.request.findings': reject(
        ({ id }: RequestFindingType) => id === params.findingId,
      ),
    },
    selector: null,
    postActions: [() => put(finishReportSaving())],
  },
});
