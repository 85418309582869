const {
  REACT_APP_GOOGLE_ANALYTICS_KEY,
  REACT_APP_BACKEND_URL,
  REACT_APP_BACKEND_URL_ENV,
  REACT_APP_BACKEND_URL_FOR_TESTING,
  REACT_APP_ENV,
  REACT_APP_PLATFORM_URL,
  REACT_APP_SENTRY_DSN,
  REACT_APP_STRIPE_KEY,
  REACT_APP_HEAP_APP_ID,
} = process.env;

const defaultPlatformUrl = 'http://localhost:3017';
const defaultPlatformAPIUrl = 'http://localhost:8080';

// ---------------------------
// Effective variable choosing
// ---------------------------

const isCypress = window.Cypress;

const hostname = isCypress
  ? REACT_APP_BACKEND_URL_FOR_TESTING || ''
  : REACT_APP_BACKEND_URL_ENV || REACT_APP_BACKEND_URL || defaultPlatformAPIUrl;
// if nothing is defined, use platform-api as the endpoint through the proxy

// ---------------------------------------
// Warnings for missing required variables
// ---------------------------------------

if (!REACT_APP_PLATFORM_URL) {
  console.warn(
    `REACT_APP_PLATFORM_URL environment variable is not set, using ${defaultPlatformUrl}.`,
  );
}

console.warn(`Using ${hostname} as backend.`);

// -------------------------------------------

interface ConfigType {
  env: Env;
  googleAnalyticsKey: string;
  hostname: string;
  platformUrl: string;
  sentryDsn: string | undefined;
  stripeKey: string | undefined;
  url: string | undefined;
  heapKey: string | undefined;
}

export enum Env {
  PROD = 'prod',
  DEMO = 'demo',
  DEV = 'dev',
  LOCAL = 'local',
}

const config: ConfigType = {
  googleAnalyticsKey: REACT_APP_GOOGLE_ANALYTICS_KEY as string,
  env: REACT_APP_ENV as Env,
  hostname,
  platformUrl: REACT_APP_PLATFORM_URL || defaultPlatformUrl,
  sentryDsn: REACT_APP_SENTRY_DSN,
  stripeKey: REACT_APP_STRIPE_KEY,
  url: hostname,
  heapKey: REACT_APP_HEAP_APP_ID,
};

export default config;
