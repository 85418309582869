import styled, { css } from 'styled-components';
import hex2rgba from 'utils/hex2rgba';

export const WIDTH = 300;

export enum LabelOrientation {
  RIGHT,
  LEFT,
}

export const Wrapper = styled.div`
  width: ${WIDTH}px;
  margin: 0 auto;
  position: relative;
  overflow: visible;
`;

interface LabelProps {
  background: string;
  labelOrientation: LabelOrientation;
  left: number;
  top: number;
}

export const Donut = styled.circle`
  stroke: ${({ theme }) => theme.atlas.grey[200]};
`;

export const Label = styled.div<LabelProps>`
  background: ${({ theme }) => hex2rgba(theme.basic.colors.black, 0.7)};
  border-radius: 4px;
  height: 22px;
  line-height: 22px;
  position: absolute;
  padding: 0 5px 0 18px;
  color: ${({ theme }) => theme.atlas.white};
  font-size: ${({ theme }) => theme.basic.fontSizes.micro};
  z-index: 5;
  top: ${({ top }) => top}px;
  ${({ left, labelOrientation }) =>
    labelOrientation === LabelOrientation.RIGHT
      ? css`
          left: ${left}px;
        `
      : css`
          right: calc(100% - ${left}px);
        `};

  &:before {
    background: ${({ background, theme }) =>
      background === 'transparent' ? theme.atlas.grey[200] : background};
    border-radius: 50%;
    content: '';
    height: 8px;
    width: 8px;
    position: absolute;
    z-index: 6;
    left: 5px;
    top: 7px;
  }
`;
