import styled from 'styled-components/macro';
import { Flex } from 'common/common.styles';

export const Controls = styled.div`
  display: flex;
  padding-right: 20px;

  > * + * {
    margin-left: 10px;
  }

  > *:last-child {
    margin-left: 26px;
  }
`;

export const TitleWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;

  p {
    margin-top: 5px;
  }
`;

export const BackButtonWrapper = styled.div`
  position: absolute;
  top: -15px;
`;

export const StartFromScratchWrapper = styled.div`
  margin-right: 70px;
`;
