/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ScheduledRequestRecurrenceTypeEnum } from './';
import { exists, mapValues } from '../runtime';

import moment from 'moment';
/**
 *
 * @export
 * @interface ScheduledRequestInternalCreate
 */
export interface ScheduledRequestInternalCreate {
  /**
   *
   * @type {Date}
   * @memberof ScheduledRequestInternalCreate
   */
  dueDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ScheduledRequestInternalCreate
   */
  emailBody?: string;
  /**
   *
   * @type {boolean}
   * @memberof ScheduledRequestInternalCreate
   */
  notify?: boolean;
  /**
   *
   * @type {number}
   * @memberof ScheduledRequestInternalCreate
   */
  occurrences?: number;
  /**
   *
   * @type {string}
   * @memberof ScheduledRequestInternalCreate
   */
  recurrenceType?: ScheduledRequestRecurrenceTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ScheduledRequestInternalCreate
   */
  recurrenceUnits?: number;
  /**
   *
   * @type {number}
   * @memberof ScheduledRequestInternalCreate
   */
  reminderDays?: number;
  /**
   *
   * @type {Date}
   * @memberof ScheduledRequestInternalCreate
   */
  sendDate: Date;
  /**
   * Source team UUID of the actual sender. The source_team must belong to the same
   * company of the sender.
   * @type {string}
   * @memberof ScheduledRequestInternalCreate
   */
  sourceTeam: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ScheduledRequestInternalCreate
   */
  standardIds: Array<string>;
  /**
   * List of recepient teams UUID of the standards. All recepient teams must belong to the
   * same company of the sender.
   * @type {Array<string>}
   * @memberof ScheduledRequestInternalCreate
   */
  targetTeams: Array<string>;
}

export function ScheduledRequestInternalCreateFromJSON(
  json: any,
): ScheduledRequestInternalCreate {
  return ScheduledRequestInternalCreateFromJSONTyped(json, false);
}

export function ScheduledRequestInternalCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ScheduledRequestInternalCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    dueDate: !exists(json, 'due_date') ? undefined : new Date(json['due_date']),
    emailBody: !exists(json, 'email_body') ? undefined : json['email_body'],
    notify: !exists(json, 'notify') ? undefined : json['notify'],
    occurrences: !exists(json, 'occurrences') ? undefined : json['occurrences'],
    recurrenceType: !exists(json, 'recurrence_type')
      ? undefined
      : json['recurrence_type'],
    recurrenceUnits: !exists(json, 'recurrence_units')
      ? undefined
      : json['recurrence_units'],
    reminderDays: !exists(json, 'reminder_days')
      ? undefined
      : json['reminder_days'],
    sendDate: new Date(json['send_date']),
    sourceTeam: json['source_team'],
    standardIds: json['standard_ids'],
    targetTeams: json['target_teams'],
  };
}

export function ScheduledRequestInternalCreateToJSON(
  value?: ScheduledRequestInternalCreate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    due_date:
      value.dueDate === undefined ? undefined : value.dueDate.toISOString(),
    email_body: value.emailBody,
    notify: value.notify,
    occurrences: value.occurrences,
    recurrence_type: value.recurrenceType,
    recurrence_units: value.recurrenceUnits,
    reminder_days: value.reminderDays,
    send_date: moment(value.sendDate).format(),
    source_team: value.sourceTeam,
    standard_ids: value.standardIds,
    target_teams: value.targetTeams,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ScheduledRequestInternalCreateRecurrenceTypeEnum {
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  YEARLY = 'YEARLY',
}
