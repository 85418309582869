import hex2rgba from 'utils/hex2rgba';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const Wrapper = styled.div`
  ${({ theme }) => theme.fonts.semibold.small}
  background-color: ${({ theme }) => theme.basic.colors.light};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => hex2rgba(theme.basic.colors.dark, 0.3)};
  position: absolute;
  z-index: 50;
  right: -120px;
  top: 30px;
  width: 250px;
  max-height: 35vh;
  overflow-y: auto;
  padding: 5px 0 3px;

  svg {
    margin-right: 5px;
  }
`;

export const AssignableList = styled.div`
  max-height: 35vh;
  overflow-y: auto;
`;

export const Actions = styled.div`
  background-color: ${({ theme }) => theme.basic.colors.light};
  position: sticky;
  bottom: -3px;
  padding-bottom: 3px;
  margin-bottom: -3px;
`;

export const Assignable = styled.div<{ isInactive?: boolean }>`
  cursor: pointer;
  padding: 0 30px 0 10px;
  display: flex;
  height: 34px;
  align-items: center;
  position: relative;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  color: ${({
    theme: {
      atlas: { grey },
    },
    isInactive,
  }) => (isInactive ? grey[500] : grey.a100)};

  &:hover {
    background-color: ${({ theme }) => theme.basic.colors.headingHover};
  }
`;

export const Name = styled.div`
  width: 188px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AssignableState = styled.div`
  width: 20px;

  svg {
    margin: 0;
  }
`;

export const ActionRow = styled(Assignable)`
  border-top: 1px solid ${({ theme }) => hex2rgba(theme.basic.colors.dark, 0.3)};
  padding-left: 30px;
`;

const faCheckProp = faCheck as IconProp;
export const AssignedIcon = styled(FontAwesomeIcon).attrs({
  icon: faCheckProp,
})`
  color: ${({ theme }) => theme.atlas.teal.primary};
`;

const faExclamationTriangleProp = faExclamationTriangle as IconProp;
export const InactiveIcon = styled(FontAwesomeIcon).attrs({
  icon: faExclamationTriangleProp,
})`
  color: ${({ theme }) => theme.atlas.grey[500]};
`;

export const InactiveWrapper = styled.div`
  position: absolute;
  right: 5px;
`;
