import { RequestDirectionType } from '../../../common/common.types';
import { SortType } from 'utils/filters/requests.sorters';

export type RequestsSetSortFunctionType = (sortBy: SortType) => void;

export interface RequestsSetSortActionType {
  type: '@requests/SET_SORT';
  payload: {
    direction: RequestDirectionType;
    sortBy: SortType;
  };
}

export const setSort = (
  sortBy: SortType,
  direction: RequestDirectionType,
): RequestsSetSortActionType => ({
  type: '@requests/SET_SORT',
  payload: {
    direction,
    sortBy,
  },
});
