import styled from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => theme.fonts.light.large}
`;

export const content = {
  maxWidth: '560px',
  borderRadius: '10px',
  border: '5px solid rgba(0,0,0, 0.15)',
};
