import { css } from 'styled-components';
import colors from 'common/common.colors';

const {
  elements: { requests },
} = colors;

export const getStatusColor = (color?: string) => {
  switch (color) {
    case 'yellow':
      return requests.created.borderColor;
    case 'seaGreen':
      return requests.inProgress.borderColor;
    case 'blue':
      return requests.reviewReady.borderColor;
    case 'green':
      return requests.closed.borderColor;
    case 'gray':
    default:
      return '#aaaaaa';
  }
};

export const getStatusColorStyle = (color?: string) => {
  switch (color) {
    case 'white':
      return css`
        background-color: #ffffff;
        color: #000;
      `;

    case 'gray':
    default:
      return {
        backgroundColor: getStatusColor(color),
      };
  }
};
