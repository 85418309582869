import styled from 'styled-components';

type WrapperType = {
  align?: 'right' | 'left' | 'center';
};

export const Wrapper = styled.div<WrapperType>`
  text-align: ${({ align }) => (align ? align : 'left')};
`;

export const Sub = styled.div`
  ${({ theme }) => theme.basic.fontWeights.regular};
`;

export const SmallSub = styled.div`
  ${({ theme }) => theme.basic.fontWeights.regular};
`;

export const Strong = styled.strong`
  position: relative;
  display: block;
`;
