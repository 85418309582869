import React, { useState, useEffect, useRef } from 'react';
import {
  Action,
  ActionWrapper,
  Container,
  Control,
  ControlWrapper,
  ClearIcon,
  TextField,
  Suffix,
  RightFootNote,
} from './Input.styles';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisualState } from '../Textarea/Textarea.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type InputVariantType = 'basic' | 'filled';

interface InputProps {
  charCountSameLine?: boolean;
  className?: string;
  'data-cy'?: string;
  height?: number;
  isClearable?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isReadOnlySelectable?: boolean;
  maxLength?: number;
  onClick?: (event: React.MouseEvent) => void;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  placeholder?: string;
  size?: number;
  suffix?: string;
  value: string;
  variant?: InputVariantType;
  visualState?: VisualState;
}

const Input = ({
  charCountSameLine = false,
  className,
  'data-cy': dataCy,
  height,
  isClearable = false,
  isDisabled = false,
  isReadOnly = false,
  isReadOnlySelectable = false,
  maxLength = 0,
  onBlur,
  onChange,
  onClick,
  placeholder,
  size,
  suffix,
  value,
  variant = 'basic',
  visualState = 'normal',
}: InputProps) => {
  const [currentValue, setValue] = useState(value);
  const showActions = isClearable || suffix;
  const showClear = isClearable && !isDisabled && currentValue;
  const showCharCount = maxLength > 0;

  if (isReadOnlySelectable) {
    isReadOnly = true;
  }

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const change = target.value;
    if (
      maxLength > 0 &&
      change.length > maxLength &&
      /* we want to allow shortening of already too long string */
      currentValue.length < change.length
    ) {
      return;
    }

    setValue(change);

    if (onChange) {
      onChange(change);
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur(currentValue);
    }
  };

  const handleClear = () => {
    const newValue = '';
    setValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
    if (onBlur) {
      onBlur(newValue);
    }
  };

  const linkInputRef = useRef<HTMLInputElement>(null);

  const handleOnMouseDown = () => {
    if (linkInputRef.current) {
      linkInputRef.current.select();
    }
  };

  const handleOnMouseUp = () => {
    if (linkInputRef.current) {
      /**
       * Necessary to call .setSelectionRange(0, 0)
       * as calling .select() only causes
       * already selected text to unselect
       */
      linkInputRef.current.setSelectionRange(0, 0);
      linkInputRef.current.select();
    }
  };

  const faTimesProp = faTimes as IconProp;
  return (
    <Container className={className} onClick={onClick} data-cy={dataCy}>
      <ControlWrapper charCountSameLine={charCountSameLine}>
        <Control
          charCountSameLine={charCountSameLine}
          height={height}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          isReadOnlySelectable={isReadOnlySelectable}
          variant={variant}
          visualState={visualState}
        >
          <TextField
            ref={linkInputRef}
            disabled={isDisabled}
            placeholder={placeholder}
            value={currentValue}
            onBlur={handleBlur}
            onChange={handleChange}
            readOnly={isReadOnly}
            size={size}
            onMouseDown={isReadOnlySelectable ? handleOnMouseDown : undefined}
            onMouseUp={isReadOnlySelectable ? handleOnMouseUp : undefined}
          />
          {showActions && (
            <ActionWrapper>
              {showClear && (
                <Action onClick={handleClear}>
                  <ClearIcon>
                    <FontAwesomeIcon icon={faTimesProp} />
                  </ClearIcon>
                </Action>
              )}
              {suffix && <Suffix>{suffix}</Suffix>}
            </ActionWrapper>
          )}
          {showCharCount && charCountSameLine === false && (
            <RightFootNote showOnSameLine={charCountSameLine}>
              {currentValue.length}/{maxLength}
            </RightFootNote>
          )}
        </Control>
        {showCharCount && charCountSameLine === true && (
          <RightFootNote showOnSameLine={charCountSameLine}>
            {currentValue.length}/{maxLength}
          </RightFootNote>
        )}
      </ControlWrapper>
    </Container>
  );
};

export default Input;
