/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestFinding,
    RequestFindingFromJSON,
    RequestFindingFromJSONTyped,
    RequestFindingToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface RequestFindingsListResponse
 */
export interface RequestFindingsListResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof RequestFindingsListResponse
     */
    msg: string;
    /**
     * 
     * @type {Array<RequestFinding>}
     * @memberof RequestFindingsListResponse
     */
    entries: Array<RequestFinding>;
}

export function RequestFindingsListResponseFromJSON(json: any): RequestFindingsListResponse {
    return RequestFindingsListResponseFromJSONTyped(json, false);
}

export function RequestFindingsListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFindingsListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entries': ((json['entries'] as Array<any>).map(RequestFindingFromJSON)),
    };
}

export function RequestFindingsListResponseToJSON(value?: RequestFindingsListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entries': ((value.entries as Array<any>).map(RequestFindingToJSON)),
    };
}


