/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";

/**
 * 
 * @export
 * @interface AutocompleteExecution
 */
export interface AutocompleteExecution {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof AutocompleteExecution
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof AutocompleteExecution
     */
    updated_at: Date;
    /**
     * Owner's company ID
     * @type {string}
     * @memberof AutocompleteExecution
     */
    company_id: string;
    /**
     * Associated form
     * @type {string}
     * @memberof AutocompleteExecution
     */
    form_id: string;
    /**
     * 
     * @type {string}
     * @memberof AutocompleteExecution
     */
    id: string;
    /**
     * List of questions that has been autofilled.
     * @type {Array<string>}
     * @memberof AutocompleteExecution
     */
    questions_autofilled: Array<string>;
    /**
     * Total number of questions processed.
     * @type {number}
     * @memberof AutocompleteExecution
     */
    questions_processed: number;
    /**
     * List of questions that has some suggestions.
     * @type {Array<string>}
     * @memberof AutocompleteExecution
     */
    questions_suggested: Array<string>;
    /**
     * Total number of questions to be processed.
     * @type {number}
     * @memberof AutocompleteExecution
     */
    questions_total: number;
    /**
     * Status for autocomplete execution
     * @type {string}
     * @memberof AutocompleteExecution
     */
    status: AutocompleteExecutionStatusEnum;
    /**
     * User who has triggered the autocomplete
     * @type {string}
     * @memberof AutocompleteExecution
     */
    user_id: string;
}




/**
* @export
* @enum {string}
*/
export enum AutocompleteExecutionStatusEnum {
    FINISHEDFAIL = 'FINISHED_FAIL',
    FINISHEDSUCCESS = 'FINISHED_SUCCESS',
    INPROGRESS = 'IN_PROGRESS',
    INQUEUE = 'IN_QUEUE'
}


