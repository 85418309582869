/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionMetadata,
    QuestionMetadataFromJSON,
    QuestionMetadataFromJSONTyped,
    QuestionMetadataToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionMetadatasListResponse
 */
export interface QuestionMetadatasListResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof QuestionMetadatasListResponse
     */
    msg: string;
    /**
     * 
     * @type {Array<QuestionMetadata>}
     * @memberof QuestionMetadatasListResponse
     */
    entries: Array<QuestionMetadata>;
}

export function QuestionMetadatasListResponseFromJSON(json: any): QuestionMetadatasListResponse {
    return QuestionMetadatasListResponseFromJSONTyped(json, false);
}

export function QuestionMetadatasListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionMetadatasListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entries': ((json['entries'] as Array<any>).map(QuestionMetadataFromJSON)),
    };
}

export function QuestionMetadatasListResponseToJSON(value?: QuestionMetadatasListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entries': ((value.entries as Array<any>).map(QuestionMetadataToJSON)),
    };
}


