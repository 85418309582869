import styled from 'styled-components';
import { Open } from '../../Form/Category/CategoryHeader.styles';

export const Wrapper = styled.div`
  ${({ theme }) => theme.basic.fontWeights.bold};
  background-color: ${({ theme }) => theme.basic.colors.lightBorder};
  padding: 20px;
  text-transform: uppercase;
  margin-top: 25px;
`;

export const Chevron = styled(Open)``;
