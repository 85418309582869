import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import Dropdown from '../Dropdown';
import {
  AddedFindingColumn,
  AddedFindingTextArea,
  AddedFindingWrapper,
  ButtonBar,
  CenterTextButton,
  CheckboxWrapper,
  DownloadWrapper,
  DownloadButtonsWrapper,
  Label,
  Separator,
  StyledDownloadButton,
  Textarea,
  Wrapper,
} from './Report.styles';
import {
  RequestFindingRemediationPlanEnum,
  RequestFindingRiskLevelEnum,
} from 'generated/new/models/RequestFinding';
import {
  RequestFindingType,
  TranslatePropsType,
  Uuid,
  ValueLabelType,
} from 'common/common.types';
import { CompactSelect as Select } from '../../../common/Select';
import FindingsTable from './FindingsTable';
import {
  FormsGetFindingsFunctionType,
  FormsAddFindingFunctionType,
  FormsDeleteFindingFunctionType,
  FormsExportFindingsToPdfFunctionType,
} from 'store/forms/actions';
import { trackEvent } from 'utils/analytics';
import { ExportFindingsToCsvPathFunctionType } from 'store/requests/requests.selectors';
import Checkbox from '../../../common/Checkbox';
import AutosavedInfo from '../../../common/AutosavedInfo';
import { ReportSavingType } from 'store/forms/forms.reducer';
import { isReportSaving } from 'store/forms/forms.selectors';

export interface ReportPropsType extends TranslatePropsType {
  addFinding: FormsAddFindingFunctionType;
  areFindingsExporting?: boolean;
  companyName: string;
  deleteFinding: FormsDeleteFindingFunctionType;
  exportFindingsToCsvPath: ExportFindingsToCsvPathFunctionType;
  exportFindingsToPdf: FormsExportFindingsToPdfFunctionType;
  findings?: RequestFindingType[];
  formId: Uuid;
  getFindings: FormsGetFindingsFunctionType;
  handleClose: () => void;
  reportSaving: ReportSavingType;
  requestId: Uuid;
  riskNote: string;
  standardName: string;
  titleKey: string;
  updateRiskNote: (riskNote: string) => void;
}

const getRiskLevelSelectItems = (t: TFunction) => {
  const { CRITICAL, HIGH, MEDIUM, LOW, NONE } = RequestFindingRiskLevelEnum;

  return [null, CRITICAL, HIGH, MEDIUM, LOW, NONE].map(code => ({
    value: code,
    label: code
      ? (t('riskLevel', { context: code }) as string)
      : (t('selectRiskLevel') as string),
  }));
};

const getRemediationPlanSelectItems = (t: TFunction) => {
  const { REMEDIATE, ACCEPT, COMPLETE } = RequestFindingRemediationPlanEnum;
  return [null, REMEDIATE, ACCEPT, COMPLETE].map(code => ({
    value: code,
    label: code
      ? (t('remediationPlan', { context: code }) as string)
      : (t('selectPlan') as string),
  }));
};

const Report = ({
  addFinding,
  areFindingsExporting,
  companyName,
  deleteFinding,
  exportFindingsToCsvPath,
  exportFindingsToPdf,
  findings,
  formId,
  getFindings,
  handleClose,
  reportSaving,
  reportSaving: { lastSave },
  requestId,
  riskNote,
  standardName,
  t,
  titleKey,
  updateRiskNote,
}: ReportPropsType) => {
  const handleNoteChange = useCallback(debounce(updateRiskNote, 2000), [
    riskNote,
  ]);

  useEffect(() => {
    getFindings(requestId);
  }, [getFindings, requestId]);

  const [riskLevel, setRiskLevel] = useState<ValueLabelType<
    RequestFindingRiskLevelEnum
  > | null>(null);
  const [riskLevelNote, setRiskLevelNote] = useState<string>('');

  const [remediationPlan, setRemediationPlan] = useState<ValueLabelType<
    RequestFindingRemediationPlanEnum
  > | null>(null);
  const [remediationPlanNote, setRemediationPlanNote] = useState<string>('');

  const handleAddFinding = useCallback(() => {
    addFinding({
      requestId,
      payload: {
        remediationPlan: remediationPlan ? remediationPlan.value : undefined,
        remediationPlanNote,
        riskLevel: riskLevel ? riskLevel.value : undefined,
        riskLevelNote,
      },
    });
    setRiskLevel(null);
    setRiskLevelNote('');
    setRemediationPlan(null);
    setRemediationPlanNote('');
  }, [
    addFinding,
    requestId,
    remediationPlan,
    remediationPlanNote,
    riskLevel,
    riskLevelNote,
  ]);

  const handleRiskLevelOptionChange = useCallback(
    (item: ValueLabelType<RequestFindingRiskLevelEnum | null> | null) => {
      setRiskLevel(
        item !== null && item.value === null
          ? null
          : (item as ValueLabelType<RequestFindingRiskLevelEnum> | null),
      );
    },
    [setRiskLevel],
  );

  const handleRemediationPlanOptionChange = useCallback(
    (item: ValueLabelType<RequestFindingRemediationPlanEnum | null> | null) => {
      setRemediationPlan(
        item !== null && item.value === null
          ? null
          : (item as ValueLabelType<RequestFindingRemediationPlanEnum> | null),
      );
    },
    [setRemediationPlan],
  );

  const handleDeleteFinding = useCallback(
    (findingId: Uuid) =>
      deleteFinding({
        requestId,
        findingId,
      }),
    [deleteFinding, requestId],
  );

  const [downloadWithNote, setDownloadWithNote] = useState<boolean>(true);

  const handlePdfExport = () => {
    location.href =
      'https://support.securityscorecard.com/hc/en-us/articles/28676218272027-Generating-ATLAS-Report-is-unavailable';
  };

  const handleCsvExport = useCallback(
    () => trackEvent('Form', 'Finding - Export CSV', `Form: ${formId}`),
    [formId],
  );

  const toggleDownloadWithNote = () => {
    setDownloadWithNote(prevState => !prevState);
  };

  const isFindingValid =
    riskLevel !== null ||
    riskLevelNote ||
    remediationPlan !== null ||
    remediationPlanNote;

  const isSaving = isReportSaving(reportSaving);

  const pdfDownloadButton = (
    <StyledDownloadButton
      disabled={areFindingsExporting}
      onClick={areFindingsExporting ? undefined : handlePdfExport}
    >
      {
        t(
          areFindingsExporting ? 'exportingDocument' : 'downloadPdfReport',
        ) as string
      }
    </StyledDownloadButton>
  );

  const csvDownloadButton = (
    <StyledDownloadButton
      href={exportFindingsToCsvPath(requestId, downloadWithNote)}
      download
      onClick={handleCsvExport}
    >
      {t('downloadCSVReport') as string}
    </StyledDownloadButton>
  );

  return (
    <Dropdown
      handleClose={handleClose}
      titleKey={titleKey}
      headerRight={
        // Don't show "autosaved" message when loading...
        findings ? (
          <AutosavedInfo isSaving={isSaving} lastUpdate={lastSave} />
        ) : null
      }
    >
      <Wrapper data-cy="form-report">
        {findings ? (
          <>
            <Label>{t('notes') as string}</Label>
            <Textarea value={riskNote} onChange={handleNoteChange} />
            <Separator />
            <Label>{t('findings') as string}</Label>
            <AddedFindingWrapper>
              <AddedFindingColumn data-cy="formReportRiskColumn">
                <Select
                  onChange={handleRiskLevelOptionChange}
                  options={getRiskLevelSelectItems(t)}
                  placeholder={t('selectRiskLevel') as string}
                  value={riskLevel}
                />
                <AddedFindingTextArea
                  value={riskLevelNote}
                  onChange={setRiskLevelNote}
                />
              </AddedFindingColumn>
              <AddedFindingColumn data-cy="formReportPlanColumn">
                <Select
                  onChange={handleRemediationPlanOptionChange}
                  options={getRemediationPlanSelectItems(t)}
                  placeholder={t('selectPlan') as string}
                  value={remediationPlan}
                />
                <AddedFindingTextArea
                  value={remediationPlanNote}
                  onChange={setRemediationPlanNote}
                />
              </AddedFindingColumn>
            </AddedFindingWrapper>
            <CenterTextButton
              disabled={!isFindingValid}
              onClick={handleAddFinding}
              data-cy="formReportAddFindingBtn"
            >
              {t('addFinding') as string}
            </CenterTextButton>
            <FindingsTable
              data={findings}
              deleteFinding={handleDeleteFinding}
            />

            <ButtonBar>
              <DownloadWrapper>
                <DownloadButtonsWrapper>
                  {pdfDownloadButton}
                  {csvDownloadButton}
                </DownloadButtonsWrapper>
                <CheckboxWrapper>
                  <Checkbox
                    isChecked={downloadWithNote}
                    label={t('exportWithNotes') as string}
                    onChange={toggleDownloadWithNote}
                    secondaryColor={'teal'}
                    isCompact={true}
                    labelSize={'small'}
                  />
                </CheckboxWrapper>
              </DownloadWrapper>
            </ButtonBar>
          </>
        ) : (
          <div>{t('loading') as string}</div>
        )}
      </Wrapper>
    </Dropdown>
  );
};

export default withTranslation('FormReport')(Report);
