import { UserType, MessageType, Uuid } from '../../../common/common.types';
import { assocPath, compose, dissocPath } from 'ramda';
import { call, put } from 'redux-saga/effects';
import { hydrateFilters } from './hydrateFilters';
import { parseMessage } from 'utils/parsers';
import { trackEvent } from 'utils/analytics';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';

export type FormsCreateMessageFunctionType = (
  id: Uuid,
  text: string,
  postingId: string,
  author: UserType,
  formId: Uuid,
) => void;

export interface FormsCreateMessageCallParamsType {
  id: string;
  text: string;
  postingId: string;
  formId: Uuid;
}

export type FormsCreateMessageActionType = OptimisticActionType<
  '@forms/API/CREATE_MESSAGE',
  ApiCallActionPayloadType<
    FormsCreateMessageCallParamsType,
    any,
    never,
    FormsStateType
  >
>;

export const createMessage = (
  id: Uuid,
  text: string,
  postingId: Uuid,
  author: UserType,
  formId: Uuid,
): FormsCreateMessageActionType => ({
  type: '@forms/API/CREATE_MESSAGE',
  payload: {
    callParams: { id, text, postingId, formId },
    endpointPath: ['forms', 'questions', 'comment'],
    expectedStateChange: {
      [`forms.currentForm.questions.${id}.messages.${postingId}`]: {
        createdAt: new Date(),
        text,
        postingId,
      },
    },
    selector: (data, state): FormsStateType =>
      compose(
        assocPath<MessageType, FormsStateType>(
          ['currentForm', 'questions', id, 'messages', data.id],
          parseMessage(data, author),
        ),
        dissocPath([
          'currentForm',
          'questions',
          id.toString(),
          'messages',
          postingId,
        ]),
      )(state.forms),
    postActions: [
      () => put(hydrateFilters(formId)),
      () =>
        call(() =>
          trackEvent('Comment', 'Commented question', `Question: ${id}`),
        ),
    ],
  },
});
