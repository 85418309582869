import { assocPath } from 'ramda';
import { parseStandard } from 'utils/parsers';
import {
  StandardSortType,
  StandardStatusType,
  Uuid,
} from '../../../common/common.types';
import { ApiCallActionType, StateType } from '../../store.types';
import { FormDefinitionsStateType } from '../formDefinitions.types';
import { Standard } from '../../../generated/models/Standard';

import { sortStandardsArray } from 'utils/filters/formDefinitions.sorters';

export interface GetStandardsForFormDefinitionsParamsType {
  limit?: number;
  ownerId?: Uuid;
  status?: StandardStatusType[];
  sort?: StandardSortType;
}

export type GetStandardsForFormDefinitionsFunctionType = (
  params: GetStandardsForFormDefinitionsParamsType,
) => void;

export type GetStandardsForFormDefinitionsActionType = ApiCallActionType<
  '@formDefinitions/API/GET_STANDARDS',
  GetStandardsForFormDefinitionsParamsType,
  Standard[],
  never,
  FormDefinitionsStateType
>;

export const getStandardsForFormDefinitions = (
  params: GetStandardsForFormDefinitionsParamsType = {},
): GetStandardsForFormDefinitionsActionType => ({
  type: '@formDefinitions/API/GET_STANDARDS',
  payload: {
    endpointPath: ['standards', 'list'],
    callParams: params,
    selector: (
      data: Standard[],
      state: StateType,
    ): Partial<FormDefinitionsStateType> => {
      const standards = data.map(parseStandard);
      const sortedStandards = sortStandardsArray(
        state.formDefinitions.standards.filter.sortBy,
      )(standards);
      return assocPath(
        ['standards', 'data'],
        sortedStandards,
        state.formDefinitions,
      );
    },
  },
});
