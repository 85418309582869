import React from 'react';
import { Badge } from '../common/Badge';
import { SearchMappingType } from './SearchMappings';
import { Table } from './Utils.styles';
import { getResponseLabel } from './utils';

interface MappingsTableType {
  mappings: SearchMappingType[];
}

const MappingsTable = ({ mappings }: MappingsTableType) => (
  <Table>
    <thead>
      <tr>
        <th>Depth</th>
        <th>Questionniare</th>
        <th>Question code</th>
        <th>Mapped question</th>
        <th>Algorithm</th>
        <th>Score</th>
        <th>Response type</th>
        <th>Selected response</th>
      </tr>
    </thead>

    <tbody>
      {mappings.map(
        ({
          depth,
          result: {
            target_question: { id: questionId, question, code, responses },
            target_standard: { id, name, version, year },
            algorithm,
            score,
          },
        }) => (
          <tr key={`mapping-target-id-${questionId}`}>
            <td style={{ paddingLeft: depth * 2 + 'em' }}>
              <Badge>{depth}</Badge>
            </td>
            <td title={`${year}: ${version}`}>{id ? name : ''}</td>
            <td>{code}</td>
            <td>{question}</td>
            <td>{algorithm}</td>
            <td>{score}</td>
            <td>
              {responses
                .map(({ definition }) =>
                  getResponseLabel(definition.response_type),
                )
                .join(', ')}
            </td>
            <td>
              {responses
                .filter(({ is_filled }) => is_filled)
                .map(({ value }) => `"${value}"`)
                .join(', ')}
            </td>
          </tr>
        ),
      )}
    </tbody>
  </Table>
);

export default MappingsTable;
