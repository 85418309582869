import { SortIcon } from 'components/FormDefinitions/FormDefinitionsSorter/FormDefinitionsSorter.styles';
import { useState } from 'react';
import { TableHead } from './table.styles';

interface TableHeaderCellSortableProps {
  label: string;
  sortOrder: 'ASC' | 'DESC' | null;
  onClick: (sortOrder: 'ASC' | 'DESC') => void;
  sortedColumn: string | null;
}

const TableHeaderCellSortable = ({
  label,
  onClick,
  sortOrder,
  sortedColumn,
}: TableHeaderCellSortableProps) => {
  const handleClick = () => {
    if (sortedColumn === label) {
      onClick(sortOrder === 'ASC' ? 'DESC' : 'ASC');
    } else {
      onClick('ASC');
    }
  };

  return (
    <TableHead onClick={handleClick}>
      {label}
      {sortedColumn === label && sortOrder === 'ASC' && (
        <SortIcon direction="ASC" />
      )}
      {sortedColumn === label && sortOrder === 'DESC' && (
        <SortIcon direction="DESC" />
      )}
    </TableHead>
  );
};

export default TableHeaderCellSortable;
