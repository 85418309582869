import { TFunction } from 'i18next';
import useFlag from 'utils/hooks/useFlag';
import { SEND_REQUEST, ATLAS_UI_MIGRATION } from '../../routes';
import { DismissedNotifications } from 'store/settings/settings.reducer';
import {
  CreditsHistoryItemType,
  CurrentUserType,
  NotificationEnum,
  ToggleEnum,
} from 'common/common.types';
import { pathOr } from 'ramda';
import { NotificationPropsType } from './Notification';
import usersApi from 'api/new/usersApi';
import { put } from 'redux-saga/effects';
import { flash } from 'store/flashes/actions';

const getLatestAddedTimestamp = (
  history: CreditsHistoryItemType[],
): CreditsHistoryItemType =>
  pathOr(
    {
      amount: 0,
      createdAt: 0,
    },
    ['0'],
    history
      .filter(({ amount }) => amount > 0)
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
  );

const hasBeenNotificationDismissed = (
  dismissedNotification: null | number | undefined,
  createdAt: Date,
) =>
  dismissedNotification === null || dismissedNotification === undefined
    ? false
    : new Date(dismissedNotification) >= createdAt;

export default (
  appealCredits: () => void,
  dismissedNotifications: DismissedNotifications,
  navigate: (url: string) => void,
  pathname: string,
  user: CurrentUserType,
  t: TFunction,
  isOptInEnabled: boolean,
): NotificationPropsType[] => {
  const {
    credits,
    stats: { logins },
  } = user;
  const notifications: NotificationPropsType[] = [];

  const hasCTAModal = useFlag(user, [ToggleEnum.CREDITS_CTA_MODAL]);

  if (!credits) {
    return notifications;
  }

  const lastAddedCredits = getLatestAddedTimestamp(credits.history || []);

  if (
    logins === 1 &&
    dismissedNotifications[NotificationEnum.WELCOME] === null
  ) {
    const currentCredits = (credits.summary && credits.summary.remaining) || 0;
    notifications.push({
      type: NotificationEnum.WELCOME,
      onClick: () => {
        return hasCTAModal
          ? appealCredits()
          : window.open(
              'https://securityscorecard.com/atlas-upgrade',
              '_blank',
            );
      },
      textButton: t(`${NotificationEnum.WELCOME}.textButton`),
      textHTML: t(`${NotificationEnum.WELCOME}.textHTML`, {
        count: currentCredits,
      }),
    });
  }

  if (
    credits.summary &&
    credits.summary.remaining === 0 &&
    pathname === SEND_REQUEST
  ) {
    notifications.push({
      type: NotificationEnum.NO_MORE_CREDITS,
      isDismissable: false,
      onClick: () => {
        return hasCTAModal
          ? appealCredits()
          : window.open(
              'https://securityscorecard.com/atlas-upgrade',
              '_blank',
            );
      },
      textButton: t(`${NotificationEnum.NO_MORE_CREDITS}.textButton`),
      textHTML: t(`${NotificationEnum.NO_MORE_CREDITS}.textHTML`),
    });
  }

  if (
    lastAddedCredits.amount > 0 &&
    !hasBeenNotificationDismissed(
      dismissedNotifications[NotificationEnum.GRANTED],
      lastAddedCredits.createdAt,
    )
  ) {
    notifications.push({
      type: NotificationEnum.GRANTED,
      onClick: () => navigate(SEND_REQUEST),
      textButton: t(`${NotificationEnum.GRANTED}.textButton`),
      textHTML: t(`${NotificationEnum.GRANTED}.textHTML`, {
        count: lastAddedCredits.amount,
      }),
    });
  }

  if (
    credits.summary &&
    credits.summary.remaining === 1 &&
    !hasBeenNotificationDismissed(
      dismissedNotifications[NotificationEnum.ALMOST_OUT],
      lastAddedCredits.createdAt,
    )
  ) {
    notifications.push({
      type: NotificationEnum.ALMOST_OUT,
      onClick: () => {
        return hasCTAModal
          ? appealCredits()
          : window.open(
              'https://securityscorecard.com/atlas-upgrade',
              '_blank',
            );
      },
      textButton: t(`${NotificationEnum.ALMOST_OUT}.textButton`),
      textHTML: t(`${NotificationEnum.ALMOST_OUT}.textHTML`, {
        count: credits.summary.remaining,
      }),
    });
  }

  if (
    isOptInEnabled &&
    !dismissedNotifications[NotificationEnum.DISMISS_OPT_IN]
  ) {
    notifications.push({
      type: NotificationEnum.DISMISS_OPT_IN,
      onClick: async () => {
        const res = await usersApi.putUserMigrationOptIn();
        if (res.status === 200) {
          window.open(`${ATLAS_UI_MIGRATION}?redirect=atlas`);
        } else {
          put(
            flash(
              'We were not able to redirect you to the new design. Try again later.',
              'error',
            ),
          );
        }
      },
      textButton: t('FormDefinitions:tryNow'),
      textHTML: `<div style="display: flex; flex-direction: column; align-items: flex-start; padding: 12px 0; text-align: left">
      <span style="margin-bottom: 8px;">${t('FormDefinitions:tryAtlasDesign')}
      </span> <span style="font-family: Proxima Nova Light">${t(
        'FormDefinitions:navigateTroughNewDesign',
      )}</span></div>`,
    });
  }

  credits.list.forEach(({ expiresAt, remaining }) => {
    const notificationDatetime = new Date(
      expiresAt.getTime() - 30 * 60 * 60 * 24 * 1000,
    );
    const daysToExpire = Math.ceil(
      (expiresAt.getTime() - new Date().getTime()) / (60 * 60 * 24 * 1000),
    );
    if (
      daysToExpire > 0 &&
      daysToExpire < 30 &&
      !hasBeenNotificationDismissed(
        dismissedNotifications[NotificationEnum.WILL_EXPIRE],
        notificationDatetime,
      )
    ) {
      notifications.push({
        type: NotificationEnum.WILL_EXPIRE,
        onClick: () => navigate(SEND_REQUEST),
        textButton: t(`${NotificationEnum.WILL_EXPIRE}.textButton`),
        textHTML: t(`${NotificationEnum.WILL_EXPIRE}.textHTML`, {
          count: remaining,
          when:
            daysToExpire < 30
              ? t('whenDays', {
                  count: daysToExpire,
                })
              : t('whenMonth'),
        }),
      });
    }
  });

  return notifications;
};
