import { FormProxyActionType } from './actions';
import { FormCollaboratorType, RequestType } from '../../common/common.types';

export interface FormProxyStateType {
  collaborators: FormCollaboratorType[];
  request?: RequestType;
}

const initialState: FormProxyStateType = {
  collaborators: [],
  request: undefined,
};

export const formProxy = (
  state = initialState,
  action: FormProxyActionType,
): FormProxyStateType => {
  switch (action.type) {
    case '@formProxy/RESET_MODAL':
      return initialState;
    default:
      return state;
  }
};
