import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface UploadPropsType {
  render: (isDragActive: boolean) => JSX.Element;
  onDrop: (files: File[]) => void;
}

const DropUpload = ({ render, onDrop: onDropProp }: UploadPropsType) => {
  const onDrop = useCallback(onDropProp, [onDropProp]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} data-test="upload-input" />

      {render(isDragActive)}
    </div>
  );
};

export default DropUpload;
