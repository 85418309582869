import Page, { PageActionsBaseType, PageBaseType, PagePropsType } from './Page';
import React from 'react';
import Sales from 'components/Sales/Sales';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import { StandardStatusBranchStateType } from 'store/standard/reducer';
import { hideModal, showModal } from '../utils/Modal';
import { resetModals } from '../utils/Modal/store/actions/resetModals';
import { startLoading } from 'store/app/actions';
import {
  addCredits,
  clearAutocomplete,
  clearCredits,
  findCompany,
  getCredits,
  CompaniesAddCreditsFunctionType,
  CompaniesGetCreditsFunctionType,
} from 'store/companies/actions';
import {
  AutocompleteCompanyType,
  CompanyCreditsType,
  FormType,
  LanguageType,
  ParsedFileType,
  StandardType,
  UserType,
  CurrentUserType,
  Uuid,
} from 'common/common.types';
import {
  getStandards,
  GetStandardsFunctionType,
  GetStandardsParamsType,
} from 'store/standard/actions/getStandards';
import {
  StandardsDeleteParamsType,
  RenameStandardFunctionType,
  RenameStandardParamsType,
  StandardsDeleteFunctionType,
  ToggleStandardSelectionFunctionType,
  ToggleStandardSelectionParamsType,
  TransferStandardsFunctionType,
  TransferStandardsParamsType,
  deleteStandard,
  renameStandard,
  resetStandards,
  toggleStandardSelection,
  transferStandards,
  uploadStandard,
} from 'store/standard/actions';
import {
  FormGetQuestionsParamsType,
  FormsGetPreviewFunctionType,
  FormsLoadQuestionsFunctionType,
  FormsPutQuestionFunctionType,
  getPreview,
  loadQuestions,
  putQuestion,
} from 'store/forms/actions';

import {
  systemTemplateUploadDefinitions,
  SystemTemplateUploadDefinitionsCallParamsType,
} from '../store/formDefinitions/actions';

export interface SalesPageContextType extends PageBaseType {
  data: {
    autocomplete: AutocompleteCompanyType[];
    companyStandards: StandardType[];
    credits: CompanyCreditsType;
    customStandards: StandardStatusBranchStateType;
    parseErrorsCount: number;
    parsedFiles: ParsedFileType[];
    previewForm: FormType;
    transferingCount: number;
    uploadingCount: number;
    user: UserType;
    currentUser: CurrentUserType;
    language: LanguageType;
  };
  actions: PageActionsBaseType & {
    addCredits: CompaniesAddCreditsFunctionType;
    clearAutocomplete: () => void;
    clearCredits: () => void;
    findCompany: (prefix: string) => void;
    deleteStandard: StandardsDeleteFunctionType;
    getCredits: CompaniesGetCreditsFunctionType;
    getPreview: FormsGetPreviewFunctionType;
    getStandards: GetStandardsFunctionType;
    hideModal: (modalId: string) => void;
    loadQuestions: FormsLoadQuestionsFunctionType;
    putQuestion: FormsPutQuestionFunctionType;
    renameStandard: RenameStandardFunctionType;
    showModal: (modalId: string) => void;
    toggleStandardSelection: ToggleStandardSelectionFunctionType;
    transferStandards: TransferStandardsFunctionType;
    uploadStandard: (formData: FormData) => void;
    uploadSystemTemplate: (
      params: SystemTemplateUploadDefinitionsCallParamsType,
    ) => void;
  };
}

const SalesPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      autocomplete: state.companies.autocomplete,
      companyStandards: state.standard.company.all,
      credits: state.companies.credits,
      customStandards: state.standard.all,
      previewForm: state.forms.currentForm,
      parseErrorsCount: state.standard.parseErrorsCount,
      parsedFiles: state.standard.parsedFiles,
      transferingCount: state.standard.transferingCount,
      uploadingCount: state.standard.uploadingCount,
      user: state.user.currentUser,
      language: state.settings.languagePreference,
      currentUser: state.user.currentUser,
    })}
    dispatches={(dispatch: Dispatch<ActionType>) => ({
      getData: () => {
        dispatch(startLoading(1));
        dispatch(getStandards({ status: 'custom' }));
      },
      reset: () => {
        dispatch(clearAutocomplete());
        dispatch(clearCredits());
        dispatch(resetStandards());
        dispatch(resetModals());
      },
      actions: {
        addCredits: (amount: number, id: Uuid) =>
          dispatch(addCredits(amount, id)),
        clearAutocomplete: () => dispatch(clearAutocomplete()),
        findCompany: (query: string) => dispatch(findCompany({ query })),
        deleteStandard: (params: StandardsDeleteParamsType) =>
          dispatch(deleteStandard(params)),
        getCredits: (id: Uuid) => dispatch(getCredits(id)),
        getPreview: (id: Uuid) => dispatch(getPreview(id)),
        getStandards: (params: GetStandardsParamsType) =>
          dispatch(getStandards(params)),
        hideModal: (modalId: string) => dispatch(hideModal(modalId)),
        loadQuestions: (params: FormGetQuestionsParamsType) =>
          dispatch(loadQuestions(params)),
        putQuestion: (
          formId: Uuid,
          questionId: Uuid,
          responseId: Uuid,
          value: string,
          category: string,
        ) => {
          dispatch(
            putQuestion(formId, questionId, responseId, value, category),
          );
        },
        renameStandard: (params: RenameStandardParamsType) =>
          dispatch(renameStandard(params)),
        showModal: (modalId: string) => dispatch(showModal(modalId)),
        toggleStandardSelection: (params: ToggleStandardSelectionParamsType) =>
          dispatch(toggleStandardSelection(params)),
        transferStandards: (params: TransferStandardsParamsType) =>
          dispatch(transferStandards(params)),
        uploadStandard: (formData: FormData) =>
          dispatch(uploadStandard(formData)),
        uploadSystemTemplate: (
          params: SystemTemplateUploadDefinitionsCallParamsType,
        ) => dispatch(systemTemplateUploadDefinitions(params)),
      },
    })}
  >
    <Sales />
  </Page>
);

export default SalesPage;
