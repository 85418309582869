import React from 'react';
import {
  CompletedIcon,
  InlineButton,
  Suggestions,
  Text,
  Wrapper,
} from './Autocomplete.styles';
import { Trans, withTranslation } from 'react-i18next';
import MatchIntro from './MatchIntro';
import { TranslatePropsType } from 'common/common.types';

interface AutocompleteFinishPropsType extends TranslatePropsType {
  handleClose: () => void;
  questionsAutofilledCount: number;
  questionsSuggestedCount: number;
}

const AutocompleteFinish = ({
  handleClose,
  questionsAutofilledCount,
  questionsSuggestedCount,
  t,
}: AutocompleteFinishPropsType) => (
  <Wrapper>
    <MatchIntro />

    <Suggestions>
      <CompletedIcon />
      <div>
        <Trans
          i18nKey="autocompleteFinish"
          count={questionsAutofilledCount}
          parent={Text}
          t={t}
        >
          <strong>count questions</strong>
          autocompleted.
          <strong>
            <>{{ questionsSuggestedCount }} questions</>
          </strong>{' '}
          have suggested answers waiting for your review
        </Trans>

        <InlineButton onClick={handleClose}>
          {t('reviewQuestions') as string}
        </InlineButton>
      </div>
    </Suggestions>
  </Wrapper>
);

export default withTranslation('Autocomplete')(AutocompleteFinish);
