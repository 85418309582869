import toCamelCase from 'utils/toCamelCase';
import { parseStandard } from 'utils/parsers';
import {
  StandardBranchType,
  StandardSortType,
  Uuid,
} from '../../../common/common.types';
import { ApiCallActionType } from '../../store.types';
import { StandardStateType } from '../reducer';
import update from 'immutability-helper';
import { compareNames } from '../standard.selectors';
import { sort as ramdaSort } from 'ramda';
import { Standard } from '../../../generated/models/Standard';

export interface GetStandardsParamsType {
  limit?: number;
  ownerId?: Uuid;
  status?: StandardBranchType | StandardBranchType[];
  sort?: StandardSortType;
}

export type GetStandardsFunctionType = (params: GetStandardsParamsType) => void;

export type GetStandardsActionType = ApiCallActionType<
  '@standard/API/GET',
  GetStandardsParamsType,
  Standard[],
  never,
  StandardStateType
>;

export const getStandards = (
  params: GetStandardsParamsType = {},
): GetStandardsActionType => ({
  type: '@standard/API/GET',
  payload: {
    endpointPath: ['standards', 'list'],
    callParams: params,
    selector: (data, { standard }) => {
      const { sort = 'all', status = 'all' } = params;
      const standards = data.map(rawStandard => parseStandard(rawStandard));

      const allByName =
        sort !== 'all' ? ramdaSort(compareNames, standards) : standards;

      const pathChunk: keyof StandardStateType = params.ownerId
        ? 'company'
        : 'all';

      const statuses: StandardBranchType[] =
        typeof status === 'string'
          ? [status]
          : Array.isArray(status)
          ? status
          : [];

      return update(standard, {
        [pathChunk]: {
          all: { $set: allByName },
          [toCamelCase(sort)]: { $set: standards },
          fetchedStatuses: { $set: statuses },
        },
      });
    },
  },
});
