import { ApiCallActionType } from '../../store.types';
import { CreditsDetail } from '../../../generated/models/CreditsDetail';
import { parseCompanyCredits } from 'utils/parsers';
import { CompaniesStateType } from '../companies.reducer';
import { Uuid } from '../../../common/common.types';

export interface CompaniesGetCreditsCallParamsType {
  id: Uuid;
}

export type CompaniesGetCreditsFunctionType = (id: Uuid) => void;

export type CompaniesGetCreditsActionType = ApiCallActionType<
  '@companies/API/GET_CREDITS',
  CompaniesGetCreditsCallParamsType,
  CreditsDetail,
  never,
  CompaniesStateType
>;

export const getCredits = (id: Uuid): CompaniesGetCreditsActionType => ({
  type: '@companies/API/GET_CREDITS',
  payload: {
    endpointPath: ['companies', 'getCredits'],
    callParams: { id },
    selector: (data: CreditsDetail) => ({
      credits: parseCompanyCredits(data),
    }),
  },
});
