/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DelegationNotificationStatusChangeStats
 */
export interface DelegationNotificationStatusChangeStats {
    /**
     * 
     * @type {number}
     * @memberof DelegationNotificationStatusChangeStats
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof DelegationNotificationStatusChangeStats
     */
    direction: DelegationNotificationStatusChangeStatsDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof DelegationNotificationStatusChangeStats
     */
    status: DelegationNotificationStatusChangeStatsStatusEnum;
}

export function DelegationNotificationStatusChangeStatsFromJSON(json: any): DelegationNotificationStatusChangeStats {
    return DelegationNotificationStatusChangeStatsFromJSONTyped(json, false);
}

export function DelegationNotificationStatusChangeStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DelegationNotificationStatusChangeStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'direction': json['direction'],
        'status': json['status'],
    };
}

export function DelegationNotificationStatusChangeStatsToJSON(value?: DelegationNotificationStatusChangeStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'direction': value.direction,
        'status': value.status,
    };
}

/**
* @export
* @enum {string}
*/
export enum DelegationNotificationStatusChangeStatsDirectionEnum {
    Forward = 'forward',
    Backward = 'backward'
}
/**
* @export
* @enum {string}
*/
export enum DelegationNotificationStatusChangeStatsStatusEnum {
    NOTNEEDED = 'NOT_NEEDED',
    ASSIGNEDINBUFFER = 'ASSIGNED_IN_BUFFER',
    ASSIGNEDANDNOTIFIED = 'ASSIGNED_AND_NOTIFIED',
    ASSIGNEDANDCLEARED = 'ASSIGNED_AND_CLEARED',
    FILLEDINBUFFER = 'FILLED_IN_BUFFER',
    FILLEDANDNOTIFIED = 'FILLED_AND_NOTIFIED',
    FILLEDANDCLEARED = 'FILLED_AND_CLEARED'
}


