import { keys, reduce } from 'ramda';
import { setStateKeyFromPath } from './setStateKeyFromPath';
import { StateType } from 'store/store.types';

export const transformState = (transformations: object) => (state: StateType) =>
  reduce(
    (newState: StateType, keyPath: string) =>
      setStateKeyFromPath(keyPath, transformations[keyPath], newState),
    state,
    keys(transformations),
  );
