import { SortType } from 'utils/filters/formDefinitions.sorters';

export type FormDefinitionsSetSortFunctionType = (sortBy: SortType) => void;

export interface FormDefinitionsSetSortActionType {
  type: '@formDefinitions/SET_SORT';
  payload: {
    sortBy: SortType;
  };
}

export const setSort = (
  sortBy: SortType,
): FormDefinitionsSetSortActionType => ({
  type: '@formDefinitions/SET_SORT',
  payload: {
    sortBy,
  },
});
