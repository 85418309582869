/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormMappingStats
 */
export interface FormMappingStats {
    /**
     * 
     * @type {number}
     * @memberof FormMappingStats
     */
    questionsPartiallyUpdated: number;
    /**
     * 
     * @type {number}
     * @memberof FormMappingStats
     */
    questionsUpdated: number;
    /**
     * 
     * @type {number}
     * @memberof FormMappingStats
     */
    responsesUpdated: number;
}

export function FormMappingStatsFromJSON(json: any): FormMappingStats {
    return FormMappingStatsFromJSONTyped(json, false);
}

export function FormMappingStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormMappingStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionsPartiallyUpdated': json['questions_partially_updated'],
        'questionsUpdated': json['questions_updated'],
        'responsesUpdated': json['responses_updated'],
    };
}

export function FormMappingStatsToJSON(value?: FormMappingStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questions_partially_updated': value.questionsPartiallyUpdated,
        'questions_updated': value.questionsUpdated,
        'responses_updated': value.responsesUpdated,
    };
}


