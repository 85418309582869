import React from 'react';
import { BarWrapper } from './ScoreChart.styles';
import formatRoute, { COMPANY } from '../../../routes';
import { Bar, BarHeader, GradeWrapper } from './GradeChart.styles';
import Hexagon from '../../common/Hexagon';
import atlas from '../../../common/themes/atlas-palette';
import { Uuid } from 'common/common.types';

interface GradeRecordPropsType {
  companyId?: Uuid;
  children: React.ReactNode;
  grade: string;
  name: string;
  width: number;
}

const GradeRecord = ({
  companyId,
  children,
  grade,
  name,
  width,
}: GradeRecordPropsType) => (
  <BarWrapper align="baseline" data-test="grade-bar">
    <BarHeader
      name={name}
      nameLink={
        companyId ? `#${formatRoute(COMPANY, { id: companyId })}` : undefined
      }
    />

    <GradeWrapper>
      <Hexagon
        color={grade ? atlas.grade.ssc[grade.toLowerCase()] : '#dddddd'}
        size={18}
        text={grade || '?'}
        textColor={!!grade ? '#fff' : '#5d5d5d'}
        textSize={'medium'}
        useSpan
      />
    </GradeWrapper>

    <Bar
      data-test="grade-value"
      color={grade ? atlas.grade.ssc[grade.toLowerCase()] : '#dddddd'}
      width={width}
    >
      {children}
    </Bar>
  </BarWrapper>
);

export default GradeRecord;
