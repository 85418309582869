import GradeImg from '../../common/Grade/Grade';
import React, { memo } from 'react';
import { format } from 'date-fns';
import { DATE_FORMAT_BASED_ON_LOCALE } from 'common/common.constants';
import { TextButton, VerticallyCentered } from 'common/common.styles';
import { withTranslation } from 'react-i18next';
import {
  Cell,
  Company,
  Grade,
  Main,
  Title,
  TitlePart,
  Transceiver,
  TransceiverPart,
  Wrapper,
  Year,
} from '../Request/Request.styles';
import {
  LanguageType,
  ScheduledRequestType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import {
  NextSendDate,
  NoCredits,
  ScheduledDate,
} from './ScheduledRequest.styles';
import { StateType } from '../../../store/store.types';
import { getLanguagePreference } from '../../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import getLocale from 'utils/getLocale';

export interface ScheduledRequestPropsType extends TranslatePropsType {
  actions: {
    deleteRequest: (
      e: React.MouseEvent<HTMLElement>,
      request: ScheduledRequestType,
    ) => void;
    previewForm: (formId: Uuid) => void;
  };
  data: {
    enoughCredits: boolean;
    request: ScheduledRequestType;
  };
  language?: LanguageType;
}

const ScheduledRequest = ({
  actions: { deleteRequest, previewForm },
  data: {
    enoughCredits,
    request,
    request: {
      isInternal,
      meta: { receiver, receiverCompanyOrTeam, sender },
      nextSendDate,
      standard: { name: standardName, version, previewFormId },
      target: { gradeUrl },
    },
  },
  language,
  t,
}: ScheduledRequestPropsType) => {
  return (
    <Wrapper
      isRead
      isVisible
      isClickable={previewFormId !== null && previewFormId !== undefined}
      onClick={previewFormId ? () => previewForm(previewFormId) : undefined}
    >
      <Cell width={220}>
        <VerticallyCentered>
          <Company>
            <VerticallyCentered>
              <Grade>
                {isInternal ? (
                  <GradeImg url={'/icons/internal.svg'} />
                ) : (
                  <GradeImg url={gradeUrl} />
                )}
              </Grade>
            </VerticallyCentered>

            <span>{receiverCompanyOrTeam}</span>
          </Company>

          <TransceiverPart>
            <Transceiver data-cy="request-receiver" title={receiver}>
              {t('Requests.receiver', { receiver }) as string}
            </Transceiver>

            <Transceiver data-cy="request-sender" title={sender}>
              {t('Requests.sender', { sender }) as string}
            </Transceiver>
          </TransceiverPart>
        </VerticallyCentered>
      </Cell>

      <Cell grow style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 8 }}>
        <Main>
          <TitlePart>
            <Title data-cy="request-title">{standardName}</Title>

            <Year>{version}</Year>
          </TitlePart>
        </Main>
      </Cell>

      <Cell width={310}>
        <VerticallyCentered>
          <NextSendDate>
            {t('ScheduledRequest.newSendDate') as string}
          </NextSendDate>

          <ScheduledDate>
            {language &&
              format(
                nextSendDate,
                DATE_FORMAT_BASED_ON_LOCALE[language.value],
                {
                  locale: getLocale(language),
                },
              )}

            {!enoughCredits && (
              <NoCredits>
                {t('ScheduledRequest.notEnoughCredits') as string}
              </NoCredits>
            )}
          </ScheduledDate>
        </VerticallyCentered>
      </Cell>

      <Cell width={90}>
        <VerticallyCentered>
          <TextButton onClick={e => deleteRequest(e, request)}>
            {t('ScheduledRequest.delete') as string}
          </TextButton>
        </VerticallyCentered>
      </Cell>
    </Wrapper>
  );
};
const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});
export default connect(
  mapStateToProps,
  null,
)(memo(withTranslation()(ScheduledRequest)));
