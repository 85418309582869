import React, { useEffect, useState, ChangeEvent } from 'react';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'utils/Modal';
import {
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalHeaderSeparator,
  ReasonTextArea,
  ModalContent,
  ModalAssessmentText,
  ModalRadioGroup,
  RadioContainer,
  ReasonText,
  Link,
  Note,
} from './FormResponseModal.styles';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Radio from 'components/common/Radio';
import { Button } from 'components/common/Button';
import { sendBackRadioOptions } from './FormResponseConstants';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface FormResponseModalPropType extends TranslatePropsType {
  onSendBack: () => void;
  onCancelSendBack: () => void;
  setSendBackValues: any;
}

const FormResponseModal = ({
  onCancelSendBack,
  onSendBack,
  setSendBackValues,
  t,
}: FormResponseModalPropType) => {
  const [radioValue, setRadioValue] = useState('');
  const [otherMessage, setOtherMessage] = useState('');
  const [additionalMessage, setAdditionalMessage] = useState('');

  useEffect(() => {
    if (radioValue !== 'other') {
      setOtherMessage('');
    }

    setSendBackValues({
      data: {
        reason:
          radioValue && radioValue !== 'other' ? t(radioValue) : otherMessage,
        additionalInformation: additionalMessage,
      },
    });
  }, [radioValue, additionalMessage, otherMessage, setSendBackValues, t]);

  const onCancel = () => {
    setRadioValue('');
    setOtherMessage('');
    setAdditionalMessage('');
    onCancelSendBack();
  };

  const modalProps = {
    modalId: 'form-response-modal',
    custom: true,
    closeWithButtonsOnly: true,
  };

  return (
    <Modal {...modalProps}>
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>{t('sendBackReasonTitle') as string}</ModalTitle>
          <div onClick={onCancel}>
            <FontAwesomeIcon
              icon={faTimes as IconProp}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </ModalHeader>
        <ModalHeaderSeparator />
        <ModalContent>
          <div style={{ paddingLeft: '25px' }}>Select a reason:</div>
          <ModalRadioGroup>
            {sendBackRadioOptions &&
              sendBackRadioOptions.map((curr, index) => {
                if (curr) {
                  const textBox = curr === 'other' && (
                    <ReasonText
                      disabled={!(radioValue === 'other')}
                      value={otherMessage}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setOtherMessage(e.target.value)
                      }
                      placeholder="Provide another reason"
                    />
                  );
                  return (
                    <div style={{ display: 'flex' }}>
                      <RadioContainer>
                        <Radio
                          key={`radio-option-${index}`}
                          isSelected={curr === radioValue}
                          label={t(curr)}
                          handleChange={() => setRadioValue(curr)}
                          size="small"
                          version="light"
                        />
                      </RadioContainer>
                      {textBox}
                    </div>
                  );
                }
                return null;
              })}
          </ModalRadioGroup>
          <ModalAssessmentText>
            <div style={{ marginBottom: '10px' }}>
              {t('sendBackAdditionalMessageLabel') as string}
            </div>
            <ReasonTextArea
              onBlur={value => setAdditionalMessage(value)}
              onChange={() => additionalMessage}
            />
            <Note>{t('sendBackAdditionalNote') as string}</Note>
            <div>
              <Button
                disabled={
                  (radioValue === 'other' && !otherMessage) || !radioValue
                }
                onClick={onSendBack}
              >
                {t('sendToVendorButton') as string}
              </Button>
              <Link onClick={onCancel}>{t('cancel') as string}</Link>
            </div>
          </ModalAssessmentText>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};
export default withTranslation('FormFooter')(FormResponseModal);
