import Mapping from './Mapping';
import React from 'react';
import getResponseComponent from './utils/getResponseComponent';
import {
  FormsCreateQuestionAttachmentFunctionType,
  FormsCreateResponseAttachmentFunctionType,
  FormsDeleteQuestionAttachmentFunctionType,
  FormsPutQuestionFunctionType,
  FormsSetBlockSeenFunctionType,
} from 'store/forms/actions';
import { Inside, Square } from '../Question/Question.styles';
import {
  AttachmentsDict,
  LanguageType,
  ResponseType,
  Uuid,
} from 'common/common.types';
import { ResponseTypeDefinitionResponseTypeEnum } from 'generated/models';

interface OptionsActionPropsType {
  createQuestionAttachment?: FormsCreateQuestionAttachmentFunctionType;
  createResponseAttachment?: FormsCreateResponseAttachmentFunctionType;
  deleteQuestionAttachment?: FormsDeleteQuestionAttachmentFunctionType;
  handleChange: (newValue: string) => void;
  setBlockSeen?: FormsSetBlockSeenFunctionType;
}

interface OptionsInputPropsType {
  attachments?: AttachmentsDict;
  hasBeenTouched?: boolean;
  formId: Uuid;
  id: Uuid;
  isEditable: boolean;
  isRequired?: boolean;
  label?: string;
  options?: string[];
  placeholder?: string;
  questionId: Uuid;
  responseType?: ResponseTypeDefinitionResponseTypeEnum;
  value: string;
  areAttachmentsModifiable?: boolean;
}

export interface ResponseInputPropsType {
  actions: OptionsActionPropsType;
  data: OptionsInputPropsType;
}

export interface ResponsePropsType {
  data: {
    category: string;
    formId: Uuid;
    isEditable: boolean;
    questionId: Uuid;
    response: ResponseType;
    language: LanguageType;
  };
  actions: {
    createResponseAttachment?: FormsCreateResponseAttachmentFunctionType;
    deleteQuestionAttachment?: FormsDeleteQuestionAttachmentFunctionType;
    putQuestion?: FormsPutQuestionFunctionType;
    setBlockSeen?: FormsSetBlockSeenFunctionType;
  };
}

const Response = ({
  data: {
    category,
    formId,
    isEditable,
    questionId,
    response: {
      attachments,
      id,
      mappingHistory,
      isFilled,
      isRequired,
      value,
      definition: { label, options, placeholder, responseType },
    },
    language,
  },
  actions: {
    createResponseAttachment,
    deleteQuestionAttachment,
    putQuestion,
    setBlockSeen,
  },
}: ResponsePropsType) => {
  const handleChange = (newValue: string) =>
    putQuestion && putQuestion(formId, questionId, id, newValue, category);

  const InputComponent = getResponseComponent(responseType);

  const isStillRequired = isRequired && !value;

  return (
    <Square
      hasBackground={isFilled}
      key={`response-${id}`}
      isRequired={isStillRequired}
      className={isStillRequired ? 'required' : ''}
    >
      <Inside
        data-cy={`question-${questionId}-response-${id}`}
        data-test="question-response"
      >
        <InputComponent
          actions={{
            createResponseAttachment,
            deleteQuestionAttachment,
            handleChange,
            setBlockSeen,
          }}
          data={{
            attachments,
            formId,
            hasBeenTouched: !!value,
            id,
            isEditable,
            label,
            options,
            placeholder,
            questionId,
            responseType,
            value,
          }}
        />
      </Inside>

      {mappingHistory && <Mapping mappingHistory={mappingHistory} />}
    </Square>
  );
};

export default Response;
