import styled from 'styled-components';

export const HistoryList = styled.div`
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  line-height: 1.4;
`;

export const Date = styled.div`
  display: inline-block;
  width: 70px;
  ${({ theme }) => theme.basic.fontWeights.bold}
  text-align: left;
`;

export const Amount = styled.div`
  display: inline-block;
  width: 40px;
  text-align: left;
`;

export const Text = styled.div`
  display: inline-block;
  text-align: right;
`;
