/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";

/**
 * 
 * @export
 * @interface TagBase
 */
export interface TagBase {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof TagBase
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof TagBase
     */
    updated_at: Date;
    /**
     * 
     * @type {string}
     * @memberof TagBase
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TagBase
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TagBase
     */
    owner_id: string;
    /**
     * 
     * @type {string}
     * @memberof TagBase
     */
    tag_type: TagBaseTagTypeEnum;
}




/**
* @export
* @enum {string}
*/
export enum TagBaseTagTypeEnum {
    NORMAL = 'NORMAL',
    VENDOR = 'VENDOR',
    VRM = 'VRM'
}


