import { pipe } from 'ramda';
import { addRollbackInfo } from './functions/addRollbackInfo';
import { removeRollbackInfo } from './functions/removeRollbackInfo';
import { transformState } from './functions/transformState';
import {
  OptimisticUpdateActionType,
  PragmaticCleanUpActionType,
  RealityCheckActionType,
} from './optimistic.types';
import { StateType } from 'store/store.types';

const optimisticReducer = (
  state: StateType,
  action:
    | OptimisticUpdateActionType
    | RealityCheckActionType
    | PragmaticCleanUpActionType,
): StateType => {
  switch (action.type) {
    case '@optimistic/OPTIMISTIC_UPDATE':
      return pipe(
        addRollbackInfo,
        transformState(action.payload.expectedStateChange),
      )(action, state);

    case '@optimistic/REALITY_CHECK':
      return transformState(state.optimisticTransactions[action.transactionId])(
        state,
      );

    case '@optimistic/PRAGMATIC_CLEANUP':
      return removeRollbackInfo(action.transactionId)(state);

    default:
      return state;
  }
};

export default optimisticReducer;
