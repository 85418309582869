import styled from 'styled-components';

export const Button = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.atlas.teal.primary};
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.atlas.white};
  cursor: pointer;
  display: flex;
  height: 46px;
  justify-content: center;
  width: 130px;
  ${({ theme }) => theme.fonts.bold.normal}
`;

export const Heading = styled.h3`
  ${({ theme }) => theme.fonts.bold.normal}
  line-height: 1.315;
  margin-bottom: 16px;
`;
