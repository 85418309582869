import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Sticky from 'react-sticky-fill';
import { ButtonsWrapper, Footer, FooterWrapper } from './FormFooter.styles';
import { FormPageContextType } from 'pages/FormPage';
import { Metadata } from '../Form.styles';
import { PageContext } from 'pages/Page';
import { ProgressBar } from '../index';
import { SharedFormPageContextType } from 'pages/SharedFormPage';
import { ToggleEnum, TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import RequestEvaluation from './RequestEvaluation';
import AcceptClose from './AcceptClose';
import { ConfirmModal, useConfirmModal } from '../../common/ConfirmModal';
import { RequestSummaryStatusEnum } from '../../../generated/models';
import {
  CONFIRM_MODAL_ID,
  FORM_RESPONSE_MODAL_ID,
} from 'common/common.constants';
import useFlag from '../../../utils/hooks/useFlag';
import { useFormResponseModal } from './FormResponseModal/useFormResponseModal';
import FormResponseModal from './FormResponseModal/FormResponseModal';

const { ACCEPTED, VENDORINPROGRESS } = RequestSummaryStatusEnum;

const FormFooter = ({ t }: TranslatePropsType) => {
  const {
    actions: { putRequest, showModal, hideModal },
    data: {
      currentForm: {
        displayName: questionnaireName,
        owner: { name: ownerName },
        permissions: { isFormReviewable, isFormSubmittable, isVRM },
        questionsFilledCount,
        questionsCount,
        request,
      },
      user,
      isFormValid,
      modalArray,
    },
  } = useContext(PageContext) as
    | FormPageContextType
    | SharedFormPageContextType;

  /*
  VENDOR_IN_PROGRESS -> ACCEPTED is added from ATL-1214 onwards.
  Feature Flagged in launchDarkly as special-form-acceptance
  */

  const hasSpecialFormAcceptance = useFlag(user, [
    ToggleEnum.SPECIAL_FORM_ACCEPTANCE,
  ]);

  const isInProgress = request && request.status === 'VENDOR_IN_PROGRESS';
  // Only show the special form acceptance button if is not Reviewable (ie normal flow) and only to the VRM
  const showCustomAccept =
    !isFormReviewable && hasSpecialFormAcceptance && isInProgress && isVRM;

  const isFormRespondModalOpen =
    modalArray && modalArray.some(curr => curr === FORM_RESPONSE_MODAL_ID);

  const { showConfirm, onCancel, onConfirm } = useConfirmModal(
    id => {
      if (putRequest) {
        putRequest(id, ACCEPTED);
      }
    },
    () => {
      if (showModal) {
        showModal(CONFIRM_MODAL_ID);
      }
    },
    () => {
      if (hideModal) {
        hideModal(CONFIRM_MODAL_ID);
      }
    },
  );

  const {
    showSendBack,
    onCancelSendBack,
    onSendBack,
    setSendBackValues,
  } = useFormResponseModal(
    ({ id, data }) => {
      if (putRequest) {
        putRequest(id, VENDORINPROGRESS, data);
      }
    },
    () => {
      if (showModal) {
        showModal(FORM_RESPONSE_MODAL_ID);
      }
    },
    () => {
      if (hideModal) {
        hideModal(FORM_RESPONSE_MODAL_ID);
      }
    },
  );

  return (
    <Sticky style={{ bottom: '0', zIndex: 1002 }}>
      <FooterWrapper>
        <Footer data-test="form-footer">
          <Metadata data-cy="form-footer-metadata">
            <ProgressBar
              chunk={questionsFilledCount}
              count={questionsCount}
              title={ReactHtmlParser(
                t('leftHTML', {
                  questionsFilledCount,
                  questionsCount,
                }),
              )}
            />
          </Metadata>

          {isFormSubmittable && !isFormValid && (
            <Metadata>{t('common:labels.fillAll') as string}</Metadata>
          )}
          <ButtonsWrapper>
            {putRequest && request && (
              <RequestEvaluation
                isReviewable={isFormReviewable}
                isSubmittable={isFormSubmittable}
                isValid={isFormValid}
                putRequest={putRequest}
                request={request}
                showConfirm={showConfirm}
                showSendBack={showSendBack}
              />
            )}

            {showCustomAccept && request && (
              <AcceptClose showConfirm={showConfirm} request={request} />
            )}
          </ButtonsWrapper>

          <ConfirmModal
            confirmText={t('yes') as string}
            cancelText={t('cancel') as string}
            title={ReactHtmlParser(t('areYouSure', { questionnaireName }))}
            text={
              showCustomAccept
                ? ReactHtmlParser(
                    t('infoText', {
                      questionnaireName,
                      ownerName,
                    }),
                  )
                : ReactHtmlParser(t('infoTextForInReview') as string)
            }
            onConfirm={onConfirm}
            onCancel={onCancel}
          />

          {isFormRespondModalOpen && (
            <FormResponseModal
              onCancelSendBack={onCancelSendBack}
              onSendBack={onSendBack}
              setSendBackValues={setSendBackValues}
            />
          )}
        </Footer>
      </FooterWrapper>
    </Sticky>
  );
};

export default withTranslation(['FormFooter', 'common'])(FormFooter);
