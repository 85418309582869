import { ApiCallActionType } from '../../store.types';
import { call, put } from 'redux-saga/effects';
import { RequestsFormType, RequestsStateType } from '../requests.types';
import { trackEvent } from 'utils/analytics';
import { startLoading } from '../../app/actions';
import { SCHEDULED_POST_ACTIONS } from './sendRequestPostActions';
import {
  getCommonScheduleRequestCallParams,
  updateSendRequestStateError,
  getEffectiveSourceTeam,
} from '../utils';
import { Uuid } from '../../../common/common.types';
import {
  ScheduledRequest,
  ScheduledRequestCreate,
} from '../../../generated/new';
import scheduledRequestsApi from '../../../api/new/scheduledRequestsApi';

export type RequestsScheduleActionType = ApiCallActionType<
  '@requests/API/SCHEDULE_REQUEST',
  ScheduledRequestCreate,
  ScheduledRequest[],
  any,
  RequestsStateType
>;

export const scheduleRequest = (
  form: RequestsFormType,
  standardIds: Uuid[],
): RequestsScheduleActionType => ({
  type: '@requests/API/SCHEDULE_REQUEST',
  payload: {
    endpointPath: callParams =>
      scheduledRequestsApi.postScheduledRequestsResource({
        payload: callParams,
      }),
    callParams: state => ({
      ...getCommonScheduleRequestCallParams(form, standardIds),
      sourceTeam: getEffectiveSourceTeam(
        form.sourceTeam,
        state.user.currentUser,
      ),
      targets: form.targets.map(target => target.value),
    }),
    selector: null,
    errorSelector: updateSendRequestStateError,
    preActions: [put(startLoading(1))],
    postActions: [
      () => call(() => trackEvent('Request', 'Create')),
      () => call(() => trackEvent('Schedule', 'Create')),
      ...SCHEDULED_POST_ACTIONS,
    ],
  },
});
