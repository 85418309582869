import { call, put } from 'redux-saga/effects';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { getFormWithoutReset } from './getFormWithoutReset';
import { startLoading } from '../../app/actions';
import { Uuid } from '../../../common/common.types';

export interface FormsUnassignFormDelegationCallParamsType {
  formId: Uuid;
}

export type FormsUnassignFormDelegationFunctionType = (formId: Uuid) => void;

export type FormsUnassignFormDelegationActionType = ApiCallActionType<
  '@forms/API/UNASSIGN_FORM_DELEGATION',
  FormsUnassignFormDelegationCallParamsType
>;

export const unassignFormDelegation = (
  formId: Uuid,
): FormsUnassignFormDelegationActionType => ({
  type: '@forms/API/UNASSIGN_FORM_DELEGATION',
  payload: {
    callParams: {
      formId,
    },
    endpointPath: ['forms', 'delegations', 'form', 'delete'],
    selector: null,
    preActions: [put(startLoading(2))],
    postActions: [
      () => put(getFormWithoutReset(formId)),
      () => call(() => trackEvent('Delegation', 'Remove - Form')),
    ],
  },
});
