/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssigneeCountMetadata,
    AssigneeCountMetadataFromJSON,
    AssigneeCountMetadataFromJSONTyped,
    AssigneeCountMetadataToJSON,
    AutoFillStats,
    AutoFillStatsFromJSON,
    AutoFillStatsFromJSONTyped,
    AutoFillStatsToJSON,
    AutocompleteExecution,
    AutocompleteExecutionFromJSON,
    AutocompleteExecutionFromJSONTyped,
    AutocompleteExecutionToJSON,
    DelegationNotificationStatsMetadata,
    DelegationNotificationStatsMetadataFromJSON,
    DelegationNotificationStatsMetadataFromJSONTyped,
    DelegationNotificationStatsMetadataToJSON,
    FormCategoryStats,
    FormCategoryStatsFromJSON,
    FormCategoryStatsFromJSONTyped,
    FormCategoryStatsToJSON,
    FormDetail,
    FormDetailFromJSON,
    FormDetailFromJSONTyped,
    FormDetailToJSON,
    FormFilterStats,
    FormFilterStatsFromJSON,
    FormFilterStatsFromJSONTyped,
    FormFilterStatsToJSON,
    FormShareMetadata,
    FormShareMetadataFromJSON,
    FormShareMetadataFromJSONTyped,
    FormShareMetadataToJSON,
    MappingRequest,
    MappingRequestFromJSON,
    MappingRequestFromJSONTyped,
    MappingRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormMetadata
 */
export interface FormMetadata {
    /**
     * Questions assignment statistics grouped by user
     * @type {Array<AssigneeCountMetadata>}
     * @memberof FormMetadata
     */
    assigneeStats?: Array<AssigneeCountMetadata>;
    /**
     * List of information about autocompletes in progress (if any).
     * After the autocomplete is done and answers are potentially filled, this array will
     * be empty again.
     * @type {Array<AutocompleteExecution>}
     * @memberof FormMetadata
     */
    autocompleteExecutions?: Array<AutocompleteExecution>;
    /**
     * Statistics about questions and responses that can be answered automatically
     * @type {AutoFillStats}
     * @memberof FormMetadata
     */
    autofillStats: AutoFillStats;
    /**
     * Categories (=sections) in which questions are grouped
     * @type {Array<FormCategoryStats>}
     * @memberof FormMetadata
     */
    categories?: Array<FormCategoryStats>;
    /**
     * List of currently sendable notifications about assigning a questions to somebody or
     * finishing of filling of assigned questions
     * @type {Array<DelegationNotificationStatsMetadata>}
     * @memberof FormMetadata
     */
    delegationNotificationStats?: Array<DelegationNotificationStatsMetadata>;
    /**
     * Statistics on how many questions would match after applying additional filter
     * @type {FormFilterStats}
     * @memberof FormMetadata
     */
    filterStats: FormFilterStats;
    /**
     * Form details
     * @type {FormDetail}
     * @memberof FormMetadata
     */
    form: FormDetail;
    /**
     * List of information about uploaded files with answers that are currently being parsed
     * (if any). After the parsing is done and answers are potentially filled, this array will
     * be empty again.
     * @type {Array<MappingRequest>}
     * @memberof FormMetadata
     */
    mappingRequests?: Array<MappingRequest>;
    /**
     * Number of question that match the applied filter (no filter specified -> number of all
     * questions in the form)
     * @type {number}
     * @memberof FormMetadata
     */
    questionsFilteredCount: number;
    /**
     * Information about how and by whom this form is shared
     * @type {FormShareMetadata}
     * @memberof FormMetadata
     */
    share?: FormShareMetadata;
}

export function FormMetadataFromJSON(json: any): FormMetadata {
    return FormMetadataFromJSONTyped(json, false);
}

export function FormMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assigneeStats': !exists(json, 'assignee_stats') ? undefined : ((json['assignee_stats'] as Array<any>).map(AssigneeCountMetadataFromJSON)),
        'autocompleteExecutions': !exists(json, 'autocomplete_executions') ? undefined : ((json['autocomplete_executions'] as Array<any>).map(AutocompleteExecutionFromJSON)),
        'autofillStats': AutoFillStatsFromJSON(json['autofill_stats']),
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(FormCategoryStatsFromJSON)),
        'delegationNotificationStats': !exists(json, 'delegation_notification_stats') ? undefined : ((json['delegation_notification_stats'] as Array<any>).map(DelegationNotificationStatsMetadataFromJSON)),
        'filterStats': FormFilterStatsFromJSON(json['filter_stats']),
        'form': FormDetailFromJSON(json['form']),
        'mappingRequests': !exists(json, 'mapping_requests') ? undefined : ((json['mapping_requests'] as Array<any>).map(MappingRequestFromJSON)),
        'questionsFilteredCount': json['questions_filtered_count'],
        'share': !exists(json, 'share') ? undefined : FormShareMetadataFromJSON(json['share']),
    };
}

export function FormMetadataToJSON(value?: FormMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_stats': value.assigneeStats === undefined ? undefined : ((value.assigneeStats as Array<any>).map(AssigneeCountMetadataToJSON)),
        'autocomplete_executions': value.autocompleteExecutions === undefined ? undefined : ((value.autocompleteExecutions as Array<any>).map(AutocompleteExecutionToJSON)),
        'autofill_stats': AutoFillStatsToJSON(value.autofillStats),
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(FormCategoryStatsToJSON)),
        'delegation_notification_stats': value.delegationNotificationStats === undefined ? undefined : ((value.delegationNotificationStats as Array<any>).map(DelegationNotificationStatsMetadataToJSON)),
        'filter_stats': FormFilterStatsToJSON(value.filterStats),
        'form': FormDetailToJSON(value.form),
        'mapping_requests': value.mappingRequests === undefined ? undefined : ((value.mappingRequests as Array<any>).map(MappingRequestToJSON)),
        'questions_filtered_count': value.questionsFilteredCount,
        'share': FormShareMetadataToJSON(value.share),
    };
}


