import { Button, ButtonType } from '../Button/Button';
import { ChildrenWrapper, DropdownWrapper, Wrapper } from './Dropdown.styles';
import React, { ElementType, ReactNode, useRef, useState } from 'react';
import {
  faChevronDown,
  faChevronUp,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOnClickOutside } from 'utils/useOnClickOutside';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface DropdownState {
  close: () => void;
}

export interface DropdownPropsType {
  children: (state: DropdownState) => ReactNode;
  childrenWrapper?: ElementType<any>;
  className?: string;
  isLoading?: boolean;
  isRelative?: boolean;
  text: string;
  hasIcon?: boolean;
  toggle?: ElementType<any>;
}

export const Dropdown = ({
  className,
  text,
  children,
  childrenWrapper,
  isLoading = false,
  isRelative = true,
  hasIcon = true,
  toggle,
  // prettier-ignore
  /* tslint:disable */
  ...props
}: /* tslint:enable */
ButtonType & DropdownPropsType) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const state = { close: handleClose };
  const ToggleComponent = toggle || Button;
  const ChildrenWrapperComponent = childrenWrapper || ChildrenWrapper;

  const faChevronUpProp = faChevronUp as IconProp;
  const faChevronDownProp = faChevronDown as IconProp;
  const faSpinnerProp = faSpinner as IconProp;

  let displayIcon = faChevronDownProp;
  if (isOpen) {
    displayIcon = faChevronUpProp;
  }

  useOnClickOutside(ref, handleClose);
  return (
    <Wrapper isRelative={isRelative} ref={ref}>
      <ToggleComponent
        {...props}
        disabled={isLoading}
        onClick={() => setOpen(!isOpen)}
      >
        <DropdownWrapper className={className} data-test="dropdown-button">
          {text}

          {hasIcon && !isLoading && <FontAwesomeIcon icon={displayIcon} />}

          {isLoading && <FontAwesomeIcon icon={faSpinnerProp} spin />}
        </DropdownWrapper>
      </ToggleComponent>

      {isOpen && (
        <ChildrenWrapperComponent>{children(state)}</ChildrenWrapperComponent>
      )}
    </Wrapper>
  );
};
