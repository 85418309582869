import React, { PureComponent } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { contains, always } from 'ramda';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ActionType, StateType } from 'store/store.types';
import { hideModal } from './store/actions/hideModal';
import {
  Background,
  CloseButton,
  Header,
  HeaderContent,
  ModalPane,
} from './Modal.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ModalPropsType {
  backdropColor?: string;
  className?: string;
  closeWithButtonsOnly?: boolean;
  custom?: boolean;
  modalId: string;
  onClose?: () => void;
  title?: string | JSX.Element;
  children: React.ReactNode;
}

interface MapStateType {
  isOpen: boolean;
  order: number;
}

interface MapDispatchType {
  hide: () => void;
}

type ModalType = MapStateType & MapDispatchType & ModalPropsType;

class Modal extends PureComponent<ModalType> {
  handleClose = (e: React.MouseEvent<HTMLElement>) => {
    const isCloseButton = contains('close-button', e.currentTarget.className);
    const isCorrectEventTarget = e.target === e.currentTarget || isCloseButton;

    if (isCorrectEventTarget) {
      const {
        closeWithButtonsOnly = false,
        hide,
        onClose = always(null),
      } = this.props;

      if (!closeWithButtonsOnly || isCloseButton) {
        onClose();
        hide();
      }
    }
  };

  render() {
    const {
      backdropColor,
      className,
      children,
      custom,
      isOpen,
      order,
      title,
    } = this.props;

    return (
      isOpen && (
        <Background
          backdropColor={backdropColor}
          order={order}
          onClick={this.handleClose}
        >
          {custom ? (
            <>{children}</>
          ) : (
            <ModalPane className={className}>
              {title && (
                <Header>
                  <HeaderContent>{title}</HeaderContent>
                  <CloseButton
                    data-test="modal-close-button"
                    className="close-button"
                    onClick={this.handleClose}
                  >
                    <FontAwesomeIcon icon={faTimes as IconProp} />
                  </CloseButton>
                </Header>
              )}
              {children}
            </ModalPane>
          )}
        </Background>
      )
    );
  }
}

const mapState = (state: StateType, ownProps: ModalPropsType) => ({
  isOpen: contains(ownProps.modalId, state.modals.opened),
  order: state.modals.opened.indexOf(ownProps.modalId),
});

const mapDispatch = (
  dispatch: Dispatch<ActionType>,
  ownProps: ModalPropsType,
) => ({
  hide: () => dispatch(hideModal(ownProps.modalId)),
});

export default connect<MapStateType, MapDispatchType, ModalPropsType>(
  mapState,
  mapDispatch,
)(Modal);
