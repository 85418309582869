import React, { useContext, useState } from 'react';
import ReactSVG from 'react-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  ButtonWrapper,
  CloseButton,
  ContentWrapper,
  CTA,
  CTAButton,
  Description,
  LogoWrapper,
  UploadLandingWrapper,
  Wrapper,
} from './Cobranding.styles';
import { SETTINGS } from '../../../../routes';
import UploadLanding from './UploadLanding';
import { PageContext } from 'pages/Page';
import { SendRequestContextType } from 'pages/SendRequestPage';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type CobrandingProps = {
  closeDialog: () => void;
};

const BrandingStep = ({
  step,
  updateStep,
  closeDialog,
}: {
  step: number;
  updateStep: (value: number) => void;
  closeDialog: () => void;
}) => {
  const {
    actions: { cobrandLogo, setSettings },
  } = useContext(PageContext) as SendRequestContextType;

  const { t } = useTranslation('Cobranding');

  const [cobrandingFile, updateCobrandingFile] = useState(undefined);

  const saveButtonHandler = () => {
    if (cobrandingFile && cobrandLogo) {
      // upload the cobrand logo
      cobrandLogo(cobrandingFile);

      // Set the user's setting showCobranding to False
      setSettings({ showCoBranding: false });
      updateStep(3);
    }
  };

  switch (step) {
    case 1:
      return (
        <>
          <div>
            <CTA>{t('information') as string}</CTA>
            <Description>{t('descriptionText') as string}</Description>
          </div>
          <CTAButton
            data-pendo="upload-company-logo"
            onClick={() => updateStep(2)}
          >
            {t('uploadLogoButton') as string}
          </CTAButton>
        </>
      );
    case 2:
      return (
        <>
          <div>
            <CTA>{t('uploadLogo') as string}</CTA>
            <Description>{t('uploadLogoDescription') as string}</Description>
          </div>
          <UploadLandingWrapper>
            <UploadLanding updateCobrandingLogoFile={updateCobrandingFile} />
          </UploadLandingWrapper>
          <ButtonWrapper>
            <CTAButton small onClick={() => updateStep(1)}>
              {t('cancel') as string}
            </CTAButton>
            <CTAButton
              data-pendo="save-company-logo"
              small
              blue
              onClick={saveButtonHandler}
            >
              {t('save') as string}
            </CTAButton>
          </ButtonWrapper>
        </>
      );
    case 3:
      return (
        <>
          <div>
            <CTA>{t('success') as string}</CTA>
            <Description>
              {t('successMessage') as string}{' '}
              <Link to={SETTINGS}>{t('atlas') as string}</Link>
              {'.'}
            </Description>
          </div>
          <CTAButton onClick={() => closeDialog()}>
            {t('dismiss') as string}
          </CTAButton>
        </>
      );
    default:
      return null;
  }
};

const Cobranding: React.FC<CobrandingProps> = ({ closeDialog }) => {
  const [step, updateStep] = useState(1);

  return (
    <Wrapper>
      <LogoWrapper>
        <div>
          <ReactSVG
            style={{
              width: 200,
            }}
            src="/atlas-product-logo.svg"
          />
        </div>
      </LogoWrapper>
      <ContentWrapper>
        <BrandingStep
          step={step}
          updateStep={updateStep}
          closeDialog={closeDialog}
        />
      </ContentWrapper>
      <CloseButton
        data-test="modal-close-button"
        data-pendo="dismiss-show-cobranding"
        className="close-button"
        onClick={() => closeDialog()}
      >
        <FontAwesomeIcon icon={faTimes as IconProp} />
      </CloseButton>
    </Wrapper>
  );
};

export default Cobranding;
