import React, { useEffect, useState } from 'react';
import { Wrapper } from './EmailRecipient.styles';
import DueDate from './DueDate';
import EmailReceivers from './EmailReceivers';
import Message from './Message';
import Reminder from './Reminder';
import Schedule from './Schedule';
import Cobranding from './Cobranding';
import FirstContact from './FirstContact';
import TeamSelector from './Internal/TeamSelector';
import useFlag from 'utils/hooks/useFlag';
import { DueDateWrapper } from './Recipient.styles';
import { Flex } from 'common/common.styles';
import {
  RequestsSendFormRedirectEnum,
  SetFormValueFunctionType,
} from 'store/requests/actions';
import { withTranslation } from 'react-i18next';
import {
  CurrentUserType,
  LanguageType,
  MailingListItemType,
  RecurrenceItemType,
  ReminderItemType,
  TargetItemType,
  ToggleEnum,
  TranslatePropsType,
  ValueLabelType,
} from 'common/common.types';
import { SubmitRequestButtonPartialProps } from './sharedTypes';
import Submit from '../Submit';
import { Team } from 'generated/new';
import { SetSettingsFunctionType } from 'store/settings/actions/setSettings';
import { scheduled } from 'rxjs';
import LinkActionDisabledNotification from './LinkActionDisabledNotification';

interface EmailRecipientPropsType extends TranslatePropsType {
  dueDate?: Date | null;
  isSourceTeamSelectorVisible: boolean;
  mailingList: MailingListItemType[];
  recurrence: RecurrenceItemType;
  reminder: ReminderItemType;
  sendDate: Date;
  setValue: SetFormValueFunctionType;
  submitButtonProps: SubmitRequestButtonPartialProps;
  targets: TargetItemType[];
  sourceTeam?: ValueLabelType<string>;
  user: CurrentUserType;
  userTeams: Team[];
  setSettings: SetSettingsFunctionType;
  showCoBranding?: boolean;
  copyToClipboard: (text: string) => void;
  getUserContactSummary: (email: string) => void;
  language: LanguageType;
}

const EmailRecipient = ({
  dueDate,
  isSourceTeamSelectorVisible,
  mailingList,
  recurrence,
  reminder,
  sendDate,
  setValue,
  sourceTeam,
  setSettings,
  showCoBranding,
  copyToClipboard,
  submitButtonProps: {
    appealCredits,
    balance,
    handleSubmit,
    isFormValid,
    sendRequestError,
    selectedStandardsCount,
  },
  t,
  targets,
  user,
  userTeams,
  getUserContactSummary,
  language,
}: EmailRecipientPropsType) => {
  const generateLinkWhileSendingQuestionnaires = useFlag(user, [
    ToggleEnum.GENERATE_LINK_WHILE_SENDING_QUESTIONNAIRES,
  ]);
  const hasCobrandingEnabled = useFlag(user, [ToggleEnum.EMAIL_COBRANDING]);
  const [displayCobranding, updateDisplayCobranding] = useState(
    hasCobrandingEnabled && showCoBranding,
  );
  const cost = targets.length * selectedStandardsCount;
  const [disableLinkAction, setDisableLinkAction] = useState(false);

  const closeDialog = () => {
    updateDisplayCobranding(false);
    setSettings({ showCoBranding: false });
  };

  const [dismissFirstContactInfo, updateDismissFirstContactInfo] = useState(
    false,
  );

  const closeDialogFirstContact = () => {
    updateDismissFirstContactInfo(true);
  };

  useEffect(() => {
    if (recurrence.type || recurrence.unit || sendDate > new Date()) {
      setDisableLinkAction(true);
      return;
    }
    setDisableLinkAction(false);
  }, [sendDate, recurrence]);

  return (
    <Wrapper>
      {!dismissFirstContactInfo && (
        <FirstContact
          closeDialog={closeDialogFirstContact}
          copyToClipboard={copyToClipboard}
        />
      )}
      {displayCobranding && <Cobranding closeDialog={closeDialog} />}
      <Flex>
        <EmailReceivers
          mailingList={mailingList}
          setValue={setValue}
          targets={targets}
          getUserContactSummary={getUserContactSummary}
          user={user}
        />

        <Schedule
          recurrence={recurrence}
          sendDate={sendDate}
          setValue={setValue}
          language={language}
        />
      </Flex>

      <Flex>
        <DueDateWrapper>
          <DueDate dueDate={dueDate} setValue={setValue} />

          {dueDate && <Reminder reminder={reminder} setValue={setValue} />}
        </DueDateWrapper>
        {isSourceTeamSelectorVisible && (
          <TeamSelector
            selected={sourceTeam}
            setValue={setValue}
            teams={userTeams}
          />
        )}
      </Flex>

      <Message setValue={setValue} placeholder={t('placeholder') as string} />
      {disableLinkAction && <LinkActionDisabledNotification />}
      <Flex justifyContent={'flex-end'}>
        {generateLinkWhileSendingQuestionnaires && (
          <Submit
            appealCredits={appealCredits}
            balance={balance}
            cost={cost}
            error={sendRequestError}
            handleSubmit={() =>
              handleSubmit(RequestsSendFormRedirectEnum.LINK, false)
            }
            isFormValid={isFormValid}
            disableAction={disableLinkAction}
            isGeneratingLink={true}
            itemsCount={selectedStandardsCount}
            displayInfo={false}
            color={'tealInverted'}
          />
        )}

        <Submit
          appealCredits={appealCredits}
          balance={balance}
          cost={cost}
          error={sendRequestError}
          handleSubmit={handleSubmit}
          isFormValid={isFormValid}
          isGeneratingLink={false}
          itemsCount={selectedStandardsCount}
        />
      </Flex>
    </Wrapper>
  );
};

export default withTranslation('SendRequest')(EmailRecipient);
