export const colorPalette: string[] = [
  '#6babae',
  '#fd9093',
  '#e6c664',
  '#56a589',
  '#b4c6c8',
];

const stringToColor = (s: string): string =>
  colorPalette[s.toLowerCase().charCodeAt(0) % colorPalette.length];

export default stringToColor;
