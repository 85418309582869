import styled from 'styled-components';

export const Backdrop = styled.div`
  background-color: #f2f2f2;
`;

export const Metadata = styled.div`
  padding-left: 30px;
`;

export const Questions = styled.div`
  padding: 20px 0;
  position: relative;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.atlas.text.secondary};
  font-size: ${({ theme }) => theme.basic.fontSizes.mini};
  line-height: 1.7;
  margin-bottom: 4px;
`;
