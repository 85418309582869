import styled from 'styled-components';

export const ChildrenWrapper = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.inputs.dropdown.background};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.inputs.dropdown.borderColor};
  z-index: 250;
  padding: 5px 0 3px;
  margin-top: 8px;
`;

export const DropdownWrapper = styled.div`
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div<{ isRelative: boolean }>`
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'static')};
`;
