/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    ProxyShortSummary,
    ProxyShortSummaryFromJSON,
    ProxyShortSummaryFromJSONTyped,
    ProxyShortSummaryToJSON,
    UserDetail,
    UserDetailFromJSON,
    UserDetailFromJSONTyped,
    UserDetailToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProxySummary
 */
export interface ProxySummary {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof ProxySummary
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof ProxySummary
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ProxySummary
     */
    formId: string;
    /**
     * 
     * @type {string}
     * @memberof ProxySummary
     */
    formRole: ProxySummaryFormRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ProxySummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProxySummary
     */
    status: ProxySummaryStatusEnum;
    /**
     * 
     * @type {UserDetail}
     * @memberof ProxySummary
     */
    user: UserDetail;
}

export function ProxySummaryFromJSON(json: any): ProxySummary {
    return ProxySummaryFromJSONTyped(json, false);
}

export function ProxySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProxySummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'formId': json['form_id'],
        'formRole': json['form_role'],
        'id': json['id'],
        'status': json['status'],
        'user': UserDetailFromJSON(json['user']),
    };
}

export function ProxySummaryToJSON(value?: ProxySummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'form_id': value.formId,
        'form_role': value.formRole,
        'id': value.id,
        'status': value.status,
        'user': UserDetailToJSON(value.user),
    };
}

/**
* @export
* @enum {string}
*/
export enum ProxySummaryFormRoleEnum {
    OWNER = 'OWNER',
    VENDOR = 'VENDOR',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY',
    OTHER = 'OTHER'
}
/**
* @export
* @enum {string}
*/
export enum ProxySummaryStatusEnum {
    ACTIVE = 'ACTIVE',
    REVOKED = 'REVOKED'
}


