import styled from 'styled-components';
import { Button as FilterBarButton } from './FilterBar.styles';

export const Button = styled(FilterBarButton)`
  background-color: transparent;
  height: 42px;
  display: flex;
  justify-content: center;
  border: none;
  flex-direction: column;
`;

export const Text = styled.div`
  ${({ theme }) => theme.basic.fontWeights.normal}
  margin: 12px 10px 0 0;
`;
