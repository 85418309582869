import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px 70px 30px 30px;
  width: 550px;
`;

export const Content = styled.div`
  padding: 10px 0px;
`;

export const ButtonWrapper = styled.div`
  padding: 15px 0 0;
  display: flex;
  justify-content: space-between;
`;

export const Closer = styled.div`
  position: absolute;
  right: 45px;
  top: 33px;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  margin: 20px 0;

  label {
    padding: 5px 0;
    display: block;
  }
`;
