import arrayToIdDict from '../arrayToIdDict';
import { AssigneeCountMetadata } from 'generated/models/AssigneeCountMetadata';
import { FormsStateType } from 'store/forms/forms.reducer';
import { pathOr } from 'ramda';
import { FormsAssigneesFilterDict } from '../filters/forms.filters';

export const parseAssignees = (
  asigneesData?: AssigneeCountMetadata[],
  state?: FormsStateType,
): FormsAssigneesFilterDict => {
  if (state && asigneesData) {
    return arrayToIdDict(
      asigneesData.map(
        ({ count, user: { display_name: label, id, is_active } }) => ({
          count,
          label,
          id,
          isActive: is_active,
          isEnabled: pathOr(false, [id, 'isEnabled'])(state.filters.assignees),
        }),
      ),
    );
  } else {
    return {};
  }
};
