/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
    ResponseFilling,
    ResponseFillingFromJSON,
    ResponseFillingFromJSONTyped,
    ResponseFillingToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResponseFillingResponse
 */
export interface ResponseFillingResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof ResponseFillingResponse
     */
    msg: string;
    /**
     * 
     * @type {ResponseFilling}
     * @memberof ResponseFillingResponse
     */
    entry: ResponseFilling;
}

export function ResponseFillingResponseFromJSON(json: any): ResponseFillingResponse {
    return ResponseFillingResponseFromJSONTyped(json, false);
}

export function ResponseFillingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseFillingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': ResponseFillingFromJSON(json['entry']),
    };
}

export function ResponseFillingResponseToJSON(value?: ResponseFillingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': ResponseFillingToJSON(value.entry),
    };
}


