import styled, { css } from 'styled-components';
import { VisualState } from './Textarea.types';

interface TextContainerType {
  disabled?: boolean;
  height?: number;
  visualState: VisualState;
}

export const TextContainer = styled.textarea<TextContainerType>`
  width: 100%;
  resize: none;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  padding: 8px 10px;
  position: relative;
  overflow-y: auto;
  line-height: 23px;
  min-height: ${({ height }) => (height ? `${height}px` : '38px')};
  ${({ theme }) => theme.fonts.regular.normal}

  ${({
    disabled: isDisabled,
    theme: {
      inputs: {
        textarea: { enabled, disabled },
      },
      basic: { colors },
    },
    visualState,
  }) =>
    isDisabled
      ? css`
          background-color: ${disabled.background};
          border-color: ${disabled.border};
          color: ${disabled.color};
          cursor: default;

          &::placeholder {
            color: ${disabled.placeholder};
          }
        `
      : css`
          background-color: ${enabled.background};
          border-color: ${visualState === 'error'
            ? colors.error
            : enabled.border};
          color: ${enabled.color};
          cursor: text;

          &:hover {
            border-color: ${visualState === 'error'
              ? colors.error
              : enabled.borderHover};
          }

          &::placeholder {
            color: ${enabled.placeholder};
          }
        `}
`;
