import { ApiCallActionType } from '../../store.types';
import { FormCreatorStateType } from '../formCreator.types';
import { put } from 'redux-saga/effects';
import { FormDefinitionSummaryDraftExtended } from '../../../generated/models/FormDefinitionSummaryDraftExtended';
import { parseFormDefinitionSummaryDraftExtended } from 'utils/parsers/formDefinition.parsers';
import { push } from 'connected-react-router';
import formatRoute, { FORM_CREATOR } from '../../../routes';
import { startLoading } from '../../app/actions';
import { setCurrentForm } from '../utils';
import i18n from 'i18next';
import { getAvailableStandardName } from '../utils/getAvailableStandardName';

export type CreateEmptyFormFunctionType = (standardName?: string) => void;

export interface CreateEmptyFormCallParamsType {
  standard_name: string;
}

export type CreateEmptyFormActionType = ApiCallActionType<
  '@formCreator/API/CREATE_EMPTY_FORM',
  CreateEmptyFormCallParamsType,
  FormDefinitionSummaryDraftExtended,
  never,
  FormCreatorStateType
>;

export const createEmptyForm = (
  standardName?: string,
): CreateEmptyFormActionType => ({
  type: '@formCreator/API/CREATE_EMPTY_FORM',
  payload: {
    callParams: {
      standard_name:
        standardName ||
        i18n.t(
          'FormDefinitions:emptyName',
          getAvailableStandardName(i18n.t('FormDefinitions:untitled')),
        ),
    },
    endpointPath: ['definitions', 'drafts', 'post'],
    selector: (data, state): Partial<FormCreatorStateType> =>
      setCurrentForm(
        state.formCreator,
        parseFormDefinitionSummaryDraftExtended(data),
      ),
    postActions: [
      // This is needed to ensure the Loading screen disappears.
      // We dispatch it in postActions because we're not interested in showing
      // the loading screen in the previous page (we will handle it in a
      // different way, say show spinner on button or something...)
      () => put(startLoading(1)),
      data => put(push(formatRoute(FORM_CREATOR, { id: data.id }))),
    ],
  },
});
