import * as R from 'ramda';
import Autosuggest from 'react-autosuggest';
import React, { PureComponent } from 'react';
import uuid from 'utils/uuid';
import { Container, Name } from '../Tag/Tag.styles';
import { Input, WrapperAbs, WrapperRel } from './Autocomplete.styles';

export interface SuggestionType {
  id: any;
  name: string;
}

export interface AutocompletePropsType {
  suggestions: SuggestionType[];
  onAdd: (item: string) => any;
  placeholder?: string;
}

class Autocomplete extends PureComponent<AutocompletePropsType> {
  state = {
    value: '',
    currentSuggestions: [],
  };

  textInputId = 'autoCompleteInput-' + uuid();

  addItem = (name?: string) => {
    if (this.state.value !== '') {
      const { onAdd } = this.props;
      const newName = name || this.state.value;

      onAdd(newName);

      this.setState({
        value: '',
      });
    }
  };

  focus = () => {
    const input = document.getElementById(this.textInputId);
    if (input) {
      // @ts-ignore
      input.focus();
    }
  };

  getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.props.suggestions.filter(suggestion =>
          R.contains(inputValue, suggestion.name.toLowerCase()),
        );
  };

  getSuggestionValue = (suggestion: SuggestionType) => suggestion.name;

  onChange = (event: any, { newValue }: { newValue: string }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    this.setState({
      currentSuggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      currentSuggestions: [],
    });
  };

  onSuggestionSelected = (
    event: any,
    { suggestionValue }: { suggestionValue: string },
  ) => {
    this.addItem(suggestionValue);
  };

  renderInputComponent = (inputProps: any) => (
    <Input
      id={this.textInputId}
      {...inputProps}
      onKeyPress={(e: any) => {
        if (R.contains(e.key, ['Enter'])) {
          this.addItem();
        }
      }}
    />
  );

  renderSuggestion = (suggestion: SuggestionType) => (
    <Container
      name={suggestion.name}
      style={{
        cursor: 'pointer',
        marginTop: 2,
      }}
    >
      <Name editable={false}>{suggestion.name}</Name>
    </Container>
  );

  render() {
    const { value, currentSuggestions } = this.state;

    return (
      <WrapperRel>
        <WrapperAbs>
          <Autosuggest
            suggestions={currentSuggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            onSuggestionSelected={this.onSuggestionSelected}
            renderSuggestion={this.renderSuggestion}
            renderInputComponent={this.renderInputComponent}
            inputProps={{
              placeholder: this.props.placeholder || 'Add ...',
              value: value,
              onBlur: () => this.setState({ value: '' }),
              onChange: this.onChange,
            }}
          />
        </WrapperAbs>
      </WrapperRel>
    );
  }
}

export default Autocomplete;
