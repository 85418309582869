/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormTrackSeenResource,
    FormTrackSeenResourceFromJSON,
    FormTrackSeenResourceFromJSONTyped,
    FormTrackSeenResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormSeen
 */
export interface FormSeen {
    /**
     * restrict scope to a single block (optional)
     * @type {string}
     * @memberof FormSeen
     */
    block?: FormSeenBlockEnum;
    /**
     * restrict scope to a single question (optional)
     * @type {string}
     * @memberof FormSeen
     */
    questionId?: string;
    /**
     * mark form as read/unread (optional)
     * @type {boolean}
     * @memberof FormSeen
     */
    read?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormSeen
     */
    formId?: string;
}

export function FormSeenFromJSON(json: any): FormSeen {
    return FormSeenFromJSONTyped(json, false);
}

export function FormSeenFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormSeen {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'block': !exists(json, 'block') ? undefined : json['block'],
        'questionId': !exists(json, 'question_id') ? undefined : json['question_id'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'formId': !exists(json, 'form_id') ? undefined : json['form_id'],
    };
}

export function FormSeenToJSON(value?: FormSeen | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block': value.block,
        'question_id': value.questionId,
        'read': value.read,
        'form_id': value.formId,
    };
}

/**
* @export
* @enum {string}
*/
export enum FormSeenBlockEnum {
    Attachments = 'attachments',
    Comments = 'comments',
    SscData = 'ssc_data',
    History = 'history',
    All = 'all'
}


