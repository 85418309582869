import { isEmpty, symmetricDifference } from 'ramda';

/**
 * Check if two arrays contains same value regardless their position.
 * [a, b, c] is same as [c, b, a]
 *
 * @param a Array of values
 * @param b Array of values
 */
const isSameArray = <T>(a: T[], b: T[]) =>
  a.length === b.length && isEmpty(symmetricDifference(a, b));

export default isSameArray;
