import styled from 'styled-components';
import { Note } from './EmailReceivers.styles';

export const Wrapper = styled.div`
  width: 513px;
  margin-right: 50px;
`;

export const ErrorNote = styled(Note)`
  color: ${({ theme }) => theme.basic.colors.error};
`;
