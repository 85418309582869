/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestFindingDeleted
 */
export interface RequestFindingDeleted {
    /**
     * 
     * @type {boolean}
     * @memberof RequestFindingDeleted
     */
    deleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestFindingDeleted
     */
    id: string;
}

export function RequestFindingDeletedFromJSON(json: any): RequestFindingDeleted {
    return RequestFindingDeletedFromJSONTyped(json, false);
}

export function RequestFindingDeletedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFindingDeleted {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleted': json['deleted'],
        'id': json['id'],
    };
}

export function RequestFindingDeletedToJSON(value?: RequestFindingDeleted | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deleted': value.deleted,
        'id': value.id,
    };
}


