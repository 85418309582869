import React from 'react';
import {
  AverageChartRecordType,
  TranslatePropsType,
} from 'common/common.types';
import { withTranslation } from 'react-i18next';
import ScoreRecord from './ScoreRecord';
import { scoreScale } from './ScoreChart.styles';
import { getBarWidth } from '../utils';
import ReactHtmlParser from 'react-html-parser';
import { AverageGauge } from '../Insights.styles';

interface CompletionAveragePropsType extends TranslatePropsType {
  average: AverageChartRecordType;
}

const CompletionAverage = ({
  average: { companies, count, maximum, sum, total },
  t,
}: CompletionAveragePropsType) => {
  const hasScore = count > 0;
  const score = Math.round(sum / count);

  return (
    <AverageGauge title={ReactHtmlParser(t('averageCharts.completionTime'))}>
      <ScoreRecord
        color={scoreScale(100 - score).hex()}
        name={t('averageScoreName', { count: total })}
        title={t('averageScoreTitle', { count: companies.size })}
        width={getBarWidth(maximum, score, 90)}
      >
        {hasScore ? score : (t('na') as string)}
      </ScoreRecord>
    </AverageGauge>
  );
};

export default withTranslation('Insights')(CompletionAverage);
