/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryDelegationStats,
    CategoryDelegationStatsFromJSON,
    CategoryDelegationStatsFromJSONTyped,
    CategoryDelegationStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface DelegationDeleted
 */
export interface DelegationDeleted {
    /**
     * 
     * @type {CategoryDelegationStats}
     * @memberof DelegationDeleted
     */
    categoryStats: CategoryDelegationStats;
    /**
     * 
     * @type {boolean}
     * @memberof DelegationDeleted
     */
    deleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof DelegationDeleted
     */
    id: string;
}

export function DelegationDeletedFromJSON(json: any): DelegationDeleted {
    return DelegationDeletedFromJSONTyped(json, false);
}

export function DelegationDeletedFromJSONTyped(json: any, ignoreDiscriminator: boolean): DelegationDeleted {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryStats': CategoryDelegationStatsFromJSON(json['category_stats']),
        'deleted': json['deleted'],
        'id': json['id'],
    };
}

export function DelegationDeletedToJSON(value?: DelegationDeleted | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category_stats': CategoryDelegationStatsToJSON(value.categoryStats),
        'deleted': value.deleted,
        'id': value.id,
    };
}


