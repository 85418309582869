import { RequestType, TranslatePropsType } from 'common/common.types';
import Grade from '../../common/Grade/Grade';
import { Clickable, Email, Unverified } from './Bar.styles';
import { COMPANIES } from '../../../routes';
import React from 'react';
import { withTranslation } from 'react-i18next';

interface RequestTitlePropsType extends TranslatePropsType {
  isVRM: boolean;
  navigate: (url: string) => void;
  request: RequestType;
}

const RequestTitle = ({
  isVRM,
  navigate,
  request: {
    isInternal,
    meta: { isVerified, sender, receiver },
    source,
    target,
    target: { id },
  },
  t,
}: RequestTitlePropsType) => {
  const companyName = isVRM ? target.name : source.name;
  const emails = isVRM ? receiver : sender;

  return (
    <div data-cy="questionnaireTitle">
      {isVRM ? (
        isVerified ? (
          <>
            {isInternal ? (
              <>
                <Grade url={'/icons/internal.svg'} />
                <strong>{companyName}</strong>
              </>
            ) : (
              <>
                <Grade url={target.gradeUrl} />
                <Clickable onClick={() => navigate(`${COMPANIES}/${id}`)}>
                  <strong>{companyName}</strong>
                </Clickable>
              </>
            )}
          </>
        ) : (
          <>
            <Grade url={'/icons/factor_unknown.svg'} />
            <Unverified
              onClick={() => navigate(`${COMPANIES}/${id}`)}
              receiver={receiver}
            />
          </>
        )
      ) : (
        <>
          {isInternal && <Grade url={'/icons/internal.svg'} />}
          <strong>{companyName}</strong>
        </>
      )}

      <Email>
        {t(isVRM ? 'sent' : 'received') as string}

        {emails}
      </Email>
    </div>
  );
};

export default withTranslation('FormHeader')(RequestTitle);
