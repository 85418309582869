import { useState, useLayoutEffect } from 'react';

const useOpenDownwards = (
  ref: React.MutableRefObject<any>,
  isComponentVisible: boolean,
  componentHeight: number,
  extraPadding: number = 5,
) => {
  const [openDownwards, setOpenDownwards] = useState<boolean>(false);

  /**
   * Effect used for calculation which way the menu should be opened
   */
  useLayoutEffect(() => {
    if (!isComponentVisible) {
      // Reset to default, otherwise an overflowing menu would
      // incorrectly open downwards every second time
      setOpenDownwards(true);
    }

    if (isComponentVisible && ref.current !== null) {
      const menuHeightPadded = componentHeight + extraPadding; // height of menu plus small padding

      const menuRect = ref.current.getBoundingClientRect();

      const windowHeight = window.innerHeight; // window/viewport height
      const menuTopRelativeToWindow = menuRect.top; // top of menu relative to window/viewport
      const pageYOffset = window.pageYOffset; // how much is window/viewport scrolled from top of document
      const menuTopRelativeToBody = pageYOffset + menuTopRelativeToWindow; // top of menu relative to body

      const bodyHeight = document.body.getBoundingClientRect().height; // full body height
      const footerHeight = 111; // TODO: maybe get this from some configuration

      const overflowsWindow =
        windowHeight - menuTopRelativeToWindow < menuHeightPadded;
      const overflowsFooter =
        bodyHeight - menuTopRelativeToBody - footerHeight < menuHeightPadded;

      if (overflowsWindow || overflowsFooter) {
        setOpenDownwards(false);
      } else {
        setOpenDownwards(true);
      }
    }
  }, [isComponentVisible, componentHeight, ref, extraPadding]);

  return openDownwards;
};

export default useOpenDownwards;
