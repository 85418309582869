import styled from 'styled-components';
import colors from 'common/common.colors';

export const Wrapper = styled.div`
  ${({ theme }) => theme.fonts.regular.mini}
  color: ${colors.basic.grayDark};
  background-color: ${colors.basic.white};
  position: relative;
  width: 100%;
  text-align: right;
  padding: 0 24px 12px;
  margin-top: -16px;
  line-height: 1.5;
`;
