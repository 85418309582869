import baseTheme from '../light';

export default {
  ...baseTheme,
  nav: {
    ...baseTheme.nav,
    button: {
      bg: '#3274C8',
      text: 'white',
    },
    icon: {
      color: '#7F9FE1',
    },
    logo: {
      src: '/whitelabel/aig/AIG-whitelogo.svg',
      height: '50px',
      marginRight: '10px',
      marginLeft: '10px',
    },
  },
  header: {
    backgroundImage: '/whitelabel/aig/bg@2x.png',
  },
};
