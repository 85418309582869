import * as R from 'ramda';
import React from 'react';
import { Badge } from './Badge';
import { Button } from './Button';
import { Buttons, Heading, SwitcherWrapper } from './Switcher.styles';

export interface OptionType {
  count?: number;
  key?: string;
  title: string;
}

interface SwitcherPropsType {
  currentSwitcherOption: string;
  options: OptionType[];
  switchView: (view: string) => void;
}

const Switcher = ({
  currentSwitcherOption,
  options,
  switchView,
}: SwitcherPropsType) => {
  const validOptions = R.filter(
    option => option.count === undefined || option.count > 0,
    options,
  );

  if (
    (currentSwitcherOption === '' ||
      !R.any(option => option.key === currentSwitcherOption, validOptions)) &&
    validOptions.length > 0
  ) {
    switchView(validOptions[0].key || validOptions[0].title);
  }

  const renderHeading = () => (
    <Heading>
      {validOptions[0].title}

      {validOptions[0].count !== undefined && (
        <Badge color={'seaGreen'}>{validOptions[0].count}</Badge>
      )}
    </Heading>
  );

  const renderButtons = () =>
    validOptions.map((option: OptionType) => {
      const key = option.key || option.title;
      const isSelected = currentSwitcherOption === key;
      return (
        <Button
          key={key}
          onClick={() => switchView(key)}
          color={isSelected ? 'seaGreen' : 'light'}
        >
          {option.title}

          {option.count !== undefined && (
            <Badge color={isSelected ? 'white' : 'seaGreen'}>
              {option.count}
            </Badge>
          )}
        </Button>
      );
    });

  return (
    <SwitcherWrapper>
      {validOptions.length === 1 ? (
        renderHeading()
      ) : (
        <Buttons>{renderButtons()}</Buttons>
      )}
    </SwitcherWrapper>
  );
};

export default Switcher;
