import {
  Uuid,
  QuestionDefinitionSummaryType,
  FormDefinitionDraftCategoryStatsType,
} from '../../../common/common.types';
import uuid from 'utils/uuid';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import update from 'immutability-helper';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';
import { AddResponseEventType, EventDescriptionType } from '../event.types';
import getFirstAvailableQuestionCode from '../utils/getFirstAvailableQuestionCode';
import { NOOP_PAYLOAD } from '../eventSequencer/eventSequencer.constants';
import encodeStructuredCondition from '../utils/encodeStructuredCondition';
import { EMPTY_ID } from '../../../common/common.constants';

export type DuplicateQuestionFunctionType = (
  origQuestion: QuestionDefinitionSummaryType,
) => void;

export type DuplicateQuestionActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/DUPLICATE_QUESTION'
>;

const getDuplicatedQuestion = (
  categories: readonly FormDefinitionDraftCategoryStatsType[],
  questions: readonly QuestionDefinitionSummaryType[],
  orig: QuestionDefinitionSummaryType,
): QuestionDefinitionSummaryType => ({
  ...orig,
  code: getFirstAvailableQuestionCode(
    categories,
    questions,
    orig.category,
    // Start the search for the code with the code of the original question.
    orig.code,
  ),
  pos: orig.pos + 1,
  responses: orig.responses.map(r => ({
    definition: r.definition,
    id: EMPTY_ID,
    isRequired: r.isRequired,
    pos: r.pos,
    uniqueId: uuid(),
  })),
  wizardId: uuid(),
});

const getCreationEventsForDuplicatedQuestion = (
  {
    category,
    code,
    condition,
    question,
    responses,
    sscIssues,
    wizardId,
  }: QuestionDefinitionSummaryType,
  origQuestionId: Uuid,
): EventDescriptionType[] => [
  {
    action: FormEditEventActionCodeEnum.ADDQUESTION,
    category,
    code,
    question_uuid: wizardId,
    question,
    ssc_issues: sscIssues,
    structured:
      condition &&
      (condition.type === 'structured'
        ? encodeStructuredCondition(condition.payload)
        : undefined),
    text:
      condition && (condition.type === 'text' ? condition.payload : undefined),
  },
  ...responses.map(
    ({
      uniqueId,
      isRequired,
      definition: { label, options, placeholder, responseType },
    }): AddResponseEventType => ({
      action: FormEditEventActionCodeEnum.ADDRESPONSE,
      response_uuid: uniqueId,
      question_uuid: wizardId,
      is_required: isRequired,
      definition: {
        label,
        options,
        placeholder,
        response_type: responseType,
      },
    }),
  ),
  {
    action: FormEditEventActionCodeEnum.MOVEQUESTION,
    question_uuid: wizardId,
    target_question: origQuestionId,
    before: false,
  },
];

export const duplicateQuestion = (
  origQuestion: QuestionDefinitionSummaryType,
): DuplicateQuestionActionType => {
  return {
    type: '@formCreator/EVENTS/DUPLICATE_QUESTION',
    payload: ({ formCreator, formCreator: { currentForm } }) => {
      if (!currentForm) {
        return NOOP_PAYLOAD;
      }

      const newQuestion = getDuplicatedQuestion(
        currentForm.categories,
        currentForm.questions,
        origQuestion,
      );

      return {
        events: getCreationEventsForDuplicatedQuestion(
          newQuestion,
          origQuestion.wizardId,
        ),
        selector: () => {
          const { category, pos } = origQuestion;
          const { questions } = currentForm;

          const newQuestions = [
            // Increment pos of all questions in the category below the original
            // question to create a free slot
            ...questions.map(q =>
              q.category === category && q.pos > pos
                ? { ...q, pos: q.pos + 1 }
                : q,
            ),
            // Add the new question at the end
            newQuestion,
          ];

          return update(formCreator, {
            currentForm: { questions: { $set: newQuestions } },
          });
        },
      };
    },
  };
};
