import { RequestFindingType, TranslatePropsType } from 'common/common.types';
import { Section, Sections } from './PdfReport.styles';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Badge, Cell, Row } from './FindingsTable.styles';
import { TFunction } from 'i18next';

export type ColumnKeyType = keyof RequestFindingType;

interface Column {
  key: ColumnKeyType;
  width?: number;
  grow?: boolean;
}

const COLUMNS: Column[] = [
  {
    key: 'riskLevel',
  },
  {
    key: 'riskLevelNote',
    grow: true,
  },
  {
    key: 'remediationPlan',
  },
  {
    key: 'remediationPlanNote',
    grow: true,
  },
];

export interface FindingsTablePropsType extends TranslatePropsType {
  findings: RequestFindingType[];
}

const getFindingContext = (
  column: ColumnKeyType,
  finding: RequestFindingType,
  t: TFunction,
) => {
  switch (column) {
    case 'riskLevel':
    case 'remediationPlan':
      if (finding[column] !== undefined) {
        return (
          <Badge column={column} value={finding[column]!}>
            {t(column, { context: finding[column] }) as string}
          </Badge>
        );
      }
      return null;
    default:
      return finding[column];
  }
};

const FindingsTable = ({ findings, t }: FindingsTablePropsType) => (
  <div>
    <Sections>
      {COLUMNS.map(column => (
        <Section key={column.key} grow={column.grow} width={column.width}>
          {t(`exportColumns.${column.key}`) as string}
        </Section>
      ))}
    </Sections>
    {findings.map(finding => (
      <Row key={finding.id}>
        {COLUMNS.map(column => (
          <Cell key={column.key} grow={column.grow} width={column.width}>
            {getFindingContext(column.key, finding, t) as string}
          </Cell>
        ))}
      </Row>
    ))}
  </div>
);

export default withTranslation('FormReport')(FindingsTable);
