import Categories from './Category';
import FormHeader from './FormHeader';
import InviteUserPrompt from './Delegator/InviteUserPrompt';
import Loading from '../common/Loading';
import React, { useCallback, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Content, RelativeBlock, Wrapper } from 'common/common.styles';
import { FormFooter } from './';
import { getPromptConfirmKey } from './utils';
import { withTranslation } from 'react-i18next';
import {
  CategoryType,
  IdOrEmailType,
  PromptInviteDelegationType,
  QuestionType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import {
  FormDataPropsType,
  FormPageContextActionsType,
  FormRouteStateType,
} from 'pages/FormPage';
import { FormDetailFillingTypeEnum as FillingTypeEnum } from 'generated/models/FormDetail';
import { SharedFormDataPropsType } from 'pages/SharedFormPage';
import { FormProxy } from '../FormProxy';
import { DropdownsEnum } from './FormHeader/FormHeader';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { StaticContext } from 'react-router';

export interface FormPropsType
  extends TranslatePropsType,
    RouteComponentProps<{}, StaticContext, FormRouteStateType | undefined> {
  actions: FormPageContextActionsType;
  data: FormDataPropsType | SharedFormDataPropsType;
  initialInviteOpen?: boolean;
  initialCategory?: CategoryType;
  initialQuestion?: QuestionType;
}

const Form = ({
  actions: {
    assignCategoryDelegation,
    assignFormDelegation,
    assignQuestionDelegation,
    getAssigneesDelegation,
    showProxyModal,
  },
  data: {
    currentForm: {
      fillingType,
      id,
      meta: { questionsLeftCount, wasAlreadyOpenedByVendor },
      permissions: { canInviteProxy, isDelegable, isFormAutocompletable },
      request,
    },
    mapping,
    user: { domain },
    language,
  },
  initialInviteOpen = false,
  initialCategory,
  initialQuestion,
  location: { state: linkState },
  t,
}: FormPropsType) => {
  const [isInviteOpen, setInviteOpen] = useState(initialInviteOpen);
  const [promptConfirmKey, setPromptConfirmKey] = useState('');
  const [promptProps, setPromptProps] = useState<PromptInviteDelegationType>({
    category: initialCategory,
    question: initialQuestion,
  });

  const isRequest = fillingType === FillingTypeEnum.REQUEST;

  const isAutocompleteInitiallyOpened =
    isRequest &&
    isFormAutocompletable &&
    ((questionsLeftCount > 0 && !wasAlreadyOpenedByVendor) ||
      (linkState ? linkState.openAutocomplete : false));

  const handleInviteUserClick = useCallback(
    (e: React.MouseEvent<HTMLElement>, prompt: PromptInviteDelegationType) => {
      e.stopPropagation();

      setPromptProps(prompt);
      setInviteOpen(true);

      // TODO: ???
      if (canInviteProxy && request) {
        showProxyModal(request);
      }

      setPromptConfirmKey(getPromptConfirmKey(prompt));
    },
    [
      canInviteProxy,
      request,
      setPromptProps,
      setInviteOpen,
      setPromptConfirmKey,
      showProxyModal,
    ],
  );

  const handleInviteClose = useCallback(() => setInviteOpen(false), [
    setInviteOpen,
  ]);

  const handleInviteSubmit = useCallback(
    (invitee: IdOrEmailType) => {
      if (promptProps.question && assignQuestionDelegation) {
        assignQuestionDelegation(invitee, promptProps.question.id, id);
      } else if (promptProps.category && assignCategoryDelegation) {
        assignCategoryDelegation(id, promptProps.category.name, invitee);
      } else if (assignFormDelegation) {
        assignFormDelegation(id, invitee);
      }
      handleInviteClose();
    },
    [
      assignCategoryDelegation,
      assignFormDelegation,
      assignQuestionDelegation,
      handleInviteClose,
      id,
      promptProps,
    ],
  );

  const handleInviteByEmail = useCallback(
    (invitee: string) =>
      handleInviteSubmit({ type: 'email', payload: invitee }),
    [handleInviteSubmit],
  );

  const handleInviteById = useCallback(
    (proxyId: Uuid) => handleInviteSubmit({ type: 'id', payload: proxyId }),
    [handleInviteSubmit],
  );

  const handleUpdateAssignees = useCallback(() => {
    if (isDelegable && getAssigneesDelegation) {
      getAssigneesDelegation(id);
    }
  }, [getAssigneesDelegation, id, isDelegable]);

  return (
    <>
      {isInviteOpen && !canInviteProxy && (
        <InviteUserPrompt
          closePrompt={handleInviteClose}
          confirmTextKey={promptConfirmKey}
          domain={domain}
          formId={id}
          handleSubmit={handleInviteByEmail}
        />
      )}

      {canInviteProxy && (
        <FormProxy
          onClose={isInviteOpen ? handleInviteClose : undefined}
          onGrantAccess={
            isInviteOpen ? handleInviteById : handleUpdateAssignees
          }
          onRevoke={handleUpdateAssignees}
        />
      )}

      <FormHeader
        handleInviteUserClick={handleInviteUserClick}
        isAutocompleteVisible={isFormAutocompletable}
        isHistoryVisible={isRequest}
        isProxyVisible={canInviteProxy}
        initialCurrentDropdown={
          isAutocompleteInitiallyOpened ? DropdownsEnum.AUTOCOMPLETE : undefined
        }
        language={language}
      />

      <Wrapper grey>
        <Content padding="0 15px">
          <RelativeBlock>
            {mapping && mapping.uploadingOrMapping && (
              <Loading scrollable text={ReactHtmlParser(t('Form.loading'))} />
            )}

            <Categories handleInviteUserClick={handleInviteUserClick} />

            <FormFooter />
          </RelativeBlock>
        </Content>
      </Wrapper>
    </>
  );
};

export default withTranslation()(withRouter(Form));
