import Hexagon from '../../common/Hexagon';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { CompanyGradeEnum } from 'generated/models/Company';
import colors from 'common/common.colors';
import { CompaniesFilterType } from 'store/companies/companies.reducer';
import { OpacitySetter } from 'common/common.styles';
import { TranslatePropsType } from 'common/common.types';
import { X_VALUES, X_VALUES_POSITION } from './Visualisation';
import { withTranslation } from 'react-i18next';
import {
  HexagonPositioner,
  SscGradeText,
  ThickHorizontalLine,
} from './Visualisation.styles';

export interface SscGradesRowType extends TranslatePropsType {
  filter: CompaniesFilterType;
  y: number;
}

const SscGradesRow = ({ filter, t, y }: SscGradesRowType) => {
  const getOpacity = (grade: CompanyGradeEnum) =>
    filter.selection.grade ? (filter.selection.grade === grade ? 1 : 0.2) : 1;

  return (
    <div>
      <ThickHorizontalLine y={y} />

      <SscGradeText y={y}>
        {ReactHtmlParser(t('SscGradesRow.titleHTML'))}
      </SscGradeText>

      {X_VALUES.map((grade: CompanyGradeEnum) => (
        <div key={`ssc-grade-hex-${grade}`}>
          <HexagonPositioner
            xy={{ x: X_VALUES_POSITION[grade], y }}
            size={32}
            key={`hex-ssc-background-${grade}`}
          >
            <Hexagon
              color={colors.elements.companiesVisualisation.background}
              size={32}
              strokeWidth={10}
              strokeColor={'#fff'}
              text={grade === CompanyGradeEnum.Empty ? '?' : grade}
              textColor={grade === CompanyGradeEnum.Empty ? '#5d5d5d' : '#fff'}
            />
          </HexagonPositioner>

          <OpacitySetter
            key={`opacity-setter-ssc-${grade}`}
            opacity={getOpacity(grade)}
          >
            <HexagonPositioner
              xy={{ x: X_VALUES_POSITION[grade], y }}
              size={30}
              key={`hex-ssc-${grade}`}
            >
              <Hexagon
                color={colors.grades[grade]}
                size={30}
                strokeWidth={10}
                strokeColor={'#fff'}
                text={grade === CompanyGradeEnum.Empty ? '?' : grade}
                textColor={
                  grade === CompanyGradeEnum.Empty ? '#5d5d5d' : '#fff'
                }
              />
            </HexagonPositioner>
          </OpacitySetter>
        </div>
      ))}
    </div>
  );
};

export default withTranslation()(SscGradesRow);
