/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormsShare
 */
export interface FormsShare {
    /**
     * 
     * @type {Date}
     * @memberof FormsShare
     */
    expirationDate?: Date;
    /**
     * List of form filling ids to share
     * @type {Array<string>}
     * @memberof FormsShare
     */
    formFillingIds: Array<string>;
    /**
     * List of e-mail addresses to share to
     * @type {Array<string>}
     * @memberof FormsShare
     */
    targets: Array<string>;
}

export function FormsShareFromJSON(json: any): FormsShare {
    return FormsShareFromJSONTyped(json, false);
}

export function FormsShareFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormsShare {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expirationDate': !exists(json, 'expiration_date') ? undefined : (new Date(json['expiration_date'])),
        'formFillingIds': json['form_filling_ids'],
        'targets': json['targets'],
    };
}

export function FormsShareToJSON(value?: FormsShare | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expiration_date': value.expirationDate === undefined ? undefined : (value.expirationDate.toISOString()),
        'form_filling_ids': value.formFillingIds,
        'targets': value.targets,
    };
}


