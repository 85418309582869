import { CategoryDelegationStats } from 'generated/models/CategoryDelegationStats';
import { CategoryType, CategoryReviewStatusType } from 'common/common.types';
import { FormCategoryStats } from 'generated/models/FormCategoryStats';
import { parseUser } from './';
import { VRMReviewStats } from 'generated/models/VRMReviewStats';

export const parseCategoryDelegation = (d: CategoryDelegationStats) => ({
  assignee: d.assignee ? parseUser({ user: d.assignee }) : undefined,
  scope: d.scope,
  quantity: d.quantity,
});

export const parseCategoryReviewStatus = (
  r: VRMReviewStats,
): CategoryReviewStatusType => ({
  scope: r.scope,
  status: r.status,
});

export const parseCategories = (
  categories: FormCategoryStats[],
): CategoryType[] =>
  categories.map(c => ({
    delegation: parseCategoryDelegation(c.delegation),
    name: c.name,
    pos: c.pos,
    questionsCount: c.question_count,
    questionsFilledCount: c.questions_filled,
    questionsIgnoreFilterCount: c.question_count_ignore_filter,
    questionsIgnoreVisibilityCount: c.question_count_ignore_visibility,
    questionsTotalVisibleCount: c.questions_total_visible,
    reviewStatus: parseCategoryReviewStatus(c.vrm_review_status),
  }));
