export interface FormsSetCanUpdateViewActionType {
  type: '@forms/SET_CAN_UPDATE_VIEW';
  payload: boolean;
}

export const setCanUpdateView = (
  setTo: boolean,
): FormsSetCanUpdateViewActionType => ({
  type: '@forms/SET_CAN_UPDATE_VIEW',
  payload: setTo,
});
