import { assoc } from 'ramda';
import { Uuid } from 'common/common.types';

const arrayToIdDict = <U extends { id: Uuid }, T, A>(
  list: U[],
  parser?: (item: U, args?: A) => T,
  parserArgs?: A,
) =>
  list.reduce(
    (result: { [key: string]: T }, item: U) =>
      assoc(item.id, parser ? parser(item, parserArgs) : item, result),
    {},
  );

export default arrayToIdDict;
