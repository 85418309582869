import React from 'react';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'utils/Modal';
import {
  Wrapper,
  Text,
  Buttons,
  ConfirmButton,
  CancelButton,
  CloseButton,
  Paragraph,
  Title,
  TextWrapper,
} from './ConfirmModal.styles';
import { CONFIRM_MODAL_ID } from 'common/common.constants';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ConfirmModalPropsType extends TranslatePropsType {
  cancelText?: string;
  confirmText?: string;
  modalId?: string;
  text: string;
  title?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmModal = ({
  cancelText,
  confirmText,
  modalId = CONFIRM_MODAL_ID,
  text,
  title,
  onConfirm,
  onCancel,
  t,
}: ConfirmModalPropsType) => {
  const modalProps = {
    modalId: modalId,
    custom: true,
  };
  const fontAwesomProps = {
    icon: faTimes,
  };

  const faTimesProp = faTimes as IconProp;

  const titleLabel = title || t('labels.areYouSure');
  const cancelLabel = cancelText || t('labels.cancel');

  return (
    <Modal {...modalProps}>
      <Wrapper data-test="confirm-modal">
        <CloseButton onClick={onCancel}>
          <FontAwesomeIcon icon={faTimesProp} />
        </CloseButton>
        <TextWrapper>
          <Title>{titleLabel}</Title>
          <Text>
            <Paragraph>{text}</Paragraph>
          </Text>
        </TextWrapper>
        <Buttons spacingSize={29}>
          <ConfirmButton onClick={onConfirm}>
            {confirmText || t('labels.confirm')}
          </ConfirmButton>
          <CancelButton onClick={onCancel}>{cancelLabel}</CancelButton>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

export default withTranslation('common')(ConfirmModal);
