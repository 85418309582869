import i18n from '../../../i18n';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { UserStateType } from '../user.types';
import { Uuid } from '../../../common/common.types';

export interface UserDeleteFormParamsType {
  formId: Uuid;
}

export type UserDeleteFormActionType = ApiCallActionType<
  '@user/API/DELETE_FORM',
  UserDeleteFormParamsType,
  never,
  never,
  UserStateType
>;

export const deleteForm = (formId: Uuid): UserDeleteFormActionType => ({
  type: '@user/API/DELETE_FORM',
  payload: {
    callParams: { formId },
    endpointPath: ['user', 'deleteForm'],
    selector: (data, state) => ({
      frameworks: state.user.frameworks.filter(r => r.id !== formId),
    }),
    postActions: [
      () => call(() => trackEvent('Form', 'Delete - Form')),
      () => put(flash(i18n.t('common:responses.fileDeleteSuccess'))),
    ],
  },
});
