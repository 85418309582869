/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface FormProxiesCreate
 */
export interface FormProxiesCreate {
    /**
     * 
     * @type {string}
     * @memberof FormProxiesCreate
     */
    email_body?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormProxiesCreate
     */
    proxy_roles: Array<FormProxiesCreateProxyRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof FormProxiesCreate
     */
    user_email: string;
}




/**
* @export
* @enum {string}
*/
export enum FormProxiesCreateProxyRolesEnum {
    VENDORPROXY = 'VENDOR_PROXY',
    VRMPROXY = 'VRM_PROXY'
}


