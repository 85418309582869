import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NotificationEnum } from 'common/common.types';
import colors from 'common/common.colors';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const Button = styled.button`
  background: ${({ theme }) => theme.atlas.white};
  border-radius: 5px;
  border: none;
  cursor: pointer;
  height: 46px;
  width: 130px;
  margin-left: 20px;
  ${({ theme }) => theme.fonts.semibold.normal}
`;

export const CloseIcon = styled(FontAwesomeIcon).attrs({
  icon: faTimes as IconProp,
})`
  color: ${({ theme }) => theme.atlas.white};
  width: 20px !important;
  height: 20px;
`;

export const Closer = styled.div`
  cursor: pointer;
  margin-left: 20px;
`;

export const Text = styled.p`
  min-width: 280px;
  text-align: center;
  line-height: 1.315;
  color: ${({ theme }) => theme.atlas.white};
  ${({ theme }) => theme.fonts.semibold.normal}
`;

export const Wrapper = styled.div<{ type: NotificationEnum }>`
  background: ${({ type }) => colors.elements.notifications[type]};
  height: fit-content;
  border-radius: 8px;
  padding: 0 28px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
`;
