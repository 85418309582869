import FormPreview from './FormPreview';
import Loader from 'react-loader-spinner';
import Modal from 'utils/Modal/Modal';
import React, { useRef, ReactNode } from 'react';
import {
  LoaderWrapper,
  Heading,
  Scroller,
  InitializeFailedWrapper,
} from './PreviewModal.styles';
import { withTranslation } from 'react-i18next';
import {
  FormType,
  LanguageType,
  TranslatePropsType,
  UserType,
} from 'common/common.types';
import {
  FormsLoadQuestionsFunctionType,
  FormsPutQuestionFunctionType,
} from 'store/forms/actions';
import { FormDetailInitializationStatusEnum } from 'generated/models/FormDetail';

interface PreviewModalPropsType extends TranslatePropsType {
  loadQuestions: FormsLoadQuestionsFunctionType;
  modalId: string;
  onClose?: () => void;
  previewForm?: FormType;
  putQuestion: FormsPutQuestionFunctionType;
  user: UserType;
  headerEnhanced?: ReactNode;
  language: LanguageType;
}

const PreviewModal = ({
  loadQuestions,
  modalId,
  previewForm,
  putQuestion,
  t,
  user,
  headerEnhanced,
  onClose,
  language,
}: PreviewModalPropsType) => {
  const ref = useRef(null);
  const isLoading = !previewForm;
  const previewNotInitializedLabel = t('previewNotAvailable');

  const modalProps = {
    modalId: modalId,
    onClose: onClose,
    title:
      !isLoading && previewForm && previewForm.standard ? (
        <div>
          <Heading>
            {t('previewTitle', { title: previewForm.standard.name }) as string}
          </Heading>
          {headerEnhanced ? headerEnhanced : null}
        </div>
      ) : (
        undefined
      ),
  };

  return (
    <Modal {...modalProps}>
      {isLoading ? (
        <LoaderWrapper>
          <Loader type="ThreeDots" color="#333" width={50} height={50} />
        </LoaderWrapper>
      ) : previewForm?.initializationStatus ===
        FormDetailInitializationStatusEnum.FINISHEDFAIL ? (
        <InitializeFailedWrapper>
          <span> {previewNotInitializedLabel}</span>{' '}
        </InitializeFailedWrapper>
      ) : (
        <Scroller ref={ref}>
          {previewForm && previewForm.categories && (
            <FormPreview
              loadQuestions={loadQuestions}
              previewForm={previewForm}
              putQuestion={putQuestion}
              user={user}
              forwardedRef={ref}
              language={language}
            />
          )}
        </Scroller>
      )}
    </Modal>
  );
};

export default withTranslation('SendRequest')(PreviewModal);
