import React from 'react';
import StandardListItem from '../StandardListItem';
import { contains } from 'ramda';
import { StandardType, Uuid } from 'common/common.types';
import {
  RenameStandardFunctionType,
  StandardsDeleteFunctionType,
  ToggleStandardSelectionFunctionType,
} from 'store/standard/actions';
import { StandardStatusEnum } from 'generated/models/Standard';

interface ListPropsType {
  'data-cy'?: string;
  deleteStandard?: StandardsDeleteFunctionType;
  handleToggle?: ToggleStandardSelectionFunctionType;
  onPreview?: (formId: Uuid) => void;
  renameStandard?: RenameStandardFunctionType;
  selectedStandards: Uuid[];
  standards: StandardType[];
}

const List = ({
  'data-cy': dataCy,
  deleteStandard,
  handleToggle,
  onPreview,
  renameStandard,
  selectedStandards,
  standards,
}: ListPropsType) => (
  <div data-cy={dataCy}>
    {standards.map(
      ({ code, id, name, previewFormId, status, version, year }) => {
        const standardListItemProps = {
          code: code,
          isCustom: status === StandardStatusEnum.CUSTOM,
          isSelected: contains(id, selectedStandards),
          name:
            status === StandardStatusEnum.SYSTEM
              ? [name, version, year].join(' ')
              : name,
          onDelete: () => (deleteStandard ? deleteStandard({ id }) : null),
          onPreview:
            onPreview && previewFormId
              ? () => onPreview(previewFormId)
              : undefined,
          onSave: (newName: any) =>
            renameStandard ? renameStandard({ id, name: newName }) : null,
          onToggle: handleToggle ? () => handleToggle({ id }) : undefined,
        };

        return <StandardListItem {...standardListItemProps} key={id} />;
      },
    )}
  </div>
);

export default List;
