import { withTranslation } from 'react-i18next';
import Chip from '../../Form/Filter/Chip';
import React from 'react';
import { FilterByStatus } from './FilterBy.Status';

const FiltersStatus = ({ action, t }: FilterByStatus) => {
  return (
    <Chip
      label={t(`FormDefinitions:filterByStatus`)}
      onDelete={() => action([])}
    />
  );
};

export default withTranslation('components')(FiltersStatus);
