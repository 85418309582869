/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestFilterCountsCompanyValues,
    RequestFilterCountsCompanyValuesFromJSON,
    RequestFilterCountsCompanyValuesFromJSONTyped,
    RequestFilterCountsCompanyValuesToJSON,
    RequestFilterCountsStandardValues,
    RequestFilterCountsStandardValuesFromJSON,
    RequestFilterCountsStandardValuesFromJSONTyped,
    RequestFilterCountsStandardValuesToJSON,
    RequestFilterCountsStringValues,
    RequestFilterCountsStringValuesFromJSON,
    RequestFilterCountsStringValuesFromJSONTyped,
    RequestFilterCountsStringValuesToJSON,
    RequestFiltersDescriptions,
    RequestFiltersDescriptionsFromJSON,
    RequestFiltersDescriptionsFromJSONTyped,
    RequestFiltersDescriptionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface RequestFiltersStats
 */
export interface RequestFiltersStats {
    /**
     * 
     * @type {Array<RequestFilterCountsCompanyValues>}
     * @memberof RequestFiltersStats
     */
    companyId: Array<RequestFilterCountsCompanyValues>;
    /**
     * 
     * @type {RequestFiltersDescriptions}
     * @memberof RequestFiltersStats
     */
    description: RequestFiltersDescriptions;
    /**
     * 
     * @type {Array<RequestFilterCountsCompanyValues>}
     * @memberof RequestFiltersStats
     */
    sourceId: Array<RequestFilterCountsCompanyValues>;
    /**
     * 
     * @type {Array<RequestFilterCountsStringValues>}
     * @memberof RequestFiltersStats
     */
    sourceUserEmail: Array<RequestFilterCountsStringValues>;
    /**
     * 
     * @type {Array<RequestFilterCountsStandardValues>}
     * @memberof RequestFiltersStats
     */
    standardId: Array<RequestFilterCountsStandardValues>;
    /**
     * 
     * @type {Array<RequestFilterCountsStringValues>}
     * @memberof RequestFiltersStats
     */
    status: Array<RequestFilterCountsStringValues>;
    /**
     * 
     * @type {Array<RequestFilterCountsCompanyValues>}
     * @memberof RequestFiltersStats
     */
    targetId: Array<RequestFilterCountsCompanyValues>;
    /**
     * 
     * @type {Array<RequestFilterCountsStringValues>}
     * @memberof RequestFiltersStats
     */
    targetUserEmail: Array<RequestFilterCountsStringValues>;
    /**
     * 
     * @type {Array<RequestFilterCountsStringValues>}
     * @memberof RequestFiltersStats
     */
    userEmail: Array<RequestFilterCountsStringValues>;
}

export function RequestFiltersStatsFromJSON(json: any): RequestFiltersStats {
    return RequestFiltersStatsFromJSONTyped(json, false);
}

export function RequestFiltersStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFiltersStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': ((json['company_id'] as Array<any>).map(RequestFilterCountsCompanyValuesFromJSON)),
        'description': RequestFiltersDescriptionsFromJSON(json['description']),
        'sourceId': ((json['source_id'] as Array<any>).map(RequestFilterCountsCompanyValuesFromJSON)),
        'sourceUserEmail': ((json['source_user_email'] as Array<any>).map(RequestFilterCountsStringValuesFromJSON)),
        'standardId': ((json['standard_id'] as Array<any>).map(RequestFilterCountsStandardValuesFromJSON)),
        'status': ((json['status'] as Array<any>).map(RequestFilterCountsStringValuesFromJSON)),
        'targetId': ((json['target_id'] as Array<any>).map(RequestFilterCountsCompanyValuesFromJSON)),
        'targetUserEmail': ((json['target_user_email'] as Array<any>).map(RequestFilterCountsStringValuesFromJSON)),
        'userEmail': ((json['user_email'] as Array<any>).map(RequestFilterCountsStringValuesFromJSON)),
    };
}

export function RequestFiltersStatsToJSON(value?: RequestFiltersStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': ((value.companyId as Array<any>).map(RequestFilterCountsCompanyValuesToJSON)),
        'description': RequestFiltersDescriptionsToJSON(value.description),
        'source_id': ((value.sourceId as Array<any>).map(RequestFilterCountsCompanyValuesToJSON)),
        'source_user_email': ((value.sourceUserEmail as Array<any>).map(RequestFilterCountsStringValuesToJSON)),
        'standard_id': ((value.standardId as Array<any>).map(RequestFilterCountsStandardValuesToJSON)),
        'status': ((value.status as Array<any>).map(RequestFilterCountsStringValuesToJSON)),
        'target_id': ((value.targetId as Array<any>).map(RequestFilterCountsCompanyValuesToJSON)),
        'target_user_email': ((value.targetUserEmail as Array<any>).map(RequestFilterCountsStringValuesToJSON)),
        'user_email': ((value.userEmail as Array<any>).map(RequestFilterCountsStringValuesToJSON)),
    };
}


