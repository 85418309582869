import styled from 'styled-components';
import colors from 'common/common.colors';

export const IconWrapper = styled.div`
  display: inline-block;
  padding: 4px 10px;
  border-radius: 12px;
  margin-right: 7px;
  line-height: 11px;
  background-color: ${({ color }: { color: string }) =>
    colors.requestDueDates[color]};

  & svg {
    width: 18px;
    height: 18px;
  }
`;

export const TextWrapper = styled.div`
  ${({ theme }) => theme.fonts.light.small}
  color: #787993;
  display: inline-block;
  line-height: 13px;
  position: relative;
  top: 2px;
`;
