import { FlashType } from '../../common/common.types';
import { FlashesActionType } from './actions';

export type FlashesStateType = FlashType[];

const initialState: FlashesStateType = [];

export const flashes = (
  state: FlashesStateType = initialState,
  action: FlashesActionType,
): FlashesStateType => {
  switch (action.type) {
    case '@flashes/FLASH':
      return [...state, { ...action.payload }];

    case '@flashes/REMOVE':
      return state.filter(flash => flash.id !== action.payload.id);

    default:
      return state;
  }
};
