import {
  Uuid,
  FormDefinitionDraftCategoryStatsType,
  QuestionDefinitionSummaryType,
} from '../../../common/common.types';
import uuid from 'utils/uuid';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import update from 'immutability-helper';
import {
  createDefaultResponse,
  addCategoryIfNotExist,
  getMaxQuestionPosInCategory,
} from '../utils';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';
import getFirstAvailableQuestionCode from '../utils/getFirstAvailableQuestionCode';

const DEFAULT_QUESTION_TEXT = '';

export type AddQuestionFunctionType = (category: string) => void;

export type AddQuestionActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/ADD_QUESTION'
>;

export const addQuestion = (category: string): AddQuestionActionType => {
  const questionUniqueId: Uuid = uuid();
  const responseUniqueId: Uuid = uuid();

  const initialQuestionResponse = createDefaultResponse(responseUniqueId);

  return {
    type: '@formCreator/EVENTS/ADD_QUESTION',
    payload: {
      events: ({ formCreator: { currentForm } }) =>
        currentForm
          ? [
              {
                action: FormEditEventActionCodeEnum.ADDQUESTION,
                category,
                code: getFirstAvailableQuestionCode(
                  currentForm.categories,
                  currentForm.questions,
                  category,
                ),
                question_uuid: questionUniqueId,
                question: DEFAULT_QUESTION_TEXT,
                ssc_issues: [],
              },
              {
                action: FormEditEventActionCodeEnum.ADDRESPONSE,
                response_uuid: responseUniqueId,
                question_uuid: questionUniqueId,
              },
            ]
          : [],
      selector: ({ formCreator, formCreator: { currentForm } }) => {
        if (!currentForm) {
          return formCreator;
        }

        const newQuestion: QuestionDefinitionSummaryType = {
          active: true,
          category,
          code: getFirstAvailableQuestionCode(
            currentForm.categories,
            currentForm.questions,
            category,
          ),
          pos: 1 + getMaxQuestionPosInCategory(currentForm.questions, category),
          question: DEFAULT_QUESTION_TEXT,
          responses: [initialQuestionResponse],
          sscIssues: [],
          wizardId: questionUniqueId,
        };

        return update(formCreator, {
          currentForm: {
            categories: {
              $apply: (categories: FormDefinitionDraftCategoryStatsType[]) =>
                addCategoryIfNotExist(categories, category, 1),
            },
            questions: {
              $push: [newQuestion],
            },
          },
          openedQuestion: {
            $set: {
              categoryName: category,
              questionUuid: questionUniqueId,
            },
          },
        });
      },
    },
  };
};
