import { Team } from '../../../generated/new';
import companiesApi from '../../../api/new/companiesApi';
import { ApiCallActionType } from '../../store.types';
import { UserStateType } from '../user.types';

import update from 'immutability-helper';

export type UserGetCompanyCurrentTeamsActionType = ApiCallActionType<
  '@user/API/GET_COMPANY_CURRENT_TEAMS',
  null,
  Team[],
  never,
  UserStateType
>;

export const getCompanyCurrentTeams = (): UserGetCompanyCurrentTeamsActionType => ({
  type: '@user/API/GET_COMPANY_CURRENT_TEAMS',
  payload: {
    endpointPath: () => companiesApi.getCompanyCurrentTeamsResource(),
    callParams: null,
    selector: (data, { user }) =>
      update(user, {
        currentUser: {
          company: {
            teams: { $set: data },
          },
        },
      }),
  },
});
