import {
  SeverityTypeEnum,
  SscDataAuditType,
  SscDataDiffType,
  SscDataFactorType,
  SscDataIssueType,
} from 'common/common.types';

export const parseSscDataIssue = (data: any): SscDataIssueType => {
  const {
    detail_url: detailUrl = '',
    issue_count: issueCount = 0,
    title = '',
    type = '',
    severity,
  } = data;

  return {
    detailUrl,
    issueCount,
    title,
    type,
    severity: severity || SeverityTypeEnum.UNKNOWN,
  };
};

export const parseSScDataFactor = (data: any): SscDataFactorType => {
  const {
    key = '',
    description = '',
    title = '',
    grade = '',
    score = 0,
    issues = [],
  } = data;

  return {
    key,
    description,
    grade,
    issues: issues.map(parseSscDataIssue),
    score,
    title,
  };
};

export const parseSscDataDiff = (data: any): SscDataDiffType => {
  const {
    added_factors: addedFactors = [],
    added_issues: addedIssues = [],
    removed_factors: removedFactors = [],
    removed_issues: removedIssues = [],
    updated_factors: updatedFactors = [],
    updated_issues: updatedIssues = [],
  } = data;
  return {
    addedFactors,
    addedIssues,
    removedFactors,
    removedIssues,
    updatedFactors,
    updatedIssues,
  };
};

export const parseSscDataAudit = (data: any): SscDataAuditType => {
  const { diff, factors = [] } = data;
  return {
    diff: parseSscDataDiff(diff || {}),
    factors: factors.map(parseSScDataFactor),
  };
};
