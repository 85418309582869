import React from 'react';
import {
  Wrapper,
  ErrorIcon,
  IconWrapper,
  MessagesWrapper,
  ValidateButton,
} from './ValidationInfo.styles';
import { TranslatePropsType } from 'common/common.types';
import { Trans, withTranslation } from 'react-i18next';
import ValidationError from 'store/formCreator/validationError.types';

interface ValidationInfoPropsType extends TranslatePropsType {
  className?: string;
  errors: ValidationError[];
  fixAttempted?: boolean;
  onValidateClick?: () => void;
  showIcon?: boolean;
}

const ValidationInfo = ({
  className,
  errors,
  fixAttempted,
  onValidateClick,
  showIcon,
  t,
}: ValidationInfoPropsType) => (
  <Wrapper className={className} fixAttempted={fixAttempted}>
    {showIcon && (
      <IconWrapper>
        <ErrorIcon fixAttempted={fixAttempted} />
      </IconWrapper>
    )}
    <MessagesWrapper fixAttempted={fixAttempted} showIcon={showIcon}>
      <p>
        <Trans i18nKey="validation.changesInSection" t={t}>
          You've made changes in this section.
          <ValidateButton onClick={onValidateClick}>
            Validate again
          </ValidateButton>{' '}
          if you want to check if your changes were correct.
        </Trans>
      </p>
      <ul>
        {errors.map(({ code }) => (
          <li key={code}>
            {t([`validation.${code}`, 'validation.UNKNOWN_ERROR']) as string}
          </li>
        ))}
      </ul>
    </MessagesWrapper>
  </Wrapper>
);

export default React.memo(withTranslation('FormCreator')(ValidationInfo));
