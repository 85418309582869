import { FormMetadata } from '../../../generated/models/FormMetadata';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { assoc, assocPath, mapObjIndexed, pathOr, pipe } from 'ramda';
import { parseFilterStats } from 'utils/parsers/forms.parsers';
import { parseForm } from 'utils/parsers';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { FormsGetFormCallParams } from './getForm';
import { FormType } from 'common/common.types';

export type FormsClearFiltersActionType = OptimisticActionType<
  '@forms/API/CLEAR_FILTERS',
  ApiCallActionPayloadType<
    FormsGetFormCallParams,
    FormMetadata,
    never,
    FormsStateType
  >
>;

export const clearFilters = (): FormsClearFiltersActionType => ({
  type: '@forms/API/CLEAR_FILTERS',
  payload: {
    callParams: state => ({
      id: pathOr(undefined, ['forms', 'currentForm', 'id'], state),
    }),
    endpointPath: ['forms', 'get'],
    expectedStateChange: {
      [`forms.filters.simple`]: mapObjIndexed(assoc('isEnabled', false)),
      [`forms.filters.assignees`]: mapObjIndexed(assoc('isEnabled', false)),
    },
    selector: (data, state) =>
      pipe(
        assocPath<FormType, FormsStateType>(
          ['currentForm'],
          parseForm(data, state),
        ),
        assocPath(
          ['filters'],
          parseFilterStats(data.filter_stats, data.assignee_stats, state.forms),
        ),
      )(state.forms),
  },
});
