/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResponseFillingHistory
 */
export interface ResponseFillingHistory {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof ResponseFillingHistory
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof ResponseFillingHistory
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    action: ResponseFillingHistoryActionEnum;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    companyId: string;
    /**
     * 
     * @type {object}
     * @memberof ResponseFillingHistory
     */
    data?: object;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    responseId: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseFillingHistory
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFillingHistory
     */
    value: string;
}

export function ResponseFillingHistoryFromJSON(json: any): ResponseFillingHistory {
    return ResponseFillingHistoryFromJSONTyped(json, false);
}

export function ResponseFillingHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseFillingHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'action': json['action'],
        'companyId': json['company_id'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'id': json['id'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'responseId': json['response_id'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'userId': json['user_id'],
        'value': json['value'],
    };
}

export function ResponseFillingHistoryToJSON(value?: ResponseFillingHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'action': value.action,
        'company_id': value.companyId,
        'data': value.data,
        'id': value.id,
        'message': value.message,
        'response_id': value.responseId,
        'score': value.score,
        'user_id': value.userId,
        'value': value.value,
    };
}

/**
* @export
* @enum {string}
*/
export enum ResponseFillingHistoryActionEnum {
    CLEARAFTERCOPY = 'CLEAR_AFTER_COPY',
    COPYFROMFORM = 'COPY_FROM_FORM',
    COPYFROMRESPONSE = 'COPY_FROM_RESPONSE',
    FILLMASTERFORM = 'FILL_MASTER_FORM',
    MANUALANSWERLOOKUPLENSES = 'MANUAL_ANSWER_LOOKUP_LENSES',
    MANUALAUTOCOMPLETE = 'MANUAL_AUTOCOMPLETE',
    MANUALBATCHERASE = 'MANUAL_BATCH_ERASE',
    MANUALINPUT = 'MANUAL_INPUT',
    MANUALMERGEUPLOADEDFILE = 'MANUAL_MERGE_UPLOADED_FILE',
    MAPPINGFUZZY = 'MAPPING_FUZZY',
    MAPPINGFUZZYBATCH = 'MAPPING_FUZZY_BATCH',
    MAPPINGSTATIC = 'MAPPING_STATIC',
    PROPAGATEFROMMASTERFORM = 'PROPAGATE_FROM_MASTER_FORM'
}


