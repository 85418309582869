import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Bar, Days, Title, Wrapper } from './StatusTimeBar.styles';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface StatusTimeBarPropsType extends TranslatePropsType {
  color: string;
  leftOffset?: number;
  percentage: any;
  title: string;
}

const StatusTimeBar = ({
  color,
  leftOffset = 0,
  percentage,
  title,
  t,
}: StatusTimeBarPropsType) => (
  <Wrapper data-test="status-time-bar">
    <Title>{title}</Title>

    <Bar
      color={color}
      leftOffset={leftOffset}
      percentage={percentage.percentage}
    />

    <Days>
      {percentage.value
        ? ReactHtmlParser(t('days', { count: percentage.value }))
        : (t('na') as string)}
    </Days>
  </Wrapper>
);

export default withTranslation('Insights')(StatusTimeBar);
