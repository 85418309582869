import Loader from 'react-loader-spinner';
import React from 'react';
import { AttachmentType, QuestionTabEnum, Uuid } from 'common/common.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TabCommonType } from '../QuestionTabs';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { trackEvent } from 'utils/analytics';
import {
  FormsDeleteQuestionAttachmentFunctionType,
  FormsSetBlockSeenFunctionType,
} from 'store/forms/actions';
import {
  AttachmentPlaceholder,
  AttachmentWrapper,
  Link,
  Remove,
} from './Attachment.styles';
import getEvidenceUrl from 'utils/getEvidenceUrl';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface AttachmentPropsType extends TabCommonType {
  attachment: AttachmentType;
  areAttachmentsModifiable: boolean;
  deleteAttachment?: FormsDeleteQuestionAttachmentFunctionType;
  formId: Uuid;
  questionId: Uuid;
  setBlockSeen?: FormsSetBlockSeenFunctionType;
}

const Attachment = ({
  attachment: { displayName, id, name, responseId, uploadingId },
  areAttachmentsModifiable,
  deleteAttachment,
  formId,
  hasNotifications,
  questionId,
  setBlockSeen,
}: AttachmentPropsType) => {
  const handleClick = () => {
    if (setBlockSeen && hasNotifications) {
      setBlockSeen(questionId, QuestionTabEnum.ATTACHMENTS);
    }

    trackEvent('Attachment', 'Download');
  };

  const url = getEvidenceUrl(id);

  if (!id) {
    return (
      <AttachmentPlaceholder key={`attachment-${uploadingId}`}>
        <Loader type="Oval" color="#333" width={12} height={12} />

        <span>{displayName}</span>
      </AttachmentPlaceholder>
    );
  }

  return (
    <AttachmentWrapper key={`attachment-${id}`}>
      <Link href={url} title={name} onClick={handleClick}>
        {displayName}
      </Link>

      {deleteAttachment && areAttachmentsModifiable && (
        <Remove
          onClick={() => deleteAttachment(id, questionId, formId, responseId)}
        >
          <FontAwesomeIcon icon={faTimes as IconProp} />
        </Remove>
      )}
    </AttachmentWrapper>
  );
};

export default Attachment;
