import styled from 'styled-components/macro';

import { TextButton as ImportedTextButton } from 'common/common.styles';

export const ModalHeaderAdditionalContent = styled.div`
  display: flex;
  margin: 15px 0;
`;

export const TextButton = styled(ImportedTextButton)`
  margin-left: 10px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const CountWrapper = styled.span`
  padding-right: 25px;
  & > strong {
    color: ${({ theme }) => theme.basic.colors.teal};
  }
`;
