import DueDateText from './DueDateText';
import AcceptedDateText from './AcceptedDateText';
import React from 'react';
import ReactSVG from 'react-svg';
import { differenceInDays } from 'date-fns';
import { IconWrapper, TextWrapper } from './DueDateKanban.styles';
import { LanguageType, TranslatePropsType } from 'common/common.types';
import { calculateKind } from './utils';
import { withTranslation } from 'react-i18next';

export interface DueDateKanbanType extends TranslatePropsType {
  dueDate?: Date;
  requestedAt?: Date;
  acceptedDate?: Date;
  language: LanguageType;
}

const DueDateKanban = ({
  dueDate,
  requestedAt,
  acceptedDate,
  t,
  language,
}: DueDateKanbanType) => (
  <div>
    <IconWrapper
      color={calculateKind(dueDate ? differenceInDays(dueDate, new Date()) : 0)}
    >
      <ReactSVG
        src={'/icons/calendar-inverted.svg'}
        title={t('components:DueDateKanban.title', { requestedAt, dueDate })}
      />
    </IconWrapper>

    <TextWrapper>
      {acceptedDate && dueDate && (
        <AcceptedDateText
          dueDate={dueDate}
          acceptedDate={acceptedDate}
          language={language}
        />
      )}
      {dueDate && !acceptedDate && <DueDateText dueDate={dueDate} />}
      {!dueDate && (t('common:dueDate.noDueDate') as string)}
    </TextWrapper>
  </div>
);

export default withTranslation(['components', 'common'])(DueDateKanban);
