import { RequestSummaryStatusEnum } from '../../generated/models';
import { RequestsActionType } from './actions';
import {
  RequestsInOutType,
  RequestsStateType,
  RequestsViewType,
  SendRequestViewEnum,
  ArchivedRequestsType,
} from './requests.types';
import { assocPath, contains, symmetricDifference } from 'ramda';
import { reminders, SCHEDULE_RECURRENCES } from '../../common/common.constants';
import {
  sortRequests,
  SortType,
  sortArchivedRequests,
} from 'utils/filters/requests.sorters';
import {
  defaultFilter,
  defaultFilterOptions,
} from 'utils/filters/requests.filters';

const VIEWS_THAT_NOTIFY_RECIPIENTS = [
  SendRequestViewEnum.EMAIL,
  SendRequestViewEnum.INTERNAL,
];

export const defaultSort: SortType = {
  by: 'UPDATED',
  direction: 'DESC',
};

const defaultView: RequestsViewType = 'List';

const defaultCategoriesOpened = {
  created: false,
  inProgress: true,
  reviewReady: false,
  closed: false,
};

export const archivedRequestsInitialState: ArchivedRequestsType = {
  data: [],
  filter: {
    sortBy: defaultSort,
  },
};

export const requestsInOutInitialState: RequestsInOutType = {
  count: 0,
  categoriesOpened: defaultCategoriesOpened,
  filter: {
    filterBy: defaultFilter,
    sortBy: defaultSort,
    view: defaultView,
  },
  filterOptions: defaultFilterOptions,
  categories: {
    created: {
      count: 0,
      requests: [],
      selected: [],
    },
    inProgress: {
      count: 0,
      requests: [],
      selected: [],
    },
    reviewReady: {
      count: 0,
      requests: [],
      selected: [],
    },
    closed: {
      count: 0,
      requests: [],
      selected: [],
    },
  },
  unsorted: [],
  description: {
    plain: '',
    html: '',
  },
};

const { CREATED, VENDORINPROGRESS, VRMINPROGRESS } = RequestSummaryStatusEnum;

const defaultStats = {
  [CREATED]: null,
  [VENDORINPROGRESS]: null,
  [VRMINPROGRESS]: null,
};

export const requestsInitialState: RequestsStateType = {
  archived: archivedRequestsInitialState,
  customModal: {
    isOpen: false,
  },
  form: {
    dueDate: undefined,
    emailBody: '',
    isValid: undefined,
    notify: true,
    recurrence: SCHEDULE_RECURRENCES[0],
    reminder: reminders[0],
    sendDate: new Date(),
    sourceTeam: undefined,
    targets: [],
    targetTeams: [],
  },
  insightsExporting: false,
  received: requestsInOutInitialState,
  scheduled: [],
  sendRequest: {
    error: null,
    requests: [],
    view: SendRequestViewEnum.EMAIL,
  },
  sent: requestsInOutInitialState,
  summary: [],
  stats: defaultStats,
};

const requests = (
  state: RequestsStateType = requestsInitialState,
  action: RequestsActionType,
): RequestsStateType | any => {
  switch (action.type) {
    case '@requests/SET_FORM_VALUE':
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.field]: action.payload.value,
        },
        sendRequest: {
          ...state.sendRequest,
          error: null,
        },
      };

    case '@requests/TOGGLE_CATEGORIES':
      return assocPath(
        [action.payload.direction, 'categoriesOpened'],
        {
          ...state[action.payload.direction].categoriesOpened,
          ...action.payload.categories,
        },
        state,
      );

    case '@requests/TOGGLE_SELECTION':
      const {
        payload: { category, direction, requestIds },
      } = action;
      const selectedRequests = state[direction].categories[category].selected;
      return assocPath(
        [direction, 'categories', category, 'selected'],
        symmetricDifference(selectedRequests, requestIds),
        state,
      );

    case '@requests/SWITCH_VIEW':
      return assocPath(
        [action.payload.direction, 'filter', 'view'],
        action.payload.view,
        state,
      );

    case '@requests/OPEN_CUSTOM_MODAL':
      return {
        ...state,
        customModal: {
          isOpen: true,
        },
      };

    case '@requests/CLOSE_CUSTOM_MODAL':
      return {
        ...state,
        customModal: {
          isOpen: false,
        },
      };

    case '@requests/RESET':
      return requestsInitialState;

    case '@requests/RESET_FORM':
      return {
        ...state,
        form: {
          ...requestsInitialState.form,
        },
        sendRequest: {
          ...requestsInitialState.sendRequest,
        },
      };

    case '@requests/SAVE_SENT_REQUESTS': {
      return {
        ...state,
        sendRequest: {
          ...state.sendRequest,
          requests: action.payload,
        },
      };
    }

    case '@requests/SET_SORT':
      return sortRequests(
        action.payload.sortBy,
        action.payload.direction,
      )(state);

    case '@requests/SET_SORT_ARCHIVED':
      return sortArchivedRequests(action.payload.sortBy)(state);

    case '@requests/SWITCH_SEND_REQUEST_VIEW':
      return {
        ...state,
        form: {
          ...requestsInitialState.form,
          dueDate: state.form.dueDate,
          notify: contains(action.payload.view, VIEWS_THAT_NOTIFY_RECIPIENTS),
        },
        sendRequest: {
          ...state.sendRequest,
          error: null,
          requests: [],
          view: action.payload.view,
        },
      };

    default:
      return state;
  }
};

export default requests;
