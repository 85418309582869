import QuantityInputColumn from './QuantityInputColumn';
import React from 'react';
import TotalPriceColumn from './TotalPriceColumn';
import UnitPriceColumn from './UnitPriceColumn';
import { MainWrapper, ProductText } from './CheckoutInput.styles';
import { TranslatePropsType, PricingType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

export interface CheckoutInputType extends TranslatePropsType {
  currentPricingInfo: PricingType;
  fullPrice: number;
  onQuantityRefresh: (quantity: number) => void;
  quantity: number | undefined;
}

const CheckoutInput = ({
  currentPricingInfo: { price },
  fullPrice,
  onQuantityRefresh,
  quantity,
  t,
}: CheckoutInputType) => (
  <MainWrapper>
    <ProductText>{t('productName') as string}</ProductText>

    <UnitPriceColumn unitPrice={price} fullPrice={fullPrice} />

    <QuantityInputColumn
      quantity={quantity}
      onQuantityRefresh={onQuantityRefresh}
    />

    <TotalPriceColumn
      unitPrice={price}
      fullPrice={fullPrice}
      quantity={quantity}
    />
  </MainWrapper>
);

export default withTranslation('CreditsSelfCheckout')(CheckoutInput);
