import * as R from 'ramda';
import { FormMetadata } from '../../../generated/models/FormMetadata';
import { getFiltersCallParam } from '../../../components/Form/utils';
import { parseCategories } from 'utils/parsers/categories.parsers';
import { parseDelegationNotifications, parseFilterStats } from 'utils/parsers';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { Uuid } from '../../../common/common.types';
import { FormsGetFormCallParams } from './getForm';
import { FormsAssigneesFilterDict } from 'utils/filters/forms.filters';

export type FormsHydrateFiltersCategoriesActionType = ApiCallActionType<
  '@forms/API/HYDRATE_FILTERS_CATEGORIES',
  FormsGetFormCallParams,
  FormMetadata,
  never,
  FormsStateType
>;

export const hydrateFiltersCategories = (
  id: Uuid,
): FormsHydrateFiltersCategoriesActionType => ({
  type: '@forms/API/HYDRATE_FILTERS_CATEGORIES',
  payload: {
    callParams: (state): FormsGetFormCallParams => ({
      id,
      ...getFiltersCallParam(state.forms.filters),
    }),
    endpointPath: ['forms', 'get'],
    selector: (data, state) =>
      R.pipe(
        R.assocPath<FormsAssigneesFilterDict, FormsStateType>(
          ['filters'],
          parseFilterStats(data.filter_stats, data.assignee_stats, state.forms),
        ),
        R.assocPath(
          ['currentForm', 'delegationNotifications'],
          parseDelegationNotifications(
            data.delegation_notification_stats || [],
          ),
        ),
        R.assocPath(
          ['currentForm', 'categories'],
          parseCategories(data.categories || []),
        ),
      )(state.forms),
  },
});
