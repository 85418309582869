import ProgressBar from '../ProgressBar';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Review from './Review';
import { CategoryDelegator } from '../Delegator';
import { DelegationActionsType } from 'pages/FormPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormsUpdateCategoryReviewStatusFunctionType } from 'store/forms/actions';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  DelegatorWrapper,
  Filling,
  Open,
  Name,
  Section,
  Wrapper,
} from './CategoryHeader.styles';
import {
  CategoryType,
  PromptInviteDelegationType,
  TranslatePropsType,
  FormAssignee,
  Uuid,
} from 'common/common.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CategoryHeaderPropsType
  extends TranslatePropsType,
    DelegationActionsType {
  isOpen?: boolean;
  isSingle?: boolean;
  onInviteUserClick: (
    e: React.MouseEvent<HTMLElement>,
    prompt: PromptInviteDelegationType,
  ) => void;
  updateCategoryReviewStatus: FormsUpdateCategoryReviewStatusFunctionType;
  assignees: FormAssignee[];
  canSendDelegationInvitation: boolean;
  category: CategoryType;
  formId: Uuid;
  hasFilters: boolean;
  isDelegable: boolean;
  isReviewable: boolean;
}

const CategoryHeader = ({
  isOpen,
  isSingle,
  onInviteUserClick,
  updateCategoryReviewStatus,
  assignees,
  canSendDelegationInvitation,
  category,
  category: {
    name,
    questionsCount,
    questionsFilledCount,
    questionsIgnoreFilterCount,
    reviewStatus: { status },
  },
  formId,
  hasFilters,
  isDelegable,
  isReviewable,
  t,
}: CategoryHeaderPropsType) => {
  if (isSingle && !name) {
    return null;
  }

  const isAssignable =
    isDelegable && assignees && questionsIgnoreFilterCount > 0;

  const faChevronDownProp = faChevronDown as IconProp;
  const nameLabel = name ? name : t('Form.defaultCategory');

  return (
    <Wrapper data-cy={`category-${name}`} data-open={`${isOpen}`}>
      {isReviewable && (
        <Review
          currentStatus={status}
          categoryName={name}
          updateCategoryReviewStatus={updateCategoryReviewStatus}
          formId={formId}
        />
      )}

      <Section>
        <Open isOpen={isOpen}>
          <FontAwesomeIcon icon={faChevronDownProp} />
        </Open>

        <Name>{nameLabel}</Name>
      </Section>

      <Filling data-cy="filling">
        {hasFilters ? (
          <p>
            {ReactHtmlParser(
              t('Request.showingQuestionsHTML', {
                questionsCount,
                questionsIgnoreFilterCount,
              }),
            )}
          </p>
        ) : (
          <ProgressBar
            chunk={questionsFilledCount}
            count={questionsCount}
            title={ReactHtmlParser(
              t('Request.questionsAnsweredHTML', {
                questionsFilledCount,
                questionsCount,
              }),
            )}
          />
        )}
      </Filling>

      <DelegatorWrapper>
        {isAssignable && (
          <CategoryDelegator
            assignees={assignees}
            canInvite={canSendDelegationInvitation}
            category={category}
            formId={formId}
            onInviteUserClick={onInviteUserClick}
          />
        )}
      </DelegatorWrapper>
    </Wrapper>
  );
};

export default withTranslation()(React.memo(CategoryHeader));
