import React from 'react';
import Audit, { AuditInputPropsType } from './Audit';
import { CommentAuditType } from 'common/common.types';

const Comment = ({
  change: { createdAt, value, user },
  t,
}: AuditInputPropsType) => {
  const { text } = value as CommentAuditType;
  return (
    <Audit
      title={t('comment.newMessage', { username: user.displayName })}
      date={createdAt}
    >
      {text}
    </Audit>
  );
};

export default Comment;
