import { useCallback, useState } from 'react';
import { CONFIRM_MODAL_ID } from 'common/common.constants';

export function useConfirmModal<T extends (...args: any) => any>(
  callback: T,
  showModal: (modalId: string) => void,
  hideModal: (modalId: string) => void,
  modalId: string = CONFIRM_MODAL_ID,
) {
  const [valueToConfirm, setValueToConfirm] = useState<Parameters<T> | null>(
    null,
  );

  const showConfirm = useCallback(
    (...args: Parameters<T>) => {
      setValueToConfirm(args);
      showModal(modalId);
    },
    [modalId, setValueToConfirm, showModal],
  );

  const onConfirm = useCallback(() => {
    if (valueToConfirm) {
      callback(...valueToConfirm);
      hideModal(modalId);
      setValueToConfirm(null);
    }
  }, [callback, hideModal, modalId, setValueToConfirm, valueToConfirm]);

  const onCancel = useCallback(() => {
    hideModal(modalId);
    setValueToConfirm(null);
  }, [hideModal, modalId, setValueToConfirm]);

  return {
    onCancel,
    onConfirm,
    showConfirm,
    valueToConfirm,
  };
}
