import { any, contains } from 'ramda';
import {
  ResponseDefinitionSummaryType,
  ValueLabelType,
} from 'common/common.types';
import { ResponseTypeDefinitionResponseTypeEnum as ResponseType } from 'generated/models/ResponseTypeDefinition';
import TextResponse from './TextResponse';
import SelectResponse from './SelectResponse';
import PercentageResponse from './PercentageResponse';
import NumberResponse from './Number';
import unreachableReturn from 'utils/unreachableReturn';

export enum ResponseValueEnum {
  EVIDENCE = 'evidence',
  MULTI_SELECT = 'multi_select',
  NUMBER = 'numeric',
  TEXT = 'text',
  SINGLE_SELECT = 'single_select',
  PERCENTAGE = 'percentage',
  NO_RESPONSE = 'no_response',
}

export interface ResponseOptionType extends ValueLabelType<ResponseValueEnum> {
  responseType: ResponseType;
}

export const RESPONSE_WITH_OPTIONS = [
  ResponseValueEnum.MULTI_SELECT,
  ResponseValueEnum.SINGLE_SELECT,
];

export const TEXT = {
  value: ResponseValueEnum.TEXT,
  label: 'text',
  responseType: ResponseType.TEXT,
};

export const SINGLE_SELECT = {
  value: ResponseValueEnum.SINGLE_SELECT,
  label: 'singleSelect',
  responseType: ResponseType.SINGLESELECT,
};

export const MULTI_SELECT = {
  value: ResponseValueEnum.MULTI_SELECT,
  label: 'multiSelect',
  responseType: ResponseType.MULTISELECT,
};

export const NUMBER = {
  value: ResponseValueEnum.NUMBER,
  label: 'number',
  responseType: ResponseType.NUMBER,
};

export const PERCENTAGE = {
  value: ResponseValueEnum.PERCENTAGE,
  label: 'percentage',
  responseType: ResponseType.PERCENTAGE,
};

export const EVIDENCE = {
  value: ResponseValueEnum.EVIDENCE,
  label: 'evidence',
  responseType: ResponseType.EVIDENCE,
};

export const NO_RESPONSE = {
  value: ResponseValueEnum.NO_RESPONSE,
  label: 'noResponse',
  responseType: ResponseType.NORESPONSE,
};

export const RESPONSE_OPTIONS: ResponseOptionType[] = [
  TEXT,
  SINGLE_SELECT,
  MULTI_SELECT,
  NUMBER,
  PERCENTAGE,
  EVIDENCE,
];

export const hasResponseTypeOptions = ({ value }: ResponseOptionType) =>
  contains(value, RESPONSE_WITH_OPTIONS);

export const hasNoResponses = (data: ResponseDefinitionSummaryType[]) =>
  any(
    ({ definition: { responseType } }) =>
      getOption(responseType) === NO_RESPONSE,
    data,
  );

export const getOption = (responseType: ResponseType): ResponseOptionType => {
  switch (responseType) {
    case ResponseType.EVIDENCE:
      return EVIDENCE;
    case ResponseType.MULTISELECT:
      return MULTI_SELECT;
    case ResponseType.NORESPONSE:
      return NO_RESPONSE;
    case ResponseType.NUMBER:
      return NUMBER;
    case ResponseType.PERCENTAGE:
      return PERCENTAGE;
    case ResponseType.SINGLESELECT:
      return SINGLE_SELECT;
    case ResponseType.TEXT:
      return TEXT;
    default:
      return unreachableReturn(responseType, TEXT);
  }
};

export const getResponseComponent = ({ value }: ResponseOptionType) => {
  switch (value) {
    case ResponseValueEnum.TEXT:
      return TextResponse;
    case ResponseValueEnum.SINGLE_SELECT:
    case ResponseValueEnum.MULTI_SELECT:
      return SelectResponse;
    case ResponseValueEnum.PERCENTAGE:
      return PercentageResponse;
    case ResponseValueEnum.NUMBER:
      return NumberResponse;
    case ResponseValueEnum.EVIDENCE:
    case ResponseValueEnum.NO_RESPONSE:
    default:
      return null;
  }
};
