/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FormDetailFillingTypeEnum } from './';
import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormShortSummary
 */
export interface FormShortSummary {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof FormShortSummary
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof FormShortSummary
     */
    updatedAt: Date;
    /**
     * Description of the form
     * @type {string}
     * @memberof FormShortSummary
     */
    description: string;
    /**
     * Name of the form displayed in the GUI (usually corresponds to the standard name)
     * @type {string}
     * @memberof FormShortSummary
     */
    displayName: string;
    /**
     * - `MASTER` form represents form with preferred responses to existing standards.
     *   It is considered as part of the knowledge base.
     *   Every company has exactly one MASTER form for:
     *   - every Standard - SYSTEM
     *   - every Standard - CUSTOM - if they have received request with it
     * 
     * - `UPLOAD` form represents uploaded file from which questions and responses have been
     *   extracted. It is considered as part of the knowledge base.
     * 
     * - `REQUEST` form represents form associated with Request.W
     *   It is always associated with some standard.
     * 
     * - `MAPPING_SPLIT` form is deprecated. **DO NOT USE.**
     *   It used to represent temporary form that was created for the purpose of mapping.
     * 
     * - `SNAPSHOT` form represents read only version of some other form.
     *   It is created when MASTER form is shared or for storing SSC Issues
     * 
     * - `PREVIEW` form is used in Send Request flow to see how the form will look like for Vendor
     *   Every company has exactly one PREVIEW form for:
     *   - every Standard - SYSTEM
     *   - every Standard - CUSTOM - they own
     * 
     * - `DELETED` form is deleted form.
     *   It should not be shown to users and not used for anything
     * 
     * - `UPLOAD_MERGED` form represents form that was uploaded and then was merged.
     *   It starts as form of type UPLOAD. If user uploaded in the past exactly the same form then
     *   responses from this form are used to update the first form. After that it changes its
     *   status to UPLOAD_MERGED
     * @type {string}
     * @memberof FormShortSummary
     */
    fillingType: FormDetailFillingTypeEnum;
    /**
     * Indicates an association with an evidence locker entity
     * @type {boolean}
     * @memberof FormShortSummary
     */
    hasEvidenceLockerEntity: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormShortSummary
     */
    id: string;
    /**
     * Name of the form
     * @type {string}
     * @memberof FormShortSummary
     */
    name: string;
    /**
     * ID of the owner company (usually the vendor company)
     * @type {string}
     * @memberof FormShortSummary
     */
    ownerId: string;
    /**
     * ID of the related standard
     * @type {string}
     * @memberof FormShortSummary
     */
    standardId: string;
}

export function FormShortSummaryFromJSON(json: any): FormShortSummary {
    return FormShortSummaryFromJSONTyped(json, false);
}

export function FormShortSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormShortSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'description': json['description'],
        'displayName': json['display_name'],
        'fillingType': json['filling_type'],
        'hasEvidenceLockerEntity': json['has_evidence_locker_entity'],
        'id': json['id'],
        'name': json['name'],
        'ownerId': json['owner_id'],
        'standardId': json['standard_id'],
    };
}

export function FormShortSummaryToJSON(value?: FormShortSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'description': value.description,
        'display_name': value.displayName,
        'filling_type': value.fillingType,
        'has_evidence_locker_entity': value.hasEvidenceLockerEntity,
        'id': value.id,
        'name': value.name,
        'owner_id': value.ownerId,
        'standard_id': value.standardId,
    };
}

/**
* @export
* @enum {string}
*/
export enum FormShortSummaryFillingTypeEnum {
    DELETED = 'DELETED',
    MAPPINGSPLIT = 'MAPPING_SPLIT',
    MASTER = 'MASTER',
    PREVIEW = 'PREVIEW',
    REQUEST = 'REQUEST',
    SNAPSHOT = 'SNAPSHOT',
    UPLOAD = 'UPLOAD',
    UPLOADMERGED = 'UPLOAD_MERGED'
}


