import React from 'react';
import Tooltip from '../../common/Tooltip';
import { Header, Name, Title } from './BarHeader.styles';
import lightTheme from '../../../common/themes/light';
import { getTextWidth } from 'utils/getTextWidth';

export interface BarHeaderPropsType {
  className?: string;
  name: string;
  nameLink?: string;
  title?: string;
  titleLink?: string;
}

const BarHeader = ({
  className,
  name,
  nameLink,
  title,
  titleLink,
}: BarHeaderPropsType) => {
  const showTooltip =
    getTextWidth(name, lightTheme.fonts.regular.small) > 140 ||
    (title && getTextWidth(title, lightTheme.fonts.bold.small) > 140);

  const headerBody = (
    <Header className={className}>
      {title && (
        <Title
          href={titleLink}
          data-test={titleLink ? 'title-link' : 'title-no-link'}
        >
          {title}
        </Title>
      )}
      <Name href={nameLink} data-test={nameLink ? 'name-link' : 'name-no-link'}>
        {name}
      </Name>
    </Header>
  );

  return showTooltip ? (
    <Tooltip
      overlay={
        <div>
          {title && <strong>{title}</strong>}
          <div>{name}</div>
        </div>
      }
    >
      {headerBody}
    </Tooltip>
  ) : (
    headerBody
  );
};

export default BarHeader;
