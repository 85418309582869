/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";

/**
 * 
 * @export
 * @interface FormShortSummary
 */
export interface FormShortSummary {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof FormShortSummary
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof FormShortSummary
     */
    updated_at: Date;
    /**
     * Description of the form
     * @type {string}
     * @memberof FormShortSummary
     */
    description: string;
    /**
     * Name of the form displayed in the GUI (usually corresponds to the standard name)
     * @type {string}
     * @memberof FormShortSummary
     */
    display_name: string;
    /**
     * - `MASTER` form represents form with preferred responses to existing standards.
     *   It is considered as part of the knowledge base.
     *   Every company has exactly one MASTER form for:
     *   - every Standard - SYSTEM
     *   - every Standard - CUSTOM - if they have received request with it
     * 
     * - `UPLOAD` form represents uploaded file from which questions and responses have been
     *   extracted. It is considered as part of the knowledge base.
     * 
     * - `REQUEST` form represents form associated with Request.W
     *   It is always associated with some standard.
     * 
     * - `MAPPING_SPLIT` form is deprecated. **DO NOT USE.**
     *   It used to represent temporary form that was created for the purpose of mapping.
     * 
     * - `SNAPSHOT` form represents read only version of some other form.
     *   It is created when MASTER form is shared or for storing SSC Issues
     * 
     * - `PREVIEW` form is used in Send Request flow to see how the form will look like for Vendor
     *   Every company has exactly one PREVIEW form for:
     *   - every Standard - SYSTEM
     *   - every Standard - CUSTOM - they own
     * 
     * - `DELETED` form is deleted form.
     *   It should not be shown to users and not used for anything
     * 
     * - `UPLOAD_MERGED` form represents form that was uploaded and then was merged.
     *   It starts as form of type UPLOAD. If user uploaded in the past exactly the same form then
     *   responses from this form are used to update the first form. After that it changes its
     *   status to UPLOAD_MERGED
     * @type {string}
     * @memberof FormShortSummary
     */
    filling_type: FormShortSummaryFillingTypeEnum;
    /**
     * Indicates an association with an evidence locker entity
     * @type {boolean}
     * @memberof FormShortSummary
     */
    has_evidence_locker_entity: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormShortSummary
     */
    id: string;
    /**
     * Name of the form
     * @type {string}
     * @memberof FormShortSummary
     */
    name: string;
    /**
     * ID of the owner company (usually the vendor company)
     * @type {string}
     * @memberof FormShortSummary
     */
    owner_id: string;
    /**
     * ID of the related standard
     * @type {string}
     * @memberof FormShortSummary
     */
    standard_id: string;
}




/**
* @export
* @enum {string}
*/
export enum FormShortSummaryFillingTypeEnum {
    DELETED = 'DELETED',
    MAPPINGSPLIT = 'MAPPING_SPLIT',
    MASTER = 'MASTER',
    PREVIEW = 'PREVIEW',
    REQUEST = 'REQUEST',
    SNAPSHOT = 'SNAPSHOT',
    UPLOAD = 'UPLOAD',
    UPLOADMERGED = 'UPLOAD_MERGED'
}


