import FiltersDefault, { FiltersDefaultPropsType } from './Filters.Default';
import { withTranslation } from 'react-i18next';
import { isDateFilter } from 'utils/filters/requests.filters';
import Chip from '../../Form/Filter/Chip';
import React from 'react';
import { DATE_FORMAT } from 'common/common.constants';
import { format } from 'date-fns';
import { StateType } from '../../../store/store.types';
import { getLanguagePreference } from '../../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import { enUS, ja } from 'date-fns/locale';
const locales = { 'en-US': enUS, 'ja-JP': ja };
const getContext = (from?: string, to?: string) => {
  if (from && to) {
    return 'fromto';
  } else if (from) {
    return 'from';
  } else if (to) {
    return 'to';
  }
  return undefined;
};

const FiltersStatus = ({
  filterBy,
  filterOptions,
  handleDelete,
  option,
  language,
  t,
}: FiltersDefaultPropsType) => {
  const locale = language ? locales[language.value] : enUS;
  if (isDateFilter(filterBy)) {
    const { from, interval, to } = filterBy;
    return (
      <Chip
        key={`chip-${option}-${interval}`}
        label={t(`Requests.dateFilter.${interval}`, {
          context: getContext(from, to),
          from: from
            ? format(new Date(from), DATE_FORMAT, { locale })
            : undefined,
          to: to ? format(new Date(to), DATE_FORMAT, { locale }) : undefined,
        })}
        onDelete={() => handleDelete(option, interval)}
      />
    );
  }
  return (
    <FiltersDefault
      filterBy={filterBy}
      filterOptions={filterOptions}
      handleDelete={handleDelete}
      option={option}
    />
  );
};
const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});
export default connect(
  mapStateToProps,
  null,
)(withTranslation('components')(FiltersStatus));
