import { identity, memoizeWith } from 'ramda';

// eslint-disable-next-line import/prefer-default-export
export const loadScript = memoizeWith(identity, (url, loadTimeout = 30000) => {
  const src = url;
  return new Promise((resolve, reject) => {
    let done = false;
    function loaded() {
      if (done) {
        return;
      }
      // set done flag to avoid ambiguous callbacks
      done = true;
      setTimeout(resolve, 100);
    }
    function failed(err: Error) {
      if (done) {
        return;
      }
      // set done flag to avoid ambiguous callbacks
      done = true;
      reject(err);
    }
    try {
      const scriptTag = document.createElement('script');
      // prepare script tag element
      scriptTag.onload = loaded;
      scriptTag.onerror = () =>
        failed(new Error(`Failed to load script: ${src}`));
      scriptTag.src = src;
      document.body.appendChild(scriptTag);
      setTimeout(() => {
        failed(new Error(`Timeout error when loading script: ${src}`));
      }, loadTimeout);
    } catch (err) {
      reject(err);
    }
  });
});
