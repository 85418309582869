import { Wrapper } from './AutosavedInfo.styles';
import { formatDistanceToNow, Locale } from 'date-fns';
import { LanguageType, TranslatePropsType } from 'common/common.types';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import { enUS, ja } from 'date-fns/locale';
import { StateType } from '../../../store/store.types';
import { getLanguagePreference } from '../../../store/settings/settings.selectors';
import { connect } from 'react-redux';

const locales = { 'en-US': enUS, 'ja-JP': ja };

export interface AutosavedInfoPropsType extends TranslatePropsType {
  className?: string;
  lastUpdate: Date;
  isSaving?: boolean;
  isInErrorState?: boolean;
  language?: LanguageType;
}

const REFRESH_INTERVAL = 10 * 1000;

const getDateAgo = (lastUpdate: Date, locale: Locale = enUS) =>
  formatDistanceToNow(lastUpdate, { includeSeconds: true, locale });

const AutosavedInfo = ({
  className,
  lastUpdate,
  isSaving = false,
  isInErrorState = false,
  language,
  t,
}: AutosavedInfoPropsType) => {
  const locale = language ? locales[language.value] : enUS;

  const [dateAgo, setDateAgo] = useState(getDateAgo(lastUpdate, locale));

  useEffect(() => {
    setDateAgo(getDateAgo(lastUpdate, locale));

    const intervalId = window.setInterval(() => {
      setDateAgo(getDateAgo(lastUpdate, locale));
    }, REFRESH_INTERVAL);

    return () => window.clearInterval(intervalId);
  }, [lastUpdate, locale]);

  const text = isInErrorState
    ? (t('savingError') as string)
    : isSaving
    ? (t('saving') as string)
    : t('autosaved', { dateAgo });

  const tooltip = isInErrorState ? (t('savingErrorTooltip') as string) : '';

  return (
    <Wrapper
      className={className}
      isInErrorState={isInErrorState}
      title={tooltip}
    >
      {text}
    </Wrapper>
  );
};
const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});
export default connect(
  mapStateToProps,
  null,
)(withTranslation('AutosavedInfo')(AutosavedInfo));
