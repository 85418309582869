import * as fa from '../store/forms/actions';
import * as fp from '../store/formProxy/actions';
import axios from 'axios';
import multipartHeaders from '../utils/url/multipartHeaders';
import withUrl from '../utils/url/withUrl';
import { FormsPostAttachmentCallParamsType } from '../store/forms/actions/postAttachment';
import { contains, without } from 'ramda';
import { Uuid } from '../common/common.types';
import urlize from '../utils/url/urlize';

const arrayOrUndefined = <T>(values?: T[]) =>
  values && values.length > 0 ? values : undefined;

/**
 * TODO: Split this into two separate functions with clear types boundaries.
 * TODO: omit arrayOfUndefined when the bug with empty comma fixed in urlize (qs.stringify).
 * See:
 * - https://github.com/ljharb/qs/issues/310
 * - https://github.com/ljharb/qs/pull/350 waiting to be merged
 */
export const getFormURL = (endpoint: '' | 'questions') => ({
  assigneeIds,
  blocks,
  category,
  filters,
  id,
  ids,
  limit,
  offset,
  questionIds,
}: fa.FormGetQuestionsParamsType & fa.FormsGetFormCallParams) => {
  const filtersWithoutNewly = filters
    ? without(['newly_autocompleted'], filters)
    : [];
  const newlyAutocompletedIds =
    filters && contains('newly_autocompleted', filters) && ids ? ids : [];

  const params = urlize({
    assignee_id: arrayOrUndefined(assigneeIds),
    blocks: arrayOrUndefined(blocks),
    category,
    filters: arrayOrUndefined(filtersWithoutNewly),
    ids: arrayOrUndefined(newlyAutocompletedIds),
    limit,
    offset,
    question_ids: arrayOrUndefined(questionIds),
  });

  return `forms/${id}${endpoint === 'questions' ? '/questions' : ''}?${params}`;
};

export default {
  attachments: {
    post: ({ formData, formId }: FormsPostAttachmentCallParamsType) =>
      axios.post(
        withUrl(`forms/${formId}/attachments`),
        formData,
        multipartHeaders,
      ),
  },
  categories: {
    setReviewStatus: ({
      category,
      formId,
      status,
    }: fa.FormsUpdateCategoryReviewStatusCallParamsType) =>
      axios.put(withUrl(`forms/${formId}/questions`), { category, status }),
  },
  clearAnswers: ({ id }: { id: Uuid }) =>
    axios.delete(withUrl(`forms/${id}/responses`)),
  delegations: {
    assignees: ({ formId }: fa.FormsGetAssigneesCallParamsType) =>
      axios.get(withUrl(`forms/${formId}/delegation/users`)),
    categories: {
      delete: ({
        formId,
        params,
      }: fa.FormsUnassignCategoryDelegationCallParamsType) =>
        axios.delete(withUrl(`forms/${formId}/delegation/delegations`), {
          data: params,
        }),
      put: ({
        formId,
        params,
      }: fa.FormsAssignCategoryDelegationCallParamsType) =>
        axios.put(withUrl(`forms/${formId}/delegation/delegations`), params),
    },
    form: {
      delete: ({ formId }: fa.FormsUnassignFormDelegationCallParamsType) =>
        axios.delete(withUrl(`forms/${formId}/delegation/delegations`), {
          data: {},
        }),
      put: ({ formId, params }: fa.FormsAssignFormDelegationCallParamsType) =>
        axios.put(withUrl(`forms/${formId}/delegation/delegations`), params),
    },
    notifications: {
      delete: ({
        formId,
        params,
      }: fa.FormsClearDelegationNotificationsCallParamsType) =>
        axios.delete(withUrl(`forms/${formId}/delegation/notify`), {
          data: params,
        }),
      send: ({
        formId,
        params,
      }: fa.FormsSendDelegationNotificationsCallParamsType) =>
        axios.post(withUrl(`forms/${formId}/delegation/notify`), params),
    },
    questions: {
      delete: ({
        formId,
        params,
      }: fa.FormsUnassignQuestionDelegationCallParamsType) =>
        axios.delete(withUrl(`forms/${formId}/delegation/delegations`), {
          data: params,
        }),
      put: ({
        formId,
        params,
      }: fa.FormsAssignQuestionDelegationCallParamsType) =>
        axios.put(withUrl(`forms/${formId}/delegation/delegations`), params),
    },
  },
  get: (params: fa.FormsGetFormCallParams) =>
    axios.get(withUrl(getFormURL('')(params))),
  history: ({ formId }: fa.FormsGetHistoryCallParamsType) =>
    axios.get(withUrl(`forms/${formId}/history`)),
  proxies: {
    delete: ({ formId, proxyId }: fp.FormProxyRevokeAccessCallParamsType) =>
      axios.delete(withUrl(`forms/${formId}/proxies/${proxyId}`)),
    list: ({ formId }: fp.FormProxyShowProxyModalCallParamsType) =>
      axios.get(withUrl(`forms/${formId}/collaborators`)),
    notify: ({
      formId,
      proxyId,
      emailBody,
    }: fp.FormProxyNotifyProxyCallParamsType) =>
      axios.post(withUrl(`forms/${formId}/proxies/${proxyId}/notify`), {
        email_body: emailBody,
      }),
    post: ({
      emailBody,
      formId,
      userEmail,
      proxyRoles,
    }: fp.FormProxyGrantAccessCallParamsType) =>
      axios.post(withUrl(`forms/${formId}/proxies`), {
        user_email: userEmail,
        email_body: emailBody,
        proxy_roles: proxyRoles,
      }),
  },
  questions: {
    addAttachment: ({
      formId,
      questionId,
      formData,
    }: fa.FormsCreateQuestionAttachmentCallParamsType) =>
      axios.post(
        withUrl(`forms/${formId}/questions/${questionId}/attachments`),
        formData,
        multipartHeaders,
      ),
    addResponseAttachment: ({
      formId,
      responseId,
      formData,
    }: fa.FormsCreateResponseAttachmentCallParamsType) =>
      axios.post(
        withUrl(`forms/${formId}/responses/${responseId}/attachments`),
        formData,
        multipartHeaders,
      ),
    answer: ({ id, data }: fa.FormsPutQuestionsCallParamsType) =>
      axios.put(withUrl(`forms/${id}/responses/${data.response_id}`), data),
    comment: ({ id, text, formId }: fa.FormsCreateMessageCallParamsType) =>
      axios.post(withUrl(`forms/${formId}/questions/${id}/comments`), {
        text,
      }),
    list: (params: fa.FormGetQuestionsParamsType) =>
      axios.get(withUrl(getFormURL('questions')(params))),
    setReviewStatus: ({
      questionId,
      formId,
      status,
    }: fa.FormsUpdateQuestionReviewStatusCallParamsType) =>
      axios.put(withUrl(`forms/${formId}/questions/${questionId}`), {
        status,
      }),
    mappings: ({
      formId,
      questionId,
    }: fa.FormsGetQuestionMappingsCallParamsType) =>
      axios.get(withUrl(`forms/${formId}/questions/${questionId}/mappings`)),
    rateMapping: ({
      sourceId,
      targetId,
      rating,
      formId,
      questionMappingPos,
      responseValue,
      responsePos,
    }: fa.FormsRateMappingCallParamsType) =>
      axios.post(
        withUrl(`forms/${formId}/questions/${targetId}/mappings/feedback`),
        {
          source_question_id: sourceId,
          rating: rating,
          question_mapping_pos: questionMappingPos,
          response_value: responseValue,
          response_pos: responsePos,
        },
      ),
  },
  rename: ({ name, formId }: fa.FormsRenameFormCallParamsType) =>
    axios.put(withUrl('forms', formId), { name }),
  setBlockSeen: (params: fa.FormsSetBlockSeenCallParamsType) =>
    axios.post(withUrl(`forms/${params.formId}/actions/track_seen`), params),
  upload: ({ formData, team_id }: fa.FormsUploadFormCallParamsType) =>
    axios.post(
      withUrl(`forms/mappings?${urlize({ team_id })}`),
      formData,
      multipartHeaders,
    ),
  uploadAndMap: {
    get: ({
      id,
    }:
      | fa.FormsGetUploadAndMapStatusCallParamsType
      | fa.FormsGetUploadFormStatusCallParamsType) =>
      axios.get(withUrl(`forms/mappings/${id}`)),
  },
};
