import styled from 'styled-components';

export const AttachmentWrapper = styled.div`
  padding: 6px 0;
  white-space: nowrap;
`;

export const AttachmentPlaceholder = styled(AttachmentWrapper)`
  display: flex;
  align-items: center;

  span {
    opacity: 0.3;
    padding-left: 6px;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  border-bottom: 1px solid #ddd;
`;

export const Remove = styled.span`
  color: #555;
  padding-left: 8px;
  cursor: pointer;
`;
