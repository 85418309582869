import axios from 'axios';
import config from '../config';
import * as R from 'ramda';

export default {
  getFactors: () => axios.get(`${config.hostname}/metadata/factors`),
  getIssueTypes: () => axios.get(`${config.hostname}/metadata/issue-types`),
  getFactorsWithIssues: () =>
    axios.get(`${config.hostname}/metadata/factors`).then(factorsResponse =>
      axios
        .get(`${config.hostname}/metadata/issue-types`)
        .then(issuesResponse => {
          const issues = issuesResponse.data.entries;
          const issuesGroupedByFactor = R.groupBy(R.prop('factor'), issues);
          const entries = factorsResponse.data.entries.map(
            (factor: { key: string | number; name: string }) => ({
              ...factor,
              title: factor.name,
              issues: issuesGroupedByFactor[factor.key] || [],
            }),
          );

          return {
            ...factorsResponse,
            data: { entries },
          };
        }),
    ),
};
