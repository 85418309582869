import { Header, Section, Title } from './FilterBy.styles';
import React from 'react';
import { withTranslation } from 'react-i18next';

import {
  StandardStatusType,
  TranslatePropsType,
} from '../../../common/common.types';
import { FormDefinitionsFilterByStatusFunctionType } from '../../../store/formDefinitions/actions';
import { DEFAULT_STANDARDS_STATUS } from '../../../store/formDefinitions/defaultValues';
import { Scrollable } from '../../Requests/Filters/FilterBy.styles';
import Checkbox from '../../common/Checkbox';
import { append, contains, reject } from 'ramda';

export interface FilterByStatus extends TranslatePropsType {
  isClearable?: boolean;
  action: FormDefinitionsFilterByStatusFunctionType;
  filters: StandardStatusType[];
}

const FilterStatus = ({ action, filters, t }: FilterByStatus) => {
  const handleChange = (standard: StandardStatusType) => {
    const query = contains(standard, filters || [])
      ? reject(item => item === standard, filters)
      : append(standard, filters);

    standard ? action(query) : action([]);
  };

  return (
    <Section>
      <Header>
        <Title>{t(`FormDefinitions:filterByStatus`) as string}</Title>
      </Header>

      <Scrollable>
        {DEFAULT_STANDARDS_STATUS!.map((standard: StandardStatusType) => (
          <Checkbox
            key={`${standard}-checkbox`}
            isChecked={Boolean(contains(standard, filters))}
            isCompact
            secondaryColor="teal"
            label={t(`FormDefinitions:statusList.${standard}`) as string}
            labelSize="small"
            onChange={() => handleChange(standard)}
          />
        ))}
      </Scrollable>
    </Section>
  );
};

export default withTranslation()(FilterStatus);
