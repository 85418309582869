/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreditsSummary
 */
export interface CreditsSummary {
    /**
     * Amount of credits that expired before their spending
     * @type {number}
     * @memberof CreditsSummary
     */
    expired: number;
    /**
     * Amount of credits that are about to expire within 30 days
     * @type {number}
     * @memberof CreditsSummary
     */
    expiring: number;
    /**
     * Amount of credits that can be spent
     * @type {number}
     * @memberof CreditsSummary
     */
    fresh: number;
    /**
     * Amount of credits that can be spent and are about to expire within 30 days
     * @type {number}
     * @memberof CreditsSummary
     */
    remaining: number;
    /**
     * Total amount of credits including expired ones
     * @type {number}
     * @memberof CreditsSummary
     */
    total: number;
    /**
     * Amount of credits that have been spent
     * @type {number}
     * @memberof CreditsSummary
     */
    used: number;
}

export function CreditsSummaryFromJSON(json: any): CreditsSummary {
    return CreditsSummaryFromJSONTyped(json, false);
}

export function CreditsSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditsSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expired': json['expired'],
        'expiring': json['expiring'],
        'fresh': json['fresh'],
        'remaining': json['remaining'],
        'total': json['total'],
        'used': json['used'],
    };
}

export function CreditsSummaryToJSON(value?: CreditsSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expired': value.expired,
        'expiring': value.expiring,
        'fresh': value.fresh,
        'remaining': value.remaining,
        'total': value.total,
        'used': value.used,
    };
}


