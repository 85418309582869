import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import { ApiCallActionType } from '../../store.types';
import { HOMEPAGE } from '../../../routes';
import { getInitialState } from '../utils';
import { UserStateType } from '../user.types';

export interface UserLoginActionTypeApiCallParamsType {
  username: string;
  password: string;
}

export type UserLoginActionType = ApiCallActionType<
  '@user/API/LOGIN',
  UserLoginActionTypeApiCallParamsType,
  never,
  never,
  UserStateType
>;

export const login = ({
  username,
  password,
}: UserLoginActionTypeApiCallParamsType): UserLoginActionType => ({
  type: '@user/API/LOGIN',
  payload: {
    endpointPath: ['user', 'login'],
    callParams: { username, password },
    selector: getInitialState,
    postActions: [() => put(push(HOMEPAGE))],
  },
});
