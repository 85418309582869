import { FilterBy, Filters } from './Filters';
import React from 'react';
import SortBy from './SortBy';
import ViewSwitcher from './ViewSwitcher';
import route, { REQUESTS_ARCHIVE } from '../../routes';
import { BarWrapper } from '../common/Bar/Bar.styles';
import { Button } from '../common/Button';
import { Controls, TitleWrapper } from './Header.styles';
import { RequestsFilterType } from 'store/requests/requests.types';
import { withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useFlag from 'utils/hooks/useFlag';
import {
  RequestsSetSortFunctionType,
  RequestsSwitchViewFunctionType,
  RequestsSetFilterFunctionType,
} from 'store/requests/actions';
import {
  LanguageType,
  RequestDirectionType,
  ToggleEnum,
  TranslatePropsType,
  UserType,
} from 'common/common.types';
import {
  FilterOptionsType,
  REQUEST_FILTERS as allowedFilters,
} from 'utils/filters/requests.filters';

interface HeaderType extends TranslatePropsType, RouteComponentProps {
  direction: RequestDirectionType;
  filter: RequestsFilterType;
  filterOptions: FilterOptionsType;
  hasArchivedRequests: boolean;
  showFilter: boolean;
  setFilter: RequestsSetFilterFunctionType;
  setSort: RequestsSetSortFunctionType;
  switchView: RequestsSwitchViewFunctionType;
  isSent: boolean;
  user: UserType;
  language: LanguageType;
}

const Header = ({
  direction,
  filter: { filterBy, sortBy, view },
  filterOptions,
  hasArchivedRequests,
  setFilter,
  setSort,
  showFilter,
  switchView,
  isSent,
  history: { push: navigate },
  t,
  user,
  language,
}: HeaderType) => {
  const teamsEnabled = useFlag(user, [ToggleEnum.INTERNAL_USECASE]);

  return (
    <BarWrapper>
      <TitleWrapper>
        <h2 data-test="page-header">
          {t(`Requests.headings.${direction}`) as string}
        </h2>
        <p>{t(`Requests.captions.${direction}`) as string}</p>
      </TitleWrapper>

      <div>
        <Controls>
          {showFilter && (
            <>
              <FilterBy
                data={{
                  allowedFilters,
                  anchor: 'right',
                  filterBy,
                  filterOptions,
                  isSent,
                }}
                actions={{ setFilter }}
                teamsEnabled={teamsEnabled}
              />

              <SortBy data={{ isSent, sortBy }} actions={{ setSort }} />
            </>
          )}

          {hasArchivedRequests && (
            <Button
              color="transparent"
              onClick={() => navigate(route(REQUESTS_ARCHIVE))}
              size="medium"
            >
              {t('Requests.archivedQuestionnaires')}
            </Button>
          )}

          {showFilter && (
            <ViewSwitcher data={{ view }} actions={{ switchView }} />
          )}
        </Controls>

        {showFilter && (
          <Filters
            allowedFilters={allowedFilters}
            filterBy={filterBy}
            filterOptions={filterOptions}
            isSent={isSent}
            setFilter={setFilter}
          />
        )}
      </div>
    </BarWrapper>
  );
};

export default withTranslation()(withRouter(Header));
