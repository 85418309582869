import React, { useMemo, useCallback } from 'react';
import { Content, Title, Wrapper, Header } from './ConditionsPanel.styles';
import {
  Condition,
  FormCreatorRenderType,
  FormCreatorValidatedQuestionType,
  TranslatePropsType,
} from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { FormCreatorContextActionsType } from 'pages/FormCreatorPage';
import { StructuredEditor, TextEditor } from '../Conditions';
import { QuestionError } from 'store/formCreator/validationError.types';
import { ValidationInfo } from '../Response/Response.styles';
import TextToggle from '../../common/TextToggle';
import { TextToggleItemType } from '../../common/TextToggle/TextToggle';
import { SwitchQuestionConditionTypeFunctionType } from 'store/formCreator/actions';

interface ConditionsPanelPropsType extends TranslatePropsType {
  actions: FormCreatorContextActionsType;
  condition?: Condition;
  errors: QuestionError[];
  question: FormCreatorValidatedQuestionType;
  removeCondition: () => void;
  renderData: FormCreatorRenderType[];
  switchQuestionConditionType: SwitchQuestionConditionTypeFunctionType;
}

type ConditionType = 'structured' | 'text';

const ConditionsPanel = ({
  actions: { updateQuestionCondition, validateForm },
  condition,
  errors,
  question,
  question: { fixAttempted, wizardId },
  renderData,
  switchQuestionConditionType,
  t,
}: ConditionsPanelPropsType) => {
  const modeToggleItems: Array<TextToggleItemType<ConditionType>> = useMemo(
    () => [
      {
        value: 'structured',
        label: t('conditionEditor.editorMode', { context: 'structured' }),
      },
      {
        value: 'text',
        label: t('conditionEditor.editorMode', { context: 'text' }),
      },
    ],
    [t],
  );

  const effectiveConditionType: ConditionType = condition
    ? condition.type
    : 'structured';

  const handleConditionTypeChange = useCallback(
    (type: any) => {
      switchQuestionConditionType(wizardId, type);
    },
    [switchQuestionConditionType, wizardId],
  );

  return (
    <Wrapper>
      {errors.length > 0 && (
        <ValidationInfo
          errors={errors}
          fixAttempted={Boolean(fixAttempted)}
          onValidateClick={validateForm}
        />
      )}
      <Content>
        <Header>
          <Title>{t('conditionEditor.title') as string}</Title>
          <TextToggle
            items={modeToggleItems}
            selectedItem={effectiveConditionType}
            onChange={handleConditionTypeChange}
          />
        </Header>
        {(!condition || condition.type === 'structured') && (
          <StructuredEditor
            condition={condition ? condition.payload : undefined}
            question={question}
            renderData={renderData}
            updateCondition={updateQuestionCondition}
          />
        )}
        {condition && condition.type === 'text' && (
          <TextEditor
            condition={condition.payload}
            questionId={question.wizardId}
            updateCondition={updateQuestionCondition}
          />
        )}
      </Content>
    </Wrapper>
  );
};

export default withTranslation('FormCreator')(React.memo(ConditionsPanel));
