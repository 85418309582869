import React from 'react';

export interface TabPanelPropsType {
  children: React.ReactElement;
  index: number;
  value: number;
}

const TabPanel = ({ children, index, value }: TabPanelPropsType) =>
  index === value ? children : null;

export default TabPanel;
