import { TagType, TaggedType, Uuid } from 'common/common.types';
import { CompanyTagDetail, Tag, TagBase } from 'generated/models';

export const parseTag = (tag: Tag | TagBase): TagType => ({
  id: tag.id,
  name: tag.name,
  type: tag.tag_type,
});

export const parseCompanyTagDetail = (
  tagged: CompanyTagDetail,
): TaggedType => ({
  id: tagged.id,
  targetId: tagged.target_company_id,
  tag: parseTag(tagged.tag),
});

export const parseCompanyTagDetails = (
  tags: CompanyTagDetail[],
): TaggedType[] => tags.map(parseCompanyTagDetail);

export const parseCompanyTag = (tag: Tag, targetId: Uuid): TaggedType => ({
  id: tag.id,
  targetId,
  tag: parseTag(tag),
});

export const parseTags = (tags: Tag[]): TagType[] => tags.map(parseTag);
