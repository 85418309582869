/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface EvidenceShare
 */
export interface EvidenceShare {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof EvidenceShare
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof EvidenceShare
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof EvidenceShare
     */
    code: string;
    /**
     * ID of the evidence shared
     * @type {string}
     * @memberof EvidenceShare
     */
    evidenceId: string;
    /**
     * 
     * @type {string}
     * @memberof EvidenceShare
     */
    id: string;
    /**
     * ID of the company that was sharing
     * @type {string}
     * @memberof EvidenceShare
     */
    ownerId: string;
    /**
     * ID of the user that was sharing
     * @type {string}
     * @memberof EvidenceShare
     */
    sharedById: string;
    /**
     * 
     * @type {number}
     * @memberof EvidenceShare
     */
    status: number;
}

export function EvidenceShareFromJSON(json: any): EvidenceShare {
    return EvidenceShareFromJSONTyped(json, false);
}

export function EvidenceShareFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvidenceShare {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'code': json['code'],
        'evidenceId': json['evidence_id'],
        'id': json['id'],
        'ownerId': json['owner_id'],
        'sharedById': json['shared_by_id'],
        'status': json['status'],
    };
}

export function EvidenceShareToJSON(value?: EvidenceShare | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'code': value.code,
        'evidence_id': value.evidenceId,
        'id': value.id,
        'owner_id': value.ownerId,
        'shared_by_id': value.sharedById,
        'status': value.status,
    };
}


