import { DEFAULT_QUESTION_BLOCKS } from '../../../common/common.constants';
import { DelegationsDeleted } from '../../../generated/models/DelegationsDeleted';
import { assocPath } from 'ramda';
import { call, put } from 'redux-saga/effects';
import { hydrateFilters } from './hydrateFilters';
import { loadQuestions } from './loadQuestions';
import { remapCategories } from '../utils';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { Uuid } from '../../../common/common.types';

export interface FormsUnassignCategoryDelegationCallParamsType {
  formId: Uuid;
  params: {
    category: string;
  };
}

export type FormsUnassignCategoryDelegationFunctionType = (
  formId: Uuid,
  category: string,
) => void;

export type FormsUnassignCategoryDelegationActionType = ApiCallActionType<
  '@forms/API/UNASSIGN_CATEGORY_DELEGATION',
  FormsUnassignCategoryDelegationCallParamsType,
  DelegationsDeleted,
  never,
  FormsStateType
>;

export const unassignCategoryDelegation = (
  formId: Uuid,
  category: string,
): FormsUnassignCategoryDelegationActionType => ({
  type: '@forms/API/UNASSIGN_CATEGORY_DELEGATION',
  payload: {
    callParams: {
      formId,
      params: {
        category,
      },
    },
    endpointPath: ['forms', 'delegations', 'categories', 'delete'],
    selector: ({ category_stats }, { forms }) =>
      assocPath(
        ['currentForm', 'categories'],
        remapCategories(category_stats, forms.currentForm!.categories!),
        forms,
      ),
    postActions: [
      () => put(hydrateFilters(formId)),
      () =>
        put(
          loadQuestions({
            id: formId,
            blocks: DEFAULT_QUESTION_BLOCKS,
            category,
            offset: 0,
          }),
        ),
      () => call(() => trackEvent('Delegation', 'Remove - Form Category')),
    ],
  },
});
