/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface FormHistoryData
 */
export interface FormHistoryData {
    /**
     * 
     * @type {string}
     * @memberof FormHistoryData
     */
    from?: FormHistoryDataFromEnum;
    /**
     * 
     * @type {string}
     * @memberof FormHistoryData
     */
    to?: FormHistoryDataToEnum;
}




/**
* @export
* @enum {string}
*/
export enum FormHistoryDataFromEnum {
    CREATED = 'CREATED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS',
    ACCEPTED = 'ACCEPTED',
    CANCELED = 'CANCELED',
    ARCHIVED = 'ARCHIVED',
    UNARCHIVED = 'UNARCHIVED'
}
/**
* @export
* @enum {string}
*/
export enum FormHistoryDataToEnum {
    CREATED = 'CREATED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS',
    ACCEPTED = 'ACCEPTED',
    CANCELED = 'CANCELED',
    ARCHIVED = 'ARCHIVED',
    UNARCHIVED = 'UNARCHIVED'
}


