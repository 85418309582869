import styled from 'styled-components';
import { TabWrapper } from '../Question/Tab.styles';

interface WrapperType {
  isOpen: boolean;
}

export const Wrapper = styled.div<WrapperType>`
  display: flex;
  flex-direction: column;
  transition: ${({ theme }) => theme.basic.animations.normal};
  ${({ isOpen }) =>
    isOpen
      ? {
          opacity: 1,
        }
      : {
          opacity: 0,
          height: 0,
          display: 'none',
        }};
  background: ${({ theme }) => theme.basic.colors.light};
  border-top: 1px ${({ theme }) => theme.basic.colors.darkerBorder} solid;
  box-sizing: border-box;
  right: 20px;
  top: 50px;
  position: absolute;
  width: 265px;
  z-index: 10000;
`;

export const Item = styled.div`
  cursor: pointer;
  ${({ theme }) => theme.fonts.regular.mini}
  height: 48px;
  background: ${({ theme }) => theme.basic.colors.light};
  border-bottom: 1px ${({ theme }) => theme.basic.colors.darkerBorder} solid;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: 7px 30px 0 0;

  &:hover {
    background-color: ${({ theme }) => theme.inputs.multiselect.default.hover};
  }

  &:hover ${TabWrapper} {
    background-color: ${({ theme }) => theme.basic.colors.grayDark};
  }
`;

export const Label = styled.div`
  padding: 10px 10px 0 0;
`;
