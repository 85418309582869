import { ApiCallActionType } from '../../store.types';
import { getForm } from './getForm';
import { put } from 'redux-saga/effects';
import { Uuid } from '../../../common/common.types';

export type FormsGetPreviewFunctionType = (id: Uuid) => void;

export type FormsGetPreviewActionType = ApiCallActionType<
  '@forms/API/GET_PREVIEW',
  { id: Uuid }
>;

export const getPreview = (id: Uuid): FormsGetPreviewActionType => ({
  type: '@forms/API/GET_PREVIEW',
  payload: {
    callParams: { id },
    endpointPath: ['forms', 'clearAnswers'],
    selector: null,
    postActions: [() => put(getForm({ id }))],
  },
});
