/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";

/**
 * 
 * @export
 * @interface RequestFinding
 */
export interface RequestFinding {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof RequestFinding
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof RequestFinding
     */
    updated_at: Date;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    owner_id: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    remediation_plan?: RequestFindingRemediationPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    remediation_plan_note: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    request_id: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    risk_level?: RequestFindingRiskLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    risk_level_note: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    user_id: string;
}




/**
* @export
* @enum {string}
*/
export enum RequestFindingRemediationPlanEnum {
    REMEDIATE = 'REMEDIATE',
    ACCEPT = 'ACCEPT',
    COMPLETE = 'COMPLETE'
}
/**
* @export
* @enum {string}
*/
export enum RequestFindingRiskLevelEnum {
    CRITICAL = 'CRITICAL',
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
    NONE = 'NONE'
}


