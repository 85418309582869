import Datepicker from '../../common/Datepicker/Datepicker';
import React from 'react';
import { ClickableLabel, LabelSpaced } from '../Stepper.styles';
import { DATE_FORMAT } from 'common/common.constants';
import { SetFormValueFunctionType } from 'store/requests/actions';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface SendDatePropsType extends TranslatePropsType {
  sendDate: Date;
  setValue: SetFormValueFunctionType;
}

const SendDate = ({ sendDate, setValue, t }: SendDatePropsType) => (
  <div data-cy="sq-send-date">
    <LabelSpaced>{t('sendDate') as string}</LabelSpaced>

    <ClickableLabel>
      <Datepicker
        data-cy="sq-send-date-picker"
        className="datepicker-input"
        dateFormat={DATE_FORMAT}
        onChange={val => setValue('sendDate', val || new Date())}
        selected={sendDate}
        minDate={new Date()}
      />
    </ClickableLabel>
  </div>
);

export default withTranslation('SendRequest')(SendDate);
