import { contains } from 'ramda';
import React, { useRef } from 'react';
import Tooltip from '../../common/Tooltip';
import { TranslatePropsType, FormAssignee, Uuid } from 'common/common.types';
import { useOnClickOutside } from 'utils/useOnClickOutside';
import { withTranslation } from 'react-i18next';
import {
  Actions,
  ActionRow,
  Assignable,
  AssignableState,
  AssignedIcon,
  InactiveIcon,
  InactiveWrapper,
  Name,
  Wrapper,
} from './Assignees.styles';

interface AssigneesPropsType {
  assign: (e: React.MouseEvent<HTMLElement>, userId: Uuid) => void;
  assignees: FormAssignee[];
  assigneesIds: Uuid[];
  canInvite: boolean;
  hasRemove: boolean;
  invite: (e: React.MouseEvent<HTMLElement>) => void;
  setDelegatorOpen: (state: boolean) => void;
  unassign: (e: React.MouseEvent<HTMLElement>) => void;
}

const Assignees = ({
  assign,
  assignees,
  assigneesIds,
  canInvite,
  hasRemove,
  invite,
  setDelegatorOpen,
  unassign,
  t,
}: AssigneesPropsType & TranslatePropsType) => {
  const ref = useRef(null);

  useOnClickOutside(ref, () => setDelegatorOpen(false));

  const onClick = (assigneeId: Uuid) => (e: React.MouseEvent<HTMLElement>) => {
    if (contains(assigneeId, assigneesIds)) {
      unassign(e);
    } else {
      assign(e, assigneeId);
    }
  };

  const tooltipTextLabel = t('tooltipText') as string;
  const removeLabel = t('remove') as string;

  return (
    <Wrapper ref={ref}>
      {assignees.map(({ id, displayName, isActive }) => (
        <Assignable
          key={`assignable-${id}`}
          onClick={onClick(id)}
          isInactive={!isActive}
        >
          <AssignableState>
            {contains(id, assigneesIds) && <AssignedIcon />}
          </AssignableState>

          <Name>{displayName}</Name>

          {!isActive && (
            <InactiveWrapper>
              <Tooltip overlay={<span>{tooltipTextLabel}</span>}>
                <InactiveIcon />
              </Tooltip>
            </InactiveWrapper>
          )}
        </Assignable>
      ))}

      <Actions>
        {unassign && hasRemove && (
          <ActionRow onClick={unassign}>{removeLabel}</ActionRow>
        )}

        {canInvite && (
          <ActionRow onClick={invite}>{t('invite') as string}</ActionRow>
        )}
      </Actions>
    </Wrapper>
  );
};

export default withTranslation('Delegator')(Assignees);
