const getOffsetAgainst = (
  child: HTMLElement | null,
  ancestor: HTMLElement = window.document.body,
): number => {
  let offsetSum = 0;
  while (child && child !== ancestor && child !== window.document.body) {
    offsetSum += child.offsetTop;
    child = child.offsetParent as HTMLElement;
  }
  return offsetSum;
};

export default getOffsetAgainst;
