import { fork, all } from 'redux-saga/effects';
import { watchApiCallSaga } from './app/watchApiCall.saga';
import { watchStripeCallSaga } from './stripe/watchStripe.saga';
import { watchFlashesCallSaga } from './flashes/watchFlashes.saga';
import { watchEventSaga } from './formCreator/eventSequencer/watchEvent.saga';

export default function* saga() {
  yield all([
    fork(watchApiCallSaga),
    fork(watchStripeCallSaga),
    fork(watchFlashesCallSaga),
    fork(watchEventSaga),
  ]);
}
