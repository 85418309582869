import { sort } from 'ramda';
import { createSelector } from 'reselect';
import { FormProxyStateType } from './formProxy.reducer';
import { FormCollaboratorType } from '../../common/common.types';

const sortCollaboratorBy = (
  a: FormCollaboratorType,
  b: FormCollaboratorType,
): number => {
  if (a.isProxy === b.isProxy) {
    return a.user.displayName.localeCompare(b.user.displayName);
  }
  return a.isProxy ? -1 : 1;
};

export const getCollaborators = createSelector(
  ({ collaborators }: FormProxyStateType) => collaborators,
  (collaborators: FormCollaboratorType[]) =>
    sort(sortCollaboratorBy, collaborators),
);
