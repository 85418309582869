import React from 'react';
import { LoaderWrapper } from '../Form/Category/Category.styles';
import { VerticallyCentered } from 'common/common.styles';
import Loader from 'react-loader-spinner';
import { propOr } from 'ramda';
import colors from 'common/common.colors';

export interface InlineLoadingPropsType {
  className?: string;
  type?:
    | 'Audio'
    | 'Ball-Triangle'
    | 'Bars'
    | 'Circles'
    | 'Grid'
    | 'Hears'
    | 'Oval'
    | 'Puff'
    | 'Rings'
    | 'TailSpin'
    | 'ThreeDots';
  color?: string;
  width?: number;
  height?: number;
}

const InlineLoading = (props: InlineLoadingPropsType) => (
  <LoaderWrapper className={props.className}>
    <VerticallyCentered>
      <Loader
        type={propOr('ThreeDots', 'type')(props)}
        color={propOr(colors.basic.grayDark, 'color')(props)}
        height={propOr(20, 'height')(props)}
        width={propOr('100%', 'width')(props)}
      />
    </VerticallyCentered>
  </LoaderWrapper>
);

export default InlineLoading;
