import React from 'react';
import { Attachment } from '.';
import {
  ContentContainer,
  ContentWrapper,
  ScrollWrapper,
} from '../QuestionTabs.styles';
import {
  FormsCreateQuestionAttachmentFunctionType,
  FormsDeleteQuestionAttachmentFunctionType,
  FormsSetBlockSeenFunctionType,
} from 'store/forms/actions';
import { TopRight } from './Attachments.styles';
import { TabCommonType } from '../QuestionTabs';
import { TabContentTitle } from '../';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { AttachmentType, TranslatePropsType, Uuid } from 'common/common.types';
import AddAttachment from './AddAttachment';

interface AttachmentsPropsType extends TranslatePropsType, TabCommonType {
  attachments: AttachmentType[];
  areAttachmentsModifiable: boolean;
  createQuestionAttachment?: FormsCreateQuestionAttachmentFunctionType;
  deleteQuestionAttachment?: FormsDeleteQuestionAttachmentFunctionType;
  formId: Uuid;
  questionId: Uuid;
  setBlockSeen?: FormsSetBlockSeenFunctionType;
}

const Attachments = ({
  attachments,
  areAttachmentsModifiable,
  createQuestionAttachment,
  deleteQuestionAttachment,
  formId,
  hasNotifications,
  questionId,
  setBlockSeen,
  t,
}: AttachmentsPropsType) => (
  <ContentContainer hasBackground={!!attachments.length}>
    <ContentWrapper>
      <TabContentTitle icon={faPaperclip} title={t('title') as string} />

      {createQuestionAttachment && areAttachmentsModifiable && (
        <TopRight data-test="add-attachment">
          <AddAttachment
            createAttachment={formData =>
              createQuestionAttachment &&
              createQuestionAttachment(formData, formId, questionId)
            }
          />

          <p>{t('addDescription') as string}</p>
        </TopRight>
      )}

      {attachments.length > 0 ? (
        <ScrollWrapper data-test="attachment">
          {attachments.map(attachment => (
            <Attachment
              attachment={attachment}
              areAttachmentsModifiable={areAttachmentsModifiable}
              deleteAttachment={deleteQuestionAttachment}
              formId={formId}
              hasNotifications={hasNotifications}
              key={`attachment-${
                attachment.id ? attachment.id : attachment.uploadingId
              }`}
              questionId={questionId}
              setBlockSeen={setBlockSeen}
            />
          ))}
        </ScrollWrapper>
      ) : (
        <p>{t('noAttachments') as string}</p>
      )}
    </ContentWrapper>
  </ContentContainer>
);

export default withTranslation('Attachments')(Attachments);
