/* eslint-disable */
// tslint:disable
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, COLLECTION_FORMATS } from '../runtime';
import {
    AcknowledgeResponse,
    AttachmentResponse,
    AutocompleteExecutionResponse,
    AutocompleteStatsResponse,
    CollaboratorsListResponse,
    DelegationDeletedResponse,
    DelegationNotificationsStatusChangeResponse,
    DelegationSummaryResponse,
    DelegationsCreatedResponse,
    DelegationsDeletedResponse,
    EvidenceDeletedResponse,
    ExceptionResponse,
    FormAssignmentReplace,
    FormClearedInfoResponse,
    FormCreate,
    FormDelegationNotify,
    FormDeleteDelegation,
    FormDetailResponse,
    FormHistoriesListResponse,
    FormInitQueue,
    FormMetadataResponse,
    FormProxiesCreate,
    FormProxiesNotify,
    FormQuestionCommentCreate,
    FormQuestionFeedbackCreate,
    FormQuestionUpdateStatus,
    FormQuestionsUpdateStatus,
    FormRename,
    FormResponseValueUpdate,
    FormSeenResponse,
    FormShareBasesListResponse,
    FormShortSummaryResponse,
    FormTrackSeenResource,
    FormsShare,
    MappingRequestResponse,
    ProxySummariesListResponse,
    ProxySummaryResponse,
    QuestionCommentResponse,
    QuestionDetailBaseWithVRMReviewStatsResponse,
    QuestionDetailBasesListWithVRMReviewStatsResponse,
    QuestionMappingFeedbackResponse,
    QuestionMappingsListResponse,
    QuestionMetadataResponse,
    QuestionMetadatasListResponse,
    QuestionsCopyStatsResponse,
    ResponseFillingResponse,
    SenderInfoExceptionResponse,
    UpdateResponseFillingResponse,
    UserDelegablesListResponse,
    AcknowledgeResponseFromJSON,
    AttachmentResponseFromJSON,
    AutocompleteExecutionResponseFromJSON,
    AutocompleteStatsResponseFromJSON,
    CollaboratorsListResponseFromJSON,
    DelegationDeletedResponseFromJSON,
    DelegationNotificationsStatusChangeResponseFromJSON,
    DelegationSummaryResponseFromJSON,
    DelegationsCreatedResponseFromJSON,
    DelegationsDeletedResponseFromJSON,
    EvidenceDeletedResponseFromJSON,
    FormAssignmentReplaceToJSON,
    FormClearedInfoResponseFromJSON,
    FormCreateToJSON,
    FormDelegationNotifyToJSON,
    FormDeleteDelegationToJSON,
    FormDetailResponseFromJSON,
    FormHistoriesListResponseFromJSON,
    FormInitQueueToJSON,
    FormMetadataResponseFromJSON,
    FormProxiesCreateToJSON,
    FormProxiesNotifyToJSON,
    FormQuestionCommentCreateToJSON,
    FormQuestionFeedbackCreateToJSON,
    FormQuestionUpdateStatusToJSON,
    FormQuestionsUpdateStatusToJSON,
    FormRenameToJSON,
    FormResponseValueUpdateToJSON,
    FormSeenResponseFromJSON,
    FormShareBasesListResponseFromJSON,
    FormShortSummaryResponseFromJSON,
    FormTrackSeenResourceToJSON,
    FormsShareToJSON,
    MappingRequestResponseFromJSON,
    ProxySummariesListResponseFromJSON,
    ProxySummaryResponseFromJSON,
    QuestionCommentResponseFromJSON,
    QuestionDetailBaseWithVRMReviewStatsResponseFromJSON,
    QuestionDetailBasesListWithVRMReviewStatsResponseFromJSON,
    QuestionMappingFeedbackResponseFromJSON,
    QuestionMappingsListResponseFromJSON,
    QuestionMetadataResponseFromJSON,
    QuestionMetadatasListResponseFromJSON,
    QuestionsCopyStatsResponseFromJSON,
    ResponseFillingResponseFromJSON,
    UpdateResponseFillingResponseFromJSON,
    UserDelegablesListResponseFromJSON,
} from '../models';

export interface DeleteFormDelegationDetailResourceRequest {
    formId: string;
    delegationId: string;
}

export interface DeleteFormDelegationNotifyResourceRequest {
    formId: string;
    payload: FormDelegationNotify;
}

export interface DeleteFormDelegationsResourceRequest {
    formId: string;
    payload: FormDeleteDelegation;
}

export interface DeleteFormDetailResourceRequest {
    formId: string;
}

export interface DeleteFormProxyResourceRequest {
    formId: string;
    proxyId: string;
}

export interface DeleteFormResponseAttachmentDetailResourceRequest {
    formId: string;
    responseId: string;
    evidenceId: string;
}

export interface DeleteFormResponsesResourceRequest {
    formId: string;
}

export interface GetFormAutocompleteDetailResourceRequest {
    formId: string;
    aceId: string;
}

export interface GetFormAutocompleteStatsResourceRequest {
    formId: string;
    full?: boolean;
}

export interface GetFormCollaboratorsResourceRequest {
    formId: string;
}

export interface GetFormDelegationDetailResourceRequest {
    formId: string;
    delegationId: string;
}

export interface GetFormDelegationUsersResourceRequest {
    formId: string;
    searchQuery?: string;
    limit?: number;
}

export interface GetFormDetailResourceRequest {
    formId: string;
    filters?: Array<GetFormDetailResourceFiltersEnum>;
    questionIds?: Array<string>;
    assigneeId?: string;
}

export interface GetFormDownloadAttachmentsResourceRequest {
    formId: string;
}

export interface GetFormDownloadCsvExportResourceRequest {
    formId: string;
}

export interface GetFormProxiesResourceRequest {
    formId: string;
}

export interface GetFormProxyResourceRequest {
    formId: string;
    proxyId: string;
}

export interface GetFormQuestionDetailResourceRequest {
    formId: string;
    questionId: string;
    blocks?: Array<GetFormQuestionDetailResourceBlocksEnum>;
}

export interface GetFormQuestionMappingsListResourceRequest {
    formId: string;
    questionId: string;
}

export interface GetFormQuestionsListResourceRequest {
    formId: string;
    category?: string;
    offset?: number;
    limit?: number;
    ids?: Array<string>;
    assigneeId?: string;
    filters?: Array<GetFormQuestionsListResourceFiltersEnum>;
    blocks?: Array<GetFormQuestionsListResourceBlocksEnum>;
}

export interface GetFormResponseDetailResourceRequest {
    formId: string;
    responseId: string;
}

export interface GetFormsHistoryResourceRequest {
    formId: string;
}

export interface GetFormsMappingDetailResourceRequest {
    mappingId: string;
}

export interface PostFormActionAutofillResourceRequest {
    formId: string;
}

export interface PostFormActionRequeueInitializedResourceRequest {
    formId: string;
    payload: FormInitQueue;
}

export interface PostFormActionTrackSeenResourceRequest {
    formId: string;
    payload: FormTrackSeenResource;
}

export interface PostFormAttachmentsResourceRequest {
    formId: string;
    file: Blob;
}

export interface PostFormAutocompleteResourceRequest {
    formId: string;
}

export interface PostFormDelegationNotifyResourceRequest {
    formId: string;
    payload: FormDelegationNotify;
}

export interface PostFormMappingsListResourceRequest {
    formId: string;
    file: Blob;
    teamId?: string;
}

export interface PostFormProxiesResourceRequest {
    formId: string;
    payload: FormProxiesCreate;
}

export interface PostFormProxyNotifyResourceRequest {
    formId: string;
    proxyId: string;
    payload: FormProxiesNotify;
}

export interface PostFormQuestionAttachmentsResourceRequest {
    formId: string;
    questionId: string;
    file: Blob;
}

export interface PostFormQuestionCommentsResourceRequest {
    formId: string;
    questionId: string;
    payload: FormQuestionCommentCreate;
}

export interface PostFormQuestionMappingsFeedbackResourceRequest {
    formId: string;
    questionId: string;
    payload: FormQuestionFeedbackCreate;
}

export interface PostFormResponseAttachmentsResourceRequest {
    formId: string;
    responseId: string;
    file: Blob;
}

export interface PostFormShareResourceRequest {
    payload: FormsShare;
}

export interface PostFormsMappingsListResourceRequest {
    file: Blob;
    teamId?: string;
}

export interface PostFormsMasterResourceRequest {
    payload: FormCreate;
}

export interface PutFormDelegationsResourceRequest {
    formId: string;
    payload: FormAssignmentReplace;
}

export interface PutFormDetailResourceRequest {
    formId: string;
    payload: FormRename;
}

export interface PutFormQuestionDetailResourceRequest {
    formId: string;
    questionId: string;
    payload: FormQuestionUpdateStatus;
}

export interface PutFormQuestionsListResourceRequest {
    formId: string;
    payload: FormQuestionsUpdateStatus;
}

export interface PutFormResponseDetailResourceRequest {
    formId: string;
    responseId: string;
    payload: FormResponseValueUpdate;
}

/**
 * no description
 */
export class FormsApi extends BaseAPI {

    /**
     * Delete single delegation
     */
    deleteFormDelegationDetailResource = ({ formId, delegationId }: DeleteFormDelegationDetailResourceRequest): Observable<DelegationDeletedResponse> => {
        throwIfNullOrUndefined(formId, 'deleteFormDelegationDetailResource');
        throwIfNullOrUndefined(delegationId, 'deleteFormDelegationDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<DelegationDeletedResponse>({
            parser: jsonValue => DelegationDeletedResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/delegation/delegations/{delegation_id}'.replace('{form_id}', encodeURI(formId)).replace('{delegation_id}', encodeURI(delegationId)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * direction: logged-in user was assignor (forward) or assignee (backward)
     * Clear all delegation notifications associated with the form, queued by the logged-in user
     */
    deleteFormDelegationNotifyResource = ({ formId, payload }: DeleteFormDelegationNotifyResourceRequest): Observable<DelegationNotificationsStatusChangeResponse> => {
        throwIfNullOrUndefined(formId, 'deleteFormDelegationNotifyResource');
        throwIfNullOrUndefined(payload, 'deleteFormDelegationNotifyResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<DelegationNotificationsStatusChangeResponse>({
            parser: jsonValue => DelegationNotificationsStatusChangeResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/delegation/notify'.replace('{form_id}', encodeURI(formId)),
            method: 'DELETE',
            headers,
            body: FormDelegationNotifyToJSON(payload),
        });
    };

    /**
     * 1. To delete delegations from a single question, use `question_id`, 2. to delete delegations from all questions in a category, use `category`, 3. to delete delegations from all questions in a form, use only `form_id`.
     * Deletes all delegations from a single question, category or form
     */
    deleteFormDelegationsResource = ({ formId, payload }: DeleteFormDelegationsResourceRequest): Observable<DelegationsDeletedResponse> => {
        throwIfNullOrUndefined(formId, 'deleteFormDelegationsResource');
        throwIfNullOrUndefined(payload, 'deleteFormDelegationsResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<DelegationsDeletedResponse>({
            parser: jsonValue => DelegationsDeletedResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/delegation/delegations'.replace('{form_id}', encodeURI(formId)),
            method: 'DELETE',
            headers,
            body: FormDeleteDelegationToJSON(payload),
        });
    };

    /**
     * This is only doable if form’s `filling_type` is `UPLOADED`
     * Deletes given form
     */
    deleteFormDetailResource = ({ formId }: DeleteFormDetailResourceRequest): Observable<FormShortSummaryResponse> => {
        throwIfNullOrUndefined(formId, 'deleteFormDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<FormShortSummaryResponse>({
            parser: jsonValue => FormShortSummaryResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}'.replace('{form_id}', encodeURI(formId)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     */
    deleteFormProxyResource = ({ formId, proxyId }: DeleteFormProxyResourceRequest): Observable<void> => {
        throwIfNullOrUndefined(formId, 'deleteFormProxyResource');
        throwIfNullOrUndefined(proxyId, 'deleteFormProxyResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<void>({
            path: '/atlas/forms/{form_id}/proxies/{proxy_id}'.replace('{form_id}', encodeURI(formId)).replace('{proxy_id}', encodeURI(proxyId)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * from a form with form_id.
     * Deletes an attachment with evidence_id from a response with response_id
     */
    deleteFormResponseAttachmentDetailResource = ({ formId, responseId, evidenceId }: DeleteFormResponseAttachmentDetailResourceRequest): Observable<EvidenceDeletedResponse> => {
        throwIfNullOrUndefined(formId, 'deleteFormResponseAttachmentDetailResource');
        throwIfNullOrUndefined(responseId, 'deleteFormResponseAttachmentDetailResource');
        throwIfNullOrUndefined(evidenceId, 'deleteFormResponseAttachmentDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<EvidenceDeletedResponse>({
            parser: jsonValue => EvidenceDeletedResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/responses/{response_id}/attachments/{evidence_id}'.replace('{form_id}', encodeURI(formId)).replace('{response_id}', encodeURI(responseId)).replace('{evidence_id}', encodeURI(evidenceId)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * Clear all answer in a single form
     */
    deleteFormResponsesResource = ({ formId }: DeleteFormResponsesResourceRequest): Observable<FormClearedInfoResponse> => {
        throwIfNullOrUndefined(formId, 'deleteFormResponsesResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<FormClearedInfoResponse>({
            parser: jsonValue => FormClearedInfoResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/responses'.replace('{form_id}', encodeURI(formId)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * Get summary of AutocompleteExecution
     */
    getFormAutocompleteDetailResource = ({ formId, aceId }: GetFormAutocompleteDetailResourceRequest): Observable<AutocompleteExecutionResponse> => {
        throwIfNullOrUndefined(formId, 'getFormAutocompleteDetailResource');
        throwIfNullOrUndefined(aceId, 'getFormAutocompleteDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<AutocompleteExecutionResponse>({
            parser: jsonValue => AutocompleteExecutionResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/autocompletes/{ace_id}'.replace('{form_id}', encodeURI(formId)).replace('{ace_id}', encodeURI(aceId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Copy responses from your master form into the form filling, \"autofill\"
     */
    getFormAutocompleteStatsResource = ({ formId, full }: GetFormAutocompleteStatsResourceRequest): Observable<AutocompleteStatsResponse> => {
        throwIfNullOrUndefined(formId, 'getFormAutocompleteStatsResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (full != null) { query['full'] = full; }

        return this.request<AutocompleteStatsResponse>({
            parser: jsonValue => AutocompleteStatsResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/autocompletes/stats'.replace('{form_id}', encodeURI(formId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * (VENDORs).
     * Get a list of collaborators - user who can review (VRMs) and users who can answer
     */
    getFormCollaboratorsResource = ({ formId }: GetFormCollaboratorsResourceRequest): Observable<CollaboratorsListResponse> => {
        throwIfNullOrUndefined(formId, 'getFormCollaboratorsResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<CollaboratorsListResponse>({
            parser: jsonValue => CollaboratorsListResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/collaborators'.replace('{form_id}', encodeURI(formId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return detail of a single delegation
     */
    getFormDelegationDetailResource = ({ formId, delegationId }: GetFormDelegationDetailResourceRequest): Observable<DelegationSummaryResponse> => {
        throwIfNullOrUndefined(formId, 'getFormDelegationDetailResource');
        throwIfNullOrUndefined(delegationId, 'getFormDelegationDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<DelegationSummaryResponse>({
            parser: jsonValue => DelegationSummaryResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/delegation/delegations/{delegation_id}'.replace('{form_id}', encodeURI(formId)).replace('{delegation_id}', encodeURI(delegationId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return list of users that can have any questions of this form assigned
     */
    getFormDelegationUsersResource = ({ formId, searchQuery, limit }: GetFormDelegationUsersResourceRequest): Observable<UserDelegablesListResponse> => {
        throwIfNullOrUndefined(formId, 'getFormDelegationUsersResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (searchQuery != null) { query['search_query'] = searchQuery; }
        if (limit != null) { query['limit'] = limit; }

        return this.request<UserDelegablesListResponse>({
            parser: jsonValue => UserDelegablesListResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/delegation/users'.replace('{form_id}', encodeURI(formId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * **Note:** Remember to check the `form.initialization_status` in the response to be sure that the form is properly initialized (has status `FINISHED_SUCCESS`) and contains complete data.  URL parameters: * form_id - UUID of the form
     * Return details, metadata and statistics of the given form
     */
    getFormDetailResource = ({ formId, filters, questionIds, assigneeId }: GetFormDetailResourceRequest): Observable<FormMetadataResponse> => {
        throwIfNullOrUndefined(formId, 'getFormDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (filters != null) { query['filters'] = filters.join(COLLECTION_FORMATS['csv']); }
        if (questionIds != null) { query['question_ids'] = questionIds.join(COLLECTION_FORMATS['csv']); }
        if (assigneeId != null) { query['assignee_id'] = assigneeId; }

        return this.request<FormMetadataResponse>({
            parser: jsonValue => FormMetadataResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}'.replace('{form_id}', encodeURI(formId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * URL parameters: * form_id - uuid, id or code
     * Download zip file with form and attachments
     */
    getFormDownloadAttachmentsResource = ({ formId }: GetFormDownloadAttachmentsResourceRequest): Observable<void> => {
        throwIfNullOrUndefined(formId, 'getFormDownloadAttachmentsResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<void>({
            path: '/atlas/forms/{form_id}/downloads/attachments'.replace('{form_id}', encodeURI(formId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Download form in csv format
     */
    getFormDownloadCsvExportResource = ({ formId }: GetFormDownloadCsvExportResourceRequest): Observable<void> => {
        throwIfNullOrUndefined(formId, 'getFormDownloadCsvExportResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<void>({
            path: '/atlas/forms/{form_id}/downloads/csv_export'.replace('{form_id}', encodeURI(formId)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    getFormProxiesResource = ({ formId }: GetFormProxiesResourceRequest): Observable<ProxySummariesListResponse> => {
        throwIfNullOrUndefined(formId, 'getFormProxiesResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<ProxySummariesListResponse>({
            parser: jsonValue => ProxySummariesListResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/proxies'.replace('{form_id}', encodeURI(formId)),
            method: 'GET',
            headers,
        });
    };

    /**
     */
    getFormProxyResource = ({ formId, proxyId }: GetFormProxyResourceRequest): Observable<ProxySummaryResponse> => {
        throwIfNullOrUndefined(formId, 'getFormProxyResource');
        throwIfNullOrUndefined(proxyId, 'getFormProxyResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<ProxySummaryResponse>({
            parser: jsonValue => ProxySummaryResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/proxies/{proxy_id}'.replace('{form_id}', encodeURI(formId)).replace('{proxy_id}', encodeURI(proxyId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return single question metadata
     */
    getFormQuestionDetailResource = ({ formId, questionId, blocks }: GetFormQuestionDetailResourceRequest): Observable<QuestionMetadataResponse> => {
        throwIfNullOrUndefined(formId, 'getFormQuestionDetailResource');
        throwIfNullOrUndefined(questionId, 'getFormQuestionDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (blocks != null) { query['blocks'] = blocks.join(COLLECTION_FORMATS['csv']); }

        return this.request<QuestionMetadataResponse>({
            parser: jsonValue => QuestionMetadataResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/questions/{question_id}'.replace('{form_id}', encodeURI(formId)).replace('{question_id}', encodeURI(questionId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return suggested mapped answers for given question
     */
    getFormQuestionMappingsListResource = ({ formId, questionId }: GetFormQuestionMappingsListResourceRequest): Observable<QuestionMappingsListResponse> => {
        throwIfNullOrUndefined(formId, 'getFormQuestionMappingsListResource');
        throwIfNullOrUndefined(questionId, 'getFormQuestionMappingsListResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<QuestionMappingsListResponse>({
            parser: jsonValue => QuestionMappingsListResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/questions/{question_id}/mappings'.replace('{form_id}', encodeURI(formId)).replace('{question_id}', encodeURI(questionId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * __Path parameters:__  * form_id -  UUID of the form
     * Return questions in given form
     */
    getFormQuestionsListResource = ({ formId, category, offset, limit, ids, assigneeId, filters, blocks }: GetFormQuestionsListResourceRequest): Observable<QuestionMetadatasListResponse> => {
        throwIfNullOrUndefined(formId, 'getFormQuestionsListResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (category != null) { query['category'] = category; }
        if (offset != null) { query['offset'] = offset; }
        if (limit != null) { query['limit'] = limit; }
        if (ids != null) { query['ids'] = ids.join(COLLECTION_FORMATS['csv']); }
        if (assigneeId != null) { query['assignee_id'] = assigneeId; }
        if (filters != null) { query['filters'] = filters.join(COLLECTION_FORMATS['csv']); }
        if (blocks != null) { query['blocks'] = blocks.join(COLLECTION_FORMATS['csv']); }

        return this.request<QuestionMetadatasListResponse>({
            parser: jsonValue => QuestionMetadatasListResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/questions'.replace('{form_id}', encodeURI(formId)),
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return response details
     */
    getFormResponseDetailResource = ({ formId, responseId }: GetFormResponseDetailResourceRequest): Observable<ResponseFillingResponse> => {
        throwIfNullOrUndefined(formId, 'getFormResponseDetailResource');
        throwIfNullOrUndefined(responseId, 'getFormResponseDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<ResponseFillingResponse>({
            parser: jsonValue => ResponseFillingResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/responses/{response_id}'.replace('{form_id}', encodeURI(formId)).replace('{response_id}', encodeURI(responseId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return history of form status changes
     */
    getFormsHistoryResource = ({ formId }: GetFormsHistoryResourceRequest): Observable<FormHistoriesListResponse> => {
        throwIfNullOrUndefined(formId, 'getFormsHistoryResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<FormHistoriesListResponse>({
            parser: jsonValue => FormHistoriesListResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/history'.replace('{form_id}', encodeURI(formId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return status of ongoing mapping request
     */
    getFormsMappingDetailResource = ({ mappingId }: GetFormsMappingDetailResourceRequest): Observable<MappingRequestResponse> => {
        throwIfNullOrUndefined(mappingId, 'getFormsMappingDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<MappingRequestResponse>({
            parser: jsonValue => MappingRequestResponseFromJSON(jsonValue),
            path: '/atlas/forms/mappings/{mapping_id}'.replace('{mapping_id}', encodeURI(mappingId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Copy responses from your master form into the form filling, \"autofill\"
     */
    postFormActionAutofillResource = ({ formId }: PostFormActionAutofillResourceRequest): Observable<QuestionsCopyStatsResponse> => {
        throwIfNullOrUndefined(formId, 'postFormActionAutofillResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<QuestionsCopyStatsResponse>({
            parser: jsonValue => QuestionsCopyStatsResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/actions/autofill'.replace('{form_id}', encodeURI(formId)),
            method: 'POST',
            headers,
        });
    };

    /**
     * If the form failed the initialization, requeue it so it can be properly initialized
     */
    postFormActionRequeueInitializedResource = ({ formId, payload }: PostFormActionRequeueInitializedResourceRequest): Observable<AcknowledgeResponse> => {
        throwIfNullOrUndefined(formId, 'postFormActionRequeueInitializedResource');
        throwIfNullOrUndefined(payload, 'postFormActionRequeueInitializedResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<AcknowledgeResponse>({
            parser: jsonValue => AcknowledgeResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/actions/init_requeue'.replace('{form_id}', encodeURI(formId)),
            method: 'POST',
            headers,
            body: FormInitQueueToJSON(payload),
        });
    };

    /**
     * * question_id - restrict scope to a single question (optional) * block - restrict scope to a single block (optional) * read - mark form as read/unread (optional)
     * Mark form or a single question (or just a single block) as seen/unseen
     */
    postFormActionTrackSeenResource = ({ formId, payload }: PostFormActionTrackSeenResourceRequest): Observable<FormSeenResponse> => {
        throwIfNullOrUndefined(formId, 'postFormActionTrackSeenResource');
        throwIfNullOrUndefined(payload, 'postFormActionTrackSeenResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<FormSeenResponse>({
            parser: jsonValue => FormSeenResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/actions/track_seen'.replace('{form_id}', encodeURI(formId)),
            method: 'POST',
            headers,
            body: FormTrackSeenResourceToJSON(payload),
        });
    };

    /**
     * Upload attachment to given form
     */
    postFormAttachmentsResource = ({ formId, file }: PostFormAttachmentsResourceRequest): Observable<AttachmentResponse> => {
        throwIfNullOrUndefined(formId, 'postFormAttachmentsResource');
        throwIfNullOrUndefined(file, 'postFormAttachmentsResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const formData = new FormData();
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<AttachmentResponse>({
            parser: jsonValue => AttachmentResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/attachments'.replace('{form_id}', encodeURI(formId)),
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * Copy responses from your knowledge base, \"autocomplete\"
     */
    postFormAutocompleteResource = ({ formId }: PostFormAutocompleteResourceRequest): Observable<AutocompleteExecutionResponse> => {
        throwIfNullOrUndefined(formId, 'postFormAutocompleteResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<AutocompleteExecutionResponse>({
            parser: jsonValue => AutocompleteExecutionResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/autocompletes'.replace('{form_id}', encodeURI(formId)),
            method: 'POST',
            headers,
        });
    };

    /**
     * * direction: logged-in user is assignor (forward) or assignee (backward)
     * Send all delegation notifications associated with the form, queued by the logged-in user
     */
    postFormDelegationNotifyResource = ({ formId, payload }: PostFormDelegationNotifyResourceRequest): Observable<DelegationNotificationsStatusChangeResponse> => {
        throwIfNullOrUndefined(formId, 'postFormDelegationNotifyResource');
        throwIfNullOrUndefined(payload, 'postFormDelegationNotifyResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<DelegationNotificationsStatusChangeResponse>({
            parser: jsonValue => DelegationNotificationsStatusChangeResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/delegation/notify'.replace('{form_id}', encodeURI(formId)),
            method: 'POST',
            headers,
            body: FormDelegationNotifyToJSON(payload),
        });
    };

    /**
     * Upload a file with answers for given form and map it into knowledge base
     */
    postFormMappingsListResource = ({ formId, file, teamId }: PostFormMappingsListResourceRequest): Observable<MappingRequestResponse> => {
        throwIfNullOrUndefined(formId, 'postFormMappingsListResource');
        throwIfNullOrUndefined(file, 'postFormMappingsListResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (teamId != null) { query['team_id'] = teamId; }

        const formData = new FormData();
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<MappingRequestResponse>({
            parser: jsonValue => MappingRequestResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/mappings'.replace('{form_id}', encodeURI(formId)),
            method: 'POST',
            headers,
            query,
            body: formData,
        });
    };

    /**
     */
    postFormProxiesResource = ({ formId, payload }: PostFormProxiesResourceRequest): Observable<ProxySummariesListResponse> => {
        throwIfNullOrUndefined(formId, 'postFormProxiesResource');
        throwIfNullOrUndefined(payload, 'postFormProxiesResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<ProxySummariesListResponse>({
            parser: jsonValue => ProxySummariesListResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/proxies'.replace('{form_id}', encodeURI(formId)),
            method: 'POST',
            headers,
            body: FormProxiesCreateToJSON(payload),
        });
    };

    /**
     */
    postFormProxyNotifyResource = ({ formId, proxyId, payload }: PostFormProxyNotifyResourceRequest): Observable<ProxySummaryResponse> => {
        throwIfNullOrUndefined(formId, 'postFormProxyNotifyResource');
        throwIfNullOrUndefined(proxyId, 'postFormProxyNotifyResource');
        throwIfNullOrUndefined(payload, 'postFormProxyNotifyResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<ProxySummaryResponse>({
            parser: jsonValue => ProxySummaryResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/proxies/{proxy_id}/notify'.replace('{form_id}', encodeURI(formId)).replace('{proxy_id}', encodeURI(proxyId)),
            method: 'POST',
            headers,
            body: FormProxiesNotifyToJSON(payload),
        });
    };

    /**
     * Upload attachment to given question
     */
    postFormQuestionAttachmentsResource = ({ formId, questionId, file }: PostFormQuestionAttachmentsResourceRequest): Observable<AttachmentResponse> => {
        throwIfNullOrUndefined(formId, 'postFormQuestionAttachmentsResource');
        throwIfNullOrUndefined(questionId, 'postFormQuestionAttachmentsResource');
        throwIfNullOrUndefined(file, 'postFormQuestionAttachmentsResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const formData = new FormData();
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<AttachmentResponse>({
            parser: jsonValue => AttachmentResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/questions/{question_id}/attachments'.replace('{form_id}', encodeURI(formId)).replace('{question_id}', encodeURI(questionId)),
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * Add comment to a given question
     */
    postFormQuestionCommentsResource = ({ formId, questionId, payload }: PostFormQuestionCommentsResourceRequest): Observable<QuestionCommentResponse> => {
        throwIfNullOrUndefined(formId, 'postFormQuestionCommentsResource');
        throwIfNullOrUndefined(questionId, 'postFormQuestionCommentsResource');
        throwIfNullOrUndefined(payload, 'postFormQuestionCommentsResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<QuestionCommentResponse>({
            parser: jsonValue => QuestionCommentResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/questions/{question_id}/comments'.replace('{form_id}', encodeURI(formId)).replace('{question_id}', encodeURI(questionId)),
            method: 'POST',
            headers,
            body: FormQuestionCommentCreateToJSON(payload),
        });
    };

    /**
     * Collect feedback on question mapping results
     */
    postFormQuestionMappingsFeedbackResource = ({ formId, questionId, payload }: PostFormQuestionMappingsFeedbackResourceRequest): Observable<QuestionMappingFeedbackResponse> => {
        throwIfNullOrUndefined(formId, 'postFormQuestionMappingsFeedbackResource');
        throwIfNullOrUndefined(questionId, 'postFormQuestionMappingsFeedbackResource');
        throwIfNullOrUndefined(payload, 'postFormQuestionMappingsFeedbackResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<QuestionMappingFeedbackResponse>({
            parser: jsonValue => QuestionMappingFeedbackResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/questions/{question_id}/mappings/feedback'.replace('{form_id}', encodeURI(formId)).replace('{question_id}', encodeURI(questionId)),
            method: 'POST',
            headers,
            body: FormQuestionFeedbackCreateToJSON(payload),
        });
    };

    /**
     * Upload attachment to given response
     */
    postFormResponseAttachmentsResource = ({ formId, responseId, file }: PostFormResponseAttachmentsResourceRequest): Observable<AttachmentResponse> => {
        throwIfNullOrUndefined(formId, 'postFormResponseAttachmentsResource');
        throwIfNullOrUndefined(responseId, 'postFormResponseAttachmentsResource');
        throwIfNullOrUndefined(file, 'postFormResponseAttachmentsResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const formData = new FormData();
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<AttachmentResponse>({
            parser: jsonValue => AttachmentResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/responses/{response_id}/attachments'.replace('{form_id}', encodeURI(formId)).replace('{response_id}', encodeURI(responseId)),
            method: 'POST',
            headers,
            body: formData,
        });
    };

    /**
     * Share given forms
     */
    postFormShareResource = ({ payload }: PostFormShareResourceRequest): Observable<FormShareBasesListResponse> => {
        throwIfNullOrUndefined(payload, 'postFormShareResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<FormShareBasesListResponse>({
            parser: jsonValue => FormShareBasesListResponseFromJSON(jsonValue),
            path: '/atlas/forms/shares',
            method: 'POST',
            headers,
            body: FormsShareToJSON(payload),
        });
    };

    /**
     * Upload a file with answers and map it into knowledge base
     */
    postFormsMappingsListResource = ({ file, teamId }: PostFormsMappingsListResourceRequest): Observable<MappingRequestResponse> => {
        throwIfNullOrUndefined(file, 'postFormsMappingsListResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (teamId != null) { query['team_id'] = teamId; }

        const formData = new FormData();
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<MappingRequestResponse>({
            parser: jsonValue => MappingRequestResponseFromJSON(jsonValue),
            path: '/atlas/forms/mappings',
            method: 'POST',
            headers,
            query,
            body: formData,
        });
    };

    /**
     */
    postFormsMasterResource = ({ payload }: PostFormsMasterResourceRequest): Observable<FormDetailResponse> => {
        throwIfNullOrUndefined(payload, 'postFormsMasterResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<FormDetailResponse>({
            parser: jsonValue => FormDetailResponseFromJSON(jsonValue),
            path: '/atlas/forms/master',
            method: 'POST',
            headers,
            body: FormCreateToJSON(payload),
        });
    };

    /**
     * Assign them to a single user.  1. To replace assignments of a single question, use `question_id`, 2. to replace assignments of all questions in a category, use `category`, 3. to replace assignemtns of all questions in a form, use only `form_id`.  Assignee can be specified either using `assignee_id` or `assignee_email`.  This operation overwrites (replaces) all previous assignments on given questions.
     * Replace assignments for a single question, category or form
     */
    putFormDelegationsResource = ({ formId, payload }: PutFormDelegationsResourceRequest): Observable<DelegationsCreatedResponse> => {
        throwIfNullOrUndefined(formId, 'putFormDelegationsResource');
        throwIfNullOrUndefined(payload, 'putFormDelegationsResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<DelegationsCreatedResponse>({
            parser: jsonValue => DelegationsCreatedResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/delegation/delegations'.replace('{form_id}', encodeURI(formId)),
            method: 'PUT',
            headers,
            body: FormAssignmentReplaceToJSON(payload),
        });
    };

    /**
     * This is only doable if form’s `filling_type` is `UPLOADED`  URL parameters: * name - new name for the form filling
     * Change name of the given form
     */
    putFormDetailResource = ({ formId, payload }: PutFormDetailResourceRequest): Observable<FormShortSummaryResponse> => {
        throwIfNullOrUndefined(formId, 'putFormDetailResource');
        throwIfNullOrUndefined(payload, 'putFormDetailResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<FormShortSummaryResponse>({
            parser: jsonValue => FormShortSummaryResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}'.replace('{form_id}', encodeURI(formId)),
            method: 'PUT',
            headers,
            body: FormRenameToJSON(payload),
        });
    };

    /**
     * Update status of a single question
     */
    putFormQuestionDetailResource = ({ formId, questionId, payload }: PutFormQuestionDetailResourceRequest): Observable<QuestionDetailBaseWithVRMReviewStatsResponse> => {
        throwIfNullOrUndefined(formId, 'putFormQuestionDetailResource');
        throwIfNullOrUndefined(questionId, 'putFormQuestionDetailResource');
        throwIfNullOrUndefined(payload, 'putFormQuestionDetailResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<QuestionDetailBaseWithVRMReviewStatsResponse>({
            parser: jsonValue => QuestionDetailBaseWithVRMReviewStatsResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/questions/{question_id}'.replace('{form_id}', encodeURI(formId)).replace('{question_id}', encodeURI(questionId)),
            method: 'PUT',
            headers,
            body: FormQuestionUpdateStatusToJSON(payload),
        });
    };

    /**
     * Currently supporting question selection by category only.
     * Update status of multiple questions
     */
    putFormQuestionsListResource = ({ formId, payload }: PutFormQuestionsListResourceRequest): Observable<QuestionDetailBasesListWithVRMReviewStatsResponse> => {
        throwIfNullOrUndefined(formId, 'putFormQuestionsListResource');
        throwIfNullOrUndefined(payload, 'putFormQuestionsListResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<QuestionDetailBasesListWithVRMReviewStatsResponse>({
            parser: jsonValue => QuestionDetailBasesListWithVRMReviewStatsResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/questions'.replace('{form_id}', encodeURI(formId)),
            method: 'PUT',
            headers,
            body: FormQuestionsUpdateStatusToJSON(payload),
        });
    };

    /**
     * Update response value
     */
    putFormResponseDetailResource = ({ formId, responseId, payload }: PutFormResponseDetailResourceRequest): Observable<UpdateResponseFillingResponse> => {
        throwIfNullOrUndefined(formId, 'putFormResponseDetailResource');
        throwIfNullOrUndefined(responseId, 'putFormResponseDetailResource');
        throwIfNullOrUndefined(payload, 'putFormResponseDetailResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<UpdateResponseFillingResponse>({
            parser: jsonValue => UpdateResponseFillingResponseFromJSON(jsonValue),
            path: '/atlas/forms/{form_id}/responses/{response_id}'.replace('{form_id}', encodeURI(formId)).replace('{response_id}', encodeURI(responseId)),
            method: 'PUT',
            headers,
            body: FormResponseValueUpdateToJSON(payload),
        });
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetFormDetailResourceFiltersEnum {
    Yes = 'yes',
    No = 'no',
    Na = 'na',
    Attachments = 'attachments',
    AttachmentsNew = 'attachments_new',
    Comments = 'comments',
    CommentsNew = 'comments_new',
    Required = 'required',
    Filled = 'filled',
    CompletelyFilled = 'completely_filled',
    PartiallyFilled = 'partially_filled',
    NotFilled = 'not_filled',
    SscData = 'ssc_data',
    SscDataNew = 'ssc_data_new',
    VrmReviewPending = 'vrm_review_pending',
    VrmReviewFlagged = 'vrm_review_flagged',
    VrmReviewDone = 'vrm_review_done',
    Unassigned = 'unassigned',
    Responses = 'responses',
    ResponsesNew = 'responses_new',
    AutocompleteDone = 'autocomplete_done',
    AutocompleteSuggestionsAvailable = 'autocomplete_suggestions_available'
}
/**
 * @export
 * @enum {string}
 */
export enum GetFormQuestionDetailResourceBlocksEnum {
    Attachments = 'attachments',
    Comments = 'comments',
    SscData = 'ssc_data',
    History = 'history'
}
/**
 * @export
 * @enum {string}
 */
export enum GetFormQuestionsListResourceFiltersEnum {
    Yes = 'yes',
    No = 'no',
    Na = 'na',
    Attachments = 'attachments',
    AttachmentsNew = 'attachments_new',
    Comments = 'comments',
    CommentsNew = 'comments_new',
    Required = 'required',
    Filled = 'filled',
    CompletelyFilled = 'completely_filled',
    PartiallyFilled = 'partially_filled',
    NotFilled = 'not_filled',
    SscData = 'ssc_data',
    SscDataNew = 'ssc_data_new',
    VrmReviewPending = 'vrm_review_pending',
    VrmReviewFlagged = 'vrm_review_flagged',
    VrmReviewDone = 'vrm_review_done',
    Unassigned = 'unassigned',
    Responses = 'responses',
    ResponsesNew = 'responses_new',
    AutocompleteDone = 'autocomplete_done',
    AutocompleteSuggestionsAvailable = 'autocomplete_suggestions_available'
}
/**
 * @export
 * @enum {string}
 */
export enum GetFormQuestionsListResourceBlocksEnum {
    Attachments = 'attachments',
    Comments = 'comments',
    SscData = 'ssc_data',
    History = 'history'
}
