import React from 'react';
import { Count, IconWrapper, Label, Wrapper } from './Tab.styles';
import { ErrorIcon } from '../ValidationInfo/ValidationInfo.styles';
import { TabBasePropsType } from '../../common/Tabs/TabList';

interface TabPropsType extends TabBasePropsType {
  count?: number;
  fixAttempted?: boolean;
  hasError: boolean;
}

const Tab = ({
  count,
  fixAttempted,
  isActive,
  hasError,
  label,
  onClick,
}: TabPropsType) => (
  <Wrapper
    isActive={Boolean(isActive)}
    onClick={onClick}
    data-test={label}
    data-pendo={label}
  >
    {hasError && (
      <IconWrapper>
        <ErrorIcon fixAttempted={fixAttempted} />
      </IconWrapper>
    )}
    <Label>{label}</Label>
    {Number(count) > 0 && <Count>{count}</Count>}
  </Wrapper>
);

export default Tab;
