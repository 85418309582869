import styled from 'styled-components';
import { Wrapper as QuestionWrapper } from '../../Form/Question/Question.styles';

export const Wrapper = styled.div`
  padding: 0 22px 22px;

  ${QuestionWrapper} {
    padding: 0;
    margin: 0 0 0 -2px;

    input,
    & > div {
      margin: 0;
      flex-basis: 100%;
    }

    & > div > div > div {
      padding: 20px 22px 0;
    }
  }

  ${QuestionWrapper} + hr {
    margin: 30px 0 10px;
  }
`;
