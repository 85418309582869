/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestFinding,
    RequestFindingFromJSON,
    RequestFindingFromJSONTyped,
    RequestFindingToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface RequestFindingResponse
 */
export interface RequestFindingResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof RequestFindingResponse
     */
    msg: string;
    /**
     * 
     * @type {RequestFinding}
     * @memberof RequestFindingResponse
     */
    entry: RequestFinding;
}

export function RequestFindingResponseFromJSON(json: any): RequestFindingResponse {
    return RequestFindingResponseFromJSONTyped(json, false);
}

export function RequestFindingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFindingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': RequestFindingFromJSON(json['entry']),
    };
}

export function RequestFindingResponseToJSON(value?: RequestFindingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': RequestFindingToJSON(value.entry),
    };
}


