import MultiOption from './MultiOption';
import React, { useEffect, useState, useCallback } from 'react';
import isGenericLabel from 'utils/isGenericLabel';
import { Label as ResponseLabel } from '../../Form.styles';
import { ResponseInputPropsType } from '../Response';
import { Wrapper } from './MultiSelect.styles';
import { filter, contains } from 'ramda';
import {
  Checkmark,
  TemplateCheckBox,
  Label,
} from '../../../common/Checkbox/Checkbox.styles';
import {
  CheckboxWrapper,
  Selectable,
} from '../../../SendRequest/StandardListItem.styles';

const parseValues = (rawValue: string, options?: string[]): string[] => {
  // values can now be '' || 'usa' || '["usa"]'
  // insane, i know.
  // we definitely need to redo this, so it's always either '' or '["usa"]'

  let values: string[] = [];
  try {
    values =
      rawValue !== '' && rawValue.length > 0
        ? rawValue[0] === '['
          ? [...JSON.parse(rawValue)]
          : [rawValue]
        : [];

    if (options) {
      values = filter(value => contains(value, options), values);
    }
  } catch (e) {
    values = [];
  }
  return values;
};

const MultiSelect = ({
  actions: { handleChange: sendChange },
  data: { id, isEditable, isRequired, label, value: rawValue, options },
}: ResponseInputPropsType) => {
  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    setValues(parseValues(rawValue, options));
  }, [rawValue, options]);

  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, value: string) => {
      if (!isEditable) {
        return false;
      }

      const updatedValues = contains(value, values)
        ? values.filter(val => val !== value)
        : [...values, value];

      setValues(updatedValues);

      return sendChange(
        updatedValues.length === 0 ? '' : JSON.stringify(updatedValues),
      );
    },
    [isEditable, sendChange, values],
  );

  const isDisabled = !isEditable || false;

  return (
    <div>
      {label && !isGenericLabel(label) && (
        <ResponseLabel>{label}</ResponseLabel>
      )}

      {(options || []).map(option => {
        const checked = contains(option, values);

        return (
          <Wrapper
            key={`multi-option-${option}`}
            data-cy="checkbox-wrapper"
            onClick={e => handleChange(e, option)}
            isDisabled={isDisabled}
          >
            <Selectable isSelected={checked}>
              <CheckboxWrapper isEditing={false}>
                <TemplateCheckBox
                  data-cy={'checkbox'}
                  isDisabled={isDisabled}
                  primaryColor={'teal'}
                  size={22}
                  hasValue={checked}
                >
                  <Label>{option}</Label>

                  <MultiOption
                    data={{
                      checked,
                      handleChange: () => false,
                      id,
                      isEditable,
                      isRequired,
                      optionLabel: option,
                    }}
                  />

                  <Checkmark />
                </TemplateCheckBox>
              </CheckboxWrapper>
            </Selectable>
          </Wrapper>
        );
      })}
    </div>
  );
};

export default MultiSelect;
