import styled from 'styled-components/macro';

import {
  Controls,
  TitleWrapper as ImportedTitleWrapper,
} from '../FormDefinitions/FormDefinitionsHeader.styles';

export { Controls };
export const TitleWrapper = styled(ImportedTitleWrapper)`
  position: relative;
`;

export const BackButtonWrapper = styled.div`
  position: absolute;
  top: -15px;
`;

export const StartFromScratchWrapper = styled.div`
  margin-right: 70px;
`;
