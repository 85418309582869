import { CheckboxSelect, Header, Section, Title } from './FilterBy.styles';
import { FilterByPropsType } from './FilterBy';
import { assocPath, find, propEq } from 'ramda';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Checkbox from '../../common/Checkbox';
import Select from '../../common/Select';

const OPTION = 'WITH_REVISIONS';

const OPTIONS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];

const FilterReviewedByVrm = ({
  data: { filterBy },
  actions: { setFilter },
  t,
}: FilterByPropsType) => {
  const defaultValue =
    find(propEq('value', filterBy[OPTION].lookFor), OPTIONS) || OPTIONS[0];

  const initialStatus = !!filterBy[OPTION].isEnabled || false;

  const [isEnabled, setIsEnabled] = useState(initialStatus);
  const [selected, setSelected] = useState(defaultValue);

  const handleOnChange = ({
    value,
    label,
  }: {
    value: string;
    label: string;
  }) => {
    setSelected({ value, label });
    setFilter(
      assocPath([OPTION], { isEnabled: true, lookFor: value }, filterBy),
    );
  };

  const handleOnCheck = () => {
    setIsEnabled(!isEnabled);
    setFilter(
      assocPath(
        [OPTION],
        { isEnabled: !isEnabled, lookFor: selected.value },
        filterBy,
      ),
    );
  };

  return (
    <Section>
      <Header>
        <Title>{t(`Requests.filterOptions.${OPTION}`) as string}</Title>
      </Header>
      <CheckboxSelect>
        <Checkbox
          isCompact
          isChecked={isEnabled}
          label={t(`Requests.reviewedByVrm`) as string}
          labelSize="small"
          secondaryColor="teal"
          onChange={handleOnCheck}
        />
        <Select
          defaultValue={defaultValue}
          isDisabled={!isEnabled}
          onChange={handleOnChange}
          options={OPTIONS}
          placeholder={t('Requests.numberOfRevisions') as string}
        />
      </CheckboxSelect>
    </Section>
  );
};

export default withTranslation()(FilterReviewedByVrm);
