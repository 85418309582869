import {
  FormDefinitionSummaryDraftExtendedType,
  QuestionDefinitionSummaryType,
  FormCreatorRenderType,
  FormDefinitionValidationType,
  Uuid,
  FormCreatorValidatedQuestionType,
} from '../../../common/common.types';
import { any, equals, groupBy, sort } from 'ramda';
import { getCategories } from './basic';
import comparePos from '../utils/comparePos';
import { enhanceQuestionWithValidationData } from './validation';

// ==================================
//  Main Part: Derived Data Creation
// ==================================

const initialCache = (): {
  categories: Record<string, FormCreatorRenderType>;
} => ({
  categories: {},
});

let cache = initialCache();

const groupByCategory = groupBy((question: QuestionDefinitionSummaryType) => {
  return question.category;
});

/**
 * Clears cache of the derived renderData. Useful to recover some memory.
 */
export const resetRenderData = () => {
  cache = initialCache();
};

/**
 * Function for creating formCreator derived data required for visual
 * components rendering. Specifically `FormCreatorRenderType` is created out of
 * normal current form state (`FormDefinitionSummaryDraftExtendedType`).
 *
 * There are three main purposes of the `FormCreatorRenderType`:
 *  - Group the list of all questions by category.
 *  - Merge validation data into the specific questions/responses for easy
 *    access - in the source state validation data is stored in an independent
 *    structure.
 *  - Memoize the resulting derived data as much as possible to prevent
 *    unwanted re-renders.
 */
export const getRenderData = (
  currentForm: FormDefinitionSummaryDraftExtendedType | undefined,
  validation: FormDefinitionValidationType,
): FormCreatorRenderType[] => {
  const result: FormCreatorRenderType[] = [];
  if (currentForm) {
    const questionsByCategory = groupByCategory(currentForm.questions);

    return getCategories(currentForm).map(cat => {
      const questions =
        cat.questionCount > 0 && questionsByCategory[cat.name]
          ? sort(comparePos, questionsByCategory[cat.name]).map(
              enhanceQuestionWithValidationData(validation),
            )
          : [];
      const hasErrors = any(question => question.hasErrors, questions);

      const newValue = { ...cat, hasErrors, questions };

      if (
        cache.categories[cat.name] &&
        equals(cache.categories[cat.name], newValue)
      ) {
        return cache.categories[cat.name];
      } else {
        cache.categories[cat.name] = newValue;
        return newValue;
      }
    });
  }

  return result;
};

// ===========================
//  Selectors on derived data
// ===========================

export const findQuestionByUuid = (
  renderData: FormCreatorRenderType[],
  questionUuid: Uuid,
) => {
  let question: FormCreatorValidatedQuestionType | undefined;
  renderData.find(({ questions }) => {
    question = questions.find(({ wizardId }) => wizardId === questionUuid);
    return Boolean(question);
  });
  return question;
};
