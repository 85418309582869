export interface MoveCategoryLocallyActionType {
  type: '@formCreator/MOVE_CATEGORY';
  payload: {
    source: string;
    destination: string;
  };
}

export type MoveCategoryLocallyFunctionType = (
  source: string,
  destination: string,
) => void;

export const moveCategoryLocally = (
  source: string,
  destination: string,
): MoveCategoryLocallyActionType => ({
  type: '@formCreator/MOVE_CATEGORY',
  payload: {
    source,
    destination,
  },
});
