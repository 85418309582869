/* eslint-disable */
// tslint:disable
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, COLLECTION_FORMATS } from '../runtime';
import {
    EntityStatsResponse,
    ExceptionResponse,
    HealthCheckReportResponse,
    EntityStatsResponseFromJSON,
    HealthCheckReportResponseFromJSON,
} from '../models';

export interface GetReportsEntitiesResourceRequest {
    periods?: Array<string>;
}

export interface GetReportsHistoryUserActionsResourceRequest {
    limit?: number;
    includeSystem?: boolean;
    companyQuery?: string;
}

export interface GetReportsStatusResourceRequest {
    fetch?: Array<GetReportsStatusResourceFetchEnum>;
    check?: Array<GetReportsStatusResourceCheckEnum>;
}

/**
 * no description
 */
export class ReportsApi extends BaseAPI {

    /**
     * Specify periods in arg `period` as e.g. `12h,1d,7d,1m,total` -> gimme data for last 12 hours, last day, last week, last month and since 1970.
     * Return stats about created entities for given time periods
     */
    getReportsEntitiesResource = ({ periods }: GetReportsEntitiesResourceRequest): Observable<EntityStatsResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (periods != null) { query['periods'] = periods.join(COLLECTION_FORMATS['csv']); }

        return this.request<EntityStatsResponse>({
            parser: jsonValue => EntityStatsResponseFromJSON(jsonValue),
            path: '/atlas/reports/entities',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * You can exclude system company users (bots) from appearing in the report by using `include_system=false`. Or just export specific company/ies users by using `company_query`.
     * Produce CSV file with list of last user actions
     */
    getReportsHistoryUserActionsResource = ({ limit, includeSystem, companyQuery }: GetReportsHistoryUserActionsResourceRequest): Observable<void> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (limit != null) { query['limit'] = limit; }
        if (includeSystem != null) { query['include_system'] = includeSystem; }
        if (companyQuery != null) { query['company_query'] = companyQuery; }

        return this.request<void>({
            path: '/atlas/reports/history/user_actions',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Shorthand for /reports/status with the following checks used in `fetch` arg: `form_fillings`, `form_definitions`, `mapping_initialized`, `graph_initialized`, `queues_length`, `queues_publish`, `queues_consumer_count`, `has_enough_disk_space`, `load_every_entity_type`
     * Return overall health-check stats about system
     */
    getReportsStatusFullResource = (): Observable<HealthCheckReportResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<HealthCheckReportResponse>({
            parser: jsonValue => HealthCheckReportResponseFromJSON(jsonValue),
            path: '/atlas/reports/status/full',
            method: 'GET',
            headers,
        });
    };

    /**
     * Specify which checks should be used in the `fetch` arg. Arg `check` can eventually specify which of these \"really matter\", that is which checks can mark the whole thing broken. Equals to `fetch` if unspecified.
     * Return customized health-check status report
     */
    getReportsStatusResource = ({ fetch, check }: GetReportsStatusResourceRequest): Observable<HealthCheckReportResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (fetch != null) { query['fetch'] = fetch.join(COLLECTION_FORMATS['csv']); }
        if (check != null) { query['check'] = check.join(COLLECTION_FORMATS['csv']); }

        return this.request<HealthCheckReportResponse>({
            parser: jsonValue => HealthCheckReportResponseFromJSON(jsonValue),
            path: '/atlas/reports/status',
            method: 'GET',
            headers,
            query,
        });
    };

}

/**
 * @export
 * @enum {string}
 */
export enum GetReportsStatusResourceFetchEnum {
    Application = 'application',
    Repository = 'repository',
    Requests = 'requests',
    FormFillings = 'form_fillings',
    FormDefinitions = 'form_definitions',
    MappingInitialized = 'mapping_initialized',
    GraphInitialized = 'graph_initialized',
    QueuesPublish = 'queues_publish',
    QueuesLength = 'queues_length',
    QueuesConsumerCount = 'queues_consumer_count',
    HasEnoughDiskSpace = 'has_enough_disk_space',
    LoadEveryEntityType = 'load_every_entity_type'
}
/**
 * @export
 * @enum {string}
 */
export enum GetReportsStatusResourceCheckEnum {
    Application = 'application',
    Repository = 'repository',
    Requests = 'requests',
    FormFillings = 'form_fillings',
    FormDefinitions = 'form_definitions',
    MappingInitialized = 'mapping_initialized',
    GraphInitialized = 'graph_initialized',
    QueuesPublish = 'queues_publish',
    QueuesLength = 'queues_length',
    QueuesConsumerCount = 'queues_consumer_count',
    HasEnoughDiskSpace = 'has_enough_disk_space',
    LoadEveryEntityType = 'load_every_entity_type'
}
