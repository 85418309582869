import React from 'react';
import { AnimatedPath, Logo } from './AtlasSpinner.styles';

interface AtlasSpinnerProps {
  height?: number;
  width?: number;
}

const AtlasSpinner = ({ height = 80, width = 80 }: AtlasSpinnerProps) => (
  <svg height={height} width={width} viewBox="0 0 139 139">
    <Logo d="M69 40L33 102.4H61.6L68.8 115H83.6L90.6 102.4H76.2L69 89.9H54.64L69 64.97L90.6 102.4H105L69 40Z" />
    <AnimatedPath d="M69.4 6L134.5 118.5H99.5L93 131.5H59L51.5 118.5H5L69.4 6Z" />
  </svg>
);

export default AtlasSpinner;
