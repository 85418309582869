import React from 'react';
import Sticky from 'react-sticky-fill';
import { Button } from '../common/Button/Button.styles';
import { TransferStandardsFunctionType } from 'store/standard/actions';
import { TranslatePropsType, Uuid } from 'common/common.types';
import { Wrapper } from './TransferStandards.styles';
import { withTranslation } from 'react-i18next';

interface TransferStandardsPropsType extends TranslatePropsType {
  companyId?: Uuid;
  selectedIds: Uuid[];
  transferingCount: number;
  transferStandards: TransferStandardsFunctionType;
}

const TransferStandards = ({
  companyId,
  selectedIds: standardIds,
  transferStandards,
  transferingCount,
  t,
}: TransferStandardsPropsType) => (
  <Wrapper>
    <Sticky style={{ top: 100 }}>
      {companyId && standardIds.length > 0 ? (
        <Button
          color="teal"
          disabled={transferingCount > 0}
          onClick={() => transferStandards({ companyId, standardIds })}
        >
          {transferingCount > 0
            ? (t('transfering') as string)
            : (t('transfer') as string)}
        </Button>
      ) : (
        <div />
      )}
    </Sticky>
  </Wrapper>
);

export default withTranslation('Sales')(TransferStandards);
