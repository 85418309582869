import React, { PureComponent } from 'react';
import { Header, Wrapper } from './AccordionSection.styles';

export interface AccordionSectionPropsType {
  children: React.ReactNode;
  dataTest?: string;
  handleClick?: (id: number) => void;
  heading: React.ReactElement<any>;
  id: number;
  isOpen?: boolean;
  isSingle?: boolean;
  topOffset: number;
  wrapperElement: Element;
}

class AccordionSection extends PureComponent<AccordionSectionPropsType> {
  ref: any;
  constructor(props: AccordionSectionPropsType) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidUpdate(prevProps: AccordionSectionPropsType) {
    if (this.props.isOpen && prevProps.isOpen === false) {
      this.scrollToSection();
    }
  }

  scrollToSection = () => {
    const element = this.ref.current;
    const { topOffset, wrapperElement } = this.props;

    if (element && wrapperElement) {
      wrapperElement.scrollTop =
        element.getBoundingClientRect().top -
        wrapperElement.getBoundingClientRect().top +
        (wrapperElement === document.documentElement
          ? 0
          : wrapperElement.scrollTop) -
        topOffset;
    }
  };

  handleClick = () => {
    const { id, handleClick } = this.props;
    if (handleClick) {
      handleClick(id);
    }
  };

  render() {
    const { children, dataTest, heading, isOpen, isSingle } = this.props;

    const header = React.cloneElement(heading, {
      isOpen,
      isSingle,
    });

    return (
      <Wrapper ref={this.ref} data-test={dataTest}>
        <Header onClick={this.handleClick}>{header}</Header>
        {isOpen && children}
      </Wrapper>
    );
  }
}

export default AccordionSection;
