import update from 'immutability-helper';
import { filter, findIndex, propEq } from 'ramda';

import {
  FormDefinitionDraftCategoryStatsType,
  Uuid,
} from '../../../common/common.types';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';

export type MoveQuestionFunctionType = (params: MoveQuestionParamsType) => void;

export type MoveQuestionActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/MOVE_QUESTION'
>;

export interface MoveQuestionParamsType {
  questionUuid: Uuid;
  targetQuestionUuid: Uuid;
}

export const moveQuestion = ({
  questionUuid,
  targetQuestionUuid,
}: MoveQuestionParamsType): MoveQuestionActionType => ({
  type: '@formCreator/EVENTS/MOVE_QUESTION',
  payload: {
    events: ({ formCreator }) => {
      const questions = formCreator!.currentForm!.questions;
      const sourceIndex = findIndex(
        propEq('wizardId', questionUuid),
        questions,
      );
      const destinationIndex = findIndex(
        propEq('wizardId', targetQuestionUuid),
        questions,
      );
      const sourceQuestion = questions[sourceIndex];
      const destinationQuestion = questions[destinationIndex];
      const questionsInSourceCategory = questions.filter(
        question => question.category === sourceQuestion.category,
      );
      let targetId = destinationQuestion.wizardId;
      let putBefore = true;
      if (
        destinationQuestion.wizardId ===
        questionsInSourceCategory[questionsInSourceCategory.length - 1].wizardId
      ) {
        const question = questions[sourceIndex - 1];
        if (destinationQuestion.category === question?.category) {
          targetId = question.wizardId;
          putBefore = false;
        }
      } else {
        const question = questions[sourceIndex + 1];
        if (destinationQuestion.category === question?.category) {
          targetId = question.wizardId;
        } else {
          putBefore = false;
        }
      }
      return [
        {
          action: FormEditEventActionCodeEnum.MOVEQUESTION,
          question_uuid: questionUuid,
          target_question: targetId,
          before: putBefore,
        },
      ];
    },
    selector: ({ formCreator }) =>
      update(formCreator, {
        currentForm: {
          categories: {
            $apply: filter((category: FormDefinitionDraftCategoryStatsType) =>
              formCreator.currentForm!.questions.some(
                question => question.category === category.name,
              ),
            ),
          },
        },
      }),
  },
});
