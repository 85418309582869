import { MappingRequest } from '../../../generated/models/MappingRequest';
import { getUploadFormStatus } from './getUploadFormStatus';
import { put } from 'redux-saga/effects';
import { updateUploadsState, updateUploadsStateError } from '../utils';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { Uuid } from '../../../common/common.types';

export interface FormsUploadFormCallParamsType {
  formData: FormData;
  team_id?: Uuid;
}

export type FormsUploadFormActionType = ApiCallActionType<
  '@forms/API/UPLOAD_FORM',
  FormsUploadFormCallParamsType,
  MappingRequest,
  never,
  FormsStateType
>;

export const uploadForm = (
  formData: FormData,
  teamId?: Uuid,
): FormsUploadFormActionType => ({
  type: '@forms/API/UPLOAD_FORM',
  payload: {
    callParams: { formData, team_id: teamId },
    endpointPath: ['forms', 'upload'],
    errorSelector: updateUploadsStateError(formData),
    selector: updateUploadsState,
    postActions: [
      ({ id }: MappingRequest) => put(getUploadFormStatus(id, teamId)),
    ],
  },
});
