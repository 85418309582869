/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormQuestionCommentCreate
 */
export interface FormQuestionCommentCreate {
    /**
     * 
     * @type {string}
     * @memberof FormQuestionCommentCreate
     */
    text: string;
}

export function FormQuestionCommentCreateFromJSON(json: any): FormQuestionCommentCreate {
    return FormQuestionCommentCreateFromJSONTyped(json, false);
}

export function FormQuestionCommentCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormQuestionCommentCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': json['text'],
    };
}

export function FormQuestionCommentCreateToJSON(value?: FormQuestionCommentCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
    };
}


