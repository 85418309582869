import * as R from 'ramda';
import CompaniesTable from './CompaniesTable';
import React, { Fragment } from 'react';
import Select from '../common/Select';
import formatRoute, { COMPANY } from '../../routes';
import { CompaniesFilter } from './CompaniesFilter';
import { CompaniesPageContextType } from 'pages/CompaniesPage';
import { CompanyGradeEnum } from 'generated/models/Company';
import { PageContextConsumer } from 'pages/Page';
import { withTranslation } from 'react-i18next';
import {
  Content,
  PageHeading,
  Wrapper,
  Marginator,
} from 'common/common.styles';
import {
  TranslatePropsType,
  AutocompleteCompanyType,
} from 'common/common.types';
import LoadMore from '../common/LoadMore';

export interface CompaniesFilterFunctionsType {
  add: (tagName: string) => void;
  remove: (tagName: string) => void;
  reset: () => void;
  setSelection: (
    tagName: string | undefined,
    grade: CompanyGradeEnum | undefined,
    hover?: boolean,
  ) => void;
}

const Companies = ({ t }: TranslatePropsType) => (
  <PageContextConsumer>
    {({
      data: { autocomplete, displayedCompanies, filter, limit, myTags },
      actions: {
        clearAutocomplete,
        findCompany,
        filterActions: { addTag, removeTag, reset, setSelection },
        loadMore,
        navigate,
      },
    }: CompaniesPageContextType) => {
      const filterFunctions = {
        add: (tagName: string) => {
          const tag = R.find(({ name }) => name === tagName, myTags);
          tag ? addTag(tag) : alert(t('CompaniesPage.alert'));
        },
        remove: (tagName: string) => {
          const tag = R.find(({ name }) => name === tagName, myTags);
          if (tag) {
            removeTag(tag);
          }
        },
        reset,
        setSelection,
      };

      const handleInputChange = (value: string) => {
        value ? findCompany(value) : clearAutocomplete();
      };

      const handleChange = (selectedCompany?: AutocompleteCompanyType) => {
        if (selectedCompany) {
          navigate(formatRoute(COMPANY, { id: selectedCompany.value }));
        }
      };

      const hasMore = limit > 0 && displayedCompanies.length >= limit;
      const companyHeading = t('Companies.heading');
      const companyEmpty = t('Companies.empty');
      return (
        <Wrapper>
          <Content>
            <PageHeading data-test="page-header">{companyHeading}</PageHeading>

            <Marginator margin={{ bottom: 40 }} data-cy="companySelector">
              <Select
                isClearable={true}
                onInputChange={handleInputChange}
                onChange={handleChange}
                options={autocomplete}
                placeholder={t('Companies.search')}
              />
            </Marginator>

            <CompaniesFilter
              allTags={myTags}
              filter={filter}
              filterFunctions={filterFunctions}
            />

            {displayedCompanies.length > 0 ? (
              <Fragment>
                <CompaniesTable data={displayedCompanies} navigate={navigate} />
                <LoadMore
                  hasMore={hasMore}
                  onLoadMore={() => loadMore(limit)}
                />
              </Fragment>
            ) : (
              <div>{companyEmpty}</div>
            )}
          </Content>
        </Wrapper>
      );
    }}
  </PageContextConsumer>
);

export default withTranslation()(Companies);
