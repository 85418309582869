import { UserStatsType, UserSimpleType, UserType } from 'common/common.types';
import { parseCompany, parseCompanyCredits } from './companies.parsers';
import { EMPTY_ID } from 'common/common.constants';
import { TeamFromJSON } from 'generated/new';

export const parseFillingStats = (
  fillingStats: any,
): UserStatsType['frameworks'] => {
  const {
    questions_filled = 0,
    questions_partially_filled = 0,
    question_count = 0,
    responses_filled = 0,
    response_count = 0,
  } = fillingStats;

  return {
    questions: {
      filledCount: questions_filled,
      partiallyFilledCount: questions_partially_filled,
      totalCount: question_count,
      percentageCompleted: Math.round(
        (questions_filled / question_count) * 100,
      ),
    },
    responses: {
      filledCount: responses_filled,
      totalCount: response_count,
      percentageCompleted: Math.round(
        (responses_filled / response_count) * 100,
      ),
    },
  };
};

export const parseUserStats = (stats: any): UserStatsType => {
  const {
    logins,
    is_new_vendor,
    requests_sent = 0,
    requests_sent_archived = 0,
    requests_received = 0,
    requests_received_archived = 0,
    filling_stats = {},
  } = stats;

  return {
    logins,
    isNewVendor: is_new_vendor,
    archivedRequests: {
      received: requests_received_archived,
      sent: requests_sent_archived,
    },
    requests: {
      received: requests_received,
      sent: requests_sent,
    },
    frameworks: parseFillingStats(filling_stats),
  };
};

export const parseUser = ({
  user = {},
  stats = {},
  toggles = {},
  ld_toggles = {},
  credits,
}: any): UserType => {
  const {
    company = {},
    display_name = '',
    domain = '',
    email = '',
    first_name = '',
    id = EMPTY_ID,
    internal_status,
    is_active,
    last_name = '',
    roles = [],
    teams = [],
  } = user;

  return {
    company: {
      ...parseCompany(company),
      teams: null,
    },
    displayName: display_name,
    credits: credits ? parseCompanyCredits(credits) : undefined,
    domain,
    email,
    firstName: first_name,
    id,
    internalStatus: internal_status,
    isActive: is_active,
    lastName: last_name,
    roles,
    stats: parseUserStats(stats),
    toggles,
    teams: teams.map(TeamFromJSON),
    ldToggles: ld_toggles,
  };
};

export const parseUserSimple = ({ user = {} }: any): UserSimpleType => {
  const { email = '', id = EMPTY_ID } = user;

  return {
    email,
    id,
  };
};
