/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionDataBlocks,
    QuestionDataBlocksFromJSON,
    QuestionDataBlocksFromJSONTyped,
    QuestionDataBlocksToJSON,
    QuestionDetailBase,
    QuestionDetailBaseFromJSON,
    QuestionDetailBaseFromJSONTyped,
    QuestionDetailBaseToJSON,
    QuestionMetadataBlocks,
    QuestionMetadataBlocksFromJSON,
    QuestionMetadataBlocksFromJSONTyped,
    QuestionMetadataBlocksToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionMetadata
 */
export interface QuestionMetadata {
    /**
     * 
     * @type {QuestionDataBlocks}
     * @memberof QuestionMetadata
     */
    blocksData: QuestionDataBlocks;
    /**
     * 
     * @type {QuestionMetadataBlocks}
     * @memberof QuestionMetadata
     */
    blocksMeta: QuestionMetadataBlocks;
    /**
     * 
     * @type {QuestionDetailBase}
     * @memberof QuestionMetadata
     */
    question: QuestionDetailBase;
}

export function QuestionMetadataFromJSON(json: any): QuestionMetadata {
    return QuestionMetadataFromJSONTyped(json, false);
}

export function QuestionMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blocksData': QuestionDataBlocksFromJSON(json['blocks_data']),
        'blocksMeta': QuestionMetadataBlocksFromJSON(json['blocks_meta']),
        'question': QuestionDetailBaseFromJSON(json['question']),
    };
}

export function QuestionMetadataToJSON(value?: QuestionMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blocks_data': QuestionDataBlocksToJSON(value.blocksData),
        'blocks_meta': QuestionMetadataBlocksToJSON(value.blocksMeta),
        'question': QuestionDetailBaseToJSON(value.question),
    };
}


