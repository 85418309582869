/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyLogo,
    CompanyLogoFromJSON,
    CompanyLogoFromJSONTyped,
    CompanyLogoToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyLogoResponse
 */
export interface CompanyLogoResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof CompanyLogoResponse
     */
    msg: string;
    /**
     * 
     * @type {CompanyLogo}
     * @memberof CompanyLogoResponse
     */
    entry: CompanyLogo;
}

export function CompanyLogoResponseFromJSON(json: any): CompanyLogoResponse {
    return CompanyLogoResponseFromJSONTyped(json, false);
}

export function CompanyLogoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyLogoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': CompanyLogoFromJSON(json['entry']),
    };
}

export function CompanyLogoResponseToJSON(value?: CompanyLogoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': CompanyLogoToJSON(value.entry),
    };
}


