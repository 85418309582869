import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  CompletedStandardType,
  LanguageType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import {
  Item,
  ItemWrapper,
  Standard,
  Date,
  Action,
  CheckIcon,
} from './CompletedStandards.styles';
import { Button } from '../common/Button';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { DATE_FORMAT_BASED_ON_LOCALE } from '../../common/common.constants';
import { StateType } from '../../store/store.types';
import { getLanguagePreference } from '../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import getLocale from 'utils/getLocale';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CompletedStandardsListType extends TranslatePropsType {
  data: CompletedStandardType[];
  onPreview: (formId: Uuid) => void;
  onRequest: (standard: CompletedStandardType) => void;
  language?: LanguageType;
}
const CompletedStandards = ({
  data,
  onPreview,
  onRequest,
  t,
  language,
}: CompletedStandardsListType) => {
  const faCheckProp = faCheck as IconProp;
  return (
    <div>
      {data.map((standard: CompletedStandardType) => {
        const {
          id,
          name,
          updatedAt,
          isAlreadyRequested,
          previewFormId,
        } = standard;
        const handleButtonClick = () => onRequest(standard);
        const getActionButton = () => {
          const color = isAlreadyRequested ? 'transparent' : 'teal';
          const translationKey = isAlreadyRequested
            ? 'isAlreadyRequested'
            : 'send';
          const child = isAlreadyRequested ? (
            <CheckIcon icon={faCheckProp} />
          ) : null;
          return (
            <Button color={color} onClick={handleButtonClick}>
              {t(`CompletedStandardsList.${translationKey}`)}
              {child}
            </Button>
          );
        };

        return (
          <ItemWrapper key={id}>
            <Item>
              <Standard
                hasPreview={
                  previewFormId !== null && previewFormId !== undefined
                }
                onClick={
                  previewFormId ? () => onPreview(previewFormId) : undefined
                }
              >
                {name}
              </Standard>
              <Date>
                <>
                  {t('CompletedStandardsList.updatedAt')}
                  <br />
                  {language &&
                    format(
                      updatedAt,
                      DATE_FORMAT_BASED_ON_LOCALE[language.value],
                      { locale: getLocale(language) },
                    )}
                </>
              </Date>
              <Action>{getActionButton()}</Action>
            </Item>
          </ItemWrapper>
        );
      })}
    </div>
  );
};

const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation('components')(CompletedStandards));
