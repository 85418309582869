import { FileType } from '../../../common/common.types';

export interface UserSelectFileActionType {
  type: '@user/SELECT_FILE';
  payload: {
    file?: FileType;
  };
}

export const selectFile = (file?: FileType): UserSelectFileActionType => ({
  type: '@user/SELECT_FILE',
  payload: {
    file,
  },
});
