import styled from 'styled-components';

export const StepContent = styled.div`
  padding-left: 37px;
  position: relative;
  cursor: default;
  margin-bottom: 30px;

  & > div {
    background-color: ${({ theme }) => theme.basic.colors.light};
    padding: 30px 40px;
  }
`;

export const Step = styled.div`
  &:first-of-type {
    ${StepContent} {
      border-left: 2px solid ${({ theme }) => theme.basic.colors.gray};
    }
  }
`;

export const StepHeading = styled.div`
  position: relative;
  color: ${({ theme }) => theme.stepper.heading};
  font-size: 18px;
  padding: 0 0 30px 40px;
  ${({ theme }) => theme.basic.fontWeights.bold}

  span {
    padding-left: 5px;
  }
`;

export const State = styled.div<{ isValid: boolean }>`
  background: ${({ isValid, theme }) =>
    isValid ? theme.basic.colors.teal : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 2px solid ${({ theme }) => theme.basic.colors.teal};
  border-radius: 50%;
  position: absolute;
  top: -7px;
  left: -14px;
`;

export const Label = styled.label<{ width?: number }>`
  display: block;
  line-height: 1.3;
  ${({ theme }) => theme.fonts.bold.small}
  min-width: ${({ width }) => (width ? `${width}px` : `80px`)};
`;

export const LabelSpaced = styled(Label)`
  padding-bottom: 10px;
`;

export const ClickableLabel = styled.label`
  cursor: pointer;
`;
