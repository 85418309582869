import React from 'react';
import { Control, Icon, Name, Separator, Wrapper } from './Controls.styles';
import { withTranslation } from 'react-i18next';
import {
  faArchive,
  faBellSlash,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faEyeSlash,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  RequestArchiveFunctionType,
  RequestClearNotificationsFunctionType,
  RequestDeleteFunctionType,
  RequestMarkReadFunctionType,
} from 'store/requests/actions';
import {
  RequestControlsStatusType,
  TranslatePropsType,
} from 'common/common.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type PermittedRequestControlsActions =
  | RequestArchiveFunctionType
  | RequestClearNotificationsFunctionType
  | RequestDeleteFunctionType
  | RequestMarkReadFunctionType;

export interface ControlsPropsType extends TranslatePropsType {
  actions: {
    archiveRequest?: RequestArchiveFunctionType;
    clearNotifications?: RequestClearNotificationsFunctionType;
    deleteRequest?: RequestDeleteFunctionType;
    toggleAllCategories?: () => void;
    markRead?: RequestMarkReadFunctionType;
  };
  data: {
    allExpanded: boolean;
    controlsStatus: RequestControlsStatusType;
  };
}

const Controls = ({
  actions: {
    archiveRequest,
    clearNotifications,
    deleteRequest,
    toggleAllCategories,
    markRead,
  },
  data: {
    allExpanded,
    controlsStatus: {
      canArchive,
      canClearNotifications,
      canDelete,
      canMarkRead,
      selectedIds,
      selectedRequests,
    },
  },
  t,
}: ControlsPropsType) => {
  const handleClick = (
    fn: PermittedRequestControlsActions,
    condition: boolean,
    params: any,
  ) => () => {
    if (condition) {
      fn(params);
    }
  };

  const sortKey = allExpanded ? 'collapseAll' : 'expandAll';
  const icon = allExpanded ? faCompressArrowsAlt : faExpandArrowsAlt;

  return (
    <Wrapper>
      {clearNotifications && (
        <Control
          data-test="clear-notifications"
          disabled={!canClearNotifications}
          onClick={handleClick(
            clearNotifications,
            canClearNotifications,
            selectedRequests,
          )}
        >
          <Icon icon={faBellSlash as IconProp} />

          <Name>{t('Requests.clearNotifications') as string}</Name>
        </Control>
      )}

      {markRead && (
        <Control
          data-test="mark-as-read"
          disabled={!canMarkRead}
          onClick={handleClick(markRead, canArchive, {
            requests: selectedRequests,
            read: true,
          })}
        >
          <Icon icon={faEyeSlash as IconProp} />

          <Name>{t('Requests.markAsRead') as string}</Name>
        </Control>
      )}

      {archiveRequest && (
        <Control
          disabled={!canArchive}
          onClick={handleClick(archiveRequest, canArchive, selectedIds)}
        >
          <Icon icon={faArchive as IconProp} />

          <Name>{t('Requests.archive') as string}</Name>
        </Control>
      )}

      {deleteRequest && (
        <Control
          disabled={!canDelete}
          onClick={handleClick(deleteRequest, canDelete, selectedIds)}
        >
          <Icon icon={faTrashAlt as IconProp} />

          <Name>{t('Requests.delete') as string}</Name>
        </Control>
      )}

      <Separator />

      {toggleAllCategories && (
        <Control onClick={toggleAllCategories} data-test="expand-all">
          <Icon icon={icon as IconProp} />

          <Name>{t(`Requests.${sortKey}`) as string}</Name>
        </Control>
      )}
    </Wrapper>
  );
};

export default withTranslation()(Controls);
