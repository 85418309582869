import { all, any, contains, map } from 'ramda';
import isNumber from 'utils/isNumber';
import {
  normalizeOption,
  OPTION_EMPTY,
  OPTIONS_YES_NO,
  OPTIONS_YES_NO_NA,
} from './normalizeOption';
import isSameArray from 'utils/isSameArray';

const MIN_LONG_LENGTH = 65;

export enum LabelTypeEnum {
  YES_NO_NA = 'YES_NO_NA',
  NUMBER = 'NUMBER',
  DEFAULT = 'DEFAULT',
  LONG = 'LONG',
}

export interface OptionType {
  option: string;
  normalizedOption: string;
}

/**
 * Consensus between backend and frontend:
 * - When backend detects the response type as YES_NO, YES_NO_NA
 *   all Yes/No/NA options are in UPPERCASE even a user defined them in lowercase
 */
export const isYesNoNAResponse = (options: string[]) => {
  const normalizedOptions = map(normalizeOption, options);

  // Yes/No/NA options created in Form Creator don't contain empty string
  if (!contains(OPTION_EMPTY, normalizedOptions)) {
    normalizedOptions.push(OPTION_EMPTY);
  }

  return (
    isSameArray(normalizedOptions, OPTIONS_YES_NO) ||
    isSameArray(normalizedOptions, OPTIONS_YES_NO_NA)
  );
};

export const isNumberResponse = (options: string[]) =>
  all((option: string) => isNumber(option), options);

export const getOptions = (options: string[]): OptionType[] =>
  options.map(option => ({
    option,
    normalizedOption: normalizeOption(option),
  }));

const isAnyLong = (arr: string[]) => any(s => s.length > MIN_LONG_LENGTH, arr);

export const getLabelType = (options?: string[]): LabelTypeEnum => {
  if (!options) {
    return LabelTypeEnum.DEFAULT;
  } else if (isAnyLong(options)) {
    return LabelTypeEnum.LONG;
  } else if (isNumberResponse(options)) {
    return LabelTypeEnum.NUMBER;
  } else if (isYesNoNAResponse(options)) {
    return LabelTypeEnum.YES_NO_NA;
  }
  return LabelTypeEnum.DEFAULT;
};
