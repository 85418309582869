import styled, { css } from 'styled-components';
import { Flex } from 'common/common.styles';

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.atlas.grey[300]};
  border-radius: 6px;
  padding: 10px 14px;
  cursor: pointer;
  margin: 0 -10px 10px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  line-height: 1.4;

  div {
    max-width: 8%;

    &:first-of-type {
      max-width: 92%;
    }
  }
`;

export const Controls = styled(Flex)`
  margin: 10px -15px 10px;
  border-top: 1px solid ${({ theme }) => theme.atlas.grey[300]};
  padding: 12px 15px 2px;
  justify-content: flex-end;
`;

export const Rating = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
`;

export const ResponseWrapper = styled.div`
  margin: 0 -14px;
  padding: 10px 15px;
  position: relative;

  &:nth-of-type(odd) {
    background: ${({ theme }) => theme.basic.colors.background};
  }

  &:nth-of-type(even) {
    background: ${({ theme }) => theme.basic.colors.light};
  }
`;

const small = css`
  line-height: 1.4;
  margin: 0 0 5px;
  ${({ theme }) => theme.fonts.light.mini}
`;

export const ResponseHeader = styled(Flex)`
  justify-content: center;
  margin: 5px 0;
  ${small}
`;

export const Latest = styled.div`
  width: 60%;
  max-width: 60%;
  text-align: right;
`;

export const Response = styled(Flex)`
  align-items: flex-start;
  justify-content: space-between;
`;

export const ValuesWrapper = styled(Flex)`
  flex: 0 0 80%;
  flex-wrap: wrap;
  max-width: 80%;
  word-break: break-all;
`;

export const Label = styled.div`
  width: 40%;
  max-width: 40%;
  ${small}
`;

export const Thanks = styled.div`
  padding: 30px;
  text-align: center;
  margin: -4px -8px;
  background-color: ${({ theme }) => theme.atlas.teal[900]};
  color: ${({ theme }) => theme.atlas.white};
  ${({ theme }) => theme.fonts.semibold.normal}

  p {
    margin-bottom: 15px;
  }
`;
