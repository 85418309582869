import styled, { css } from 'styled-components';
import { Flex } from 'common/common.styles';
import Input from '../../common/Input';
import { default as OrigAutosavedInfo } from '../../common/AutosavedInfo';

export const Controls = styled(Flex)`
  padding-right: 20px;

  > * + * {
    margin-left: 10px;
  }
`;

export const Title = styled(Flex)`
  align-items: center;
  flex: 1;
`;

export const Version = styled(Flex)`
  align-items: center;
  margin: 0 20px;
`;

export const inputCssProps = css`
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.atlas.text.primary} !important;
  ${({ theme }) => theme.fonts.regular.small}
`;

export const VersionInput = styled(Input).attrs({
  height: 37,
})`
  // Use 'max-width' instead of 'width' to fix layout in Firefox
  max-width: 100px;

  & input {
    ${inputCssProps}
  }
`;

export const TitleInput = styled(Input).attrs({
  height: 37,
})`
  width: 100%;

  & input {
    ${inputCssProps}
  }
`;

export const Label = styled.div`
  margin-right: 10px;
  line-height: 1.3;
  ${({ theme }) => theme.fonts.regular.small}
`;

export const BetaSignWrapper = styled.div`
  position: absolute;
  top: -10px;
  left: 20px;
`;

export const AutosavedInfo = styled(OrigAutosavedInfo)`
  margin-right: 20px;
`;
