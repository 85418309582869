import * as R from 'ramda';
import api from '../../api';
import isCallable from 'is-callable';
import {
  ApiCallActionType,
  ApiCallActionPayloadType,
  ApiCallActionCallbackType,
  StateType,
  NullableEffect,
  ActionType,
} from '../store.types';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { apiCallSuccess } from './actions/apiCallSuccess';
import { captureError } from 'utils/sentryConfig';
import { endLoading } from './actions';
import { error as errorAction } from './actions/error';
import { flash } from '../flashes/actions';
import { getActionDomain } from 'utils/getActionDomain';
import { getErrorMessage } from 'utils/getErrorMessage';
import { trackTiming } from 'utils/analytics';

const errorPlaceholder = {
  status: 901,
  data: {
    msg: 'An unexpected error occurred.',
  },
};

interface Data<ApiSuccResponse, ApiErrorResponse, Meta> {
  entry?: ApiSuccResponse | ApiErrorResponse;
  entries?: ApiSuccResponse | ApiErrorResponse;
  payload?: ApiSuccResponse | ApiErrorResponse;
  meta: Meta;
  msg?: string;
}

interface RawResponse<ApiSuccResponse, ApiErrorResponse, Meta> {
  data: Data<ApiSuccResponse, ApiErrorResponse, Meta>;
  status: number;
}

function* apiCall<
  Action extends string,
  ApiParam,
  ApiSuccResponse extends object,
  ApiErrorResponse,
  LocalState extends object,
  Meta extends object
>(
  action: ApiCallActionType<
    Action,
    ApiParam,
    ApiSuccResponse,
    ApiErrorResponse,
    LocalState,
    Meta
  > &
    OptimisticActionType<
      Action,
      ApiCallActionPayloadType<
        ApiParam,
        ApiSuccResponse,
        ApiErrorResponse,
        LocalState,
        Meta
      >
    >,
) {
  const {
    transactionId,
    type,
    payload: {
      callParams,
      endpointPath,
      errorSelector,
      noPopupForErrorStatuses,
      postActions,
      preActions,
      selector,
      resourceGoneHandlers,
    },
  } = action;

  const domain = getActionDomain(type);

  try {
    if (preActions && preActions.length > 0) {
      yield all(preActions);
    }

    const apiBeginTs = new Date().getTime();

    const state: StateType = yield select(s => s);

    const computedCallParams = isCallable(callParams)
      ? callParams(state)
      : callParams;

    let rawResponse: RawResponse<ApiSuccResponse, ApiErrorResponse, Meta>;
    if (isCallable(endpointPath)) {
      // If endpointPath is function then callParams is required.
      // It is written in the ApiCallActionPayloadType but TypeScript doesn't
      // understand it.

      // tslint:disable-next-line:no-shadowed-variable
      const data: Data<
        ApiSuccResponse,
        ApiErrorResponse,
        Meta
      > = yield call(() => endpointPath(computedCallParams!).toPromise());
      rawResponse = {
        data,
        status: 200,
      };
    } else {
      rawResponse = yield call(
        // @ts-ignore
        R.path(endpointPath, api),
        computedCallParams,
      );
    }

    const {
      data: { entry, entries, payload, meta, msg, ...data },
      status,
    } = rawResponse;

    const entryOrEntries = entry || entries || payload || data;

    const apiEndTs = new Date().getTime();

    if (Array.isArray(endpointPath)) {
      trackTiming(
        'API',
        endpointPath.join('/'),
        apiEndTs - apiBeginTs,
        msg || '',
      );
    }

    if (status === 200 || status === 202) {
      const payloadSucc = entryOrEntries as ApiSuccResponse;

      yield all([
        put(apiCallSuccess({ domain, data: payloadSucc, meta, selector })),
        put(endLoading()),
      ]);

      if (postActions) {
        let effects: Array<ApiCallActionCallbackType<
          ApiSuccResponse,
          NullableEffect,
          Meta
        >> = [];
        if (isCallable(postActions)) {
          effects = postActions(payloadSucc, state, meta);
        } else if (Array.isArray(postActions) && postActions.length > 0) {
          effects = postActions;
        }
        const preparedEffects = effects.map(effect =>
          effect(payloadSucc, state, meta),
        );

        for (const e of preparedEffects) {
          yield e;
        }
      }
    } else {
      const payloadErr = entryOrEntries as ApiErrorResponse;

      const errorMsg = getErrorMessage(
        status,
        type,
        msg || errorPlaceholder.data.msg,
      );

      yield all([
        put(
          errorAction(
            { domain, data: payloadErr, meta, selector: errorSelector },
            transactionId,
          ),
        ),
        put(endLoading()),
        put(flash(errorMsg, 'error')),
      ]);
    }
  } catch (exception) {
    let error = exception;
    if (typeof exception.status !== 'undefined') {
      // RxJS error. Needs conversion to different format.
      error = {
        response: {
          data: exception.response,
          status:
            exception.status !== 0 ? exception.status : errorPlaceholder.status,
        },
      };
    }

    if (error.response === undefined) {
      error.response = errorPlaceholder;
      error.response.status = error.message;
    }

    const {
      response,
      response: { status, data },
    } = error;

    yield put(endLoading());

    if (status === 410 && resourceGoneHandlers) {
      const effects = resourceGoneHandlers.map((effect: any) => effect(data));

      for (const e of effects) {
        yield e;
      }
    }

    // Don't show error popups for these errors because these are handled in a
    // different way (redirect, custom handler, ...)
    if (
      !R.anyPass([
        R.propEq('status', 401),
        R.propEq('status', 402),
        R.propEq('status', 403),
        R.propEq('status', 410),
      ])(response)
    ) {
      const errorMsg = getErrorMessage(
        status,
        type,
        (response.data && response.data.msg) || errorPlaceholder.data.msg,
      );

      captureError(error, { callParams, endpointPath, type });

      yield put(
        errorAction(
          {
            domain,
            data: response.data,
            meta: response.data.meta,
            selector: errorSelector,
          },
          transactionId,
        ),
      );

      const popupDisabledForThisError =
        noPopupForErrorStatuses &&
        R.contains(response.status, noPopupForErrorStatuses);

      if (!popupDisabledForThisError) {
        yield put(flash(errorMsg, 'error'));
      }
    }
  }
}

export function* watchApiCallSaga() {
  yield takeEvery((action: ActionType) => {
    return new RegExp('/API/').test(action.type);
  }, apiCall);
}
