import React, { useContext } from 'react';
import { PageContext } from 'pages/Page';
import { withTranslation } from 'react-i18next';
import { Content } from 'common/common.styles';
import { ToggleEnum, TranslatePropsType } from 'common/common.types';
import { PageHeader } from '../common/PageHeader';
import { FormDefinitionsPageContextType } from 'pages/FormDefinitionsPage';
import FormDefinitionsHeader from './FormDefinitionsHeader';
import FormDefinitionsList from './FormDefinitionsList';
import hasToggle from 'utils/hasToggle';

const FormDefinitions = ({ t }: TranslatePropsType) => {
  const {
    data: {
      formDefinitions: {
        standards: {
          data,
          selected,
          filter: { sortBy, byStatus },
        },
      },
      previewForm,
      user,
      language,
    },
    actions,
    actions: { setSort, setFilterByStatus, showModal, createEmptyForm },
  } = useContext(PageContext) as FormDefinitionsPageContextType;

  const hasConditionsToggle = hasToggle(user, [
    ToggleEnum.FORM_CREATOR_CONDITIONS,
  ]);

  const HeaderComponent = () => (
    <FormDefinitionsHeader
      filter={{ sortBy, byStatus }}
      setFilterByStatus={setFilterByStatus}
      setSort={setSort}
      showBetaSign={!hasConditionsToggle}
      showModal={showModal}
      createEmptyForm={createEmptyForm}
    />
  );

  return (
    <>
      <PageHeader heading={<HeaderComponent />} />
      <Content>
        <FormDefinitionsList
          actions={actions}
          data={{
            standards: data,
            sortBy,
            previewForm,
            user,
            selected,
            language,
          }}
        />
      </Content>
    </>
  );
};

export default withTranslation('FormDefinitions')(FormDefinitions);
