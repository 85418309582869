import {
  DatepickerGroup,
  Header,
  RadioGroup,
  Section,
  Title,
} from './FilterBy.styles';
import { startOfDay, endOfDay } from 'date-fns';
import { assocPath, contains, equals } from 'ramda';
import { FilterByPropsType } from './FilterBy';
import React, { useCallback, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import Radio from '../../common/Radio';
import Datepicker from '../../common/Datepicker';
import { DATE_FORMAT } from 'common/common.constants';
import {
  DATE_FILTERS,
  FilterDateIntervalType,
  FilterDateType,
} from 'utils/filters/requests.filters';
import ReactDatePicker from 'react-datepicker';

const { ALL, CUSTOM } = FilterDateIntervalType;

const OPTION = 'DATE';

const FilterDate = ({
  data: {
    filterBy,
    filterBy: {
      DATE,
      DATE: { from, interval, to },
    },
  },
  actions: { setFilter },
  t,
}: FilterByPropsType) => {
  const ref = useRef<ReactDatePicker>(null);
  const handleChange = useCallback(
    (
      type: FilterDateIntervalType,
      fromDate?: Date | null,
      toDate?: Date | null,
      setOpen?: boolean,
    ) => {
      const value: FilterDateType = {
        isEnabled: true,
        from: undefined,
        to: undefined,
        interval: type,
      };

      if (type === ALL) {
        value.isEnabled = false;
      } else if (type === CUSTOM) {
        if (setOpen && ref && ref.current) {
          ref.current!.setOpen(true, true);
          return;
        }
        value.from = fromDate ? startOfDay(fromDate).toISOString() : from;
        value.to = toDate ? endOfDay(toDate).toISOString() : to;
        value.isEnabled = !!value.from || !!value.to;
      }

      if (!equals(value, DATE)) {
        setFilter(assocPath([OPTION], value, filterBy));
      }
    },
    [DATE, filterBy, from, setFilter, to],
  );

  return (
    <Section>
      <Header>
        <Title>{t(`Requests.filterOptions.${OPTION}`) as string}</Title>
      </Header>
      <RadioGroup>
        {DATE_FILTERS.map(type => (
          <Radio
            key={type}
            isSelected={interval === type}
            label={t(`Requests.dateFilter.${type}`)}
            handleChange={() => handleChange(type, undefined, undefined, true)}
            size="small"
            version="light"
          />
        ))}
      </RadioGroup>
      {contains(CUSTOM, DATE_FILTERS) && (
        <DatepickerGroup>
          <Datepicker
            ref={ref}
            className="datepicker-input"
            dateFormat={DATE_FORMAT}
            onCalendarOpen={() => handleChange(CUSTOM)}
            onChange={val => handleChange(CUSTOM, val)}
            placeholderText={t('common:labels.from')}
            selected={interval === CUSTOM && from ? new Date(from) : undefined}
          />
          <Datepicker
            className="datepicker-input"
            dateFormat={DATE_FORMAT}
            onCalendarOpen={() => handleChange(CUSTOM)}
            onChange={val => handleChange(CUSTOM, undefined, val)}
            placeholderText={t('common:labels.to')}
            selected={interval === CUSTOM && to ? new Date(to) : undefined}
          />
        </DatepickerGroup>
      )}
    </Section>
  );
};

export default withTranslation()(FilterDate);
