import Linkify from 'react-linkify';
import Loader from 'react-loader-spinner';
import React from 'react';
import { format } from 'date-fns';
import { Bubble, MessageWrapper, Metadata } from './Message.styles';
import { DATE_TIME_FORMAT_BASED_ON_LOCALE } from 'common/common.constants';
import { LanguageType, MessageType } from 'common/common.types';
import getLocale from 'utils/getLocale';
import { connect } from 'react-redux';
import { StateType } from '../../../../store/store.types';
import { getLanguagePreference } from '../../../../store/settings/settings.selectors';

export interface MessagePropsType {
  message: MessageType;
  language: LanguageType;
}

const Message = ({
  message: { id, user, createdAt, isMine, postingId, text, isInternal },
  language,
}: MessagePropsType) => {
  if (!id) {
    return (
      <MessageWrapper isMine isPosting key={`message-${postingId}`}>
        <Metadata isMine>
          {format(createdAt, DATE_TIME_FORMAT_BASED_ON_LOCALE[language.value], {
            locale: getLocale(language),
          })}
        </Metadata>

        <Bubble isMine>
          <Loader type="Oval" color="#333" width={14} height={14} />
        </Bubble>
      </MessageWrapper>
    );
  }

  return (
    <MessageWrapper isMine={isMine} key={`message-${id}`}>
      <Metadata isMine={isMine} title={user && user.displayName}>
        {user && `${user.displayName} `}

        {format(createdAt, DATE_TIME_FORMAT_BASED_ON_LOCALE[language.value], {
          locale: getLocale(language),
        })}
      </Metadata>

      <Bubble
        isMine={isMine}
        isInternal={isInternal}
        data-test="comment-bubble"
      >
        <Linkify properties={{ target: '_blank' }}>{text}</Linkify>
      </Bubble>
    </MessageWrapper>
  );
};

const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});

export default connect(mapStateToProps, null)(Message);
