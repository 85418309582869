import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Tooltip from '../Tooltip';
import { QuestionMark, QuestionText } from './QuestionMarkTooltip.styles';

interface QuestionMarkTooltipPropsType {
  className?: string;
  text: string;
  size?: number;
}

const QuestionMarkTooltip = ({
  className,
  text,
  size,
}: QuestionMarkTooltipPropsType) => (
  <div
    className={className}
    data-cy="questionMark"
    style={{ width: size, height: size }}
  >
    <Tooltip overlay={<QuestionText>{ReactHtmlParser(text)}</QuestionText>}>
      <QuestionMark size={size}>?</QuestionMark>
    </Tooltip>
  </div>
);

export default QuestionMarkTooltip;
