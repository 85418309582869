import styled from 'styled-components';

export const UploadBox = styled.div<{ hasDrag?: boolean }>`
  background: ${({ hasDrag, theme }) =>
    hasDrag
      ? theme.basic.colors.light
      : theme.sendRequest.uploadBar.background};
  border: 2px dashed ${({ theme }) => theme.basic.colors.atlas};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 100px;
  position: relative;
`;

export const Instructions = styled.ol`
  margin-left: 46px;

  li {
    padding: 6px 0;
    ${({ theme }) => theme.basic.fontSizes.small}
  }

  a {
    color: ${({ theme }) => theme.basic.colors.atlas};
    ${({ theme }) => theme.fonts.semibold.normal}

    &:hover {
      text-decoration: none;
    }
  }
`;
