/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormDefinitionDraftCategoryStats,
    FormDefinitionDraftCategoryStatsFromJSON,
    FormDefinitionDraftCategoryStatsFromJSONTyped,
    FormDefinitionDraftCategoryStatsToJSON,
    FormDefinitionSummaryExtended,
    FormDefinitionSummaryExtendedFromJSON,
    FormDefinitionSummaryExtendedFromJSONTyped,
    FormDefinitionSummaryExtendedToJSON,
    QuestionDefinitionSummary,
    QuestionDefinitionSummaryFromJSON,
    QuestionDefinitionSummaryFromJSONTyped,
    QuestionDefinitionSummaryToJSON,
    Standard,
    StandardFromJSON,
    StandardFromJSONTyped,
    StandardToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormDefinitionSummaryDraftExtended
 */
export interface FormDefinitionSummaryDraftExtended {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    updatedAt: Date;
    /**
     * Status for form definition initialization (creation).
     * @type {string}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    creationStatus: FormDefinitionSummaryDraftExtendedCreationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    ownerId: string;
    /**
     * ID of my own preview form (if available)
     * @type {string}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    previewFormId?: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    standardId: string;
    /**
     * 
     * @type {number}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    withConditionCount: number;
    /**
     * 
     * @type {Array<FormDefinitionDraftCategoryStats>}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    categories?: Array<FormDefinitionDraftCategoryStats>;
    /**
     * 
     * @type {Array<QuestionDefinitionSummary>}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    questions?: Array<QuestionDefinitionSummary>;
    /**
     * 
     * @type {Standard}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    standard: Standard;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryDraftExtended
     */
    nextEventToken: string;
}

export function FormDefinitionSummaryDraftExtendedFromJSON(json: any): FormDefinitionSummaryDraftExtended {
    return FormDefinitionSummaryDraftExtendedFromJSONTyped(json, false);
}

export function FormDefinitionSummaryDraftExtendedFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormDefinitionSummaryDraftExtended {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'creationStatus': json['creation_status'],
        'description': json['description'],
        'id': json['id'],
        'name': json['name'],
        'ownerId': json['owner_id'],
        'previewFormId': !exists(json, 'preview_form_id') ? undefined : json['preview_form_id'],
        'standardId': json['standard_id'],
        'withConditionCount': json['with_condition_count'],
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(FormDefinitionDraftCategoryStatsFromJSON)),
        'questions': !exists(json, 'questions') ? undefined : ((json['questions'] as Array<any>).map(QuestionDefinitionSummaryFromJSON)),
        'standard': StandardFromJSON(json['standard']),
        'nextEventToken': json['next_event_token'],
    };
}

export function FormDefinitionSummaryDraftExtendedToJSON(value?: FormDefinitionSummaryDraftExtended | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'creation_status': value.creationStatus,
        'description': value.description,
        'id': value.id,
        'name': value.name,
        'owner_id': value.ownerId,
        'preview_form_id': value.previewFormId,
        'standard_id': value.standardId,
        'with_condition_count': value.withConditionCount,
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(FormDefinitionDraftCategoryStatsToJSON)),
        'questions': value.questions === undefined ? undefined : ((value.questions as Array<any>).map(QuestionDefinitionSummaryToJSON)),
        'standard': StandardToJSON(value.standard),
        'next_event_token': value.nextEventToken,
    };
}

/**
* @export
* @enum {string}
*/
export enum FormDefinitionSummaryDraftExtendedCreationStatusEnum {
    FINISHEDFAIL = 'FINISHED_FAIL',
    FINISHEDSUCCESS = 'FINISHED_SUCCESS',
    INPROGRESS = 'IN_PROGRESS',
    INQUEUE = 'IN_QUEUE'
}


