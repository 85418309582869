import { MappingHistoryType } from 'common/common.types';
import { ResponseFillingHistory } from 'generated/models';

export const parseMapping = ({
  action,
  message = '',
  created_at = new Date(0),
  score = 0,
}: ResponseFillingHistory): MappingHistoryType => {
  return {
    action,
    createdAt: new Date(created_at),
    message,
    score,
  };
};
