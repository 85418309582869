import { formatRoute as origFormatRoute } from 'react-router-named-routes';
import config from './config';

export const getSignInUrl = () => {
  const path = `${window.location.toString().split('/#/')[1] || ''}`;
  return path
    ? `${config.platformUrl}/#/external/atlas?path=${encodeURIComponent(
        `/#/${path}`,
      )}`
    : `${config.platformUrl}/#/external/atlas`;
};

export const LOGIN = getSignInUrl();

export const ATTACHMENT = '/evidences/:id';
export const COMPANIES = '/companies';
export const COMPANY = '/companies/:id/:tab?';
export const DOWNLOAD_EVIDENCE = '/download/evidence/:id';
export const EVIDENCE = '/evidence/:id';
export const FAQ = '/faq';
export const FEEDBACK = 'https://securityscorecard.ideas.aha.io/ideas';
export const FIRST_RUN = '/first-run';
export const FORM = '/form/:id';
export const FORM_FINDINGS = '/form-findings/:id';
export const FORM_CREATOR = '/form-creator/:id';
export const FORM_DEFINITION_PREVIEW = '/form-definition-preview/:id';
export const FORM_DEFINITIONS = '/form-definitions';
export const FORM_NEW_FROM_EXISTING = '/form-definitions/new-from-template';
export const FRAMEWORKS_DASHBOARD = '/frameworks/:tab?';
export const HOMEPAGE = '/';
export const INSIGHTS = '/insights';
export const P403 = '/403';
export const MAINTENANCE_PAGE = '/maintenance';
export const QUESTIONNAIRE = '/questionnaire/:id';
export const REQUESTS = '/requests';
export const REQUESTS_ARCHIVE = '/requests/archive';
export const REQUESTS_RECEIVED = '/requests/received';
export const REQUESTS_SENT = '/requests/sent/:tab?';
export const SALES = '/sales';
export const SEND_REQUEST = '/send-request';
export const SETTINGS = '/settings';
export const SHARED_FORM = '/share/:code';
export const SSC_HOME = config.platformUrl;
export const UPLOAD_CUSTOM_STANDARD = '/upload-custom';
export const UTILS = '/gLkcEogCtm';
export const NEW_VENDOR_LANDING_PAGE = '/receiver-onboarding';

export const ATLAS_UI_MIGRATION = config.platformUrl + '/#/assessments';

export const getEvidenceLockerURL = (domain: string) =>
  `${config.platformUrl}/#/scorecard/${domain}/compliance`;

export const getEvidenceDownloadURL = (id: number | string) =>
  `${config.url}/atlas/evidences/download/${id}`;

const formatRoute = (routeName: string, params?: any): string =>
  origFormatRoute(routeName, params);

export default formatRoute;
