import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Heading, Inner, Title, Wrapper, HeadingIcon } from './Dropdown.styles';
import { TranslatePropsType } from 'common/common.types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface DropdownPropsType extends TranslatePropsType {
  children: React.ReactNode;
  handleClose: () => void;
  headerRight?: React.ReactNode;
  title?: string;
  titleKey?: string;
}

const Dropdown = ({
  children,
  handleClose,
  headerRight,
  t,
  title,
  titleKey,
}: DropdownPropsType) => (
  <Wrapper>
    <Heading>
      {(title || titleKey) && (
        <Title>{title || (t(`dropdowns.${titleKey}`) as string)}</Title>
      )}

      {headerRight}

      <HeadingIcon onClick={handleClose} data-cy="dropdown-close-button">
        <FontAwesomeIcon icon={faTimes as IconProp} color="#000" />
      </HeadingIcon>
    </Heading>

    <Inner>{children}</Inner>
  </Wrapper>
);

export default withTranslation('FormHeader')(Dropdown);
