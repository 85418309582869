import React from 'react';
import getAuditComponent from './Audit/getAuditComponent';
import {
  ContentContainer,
  ContentWrapper,
  ScrollWrapper,
} from '../QuestionTabs.styles';
import { TabContentTitle } from '../';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { QuestionHistoryType, TranslatePropsType } from 'common/common.types';

interface HistoryPropsType extends TranslatePropsType {
  history: QuestionHistoryType[];
}

const History = ({ history, t }: HistoryPropsType) => (
  <ContentContainer hasBackground={!!history.length}>
    <ContentWrapper>
      <TabContentTitle icon={faHistory} title={t('title') as string} />

      {history.length > 0 ? (
        <ScrollWrapper data-test="history-records">
          {history.map((change: QuestionHistoryType) => {
            const AuditComponent = getAuditComponent(change.type);

            return <AuditComponent key={change.id} change={change} t={t} />;
          })}
        </ScrollWrapper>
      ) : (
        <p>{t('noHistory') as string}</p>
      )}
    </ContentWrapper>
  </ContentContainer>
);

export default withTranslation('History')(History);
