/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface FormQuestionsUpdateStatus
 */
export interface FormQuestionsUpdateStatus {
    /**
     * 
     * @type {string}
     * @memberof FormQuestionsUpdateStatus
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof FormQuestionsUpdateStatus
     */
    status: FormQuestionsUpdateStatusStatusEnum;
}




/**
* @export
* @enum {string}
*/
export enum FormQuestionsUpdateStatusStatusEnum {
    DONE = 'DONE',
    FLAGGED = 'FLAGGED',
    PENDING = 'PENDING'
}


