import colors from 'common/common.colors';
import styled from 'styled-components';
import { Content } from 'common/common.styles';
import { default as HexComponent } from '../../common/Hexagon';
import OrigUnverified from '../../Requests/Request/Unverified';

export const Banner = styled(Content)`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0;
  position: relative;
  z-index: 2;
`;

export const CompanyDetails = styled.div`
  display: flex;
  ${({ theme }) => theme.fonts.light.normal}
  color: ${colors.basic.black};
  margin-bottom: 10px;
  margin-left: 1px;
`;

export const CompanyInfo = styled.span`
  display: flex;
  flex-direction: column;
  font-weight: 200;
  margin-left: 79px;
  margin-top: 45px;
`;

export const CompanyName = styled.span`
  display: flex;
  ${({ theme }) => theme.fonts.light.massive}
  color: ${colors.basic.black};
`;

export const Domain = styled.a`
  color: ${colors.basic.black};
  text-decoration: none;
  ${({ theme }) => theme.fonts.bold.normal}
  padding-right: 4px;
`;

export const Hexagon = styled(HexComponent)`
  z-index: 4;
`;

export const RacingStripe = styled.div`
  background-color: ${colors.elements.header.stripe};
  border-top-right-radius: 8px;
  bottom: -18px;
  height: 90%;
  position: absolute;
  right: -28px;
  transform: skewX(25deg);
  width: calc(100vw - 1170px);
  min-width: 30vw;
  z-index: -1;
`;

export const Score = styled.div`
  ${({ theme }) => theme.fonts.regular.bigger}
  font-weight: 200;
  z-index: 4;
`;

export const ScoreDetail = styled.a`
  ${({ theme }) => theme.fonts.light.mini}
  z-index: 4;
`;

export const ScoreInfo = styled.div`
  align-self: flex-start;
  flex-direction: column;
  height: 135px;
  margin: 30px 40px 15px 30px;
  position: relative;
  text-align: center;
  width: 174px;
  z-index: 3;
`;

export const ScoreText = styled.div`
  ${({ theme }) => theme.fonts.light.mini}
  text-transform: uppercase;
  z-index: 4;
`;

export const Unverified = styled(OrigUnverified)`
  margin-top: 10px;
  justify-content: center;
`;

export const Wrapper = styled.div`
  background-color: ${colors.elements.header.background};
  height: 183px;
  width: 100%;
  z-index: 1;
`;
