/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestDetail,
    RequestDetailFromJSON,
    RequestDetailFromJSONTyped,
    RequestDetailToJSON,
    RequestFiltersStatsMeta,
    RequestFiltersStatsMetaFromJSON,
    RequestFiltersStatsMetaFromJSONTyped,
    RequestFiltersStatsMetaToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface RequestDetailsListWithRequestFiltersStatsResponse
 */
export interface RequestDetailsListWithRequestFiltersStatsResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof RequestDetailsListWithRequestFiltersStatsResponse
     */
    msg: string;
    /**
     * 
     * @type {Array<RequestDetail>}
     * @memberof RequestDetailsListWithRequestFiltersStatsResponse
     */
    entries: Array<RequestDetail>;
    /**
     * 
     * @type {RequestFiltersStatsMeta}
     * @memberof RequestDetailsListWithRequestFiltersStatsResponse
     */
    meta: RequestFiltersStatsMeta;
}

export function RequestDetailsListWithRequestFiltersStatsResponseFromJSON(json: any): RequestDetailsListWithRequestFiltersStatsResponse {
    return RequestDetailsListWithRequestFiltersStatsResponseFromJSONTyped(json, false);
}

export function RequestDetailsListWithRequestFiltersStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestDetailsListWithRequestFiltersStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entries': ((json['entries'] as Array<any>).map(RequestDetailFromJSON)),
        'meta': RequestFiltersStatsMetaFromJSON(json['meta']),
    };
}

export function RequestDetailsListWithRequestFiltersStatsResponseToJSON(value?: RequestDetailsListWithRequestFiltersStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entries': ((value.entries as Array<any>).map(RequestDetailToJSON)),
        'meta': RequestFiltersStatsMetaToJSON(value.meta),
    };
}


