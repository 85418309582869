/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyWithTags,
    CompanyWithTagsFromJSON,
    CompanyWithTagsFromJSONTyped,
    CompanyWithTagsToJSON,
    CompletedStandardsSummary,
    CompletedStandardsSummaryFromJSON,
    CompletedStandardsSummaryFromJSONTyped,
    CompletedStandardsSummaryToJSON,
    RequestSummary,
    RequestSummaryFromJSON,
    RequestSummaryFromJSONTyped,
    RequestSummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyDetail
 */
export interface CompanyDetail {
    /**
     * 
     * @type {CompanyWithTags}
     * @memberof CompanyDetail
     */
    companyWithTags: CompanyWithTags;
    /**
     * 
     * @type {CompletedStandardsSummary}
     * @memberof CompanyDetail
     */
    completedStandards: CompletedStandardsSummary;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetail
     */
    ownerId: string;
    /**
     * 
     * @type {Array<RequestSummary>}
     * @memberof CompanyDetail
     */
    requestsIn?: Array<RequestSummary>;
    /**
     * 
     * @type {Array<RequestSummary>}
     * @memberof CompanyDetail
     */
    requestsOut?: Array<RequestSummary>;
}

export function CompanyDetailFromJSON(json: any): CompanyDetail {
    return CompanyDetailFromJSONTyped(json, false);
}

export function CompanyDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyWithTags': CompanyWithTagsFromJSON(json['company_with_tags']),
        'completedStandards': CompletedStandardsSummaryFromJSON(json['completed_standards']),
        'ownerId': json['owner_id'],
        'requestsIn': !exists(json, 'requests_in') ? undefined : ((json['requests_in'] as Array<any>).map(RequestSummaryFromJSON)),
        'requestsOut': !exists(json, 'requests_out') ? undefined : ((json['requests_out'] as Array<any>).map(RequestSummaryFromJSON)),
    };
}

export function CompanyDetailToJSON(value?: CompanyDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_with_tags': CompanyWithTagsToJSON(value.companyWithTags),
        'completed_standards': CompletedStandardsSummaryToJSON(value.completedStandards),
        'owner_id': value.ownerId,
        'requests_in': value.requestsIn === undefined ? undefined : ((value.requestsIn as Array<any>).map(RequestSummaryToJSON)),
        'requests_out': value.requestsOut === undefined ? undefined : ((value.requestsOut as Array<any>).map(RequestSummaryToJSON)),
    };
}


