import React from 'react';
import ReactSVG from 'react-svg';
import { RequestsViewType } from 'store/requests/requests.types';
import { Button } from '../common/Button';
import { Root } from './ViewSwitcher.styles';
import { RequestsSwitchViewFunctionType } from 'store/requests/actions';

interface ViewSwitcherPropsType {
  actions: {
    switchView: RequestsSwitchViewFunctionType;
  };
  data: {
    view: RequestsViewType;
  };
}

const ViewSwitcher = ({
  actions: { switchView },
  data: { view },
}: ViewSwitcherPropsType) => {
  const isKanban = view === 'Kanban';

  return (
    <Button
      color="transparent"
      onClick={() => switchView(isKanban ? 'List' : 'Kanban')}
      size="medium"
      style={{ minWidth: 29 }}
    >
      <Root>
        <ReactSVG src={isKanban ? '/table-view.svg' : '/column-view.svg'} />
      </Root>
    </Button>
  );
};

export default ViewSwitcher;
