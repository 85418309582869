import styled, { css } from 'styled-components';
import { Hoverable } from '../../../SendRequest/StandardListItem.styles';
import { Checkmark } from '../../../common/Checkbox/Checkbox.styles';

export const Wrapper = styled(Hoverable)<{ isDisabled: boolean }>`
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          &:hover,
          &:hover ${Checkmark} {
            background-color: initial;
          }
        `
      : css`
          cursor: pointer;
          &:hover,
          &:hover ${Checkmark} {
            background-color: ${({ theme }) =>
              theme.inputs.multiselect.default.hover};
          }
        `}
`;
