/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormFilterStats
 */
export interface FormFilterStats {
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    attachments: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    attachmentsNew: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    autocompleteDone: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    autocompleteSuggestionsAvailable: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    comments: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    commentsNew: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    completelyFilled: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    filled: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    na: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    no: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    notFilled: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    partiallyFilled: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    required: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    responses: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    responsesNew: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    sscData: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    sscDataNew: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    unassigned: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    vrmReviewDone: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    vrmReviewFlagged: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    vrmReviewPending: number;
    /**
     * 
     * @type {number}
     * @memberof FormFilterStats
     */
    yes: number;
}

export function FormFilterStatsFromJSON(json: any): FormFilterStats {
    return FormFilterStatsFromJSONTyped(json, false);
}

export function FormFilterStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormFilterStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachments': json['attachments'],
        'attachmentsNew': json['attachments_new'],
        'autocompleteDone': json['autocomplete_done'],
        'autocompleteSuggestionsAvailable': json['autocomplete_suggestions_available'],
        'comments': json['comments'],
        'commentsNew': json['comments_new'],
        'completelyFilled': json['completely_filled'],
        'filled': json['filled'],
        'na': json['na'],
        'no': json['no'],
        'notFilled': json['not_filled'],
        'partiallyFilled': json['partially_filled'],
        'required': json['required'],
        'responses': json['responses'],
        'responsesNew': json['responses_new'],
        'sscData': json['ssc_data'],
        'sscDataNew': json['ssc_data_new'],
        'unassigned': json['unassigned'],
        'vrmReviewDone': json['vrm_review_done'],
        'vrmReviewFlagged': json['vrm_review_flagged'],
        'vrmReviewPending': json['vrm_review_pending'],
        'yes': json['yes'],
    };
}

export function FormFilterStatsToJSON(value?: FormFilterStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachments': value.attachments,
        'attachments_new': value.attachmentsNew,
        'autocomplete_done': value.autocompleteDone,
        'autocomplete_suggestions_available': value.autocompleteSuggestionsAvailable,
        'comments': value.comments,
        'comments_new': value.commentsNew,
        'completely_filled': value.completelyFilled,
        'filled': value.filled,
        'na': value.na,
        'no': value.no,
        'not_filled': value.notFilled,
        'partially_filled': value.partiallyFilled,
        'required': value.required,
        'responses': value.responses,
        'responses_new': value.responsesNew,
        'ssc_data': value.sscData,
        'ssc_data_new': value.sscDataNew,
        'unassigned': value.unassigned,
        'vrm_review_done': value.vrmReviewDone,
        'vrm_review_flagged': value.vrmReviewFlagged,
        'vrm_review_pending': value.vrmReviewPending,
        'yes': value.yes,
    };
}


