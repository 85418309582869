import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { State, Step, StepContent, StepHeading } from './Stepper.styles';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

// NOTE: This component originally supported expanding/collapsing of individual
//       steps, but we decided to remove it because it felt unnecessary for the
//       simple two-step process. If you need the interactive version, you can
//       find it on Git somewhere before 2020-08-05.

type StepType = {
  name: string;
  component: React.ReactElement<any>;
  isValid: boolean;
};

interface StepperPropsType {
  steps: StepType[];
}

const Stepper = ({ steps }: StepperPropsType) => {
  return (
    <>
      {steps.map(({ name, component, isValid }, index) => (
        <Step key={index}>
          <StepHeading>
            <State isValid={isValid}>
              {isValid && (
                <FontAwesomeIcon icon={faCheck as IconProp} color="#fff" />
              )}
            </State>

            {`${index + 1}.`}

            <span>{name}</span>
          </StepHeading>

          <StepContent>{component}</StepContent>
        </Step>
      ))}
    </>
  );
};

export default memo(Stepper);
