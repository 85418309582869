import styled from 'styled-components';

export const LogoWrapper = styled.div`
  top: 100px;
  left: 0;
  right: 0;
  position: absolute;
  margin: 0 auto;
  width: 132px;
  height: 66px;
`;

export const LoginForm = styled.div`
  position: absolute;
  width: 460px;
  padding: 15px 105px 35px;
  margin: 0 auto;
  top: 200px;
  left: 0;
  right: 0;
  background-color: #f6f6f6;
  border-radius: 2px;
  box-shadow: 0 8px 21px 0 rgba(86, 155, 161, 0.15);
  color: #262529;
`;

export const LoginTitle = styled.h2`
  margin-bottom: 20px;
  text-align: center;
  ${({ theme }) => theme.fonts.light.bigger}
`;

export const InputField = styled.input`
  margin-bottom: 10px;
  width: 100%;
  background-color: #fff;
  height: 36px;
  border: 1px solid #babcbe;
  border-radius: 2px;
  padding: 13px;
  color: #262529;
  font-size: ${({ theme }) => theme.basic.fontSizes.mini};
`;

export const Button = styled.button`
  margin-bottom: 10px;
  width: 100%;
  height: 42px;
  border: 2px solid #babcbe;
  border-radius: 6px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  background-color: #f6f6f6;
`;
