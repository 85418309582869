import React from 'react';
import { BarWrapper } from '../common/Bar/Bar.styles';
import { withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TranslatePropsType } from 'common/common.types';

import { CreateEmptyFormFunctionType } from 'store/formCreator/actions';

import { TextButton } from 'common/common.styles';

import {
  Controls,
  TitleWrapper,
  StartFromScratchWrapper,
  BackButtonWrapper,
} from './FormNewFromExistingHeader.styles';

interface HeaderType extends TranslatePropsType, RouteComponentProps {
  createEmptyForm: CreateEmptyFormFunctionType;
}

const FormNewFromExistingHeader = ({
  history: { push: navigate, goBack },
  createEmptyForm,
  t,
}: HeaderType) => (
  <BarWrapper>
    <TitleWrapper>
      <BackButtonWrapper>
        <TextButton size={'mini'} color={'atlas'} onClick={goBack}>
          {`< ${t('goBack') as string}`}
        </TextButton>
      </BackButtonWrapper>

      <h2>{t('heading') as string}</h2>
    </TitleWrapper>

    <div>
      <Controls>
        <StartFromScratchWrapper>
          <TextButton
            size={'small'}
            color={'atlas'}
            onClick={() => createEmptyForm()}
          >
            {t('FormDefinitions:startFromScratch') as string}
          </TextButton>
        </StartFromScratchWrapper>
      </Controls>
    </div>
  </BarWrapper>
);

export default withTranslation(['FormNewFromExisting', 'FormDefinitions'])(
  withRouter(FormNewFromExistingHeader),
);
