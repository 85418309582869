import React from 'react';
import ReactModal from 'react-modal';
import { content, Wrapper } from './SimpleModal.styles';

export interface SimpleModalType {
  children: any;
  isOpen: boolean;
  label: string;
  overflow?: string;
  style?: object;
}

export const SimpleModal = ({
  children,
  isOpen,
  label,
  overflow,
  style,
}: SimpleModalType) => (
  <Wrapper>
    <ReactModal
      isOpen={isOpen}
      contentLabel={label}
      ariaHideApp={false}
      style={{
        content: overflow
          ? { ...content, ...style, overflow }
          : { ...content, ...style },
      }}
    >
      {children}
    </ReactModal>
  </Wrapper>
);
