import React from 'react';
import { FontSizeType } from 'common/common.fonts';
import { Text, Wrapper } from './Hexagon.styles';

interface HexagonType {
  color: string;
  filled?: boolean;
  size?: number;
  strokeColor?: string;
  strokeWidth?: number;
  text?: string;
  textColor?: string;
  textSize?: FontSizeType;
  useSpan?: boolean;
}

const hexagonPoints =
  'M19.9359429,0.0548380952 L-5.71428575e-05,11.5649333 L-5.71428575e-05,34.5851238' +
  ' L19.9359429,46.095219 L39.8721333,34.5851238 L39.8721333,11.5649333 L19.9359429,0.0548380952 ';

const Hexagon = ({
  color,
  filled,
  size,
  strokeColor,
  strokeWidth,
  text,
  textColor,
  textSize,
  useSpan,
}: HexagonType) => (
  <Wrapper>
    <svg
      viewBox={`0 0 40 47`}
      xmlns="http://www.w3.org/2000/svg"
      width={size || 100}
      height={size || 100}
    >
      <g
        stroke={strokeColor || (filled ? 'none' : color)}
        strokeWidth={strokeWidth || (filled ? 0 : 2)}
        fill={filled ? color : 'none'}
      >
        <g>
          <path d={hexagonPoints} />
        </g>
      </g>

      {!useSpan && (
        <Text
          x="50%"
          y="50%"
          dy="0.30em"
          size={textSize}
          color={textColor || color}
        >
          {text}
        </Text>
      )}
    </svg>

    {useSpan && <span>{text}</span>}
  </Wrapper>
);

Hexagon.defaultProps = {
  filled: true,
};

export default Hexagon;
