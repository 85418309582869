/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionComment,
    QuestionCommentFromJSON,
    QuestionCommentFromJSONTyped,
    QuestionCommentToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionCommentResponse
 */
export interface QuestionCommentResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof QuestionCommentResponse
     */
    msg: string;
    /**
     * 
     * @type {QuestionComment}
     * @memberof QuestionCommentResponse
     */
    entry: QuestionComment;
}

export function QuestionCommentResponseFromJSON(json: any): QuestionCommentResponse {
    return QuestionCommentResponseFromJSONTyped(json, false);
}

export function QuestionCommentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionCommentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': QuestionCommentFromJSON(json['entry']),
    };
}

export function QuestionCommentResponseToJSON(value?: QuestionCommentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': QuestionCommentToJSON(value.entry),
    };
}


