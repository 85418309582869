import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-basis: 49%;
  margin-bottom: 2%;
  padding: 30px 45px;
  background-color: white;
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  position: relative;

  @media print {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.atlas.grey['300']};
  }
`;

export const Title = styled.div`
  line-height: 1.125;
  margin-bottom: 29px;
  ${({ theme }) => theme.fonts.bold.normal}

  > span {
    ${({ theme }) => theme.fonts.regular.normal}
  }
`;

export const Subtitle = styled.div`
  margin-top: 15px;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
`;

export const Header = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  min-height: 40px;
  margin: 0 auto;
`;
