import { ModalsHideActionType } from './actions/hideModal';
import { ModalsShowActionType } from './actions/showModal';
import { append, evolve, without } from 'ramda';
import { ModalsResetActionType } from './actions/resetModals';

export interface ModalsStateType {
  opened: string[];
}

const modalsInitialState: ModalsStateType = {
  opened: [],
};

const modalsReducer = (
  state: ModalsStateType = modalsInitialState,
  action: ModalsShowActionType | ModalsHideActionType | ModalsResetActionType,
): ModalsStateType => {
  switch (action.type) {
    case '@modals/SHOW':
      return evolve({
        opened: (currentlyOpened: string[]) =>
          append(action.payload, currentlyOpened),
      })(state);

    case '@modals/HIDE':
      return evolve({ opened: without([action.payload]) })(state);

    case '@modals/RESET':
      return modalsInitialState;

    default:
      return state;
  }
};

export default modalsReducer;
