import styled, { css } from 'styled-components';

export const ItemWrapper = styled.div`
  margin-bottom: 6px;
  padding-right: 5px;
`;

export const Item = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  border-left: 4px solid #fff;
  box-shadow: 0 2px 5px rgba(37, 38, 94, 0.1);
  border-left-color: ${({ color }: { color?: string }) => color || '#000'};
`;

const cell = css`
  flex: 1;
  padding: 15px 0px 10px 0px;
  line-height: 1.2em;
`;

export const Status = styled.div`
  ${cell};
  flex: 0 0 60px;
  padding-left: 13px;
  color: #fff;
`;

export const StatusIcon = styled.div`
  ${cell};
  height: 24px;
  width: 32px;
  line-height: 24px;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  color: #fff;
  border-radius: 23px;
  padding: 0 7px;
  border: 1px solid #fff;
  display: inline-block;
  text-align: center;
  background-color: ${({ color }: { color?: string }) =>
    color || 'transparent'};
`;

export const Company = styled.div`
  ${cell};
  flex: 0 0 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => theme.fonts.light.mini}
`;

export const Standard = styled.div`
  ${cell};
  ${({ theme }) => theme.fonts.semibold.mini}
`;

export const Grade = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

export const Version = styled.div`
  color: #787993;
  ${({ theme }) => theme.fonts.light.micro}
`;

export const Date = styled.div`
  ${cell};
  flex: 0 0 110px;
  ${({ theme }) => theme.fonts.light.mini}
`;

export const DueDateWrapper = styled.div`
  ${cell};
  flex: 0 0 170px;
  ${({ theme }) => theme.fonts.light.mini}
`;

export const GradeImage = styled.img`
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
`;

export const GradeSpan = styled.span`
  vertical-align: middle;
  ${({ theme }) => theme.fonts.light.mini}
`;
