import React, { createRef } from 'react';
import uuid from 'utils/uuid';
import { withTranslation } from 'react-i18next';
import { PlusIcon } from './Attachments.styles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../common/Button';
import { TranslatePropsType } from 'common/common.types';
import { HiddenInput } from 'common/common.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface AddAttachmentPropsType extends TranslatePropsType {
  createAttachment: (formData: FormData) => void;
  disabled?: boolean;
  handleChange?: (newValue: string) => void;
}

const AddAttachment = ({
  createAttachment,
  disabled,
  handleChange,
  t,
}: AddAttachmentPropsType) => {
  const fileInput = createRef<HTMLInputElement>();

  const handleFiles = (event: any) => {
    const files = Array.from(event.target.files);

    files.forEach((file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('uploadingId', uuid());
      if (handleChange) {
        handleChange(file.name);
      }
      createAttachment(formData);
    });
  };

  const handleClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  return (
    <>
      <HiddenInput>
        <input
          multiple={true}
          name="files"
          onChange={event => handleFiles(event)}
          ref={fileInput}
          type="file"
        />
      </HiddenInput>

      <Button
        onClick={handleClick}
        color="transparent"
        disabled={disabled}
        data-test="attachment-button"
      >
        <PlusIcon icon={faPlus as IconProp} />

        {t('add') as string}
      </Button>
    </>
  );
};

export default withTranslation('Attachments')(AddAttachment);
