import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.basic.colors.light};
  position: absolute;
  top: 67px;
  right: 0;
  border: 1px solid ${({ theme }) => theme.basic.colors.darkerBorder};
  border-top: none;
`;

export const Heading = styled.div`
  background: ${({ theme }) => theme.atlas.teal[300]};
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
  align-items: center;
  width: 480px;
  ${({ theme }) => theme.fonts.regular.small}
`;

export const HeadingIcon = styled.span`
  cursor: pointer;
  padding: 12px;
  line-height: 1;
`;

export const Inner = styled.div`
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  padding: 20px;
  overflow-y: auto;
  max-height: 69vh;
  max-width: 480px;
`;

export const InnerWrapper = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  min-height: 80px;
  justify-content: space-between;
  position: relative;
  padding: 20px;
`;

export const ActionWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const Skip = styled.button`
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.atlas.teal.primary};
  ${({ theme }) => theme.fonts.bold.small}
  border: none;

  &:hover {
    text-decoration: none;
  }
`;

export const Title = styled.span`
  padding: 12px 16px 12px 16px;
  flex: 1;
  ${({ theme }) => theme.fonts.bold.small}
`;

export const Caption = styled.span<{ bottom?: boolean }>`
  ${({ theme }) => theme.fonts.light.normal}
  line-height: 1.1;
  max-width: 60%;
  margin: ${({ bottom }) => (bottom ? '10px 0 0' : '0 20px 0 0')};
`;
