import { ApiCallActionType } from '../../store.types';
import { CompaniesStateType } from '../companies.reducer';
import { CompanySettingType } from '../../../common/common.types';

export type CompaniesGetSettingsActionType = ApiCallActionType<
  '@companies/API/GET_SETTINGS',
  never,
  CompanySettingType,
  never,
  CompaniesStateType
>;

export const getSettings = (): CompaniesGetSettingsActionType => ({
  type: '@companies/API/GET_SETTINGS',
  payload: {
    endpointPath: ['companies', 'settings', 'get'],
    selector: data => ({ settings: data }),
  },
});
