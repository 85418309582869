import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import atlas from '../../common/themes/atlas-palette';

const offset = 80 + 111; // page header + page footer

export const Wrapper = styled.div`
  text-align: center;
  padding-top: 150px;
  height: calc(100vh - ${offset}px);
  max-width: 700px;
  margin: 0 auto;
`;

export const Heading = styled.h1`
  ${({ theme }) => theme.fonts.light.big}
  margin-bottom: 16px;
`;

export const Caption = styled.h2`
  ${({ theme }) => theme.fonts.light.normal}
  margin-bottom: 16px;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${atlas.teal.primary};
  margin-bottom: 20px;
`;
