import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { TextButton } from 'common/common.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const transitionBase = css`
  transition-duration: 0.3s;
`;

interface FixAttemptPossiblePropsType {
  fixAttempted?: boolean;
  showIcon?: boolean;
}

export const Wrapper = styled.div<FixAttemptPossiblePropsType>`
  ${transitionBase}
  transition-property: background-color;
  display: flex;
  background-color: ${({ fixAttempted, theme }) =>
    fixAttempted
      ? theme.atlas.blue[100]
      : theme.basic.colors.errorBackgroundSolid};
  color: ${({ theme }) => theme.atlas.text.primary};
  ${({ theme }) => theme.fonts.regular.small}
`;

export const IconWrapper = styled.div`
  padding: 12px 16px;
`;

export const ErrorIcon = styled(
  ({ fixAttempted, ...props }: FixAttemptPossiblePropsType) => (
    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} {...props} />
  ),
)`
  ${transitionBase}
  && {
    width: 16px;
  }
  transition-property: color;
  color: ${({ fixAttempted, theme }) =>
    fixAttempted ? theme.atlas.blue[300] : theme.basic.colors.error};
`;

export const MessagesWrapper = styled.div<FixAttemptPossiblePropsType>`
  flex: 1;
  padding: ${({ showIcon }) => (showIcon ? '13px 16px 13px 0' : '13px 16px')};

  & > * + * {
    margin-top: 2px;
  }

  & > ul {
    ${transitionBase}
    transition-property: opacity;
    padding-left: 17px;
    opacity: ${({ fixAttempted }) => (fixAttempted ? 0.6 : 1)};
  }

  & > p {
    display: ${({ fixAttempted }) => (fixAttempted ? 'initial' : 'none')};
  }

  & > p,
  & > ul > li {
    padding: 1px 0;
  }
`;

export const ValidateButton = styled(TextButton)`
  display: inline;
  color: inherit;
  font-size: inherit;
`;
