import i18n from '../../../i18n';
import { RequestSummaryStatusEnum } from '../../../generated/models/RequestSummary';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { RequestsStateType } from '../requests.types';
import { Uuid } from '../../../common/common.types';

const { UNARCHIVED } = RequestSummaryStatusEnum;

export type RequestUnarchiveFunctionType = (id: Uuid) => void;

export type RequestUnarchiveActionType = ApiCallActionType<
  '@requests/API/UNARCHIVE_REQUEST',
  {
    id: Uuid;
    data?: {
      status: RequestSummaryStatusEnum;
    };
  },
  never,
  never,
  RequestsStateType
>;

export const unarchiveRequest = (id: Uuid): RequestUnarchiveActionType => ({
  type: '@requests/API/UNARCHIVE_REQUEST',
  payload: {
    callParams: { id, data: { status: UNARCHIVED } },
    endpointPath: ['requests', 'modify'],
    selector: (data, state): Partial<RequestsStateType> => ({
      ...state.requests,
      archived: {
        ...state.requests.archived,
        data: state.requests.archived.data.filter(r => r.id !== id),
      },
    }),
    postActions: [
      () => call(() => trackEvent('Request', `Change status -> ${UNARCHIVED}`)),
      () => put(flash(i18n.t('common:responses.requestUnarchived'))),
    ],
  },
});
