import styled from 'styled-components';

import Modal from 'utils/Modal/Modal';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  min-height: 200px;
  padding: 25px;
  color: ${({ theme }) => theme.atlas.text.primary};
`;

export const Heading = styled.div`
  ${({ theme }) => theme.basic.fontWeights.bold};
  line-height: 20px;
`;

export const MyModal = styled(Modal)`
  width: 600px;
`;
