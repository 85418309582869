import * as R from 'ramda';
import arrayToIdDict from 'utils/arrayToIdDict';
import { QUESTIONS_LOAD_STEP } from '../../../common/common.constants';
import { UpdateResponseFilling } from '../../../generated/models/UpdateResponseFilling';
import { hydrateFiltersCategories } from './hydrateFiltersCategories';
import { parseResponse } from 'utils/parsers';
import { put } from 'redux-saga/effects';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType, StateType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { UpdateResponseSourceEnum, Uuid } from '../../../common/common.types';

export type FormsPutQuestionFunctionType = (
  formId: Uuid,
  questionId: Uuid,
  responseId: Uuid,
  value: string,
  category: string,
  sourceResponseId?: Uuid,
  sourceQuestionId?: Uuid,
  UpdateResponseSource?: UpdateResponseSourceEnum,
) => void;

export interface FormsPutQuestionsCallParamsType {
  id: Uuid;
  data: {
    category?: string;
    limit?: number;
    offset?: number;
    question_id: Uuid;
    response_id: Uuid;
    value: string;
    update_response_source?: UpdateResponseSourceEnum;
  };
}

export type FormsPutQuestionActionType = OptimisticActionType<
  '@forms/API/PUT_QUESTION',
  ApiCallActionPayloadType<
    FormsPutQuestionsCallParamsType,
    UpdateResponseFilling,
    never,
    FormsStateType
  >
>;

export const putQuestion = (
  formId: Uuid,
  questionId: Uuid,
  responseId: Uuid,
  value: string,
  category: string,
  sourceResponseId?: Uuid,
  sourceQuestionId?: Uuid,
  UpdateResponseSource?: UpdateResponseSourceEnum,
): FormsPutQuestionActionType => ({
  type: '@forms/API/PUT_QUESTION',
  payload: {
    callParams: state => {
      const questionsInCategory = R.values(
        R.pathOr({}, ['forms', 'currentForm', 'questions'], state),
      ).filter(question => question.category === category);

      const offset = R.findIndex(R.propEq('id', questionId))(
        questionsInCategory,
      );

      return {
        id: formId,
        data: {
          category,
          limit: QUESTIONS_LOAD_STEP,
          offset,
          question_id: questionId,
          response_id: responseId,
          source_response_id: sourceResponseId,
          source_question_id: sourceQuestionId,
          value,
          update_response_source: UpdateResponseSource,
        },
      };
    },
    endpointPath: ['forms', 'questions', 'answer'],
    expectedStateChange: {
      [`forms.currentForm.questions.${questionId}.responses.${responseId}`]: R.assoc(
        'value',
        value,
      ),
    },
    selector: (data: UpdateResponseFilling, state: StateType) => {
      // TODO we should separate this logic
      const {
        form: {
          question_count: questionCount,
          questions_completely_filled: questionsCompletelyFilledCount,
          questions_filled: questionsFilledCount,
          is_submittable: isSubmittable,
          percentage_filled: percentageFilled,
        },
        response,
        response: { id },
        refresh_questions,
      } = data;

      const {
        categories,
        questionsFilledCount: filledCount,
      } = state.forms.currentForm!;

      const i = R.findIndex(R.propEq('name', category))(categories!);
      const noChange = filledCount === questionsFilledCount;
      const result = filledCount < questionsFilledCount ? 1 : noChange ? 0 : -1;
      const count = categories![i].questionsFilledCount + result;
      const refreshedQuestions = R.values(
        state.forms.currentForm!.questions,
      ).map(question => ({
        ...question,
        isVisible: R.propOr(
          question.isVisible, // default value
          'visible', // property of the refreshed question
          R.find(q => q.question_id === question.id, refresh_questions),
        ),
      }));

      return R.pipe(
        R.assocPath<boolean, FormsStateType>(
          ['currentForm', 'isSubmittable'],
          isSubmittable,
        ),
        R.assocPath(['currentForm', 'percentageFilled'], percentageFilled),
        R.assocPath(
          ['currentForm', 'questions'],
          arrayToIdDict(refreshedQuestions),
        ),
        R.assocPath(
          ['currentForm', 'categories', i, 'questionsFilledCount'],
          count,
        ),
        R.assocPath(['currentForm', 'questionsCount'], questionCount),
        R.assocPath(
          ['currentForm', 'questionsFilledCount'],
          questionsFilledCount,
        ),
        R.assocPath(
          ['currentForm', 'meta', 'questionsLeftCount'],
          questionCount - questionsCompletelyFilledCount,
        ),
        R.assocPath(
          ['currentForm', 'questions', questionId, 'responses', id],
          parseResponse(response),
        ),
      )(state.forms);
    },
    postActions: [() => put(hydrateFiltersCategories(formId))],
  },
});
