import formatRoute, * as routes from '../routes';
import React from 'react';
import UserDropdown from '../../src/components/Layout/UserDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Logo,
  NavIcon,
  SendButton,
  MigrationOptIn,
} from 'components/Layout/NavItem.styles';
import { CurrentUserType, NavigationContextEnum } from 'common/common.types';
import { faComment, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ContextSwitch from 'components/Layout/ContextSwitch';
import { useTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import usersApi from '../../src/api/new/usersApi';
import { put } from 'redux-saga/effects';
import { flash } from 'store/flashes/actions';
import { useLocation } from 'react-router-dom';
interface GetNavItemsParamsType {
  appealCredits: () => void;
  logout: () => void;
  switchContext: (context: NavigationContextEnum) => void;
  user: CurrentUserType;
  setLanguagePreference: (value: { label: string; value: string }) => void;
  getLanguagePreference: {
    label: string;
    value: string;
  };
  isOptInEnabled: boolean;
}

export interface NavItemType {
  Child: React.FunctionComponent;
  href?: string;
  isExternal?: boolean;
  isHidden?: boolean;
  key: string;
  noAuth?: boolean;
  noHover?: boolean;
  noPadding?: boolean;
  to?: string;
}

const getNavItems = ({
  appealCredits,
  switchContext,
  user,
  user: { isNormal, isGuest, navigationContext },
  logout,
  getLanguagePreference,
  setLanguagePreference,
  isOptInEnabled,
}: GetNavItemsParamsType): {
  logo: NavItemType;
  left: NavItemType[];
  right: NavItemType[];
} => ({
  logo: {
    key: 'home',
    to: routes.HOMEPAGE,
    Child: () => <Logo />,
    noAuth: true,
    noHover: true,
  },
  left: [
    {
      isHidden: !isNormal,
      key: 'contextSwitch',
      Child: () => (
        <ContextSwitch
          context={navigationContext}
          switchContext={switchContext}
        />
      ),
      noHover: true,
    },
    {
      isHidden: navigationContext !== NavigationContextEnum.ANSWER && !isGuest,
      key: 'receivedRequests',
      to: routes.REQUESTS_RECEIVED,
      Child: () => {
        const { t } = useTranslation();
        return (
          <span data-test="navItem">
            {t('navigation:receivedRequests') as string}
          </span>
        );
      },
    },
    {
      isHidden: navigationContext !== NavigationContextEnum.SEND && !isGuest,
      key: 'sentRequests',
      to: formatRoute(routes.REQUESTS_SENT, {}),
      Child: () => {
        const { t } = useTranslation();
        return (
          <span data-test="navItem">
            {t('navigation:sentRequests') as string}
          </span>
        );
      },
    },
    {
      isHidden: navigationContext !== NavigationContextEnum.SEND || !isNormal,
      key: 'formDefinitions',
      to: routes.FORM_DEFINITIONS,
      Child: () => {
        const { t } = useTranslation();
        return (
          <span data-test="navItem">
            {t('navigation:formDefinitions') as string}
          </span>
        );
      },
    },
    {
      isHidden: navigationContext !== NavigationContextEnum.SEND || !isNormal,
      key: 'insights',
      to: routes.INSIGHTS,
      Child: () => {
        const { t } = useTranslation();
        return (
          <span data-test="navItem">{t('navigation:insights') as string}</span>
        );
      },
    },
    {
      isHidden: navigationContext !== NavigationContextEnum.ANSWER || !isNormal,
      key: 'evidenceLocker',
      to: formatRoute(routes.FRAMEWORKS_DASHBOARD, {}),
      Child: () => {
        const { t } = useTranslation();
        return (
          <span data-test="navItem">
            {t('navigation:evidenceLocker') as string}
          </span>
        );
      },
    },
    {
      isHidden: !isNormal,
      key: 'companies',
      to: routes.COMPANIES,
      Child: () => {
        const { t } = useTranslation();
        return (
          <span data-test="navItem">{t('navigation:companies') as string}</span>
        );
      },
    },
    {
      isHidden: navigationContext !== NavigationContextEnum.SEND || !isNormal,
      key: 'sendForm',
      to: routes.SEND_REQUEST,
      Child: () => {
        const { t } = useTranslation();
        return <SendButton>{t('navigation:sendForm') as string}</SendButton>;
      },
      noHover: true,
    },
  ],
  right: [
    {
      key: 'atlasUiMigration',
      isExternal: true,
      isHidden: !isOptInEnabled,
      Child: () => {
        const { t } = useTranslation();
        const location = useLocation();

        // If the user is currently in a form, redirect to the same specific form in the Assessments
        const formId = location.pathname.startsWith('/form/')
          ? location.pathname.substring(6)
          : '';

        return (
          <MigrationOptIn
            onClick={async () => {
              const res = await usersApi.putUserMigrationOptIn();

              const formAddress = formId.length ? `/${formId}/questions` : '';

              if (res.status === 200) {
                window.open(
                  `${routes.ATLAS_UI_MIGRATION}${formAddress}?redirect=atlas`,
                );
              } else {
                put(
                  flash(
                    'We were not able to redirect you to the new design. Try again later.',
                    'error',
                  ),
                );
              }
            }}
          >
            {t('FormDefinitions:tryNewAssessments') as string}
          </MigrationOptIn>
        );
      },
      noAuth: true,
      noHover: true,
    },
    {
      key: 'sscLink',
      href: routes.SSC_HOME,
      isExternal: true,
      Child: () => {
        const { t } = useTranslation();
        return <span>{t('navigation:sscLink') as string}</span>;
      },
      noAuth: true,
      noHover: true,
    },
    // ATL-1855
    // Disabling feedback link since it is no longer supported.
    // Keeping reference in case we get a new link
    // {
    //   key: 'feedback',
    //   href: routes.FEEDBACK,
    //   isExternal: true,
    //   Child: () => (
    //     <NavIcon data-test="feedbackIcon">
    //       <FontAwesomeIcon icon={faComment as IconProp} />
    //     </NavIcon>
    //   ),
    //   noAuth: true,
    //   noHover: true,
    //   noPadding: true,
    // },
    {
      key: 'faq',
      to: routes.FAQ,
      Child: () => (
        <NavIcon data-test="faqIcon">
          <FontAwesomeIcon icon={faQuestionCircle as IconProp} />
        </NavIcon>
      ),
      noHover: true,
      noPadding: true,
    },
    {
      key: 'userMenu',
      Child: () => (
        <UserDropdown
          logout={logout}
          user={user}
          appealCredits={appealCredits}
          setLanguagePreference={setLanguagePreference}
          getLanguagePreference={getLanguagePreference}
          language={getLanguagePreference}
        />
      ),
      noAuth: true,
      noHover: true,
    },
  ],
});

export default getNavItems;
