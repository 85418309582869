import styled from 'styled-components/macro';

import {
  RowWrapper,
  StandardTitle,
  StandardTitleText as ImportedStandardTitleText,
  StandardTitleTextProps,
  DateModified,
  Cell,
  VerticallyCentered,
  STANDARD_HEIGHT,
  SystemStandardVersionAndYear,
} from '../FormDefinitions/FormDefinitionsRow.styles';

export {
  RowWrapper,
  StandardTitle,
  DateModified,
  Cell,
  VerticallyCentered,
  STANDARD_HEIGHT,
  SystemStandardVersionAndYear,
};

export const StandardTitleText = styled(ImportedStandardTitleText).attrs(
  (): StandardTitleTextProps => ({
    isEditing: false,
    isPreviewable: true,
  }),
)<StandardTitleTextProps>``;
