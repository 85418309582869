import React from 'react';
import { TranslatePropsType, Uuid } from 'common/common.types';
import Modal from 'utils/Modal';
import { Trans, withTranslation } from 'react-i18next';
import { RefreshLink, Heading, Wrapper, Message } from './ConflictModal.styles';
import img from './conflict-image.svg';

interface ConflictModalPropsType extends TranslatePropsType {
  modalId: string;
  draftId: Uuid;
  onRefreshClick: () => void;
}

const ConflictModal = ({
  modalId,
  onRefreshClick,
  t,
}: ConflictModalPropsType) => {
  const modalProps = {
    modalId: modalId,
    closeWithButtonsOnly: true,
  };

  return (
    <Modal {...modalProps}>
      <Wrapper>
        <img src={img} alt={t('conflictModal.imageAlt')} />
        <Heading>
          {t('conflictModal.formChangedBySomeoneElse') as string}
        </Heading>
        <Message>
          <Trans i18nKey="conflictModal.pleaseRefresh" t={t}>
            Please
            <RefreshLink onClick={onRefreshClick}>refresh the page</RefreshLink>
            to display the latest version. Your most recent change will be lost.
          </Trans>
        </Message>
      </Wrapper>
    </Modal>
  );
};

export default withTranslation('FormCreator')(ConflictModal);
