import React, { useState, useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Tooltip from 'components/common/Tooltip';

import {
  ActionsMenuWrapper,
  StyledHR,
  StyledOption,
} from './ActionsMenu.styles';
import { TranslatePropsType } from '../../../common/common.types';
import { TFunction } from 'i18next';

export enum Options {
  HORIZONTAL_LINE = 'HORIZONTAL_LINE',
  REAL_OPTION = 'REAL_OPTION',
}

interface HorizontalLine {
  type: Options.HORIZONTAL_LINE;
}

interface RealOption {
  type: Options.REAL_OPTION;
  config: OptionConfiguration;
}

export interface ActionTooltip {
  translationKey: string;
}

export interface OptionConfiguration {
  id: string;
  translationKey: string;
  enabled?: boolean;
  clickHandler?: () => any;
  tooltip?: ActionTooltip;
}

export type OptionConfigType = HorizontalLine | RealOption;

interface RealOptionComponentProps {
  option: RealOption;
  t: TFunction;
}

const RealOptionComponent = ({ option, t }: RealOptionComponentProps) => {
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [mouseOnComponent, setMouseOnComponent] = useState<boolean>(false);

  const { enabled = true, clickHandler, translationKey } = option.config;

  const optionMouseEnter = useCallback(() => {
    setMouseOnComponent(true);
  }, []);

  const optionMouseLeave = useCallback(() => {
    setMouseOnComponent(false);
  }, []);

  useEffect(() => {
    let timeoutId: number | null = null;
    if (mouseOnComponent) {
      /**
       * Need to use timeout here although the tooltip component supports delay via
       * 'mouseEnterDelay' prop, but it doesn't work together with 'visible' prop
       */
      timeoutId = window.setTimeout(() => setTooltipVisible(true), 400);
    } else {
      if (timeoutId !== null) {
        window.clearTimeout(timeoutId);
      }
      setTooltipVisible(false);
    }
    return () => {
      if (timeoutId !== null) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [mouseOnComponent]);

  const SOption = (
    <StyledOption
      enabled={enabled}
      onClick={enabled && clickHandler ? clickHandler : undefined}
      onMouseEnter={optionMouseEnter}
      onMouseLeave={optionMouseLeave}
    >
      {t(`TemplateOptions.${translationKey}`) as string}
    </StyledOption>
  );
  return option.config.tooltip ? (
    <Tooltip
      overlay={
        t(
          `TemplateOptions.tooltip.${option.config.tooltip.translationKey}`,
        ) as string
      }
      visible={tooltipVisible}
    >
      {SOption}
    </Tooltip>
  ) : (
    SOption
  );
};

interface ActionsMenuProps extends TranslatePropsType {
  options: OptionConfigType[];
}

const ActionsMenu = ({ options, t }: ActionsMenuProps) => {
  const optionsJSX = options.map((option, index) => {
    switch (option.type) {
      case Options.HORIZONTAL_LINE:
        return <StyledHR key={index} />;
      case Options.REAL_OPTION:
        return (
          <React.Fragment key={index}>
            <RealOptionComponent option={option} t={t} />
          </React.Fragment>
        );
      default:
        throw new Error('Invalid option type');
    }
  });

  return <ActionsMenuWrapper>{optionsJSX}</ActionsMenuWrapper>;
};

export default withTranslation()(ActionsMenu);
