import React from 'react';
import { Content } from './PdfReport.styles';

interface DocumentPropsType {
  children: React.ReactNode;
  header?: React.ReactElement<any>;
  footer?: React.ReactElement<any>;
}

/**
 * There is a bug in Chrome which allows a fixed header to overlap the content on consecutive pages.
 * It can be solved using table and filling a space in table header with same height as header has.
 */
const Document = ({ children, footer, header }: DocumentPropsType) => (
  <Content>
    {header && (
      <thead>
        <tr>
          <td>{header}</td>
        </tr>
      </thead>
    )}
    <tbody>
      <tr>
        <td>{children}</td>
      </tr>
    </tbody>
    {footer && (
      <tfoot>
        <tr>
          <td>{footer}</td>
        </tr>
      </tfoot>
    )}
  </Content>
);

export default Document;
