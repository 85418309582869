/*eslint no-unexpected-multiline: "off"*/
import styled from 'styled-components';

export const autoExpand = (field: HTMLTextAreaElement) => {
  field.style.height = 'inherit';
  field.style.height = `${field.scrollHeight}px`;
};

export const Textarea = styled.textarea<{
  placeholder: string;
  editable: boolean;
}>`
  background-color: transparent;
  border: 1px solid
    ${({ editable, theme }) =>
      editable ? theme.inputs.textarea.colors.border : 'transparent'};
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  font-weight: 400;
  padding: ${({ editable }) => (editable ? '20px' : '0')};
  width: 100%;
  min-height: 100px;
  resize: none;
  font-style: ${({ editable }) => (editable ? 'normal' : 'italic')};
  overflow: hidden;

  &:focus {
    background-color: ${({ theme }) => theme.basic.colors.light};
    border-color: ${({ theme }) => theme.basic.colors.dark};
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.inputs.textarea.colors.placeholder};
  }

  &:hover[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:empty:before {
    content: ${({ placeholder }) => `'${placeholder}'`};
    color: ${({ theme }) => theme.inputs.textarea.colors.placeholder};
  }

  animation: 0.5s ease-in-out 0s 1 animationTrigger;

  @keyframes animationTrigger {
    from {
      left: 0;
    }
    to {
      left: 0;
    }
  }
`;
