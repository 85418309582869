import React from 'react';
import { severityStylesDict } from './Severity.styles';
import { SeverityTypeEnum } from 'common/common.types';

interface SeverityProps {
  color?: string;
  fillColor?: string;
  size?: number;
  type: SeverityTypeEnum;
}

const Severity = ({ color, fillColor, size = 16, type }: SeverityProps) => {
  if (!(type in severityStylesDict)) {
    return null;
  }

  return (
    <svg viewBox="0 0 60 60" width={size} height={size}>
      <g>
        <path
          fill={fillColor || severityStylesDict[type].fillColor}
          d="M30,60C13.372,60,0,46.457,0,30S13.372,0,30,0c16.629,0,30,13.372,30,30C60,46.629,46.629,60,30,60z"
        />
        <path fill={color || '#ffffff'} d={severityStylesDict[type].points} />
      </g>
    </svg>
  );
};

export default Severity;
