import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from './Tooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface ExplanationType {
  children: React.ReactNode;
  onClick?: () => void;
}

const Explanation = ({ children, onClick }: ExplanationType) => {
  const faQuestionCircleProp = faQuestionCircle as IconProp;
  return (
    <Tooltip placement="left" overlay={children}>
      <span onClick={onClick}>
        <FontAwesomeIcon icon={faQuestionCircleProp} />
      </span>
    </Tooltip>
  );
};

export default Explanation;
