import styled from 'styled-components';

export const Label = styled.div`
  font-size: ${({ theme }) => theme.basic.fontSizes.mini};
  line-height: 1.7;
  margin-bottom: 4px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
