import * as R from 'ramda';
import { QuestionMapping } from '../../../generated/models/QuestionMapping';
import { parseQuestion } from 'utils/parsers';
import uuid from 'utils/uuid';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { Uuid } from '../../../common/common.types';

export type FormsGetQuestionMappingsFunctionType = (
  questionId: Uuid,
  formId: Uuid,
) => void;

export interface FormsGetQuestionMappingsCallParamsType {
  questionId: Uuid;
  formId: Uuid;
}

export type FormsGetQuestionMappingsActionType = ApiCallActionType<
  '@forms/API/GET_QUESTION_MAPPINGS',
  {
    questionId: Uuid;
    formId: Uuid;
  },
  QuestionMapping[],
  never,
  FormsStateType
>;

export const getQuestionMappings = (
  questionId: Uuid,
  formId: Uuid,
): FormsGetQuestionMappingsActionType => {
  return {
    type: '@forms/API/GET_QUESTION_MAPPINGS',
    payload: {
      callParams: { questionId, formId },
      endpointPath: ['forms', 'questions', 'mappings'],
      selector: (data, state): FormsStateType =>
        R.assocPath(
          ['currentForm', 'questions', questionId, 'mappings'],
          data.map(({ form: { name }, matches, question, score }) => ({
            id: uuid(),
            name,
            // @ts-ignore
            question: parseQuestion(question, state),
            score,
            matches: matches.map(match => ({
              sourceId: match.source_id,
              targetId: match.target_id,
              matchCode: match.match_code,
              formNames: [
                // @ts-ignore
                ...new Set(match.forms.map(form => form.display_name)),
              ],
              // @ts-ignore
              question: parseQuestion(match.question, state),
              value: match.value,
            })),
          })),
          state.forms,
        ),
    },
  };
};
