/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestsCreate
 */
export interface RequestsCreate {
    /**
     * Due date for filling the form. Omit to send questionnaire without a due date
     * @type {Date}
     * @memberof RequestsCreate
     */
    dueDate?: Date;
    /**
     * Text of the email sent to the vendor
     * @type {string}
     * @memberof RequestsCreate
     */
    emailBody?: string;
    /**
     * Set to true if recepients specified in `targets` should be notified via email. This is
     * usually useful for sending requests to individual users, in case of sending to a
     * company by domain, `company.requests_email` is used.
     * @type {boolean}
     * @memberof RequestsCreate
     */
    notify?: boolean;
    /**
     * How many days before due date should the user be notified
     * @type {number}
     * @memberof RequestsCreate
     */
    reminder?: number;
    /**
     * Source team UUID of the actual sender. This field is optional when a user who
     * is not a part of any team, sends a questionnaire to another user/company.
     * @type {string}
     * @memberof RequestsCreate
     */
    sourceTeam?: string;
    /**
     * List of IDs of standards that should be sent to the vendor for filling
     * @type {Array<string>}
     * @memberof RequestsCreate
     */
    standardIds: Array<string>;
    /**
     * List of recepients of the standards. Recepient can be either a company specified as a
     * domain (e.g. "example.com") or a specific user from the company (e.g.
     * "john.doe@example.com").
     * @type {Array<string>}
     * @memberof RequestsCreate
     */
    targets: Array<string>;
}

export function RequestsCreateFromJSON(json: any): RequestsCreate {
    return RequestsCreateFromJSONTyped(json, false);
}

export function RequestsCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestsCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dueDate': !exists(json, 'due_date') ? undefined : (new Date(json['due_date'])),
        'emailBody': !exists(json, 'email_body') ? undefined : json['email_body'],
        'notify': !exists(json, 'notify') ? undefined : json['notify'],
        'reminder': !exists(json, 'reminder') ? undefined : json['reminder'],
        'sourceTeam': !exists(json, 'source_team') ? undefined : json['source_team'],
        'standardIds': json['standard_ids'],
        'targets': json['targets'],
    };
}

export function RequestsCreateToJSON(value?: RequestsCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'due_date': value.dueDate === undefined ? undefined : (value.dueDate.toISOString()),
        'email_body': value.emailBody,
        'notify': value.notify,
        'reminder': value.reminder,
        'source_team': value.sourceTeam,
        'standard_ids': value.standardIds,
        'targets': value.targets,
    };
}


