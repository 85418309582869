import styled from 'styled-components';
import hex2rgba from 'utils/hex2rgba';
import { ParsingMessageSeverityType } from 'common/common.types';
import { severityStyles } from './ParsingMessage';

export const Wrapper = styled.div<{ severity: ParsingMessageSeverityType }>`
  align-items: center;
  background-color: ${({ severity }) =>
    hex2rgba(severityStyles[severity].color, 0.1)};
  display: flex;
  flex-direction: row;
  padding: 10px 10px 10px 0;
  width: 100%;
  margin: 8px 0;
  border-radius: 6px;
  ${({ theme }) => theme.fonts.regular.small}

  svg {
    color: ${({ severity }) => severityStyles[severity].color};
  }
`;

export const Icon = styled.div`
  flex-basis: 16%;
  text-align: center;
`;

export const Message = styled.div`
  flex-basis: 84%;
  display: flex;
  flex-direction: column;
`;
