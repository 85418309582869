import isNil from 'lodash.isnil';

import { UserInfo } from '../../generated/models/UserInfo';
import { UserStateType } from './user.types';
import { parseUser } from 'utils/parsers';
import { StateType } from '../store.types';
import { NavigationContextEnum, UserType } from '../../common/common.types';
import hasRole from 'utils/hasRole';
import { UserDetailRolesEnum } from '../../generated/models/UserDetail';
import { EMPTY_ID } from '../../common/common.constants';

const defaultNavigationContext = ({
  stats: { requests },
}: UserType): NavigationContextEnum =>
  requests.received > requests.sent
    ? NavigationContextEnum.ANSWER
    : NavigationContextEnum.SEND;

export const answersEvidenceParams = (teamID: string | undefined) =>
  isNil(teamID)
    ? { team_id: teamID }
    : { team_id: teamID, exclude_team_data: false };

export const getInitialState = (
  userData: UserInfo | {},
  state?: StateType,
): UserStateType => {
  const parsedUserData = parseUser(userData);
  const currentUser = {
    ...parsedUserData,
    company:
      // Company teams are downloaded separately so try to preserve them
      // when it makes sense...
      state &&
      state.user.currentUser.company.id === parsedUserData.company.id &&
      state.user.currentUser.company.teams !== null
        ? {
            ...parsedUserData.company,
            teams: state.user.currentUser.company.teams,
          }
        : parsedUserData.company,
    isAuthenticated: parsedUserData.id !== EMPTY_ID,
    isCustomerAdmin: hasRole(parsedUserData, UserDetailRolesEnum.CUSTOMERADMIN),
    isGuest: hasRole(parsedUserData, UserDetailRolesEnum.GUEST),
    isNormal: hasRole(parsedUserData, UserDetailRolesEnum.NORMAL),
    isSales: hasRole(parsedUserData, UserDetailRolesEnum.SALES),
    isSystemAdmin: hasRole(parsedUserData, UserDetailRolesEnum.SYSTEMADMIN),
    navigationContext: state
      ? state.user.currentUser.navigationContext
      : defaultNavigationContext(parsedUserData),
  };

  return {
    ...(state
      ? state.user
      : {
          files: [],
          frameworks: [],
          mailingList: {
            request: [],
            share: [],
          },
        }),
    currentUser,
    myTags: [],
    fileSelected: undefined,
    frameworkSelected: undefined,
    firstContact: false,
  };
};
