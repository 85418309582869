import styled from 'styled-components';
import { FontSizeType } from 'common/common.fonts';

type TextType = {
  color?: string;
  size?: FontSizeType;
};

export const Text = styled.text<TextType>`
  ${({ size, theme }) => theme.fonts.bold[size || 'big']}
  fill: ${({ color, theme }) => color || theme.basic.colors.light};
  text-anchor: middle;
`;

export const Wrapper = styled.div`
  align-items: center;

  svg {
    align-self: center;
  }

  span {
    ${({ theme }) => theme.fonts.bold.tiny}
    color: ${({ theme }) => theme.basic.colors.light};
    width: 100%;
    height: 100%;
    position: absolute;
    top: -8px;
    left: -3px;
    z-index: 5000;
    display: block;
  }
`;
