import React, { memo } from 'react';
import * as routes from '../../routes';
import { Action, Actions, AssetBody, LeftSide, Wrapper } from './Asset.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import {
  faEye,
  faEyeSlash,
  faShareAlt,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../common/Tooltip';
import { connect } from 'react-redux';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface AssetPropsType extends TranslatePropsType {
  body: React.ReactElement<any>;
  handleDelete?: () => void;
  handleHide?: () => void;
  handleShare?: () => void;
  isHidden: boolean;
  left: React.ReactElement<any>;
  showHidden: boolean;
  hasEvidenceLockerEntity?: boolean;
  domain: string | undefined;
}

const Asset = ({
  body,
  handleDelete,
  handleHide,
  handleShare,
  isHidden,
  left,
  showHidden,
  hasEvidenceLockerEntity,
  t,
  domain,
}: AssetPropsType) => {
  const unhideLabel = t('unhide') as string;
  const hideLabel = t('hide') as string;
  const hideOverlay = isHidden ? unhideLabel : hideLabel;

  const faEyeProp = faEye as IconProp;
  const faEyeSlashProp = faEyeSlash as IconProp;
  let hideIcon = faEyeSlashProp;
  if (isHidden) {
    hideIcon = faEyeProp;
  }

  const shareLabel = t('share') as string;

  const faShareAltProp = faShareAlt as IconProp;
  const faTrashProp = faTrash as IconProp;

  return (
    <Wrapper showHidden={showHidden} isHidden={isHidden} data-test="asset">
      <LeftSide>{left}</LeftSide>

      <AssetBody>
        {body}

        <Actions>
          {handleHide && (
            <Tooltip placement="right" overlay={hideOverlay}>
              <Action onClick={handleHide}>
                <FontAwesomeIcon icon={hideIcon} />
              </Action>
            </Tooltip>
          )}

          {handleShare && (
            <Tooltip placement="right" overlay={shareLabel}>
              <Action onClick={handleShare}>
                <FontAwesomeIcon icon={faShareAltProp} />
              </Action>
            </Tooltip>
          )}

          {handleDelete && (
            <Tooltip
              placement="right"
              overlay={
                hasEvidenceLockerEntity
                  ? ReactHtmlParser(
                      t('disabledDelete', {
                        route: domain
                          ? routes.getEvidenceLockerURL(`${domain}`)
                          : routes.SSC_HOME,
                      }),
                    )
                  : (t('delete') as string)
              }
            >
              <Action
                hasEvidenceLockerEntity={hasEvidenceLockerEntity}
                onClick={hasEvidenceLockerEntity ? undefined : handleDelete}
              >
                <FontAwesomeIcon icon={faTrashProp} />
              </Action>
            </Tooltip>
          )}
        </Actions>
      </AssetBody>
    </Wrapper>
  );
};

const mapStateToProps = (state: any) => ({
  domain: state.user.currentUser.company.domain,
});

export default connect(mapStateToProps)(
  withTranslation('EvidenceLocker')(memo(Asset)),
);
