import DropUpload from './DropUpload';
import React from 'react';
import { TranslatePropsType } from 'common/common.types';
import { UploadBox } from './DropUploadWrapper.styles';
import { UploadButton } from '../Button';
import { UploadIcon, Flex } from 'common/common.styles';
import { withTranslation } from 'react-i18next';

interface UploadPropsType extends TranslatePropsType {
  instructions?: any;
  isProcessing?: boolean;
  onDrop: (acceptedFiles: File[]) => void;
}

const DropUploadWrapper = ({
  instructions,
  isProcessing,
  onDrop,
  t,
}: UploadPropsType) => (
  <Flex>
    <DropUpload
      render={isDragActive => (
        <UploadBox hasDrag={isDragActive}>
          <UploadButton processing={isProcessing}>
            <>
              <UploadIcon />

              {t('DropUpload.uploadFiles')}
            </>
          </UploadButton>
        </UploadBox>
      )}
      onDrop={onDrop}
    />

    {instructions}
  </Flex>
);

export default withTranslation('components')(DropUploadWrapper);
