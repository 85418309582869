import React from 'react';
import { ResponseAuditType } from 'common/common.types';
import Audit, { AuditInputPropsType } from './Audit';

const Response = ({
  change: { createdAt, value, user },
  t,
}: AuditInputPropsType) => {
  const { response } = value as ResponseAuditType;
  const responseType = response ? 'new' : 'cleared';

  return (
    <Audit
      title={t(`response.${responseType}`, {
        username: user.displayName,
      })}
      date={createdAt}
    >
      {response}
    </Audit>
  );
};

export default Response;
