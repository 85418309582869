import React from 'react';
import { format } from 'date-fns';
import { Amount, Date, HistoryList, Text } from './History.styles';
import { DATE_FORMAT_WITHOUT_YEAR } from 'common/common.constants';
import { Heading } from '../Upsell.styles';
import { Marginator } from 'common/common.styles';
import { withTranslation } from 'react-i18next';
import {
  CreditsHistoryItemType,
  LanguageType,
  TranslatePropsType,
} from 'common/common.types';
import { StateType } from '../../../store/store.types';
import { getLanguagePreference } from '../../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import getLocale from 'utils/getLocale';

interface HistoryPropsType extends TranslatePropsType {
  history: CreditsHistoryItemType[];
  language: LanguageType;
}

const History = ({ history, language, t }: HistoryPropsType) => {
  return (
    <Marginator margin={{ top: 20 }}>
      <Heading>{t('recentActivity') as string}</Heading>

      {history.map(({ amount, createdAt }, key) => (
        <HistoryList key={key}>
          <Date>
            {format(createdAt, DATE_FORMAT_WITHOUT_YEAR, {
              locale: getLocale(language),
            })}
          </Date>

          <Amount>
            {amount > 0 ? '+' : ''}
            {amount}
          </Amount>

          <Text>{t('credits', { count: amount }) as string}</Text>
        </HistoryList>
      ))}
    </Marginator>
  );
};
const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});
export default connect(
  mapStateToProps,
  null,
)(withTranslation('CreditsManagement')(History));
