import formatRoute, { REQUESTS_SENT } from '../../../routes';
import i18n from '../../../i18n';
import qs from 'qs';
import { REQUEST_LINK_MODAL_ID } from '../../../common/common.constants';
import { RequestDetail } from '../../../generated/models/RequestDetail';
import { RequestsSendFormRedirectEnum } from './sendForm';
import { flash } from '../../flashes/actions';
import { parseRequestDetailList } from 'utils/parsers';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';
import { saveSentRequests } from './saveSentRequests';
import { showModal } from 'utils/Modal';
import unreachableReturn from 'utils/unreachableReturn';
import { startLoading } from '../../app/actions';
import { getInfo } from '../../user/actions';

// TODO: After sendForm action is rewritten to use the new generated API, move
//       this back to the function below
export const SENT_POST_ACTIONS = [
  () => put(flash(i18n.t('common:responses.formSent'))),
  () =>
    put(
      push(
        `${formatRoute(REQUESTS_SENT, {})}?${qs.stringify({
          categories: { created: true },
        })}`,
      ),
    ),
];

export const SCHEDULED_POST_ACTIONS = [
  () => put(flash(i18n.t('common:responses.requestScheduled'))),
  () => put(push(`${formatRoute(REQUESTS_SENT, { tab: 'scheduled' })}`)),
  () => put(startLoading(1)),
  () => put(getInfo()),
];

export const sendRequestPostActions = (
  redirectType?: RequestsSendFormRedirectEnum,
) => {
  switch (redirectType) {
    case RequestsSendFormRedirectEnum.LINK:
      return [
        (payload: RequestDetail[]) =>
          put(saveSentRequests(parseRequestDetailList(payload))),
        () => put(flash(i18n.t('common:responses.createdLink'))),
        () => put(showModal(REQUEST_LINK_MODAL_ID)),
      ];

    case RequestsSendFormRedirectEnum.SCHEDULED:
      return SCHEDULED_POST_ACTIONS;

    case RequestsSendFormRedirectEnum.NONE:
      return [];

    case RequestsSendFormRedirectEnum.SENT:
    case RequestsSendFormRedirectEnum.INTERNAL:
    case undefined:
      return SENT_POST_ACTIONS;

    default:
      return unreachableReturn(redirectType, []);
  }
};
