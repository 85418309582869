import { ProxyShortSummaryFormRoleEnum as FormRoleEnum } from 'generated/models';
import { FormCollaboratorType, Uuid } from 'common/common.types';
import { any, find } from 'ramda';

export enum ContextEnum {
  VRM = 'VRM',
  VENDOR = 'Vendor',
  BOTH = 'VRMVendor',
}

export const getContext = (isVRM: boolean, isVendor: boolean): ContextEnum => {
  if (!isVRM && !isVendor) {
    throw new Error('Neither of isVRM and isVendor are true');
  }

  if (isVRM && isVendor) {
    return ContextEnum.BOTH;
  }

  return isVRM ? ContextEnum.VRM : ContextEnum.VENDOR;
};

export const getProxyRoles = (context: ContextEnum): FormRoleEnum[] => {
  switch (context) {
    case ContextEnum.VRM:
      return [FormRoleEnum.VRMPROXY];
    case ContextEnum.VENDOR:
      return [FormRoleEnum.VENDORPROXY];
    case ContextEnum.BOTH:
      return [FormRoleEnum.VRMPROXY, FormRoleEnum.VENDORPROXY];
    default:
      return [];
  }
};

export const grantAccessOrNotify = (
  rolesToGrant: FormRoleEnum[],
  receiverEmail: string,
  collaborators: FormCollaboratorType[],
): [FormRoleEnum[], Uuid[]] => {
  const receiver = find(
    collaborator => collaborator.user.email === receiverEmail,
    collaborators,
  );

  // if found already granted receiver, check his form roles
  if (receiver) {
    // get proxies that current logged user can notify
    const notifiableProxies = receiver.proxies.filter(
      proxy => proxy.canBeNotified,
    );

    // form roles to create based on current logged user
    // tip: there could be multiple proxy objects for one role that
    // differs only on flags (canRevoke, canNotify). Value of a flag
    // is based on currently logged user.
    const rolesToCreate = rolesToGrant.filter(
      role => !any(proxy => proxy.formRole === role, notifiableProxies),
    );

    return [rolesToCreate, notifiableProxies.map(proxy => proxy.id)];
  }
  // otherwise grant access to the receiver with all form roles
  return [rolesToGrant, []];
};
