import { UserStateType } from './user.types';
import { compose, groupBy, mapObjIndexed, sort, reduce, filter } from 'ramda';
import {
  FRAMEWORK_CATEGORIES_ORDER,
  FrameworkType,
} from '../../common/common.types';
import { createSelector } from 'reselect';

export const selectedFramework = (state: UserStateType) =>
  state.frameworkSelected;

export const selectedFile = (state: UserStateType) => state.fileSelected;

export const selectedTeam = (state: UserStateType) =>
  state.currentUser.selectedTeam;

export const dashboard = (state: UserStateType) => state.frameworks;

export const groupedFrameworks = createSelector(
  ({ frameworks }: UserStateType) => frameworks,
  frameworks => {
    const groupedSorted = compose(
      mapObjIndexed(
        sort(
          (a: FrameworkType, b: FrameworkType) =>
            b.percentageCompleted - a.percentageCompleted,
        ),
      ),
      groupBy((framework: FrameworkType) =>
        framework.standard.status.toLowerCase(),
      ),
    )(filter(f => !!f.standard.status, frameworks));

    return {
      items: groupedSorted,
      count: reduce(
        (acc: number, category: string) =>
          groupedSorted[category] ? groupedSorted[category].length + acc : acc,
        0,
      )(FRAMEWORK_CATEGORIES_ORDER),
    };
  },
);

export const getCompanyId = (state: UserStateType) =>
  state.currentUser.company.id;

export const getTeams = (state: UserStateType) => state.currentUser.teams;

export const getUserFeatureFlags = (state: UserStateType) =>
  state.currentUser.ldToggles;
