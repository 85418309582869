/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface QuestionDefinitionEntityError
 */
export interface QuestionDefinitionEntityError {
    /**
     * 
     * @type {string}
     * @memberof QuestionDefinitionEntityError
     */
    code: QuestionDefinitionEntityErrorCodeEnum;
}




/**
* @export
* @enum {string}
*/
export enum QuestionDefinitionEntityErrorCodeEnum {
    CONDITIONREFERENCEINVALIDQUESTION = 'CONDITION_REFERENCE_INVALID_QUESTION',
    CONDITIONREFERENCELOWER = 'CONDITION_REFERENCE_LOWER',
    CONDITIONREFERENCENOTFOUND = 'CONDITION_REFERENCE_NOT_FOUND',
    CONDITIONREFERENCESELF = 'CONDITION_REFERENCE_SELF',
    CONDITIONSTRUCTUREDWRONGFORMAT = 'CONDITION_STRUCTURED_WRONG_FORMAT',
    CONDITIONSYNTAXERROR = 'CONDITION_SYNTAX_ERROR',
    CONDITIONUUIDNOTFOUND = 'CONDITION_UUID_NOT_FOUND',
    CONDITIONWRONGVALUE = 'CONDITION_WRONG_VALUE',
    DUPLICATEDQUESTIONCODE = 'DUPLICATED_QUESTION_CODE',
    EMPTYQUESTION = 'EMPTY_QUESTION',
    EMPTYQUESTIONCODE = 'EMPTY_QUESTION_CODE',
    NORESPONSES = 'NO_RESPONSES'
}


