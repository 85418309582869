/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionVisibility
 */
export interface QuestionVisibility {
    /**
     * 
     * @type {string}
     * @memberof QuestionVisibility
     */
    questionId: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionVisibility
     */
    visible: boolean;
}

export function QuestionVisibilityFromJSON(json: any): QuestionVisibility {
    return QuestionVisibilityFromJSONTyped(json, false);
}

export function QuestionVisibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionVisibility {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionId': json['question_id'],
        'visible': json['visible'],
    };
}

export function QuestionVisibilityToJSON(value?: QuestionVisibility | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'question_id': value.questionId,
        'visible': value.visible,
    };
}


