import QuestionMarkTooltip from '../../common/QuestionMarkTooltip';
import React from 'react';
import { Column, Wrapper, Label, ToolTipWrapper } from './RequestSorter.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import {
  faSortAmountDown,
  faSortAmountUp,
} from '@fortawesome/free-solid-svg-icons';
import {
  reverseDirection,
  SORT_OPTIONS,
  SortByType,
  SortType,
} from 'utils/filters/requests.sorters';
import { RequestsSetSortFunctionType } from 'store/requests/actions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface RequestSorterPropsType extends TranslatePropsType {
  setSort: RequestsSetSortFunctionType;
  showScore?: boolean;
  sortBy: SortType;
}

const columnWidths = {
  COMPANY_TARGET: 250,
  COMPANY_SOURCE: 250,
  STANDARD: undefined,
  COMPLETION: 100,
  SCORE: 130,
  DUE: 125,
  UPDATED: 125,
};

const RequestSorter = ({
  setSort,
  showScore,
  sortBy,
  sortBy: { direction },
  t,
}: RequestSorterPropsType) => {
  const validOptions = SORT_OPTIONS.filter(
    option =>
      (option !== 'SCORE' || showScore) &&
      option !== 'REQUESTED' &&
      (option !== 'COMPANY_TARGET' || showScore) &&
      (option !== 'COMPANY_SOURCE' || !showScore),
  );

  let directionIcon = faSortAmountDown as IconProp;
  if (direction === 'ASC') {
    directionIcon = faSortAmountUp as IconProp;
  }

  return (
    <Wrapper>
      <Column key="select" width={50} />
      {validOptions.map((option: SortByType) => (
        <Column
          data-test="sorter"
          key={option}
          clickable
          width={columnWidths[option]}
          selected={sortBy.by === option}
          onClick={() =>
            setSort({
              by: option,
              direction:
                sortBy.by === option ? reverseDirection(direction) : 'ASC',
            })
          }
        >
          <Label>{t(`Requests.sortOptions.${option}`) as string}</Label>

          {option === 'UPDATED' && (
            <ToolTipWrapper>
              <QuestionMarkTooltip text={t('Requests.explainUpdated')} />
            </ToolTipWrapper>
          )}

          {sortBy.by === option && (
            <FontAwesomeIcon icon={directionIcon} style={{ marginLeft: 8 }} />
          )}
        </Column>
      ))}
    </Wrapper>
  );
};

export default withTranslation()(RequestSorter);
