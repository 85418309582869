import React, { useContext } from 'react';
import TabView from '../common/Tabs';
import { EvidenceLockerContextType } from 'pages/EvidenceLockerPage';
import { Files } from './Files';
import { Frameworks } from './Frameworks';
import { PageContext } from 'pages/Page';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import Header from './Header';

const EvidenceLocker = ({ t }: TranslatePropsType) => {
  const {
    actions: { selectTeam, switchTab },
    data: { companyName, files, frameworks, selectedTeam, teams, user },
    routeParams: { tab },
  } = useContext(PageContext) as EvidenceLockerContextType;

  return (
    <TabView
      initialState={tab === 'evidence' ? 1 : 0}
      onTabSelect={switchTab}
      tabs={[
        {
          key: 'answers',
          component: <Frameworks />,
          label: t('answers', { count: frameworks.count }),
        },
        {
          key: 'evidence',
          component: <Files />,
          label: t('evidence', { count: files.length }),
        },
      ]}
      heading={
        <Header
          companyName={companyName}
          selectTeam={selectTeam}
          selectedTeam={selectedTeam}
          teams={teams}
          user={user}
        />
      }
    />
  );
};

export default withTranslation('EvidenceLocker')(EvidenceLocker);
