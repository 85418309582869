/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionMappingFeedback
 */
export interface QuestionMappingFeedback {
    /**
     * 
     * @type {string}
     * @memberof QuestionMappingFeedback
     */
    companyId: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionMappingFeedback
     */
    questionMappingPos: number;
    /**
     * Collection of feedback options for question mapping feature.
     * @type {string}
     * @memberof QuestionMappingFeedback
     */
    rating: QuestionMappingFeedbackRatingEnum;
    /**
     * 
     * @type {number}
     * @memberof QuestionMappingFeedback
     */
    responsePos?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionMappingFeedback
     */
    responseValue?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionMappingFeedback
     */
    sourceQuestionId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionMappingFeedback
     */
    targetQuestionId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionMappingFeedback
     */
    userId: string;
}

export function QuestionMappingFeedbackFromJSON(json: any): QuestionMappingFeedback {
    return QuestionMappingFeedbackFromJSONTyped(json, false);
}

export function QuestionMappingFeedbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionMappingFeedback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'questionMappingPos': json['question_mapping_pos'],
        'rating': json['rating'],
        'responsePos': !exists(json, 'response_pos') ? undefined : json['response_pos'],
        'responseValue': !exists(json, 'response_value') ? undefined : json['response_value'],
        'sourceQuestionId': json['source_question_id'],
        'targetQuestionId': json['target_question_id'],
        'userId': json['user_id'],
    };
}

export function QuestionMappingFeedbackToJSON(value?: QuestionMappingFeedback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'question_mapping_pos': value.questionMappingPos,
        'rating': value.rating,
        'response_pos': value.responsePos,
        'response_value': value.responseValue,
        'source_question_id': value.sourceQuestionId,
        'target_question_id': value.targetQuestionId,
        'user_id': value.userId,
    };
}

/**
* @export
* @enum {string}
*/
export enum QuestionMappingFeedbackRatingEnum {
    RESPONSEAPPLIED = 'RESPONSE_APPLIED',
    THUMBSDOWN = 'THUMBS_DOWN',
    THUMBSUP = 'THUMBS_UP'
}


