import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const LinkModalWrapper = styled.div`
  width: 1139px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.basic.colors.light};
`;

export const Header = styled.div`
  height: 81px;
  width: 1139px;
  ${({ theme }) => theme.basic.fontWeights.bold}
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
`;

export const Footer = styled.div`
  height: 81px;
  width: 1139px;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const LinkModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RequestInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RequestTitle = styled.div`
  font-family: ${({ theme }) => theme.basic.fontWeights.bold};
  padding: 4px 0;
  font-size: 16px;
`;

export const RequestsEmail = styled.div`
  font-family: ${({ theme }) => theme.basic.fontWeights.regular};
  font-size: 14px;
`;

export const RequestLink = styled.a`
  text-decoration: none;
  font-family: ${({ theme }) => theme.basic.fontWeights.regular};
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

export const Scroller = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 1139px;
  max-height: 332px;
  padding: 25px;
`;

export const Content = styled.div`
  padding-top: 22px;
`;

export const Request = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 11px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.atlas.grey[200]};

  &:first-child {
    padding: 0px 0px 11px 0px;
  }
  &:last-child {
    padding: 11px 0px 0px 0px;
  }
  &:last-of-type {
    border-bottom: none;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  margin: auto 0;
  ${({ theme }) => theme.basic.fontWeights.bold}
`;

export const FirstContactWrapper = styled.div`
  padding: 25px;
`;

export const LinkWrapper = styled.div`
  & + & {
    margin-top: 10px;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.basic.colors.dark};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const CopyButton = styled.span`
  margin-left: 5px;
  cursor: pointer;
  ${({ theme }) => theme.fonts.regular.small}
`;

const commonActionElementStyle = `
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 25px;
  margin-left: 0px;
  background: #0275d8;
  color: #ffffff;
  font-size: 14px;
  border: 0px;
  border-radius: 3px;
  padding: 11px 10px;
`;

export const LinkModalActionElement = styled.button`
  ${commonActionElementStyle}
  font-family: ${({ theme }) => theme.basic.fontWeights.regular};
`;

export const StyledLink = styled(RouterLink)`
  ${commonActionElementStyle}
  text-decoration: none;
`;

export const DeleteModalWrapper = styled(LinkModalWrapper)`
  width: 600px;
`;

export const DeleteModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 32px;
  position: static;
  width: 600px;
  height: 64px;
  left: 0px;
  top: 246px;
  background: #eeeeee;
  border-radius: 0px 0px 4px 4px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const DeleteModalHeader = styled.h3`
  height: 54px;
  width: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 32px 0px;
`;

export const DeleteModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  position: static;
  width: 600px;
  height: 192px;
  left: 0px;
  top: 54px;
  background: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const DeleteModalButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  position: static;
  width: 75px;
  height: 32px;
  left: 396px;
  top: 16px;
  color: #ffffff;
  background: #0275d8;

  ${({ color }) =>
    color &&
    `
    background: #FFFFFF;
    color: #5D5D5D;
    margin: 0px 16px;
  `}
  border: 1px solid #D1D1D1;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
`;
