import { findIndex, propEq, update } from 'ramda';

import { ApiCallActionPayloadType } from '../../store.types';

import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import {
  Standard,
  StandardStatusEnum,
} from '../../../generated/models/Standard';
import { FormDefinitionsStateType } from '../formDefinitions.types';
import { StandardType, Uuid } from '../../../common/common.types';

export type UnpublishStandardFromFormDefinitionsFunctionType = (
  params: UnpublishStandardFromFormDefinitionsParamsType,
) => void;

export interface UnpublishStandardFromFormDefinitionsParamsType {
  standard_id: Uuid;
}

export type UnpublishStandardFromFormDefinitionsActionType = OptimisticActionType<
  '@formDefinitions/API/UNPUBLISH_STANDARD',
  ApiCallActionPayloadType<
    UnpublishStandardFromFormDefinitionsParamsType,
    Standard,
    never,
    FormDefinitionsStateType
  >
>;

export const unpublishStandardFromFormDefinitions = (
  params: UnpublishStandardFromFormDefinitionsParamsType,
): UnpublishStandardFromFormDefinitionsActionType => ({
  type: '@formDefinitions/API/UNPUBLISH_STANDARD',
  payload: {
    endpointPath: ['standards', 'unpublish'],
    callParams: params,
    expectedStateChange: {
      'formDefinitions.standards.data': (standards: StandardType[]) => {
        const indexOfStandardToUpdate = findIndex(
          propEq('id', params.standard_id),
        )(standards);

        const updatedStandard: StandardType = {
          ...standards[indexOfStandardToUpdate],
          status: StandardStatusEnum.DRAFT,
        };

        return update(indexOfStandardToUpdate, updatedStandard, standards);
      },
    },
    selector: null,
  },
});
