import { contains, evolve } from 'ramda';

import { StandardType } from 'common/common.types';
import { FormDefinitionsStateType } from 'store/formDefinitions/formDefinitions.types';
import { StandardStatusEnum } from 'generated/models/Standard';

export const SORT_OPTIONS: SortByType[] = ['NAME', 'UPDATED', 'STATE'];

export type SortByType = 'NAME' | 'UPDATED' | 'STATE';

export type SortDirectionType = 'ASC' | 'DESC';

export const reverseDirection = (direction: SortDirectionType) =>
  direction === 'ASC' ? 'DESC' : 'ASC';

export interface SortType {
  by: SortByType;
  direction: SortDirectionType;
}

const comparatorFunction = ({ by, direction }: SortType) => (
  a: StandardType,
  b: StandardType,
) => {
  const comparators = {
    NAME_ASC: a.name.localeCompare(b.name),
    NAME_DESC: b.name.localeCompare(a.name),
    UPDATED_ASC: a.updatedAt.getTime() - b.updatedAt.getTime(),
    UPDATED_DESC: b.updatedAt.getTime() - a.updatedAt.getTime(),
    STATE_ASC: convertStatusToState(a.status).localeCompare(
      convertStatusToState(b.status),
    ),
    STATE_DESC: convertStatusToState(b.status).localeCompare(
      convertStatusToState(a.status),
    ),
  };

  return comparators[`${by}_${direction}`] || 0;
};

// TODO: load 'Published' and 'Draft' from i18n translations

const convertStatusToState = (status: StandardStatusEnum) => {
  switch (status) {
    case StandardStatusEnum.SYSTEM:
    case StandardStatusEnum.CUSTOM:
      return 'Published';
    case StandardStatusEnum.DRAFT:
      return 'Draft';
    default:
      throw new Error('Invalid standard status');
  }
};

export const sortStandardsArray = (sortBy: SortType) => (
  standards: StandardType[],
) => standards.sort(comparatorFunction(sortBy));

const ensureCorrectFormat = ({
  by = 'UPDATED',
  direction = 'DESC',
}: SortType): SortType => ({
  by: contains(by, SORT_OPTIONS) ? by : 'UPDATED',
  direction: direction === 'DESC' ? 'DESC' : 'ASC',
});

export const sortStandards = ({
  by = 'UPDATED',
  direction = 'DESC',
}: SortType) => (formDefinitions: FormDefinitionsStateType) => {
  const sortBy = ensureCorrectFormat({ by, direction });

  return evolve(
    {
      standards: {
        filter: {
          sortBy: () => sortBy,
        },
        data: sortStandardsArray(sortBy),
      },
    },
    formDefinitions,
  );
};

export const defaultSortBy: SortType = {
  by: 'UPDATED',
  direction: 'DESC',
};
