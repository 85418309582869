export interface ErrorParamsType {
  domain: string;
  data: any;
  meta?: object;
  selector?: (data: any, state?: any, meta?: object) => object | null;
}

export interface ErrorActionType {
  type: '@app/ERROR';
  payload: ErrorParamsType;
  transactionId?: string;
}

export const error = (
  params: ErrorParamsType,
  transactionId?: string,
): ErrorActionType => ({
  type: '@app/ERROR',
  payload: params,
  transactionId,
});
