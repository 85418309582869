import { NotificationEnum, Uuid } from '../../common/common.types';
import { SettingsActionType } from './actions';

export type SupportedSettingsOptionType =
  | 'hiddenFiles'
  | 'hiddenFrameworks'
  | 'showHidden'
  | 'hasAgreedCookies'
  | 'dismissedNotifications'
  | 'showCoBranding'
  | 'languagePreference';

export type SettingsStateBaseType = {
  [key in SupportedSettingsOptionType]?: any;
};

export type DismissedNotifications = {
  [value in NotificationEnum]?: number | null;
};

export interface SettingsStateType extends SettingsStateBaseType {
  dismissedNotifications?: DismissedNotifications;
  hiddenFrameworks?: Uuid[];
  hiddenFiles?: Uuid[];
  showHidden?: boolean;
  hasAgreedCookies?: boolean;
  showCoBranding?: boolean;
  languagePreference?: {
    value: string;
    label: string;
  };
}

export const initialState: SettingsStateType = {
  dismissedNotifications: {
    [NotificationEnum.ALMOST_OUT]: null,
    [NotificationEnum.GRANTED]: null,
    [NotificationEnum.NO_MORE_CREDITS]: null,
    [NotificationEnum.WELCOME]: null,
    [NotificationEnum.WILL_EXPIRE]: null,
  },
  hiddenFiles: [],
  hiddenFrameworks: [],
  showHidden: false,
  hasAgreedCookies: false,
  showCoBranding: true, // Always show the co-branding unless it is discarded by the user.
  languagePreference: { value: 'en-US', label: 'English' },
};

const settings = (
  state = initialState,
  action: SettingsActionType,
): SettingsStateType => {
  switch (action.type) {
    case '@settings/STORE':
      return {
        ...initialState,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default settings;
