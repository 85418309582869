import Header from './Header';
import React, { useState } from 'react';
import Requests from './Requests';
import ScheduledRequests from './ScheduledRequests';
import TabView from '../common/Tabs';
import { PageContextConsumer } from 'pages/Page';
import { PageHeader } from '../common/PageHeader';
import { ReceivedContainer } from './Requests.styles';
import { RequestsPageContextType } from 'pages/RequestsPage';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps, match as MatchType } from 'react-router';

interface RequestsContextWrapper
  extends TranslatePropsType,
    RouteComponentProps {
  match: MatchType & {
    params: {
      tab: string;
    };
  };
}

const RequestsContextWrapper = ({ match, t }: RequestsContextWrapper) => {
  const initialTab = match.params.tab;
  const [currentTab, setCurrentTab] = useState(initialTab);

  return (
    <PageContextConsumer>
      {({
        actions: {
          setFilter,
          setSort,
          switchRequestsView: switchView,
          switchTab,
        },
        data: {
          direction,
          requests,
          requests: { filter, filterOptions },
          scheduled,
          user,
          user: {
            isNormal,
            stats: { archivedRequests },
          },
          language,
        },
        routeParams: { tab },
      }: RequestsPageContextType) => {
        const HeaderComponent = (
          <Header
            direction={direction}
            filter={filter}
            filterOptions={filterOptions}
            hasArchivedRequests={archivedRequests[direction] > 0}
            isSent={direction === 'sent'}
            setFilter={setFilter}
            setSort={setSort}
            showFilter={currentTab !== 'scheduled'}
            switchView={switchView}
            user={user}
            language={language}
          />
        );

        const handleTabSelect = (selectedTab: string) => {
          setCurrentTab(selectedTab);
          switchTab(selectedTab);
        };

        if (isNormal && direction === 'sent') {
          return (
            <TabView
              heading={HeaderComponent}
              initialState={tab === 'scheduled' ? 1 : 0}
              onTabSelect={handleTabSelect}
              tabs={[
                {
                  key: 'sent',
                  label: t('Requests.subHeadings.sent', {
                    count: requests.count,
                  }),
                  component: <Requests />,
                },
                {
                  key: 'scheduled',
                  label: t('Requests.subHeadings.scheduled', {
                    count: scheduled.count,
                  }),
                  component: <ScheduledRequests />,
                },
              ]}
            />
          );
        }

        return (
          <>
            <PageHeader heading={HeaderComponent} />

            <ReceivedContainer>
              <Requests />
            </ReceivedContainer>
          </>
        );
      }}
    </PageContextConsumer>
  );
};

export default withTranslation()(withRouter(RequestsContextWrapper));
