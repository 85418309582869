import React from 'react';
import { Wrapper } from './Autocomplete.styles';
import { Trans, withTranslation } from 'react-i18next';
import Spinner from './Spinner';
import { TranslatePropsType } from 'common/common.types';

const GlobalMappingInProgress = ({ t }: TranslatePropsType) => (
  <Wrapper>
    <Spinner
      text={
        <Trans i18nKey="globalMappingInProgress" t={t}>
          We are searching for matching answers for this questionnaire. This
          might take 5-10 minutes. <br /> You can close this tab and return
          later.
        </Trans>
      }
    />
  </Wrapper>
);

export default withTranslation('Autocomplete')(GlobalMappingInProgress);
