import BundleBox from './Bundlebox';
import CheckoutInput from './CheckoutInput';
import React, { memo, useState } from 'react';
import { Button } from '../../SendRequest/Submit.styles';
import { CREDITS_FULL_PRICE } from 'common/common.constants';
import { Closer } from '../../EvidenceLocker/ShareModal.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SimpleModal } from '../../common/SimpleModal';
import { PricingType, TranslatePropsType, UserType } from 'common/common.types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  ButtonContainer,
  CancelButton,
  CreditsDisclaimer,
  ModalAction,
  ModalFrame,
  Representative,
  Shelf,
  Wrapper,
} from './CreditsSelfCheckout.styles';
import { StripeCreateSessionFunctionType } from 'store/stripe/actions';
import getAppealUrl from 'utils/getAppealUrl';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CreditsSelfCheckoutType extends TranslatePropsType {
  appealCredits: () => void;
  closeModal: () => void;
  currentQuantity?: number;
  isCreditsModalOpened: boolean;
  onCreditsCheckout: StripeCreateSessionFunctionType;
  pricings: PricingType[];
  user: UserType;
}

const CreditsSelfCheckout = ({
  appealCredits,
  closeModal,
  currentQuantity,
  isCreditsModalOpened,
  onCreditsCheckout,
  pricings,
  user,
  t,
}: CreditsSelfCheckoutType) => {
  const [quantity, setQuantity] = useState(currentQuantity || 1);

  const currentPricingInfo =
    pricings &&
    pricings.reduce(
      (p, c) => (c.minQuantity <= (quantity || 1) ? c : p),
      pricings[0],
    );

  const handleQuantity = (minQuantity: number) =>
    setQuantity(minQuantity > 500 ? 500 : minQuantity);

  return (
    <SimpleModal
      label={t('checkout') as string}
      isOpen={isCreditsModalOpened}
      style={ModalFrame}
    >
      <Wrapper data-cy="credits-modal">
        <h3>{t('heading') as string}</h3>

        {t('caption') as string}

        <Representative
          href={getAppealUrl(user)}
          rel="noreferrer nofollow"
          target="_blank"
        >
          {t('captionLink') as string}
        </Representative>

        {pricings ? (
          <>
            <Shelf>
              {pricings.map(({ price, discount, label, minQuantity }) => (
                <BundleBox
                  discount={discount}
                  isCurrentPrice={currentPricingInfo.price === price}
                  key={price}
                  label={label}
                  minQuantity={minQuantity}
                  onQuantityRefresh={handleQuantity}
                  price={price}
                />
              ))}
            </Shelf>

            <CheckoutInput
              currentPricingInfo={currentPricingInfo}
              fullPrice={CREDITS_FULL_PRICE}
              onQuantityRefresh={handleQuantity}
              quantity={quantity}
            />
          </>
        ) : (
          <Shelf>{t('loading') as string}</Shelf>
        )}

        <ModalAction>
          <CreditsDisclaimer>
            {t('atlasCreditsDisclaimer') as string}
          </CreditsDisclaimer>

          <ButtonContainer>
            <CancelButton onClick={closeModal}>
              {t('cancel') as string}
            </CancelButton>

            <Button
              color="teal"
              data-cy="cc-submit"
              onClick={() =>
                onCreditsCheckout(currentPricingInfo.price, quantity)
              }
              size="huge"
            >
              {t('checkout') as string}
            </Button>
          </ButtonContainer>
        </ModalAction>
      </Wrapper>

      <Closer onClick={closeModal}>
        <FontAwesomeIcon icon={faTimes as IconProp} />
      </Closer>
    </SimpleModal>
  );
};

export default memo(
  withTranslation('CreditsSelfCheckout')(CreditsSelfCheckout),
);
