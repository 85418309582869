import React from 'react';
import Page, {
  PageActionsBaseType,
  PageBaseType,
  PagePropsType,
  UpdateQueryType,
} from './Page';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import { FormDefinitions } from 'components/FormDefinitions';

import {
  FormType,
  LanguageType,
  StandardStatusType,
  UserType,
  Uuid,
} from 'common/common.types';

import { SortType } from '../utils/filters/formDefinitions.sorters';

import { startLoading } from 'store/app/actions';
import { resetModals } from '../utils/Modal/store/actions/resetModals';

import { hideModal, showModal } from '../utils/Modal';

import {
  FormGetQuestionsParamsType,
  FormsLoadQuestionsFunctionType,
  FormsPutQuestionFunctionType,
  getPreview as getPreviewForm,
  loadQuestions,
  putQuestion,
  resetForm as resetPreviewForm,
} from 'store/forms/actions';

import {
  toggleStandardSelection,
  ToggleStandardSelectionFunctionType,
  ToggleStandardSelectionParamsType,
} from 'store/standard/actions';

import {
  createEmptyForm,
  CreateEmptyFormFunctionType,
  getPreview,
  GetPreviewFunctionType,
  GetPreviewParamsType,
} from 'store/formCreator/actions';

import { clearSendRequestError } from 'store/requests/actions';

import * as fda from 'store/formDefinitions/actions';
import { FormDefinitionsStateType } from 'store/formDefinitions/formDefinitions.types';

import { StandardStatusEnum } from 'generated/models/Standard';

export interface FormDefinitionsContextActionsType {
  setSort: fda.FormDefinitionsSetSortFunctionType;
  setFilterByStatus: fda.FormDefinitionsFilterByStatusFunctionType;
  showModal: (modalId: string) => void;
  hideModal: (modalId: string) => void;
  getPreview: GetPreviewFunctionType;
  getPreviewForm: (id: Uuid) => void;
  loadQuestions: FormsLoadQuestionsFunctionType;
  putQuestion: FormsPutQuestionFunctionType;
  resetPreviewForm: () => void;
  toggleStandardAndContinue: ToggleStandardSelectionFunctionType;
  createEmptyForm: CreateEmptyFormFunctionType;
  deleteFromFormDefinitions: (
    status: StandardStatusEnum,
    params: fda.DeleteStandardFromFormDefinitionsParamsType &
      fda.DeleteDraftFromFormDefinitionsParamsType,
  ) => void;
  renameStandardFromFormDefinitions: fda.RenameStandardFromFormDefinitionsFunctionType;
  duplicateStandardFromFormDefinitions: fda.DuplicateStandardFromFormDefinitionsFunctionType;
  publishDraftFromFormDefinitions: fda.PublishDraftFromFormDefinitionsFunctionType;
  unpublishStandardFromFormDefinitions: fda.UnpublishStandardFromFormDefinitionsFunctionType;
  toggleFormDefinitionSelection: fda.ToggleSelectionsFormDefinitionFunctionType;
}

export interface FormDefinitionsPageContextType extends PageBaseType {
  data: {
    formDefinitions: FormDefinitionsStateType;
    previewForm: FormType;
    user: UserType;
    language: LanguageType;
  };
  actions: PageActionsBaseType & FormDefinitionsContextActionsType;
}

const FormDefinitionsPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      formDefinitions: state.formDefinitions,
      previewForm: state.forms.currentForm,
      user: state.user.currentUser,
      language: state.settings.languagePreference,
    })}
    dispatches={(
      dispatch: Dispatch<ActionType>,
      ownProps: PagePropsType,
      updateQuery: UpdateQueryType,
    ) => ({
      getData: ({
        queryParams: { sortBy, byStatus },
      }: {
        queryParams: {
          sortBy?: SortType;
          byStatus: StandardStatusType[];
        };
      }) => {
        dispatch(startLoading(1));
        dispatch(
          fda.getStandardsForFormDefinitions({
            status: byStatus,
          }),
        );

        if (sortBy) {
          dispatch(fda.setSort(sortBy));
        }
        if (byStatus) {
          dispatch(fda.setFilterByStatus(byStatus));
        }
      },
      reset: () => {
        dispatch(fda.resetStandardsForFormDefinitions());
        dispatch(resetModals());
      },
      actions: {
        navigate: ownProps.history.push,
        setSort: (sortBy: SortType) => {
          updateQuery({ sortBy });
          dispatch(fda.setSort(sortBy));
        },
        setFilterByStatus: (query: StandardStatusType[] | []) => {
          if (query.length > 0) {
            updateQuery({ byStatus: `${query}`.toLowerCase() });
            dispatch(fda.setFilterByStatus(query));
            dispatch(
              fda.getStandardsForFormDefinitions({
                status: query,
              }),
            );
          } else {
            updateQuery({ byStatus: undefined });
            dispatch(fda.setFilterByStatus([]));
            dispatch(fda.getStandardsForFormDefinitions());
          }
        },
        showModal: (modalId: string) => dispatch(showModal(modalId)),
        hideModal: (modalId: string) => dispatch(hideModal(modalId)),
        getPreview: (params: GetPreviewParamsType) =>
          dispatch(getPreview(params)),
        getPreviewForm: (id: Uuid) => dispatch(getPreviewForm(id)),
        loadQuestions: (params: FormGetQuestionsParamsType) =>
          dispatch(loadQuestions(params)),
        putQuestion: (
          formId: Uuid,
          questionId: Uuid,
          responseId: Uuid,
          value: string,
          category: string,
        ) =>
          dispatch(
            putQuestion(formId, questionId, responseId, value, category),
          ),
        toggleStandardAndContinue: (
          params: ToggleStandardSelectionParamsType,
        ) => {
          dispatch(toggleStandardSelection(params));
          // TODO: check if clearSendRequestError is necessary, copied from
          // SendReuqestPage where it is used also in toggleStandardSelection
          dispatch(clearSendRequestError());
        },
        createEmptyForm: (standardName?: string) =>
          dispatch(createEmptyForm(standardName)),
        deleteFromFormDefinitions: (
          status: StandardStatusEnum,
          params:
            | fda.DeleteStandardFromFormDefinitionsParamsType
            | fda.DeleteDraftFromFormDefinitionsParamsType,
        ) => {
          if (status === StandardStatusEnum.DRAFT) {
            dispatch(fda.deleteDraftFromFormDefinitions(params));
          } else if (status === StandardStatusEnum.CUSTOM) {
            dispatch(fda.deleteStandardFromFormDefinitions(params));
          }
        },
        renameStandardFromFormDefinitions: (
          params: fda.RenameStandardFromFormDefinitionsParamsType,
        ) => dispatch(fda.renameStandardFromFormDefinitions(params)),
        resetPreviewForm: () => dispatch(resetPreviewForm()),
        duplicateStandardFromFormDefinitions: (
          params: fda.DuplicateStandardFromFormDefinitionsParamsType,
        ) => dispatch(fda.duplicateStandardFromFormDefinitions(params)),
        publishDraftFromFormDefinitions: (
          params: fda.PublishDraftFromFormDefinitionsCallParamsType,
        ) => dispatch(fda.publishDraftFromFormDefinitions(params)),
        unpublishStandardFromFormDefinitions: (
          params: fda.UnpublishStandardFromFormDefinitionsParamsType,
        ) => dispatch(fda.unpublishStandardFromFormDefinitions(params)),
        toggleFormDefinitionSelection: (formDefinitionIds: Uuid[]) => {
          dispatch(fda.toggleFormDefinitionSelection(formDefinitionIds));
        },
      },
    })}
  >
    <FormDefinitions />
  </Page>
);

export default FormDefinitionsPage;
