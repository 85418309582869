import Kanban from './Kanban';
import List from './List';
import React from 'react';
import { PageContextConsumer } from 'pages/Page';
import { RequestsPageContextType } from 'pages/RequestsPage';

const Requests = () => (
  <PageContextConsumer>
    {({
      actions: {
        archiveRequest,
        clearNotifications,
        copyToClipboard,
        deleteRequest,
        markRead,
        setSort,
        showProxyModal,
        toggleCategories,
        toggleSelection,
      },
      data: {
        controlsStatus,
        direction,
        requests,
        requests: {
          filter: { sortBy, view },
        },
        user,
        language,
      },
    }: RequestsPageContextType) =>
      view === 'List' ? (
        <List
          actions={{
            archiveRequest,
            clearNotifications,
            copyToClipboard,
            deleteRequest,
            markRead,
            setSort,
            showProxyModal,
            toggleCategories,
            toggleSelection,
          }}
          data={{
            controlsStatus,
            direction,
            requests,
            sortBy,
            user,
            language,
          }}
        />
      ) : (
        <Kanban
          data={{
            direction,
            requests,
            sortBy,
            language,
          }}
        />
      )
    }
  </PageContextConsumer>
);

export default Requests;
