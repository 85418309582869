import Archive from '../../src/components/Requests/Archive';
import Page, {
  PageActionsBaseType,
  PageBaseType,
  PagePropsType,
  UpdateQueryType,
} from './Page';
import React from 'react';
import i18n from '../i18n';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import { LanguageType, UserType, Uuid } from 'common/common.types';
import { ArchivedRequestsSimpleType } from 'store/requests/requests.types';
import { copyTextToClipboard } from '../utils/copyTextToClipboard';
import { flash } from 'store/flashes/actions';
import { startLoading } from 'store/app/actions';
import {
  getArchivedRequests,
  RequestUnarchiveFunctionType,
  resetRequests,
  unarchiveRequest,
  setSortArchived,
  ArchivedRequestsSetSortFunctionType,
} from 'store/requests/actions';
import { SortType } from '../utils/filters/requests.sorters';

export interface RequestsArchivePageContextType extends PageBaseType {
  data: {
    requests: ArchivedRequestsSimpleType;
    user: UserType;
    language: LanguageType;
  };
  actions: PageActionsBaseType & {
    copyToClipboard: (text: string) => void;
    goBack: () => void;
    unarchiveRequest: RequestUnarchiveFunctionType;
    setSort: ArchivedRequestsSetSortFunctionType;
  };
}

const RequestsArchivePage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      requests: state.requests.archived,
      user: state.user.currentUser,
      language: state.settings.languagePreference,
    })}
    dispatches={(
      dispatch: Dispatch<ActionType>,
      ownProps: PagePropsType,
      updateQuery: UpdateQueryType,
    ) => ({
      getData: ({
        data: {
          user: {
            company: { id },
          },
        },
        queryParams: { sortBy },
      }: {
        data: {
          user: UserType;
        };
        queryParams: { sortBy: SortType };
      }) => {
        dispatch(startLoading(1));
        dispatch(getArchivedRequests(id));

        if (sortBy) {
          dispatch(setSortArchived(sortBy));
        }
      },
      reset: () => dispatch(resetRequests()),
      actions: {
        copyToClipboard: (text: string) => {
          copyTextToClipboard(text);
          dispatch(flash(i18n.t('common:responses.copySuccess')));
        },
        goBack: ownProps.history.goBack,
        unarchiveRequest: (id: Uuid) => dispatch(unarchiveRequest(id)),
        setSort: (sortBy: SortType) => {
          updateQuery({ sortBy });
          dispatch(setSortArchived(sortBy));
        },
      },
    })}
  >
    <Archive />
  </Page>
);

export default RequestsArchivePage;
