import Loader from 'react-loader-spinner';
import React from 'react';
import { Centered } from 'common/common.styles';
import { Heading } from './Loading.styles';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

const Loading = ({ t }: TranslatePropsType) => (
  <Centered>
    <Loader type="Grid" color="#333" width={50} height={50} />

    <Heading>{t('searching') as string}</Heading>
  </Centered>
);

export default withTranslation('QuestionMappings')(Loading);
