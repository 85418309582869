import styled, { css } from 'styled-components/macro';

interface IconWrapperProps {
  active?: boolean;
}

const hoveredOrActiveProps = css`
  background-color: ${({ theme }) => theme.atlas.teal[100]};
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  font-size: ${({ theme }) => theme.basic.fontSizes.medium};

  ${({ active }) => (active ? hoveredOrActiveProps : null)};

  &:hover {
    cursor: pointer;
    ${hoveredOrActiveProps};
  }
`;
