import styled from 'styled-components';
import hex2rgba from 'utils/hex2rgba';

export const BodyWrapper = styled.div`
  padding: 15px 30px 15px 15px;
  position: relative;

  > span {
    ${({ theme }) => theme.fonts.light.small}
    display: block;
    position: relative;
  }

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => hex2rgba(theme.atlas.white, 0.7)};
  }
`;

export const Extension = styled.div<{ extension: string }>`
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: ${({ theme }) => theme.atlas.grey[50]};

  position: relative;

  > div {
    background-color: ${({ theme }) => hex2rgba(theme.atlas.grey[50], 0.7)};
    position: absolute;
    width: 100%;
    height: 100%;
    top: -7px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
