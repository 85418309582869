import Loading from '../common/Loading';
import MappingsTable from './MappingsTable';
import React, { useState } from 'react';
import axios from 'axios';
import withUrl from 'utils/url/withUrl';
import { Flex, RelativeBlock } from 'common/common.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from './Utils.styles';
import { QuestionDetailBase } from 'generated/models/QuestionDetailBase';
import { Standard } from 'generated/models/Standard';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Caption,
  Input,
  InputWrapper,
  Instructions,
  Label,
  SmallerInputWrapper,
} from './SearchMappings.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface SearchMappingResultType {
  algorithm: string;
  score: number;
  source_question: QuestionDetailBase;
  source_standard: Standard;
  target_question: QuestionDetailBase;
  target_standard: Standard;
}

export interface SearchMappingType {
  depth: number;
  result: SearchMappingResultType;
}

interface InviteUserPromptPropsType {
  initialForm?: {
    edits: number;
    hops: number;
    query: string;
  };
  initialLoading?: boolean;
  initialMappings?: SearchMappingType[] | null;
}

const SearchMappings = ({
  initialForm = {
    hops: 1,
    edits: 15,
    query: '',
  },
  initialLoading = false,
  initialMappings = null,
}: InviteUserPromptPropsType) => {
  const [form, setForm] = useState(initialForm);
  const [mappings, setMappings] = useState(initialMappings);
  const [isLoading, setLoading] = useState(initialLoading);

  const onQueryChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, query: target.value });
  };

  const onHopsChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, hops: parseInt(target.value, 10) });
  };

  const onEditsChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, edits: parseInt(target.value, 10) });
  };

  const onSubmit = () => {
    const params = [];

    if (form.query) {
      setLoading(true);

      params.push(`text=${form.query}`);

      if (form.hops) {
        params.push(`max_hops=${form.hops}`);
      }

      if (form.edits) {
        params.push(`max_diff=${form.edits}`);
      }

      axios
        .get(withUrl(`companies/current/mappings?${params.join('&')}`, ''))
        .then(
          ({ data: { msg, payload } }) => {
            setLoading(false);

            if (msg === 'OK') {
              setMappings(payload);
            }
          },
          error => {
            setLoading(false);

            console.log('Data fetching error - ', { error });
          },
        );
    }
  };

  return (
    <RelativeBlock>
      <Instructions>
        <p>
          <strong>Search query</strong> - text to search for
        </p>
        <p>
          <strong>Hops</strong> - number of hops from fuzzy matching with static
          mapping (def: 1)
        </p>
        <p>
          <strong>Edits</strong> - what percentage of characters could be
          different (def: 15)
        </p>
      </Instructions>

      <Flex>
        {isLoading && <Loading text="Fetching mappings..." />}

        <InputWrapper>
          <Label>Search query</Label>
          <Input
            type={'text'}
            onChange={onQueryChange}
            placeholder={'Enter text here'}
          />
        </InputWrapper>

        <SmallerInputWrapper>
          <Label>Hops</Label>
          <Input type={'number'} value={form.hops} onChange={onHopsChange} />
        </SmallerInputWrapper>

        <SmallerInputWrapper>
          <Label>Edits</Label>
          <Input type={'number'} value={form.edits} onChange={onEditsChange} />
        </SmallerInputWrapper>

        <InputWrapper>
          <Link
            primary
            onClick={form.query === '' ? undefined : onSubmit}
            disabled={form.query === ''}
          >
            <FontAwesomeIcon icon={faSearch as IconProp} color={'#fff'} />

            <span style={{ paddingRight: 26 }}>Search</span>
          </Link>
        </InputWrapper>
      </Flex>

      {mappings && (
        <div>
          {mappings.length === 0 ? (
            <Caption>0 results.</Caption>
          ) : (
            <MappingsTable mappings={mappings} />
          )}
        </div>
      )}
    </RelativeBlock>
  );
};

export default SearchMappings;
