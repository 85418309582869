import { ApiCallActionType } from '../../store.types';
import { PriceInfo } from '../../../generated/models/PriceInfo';
import { parsePricing } from 'utils/parsers';
import { StripeStateType } from '../stripe.reducer';

export type StripeGetPricingInfoActionType = ApiCallActionType<
  '@stripe/API/GET_PRICING_INFO',
  never,
  PriceInfo[],
  never,
  StripeStateType
>;

export const getPricingInfo = (): StripeGetPricingInfoActionType => ({
  type: '@stripe/API/GET_PRICING_INFO',
  payload: {
    endpointPath: ['stripe', 'get'],
    selector: data => ({
      pricings: data.map(parsePricing),
    }),
  },
});
