import React, { MouseEvent } from 'react';
import { Arity1Fn } from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  RequestArchiveFunctionType,
  RequestClearNotificationsFunctionType,
  RequestDeleteFunctionType,
  RequestMarkReadFunctionType,
  RequestUnarchiveFunctionType,
} from 'store/requests/actions';
import { RequestSimpleType, TranslatePropsType } from 'common/common.types';
import { Wrapper, Action } from './Actions.styles';
import { withTranslation } from 'react-i18next';
import {
  faArchive,
  faBellSlash,
  faEye,
  faEyeSlash,
  faUndo,
  faFileExport,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FormProxyShowProxyModalFunctionType } from 'store/formProxy/actions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface ActionsPropsType extends TranslatePropsType {
  actions: {
    archiveRequest?: RequestArchiveFunctionType;
    clearNotifications?: RequestClearNotificationsFunctionType;
    copyToClipboard: (text: string) => void;
    deleteRequest?: RequestDeleteFunctionType;
    handleClick: (fn: Arity1Fn) => (fnArg: any) => (e: MouseEvent) => void;
    markRead?: RequestMarkReadFunctionType;
    showProxyModal?: FormProxyShowProxyModalFunctionType;
    unarchiveRequest?: RequestUnarchiveFunctionType;
  };
  background: string;
  formURL: string;
  hasNotifications: boolean;
  request: RequestSimpleType;
}

const Actions = ({
  actions: {
    archiveRequest,
    clearNotifications,
    deleteRequest,
    handleClick,
    markRead,
    showProxyModal,
    unarchiveRequest,
  },
  background,
  request,
  request: {
    id,
    isRead,
    meta: { isArchivable, statusBeforeArchive },
  },
  formURL,
  hasNotifications,
  t,
}: ActionsPropsType) => (
  <Wrapper background={background}>
    {deleteRequest && (
      <Action onClick={handleClick(deleteRequest)([id])}>
        <FontAwesomeIcon icon={faTrashAlt as IconProp} />

        <span data-cy="delete-request">{t('Requests.delete') as string}</span>
      </Action>
    )}

    {isArchivable && archiveRequest && (
      <Action onClick={handleClick(archiveRequest)([id])}>
        <FontAwesomeIcon icon={faArchive as IconProp} />

        <span data-cy="request-archive">{t('Requests.archive') as string}</span>
      </Action>
    )}

    {isArchivable && unarchiveRequest && (
      <Action onClick={handleClick(unarchiveRequest)(id)}>
        <FontAwesomeIcon icon={faUndo as IconProp} />

        <span>{t('Requests.moveBack', { statusBeforeArchive }) as string}</span>
      </Action>
    )}

    {!isRead && markRead && (
      <Action
        onClick={handleClick(markRead)({ requests: [request], read: true })}
      >
        <FontAwesomeIcon icon={faEyeSlash as IconProp} />

        <span>{t('Requests.markAsRead') as string}</span>
      </Action>
    )}

    {isRead && markRead && (
      <Action
        onClick={handleClick(markRead)({ requests: [request], read: false })}
      >
        <FontAwesomeIcon icon={faEye as IconProp} />

        <span>{t('Requests.markAsUnread') as string}</span>
      </Action>
    )}

    {hasNotifications && clearNotifications && (
      <Action onClick={handleClick(clearNotifications)([request])}>
        <FontAwesomeIcon icon={faBellSlash as IconProp} />

        <span>{t('Requests.clearNotifications') as string}</span>
      </Action>
    )}

    {showProxyModal && (
      <Action onClick={handleClick(showProxyModal)(request)}>
        <FontAwesomeIcon icon={faFileExport as IconProp} />

        <span data-cy="collaboration">
          {t('Requests.showProxyModal') as string}
        </span>
      </Action>
    )}
  </Wrapper>
);

export default withTranslation()(Actions);
