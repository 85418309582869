import React, { memo } from 'react';
import {
  CategoryName,
  CategoryWrapper,
  Wrapper as FrameworksWrapper,
} from './Frameworks.styles';
import { Badge } from '../../common/Badge';
import colors from 'common/common.colors';
import { Framework } from './index';
import { withTranslation } from 'react-i18next';
import {
  FrameworkCategoryNameType,
  FrameworkType,
  TranslatePropsType,
  Uuid,
  UserType,
} from 'common/common.types';

export interface CategoryPropsType extends TranslatePropsType {
  category: FrameworkCategoryNameType;
  deleteForm: (formId: Uuid) => void;
  frameworks: FrameworkType[];
  hiddenFrameworks: Uuid[];
  hideFramework: (frameworkId: Uuid) => void;
  selectFramework: (framework: FrameworkType) => void;
  showHidden: boolean;
  unhideFramework: (frameworkId: Uuid) => void;
  user: UserType;
}

const Category = ({
  category,
  deleteForm,
  frameworks,
  hiddenFrameworks,
  hideFramework,
  selectFramework,
  showHidden,
  unhideFramework,
  user,
  t,
}: CategoryPropsType) => {
  const hiddenFrameworksSet = new Set(hiddenFrameworks);

  return (
    <CategoryWrapper>
      <CategoryName hasItems={frameworks.length > 0}>
        <>
          {t(`Framework.${category}`)}

          <Badge color={colors.elements.framework[category].color}>
            {frameworks.length}
          </Badge>
        </>
      </CategoryName>

      <FrameworksWrapper>
        {frameworks.map(framework => (
          <Framework
            key={`framework-${category}-${framework.id}`}
            deleteForm={deleteForm}
            framework={framework}
            hideFramework={hideFramework}
            isHidden={hiddenFrameworksSet.has(framework.id)}
            selectFramework={selectFramework}
            showHidden={
              hiddenFrameworksSet.has(framework.id) ? showHidden : false
            }
            unhideFramework={unhideFramework}
            user={user}
          />
        ))}
      </FrameworksWrapper>
    </CategoryWrapper>
  );
};

export default memo(withTranslation(['components', 'common'])(Category));
