import styled from 'styled-components';
import { HEADER_HEIGHT } from 'common/common.styles';

export const Wrapper = styled.div<{ withOffset: boolean }>`
  position: absolute;
  top: ${({ withOffset }) => HEADER_HEIGHT + (withOffset ? 100 : 30)}px;
  right: calc((100% - 1140px) / 2 - 33px);
  z-index: 500;
  max-width: 800px;
  text-align: right;

  @media print {
    display: none;
  }
`;
