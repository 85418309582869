import * as R from 'ramda';
import Checkbox from '../common/Checkbox';
import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';
import { TextButton } from 'common/common.styles';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import {
  CheckboxWrapper,
  Hoverable,
  Selectable,
  TextInput,
} from './StandardListItem.styles';
import Tooltip from '../common/Tooltip';

interface StandardListItemPropsType extends TranslatePropsType {
  code: string;
  isCustom?: boolean;
  isSelected: boolean;
  name: string;
  onDelete: () => void;
  onPreview?: () => void;
  onSave: (newName: string) => void;
  onToggle?: () => void;
}

interface StandardListItemStateType {
  isEditing: boolean;
  newName: string;
}

type ActionButtonType = {
  key: string;
  title: string;
  onClick?: () => void;
  disabled?: boolean;
};

class StandardListItem extends PureComponent<
  StandardListItemPropsType,
  StandardListItemStateType
> {
  renameInput = React.createRef();

  constructor(props: StandardListItemPropsType) {
    super(props);
    this.state = { isEditing: false, newName: this.props.name };
  }

  handleClickOutside = () => this.setState({ isEditing: false });

  handleTextChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => this.setState({ newName: value });

  handleKeyPress = (e: React.KeyboardEvent) => {
    if (R.contains(e.key, ['Enter'])) {
      this.handleSave();
    }
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { newName } = this.state;
    onSave(newName);
    this.setState({ isEditing: false, newName });
  };

  render() {
    const {
      code,
      isCustom,
      isSelected,
      name,
      onDelete,
      onPreview,
      onToggle,
      t,
    } = this.props;

    const { isEditing, newName } = this.state;

    let actionButtons: ActionButtonType[] = isEditing
      ? [
          {
            key: 'save',
            title: 'common:labels.save',
            onClick: this.handleSave,
          },
          {
            key: 'cancel',
            title: 'common:labels.cancel',
            onClick: () => this.setState({ isEditing: false, newName: name }),
          },
        ]
      : onPreview
      ? [
          {
            key: 'preview',
            title: 'common:labels.preview',
            onClick: onPreview,
          },
        ]
      : [];
    if (!isEditing) {
      actionButtons = actionButtons.concat(
        isCustom
          ? [
              {
                key: 'rename',
                title: 'rename',
                onClick: () => {
                  this.setState({ isEditing: true });
                  if (this.renameInput.current) {
                    (this.renameInput.current as any).focus();
                    (this.renameInput.current as any).select();
                  }
                },
              },
              {
                key: 'delete',
                title: 'delete',
                onClick: onDelete,
              },
            ]
          : [
              {
                key: 'rename',
                title: 'rename',
                disabled: true,
              },
              {
                key: 'delete',
                title: 'delete',
                disabled: true,
              },
            ],
      );
    }

    const renderActionButton = ({
      key,
      title,
      onClick,
      disabled,
    }: ActionButtonType) => {
      const buttonItem = (
        <TextButton
          disabled={disabled}
          key={key}
          size={'mini'}
          color={'atlas'}
          onClick={onClick}
        >
          {t(title) as string}
        </TextButton>
      );
      return disabled ? (
        <Tooltip key={key} placement={'top'} overlay={t('tooltip') as string}>
          {buttonItem}
        </Tooltip>
      ) : (
        buttonItem
      );
    };

    return (
      <Hoverable
        data-cy={`standard-${code}`}
        data-test="questionnaire-template"
      >
        <Selectable isSelected={isSelected}>
          <CheckboxWrapper isEditing={isEditing}>
            <Checkbox
              size={22}
              primaryColor={'atlas'}
              isChecked={isSelected}
              label={name}
              onChange={onToggle ? onToggle : () => null}
              hideCheckmark={!onToggle}
            />
          </CheckboxWrapper>

          <TextInput
            value={newName}
            isEditing={isEditing}
            ref={this.renameInput as any}
            onChange={this.handleTextChange}
            onKeyPress={this.handleKeyPress}
          />
        </Selectable>

        {actionButtons.map(renderActionButton)}
      </Hoverable>
    );
  }
}

export default withTranslation('SendRequest')(onClickOutside(StandardListItem));
