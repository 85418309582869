/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface ScheduledRequestUpdate
 */
export interface ScheduledRequestUpdate {
    /**
     * 
     * @type {number}
     * @memberof ScheduledRequestUpdate
     */
    occurrence_number: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledRequestUpdate
     */
    recurrence_type: ScheduledRequestUpdateRecurrenceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ScheduledRequestUpdate
     */
    recurrence_units: number;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledRequestUpdate
     */
    send_date: Date;
}




/**
* @export
* @enum {string}
*/
export enum ScheduledRequestUpdateRecurrenceTypeEnum {
    DAILY = 'DAILY',
    HOURLY = 'HOURLY',
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY',
    YEARLY = 'YEARLY'
}


