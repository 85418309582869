/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormTrackSeenResource
 */
export interface FormTrackSeenResource {
    /**
     * restrict scope to a single block (optional)
     * @type {string}
     * @memberof FormTrackSeenResource
     */
    block?: FormTrackSeenResourceBlockEnum;
    /**
     * restrict scope to a single question (optional)
     * @type {string}
     * @memberof FormTrackSeenResource
     */
    questionId?: string;
    /**
     * mark form as read/unread (optional)
     * @type {boolean}
     * @memberof FormTrackSeenResource
     */
    read?: boolean;
}

export function FormTrackSeenResourceFromJSON(json: any): FormTrackSeenResource {
    return FormTrackSeenResourceFromJSONTyped(json, false);
}

export function FormTrackSeenResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormTrackSeenResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'block': !exists(json, 'block') ? undefined : json['block'],
        'questionId': !exists(json, 'question_id') ? undefined : json['question_id'],
        'read': !exists(json, 'read') ? undefined : json['read'],
    };
}

export function FormTrackSeenResourceToJSON(value?: FormTrackSeenResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block': value.block,
        'question_id': value.questionId,
        'read': value.read,
    };
}

/**
* @export
* @enum {string}
*/
export enum FormTrackSeenResourceBlockEnum {
    Attachments = 'attachments',
    Comments = 'comments',
    SscData = 'ssc_data',
    History = 'history',
    All = 'all'
}


