import React, { useState, Fragment } from 'react';
import ScheduledRequest, { DeleteRequestModal } from './ScheduledRequest';
import formatRoute, { SEND_REQUEST } from '../../routes';
import { Badge } from '../common/Badge';
import { Content, Wrapper } from 'common/common.styles';
import { PageContextConsumer } from 'pages/Page';
import { PreviewModal } from '../SendRequest/Modals';
import { RequestsPageContextType } from 'pages/RequestsPage';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  FORM_PREVIEW_MODAL_ID,
  SCHEDULED_REQUEST_DELETE_MODAL_ID,
} from 'common/common.constants';
import {
  DeleteIntervalEnum,
  ScheduledRequestType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import {
  ActionsWrapper,
  Categories,
  CollapsibleContent,
  ExpandIconWrapper,
  Icon,
  Title,
  TriggerWrapper,
} from './List.styles';
import NoData from '../common/NoData';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface TriggerType {
  company: string;
  count: number;
  isOpen: boolean;
}

interface OpenedType {
  [key: string]: boolean;
}

const ScheduledRequests = ({ t }: TranslatePropsType) => {
  const [
    requestToDelete,
    setRequestToDelete,
  ] = useState<ScheduledRequestType | null>(null);

  const [openedCompanies, toggleOpened] = useState<OpenedType>({});

  const Trigger = ({ company, count, isOpen }: TriggerType) => (
    <TriggerWrapper
      count={count}
      onClick={() => {
        if (count) {
          toggleOpened({
            ...openedCompanies,
            [company]: !isOpen,
          });
        }
      }}
    >
      <ActionsWrapper>
        <ExpandIconWrapper isOpen={isOpen} count={count}>
          <Icon icon={faChevronRight as IconProp} open={isOpen} />
        </ExpandIconWrapper>

        <Title withOffset>{company}</Title>

        <Badge color="seaGreen">{count}</Badge>
      </ActionsWrapper>
    </TriggerWrapper>
  );

  return (
    <PageContextConsumer>
      {({
        actions: {
          deleteScheduledRequest,
          getPreview,
          loadQuestions,
          putQuestion,
          showModal,
        },
        data: {
          scheduled,
          previewForm,
          user,
          user: { credits },
          language,
        },
      }: RequestsPageContextType) => {
        const handleDeleteRequestClick = (
          e: React.MouseEvent<HTMLElement>,
          request: ScheduledRequestType,
        ) => {
          e.stopPropagation();
          showModal(SCHEDULED_REQUEST_DELETE_MODAL_ID);
          setRequestToDelete(request);
        };

        const handlePreviewFormClick = (formId: Uuid) => {
          getPreview(formId);
          showModal(FORM_PREVIEW_MODAL_ID);
        };

        const onDeleteRequest = (id: Uuid, type: DeleteIntervalEnum) => {
          setRequestToDelete(null);
          deleteScheduledRequest(id, type);
        };

        const hasData = scheduled.count > 0;

        return (
          <>
            {requestToDelete && (
              <DeleteRequestModal
                closeModal={() => setRequestToDelete(null)}
                handleSubmit={onDeleteRequest}
                modalId={SCHEDULED_REQUEST_DELETE_MODAL_ID}
                request={requestToDelete}
                language={language}
              />
            )}

            <PreviewModal
              loadQuestions={loadQuestions}
              modalId={FORM_PREVIEW_MODAL_ID}
              previewForm={previewForm}
              putQuestion={putQuestion}
              user={user}
              language={language}
            />

            <Wrapper grey>
              <Content padding={'0'}>
                {hasData && (
                  <Categories>
                    {scheduled.companies.map(company => {
                      const requests = scheduled.items[company];
                      const count = requests.length;
                      const isOpen = openedCompanies[company] !== false;

                      return (
                        <Fragment key={`fragment-${company}`}>
                          <Trigger
                            company={company}
                            count={count}
                            isOpen={isOpen}
                          />

                          {count > 0 && (
                            <CollapsibleContent isOpen={isOpen} withTopOffset>
                              {requests.map(request => (
                                <ScheduledRequest
                                  key={request.id}
                                  data={{
                                    enoughCredits:
                                      ((credits &&
                                        credits.summary!.remaining) ||
                                        0) > 0,
                                    request,
                                  }}
                                  actions={{
                                    previewForm: handlePreviewFormClick,
                                    deleteRequest: handleDeleteRequestClick,
                                  }}
                                />
                              ))}
                            </CollapsibleContent>
                          )}
                        </Fragment>
                      );
                    })}
                  </Categories>
                )}
                {!hasData && (
                  <NoData
                    buttonText={t('ScheduledRequest.noDataButton')}
                    text={t('ScheduledRequest.noData')}
                    path={formatRoute(SEND_REQUEST)}
                  />
                )}
              </Content>
            </Wrapper>
          </>
        );
      }}
    </PageContextConsumer>
  );
};

export default withTranslation()(ScheduledRequests);
