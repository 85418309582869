import EmptyPlaceholder from './EmptyPlaceholder';
import React, { ChangeEvent, Component } from 'react';
import { Label } from '../../Form.styles';
import { ResponseInputPropsType } from '../Response';
import { Textarea as StyledTextarea, autoExpand } from './Textarea.styles';

interface StateType {
  value: string;
}

class TextArea extends Component<ResponseInputPropsType, StateType> {
  constructor(props: ResponseInputPropsType) {
    super(props);
    const {
      data: { value },
    } = props;

    this.state = { value };
  }

  shouldComponentUpdate(
    nextProps: Readonly<ResponseInputPropsType>,
    nextState: Readonly<StateType>,
  ): boolean {
    return (
      nextProps.data.value !== this.props.data.value ||
      nextState.value !== this.state.value
    );
  }

  componentDidUpdate(prevProps: ResponseInputPropsType) {
    const {
      data: { value },
    } = this.props;

    if (prevProps.data.value !== value) {
      this.setState({ value });
    }
  }

  handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    autoExpand(event.target);

    this.setState({ value: event.currentTarget.value });
  };

  handleBlur = () => {
    const { handleChange } = this.props.actions;
    const { value: originalValue } = this.props.data;
    const { value } = this.state;

    if (originalValue !== value.trim()) {
      handleChange(value.trim());
    }
  };

  render() {
    const { isEditable, label, placeholder } = this.props.data;
    const { value } = this.state;

    if (!isEditable && !value) {
      return <EmptyPlaceholder label={label} />;
    }

    return (
      <>
        {<Label>{label}</Label>}

        <StyledTextarea
          disabled={!isEditable}
          editable={isEditable}
          onAnimationStart={e => autoExpand(e.currentTarget)}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          placeholder={placeholder || ''}
          value={value}
        />
      </>
    );
  }
}

export default TextArea;
