import { TagType } from '../../../common/common.types';

export interface CompaniesRemoveTagFromFilterActionType {
  type: '@companies/REMOVE_TAG_FROM_FILTER';
  payload: TagType;
}

export const removeTagFromFilter = (
  tag: TagType,
): CompaniesRemoveTagFromFilterActionType => ({
  type: '@companies/REMOVE_TAG_FROM_FILTER',
  payload: tag,
});
