/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Company } from "./Company";
// @ts-ignore
import { Standard } from "./Standard";
// @ts-ignore
import { Team } from "./Team";

/**
 * 
 * @export
 * @interface ScheduledRequest
 */
export interface ScheduledRequest {
    /**
     * Flag of whether the scheduled request has been cancelled
     * @type {boolean}
     * @memberof ScheduledRequest
     */
    cancelled: boolean;
    /**
     * Datetime in ms of next requests due date
     * @type {Date}
     * @memberof ScheduledRequest
     */
    due_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof ScheduledRequest
     */
    email_body: string;
    /**
     * The unique identifier of scheduled filling request
     * @type {string}
     * @memberof ScheduledRequest
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledRequest
     */
    is_internal: boolean;
    /**
     * Datetime in ms of next request send
     * @type {Date}
     * @memberof ScheduledRequest
     */
    next_send_date: Date;
    /**
     * How many times a request will be sent. If not set, send indefinitely
     * @type {number}
     * @memberof ScheduledRequest
     */
    num_of_occurrences?: number;
    /**
     * Temporal type of scheduled recurrence (Monthly, Daily, etc.)
     * @type {string}
     * @memberof ScheduledRequest
     */
    recurrence_type?: ScheduledRequestRecurrenceTypeEnum;
    /**
     * Number of temporal units for recurrence (2Months, 12days, etc.)
     * @type {number}
     * @memberof ScheduledRequest
     */
    recurrence_units?: number;
    /**
     * 
     * @type {Company}
     * @memberof ScheduledRequest
     */
    source: Company;
    /**
     * 
     * @type {Team}
     * @memberof ScheduledRequest
     */
    source_team?: Team;
    /**
     * 
     * @type {string}
     * @memberof ScheduledRequest
     */
    source_user_email: string;
    /**
     * 
     * @type {Standard}
     * @memberof ScheduledRequest
     */
    standard: Standard;
    /**
     * 
     * @type {Company}
     * @memberof ScheduledRequest
     */
    target: Company;
    /**
     * 
     * @type {Team}
     * @memberof ScheduledRequest
     */
    target_team?: Team;
    /**
     * 
     * @type {string}
     * @memberof ScheduledRequest
     */
    target_user_email: string;
}




/**
* @export
* @enum {string}
*/
export enum ScheduledRequestRecurrenceTypeEnum {
    DAILY = 'DAILY',
    HOURLY = 'HOURLY',
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY',
    YEARLY = 'YEARLY'
}


