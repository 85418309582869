import { Uuid, StructuredCondition } from '../../../common/common.types';
import {
  UpdateQuestionConditionActionType,
  updateQuestionCondition,
} from './updateQuestionCondition';
import { QuestionConditionStructuredOperatorEnum } from '../../../generated/models';
import structuredToText from '../conditions/structuredToText';
import isCallable from 'is-callable';
import { NOOP_PAYLOAD } from '../eventSequencer/eventSequencer.constants';

export type SwitchQuestionConditionTypeFunctionType = (
  questionUuid: Uuid,
  conditionType: 'structured' | 'text',
) => void;

const EMPTY_STRUCTURED_CONDITION: StructuredCondition = {
  operator: QuestionConditionStructuredOperatorEnum.AND,
  items: [],
};

export const switchQuestionConditionType = (
  questionUuid: Uuid,
  conditionType: 'structured' | 'text',
): UpdateQuestionConditionActionType => ({
  type: '@formCreator/EVENTS/UPDATE_CONDITION',
  payload: state => {
    const {
      formCreator: { currentForm },
    } = state;

    if (!currentForm) {
      return NOOP_PAYLOAD;
    }

    const question = currentForm.questions.find(
      ({ wizardId }) => wizardId === questionUuid,
    );

    if (!question) {
      return NOOP_PAYLOAD;
    }

    const { condition } = question;

    let updateAction: UpdateQuestionConditionActionType | undefined;

    if (!condition && conditionType === 'text') {
      updateAction = updateQuestionCondition(questionUuid, {
        type: 'text',
        payload: '',
      });
    } else if (!condition && conditionType === 'structured') {
      updateAction = updateQuestionCondition(questionUuid, {
        type: 'structured',
        payload: question.conditionBackup
          ? question.conditionBackup.structured
          : EMPTY_STRUCTURED_CONDITION,
      });
    } else if (!condition) {
      updateAction = undefined;
    } else if (condition.type === 'structured' && conditionType === 'text') {
      updateAction = updateQuestionCondition(questionUuid, {
        type: 'text',
        payload: structuredToText(currentForm.questions, condition.payload),
      });
    } else if (condition.type === 'text' && conditionType === 'structured') {
      updateAction = updateQuestionCondition(questionUuid, {
        type: 'structured',
        payload:
          // We restore the backup only if the user hasn't changed the condition
          // text.
          question.conditionBackup &&
          question.conditionBackup.text === condition.payload
            ? question.conditionBackup.structured
            : EMPTY_STRUCTURED_CONDITION,
      });
    }

    return updateAction
      ? isCallable(updateAction.payload)
        ? updateAction.payload(state)
        : updateAction.payload
      : NOOP_PAYLOAD;
  },
});
