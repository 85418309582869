import styled from 'styled-components';
import { Category } from './FilterCategory.styles';

export const Wrapper = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen
      ? {
          display: 'block',
        }
      : {
          display: 'none',
        }};
  background: ${({ theme }) => theme.basic.colors.light};
  border: 1px ${({ theme }) => theme.basic.colors.lightBorder} solid;
  box-shadow: 4px 4px 4px 0 ${({ theme }) => theme.basic.colors.lightBorder};
  box-sizing: border-box;
  top: 63px;
  position: absolute;
  z-index: 10000;

  &:after {
    ${({ isOpen }) =>
      isOpen
        ? {
            opacity: 1,
          }
        : {
            opacity: 0,
            marginTop: 0,
            borderColor: 'transparent',
          }};
    border-color: transparent transparent
      ${({ theme }) => theme.basic.colors.light} transparent;
    border-style: solid;
    border-width: 0 16px 12px 16px;
    content: '';
    height: 0;
    left: 64px;
    margin-left: -15px;
    margin-top: -11px;
    position: absolute;
    top: 0;
    width: 0;
  }

  &:before {
    ${({ isOpen }) =>
      isOpen
        ? {
            opacity: 1,
          }
        : {
            opacity: 0,
            marginTop: 0,
            borderColor: 'transparent',
          }};
    border-color: transparent transparent
      ${({ theme }) => theme.basic.colors.lightBorder} transparent;
    border-style: solid;
    border-width: 0 16px 12px 16px;
    content: '';
    height: 0;
    left: 64px;
    margin-left: -15px;
    margin-top: -12px;
    position: absolute;
    top: 0;
    width: 0;
  }
`;

export const BottomBar = styled.div`
  border-top: 1px ${({ theme }) => theme.basic.colors.darkerBorder} solid;
  display: flex;
  flex-direction: column;
  height: 49.5px;
  justify-content: center;
  padding: 0 19.5px;
`;

export const CloseButton = styled.div`
  ${({ theme }) => theme.fonts.bold.large}
  position: relative;
  top: 20px;
  left: 95%;
  cursor: pointer;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 32px 29px;

  > ${Category} {
    margin: 0 25px;
  }
`;
