/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormAssignmentReplace
 */
export interface FormAssignmentReplace {
    /**
     * 
     * @type {string}
     * @memberof FormAssignmentReplace
     */
    assigneeEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof FormAssignmentReplace
     */
    assigneeId?: string;
    /**
     * 
     * @type {string}
     * @memberof FormAssignmentReplace
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof FormAssignmentReplace
     */
    questionId?: string;
}

export function FormAssignmentReplaceFromJSON(json: any): FormAssignmentReplace {
    return FormAssignmentReplaceFromJSONTyped(json, false);
}

export function FormAssignmentReplaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormAssignmentReplace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assigneeEmail': !exists(json, 'assignee_email') ? undefined : json['assignee_email'],
        'assigneeId': !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'questionId': !exists(json, 'question_id') ? undefined : json['question_id'],
    };
}

export function FormAssignmentReplaceToJSON(value?: FormAssignmentReplace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee_email': value.assigneeEmail,
        'assignee_id': value.assigneeId,
        'category': value.category,
        'question_id': value.questionId,
    };
}


