import ReactHtmlParser from 'react-html-parser';

export const getErrorMessage = (
  status: number,
  actionType: string,
  message: string,
): string =>
  ReactHtmlParser(
    `${message}
  <p>
    <small>Status code: ${status}, Action: ${actionType}.</small>
  </p>`,
  );
