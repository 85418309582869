import styled from 'styled-components';
import pickColorFromGradient from 'utils/pickColorFromGradient';

const getBackgroundColor = (score?: number) => {
  const colorArr = pickColorFromGradient(
    [135, 200, 204],
    [247, 124, 124],
    score || 0,
  );

  return `rgb(${colorArr[0]}, ${colorArr[1]}, ${colorArr[2]})`;
};

type ScoreType = {
  score?: number;
  width?: number;
};

export const Progress = styled.div<ScoreType>`
  background: ${({ theme }) => theme.request.barBackground};
  border-radius: 3px;
  height: 18px;
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '100%')};

  &:before {
    background: ${({ score }) => getBackgroundColor(score)};
    border-radius: 3px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: ${({ score }) => `${score}%`};
  }
`;
export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextInfo = styled.span`
  ${({ theme }) => theme.basic.fontWeights.light};
  color: ${({ theme }) => theme.basic.colors.gray};
  width: 90%;
`;
