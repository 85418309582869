import { ApiCallActionPayloadType } from '../../store.types';
import { propEq, reject } from 'ramda';
import { getStandardsForFormDefinitions } from './getStandardsForFormDefinitions';
import { clearFormDefinitionSelection } from './selectStandardForFormDefinition';
import { put } from 'redux-saga/effects';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';

import { DEFAULT_STANDARDS_STATUS } from '../defaultValues';
import { Uuid } from '../../../common/common.types';
import { flash } from '../../flashes/actions';
import i18n from '../../../i18n';
import { startLoading } from '../../app/actions';

export type DeleteDraftFromFormDefinitionsFunctionType = (
  params: DeleteDraftFromFormDefinitionsParamsType,
) => void;

export interface DeleteDraftFromFormDefinitionsParamsType {
  id: Uuid[];
}

export type DeleteDraftFromFormDefinitionsActionType = OptimisticActionType<
  '@formDefinitions/API/DELETE_DRAFT',
  ApiCallActionPayloadType<DeleteDraftFromFormDefinitionsParamsType>
>;

export const deleteDraftFromFormDefinitions = (
  params: DeleteDraftFromFormDefinitionsParamsType,
): DeleteDraftFromFormDefinitionsActionType => {
  return {
    type: '@formDefinitions/API/DELETE_DRAFT',
    payload: {
      endpointPath: ['definitions', 'drafts', 'delete'],
      callParams: {
        id: params.id,
      },
      expectedStateChange: {
        'formDefinitions.standards.data': reject(
          propEq('definitionId', params),
        ),
      },
      selector: null,
      postActions: [
        () =>
          put(
            getStandardsForFormDefinitions({
              status: DEFAULT_STANDARDS_STATUS,
            }),
          ),
        () => put(clearFormDefinitionSelection()),
        () => put(startLoading(1)),
        () =>
          put(
            flash(
              i18n.t('common:responses.requestDeleted', {
                count: params.id.length,
              }),
            ),
          ),
      ],
    },
  };
};
