import React, { useCallback } from 'react';
import { Wrapper } from './Filters.styles';
import FiltersStatus from './Filters.Status';
import { TextButton } from 'common/common.styles';
import { withTranslation } from 'react-i18next';
import { FilterByPropsType } from './FilterBy';

const Filters = ({
  data: { isClearable = false, byStatus },
  actions: { setFilterByStatus },
  t,
}: FilterByPropsType) => {
  const clearFilters = useCallback(() => setFilterByStatus([]), [
    setFilterByStatus,
  ]);

  const isFilterSet = byStatus.length !== 0;

  return (
    <Wrapper>
      {isFilterSet && (
        <FiltersStatus
          isClearable={isClearable}
          action={clearFilters}
          filters={byStatus}
        />
      )}

      {isFilterSet && isClearable && (
        <TextButton color="teal" onClick={clearFilters} size="small">
          {t('Requests.clearAllFilters') as string}
        </TextButton>
      )}
    </Wrapper>
  );
};

export default withTranslation('components')(Filters);
