import styled from 'styled-components';

export const Percentage = styled.div`
  font-size: ${({ theme }) => theme.basic.fontSizes.normal};
`;

export const Totals = styled.div`
  color: ${({ theme }) => theme.atlas.text.secondary};
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  ${({ theme }) => theme.basic.fontWeights.regular}
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
