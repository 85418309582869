/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RequestFindingRemediationPlanEnum } from './';
import { RequestFindingRiskLevelEnum } from './';
import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestFindingUpdateInsert
 */
export interface RequestFindingUpdateInsert {
    /**
     * 
     * @type {string}
     * @memberof RequestFindingUpdateInsert
     */
    remediationPlan?: RequestFindingRemediationPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestFindingUpdateInsert
     */
    remediationPlanNote?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFindingUpdateInsert
     */
    riskLevel?: RequestFindingRiskLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestFindingUpdateInsert
     */
    riskLevelNote?: string;
}

export function RequestFindingUpdateInsertFromJSON(json: any): RequestFindingUpdateInsert {
    return RequestFindingUpdateInsertFromJSONTyped(json, false);
}

export function RequestFindingUpdateInsertFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFindingUpdateInsert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'remediationPlan': !exists(json, 'remediation_plan') ? undefined : json['remediation_plan'],
        'remediationPlanNote': !exists(json, 'remediation_plan_note') ? undefined : json['remediation_plan_note'],
        'riskLevel': !exists(json, 'risk_level') ? undefined : json['risk_level'],
        'riskLevelNote': !exists(json, 'risk_level_note') ? undefined : json['risk_level_note'],
    };
}

export function RequestFindingUpdateInsertToJSON(value?: RequestFindingUpdateInsert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'remediation_plan': value.remediationPlan,
        'remediation_plan_note': value.remediationPlanNote,
        'risk_level': value.riskLevel,
        'risk_level_note': value.riskLevelNote,
    };
}

/**
* @export
* @enum {string}
*/
export enum RequestFindingUpdateInsertRemediationPlanEnum {
    ACCEPT = 'ACCEPT',
    COMPLETE = 'COMPLETE',
    REMEDIATE = 'REMEDIATE'
}
/**
* @export
* @enum {string}
*/
export enum RequestFindingUpdateInsertRiskLevelEnum {
    CRITICAL = 'CRITICAL',
    HIGH = 'HIGH',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    NONE = 'NONE'
}


