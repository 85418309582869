import React from 'react';
import { MappingHistoryType } from 'common/common.types';
import { Wrapper } from './Mapping.styles';

export interface MappingPropsType {
  mappingHistory: MappingHistoryType;
}

const Mapping = ({ mappingHistory: { message } }: MappingPropsType) => (
  <Wrapper>{message}</Wrapper>
);

export default Mapping;
