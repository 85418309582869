import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Heading, Hint, Wrapper } from './NoFiles.styles';
import { TranslatePropsType } from 'common/common.types';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const NoFiles = ({ t }: TranslatePropsType) => {
  const noFilesLabel = t('noFilesHere') as string;
  const hintLabel = t('filesUploadHint') as string;
  const faUploadProp = faUpload as IconProp;

  return (
    <Wrapper>
      <Heading>{noFilesLabel}</Heading>

      <FontAwesomeIcon size={'3x'} icon={faUploadProp} />

      <Hint>{hintLabel}</Hint>
    </Wrapper>
  );
};

export default withTranslation('EvidenceLocker')(NoFiles);
