import { RequestType } from 'common/common.types';
import { map, reduce, uniq } from 'ramda';
import { STATUS_TO_CATEGORY } from 'common/common.constants';

type MapRequestType = (request: RequestType) => RequestType;

export default (
  path: string,
  requests: RequestType[],
  remapFn: MapRequestType,
) => {
  const mapRequest = map(remapFn);
  const requestStatuses = uniq(requests.map((r: RequestType) => r.status));
  const finalStateChange: { [x: string]: any } = {
    [`${path}.unsorted`]: (currentValue: RequestType[]) =>
      mapRequest(currentValue),
  };

  return reduce(
    (stateChange, status) => {
      const categoryPath = `${path}.categories.${STATUS_TO_CATEGORY[status]}`;
      stateChange[`${categoryPath}.selected`] = [];
      stateChange[`${categoryPath}.requests`] = (currentValue: RequestType[]) =>
        mapRequest(currentValue);
      return stateChange;
    },
    finalStateChange,
    requestStatuses,
  );
};
