/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormClearedInfo
 */
export interface FormClearedInfo {
    /**
     * ID of the cleared form filling
     * @type {string}
     * @memberof FormClearedInfo
     */
    formId: string;
    /**
     * Total number of questions cleared
     * @type {number}
     * @memberof FormClearedInfo
     */
    questionsCleared: number;
    /**
     * Total number of responses cleared
     * @type {number}
     * @memberof FormClearedInfo
     */
    responsesCleared: number;
}

export function FormClearedInfoFromJSON(json: any): FormClearedInfo {
    return FormClearedInfoFromJSONTyped(json, false);
}

export function FormClearedInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormClearedInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formId': json['form_id'],
        'questionsCleared': json['questions_cleared'],
        'responsesCleared': json['responses_cleared'],
    };
}

export function FormClearedInfoToJSON(value?: FormClearedInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_id': value.formId,
        'questions_cleared': value.questionsCleared,
        'responses_cleared': value.responsesCleared,
    };
}


