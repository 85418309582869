import FiltersDefault, { FiltersDefaultPropsType } from './Filters.Default';
import { withTranslation } from 'react-i18next';
import { isLookForFilter } from 'utils/filters/requests.filters';
import Chip from '../../Form/Filter/Chip';
import React from 'react';
import { STATUS_TO_CATEGORY } from 'common/common.constants';

const FiltersStatus = ({
  filterBy,
  filterOptions,
  handleDelete,
  option,
  t,
}: FiltersDefaultPropsType) => {
  if (isLookForFilter(filterBy)) {
    return (
      <>
        {filterBy.lookFor
          .filter(status => status in STATUS_TO_CATEGORY)
          .map(status => (
            <Chip
              key={`chip-${option}-${status}`}
              label={t(`Requests.${STATUS_TO_CATEGORY[status]}`)}
              onDelete={() => handleDelete(option, status)}
            />
          ))}
      </>
    );
  }
  return (
    <FiltersDefault
      filterBy={filterBy}
      filterOptions={filterOptions}
      handleDelete={handleDelete}
      option={option}
    />
  );
};

export default withTranslation('components')(FiltersStatus);
