import { Select } from 'components/Form/Response/AdaptableSelect/Select/Select.styles';
import styled from 'styled-components';
import hex2rgba from 'utils/hex2rgba';

export const UserDropdownWrapper = styled.div`
  position: absolute;
  top: 70px;
  right: 0;
  background-color: ${({ theme }) => theme.basic.colors.light};
  min-width: 390px;
  color: ${({ theme }) => theme.atlas.text.primary};
  border-radius: 4px;
  box-shadow: 1px 6px 4px 0 ${({ theme }) => theme.basic.colors.lightBorder};
  padding: 0 28px;
  overflow: auto;
  max-height: calc(100vh - 90px);
`;

export const Item = styled.div`
  display: flex;
  padding: 0 15px;
  align-items: center;
  justify-content: center;
  line-height: 45px;
  white-space: nowrap;
  border-top: 1px solid
    ${({ theme }) => hex2rgba(theme.basic.colors.black, 0.2)};

  a {
    text-decoration: underline;
    border: none;
    background: none;
    color: ${({ theme }) => theme.atlas.text.primary};
    cursor: pointer;
    font-size: ${({ theme }) => theme.basic.fontSizes.normal};

    &:hover {
      text-decoration: none;
    }
  }
`;

export const Wrapper = styled.div`
  padding-left: 10px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
`;

export const Email = styled.div`
  ${({ theme }) => theme.fonts.bold.normal}
  line-height: 1.315;
  text-align: center;
  margin: 28px 0 5px;
`;

export const LogOut = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.atlas.teal.primary};
  cursor: pointer;
  ${({ theme }) => theme.fonts.semibold.normal}
  padding: 25px;

  svg {
    margin-right: 10px;
  }

  &:hover {
    color: ${({ theme }) => theme.atlas.teal[800]};
  }
`;

export const LanguageLabel = styled.label`
  margin-right: 5px;
`;

export const LanguageSelect = styled(Select)`
  flex:1
  line-height: 24px;
`;
