import { ApiCallActionType } from '../../store.types';
import { UserDetail } from '../../../generated/models/UserDetail';
import { parseUser } from 'utils/parsers';
import { CompaniesStateType } from '../companies.reducer';

export interface CompaniesFindUsersParamsType {
  prefix: string;
  limit: number;
  active_only?: boolean;
}

export type CompaniesFindUsersActionType = ApiCallActionType<
  '@companies/API/FIND_USERS',
  CompaniesFindUsersParamsType,
  UserDetail[],
  never,
  CompaniesStateType
>;

export const findUsers = (
  params: CompaniesFindUsersParamsType,
): CompaniesFindUsersActionType => ({
  type: '@companies/API/FIND_USERS',
  payload: {
    endpointPath: ['companies', 'getUsers'],
    callParams: params,
    selector: (data: UserDetail[]) => ({
      users: data.map((user: UserDetail) => parseUser({ user })),
    }),
  },
});
