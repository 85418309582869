/*eslint no-unexpected-multiline: "off"*/
import styled from 'styled-components';

const MAX_BAR_WIDTH = 224;

const getPx = (percentage: number) => `${percentage * MAX_BAR_WIDTH}px`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 13px;
  justify-content: start;
  align-items: center;
  white-space: nowrap;
`;

export const Title = styled.div`
  align-text: right;
  width: 60px;
  margin-right: 30px;

  ${({ theme }) => theme.fonts.bold.normal}
`;

export const Bar = styled.div<{
  color: string;
  leftOffset: number;
  percentage: number;
}>`
  border-radius: 2px;
  height: 14px;
  width: ${({ percentage }) => getPx(percentage)};
  background-color: ${({ color }) => color};
  margin-left: ${({ leftOffset }) => getPx(leftOffset)};
  position: relative;

  &:before {
    border-bottom: 1px dashed ${({ theme }) => theme.atlas.grey[300]};
    content: '';
    width: ${({ leftOffset }) => getPx(leftOffset)};
    height: 50%;
    position: absolute;
    left: ${({ leftOffset }) => getPx(-leftOffset)};
    top: 0;
  }
`;

export const Days = styled.div`
  color: ${({ theme }) => theme.atlas.text.secondary};
  padding-left: 8px;
  ${({ theme }) => theme.fonts.bold.small};

  > span {
    ${({ theme }) => theme.basic.fontWeights.regular};
  }
`;
