/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UsersListResponse
 */
export interface UsersListResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof UsersListResponse
     */
    msg: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof UsersListResponse
     */
    entries: Array<User>;
}

export function UsersListResponseFromJSON(json: any): UsersListResponse {
    return UsersListResponseFromJSONTyped(json, false);
}

export function UsersListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entries': ((json['entries'] as Array<any>).map(UserFromJSON)),
    };
}

export function UsersListResponseToJSON(value?: UsersListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entries': ((value.entries as Array<any>).map(UserToJSON)),
    };
}


