import React, { PureComponent } from 'react';

export interface ScrolledToBottomPropsType {
  children: React.ReactNode;
  className?: string;
}

class ScrolledToBottom extends PureComponent<ScrolledToBottomPropsType> {
  el = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    const { current } = this.el;

    current!.scrollTop = current!.scrollHeight;
  }

  render() {
    const { children, className } = this.props;

    return (
      <div ref={this.el} className={className}>
        {children}
      </div>
    );
  }
}

export default ScrolledToBottom;
