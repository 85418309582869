import { Team } from '../../../generated/new/models';

export interface UserSelectTeamActionType {
  type: '@user/SELECT_TEAM';
  payload: {
    team?: Team;
  };
}

export const selectTeam = (team?: Team): UserSelectTeamActionType => ({
  type: '@user/SELECT_TEAM',
  payload: { team },
});
