import { addQuestion } from './addQuestion';
import { FormDefinitionDraftCategoryStatsType } from '../../../common/common.types';
import i18n from '../../../i18n';

export type AddCategoryFunctionType = (
  existingCategories: FormDefinitionDraftCategoryStatsType[],
) => void;

const categoryExists = (
  categories: FormDefinitionDraftCategoryStatsType[],
  categoryName: string,
) => categories.some(({ name }) => name === categoryName);

const getNewCategoryName = (
  existingCategories: FormDefinitionDraftCategoryStatsType[],
): string => {
  const makeNameWithNumber = (x: number) =>
    i18n.t('FormCreator:categoryDefaultTitle', {
      categoryNumber: x,
    });

  let categoryNumber = existingCategories.length + 1;
  while (
    categoryExists(existingCategories, makeNameWithNumber(categoryNumber))
  ) {
    categoryNumber++;
  }

  return makeNameWithNumber(categoryNumber);
};

export const addCategory = (
  existingCategories: FormDefinitionDraftCategoryStatsType[],
) => addQuestion(getNewCategoryName(existingCategories));
