import * as R from 'ramda';
import i18n from '../../../i18n';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { Uuid } from '../../../common/common.types';
import {
  DeleteEvidenceDetailResourceRequest,
  EvidenceDeleted,
} from '../../../generated/new';
import evidencesApi from '../../../api/new/evidencesApi';

export type FormsDeleteAttachmentActionType = OptimisticActionType<
  '@forms/API/DELETE_ATTACHMENT',
  ApiCallActionPayloadType<
    DeleteEvidenceDetailResourceRequest,
    EvidenceDeleted,
    never,
    FormsStateType
  >
>;

export const deleteAttachment = (
  id: Uuid,
): FormsDeleteAttachmentActionType => ({
  type: '@forms/API/DELETE_ATTACHMENT',
  payload: {
    callParams: { evidenceId: id },
    endpointPath: params => evidencesApi.deleteEvidenceDetailResource(params),
    expectedStateChange: {
      'forms.currentForm.attachments': R.dissoc(id.toString()),
    },
    selector: (data, state): FormsStateType =>
      R.dissocPath(['currentForm', 'attachments', data.id], state),
    postActions: [
      () => call(() => trackEvent('Attachment', 'Delete - Form')),
      () => put(flash(i18n.t('common:responses.fileDeleteSuccess'))),
    ],
  },
});
