/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { FormTrackSeenResource } from "./FormTrackSeenResource";

/**
 * 
 * @export
 * @interface FormSeen
 */
export interface FormSeen {
    /**
     * restrict scope to a single block (optional)
     * @type {string}
     * @memberof FormSeen
     */
    block?: FormSeenBlockEnum;
    /**
     * restrict scope to a single question (optional)
     * @type {string}
     * @memberof FormSeen
     */
    question_id?: string;
    /**
     * mark form as read/unread (optional)
     * @type {boolean}
     * @memberof FormSeen
     */
    read?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormSeen
     */
    form_id?: string;
}




/**
* @export
* @enum {string}
*/
export enum FormSeenBlockEnum {
    Attachments = 'attachments',
    Comments = 'comments',
    SscData = 'ssc_data',
    History = 'history',
    All = 'all'
}


