import styled from 'styled-components/macro';

type WrapperProps = {
  background: string;
};

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  right: 0px;
  padding-right: 10px;
  background: ${({ background }) => background};
  width: auto;
  margin-left: 8px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-self: center;

  > * {
    margin: 4px;
  }
`;

export const Action = styled.button`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-content: left;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 3px 0;
  margin: 0;
  ${({ theme }) => theme.fonts.light.mini}
  color: ${({ theme }) => theme.basic.colors.notificationButton};

  svg {
    width: 26px !important;
    height: 11px;
  }

  &:hover {
    color: ${({ theme }) => theme.basic.colors.extraDark};
  }

  > span {
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`;
