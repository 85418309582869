/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface PurgeTestRequestsData
 */
export interface PurgeTestRequestsData {
    /**
     * Start of the time range, in which the requests that were created after this
     * timestamp will be deleted.
     * @type {Date}
     * @memberof PurgeTestRequestsData
     */
    from?: Date;
    /**
     * Requests that were sent by this user and received by `target_user` will be deleted
     * @type {string}
     * @memberof PurgeTestRequestsData
     */
    source_user_id: string;
    /**
     * Standard id of the requests that needs to be deleted. If this is not provided,
     * the API will still pursue to delete ALL requests that were sent and received between
     * the `source_user` and `target_user` respectively.
     * @type {string}
     * @memberof PurgeTestRequestsData
     */
    standard_id?: string;
    /**
     * Current request status
     * @type {string}
     * @memberof PurgeTestRequestsData
     */
    status?: PurgeTestRequestsDataStatusEnum;
    /**
     * Requests that were received by this user and sent by `source_user` will be deleted
     * @type {string}
     * @memberof PurgeTestRequestsData
     */
    target_user_id: string;
    /**
     * End of the time range, in which the requests that were created before this
     * timestamp will be deleted.
     * @type {Date}
     * @memberof PurgeTestRequestsData
     */
    to?: Date;
}




/**
* @export
* @enum {string}
*/
export enum PurgeTestRequestsDataStatusEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}


