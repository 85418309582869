import React, { ReactNode } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Overlay, Text } from './Loading.styles';
import { VerticallyCentered } from 'common/common.styles';
import AtlasSpinner from './AtlasSpinner';

interface LoadingPropsType {
  scrollable?: boolean;
  text?: string | ReactNode;
}

const Loading = ({ scrollable, text }: LoadingPropsType) => {
  const loader = (
    <div>
      <AtlasSpinner height={100} width={100} />

      {text && (
        <Text data-cy="loadingText">
          {React.isValidElement(text) ? text : ReactHtmlParser(text)}
        </Text>
      )}
    </div>
  );

  return (
    <Overlay scrollable={scrollable}>
      {scrollable ? loader : <VerticallyCentered>{loader}</VerticallyCentered>}
    </Overlay>
  );
};

export default Loading;
