/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Standard,
    StandardFromJSON,
    StandardFromJSONTyped,
    StandardToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompletedStandard
 */
export interface CompletedStandard {
    /**
     * 
     * @type {boolean}
     * @memberof CompletedStandard
     */
    alreadyRequested: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CompletedStandard
     */
    completedAt: Date;
    /**
     * 
     * @type {Standard}
     * @memberof CompletedStandard
     */
    standard: Standard;
}

export function CompletedStandardFromJSON(json: any): CompletedStandard {
    return CompletedStandardFromJSONTyped(json, false);
}

export function CompletedStandardFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompletedStandard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alreadyRequested': json['already_requested'],
        'completedAt': (new Date(json['completed_at'])),
        'standard': StandardFromJSON(json['standard']),
    };
}

export function CompletedStandardToJSON(value?: CompletedStandard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'already_requested': value.alreadyRequested,
        'completed_at': (value.completedAt.toISOString()),
        'standard': StandardToJSON(value.standard),
    };
}


