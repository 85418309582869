/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormDefinition
 */
export interface FormDefinition {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof FormDefinition
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof FormDefinition
     */
    updatedAt: Date;
    /**
     * Status for form definition initialization (creation).
     * @type {string}
     * @memberof FormDefinition
     */
    creationStatus: FormDefinitionCreationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FormDefinition
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinition
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinition
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinition
     */
    ownerId: string;
    /**
     * ID of my own preview form (if available)
     * @type {string}
     * @memberof FormDefinition
     */
    previewFormId?: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinition
     */
    standardId: string;
    /**
     * 
     * @type {number}
     * @memberof FormDefinition
     */
    withConditionCount: number;
}

export function FormDefinitionFromJSON(json: any): FormDefinition {
    return FormDefinitionFromJSONTyped(json, false);
}

export function FormDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'creationStatus': json['creation_status'],
        'description': json['description'],
        'id': json['id'],
        'name': json['name'],
        'ownerId': json['owner_id'],
        'previewFormId': !exists(json, 'preview_form_id') ? undefined : json['preview_form_id'],
        'standardId': json['standard_id'],
        'withConditionCount': json['with_condition_count'],
    };
}

export function FormDefinitionToJSON(value?: FormDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'creation_status': value.creationStatus,
        'description': value.description,
        'id': value.id,
        'name': value.name,
        'owner_id': value.ownerId,
        'preview_form_id': value.previewFormId,
        'standard_id': value.standardId,
        'with_condition_count': value.withConditionCount,
    };
}

/**
* @export
* @enum {string}
*/
export enum FormDefinitionCreationStatusEnum {
    FINISHEDFAIL = 'FINISHED_FAIL',
    FINISHEDSUCCESS = 'FINISHED_SUCCESS',
    INPROGRESS = 'IN_PROGRESS',
    INQUEUE = 'IN_QUEUE'
}


