import React from 'react';
import config, { Env } from '../config';
import { GlobalStyle } from 'common/common.styles';
import {
  Button,
  InputField,
  LogoWrapper,
  LoginForm,
  LoginTitle,
} from 'components/Login';
import { LOGIN } from '../routes';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { login as loginAction } from 'store/user/actions/login';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { ActionType } from 'store/store.types';

interface LoginPropsType extends TranslatePropsType {
  login: (username: string, password: string) => void;
}

const Login = ({ login, t }: LoginPropsType) => {
  const username = React.createRef<HTMLInputElement>();
  const password = React.createRef<HTMLInputElement>();

  const handleLogin = () => {
    if (username.current && password.current) {
      login(username.current.value, password.current.value);
    }
  };

  if (config.env === Env.PROD) {
    return null;
  }

  return (
    <>
      <GlobalStyle />

      <LogoWrapper>
        <img src="/img/login-logo.png" alt="logo" />
      </LogoWrapper>

      <LoginForm>
        <LoginTitle>{t('logIn') as string}</LoginTitle>

        <InputField
          placeholder={t('username') as string}
          ref={username}
          type="text"
        />

        <InputField
          placeholder={t('password') as string}
          ref={password}
          type="password"
        />

        <Button onClick={handleLogin}>{t('signIn') as string}</Button>

        <a className="button" href={LOGIN}>
          {t('ssc') as string}
        </a>
      </LoginForm>
    </>
  );
};

const mapDispatch = (dispatch: Dispatch<ActionType>) => ({
  login: (username: string, password: string) =>
    dispatch(loginAction({ username, password })),
});

export default connect(null, mapDispatch)(withTranslation('Login')(Login));
