import styled, { css } from 'styled-components';

export const Link = styled.a<{ primary?: boolean; disabled?: boolean }>`
  display: inline-block;
  background: ${({ theme, primary }) =>
    primary ? theme.basic.colors.atlas : theme.basic.colors.notification};
  min-width: 180px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  color: ${({ theme }) => theme.basic.colors.light};
  text-align: center;
  text-decoration: none;
  margin: 20px 0;
  padding: 0 5px;

  svg {
    margin-right: 10px;
    opacity: 0;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
          background: ${({ theme }) => theme.basic.colors.disabled};
          cursor: default;
        `
      : null};
`;

export const Section = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.basic.colors.light};
  border-radius: 8px;
  padding: 20px;
`;

export const Table = styled.table`
  table-layout: fixed;
  width: 100%;

  th {
    ${({ theme }) => theme.fonts.semibold.normal}
    background-color: ${({ theme }) => theme.atlas.teal[100]};
    padding: 10px;
  }

  td {
    padding: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.atlas.teal[200]};
  }

  tbody tr:last-child td {
    border-bottom: none;
  }
`;
