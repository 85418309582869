import * as sa from '../store/standard/actions';
import { UnpublishStandardFromFormDefinitionsParamsType } from '../store/formDefinitions/actions';
import urlize from '../utils/url/urlize';
import axios from 'axios';
import isObjectEmpty from '../utils/isObjectEmpty';
import multipartHeaders from '../utils/url/multipartHeaders';
import withUrl from '../utils/url/withUrl';

const getStandardsURL = ({
  ownerId,
  status,
  sort,
  limit,
}: sa.GetStandardsParamsType) => {
  const companyId = ownerId ? ownerId : 'current';
  const hp = status === 'custom' ? 0 : undefined;

  const params = { status, hp, sort, limit };

  const joinedParams = !isObjectEmpty(params) ? `?${urlize(params)}` : '';

  return `companies/${companyId}/standards${joinedParams}`;
};

export default {
  delete: ({ id }: sa.StandardsDeleteParamsType) =>
    axios.delete(withUrl('standards', id)),
  list: (params: sa.GetStandardsParamsType) =>
    axios.get(withUrl(getStandardsURL(params))),
  rename: ({ id, name, version }: sa.RenameStandardParamsType) =>
    axios.put(withUrl('standards', id), { name, version }),
  save: ({ id, reqData }: sa.SaveStandardCallParamsType) =>
    axios.put(withUrl('definitions', id), reqData),
  transfer: (params: sa.TransferStandardsCallParamsType) =>
    axios.post(withUrl('standards/transfers'), params),
  unpublish: (params: UnpublishStandardFromFormDefinitionsParamsType) =>
    axios.post(withUrl(`standards/${params.standard_id}/unpublish`)),
  upload: (params: FormData) =>
    axios.post(withUrl('definitions'), params, multipartHeaders),
};
