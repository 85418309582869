import styled from 'styled-components';
import TextArea from 'components/common/Textarea';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  max-height: 650px;
  margin-top: 40px;
  caret-color: transparent;
  border-radius: 4px;
`;

export const ModalHeader = styled.div`
  display: flex;
  background-color: white;
  padding: 30px;
  border-radius: 4px;
`;

export const ModalTitle = styled.div`
  margin-left: 0;
  margin-right: 200px;
  font-weight: bold;
`;

export const ModalHeaderSeparator = styled.div`
  background-color: ${({ theme }) => theme.atlas.grey[200]};
  padding: 1px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  overflow-y: auto;
  border-radius: 4px;
`;

export const RadioContainer = styled.div`
  margin-bottom: 25px;
`;

export const ModalRadioGroup = styled.div`
  padding-top: 10px;
  padding: 25px;
`;

export const ModalAssessmentText = styled.div`
  padding-top: 10px;
  background-color: ${({ theme }) => theme.atlas.grey[50]};
  color: ${({ theme }) => theme.atlas.grey[700]};
  padding: 25px;
`;

export const ReasonText = styled.input.attrs({ type: 'text' })`
  margin-left: 10px;
  margin-top: -7px;
  height: 34px;
  width: 350px;
  border: 1px solid ${({ theme }) => theme.atlas.grey[200]};
  border-radius: 5px;
  padding: 10px;
  color: ${({ theme }) => theme.atlas.grey[700]};
  caret-color: ${({ theme }) => theme.atlas.grey[700]};
`;

export const ReasonTextArea = styled(TextArea)`
  caret-color: ${({ theme }) => theme.atlas.grey[700]};
`;

export const Link = styled.a`
  text-decoration: none;
  border-bottom: 1px solid #ddd;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  margin-left: 25px;
`;

export const Note = styled.div`
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  margin-bottom: 25px;
  margin-top: 30px;
`;
