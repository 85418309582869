import React from 'react';
import { TextInput, Wrapper } from './NumberResponse.styles';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { ResponseComponentPropsType } from '../Response';

interface NumberResponsePropsType
  extends ResponseComponentPropsType,
    TranslatePropsType {}

const NumberResponse = ({ t }: NumberResponsePropsType) => (
  <Wrapper>
    <TextInput
      isDisabled
      placeholder={t('numberPlaceholder') as string}
      value={''}
    />
  </Wrapper>
);

export default withTranslation('FormCreator')(NumberResponse);
