import React, { memo, useMemo, useState, useRef, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { format } from 'date-fns';
import * as R from 'ramda';
import ReactHtmlParser from 'react-html-parser';
import getLocale from 'utils/getLocale';
import {
  LanguageType,
  StandardType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import { DATE_FORMAT_BASED_ON_LOCALE } from 'common/common.constants';
import { TextButton } from 'common/common.styles';
import Checkbox from 'components/common/Checkbox';

import route, { SEND_REQUEST, FORM_CREATOR } from '../../routes';

import FormDefinitionsActionsIcon from './ActionsMenu';

import ActionsMenu from './ActionsMenu/ActionsMenu';

import { ToggleStandardSelectionFunctionType } from 'store/standard/actions';

import Tooltip from 'components/common/Tooltip';

import {
  RenameStandardFromFormDefinitionsFunctionType,
  DuplicateStandardFromFormDefinitionsFunctionType,
  PublishDraftFromFormDefinitionsFunctionType,
  UnpublishStandardFromFormDefinitionsFunctionType,
  DeleteStandardFromFormDefinitionsParamsType,
  DeleteDraftFromFormDefinitionsParamsType,
  ToggleSelectionsFormDefinitionFunctionType,
} from 'store/formDefinitions/actions';

import {
  getSystemOptions,
  getCustomOptions,
  getDraftOptions,
  OPTION_WIDTH,
  calculateTotalOptionsHeight,
  createOptionsWithClickHandlersAndTooltips,
  ClickHandlerMapType,
  TooltipMapType,
} from './ActionsMenu/optionsConfigs';

import { StandardStatusEnum } from 'generated/models/Standard';

import { useOnClickOutside } from 'utils/useOnClickOutside';

import useOpenDownwards from './ActionsMenu/hooks/useOpenDownwards';

import {
  RowWrapper,
  StandardEditingActions,
  StandardEditingAction,
  StandardTitle,
  StandardTitleInput,
  StandardTitleText,
  DateModified,
  State,
  Cell,
  VerticallyCentered,
  ActionsWrapper,
  SystemStandardIconWrapper,
  SystemStandardIcon,
  SystemStandardVersionAndYear,
  Processing,
  CheckboxWrapper,
} from './FormDefinitionsRow.styles';
import { StateType } from '../../store/store.types';
import { getLanguagePreference } from '../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import { capitalize } from '../../utils/toCamelCase';

export interface FormDefinitionRowPropsType
  extends RouteComponentProps,
    TranslatePropsType {
  data: {
    standard: StandardType;
    isSelected: boolean;
    selected: Uuid[];
    language: LanguageType;
  };
  actions: {
    onPreview: (standard: StandardType) => void;
    deleteFromFormDefinitions: (
      status: StandardStatusEnum,
      params:
        | DeleteStandardFromFormDefinitionsParamsType
        | DeleteDraftFromFormDefinitionsParamsType,
    ) => void;
    toggleStandardAndContinue: ToggleStandardSelectionFunctionType;
    renameStandardFromFormDefinitions: RenameStandardFromFormDefinitionsFunctionType;
    duplicateStandardFromFormDefinitions: DuplicateStandardFromFormDefinitionsFunctionType;
    publishDraftFromFormDefinitions: PublishDraftFromFormDefinitionsFunctionType;
    unpublishStandardFromFormDefinitions: UnpublishStandardFromFormDefinitionsFunctionType;
    toggleFormDefinitionSelection: ToggleSelectionsFormDefinitionFunctionType;
  };
}

export const FormDefinitionRow = ({
  data: { standard, isSelected = false, language },
  actions: {
    onPreview,
    deleteFromFormDefinitions,
    toggleStandardAndContinue,
    renameStandardFromFormDefinitions,
    duplicateStandardFromFormDefinitions,
    publishDraftFromFormDefinitions,
    unpublishStandardFromFormDefinitions,
    toggleFormDefinitionSelection,
  },
  history: { push: navigate },
  t,
}: FormDefinitionRowPropsType) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [nameInputValue, setNameInputValue] = useState<string>(standard.name);

  const [draftStateTooltipVisible, setDraftStateTooltipVisible] = useState<
    boolean
  >(false);

  const [
    draftCheckboxTooltipVisible,
    setDraftCheckboxTooltipVisible,
  ] = useState<boolean>(false);

  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(false);

  const componentVisibleRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(componentVisibleRef, () => setIsComponentVisible(false));

  const nameInputRef = useRef<HTMLDivElement>(null);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
    setNameInputValue(standard.name);
  }, [standard]);

  const handleTextChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setNameInputValue(value),
    [],
  );

  const handleSave = useCallback(() => {
    renameStandardFromFormDefinitions({
      id: standard.id,
      name: nameInputValue,
    });
    setIsEditing(false);
  }, [renameStandardFromFormDefinitions, nameInputValue, standard]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (R.contains(e.key, ['Enter'])) {
        handleSave();
      }
    },
    [handleSave],
  );

  const handleOnRenameClick = useCallback(() => {
    setIsComponentVisible(false);
    setIsEditing(true);
    if (nameInputRef.current) {
      (nameInputRef.current as any).focus();
      (nameInputRef.current as any).select();
    }
  }, []);

  const previewForm = useCallback(() => {
    setIsComponentVisible(false);
    onPreview(standard);
  }, [onPreview, standard]);

  const clickHandlerMap: ClickHandlerMapType = {};
  const tooltipMap: TooltipMapType = {};

  // all can be previewed
  if (standard.previewFormId !== undefined) {
    clickHandlerMap.preview = previewForm;
  }

  // all can be duplicated unless they contain conditional questions
  if (standard.isDuplicable) {
    if (standard.definitionId) {
      clickHandlerMap.duplicate = () => {
        duplicateStandardFromFormDefinitions({
          definition_id: standard.definitionId as Uuid,
          standard_name: standard.name,
        });
      };
    }
  } else {
    tooltipMap.duplicate = {
      translationKey: `cantDuplicate${capitalize(
        `${standard.duplicateState || ''}`,
      )}`,
    };
  }

  if (standard.status === StandardStatusEnum.SYSTEM) {
    clickHandlerMap.send = () => {
      navigate(route(SEND_REQUEST));
      toggleStandardAndContinue({ id: standard.id });
    };
  } else if (standard.status === StandardStatusEnum.CUSTOM) {
    if (standard.isUnpublishable) {
      clickHandlerMap.unpublish = () => {
        unpublishStandardFromFormDefinitions({ standard_id: standard.id });
      };
    } else {
      tooltipMap.unpublish = {
        translationKey: `cantUnpublish${capitalize(
          `${standard.unpublishState || ''}`,
        )}`,
      };
    }

    clickHandlerMap.send = () => {
      navigate(route(SEND_REQUEST));
      toggleStandardAndContinue({ id: standard.id });
    };
    clickHandlerMap.delete = () =>
      deleteFromFormDefinitions(standard.status, { id: [standard.id] });
    clickHandlerMap.rename = () => handleOnRenameClick();
  } else if (standard.status === StandardStatusEnum.DRAFT) {
    if (standard.definitionId !== undefined) {
      clickHandlerMap.edit = () => {
        navigate(route(FORM_CREATOR, { id: standard.definitionId }));
      };
      clickHandlerMap.publish = () => {
        publishDraftFromFormDefinitions({
          draft_id: standard.definitionId as Uuid,
        });
      };
      clickHandlerMap.delete = () => {
        deleteFromFormDefinitions(standard.status, {
          id: [standard.definitionId as Uuid],
        });
      };
    }
    clickHandlerMap.rename = () => handleOnRenameClick();
  }

  // TODO: add the rest of click handlers

  const actionsMenuOptions = getActionsMenuOptionsForStandard(
    standard,
    clickHandlerMap,
    tooltipMap,
  );

  const actionsMenuHeight = useMemo(
    () => calculateTotalOptionsHeight(actionsMenuOptions),
    [actionsMenuOptions],
  );

  const openDownwards = useOpenDownwards(
    componentVisibleRef,
    isComponentVisible,
    actionsMenuHeight,
  );

  const stateText = t('state', { context: standard.status });

  return (
    <RowWrapper data-test="template-row" isProcessing={standard.isProcessing}>
      {standard.isProcessing && <Processing />}

      <Cell
        width={40}
        onMouseEnter={() => setDraftCheckboxTooltipVisible(true)}
        onMouseLeave={() => setDraftCheckboxTooltipVisible(false)}
      >
        <Tooltip
          overlay={ReactHtmlParser(
            t('cannotSelectPublishedTemplate') as string,
          )}
          visible={
            standard.status === 'DRAFT'
              ? false
              : true && draftCheckboxTooltipVisible
          }
        >
          <VerticallyCentered>
            <CheckboxWrapper>
              <Checkbox
                onChange={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  toggleFormDefinitionSelection([
                    standard.definitionId as Uuid,
                  ]);
                }}
                secondaryColor="teal"
                isChecked={isSelected}
                isDisabled={standard.status === 'DRAFT' ? false : true}
              />
            </CheckboxWrapper>
          </VerticallyCentered>
        </Tooltip>
      </Cell>

      <Cell grow>
        <VerticallyCentered>
          <StandardTitle data-test="template-title">
            <Tooltip overlay={standard.name}>
              <StandardTitleText
                isEditing={isEditing}
                onClick={standard.previewFormId ? previewForm : undefined}
                isPreviewable={standard.previewFormId ? true : false}
              >
                {standard.name}
              </StandardTitleText>
            </Tooltip>
            {standard.status === StandardStatusEnum.SYSTEM ? (
              <Tooltip
                overlay={ReactHtmlParser(t('standardQuestionnaire') as string)}
              >
                <SystemStandardIconWrapper>
                  <SystemStandardIcon />
                </SystemStandardIconWrapper>
              </Tooltip>
            ) : null}
            <StandardTitleInput
              ref={nameInputRef as any}
              value={nameInputValue}
              isEditing={isEditing}
              onChange={handleTextChange}
              onKeyPress={handleKeyPress}
            />
            {isEditing ? (
              <StandardEditingActions>
                <StandardEditingAction>
                  <TextButton
                    key={'save'}
                    size={'mini'}
                    color={'atlas'}
                    onClick={handleSave}
                  >
                    {t('renamingAction', { context: 'save' }) as string}
                  </TextButton>
                </StandardEditingAction>
                <StandardEditingAction>
                  <TextButton
                    key={'cancel'}
                    size={'mini'}
                    color={'atlas'}
                    onClick={handleCancelEdit}
                  >
                    {t('renamingAction', { context: 'cancel' }) as string}
                  </TextButton>
                </StandardEditingAction>
              </StandardEditingActions>
            ) : null}
            <SystemStandardVersionAndYear isEditing={isEditing}>
              {[standard.version, standard.year].join(' ')}
            </SystemStandardVersionAndYear>
          </StandardTitle>
        </VerticallyCentered>
      </Cell>

      <Cell width={200}>
        <VerticallyCentered>
          <DateModified>
            {format(
              standard.updatedAt,
              DATE_FORMAT_BASED_ON_LOCALE[language.value],
              { locale: getLocale(language) },
            )}
          </DateModified>
        </VerticallyCentered>
      </Cell>
      <Cell
        width={100}
        onMouseEnter={() => setDraftStateTooltipVisible(true)}
        onMouseLeave={() => setDraftStateTooltipVisible(false)}
      >
        {standard.status === StandardStatusEnum.DRAFT ? (
          <Tooltip
            overlay={ReactHtmlParser(t('cantSendUnpublished') as string)}
            visible={draftStateTooltipVisible}
          >
            <VerticallyCentered>
              <State>{stateText}</State>
            </VerticallyCentered>
          </Tooltip>
        ) : (
          <VerticallyCentered>
            <State>{stateText}</State>
          </VerticallyCentered>
        )}
      </Cell>
      <Cell width={72}>
        <VerticallyCentered>
          <FormDefinitionsActionsIcon
            showActions={() => setIsComponentVisible(true)}
            actionsVisible={isComponentVisible}
          />
        </VerticallyCentered>
      </Cell>
      <ActionsWrapper
        data-test="actions-menu"
        ref={componentVisibleRef}
        width={OPTION_WIDTH}
        height={actionsMenuHeight}
        show={isComponentVisible}
        openDownwards={openDownwards}
      >
        <ActionsMenu options={actionsMenuOptions} />
      </ActionsWrapper>
    </RowWrapper>
  );
};

const getActionsMenuOptionsForStandard = (
  standard: StandardType,
  clickHandlerMap: ClickHandlerMapType,
  tooltipMap: TooltipMapType,
) => {
  // let actionsMenuOptions: OptionConfigType[];
  switch (standard.status) {
    case StandardStatusEnum.SYSTEM:
      return createOptionsWithClickHandlersAndTooltips(
        getSystemOptions(standard),
        clickHandlerMap,
        tooltipMap,
      );
    case StandardStatusEnum.CUSTOM:
      return createOptionsWithClickHandlersAndTooltips(
        getCustomOptions(standard),
        clickHandlerMap,
        tooltipMap,
      );
    case StandardStatusEnum.DRAFT:
      return createOptionsWithClickHandlersAndTooltips(
        getDraftOptions(standard),
        clickHandlerMap,
        tooltipMap,
      );
    default:
      throw new Error(`Invalid status code: ${standard.status}`);
  }
};
const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});
export default memo(
  connect(
    mapStateToProps,
    null,
  )(withRouter(withTranslation('FormDefinitions')(FormDefinitionRow))),
);
