import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { Button as OrigButton } from '../../../common/Button/Button.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface ProgressPropsType {
  percentage: number;
}

export const Text = styled.p`
  ${({ theme }) => theme.fonts.regular.normal}
  transition: opacity 0.5s;
`;

export const Wrapper = styled.div`
  > p + p {
    margin-top: 9px;
  }
`;

export const Link = styled(RouterLink)`
  text-decoration-line: underline;
  color: ${({ theme }) => theme.atlas.teal.primary};
  ${({ theme }) => theme.basic.fontWeights.bold}
`;

export const ProgressionWrapper = styled.div`
  margin-top: 18px;
`;

export const Button = styled(OrigButton).attrs({
  color: 'teal',
  size: 'small',
})`
  margin-top: 9px;
  ${({ theme }) => theme.fonts.bold.normal}
`;

export const Progress = styled.div<ProgressPropsType>`
  background: ${({ theme }) => theme.atlas.teal['200']};
  border-radius: 6px;
  height: 12px;
  position: relative;
  width: 100%;

  &:before {
    background: ${({ theme }) => theme.atlas.teal.primary};
    border-radius: 6px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: ${({ percentage }) => `${percentage}%`};
    transition: width 0.5s;
  }
`;

export const ProgressText = styled(Text)`
  margin: 9px 0 6px;
  color: ${({ theme }) => theme.atlas.teal.primary};
`;

export const CompletedIcon = styled(FontAwesomeIcon).attrs({
  icon: faCheckCircle as IconProp,
  size: '2x',
})`
  color: ${({ theme }) => theme.atlas.teal.primary};
`;

export const InlineButton = styled.div`
  text-decoration-line: underline;
  color: ${({ theme }) => theme.atlas.teal.primary};
  cursor: pointer;
  ${({ theme }) => theme.fonts.bold.small}
`;

export const Suggestions = styled.div`
  margin-top: 18px;
  border-radius: 5px;
  background: ${({ theme }) => theme.atlas.teal['100']};
  padding: 16px;
  align-items: center;
  display: flex;

  > div {
    margin-left: 18px;
  }

  ${InlineButton} {
    margin-top: 9px;
  }
`;
