import React from 'react';
import { withTranslation } from 'react-i18next';

import { TranslatePropsType, StandardType, Uuid } from 'common/common.types';

import {
  FormDefinitionsSetSortFunctionType,
  ToggleSelectionsFormDefinitionFunctionType,
} from 'store/formDefinitions/actions';
import {
  SortType,
  SortByType,
  SORT_OPTIONS,
  reverseDirection,
} from 'utils/filters/formDefinitions.sorters';
import { isEmpty } from 'ramda';

import { ActionsWrapper } from '../FormDefinitionsList.styles';
import Checkbox from 'components/common/Checkbox';
import { CheckboxContainer } from 'components/common/Checkbox/Checkbox.styles';

import {
  Column,
  Wrapper,
  SortIcon,
  CheckboxHeader,
} from './FormDefinitionsSorter.styles';

const columnWidths = {
  NAME: undefined,
  UPDATED: 200,
  STATE: 100,
};

interface FormDefinitionsSorterPropsType extends TranslatePropsType {
  setSort: FormDefinitionsSetSortFunctionType;
  sortBy: SortType;
  draftCount: number;
  selectedCount: number;
  standards: StandardType[];
  toggleFormDefinitionSelection: ToggleSelectionsFormDefinitionFunctionType;
  selected: Uuid[];
}

const FormDefinitionsSorter = ({
  setSort,
  sortBy,
  sortBy: { direction },
  t,
  draftCount,
  selectedCount,
  standards,
  toggleFormDefinitionSelection,
  selected,
}: FormDefinitionsSorterPropsType) => {
  const standardIds = standards
    .filter(curr => curr.definitionId && curr.status === 'DRAFT')
    .map(curr => curr.definitionId);

  return (
    <Wrapper>
      <ActionsWrapper>
        <CheckboxContainer>
          <CheckboxHeader>
            <Checkbox
              onChange={event => {
                event.preventDefault();
                event.stopPropagation();
                toggleFormDefinitionSelection(
                  !isEmpty(selected) ? selected : standardIds,
                );
              }}
              isChecked={draftCount > 0 && draftCount === selectedCount}
              isIndeterminate={selectedCount > 0 && selectedCount < draftCount}
              secondaryColor="teal"
              isDisabled={!!!draftCount}
            />
          </CheckboxHeader>
        </CheckboxContainer>
      </ActionsWrapper>
      {SORT_OPTIONS.map((option: SortByType) => (
        <Column
          key={option}
          clickable
          width={columnWidths[option]}
          selected={sortBy.by === option}
          onClick={() =>
            setSort({
              by: option,
              direction:
                sortBy.by === option ? reverseDirection(direction) : 'ASC',
            })
          }
        >
          <span>{t(`sortOptions.${option}`) as string}</span>

          {sortBy.by === option && <SortIcon direction={direction} />}
        </Column>
      ))}
      <Column width={72} />
    </Wrapper>
  );
};

export default withTranslation('FormDefinitions')(FormDefinitionsSorter);
