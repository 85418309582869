import React from 'react';

import {
  DeleteModalFooter,
  DeleteModalHeader,
  Title,
  DeleteModalWrapper,
  DeleteModalButton,
} from 'components/SendRequest/Modals/LinkModal.styles';

import Modal from 'utils/Modal';
import { Content } from './SettingsCobranding.styles';
import { useTranslation } from 'react-i18next';

interface SettingsDeleteLogoType {
  removeLogo: () => void;
  modalId: string;
  hideModal: (modalId: string) => void;
}

const SettingsDeleteLogo: React.FC<SettingsDeleteLogoType> = ({
  removeLogo,
  modalId,
  hideModal,
}) => {
  const dismissModal = () => {
    hideModal(modalId);
  };
  const { t } = useTranslation('Settings');

  const modalProps = {
    modalId: modalId,
  };
  return (
    <Modal {...modalProps}>
      <DeleteModalWrapper>
        <DeleteModalHeader>
          <Title>{t('cobrandig.delete.title') as string}</Title>
        </DeleteModalHeader>
        <Content>{t('cobrandig.delete.content') as string}</Content>
        <DeleteModalFooter>
          <DeleteModalButton onClick={dismissModal} color="transparent">
            {t('cobrandig.delete.cancel') as string}
          </DeleteModalButton>

          <DeleteModalButton
            onClick={() => {
              removeLogo();
              hideModal(modalId);
            }}
            data-pendo="delete-logo"
          >
            {t('cobrandig.delete.confirm') as string}
          </DeleteModalButton>
        </DeleteModalFooter>
      </DeleteModalWrapper>
    </Modal>
  );
};

export default SettingsDeleteLogo;
