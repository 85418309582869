import styled, { css } from 'styled-components';

interface ColumnType {
  clickable?: boolean;
  width?: number;
  selected?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Column = styled.div<ColumnType>`
  align-items: flex-end;
  color: ${({ theme }) => theme.basic.colors.black}
  display: flex;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  line-height: 1;
  ${({ width }) =>
    !width
      ? css`
          flex-grow: 1;
        `
      : ''};
  padding: 18px 0;
  ${({ width }) => (width ? `width: ${width}px` : null)};
  opacity: ${({ selected }) => (selected ? 1 : 0.7)};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

  &:hover {
    opacity: 1;
  }
`;

export const Label = styled.span`
  padding-left: 8px;
`;

export const ToolTipWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 3px;
  top: -1.5px;
`;
