import React, { memo, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { format } from 'date-fns';

import {
  LanguageType,
  StandardType,
  TranslatePropsType,
} from 'common/common.types';
import { DATE_FORMAT_BASED_ON_LOCALE } from 'common/common.constants';
import {
  RowWrapper,
  StandardTitle,
  StandardTitleText,
  SystemStandardVersionAndYear,
  DateModified,
  Cell,
  VerticallyCentered,
} from './FormNewFromExistingRow.styles';
import getLocale from '../../utils/getLocale';
import { StateType } from '../../store/store.types';
import { getLanguagePreference } from '../../store/settings/settings.selectors';
import { connect } from 'react-redux';

export interface FormNewFromExistingRowPropsType
  extends RouteComponentProps,
    TranslatePropsType {
  data: {
    standard: StandardType;
  };
  actions: {
    onPreview: (standard: StandardType) => void;
  };
  language?: LanguageType;
}

export const FormNewFromExistingRow = ({
  data: { standard },
  actions: { onPreview },
  history: { push: navigate },
  language,
  t,
}: FormNewFromExistingRowPropsType) => {
  const previewForm = useCallback(() => {
    onPreview(standard);
  }, [onPreview, standard]);

  return (
    <RowWrapper>
      <Cell grow>
        <VerticallyCentered>
          <StandardTitle>
            <StandardTitleText onClick={previewForm}>
              {standard.name}
            </StandardTitleText>
            <SystemStandardVersionAndYear>
              {[standard.version, standard.year].join(' ')}
            </SystemStandardVersionAndYear>
          </StandardTitle>
        </VerticallyCentered>
      </Cell>

      <Cell width={160}>
        <VerticallyCentered>
          <DateModified>
            {language &&
              format(
                standard.updatedAt,
                DATE_FORMAT_BASED_ON_LOCALE[language.value],
                { locale: getLocale(language) },
              )}
          </DateModified>
        </VerticallyCentered>
      </Cell>
    </RowWrapper>
  );
};

const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});

export default memo(
  connect(
    mapStateToProps,
    null,
  )(withRouter(withTranslation('FormNewFromExisting')(FormNewFromExistingRow))),
);
