import * as R from 'ramda';
import { getCurrentStateFromPath } from './getCurrentStateFromPath';
import { StateType } from 'store/store.types';

export const saveCurrentStatePartition = (
  keysToChange: any[],
  state: StateType,
) =>
  keysToChange.reduce(
    (backup: object, key: string) =>
      R.assoc(key, getCurrentStateFromPath(key, state), backup),
    {},
  );
