/**
 * Repeatedly tries to find an element with the specified DOM ID.
 *
 * @param id DOM ID of the searched element.
 * @param intervalMs Interval between the existency checks.
 * @param maxRepeat Stop trying after this count of attempts.
 * @param callback Callback that is called if the element is found.
 * @return Function that cancells the search attempts.
 */
const waitForElement = (
  id: string,
  intervalMs: number,
  maxRepeat: number,
  callback: (element: HTMLElement) => void,
) => {
  let count = 0;
  const intervalId = window.setInterval(() => {
    count++;
    const element = document.getElementById(id);
    if (element) {
      clearInterval(intervalId);
      callback(element);
      return;
    }

    if (count >= maxRepeat) {
      clearInterval(intervalId);
      console.warn(
        `Element with id '${id}' not found after ${maxRepeat} attempts.`,
      );
      return;
    }
  }, intervalMs);
  return () => window.clearInterval(intervalId);
};

export default waitForElement;
