import { adjust, assoc, evolve, findIndex, identity, propEq } from 'ramda';
import { put } from 'redux-saga/effects';
import { getStandards } from './getStandards';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { StandardStatusBranchStateType } from '../reducer';
import { Uuid } from '../../../common/common.types';

export type RenameStandardFunctionType = (
  params: RenameStandardParamsType,
) => void;

export interface RenameStandardParamsType {
  id: Uuid;
  name: string;
  version?: string;
}

export type RenameStandardActionType = OptimisticActionType<
  '@standard/API/RENAME',
  ApiCallActionPayloadType<RenameStandardParamsType>
>;

export const renameStandard = ({
  id,
  name,
}: RenameStandardParamsType): RenameStandardActionType => ({
  type: '@standard/API/RENAME',
  payload: {
    endpointPath: ['standards', 'rename'],
    callParams: { id, name },
    expectedStateChange: {
      'standard.all': (state: StandardStatusBranchStateType) => {
        const allCustomIndex = findIndex(propEq('id', id))(state.all);
        const mostRecentIndex = findIndex(propEq('id', id))(state.mostUsed);

        return evolve(
          {
            // @ts-ignore
            all: adjust(assoc('name', name), allCustomIndex),
            // @ts-ignore
            mostRecent:
              mostRecentIndex === -1
                ? identity
                : adjust(
                    // @ts-ignore
                    assoc('name', name),
                    mostRecentIndex,
                  ),
          },
          state,
        );
      },
    },
    selector: null,
    postActions: [
      (_, state) =>
        put(
          getStandards({
            status: state.standard.all.fetchedStatuses,
            sort: 'most_recent',
          }),
        ),
    ],
  },
});
