import styled, { css } from 'styled-components';

const VERTICAL_PADDING = 15;

export const SHADOW = css`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

export interface BarWrapperPropsType {
  border?: boolean;
  shadow?: boolean;
  noVerticalPadding?: boolean;
}

export const BarWrapper = styled.div<BarWrapperPropsType>`
  position: relative;
  background: ${({ theme }) => theme.atlas.white};
  padding-left: 25px;
  ${({ noVerticalPadding }) =>
    noVerticalPadding
      ? css``
      : css`
          padding-top: ${VERTICAL_PADDING}px;
          padding-bottom: ${VERTICAL_PADDING}px;
        `}
  display: flex;
  justify-content: space-between;
  width: 1140px;
  min-height: 70px;
  align-items: center;
  ${({ shadow }) => (shadow ? SHADOW : null)}

  ${({ theme, border }) =>
    border
      ? css`
          border: 1px solid ${theme.basic.colors.darkerBorder};
        `
      : null}

  h2 {
    ${({ theme }) => theme.fonts.semibold.large}
  }

  p {
    ${({ theme }) => theme.fonts.regular.small}
  }
`;

export const BarWrapperVertical = styled(BarWrapper)`
  flex-direction: column;
  align-items: flex-start;
`;
