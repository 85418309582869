import styled from 'styled-components';

type ActionProps = {
  hasEvidenceLockerEntity?: boolean;
};

export const Wrapper = styled.div<{ isHidden: boolean; showHidden: boolean }>`
  ${({ theme }) => theme.fonts.light.normal}
  height: 100px;
  border: solid ${({ theme }) => theme.basic.colors.lightBorder};
  border-width: ${({ isHidden, showHidden }) =>
    isHidden && !showHidden ? 0 : 1}px;
  width: ${({ isHidden, showHidden }) => (isHidden && !showHidden ? 0 : 356)}px;
  margin: ${({ isHidden, showHidden }) => (isHidden && !showHidden ? 0 : 10)}px;
  border-radius: 8px;
  position: relative;
  line-height: 20px;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  box-shadow: 0 0 10px rgba(37, 38, 94, 0.1);
  opacity: ${({ isHidden, showHidden }) => {
    if (isHidden && !showHidden) {
      return 0;
    }
    if (isHidden && showHidden) {
      return 0.4;
    }
    return 1;
  }};
  transition: ${({ theme }) => theme.basic.animations.slow};
`;

export const LeftSide = styled.div`
  width: 25%;
  float: left;
  height: 100%;
  text-align: center;
  position: relative;
`;

export const AssetBody = styled.div`
  ${({ theme }) => theme.fonts.bold.normal}
  width: 75%;
  float: left;
  height: 100%;
  background-color: ${({ theme }) => theme.atlas.white};
`;

export const Actions = styled.div`
  position: absolute;
  right: 10px;
  bottom: 5px;
  text-align: center;

  svg {
    height: 14px;
    margin-bottom: 5px;
  }
`;

export const Action = styled.div<ActionProps>`
  cursor: ${({ hasEvidenceLockerEntity }) =>
    hasEvidenceLockerEntity ? 'not-allowed' : 'pointer'};
  opacity: ${({ hasEvidenceLockerEntity }) =>
    hasEvidenceLockerEntity ? 0.4 : 1};
  padding: 0 0 0 5px;
`;
