/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RequestDetailFormRolesEnum } from './';
import { RequestDetailStatusBeforeArchivedEnum } from './';
import { RequestDetailStatusEnum } from './';
import { RequestDetailVrmViewStatusEnum } from './';
import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    RequestExternalEntityData,
    RequestExternalEntityDataFromJSON,
    RequestExternalEntityDataFromJSONTyped,
    RequestExternalEntityDataToJSON,
    RequestMeta,
    RequestMetaFromJSON,
    RequestMetaFromJSONTyped,
    RequestMetaToJSON,
    RequestStats,
    RequestStatsFromJSON,
    RequestStatsFromJSONTyped,
    RequestStatsToJSON,
    Standard,
    StandardFromJSON,
    StandardFromJSONTyped,
    StandardToJSON,
    Team,
    TeamFromJSON,
    TeamFromJSONTyped,
    TeamToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 *
 * @export
 * @interface RequestSummary
 */
export interface RequestSummary {
    /**
     *
     * @type {number}
     * @memberof RequestSummary
     */
    completionDays?: number;
    /**
     * Alias for form_questions_filled
     * @type {number}
     * @memberof RequestSummary
     */
    formFilled: number;
    /**
     * Portion of visible questions filled
     * @type {number}
     * @memberof RequestSummary
     */
    formPercentageFilled: number;
    /**
     * Number of questions in the form
     * @type {number}
     * @memberof RequestSummary
     */
    formQuestionCount: number;
    /**
     * Number of questions in the form without taking visibility into account
     * @type {number}
     * @memberof RequestSummary
     */
    formQuestionCountIgnoreVisibility: number;
    /**
     * Number of questions that have all responses filled
     * @type {number}
     * @memberof RequestSummary
     */
    formQuestionsCompletelyFilled: number;
    /**
     * Number of questions that are either completely or partially filled
     * @type {number}
     * @memberof RequestSummary
     */
    formQuestionsFilled: number;
    /**
     * Number of questions that have at least one response and not all responses filled
     * @type {number}
     * @memberof RequestSummary
     */
    formQuestionsPartiallyFilled: number;
    /**
     * Number of responses in the form
     * @type {number}
     * @memberof RequestSummary
     */
    formResponseCount: number;
    /**
     * Number of responses in the form without taking visibility into account
     * @type {number}
     * @memberof RequestSummary
     */
    formResponseCountIgnoreVisibility: number;
    /**
     * Number of responses filled in the form
     * @type {number}
     * @memberof RequestSummary
     */
    formResponsesFilled: number;
    /**
     *
     * @type {number}
     * @memberof RequestSummary
     */
    formScore: number;
    /**
     * Visibility flag for form score
     * @type {boolean}
     * @memberof RequestSummary
     */
    formScoreVisible: boolean;
    /**
     * Alias for form_question_count
     * @type {number}
     * @memberof RequestSummary
     */
    formTotal: number;
    /**
     * When was the entity created
     * @type {Date}
     * @memberof RequestSummary
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof RequestSummary
     */
    updatedAt: Date;
    /**
     * The day when the VRM accepted the request. This will be set internally and         only when the VRM status changes from in progress to accepted
     * @type {Date}
     * @memberof RequestSummary
     */
    acceptedDate?: Date;
    /**
     * User can view form data
     * @type {boolean}
     * @memberof RequestSummary
     */
    canViewData: boolean;
    /**
     *
     * @type {string}
     * @memberof RequestSummary
     */
    code: string;
    /**
     *
     * @type {Date}
     * @memberof RequestSummary
     */
    dueDate?: Date;
    /**
     *
     * @type {string}
     * @memberof RequestSummary
     */
    formId: string;
    /**
     *
     * @type {string}
     * @memberof RequestSummary
     */
    formName: string;
    /**
     *
     * @type {Array<string>}
     * @memberof RequestSummary
     */
    formRoles: Array<RequestDetailFormRolesEnum>;
    /**
     *
     * @type {number}
     * @memberof RequestSummary
     */
    historyCount: number;
    /**
     *
     * @type {string}
     * @memberof RequestSummary
     */
    id: string;
    /**
     *
     * @type {boolean}
     * @memberof RequestSummary
     */
    isInternal: boolean;
    /**
     *
     * @type {string}
     * @memberof RequestSummary
     */
    message: string;
    /**
     *
     * @type {number}
     * @memberof RequestSummary
     */
    revisionCount: number;
    /**
     *
     * @type {string}
     * @memberof RequestSummary
     */
    riskNote: string;
    /**
     *
     * @type {Standard}
     * @memberof RequestSummary
     */
    standard: Standard;
    /**
     * Current request status
     * @type {string}
     * @memberof RequestSummary
     */
    status: RequestDetailStatusEnum;
    /**
     * Status of the request after it will be unarchived
     * @type {string}
     * @memberof RequestSummary
     */
    statusBeforeArchived: RequestDetailStatusBeforeArchivedEnum;
    /**
     * When the request was first opened by target (vendor).
     * @type {Date}
     * @memberof RequestSummary
     */
    targetOpenedAt?: Date;
    /**
     *
     * @type {boolean}
     * @memberof RequestSummary
     */
    wasScheduled: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestSummary
     */
    isVendor: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestSummary
     */
    isVrm: boolean;
    /**
     *
     * @type {Company}
     * @memberof RequestSummary
     */
    source: Company;
    /**
     *
     * @type {Team}
     * @memberof RequestSummary
     */
    sourceTeam?: Team;
    /**
     *
     * @type {User}
     * @memberof RequestSummary
     */
    sourceUser: User;
    /**
     *
     * @type {Company}
     * @memberof RequestSummary
     */
    target: Company;
    /**
     *
     * @type {Team}
     * @memberof RequestSummary
     */
    targetTeam?: Team;
    /**
     *
     * @type {User}
     * @memberof RequestSummary
     */
    targetUser: User;
    /**
     * Current vrm view status
     * @type {string}
     * @memberof RequestSummary
     */
    vrmViewStatus: RequestDetailVrmViewStatusEnum;
}

export function RequestSummaryFromJSON(json: any): RequestSummary {
    return RequestSummaryFromJSONTyped(json, false);
}

export function RequestSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'completionDays': !exists(json, 'completion_days') ? undefined : json['completion_days'],
        'formFilled': json['form_filled'],
        'formPercentageFilled': json['form_percentage_filled'],
        'formQuestionCount': json['form_question_count'],
        'formQuestionCountIgnoreVisibility': json['form_question_count_ignore_visibility'],
        'formQuestionsCompletelyFilled': json['form_questions_completely_filled'],
        'formQuestionsFilled': json['form_questions_filled'],
        'formQuestionsPartiallyFilled': json['form_questions_partially_filled'],
        'formResponseCount': json['form_response_count'],
        'formResponseCountIgnoreVisibility': json['form_response_count_ignore_visibility'],
        'formResponsesFilled': json['form_responses_filled'],
        'formScore': json['form_score'],
        'formScoreVisible': json['form_score_visible'],
        'formTotal': json['form_total'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'acceptedDate': !exists(json, 'accepted_date') ? undefined : (new Date(json['accepted_date'])),
        'canViewData': json['can_view_data'],
        'code': json['code'],
        'dueDate': !exists(json, 'due_date') ? undefined : (new Date(json['due_date'])),
        'formId': json['form_id'],
        'formName': json['form_name'],
        'formRoles': json['form_roles'],
        'historyCount': json['history_count'],
        'id': json['id'],
        'isInternal': json['is_internal'],
        'message': json['message'],
        'revisionCount': json['revision_count'],
        'riskNote': json['risk_note'],
        'standard': StandardFromJSON(json['standard']),
        'status': json['status'],
        'statusBeforeArchived': json['status_before_archived'],
        'targetOpenedAt': !exists(json, 'target_opened_at') ? undefined : (new Date(json['target_opened_at'])),
        'wasScheduled': json['was_scheduled'],
        'isVendor': json['is_vendor'],
        'isVrm': json['is_vrm'],
        'source': CompanyFromJSON(json['source']),
        'sourceTeam': !exists(json, 'source_team') ? undefined : TeamFromJSON(json['source_team']),
        'sourceUser': UserFromJSON(json['source_user']),
        'target': CompanyFromJSON(json['target']),
        'targetTeam': !exists(json, 'target_team') ? undefined : TeamFromJSON(json['target_team']),
        'targetUser': UserFromJSON(json['target_user']),
        'vrmViewStatus': json['vrm_view_status'],
    };
}

export function RequestSummaryToJSON(value?: RequestSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'completion_days': value.completionDays,
        'form_filled': value.formFilled,
        'form_percentage_filled': value.formPercentageFilled,
        'form_question_count': value.formQuestionCount,
        'form_question_count_ignore_visibility': value.formQuestionCountIgnoreVisibility,
        'form_questions_completely_filled': value.formQuestionsCompletelyFilled,
        'form_questions_filled': value.formQuestionsFilled,
        'form_questions_partially_filled': value.formQuestionsPartiallyFilled,
        'form_response_count': value.formResponseCount,
        'form_response_count_ignore_visibility': value.formResponseCountIgnoreVisibility,
        'form_responses_filled': value.formResponsesFilled,
        'form_score': value.formScore,
        'form_score_visible': value.formScoreVisible,
        'form_total': value.formTotal,
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'accepted_date': value.acceptedDate === undefined ? undefined : (value.acceptedDate.toISOString()),
        'can_view_data': value.canViewData,
        'code': value.code,
        'due_date': value.dueDate === undefined ? undefined : (value.dueDate.toISOString()),
        'form_id': value.formId,
        'form_name': value.formName,
        'form_roles': value.formRoles,
        'history_count': value.historyCount,
        'id': value.id,
        'is_internal': value.isInternal,
        'message': value.message,
        'revision_count': value.revisionCount,
        'risk_note': value.riskNote,
        'standard': StandardToJSON(value.standard),
        'status': value.status,
        'status_before_archived': value.statusBeforeArchived,
        'target_opened_at': value.targetOpenedAt === undefined ? undefined : (value.targetOpenedAt.toISOString()),
        'was_scheduled': value.wasScheduled,
        'is_vendor': value.isVendor,
        'is_vrm': value.isVrm,
        'source': CompanyToJSON(value.source),
        'source_team': TeamToJSON(value.sourceTeam),
        'source_user': UserToJSON(value.sourceUser),
        'target': CompanyToJSON(value.target),
        'target_team': TeamToJSON(value.targetTeam),
        'target_user': UserToJSON(value.targetUser),
        'vrm_view_status': value.vrmViewStatus,
    };
}

/**
* @export
* @enum {string}
*/
export enum RequestSummaryFormRolesEnum {
    OWNER = 'OWNER',
    VENDOR = 'VENDOR',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY',
    OTHER = 'OTHER'
}
/**
* @export
* @enum {string}
*/
export enum RequestSummaryStatusEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}
/**
* @export
* @enum {string}
*/
export enum RequestSummaryStatusBeforeArchivedEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}
/**
* @export
* @enum {string}
*/
export enum RequestSummaryVrmViewStatusEnum {
    VENDORAPPROVED = 'VENDOR_APPROVED',
    VENDORDECLINED = 'VENDOR_DECLINED',
    VENDORINITIAL = 'VENDOR_INITIAL',
    VRMREQUESTED = 'VRM_REQUESTED'
}


