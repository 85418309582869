import styled from 'styled-components';

export const TagsWrapper = styled.span`
  display: flex;
  justify-content: left;
  align-self: center;
  width: 600px;
  margin-right: 0;
  flex-wrap: wrap;
`;

export const AddButton = styled.div`
  cursor: pointer;
  margin: 0 4px;
  color: ${({ theme }) => theme.basic.colors.dark};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  transition: ${({ theme }) => theme.basic.animations.normal};

  &:hover {
    color: ${({ theme }) => theme.basic.colors.light};
  }
`;

export const AutocompleteWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 196px;
  height: 24px;
`;
