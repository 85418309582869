import styled from 'styled-components';
import { Flex } from 'common/common.styles';

export const SpaceAround = styled.div`
  padding: 11px 0;
  background: ${({ theme }) => theme.basic.colors.background};
  /* Top border for the filters bar below. It is here to prevent displaying of
  two borders on top of each other when FormAttachments area is collapsed. */
  border-bottom: 1px solid ${({ theme }) => theme.basic.colors.darkerBorder};
`;

interface WrapperType {
  grey: boolean;
  hasBackground: boolean;
}

export const Wrapper = styled.div<WrapperType>`
  ${({ theme }) => theme.fonts.bold.small}
  padding: ${({ grey }) => (grey ? '10px' : '0')} 55px;
  line-height: 28px;
  background: ${({ grey, hasBackground, theme }) =>
    hasBackground
      ? theme.basic.colors.light
      : grey
      ? theme.atlas.grey[100]
      : 'transparent'};

  div {
    ${({ theme }) => theme.basic.fontWeights.regular}
  }
`;

export const ButtonText = styled.strong`
  ${({ theme }) => theme.basic.fontWeights.semibold};
  color: ${({ theme }) => theme.basic.colors.extraDark};
  text-decoration: underline;
`;

export const InnerFlex = styled(Flex)`
  flex-grow: 1;
  margin: 4px -10px 0;
  padding: 0;
`;

export const Attachment = styled.div<{ strong: boolean; inline: boolean }>`
  ${({
    strong,
    theme: {
      basic: {
        fontWeights: { light, bold },
      },
    },
  }) => (strong ? bold : light)};
  color: ${({ theme }) => theme.basic.colors.extraDark};
  padding: ${({ inline }) => (inline ? '0 10px' : 'initial')};
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
`;

export const Message = styled.div`
  ${({ theme }) => theme.basic.fontWeights.regular}
  font-style: italic;
  padding: 0 10px;
  min-width: 15em;
`;
