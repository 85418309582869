import React, { useCallback, useRef, useState } from 'react';
import { useDrop } from 'react-dnd-cjs';
import Tile from '../QuestionTile';
import Expand from 'react-expand-animated';
import { Vertical } from 'common/common.styles';
import * as R from 'ramda';
import {
  Uuid,
  FormCreatorRenderType,
  FormCreatorValidatedQuestionType,
} from 'common/common.types';
import update from 'immutability-helper';
import { MoveTypeEnum } from '../FormCreator';
import { SetOpenedQuestionFunctionType } from 'store/formCreator/actions';
import waitForElement from 'utils/dom/waitForElement';
import getOffsetAgainst from 'utils/dom/getOffsetAgainst';

export enum TileTypeEnum {
  CATEGORY = 'Category',
  QUESTION = 'Question',
}

interface NewPositionInfoType {
  source: Uuid;
  destination: Uuid;
  type: TileTypeEnum | string;
}

const initMoved = () => ({
  source: '',
  destination: '',
  type: '',
});

export interface FormOutlinePropsType {
  className?: string;
  renderData: FormCreatorRenderType[];
  onMoveQuestion: (
    questionUuid: Uuid,
    targetQuestionUuid: Uuid,
    type: MoveTypeEnum,
  ) => void;
  onMoveCategory: (
    sourceCat: string,
    destinationCat: string,
    type: MoveTypeEnum,
  ) => void;
  setOpenedQuestion: SetOpenedQuestionFunctionType;
  showOnlyErrors: boolean;
  style?: React.CSSProperties;
}

const FormOutline = ({
  className,
  style,
  renderData,
  onMoveQuestion,
  onMoveCategory,
  setOpenedQuestion,
  showOnlyErrors,
}: FormOutlinePropsType) => {
  const findCategory = useCallback(
    (categoryName: string) =>
      R.findIndex(R.propEq('name', categoryName))(renderData),
    [renderData],
  );

  const movedItems = useRef<NewPositionInfoType>(initMoved());

  const [collapsedCategories, setCollapsedCategories] = useState({});

  const moveQuestion = useCallback(
    (id: Uuid, atIndex: number, atCategory: string) => {
      const toCatIndex = findCategory(atCategory);
      const destinationUuid =
        renderData[toCatIndex].questions[atIndex].wizardId;
      movedItems.current = {
        source: id,
        type: TileTypeEnum.QUESTION,
        destination: destinationUuid,
      };
      onMoveQuestion(
        movedItems.current.source,
        movedItems.current.destination,
        MoveTypeEnum.LOCALLY,
      );
    },
    [renderData, onMoveQuestion, findCategory],
  );

  const scrollTargetWaitCancelRef = useRef<() => void | undefined>();
  const handleQuestionClick = useCallback(
    (question: FormCreatorValidatedQuestionType) => {
      setOpenedQuestion({
        categoryName: question.category,
        questionUuid: question.wizardId,
      });
      if (scrollTargetWaitCancelRef.current) {
        scrollTargetWaitCancelRef.current();
      }
      scrollTargetWaitCancelRef.current = waitForElement(
        `#${question.wizardId}`,
        100,
        40,
        questionEl => {
          const windowOffset = getOffsetAgainst(questionEl);
          window.scrollTo({
            behavior: 'smooth',
            top: windowOffset - 106,
          });
        },
      );
    },
    [setOpenedQuestion],
  );

  const onCategoryClick = (catName: string) => {
    setCollapsedCategories(
      update(collapsedCategories, {
        [catName]: {
          $set: !collapsedCategories[catName],
        },
      }),
    );
  };

  const moveCategory = useCallback(
    (sourceName: string, destinationIndex: number) => {
      movedItems.current = {
        source: sourceName,
        type: TileTypeEnum.CATEGORY,
        destination: renderData[destinationIndex].name,
      };
      onMoveCategory(
        sourceName,
        renderData[destinationIndex].name,
        MoveTypeEnum.LOCALLY,
      );
    },
    [renderData, onMoveCategory],
  );

  const [, drop] = useDrop({
    accept: [TileTypeEnum.QUESTION, TileTypeEnum.CATEGORY],
    drop: () => {
      if (movedItems.current.source !== '') {
        if (movedItems.current.type === TileTypeEnum.QUESTION) {
          onMoveQuestion(
            movedItems.current.source,
            movedItems.current.destination,
            MoveTypeEnum.WITH_API_CALL,
          );
        } else if (movedItems.current.type === TileTypeEnum.CATEGORY) {
          onMoveCategory(
            movedItems.current.source,
            movedItems.current.destination,
            MoveTypeEnum.WITH_API_CALL,
          );
        }
        movedItems.current = initMoved();
      }
    },
  });
  return (
    <Vertical ref={drop} className={className} style={style}>
      {renderData
        .filter(category => category.hasErrors || !showOnlyErrors)
        .map((category: FormCreatorRenderType, i: number) => (
          <div key={i}>
            <div onClick={() => onCategoryClick(category.name)}>
              <Tile
                categoryName={category.name}
                id={category.name}
                moveCategory={moveCategory}
                moveQuestion={moveQuestion}
                panelOpen={!collapsedCategories[category.name]}
                position={i}
                text={category.name}
                type={TileTypeEnum.CATEGORY}
              />
            </div>
            <Expand open={!collapsedCategories[category.name]}>
              {category.questions
                .filter(questions => questions.hasErrors || !showOnlyErrors)
                .map((question, j) => (
                  <div
                    key={question.wizardId}
                    onClick={() => handleQuestionClick(question)}
                  >
                    <Tile
                      categoryName={category.name}
                      code={question.code}
                      id={question.wizardId}
                      moveQuestion={moveQuestion}
                      position={j}
                      text={question.question}
                      type={TileTypeEnum.QUESTION}
                      validationStatus={question.summaryValidationStatus}
                    />
                  </div>
                ))}
            </Expand>
          </div>
        ))}
    </Vertical>
  );
};
export default React.memo(FormOutline);
