import styled, { css } from 'styled-components';
import { getStatusColorStyle } from 'utils/statusColor';
import { BadgeColorType, BadgeSizeType } from './Badge';

export interface BadgeType {
  color?: BadgeColorType;
  size?: BadgeSizeType;
}

export const Badge = styled.span<BadgeType>`
  display: inline-block;
  padding: 3px 10px;
  border-radius: 12px;
  color: #fff;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  margin-left: 5px;
  min-width: 30px;
  text-align: center;

  ${({ color }: { color?: BadgeColorType }) => getStatusColorStyle(color)};

  ${({ size }: { size?: BadgeSizeType }) => {
    switch (size) {
      case 'small':
        return css`
          padding: 1px 6px;
          border-radius: 7px;
          font-size: ${({ theme }) => theme.basic.fontSizes.micro};
          min-width: auto;
          margin-left: 0;
        `;
      case 'big':
        return css`
          padding: 4px 12px;
          border-radius: 14px;
          font-size: 18px;
        `;
      case 'medium':
      default:
        return css`
          padding: 3px 10px;
          border-radius: 12px;
          font-size: ${({ theme }) => theme.basic.fontSizes.small};
        `;
    }
  }};
`;
