import React from 'react';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { ResponseStatus } from 'components/ResponseStatus';
import { connect } from 'react-redux';

interface P403Type extends TranslatePropsType {
  domain: string;
}

const p403 = ({ t, domain }: P403Type) => {
  return (
    <ResponseStatus
      heading={t('P403.heading')}
      caption={t('P403.caption', { domain })}
      goLink={t('P403.link')}
    />
  );
};

const mapStateToProps = (state: any) => ({
  domain: state.user.currentUser.company.domain,
});

export default connect(mapStateToProps)(withTranslation()(p403));
