export default (
  color1: number[],
  color2: number[],
  percentage: number,
): number[] => {
  const w1 = percentage / 100;
  const w2 = 1 - w1;

  return [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
};
