import { ApiCallActionType } from '../../store.types';
import { FormCreatorStateType } from '../formCreator.types';
import { resetForm } from './resetForm';
import { resetForm as resetPreviewForm } from '../../forms/actions';
import { put } from 'redux-saga/effects';
import { FormDefinitionSummaryDraftExtended } from '../../../generated/models/FormDefinitionSummaryDraftExtended';
import { parseFormDefinitionSummaryDraftExtended } from 'utils/parsers/formDefinition.parsers';
import { setCurrentForm } from '../utils';
import { addFirstQuestion } from './addFirstQuestion';
import { Uuid } from '../../../common/common.types';
import {
  FormDefinitionInfoExceptionResponse,
  StandardStatusEnum,
} from '../../../generated/models';
import update from 'immutability-helper';

export interface GetFormParamType {
  id: Uuid;
}

export type GetFormActionType = ApiCallActionType<
  '@formCreator/API/GET_FORM',
  GetFormParamType,
  FormDefinitionSummaryDraftExtended,
  FormDefinitionInfoExceptionResponse,
  FormCreatorStateType
>;

export const getForm = (id: Uuid): GetFormActionType => ({
  type: '@formCreator/API/GET_FORM',
  payload: {
    callParams: { id },
    endpointPath: ['definitions', 'drafts', 'get'],
    preActions: [put(resetForm()), put(resetPreviewForm())],
    selector: (data, state): Partial<FormCreatorStateType> =>
      setCurrentForm(
        state.formCreator,
        parseFormDefinitionSummaryDraftExtended(data),
      ),
    noPopupForErrorStatuses: [409],
    errorSelector: (
      { exception, entry: { standard } },
      { formCreator },
    ): FormCreatorStateType =>
      exception === 'StateConflict'
        ? update(formCreator, {
            currentForm: { $set: undefined },
            resourceGoneState: {
              $set: {
                standard: standard.name,
                kind:
                  standard.status === StandardStatusEnum.DEPRECATED
                    ? 'deleted'
                    : 'published',
              },
            },
          })
        : formCreator,
    postActions: [
      ({ questions }) =>
        !questions || questions.length < 1 ? put(addFirstQuestion()) : null,
    ],
  },
});
