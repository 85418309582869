/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanySearchSummary,
    CompanySearchSummaryFromJSON,
    CompanySearchSummaryFromJSONTyped,
    CompanySearchSummaryToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanySearchSummaryResponse
 */
export interface CompanySearchSummaryResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof CompanySearchSummaryResponse
     */
    msg: string;
    /**
     * 
     * @type {CompanySearchSummary}
     * @memberof CompanySearchSummaryResponse
     */
    entry: CompanySearchSummary;
}

export function CompanySearchSummaryResponseFromJSON(json: any): CompanySearchSummaryResponse {
    return CompanySearchSummaryResponseFromJSONTyped(json, false);
}

export function CompanySearchSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySearchSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': CompanySearchSummaryFromJSON(json['entry']),
    };
}

export function CompanySearchSummaryResponseToJSON(value?: CompanySearchSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': CompanySearchSummaryToJSON(value.entry),
    };
}


