import styled from 'styled-components';
import colors from 'common/common.colors';
import { HEADER_HEIGHT } from 'common/common.styles';

export const Flashes = styled.div`
  position: fixed;
  top: 93px;
  right: 35px;
  z-index: 50000;
`;

export const Footer = styled.div`
  background-color: ${colors.elements.layout.headerAndFooter};
  color: #fff;
  padding: 0 115px;
  height: 111px;
  display: flex;
  align-items: flex-start;

  @media print {
    display: none;
  }
`;

export const FooterItem = styled.div`
  ${({ theme }) => theme.fonts.light.small}
  width: 150px;
  margin-right: 21px;
  align-items: center;
  text-align: center;
  text-decoration: none !important;

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    text-decoration: underline;
  }
`;

export const Main = styled.div`
  background-color: ${({ theme }) => theme.basic.colors.background};
  position: relative;
  min-height: calc(100vh - 111px);
  padding-top: ${HEADER_HEIGHT}px;
  z-index: 50;

  @media print {
    background-color: transparent;
    padding-top: 0;
  }
`;
