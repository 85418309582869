import styled from 'styled-components';

export const Instructions = styled.ul`
  margin-left: 46px;
  list-style-type: none;

  li {
    padding: 6px 0;
    ${({ theme }) => theme.basic.fontSizes.small}
  }

  li:not(:first-of-type) {
    color: ${({ theme }) => theme.atlas.grey['600']};
  }

  a {
    ${({ theme }) => theme.fonts.bold.normal}
  }
`;
