/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormDeleteDelegation
 */
export interface FormDeleteDelegation {
    /**
     * Use to delete delegations from all questions in a category
     * @type {string}
     * @memberof FormDeleteDelegation
     */
    category?: string;
    /**
     * Use to delete delegations from single question
     * @type {string}
     * @memberof FormDeleteDelegation
     */
    questionId?: string;
}

export function FormDeleteDelegationFromJSON(json: any): FormDeleteDelegation {
    return FormDeleteDelegationFromJSONTyped(json, false);
}

export function FormDeleteDelegationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormDeleteDelegation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : json['category'],
        'questionId': !exists(json, 'question_id') ? undefined : json['question_id'],
    };
}

export function FormDeleteDelegationToJSON(value?: FormDeleteDelegation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'question_id': value.questionId,
    };
}


