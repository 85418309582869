import styled, { css } from 'styled-components/macro';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract } from '@fortawesome/free-solid-svg-icons';

import hex2rgba from 'utils/hex2rgba';
import InlineLoading from '../common/InlineLoading';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const STANDARD_HEIGHT = 77;

export const StandardTitle = styled.div`
  display: flex;
  position: relative;
`;

export const StandardTitleRow = styled.div`
  display: flex;
`;

export interface StandardTitleTextProps {
  isEditing: boolean;
  isPreviewable: boolean;
}

export const StandardTitleText = styled.div<StandardTitleTextProps>`
  display: ${({ isEditing }) => (isEditing ? 'none' : 'inline-block')};
  line-height: 20px;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 600px;
  cursor: ${({ isPreviewable }) => (isPreviewable ? 'pointer' : 'default')};
  ${({ theme }) => theme.fonts.semibold.normal}
`;

interface StandardTitleInputProps {
  isEditing?: boolean;
}

export const SystemStandardVersionAndYear = styled.div<StandardTitleInputProps>`
  position: absolute;
  top: 18px;
  color: ${({ theme }) => hex2rgba(theme.atlas.text.secondary, 0.6)};
  letter-spacing: 0.25px;
  line-height: 20px;
  ${({ theme }) => theme.fonts.regular.mini}
  margin-top: ${({ isEditing }) => (isEditing ? '8px' : 0)};
`;

export const SystemStandardIcon = styled(FontAwesomeIcon).attrs({
  icon: faFileContract as IconProp,
})``;

export const SystemStandardIconWrapper = styled.div`
  color: ${({ theme }) => theme.basic.colors.dark};
  ${({ theme }) => theme.fonts.bold.mini};
  line-height: 14px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export const StandardTitleInput = styled.input<StandardTitleInputProps>`
  width: ${({ isEditing }) => (isEditing ? 260 : 0)}px;
  border-radius: 3px;
  border: ${({ isEditing }) => (isEditing ? 1 : 0)}px
    ${({ theme }) => theme.basic.colors.darkerBorder} solid;
  padding: ${({ isEditing }) => (isEditing ? '6px 16px' : 0)};
`;

export const StandardEditingActions = styled.div`
  display: flex;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
`;

export const StandardEditingAction = styled.div`
  margin: 0 5px;
`;

const textProps = css`
  line-height: 17px;
  text-align: right;
  color: ${({ theme }) => theme.atlas.text.primary};
  ${({ theme }) => theme.fonts.regular.small}
`;

export const DateModified = styled.p`
  ${textProps};
`;

export const State = styled.p`
  ${textProps};
`;

type CellType = {
  grow?: boolean;
  width?: number;
  background?: string;
  addBorder?: boolean;
};

export const Cell = styled.div<CellType>`
  display: flex;
  ${({ grow, width }) =>
    grow
      ? css`
          flex-grow: 1;
        `
      : css`
          flex: 0 0 ${width || 100}px;
        `};
  justify-content: space-between;
  height: ${STANDARD_HEIGHT}px;
  padding: 12px 18px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VerticallyCentered = styled.div<{ grow?: boolean }>`
  height: 100%;
  width: ${({ grow }: { grow?: boolean }) => (grow ? '100%' : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface ActionsWrapperProps {
  height: number;
  width: number;
  show: boolean;
  openDownwards: boolean;
}

export const ActionsWrapper = styled.div<ActionsWrapperProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  ${({ height, width }) => css`
    height: ${height}px;
    width: ${width}px;
  `};
  ${({ openDownwards }) =>
    openDownwards
      ? css`
          top: 43px;
        `
      : css`
          bottom: 43px;
        `}
  right: -175px;
  z-index: 200;
`;

export const RowWrapper = styled.div<{ isProcessing?: boolean }>`
  position: relative;
  background-color: white;
  height: 77px;
  display: flex;
  align-items: center;
  padding-left: 32px;
  border: 1px solid ${({ theme }) => theme.atlas.grey[200]};
  border-bottom: 0;

  ${({ isProcessing }) =>
    isProcessing
      ? css`
          > ${Cell}, > ${ActionsWrapper} {
            opacity: 0.3;
          }
        `
      : null}

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.atlas.grey[200]};
  }
`;

export const Processing = styled(InlineLoading)`
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: -32px;
  z-index: 1;
`;

export const CheckboxWrapper = styled.div`
  margin-left: 3px;
  margin-top: 24px;
`;
