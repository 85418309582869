import * as R from 'ramda';
import { put } from 'redux-saga/effects';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { getSettings } from './getSettings';
import { SettingsStateType } from '../settings.reducer';
import { ApiCallActionPayloadType } from '../../store.types';
import renameKeys from 'utils/renameKeys';

export type SettingsSetValueActionType = OptimisticActionType<
  '@settings/API/SET',
  ApiCallActionPayloadType<SettingsStateType>
>;

export type SetSettingsFunctionType = (value: SettingsStateType) => void;

export const setSettings = (
  settingsDict: SettingsStateType,
): SettingsSetValueActionType => ({
  type: '@settings/API/SET',
  payload: {
    endpointPath: ['settings', 'set'],
    expectedStateChange: renameKeys(
      (key: string) => `settings.${key}`,
      settingsDict,
    ),
    callParams: settingsDict,
    selector: null,
    postActions: [() => put(getSettings(R.keys(settingsDict)))],
  },
});
