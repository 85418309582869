import { MessageType, UserType } from 'common/common.types';
import { parseCompany } from './companies.parsers';
import { parseUser } from './user.parsers';
import { EMPTY_ID } from 'common/common.constants';

export const parseMessage = (message: any, author: UserType): MessageType => {
  const {
    id = EMPTY_ID,
    created_at = 0,
    updated_at = 0,
    text = '',
    company = {},
    user = {},
    user_id = '',
  } = message;
  return {
    id,
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    isMine: author.email === user.email || author.id === user_id,
    isInternal: author.domain === user.domain,
    text,
    company: parseCompany(company),
    user: parseUser({ user }),
  };
};
