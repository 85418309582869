/* eslint-disable */
// tslint:disable
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, COLLECTION_FORMATS } from '../runtime';
import {
    CreditsDetailResponse,
    ExceptionResponse,
    SettingsDictionaryResponse,
    UserInfoResponse,
    UserPresentAtlasResponse,
    CreditsDetailResponseFromJSON,
    SettingsDictionaryResponseFromJSON,
    UserInfoResponseFromJSON,
    UserPresentAtlasResponseFromJSON,
} from '../models';
import axios from 'axios';
import withUrl from '../../../utils/url/withUrl';

export interface GetUserInfoResourceRequest {
    includeCreditHistory?: boolean;
}

export interface GetUserSearchByEmailResourceRequest {
    email?: string;
}

export interface GetUserSettingsResourceRequest {
    keys?: Array<string>;
}

/**
 * no description
 */
export class UsersApi extends BaseAPI {

    /**
     * Return information about the currently logged in user\'s credits
     */
    getUserInfoCreditsResource = (): Observable<CreditsDetailResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<CreditsDetailResponse>({
            parser: jsonValue => CreditsDetailResponseFromJSON(jsonValue),
            path: '/atlas/users/current/info/credits',
            method: 'GET',
            headers,
        });
    };

    /**
     * When the response to `GET /users/current/info` states that the user is not fully initialized (as indicated by field `user.internal_status` having value of `MERGING`), the subsequent results from other endpoints may be incomplete (namely `GET /forms/{form_id}`, ...) until user initialization is finished.  It is advised to periodically re-check user status, until `user.internal_status` is `NORMAL`, before querying any other data. The expected time before user initialization finishes depends on number of form filling requests received by the user - from seconds to minutes (in extreme cases).
     * Return information about the currently logged in user
     */
    getUserInfoResource = ({ includeCreditHistory }: GetUserInfoResourceRequest): Observable<UserInfoResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (includeCreditHistory != null) { query['include_credit_history'] = includeCreditHistory; }

        return this.request<UserInfoResponse>({
            parser: jsonValue => UserInfoResponseFromJSON(jsonValue),
            path: '/atlas/users/current/info',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     */
    getUserSearchByEmailResource = ({ email }: GetUserSearchByEmailResourceRequest): Observable<UserPresentAtlasResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (email != null) { query['email'] = email; }

        return this.request<UserPresentAtlasResponse>({
            parser: jsonValue => UserPresentAtlasResponseFromJSON(jsonValue),
            path: '/atlas/users/by-email',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Return current user settings
     */
    getUserSettingsResource = ({ keys }: GetUserSettingsResourceRequest): Observable<SettingsDictionaryResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (keys != null) { query['keys'] = keys.join(COLLECTION_FORMATS['csv']); }

        return this.request<SettingsDictionaryResponse>({
            parser: jsonValue => SettingsDictionaryResponseFromJSON(jsonValue),
            path: '/atlas/users/current/settings',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Update current user settings
     */
    putUserSettingsResource = (): Observable<SettingsDictionaryResponse> => {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<SettingsDictionaryResponse>({
            parser: jsonValue => SettingsDictionaryResponseFromJSON(jsonValue),
            path: '/atlas/users/current/settings',
            method: 'PUT',
            headers,
        });
    };

    putUserMigrationOptIn = (): any => {
        const headers: HttpHeaders = {
          ...(this.configuration.apiKey && {
            Authorization: this.configuration.apiKey('Authorization'),
          }),
        };
        
        return axios.put(
          withUrl('/current/settings/ui-preference', null, 'atlas/users'),
          { enable_platform_ui: true },
          headers,
        );
    }
}
