const errorPlaceholder = {
  status: 901,
  msg: 'An unexpected error occurred.',
};

export enum ApiGeneralStatusEnum {
  SUCCESS,
  ERROR,
}

type InternalApiCallResult<SuccRes, ErrorRes, Meta> = {
  status: number;
  data: {
    entry?: SuccRes | ErrorRes;
    entries?: SuccRes | ErrorRes;
    payload?: SuccRes | ErrorRes;
    meta: Meta;
    msg?: string;
  };
};

type ApiCallResultCommon = {
  status: number;
};

export type ApiCallResult<
  SuccRes,
  ErrorRes = never,
  Meta = never
> = ApiCallResultCommon &
  (
    | {
        generalStatus: ApiGeneralStatusEnum.SUCCESS;
        meta: Meta;
        msg?: string;
        data: SuccRes;
      }
    | {
        generalStatus: ApiGeneralStatusEnum.ERROR;
        meta?: Meta;
        msg: string;
        data?: ErrorRes;
      }
  );

type InternalApiPromise<SuccRes, ErrorRes, Meta> = Promise<
  InternalApiCallResult<SuccRes, ErrorRes, Meta>
>;

/**
 * Calls specified API endpoint, handles exceptions and converts result into an
 * easy-to-process typed structure.
 *
 * const result = await callEndpoint(api.common.get, {param1: true});
 * switch (result.generalStatus) {
 *   case ApiGeneralStatusEnum.SUCCESS:
 *     console.log('success', result.data)'
 *     break;
 *   case ApiGeneralStatusEnum.ERROR:
 *     console.log('error', result.msg);
 *     break;
 * }
 *
 * @param apiCallFun Call function from `api` module
 * @param params Params object that is passed to the `apiCallFun`
 */
export default async function callEndpoint<
  CallParams,
  SuccRes,
  ErrorRes = never,
  Meta = never
>(
  apiCallFun: (
    params: CallParams,
  ) => InternalApiPromise<SuccRes, ErrorRes, Meta>,
  params: CallParams,
): Promise<ApiCallResult<SuccRes, ErrorRes, Meta>> {
  try {
    const {
      status,
      data: { entry, entries, payload, meta, msg },
    }: InternalApiCallResult<SuccRes, ErrorRes, Meta> = await apiCallFun(
      params,
    );

    const entryOrEntries = entry || entries || payload;

    if (status === 200) {
      const payloadSucc = entryOrEntries as SuccRes;

      return {
        generalStatus: ApiGeneralStatusEnum.SUCCESS,
        status,
        meta: meta as Meta,
        msg,
        data: payloadSucc,
      };
    } else {
      const payloadErr = entryOrEntries as ErrorRes;

      return {
        generalStatus: ApiGeneralStatusEnum.ERROR,
        status,
        meta: meta as Meta,
        msg: msg || errorPlaceholder.msg,
        data: payloadErr,
      };
    }
  } catch (error) {
    if (error.response === undefined) {
      error.response = errorPlaceholder;
      error.response.status = error.message;
    }

    const {
      response: { status, data },
    } = error;

    return {
      generalStatus: ApiGeneralStatusEnum.ERROR,
      status: typeof status !== 'undefined' ? status : errorPlaceholder.status,
      msg: (data && data.msg) || errorPlaceholder.msg,
    };
  }
}
