/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyWithTags,
    CompanyWithTagsFromJSON,
    CompanyWithTagsFromJSONTyped,
    CompanyWithTagsToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyPartner
 */
export interface CompanyPartner {
    /**
     * 
     * @type {CompanyWithTags}
     * @memberof CompanyPartner
     */
    companyWithTags: CompanyWithTags;
    /**
     * 
     * @type {number}
     * @memberof CompanyPartner
     */
    requestsReceived: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyPartner
     */
    requestsSent: number;
    /**
     * 
     * @type {Date}
     * @memberof CompanyPartner
     */
     lastRequestSentDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyPartner
     */
     lastRequestSubmittedDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyPartner
     */
     nextRequestScheduledSend: Date;
}

export function CompanyPartnerFromJSON(json: any): CompanyPartner {
    return CompanyPartnerFromJSONTyped(json, false);
}

export function CompanyPartnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyPartner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyWithTags': CompanyWithTagsFromJSON(json['company_with_tags']),
        'requestsReceived': json['requests_received'],
        'requestsSent': json['requests_sent'],
        'lastRequestSentDate': json['last_request_sent_date'],
        'lastRequestSubmittedDate': json['last_request_submitted_date'],
        'nextRequestScheduledSend': json['next_request_scheduled_send'],
    };
}

export function CompanyPartnerToJSON(value?: CompanyPartner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_with_tags': CompanyWithTagsToJSON(value.companyWithTags),
        'requests_received': value.requestsReceived,
        'requests_sent': value.requestsSent,
        'last_request_sent_date': value.lastRequestSentDate,
        'last_request_submitted_date': value.lastRequestSubmittedDate,
        'next_request_scheduled_send': value.nextRequestScheduledSend,
    };
}


