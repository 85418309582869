import React from 'react';
import { PageContextConsumer } from 'pages/Page';
import { withTranslation } from 'react-i18next';
import { Content } from 'common/common.styles';
import { TranslatePropsType } from 'common/common.types';
import { PageHeader } from '../common/PageHeader';
import { BarWrapper } from '../common/Bar/Bar.styles';

const FormDefinitionPreview = ({ t }: TranslatePropsType) => (
  <PageContextConsumer>
    {() => {
      return (
        <>
          <PageHeader
            heading={
              <BarWrapper>{t('formDefinitionPreview') as string}</BarWrapper>
            }
          />
          <Content>{t('pageContent') as string}</Content>
        </>
      );
    }}
  </PageContextConsumer>
);

export default withTranslation('FormDefinitionPreview')(FormDefinitionPreview);
