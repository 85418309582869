import { ApiCallActionType } from '../../store.types';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import { resolveTargets } from './utils';
import { selectFile } from './selectFile';
import i18n from '../../../i18n';
import { Uuid } from '../../../common/common.types';

export interface UserShareFilesCallParamsType {
  evidence_ids: Uuid[];
  target_emails: string[];
  expiration_date?: Date | null;
}

export type UserShareFilesActionType = ApiCallActionType<
  '@user/API/SHARE_FILES',
  UserShareFilesCallParamsType
>;

export const shareFiles = (
  fileIds: Uuid[],
  targets: string[],
  expirationDate: Date | null,
): UserShareFilesActionType => ({
  type: '@user/API/SHARE_FILES',
  payload: {
    endpointPath: ['user', 'shareFiles'],
    callParams: {
      evidence_ids: fileIds,
      target_emails: targets,
      expiration_date: expirationDate,
    },
    preActions: [put(selectFile(undefined))],
    selector: null,
    postActions: [
      () => call(() => trackEvent('Evidence', 'Share')),
      () =>
        put(
          flash(
            i18n.t('common:responses.shareFileSuccess', {
              target: resolveTargets(targets),
            }),
          ),
        ),
    ],
  },
});
