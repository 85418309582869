import React from 'react';
import { Caption, Heading, Icon, Wrapper } from './ResponseStatus.styles';
import { HOMEPAGE } from '../../routes';
import { Link } from 'react-router-dom';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface ResponseStatusProps {
  caption: string;
  children?: any;
  goLink?: string;
  heading: string;
  showIcon?: boolean;
}

const ResponseStatus = ({
  children,
  heading,
  caption,
  goLink,
  showIcon = true,
}: ResponseStatusProps) => (
  <Wrapper>
    {showIcon && <Icon size="3x" icon={faExclamationCircle as IconProp} />}

    <Heading>{heading}</Heading>

    <Caption>{caption}</Caption>

    {children}

    {goLink && <Link to={HOMEPAGE}>{goLink}</Link>}
  </Wrapper>
);

export default ResponseStatus;
