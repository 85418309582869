import React, { useCallback, useContext } from 'react';
import ShowHidden from '../ShowHidden';
import UploadResult from '../../SendRequest/Standards/UploadResult';
import { DropUploadWrapper } from '../../common/DropUpload';
import { EvidenceLockerContextType } from 'pages/EvidenceLockerPage';
import { FrameworkShareModal } from './';
import { PageContext } from 'pages/Page';
import { withTranslation } from 'react-i18next';
import { UploadSection } from './Frameworks.styles';
import {
  FRAMEWORK_CATEGORIES_ORDER as CATEGORIES,
  TranslatePropsType,
} from 'common/common.types';
import Category from './Category';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import UploadGoBack from './UploadGoBack';
import FrameworksInstructions from './Instructions';
import { FILE_SIZE_LIMIT } from '../../../common/common.constants';

interface FrameworksPropsType extends TranslatePropsType, RouteComponentProps {}

const Frameworks = ({ t }: FrameworksPropsType) => {
  const {
    actions: {
      closeFrameworkShareModal,
      deleteForm,
      hideFramework,
      selectFramework,
      shareFrameworks,
      toggleHiding,
      unhideFramework,
      uploadForm,
      invalidFileSize,
    },
    data: {
      frameworks: { items: frameworksDict, count },
      hiddenFrameworks,
      hiddenFrameworks: { length: hiddenCount },
      isUploadingForm,
      mailingList,
      parsedForms,
      selectedFramework,
      selectedTeam,
      showHidden,
      user,
    },
  } = useContext(PageContext) as EvidenceLockerContextType;

  const handleDropFiles = useCallback(
    (acceptedFiles: File[]) => {
      const teamId = selectedTeam ? selectedTeam.id : undefined;
      acceptedFiles.forEach((file: Blob) => {
        if (file.size <= FILE_SIZE_LIMIT) {
          const formData = new FormData();
          formData.append('file', file);
          uploadForm(formData, teamId);
        } else {
          invalidFileSize('File exceeded the 50 MB limit');
        }
      });
    },
    [selectedTeam, uploadForm, invalidFileSize],
  );
  const hasFrameworks = count > 0;

  const emptyLabel = t(`Framework.empty`);

  return (
    <>
      {selectedFramework && (
        <FrameworkShareModal
          mailingList={mailingList}
          selectedFramework={selectedFramework}
          shareFrameworks={shareFrameworks}
          toggleFrameworkShareModal={closeFrameworkShareModal}
        />
      )}

      <UploadSection>
        <DropUploadWrapper
          instructions={<FrameworksInstructions />}
          isProcessing={isUploadingForm}
          onDrop={handleDropFiles}
        />

        <UploadGoBack />

        <UploadResult
          hasParsingErrors={false}
          parsedFiles={parsedForms}
          showFormLink
        />
      </UploadSection>

      {hiddenCount > 0 && (
        <ShowHidden
          count={hiddenCount}
          showHidden={showHidden}
          toggleHiding={toggleHiding}
        />
      )}

      {!hasFrameworks ? (
        <div>{emptyLabel}</div>
      ) : (
        CATEGORIES.map(
          category =>
            frameworksDict[category] && (
              <Category
                key={category}
                category={category}
                deleteForm={deleteForm}
                frameworks={frameworksDict[category]}
                hiddenFrameworks={hiddenFrameworks}
                hideFramework={hideFramework}
                selectFramework={selectFramework}
                showHidden={showHidden}
                unhideFramework={unhideFramework}
                user={user}
              />
            ),
        )
      )}
    </>
  );
};

export default withTranslation(['components', 'common'])(
  withRouter(Frameworks),
);
