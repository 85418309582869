import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { contains } from 'ramda';
import { PageContextConsumer } from 'pages/Page';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType, Uuid } from 'common/common.types';
import { PageHeader } from '../common/PageHeader';
import { UploadCustomStandardPageContextType } from 'pages/UploadCustomStandardPage';

import {
  FRAMEWORK_TEMPLATE_FILE_NAME,
  FRAMEWORK_TEMPLATE_FILE_PATH,
  FORM_PREVIEW_MODAL_ID,
} from 'common/common.constants';

import { PreviewModal } from 'components/SendRequest/Modals';

import Upload from '../SendRequest/Standards/Upload';

import UploadCustomStandardHeader from './UploadCustomStandardHeader';

import UploadedStandard from './UploadedStandard';

import { Content, Horizontal, Vertical } from 'common/common.styles';

import {
  Instructions,
  Section,
  SectionHeading,
  UploadedStandards,
} from './UploadCustomStandard.styles';

const UploadCustomStandard = ({ t }: TranslatePropsType) => (
  <PageContextConsumer>
    {({
      data: {
        previewForm,
        standards,
        standards: { parseErrorsCount, parsedFiles, uploadingCount },
        user,
        language,
      },
      actions: {
        createEmptyForm,
        deleteStandard,
        getPreview,
        loadQuestions,
        putQuestion,
        renameStandard,
        showModal,
        uploadStandard,
      },
    }: UploadCustomStandardPageContextType) => {
      const HeaderComponent = () => (
        <UploadCustomStandardHeader createEmptyForm={createEmptyForm} />
      );

      const handlePreviewClick = (formId: Uuid) => {
        getPreview(formId);
        showModal(FORM_PREVIEW_MODAL_ID);
      };

      const hasParsingErrors = parseErrorsCount > 0;

      return (
        <>
          <PageHeader heading={<HeaderComponent />} />
          <Content padding="0 15px">
            <PreviewModal
              loadQuestions={loadQuestions}
              modalId={FORM_PREVIEW_MODAL_ID}
              previewForm={previewForm}
              putQuestion={putQuestion}
              user={user}
              language={language}
            />
            <SectionHeading>{t('instruction') as string}</SectionHeading>
            <Section>
              <Instructions>
                <li data-test="download-template">
                  {ReactHtmlParser(
                    t('instructions1HTML', {
                      link: FRAMEWORK_TEMPLATE_FILE_PATH,
                      filename: FRAMEWORK_TEMPLATE_FILE_NAME,
                    }),
                  )}
                </li>

                <li>{t('instructions2') as string}</li>

                <li>{t('instructions3') as string}</li>
              </Instructions>
            </Section>
            <SectionHeading>{t('sectionHeading') as string}</SectionHeading>
            <Section>
              <Horizontal>
                <Vertical>
                  <Upload
                    hasParsingErrors={hasParsingErrors}
                    isProcessing={uploadingCount > 0}
                    onUpload={uploadStandard}
                    parsedFiles={parsedFiles}
                    showInstructions={false}
                  />
                </Vertical>
                {standards.all.mostRecent.length > 0 ? (
                  <UploadedStandards>
                    {standards.all.mostRecent
                      .filter(standard =>
                        contains(standard.id, standards.all.selected),
                      )
                      .map(standard => (
                        <UploadedStandard
                          key={standard.id}
                          standard={standard}
                          onPreview={handlePreviewClick}
                          renameStandard={renameStandard}
                          showModal={showModal}
                          deleteStandard={deleteStandard}
                        />
                      ))}
                  </UploadedStandards>
                ) : null}
              </Horizontal>
            </Section>
          </Content>
        </>
      );
    }}
  </PageContextConsumer>
);

export default withTranslation(['UploadCustomStandard', 'SendRequest'])(
  UploadCustomStandard,
);
