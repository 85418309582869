import React from 'react';
import Request from '../Request/Request';
import { PageContextConsumer } from 'pages/Page';
import { RequestsArchivePageContextType } from 'pages/RequestsArchivePage';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { PageHeader } from '../../common/PageHeader';
import ArchiveHeader from './ArchiveHeader';
import RequestSorter from '../RequestSorter';
import VirtualizedList from 'utils/virtualizer/src/VirtualizedList';
import { REQUEST_HEIGHT } from '../Request/Request.styles';
import { Wrapper } from 'common/common.styles';
import { ReceivedContainer } from './Archive.styles';

const Archive = ({ t }: TranslatePropsType) => (
  <PageContextConsumer>
    {({
      actions: { copyToClipboard, goBack, unarchiveRequest, setSort },
      data: {
        requests: {
          data,
          filter,
          filter: { sortBy },
        },
        language,
      },
    }: RequestsArchivePageContextType) => {
      const HeaderComponent = () => (
        <ArchiveHeader filter={filter} setSort={setSort} goBack={goBack} />
      );
      return (
        <>
          <PageHeader heading={<HeaderComponent />} />

          <Wrapper grey>
            <ReceivedContainer data-test="archived-questionnaires">
              <RequestSorter
                setSort={setSort}
                sortBy={sortBy}
                showScore={true}
              />

              <VirtualizedList
                overScan={5}
                items={data}
                itemHeight={REQUEST_HEIGHT}
                renderItem={request => (
                  <Request
                    key={`request-archive-${request.id}`}
                    data={{
                      request,
                      showScore: true,
                      showGrade: true,
                      language,
                    }}
                    actions={{ copyToClipboard, unarchiveRequest }}
                  />
                )}
              />
            </ReceivedContainer>
          </Wrapper>
        </>
      );
    }}
  </PageContextConsumer>
);

export default withTranslation()(Archive);
