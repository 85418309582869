import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import {
  FormCreatorValidatedQuestionType,
  QuestionDefinitionSummaryTypeEnum as QuestionTypeEnum,
  TranslatePropsType,
  FixableFieldEnum,
  FormCreatorRenderType,
} from 'common/common.types';
import {
  CollapsedQuestionText,
  CollapsedWrapper,
  QuestionCodeInput,
  QuestionCodeWrapper,
  QuestionText,
  QuestionTextWrapper,
  QuestionType,
  QuestionTypeOption,
  QuestionTypeToggle,
  QuestionWrapper,
  Tabs,
  TabsWrapper,
} from './Question.styles';
import { FormCreatorContextActionsType } from 'pages/FormCreatorPage';
import { ResponseTypeDefinitionResponseTypeEnum as ResponseType } from 'generated/models/ResponseTypeDefinition';
import {
  Action,
  ActionsWrapper,
  DeleteTrashIcon,
  DuplicateIcon,
} from '../Actions/Actions.styles';
import { DropdownState } from '../../common/Dropdown/Dropdown';
import { hasNoResponses } from '../Response/Response.options';
import Label from '../../common/typography/Label';
import ValidationInfo from '../ValidationInfo';
import { QuestionDefinitionEntityErrorCodeEnum } from 'generated/models/QuestionDefinitionEntityError';
import { isFieldFixAttempted } from 'store/formCreator/selectors/validation';
import HeaderWarningIcon from '../HeaderWarningIcon';
import someAncestor from 'utils/dom/someAncestor';
import { ConditionsPanel, ResponsePanel, Tab } from '../Tabs';
import { getConditionsCount, groupQuestionErrors } from './utils';
import { TabPanel } from '../../common/Tabs';
import { SwitchQuestionConditionTypeFunctionType } from 'store/formCreator/actions';
import IssuesPanel from '../Tabs/IssuesPanel';
import { FactorWithIssuesSelectOption } from 'store/formCreator/selectors/issues';

interface QuestionPropsType extends TranslatePropsType {
  actions: FormCreatorContextActionsType;
  factorsWithIssues: FactorWithIssuesSelectOption[];
  isDeletable: boolean;
  isOpen: boolean;
  hasConditionsToggle: boolean;
  hasIssuesToggle: boolean;
  question: FormCreatorValidatedQuestionType;
  renderData: FormCreatorRenderType[];
  switchQuestionConditionType: SwitchQuestionConditionTypeFunctionType;
}

enum TabValue {
  RESPONSE,
  CONDITIONS,
  SCORE,
  ISSUES,
}

const Question = ({
  actions,
  actions: {
    addResponse,
    deleteQuestion,
    duplicateQuestion,
    setOpenedQuestion,
    updateQuestionCode,
    updateQuestionCondition,
    updateQuestionSscIssues,
    updateQuestionText,
    updateQuestionType,
    validateForm,
  },
  isDeletable,
  isOpen = true,
  hasConditionsToggle,
  hasIssuesToggle,
  factorsWithIssues,
  question: wholeQuestion,
  question: {
    category,
    code,
    condition,
    errors,
    fixAttempted,
    fixAttemptedInAllResponses,
    hasErrors,
    hasResponseErrors,
    question,
    responses,
    sscIssues: questionIssues,
    wizardId: questionId,
  },
  renderData,
  switchQuestionConditionType,
  t,
}: QuestionPropsType) => {
  const [questionText, setQuestionText] = useState(question);
  const [currentTab, setCurrentTab] = useState<TabValue>(TabValue.RESPONSE);

  useEffect(() => {
    setQuestionText(question);
  }, [question]);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [conditionErrors, questionErrors] = useMemo(
    () => groupQuestionErrors(errors),
    [errors],
  );

  const onDeleteQuestion = useCallback(() => {
    deleteQuestion(questionId);
  }, [deleteQuestion, questionId]);

  const onDuplicateQuestion = useCallback(() => {
    duplicateQuestion(wholeQuestion);
  }, [duplicateQuestion, wholeQuestion]);

  const onQuestionChanged = useCallback(
    debounce((value: string) => {
      updateQuestionText(questionId, value);
    }, 1000),
    [questionText],
  );

  const onCodeBlur = useCallback(
    (newCode: string) => {
      if (newCode !== code) {
        updateQuestionCode(questionId, newCode);
      }
    },
    [code, questionId, updateQuestionCode],
  );

  const onAddNewResponse = useCallback(() => {
    addResponse({
      questionId,
      responseType: ResponseType.TEXT,
      options: [],
    });
  }, [addResponse, questionId]);

  const onQuestionTypeChange = (type: QuestionTypeEnum, close: () => void) => {
    updateQuestionType(questionId, type, responses);
    close();
  };

  const onRemoveCondition = useCallback(() => {
    updateQuestionCondition(questionId, null);
  }, [questionId, updateQuestionCondition]);

  const onHeaderClick = useCallback(
    (e: React.MouseEvent) => {
      if (!e.target) {
        return;
      }
      const dontCollapse = someAncestor(
        e.target as HTMLElement,
        el => Boolean(el.dataset.noCollapse),
        e.currentTarget as HTMLElement,
      );
      if (!dontCollapse) {
        setOpenedQuestion({
          categoryName: category,
          questionUuid: isOpen ? undefined : questionId,
        });
      }
    },
    [category, setOpenedQuestion, isOpen, questionId],
  );

  const dontPropagate = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  const isQuestion = !hasNoResponses(responses);

  return isOpen ? (
    <div id={`#${questionId}`} data-test={'form-question'}>
      {questionErrors.length > 0 && (
        <ValidationInfo
          errors={questionErrors}
          fixAttempted={Boolean(fixAttempted)}
          onValidateClick={validateForm}
          showIcon={!hasConditionsToggle}
        />
      )}
      <QuestionWrapper onClick={onHeaderClick} ref={wrapperRef}>
        <QuestionType
          data-no-collapse
          toggle={QuestionTypeToggle}
          text={t(isQuestion ? 'question' : 'noResponse')}
        >
          {({ close }: DropdownState) => (
            <>
              <QuestionTypeOption
                data-test="question-option"
                data-no-collapse
                isSelected={isQuestion}
                onClick={() =>
                  onQuestionTypeChange(QuestionTypeEnum.QUESTION, close)
                }
              >
                {t('question') as string}
              </QuestionTypeOption>
              <QuestionTypeOption
                data-test="info-option"
                data-no-collapse
                isSelected={!isQuestion}
                onClick={() =>
                  onQuestionTypeChange(QuestionTypeEnum.NO_RESPONSE, close)
                }
              >
                {t('noResponse') as string}
              </QuestionTypeOption>
            </>
          )}
        </QuestionType>
        <QuestionTextWrapper data-test="question-text" data-no-collapse>
          <QuestionText
            onChange={onQuestionChanged}
            value={questionText}
            visualState={
              errors.some(
                error =>
                  error.code ===
                  QuestionDefinitionEntityErrorCodeEnum.EMPTYQUESTION,
              ) && !isFieldFixAttempted(fixAttempted, FixableFieldEnum.TEXT)
                ? 'error'
                : 'normal'
            }
          />
        </QuestionTextWrapper>
        <QuestionCodeWrapper data-test="question-id">
          <Label>{t('code') as string}</Label>
          <QuestionCodeInput
            // We use dontPropagate instead of data-no-collapse because
            // the text-input component doesn't support ambiguous data
            // attributes.
            onClick={dontPropagate}
            onBlur={onCodeBlur}
            value={code}
            visualState={
              errors.some(
                error =>
                  error.code ===
                    QuestionDefinitionEntityErrorCodeEnum.EMPTYQUESTIONCODE ||
                  error.code ===
                    QuestionDefinitionEntityErrorCodeEnum.DUPLICATEDQUESTIONCODE,
              ) && !isFieldFixAttempted(fixAttempted, FixableFieldEnum.CODE)
                ? 'error'
                : 'normal'
            }
          />
        </QuestionCodeWrapper>
        <ActionsWrapper>
          <Action
            data-no-collapse
            onClick={onDuplicateQuestion}
            title={t('duplicate') as string}
          >
            <DuplicateIcon />
          </Action>
          {isDeletable && (
            <Action
              data-no-collapse
              onClick={onDeleteQuestion}
              title={t('delete') as string}
            >
              <DeleteTrashIcon />
            </Action>
          )}
        </ActionsWrapper>
      </QuestionWrapper>
      {isQuestion && (
        <TabsWrapper>
          <Tabs value={currentTab} onChange={setCurrentTab}>
            <Tab
              hasError={hasResponseErrors}
              fixAttempted={fixAttemptedInAllResponses}
              label={t('tabs.response')}
              value={TabValue.RESPONSE}
            />
            {hasConditionsToggle && (
              <Tab
                count={getConditionsCount(condition)}
                fixAttempted={Boolean(fixAttempted)}
                hasError={conditionErrors.length > 0}
                label={t('tabs.conditions')}
                value={TabValue.CONDITIONS}
              />
            )}
            {hasIssuesToggle && (
              <Tab
                count={questionIssues.length}
                hasError={false}
                label={t('tabs.issues')}
                value={TabValue.ISSUES}
              />
            )}
          </Tabs>

          <TabPanel index={TabValue.RESPONSE} value={currentTab}>
            <ResponsePanel
              actions={actions}
              hasConditionsToggle={hasConditionsToggle}
              onAddNewResponse={onAddNewResponse}
              questionId={questionId}
              responses={responses}
            />
          </TabPanel>
          <TabPanel index={TabValue.CONDITIONS} value={currentTab}>
            <ConditionsPanel
              actions={actions}
              condition={condition}
              errors={conditionErrors}
              question={wholeQuestion}
              removeCondition={onRemoveCondition}
              renderData={renderData}
              switchQuestionConditionType={switchQuestionConditionType}
            />
          </TabPanel>
          <TabPanel index={TabValue.ISSUES} value={currentTab}>
            <IssuesPanel
              questionId={questionId}
              questionIssues={questionIssues}
              factorsWithIssues={factorsWithIssues}
              updateQuestionSscIssues={updateQuestionSscIssues}
            />
          </TabPanel>
        </TabsWrapper>
      )}
    </div>
  ) : (
    <CollapsedWrapper data-test="collapsed-question" onClick={onHeaderClick}>
      <HeaderWarningIcon visible={hasErrors} />
      <CollapsedQuestionText>
        <strong>{code}</strong>&nbsp;
        {question}
      </CollapsedQuestionText>
      <ActionsWrapper>
        <Action
          data-no-collapse
          onClick={onDuplicateQuestion}
          title={t('duplicate') as string}
        >
          <DuplicateIcon />
        </Action>
        {isDeletable && (
          <Action
            data-no-collapse
            onClick={onDeleteQuestion}
            title={t('delete') as string}
          >
            <DeleteTrashIcon />
          </Action>
        )}
      </ActionsWrapper>
    </CollapsedWrapper>
  );
};

export default withTranslation('FormCreator')(React.memo(Question));
