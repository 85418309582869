import React, { createRef } from 'react';
import uuid from 'utils/uuid';
import { Button } from '../../common/Button';
import { ButtonText } from './FormAttachments.styles';
import { FormsPostAttachmentFunctionType } from 'store/forms/actions/postAttachment';
import { HiddenInput } from 'common/common.styles';
import { TranslatePropsType, Uuid } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface AddAttachmentsPropsType extends TranslatePropsType {
  formId: Uuid;
  postAttachment: FormsPostAttachmentFunctionType;
}

const AddAttachments = ({
  formId,
  postAttachment,
  t,
}: AddAttachmentsPropsType) => {
  const fileInput = createRef<HTMLInputElement>();

  const handleFiles = (event: any) =>
    Array.from(event.target.files).forEach((file: Blob) => {
      const formData = new FormData();

      formData.append('form_id', formId.toString());
      formData.append('file', file);
      formData.append('uploadingId', uuid());
      // @ts-ignore
      postAttachment(formData, formId);
    });

  function handleAddButton() {
    if (fileInput.current) {
      fileInput.current.click();
    }
  }

  return (
    <>
      <HiddenInput>
        <input
          className="file-input"
          multiple
          name="files"
          onChange={event => handleFiles(event)}
          ref={fileInput}
          type="file"
        />
      </HiddenInput>

      <Button
        color={'transparent'}
        onClick={handleAddButton}
        style={{ padding: 0, border: 'none' }}
      >
        <ButtonText>{t('add') as string}</ButtonText>
      </Button>
    </>
  );
};

export default withTranslation('FormAttachments')(AddAttachments);
