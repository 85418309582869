import { ApiCallActionType } from '../../store.types';

export type SupportAppealCreditsFunctionType = () => void;

export type SupportAppealCredits = ApiCallActionType<
  '@support/API/APPEAL_CREDITS',
  {}
>;

export const appealCredits = (): SupportAppealCredits => ({
  type: '@support/API/APPEAL_CREDITS',
  payload: {
    callParams: {},
    endpointPath: ['support', 'appealCredits'],
    selector: null,
  },
});
