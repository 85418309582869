/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestFiltersDescriptions
 */
export interface RequestFiltersDescriptions {
    /**
     * 
     * @type {string}
     * @memberof RequestFiltersDescriptions
     */
    html: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFiltersDescriptions
     */
    plain: string;
}

export function RequestFiltersDescriptionsFromJSON(json: any): RequestFiltersDescriptions {
    return RequestFiltersDescriptionsFromJSONTyped(json, false);
}

export function RequestFiltersDescriptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFiltersDescriptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'html': json['html'],
        'plain': json['plain'],
    };
}

export function RequestFiltersDescriptionsToJSON(value?: RequestFiltersDescriptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'html': value.html,
        'plain': value.plain,
    };
}


