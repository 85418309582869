import React, { useContext, useState, useEffect, useCallback } from 'react';
import { any } from 'ramda';
import { FormCreatorPageContextType } from 'pages/FormCreatorPage';
import { PageContext } from 'pages/Page';
import {
  Uuid,
  TranslatePropsType,
  FormDefinitionSummaryDraftExtendedType,
  ToggleEnum,
} from 'common/common.types';
import {
  FORM_PREVIEW_MODAL_ID,
  FORM_PUBLISH_MODAL_ID,
  FORM_CREATOR_CONFLICT_MODAL_ID,
  FORM_PREVIEW_ERROR_MODAL_ID,
  DEFAULT_LANGUAGE,
} from 'common/common.constants';
import { PreviewModal } from 'components/SendRequest/Modals';
import Loading from '../common/Loading';
import Header from './Header';
import Categories from './Categories';
import { DndProvider } from 'react-dnd-cjs';
import HTML5Backend from 'react-dnd-html5-backend-cjs';
import PublishModal from './PublishModal/PublishModal';
import PreviewErrorModal from './PreviewModal/PreviewErrorModal';
import ConflictModal from './ConflictModal/ConflictModal';
import { Prompt } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getPreviewHelper } from 'store/formCreator/previewUtils';
import {
  Content,
  ScrollableFormOutline,
  StyledPageHeader,
  ValidationOverlay,
  Wrapper,
} from './FormCreator.styles';
import ValidationBar from './ValidationBar';
import hasToggle from 'utils/hasToggle';
import useFlag from 'utils/hooks/useFlag';

import { trackEvent } from 'utils/analytics';
import AnimatedSticky from '../common/AnimatedSticky';
import { HEADER_HEIGHT } from 'common/common.styles';
import withHeaderVisibility from '../Layout/HeaderVisibility';
import { ResponseStatus } from '../ResponseStatus';

export enum MoveTypeEnum {
  LOCALLY = 'locally',
  WITH_API_CALL = 'withApiCall',
}

interface FormCreatorPropsType extends TranslatePropsType {
  isHeaderVisible?: boolean;
}

const FormCreator = ({ isHeaderVisible, t }: FormCreatorPropsType) => {
  const {
    data: {
      areOnlyInvalidQuestionsVisible,
      areQuestionsDeletable,
      currentForm,
      factorsWithIssues,
      isSaving,
      lastSavedUpdate,
      openedQuestion,
      previewForm,
      renderData,
      resourceGone,
      savingError,
      user,
      validation,
      validationStats,
      language = DEFAULT_LANGUAGE,
    },
    actions,
    actions: {
      getPreview,
      getPreviewForm,
      loadQuestions,
      publishForm,
      putQuestion,
      resetPreviewForm,
      setOpenedQuestion,
      showModal,
      showOnlyInvalidQuestions,
      updateFormDefinition,
      moveQuestion,
      moveCategory,
      moveQuestionLocally,
      moveCategoryLocally,
      hideModal,
      reloadData,
      validateForm,
      getForm,
    },
  } = useContext(PageContext) as FormCreatorPageContextType;

  const [isPreviewErrorModal, setIsPreviewErrorModal] = useState('');

  const onMoveQuestion = (
    questionUuid: Uuid,
    targetQuestionUuid: Uuid,
    type: MoveTypeEnum,
  ) => {
    if (type === MoveTypeEnum.LOCALLY) {
      moveQuestionLocally(questionUuid, targetQuestionUuid);
    } else if (type === MoveTypeEnum.WITH_API_CALL) {
      moveQuestion({ questionUuid, targetQuestionUuid });
    }
  };

  const onMoveCategory = (
    sourceCat: string,
    destinationCat: string,
    type: MoveTypeEnum,
  ) => {
    if (type === MoveTypeEnum.LOCALLY) {
      moveCategoryLocally(sourceCat, destinationCat);
    } else if (type === MoveTypeEnum.WITH_API_CALL) {
      moveCategory({ categoryName: sourceCat });
    }
  };

  const handlePreviewClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateForm();
    setIsPreviewErrorModal(e.target.id);
  };

  const handlePreviewForm = useCallback(() => {
    if (!currentForm) {
      return;
    }

    getPreviewHelper(currentForm.standard, currentForm.id, {
      getPreview,
      getPreviewForm,
    });
    showModal(FORM_PREVIEW_MODAL_ID);
  }, [currentForm, showModal, getPreview, getPreviewForm]);

  useEffect(() => {
    if (isPreviewErrorModal === 'preview') {
      if (!validation.correct) {
        showModal(FORM_PREVIEW_ERROR_MODAL_ID);
      } else if (validation.correct) {
        hideModal(FORM_PREVIEW_ERROR_MODAL_ID);
        handlePreviewForm();
      }
    }
  }, [
    validation,
    isPreviewErrorModal,
    hideModal,
    showModal,
    handlePreviewForm,
  ]);

  const handleResetPreviewForm = () => {
    setIsPreviewErrorModal('');
    resetPreviewForm();
  };

  const onClickCancelPreviewErrorModal = () => {
    hideModal(FORM_PREVIEW_ERROR_MODAL_ID);
  };

  const onClickCancelPublishModal = () => {
    hideModal(FORM_PUBLISH_MODAL_ID);
  };

  const onClickPublish = () => {
    publishForm({ force: false });
  };

  const onClickPublishForce = () => {
    trackEvent('FormCreator', 'Force Publish');
    publishForm({ force: true });
  };

  const checkForm = (form: FormDefinitionSummaryDraftExtendedType) => {
    // if no questions, cannot publish
    if (form.questions.length < 1) {
      return false;
    }
    return form.questions.some(question => {
      return question.question !== '' && question.responses.length > 0;
    });
  };

  const showPromptOnLeave = isSaving && !savingError;

  const showValidationBar =
    validation.correct || validationStats.invalidEntitiesCount > 0;

  const hasConditionsToggle = hasToggle(user, [
    ToggleEnum.FORM_CREATOR_CONDITIONS,
  ]);

  const hasIssuesToggle = useFlag(user, [ToggleEnum.FORM_CREATOR_ISSUES]);

  const showOnlyErrors =
    areOnlyInvalidQuestionsVisible &&
    any(category => category.hasErrors, renderData);

  if (resourceGone) {
    const errorPageId =
      resourceGone.kind === 'deleted'
        ? 'errorPageDeleted'
        : 'errorPageAlreadyPublished';
    return (
      <ResponseStatus
        caption={t(`${errorPageId}.caption`, {
          standard: resourceGone.standard,
        })}
        heading={t(`${errorPageId}.heading`)}
      />
    );
  }

  if (!currentForm) {
    return <Loading />;
  } else if (currentForm.creationStatus !== 'FINISHED_SUCCESS') {
    // Refresh the state for currentForm creation status, every 3 seconds.
    setTimeout(() => {
      getForm(currentForm.id);
    }, 3000);
    return <Loading />;
  } else {
    const publishDisabled = !checkForm(currentForm) || isSaving || savingError;
    const previewDisabled =
      publishDisabled ||
      typeof currentForm.standard.previewFormId === 'undefined';

    const dndProps = {
      backend: HTML5Backend,
    };

    return (
      <Wrapper>
        <AnimatedSticky
          style={{
            top: isHeaderVisible ? HEADER_HEIGHT : 0,
            zIndex: 1002,
          }}
        >
          <StyledPageHeader
            heading={
              <>
                <Header
                  draftId={currentForm.id}
                  isSaving={isSaving}
                  lastUpdate={lastSavedUpdate!}
                  onPreview={handlePreviewClick}
                  previewDisabled={previewDisabled}
                  publishDisabled={publishDisabled}
                  publishForm={onClickPublish}
                  savingError={savingError}
                  showBetaSign={!hasConditionsToggle}
                  standard={currentForm.standard}
                  updateForm={updateFormDefinition}
                />
                {showValidationBar && (
                  <ValidationBar
                    areOnlyInvalidQuestionsVisible={
                      areOnlyInvalidQuestionsVisible
                    }
                    invalidCount={validationStats.invalidEntitiesCount}
                    publishForm={onClickPublish}
                    showOnlyInvalidQuestions={showOnlyInvalidQuestions}
                    validateForm={validateForm}
                    validation={validation}
                  />
                )}
              </>
            }
          />
        </AnimatedSticky>
        <Content>
          {validation.isLoading && <ValidationOverlay />}
          <DndProvider {...dndProps}>
            {renderData && (
              <ScrollableFormOutline
                isValidationBarVisible={showValidationBar}
                onMoveCategory={onMoveCategory}
                onMoveQuestion={onMoveQuestion}
                renderData={renderData}
                setOpenedQuestion={setOpenedQuestion}
                showOnlyErrors={showOnlyErrors}
              />
            )}
          </DndProvider>
          {renderData && (
            <Categories
              actions={actions}
              areQuestionsDeletable={areQuestionsDeletable}
              factorsWithIssues={factorsWithIssues}
              hasConditionsToggle={hasConditionsToggle}
              hasIssuesToggle={hasIssuesToggle}
              openedQuestion={openedQuestion}
              renderData={renderData}
              showOnlyErrors={showOnlyErrors}
            />
          )}
        </Content>
        <ConflictModal
          draftId={currentForm.id}
          modalId={FORM_CREATOR_CONFLICT_MODAL_ID}
          onRefreshClick={() => reloadData(currentForm.id)}
        />
        <Prompt
          when={showPromptOnLeave}
          message={t('savingLeavePageWarning') as string}
        />

        {validation.correct &&
        isPreviewErrorModal === 'preview' &&
        !validation.isLoading ? (
          <PreviewModal
            loadQuestions={loadQuestions}
            modalId={FORM_PREVIEW_MODAL_ID}
            onClose={handleResetPreviewForm}
            previewForm={previewForm}
            putQuestion={putQuestion}
            user={user}
            language={language}
          />
        ) : null}

        {!validation.correct &&
        isPreviewErrorModal === 'preview' &&
        !validation.isLoading ? (
          <PreviewErrorModal
            invalidCount={validationStats.allErrorsCount}
            modalId={FORM_PREVIEW_ERROR_MODAL_ID}
            onCancelClick={onClickCancelPreviewErrorModal}
            validation={validation}
            hideModal={hideModal}
            showModal={showModal}
            isPreviewErrorModal={isPreviewErrorModal}
            setIsPreviewErrorModal={setIsPreviewErrorModal}
            handlePreviewForm={handlePreviewForm}
          />
        ) : null}

        <PublishModal
          invalidCount={validationStats.allErrorsCount}
          modalId={FORM_PUBLISH_MODAL_ID}
          onPublishClick={onClickPublishForce}
          onCancelClick={onClickCancelPublishModal}
          validation={validation}
        />
      </Wrapper>
    );
  }
};

export default withHeaderVisibility(
  withTranslation('FormCreator')(FormCreator),
);
