import styled from 'styled-components';
import { Action as DropdownAction } from '../Form/FormHeader/Bar.styles';
import hex2rgba from 'utils/hex2rgba';

export const Pane = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto 16px;
  min-height: 240px;
  position: relative;
`;

export const HeaderBar = styled(Pane)`
  background-color: ${({ theme }) => theme.atlas.white};
  box-shadow: 4px 4px 4px 0
    ${({ theme }) => hex2rgba(theme.atlas.grey[400], 0.2)};
  border: 1px solid ${({ theme }) => theme.atlas.grey[100]};
  min-height: 80px;
  padding: 30px 15px;
  height: 80px;
  line-height: 50px;
  color: #333;
  font-size: ${({ theme }) => theme.basic.fontSizes.large};
  letter-spacing: 0.25px;
  position: absolute;
  top: 40px;
  width: 100%;
`;

export const Heading = styled.div`
  position: absolute;
  top: 0;
`;

export const Actions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  height: 100%;

  > * {
    display: flex;
  }
`;

export const Action = styled(DropdownAction)`
  height: 78px;
  margin: 0;
`;
