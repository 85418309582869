import React from 'react';

import {
  PREVIEW_COBRANDING_MODAL_ID,
  DELETE_LOGO,
} from 'common/common.constants';
import {
  CobrandButton,
  CobrandButtons,
  CobrandButtonDelete,
} from './SettingsCobranding.styles';
import UploadLanding from '../SendRequest/Recipient/Cobranding/UploadLanding';
import SettingsDeleteLogo from './SettingsDeleteLogo';
import { useTranslation } from 'react-i18next';

interface SettingsCobrandingType {
  fileURL: string;
  handleFile: (event: any) => void;
  showModal: (modalId: string) => void;
  hideModal: (modalId: string) => void;
  logo: string | undefined;
  removeLogo: () => void;
}

const SettingsCobranding: React.FC<SettingsCobrandingType> = ({
  fileURL,
  handleFile,
  showModal,
  logo,
  removeLogo,
  hideModal,
}) => {
  function handleDelete() {
    showModal(DELETE_LOGO);
  }
  const { t } = useTranslation('Settings');

  return (
    <CobrandButtons>
      <UploadLanding updateCobrandingLogoFile={handleFile} />

      {!!fileURL.length && (
        <React.Fragment>
          <CobrandButton
            onClick={() => {
              showModal(PREVIEW_COBRANDING_MODAL_ID);
            }}
            data-pendo="preview-logo-settings"
          >
            {t('cobrandig.previewLogo') as string}
          </CobrandButton>
        </React.Fragment>
      )}

      {logo && (
        <React.Fragment>
          <CobrandButtonDelete onClick={handleDelete}>
            {t('cobrandig.deleteLogo') as string}
          </CobrandButtonDelete>
          <SettingsDeleteLogo
            removeLogo={removeLogo}
            modalId={DELETE_LOGO}
            hideModal={hideModal}
          />
        </React.Fragment>
      )}
    </CobrandButtons>
  );
};

export default SettingsCobranding;
