import * as R from 'ramda';
import combineArrays from '../combineArrays';
import { CompanyGradeEnum } from 'generated/models/Company';
import { ListedCompanyType, TagType } from 'common/common.types';
import {
  FilterVisualisationType,
  VisualisationByGradeDataType,
} from 'store/companies/companies.reducer';

export const filterByTag = (
  companies: ListedCompanyType[],
  tagName: string,
): ListedCompanyType[] => {
  const hasTag = R.any(R.pathEq(['tag', 'name'], tagName));

  return R.filter(R.compose(hasTag, R.path(['company', 'tags'])))(companies);
};

export const filterByTags = (
  companies: ListedCompanyType[],
  tags: TagType[],
): ListedCompanyType[] =>
  R.reduce(
    (filteredCompanies: ListedCompanyType[], tag: TagType) =>
      filterByTag(filteredCompanies, tag.name),
    companies,
    tags,
  );

export const filterByGrade = (
  companies: ListedCompanyType[],
  grade: CompanyGradeEnum,
): ListedCompanyType[] => {
  return R.filter(R.pathEq(['company', 'info', 'grade'], grade))(companies);
};

export const partitionByGrade = (
  companies: ListedCompanyType[],
  grandTotal: number,
): VisualisationByGradeDataType => {
  const grades = R.values(CompanyGradeEnum);

  return R.reduce(
    (acc: VisualisationByGradeDataType, grade: CompanyGradeEnum) => {
      const gradeKey = grade === '' ? 'NONE' : grade;
      const filtered = filterByGrade(companies, grade);

      return {
        ...acc,
        [gradeKey]: {
          qtyAbs: filtered.length,
          qtyRel: (filtered.length / grandTotal) * 100,
          companies: filtered,
        },
      };
    },
    {},
    grades,
  );
};

export const filterUnsorted = (
  companies: ListedCompanyType[],
  filteredCompanies: ListedCompanyType[],
): ListedCompanyType[] => R.difference(companies, filteredCompanies);

export const getVisualisationData = (
  companies: ListedCompanyType[],
  filterTags: TagType[],
): {
  total: number;
  filtered: ListedCompanyType[];
  rows: FilterVisualisationType;
} => {
  const filtered = filterByTags(companies, filterTags);
  const filteredCategories = {
    'Low risk': filterByTag(filtered, 'Low risk'),
    'Medium risk': filterByTag(filtered, 'Medium risk'),
    'High risk': filterByTag(filtered, 'High risk'),
  };

  return {
    total: filtered.length,
    filtered,
    rows: {
      tagged: [
        {
          legend: 'High risk',
          byGrade: partitionByGrade(
            filteredCategories['High risk'],
            filtered.length,
          ),
        },
        {
          legend: 'Medium risk',
          byGrade: partitionByGrade(
            filteredCategories['Medium risk'],
            filtered.length,
          ),
        },
        {
          legend: 'Low risk',
          byGrade: partitionByGrade(
            filteredCategories['Low risk'],
            filtered.length,
          ),
        },
      ],
      unsorted: {
        legend: 'Unsorted',
        byGrade: partitionByGrade(
          filterUnsorted(
            filtered,
            combineArrays([
              filteredCategories['High risk'],
              filteredCategories['Medium risk'],
              filteredCategories['Low risk'],
            ]),
          ),
          filtered.length,
        ),
      },
    },
  };
};
