export interface FormsSetResourceGoneActionType {
  type: '@forms/SET_RESOURCE_GONE';
  payload: {
    company: string;
    statusOfResource: string;
  };
}

export const setResourceGone = (
  company: string,
  statusOfResource: string,
): FormsSetResourceGoneActionType => ({
  type: '@forms/SET_RESOURCE_GONE',
  payload: {
    company,
    statusOfResource,
  },
});
