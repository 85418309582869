import { Standard } from '../../../generated/models/Standard';
import { FormCreatorStateType } from '../formCreator.types';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { RenameStandardParamsType } from '../../standard/actions';
import { StandardType } from '../../../common/common.types';
import update from 'immutability-helper';

export type UpdateFormDefinitionFunctionType = (
  params: UpdateFormDefinitionParamsType,
) => void;

export type UpdateFormDefinitionParamsType = RenameStandardParamsType;

export type UpdateFormDefinitionActionType = OptimisticActionType<
  '@formCreator/API/UPDATE_FORM_DEFINITION',
  ApiCallActionPayloadType<
    UpdateFormDefinitionParamsType,
    Standard,
    never,
    FormCreatorStateType
  >
>;

export const updateFormDefinition = (
  params: UpdateFormDefinitionParamsType,
): UpdateFormDefinitionActionType => ({
  type: '@formCreator/API/UPDATE_FORM_DEFINITION',
  payload: {
    callParams: params,
    endpointPath: ['standards', 'rename'],
    expectedStateChange: {
      'formCreator.currentForm.standard': (standard: StandardType) =>
        update(standard, {
          name: { $set: params.name },
          version: {
            $apply: (oldVersion: string) =>
              typeof params.version !== 'undefined'
                ? params.version
                : oldVersion,
          },
        }),
    },
    selector: (data, state): Partial<FormCreatorStateType> =>
      update(state.formCreator, {
        eventSequencer: {
          lastSavedUpdate: { $set: new Date(data.updated_at) },
        },
      }),
  },
});
