import React from 'react';
import { Wrapper } from './Spinner.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface SpinnerPropsType {
  text?: React.ReactNode;
}

const Spinner = ({ text }: SpinnerPropsType) => (
  <Wrapper>
    <FontAwesomeIcon icon={faSpinner as IconProp} spin size="2x" />
    {text && <span>{text}</span>}
  </Wrapper>
);

export default Spinner;
