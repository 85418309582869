import React from 'react';
import { Bar, BarWrapper } from './ScoreChart.styles';
import BarHeader from './BarHeader';
import formatRoute, { COMPANY, FORM } from '../../../routes';
import { Uuid } from 'common/common.types';

interface ScoreRecordPropsType {
  color: string;
  companyId?: Uuid;
  children: React.ReactNode;
  formId?: Uuid;
  hasStripes?: boolean;
  name: string;
  title?: string;
  width: number;
}

const ScoreRecord = ({
  color,
  companyId,
  children,
  formId,
  hasStripes,
  name,
  title,
  width,
}: ScoreRecordPropsType) => (
  <BarWrapper align="center" data-test="score-bar">
    <BarHeader
      name={name}
      nameLink={formId ? `#${formatRoute(FORM, { id: formId })}` : undefined}
      title={title}
      titleLink={
        companyId ? `#${formatRoute(COMPANY, { id: companyId })}` : undefined
      }
    />

    <Bar
      color={color}
      hasStripes={hasStripes}
      width={width}
      data-test="score-value"
    >
      {children}
    </Bar>
  </BarWrapper>
);

export default ScoreRecord;
