import {
  QuestionDefinitionSummaryTypeEnum,
  ResponseDefinitionSummaryType,
  Uuid,
} from '../../../common/common.types';
import { DeleteResponseEventType } from '../event.types';
import uuid from 'utils/uuid';
import { createDefaultResponse, createResponse } from '../utils';
import { ResponseTypeDefinitionResponseTypeEnum as ResponseType } from '../../../generated/models/ResponseTypeDefinition';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';
import update from 'immutability-helper';
import { map } from 'ramda';

export type UpdateQuestionTypeFunctionType = (
  questionId: Uuid,
  type: QuestionDefinitionSummaryTypeEnum,
  responses: ResponseDefinitionSummaryType[],
) => void;

export type UpdateQuestionTypeActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/UPDATE_QUESTION_TYPE'
>;

export const updateQuestionType = (
  questionId: Uuid,
  type: QuestionDefinitionSummaryTypeEnum,
  responses: ResponseDefinitionSummaryType[],
): UpdateQuestionTypeActionType => {
  const responseUuid = uuid();
  const newResponse =
    type === QuestionDefinitionSummaryTypeEnum.NO_RESPONSE
      ? createResponse(ResponseType.NORESPONSE, responseUuid)
      : createDefaultResponse(responseUuid);

  return {
    type: '@formCreator/EVENTS/UPDATE_QUESTION_TYPE',
    payload: {
      events: [
        ...responses.map(
          ({ uniqueId }): DeleteResponseEventType => ({
            action: FormEditEventActionCodeEnum.DELETERESPONSE,
            response_uuid: uniqueId,
          }),
        ),
        {
          action: FormEditEventActionCodeEnum.ADDRESPONSE,
          response_uuid: responseUuid,
          question_uuid: questionId,
          definition: {
            label: newResponse.definition.label,
            options: newResponse.definition.options,
            placeholder: newResponse.definition.placeholder,
            response_type: newResponse.definition.responseType,
          },
        },
      ],
      selector: ({ formCreator }) =>
        update(formCreator, {
          currentForm: {
            questions: {
              $apply: map(q =>
                q.wizardId === questionId
                  ? { ...q, responses: [newResponse] }
                  : q,
              ),
            },
          },
        }),
    },
  };
};
