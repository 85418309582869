import React, { FocusEvent, useCallback, useState } from 'react';
import { Creatable } from '../../common/Select';
import { LabelSpaced } from '../Stepper.styles';
import { Note, Wrapper, ErrorNote, TextNote } from './EmailReceivers.styles';
import { SetFormValueFunctionType } from 'store/requests/actions';
import { parseEmail } from 'utils/parseEmail';
import { withTranslation } from 'react-i18next';
import { UploadLinkWrapper } from '../../common/UploadLink';
import useFlag from 'utils/hooks/useFlag';
import {
  MailingListItemType,
  TargetItemType,
  TranslatePropsType,
  ToggleEnum,
  UserType,
} from 'common/common.types';

interface EmailReceiversPropsType extends TranslatePropsType {
  isMulti?: boolean;
  mailingList: MailingListItemType[];
  readOnly?: boolean;
  setValue: SetFormValueFunctionType;
  showNote?: boolean;
  targets: TargetItemType[];
  getUserContactSummary: (email: string) => void;
  user: UserType;
}

const extractEmails = (text: string) => parseEmail(text) || [];

const EmailReceivers = ({
  mailingList,
  setValue,
  t,
  targets,
  readOnly = false,
  showNote = true,
  isMulti = true,
  getUserContactSummary,
  user,
}: EmailReceiversPropsType) => {
  const hasEmailFileUpload = useFlag(user, [ToggleEnum.EMAIL_FILE_UPLOAD]);
  const [isEmailFileFormatError, setEmailFileFormatError] = useState(false);
  const [isEmailLimitError, setEmailLimitError] = useState(false);

  const onReceiversChange = useCallback(
    (options: MailingListItemType[]) => {
      setEmailLimitError(false);
      const emails: MailingListItemType[] = [];

      options.forEach((option: MailingListItemType) => {
        // Support pasting multiple addresses from the keyboard, separated by space, new line, comma or semi-colon
        const splitEmails = option.value.split(/[ \n,;]/);
        splitEmails.forEach(token =>
          extractEmails(token).forEach((value: string) => {
            if (emails.length >= 100) {
              setEmailLimitError(true);
              return;
            }

            emails.push({ value, label: value });
          }),
        );
      });
      setValue('targets', emails);
    },
    [setValue],
  );

  const onReceiversBlur = (val: string) => {
    setEmailLimitError(false);
    setEmailFileFormatError(false);
    const emails = [...targets];

    extractEmails(val).forEach((value: string) => {
      emails.push({ value, label: value });
    });

    setValue('targets', emails);
  };

  const handleDropFiles = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach(file => {
        if (file.type === 'text/csv') {
          setEmailFileFormatError(false);
          const reader = new FileReader();
          reader.onload = e => {
            const binaryStr = JSON.stringify(reader.result);
            const binaryStrArray = binaryStr.replace(/(?:\\[rnt])+/g, ',');
            const removeWhiteSpace = binaryStrArray.replace(/^.|.$/g, '');
            const binaryStrCells = removeWhiteSpace
              ? removeWhiteSpace.split(',')
              : [];

            let emailList: MailingListItemType[] = [];
            binaryStrCells.forEach(curr => {
              const validEmail = extractEmails(curr);
              if (validEmail.length !== 0) {
                emailList.push({ label: curr, value: curr });
              }
            });

            emailList = [...targets, ...emailList];
            onReceiversChange(
              emailList.filter(
                (n, i, arr) =>
                  arr.findIndex(curr => curr.value === n.value) === i,
              ),
            );
          };
          reader.readAsText(file);
        } else {
          setEmailFileFormatError(true);
        }
      });
    },
    [targets, onReceiversChange],
  );

  return (
    <Wrapper data-cy={'sq-receivers'}>
      <LabelSpaced>{t('sendTo') as string}</LabelSpaced>

      <Creatable
        onChange={onReceiversChange}
        options={mailingList}
        placeholder={t('typeEmail') as string}
        value={targets}
        isMulti={isMulti}
        onBlur={(event: FocusEvent<HTMLInputElement>) =>
          onReceiversBlur(event.target.value)
        }
        formatCreateLabel={(value: string) => `add ${value}`}
        isDisabled={readOnly}
      />

      {isEmailFileFormatError && (
        <ErrorNote>{t('emailFormatErrorMessage') as string}</ErrorNote>
      )}
      {isEmailLimitError && (
        <ErrorNote>{t('emailExceedErrorMessage') as string}</ErrorNote>
      )}

      {showNote && (
        <Note>
          {t('sendToNote') as string}
          {hasEmailFileUpload && (
            <>
              <UploadLinkWrapper
                onDrop={handleDropFiles}
                linkText={t('uploadLinkText') as string}
                isMultiple={false}
                acceptFiles={'text/csv'}
              />
              <TextNote>{t('uploadExceedNote') as string}</TextNote>
            </>
          )}
        </Note>
      )}
    </Wrapper>
  );
};

export default withTranslation('SendRequest')(EmailReceivers);
