import { OptimisticActionPayloadType } from 'utils/optimistic/optimistic.types';

export interface RequestsClearSendRequestErrorActionType {
  type: '@companies/CLEAR_SEND_REQUEST_ERROR';
  payload: OptimisticActionPayloadType;
}

export const clearSendRequestError = () => ({
  type: '@companies/CLEAR_SEND_REQUEST_ERROR',
  payload: {
    expectedStateChange: {
      'requests.sendRequest.error': null,
    },
  },
});
