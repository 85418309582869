/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryDelegationStats,
    CategoryDelegationStatsFromJSON,
    CategoryDelegationStatsFromJSONTyped,
    CategoryDelegationStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface DelegationsDeleted
 */
export interface DelegationsDeleted {
    /**
     * 
     * @type {string}
     * @memberof DelegationsDeleted
     */
    category?: string;
    /**
     * 
     * @type {CategoryDelegationStats}
     * @memberof DelegationsDeleted
     */
    categoryStats?: CategoryDelegationStats;
    /**
     * 
     * @type {number}
     * @memberof DelegationsDeleted
     */
    deleted: number;
    /**
     * 
     * @type {string}
     * @memberof DelegationsDeleted
     */
    formId?: string;
    /**
     * 
     * @type {string}
     * @memberof DelegationsDeleted
     */
    questionId?: string;
}

export function DelegationsDeletedFromJSON(json: any): DelegationsDeleted {
    return DelegationsDeletedFromJSONTyped(json, false);
}

export function DelegationsDeletedFromJSONTyped(json: any, ignoreDiscriminator: boolean): DelegationsDeleted {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : json['category'],
        'categoryStats': !exists(json, 'category_stats') ? undefined : CategoryDelegationStatsFromJSON(json['category_stats']),
        'deleted': json['deleted'],
        'formId': !exists(json, 'form_id') ? undefined : json['form_id'],
        'questionId': !exists(json, 'question_id') ? undefined : json['question_id'],
    };
}

export function DelegationsDeletedToJSON(value?: DelegationsDeleted | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'category_stats': CategoryDelegationStatsToJSON(value.categoryStats),
        'deleted': value.deleted,
        'form_id': value.formId,
        'question_id': value.questionId,
    };
}


