import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button } from '../../../common/Button';
import {
  LanguageType,
  RequestType,
  TranslatePropsType,
  UserType,
} from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { FormsUpdateDueDateFunctionType } from 'store/forms/actions';
import { Spacer, DateWrapper } from './Details.styles';
import RequestDetails from './RequestDetails';

export interface DetailsPropsType extends TranslatePropsType {
  clearAnswers: () => void;
  createdAt: Date;
  isResponseEditable: boolean;
  isScoreVisible: boolean;
  isVendor: boolean;
  isVRM: boolean;
  lastFormStatusChangeDate: Date;
  percentageFilled: number;
  questionsCount: number;
  questionsFilledCount: number;
  request?: RequestType;
  score: number;
  updatedOn: Date;
  user: UserType;
  updateDueDate: FormsUpdateDueDateFunctionType;
  language: LanguageType;
}

const Details = ({
  clearAnswers,
  createdAt,
  isResponseEditable,
  isScoreVisible,
  isVendor,
  isVRM,
  percentageFilled,
  questionsCount,
  questionsFilledCount,
  request,
  score,
  updatedOn,
  updateDueDate,
  language,
  t,
}: DetailsPropsType) => {
  const showValidationScore = isVRM && isScoreVisible;

  return (
    <div data-cy="questionnaireDetail">
      {ReactHtmlParser(
        t('filledHTML', {
          questionsFilledCount,
          questionsCount,
          percentageFilled,
        }),
      )}
      {showValidationScore && (
        <DateWrapper data-test="validation-score">
          <Spacer />
          {ReactHtmlParser(t('validationScore', { score }))}
        </DateWrapper>
      )}

      {request && (
        <>
          <Spacer />

          <RequestDetails
            createdAt={createdAt}
            isVendor={isVendor}
            isVRM={isVRM}
            request={request}
            updatedOn={updatedOn}
            updateDueDate={updateDueDate}
            language={language}
          />
        </>
      )}

      {isResponseEditable && (
        <>
          <Spacer />

          <div data-test="clear-answers">
            <Button onClick={clearAnswers}>
              {t('clearAnswers') as string}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation('FormHeader')(Details);
