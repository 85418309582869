/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof Company
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof Company
     */
    updatedAt: Date;
    /**
     * Domain of the company
     * @type {string}
     * @memberof Company
     */
    domain: string;
    /**
     * SSC Grade A-F
     * @type {string}
     * @memberof Company
     */
    grade: CompanyGradeEnum;
    /**
     * Grade picture URL
     * @type {string}
     * @memberof Company
     */
    gradeUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    id: string;
    /**
     * Industry in which the company operates
     * @type {string}
     * @memberof Company
     */
    industry: string;
    /**
     * - `NORMAL` company has at least one user that signed in via platform.
     *   Companies of this type can be seen/searched in UI
     * - `SYSTEM` company is a special company that is used when system performs some actions
     *   There MUST be only one in the system
     *   Companies of this type should **not** be seen/searched in UI.
     * - `TEMPORARY` company has no user that has signed in via platform.
     *   When user sends request to new user they always belong to new TEMPORARY company.
     *   Companies of this type should **not** be seen/searched in UI.
     * - `DELETED` company is deleted :)
     *   Companies of this type should **not** be seen/searched in UI.
     *   Company can be deleted either by the script - `NORMAL` => `DELETED` or when user logs in for
     *   the first time and their company already exists and we merge it, then `TEMPORARY` =>
     *   `DELETED`.
     * @type {string}
     * @memberof Company
     */
    internalStatus: CompanyInternalStatusEnum;
    /**
     * SSC score change in last 30 days
     * @type {number}
     * @memberof Company
     */
    last30dayScoreChange: number;
    /**
     * Company logo uri
     * @type {string}
     * @memberof Company
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * Address to which notification email is sent after sending a form-filling request to
     * a company domain instead of a specific email. The email is sent only if `notify=true`
     * is set when sending the request.
     * @type {string}
     * @memberof Company
     */
    requestsEmail: string;
    /**
     * SSC Score
     * @type {number}
     * @memberof Company
     */
    score: number;
    /**
     * Word description of the company size
     * @type {string}
     * @memberof Company
     */
    size: string;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'domain': json['domain'],
        'grade': json['grade'],
        'gradeUrl': json['grade_url'],
        'id': json['id'],
        'industry': json['industry'],
        'internalStatus': json['internal_status'],
        'last30dayScoreChange': json['last30day_score_change'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'name': json['name'],
        'requestsEmail': json['requests_email'],
        'score': json['score'],
        'size': json['size'],
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'domain': value.domain,
        'grade': value.grade,
        'grade_url': value.gradeUrl,
        'id': value.id,
        'industry': value.industry,
        'internal_status': value.internalStatus,
        'last30day_score_change': value.last30dayScoreChange,
        'logo': value.logo,
        'name': value.name,
        'requests_email': value.requestsEmail,
        'score': value.score,
        'size': value.size,
    };
}

/**
* @export
* @enum {string}
*/
export enum CompanyGradeEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    F = 'F',
    Empty = ''
}
/**
* @export
* @enum {string}
*/
export enum CompanyInternalStatusEnum {
    DELETED = 'DELETED',
    NORMAL = 'NORMAL',
    SYSTEM = 'SYSTEM',
    TEMPORARY = 'TEMPORARY'
}


