import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { HOMEPAGE } from '../../routes';
import { Content, Wrapper } from 'common/common.styles';
import { PageHeader } from 'components/common/PageHeader';
import {
  Header,
  Left,
  Logo,
  Title,
  TitleWrapper,
  ErrorMessageWrapper,
  ErrorMessage,
  AtlasProductLogo,
} from './GeneralError.styles';

const GeneralError = () => {
  const { t } = useTranslation('GeneralError');
  return (
    <>
      <Header>
        <Left>
          <a href={HOMEPAGE}>
            <Logo src={'./atlas-logo.svg'} height={40} width={90} />
          </a>
        </Left>
      </Header>
      <PageHeader
        heading={
          <TitleWrapper>
            <Title>{t('error') as string}</Title>
          </TitleWrapper>
        }
      />
      <Wrapper grey landscape>
        <Content>
          <ErrorMessageWrapper>
            <ErrorMessage>
              {ReactHtmlParser(t('errorInformationHTML') as string)}
            </ErrorMessage>
            <AtlasProductLogo style={{ margin: '0 auto' }}>
              <Logo src={'./atlas-product-logo.svg'} height={140} width={180} />
            </AtlasProductLogo>
          </ErrorMessageWrapper>
        </Content>
      </Wrapper>
    </>
  );
};
export default GeneralError;
