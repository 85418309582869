import React, { ReactNode } from 'react';
import Waypoint from 'react-waypoint';
import InlineLoading, { InlineLoadingPropsType } from './InlineLoading';

interface WaypointCallbackArgsType {
  currentPosition: string;
  previousPosition: string;
  event: any;
  viewportBottom: number;
  viewportTop: number;
  waypointBottom: number;
  waypointTop: number;
}

export interface LoadMorePropsType {
  bottomOffset?: number | string;
  hasMore: boolean;
  onLoadMore: (args: WaypointCallbackArgsType) => void;
  onUnload?: (args: WaypointCallbackArgsType) => void;
  topOffset?: number | string;
  customLoader?: ReactNode;
  loader?: InlineLoadingPropsType;
}

const LoadMore = ({
  bottomOffset,
  customLoader,
  hasMore,
  loader,
  onLoadMore,
  onUnload,
  topOffset,
}: LoadMorePropsType) => {
  if (hasMore) {
    const waypointProps = {
      onEnter: onLoadMore,
      onLeave: onUnload,
      topOffset: topOffset,
      bottomOffset: bottomOffset,
    };
    return (
      <Waypoint {...waypointProps}>
        <div>{customLoader ? customLoader : <InlineLoading {...loader} />}</div>
      </Waypoint>
    );
  }
  return null;
};

export default LoadMore;
