/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestStatusStats
 */
export interface RequestStatusStats {
    /**
     * Number of days requests spent in ACCEPTED status
     * @type {number}
     * @memberof RequestStatusStats
     */
    aCCEPTED?: number;
    /**
     * Number of days requests spent in ARCHIVED status
     * @type {number}
     * @memberof RequestStatusStats
     */
    aRCHIVED?: number;
    /**
     * Number of days requests spent in CANCELED status
     * @type {number}
     * @memberof RequestStatusStats
     */
    cANCELED?: number;
    /**
     * Number of days requests spent in CREATED status
     * @type {number}
     * @memberof RequestStatusStats
     */
    cREATED?: number;
    /**
     * Number of days requests spent in UNARCHIVED status
     * @type {number}
     * @memberof RequestStatusStats
     */
    uNARCHIVED?: number;
    /**
     * Number of days requests spent in VENDOR_IN_PROGRESS status
     * @type {number}
     * @memberof RequestStatusStats
     */
    vENDORINPROGRESS?: number;
    /**
     * Number of days requests spent in VRM_IN_PROGRESS status
     * @type {number}
     * @memberof RequestStatusStats
     */
    vRMINPROGRESS?: number;
}

export function RequestStatusStatsFromJSON(json: any): RequestStatusStats {
    return RequestStatusStatsFromJSONTyped(json, false);
}

export function RequestStatusStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestStatusStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aCCEPTED': !exists(json, 'ACCEPTED') ? undefined : json['ACCEPTED'],
        'aRCHIVED': !exists(json, 'ARCHIVED') ? undefined : json['ARCHIVED'],
        'cANCELED': !exists(json, 'CANCELED') ? undefined : json['CANCELED'],
        'cREATED': !exists(json, 'CREATED') ? undefined : json['CREATED'],
        'uNARCHIVED': !exists(json, 'UNARCHIVED') ? undefined : json['UNARCHIVED'],
        'vENDORINPROGRESS': !exists(json, 'VENDOR_IN_PROGRESS') ? undefined : json['VENDOR_IN_PROGRESS'],
        'vRMINPROGRESS': !exists(json, 'VRM_IN_PROGRESS') ? undefined : json['VRM_IN_PROGRESS'],
    };
}

export function RequestStatusStatsToJSON(value?: RequestStatusStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ACCEPTED': value.aCCEPTED,
        'ARCHIVED': value.aRCHIVED,
        'CANCELED': value.cANCELED,
        'CREATED': value.cREATED,
        'UNARCHIVED': value.uNARCHIVED,
        'VENDOR_IN_PROGRESS': value.vENDORINPROGRESS,
        'VRM_IN_PROGRESS': value.vRMINPROGRESS,
    };
}


