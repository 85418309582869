import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button } from '../Filter/FilterBar.styles';
import { CloseIcon, Wrapper } from './DelegationNotifications.styles';
import { Flex, Marginator } from 'common/common.styles';
import { FormsClearDelegationNotificationsFunctionType } from 'store/forms/actions';
import {
  DelegationNotificationsDirectionType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface DelegationNotificationsPropsType extends TranslatePropsType {
  clearDelegationNotifications: FormsClearDelegationNotificationsFunctionType;
  direction: DelegationNotificationsDirectionType;
  formId: Uuid;
  openPrompt: () => void;
  questionsCount: number;
  usersCount: number;
}

const DelegationNotifications = ({
  clearDelegationNotifications,
  direction,
  formId,
  openPrompt,
  questionsCount,
  usersCount,
  t,
}: DelegationNotificationsPropsType) => (
  <Wrapper data-cy="delegation-notification">
    <Flex alignItems="center">
      {direction === 'forward' ? (
        <div>
          {ReactHtmlParser(t('assignedQuestions', { count: questionsCount }))}

          {ReactHtmlParser(t('assignedUsers', { count: usersCount }))}
        </div>
      ) : (
        <div>
          <strong>{t('finishedAnsweringHeading') as string}</strong>
        </div>
      )}

      <Marginator margin={{ left: 20 }}>
        <Button
          data-cy="notify-button"
          isActive={false}
          width={155}
          onClick={openPrompt}
        >
          {
            t(direction === 'forward' ? 'notifyUsers' : 'notifyAssigners', {
              count: usersCount,
            }) as string
          }
        </Button>
      </Marginator>
    </Flex>

    <div onClick={() => clearDelegationNotifications(direction, formId)}>
      <CloseIcon />
    </div>
  </Wrapper>
);

export default withTranslation('FormHeader')(DelegationNotifications);
