/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormDetail,
    FormDetailFromJSON,
    FormDetailFromJSONTyped,
    FormDetailToJSON,
    QuestionVisibility,
    QuestionVisibilityFromJSON,
    QuestionVisibilityFromJSONTyped,
    QuestionVisibilityToJSON,
    ResponseFilling,
    ResponseFillingFromJSON,
    ResponseFillingFromJSONTyped,
    ResponseFillingToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateResponseFilling
 */
export interface UpdateResponseFilling {
    /**
     * 
     * @type {FormDetail}
     * @memberof UpdateResponseFilling
     */
    form: FormDetail;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateResponseFilling
     */
    refresh: boolean;
    /**
     * 
     * @type {Array<QuestionVisibility>}
     * @memberof UpdateResponseFilling
     */
    refreshQuestions: Array<QuestionVisibility>;
    /**
     * 
     * @type {ResponseFilling}
     * @memberof UpdateResponseFilling
     */
    response: ResponseFilling;
}

export function UpdateResponseFillingFromJSON(json: any): UpdateResponseFilling {
    return UpdateResponseFillingFromJSONTyped(json, false);
}

export function UpdateResponseFillingFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateResponseFilling {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'form': FormDetailFromJSON(json['form']),
        'refresh': json['refresh'],
        'refreshQuestions': ((json['refresh_questions'] as Array<any>).map(QuestionVisibilityFromJSON)),
        'response': ResponseFillingFromJSON(json['response']),
    };
}

export function UpdateResponseFillingToJSON(value?: UpdateResponseFilling | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form': FormDetailToJSON(value.form),
        'refresh': value.refresh,
        'refresh_questions': ((value.refreshQuestions as Array<any>).map(QuestionVisibilityToJSON)),
        'response': ResponseFillingToJSON(value.response),
    };
}


