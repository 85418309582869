import axios from 'axios';
import withUrl from '../utils/url/withUrl';
import urlize from '../utils/url/urlize';
import {
  SupportedSettingsOptionType,
  SettingsStateType,
} from '../store/settings/settings.reducer';

export default {
  get: (params: SupportedSettingsOptionType[]) =>
    axios.get(withUrl(`users/current/settings?${urlize(params)}`)),
  set: (settingsDict: SettingsStateType) =>
    axios.put(withUrl('users/current/settings'), settingsDict),
};
