import { adjust, assoc, evolve, findIndex, propEq } from 'ramda';
import { put } from 'redux-saga/effects';
import { getStandardsForFormDefinitions } from './getStandardsForFormDefinitions';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormDefinitionsStandardsType } from '../formDefinitions.types';
import { RenameStandardParamsType } from '../../standard/actions';

import { DEFAULT_STANDARDS_STATUS } from '../defaultValues';

export type RenameStandardFromFormDefinitionsFunctionType = (
  params: RenameStandardFromFormDefinitionsParamsType,
) => void;

export type RenameStandardFromFormDefinitionsParamsType = RenameStandardParamsType;

export type RenameStandardFromFormDefinitionsActionType = OptimisticActionType<
  '@formDefinitions/API/RENAME_STANDARD',
  ApiCallActionPayloadType<RenameStandardFromFormDefinitionsParamsType>
>;

export const renameStandardFromFormDefinitions = ({
  id,
  name,
}: RenameStandardFromFormDefinitionsParamsType): RenameStandardFromFormDefinitionsActionType => ({
  type: '@formDefinitions/API/RENAME_STANDARD',
  payload: {
    endpointPath: ['standards', 'rename'],
    callParams: { id, name },
    expectedStateChange: {
      'formDefinitions.standards': (state: FormDefinitionsStandardsType) => {
        const dataIndex = findIndex(propEq('id', id), state.data);

        return evolve(
          {
            // @ts-ignore
            data: adjust(assoc('name', name), dataIndex),
          },
          state,
        );
      },
    },
    selector: null,
    postActions: [
      () =>
        put(
          getStandardsForFormDefinitions({ status: DEFAULT_STANDARDS_STATUS }),
        ),
    ],
  },
});
