/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    DelegationSummary,
    DelegationSummaryFromJSON,
    DelegationSummaryFromJSONTyped,
    DelegationSummaryToJSON,
    ResponseFilling,
    ResponseFillingFromJSON,
    ResponseFillingFromJSONTyped,
    ResponseFillingToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionDetailBase
 */
export interface QuestionDetailBase {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof QuestionDetailBase
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof QuestionDetailBase
     */
    updatedAt: Date;
    /**
     * Number of attachments for the question
     * @type {number}
     * @memberof QuestionDetailBase
     */
    attachmentCount: number;
    /**
     * True if the current user is allowed to add or remove question attachments
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    attachmentModifiable: boolean;
    /**
     * List of files (evidence) attached to the question
     * @type {Array<Attachment>}
     * @memberof QuestionDetailBase
     */
    attachments: Array<Attachment>;
    /**
     * Name of the category to which the question belongs and by which the questions can be
     * grouped.
     * @type {string}
     * @memberof QuestionDetailBase
     */
    category: string;
    /**
     * Unique code of the question visible in the UI
     * @type {string}
     * @memberof QuestionDetailBase
     */
    code: string;
    /**
     * Number of comments for the question
     * @type {number}
     * @memberof QuestionDetailBase
     */
    commentCount: number;
    /**
     * 
     * @type {Array<DelegationSummary>}
     * @memberof QuestionDetailBase
     */
    delegations?: Array<DelegationSummary>;
    /**
     * ID of the form to which the question belongs
     * @type {string}
     * @memberof QuestionDetailBase
     */
    formId: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    hasAttachment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    hasComment: boolean;
    /**
     * True if the question contains at least one response with answer N/A
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    hasNa: boolean;
    /**
     * True if the question contains at least one response with answer No
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    hasNo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    hasRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    hasRequiredFilled: boolean;
    /**
     * True if the question contains at least one response with answer Yes
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    hasYes: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionDetailBase
     */
    id: string;
    /**
     * True if the question should be visible (e.g. depending on the visibility condition)
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    isVisible: boolean;
    /**
     * ID of the company that owns the question
     * @type {string}
     * @memberof QuestionDetailBase
     */
    ownerId: string;
    /**
     * Integer that can be used to sort questions to get their positions in category. Note
     * that positions of neighboring items don't have to follow immediately one after another
     * (positions can be 3, 6, 20 instead of 1, 2, 3).
     * @type {number}
     * @memberof QuestionDetailBase
     */
    pos: number;
    /**
     * Text of the question
     * @type {string}
     * @memberof QuestionDetailBase
     */
    question: string;
    /**
     * Number of responses in the question
     * @type {number}
     * @memberof QuestionDetailBase
     */
    responseCount: number;
    /**
     * List of responses in the question
     * @type {Array<ResponseFilling>}
     * @memberof QuestionDetailBase
     */
    responses: Array<ResponseFilling>;
    /**
     * Number of filled responses in the question
     * @type {number}
     * @memberof QuestionDetailBase
     */
    responsesFilled: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionDetailBase
     */
    reviewInstructions?: string;
    /**
     * 
     * @type {object}
     * @memberof QuestionDetailBase
     */
    seenInfo?: object;
    /**
     * Current status of the request
     * @type {string}
     * @memberof QuestionDetailBase
     */
    vrmReviewStatus: QuestionDetailBaseVrmReviewStatusEnum;
}

export function QuestionDetailBaseFromJSON(json: any): QuestionDetailBase {
    return QuestionDetailBaseFromJSONTyped(json, false);
}

export function QuestionDetailBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionDetailBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'attachmentCount': json['attachment_count'],
        'attachmentModifiable': json['attachment_modifiable'],
        'attachments': ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'category': json['category'],
        'code': json['code'],
        'commentCount': json['comment_count'],
        'delegations': !exists(json, 'delegations') ? undefined : ((json['delegations'] as Array<any>).map(DelegationSummaryFromJSON)),
        'formId': json['form_id'],
        'hasAttachment': json['has_attachment'],
        'hasComment': json['has_comment'],
        'hasNa': json['has_na'],
        'hasNo': json['has_no'],
        'hasRequired': json['has_required'],
        'hasRequiredFilled': json['has_required_filled'],
        'hasYes': json['has_yes'],
        'id': json['id'],
        'isVisible': json['is_visible'],
        'ownerId': json['owner_id'],
        'pos': json['pos'],
        'question': json['question'],
        'responseCount': json['response_count'],
        'responses': ((json['responses'] as Array<any>).map(ResponseFillingFromJSON)),
        'responsesFilled': json['responses_filled'],
        'reviewInstructions': !exists(json, 'review_instructions') ? undefined : json['review_instructions'],
        'seenInfo': !exists(json, 'seen_info') ? undefined : json['seen_info'],
        'vrmReviewStatus': json['vrm_review_status'],
    };
}

export function QuestionDetailBaseToJSON(value?: QuestionDetailBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'attachment_count': value.attachmentCount,
        'attachment_modifiable': value.attachmentModifiable,
        'attachments': ((value.attachments as Array<any>).map(AttachmentToJSON)),
        'category': value.category,
        'code': value.code,
        'comment_count': value.commentCount,
        'delegations': value.delegations === undefined ? undefined : ((value.delegations as Array<any>).map(DelegationSummaryToJSON)),
        'form_id': value.formId,
        'has_attachment': value.hasAttachment,
        'has_comment': value.hasComment,
        'has_na': value.hasNa,
        'has_no': value.hasNo,
        'has_required': value.hasRequired,
        'has_required_filled': value.hasRequiredFilled,
        'has_yes': value.hasYes,
        'id': value.id,
        'is_visible': value.isVisible,
        'owner_id': value.ownerId,
        'pos': value.pos,
        'question': value.question,
        'response_count': value.responseCount,
        'responses': ((value.responses as Array<any>).map(ResponseFillingToJSON)),
        'responses_filled': value.responsesFilled,
        'review_instructions': value.reviewInstructions,
        'seen_info': value.seenInfo,
        'vrm_review_status': value.vrmReviewStatus,
    };
}

/**
* @export
* @enum {string}
*/
export enum QuestionDetailBaseVrmReviewStatusEnum {
    DONE = 'DONE',
    FLAGGED = 'FLAGGED',
    PENDING = 'PENDING'
}


