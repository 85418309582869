import React, { useCallback, useMemo } from 'react';
import { Select } from '../../common/Select';
import { components } from 'react-select';
import {
  Wrapper,
  WrapperForLabelWithInputBelow,
  issuesSelectStyles,
} from './IssuesPanel.styles';
import { useTranslation } from 'react-i18next';
import Label from '../../common/typography/Label';
import IntenseLabel from '../../common/typography/IntenseLabel';
import {
  FactorWithIssuesSelectOption,
  IssueSelectOption,
} from 'store/formCreator/selectors/issues';
import { UpdateQuestionSscIssuesFunctionType } from 'store/formCreator/actions/updateQuestionSscIssues';
import { OptionProps } from 'react-select/lib/components/Option';
import { OptionsType } from 'react-select/lib/types';
import { Uuid, ValueLabelType } from 'common/common.types';
import { find } from 'ramda';
import { SelectComponents } from 'react-select/lib/components';

const formatGroupLabel = ({ label }: { label: string }) => (
  <strong>{label}</strong>
);

export interface IssuesPanelProps {
  factorsWithIssues: FactorWithIssuesSelectOption[];
  questionId: Uuid;
  questionIssues: string[];
  updateQuestionSscIssues: UpdateQuestionSscIssuesFunctionType;
}

const IssueSelectOptionComponent = React.memo(
  (props: OptionProps<IssueSelectOption>) => {
    // ^^ The props.cx and props.getValue get changed on each mouse hover, so the
    // React.memo doesn't have much effect there.

    return <components.Option {...props}>{props.label}</components.Option>;
  },
);

const ISSUES_SELECT_COMPONENTS: Partial<SelectComponents<IssueSelectOption>> = {
  Option: IssueSelectOptionComponent,
};

const IssuesPanel = ({
  factorsWithIssues,
  questionId,
  questionIssues,
  updateQuestionSscIssues,
}: IssuesPanelProps) => {
  const { t } = useTranslation('FormCreator');
  const onIssuesChange = useCallback(
    (issuesSelectedOptions: OptionsType<ValueLabelType<string>> | null) => {
      updateQuestionSscIssues(
        questionId,
        issuesSelectedOptions
          ? issuesSelectedOptions.map(({ value }) => value)
          : [],
      );
    },
    [updateQuestionSscIssues, questionId],
  );
  const selectedOptions = useMemo(() => {
    const newSelectedOptions: Array<ValueLabelType<string>> = [];
    questionIssues.forEach(issueId => {
      find(factor => {
        const maybeIssue = find(
          issue => issue.value === issueId,
          factor.options,
        );
        if (maybeIssue) {
          newSelectedOptions.push(maybeIssue);
        }
        // Don't search the rest of issues when the required issue was found.
        return Boolean(maybeIssue);
      }, factorsWithIssues);
    });
    return newSelectedOptions;
  }, [questionIssues, factorsWithIssues]);

  return (
    <Wrapper>
      <Label>{t('issuesPanel.introduction') as string}</Label>
      <WrapperForLabelWithInputBelow>
        <IntenseLabel>
          {t('issuesPanel.associatedScorecardIssues') as string}
        </IntenseLabel>
        <Select
          components={ISSUES_SELECT_COMPONENTS}
          formatGroupLabel={formatGroupLabel}
          isMulti={true}
          onChange={onIssuesChange}
          options={factorsWithIssues}
          placeholder={t('issuesPanel.selectIssueTypes') as string}
          styles={issuesSelectStyles}
          value={selectedOptions}
        />
      </WrapperForLabelWithInputBelow>
    </Wrapper>
  );
};

export default IssuesPanel;
