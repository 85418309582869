import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Heading, Wrapper } from './Droppable.styles';
import { TranslatePropsType } from 'common/common.types';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const Droppable = ({ t }: TranslatePropsType) => {
  const faPlusCircleProp = faPlusCircle as IconProp;
  const label = t('dropFiles') as string;
  return (
    <Wrapper>
      <FontAwesomeIcon size={'3x'} icon={faPlusCircleProp} />

      <Heading>{label}</Heading>
    </Wrapper>
  );
};

export default withTranslation('EvidenceLocker')(Droppable);
