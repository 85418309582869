/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { User } from "./User";

/**
 * 
 * @export
 * @interface DelegationNotificationStatsMetadata
 */
export interface DelegationNotificationStatsMetadata {
    /**
     * Number of newly assigned questions user can be notified about
     * @type {number}
     * @memberof DelegationNotificationStatsMetadata
     */
    count: number;
    /**
     * Direction in which delegation notification is sent
     * 
     * - `forward` - Assignor notifies assignee: "Please fill these questions."
     * - `backward` - Assignee notifies assignor: "These questions are now filled."
     * @type {string}
     * @memberof DelegationNotificationStatsMetadata
     */
    direction: DelegationNotificationStatsMetadataDirectionEnum;
    /**
     * User to whom the notification can be sent
     * @type {User}
     * @memberof DelegationNotificationStatsMetadata
     */
    user: User;
}




/**
* @export
* @enum {string}
*/
export enum DelegationNotificationStatsMetadataDirectionEnum {
    Backward = 'backward',
    Forward = 'forward'
}


