import styled from 'styled-components';

export const Label = styled.span<{ isRecurrent?: boolean }>`
  ${({ theme, isRecurrent }) => (isRecurrent ? theme.fonts.bold.normal : null)}
`;

export const Description = styled.span`
  margin-left: 10px;
  font-style: italic;
  ${({ theme }) => theme.fonts.regular.small}
`;
