import styled, { css } from 'styled-components';
import ReactSVG from 'react-svg';

export const Header = styled.header`
  position: 'fixed';
  top: 0;
  width: 100%;
  height: 50px;
  background-color: #222324;
  display: flex;
  flex-direction: row;
`;

export const Group = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  min-width: 1140px;
`;

export const Left = styled(Group)`
  align-self: flex-start;
  padding: 7px 0;
`;

export const Logo = styled(ReactSVG).attrs(
  (props: { src: string; height: number; width: number }) => ({
    src: props.src,
    height: props.height,
    width: props.width,
  }),
)`
  svg {
    ${({ height, width }) =>
      css`
        width: ${width}px;
        height: ${height}px;
      `};
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  background: #ffffff;
  padding-left: 25px;
  padding-top: 35px;
  padding-bottom: 34px;
  width: 1140px;
  min-height: 70px;
`;

export const Title = styled.div`
  font-size: 25px;
  font-weight: bold;
  font-family: 'Proxima Nova Bold';
`;

export const ErrorMessageWrapper = styled.div`
  width: 1140px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  background: #ffffff;
  min-height: 368px;
`;

export const ErrorMessage = styled.div`
  padding-top: 40px;
  padding-left: 40px;
  font-size: 21px;
  font-weight: 200;
  font-family: 'Proxima Nova Light';
  line-height: 50px;
`;

export const AtlasProductLogo = styled.div`
  margin: 0 auto;
`;
