import ParsingMessage from '../../common/ParsingMessage';
import React from 'react';
import uuid from 'utils/uuid';
import { ParsedFile } from './Standards.styles';
import { withTranslation } from 'react-i18next';
import {
  ParsedFileType,
  TranslatePropsType,
  UploadStatusEnum,
} from 'common/common.types';
import { Link } from 'react-router-dom';
import formatRoute, { FORM } from '../../../routes';

interface UploadResultPropsType extends TranslatePropsType {
  hasParsingErrors: boolean;
  parsedFiles: ParsedFileType[];
  showFormLink?: boolean;
}

const PROCESSING_MESSAGES = new Set<UploadStatusEnum | undefined>([
  UploadStatusEnum.ACCEPTED,
  UploadStatusEnum.FINISHED,
  UploadStatusEnum.PARSING,
  UploadStatusEnum.MAPPING,
]);

const UploadResult = ({
  parsedFiles,
  showFormLink,
  t,
}: UploadResultPropsType) => (
  <>
    {parsedFiles.map(
      ({ fileName, formId, hasError, parsingMessages, status }) => (
        <ParsedFile
          key={`parsed-file-${fileName}-${uuid()}`}
          data-cy="upload-result-parsed-file"
        >
          <strong>{fileName}</strong>
          {!hasError && PROCESSING_MESSAGES.has(status) && (
            <ParsingMessage
              message={t('UploadResult.status', { context: `${status}` })}
              severity={
                status !== UploadStatusEnum.FINISHED ? 'PROCESSING' : 'SUCCESS'
              }
            >
              {formId && showFormLink && status === UploadStatusEnum.FINISHED && (
                <Link to={formatRoute(FORM, { id: formId })} target="_blank">
                  {t('UploadResult.openForm') as string}
                </Link>
              )}
            </ParsingMessage>
          )}
          {parsingMessages.map(({ id, message, severity }) => (
            <ParsingMessage
              key={`parsing-message-${id}`}
              message={message}
              severity={severity}
            />
          ))}
        </ParsedFile>
      ),
    )}
  </>
);

export default withTranslation('components')(UploadResult);
