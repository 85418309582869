import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

export const DescriptionWrapper = styled.section`
  width: 400px;
  padding-right: 40px;
`;

export const DescriptionHeader = styled.h3`
  color: ${({ theme }) => theme.basic.colors.black};
  font-weight: ${({ theme }) => theme.basic.fontWeights.normal};
  padding-bottom: 10px;
`;

export const Paragraph = styled.p`
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: ${({ theme }) => theme.basic.fontWeights.light};
  font-size: ${({ theme }) => theme.basic.fontSizes.normal};
`;

export const CancelButton = styled.button`
  cursor: pointer;
  background: ${({ theme }) => theme.atlas.white};
  font-weight: ${({ theme }) => theme.basic.fontWeights.light};
  font-size: ${({ theme }) => theme.basic.fontSizes.normal};
  border: none;
  border-radius: 3px;
  padding: 11px 20px;
  height: fit-content;
  align-self: center;
  margin-right: 25px;
`;

export const PreviewContainer = styled.section`
  width: calc(100% - 400px);
`;

export const PreviewBackground = styled.img`
  width: 100%;
`;

export const LogoWrapper = styled.div`
  padding: 15px 0 10px;
  width: 100%;
  background: ${({ theme }) => theme.atlas.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PreviewLogo = styled.img`
  height: 60px;
`;

export const Scroller = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  max-height: 420px;
  padding: 40px 35px 20px 35px;
  background: ${({ theme }) => theme.basic.colors.lighterBorder};
  border: 2px solid #979797;
`;
