import { SharedFormType } from '../../../common/common.types';

export interface FormsSetSharedDataActionType {
  type: '@forms/SET_SHARED_DATA';
  payload: {
    sharedFormData: SharedFormType;
  };
}

export const setSharedData = (
  sharedFormData: SharedFormType,
): FormsSetSharedDataActionType => ({
  type: '@forms/SET_SHARED_DATA',
  payload: { sharedFormData },
});
