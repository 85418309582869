import styled, { css } from 'styled-components/macro';
import {
  allChildrenFlexed,
  Horizontal,
  spacingBetweenChildren,
  Vertical,
} from 'common/common.styles';
import { default as OrigTextarea } from '../../../common/Textarea';
import { Button } from '../../../common/Button/Button.styles';
import colors from 'common/common.colors';

export const DIALOG_PADDING_PX = 20;

export const Label = styled.div`
  color: ${({ theme }) => theme.atlas.text.secondary};
  ${({ theme }) => theme.fonts.regular.xsmall};
  margin-top: 6px;

  &:first-child {
    margin-top: 0;
  }
`;

export const CenterTextButton = styled(Button).attrs({
  color: 'transparent',
})`
  color: ${({ theme }) => theme.atlas.teal.primary};
  border: none;
  display: block;
  width: 100%;
  line-height: 20px;
  text-decoration: underline;

  ${({ theme }) => theme.fonts.bold.small}

  ${({ disabled }: { disabled?: boolean }) => {
    if (disabled) {
      return css`
        background-color: initial;
        cursor: not-allowed;
      `;
    } else {
      return null;
    }
  }};
`;

export const Wrapper = styled(Vertical)`
  ${spacingBetweenChildren(6)}

  // !!! Context aware props...
  // 69vh = Dropdown.styles.ts / Inner / max-height
  // 40px = 20+20px = Dropdown.styles.ts / Inner / padding
  max-height: calc(69vh - 40px);
`;

export const Textarea = styled(OrigTextarea)`
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  color: ${({ theme }) => theme.atlas.text.primary};
  letter-spacing: 0.25px;
  line-height: 20px;
  min-height: 74px;
`;

export const AddedFindingTextArea = styled(Textarea)`
  flex-grow: 1;
`;

export const Separator = styled.hr`
  border: 0;
  height: 1px;
  margin-left: -${DIALOG_PADDING_PX}px;
  margin-right: -${DIALOG_PADDING_PX}px;
  background: ${({ theme }) => theme.basic.colors.darkerBorder};
  flex-shrink: 0;
`;

export const AddedFindingWrapper = styled(Horizontal)`
  ${spacingBetweenChildren(DIALOG_PADDING_PX, 'horizontal')}
  ${allChildrenFlexed}
`;

export const AddedFindingColumn = styled(Vertical)`
  ${spacingBetweenChildren(7)}
`;

export const ButtonBar = styled.div`
  padding: 8px ${DIALOG_PADDING_PX}px;
  text-align: right;
  border-top: 1px solid ${({ theme }) => theme.basic.colors.darkerBorder};

  & > ${Button} {
    height: 32px;
    line-height: 32px;
    ${({ theme }) => theme.fonts.bold.small}
  }

  ${spacingBetweenChildren(8, 'horizontal')}

  // !!! Context aware props...

  margin-left: -${DIALOG_PADDING_PX}px;
  margin-right: -${DIALOG_PADDING_PX}px;
  // increase specificity to override spacing between children
  &&& {
    margin-bottom: -${DIALOG_PADDING_PX}px;
  }
`;

export const DownloadButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CheckboxWrapper = styled.div`
  padding-right: 17px;
`;

export const StyledDownloadButton = styled.a`
  margin: 4px 0;
  margin-left: 8px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  border: none;
  position: relative;
  white-space: nowrap;
  padding: 0 12px;
  border-radius: 7px;

  height: 32px;
  line-height: 32px;
  ${({ theme }) => theme.fonts.bold.small}

  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.4 : 1)};
  background-color: ${colors.elements.button.teal.background};
  color: ${colors.elements.button.teal.color};

  ${({ disabled }: { disabled?: boolean }) => {
    if (disabled) {
      return css`
        background-color: #a5adb0;
        color: #fff;
        cursor: not-allowed;
      `;
    } else {
      return null;
    }
  }}
`;
