import { TagType } from '../../../common/common.types';

export interface CompaniesAddTagToFilterActionType {
  type: '@companies/ADD_TAG_TO_FILTER';
  payload: TagType;
}

export const addTagToFilter = (
  tag: TagType,
): CompaniesAddTagToFilterActionType => ({
  type: '@companies/ADD_TAG_TO_FILTER',
  payload: tag,
});
