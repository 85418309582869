import {
  DateWrapper,
  DueDateWrapper,
  Spacer,
  TextStyle,
} from './Details.styles';
import { differenceInDays, format } from 'date-fns';
import ReactHtmlParser from 'react-html-parser';
import React, { useState } from 'react';
import { DATE_FORMAT_BASED_ON_LOCALE } from '../../../../common/common.constants';
import {
  LanguageType,
  RequestType,
  TranslatePropsType,
} from 'common/common.types';
import getLocale from '../../../../utils/getLocale';
import { withTranslation } from 'react-i18next';
import getGroupedStatus from 'utils/getGroupedStatus';
import Datepicker from '../../../common/Datepicker';
import { Wrapper } from '../../../common/Datepicker/Datepicker.styles';
import { FormsUpdateDueDateFunctionType } from 'store/forms/actions';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { StateType } from '../../../../store/store.types';
import { getLanguagePreference } from '../../../../store/settings/settings.selectors';
import { connect } from 'react-redux';

interface RequestDetailsPropsType extends TranslatePropsType {
  createdAt: Date;
  isVendor: boolean;
  isVRM: boolean;
  request: RequestType;
  updatedOn: Date;
  updateDueDate: FormsUpdateDueDateFunctionType;
  language: LanguageType;
}

const RequestDetails = ({
  createdAt: createdAtRaw,
  isVendor,
  isVRM,
  request: {
    dueDate: dueDateRaw,
    meta: { receiver, sender },
    revisionCount,
    status: statusRaw,
    id,
  },
  updatedOn: updatedOnRaw,
  updateDueDate,
  t,
  language,
}: RequestDetailsPropsType) => {
  const status = statusRaw && getGroupedStatus(statusRaw, isVendor);
  const dueDate =
    dueDateRaw && language
      ? format(dueDateRaw, DATE_FORMAT_BASED_ON_LOCALE[language.value], {
          locale: getLocale(language),
        })
      : undefined;
  const [isEditable, setEditable] = useState(false);
  const [newDueDate, setDueDate] = useState(dueDateRaw);
  const daysLeft = dueDateRaw
    ? differenceInDays(dueDateRaw, new Date())
    : undefined;
  const isPastDue = daysLeft && daysLeft < 0 ? true : false;
  const updatedOn =
    language &&
    format(updatedOnRaw, DATE_FORMAT_BASED_ON_LOCALE[language.value], {
      locale: getLocale(language),
    });

  const createdAt =
    language &&
    format(createdAtRaw, DATE_FORMAT_BASED_ON_LOCALE[language.value], {
      locale: getLocale(language),
    });
  const transceivers = isVendor ? sender : receiver;
  const showRevision = revisionCount > 1;
  const DueDatePicker = () => {
    if (isEditable) {
      return (
        <Datepicker
          data-cy={'sq-due-date-picker'}
          data-pendo="update-due-date"
          className={'datepicker-input'}
          dateFormat={DATE_FORMAT_BASED_ON_LOCALE[language.value]}
          onChange={val => {
            setDueDate(val ? val : undefined);
          }}
          minDate={new Date()}
          selected={newDueDate}
        />
      );
    } else {
      return (
        <DueDateWrapper isPastDue={isPastDue}>
          {ReactHtmlParser(t('dueDateHTML', { dueDate }))}
        </DueDateWrapper>
      );
    }
  };

  const onHandleInput = () => {
    if (isEditable) {
      updateDueDate(id, newDueDate ? newDueDate : undefined);
    }
    return setEditable(!isEditable);
  };
  return (
    <>
      <DateWrapper data-test="sent-on">
        {ReactHtmlParser(
          t(isVendor ? 'receivedHTML' : 'sentHTML', { createdAt }),
        )}
      </DateWrapper>

      {transceivers && (
        <DateWrapper data-test="sent-by">
          {ReactHtmlParser(
            t(isVendor ? 'sentByHTML' : 'sentToHTML', { transceivers }),
          )}
        </DateWrapper>
      )}

      {dueDate && (
        <DateWrapper data-test="due-date">
          {ReactHtmlParser(t('dueDateLabel') as string)}
          {isVRM ? (
            <Wrapper>
              <DueDatePicker />
              <TextStyle onClick={() => onHandleInput()}>
                {ReactHtmlParser(t(isEditable ? 'saveDate' : 'editDate'))}
              </TextStyle>
            </Wrapper>
          ) : (
            <DueDateWrapper isPastDue={isPastDue}>
              {ReactHtmlParser(t('dueDateHTML', { dueDate }))}
            </DueDateWrapper>
          )}
        </DateWrapper>
      )}

      <DateWrapper data-test="last-updated">
        {ReactHtmlParser(t('updatedAtHTML', { updatedOn }))}
      </DateWrapper>

      <Spacer />

      <DateWrapper data-test="current-state">
        {ReactHtmlParser(t('currentState', { status }))}
      </DateWrapper>

      {showRevision && (
        <DateWrapper data-test="revision">
          {ReactHtmlParser(t('revisionHTML', { revisionCount }))}
        </DateWrapper>
      )}
    </>
  );
};

const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation('FormHeader')(RequestDetails));
