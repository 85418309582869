import { ApiCallActionPayloadType } from '../../store.types';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { PdfExportSummary } from '../../../generated/models/PdfExportSummary';
import { FormsStateType } from '../forms.reducer';
import { downloadFindingsPdf } from './downloadFindingsPdf';
import { put } from 'redux-saga/effects';
import { Uuid } from '../../../common/common.types';

export interface FormsExportFindingsToPdfCallParamsType {
  page_query?: string;
  pdf_params?: string;
  request_id: Uuid;
}

export type FormsExportFindingsToPdfFunctionType = (
  formId: Uuid,
  requestId: Uuid,
  withNotes: boolean,
) => void;

export type FormsExportFindingsToPdfActionType = OptimisticActionType<
  '@forms/API/EXPORT_FINDINGS_TO_PDF',
  ApiCallActionPayloadType<
    FormsExportFindingsToPdfCallParamsType,
    PdfExportSummary,
    never,
    FormsStateType
  >
>;

export const exportFindingsToPdf = (
  formId: Uuid,
  requestId: Uuid,
  withNotes: boolean,
): FormsExportFindingsToPdfActionType => ({
  type: '@forms/API/EXPORT_FINDINGS_TO_PDF',
  payload: {
    endpointPath: ['requests', 'findings', 'startExportToPdf'],
    callParams: {
      request_id: requestId,
      page_query: withNotes ? 'includeRiskNote=true' : undefined,
    },
    expectedStateChange: {
      'forms.findingsExporting': true,
    },
    selector: null,
    postActions: [
      ({ export_uuid }: PdfExportSummary) =>
        put(downloadFindingsPdf(formId, requestId, export_uuid)),
    ],
  },
});
