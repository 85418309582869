import { RequestType, Uuid } from '../../../common/common.types';
import { ApiCallActionType } from '../../store.types';
import { FormProxyStateType } from '../formProxy.reducer';
import { showModal } from 'utils/Modal';
import { FORM_PROXY_MODAL_ID } from '../../../common/common.constants';
import { put } from 'redux-saga/effects';
import update from 'immutability-helper';
import { parseCollaborator } from 'utils/parsers/fromProxy.parsers';
import { Collaborator } from '../../../generated/models/Collaborator';

export interface FormProxyShowProxyModalCallParamsType {
  formId: Uuid;
}

export type FormProxyShowProxyModalFunctionType = (
  request: RequestType,
) => void;

export type FormProxyShowProxyModalActionType = ApiCallActionType<
  '@formProxy/API/SHOW_MODAL',
  FormProxyShowProxyModalCallParamsType,
  Collaborator[],
  never,
  FormProxyStateType
>;

export const showProxyModal = (
  request: RequestType,
): FormProxyShowProxyModalActionType => ({
  type: '@formProxy/API/SHOW_MODAL',
  payload: {
    callParams: { formId: request.form.id },
    endpointPath: ['forms', 'proxies', 'list'],
    selector: (data, state): Partial<FormProxyStateType> =>
      update(state.formProxy, {
        request: { $set: request },
        collaborators: { $set: data.map(parseCollaborator) },
      }),
    postActions: [() => put(showModal(FORM_PROXY_MODAL_ID))],
  },
});
