import React from 'react';
import { Badge as BadgeElement } from './Badge.styles';

export type BadgeColorType =
  | 'blue'
  | 'gray'
  | 'green'
  | 'seaGreen'
  | 'white'
  | 'yellow'
  | string;

export type BadgeSizeType = 'small' | 'medium' | 'big';

export interface BadgeType {
  children: number;
  color?: BadgeColorType;
  size?: BadgeSizeType;
}

export const Badge = ({ color, children, size }: BadgeType) => (
  <BadgeElement color={color} size={size}>
    {children}
  </BadgeElement>
);
