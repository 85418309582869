import React from 'react';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { BarWrapper } from 'components/common/Bar/Bar.styles';
import { NewVendorHeadingText } from './NewVendorLanding.styles';

interface HeaderType extends TranslatePropsType {}

const NewVendorLandingHeader = ({ t }: HeaderType) => (
  <BarWrapper>
    <NewVendorHeadingText data-test="page-header" style={{ fontSize: 24 }}>
      {t('heading') as string}
    </NewVendorHeadingText>
  </BarWrapper>
);

export default withTranslation('NewVendorLanding')(NewVendorLandingHeader);
