import styled from 'styled-components';

type DropZoneProps = {
  isActive: boolean;
  hasError: boolean;
  success: boolean;
};

export const DropWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  width: 100%;
`;

export const DropZone = styled.div<DropZoneProps>`
  background: ${({ theme, isActive, hasError, success }) =>
    isActive || hasError || success ? '#ffffff' : '#d5dcdc'};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: ${({ theme, isActive, hasError, success }) =>
    isActive
      ? `2px dashed ${theme.basic.colors.blue}`
      : hasError
      ? `2px dashed ${theme.basic.colors.error}`
      : success
      ? `2px dashed ${theme.basic.colors.success}`
      : '2px dashed #979797'};
  color: ${({ theme, isActive, hasError }) =>
    isActive
      ? theme.basic.colors.blue
      : hasError
      ? theme.basic.colors.error
      : theme.basic.colors.extraDark};
  text-align: center;
  font-weight: ${({ theme }) => theme.basic.fontWeights.bold};
  font-size: ${({ theme }) => theme.basic.fontSizes.medium};
  height: 200px;
  padding: 20px;
`;

export const DropzoneContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoThumbnail = styled.img`
  width: 100px;
  height: auto;
  margin: 1rem;
`;

export const FileUpload = styled.label`
  color: ${({ theme }) => theme.basic.colors.blue};
  font-size: ${({ theme }) => theme.basic.fontSizes.medium};
  cursor: pointer;
  letter-spacing: 0.5px;
  margin-top: 15px;

  input {
    display: none;
  }
`;
