import styled, { css } from 'styled-components';
import { FontSizeType } from 'common/common.fonts';

interface CheckboxContainerType {
  hideCheckmark?: boolean;
  isCompact?: boolean;
  isDisabled?: boolean;
  isIndeterminate?: boolean;
  labelSize?: FontSizeType;
  marginTop?: number;
  primaryColor?: string;
  secondaryColor?: string;
  size?: number;
  hasValue?: boolean;
}

export const Checkmark = styled.span`
  position: absolute;
  top: 15px;
  left: -4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.basic.colors.light};
  border: 2px ${({ theme }) => theme.basic.colors.darkerBorder} solid;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const Checkmarkable = css<CheckboxContainerType>`
  ${Checkmark} {
    ${({ marginTop, size }) =>
      size
        ? {
            width: size,
            height: size,
            marginTop: marginTop ? marginTop : -size / 2,
          }
        : { width: 16, height: 16, marginTop: marginTop ? marginTop : -8 }};
  }

  ${({ isIndeterminate, theme, size, secondaryColor, primaryColor }) =>
    isIndeterminate
      ? `
        ${Checkmark} {
          border: none;
          background-color: ${
            secondaryColor
              ? theme.basic.colors[secondaryColor]
              : theme.basic.colors.light
          } !important;
        }

        ${Checkmark}:after {
          display: block !important;
          width: 10px;
          height: 2px;
          transform: none;
          top: 7px !important;
          border: none;
          background: ${theme.basic.colors.light};
        }`
      : `${Checkmark}:after {
          transform: rotate(45deg);
          left: ${size ? size * 0.25 : 3}px;
          top: ${size ? size * 0.0625 - 1 : -1}px;
          width: ${size ? size * 0.25 : 4}px;
          height: ${size ? size * 0.5625 : 9}px;
          border: solid
            ${
              primaryColor
                ? theme.basic.colors[primaryColor]
                : theme.basic.colors.light
            };
          border-width: ${
            size ? `0 ${size / 8}px ${size / 8}px 0` : '0 2px 2px 0'
          };
        }`}

  &:hover ${Checkmark} {
    ${({ isDisabled, theme, isIndeterminate }) =>
      isDisabled || isIndeterminate
        ? {}
        : {
            display: 'block',
            backgroundColor: theme.inputs.multiselect.default.hover,
          }};
  }

  & input:checked ~ ${Checkmark} {
    background-color: ${({ theme, secondaryColor }) =>
      secondaryColor
        ? theme.basic.colors[secondaryColor]
        : theme.basic.colors.light};
    border: 2px
      ${({ theme, secondaryColor }) =>
        secondaryColor
          ? theme.basic.colors[secondaryColor]
          : theme.basic.colors.light}
      solid;
  }

  & input:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

export const CheckboxContainer = styled.div<CheckboxContainerType>`
  display: flex;
  align-items: baseline;
  height: 40px;
  padding-top: 5px;
  padding-bottom: ${({ isCompact }) => (isCompact ? 0 : 4)}px;
  position: relative;
  padding-left: ${({ size }) => (size ? size * 1.1 : 24)}px;
  margin-bottom: ${({ isCompact }) => (isCompact ? 0 : 12)}px;
  color: ${({ theme }) => theme.basic.colors.dark};
  user-select: ${({ isDisabled }) => (isDisabled ? 'text' : 'none')};
  ${({ theme, labelSize }) =>
    theme.fonts.light[labelSize ? labelSize : 'normal']}

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    z-index: 10;
  }
  ${({ hideCheckmark }) => !hideCheckmark && Checkmarkable}

  ${({ isDisabled, hasValue, theme }) =>
    isDisabled
      ? !hasValue
        ? `
        & ${Checkmark} {
           border-color: ${theme.basic.colors.lighterBorder};
        }

        & ${Label} {
          color: ${theme.basic.colors.disabled};
        }
        `
        : {}
      : { cursor: 'pointer' }}
`;

export const Label = styled.div`
  line-height: 20px;
`;

export const TemplateCheckBox = styled(CheckboxContainer as any)`
  height: 100%;
  padding-top: 0px;
  padding-bottom: 20px;
`;
