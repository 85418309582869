export interface ApiCallSuccessParamsType {
  domain: string;
  data: object;
  meta: object;
  selector: ((data: any, state?: any, meta?: object) => object | null) | null;
}

export interface ApiCallSuccessActionType {
  type: '@app/API_CALL_SUCCESS' | string;
  payload: ApiCallSuccessParamsType;
}

export const apiCallSuccess = (
  params: ApiCallSuccessParamsType,
): ApiCallSuccessActionType => ({
  type: '@app/API_CALL_SUCCESS',
  payload: params,
});
