import styled, { css } from 'styled-components';
import getResponseFontSize from '../../utils/getResponseFontSize';
import { OPTION_NA, OPTION_NO, OPTION_YES } from '../../utils/normalizeOption';

const pickValueTheme = (
  value: string,
  yes: string,
  no: string,
  na: string,
  implicit?: string,
) => {
  switch (value) {
    case OPTION_YES:
      return yes;
    case OPTION_NO:
      return no;
    case OPTION_NA:
      return na;
    default:
      return implicit || na;
  }
};

export const Input = styled.input`
  display: none;
`;

interface ComponentLabelType {
  disabled: boolean;
  hasBeenTouched: boolean;
  hasValue: boolean;
  isSelected: boolean;
  isYesNoNA: boolean;
  normalizedValue: string;
}

export const RadioLabel = styled.label<ComponentLabelType>`
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin: 0 8px 8px 0;
  min-height: 30px;
  padding: 8px 16px;
  font-size: ${({ theme: { basic } }) => basic.fontSizes.normal};
  ${({ theme: { basic } }) => basic.fontWeights.semibold};
  opacity: ${({ hasValue, isSelected }) => (hasValue && !isSelected ? 0.3 : 1)};

  background-color: ${({
    hasBeenTouched,
    isSelected,
    normalizedValue,
    theme,
  }) =>
    isSelected
      ? pickValueTheme(
          normalizedValue,
          theme.inputs.certainty.colors.yes,
          theme.inputs.certainty.colors.no,
          theme.inputs.certainty.colors.na,
          theme.inputs.radio.selected.background,
        )
      : hasBeenTouched
      ? theme.basic.colors.light
      : theme.inputs.radio.default.background};

  border: 1px solid
    ${({ isSelected, normalizedValue, theme }) =>
      isSelected
        ? pickValueTheme(
            normalizedValue,
            theme.inputs.certainty.colors.yes,
            theme.inputs.certainty.colors.no,
            theme.inputs.certainty.colors.na,
            theme.inputs.radio.selected.border,
          )
        : theme.inputs.radio.default.border};

  color: ${({ hasBeenTouched, isSelected, theme }) =>
    isSelected
      ? theme.inputs.radio.selected.text
      : hasBeenTouched
      ? theme.inputs.radio.default.border
      : theme.inputs.radio.default.text};

  ${({ isSelected, isYesNoNA, normalizedValue, theme }) =>
    !isSelected &&
    css`
      &:hover:not([disabled]) {
        background-color: ${pickValueTheme(
          normalizedValue,
          theme.inputs.certainty.hover.yes,
          theme.inputs.certainty.hover.no,
          theme.inputs.certainty.hover.na,
          theme.inputs.radio.hover.background,
        )};
        border-color: ${theme.inputs.radio.hover.border};
        color: ${isYesNoNA
          ? theme.inputs.certainty.hover.border
          : theme.inputs.radio.hover.text};
      }
    `}

  ${({ isYesNoNA, normalizedValue }) =>
    normalizedValue === OPTION_NA
      ? css`
          ${OptionWrapper} {
            text-transform: uppercase;
          }
        `
      : isYesNoNA &&
        css`
          ${OptionWrapper} {
            text-transform: lowercase;
          }

          ${OptionWrapper}::first-letter {
            text-transform: capitalize;
          }
        `}

  &[disabled] {
    cursor: initial;
    background-color: ${({ theme }) => theme.inputs.radio.disabled.background};
    border-color: ${({ theme }) => theme.inputs.radio.disabled.border};
    color: ${({ theme }) => theme.inputs.radio.disabled.text};
    font-size: ${({ theme }) => theme.basic.fontSizes.small};

    ${({ isSelected, normalizedValue, theme }) =>
      isSelected &&
      css`
        color: ${pickValueTheme(
          normalizedValue,
          theme.inputs.certainty.colors.yes,
          theme.inputs.certainty.colors.no,
          theme.inputs.certainty.colors.na,
          theme.atlas.text.primary,
        )};
        font-size: ${normalizedValue &&
          `${getResponseFontSize(normalizedValue)}px`};
      `}
  }
`;

export const LongRadioLabel = styled.label<ComponentLabelType>`
  display: block;
  padding: 11px 0 11px 37px;
  position: relative;
  color: ${({ theme }) => theme.atlas.text.secondary};
  cursor: pointer;
  ${({ theme }) => theme.fonts.regular.small};

  &:before {
    position: absolute;
    background: ${({ theme }) => theme.atlas.white};
    border: 2px solid
      ${({ isSelected, theme: { atlas } }) =>
        isSelected ? atlas.teal.primary : atlas.grey[500]};
    border-radius: 50%;
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    top: 12px;
    left: 0;
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      &:after {
        content: '';
        position: absolute;
        background: ${theme.atlas.teal.primary};
        border-radius: 50%;
        width: 10px;
        height: 10px;
        left: 5px;
        top: 17px;
      }
    `}

  &:hover {
    &:before {
      border: 2px solid ${({ theme }) => theme.atlas.teal.primary};
    }
  }

  &[disabled] {
    cursor: initial;
    background-color: ${({ theme }) => theme.inputs.radio.disabled.background};
    border-color: ${({ theme }) => theme.inputs.radio.disabled.border};
    color: ${({ theme }) => theme.inputs.radio.disabled.text};
    font-size: ${({ theme }) => theme.basic.fontSizes.small};
    padding-left: 0;

    &:before,
    &:after {
      display: none;
    }

    ${({ isSelected, theme, normalizedValue }) =>
      isSelected &&
      css`
        color: ${theme.atlas.text.primary};
        font-size: ${normalizedValue &&
          `${getResponseFontSize(normalizedValue)}px`};
      `}
  }
`;

export const MaturityLabel = styled(RadioLabel)`
  width: 30px;
`;

export const OptionWrapper = styled.div``;
