/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupportTicket
 */
export interface SupportTicket {
    /**
     * 
     * @type {Date}
     * @memberof SupportTicket
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    ownerId: string;
    /**
     * 
     * @type {number}
     * @memberof SupportTicket
     */
    ticketStatus: number;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    ticketStatusCode: SupportTicketTicketStatusCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof SupportTicket
     */
    ticketType: number;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    ticketTypeCode: SupportTicketTicketTypeCodeEnum;
    /**
     * 
     * @type {Date}
     * @memberof SupportTicket
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    userId: string;
}

export function SupportTicketFromJSON(json: any): SupportTicket {
    return SupportTicketFromJSONTyped(json, false);
}

export function SupportTicketFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportTicket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'id': json['id'],
        'ownerId': json['owner_id'],
        'ticketStatus': json['ticket_status'],
        'ticketStatusCode': json['ticket_status_code'],
        'ticketType': json['ticket_type'],
        'ticketTypeCode': json['ticket_type_code'],
        'updatedAt': (new Date(json['updated_at'])),
        'userId': json['user_id'],
    };
}

export function SupportTicketToJSON(value?: SupportTicket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'id': value.id,
        'owner_id': value.ownerId,
        'ticket_status': value.ticketStatus,
        'ticket_status_code': value.ticketStatusCode,
        'ticket_type': value.ticketType,
        'ticket_type_code': value.ticketTypeCode,
        'updated_at': (value.updatedAt.toISOString()),
        'user_id': value.userId,
    };
}

/**
* @export
* @enum {string}
*/
export enum SupportTicketTicketStatusCodeEnum {
    OPEN = 'OPEN',
    INPROGRESS = 'IN_PROGRESS',
    CLOSED = 'CLOSED'
}
/**
* @export
* @enum {string}
*/
export enum SupportTicketTicketTypeCodeEnum {
    CREDITAPPEAL = 'CREDIT_APPEAL'
}


