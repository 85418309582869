import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { SettingsStateType } from '../settings.reducer';
import { reject } from 'ramda';
import update from 'immutability-helper';
import { Uuid } from '../../../common/common.types';

export type SettingsToggleFrameworkVisibilityActionType = OptimisticActionType<
  '@settings/API/SET_FRAMEWORK_VISIBILITY',
  ApiCallActionPayloadType<SettingsStateType>
>;

const updateVisibility = (
  frameworkId: Uuid,
  visible: boolean,
  hiddenFrameworks: Uuid[] = [],
) =>
  visible
    ? reject(it => it === frameworkId, hiddenFrameworks || [])
    : [...hiddenFrameworks, frameworkId];

export const setFrameworkVisibility = (
  frameworkId: Uuid,
  visible: boolean,
): SettingsToggleFrameworkVisibilityActionType => ({
  type: '@settings/API/SET_FRAMEWORK_VISIBILITY',
  payload: {
    endpointPath: ['settings', 'set'],
    expectedStateChange: {
      settings: (settings: SettingsStateType) =>
        update(settings, {
          hiddenFrameworks: {
            $set: updateVisibility(
              frameworkId,
              visible,
              settings.hiddenFrameworks,
            ),
          },
        }),
    },
    // state is already updated by expectedStateChange
    callParams: ({ settings: { hiddenFrameworks } }) => ({
      hiddenFrameworks,
    }),
    selector: null,
  },
});
