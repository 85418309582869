import React from 'react';

// Selectors
import * as basicSelectors from 'store/formCreator/selectors/basic';
import {
  ValidationStats,
  getValidationStats,
} from 'store/formCreator/selectors/validationStats';
import {
  getRenderData,
  resetRenderData,
} from 'store/formCreator/selectors/renderData';

import Page, { PageBaseType, PageActionsBaseType, PagePropsType } from './Page';
import { StateType, ActionType } from 'store/store.types';
import { Dispatch, bindActionCreators } from 'redux';
import FormCreator from 'components/FormCreator/FormCreator';
import * as actions from 'store/formCreator/actions';
import { startLoading, endLoading } from 'store/app/actions';
import {
  FormDefinitionSummaryDraftExtendedType,
  FormType,
  Uuid,
  UserType,
  FormCreatorRenderType,
  FormDefinitionValidationType,
  LanguageType,
} from 'common/common.types';

import { resetModals } from 'utils/Modal/store/actions/resetModals';
import { showModal, hideModal } from 'utils/Modal/';
import {
  FormsLoadQuestionsFunctionType,
  FormsPutQuestionFunctionType,
  getPreview as getPreviewForm,
  loadQuestions,
  putQuestion,
  resetForm as resetPreviewForm,
} from 'store/forms/actions';
import { isInErrorState } from 'store/formCreator/eventSequencer/eventSequencer.selectors';
import {
  FormCreatorResourceGoneStateType,
  OpenedQuestionType,
} from 'store/formCreator/formCreator.types';
import {
  FactorWithIssuesSelectOption,
  getFactorsWithIssuesAsSelectOptions,
} from 'store/formCreator/selectors/issues';

export interface FormCreatorDataPropsType {
  areOnlyInvalidQuestionsVisible: boolean;
  areQuestionsDeletable: boolean;
  currentForm?: FormDefinitionSummaryDraftExtendedType;
  factorsWithIssues: FactorWithIssuesSelectOption[];
  isSaving: boolean;
  lastSavedUpdate?: Date;
  openedQuestion?: OpenedQuestionType;
  previewForm?: FormType;
  renderData: FormCreatorRenderType[];
  resourceGone?: FormCreatorResourceGoneStateType;
  savingError: boolean;
  user: UserType;
  validation: FormDefinitionValidationType;
  validationStats: ValidationStats;
  language?: LanguageType;
}

export interface FormCreatorContextActionsType {
  addCategory: actions.AddCategoryFunctionType;
  addQuestion: actions.AddQuestionFunctionType;
  addResponse: actions.AddResponseFunctionType;
  deleteCategory: actions.DeleteCategoryFunctionType;
  deleteQuestion: actions.DeleteQuestionFunctionType;
  deleteResponse: actions.DeleteResponseFunctionType;
  duplicateQuestion: actions.DuplicateQuestionFunctionType;
  getPreview: actions.GetPreviewFunctionType;
  getPreviewForm: (id: Uuid) => void;
  hideModal: (modalId: string) => void;
  loadQuestions: FormsLoadQuestionsFunctionType;
  setOpenedQuestion: actions.SetOpenedQuestionFunctionType;
  publishForm: actions.PublishFormFunctionType;
  putQuestion: FormsPutQuestionFunctionType;
  renameCategory: actions.RenameCategoryFunctionType;
  resetPreviewForm: () => void;
  showModal: (modalId: string) => void;
  showOnlyInvalidQuestions: actions.ShowOnlyInvalidQuestionsFunctionType;
  switchQuestionConditionType: actions.SwitchQuestionConditionTypeFunctionType;
  updateFormDefinition: actions.UpdateFormDefinitionFunctionType;
  updateQuestionCode: actions.UpdateQuestionCodeFunctionType;
  updateQuestionCondition: actions.UpdateQuestionConditionFunctionType;
  updateQuestionSscIssues: actions.UpdateQuestionSscIssuesFunctionType;
  updateQuestionText: actions.UpdateQuestionTextFunctionType;
  updateQuestionType: actions.UpdateQuestionTypeFunctionType;
  updateResponseDefinition: actions.UpdateResponseDefinitionFunctionType;
  moveQuestion: actions.MoveQuestionFunctionType;
  moveQuestionLocally: actions.MoveQuestionLocallyFunctionType;
  moveCategory: actions.MoveCategoryFunctionType;
  moveCategoryLocally: actions.MoveQuestionLocallyFunctionType;
  cleanValidation: actions.CleanValidationFunctionType;
  reloadData: (draftId: Uuid) => void;
  validateForm: actions.ValidateFormFunctionType;
  getForm: (formId: Uuid) => actions.GetFormActionType;
}
export interface FormCreatorPageContextType extends PageBaseType {
  data: FormCreatorDataPropsType;
  actions: PageActionsBaseType & FormCreatorContextActionsType;
}

const FormCreatorPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType): FormCreatorDataPropsType => ({
      areQuestionsDeletable: basicSelectors.areQuestionsDeletable(
        state.formCreator,
      ),
      areOnlyInvalidQuestionsVisible:
        state.formCreator.areOnlyInvalidQuestionsVisible,
      currentForm: basicSelectors.getCurrentForm(state.formCreator),
      factorsWithIssues: getFactorsWithIssuesAsSelectOptions(state.formCreator),
      validationStats: getValidationStats(state.formCreator),
      isSaving: state.formCreator.eventSequencer.isWaitingForResponse,
      lastSavedUpdate: state.formCreator.eventSequencer.lastSavedUpdate,
      openedQuestion: state.formCreator.openedQuestion,
      previewForm: state.forms.currentForm,
      user: state.user.currentUser,
      renderData: getRenderData(
        state.formCreator.currentForm,
        state.formCreator.validation,
      ),
      savingError: isInErrorState(state.formCreator.eventSequencer),
      validation: state.formCreator.validation,
      resourceGone: state.formCreator.resourceGoneState,
      language: state.settings.languagePreference,
    })}
    dispatches={(dispatch: Dispatch<ActionType>, ownProps: PagePropsType) => ({
      getData: ({
        routeParams: { id },
        data,
      }: {
        routeParams: { id: string };
        data: FormCreatorDataPropsType;
      }) => {
        dispatch(actions.getIssues());
        if (data.currentForm && data.currentForm.id === id) {
          // We already have data prepared from some previous screen...
          dispatch(endLoading());
          if (data.currentForm.questions.length < 1) {
            dispatch(actions.addFirstQuestion());
          }
          return;
        }
        dispatch(startLoading(1));
        dispatch(actions.getForm(id));
      },
      reset: () => {
        dispatch(actions.resetForm());
        dispatch(resetPreviewForm());
        dispatch(resetModals());
        resetRenderData();
      },
      actions: {
        ...bindActionCreators(
          {
            addCategory: actions.addCategory,
            addQuestion: actions.addQuestion,
            addResponse: actions.addResponse,
            deleteCategory: actions.deleteCategory,
            deleteQuestion: actions.deleteQuestion,
            deleteResponse: actions.deleteResponse,
            duplicateQuestion: actions.duplicateQuestion,
            getPreview: actions.getPreview,
            getPreviewForm,
            hideModal,
            loadQuestions,
            putQuestion,
            publishForm: actions.publishForm,
            renameCategory: actions.renameCategory,
            resetPreviewForm,
            setOpenedQuestion: actions.setOpenedQuestion,
            showModal,
            showOnlyInvalidQuestions: actions.showOnlyInvalidQuestions,
            switchQuestionConditionType: actions.switchQuestionConditionType,
            updateFormDefinition: actions.updateFormDefinition,
            updateQuestionCode: actions.updateQuestionCode,
            updateQuestionCondition: actions.updateQuestionCondition,
            updateQuestionSscIssues: actions.updateQuestionSscIssues,
            updateQuestionText: actions.updateQuestionText,
            updateQuestionType: actions.updateQuestionType,
            updateResponseDefinition: actions.updateResponseDefinition,
            moveQuestion: actions.moveQuestion,
            moveQuestionLocally: actions.moveQuestionLocally,
            moveCategory: actions.moveCategory,
            moveCategoryLocally: actions.moveCategoryLocally,
            cleanValidation: actions.cleanValidation,
            validateForm: actions.validateForm,
            getForm: actions.getForm,
          },
          dispatch,
        ),
        navigate: ownProps.history.push,
        reloadData: (draftId: Uuid) => {
          dispatch(startLoading(1));
          dispatch(resetModals());
          dispatch(actions.getForm(draftId));
          dispatch(actions.getIssues());
        },
      },
    })}
  >
    <FormCreator />
  </Page>
);

export default FormCreatorPage;
