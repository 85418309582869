import { DelegationNotificationStatsMetadata } from 'generated/models/DelegationNotificationStatsMetadata';
import { DelegationSummary } from 'generated/models/DelegationSummary';
import { parseUser } from '.';
import { propEq } from 'ramda';
import {
  DelegationType,
  DelegationNotificationsDirectionType,
  FormAssignee,
} from 'common/common.types';
import { UserDelegable } from 'generated/models/UserDelegable';
import { EMPTY_ID } from 'common/common.constants';

const parseDelegationNotificationsDirection = (
  stats: DelegationNotificationStatsMetadata[],
  direction: DelegationNotificationsDirectionType,
) => {
  const directionStats = stats.filter(propEq('direction', direction));

  return {
    count: directionStats.reduce(
      (sum: number, { count }: DelegationNotificationStatsMetadata) =>
        sum + count,
      0,
    ),
    users: directionStats.map(parseUser),
  };
};

export const parseDelegationNotifications = (
  stats: DelegationNotificationStatsMetadata[],
) => ({
  backward: parseDelegationNotificationsDirection(stats, 'backward'),
  forward: parseDelegationNotificationsDirection(stats, 'forward'),
});

export const parseDelegation = (
  delegation: DelegationSummary,
): DelegationType => {
  const {
    id = EMPTY_ID,
    assignee = {},
    assignor = {},
    owner_id = EMPTY_ID,
    question_id = EMPTY_ID,
  } = delegation;

  return {
    delegationId: id,
    assignee: parseUser({ user: assignee }),
    assignor: parseUser({ user: assignor }),
    ownerId: owner_id,
    questionId: question_id,
  };
};

export const parseAssignee = (user: UserDelegable): FormAssignee => {
  const {
    created_at,
    updated_at,
    company_id = EMPTY_ID,
    display_name = '',
    domain = '',
    email = '',
    first_name = '',
    full_name = '',
    last_name = '',
    id = EMPTY_ID,
    is_active = false,
    is_proxy = false,
  } = user;

  return {
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    companyId: company_id,
    displayName: display_name,
    domain,
    email,
    firstName: first_name,
    fullName: full_name,
    lastName: last_name,
    id,
    isActive: is_active,
    isProxy: is_proxy,
  };
};
