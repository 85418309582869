import React, { useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import route, { FORM_CREATOR } from '../../routes';
import { PreviewModal } from 'components/SendRequest/Modals';
import { SortType } from 'utils/filters/formDefinitions.sorters';
import VirtualizedList from 'utils/virtualizer/src/VirtualizedList';
import {
  TranslatePropsType,
  StandardType,
  FormType,
  UserType,
  Uuid,
  LanguageType,
} from 'common/common.types';
import { FORM_PREVIEW_MODAL_ID } from 'common/common.constants';
import FortDefinitionsSorter from './FormDefinitionsSorter';
import FormDefinitionRow from './FormDefinitionsRow';
import { Button } from '../common/Button';
import { STANDARD_HEIGHT } from './FormDefinitionsRow.styles';
import {
  ModalHeaderAdditionalContent,
  TextButton,
} from './FormDefinitionsList.styles';
import { StandardStatusEnum } from 'generated/models/Standard';
import { getPreviewHelper } from 'store/formCreator/previewUtils';
import { FormDefinitionsContextActionsType } from 'pages/FormDefinitionsPage';
import { ConfirmModal, useConfirmModal } from '../common/ConfirmModal';
import Controls from './FormDefinitionControls/Controls';
import { isEmpty } from 'ramda';

export interface ListPropsType extends RouteComponentProps, TranslatePropsType {
  actions: FormDefinitionsContextActionsType;
  data: {
    standards: StandardType[];
    sortBy: SortType;
    previewForm: FormType;
    user: UserType;
    selected: Uuid[];
    language: LanguageType;
  };
}

const FormDefinitionsList = ({
  actions: {
    setSort,
    getPreview,
    getPreviewForm,
    showModal,
    hideModal,
    loadQuestions,
    putQuestion,
    resetPreviewForm,
    toggleStandardAndContinue,
    deleteFromFormDefinitions,
    renameStandardFromFormDefinitions,
    duplicateStandardFromFormDefinitions,
    publishDraftFromFormDefinitions,
    unpublishStandardFromFormDefinitions,
    createEmptyForm,
    toggleFormDefinitionSelection,
  },
  data: { standards, sortBy, previewForm, user, selected, language },
  history: { push: navigate },
  t,
}: ListPropsType) => {
  const { showConfirm, onCancel, onConfirm } = useConfirmModal(
    deleteFromFormDefinitions,
    showModal,
    hideModal,
  );

  const handlePreviewClick = useCallback(
    (standard: StandardType) => {
      if (
        typeof standard.definitionId === 'undefined' ||
        standard.definitionId === null
      ) {
        return;
      }
      getPreviewHelper(standard, standard.definitionId, {
        getPreview,
        getPreviewForm,
      });
      showModal(FORM_PREVIEW_MODAL_ID);
    },
    [getPreview, getPreviewForm, showModal],
  );

  const useThisAsTemplateButton =
    previewForm && previewForm.standard ? (
      <Button
        color={'teal'}
        size={'medium'}
        disabled={
          !previewForm.standard.definitionId ||
          previewForm.standard.status !== StandardStatusEnum.DRAFT
        }
        onClick={
          typeof previewForm.standard.definitionId !== 'undefined'
            ? () => {
                navigate(
                  route(FORM_CREATOR, {
                    id: previewForm.standard.definitionId,
                  }),
                );
              }
            : undefined
        }
      >
        <>{t('FormDefinitions:editThisQuestionnaire')}</>
      </Button>
    ) : (
      <></>
    );

  const modalHeaderAdditional = (
    <ModalHeaderAdditionalContent>
      {useThisAsTemplateButton}
      <TextButton
        size={'small'}
        color={'atlas'}
        onClick={() => createEmptyForm()}
      >
        {`${t('FormDefinitions:orStartFromScratch')}`}
      </TextButton>
    </ModalHeaderAdditionalContent>
  );

  const selectedCount = selected.length;
  const draftCount = standards.filter(curr => curr.status === 'DRAFT').length;

  return (
    <>
      {standards.length > 0 && (
        <>
          <Controls
            actions={{
              deleteFromFormDefinitions: showConfirm,
            }}
            data={{
              controlsStatus: {
                canDelete: !isEmpty(selected) ? true : false,
              },
              selected,
              selectedCount,
              draftCount,
            }}
          />

          <ConfirmModal
            confirmText={t('SendRequest:deleteConfirm', {
              count: selectedCount,
            })}
            text={t('SendRequest:deleteConfirmText', { count: selectedCount })}
            onConfirm={onConfirm}
            onCancel={onCancel}
          />

          <PreviewModal
            loadQuestions={loadQuestions}
            modalId={FORM_PREVIEW_MODAL_ID}
            previewForm={previewForm}
            putQuestion={putQuestion}
            user={user}
            headerEnhanced={modalHeaderAdditional}
            onClose={resetPreviewForm}
            language={language}
          />
          <FortDefinitionsSorter
            setSort={setSort}
            sortBy={sortBy}
            toggleFormDefinitionSelection={toggleFormDefinitionSelection}
            draftCount={draftCount}
            selectedCount={selectedCount}
            standards={standards}
            selected={selected}
          />

          <VirtualizedList
            overScan={5}
            items={standards}
            itemHeight={STANDARD_HEIGHT}
            renderItem={(standard: StandardType) => (
              <FormDefinitionRow
                key={`standard-list-${standard.id}`}
                data={{
                  standard,
                  isSelected: selected.includes(standard.definitionId as Uuid),
                  selected,
                  language,
                }}
                actions={{
                  onPreview: handlePreviewClick,
                  toggleFormDefinitionSelection,
                  toggleStandardAndContinue,
                  deleteFromFormDefinitions: showConfirm,
                  renameStandardFromFormDefinitions,
                  duplicateStandardFromFormDefinitions,
                  publishDraftFromFormDefinitions,
                  unpublishStandardFromFormDefinitions,
                }}
              />
            )}
          />
        </>
      )}
    </>
  );
};

export default withRouter(
  withTranslation(['FormDefinitions', 'SendRequest'])(FormDefinitionsList),
);
