import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

export const Icon = styled(FontAwesomeIcon)`
  height: 16px;
  margin-right: 10px;
`;

export const GoBack = styled.div`
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  align-items: center;
`;
