import { CheckboxGroup, Header, Section, Title } from './FilterBy.styles';
import { FilterByPropsType } from './FilterBy';
import { assocPath } from 'ramda';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Checkbox from '../../common/Checkbox';

const OPTION = 'INTERNAL';

const FilterInternal = ({
  data: { filterBy },
  actions: { setFilter },
  t,
}: FilterByPropsType) => {
  const handleChange = () => {
    setFilter(
      assocPath([OPTION], { isEnabled: !filterBy[OPTION].isEnabled }, filterBy),
    );
  };

  return (
    <Section>
      <Header>
        <Title>{t(`Requests.filterOptions.${OPTION}`) as string}</Title>
      </Header>
      <CheckboxGroup>
        <Checkbox
          isCompact
          isChecked={!!filterBy[OPTION].isEnabled}
          label={t(`Requests.internal`) as string}
          labelSize="small"
          secondaryColor="teal"
          onChange={handleChange}
        />
      </CheckboxGroup>
    </Section>
  );
};

export default withTranslation()(FilterInternal);
