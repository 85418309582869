import React from 'react';
import { differenceInDays, format } from 'date-fns';
import { SmallSub, Wrapper, Strong } from './DueDateText.styles';
import { LanguageType, TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { StateType } from '../../../../store/store.types';
import { getLanguagePreference } from '../../../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import { DATE_FORMAT_DAY } from '../../../../common/common.constants';
import getLocale from 'utils/getLocale';

interface DueDateTextPropsType extends TranslatePropsType {
  align?: 'right' | 'left' | 'center';
  dueDate: Date;
  acceptedDate: Date;
  language: LanguageType;
}

const DueDateText = ({
  align,
  dueDate,
  acceptedDate,
  language,
  t,
}: DueDateTextPropsType) => {
  const days = differenceInDays(dueDate, acceptedDate);

  return (
    <Wrapper align={align}>
      <SmallSub>{t('common:dueDate.received') as string}</SmallSub>
      <Strong>
        {format(new Date(acceptedDate), DATE_FORMAT_DAY, {
          locale: getLocale(language),
        })}
      </Strong>
      <Strong>
        {days === 0 && (t('common:dueDate.today') as string)}
        {days !== 0 &&
          (days > 0
            ? `${t('common:dueDate.dayWithCount', {
                count: Math.abs(days),
              }) as string} ${t('common:dueDate.early') as string}`
            : `${t('common:dueDate.dayWithCount', {
                count: Math.abs(days),
              }) as string} ${t('common:dueDate.late') as string}`)}
      </Strong>
    </Wrapper>
  );
};

const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation(['common'])(DueDateText));
