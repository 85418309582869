import styled from 'styled-components';

export const Wrapper = styled.div`
  transition: 0.5s ease-in-out;
`;

export const Header = styled.div`
  cursor: pointer;
  margin: 1px 0;
`;
