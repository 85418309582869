import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

import {
  CTA,
  UploadReccomendations,
  UploadWrapper,
  Reccomendations,
} from './Cobranding.styles';

import {
  DropWrapper,
  DropZone,
  DropzoneContent,
  LogoThumbnail,
  FileUpload,
} from './UploadLanding.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface UploadLandingType {
  updateCobrandingLogoFile: Dispatch<SetStateAction<undefined>>;
}

const UploadLanding = ({ updateCobrandingLogoFile }: UploadLandingType) => {
  useEffect(() => {
    const ondragover = window.ondragover;
    const ondrop = window.ondrop;

    window.ondragover = (event: any) => {
      event.preventDefault();
      return false;
    };
    window.ondrop = (event: any) => {
      event.preventDefault();
      return false;
    };

    return () => {
      window.ondragover = ondragover;
      window.ondrop = ondrop;
    };
  });
  const [dragState, updateDragState] = useState('inactive');
  const [imageUrl, updateImageUrl] = useState('');

  const [selectedFile, updateSelected] = useState({ name: '' });
  const isActive = dragState === 'active';
  const hasError = dragState === 'error-size' || dragState === 'error-type';
  const success = dragState === 'success';
  const { t } = useTranslation('Cobranding');

  const handleDrag = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (dragState === 'inactive' && !selectedFile.name.length) {
      updateDragState('active');
    } else if (dragState === 'active' && !selectedFile.name.length) {
      updateDragState('inactive');
    }
  };

  let fileChosen: any;
  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    fileChosen = event.dataTransfer
      ? event.dataTransfer.files[0]
      : event.target.files[0];

    const validity =
      fileChosen.type === 'image/png' || fileChosen.type === 'image/gif';

    if (!validity) {
      updateDragState('error-type');
      return;
    }

    if (fileChosen.size <= 50000) {
      updateSelected(fileChosen);
      updateCobrandingLogoFile(fileChosen);
      updateDragState('success');
      updateImageUrl(URL.createObjectURL(fileChosen));
      return;
    }

    updateDragState('error-size');
  };

  const statusText = () => {
    switch (dragState) {
      case 'inactive':
        return t('dragState.stateInactive');
      case 'active':
        return t('dragState.stateActive');
      case 'success':
        return `${selectedFile.name}`;
      case 'error-size':
        return 'File is too large';
      case 'error-type':
        return 'File is not a .png or .gif';
      default:
        return null;
    }
  };

  return (
    <UploadWrapper>
      <DropWrapper>
        <DropZone
          isActive={isActive}
          hasError={hasError}
          success={success}
          onDragEnter={event => handleDrag(event)}
          onDragLeave={event => handleDrag(event)}
          onDrop={event => handleDrop(event)}
          data-pendo="settings-drag-drop-logo"
        >
          <DropzoneContent>
            {imageUrl && !hasError && (
              <LogoThumbnail alt="uploaded logo thumbnail" src={imageUrl} />
            )}
            {statusText()}
          </DropzoneContent>
        </DropZone>
        <FileUpload htmlFor="file-upload">
          <FontAwesomeIcon icon={faUpload as IconProp} />{' '}
          {t('uploadImage') as string}
          <input
            id="file-upload"
            type="file"
            onChange={event => handleDrop(event)}
            accept=".png, .gif"
            data-pendo="settings-upload-logo"
          />
        </FileUpload>
      </DropWrapper>

      <UploadReccomendations>
        <CTA>{t('suggestion') as string}</CTA>
        <Reccomendations>
          <li>{t('suggestionList.logoPixel') as string}</li>
          <li>{t('suggestionList.logoSize') as string}</li>
          <li>{t('suggestionList.logoBackground') as string}</li>
          <li>{t('suggestionList.logoFiletype') as string}</li>
        </Reccomendations>
      </UploadReccomendations>
    </UploadWrapper>
  );
};

export default UploadLanding;
