import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import stringToColor from 'utils/stringToColor';

export const Container = styled.div<{ name: string }>`
  ${({ theme }) => theme.basic.fontSizes.small};
  height: 24px;
  border-radius: 12px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  margin-right: 3px;
  margin-bottom: 4px;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  z-index: 1;

  background-color: ${({ name }: { name: string }) => stringToColor(name)};

  &:hover .tagDeleteButton {
    opacity: 1;
    margin: 0 4px;
  }

  &:hover .tagName {
    position: relative;
    left: 0;
  }
`;

export const Name = styled.span<{ editable: boolean }>`
  ${({ theme }) => theme.fonts.bold.small}
  color: #fff;
  padding: 0 5px;
  position: relative;
  left: ${({ editable }: { editable: boolean }) => (editable ? 7 : 0)}px;
  width: auto;
  transition: all 0.3s ease-in-out;
`;

export const DeleteButton = styled(FontAwesomeIcon)<{ editable: 'yes' | 'no' }>`
  margin: 0 0;
  opacity: 0;
  width: 0;
  transition: all 0.3s ease-in-out;
  ${({ editable }: { editable: 'yes' | 'no' }) =>
    editable === 'yes' ? '' : { display: 'none' }};
  cursor: pointer;

  &:hover {
    color: #2d2d2d;
  }
`;
