import { OptimisticUpdateActionType } from '../optimistic.types';

export const optimisticUpdate = (
  transactionId: string,
  expectedStateChange: object,
): OptimisticUpdateActionType => ({
  type: '@optimistic/OPTIMISTIC_UPDATE',
  transactionId,
  payload: {
    expectedStateChange,
  },
});
