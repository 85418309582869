/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { User } from "./User";

/**
 * 
 * @export
 * @interface CategoryDelegationStats
 */
export interface CategoryDelegationStats {
    /**
     * 
     * @type {User}
     * @memberof CategoryDelegationStats
     */
    assignee?: User;
    /**
     * 
     * @type {string}
     * @memberof CategoryDelegationStats
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDelegationStats
     */
    quantity: CategoryDelegationStatsQuantityEnum;
    /**
     * 
     * @type {string}
     * @memberof CategoryDelegationStats
     */
    scope: CategoryDelegationStatsScopeEnum;
}




/**
* @export
* @enum {string}
*/
export enum CategoryDelegationStatsQuantityEnum {
    MULTIPLEUSERS = 'MULTIPLE_USERS',
    SINGLEUSER = 'SINGLE_USER',
    NOUSER = 'NO_USER'
}
/**
* @export
* @enum {string}
*/
export enum CategoryDelegationStatsScopeEnum {
    ALL = 'ALL',
    SOME = 'SOME',
    NONE = 'NONE'
}


