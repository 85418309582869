import styled from 'styled-components';
import { QuestionDetailBaseVrmReviewStatusEnum } from 'generated/models/QuestionDetailBase';
import {
  Wrapper as RadiosWrapper,
  CurrentStatusType,
} from '../Question/Review/Radios.styles';

const { PENDING, FLAGGED, DONE } = QuestionDetailBaseVrmReviewStatusEnum;

export const ReviewWrapper = styled.div`
  position: relative;
  top: 0px;
  left: 136px;
  z-index: 40;
`;

export const Wrapper = styled(RadiosWrapper)<CurrentStatusType>`
  background-color: ${({ theme }) => theme.basic.colors.light};
  border-right: 3px solid
    ${({ theme: { atlas }, currentStatus }) =>
      currentStatus === PENDING
        ? atlas.white
        : currentStatus === FLAGGED
        ? atlas.yellow.primary
        : currentStatus === DONE
        ? atlas.teal.primary
        : atlas.white};
`;

export const Status = styled.div`
  padding-right: 6px;
`;

export const ReviewFlex = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
