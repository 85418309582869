/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface SupportTicket
 */
export interface SupportTicket {
    /**
     * 
     * @type {Date}
     * @memberof SupportTicket
     */
    created_at: Date;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    owner_id: string;
    /**
     * 
     * @type {number}
     * @memberof SupportTicket
     */
    ticket_status: number;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    ticket_status_code: SupportTicketTicketStatusCodeEnum;
    /**
     * 
     * @type {number}
     * @memberof SupportTicket
     */
    ticket_type: number;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    ticket_type_code: SupportTicketTicketTypeCodeEnum;
    /**
     * 
     * @type {Date}
     * @memberof SupportTicket
     */
    updated_at: Date;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    user_id: string;
}




/**
* @export
* @enum {string}
*/
export enum SupportTicketTicketStatusCodeEnum {
    OPEN = 'OPEN',
    INPROGRESS = 'IN_PROGRESS',
    CLOSED = 'CLOSED'
}
/**
* @export
* @enum {string}
*/
export enum SupportTicketTicketTypeCodeEnum {
    CREDITAPPEAL = 'CREDIT_APPEAL'
}


