import arrayToIdDict from '../arrayToIdDict';
import {
  ResponseType,
  ResponseDefinitionSummaryType,
} from 'common/common.types';
import { parseAttachment, parseResponseDefinition, parseMapping } from './';
import { ResponseDefinitionSummary } from 'generated/models/ResponseDefinitionSummary';
import { EMPTY_ID } from 'common/common.constants';
import { ResponseFilling } from 'generated/models';

export const parseResponse = (data: ResponseFilling): ResponseType => {
  const {
    id = EMPTY_ID,
    attachments = [],
    is_filled,
    is_required = false,
    updated_at = 0,
    value = '',
  } = data;
  const definition = data.definition || {};

  return {
    id,
    attachments: arrayToIdDict(attachments, parseAttachment, () => id),
    definition: parseResponseDefinition(definition),
    isFilled: is_filled,
    isRequired: is_required,
    mappingHistory: data.mapping_history && parseMapping(data.mapping_history),
    updatedAt: new Date(updated_at),
    value,
  };
};

export const parseResponseDefinitionSummary = ({
  definition,
  id = EMPTY_ID,
  is_required = false,
  pos = 0,
  wizard_uuid,
}: ResponseDefinitionSummary): ResponseDefinitionSummaryType => ({
  definition: parseResponseDefinition(definition || {}),
  id,
  isRequired: is_required,
  pos,
  uniqueId: wizard_uuid,
});
