export const formatPrice = (price: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(price);
};

export const getTotal = (quantity: number, unit: number) =>
  quantity ? unit * quantity : 0;

export const getSavings = (quantity: number, unit: number, full: number) =>
  quantity ? full * quantity - unit * quantity : 0;
