export default (str: string): string => {
  const stringed = str
    .toLowerCase()
    .replace(/[^A-Za-z0-9]/g, ' ')
    .split(' ')
    .reduce((result, word) => result + capitalize(word.toLowerCase()));
  return stringed.charAt(0).toLowerCase() + stringed.slice(1);
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
