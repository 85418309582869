import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 24px 0;
  background-color: ${({ theme }) => theme.basic.colors.light};
  margin-top: -30px;

  > * {
    margin-top: 16px;
  }

  > :nth-child(2) {
    color: ${({ theme }) => theme.basic.colors.atlas};
  }
`;

export const Heading = styled.h3`
  margin-bottom: 16px;
`;

export const Hint = styled.div`
  text-align: center;
  width: 300px;
  ${({ theme }) => theme.fonts.light.small}
  line-height: 24px;
  margin-top: 30px;
`;
