import React from 'react';
import { useDrag, useDrop } from 'react-dnd-cjs';
import {
  ArrowIcon,
  QuestionCode,
  QuestionText,
  RectsIcon,
  TEXT_LENGTH,
  Wrapper,
} from './QuestionTile.styles';

import { TileTypeEnum } from '../FormOutline/FormOutline';

import {
  faChevronDown,
  faGripVertical,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Uuid } from 'common/common.types';
import { textTruncate } from 'utils/multiLineEllipsis';
import { ValidationStatus } from '../FormOutline/FormOutline.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface TileProps {
  categoryName: string;
  code?: string;
  id: Uuid;
  moveCategory?: (sourceCategory: string, destinationIndex: number) => void;
  moveQuestion?: (id: Uuid, atIndex: number, atCategory: string) => void;
  panelOpen?: boolean;
  position: number;
  text: string;
  type: TileTypeEnum;
  validationStatus?: ValidationStatus;
}

interface DragItem {
  type: TileTypeEnum;
  id: Uuid;
  position: number;
  catName: string;
}

const QuestionTile = ({
  categoryName,
  code,
  id,
  moveCategory,
  moveQuestion,
  panelOpen,
  position,
  text,
  type,
  validationStatus = 'normal',
}: TileProps) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type, id, position, catName: categoryName },
    collect: monitor => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
  });
  const [, drop] = useDrop({
    accept: [TileTypeEnum.QUESTION, TileTypeEnum.CATEGORY],
    canDrop: () => false,
    hover(item: DragItem) {
      if (item.id !== id && type === item.type) {
        if (item.type === TileTypeEnum.QUESTION && moveQuestion) {
          moveQuestion(item.id, position, categoryName);
        } else if (item.type === TileTypeEnum.CATEGORY && moveCategory) {
          moveCategory(item.catName, position);
        }
      }
    },
  });

  const opacity = isDragging && type !== TileTypeEnum.CATEGORY ? 0 : 1;

  const codeLength = code ? code.length : 0;

  return (
    <Wrapper
      ref={node => drop(drag(node))}
      style={{ opacity }}
      type={type}
      validationStatus={validationStatus}
    >
      <RectsIcon validationStatus={validationStatus}>
        <FontAwesomeIcon icon={faGripVertical as IconProp} />
      </RectsIcon>
      <QuestionText>
        {code && <QuestionCode>{code} </QuestionCode>}
        {textTruncate(text, TEXT_LENGTH - codeLength, '...')}
      </QuestionText>
      {type === TileTypeEnum.CATEGORY && (
        <ArrowIcon open={panelOpen}>
          <FontAwesomeIcon icon={faChevronDown as IconProp} />
        </ArrowIcon>
      )}
    </Wrapper>
  );
};

export default QuestionTile;
