/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormResponseValueUpdate
 */
export interface FormResponseValueUpdate {
    /**
     * 
     * @type {string}
     * @memberof FormResponseValueUpdate
     */
    sourceQuestionId?: string;
    /**
     * 
     * @type {string}
     * @memberof FormResponseValueUpdate
     */
    sourceResponseId?: string;
    /**
     * 
     * @type {string}
     * @memberof FormResponseValueUpdate
     */
    updateResponseSource?: FormResponseValueUpdateUpdateResponseSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof FormResponseValueUpdate
     */
    value: string;
}

export function FormResponseValueUpdateFromJSON(json: any): FormResponseValueUpdate {
    return FormResponseValueUpdateFromJSONTyped(json, false);
}

export function FormResponseValueUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormResponseValueUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceQuestionId': !exists(json, 'source_question_id') ? undefined : json['source_question_id'],
        'sourceResponseId': !exists(json, 'source_response_id') ? undefined : json['source_response_id'],
        'updateResponseSource': !exists(json, 'update_response_source') ? undefined : json['update_response_source'],
        'value': json['value'],
    };
}

export function FormResponseValueUpdateToJSON(value?: FormResponseValueUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source_question_id': value.sourceQuestionId,
        'source_response_id': value.sourceResponseId,
        'update_response_source': value.updateResponseSource,
        'value': value.value,
    };
}

/**
* @export
* @enum {string}
*/
export enum FormResponseValueUpdateUpdateResponseSourceEnum {
    ANSWERLOOKUPLENSES = 'ANSWER_LOOKUP_LENSES',
    AUTOCOMPLETE = 'AUTOCOMPLETE',
    MANUAL = 'MANUAL',
    MERGE = 'MERGE'
}


