import styled from 'styled-components';
import { TextButton } from 'common/common.styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.atlas.grey[50]};
`;

export const Content = styled.div`
  padding: 22px 0 10px 45px;
`;

export const Header = styled.div`
  display: flex;
  padding-bottom: 10px;
  padding-right: 28px;
  align-items: center;
`;

export const Title = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.atlas.text.secondary};
  ${({ theme }) => theme.fonts.bold.small}
`;

export const RemoveButton = styled(TextButton).attrs({
  size: 'small',
})`
  color: ${({ theme }) => theme.atlas.text.secondary};
  line-height: 34px;

  > span {
    margin-left: 10px;
  }
`;
