import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.atlas.white};
  margin-left: 8px;
  width: 400px;
  text-align: center;
  padding-top: 26px;
`;

export const Form = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

export const Input = styled.input.attrs({ type: 'number' })`
  border: 1px solid ${({ theme }) => theme.atlas.grey[300]};
  height: 46px;
  padding: 15px;
  width: 180px;

  &::placeholder {
    color: ${({ theme }) => theme.atlas.text.primary};
    font-size: ${({ theme }) => theme.basic.fontSizes.small};
    opacity: 0.4;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Hint = styled.div`
  font-size: ${({ theme }) => theme.basic.fontSizes.mini};
  padding-top: 7px;
`;
