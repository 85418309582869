import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'formdata-polyfill';
import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { setupAnalytics } from './utils/analytics';
import { setupAxios } from './utils/apiConfig';
import { setupSentry } from './utils/sentryConfig';
import setupTestingHelpers from './utils/testingHelpers';
import setupDatadogRum from './utils/datadogRumConfig';

setupAnalytics();
setupDatadogRum();
setupAxios();
setupSentry();
setupTestingHelpers();

const rootElement = document.getElementById('root');

render(<App />, rootElement);
