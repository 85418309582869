import styled from 'styled-components';

export const Wrapper = styled.div``;

export const TitleWrapper = styled.div`
  padding: 0 0 3px;
`;

type ProgressType = {
  count: number;
  chunk: number;
};

const WIDTH = 150;

export const Progress = styled.div<ProgressType>`
  width: ${WIDTH}px;
  height: 8px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;

  &:before {
    content: '';
    width: ${WIDTH}px;
    height: 8px;
    background-color: ${({ theme }) => theme.basic.colors.lightBorder};
    position: absolute;
  }

  &:after {
    content: '';
    width: ${({ chunk, count }) => `${(WIDTH * chunk) / count}px`}
    height: 8px;
    background-color: ${({ theme }) => theme.basic.colors.black};
    position: absolute;
    border-radius: 6px;
    transition: width .1s linear;
  }
`;
