import React from 'react';
import { LinkDisabledNotification } from './Recipient.styles';

const LinkActionDisabledNotification = () => {
  return (
    <LinkDisabledNotification>
      Selecting a future 'Send Date' or scheduling can only be used with the
      Send Questionnaire option. Generate Link is removed as an option.
    </LinkDisabledNotification>
  );
};

export default LinkActionDisabledNotification;
