import React from 'react';

import { BetaText, BetaWrapper } from './BetaSign.styles';

export const BetaSign = () => {
  return (
    <BetaWrapper>
      <BetaText>beta</BetaText>
    </BetaWrapper>
  );
};
