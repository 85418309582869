import colors from './common.colors';
import {
  NavigationContextEnum,
  QuestionBlockType,
  QuestionTabEnum,
  RecurrenceItemType,
  ReminderItemType,
  Uuid,
  LanguagePreferenceType,
  LanguageType,
} from './common.types';
import { RequestSummaryStatusEnum } from 'generated/models/RequestSummary';
import { FormDetailFillingTypeEnum } from 'generated/models/FormDetail';
import formatRoute, * as routes from '../routes';
import { ScheduledRequestRecurrenceTypeEnum } from 'generated/models';
import { enUS, ja } from 'date-fns/locale';

const {
  elements: { requests },
} = colors;

export const EMPTY_ID: Uuid = '';

export const ANSWER_BASE_FILE_NAME = 'answer_base_template.csv';
export const ANSWER_BASE_FILE_PATH = `/doc/${ANSWER_BASE_FILE_NAME}`;
export const COMPANIES_LOAD_LIMIT = 25;
export const CONFIRM_MODAL_ID = 'confirm-modal';
export const CONFIRM_CONDITION_SWITCH_MODAL_ID =
  'confirm-condition-switch-modal-id';
export const CREDITS_FULL_PRICE = 300;
export const CUSTOM_FRAMEWORKS_MODAL_ID = 'custom-frameworks';
export const DATETIME_FORMAT = 'MMMM d, yyyy HH:mm';
export const DATETIME_SECONDS_FORMAT = 'MMM d, yyyy HH:mm:ss';
export const DATE_FORMAT = 'MMMM d, yyyy';
export const DATE_FORMAT_WITHOUT_YEAR = 'MMM d';
export const DATE_FORMAT_DAY = 'dd MMM, yyyy';
export const FORM_CREATOR_CONFLICT_MODAL_ID = 'form-creator-conflict';
export const FORM_PREVIEW_MODAL_ID = 'form-preview';
export const FORM_PROXY_MODAL_ID = 'form-proxy';
export const FORM_DEFINITIONS_ADD_NEW_MODAL_ID = 'form-definitions-add-new';
export const FORM_PUBLISH_MODAL_ID = 'form-publish-modal-id';
export const FORM_PREVIEW_ERROR_MODAL_ID = 'form-preview-modal-id';
export const FRAMEWORK_TEMPLATE_FILE_NAME = 'Atlas_Framework_Template.xlsx';
export const FRAMEWORK_TEMPLATE_FILE_PATH = `/doc/${FRAMEWORK_TEMPLATE_FILE_NAME}`;
export const PERCENTAGE_FILLED_THRESHOLD = 10;
export const PREVIEW_COBRANDING_MODAL_ID = 'preivew-cobranding-modal';
export const QUESTIONS_LOAD_STEP = 30;
export const REQUEST_COMPLETED_STANDARD_MODAL_ID = 'request-completed-standard';
export const REQUEST_LINK_MODAL_ID = 'request-link-modal';
export const SALES_MAIL = 'happytohelp@securityscorecard.io';
export const SCHEDULED_REQUEST_DELETE_MODAL_ID = 'scheduled-request-delete';
export const SSC_TOKEN_PARAM_KEY = '?ssc-token=';
export const SYSTEM_FRAMEWORKS_MODAL_ID = 'system-frameworks';
export const TWO_WEEKS = 12096e5;
export const USERS_LOAD_LIMIT = 500;
export const USERS_SETTINGS_LOAD_LIMIT = 200;
export const DELETE_LOGO = 'delete-logo';
export const FILE_SIZE_LIMIT = 50 * 1024 * 1024;
export const JAPANESE_DATE_FORMAT = 'yyyy年MM月dd日';
export const JAPANESE_DATE_TIME_FORMAT = 'yyyy年MM月dd日 HH:mm';
export const FORM_RESPONSE_MODAL_ID = 'form-response-modal';

export const ACTIVE_REQUEST_STATUSES: RequestSummaryStatusEnum[] = [
  RequestSummaryStatusEnum.CREATED,
  RequestSummaryStatusEnum.VENDORINPROGRESS,
  RequestSummaryStatusEnum.VRMINPROGRESS,
  RequestSummaryStatusEnum.ACCEPTED,
];

export const CONTEXT_OPTIONS: NavigationContextEnum[] = [
  NavigationContextEnum.ANSWER,
  NavigationContextEnum.SEND,
];

export const CONTEXT_OPTION_ROUTES = {
  [NavigationContextEnum.ANSWER]: routes.REQUESTS_RECEIVED,
  [NavigationContextEnum.SEND]: formatRoute(routes.REQUESTS_SENT, {}),
};

export const STATUS_TO_CATEGORY = {
  [RequestSummaryStatusEnum.ACCEPTED]: 'closed',
  [RequestSummaryStatusEnum.CANCELED]: 'closed',
  [RequestSummaryStatusEnum.CREATED]: 'created',
  [RequestSummaryStatusEnum.VENDORINPROGRESS]: 'inProgress',
  [RequestSummaryStatusEnum.VRMINPROGRESS]: 'reviewReady',
};

export const DEFAULT_QUESTION_BLOCKS: QuestionBlockType[] = [
  QuestionTabEnum.ATTACHMENTS,
  QuestionTabEnum.COMMENTS,
  QuestionTabEnum.HISTORY,
  QuestionTabEnum.SSC_DATA,
];

export const reminders: ReminderItemType[] = [
  { label: 'reminderNo', value: -1 },
  { label: 'reminder1D', value: 1 },
  { label: 'reminder3D', value: 3 },
  { label: 'reminder1W', value: 7 },
  { label: 'reminder2W', value: 14 },
];

export const SCHEDULE_RECURRENCES: RecurrenceItemType[] = [
  {
    label: 'scheduleRecurrenceNo',
    value: 'No',
    type: undefined,
    unit: undefined,
    occurrences: 1,
  },
  {
    label: 'scheduleRecurrence3M',
    value: '3M',
    type: ScheduledRequestRecurrenceTypeEnum.MONTHLY,
    unit: 3,
  },
  {
    label: 'scheduleRecurrence6M',
    value: '6M',
    type: ScheduledRequestRecurrenceTypeEnum.MONTHLY,
    unit: 6,
  },
  {
    label: 'scheduleRecurrence1Y',
    value: '1Y',
    type: ScheduledRequestRecurrenceTypeEnum.YEARLY,
    unit: 1,
  },
];

export const STATUSES = {
  ACCEPTED_CANCELED: {
    id: 'ACCEPTED_CANCELED',
    title: {
      en: 'Accepted / Closed',
    },
    color: 'green',
    borderColor: requests.closed.borderColor,
  },
  ARCHIVED: {
    id: 'ARCHIVED',
    title: {
      en: 'Archived',
    },
  },
  CREATED: {
    id: 'CREATED',
    title: {
      en: {
        received: 'Received',
        sent: 'Sent',
      },
    },
    color: 'yellow',
    borderColor: requests.created.borderColor,
  },
  VENDOR_IN_PROGRESS: {
    id: 'VENDOR_IN_PROGRESS',
    title: {
      en: 'In progress',
    },
    color: 'seaGreen',
    borderColor: requests.inProgress.borderColor,
  },
  VRM_IN_PROGRESS: {
    id: 'VRM_IN_PROGRESS',
    title: {
      en: 'Ready for review',
    },
    color: 'blue',
    borderColor: requests.reviewReady.borderColor,
  },
};

export const GROUPED_STATUSES = {
  ARCHIVED: STATUSES.ARCHIVED,
  ACCEPTED: STATUSES.ACCEPTED_CANCELED,
  CANCELED: STATUSES.ACCEPTED_CANCELED,
  CREATED: STATUSES.CREATED,
  VENDOR_IN_PROGRESS: STATUSES.VENDOR_IN_PROGRESS,
  VRM_IN_PROGRESS: STATUSES.VRM_IN_PROGRESS,
};

export const STATUS_ORDERING = {
  CREATED: 0,
  VENDOR_IN_PROGRESS: 1,
  VRM_IN_PROGRESS: 2,
  ACCEPTED: 3,
  CANCELED: 4,
  ARCHIVED: 5,
  UNARCHIVED: 6,
};

export const FILLING_TYPE_WITH_DESCRIPTION = [
  FormDetailFillingTypeEnum.SNAPSHOT,
  FormDetailFillingTypeEnum.MASTER,
  FormDetailFillingTypeEnum.UPLOAD,
];

export const languagePreference: LanguagePreferenceType[] = [
  { value: 'en-US', label: 'English' },
  { value: 'ja-JP', label: '日本語 (Japanese)' },
];

export const DEFAULT_LANGUAGE: LanguageType = {
  value: 'en-US',
  label: 'English',
};

export const LOCALES = { 'en-US': enUS, 'ja-JP': ja, default: enUS };
export const DATE_FORMAT_BASED_ON_LOCALE = {
  'en-US': DATE_FORMAT,
  'ja-JP': JAPANESE_DATE_FORMAT,
  default: DATE_FORMAT,
};
export const DATE_TIME_FORMAT_BASED_ON_LOCALE = {
  'en-US': DATETIME_FORMAT,
  'ja-JP': JAPANESE_DATE_TIME_FORMAT,
  default: DATETIME_FORMAT,
};
