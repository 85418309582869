import React from 'react';
import RequestsContextWrapper from 'components/Requests/RequestsContextWrapper';
import i18n from '../i18n';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import { FilterType, omitDisabled } from '../utils/filters/requests.filters';
import { SortType } from '../utils/filters/requests.sorters';
import { copyTextToClipboard } from '../utils/copyTextToClipboard';
import { flash } from 'store/flashes/actions';
import { mapObjIndexed, Omit } from 'ramda';
import { showModal } from '../utils/Modal';
import { startLoading } from 'store/app/actions';
import {
  CurrentUserType,
  DeleteIntervalEnum,
  FormType,
  GroupedScheduledRequests,
  LanguageType,
  RequestControlsStatusType,
  RequestDetailType,
  RequestDirectionType,
  RequestType,
  StatusFlagType,
  Uuid,
} from 'common/common.types';
import {
  FormGetQuestionsParamsType,
  FormsGetPreviewFunctionType,
  FormsLoadQuestionsFunctionType,
  FormsPutQuestionFunctionType,
  getPreview,
  loadQuestions,
  putQuestion,
} from 'store/forms/actions';
import {
  RequestsCategoryNameType,
  RequestsInOutType,
  RequestsViewType,
} from 'store/requests/requests.types';
import {
  archiveRequest,
  clearNotifications,
  deleteNextScheduledRequest,
  deleteRequest,
  deleteScheduledRequest,
  getRequests,
  getScheduledRequests,
  markRead,
  RequestArchiveFunctionType,
  RequestClearNotificationsFunctionType,
  RequestDeleteFunctionType,
  RequestDeleteScheduledFunctionType,
  RequestMarkReadFunctionType,
  RequestMarkReadParamsType,
  RequestsSetFilterFunctionType,
  RequestsSetSortFunctionType,
  RequestsSwitchViewFunctionType,
  RequestsToggleCategoriesFunctionType,
  RequestsToggleCategoriesValueType,
  RequestsToggleSelectionsFunctionType,
  setSort,
  switchView as requestsSwitchView,
  toggleCategories,
  toggleRequestSelection,
} from 'store/requests/actions';
import {
  getControlsStatus,
  groupScheduledRequests,
} from 'store/requests/requests.selectors';
import Page, {
  PageActionsBaseType,
  PageBaseType,
  PageParamType,
  PagePropsType,
  UpdatePathType,
  UpdateQueryType,
} from './Page';
import {
  showProxyModal,
  FormProxyShowProxyModalFunctionType,
} from 'store/formProxy/actions';

export interface RequestsPageContextType extends PageBaseType {
  data: {
    controlsStatus: RequestControlsStatusType;
    direction: RequestDirectionType;
    previewForm: FormType;
    putQuestion: FormsPutQuestionFunctionType;
    requests: RequestsInOutType;
    scheduled: GroupedScheduledRequests;
    user: CurrentUserType;
    language: LanguageType;
  };
  actions: PageActionsBaseType & {
    archiveRequest: RequestArchiveFunctionType;
    clearNotifications: RequestClearNotificationsFunctionType;
    copyToClipboard: (text: string) => void;
    deleteRequest: RequestDeleteFunctionType;
    deleteScheduledRequest: RequestDeleteScheduledFunctionType;
    getPreview: FormsGetPreviewFunctionType;
    loadQuestions: FormsLoadQuestionsFunctionType;
    markRead: RequestMarkReadFunctionType;
    putQuestion: FormsPutQuestionFunctionType;
    setFilter: RequestsSetFilterFunctionType;
    setSort: RequestsSetSortFunctionType;
    showModal: (modalId: string) => void;
    showProxyModal: FormProxyShowProxyModalFunctionType;
    switchRequestsView: RequestsSwitchViewFunctionType;
    switchTab: (tab: string) => void;
    toggleCategories: RequestsToggleCategoriesFunctionType;
    toggleSelection: RequestsToggleSelectionsFunctionType;
  };
  routeParams: PageParamType & {
    tab?: string;
  };
}

const RequestsPage = ({
  direction,
  // prettier-ignore
  /* tslint:disable */
  ...props
}: /* tslint:enable */
PagePropsType & { direction: RequestDirectionType }) => (
  <Page
    dataSelector={(state: StateType) => ({
      controlsStatus: getControlsStatus(state.requests[direction]),
      direction,
      previewForm: state.forms.currentForm,
      requests: state.requests[direction],
      scheduled: groupScheduledRequests(state.requests),
      user: state.user.currentUser,
      language: state.settings.languagePreference,
    })}
    dispatches={(
      dispatch: Dispatch<ActionType>,
      _: PagePropsType,
      updateQuery: UpdateQueryType,
      updatePath: UpdatePathType,
    ) => ({
      getData: ({
        queryParams: { view, charge_state, categories, filterBy, sortBy },
        currentUser: { isNormal: showScheduled },
      }: {
        queryParams: {
          view?: string;
          charge_state?: string;
          categories: any;
          filterBy?: FilterType;
          sortBy?: SortType;
        };
        currentUser: CurrentUserType;
      }) => {
        dispatch(startLoading(showScheduled ? 2 : 1));
        dispatch(getRequests(direction, filterBy));

        if (showScheduled) {
          dispatch(getScheduledRequests());
        }

        dispatch(
          requestsSwitchView(view === 'Kanban' ? 'Kanban' : 'List', direction),
        );

        if (charge_state) {
          dispatch(
            flash(
              i18n.t(`common:responses.payment${charge_state}`),
              charge_state.toLowerCase() as StatusFlagType,
            ),
          );
        }

        if (categories) {
          const allClosed = {
            created: false,
            inProgress: false,
            reviewReady: false,
            closed: false,
          };
          const ensureBooleans = mapObjIndexed(val => val === 'true');

          dispatch(
            toggleCategories(
              { ...allClosed, ...ensureBooleans(categories) },
              direction,
            ),
          );
        }

        if (sortBy) {
          dispatch(setSort(sortBy, direction));
        }
      },
      actions: {
        archiveRequest: (ids: Uuid[]) => dispatch(archiveRequest(ids)),
        clearNotifications: (requests: RequestDetailType[]) => {
          dispatch(clearNotifications(requests, direction));
        },
        copyToClipboard: (text: string) => {
          copyTextToClipboard(text);
          dispatch(flash(i18n.t('common:responses.copySuccess')));
        },
        deleteRequest: (ids: Uuid[]) => {
          dispatch(deleteRequest(ids));
        },
        deleteScheduledRequest: (id: Uuid, type: DeleteIntervalEnum) => {
          dispatch(startLoading(1));
          if (type === DeleteIntervalEnum.ALL) {
            dispatch(deleteScheduledRequest(id));
          } else if (type === DeleteIntervalEnum.NEXT) {
            dispatch(deleteNextScheduledRequest(id));
          }
        },
        getPreview: (id: Uuid) => dispatch(getPreview(id)),
        loadQuestions: (params: FormGetQuestionsParamsType) =>
          dispatch(loadQuestions(params)),
        markRead: (params: Omit<RequestMarkReadParamsType, 'direction'>) =>
          dispatch(markRead({ ...params, direction })),
        putQuestion: (
          formId: Uuid,
          questionId: Uuid,
          responseId: Uuid,
          value: string,
          category: string,
        ) => {
          dispatch(
            putQuestion(formId, questionId, responseId, value, category),
          );
        },
        setFilter: (filterBy: FilterType) => {
          updateQuery({ filterBy: omitDisabled(filterBy) });
          dispatch(getRequests(direction, filterBy));
        },
        setSort: (sortBy: SortType) => {
          updateQuery({ sortBy });
          dispatch(setSort(sortBy, direction));
        },
        showModal: (modalId: string) => dispatch(showModal(modalId)),
        showProxyModal: (request: RequestType) =>
          dispatch(showProxyModal(request)),
        switchRequestsView: (view: RequestsViewType) => {
          updateQuery({ view });
          dispatch(requestsSwitchView(view, direction));
        },
        switchTab: (tab: string) =>
          updatePath({ tab: tab === 'sent' ? '' : tab }),
        toggleCategories: (categories: RequestsToggleCategoriesValueType) => {
          updateQuery({ categories });
          dispatch(toggleCategories(categories, direction));
        },
        toggleSelection: (
          category: RequestsCategoryNameType,
          requestIds: Uuid[],
        ) => {
          dispatch(toggleRequestSelection(category, direction, requestIds));
        },
      },
    })}
  >
    <RequestsContextWrapper />
  </Page>
);

export default RequestsPage;
