import FilteredRows from './FilteredRows';
import React from 'react';
import Row from './Row';
import SscGradesRow from './SscGradesRow';
import VerticalLines from './VerticalLines';
import { CompaniesFilterFunctionsType } from '../Companies';
import { CompaniesFilterType } from 'store/companies/companies.reducer';
import { Grid } from './Visualisation.styles';
import { CompanyGradeEnum } from 'generated/models/Company';

export const X_VALUES: CompanyGradeEnum[] = [
  CompanyGradeEnum.A,
  CompanyGradeEnum.B,
  CompanyGradeEnum.C,
  CompanyGradeEnum.D,
  CompanyGradeEnum.F,
  CompanyGradeEnum.Empty,
];
export const X_VALUES_POSITION: { [key in CompanyGradeEnum]: number } = {
  [CompanyGradeEnum.A]: 13,
  [CompanyGradeEnum.B]: 26.2,
  [CompanyGradeEnum.C]: 39.4,
  [CompanyGradeEnum.D]: 52.6,
  [CompanyGradeEnum.F]: 65.8,
  [CompanyGradeEnum.Empty]: 79,
};
export const SSC_ROW_Y_POSITION = 65;
export const UNSORTED_ROW_Y_POSITION = 90;

interface VisualisationType {
  filter: CompaniesFilterType;
  filterFunctions: CompaniesFilterFunctionsType;
}

const Visualisation = ({ filter, filterFunctions }: VisualisationType) => (
  <Grid onClick={() => filterFunctions.setSelection(undefined, undefined)}>
    <VerticalLines />

    <SscGradesRow y={SSC_ROW_Y_POSITION} filter={filter} />

    <FilteredRows
      filterFunctions={filterFunctions}
      data={filter.rows.tagged}
      yRange={SSC_ROW_Y_POSITION}
      filter={filter}
    />

    <Row
      y={UNSORTED_ROW_Y_POSITION}
      legend={filter.rows.unsorted.legend}
      byGrade={filter.rows.unsorted.byGrade}
      filter={filter}
      filterFunctions={filterFunctions}
    />
  </Grid>
);

export default Visualisation;
