import React from 'react';
import { Heading } from './Sales.styles';
import { List } from '../SendRequest/Standards';
import { StandardType, TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface CompanyStandardsPropsType extends TranslatePropsType {
  name: string;
  standards: StandardType[];
}
const CompanyStandards = ({
  name,
  standards,
  t,
}: CompanyStandardsPropsType) => (
  <div>
    <Heading>{t('companyStandards', { name }) as string}</Heading>

    <List selectedStandards={[]} standards={standards} />
  </div>
);

export default withTranslation('Sales')(CompanyStandards);
