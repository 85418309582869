import * as R from 'ramda';
import i18n from '../../../i18n';
import { call, put } from 'redux-saga/effects';
import { getFiles } from './getFiles';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { Uuid } from '../../../common/common.types';

export interface DeleteFileParamsType {
  id: Uuid;
}

export type UserDeleteFileActionType = OptimisticActionType<
  '@user/API/DELETE_FILE',
  ApiCallActionPayloadType<DeleteFileParamsType, any>
>;

export const deleteFile = (id: Uuid): UserDeleteFileActionType => ({
  type: '@user/API/DELETE_FILE',
  payload: {
    callParams: {
      id,
    },
    endpointPath: ['user', 'files', 'delete'],
    expectedStateChange: {
      'forms.currentForm.attachments': R.dissoc(id.toString()),
    },
    selector: (data, state) =>
      R.dissocPath(['currentForm', 'attachments', data.id], state),
    postActions: [
      () => put(getFiles()),
      () => call(() => trackEvent('Attachment', 'Delete - Form')),
      () => put(flash(i18n.t('common:responses.fileDeleteSuccess'))),
    ],
  },
});
