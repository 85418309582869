import React, { ChangeEvent, Component } from 'react';
import withUrl from 'utils/url/withUrl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from './Actions.styles';
import { Link } from './Utils.styles';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Translation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

class Actions extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      limit: '',
      filterSystem: true,
      companySubstring: '',
    };
  }

  handleCompanyChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      companySubstring: value,
    });
  };

  handleLimitChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      limit: value,
    });
  };

  toggleFilterSystem = () => {
    const { filterSystem } = this.state;

    this.setState({ filterSystem: !filterSystem });
  };

  getUrl = () => {
    const { companySubstring, filterSystem, limit } = this.state;
    const params = [];

    params.push(`include_system=${filterSystem ? 0 : 1}`);

    if (companySubstring !== '') {
      params.push(`company_query=${encodeURIComponent(companySubstring)}`);
    }

    if (limit !== '') {
      params.push(`limit=${encodeURIComponent(limit)}`);
    }

    return withUrl(`reports/history/user_actions?${params.join('&')}`, '');
  };

  render() {
    const { companySubstring, filterSystem, limit } = this.state;

    return (
      <div>
        <div>
          <label style={{ display: 'block' }}>
            <Translation ns={'components'}>
              {t => <>{t('Actions.companyNameDomain')}</>}
            </Translation>
          </label>
          <Input
            type="text"
            onChange={this.handleCompanyChange}
            value={companySubstring}
          />
        </div>
        <div>
          <label style={{ display: 'block' }}>
            <Translation ns={'components'}>
              {t => <>{t('Actions.limit')}</>}
            </Translation>
          </label>
          <Input
            type="number"
            placeholder="10 000"
            onChange={this.handleLimitChange}
            value={limit}
          />
        </div>
        <div>
          <label>
            <Translation ns={'components'}>
              {t => <>{t('Actions.excludeSystem')}</>}
            </Translation>
            <input
              style={{ marginLeft: 10 }}
              type="checkbox"
              onChange={this.toggleFilterSystem}
              checked={filterSystem}
            />
          </label>
        </div>
        <Link primary href={this.getUrl()}>
          <FontAwesomeIcon icon={faDownload as IconProp} color={'#fff'} />
          <span style={{ paddingRight: 26 }}>
            <Translation ns={'components'}>
              {t => <>{t('Actions.export')}</>}
            </Translation>
          </span>
        </Link>
      </div>
    );
  }
}

export default Actions;
