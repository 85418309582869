import React, { useCallback, useState } from 'react';

import { withTranslation } from 'react-i18next';

import ReactHtmlParser from 'react-html-parser';

import { PreviewModal } from 'components/SendRequest/Modals';
import Tooltip from 'components/common/Tooltip';

import { SortType } from 'utils/filters/formNewFromExisting.sorters';

import VirtualizedList from 'utils/virtualizer/src/VirtualizedList';

import {
  TranslatePropsType,
  StandardType,
  FormType,
  UserType,
  Uuid,
  LanguageType,
} from 'common/common.types';

import { FORM_PREVIEW_MODAL_ID } from 'common/common.constants';

import FormNewFromExistingSorter from './FormNewFromExistingSorter';

import FormNewFromExistingRow from './FormNewFromExistingRow';

import { Button } from '../common/Button';

import { STANDARD_HEIGHT } from './FormNewFromExistingRow.styles';

import {
  ModalHeaderAdditionalContent,
  TextButton,
} from '../FormDefinitions/FormDefinitionsList.styles';

import { getPreviewHelper } from 'store/formCreator/previewUtils';
import { FormNewFromExistingContextActionsType } from 'pages/FormNewFromExistingPage';

export interface ListPropsType extends TranslatePropsType {
  actions: FormNewFromExistingContextActionsType;
  data: {
    standards: StandardType[];
    sortBy: SortType;
    previewForm: FormType;
    user: UserType;
    language: LanguageType;
  };
}

const FormNewFromExistingList = ({
  actions: {
    setSort,
    getPreview,
    getPreviewForm,
    showModal,
    loadQuestions,
    putQuestion,
    resetPreviewForm,
    createEmptyForm,
    duplicateStandardAndRedirectToFormCreator,
  },
  data: { standards, sortBy, previewForm, user, language },
  t,
}: ListPropsType) => {
  const handlePreviewClick = useCallback(
    (standard: StandardType) => {
      if (
        typeof standard.definitionId === 'undefined' ||
        standard.definitionId === null
      ) {
        return;
      }
      getPreviewHelper(standard, standard.definitionId, {
        getPreview,
        getPreviewForm,
      });
      showModal(FORM_PREVIEW_MODAL_ID);
    },
    [getPreview, getPreviewForm, showModal],
  );

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const useThisAsTemplateButton =
    previewForm && previewForm.standard ? (
      <div
        onMouseEnter={() => {
          setTooltipVisible(true);
        }}
        onMouseLeave={() => {
          setTooltipVisible(false);
        }}
      >
        <Button
          color={'teal'}
          size={'medium'}
          disabled={
            !previewForm.standard.isDuplicable ||
            !previewForm.standard.definitionId
          }
          /**
           * necessary if you need onMouseLeave event
           * to fire on the div that contains this button
           */
          pointerEventsNoneForDisabled={true}
          onClick={
            typeof previewForm.standard.definitionId !== 'undefined'
              ? () => {
                  duplicateStandardAndRedirectToFormCreator({
                    definition_id: previewForm.standard.definitionId as Uuid,
                    standard_name: previewForm.standard.name,
                  });
                }
              : undefined
          }
        >
          <>{t('useThisAsTemplate') as string}</>
        </Button>
      </div>
    ) : (
      <></>
    );

  const modalHeaderAdditional =
    previewForm && previewForm.standard ? (
      <ModalHeaderAdditionalContent>
        <Tooltip
          overlay={ReactHtmlParser(
            t('FormDefinitions:cantDuplicate', {
              context: previewForm.standard.duplicateState,
            }),
          )}
          visible={!previewForm.standard.isDuplicable && tooltipVisible}
        >
          {useThisAsTemplateButton}
        </Tooltip>
        <TextButton
          size={'small'}
          color={'atlas'}
          onClick={() => createEmptyForm()}
        >
          {`${t('FormDefinitions:orStartFromScratch')}`}
        </TextButton>
      </ModalHeaderAdditionalContent>
    ) : (
      <></>
    );

  return (
    <>
      {standards.length > 0 && (
        <>
          <PreviewModal
            loadQuestions={loadQuestions}
            modalId={FORM_PREVIEW_MODAL_ID}
            previewForm={previewForm}
            putQuestion={putQuestion}
            user={user}
            headerEnhanced={modalHeaderAdditional}
            onClose={resetPreviewForm}
            language={language}
          />
          <FormNewFromExistingSorter setSort={setSort} sortBy={sortBy} />

          <VirtualizedList
            overScan={5}
            items={standards}
            itemHeight={STANDARD_HEIGHT}
            renderItem={(standard: StandardType) => (
              <FormNewFromExistingRow
                key={`standard-list-${standard.id}`}
                data={{ standard }}
                actions={{
                  onPreview: handlePreviewClick,
                }}
              />
            )}
          />
        </>
      )}
    </>
  );
};

export default withTranslation(['FormNewFromExisting', 'FormDefinitions'])(
  FormNewFromExistingList,
);
