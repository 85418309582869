import { ApiCallActionType } from '../../store.types';
import { put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import i18n from '../../../i18n';
import formatRoute, { FORM_DEFINITIONS } from '../../../routes';
import { push } from 'connected-react-router';
import update from 'immutability-helper';
import { FormCreatorStateType } from '../formCreator.types';
import { cleanValidation } from './cleanValidation';
import { showModal } from 'utils/Modal';
import {
  EMPTY_ID,
  FORM_PUBLISH_MODAL_ID,
} from '../../../common/common.constants';
import { FormDefinitionValidateActionSummary } from '../../../generated/models/FormDefinitionValidateActionSummary';
import { parseFormDefinitionValidateActionSummary } from 'utils/parsers/formDefinition.parsers';
import { Uuid } from '../../../common/common.types';

export type PublishFormFunctionType = (
  payload: PublishFormCallParamsType,
) => void;

export interface PublishFormCallParamsType {
  draft_id?: Uuid;
  force: boolean;
}

export type PublishFormActionType = ApiCallActionType<
  '@formCreator/API/PUBLISH_FORM',
  PublishFormCallParamsType,
  FormDefinitionValidateActionSummary,
  never,
  FormCreatorStateType
>;

export const publishForm = ({
  force,
}: PublishFormCallParamsType): PublishFormActionType => ({
  type: '@formCreator/API/PUBLISH_FORM',
  payload: {
    endpointPath: ['definitions', 'drafts', 'publish'],
    callParams: state => ({
      draft_id: state.formCreator.currentForm
        ? state.formCreator.currentForm.id
        : EMPTY_ID,
      force,
    }),
    preActions: force ? undefined : [put(cleanValidation())],
    selector: (data, state): Partial<FormCreatorStateType> => {
      if (!data.correct) {
        return update(state.formCreator, {
          validation: {
            $set: parseFormDefinitionValidateActionSummary(data),
          },
        });
      }
      return state.formCreator;
    },
    postActions: [
      data => {
        if (data.correct === true) {
          return put(push(formatRoute(FORM_DEFINITIONS)));
        }
        return null;
      },
      (data, { formCreator: { currentForm } }) => {
        if (data.correct) {
          return put(flash(i18n.t('FormCreator:formPublished')));
        } else {
          if (!force) {
            return put(showModal(FORM_PUBLISH_MODAL_ID));
          }

          return put(
            flash(i18n.t('FormCreator:formPublishErrorGeneral'), 'error'),
          );
        }
      },
    ],
  },
});
