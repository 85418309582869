import React, { useEffect, useState } from 'react';
import { Content, Wrapper } from 'common/common.styles';
import { PageHeader, Tab, TabsContainer } from '../PageHeader';

type TabType = {
  component: React.ReactNode;
  hidden?: boolean;
  label: string;
  key?: string;
};

interface TabViewPropsType {
  heading?: React.ReactNode;
  initialState?: number;
  onTabSelect?: (tab: string) => void;
  tabs: TabType[];
}

const TabView = ({
  heading,
  initialState = 0,
  onTabSelect,
  tabs,
}: TabViewPropsType) => {
  const [currentTab, setCurrentTab] = useState(initialState);

  useEffect(() => {
    setCurrentTab(initialState);
  }, [initialState]);

  const handleTabClick = (key: string | undefined, index: number) => {
    if (key && onTabSelect) {
      onTabSelect(key);
    }

    setCurrentTab(index);
  };

  return (
    <>
      {heading && <PageHeader heading={heading} />}

      <TabsContainer>
        {tabs.map(
          ({ hidden = false, label, key }, i) =>
            !hidden && (
              <Tab
                data-test="tab"
                data-pendo={key}
                key={i}
                onClick={() => handleTabClick(key, i)}
                isSelected={currentTab === i}
              >
                {label}
              </Tab>
            ),
        )}
      </TabsContainer>

      <Wrapper grey>
        <Content>{tabs[currentTab].component}</Content>
      </Wrapper>
    </>
  );
};

export default TabView;
