import History from './History';
import QuestionMappings from './QuestionMappings';
import React from 'react';
import { Attachments } from './Attachments';
import { Content, Tabs, Wrapper } from './QuestionTabs.styles';
import { Messages } from './Messages';
import { SscData } from './SscData';
import { ReviewerInstructions } from './ReviewerInstructions';
import { Tab } from '.';
import { withTranslation } from 'react-i18next';
import {
  faComment,
  faHistory,
  faInfoCircle,
  faMagic,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import {
  FormsCreateMessageFunctionType,
  FormsCreateQuestionAttachmentFunctionType,
  FormsDeleteQuestionAttachmentFunctionType,
  FormsGetQuestionMappingsFunctionType,
  FormsPutQuestionFunctionType,
  FormsRateMappingFunctionType,
  FormsSetBlockSeenFunctionType,
  FormsSwitchQuestionFunctionType,
} from 'store/forms/actions';
import {
  AttachmentType,
  FormPermissionsType,
  LanguageType,
  MessageType,
  QuestionBlocksType,
  QuestionHistoryType,
  QuestionTabEnum,
  QuestionType,
  SscDataFactorType,
  ToggleEnum,
  TranslatePropsType,
  UserType,
} from 'common/common.types';
import { FormDetailFillingTypeEnum } from 'generated/models/FormDetail';
import useFlag from 'utils/hooks/useFlag';
import {
  QuestionMetadataBlocks,
  QuestionMetadataBlockStats,
} from 'generated/models';
import { TabPropsType } from './Tab';

// TODO: implement properly typed generic "pathOr"
const blockMetadataWithDefault = <T extends unknown>(
  blocks: undefined | QuestionBlocksType,
  block: keyof QuestionMetadataBlocks,
  property: keyof QuestionMetadataBlockStats,
  defaultValue: T,
) => {
  if (blocks) {
    return blocks.meta[block][property] || defaultValue;
  }
  return defaultValue;
};

export interface QuestionTabsPropsType extends TranslatePropsType {
  attachments: AttachmentType[];
  copyToClipboard?: (text: string) => void;
  createMessage?: FormsCreateMessageFunctionType;
  createQuestionAttachment?: FormsCreateQuestionAttachmentFunctionType;
  deleteQuestionAttachment?: FormsDeleteQuestionAttachmentFunctionType;
  getQuestionMappings?: FormsGetQuestionMappingsFunctionType;
  formFillingType: FormDetailFillingTypeEnum;
  formOwner: string;
  formSource?: string;
  history: QuestionHistoryType[];
  messages: MessageType[];
  question: QuestionType;
  putQuestion?: FormsPutQuestionFunctionType;
  permissions: FormPermissionsType;
  rateMapping?: FormsRateMappingFunctionType;
  setBlockSeen?: FormsSetBlockSeenFunctionType;
  sscData: SscDataFactorType[];
  switchQuestionTab: FormsSwitchQuestionFunctionType;
  user: UserType;
  language: LanguageType;
}

export interface TabCommonType {
  hasNotifications: boolean;
}

const QuestionTabs = ({
  attachments,
  copyToClipboard,
  createMessage,
  createQuestionAttachment,
  deleteQuestionAttachment,
  getQuestionMappings,
  formFillingType,
  formOwner,
  formSource,
  history,
  messages,
  putQuestion,
  permissions: {
    isLookupLensesReadable,
    isQuestionAttachmentReadable,
    isQuestionAttachmentDeletable,
    isQuestionAttachmentWritable,
    isQuestionCommentReadable,
    isQuestionCommentWritable,
    isResponseHistoryReadable,
    isReviewInstructionsReadable,
    isSscScoreReadable,
  },
  question,
  question: { id, blocks, formId, selectedTab, reviewInstructions },
  rateMapping,
  setBlockSeen,
  sscData,
  switchQuestionTab,
  t,
  user,
  language,
}: QuestionTabsPropsType) => {
  const newAttachmentsCount = blockMetadataWithDefault(
    blocks,
    'attachments',
    'new_count',
    0,
  );
  const newCommentsCount = blockMetadataWithDefault(
    blocks,
    'comments',
    'new_count',
    0,
  );
  const newHistoryCount = blockMetadataWithDefault(
    blocks,
    'history',
    'new_count',
    0,
  );
  const newSscDataCount = blockMetadataWithDefault(
    blocks,
    'ssc_data',
    'new_count',
    0,
  );

  const hideSscData = useFlag(user, [ToggleEnum.HIDE_SSC_DATA]);

  const tabs: TabPropsType[] = [];

  if (isQuestionAttachmentReadable) {
    tabs.push({
      active: selectedTab === QuestionTabEnum.ATTACHMENTS,
      hasCount: attachments.length > 0,
      hasNotifications: newAttachmentsCount > 0,
      notificationsCount: newAttachmentsCount,
      icon: faPaperclip,
      id: QuestionTabEnum.ATTACHMENTS,
      onClick: () => {
        if (setBlockSeen && newAttachmentsCount > 0) {
          setBlockSeen(id, QuestionTabEnum.ATTACHMENTS);
        }
        switchQuestionTab(QuestionTabEnum.ATTACHMENTS, id);
      },
      title: t('QuestionTabs.attachments'),
    });
  }

  if (isQuestionCommentReadable) {
    tabs.push({
      active: selectedTab === QuestionTabEnum.COMMENTS,
      hasCount: messages.length > 0,
      hasNotifications: newCommentsCount > 0,
      notificationsCount: newCommentsCount,
      icon: faComment,
      id: QuestionTabEnum.COMMENTS,
      onClick: () => {
        if (setBlockSeen && newCommentsCount > 0) {
          setBlockSeen(id, QuestionTabEnum.COMMENTS);
        }
        switchQuestionTab(QuestionTabEnum.COMMENTS, id);
      },
      title: t('QuestionTabs.messages'),
    });
  }

  if (isResponseHistoryReadable) {
    tabs.push({
      active: selectedTab === QuestionTabEnum.HISTORY,
      hasCount: history.length > 0,
      hasNotifications: newHistoryCount > 0,
      notificationsCount: newHistoryCount,
      icon: faHistory,
      id: QuestionTabEnum.HISTORY,
      onClick: () => {
        if (setBlockSeen && newHistoryCount > 0) {
          setBlockSeen(id, QuestionTabEnum.HISTORY);
        }
        switchQuestionTab(QuestionTabEnum.HISTORY, id);
      },
      title: t('QuestionTabs.history'),
    });
  }

  if (isSscScoreReadable && !hideSscData) {
    tabs.push({
      active: selectedTab === QuestionTabEnum.SSC_DATA,
      hasCount: sscData.length > 0,
      hasNotifications: newSscDataCount > 0,
      notificationsCount: undefined, // TODO: ATL-856 send proper newSscDataCount instead of number of SSC data fetches,
      icon: QuestionTabEnum.SSC_DATA,
      id: QuestionTabEnum.SSC_DATA,
      onClick: () => {
        if (setBlockSeen && newSscDataCount > 0) {
          setBlockSeen(id, QuestionTabEnum.SSC_DATA);
        }
        switchQuestionTab(QuestionTabEnum.SSC_DATA, id);
      },
      title: t('QuestionTabs.sscData'),
    });
  }

  if (isLookupLensesReadable) {
    tabs.push({
      active: selectedTab === QuestionTabEnum.LOOKUP_LENS,
      hasCount: false,
      hasNotifications: false,
      notificationsCount: 0,
      icon: faMagic,
      id: QuestionTabEnum.LOOKUP_LENS,
      onClick: () => {
        switchQuestionTab(QuestionTabEnum.LOOKUP_LENS, id);
      },
      title: t('QuestionTabs.lookupLens'),
    });
  }

  // If the user has the Reviewer Instructions feature flag enabled,
  // and the particular question has a valid Reviewer Instruction string
  if (
    useFlag(user, [ToggleEnum.REVIEWER_INSTRUCTIONS]) &&
    isReviewInstructionsReadable &&
    Boolean(reviewInstructions)
  ) {
    tabs.push({
      active: selectedTab === QuestionTabEnum.REVIEWER_INSTRUCTIONS,
      hasCount: Boolean(reviewInstructions),
      hasNotifications: false,
      notificationsCount: 0,
      icon: faInfoCircle,
      id: QuestionTabEnum.REVIEWER_INSTRUCTIONS,
      onClick: () => {
        switchQuestionTab(QuestionTabEnum.REVIEWER_INSTRUCTIONS, id);
      },
      title: t('QuestionTabs.reviewerInstructions'),
    });
  }

  const renderTabContent = (tab: QuestionTabEnum) => {
    switch (tab) {
      case QuestionTabEnum.ATTACHMENTS:
        return (
          <Attachments
            attachments={attachments}
            areAttachmentsModifiable={isQuestionAttachmentWritable}
            createQuestionAttachment={
              isQuestionAttachmentWritable
                ? createQuestionAttachment
                : undefined
            }
            deleteQuestionAttachment={
              isQuestionAttachmentDeletable
                ? deleteQuestionAttachment
                : undefined
            }
            formId={formId}
            hasNotifications={newAttachmentsCount > 0}
            questionId={id}
            setBlockSeen={setBlockSeen}
          />
        );

      case QuestionTabEnum.COMMENTS:
        return (
          <Messages
            createMessage={
              isQuestionCommentWritable ? createMessage : undefined
            }
            formFillingType={formFillingType}
            formId={formId}
            formOwner={formOwner}
            formSource={formSource}
            hasNotifications={newCommentsCount > 0}
            messages={messages}
            question={question}
            setBlockSeen={setBlockSeen}
            user={user}
            language={language}
          />
        );

      case QuestionTabEnum.HISTORY:
        return <History history={history} />;

      case QuestionTabEnum.SSC_DATA:
        if (!hideSscData) {
          return <SscData sscData={sscData} />;
        }
        return <SscData />;

      case QuestionTabEnum.LOOKUP_LENS:
        return (
          rateMapping &&
          putQuestion && (
            <QuestionMappings
              copyToClipboard={copyToClipboard}
              getQuestionMappings={getQuestionMappings}
              mappings={question.mappings}
              putQuestion={putQuestion}
              rateMapping={rateMapping}
              question={question}
              showLikeness={true}
              language={language}
            />
          )
        );
      case QuestionTabEnum.REVIEWER_INSTRUCTIONS:
        return <ReviewerInstructions instructions={reviewInstructions} />;

      default:
        return '';
    }
  };

  return (
    <Wrapper>
      <Content
        data-cy={`question-${question.id}-tab-${selectedTab}-content`}
        data-test={`question-${selectedTab}`}
      >
        {renderTabContent(selectedTab)}
      </Content>

      <Tabs>
        {tabs.map(
          ({
            active,
            hasCount,
            hasNotifications,
            notificationsCount,
            icon,
            id: tabId,
            onClick,
            title,
          }) => (
            <Tab
              active={active}
              hasCount={hasCount}
              hasNotifications={hasNotifications}
              notificationsCount={notificationsCount}
              icon={icon}
              id={tabId}
              key={`question-${question.id}-tab-${tabId}`}
              onClick={onClick}
              title={title}
            />
          ),
        )}
      </Tabs>
    </Wrapper>
  );
};

export default withTranslation()(QuestionTabs);
