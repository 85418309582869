import styled from 'styled-components';

export const NoTeamsBody = styled.strong`
  position: relative;
  display: block;
  margin-top: 20px;
`;

export const NoTeamsLink = styled.a`
  color: ${({ theme }) => theme.basic.colors.info};
`;
