/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Acknowledge,
    AcknowledgeFromJSON,
    AcknowledgeFromJSONTyped,
    AcknowledgeToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface AcknowledgeResponse
 */
export interface AcknowledgeResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof AcknowledgeResponse
     */
    msg: string;
    /**
     * 
     * @type {Acknowledge}
     * @memberof AcknowledgeResponse
     */
    entry: Acknowledge;
}

export function AcknowledgeResponseFromJSON(json: any): AcknowledgeResponse {
    return AcknowledgeResponseFromJSONTyped(json, false);
}

export function AcknowledgeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcknowledgeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': AcknowledgeFromJSON(json['entry']),
    };
}

export function AcknowledgeResponseToJSON(value?: AcknowledgeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': AcknowledgeToJSON(value.entry),
    };
}


