import {
  QuestionDefinitionSummaryType,
  StructuredCondition,
  ResponseDefinitionSummaryType,
} from '../../../common/common.types';
import {
  QuestionConditionStructuredOperatorEnum,
  QuestionConditionStructuredItemOperatorEnum as ItemOperators,
  ResponseTypeDefinitionResponseTypeEnum as ResponseType,
} from '../../../generated/models';
import { findIndex } from 'ramda';
import isNumber from 'utils/isNumber';
import unreachableReturn from 'utils/unreachableReturn';

const operatorToString = (
  operator: QuestionConditionStructuredOperatorEnum,
): string => operator;

const itemOperatorToString = (operator: ItemOperators): string => {
  switch (operator) {
    case ItemOperators.CONTAINS:
      return 'CONTAINS';
    case ItemOperators.ENDSWITH:
      return 'ENDS WITH';
    case ItemOperators.EQUALTO:
      return '=';
    case ItemOperators.GREATERTHAN:
      return '>';
    case ItemOperators.GREATERTHANOREQUALTO:
      return '>=';
    case ItemOperators.GREATERTHANORLESSTHAN:
      return '<>';
    case ItemOperators.LESSTHAN:
      return '<';
    case ItemOperators.LESSTHANOREQUALTO:
      return '<=';
    case ItemOperators.NOTCONTAINS:
      return 'NOT CONTAINS';
    case ItemOperators.NOTENDSWITH:
      return 'NOT ENDS WITH';
    case ItemOperators.NOTEQUALTO:
      return '!=';
    case ItemOperators.NOTSTARTSWITH:
      return 'NOT STARTS WITH';
    case ItemOperators.STARTSWITH:
      return 'STARTS WITH';
    default:
      return unreachableReturn(operator, 'UNKNOWN OPERATOR');
  }
};

const escapeQuotes = (input: string) => input.replace(/"/g, '\\"');

const stringLiteral = (input: string) => `"${escapeQuotes(input)}"`;

const codeToString = (code: string) =>
  /^[a-zA-Z0-9_.-]+$/.test(code) ? code : stringLiteral(code);

const responseCanBeNumber = ({
  definition: { responseType },
}: ResponseDefinitionSummaryType): boolean => {
  switch (responseType) {
    case ResponseType.PERCENTAGE:
    case ResponseType.NUMBER:
      return true;
    case ResponseType.EVIDENCE:
    case ResponseType.TEXT:
    case ResponseType.MULTISELECT:
    case ResponseType.NORESPONSE:
    case ResponseType.SINGLESELECT:
      return false;
    default:
      // Force developer to add a newly added subtype also here and think
      // whether it can be number or not. (hihihihi...)
      return unreachableReturn(responseType, false);
  }
};

const tryConvertNumberToString = (maybeNumber: string) =>
  isNumber(maybeNumber) ? maybeNumber : stringLiteral(maybeNumber);

const structuredToText = (
  questions: QuestionDefinitionSummaryType[],
  { operator, items }: StructuredCondition,
): string => {
  const itemsStr: string[] = items.map(
    ({ operator: itemOperator, questionUuid, responseUuid, value }) => {
      const question = questions.find(
        ({ wizardId }) => wizardId === questionUuid,
      );
      const responseIndex = question
        ? findIndex(
            ({ uniqueId }) => uniqueId === responseUuid,
            question.responses,
          )
        : -1;

      const response =
        question && responseIndex !== -1
          ? question.responses[responseIndex]
          : undefined;

      const codeStr =
        '$' + (question && response ? codeToString(question.code) : '#REF!');

      const addressStr =
        !response || responseIndex === 0
          ? codeStr
          : `${codeStr}@${responseIndex + 1}`;

      const operatorStr = itemOperatorToString(itemOperator);

      const valueStr =
        response && responseCanBeNumber(response)
          ? tryConvertNumberToString(value)
          : stringLiteral(value);

      return [addressStr, operatorStr, valueStr].join(' ');
    },
  );

  return itemsStr.join(` ${operatorToString(operator)} `);
};

export default structuredToText;
