import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Wrapper } from './StatusIcon.styles';
import { faCheck, faFileAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface StatusIconPropsType {
  color: string;
  status: string;
}

const StatusIcon = ({ color, status }: StatusIconPropsType) => (
  <Wrapper color={color}>
    {status === 'CREATED' && <FontAwesomeIcon icon={faStar as IconProp} />}
    {status === 'VENDOR_IN_PROGRESS' && (
      <FontAwesomeIcon icon={faStar as IconProp} />
    )}
    {status === 'VRM_IN_PROGRESS' && (
      <FontAwesomeIcon icon={faFileAlt as IconProp} />
    )}
    {status === 'ACCEPTED' && <FontAwesomeIcon icon={faCheck as IconProp} />}
  </Wrapper>
);

export default StatusIcon;
