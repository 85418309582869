import * as React from 'react';
import { FontSizeType } from 'common/common.fonts';
import { CheckboxContainer, Checkmark, Label } from './Checkbox.styles';

interface CheckboxPropsType {
  hideCheckmark?: boolean;
  isChecked: boolean;
  isCompact?: boolean;
  isDisabled?: boolean;
  isIndeterminate?: boolean;
  label?: string | React.ReactNode;
  marginTop?: number;
  onChange: (event: React.MouseEvent<HTMLDivElement>) => void;
  primaryColor?: string;
  secondaryColor?: string;
  size?: number;
  labelSize?: FontSizeType;
}

const Checkbox = ({
  hideCheckmark,
  isChecked,
  isCompact,
  isDisabled,
  isIndeterminate,
  label,
  marginTop,
  onChange,
  primaryColor,
  secondaryColor,
  size,
  labelSize,
}: CheckboxPropsType) => (
  <CheckboxContainer
    data-cy="checkbox"
    hideCheckmark={hideCheckmark}
    onClick={isDisabled ? () => null : onChange}
    isCompact={isCompact}
    isDisabled={isDisabled}
    isIndeterminate={isIndeterminate}
    primaryColor={primaryColor}
    secondaryColor={secondaryColor}
    size={size}
    marginTop={marginTop}
    labelSize={labelSize}
  >
    {label && <Label data-cy="checkbox-label">{label}</Label>}

    <input
      data-cy="checkbox-input"
      type="checkbox"
      disabled={isDisabled}
      checked={isChecked}
      readOnly
    />

    <Checkmark />
  </CheckboxContainer>
);

export default Checkbox;
