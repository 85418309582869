/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestsUpdate
 */
export interface RequestsUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof RequestsUpdate
     */
    clearNotifications?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestsUpdate
     */
    read?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestsUpdate
     */
    requestIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RequestsUpdate
     */
    status?: RequestsUpdateStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestsUpdate
     */
    vrmViewStatus?: RequestsUpdateVrmViewStatusEnum;
}

export function RequestsUpdateFromJSON(json: any): RequestsUpdate {
    return RequestsUpdateFromJSONTyped(json, false);
}

export function RequestsUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestsUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clearNotifications': !exists(json, 'clear_notifications') ? undefined : json['clear_notifications'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'requestIds': json['request_ids'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'vrmViewStatus': !exists(json, 'vrm_view_status') ? undefined : json['vrm_view_status'],
    };
}

export function RequestsUpdateToJSON(value?: RequestsUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clear_notifications': value.clearNotifications,
        'read': value.read,
        'request_ids': value.requestIds,
        'status': value.status,
        'vrm_view_status': value.vrmViewStatus,
    };
}

/**
* @export
* @enum {string}
*/
export enum RequestsUpdateStatusEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}
/**
* @export
* @enum {string}
*/
export enum RequestsUpdateVrmViewStatusEnum {
    VENDORAPPROVED = 'VENDOR_APPROVED',
    VENDORDECLINED = 'VENDOR_DECLINED',
    VENDORINITIAL = 'VENDOR_INITIAL',
    VRMREQUESTED = 'VRM_REQUESTED'
}


