import styled from 'styled-components';

export const WrapperRel = styled.div`
  position: relative;
  width: auto;
  min-width: 180px;
`;

export const WrapperAbs = styled.div`
  position: absolute;
  width: 100%;
  top: 0;

  ul {
    padding: 12px;
    border-radius: 12px;
    list-style: none;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease-in-out;
    position: absolute;
    width: 100%;
  }
`;

export const Input = styled.input`
  ${({ theme }) => theme.fonts.bold.small}
  height: 24px;
  position: relative;
  top: 1px;
  left: 4px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  background: transparent;
  border: none;

  &:focus {
    opacity: 1;
    outline: none;
  }
`;
