import styled from 'styled-components';

export const FooterWrapper = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  z-index: 200;
`;

export const Footer = styled.div`
  padding: 14px;
  height: 62px;
  background-color: ${({ theme }) => theme.basic.colors.light};
  border: 1px ${({ theme }) => theme.basic.colors.darkerBorder} solid;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    white-space: nowrap;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;
