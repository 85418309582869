import ReactGA from 'react-ga';
import config, { Env } from '../config';
import { datadogRum } from '@datadog/browser-rum';
import { loadScript } from './preload';

function locationHashChanged() {
  ReactGA.pageview(window.location.hash.substring(1));
}

export const setupAnalytics = () => {
  ReactGA.initialize(config.googleAnalyticsKey, {
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });

  locationHashChanged();

  window.onhashchange = locationHashChanged;
};

export const trackEvent = (
  category: string,
  action: string,
  label?: string,
) => {
  ReactGA.event({
    action,
    category,
    label,
  });
  // Google analytics track 3 properties:
  // Action - e.g. Delegation
  // Category - e.g. Add - Form
  // Label - e.g. Question: 758c1b82-2539-471f-854f-Fd6e55b9f127
  // pendo can track arbitrary number of properties. For test purposes, let's start tracking Delegation as in GA

  datadogRum.addAction(`${category} - ${action}`, { category, label });

  heapTracking(category, { data: `action - ${action}, label - ${label}` });
  // Disabling this until further notice, need to explor the pendo Event Tracker API further
  // Currently causing error for customer's with Chrome's security settings turned to Max
  // Because on that setting it disables all tracking (going to require a POC)
  // const pendo = window.pendo;
  // pendo.track('Test', {
  //   plan: 'code',
  //   action,
  //   category,
  //   label,
  // });
};

const HEAP_SCRIPT_URL = `https://cdn.heapanalytics.com/js/heap-${config.heapKey}.js`;

const heapLoadConfig = {
  secureCookie: config.env !== Env.LOCAL,
};

const load = async () => {
  if ((window as any).heap && (window as any).heap.loaded) {
    return;
  }
  (window as any).heap = (window as any).heap || [];
  (window as any).heap.appid = config.heapKey;
  (window as any).heap.config = heapLoadConfig;
  await loadScript(HEAP_SCRIPT_URL);
};

const heapTracking = async (
  eventName: string,
  eventData: { [key: string]: string },
) => {
  await load();
  if (
    (window as any).heap &&
    typeof (window as any).heap.track === 'function' &&
    eventName
  ) {
    return (window as any).heap.track(eventName, eventData);
  }
  return null;
};

export const trackTiming = (
  category: string,
  variable: string,
  value: number,
  label: string,
) => {
  ReactGA.timing({
    category,
    label,
    value,
    variable,
  });
};
