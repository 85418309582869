import { Uuid } from '../../../common/common.types';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import update from 'immutability-helper';
import { reject, find } from 'ramda';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';
import { getCountOfQuestionsInCategory } from '../utils';

export type DeleteQuestionFunctionType = (questionId: Uuid) => void;

export type DeleteQuestionActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/DELETE_QUESTION'
>;

export const deleteQuestion = (questionId: Uuid): DeleteQuestionActionType => ({
  type: '@formCreator/EVENTS/DELETE_QUESTION',
  payload: {
    events: [
      {
        action: FormEditEventActionCodeEnum.DELETEQUESTION,
        question_uuid: questionId,
      },
    ],
    selector: ({ formCreator, formCreator: { currentForm } }) => {
      if (!currentForm) {
        return formCreator;
      }

      const removedQuestion = find(
        question => question.wizardId === questionId,
        currentForm.questions,
      );

      const newQuestions = reject(
        ({ wizardId }) => wizardId === questionId,
        currentForm.questions,
      );

      const newCategories =
        removedQuestion &&
        getCountOfQuestionsInCategory(newQuestions, removedQuestion.category) <
          1
          ? reject(
              ({ name }) => name === removedQuestion.category,
              currentForm.categories,
            )
          : currentForm.categories;

      return update(formCreator, {
        currentForm: {
          categories: { $set: newCategories },
          questions: { $set: newQuestions },
        },
        validation: {
          attemptedFixes: {
            $unset: [
              questionId,
              ...(removedQuestion
                ? removedQuestion.responses.map(r => r.uniqueId)
                : []),
            ],
          },
        },
      });
    },
  },
});
