/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    Team,
    TeamFromJSON,
    TeamFromJSONTyped,
    TeamToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 *
 * @export
 * @interface RequestExternalEntityData
 */
export interface RequestExternalEntityData {
    /**
     *
     * @type {boolean}
     * @memberof RequestExternalEntityData
     */
    isVendor: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestExternalEntityData
     */
    isVrm: boolean;
    /**
     *
     * @type {Company}
     * @memberof RequestExternalEntityData
     */
    source: Company;
    /**
     *
     * @type {Team}
     * @memberof RequestExternalEntityData
     */
    sourceTeam?: Team;
    /**
     *
     * @type {User}
     * @memberof RequestExternalEntityData
     */
    sourceUser: User;
    /**
     *
     * @type {Company}
     * @memberof RequestExternalEntityData
     */
    target: Company;
    /**
     *
     * @type {Team}
     * @memberof RequestExternalEntityData
     */
    targetTeam?: Team;
    /**
     *
     * @type {User}
     * @memberof RequestExternalEntityData
     */
    targetUser: User;
    /**
     * Current vrm view status
     * @type {string}
     * @memberof RequestExternalEntityData
     */
    vrmViewStatus: RequestExternalEntityDataVrmViewStatusEnum;
}

export function RequestExternalEntityDataFromJSON(json: any): RequestExternalEntityData {
    return RequestExternalEntityDataFromJSONTyped(json, false);
}

export function RequestExternalEntityDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestExternalEntityData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'isVendor': json['is_vendor'],
        'isVrm': json['is_vrm'],
        'source': CompanyFromJSON(json['source']),
        'sourceTeam': !exists(json, 'source_team') ? undefined : TeamFromJSON(json['source_team']),
        'sourceUser': UserFromJSON(json['source_user']),
        'target': CompanyFromJSON(json['target']),
        'targetTeam': !exists(json, 'target_team') ? undefined : TeamFromJSON(json['target_team']),
        'targetUser': UserFromJSON(json['target_user']),
        'vrmViewStatus': json['vrm_view_status'],
    };
}

export function RequestExternalEntityDataToJSON(value?: RequestExternalEntityData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'is_vendor': value.isVendor,
        'is_vrm': value.isVrm,
        'source': CompanyToJSON(value.source),
        'source_team': TeamToJSON(value.sourceTeam),
        'source_user': UserToJSON(value.sourceUser),
        'target': CompanyToJSON(value.target),
        'target_team': TeamToJSON(value.targetTeam),
        'target_user': UserToJSON(value.targetUser),
        'vrm_view_status': value.vrmViewStatus,
    };
}

/**
* @export
* @enum {string}
*/
export enum RequestExternalEntityDataVrmViewStatusEnum {
    VENDORAPPROVED = 'VENDOR_APPROVED',
    VENDORDECLINED = 'VENDOR_DECLINED',
    VENDORINITIAL = 'VENDOR_INITIAL',
    VRMREQUESTED = 'VRM_REQUESTED'
}


