import styled from 'styled-components';

export const TextInfo = styled.span`
  ${({ theme }) => theme.basic.fontWeights.light};
  color: ${({ theme }) => theme.basic.colors.gray};
  width: 90%;
`;

export const Score = styled.span`
  padding-right: 5px;
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
