import React, { SyntheticEvent, useCallback } from 'react';
import { BarWrapper } from '../../common/Bar/Bar.styles';
import { Button } from '../../common/Button';
import { withTranslation } from 'react-i18next';
import { StandardType, TranslatePropsType, Uuid } from 'common/common.types';
import { BetaSign } from '../../common/BetaSign';
import {
  AutosavedInfo,
  Controls,
  Label,
  Title,
  TitleInput,
  Version,
  VersionInput,
  BetaSignWrapper,
} from './Header.styles';
import { UpdateFormDefinitionFunctionType } from 'store/formCreator/actions';

export interface HeaderPropsType extends TranslatePropsType {
  draftId: Uuid;
  isSaving: boolean;
  lastUpdate: Date;
  onPreview: (event: SyntheticEvent<any, Event> | undefined) => void;
  previewDisabled: boolean;
  publishDisabled: boolean;
  publishForm: () => void;
  savingError: boolean;
  showBetaSign: boolean;
  standard: StandardType;
  updateForm: UpdateFormDefinitionFunctionType;
}

const Header = ({
  draftId,
  isSaving,
  lastUpdate,
  onPreview,
  previewDisabled,
  publishDisabled,
  publishForm,
  savingError,
  showBetaSign,
  standard: { id, name, previewFormId, version },
  updateForm,
  t,
}: HeaderPropsType) => {
  const onUpdateName = useCallback(
    (newName: string) => updateForm({ id, name: newName }),
    [id, updateForm],
  );

  const onUpdateVersion = useCallback(
    (newVersion: string) => updateForm({ id, name, version: newVersion }),
    [id, name, updateForm],
  );

  return (
    <BarWrapper shadow>
      {showBetaSign && (
        <BetaSignWrapper>
          <BetaSign />
        </BetaSignWrapper>
      )}
      <Title data-test="questionnaire-name">
        <Label>{t('title') as string}</Label>
        <TitleInput onBlur={onUpdateName} value={name} variant="filled" />
      </Title>

      <Version data-test="version-value">
        <Label>{t('version') as string}</Label>
        <VersionInput
          onBlur={onUpdateVersion}
          value={version}
          variant="filled"
        />
      </Version>

      <AutosavedInfo
        isInErrorState={savingError}
        isSaving={isSaving}
        lastUpdate={lastUpdate}
      />

      <Controls>
        <Button
          disabled={previewDisabled}
          id="preview"
          onClick={e => onPreview(e)}
          color="transparent"
          size="medium"
        >
          {t('preview') as string}
        </Button>
        <Button disabled={publishDisabled} size="medium" onClick={publishForm}>
          {t('publish') as string}
        </Button>
      </Controls>
    </BarWrapper>
  );
};

export default withTranslation('FormCreator')(Header);
