import { MappingRequest } from 'generated/models/MappingRequest';
import { MappingRequestType } from 'common/common.types';
import { parseParsedFile } from '.';
import { EMPTY_ID } from 'common/common.constants';

export const parseMappingRequest = (
  mapping: MappingRequest,
): MappingRequestType => {
  const {
    created_at,
    updated_at,
    id = EMPTY_ID,
    parsed_files = [],
    status = 0,
    updated_questions_ids = [],
    user_id = EMPTY_ID,
  } = mapping;

  return {
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    id,
    parsedFiles: parsed_files.map(parseParsedFile),
    status,
    updatedQuestionsIds: updated_questions_ids,
    userId: user_id,
  };
};
