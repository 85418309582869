import { Header, Scrollable, Section, Title } from './FilterBy.styles';
import { append, assoc, contains, reject } from 'ramda';
import Checkbox from '../../common/Checkbox/Checkbox';
import { FilterByPropsType } from './FilterBy';
import React from 'react';
import { TextButton } from 'common/common.styles';
import { withTranslation } from 'react-i18next';
import { FilterOptionType } from 'utils/filters/requests.filters';

const OPTION = 'STANDARDS';

export const FilterStandards = ({
  data: {
    filterBy,
    filterOptions: { STANDARDS },
    isSent,
  },
  actions: { setFilter },
  t,
}: FilterByPropsType) => {
  const handleChange = (standard: FilterOptionType) => () => {
    const lookFor = contains(standard.value, filterBy.STANDARDS.lookFor || [])
      ? reject(item => item === standard.value, filterBy.STANDARDS.lookFor)
      : append(standard.value, filterBy.STANDARDS.lookFor);
    const isEnabled = lookFor.length > 0;

    setFilter(assoc(OPTION, { isEnabled, lookFor }, filterBy));
  };

  const handleClear = () =>
    setFilter(assoc(OPTION, { isEnabled: false, lookFor: [] }, filterBy));

  return (
    <Section>
      <Header>
        <Title>{t(`Requests.filterOptions.${OPTION}`) as string}</Title>
        <TextButton color="teal" onClick={handleClear} size="small">
          {t('common:labels.clear') as string}
        </TextButton>
      </Header>
      <Scrollable>
        {STANDARDS!.map((standard: FilterOptionType) => (
          <Checkbox
            key={`${standard.value}-checkbox`}
            isChecked={Boolean(
              filterBy.STANDARDS.isEnabled &&
                contains(standard.value, filterBy.STANDARDS.lookFor),
            )}
            isCompact
            secondaryColor="teal"
            label={`${standard.label}${
              standard.count !== undefined ? ` (${standard.count})` : ''
            }`}
            labelSize="small"
            onChange={handleChange(standard)}
          />
        ))}
      </Scrollable>
    </Section>
  );
};

export default withTranslation()(FilterStandards);
