import getGroupedStatus from '../getGroupedStatus';
import { RequestsInOutType } from 'store/requests/requests.types';
import { STATUS_TO_CATEGORY } from 'common/common.constants';
import { StateType } from 'store/store.types';
import { append, reduce, uniq } from 'ramda';
import { parseCompany, parseCompanySimple } from './companies.parsers';
import { parseStandard } from './standard.parsers';
import { requestsInOutInitialState } from 'store/requests/requests.reducer';
import { sortRequestCategories } from '../filters/requests.sorters';
import {
  RequestDetailType,
  RequestSimpleType,
  RequestDirectionType,
  RequestFormRolesEnum,
  RequestType,
  ScheduledRequestType,
} from 'common/common.types';
import hasRoles from '../arrayHasSome';
import {
  RequestDetail,
  RequestDetailFormRolesEnum,
  RequestDetailStatusEnum,
} from 'generated/models/RequestDetail';
import {
  FormRolesEnum,
  RequestSimple,
  StatusEnum,
} from 'generated/models/RequestSimple';
import { ScheduledRequest } from 'generated/models/ScheduledRequest';
import { RequestSummary } from 'generated/models';
import { TeamFromJSON } from 'generated/new';
import { parseUser, parseUserSimple } from './user.parsers';

export const parseRequest = (
  request: RequestDetail | RequestSummary,
  state?: StateType,
): RequestType => {
  const isVRM = hasRoles<RequestFormRolesEnum>(
    request.form_roles,
    RequestDetailFormRolesEnum.VRM,
    RequestDetailFormRolesEnum.VRMPROXY,
  );
  const isVendor = hasRoles<RequestFormRolesEnum>(
    request.form_roles,
    RequestDetailFormRolesEnum.VENDOR,
    RequestDetailFormRolesEnum.VENDORPROXY,
  );
  const isProxy = hasRoles<RequestFormRolesEnum>(
    request.form_roles,
    RequestDetailFormRolesEnum.VENDORPROXY,
    RequestDetailFormRolesEnum.VRMPROXY,
  );
  const isInternal = request.is_internal;
  const source = parseCompany(request.source);
  const target = parseCompany(request.target);
  const targetUser = parseUser({ user: request.target_user });
  const sourceTeam = request.source_team
    ? TeamFromJSON(request.source_team)
    : undefined;
  const targetTeam = request.target_team
    ? TeamFromJSON(request.target_team)
    : undefined;
  const isVerified = isVRM ? target.isVerified : source.isVerified;
  const receiver =
    isInternal && targetTeam
      ? targetTeam.name
      : request.target_user.email || request.target.name;
  const sender =
    isInternal && sourceTeam
      ? sourceTeam.name
      : sourceTeam && sourceTeam.name && isVRM
      ? sourceTeam.name
      : request.source_user.email || '';
  const receiverCompanyOrTeam =
    isInternal && targetTeam ? targetTeam.name : target.name;
  const senderCompanyOrTeam =
    isInternal && sourceTeam ? sourceTeam.name : source.name;

  return {
    message: request.message,
    meta: state
      ? {
          canViewData: isVRM ? request.can_view_data : true,
          company: isVRM ? target.name : source.name,
          companyId: isVRM ? target.id : source.id,
          completedPercentage: (request.form_filled / request.form_total) * 100,
          email: isVRM
            ? request.target_user.email || ''
            : request.source_user.email,
          grade: isVRM ? target.grade : undefined,
          gradeUrl: isVRM ? target.gradeUrl : undefined,
          isArchivable: isVRM,
          isProxy,
          isVisible: true,
          isVerified,
          isVendor,
          isVRM,
          questionsLeftCount: request.form_total - request.form_filled,
          receiver,
          receiverCompanyOrTeam,
          sender,
          senderCompanyOrTeam,
          statusBeforeArchive: request.status_before_archived
            ? getGroupedStatus(request.status_before_archived, !isVRM)
            : '',
        }
      : {
          canViewData: true,
          company: '',
          companyId: undefined,
          completedPercentage: (request.form_filled / request.form_total) * 100,
          email: '',
          grade: undefined,
          gradeUrl: undefined,
          isProxy,
          isVisible: true,
          isVerified,
          isVendor,
          isVRM,
          questionsLeftCount: request.form_total - request.form_filled,
          receiver,
          receiverCompanyOrTeam,
          sender,
          senderCompanyOrTeam,
          statusBeforeArchive: request.status_before_archived
            ? getGroupedStatus(request.status_before_archived, false)
            : '',
        },
    acceptedDate: request.accepted_date
      ? new Date(request.accepted_date)
      : undefined,
    completionDays: request.completion_days || null,
    dueDate: request.due_date ? new Date(request.due_date) : undefined,
    form: {
      filled: request.form_filled,
      id: request.form_id,
      isScoreVisible: request.form_score_visible,
      name: request.form_name,
      partiallyFilled: request.form_questions_partially_filled,
      percentageFilled: request.form_percentage_filled,
      responseCount: request.form_response_count,
      responsesFilledCount: request.form_responses_filled,
      score: request.form_score,
      total: request.form_total,
    },
    id: request.id,
    isVrmStatusChangeable:
      request.revision_count < 2 &&
      (request.status === RequestDetailStatusEnum.CREATED ||
        request.status === RequestDetailStatusEnum.VENDORINPROGRESS),
    isInternal,
    requestedAt: request.created_at && new Date(request.created_at),
    revisionCount: request.revision_count || 0,
    riskNote: request.risk_note || '',
    updatedAt: request.updated_at && new Date(request.updated_at),
    source,
    sourceTeam,
    standard: {
      code: request.standard.code,
      description: request.standard.description,
      id: request.standard.id,
      logo: request.standard.logo,
      name: request.standard.name,
      updatedAt: new Date(request.updated_at),
      status: request.standard.status,
      version: [request.standard.version, request.standard.year].join(' '),
      year: request.standard.year || 0,
    },
    status: request.status,
    target,
    targetTeam,
    targetUser,
    targetOpenedAt: request.target_opened_at
      ? new Date(request.target_opened_at)
      : undefined,
    vrmViewStatus: request.vrm_view_status,
  };
};

export const parseRequestDetail = (
  request: RequestDetail,
  state?: StateType,
): RequestDetailType => ({
  ...parseRequest(request, state),
  hasNewAttachments: request.has_new_attachments,
  hasNewMessages: request.has_new_comments,
  hasNewSscData: request.has_new_ssc_data,
  hasNewResponses: request.has_new_history,
  isRead: !request.is_unread,
});

export const parseRequestDetailList = (
  requestsArray: RequestDetail[],
  state?: StateType,
): RequestDetailType[] =>
  requestsArray.map((request: RequestDetail) =>
    parseRequestDetail(request, state),
  );

export const parseRequestSummaryList = (
  requests: RequestSummary[],
  state?: StateType,
): RequestType[] => requests.map(request => parseRequest(request, state));

export const parseArchivedRequests = (
  requestsArray: RequestDetail[],
  state?: StateType,
): RequestType[] =>
  parseRequestDetailList(
    requestsArray.filter(r => r.status === RequestDetailStatusEnum.ARCHIVED),
    state,
  );

export const parseRequestsCategorized = (
  requestsArray: RequestSimple[],
  state: StateType,
  direction: RequestDirectionType,
): RequestsInOutType => {
  const unsorted = reduce(
    (requests, request) => {
      const parsedRequest = parseRequestSimple(request, state);

      return {
        ...requests,
        categories: {
          ...requests.categories,
          [STATUS_TO_CATEGORY[request.status]]: {
            ...requests.categories[STATUS_TO_CATEGORY[request.status]],
            requests: append(
              parsedRequest,
              requests.categories[STATUS_TO_CATEGORY[request.status]].requests,
            ),
            count:
              requests.categories[STATUS_TO_CATEGORY[request.status]].count + 1,
          },
        },
        count: requests.count + 1,
        unsorted: append(parsedRequest, requests.unsorted),
      };
    },
    requestsInOutInitialState,
    requestsArray.filter(r => r.status !== StatusEnum.ARCHIVED),
  );
  /*
    received in the context of "Answering Questionnaires"
    sent in the contect of "Sending Questionnaires"
    Added to fix PROPS-11597
  */

  const company =
    direction === 'received' ? 'source_company' : 'target_company';
  unsorted.filterOptions.COMPANIES = uniq(
    requestsArray.map(request => {
      return {
        label: request[company].name,
        value: request[company].id,
      };
    }),
  );
  unsorted.filterOptions.STANDARDS = uniq(
    requestsArray.map(request => {
      return {
        label: request.standard.name,
        value: request.standard.id,
      };
    }),
  );
  return {
    ...unsorted,
    categoriesOpened: state.requests[direction].categoriesOpened,
    filter: state.requests[direction].filter,
    categories: sortRequestCategories(state.requests[direction].filter.sortBy)(
      unsorted.categories,
    ),
  };
};

export const parseScheduledRequest = (
  request: ScheduledRequest,
): ScheduledRequestType => {
  const isInternal = request.is_internal;
  const sourceTeam = request.source_team
    ? TeamFromJSON(request.source_team)
    : undefined;
  const targetTeam = request.target_team
    ? TeamFromJSON(request.target_team)
    : undefined;
  const target = parseCompany(request.target);
  const receiver =
    isInternal && targetTeam
      ? targetTeam.name
      : request.target_user_email || request.target.name;
  const sender =
    sourceTeam && sourceTeam.name
      ? sourceTeam.name
      : request.source_user_email || '';
  const receiverCompanyOrTeam =
    isInternal && targetTeam ? targetTeam.name : target.name;

  return {
    emailBody: request.email_body,
    id: request.id,
    isCancelled: request.cancelled,
    isInternal: request.is_internal,
    meta: {
      receiver,
      receiverCompanyOrTeam,
      sender,
    },
    nextSendDate: request.next_send_date && new Date(request.next_send_date),
    occurrences: request.num_of_occurrences,
    receiver: request.target_user_email || request.target.domain,
    recurrenceType: request.recurrence_type,
    recurrenceUnits: request.recurrence_units,
    sender: request.source_user_email || request.source.domain,
    source: {
      email: request.source_user_email || '',
      id: request.source.id,
      name: request.source.name,
    },
    standard: parseStandard(request.standard),
    target: parseCompany(request.target),
  };
};

export const parseScheduledRequests = (
  requestsArray: ScheduledRequest[],
): ScheduledRequestType[] =>
  requestsArray.map((request: ScheduledRequest) =>
    parseScheduledRequest(request),
  );

export const parseRequestSimple = (
  request: RequestSimple,
  state?: StateType,
): RequestSimpleType => {
  const isVRM = hasRoles<FormRolesEnum>(
    request.form.roles,
    FormRolesEnum.VRM,
    FormRolesEnum.VRMPROXY,
  );
  const isVendor = hasRoles<FormRolesEnum>(
    request.form.roles,
    FormRolesEnum.VENDOR,
    FormRolesEnum.VENDORPROXY,
    FormRolesEnum.OWNER,
  );
  const isInternal = request.is_internal;
  const source = parseCompanySimple(request.source_company);
  const target = parseCompanySimple(request.target_company);
  const targetUser = parseUserSimple({ user: request.target_user });
  const isVerified = isVRM ? target.isVerified : source.isVerified;
  const targetTeamName = request.target_team.name;
  const sourceTeamName = request.source_team.name;
  const receiver =
    isInternal && targetTeamName
      ? targetTeamName
      : request.target_user.email || request.target_company.name;
  const sender =
    isInternal && sourceTeamName
      ? sourceTeamName
      : request.source_user.email || '';
  const receiverCompanyOrTeam = target.name;
  const senderCompanyOrTeam = source.name;

  return {
    id: request.id,
    dueDate: request.due_date ? new Date(request.due_date) : undefined,
    completionDays: request.completion_days || null,
    acceptedDate: request.accepted_date
      ? new Date(request.accepted_date)
      : undefined,
    isInternal,
    updatedAt: new Date(request.updated_at_vendor),
    vrmViewStatus: request.vrm_view_status,
    hasNewAttachments: request.has_new_attachments,
    hasNewMessages: request.has_new_comments,
    hasNewSscData: request.has_new_ssc_data,
    hasNewResponses: request.has_new_history,
    isRead: !request.is_unread,
    requestedAt: request.created_at && new Date(request.created_at),
    revisionCount: request.form.revision_count || 0,
    form: {
      filled: request.form.questions_filled,
      id: request.form.id,
      isScoreVisible: request.form.score_visible,
      name: request.form.name,
      partiallyFilled: request.form.questions_partially_filled,
      percentageFilled: request.form.percentage_filled,
      responseCount: request.form.response_count,
      responsesFilledCount: request.form.responses_filled,
      revisionCount: request.form.revision_count || 0,
      score: request.form.score,
      total: request.form.question_count,
    },
    meta: state
      ? {
          isVRM: isVRM,
          isVendor: isVendor,
          companyId: isVRM ? target.id : source.id,
          canViewData: isVRM ? request.can_view_data : true,
          gradeUrl: isVRM ? target.gradeUrl : undefined,
          isVisible: true,
          company: isVRM ? target.name : source.name,
          email: isVRM
            ? request.target_user.email || ''
            : request.source_user.email,
          isVerified,
          isArchivable: isVRM,
          receiver,
          receiverCompanyOrTeam,
          sender,
          senderCompanyOrTeam,
          completedPercentage:
            (request.form.questions_filled / request.form.question_count) * 100,
          statusBeforeArchive: request.status_before_archived
            ? getGroupedStatus(request.status_before_archived, !isVRM)
            : '',
        }
      : {
          canViewData: true,
          gradeUrl: undefined,
          isVisible: true,
          company: '',
          email: '',
          isVerified,
          receiver,
          receiverCompanyOrTeam,
          sender,
          senderCompanyOrTeam,
          completedPercentage:
            (request.form.questions_filled / request.form.question_count) * 100,
          statusBeforeArchive: request.status_before_archived
            ? getGroupedStatus(request.status_before_archived, false)
            : '',
          isVRM: isVRM,
          isVendor: isVendor,
        },
    source,
    status: request.status,
    target,
    targetUser,
    standard: {
      version: [request.standard.version, request.standard.year].join(' '),
      year: request.standard.year || 0,
      name: request.standard.name,
    },
    isVrmStatusChangeable:
      request.form.revision_count < 2 &&
      (request.status === StatusEnum.CREATED ||
        request.status === StatusEnum.VENDORINPROGRESS),
  };
};

export const parseRequestSimpleList = (
  requestsArray: RequestSimple[],
  state?: StateType,
): RequestSimpleType[] =>
  requestsArray.map((request: RequestSimple) =>
    parseRequestSimple(request, state),
  );
