import styled from 'styled-components';

export const Title = styled.div`
  ${({ theme }) => theme.fonts.regular.small}
  margin-bottom: 15px;

  svg {
    margin-right: 15px;
  }
`;
