import React from 'react';
import { BarWrapper } from '../common/Bar/Bar.styles';
import { withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { TranslatePropsType } from 'common/common.types';

import { CreateEmptyFormFunctionType } from 'store/formCreator/actions';

import {
  FormDefinitionsFilterByStatusFunctionType,
  FormDefinitionsSetSortFunctionType,
} from 'store/formDefinitions/actions';
import FormDefinitionsSortBy from './FormDefinitionsSortBy';
import { FormDefinitionsFilterType } from 'store/formDefinitions/formDefinitions.types';

import { Button } from '../common/Button';

import { FORM_DEFINITIONS_ADD_NEW_MODAL_ID } from 'common/common.constants';
import { AddNewModal } from './Modals';

import { BetaSign } from '../common/BetaSign';

import {
  BetaSignWrapper,
  ButtonContent,
  Controls,
  PlusIcon,
  TitleWrapper,
  Wrapper,
  FilterControls,
  FilterBar,
} from './FormDefinitionsHeader.styles';

import { FilterBy, Filters } from './Filters';

interface HeaderType extends TranslatePropsType, RouteComponentProps {
  filter: FormDefinitionsFilterType;
  setSort: FormDefinitionsSetSortFunctionType;
  setFilterByStatus: FormDefinitionsFilterByStatusFunctionType;
  showBetaSign: boolean;
  showModal: (modalId: string) => void;
  createEmptyForm: CreateEmptyFormFunctionType;
}

const FormDefinitionsHeader = ({
  filter: { sortBy, byStatus },
  history: { push: navigate },
  setSort,
  setFilterByStatus,
  showBetaSign,
  showModal,
  createEmptyForm,
  t,
}: HeaderType) => {
  return (
    <BarWrapper>
      {showBetaSign && (
        <BetaSignWrapper>
          <BetaSign />
        </BetaSignWrapper>
      )}

      <TitleWrapper>
        <h2 data-test="page-header">{t('heading') as string}</h2>
      </TitleWrapper>

      <AddNewModal
        modalId={FORM_DEFINITIONS_ADD_NEW_MODAL_ID}
        createEmptyForm={createEmptyForm}
      />
      <Wrapper>
        <Controls>
          <FilterControls>
            <FilterBy
              data={{
                byStatus,
                isClearable: true,
              }}
              actions={{ setFilterByStatus }}
            />
          </FilterControls>

          <FormDefinitionsSortBy data={{ sortBy }} actions={{ setSort }} />

          <Button
            color={'teal'}
            size={'medium'}
            onClick={() => showModal(FORM_DEFINITIONS_ADD_NEW_MODAL_ID)}
          >
            <ButtonContent data-test="add-new-button">
              <PlusIcon />
              {t('addNewButtonText') as string}
            </ButtonContent>
          </Button>
        </Controls>
        <FilterBar>
          <Filters
            data={{
              byStatus,
            }}
            actions={{ setFilterByStatus }}
          />
        </FilterBar>
      </Wrapper>
    </BarWrapper>
  );
};

export default withTranslation('FormDefinitions')(
  withRouter(FormDefinitionsHeader),
);
