import React from 'react';
import {
  CloseIcon,
  Closer,
  Button,
  Text,
  Wrapper,
} from './Notification.styles';
import ReactHtmlParser from 'react-html-parser';
import { NotificationEnum } from 'common/common.types';

export interface NotificationPropsType {
  dismiss?: () => void;
  isDismissable?: boolean;
  onClick: () => void;
  textButton: string;
  textHTML: string;
  type: NotificationEnum;
}

export const Notification = ({
  dismiss,
  isDismissable = true,
  onClick,
  textButton,
  textHTML,
  type,
}: NotificationPropsType) => {
  const handleClick = () => {
    if (isDismissable && dismiss) {
      dismiss();
    }

    onClick();
  };

  return (
    <Wrapper type={type}>
      <Text>{ReactHtmlParser(textHTML)}</Text>
      <Button onClick={handleClick}>{textButton}</Button>
      {isDismissable && (
        <Closer data-cy="dismissNotification" onClick={dismiss}>
          <CloseIcon />
        </Closer>
      )}
    </Wrapper>
  );
};

export default Notification;
