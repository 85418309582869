import pendo from 'utils/pendo';
import { ApiCallActionType } from '../../store.types';
import { UserInfo } from '../../../generated/models/UserInfo';
import { call, put } from 'redux-saga/effects';
import { storeSettings } from '../../settings/actions/storeSettings';
import delay from 'utils/delayPromise';
import { setSentryUser } from 'utils/sentryConfig';
import { getInitialState } from '../utils';
import { UserDetailInternalStatusEnum } from '../../../generated/models/UserDetail';
import { UserStateType } from '../user.types';

const { MERGING } = UserDetailInternalStatusEnum;

export type UserLoadAndInitInfoActionType = ApiCallActionType<
  '@user/API/LOAD_INIT_INFO',
  never,
  UserInfo,
  never,
  UserStateType
>;

export const loadAndInitInfo = (): UserLoadAndInitInfoActionType => ({
  type: '@user/API/LOAD_INIT_INFO',
  payload: {
    endpointPath: ['user', 'getInfo'],
    selector: data => getInitialState(data, undefined),
    postActions: ({ user: { internal_status } }: UserInfo) =>
      internal_status === MERGING
        ? [() => call(delay, 5000), () => put(loadAndInitInfo())]
        : [
            (data: UserInfo) => put(storeSettings(data.settings)),
            (data: UserInfo) => pendo(data),
            (data: UserInfo) => setSentryUser(data),
          ],
  },
});
