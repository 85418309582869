import * as fca from '../store/formCreator/actions';
import * as fda from '../store/formDefinitions/actions';

import multipartHeaders from '../utils/url/multipartHeaders';
import axios from 'axios';
import withUrl from '../utils/url/withUrl';
import {
  FormCreatorEventType,
  FormCreatorEventCallParamsType,
} from '../store/formCreator/event.types';

export default {
  post: (params: any) => axios.post(withUrl('definitions'), params),
  get: ({ definitionId }: any) =>
    axios.get(withUrl('definitions', definitionId)),
  patch: ({ definitionId }: any) =>
    axios.put(withUrl('definitions', definitionId)),
  drafts: {
    get: ({ id }: fca.GetFormParamType) =>
      axios.get(withUrl(`definitions/drafts/${id}`)),
    post: (
      params: fca.CreateEmptyFormCallParamsType &
        fda.DuplicateStandardFromFormDefinitionsParamsType,
    ) => axios.post(withUrl('definitions/drafts'), params),
    delete: (params: fda.DeleteDraftFromFormDefinitionsParamsType) =>
      axios.delete(withUrl('definitions/drafts'), { data: params }),
    postEvent: ({ draft_id, ...params }: FormCreatorEventCallParamsType) =>
      axios.post(
        withUrl(`definitions/drafts/${draft_id}/events`),
        params as FormCreatorEventType,
      ),
    publish: ({ draft_id, force }: fca.PublishFormCallParamsType) =>
      axios.post(withUrl(`definitions/drafts/${draft_id}/actions/publish`), {
        force,
      }),
    preview: ({ draftId }: fca.GetPreviewParamsType) =>
      axios.post(withUrl(`definitions/drafts/${draftId}/actions/preview`)),
    validate: ({ draft_id }: fca.ValidateFormCallParamsType) =>
      axios.post(withUrl(`definitions/drafts/${draft_id}/actions/validate`)),
  },
  system: {
    upload: (params: fda.SystemTemplateUploadDefinitionsCallParamsType) => {
      const formData = new FormData();
      formData.append('name', params.name);
      formData.append('code', params.code);
      formData.append('file', params.file || '');
      formData.append('description', params.description);
      formData.append('version', params.version);

      return axios.post(
        withUrl('definitions/system'),
        formData,
        multipartHeaders,
      );
    },
  },
};
