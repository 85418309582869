import { FormsModifyRequestCallParamsType } from './putRequest';
import { call, put } from 'redux-saga/effects';
import { trackEvent } from 'utils/analytics';
import { RequestDetail } from '../../../generated/models/RequestDetail';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { finishReportSaving } from './finishReportSaving';
import { Uuid } from '../../../common/common.types';

export type FormsUpdateRiskNoteFunctionType = (
  requestId: Uuid,
  riskNote: string,
) => void;

export type FormsUpdateRiskNoteActionType = OptimisticActionType<
  '@forms/API/UPDATE_RISK_NOTE',
  ApiCallActionPayloadType<
    FormsModifyRequestCallParamsType,
    RequestDetail,
    never,
    FormsStateType
  >
>;

export const updateRiskNote = (
  requestId: Uuid,
  riskNote: string,
): FormsUpdateRiskNoteActionType => ({
  type: '@forms/API/UPDATE_RISK_NOTE',
  payload: {
    callParams: {
      id: requestId,
      data: { risk_note: riskNote },
    },
    endpointPath: ['requests', 'modify'],
    expectedStateChange: {
      'forms.currentForm.request.riskNote': riskNote,
    },
    selector: null,
    postActions: [
      () => call(() => trackEvent('Request', 'Updated risk note')),
      () => put(finishReportSaving()),
    ],
  },
});
