/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    EvidenceLockerEntity,
    EvidenceLockerEntityFromJSON,
    EvidenceLockerEntityFromJSONTyped,
    EvidenceLockerEntityToJSON,
} from './';

/**
 * 
 * @export
 * @interface EvidenceLockerActivityLogsSummary
 */
export interface EvidenceLockerActivityLogsSummary {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    updatedAt: Date;
    /**
     * Type of the activity
     * @type {string}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    activityType: EvidenceLockerActivityLogsSummaryActivityTypeEnum;
    /**
     * Evidence locker entity associated with this activity log
     * @type {EvidenceLockerEntity}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    evidenceLockerEntity: EvidenceLockerEntity;
    /**
     * ID of the evidence locker activity log
     * @type {string}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    id: string;
    /**
     * IP address of the user's machine who performed the activity
     * @type {string}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    ipAddress: string;
    /**
     * Owner domain of the evidence locker entity
     * @type {string}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    ownerDomain: string;
    /**
     * User domain of the person who triggered this activity
     * @type {string}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    userDomain: string;
    /**
     * User email of the person who triggered this activity
     * @type {string}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    userEmail: string;
}

export function EvidenceLockerActivityLogsSummaryFromJSON(json: any): EvidenceLockerActivityLogsSummary {
    return EvidenceLockerActivityLogsSummaryFromJSONTyped(json, false);
}

export function EvidenceLockerActivityLogsSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvidenceLockerActivityLogsSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'activityType': json['activity_type'],
        'evidenceLockerEntity': EvidenceLockerEntityFromJSON(json['evidence_locker_entity']),
        'id': json['id'],
        'ipAddress': json['ip_address'],
        'ownerDomain': json['owner_domain'],
        'userDomain': json['user_domain'],
        'userEmail': json['user_email'],
    };
}

export function EvidenceLockerActivityLogsSummaryToJSON(value?: EvidenceLockerActivityLogsSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'activity_type': value.activityType,
        'evidence_locker_entity': EvidenceLockerEntityToJSON(value.evidenceLockerEntity),
        'id': value.id,
        'ip_address': value.ipAddress,
        'owner_domain': value.ownerDomain,
        'user_domain': value.userDomain,
        'user_email': value.userEmail,
    };
}

/**
* @export
* @enum {string}
*/
export enum EvidenceLockerActivityLogsSummaryActivityTypeEnum {
    DELETED = 'DELETED',
    DOWNLOADED = 'DOWNLOADED',
    IMPORTED = 'IMPORTED',
    MODIFIED = 'MODIFIED',
    VIEWED = 'VIEWED'
}


