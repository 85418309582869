import { useCallback, useState } from 'react';
import { FORM_RESPONSE_MODAL_ID } from 'common/common.constants';

export function useFormResponseModal<T extends (...args: any) => any>(
  callback: T,
  showModal: (modalId: string) => void,
  hideModal: (modalId: string) => void,
  modalId: string = FORM_RESPONSE_MODAL_ID,
) {
  const [valueToSendBack, setValueToSendBack] = useState({
    id: '',
    data: {
      reason: '',
      additionalInformation: '',
    },
  });

  const showSendBack = useCallback(
    (value: any) => {
      setValueToSendBack(prevState => ({
        ...prevState,
        ...value,
      }));
      showModal(modalId);
    },
    [modalId, setValueToSendBack, showModal],
  );

  const setSendBackValues = useCallback(
    (value: any) => {
      setValueToSendBack(prevState => ({
        ...prevState,
        ...value,
      }));
    },
    [setValueToSendBack],
  );

  const onSendBack = useCallback(() => {
    if (valueToSendBack) {
      callback(valueToSendBack);
      hideModal(modalId);
      setValueToSendBack({
        id: '',
        data: {
          reason: '',
          additionalInformation: '',
        },
      });
    }
  }, [callback, hideModal, modalId, setValueToSendBack, valueToSendBack]);

  const onCancelSendBack = useCallback(() => {
    hideModal(modalId);
    setValueToSendBack({
      id: '',
      data: {
        reason: '',
        additionalInformation: '',
      },
    });
  }, [hideModal, modalId, setValueToSendBack]);

  return {
    onCancelSendBack,
    onSendBack,
    showSendBack,
    valueToSendBack,
    setSendBackValues,
  };
}
