import React from 'react';
import { X_VALUES, X_VALUES_POSITION } from './Visualisation';
import { VerticalLine } from './Visualisation.styles';

const VerticalLines = () => (
  <div>
    {X_VALUES.map(grade => (
      <VerticalLine
        x={X_VALUES_POSITION[grade]}
        key={`vertical-line-${grade}`}
      />
    ))}
  </div>
);

export default VerticalLines;
