import styled from 'styled-components';
import Textarea from '../../common/Textarea';

export const Wrapper = styled.div`
  margin-top: 20px;
`;

export const TextareaInput = styled(Textarea)`
  height: 85px;
`;
