import React from 'react';
import hasRole from 'utils/hasRole';
import { P403 } from '../../routes';
import { Redirect } from 'react-router-dom';
import { StateType } from 'store/store.types';
import { UserDetailRolesEnum } from 'generated/models/UserDetail';
import { connect } from 'react-redux';
import { CurrentUserType } from 'common/common.types';

interface RoleProps {
  user: CurrentUserType;
}

const withRole = (...roles: UserDetailRolesEnum[]) => <BaseProps extends {}>(
  BaseComponent: React.ComponentType<BaseProps>,
) => {
  const mapStateToProps = (state: StateType): RoleProps => ({
    user: state.user.currentUser,
  });

  const Hoc = React.memo(({ user, ...others }: BaseProps & RoleProps) => {
    if (!user.isAuthenticated || hasRole(user, ...roles)) {
      return <BaseComponent {...((others as unknown) as BaseProps)} />;
    }
    return <Redirect to={P403} />;
  });

  return (connect(mapStateToProps)(
    (Hoc as unknown) as React.ComponentType<RoleProps>,
  ) as unknown) as React.ComponentType<BaseProps>;
};

export default withRole;
