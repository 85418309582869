/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryDelegationStats,
    CategoryDelegationStatsFromJSON,
    CategoryDelegationStatsFromJSONTyped,
    CategoryDelegationStatsToJSON,
    VRMReviewStats,
    VRMReviewStatsFromJSON,
    VRMReviewStatsFromJSONTyped,
    VRMReviewStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormCategoryStats
 */
export interface FormCategoryStats {
    /**
     * Summary of delegation of the category as a whole
     * @type {CategoryDelegationStats}
     * @memberof FormCategoryStats
     */
    delegation: CategoryDelegationStats;
    /**
     * Name of the category
     * @type {string}
     * @memberof FormCategoryStats
     */
    name: string;
    /**
     * Integer that can be used to sort categories to get their positions in form. Note that
     * positions of neighboring items don't have to follow immediately one after another
     * (positions can be 3, 6, 20 instead of 1, 2, 3).
     * @type {number}
     * @memberof FormCategoryStats
     */
    pos: number;
    /**
     * Number of questions in the category with current filter applied
     * @type {number}
     * @memberof FormCategoryStats
     */
    questionCount: number;
    /**
     * Number of all questions in the category with filter disabled
     * @type {number}
     * @memberof FormCategoryStats
     */
    questionCountIgnoreFilter: number;
    /**
     * Number of questions in the category including those that should be normally hidden due
     * to e.g. an unmet visibility condition
     * @type {number}
     * @memberof FormCategoryStats
     */
    questionCountIgnoreVisibility: number;
    /**
     * Number of questions in the category that have all responses filled
     * @type {number}
     * @memberof FormCategoryStats
     */
    questionsCompletelyFilled: number;
    /**
     * Number of questions in the category that have either all responses filled or all
     * **required** responses filled
     * @type {number}
     * @memberof FormCategoryStats
     */
    questionsFilled: number;
    /**
     * Number of questions in the category that have at least one response and not all
     * responses filled
     * @type {number}
     * @memberof FormCategoryStats
     */
    questionsPartiallyFilled: number;
    /**
     * Number of questions in the category that are visible with applied conditions and
     * filters
     * @type {number}
     * @memberof FormCategoryStats
     */
    questionsTotalVisible: number;
    /**
     * Summary of VRM review status for the category as a whole
     * @type {VRMReviewStats}
     * @memberof FormCategoryStats
     */
    vrmReviewStatus: VRMReviewStats;
}

export function FormCategoryStatsFromJSON(json: any): FormCategoryStats {
    return FormCategoryStatsFromJSONTyped(json, false);
}

export function FormCategoryStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormCategoryStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delegation': CategoryDelegationStatsFromJSON(json['delegation']),
        'name': json['name'],
        'pos': json['pos'],
        'questionCount': json['question_count'],
        'questionCountIgnoreFilter': json['question_count_ignore_filter'],
        'questionCountIgnoreVisibility': json['question_count_ignore_visibility'],
        'questionsCompletelyFilled': json['questions_completely_filled'],
        'questionsFilled': json['questions_filled'],
        'questionsPartiallyFilled': json['questions_partially_filled'],
        'questionsTotalVisible': json['questions_total_visible'],
        'vrmReviewStatus': VRMReviewStatsFromJSON(json['vrm_review_status']),
    };
}

export function FormCategoryStatsToJSON(value?: FormCategoryStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delegation': CategoryDelegationStatsToJSON(value.delegation),
        'name': value.name,
        'pos': value.pos,
        'question_count': value.questionCount,
        'question_count_ignore_filter': value.questionCountIgnoreFilter,
        'question_count_ignore_visibility': value.questionCountIgnoreVisibility,
        'questions_completely_filled': value.questionsCompletelyFilled,
        'questions_filled': value.questionsFilled,
        'questions_partially_filled': value.questionsPartiallyFilled,
        'questions_total_visible': value.questionsTotalVisible,
        'vrm_review_status': VRMReviewStatsToJSON(value.vrmReviewStatus),
    };
}


