/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormHistoryData,
    FormHistoryDataFromJSON,
    FormHistoryDataFromJSONTyped,
    FormHistoryDataToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormHistory
 */
export interface FormHistory {
    /**
     * 
     * @type {string}
     * @memberof FormHistory
     */
    action: FormHistoryActionEnum;
    /**
     * Datetime of history creation
     * @type {Date}
     * @memberof FormHistory
     */
    createdAt: Date;
    /**
     * 
     * @type {FormHistoryData}
     * @memberof FormHistory
     */
    data?: FormHistoryData;
    /**
     * 
     * @type {User}
     * @memberof FormHistory
     */
    user: User;
}

export function FormHistoryFromJSON(json: any): FormHistory {
    return FormHistoryFromJSONTyped(json, false);
}

export function FormHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': json['action'],
        'createdAt': (new Date(json['created_at'])),
        'data': !exists(json, 'data') ? undefined : FormHistoryDataFromJSON(json['data']),
        'user': UserFromJSON(json['user']),
    };
}

export function FormHistoryToJSON(value?: FormHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'created_at': (value.createdAt.toISOString()),
        'data': FormHistoryDataToJSON(value.data),
        'user': UserToJSON(value.user),
    };
}

/**
* @export
* @enum {string}
*/
export enum FormHistoryActionEnum {
    CREATED = 'CREATED',
    FIRSTOPENEDVENDOR = 'FIRST_OPENED_VENDOR',
    CHANGEDSTATE = 'CHANGED_STATE'
}


