/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormMetadata,
    FormMetadataFromJSON,
    FormMetadataFromJSONTyped,
    FormMetadataToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormMetadataResponse
 */
export interface FormMetadataResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof FormMetadataResponse
     */
    msg: string;
    /**
     * 
     * @type {FormMetadata}
     * @memberof FormMetadataResponse
     */
    entry: FormMetadata;
}

export function FormMetadataResponseFromJSON(json: any): FormMetadataResponse {
    return FormMetadataResponseFromJSONTyped(json, false);
}

export function FormMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': FormMetadataFromJSON(json['entry']),
    };
}

export function FormMetadataResponseToJSON(value?: FormMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': FormMetadataToJSON(value.entry),
    };
}


