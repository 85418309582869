/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvidenceDeleted,
    EvidenceDeletedFromJSON,
    EvidenceDeletedFromJSONTyped,
    EvidenceDeletedToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface EvidenceDeletedResponse
 */
export interface EvidenceDeletedResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof EvidenceDeletedResponse
     */
    msg: string;
    /**
     * 
     * @type {EvidenceDeleted}
     * @memberof EvidenceDeletedResponse
     */
    entry: EvidenceDeleted;
}

export function EvidenceDeletedResponseFromJSON(json: any): EvidenceDeletedResponse {
    return EvidenceDeletedResponseFromJSONTyped(json, false);
}

export function EvidenceDeletedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvidenceDeletedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': EvidenceDeletedFromJSON(json['entry']),
    };
}

export function EvidenceDeletedResponseToJSON(value?: EvidenceDeletedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': EvidenceDeletedToJSON(value.entry),
    };
}


