// tslint:disable:jsdoc-format

/**
 * Goes through the direct ancestors of the specified child element and returns
 * true if there is at least one element that meets the specified predicate.
 *
 * __Example 1__
 * ```html

<div data-some-var>
  <div>
    <div id="asdf"></div>
  </div>
</div>
```
```ts

assert(
  someAncestor(
    document.querySelector('#asdf'),
    element => Boolean(element.dataset.someVar),
  ) === true
)
```

__Example 2__
```html
<div data-some-var>
  <div id="stopElement">
    <div>
      <div id="asdf"></div>
    </div>
  </div>
</div>
```
```ts

assert(
  someAncestor(
    document.querySelector('#asdf'),
    element => Boolean(element.dataset.someVar),
    document.querySelector('#stopElement'),
  ) === false
)
```
 */
const someAncestor = (
  child: HTMLElement | null,
  predicate: (el: HTMLElement) => boolean,
  stopElement?: HTMLElement,
): boolean => {
  let target = child;
  while (target && target !== stopElement && target !== window.document.body) {
    if (predicate(target)) {
      return true;
    }
    target = target.parentElement;
  }
  return false;
};

export default someAncestor;
