import { ApiCallActionPayloadType } from '../../store.types';
import { reject, propEq } from 'ramda';
import { getStandardsForFormDefinitions } from './getStandardsForFormDefinitions';
import { clearFormDefinitionSelection } from '../actions/selectStandardForFormDefinition';

import { put } from 'redux-saga/effects';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';

import { DEFAULT_STANDARDS_STATUS } from '../defaultValues';
import { Uuid } from '../../../common/common.types';
import { flash } from '../../flashes/actions';
import i18n from '../../../i18n';
import { startLoading } from '../../app/actions';

export type DeleteStandardFromFormDefinitionsFunctionType = (
  params: DeleteStandardFromFormDefinitionsParamsType,
) => void;

export interface DeleteStandardFromFormDefinitionsParamsType {
  id: Uuid[];
}

export type DeleteStandardFromFormDefinitionsActionType = OptimisticActionType<
  '@formDefinitions/API/DELETE_STANDARD',
  ApiCallActionPayloadType<DeleteStandardFromFormDefinitionsParamsType>
>;

export const deleteStandardFromFormDefinitions = (
  params: DeleteStandardFromFormDefinitionsParamsType,
): DeleteStandardFromFormDefinitionsActionType => {
  return {
    type: '@formDefinitions/API/DELETE_STANDARD',
    payload: {
      endpointPath: ['standards', 'delete'],
      callParams: params,
      expectedStateChange: {
        'formDefinitions.standards.data': reject(propEq('id', params)),
      },
      selector: null,
      postActions: [
        () =>
          put(
            getStandardsForFormDefinitions({
              status: DEFAULT_STANDARDS_STATUS,
            }),
          ),
        () => put(clearFormDefinitionSelection()),
        () => put(startLoading(1)),
        () => put(flash(i18n.t('common:responses.requestDeleted'))),
      ],
    },
  };
};
