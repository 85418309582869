import React from 'react';
import { FormsUpdateVrmViewStatusFunctionType } from 'store/forms/actions';
import { RequestDetailVrmViewStatusEnum as ViewStatusEnum } from 'generated/models/RequestDetail';
import { RequestedAccess } from './ViewStatus.styles';
import { TextButton } from 'common/common.styles';
import {
  RequestVrmViewStatusEnum,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import { withTranslation } from 'react-i18next';

const { VENDORINITIAL, VRMREQUESTED } = ViewStatusEnum;

interface VrmViewStatusProps extends TranslatePropsType {
  requestId: Uuid;
  updateStatus: FormsUpdateVrmViewStatusFunctionType;
  viewStatus: RequestVrmViewStatusEnum;
}

const VrmViewStatus = ({
  requestId,
  updateStatus,
  viewStatus,
  t,
}: VrmViewStatusProps) => {
  if (viewStatus === VENDORINITIAL) {
    return (
      <TextButton
        data-cy="requestAccessToAnswers"
        size="mini"
        color="teal"
        onClick={() => updateStatus(requestId, VRMREQUESTED)}
      >
        {t('requestAccess') as string}
      </TextButton>
    );
  } else if (viewStatus === VRMREQUESTED) {
    return (
      <RequestedAccess data-cy="requestAccessToAnswers">
        {t('requestedAccess') as string}
      </RequestedAccess>
    );
  }
  return null;
};

export default withTranslation('ViewStatus')(VrmViewStatus);
