import React from 'react';

import Modal from 'utils/Modal';
import {
  CancelButton,
  DescriptionHeader,
  DescriptionWrapper,
  LogoWrapper,
  ModalWrapper,
  Paragraph,
  PreviewBackground,
  PreviewContainer,
  PreviewLogo,
  Scroller,
} from './PreviewCobrandingModal.styles';
import {
  Footer,
  Header,
  Title,
  LinkModalWrapper,
} from 'components/SendRequest/Modals/LinkModal.styles';
import { PREVIEW_COBRANDING_MODAL_ID } from 'common/common.constants';
import { useTranslation } from 'react-i18next';

interface PreviewCobrandingModal {
  onCancel: () => void;
  logo: string;
}

const PreviewCobrandingModal: React.FC<PreviewCobrandingModal> = ({
  onCancel,
  logo,
}) => {
  const { t } = useTranslation('Settings');

  const modalProps = {
    modalId: PREVIEW_COBRANDING_MODAL_ID,
    onClose: onCancel,
    custom: true,
  };

  return (
    <Modal {...modalProps}>
      <LinkModalWrapper>
        <Header>
          <Title>{t('cobrandig.preview.title') as string}</Title>
        </Header>
        <ModalWrapper>
          <DescriptionWrapper>
            <DescriptionHeader>
              {t('cobrandig.preview.header') as string}
            </DescriptionHeader>
            <Paragraph>
              {t('cobrandig.preview.description') as string}
            </Paragraph>
          </DescriptionWrapper>
          <PreviewContainer>
            <Scroller>
              <PreviewBackground src="/img/email-preview-top.png" />
              <LogoWrapper>
                <PreviewLogo src={logo} />
              </LogoWrapper>
              <PreviewBackground src="/img/email-preview-bottom.png" />
            </Scroller>
          </PreviewContainer>
        </ModalWrapper>
        <Footer>
          <CancelButton onClick={onCancel}>
            {t('cobrandig.preview.dismiss') as string}
          </CancelButton>
        </Footer>
      </LinkModalWrapper>
    </Modal>
  );
};
export default PreviewCobrandingModal;
