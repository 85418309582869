import React from 'react';
import theme from '../../../common/themes/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon, Message, Wrapper } from './ParsingMessage.styles';
import { ParsingMessageSeverityEnum } from 'generated/models/ParsingMessage';
import { ParsingMessageSeverityType } from 'common/common.types';
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfo,
  faTimesCircle,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';

interface ParsingMessagePropsType {
  children?: React.ReactNode;
  severity: ParsingMessageSeverityType;
  message: string;
}

const { INFO, WARNING, ERROR } = ParsingMessageSeverityEnum;

const {
  atlas: { coral, grey, skyblue, teal, yellow },
} = theme;

type SeverityStylesDict = {
  [key in ParsingMessageSeverityType]: {
    icon: any;
    color: string;
    spin?: boolean;
  };
};

export const severityStyles: SeverityStylesDict = {
  [ERROR]: { icon: faTimesCircle, color: coral },
  [INFO]: { icon: faInfo, color: skyblue },
  SUCCESS: { icon: faCheckCircle, color: teal.primary },
  PROCESSING: { icon: faCircleNotch, color: grey['500'], spin: true },
  [WARNING]: { icon: faExclamationTriangle, color: yellow.primary },
};

const ParsingMessage = ({
  children,
  severity,
  message,
}: ParsingMessagePropsType) => (
  <Wrapper severity={severity}>
    <Icon>
      <FontAwesomeIcon
        icon={severityStyles[severity].icon}
        spin={severityStyles[severity].spin}
        size={'2x'}
      />
    </Icon>

    <Message>
      {message}
      {children}
    </Message>
  </Wrapper>
);

export default ParsingMessage;
