import * as R from 'ramda';
import i18n from '../../../i18n';
import { RequestSummaryStatusEnum } from '../../../generated/models/RequestSummary';
import { call, put } from 'redux-saga/effects';
import { getInfo } from '../../user/actions';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { RequestsStateType } from '../requests.types';
import { RequestSimpleType, Uuid } from '../../../common/common.types';

const { ARCHIVED } = RequestSummaryStatusEnum;

export interface RequestModifyListCallParamsType {
  clear_notifications?: boolean;
  read?: boolean;
  request_ids: Uuid[];
  status?: RequestSummaryStatusEnum;
}

export type RequestArchiveFunctionType = (ids: Uuid[]) => void;

export type RequestArchiveActionType = ApiCallActionType<
  '@requests/API/ARCHIVE_REQUEST',
  RequestModifyListCallParamsType,
  never,
  never,
  RequestsStateType
>;

export const archiveRequest = (ids: Uuid[]): RequestArchiveActionType => ({
  type: '@requests/API/ARCHIVE_REQUEST',
  payload: {
    callParams: {
      request_ids: ids,
      status: ARCHIVED,
    },
    endpointPath: ['requests', 'modifyList'],
    selector: (data, state) =>
      R.pipe(
        R.assocPath<RequestSimpleType[], RequestsStateType>(
          ['sent', 'unsorted'],
          state.requests.sent.unsorted.filter(r => !R.contains(r.id, ids)),
        ),
        R.assocPath(
          ['sent', 'categories'],
          R.mapObjIndexed(c => {
            const requests = c.requests.filter(r => !R.contains(r.id, ids));
            return {
              ...c,
              selected: [],
              count: requests.length,
              requests,
            };
          }, state.requests.sent.categories),
        ),
      )(state.requests),
    postActions: [
      () => put(getInfo()),
      () => call(() => trackEvent('Request', `Change status -> ${ARCHIVED}`)),
      () =>
        put(
          flash(
            i18n.t('common:responses.requestArchived', { count: ids.length }),
          ),
        ),
    ],
  },
});
