import { OptimisticActionPayloadType } from 'utils/optimistic/optimistic.types';

export interface CompaniesClearCreditsActionType {
  type: '@companies/CLEAR_CREDITS';
  payload: OptimisticActionPayloadType;
}

export const clearCredits = () => ({
  type: '@companies/CLEAR_CREDITS',
  payload: {
    expectedStateChange: {
      'companies.credits': {
        list: [],
        history: [],
        summary: null,
      },
    },
  },
});
