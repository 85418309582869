import delay from 'utils/delayPromise';
import {
  FormDetailFillingTypeEnum,
  FormDetailInitializationStatusEnum,
  FormDetailPermissionsEnum,
} from '../../../generated/models/FormDetail';
import { FormMetadata } from '../../../generated/models/FormMetadata';
import { NavigationContextEnum, Uuid } from '../../../common/common.types';
import {
  FormsEnableFiltersItemType,
  SupportedFilterOptionType,
} from 'utils/filters/forms.filters';
import {
  enableFilters,
  getAssignees,
  getFindings,
  getHistory,
  getUploadAndMapStatus,
  setSharedData,
} from '.';
import { markRead } from '../../requests/actions';
import { parseFilterStats, parseSharedForm } from 'utils/parsers/forms.parsers';
import { parseForm } from 'utils/parsers';
import { pathOr } from 'ramda';
import { getPreferredTab, pushMappingFile } from '../utils';
import { all, call, put } from 'redux-saga/effects';
import { resetForm } from './resetForm';
import { setResourceGone } from './setResourceGone';
import { ApiCallActionType } from '../../store.types';
import { UnreachableCaseError } from 'utils/errors';
import { FormsStateType } from '../forms.reducer';
import { switchContext } from '../../user/actions';
import hasPermission from 'utils/arrayHasSome';
import { getSettings } from '../../companies/actions';
import { getFiltersCallParam } from '../../../components/Form/utils';
import { clearFilters } from '../actions/clearFilters';

const {
  EMPTY,
  INPROGRESS,
  INQUEUE,
  FINISHEDFAIL,
  FINISHEDSUCCESS,
} = FormDetailInitializationStatusEnum;

export interface FormsGetFormCallParams {
  id?: Uuid;
  assigneeIds?: Uuid[];
  filters?: string[];
  questionIds?: Uuid[];
}

export interface FormsGetFormParamsType {
  id: Uuid;
  queryFilters?: SupportedFilterOptionType[];
  queryAssignee?: string;
  isRetry?: boolean;
  canSwitchNavContext?: boolean;
}

export type FormsGetFormActionType = ApiCallActionType<
  '@forms/API/FETCH',
  FormsGetFormCallParams,
  FormMetadata,
  never,
  FormsStateType
>;

const getFilters = (
  queryAssignee?: string,
  queryFilters?: SupportedFilterOptionType[],
) => {
  const result: FormsEnableFiltersItemType[] = [];

  if (queryAssignee) {
    result.push({ branch: 'assignees', filterName: queryAssignee });
  }

  if (queryFilters) {
    result.push(
      ...queryFilters.map(
        (filterName): FormsEnableFiltersItemType => ({
          branch: 'simple',
          filterName,
        }),
      ),
    );
  }

  return result;
};

export interface ResourceGoneException {
  error: {
    sender: {
      name: string;
      domain: string;
    };
    statusOfResource: string;
  };
}

export const getForm = ({
  id,
  queryAssignee,
  queryFilters,
  isRetry = false,
  canSwitchNavContext = false,
}: FormsGetFormParamsType): FormsGetFormActionType => ({
  type: '@forms/API/FETCH',
  payload: {
    // Retry attempts should not reset form and clear fetchedFormId.
    preActions: !isRetry
      ? [
          put(resetForm(id)),
          put(enableFilters(getFilters(queryAssignee, queryFilters))),
        ]
      : [],
    callParams: state => ({ id, ...getFiltersCallParam(state.forms.filters) }),
    endpointPath: ['forms', 'get'],
    resourceGoneHandlers: [
      ({ error }: ResourceGoneException) =>
        put(
          setResourceGone(
            error.sender.name ? error.sender.name : error.sender.domain,
            error.statusOfResource,
          ),
        ),
    ],
    selector: (data, state): Partial<FormsStateType> => {
      if (
        typeof state.forms.fetchedFormId === 'undefined' ||
        id !== state.forms.fetchedFormId
      ) {
        // Not interested in the results anymore
        return state.forms;
      }

      const currentForm = parseForm(data, state);

      const { assignee_stats, filter_stats, mapping_requests: maps } = data;
      const { forms } = state;

      const filters = parseFilterStats(filter_stats, assignee_stats, forms);

      const preferredTab = getPreferredTab(
        filters.simple,
        currentForm.permissions.isVRM,
      );

      const uploads =
        maps && maps.length > 0
          ? [...pathOr([], ['uploads'], forms), ...maps.map(pushMappingFile)]
          : forms.uploads;

      return {
        canUpdateView: false,
        currentForm,
        filters,
        preferredTab,
        resourceGoneState: undefined,
        uploads,
      };
    },
    postActions: (
      {
        form,
        form: { initialization_status },
        questions_filtered_count,
      }: FormMetadata,
      { forms: { fetchedFormId } },
    ) => {
      // NOTE:
      // This is a stop gap solution for ATL 955 and this will be removed
      // when scroll to question is implemented.
      //
      // When filters with zero question count is enabled using external link,
      // the questionnaire shows nothing and that is not a desired behavior. So this hack
      // will just clear out the filters manually, thus showing all questions.
      //
      // The below IF statement cannot be true if filters are set manually by the user (because
      // filter options are not checkable if the count is zero) but it could be true if the filters
      // are set via external link.
      if (
        queryFilters &&
        queryFilters.length > 0 &&
        questions_filtered_count === 0
      ) {
        return [() => put(clearFilters())];
      }
      switch (initialization_status) {
        case FINISHEDFAIL:
          return [];
        case FINISHEDSUCCESS:
          return [
            (payload: FormMetadata) => {
              if (!canSwitchNavContext) {
                return null;
              }
              const isAnswerContext =
                hasPermission(
                  payload.form.permissions,
                  FormDetailPermissionsEnum.VENDOR,
                  FormDetailPermissionsEnum.VENDORPROXY,
                ) ||
                payload.form.filling_type ===
                  FormDetailFillingTypeEnum.MASTER ||
                payload.form.filling_type === FormDetailFillingTypeEnum.UPLOAD;

              const isSendContext = hasPermission(
                payload.form.permissions,
                FormDetailPermissionsEnum.VRM,
                FormDetailPermissionsEnum.VRMPROXY,
              );

              if (isSendContext && !isAnswerContext) {
                return put(switchContext(NavigationContextEnum.SEND));
              } else if (!isSendContext && isAnswerContext) {
                return put(switchContext(NavigationContextEnum.ANSWER));
              }
              // otherwise keep navigation context as is
              return null;
            },
            (payload: FormMetadata) =>
              payload.form.request ? put(markRead()) : null,
            ({ mapping_requests: maps, form: { id: formId } }: FormMetadata) =>
              maps
                ? all([
                    ...maps.map(m => put(getUploadAndMapStatus(m.id, formId))),
                  ])
                : null,
            (payload: FormMetadata) => {
              switch (payload.form.filling_type) {
                case FormDetailFillingTypeEnum.REQUEST:
                  return put(getHistory(payload.form.id));
                case FormDetailFillingTypeEnum.SNAPSHOT:
                  return put(setSharedData(parseSharedForm(payload)));
                default:
                  return null;
              }
            },
            (payload: FormMetadata) =>
              hasPermission(
                payload.form.permissions,
                FormDetailPermissionsEnum.VRMRISKREPORTREAD,
              ) && payload.form.request_id
                ? put(getFindings(payload.form.request_id))
                : null,
            (payload: FormMetadata) =>
              hasPermission(
                payload.form.permissions,
                FormDetailPermissionsEnum.VENDORDELEGATIONASSIGN,
              )
                ? put(getAssignees(payload.form.id))
                : null,
            (payload: FormMetadata) =>
              hasPermission(
                payload.form.permissions,
                FormDetailPermissionsEnum.ANSWERLOOKUPLENSESREAD,
              )
                ? put(getSettings())
                : null,
          ];
        case EMPTY:
        case INPROGRESS:
        case INQUEUE:
          if (typeof fetchedFormId !== 'undefined' && id === fetchedFormId) {
            return [
              () => call(delay, 10000),
              () =>
                put(
                  getForm({
                    id,
                    isRetry: true,
                    queryFilters,
                    queryAssignee,
                    canSwitchNavContext,
                  }),
                ),
            ];
          } else {
            return [];
          }

        default:
          throw new UnreachableCaseError(initialization_status);
      }
    },
  },
});
