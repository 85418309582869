import { Uuid } from '../../../common/common.types';
import { call } from 'redux-saga/effects';
import { trackEvent } from 'utils/analytics';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { RequestDetail, RequestUpdate } from '../../../generated/models';
import update from 'immutability-helper';

export type FormsUpdateDueDateFunctionType = (
  requestId: Uuid,
  dueDate?: Date,
) => void;

export type FormsUpdateDueDateActionType = OptimisticActionType<
  '@forms/API/UPDATE_DUE_DATE',
  ApiCallActionPayloadType<
    {
      id: Uuid;
      data: RequestUpdate;
    },
    RequestDetail,
    never,
    FormsStateType
  >
>;

/* tslint:disable */
export const updateDueDate = (
  id: Uuid,
  dueDate: Date,
): FormsUpdateDueDateActionType => {
  return {
    type: '@forms/API/UPDATE_DUE_DATE',
    payload: {
      callParams: { id, data: { due_date: dueDate } },
      endpointPath: ['requests', 'modify'],
      expectedStateChange: { 'forms.currentForm.request.dueDate': dueDate },
      selector: ({ due_date: newDueDate }, { forms }) => {
        newDueDate = dueDate ? new Date(dueDate) : undefined;
        return update(forms, {
          currentForm: {
            request: {
              dueDate: { $set: newDueDate },
            },
          },
        });
      },
      postActions: [() => call(() => trackEvent('Form', 'DueDate'))],
    },
  };
};
