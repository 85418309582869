import CompanyStandards from './CompanyStandards';
import CreditsManagement from '../Upsell/CreditsManagement/CreditsManagement';
import PageHeader from '../common/PageHeader';
import React, { useState } from 'react';
import Select from '../common/Select';
import TransferStandards from './TransferStandards';
import YourStandards from './YourStandards';
import { Content, Flex, Marginator, Wrapper } from 'common/common.styles';
import { CreditsOverview } from '../Upsell';
import { FORM_PREVIEW_MODAL_ID } from 'common/common.constants';
import { PageContextConsumer } from 'pages/Page';
import { PreviewModal } from '../SendRequest/Modals';
import { SalesPageContextType } from 'pages/SalesPage';
import { TopHeading } from './Sales.styles';
import { withTranslation } from 'react-i18next';
import useFlag from 'utils/hooks/useFlag';
import {
  AutocompleteCompanyType,
  TranslatePropsType,
  Uuid,
  ToggleEnum,
} from 'common/common.types';
import UploadSystemTemplate from './UploadSystemFile/UploadSystemTemplate';

const getUserFlag = useFlag;

const Sales = ({
  initialState,
  t,
}: TranslatePropsType & {
  initialState?: AutocompleteCompanyType;
}) => {
  const [company, setCompany] = useState(initialState);

  return (
    <PageContextConsumer>
      {({
        data: {
          autocomplete,
          companyStandards,
          credits,
          customStandards,
          parseErrorsCount,
          parsedFiles,
          previewForm,
          transferingCount,
          uploadingCount,
          user,
          currentUser: { isSystemAdmin },
          language,
        },
        actions: {
          addCredits,
          clearAutocomplete,
          deleteStandard,
          findCompany,
          getCredits,
          getPreview,
          getStandards,
          hideModal,
          loadQuestions,
          putQuestion,
          renameStandard,
          showModal,
          toggleStandardSelection,
          transferStandards,
          uploadStandard,
          uploadSystemTemplate,
        },
      }: SalesPageContextType) => {
        const hasSystemTemplateUploadEnabled = getUserFlag(user, [
          ToggleEnum.SYSTEM_TEMPLATE_UPLOAD,
        ]);

        const handleChange = (selectedCompany?: AutocompleteCompanyType) => {
          setCompany(selectedCompany);
          if (selectedCompany) {
            getStandards({
              status: 'custom',
              ownerId: selectedCompany.value,
            });
            getCredits(selectedCompany.value);
          }
        };

        const handleInputChange = (value: string) => {
          if (value) {
            findCompany(value);
          } else {
            clearAutocomplete();
          }
        };

        const handlePreviewClick = (formId: Uuid) => {
          getPreview(formId);
          showModal(FORM_PREVIEW_MODAL_ID);
        };

        return (
          <>
            <PageHeader title={t('name') as string} />

            <Wrapper grey>
              <Content>
                <Select
                  isClearable={true}
                  onInputChange={handleInputChange}
                  onChange={handleChange}
                  options={autocomplete}
                  placeholder={t('selectCompany') as string}
                />

                {isSystemAdmin && hasSystemTemplateUploadEnabled && (
                  <UploadSystemTemplate
                    uploadSystemTemplate={uploadSystemTemplate}
                  />
                )}

                <Marginator margin={{ top: 50 }}>
                  <TopHeading>{t('addCredits') as string}</TopHeading>

                  {company && credits.summary && (
                    <Flex>
                      <CreditsOverview
                        big
                        companyName={company.name}
                        credits={credits}
                        language={language}
                      />

                      <CreditsManagement
                        addCredits={addCredits}
                        companyId={company.value}
                        history={credits.history}
                        language={language}
                      />
                    </Flex>
                  )}
                </Marginator>

                <Marginator margin={{ top: 50 }}>
                  <TopHeading>{t('heading') as string}</TopHeading>

                  <Flex>
                    <PreviewModal
                      loadQuestions={loadQuestions}
                      modalId={FORM_PREVIEW_MODAL_ID}
                      previewForm={previewForm}
                      putQuestion={putQuestion}
                      user={user}
                      language={language}
                    />

                    <YourStandards
                      deleteStandard={deleteStandard}
                      handlePreview={handlePreviewClick}
                      hasParsingErrors={parseErrorsCount > 0}
                      parsedFiles={parsedFiles}
                      renameStandard={renameStandard}
                      standards={customStandards}
                      toggleStandard={toggleStandardSelection}
                      uploadStandard={uploadStandard}
                      uploadingCount={uploadingCount}
                    />

                    <TransferStandards
                      companyId={company ? company.value : undefined}
                      selectedIds={customStandards.selected}
                      transferingCount={transferingCount}
                      transferStandards={transferStandards}
                    />

                    {companyStandards && company && (
                      <CompanyStandards
                        name={company.name}
                        standards={companyStandards}
                      />
                    )}
                  </Flex>
                </Marginator>
              </Content>
            </Wrapper>
          </>
        );
      }}
    </PageContextConsumer>
  );
};

export default withTranslation('Sales')(Sales);
