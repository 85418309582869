import { FormsActionType } from './actions';
import { FormsStateType, DropdownsEnum } from './forms.reducer';
import {
  AutocompleteDialogStateType,
  AutocompleteProgressType,
} from '../../common/common.types';
import { AutocompleteExecutionStatusEnum } from '../../generated/new/models';
import update from 'immutability-helper';

export const initialState: AutocompleteDialogStateType = {
  type: 'CLOSED_IDLE',
};

/**
 * How to read the file?
 *
 * case ... describes transition between states A --transition--> B
 * actualDialog.type ... describes current state A
 * return ... describes next state B
 *
 * Example:
 * case OPEN_AUTOCOMPLETE_DIALOG:
 *   if (actualDialog.type === 'CLOSED_IDLE')
 *     return { type: 'OPENED_MATCH_SEARCH_IN_PROGRESS' };
 *
 * It says, if we are currently in the state CLOSED_IDLE, transition called OPEN_AUTOCOMPLETE_DIALOG
 * occurred, we move to next state called OPENED_MATCH_SEARCH_IN_PROGRESS.
 *
 * State diagram is defined here:
 * https://docs.google.com/drawings/d/1uj7lPiH-etV3Jl4kUc7prTLYW2AUfrDLmyQwd9qHAn8/edit
 */
export const autocompleteDialogReducer = (
  { currentForm, autocomplete: { dialog: actualDialog } }: FormsStateType,
  action: FormsActionType,
): AutocompleteDialogStateType => {
  if (!currentForm) {
    return actualDialog;
  }

  switch (action.type) {
    case '@forms/RESET':
      return initialState;

    case '@forms/SET_CURRENT_DROPDOWN': // transition
      if (action.payload.dropdown === DropdownsEnum.AUTOCOMPLETE) {
        // Opening
        // current state
        switch (actualDialog.type) {
          case 'CLOSED_IDLE': {
            const { autocompleteExecutions, meta } = currentForm;

            if (meta.questionsLeftCount === 0) {
              return { type: 'OPENED_ALL_QUESTIONS_FILLED' }; // next state
            } else if (autocompleteExecutions.length === 0) {
              return { type: 'OPENED_MATCH_SEARCH_IN_PROGRESS' };
            }

            // TODO: what if there are multiple running executions?
            return {
              type: 'OPENED_AUTOCOMPLETE_IN_PROGRESS',
              progress: {
                autocompleteId: autocompleteExecutions[0].id,
                matchingQuestionsCount: undefined,
                questionsProcessed:
                  autocompleteExecutions[0].questionsProcessed,
                questionsTotal: autocompleteExecutions[0].questionsTotal,
              },
            };
          }

          case 'CLOSED_AUTOCOMPLETE_IN_PROGRESS':
            return {
              type: 'OPENED_AUTOCOMPLETE_IN_PROGRESS',
              progress: actualDialog.progress,
            };

          default:
            return actualDialog;
        }
      } else {
        // Closing
        if (actualDialog.type === 'OPENED_AUTOCOMPLETE_IN_PROGRESS') {
          return {
            type: 'CLOSED_AUTOCOMPLETE_IN_PROGRESS',
            progress: actualDialog.progress,
          };
        }
        return initialState;
      }

    case '@forms/AUTOCOMPLETE_MATCH_SEARCH_FINISHED':
      switch (actualDialog.type) {
        case 'OPENED_MATCH_SEARCH_IN_PROGRESS': {
          const { matchingQuestionsCount } = action.payload;

          if (matchingQuestionsCount === 0) {
            return { type: 'OPENED_MATCHES_NOT_FOUND' };
          }

          return {
            type: 'OPENED_MATCHES_FOUND',
            matchingQuestionsCount,
          };
        }

        case 'OPENED_AUTOCOMPLETE_IN_PROGRESS': {
          const { matchingQuestionsCount } = action.payload;
          // We've just fetched the missing matchingQuestionsCount
          return update(actualDialog, {
            progress: {
              matchingQuestionsCount: { $set: matchingQuestionsCount },
            },
          });
        }

        default:
          return actualDialog;
      }

    case '@forms/AUTOCOMPLETE_GLOBAL_MAPPING_STARTED':
      switch (actualDialog.type) {
        case 'OPENED_GLOBAL_MAPPING_IN_PROGRESS':
        case 'OPENED_MATCH_SEARCH_IN_PROGRESS':
        case 'OPENED_MATCHES_FOUND':
        case 'OPENED_MATCHES_NOT_FOUND':
        case 'OPENED_STARTING_AUTOCOMPLETE':
        case 'OPENED_AUTOCOMPLETE_IN_PROGRESS':
        case 'OPENED_AUTOCOMPLETE_FINISHED':
          return { type: 'OPENED_GLOBAL_MAPPING_IN_PROGRESS' };
        default:
          return actualDialog;
      }

    case '@forms/AUTOCOMPLETE_GLOBAL_MAPPING_FINISHED':
      if (actualDialog.type === 'OPENED_GLOBAL_MAPPING_IN_PROGRESS') {
        return { type: 'OPENED_MATCH_SEARCH_IN_PROGRESS' };
      }
      return actualDialog;

    case '@forms/START_AUTOCOMPLETE':
      if (actualDialog.type === 'OPENED_MATCHES_FOUND') {
        return {
          type: 'OPENED_STARTING_AUTOCOMPLETE',
          matchingQuestionsCount: actualDialog.matchingQuestionsCount,
        };
      }
      return actualDialog;

    case '@forms/AUTOCOMPLETE_PROGRESS_UPDATED': {
      const execution = action.payload;
      const matchingQuestionsCount =
        actualDialog.type === 'OPENED_STARTING_AUTOCOMPLETE'
          ? actualDialog.matchingQuestionsCount
          : actualDialog.type === 'OPENED_AUTOCOMPLETE_IN_PROGRESS' ||
            actualDialog.type === 'CLOSED_AUTOCOMPLETE_IN_PROGRESS'
          ? actualDialog.progress.matchingQuestionsCount
          : undefined;

      if (
        actualDialog.type === 'OPENED_STARTING_AUTOCOMPLETE' ||
        actualDialog.type === 'OPENED_AUTOCOMPLETE_IN_PROGRESS' ||
        actualDialog.type === 'CLOSED_AUTOCOMPLETE_IN_PROGRESS'
      ) {
        if (
          execution.status === AutocompleteExecutionStatusEnum.FINISHEDSUCCESS
        ) {
          return {
            type: 'OPENED_AUTOCOMPLETE_FINISHED',
            questionsAutofilledCount: execution.questionsAutofilled.length,
            questionsSuggestedCount: execution.questionsSuggested.length,
          };
        }

        const progress: AutocompleteProgressType = {
          autocompleteId: execution.id,
          matchingQuestionsCount,
          questionsProcessed: execution.questionsProcessed,
          questionsTotal: execution.questionsTotal,
        };

        return actualDialog.type === 'CLOSED_AUTOCOMPLETE_IN_PROGRESS'
          ? {
              type: 'CLOSED_AUTOCOMPLETE_IN_PROGRESS',
              progress,
            }
          : {
              type: 'OPENED_AUTOCOMPLETE_IN_PROGRESS',
              progress,
            };
      }
      return actualDialog;
    }

    default:
      return actualDialog;
  }
};
