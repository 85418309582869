import config from '../../config';
import { withHashUrl } from './withHashUrl';
import route, * as routes from '../../routes';

export const generateLinkUrl = (
  email: string,
  isInvited: boolean,
  formId: string,
) =>
  email && isInvited
    ? `${
        config.platformUrl
      }/?new-guest-user#/external/atlas?path=${encodeURIComponent(
        `/#/form/${formId}`,
      )}`
    : withHashUrl(route(routes.FORM, { id: formId }));
