import styled from 'styled-components';
import Input from '../../../common/Input';

export const TextInput = styled(Input)`
  flex: 1;
  ${({ theme }) => theme.fonts.regular.small}
`;

export const Symbol = styled.div`
  padding-left: 10px;
  text-align: left;
  width: 85px;
  line-height: 38px;
  color: ${({ theme }) => theme.atlas.text.secondary};
  ${({ theme }) => theme.fonts.regular.small}
`;
