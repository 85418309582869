import AddAttachments from './AddAttachments';
import AddComment from './AddComment';
import AttachmentsList from './AttachmentsList';
import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HorizontallyJustified } from 'common/common.styles';
import { InnerFlex } from './FormAttachments.styles';
import { VrmViewStatus } from '../ViewStatus';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  FormsPutMessageFunctionType,
  FormsUpdateVrmViewStatusFunctionType,
} from 'store/forms/actions';
import {
  Open,
  Toggler,
  LeftSide,
  RightSide,
  AttachmentsWrapper,
} from './EditableView.styles';
import {
  AttachmentsDict,
  RequestVrmViewStatusEnum,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface EditableViewPropsType extends TranslatePropsType {
  attachments: AttachmentsDict;
  companyName: string;
  deleteAttachment?: (id: Uuid) => void;
  formId: Uuid;
  formMessage: string;
  isViewStatusEditable: boolean;
  isVRM: boolean;
  postAttachment?: (formData: FormData) => void;
  putMessage?: FormsPutMessageFunctionType;
  requestId: Uuid;
  updateVrmViewStatus: FormsUpdateVrmViewStatusFunctionType;
  vrmViewStatus: RequestVrmViewStatusEnum;
}

interface EditableViewStateType {
  isOpen: boolean;
}

class EditableView extends PureComponent<
  EditableViewPropsType,
  EditableViewStateType
> {
  constructor(props: EditableViewPropsType) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggleOpen = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  };

  render() {
    const {
      attachments,
      companyName,
      deleteAttachment,
      formId,
      formMessage,
      isViewStatusEditable,
      isVRM,
      postAttachment,
      putMessage,
      requestId,
      updateVrmViewStatus,
      vrmViewStatus,
      t,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <HorizontallyJustified>
          <Toggler data-cy="questionnaireMessage" onClick={this.toggleOpen}>
            <Open isOpen={isOpen}>
              <FontAwesomeIcon icon={faChevronDown as IconProp} />
            </Open>

            {t('titleVRM', { companyName }) as string}
          </Toggler>

          {isViewStatusEditable && (
            <VrmViewStatus
              requestId={requestId}
              updateStatus={updateVrmViewStatus}
              viewStatus={vrmViewStatus}
            />
          )}
        </HorizontallyJustified>

        {isOpen && (
          <InnerFlex>
            <LeftSide data-test="questionnaire-message-textarea">
              <AddComment
                formMessage={formMessage}
                requestId={requestId}
                putMessage={putMessage}
              />
            </LeftSide>
            <RightSide data-test="questionnaire-message-attachment">
              {postAttachment && (
                <AddAttachments
                  formId={formId}
                  postAttachment={postAttachment}
                />
              )}

              <AttachmentsWrapper>
                <AttachmentsList
                  attachments={attachments}
                  deleteAttachment={deleteAttachment}
                  isVRM={isVRM}
                />
              </AttachmentsWrapper>
            </RightSide>
          </InnerFlex>
        )}
      </>
    );
  }
}

export default withTranslation('FormAttachments')(EditableView);
