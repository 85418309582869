import React from 'react';
import Page, { PageBaseType, PageActionsBaseType, PagePropsType } from './Page';
import { StateType, ActionType } from 'store/store.types';
import { Dispatch } from 'redux';
import FormDefinitionPreview from 'components/FormDefinitionPreview/FormDefinitionPreview';

export interface FormDefinitionPreviewPageContextType extends PageBaseType {
  data: any;
  actions: PageActionsBaseType & {
    whatever: any;
  };
}

const FormDefinitionPreviewPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({})}
    dispatches={(dispatch: Dispatch<ActionType>, ownProps: PagePropsType) => ({
      // getData: () => {
      //   dispatch(startLoading(2));
      //   dispatch(getCompanies({ shouldRefreshFilter: true }));
      //   dispatch(getTagsAction());
      // },
      actions: {
        navigate: ownProps.history.push,
      },
    })}
  >
    <FormDefinitionPreview />
  </Page>
);

export default FormDefinitionPreviewPage;
