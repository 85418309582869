import styled, { css } from 'styled-components';

const UploadButton = styled.button<{ processing?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.basic.colors.atlas};
  border: none;
  border-radius: 6px;
  color: ${({ theme }) => theme.basic.colors.light};
  cursor: pointer;
  ${({ theme }) => theme.fonts.bold.medium}
  letter-spacing: .25px;
  height: 50px;
  transition: ${({ theme }) => theme.basic.animations.normal};
  width: 200px;

  svg {
    margin-right: 25px;
  }

  ${({ processing }) =>
    processing
      ? css`
      background-color: transparent;
      color: transparent;
      width: 50px;
      border-radius: 50%;
      border: 2px solid ${({ theme }) => theme.basic.colors.atlas};
      position: relative;
      z-index: 1;
      transition: ${({ theme }) => theme.basic.animations.fast};

      svg {
        display: none;
      }

      &:after {
        content: '';
        border-top: 2px solid ${({ theme }) => theme.basic.colors.atlasHover};
        border-radius: 50%;
        position: absolute;
        top: -2px;
        left: -2px;
        z-index: 2;
        width: 50px;
        height: 48px;
        animation:spin 2s linear infinite;
      `
      : null}

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const ImportButton = styled(UploadButton)`
  height: 36px;
  width: 140px;
  background-color: transparent;
  color: ${({ theme }) => theme.basic.colors.dark};
  border: 1px solid ${({ theme }) => theme.basic.colors.dark};
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
`;

export default UploadButton;
