import Actions from './Actions';
import Checkbox from '../../common/Checkbox';
import Completion from './Cells/Completion';
import DueDate from './Cells/DueDate';
import GradeImg from '../../common/Grade';
import Notifications from './Notifications';
import React, { memo, MouseEvent, useCallback } from 'react';
import Title from './Title';
import ValidationScore from './Cells/ValidationScore';
import { format } from 'date-fns';
import route, { FORM } from '../../../routes';
import { Arity1Fn } from 'ramda';
import {
  LanguageType,
  RequestSimpleType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import { VerticallyCentered } from 'common/common.styles';
import { getNotificationTypes } from '../utils';
import { withTranslation } from 'react-i18next';
import {
  RequestArchiveFunctionType,
  RequestClearNotificationsFunctionType,
  RequestDeleteFunctionType,
  RequestMarkReadFunctionType,
  RequestUnarchiveFunctionType,
} from 'store/requests/actions';
import {
  Cell,
  CheckboxWrapper,
  Company,
  Grade,
  Main,
  Spacer,
  TitlePart,
  Transceiver,
  TransceiverPart,
  Unverified,
  UpdatedOn,
  WrapperLink,
  Year,
} from './Request.styles';
import { FormProxyShowProxyModalFunctionType } from 'store/formProxy/actions';
import { DATE_FORMAT_BASED_ON_LOCALE } from 'common/common.constants';
import { StateType } from '../../../store/store.types';
import { getLanguagePreference } from '../../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import getLocale from 'utils/getLocale';

export interface RequestPropsType extends TranslatePropsType {
  data: {
    isSelected?: boolean;
    isSelectable?: boolean;
    request: RequestSimpleType;
    reverseTransceiver?: boolean;
    showGrade: boolean;
    showScore: boolean;
    hideSscData?: boolean;
    language: LanguageType;
  };
  actions: {
    archiveRequest?: RequestArchiveFunctionType;
    clearNotifications?: RequestClearNotificationsFunctionType;
    copyToClipboard: (text: string) => void;
    deleteRequest?: RequestDeleteFunctionType;
    markRead?: RequestMarkReadFunctionType;
    showProxyModal?: FormProxyShowProxyModalFunctionType;
    toggleRequestSelection?: (id: Uuid) => void;
    unarchiveRequest?: RequestUnarchiveFunctionType;
  };
}

const Request = ({
  data: {
    isSelected = false,
    isSelectable = false,
    request,
    request: {
      id,
      isRead,
      dueDate,
      acceptedDate,
      hasNewMessages,
      hasNewResponses,
      hasNewSscData,
      form: {
        id: formId,
        isScoreVisible: showValidationScore,
        filled: questionsFilledCount,
        percentageFilled: completion,
        score,
        total: questionsCount,
      },
      meta: {
        canViewData,
        gradeUrl,
        isVisible,
        isVerified,
        receiver,
        receiverCompanyOrTeam,
        sender,
        senderCompanyOrTeam,
      },
      requestedAt,
      revisionCount,
      standard: { name, version },
      status,
      isInternal,
      updatedAt,
    },
    reverseTransceiver,
    showGrade,
    showScore,
    hideSscData,
    language,
  },
  actions: {
    archiveRequest,
    clearNotifications,
    copyToClipboard,
    deleteRequest,
    markRead,
    toggleRequestSelection,
    showProxyModal,
    unarchiveRequest,
  },
  t,
}: RequestPropsType) => {
  const onWrapperClick = useCallback(() => {
    if (markRead) {
      markRead({ requests: [request], read: true });
    }
  }, [markRead, request]);

  const formURL = route(FORM, { id: formId });

  const notificationTypes = getNotificationTypes(
    hasNewMessages,
    hasNewResponses,
    !hideSscData && hasNewSscData,
  );

  const hasNotifications = notificationTypes.length > 0;

  const handleClickInside = useCallback(
    (fn: Arity1Fn) => (fnArg: any) => (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      fn(fnArg);
    },
    [],
  );

  const handleCheckBoxClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (toggleRequestSelection) {
      toggleRequestSelection(id);
    }
  };
  return (
    <WrapperLink
      data-cy={`request-${formId}`}
      data-read={isRead}
      isRead={isRead}
      isVisible={isVisible}
      onClick={onWrapperClick}
      to={formURL}
    >
      {isSelectable && toggleRequestSelection && (
        <Cell width={50} addBorder onClick={handleCheckBoxClick}>
          <CheckboxWrapper>
            <Checkbox
              onChange={handleCheckBoxClick}
              secondaryColor="teal"
              isChecked={isSelected}
            />
          </CheckboxWrapper>
        </Cell>
      )}

      <Cell width={250} addBorder>
        <VerticallyCentered data-cy="request-company">
          <Company>
            {(showGrade || isInternal) && (
              <VerticallyCentered>
                <Grade>
                  {isInternal ? (
                    <GradeImg url={'/icons/internal.svg'} />
                  ) : (
                    <GradeImg url={gradeUrl} />
                  )}
                </Grade>
              </VerticallyCentered>
            )}

            {isVerified ? (
              <span data-test="company-name">
                {reverseTransceiver
                  ? senderCompanyOrTeam
                  : receiverCompanyOrTeam}
              </span>
            ) : (
              <Unverified receiver={receiver} />
            )}
          </Company>

          <TransceiverPart reverse={reverseTransceiver}>
            {isVerified ? (
              <>
                <Transceiver data-cy="request-receiver" title={receiver}>
                  {t('Requests.receiver', { receiver }) as string}
                </Transceiver>
                <Transceiver data-cy="request-sender" title={sender}>
                  {t('Requests.sender', { sender }) as string}
                </Transceiver>
              </>
            ) : (
              <Transceiver>{t('Requests.unverified') as string}</Transceiver>
            )}
          </TransceiverPart>
        </VerticallyCentered>
      </Cell>

      <Cell grow addBorder>
        <Main>
          <TitlePart>
            <Title revision={revisionCount} status={status} title={name} />

            <Year>{version}</Year>

            {hasNotifications && (
              <Notifications
                formId={formId}
                handleClick={handleClickInside}
                notifications={notificationTypes}
              />
            )}
          </TitlePart>

          <Actions
            actions={{
              archiveRequest,
              clearNotifications,
              copyToClipboard,
              deleteRequest,
              handleClick: handleClickInside,
              markRead,
              showProxyModal,
              unarchiveRequest,
            }}
            background={isRead ? '#F5F5F5' : '#ffffff'}
            formURL={formURL}
            hasNotifications={hasNotifications}
            request={request}
          />
        </Main>
      </Cell>

      <Cell
        data-cy="completion"
        width={100}
        background={isRead ? '#F5F5F5' : '#ffffff'}
        addBorder
      >
        <Completion
          completion={completion}
          questionsFilledCount={questionsFilledCount}
          questionsCount={questionsCount}
        />
      </Cell>

      {showScore ? (
        <Cell width={130} addBorder>
          <ValidationScore
            canViewData={canViewData}
            score={score}
            showValidationScore={showValidationScore}
          />
        </Cell>
      ) : (
        <Spacer width={2} />
      )}

      <Cell background={isRead ? '#eceded' : '#F5F5F5'} width={125} addBorder>
        {dueDate && (
          <DueDate
            dueDate={dueDate}
            acceptedDate={acceptedDate}
            requested={requestedAt}
            language={language}
          />
        )}
      </Cell>

      <Cell width={125}>
        <UpdatedOn>
          {language &&
            format(updatedAt, DATE_FORMAT_BASED_ON_LOCALE[language.value], {
              locale: getLocale(language),
            })}
        </UpdatedOn>
      </Cell>
    </WrapperLink>
  );
};
const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});
export default connect(mapStateToProps, null)(memo(withTranslation()(Request)));
