import delay from 'utils/delayPromise';
import { ApiCallActionType } from '../../store.types';
import { MappingRequest } from '../../../generated/models/MappingRequest';
import { call, put } from 'redux-saga/effects';
import { getFormWithoutReset } from './getFormWithoutReset';
import { updateUploadStatus } from '../utils';
import { FormsStateType } from '../forms.reducer';
import { UploadStatusEnum, Uuid } from '../../../common/common.types';

export interface FormsGetUploadAndMapStatusCallParamsType {
  id: Uuid;
  formId: Uuid;
}

export type FormsGetUploadAndMapStatusActionType = ApiCallActionType<
  '@forms/API/GET_UPLOAD_AND_MAP_STATUS',
  FormsGetUploadAndMapStatusCallParamsType,
  MappingRequest,
  never,
  FormsStateType
>;

const {
  FINISHED,
  PARSING_FAILED,
  PARSING_EMPTY_RESULT,
  MAPPING_FAILED,
} = UploadStatusEnum;

export const getUploadAndMapStatus = (
  id: Uuid,
  formId: Uuid,
): FormsGetUploadAndMapStatusActionType => ({
  type: '@forms/API/GET_UPLOAD_AND_MAP_STATUS',
  payload: {
    callParams: { id, formId },
    endpointPath: ['forms', 'uploadAndMap', 'get'],
    selector: updateUploadStatus(id),
    postActions: [
      ({ status, mapping_stats }) =>
        status === FINISHED &&
        mapping_stats &&
        mapping_stats.questions_updated > 0
          ? put(getFormWithoutReset())
          : null,
      () => call(delay, 10000),
      (payload: MappingRequest) => delayedPostAction(payload, id, formId),
    ],
  },
});

const delayedPostAction = (payload: MappingRequest, id: Uuid, formId: Uuid) => {
  switch (payload.status) {
    case FINISHED:
    case PARSING_FAILED:
    case PARSING_EMPTY_RESULT:
    case MAPPING_FAILED:
      return null;

    default:
      return put(getUploadAndMapStatus(id, formId));
  }
};
