import styled from 'styled-components';
import { HEADER_HEIGHT } from 'common/common.styles';

const INNER_BORDER_RADIUS = '3px';

export const Bar = styled.header<{ hide?: boolean }>`
  position: ${({ hide }) => (hide ? 'relative' : 'fixed')};
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.basic.colors.extraDark};
  z-index: 1000;

  @media print {
    display: none;
  }
`;

export const BarInner = styled.div`
  max-width: 1170px;
  min-width: 1000px;
  margin: 0 auto;
  height: ${HEADER_HEIGHT}px;
  color: ${({ theme }) => theme.basic.colors.light};
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: space-between;
`;

export const Group = styled.div`
  height: 100%;
  display: flex;
`;

export const Left = styled(Group)`
  align-self: flex-start;
  padding: 7px 0;
`;

export const LeftInner = styled(Group)`
  > * {
    background: ${({ theme }) => theme.nav.leftBackground};

    &:first-child {
      border-top-left-radius: ${INNER_BORDER_RADIUS};
      border-bottom-left-radius: ${INNER_BORDER_RADIUS};
    }

    &:last-child {
      border-top-right-radius: ${INNER_BORDER_RADIUS};
      border-bottom-right-radius: ${INNER_BORDER_RADIUS};
    }
  }
`;

export const Right = styled(Group)`
  align-self: flex-end;
`;
