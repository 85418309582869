import * as R from 'ramda';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { Uuid } from '../../../common/common.types';
import { FormQuestionFeedbackCreateRatingEnum } from '../../../generated/models/FormQuestionFeedbackCreate';

export interface FormsRateMappingCallParamsType {
  sourceId: Uuid;
  targetId: Uuid;
  rating: FormQuestionFeedbackCreateRatingEnum;
  formId: Uuid;
  questionMappingPos: number;
  responseValue?: string;
  responsePos?: number;
}

export interface FormsRateMappingFunctionParamsType
  extends FormsRateMappingCallParamsType {
  id?: Uuid;
}

export type FormsRateMappingFunctionType = (
  rateMappingParams: FormsRateMappingFunctionParamsType,
) => void;

export type FormsRateMappingActionType = ApiCallActionType<
  '@forms/API/RATE_QUESTION_MAPPING',
  FormsRateMappingCallParamsType,
  any,
  never,
  FormsStateType
>;

export const rateMapping = ({
  sourceId,
  targetId,
  rating,
  formId,
  questionMappingPos,
  responseValue,
  responsePos,
  id,
}: FormsRateMappingFunctionParamsType): FormsRateMappingActionType => {
  return {
    type: '@forms/API/RATE_QUESTION_MAPPING',
    payload: {
      callParams: {
        sourceId,
        targetId,
        rating,
        formId,
        questionMappingPos,
        responseValue,
        responsePos,
      },
      endpointPath: ['forms', 'questions', 'rateMapping'],
      selector: (data, state) =>
        id
          ? R.assocPath(
              ['currentForm', 'questions', targetId, 'mappings'],
              state.forms.currentForm!.questions[targetId].mappings!.map(
                mapping =>
                  mapping.id === id
                    ? {
                        ...mapping,
                        isRated: true,
                        rating,
                      }
                    : { ...mapping },
              ),
              state.forms,
            )
          : null,
    },
  };
};
