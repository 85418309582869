import { TranslatePropsType, Uuid } from 'common/common.types';
import { Link, Text } from './Autocomplete.styles';
import { Trans, withTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { getLinkFormTo } from '../../../EvidenceLocker/Frameworks/UploadGoBack';
import { PageContext } from 'pages/Page';
import { FormPageContextType } from 'pages/FormPage';
import { SharedFormPageContextType } from 'pages/SharedFormPage';

export interface MatchIntroBasePropsType {
  formId: Uuid;
  standardName: string;
}

const MatchIntro = ({ t }: TranslatePropsType) => {
  const {
    data: {
      currentForm: { displayName, id },
    },
  } = useContext(PageContext) as
    | FormPageContextType
    | SharedFormPageContextType;

  return (
    <>
      <Text>{t('matchIntro') as string}</Text>

      <Trans i18nKey="answersEvidence" parent="p" t={t}>
        You may upload more answers to your
        <Link to={getLinkFormTo(id, displayName, false)}>
          answer repository
        </Link>
      </Trans>
    </>
  );
};

export default withTranslation('Autocomplete')(MatchIntro);
