import { FormsGetFormActionType } from './getForm';
import { getFiltersCallParam } from '../../../components/Form/utils';
import { parseFilterStats } from 'utils/parsers/forms.parsers';
import { Uuid } from '../../../common/common.types';
import update from 'immutability-helper';
import { FormsFiltersType } from '../forms.reducer';

export const updateFilterStats = (id: Uuid): FormsGetFormActionType => ({
  type: '@forms/API/FETCH',
  payload: {
    callParams: state => ({ id, ...getFiltersCallParam(state.forms.filters) }),
    endpointPath: ['forms', 'get'],
    selector: (data, { forms }) =>
      update(forms, {
        filters: {
          $apply: (currentFilters: FormsFiltersType) => ({
            ...currentFilters,
            ...parseFilterStats(data.filter_stats, data.assignee_stats, forms),
          }),
        },
      }),
  },
});
