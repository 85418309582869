import React from 'react';
import { RequestType, TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { Button } from '../../common/Button';

interface AcceptClosePropsType extends TranslatePropsType {
  request: RequestType;
  showConfirm: (id: string) => void;
}

const AcceptClose = ({
  showConfirm,
  request: { id },
  t,
}: AcceptClosePropsType) => {
  return (
    <>
      <Button
        id="form-accept"
        onClick={() => showConfirm(id)}
        margin="0 0 0 24px"
      >
        {t('close') as string}
      </Button>
    </>
  );
};

export default withTranslation(['FormFooter', 'common'])(AcceptClose);
