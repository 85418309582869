import React from 'react';
import SortBy from '../SortBy';
// import route, { REQUESTS_ARCHIVE } from '../../../routes';
import { BarWrapper } from '../../common/Bar/Bar.styles';
import { ArchivedRequestsFilterType } from 'store/requests/requests.types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ArchivedRequestsSetSortFunctionType } from 'store/requests/actions';
import { TranslatePropsType } from 'common/common.types';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { Controls, TitleWrapper } from '../Header.styles';
import { GoBack, Icon } from './ArchiveHeader.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface HeaderType extends TranslatePropsType, RouteComponentProps {
  filter: ArchivedRequestsFilterType;
  setSort: ArchivedRequestsSetSortFunctionType;
  goBack: () => void;
}

const ArchiveHeader = ({
  filter: { sortBy },
  setSort,
  goBack,
  history: { push: navigate },
  t,
}: HeaderType) => (
  <BarWrapper>
    <TitleWrapper>
      <h2>{t('RequestsArchive.heading') as string}</h2>
      <p>{t('RequestsArchive.caption') as string}</p>
    </TitleWrapper>

    <div>
      <Controls>
        <GoBack onClick={goBack} data-test="go-back">
          <Icon icon={faChevronLeft as IconProp} />

          {t('RequestsArchive.back') as string}
        </GoBack>
        <SortBy data={{ sortBy, isSent: true }} actions={{ setSort }} />
      </Controls>
    </div>
  </BarWrapper>
);

export default withTranslation()(withRouter(ArchiveHeader));
