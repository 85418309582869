import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const NewVendorLandingWrapper = styled.div`
  max-width: 1140px;
  background: #ffffff;
`;

export const NewVendorHeadingText = styled.h2`
  font-family: ${({ theme }) => theme.basic.fontWeights.semibold};
  font-size: 24px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InformationBoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 34px 30px;
  max-width: ${(props: { width?: number }) =>
    props.width ? `${props.width}px` : `333px`};

  & ul,
  ol {
    padding: 15px 0px;
    list-style-position: inside;
  }
`;

export const InformationBoardTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  font-family: ${({ theme }) => theme.basic.fontWeights.semibold};
  padding: 0px 0px 8px 0px;
`;

export const InformationBoardContent = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.basic.fontWeights.regular};
  line-height: 24px;
  padding: 8px 0px;
`;

export const StyledLink = styled(RouterLink)`
  height: 40px;
  width: 285px;
  margin: 8px 0px;

  border-radius: 3px;
  text-decoration: none;
`;

export const InformationBoardActionElement = styled.div`
  background: ${(props: { highlight?: boolean }) =>
    props.highlight ? `#0275D8` : `#ffffff`};
  color: ${(props: { highlight?: boolean }) =>
    props.highlight ? `#ffffff` : `#000000`};
  font-size: 16px;
  font-family: ${({ theme }) => theme.basic.fontWeights.regular};
  font-weight: 500;
  border: ${(props: { highlight?: boolean }) =>
    props.highlight ? `0px` : `1px solid #d1d1d1`};
  text-align: center;
  padding: 9px 0;
`;
