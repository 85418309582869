import { DelegationsCreated } from '../../../generated/models/DelegationsCreated';
import { assocPath } from 'ramda';
import { call, put } from 'redux-saga/effects';
import { getAssignees, hydrateFilters, loadQuestions } from '.';
import { remapCategories, getAssigneeKey, isAssigneeKeyEmail } from '../utils';
import { trackEvent } from 'utils/analytics';
import { DEFAULT_QUESTION_BLOCKS } from '../../../common/common.constants';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { IdOrEmailType, Uuid } from '../../../common/common.types';

export interface FormsAssignCategoryDelegationCallParamsType {
  formId: Uuid;
  params: {
    category: string;
    assignee_id?: Uuid;
    assignee_email?: string;
  };
}

export type FormsAssignCategoryDelegationFunctionType = (
  formId: Uuid,
  category: string,
  assignee: IdOrEmailType,
) => void;

export type FormsAssignCategoryDelegationActionType = ApiCallActionType<
  '@forms/API/ASSIGN_CATEGORY_DELEGATION',
  FormsAssignCategoryDelegationCallParamsType,
  DelegationsCreated,
  never,
  FormsStateType
>;

export const assignCategoryDelegation = (
  formId: Uuid,
  category: string,
  assignee: IdOrEmailType,
): FormsAssignCategoryDelegationActionType => ({
  type: '@forms/API/ASSIGN_CATEGORY_DELEGATION',
  payload: {
    callParams: {
      formId,
      params: {
        [getAssigneeKey(assignee)]: assignee.payload,
        category,
      },
    },
    endpointPath: ['forms', 'delegations', 'categories', 'put'],
    selector: ({ category_stats }, { forms }) =>
      assocPath(
        ['currentForm', 'categories'],
        remapCategories(category_stats, forms.currentForm!.categories!),
        forms,
      ),
    postActions: [
      () => (isAssigneeKeyEmail(assignee) ? put(getAssignees(formId)) : null),
      () => put(hydrateFilters(formId)),
      () =>
        put(
          loadQuestions({
            id: formId,
            blocks: DEFAULT_QUESTION_BLOCKS,
            category,
            offset: 0,
          }),
        ),
      () => call(() => trackEvent('Delegation', 'Add - Form Category')),
    ],
  },
});
