/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface Standard
 */
export interface Standard {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof Standard
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof Standard
     */
    updatedAt: Date;
    /**
     * A unique code that identifies the specific standard
     * @type {string}
     * @memberof Standard
     */
    code: string;
    /**
     * ID of form definition
     * @type {string}
     * @memberof Standard
     */
    definitionId?: string;
    /**
     * Description field - sometimes used to supply additinal long name of the standard that
     * is too long for listings
     * @type {string}
     * @memberof Standard
     */
    description: string;
    /**
     * True if standard has conditions
     * @type {boolean}
     * @memberof Standard
     */
    hasConditions?: boolean;
    /**
     * True if standard has custom scoring
     * @type {boolean}
     * @memberof Standard
     */
    hasCustomScoring?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Standard
     */
    id: string;
    /**
     * True if standard could be duplicated
     * @type {boolean}
     * @memberof Standard
     */
    isDuplicable?: boolean;
    /**
     * True if standard could be unpublished (switched back to `DRAFT` status)
     * @type {boolean}
     * @memberof Standard
     */
    isUnpublishable?: boolean;
    /**
     * When was the standard sent last time
     * @type {Date}
     * @memberof Standard
     */
    lastSentAt?: Date;
    /**
     * Identifier of an Atlas built-in standard logo
     * @type {string}
     * @memberof Standard
     */
    logo: string;
    /**
     * Visible name of the standard, usually without version
     * @type {string}
     * @memberof Standard
     */
    name: string;
    /**
     * Owner's company ID
     * @type {string}
     * @memberof Standard
     */
    ownerId: string;
    /**
     * ID of my own preview form (if available)
     * @type {string}
     * @memberof Standard
     */
    previewFormId?: string;
    /**
     * Should the standard be shown in evidence locker page?
     * @type {boolean}
     * @memberof Standard
     */
    showOnHp: boolean;
    /**
     * Status of the standard:
     * 
     * - `SYSTEM` standards are accessible to all companies.
     *     They should be owned by Company with internal_status = SYSTEM.
     * - `CUSTOM` standards are created by individual companies.
     *     Only owner of the standard can send request for this standard.
     * - `TEMPORARY` standard is created when user uploads new form definition.
     *     When it is confirmed by the user it becomes CUSTOM standard.
     *     Standards of this type are not shown anywhere and requests for this type cannot
     *     be sent.
     * - `DEPRECATED` standard is a deleted standard. Standards of this type are not
     *     shown anywhere and requests for this type cannot be sent.
     * - `DRAFT` standard is a standard whose form definition is currently being created
     *     in the form creator. Request from this standard cannot be (yet) sent.
     * @type {string}
     * @memberof Standard
     */
    status: StandardStatusEnum;
    /**
     * Version of the standard displayed in GUI
     * @type {string}
     * @memberof Standard
     */
    version: string;
    /**
     * Year of release of the standard, usually displayed together with name and version
     * @type {number}
     * @memberof Standard
     */
    year?: number;
    /**
     * Metadata with attribution and copyright information
     * @type {JSON}
     * @memberof Standard
     */
    data?: JSON;
}

export function StandardFromJSON(json: any): Standard {
    return StandardFromJSONTyped(json, false);
}

export function StandardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Standard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'code': json['code'],
        'definitionId': !exists(json, 'definition_id') ? undefined : json['definition_id'],
        'description': json['description'],
        'hasConditions': !exists(json, 'has_conditions') ? undefined : json['has_conditions'],
        'hasCustomScoring': !exists(json, 'has_custom_scoring') ? undefined : json['has_custom_scoring'],
        'id': json['id'],
        'isDuplicable': !exists(json, 'is_duplicable') ? undefined : json['is_duplicable'],
        'isUnpublishable': !exists(json, 'is_unpublishable') ? undefined : json['is_unpublishable'],
        'lastSentAt': !exists(json, 'last_sent_at') ? undefined : (new Date(json['last_sent_at'])),
        'logo': json['logo'],
        'name': json['name'],
        'ownerId': json['owner_id'],
        'previewFormId': !exists(json, 'preview_form_id') ? undefined : json['preview_form_id'],
        'showOnHp': json['show_on_hp'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'status': json['status'],
        'version': json['version'],
        'year': !exists(json, 'year') ? undefined : json['year'],
    };
}

export function StandardToJSON(value?: Standard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'code': value.code,
        'definition_id': value.definitionId,
        'description': value.description,
        'has_conditions': value.hasConditions,
        'has_custom_scoring': value.hasCustomScoring,
        'id': value.id,
        'is_duplicable': value.isDuplicable,
        'is_unpublishable': value.isUnpublishable,
        'last_sent_at': value.lastSentAt === undefined ? undefined : (value.lastSentAt.toISOString()),
        'logo': value.logo,
        'name': value.name,
        'owner_id': value.ownerId,
        'preview_form_id': value.previewFormId,
        'show_on_hp': value.showOnHp,
        'data':value.data,
        'status': value.status,
        'version': value.version,
        'year': value.year,
    };
}

/**
* @export
* @enum {string}
*/
export enum StandardStatusEnum {
    CUSTOM = 'CUSTOM',
    DEPRECATED = 'DEPRECATED',
    DRAFT = 'DRAFT',
    SYSTEM = 'SYSTEM',
    TEMPORARY = 'TEMPORARY'
}


