import { ApiCallActionType } from '../../store.types';
import { GetUserSearchByEmailResourceRequest } from '../../../generated/new/apis/UsersApi';
import usersApi from '../../../api/new/usersApi';
import { UserPresentAtlas } from 'generated/new';
import update from 'immutability-helper';
import { UserStateType } from '../user.types';

export type UserGetContactSummaryActionType = ApiCallActionType<
  '@user/API/GET_FIRST_CONTACT',
  GetUserSearchByEmailResourceRequest,
  UserPresentAtlas,
  never,
  UserStateType
>;

export const getUserContactSummary = (
  email: string,
): UserGetContactSummaryActionType => {
  return {
    type: '@user/API/GET_FIRST_CONTACT',
    payload: {
      callParams: { email: email },
      endpointPath: () =>
        usersApi.getUserSearchByEmailResource({
          email: email,
        }),
      selector: (data, { user }) => {
        return update(user, {
          firstContact: { $set: !data.existInAtlas },
        });
      },
    },
  };
};
