import getResponseFontSize from '../utils/getResponseFontSize';
import styled from 'styled-components';

export const Suffix = styled.div`
  font-size: 18px;
  ${({ theme }) => theme.basic.fontWeights.semibold}
  padding-left: 10px;
  min-width: 22px;
`;

export const DisabledValue = styled.div`
  color: ${({ theme }) => theme.atlas.text.primary};
  font-size: ${({ value }: { value: string }) =>
    `${getResponseFontSize(value)}px`};
`;

export const DisabledEmpty = styled.div`
  color: ${({ theme }) => theme.atlas.grey[400]};
`;
