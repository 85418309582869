import { assocPath, append, compose, contains, equals, reject } from 'ramda';
import { ParsedFileDetails } from '../../generated/models/ParsedFileDetails';
import { StandardActionType } from './actions';
import {
  StandardType,
  ParsedFileType,
  StandardBranchType,
  Uuid,
} from '../../common/common.types';
import { parseParsedFile } from 'utils/parsers';
import update from 'immutability-helper';

export interface StandardStatusBranchStateType {
  all: StandardType[];
  mostRecent: StandardType[];
  mostUsed: StandardType[];
  selected: Uuid[];
  /**
   * Contains a list of statuses that are fetched in the other fields
   * (all, mostRecent). This is useful for refetching updated data after
   * certain operations (renameStandard, deleteStandard...).
   */
  fetchedStatuses: StandardBranchType[];
}

export interface StandardStateType {
  all: StandardStatusBranchStateType;
  company: StandardStatusBranchStateType;
  parseErrorsCount: number;
  parsedFiles: ParsedFileType[];
  transferingCount: number;
  uploadingCount: number;
}

const initialStandardBranchState: StandardStatusBranchStateType = {
  all: [],
  mostRecent: [],
  mostUsed: [],
  selected: [],
  fetchedStatuses: [],
};

const initialState: StandardStateType = {
  all: initialStandardBranchState,
  company: initialStandardBranchState,
  parseErrorsCount: 0,
  parsedFiles: [],
  transferingCount: 0,
  uploadingCount: 0,
};

const standardsReducer = (
  state = initialState,
  action: StandardActionType,
): StandardStateType => {
  switch (action.type) {
    case '@standard/TOGGLE_SELECTION':
      const {
        payload: { id },
      } = action;
      const selectedStandards = state.all.selected;

      return assocPath(
        ['all', 'selected'],
        contains(id, selectedStandards)
          ? reject(equals(id), selectedStandards)
          : append(id, selectedStandards),
        state,
      );

    case '@standard/ADD_PARSED_FILES':
      const { parsedFiles } = action.payload;

      return {
        ...state,
        uploadingCount: state.uploadingCount - 1,
        parsedFiles: parsedFiles.map((pf: ParsedFileDetails) =>
          parseParsedFile(pf),
        ),
      };

    case '@standard/SET_FETCHED_STATUSES':
      const { statuses } = action.payload;
      return update(state, { all: { fetchedStatuses: { $set: statuses } } });

    case '@standard/SET_PARSE_ERROR':
      return {
        ...state,
        parseErrorsCount: state.parseErrorsCount + 1,
      };

    case '@standard/RESET_STANDARDS':
      return initialState;

    case '@standard/RESET_SELECTION':
      return compose(
        assocPath<StandardType[], StandardStateType>(['all', 'selected'], []),
        assocPath(['company', 'selected'], []),
      )(state);

    default:
      return state;
  }
};

export default standardsReducer;
