import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface UploadPropsType {
  render: (isDragActive: boolean) => JSX.Element;
  onDrop: (files: File[]) => void;
  isMultiple: boolean;
  acceptFiles?: string;
}

const UploadLink = ({
  render,
  onDrop: onDropProp,
  isMultiple,
  acceptFiles = '',
}: UploadPropsType) => {
  const onDrop = useCallback(onDropProp, [onDropProp]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: isMultiple,
    accept: acceptFiles,
  });

  return (
    <div style={{ display: 'inline' }} {...getRootProps()}>
      <input {...getInputProps()} data-test="upload-input" />

      {render(isDragActive)}
    </div>
  );
};

export default UploadLink;
