/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof User
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof User
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * Has the user ever signed into the system?
     * @type {boolean}
     * @memberof User
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'companyId': json['company_id'],
        'displayName': json['display_name'],
        'domain': json['domain'],
        'email': json['email'],
        'firstName': json['first_name'],
        'fullName': json['full_name'],
        'id': json['id'],
        'isActive': json['is_active'],
        'lastName': json['last_name'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'company_id': value.companyId,
        'display_name': value.displayName,
        'domain': value.domain,
        'email': value.email,
        'first_name': value.firstName,
        'full_name': value.fullName,
        'id': value.id,
        'is_active': value.isActive,
        'last_name': value.lastName,
    };
}


