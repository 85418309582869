import React from 'react';
import { ResponseStatus } from 'components/ResponseStatus';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface FormPage410PropsType extends TranslatePropsType {
  resourceGone?: {
    company?: string;
    statusOfResource?: string;
  };
}

const FormPage410 = ({ resourceGone, t }: FormPage410PropsType) => {
  const resourceExpired =
    resourceGone && resourceGone.statusOfResource === 'expired';
  return (
    <ResponseStatus
      caption={
        resourceExpired
          ? t('FormPage410.expiredCaption')
          : t('FormPage410.caption', resourceGone)
      }
      heading={
        resourceExpired
          ? t('FormPage410.expiredHeading')
          : t('FormPage410.heading')
      }
    />
  );
};

export default withTranslation()(FormPage410);
