import Input from '../../common/Input';
import { Prompt } from '../../common/Overlay';
import React, { useState } from 'react';
import { Caption } from './InviteUserPrompt.styles';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType, Uuid } from 'common/common.types';
import { parseEmail } from 'utils/parseEmail';

interface InviteUserPromptPropsType extends TranslatePropsType {
  closePrompt: () => void;
  confirmTextKey: string;
  domain: string;
  formId: Uuid;
  handleSubmit: (invitee: string) => void;
  initialDisabled?: boolean;
  initialInvitee?: string;
}

const InviteUserPrompt = ({
  closePrompt,
  confirmTextKey,
  domain,
  handleSubmit,
  initialDisabled = true,
  initialInvitee = '',
  t,
}: InviteUserPromptPropsType) => {
  const [invitee, setInvitee] = useState(initialInvitee);
  const [isDisabled, setDisabled] = useState(initialDisabled);

  const onInviteeChange = (value: string) => {
    setDisabled(!parseEmail(`${value}@${domain}`));
    setInvitee(value);
  };

  const onSubmit = () => handleSubmit(`${invitee}@${domain}`);

  const confirmText = t(`assignType.${confirmTextKey}`);

  return (
    <Prompt
      confirmText={confirmText}
      heading={t('invite') as string}
      onClose={closePrompt}
      onSubmit={onSubmit}
      isButtonDisabled={isDisabled}
    >
      <Input
        onChange={onInviteeChange}
        placeholder={t('inviteInputCaption') as string}
        suffix={t('inviteDomainCaption', { domain })}
        value={invitee}
      />

      <Caption>{t('inviteCaption') as string}</Caption>
    </Prompt>
  );
};

export default withTranslation('Delegator')(InviteUserPrompt);
