import DueDate from '../SendRequest/Recipient/DueDate';
import Message from '../SendRequest/Recipient/Message';
import Modal from 'utils/Modal';
import React from 'react';
import EmailReceivers from '../SendRequest/Recipient/EmailReceivers';
import Reminder from '../SendRequest/Recipient/Reminder';
import Submit from '../SendRequest/Submit';
import { Content, Footer, Heading, Scroller } from './RequestModal.styles';
import { Flex } from 'common/common.styles';
import { SendRequestErrorType } from 'store/requests/requests.types';
import { SetFormValueFunctionType } from 'store/requests/actions';
import { withTranslation } from 'react-i18next';
import {
  CompanyWithTagsType,
  CompletedStandardType,
  TranslatePropsType,
  UserType,
} from 'common/common.types';

interface RequestModalPropsType extends TranslatePropsType {
  appealCredits: () => void;
  company: CompanyWithTagsType;
  error: SendRequestErrorType;
  form: any;
  handleSubmit: () => void;
  modalId: string;
  setValue: SetFormValueFunctionType;
  standard: CompletedStandardType;
  user: UserType;
}
const RequestModal = ({
  appealCredits,
  company: { info },
  error,
  form: { dueDate, reminder, targets },
  handleSubmit,
  modalId,
  setValue,
  standard,
  user: { credits },
  user,
  t,
}: RequestModalPropsType) => {
  const balance = (credits && credits.summary!.remaining) || 0;
  const cost = 1;
  const titleLabel = t('sendQuestionnaireTitle', { title: standard.name });

  const modalProps = {
    modalId: modalId,
    title: <Heading>{titleLabel}</Heading>,
  };

  return (
    <Modal {...modalProps}>
      <Scroller>
        <Content>
          <Flex>
            <EmailReceivers
              mailingList={targets}
              setValue={setValue}
              targets={targets}
              isMulti={false}
              showNote={false}
              readOnly
              getUserContactSummary={targets}
              user={user}
            />

            <DueDate dueDate={dueDate} setValue={setValue} />

            {dueDate && <Reminder reminder={reminder} setValue={setValue} />}
          </Flex>

          <Message
            setValue={setValue}
            placeholder={t('messagePlaceholder', {
              company: info.name,
              standard: standard.name,
            })}
          />
        </Content>
      </Scroller>
      <Footer>
        <Submit
          appealCredits={appealCredits}
          balance={balance}
          cost={cost}
          error={error}
          handleSubmit={handleSubmit}
          isFormValid
          itemsCount={1}
        />
      </Footer>
    </Modal>
  );
};

export default withTranslation('SendRequest')(RequestModal);
