/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface RequestFinding
 */
export interface RequestFinding {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof RequestFinding
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof RequestFinding
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    ownerId: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    remediationPlan?: RequestFindingRemediationPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    remediationPlanNote: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    requestId: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    riskLevel?: RequestFindingRiskLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    riskLevelNote: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFinding
     */
    userId: string;
}

export function RequestFindingFromJSON(json: any): RequestFinding {
    return RequestFindingFromJSONTyped(json, false);
}

export function RequestFindingFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFinding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'id': json['id'],
        'ownerId': json['owner_id'],
        'remediationPlan': !exists(json, 'remediation_plan') ? undefined : json['remediation_plan'],
        'remediationPlanNote': json['remediation_plan_note'],
        'requestId': json['request_id'],
        'riskLevel': !exists(json, 'risk_level') ? undefined : json['risk_level'],
        'riskLevelNote': json['risk_level_note'],
        'userId': json['user_id'],
    };
}

export function RequestFindingToJSON(value?: RequestFinding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'id': value.id,
        'owner_id': value.ownerId,
        'remediation_plan': value.remediationPlan,
        'remediation_plan_note': value.remediationPlanNote,
        'request_id': value.requestId,
        'risk_level': value.riskLevel,
        'risk_level_note': value.riskLevelNote,
        'user_id': value.userId,
    };
}

/**
* @export
* @enum {string}
*/
export enum RequestFindingRemediationPlanEnum {
    REMEDIATE = 'REMEDIATE',
    ACCEPT = 'ACCEPT',
    COMPLETE = 'COMPLETE'
}
/**
* @export
* @enum {string}
*/
export enum RequestFindingRiskLevelEnum {
    CRITICAL = 'CRITICAL',
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
    NONE = 'NONE'
}


