import { ApiCallActionType } from '../../store.types';
import { parseTags } from 'utils/parsers';
import { CompaniesStateType } from '../companies.reducer';
import { Tag } from '../../../generated/models';

export type CompaniesGetTagsActionType = ApiCallActionType<
  '@companies/API/GET_TAGS',
  {},
  Tag[],
  never,
  CompaniesStateType
>;

export const getTags = (): CompaniesGetTagsActionType => ({
  type: '@companies/API/GET_TAGS',
  payload: {
    endpointPath: ['companies', 'tags', 'get'],
    callParams: {},
    selector: data => ({ myTags: parseTags(data) }),
  },
});
