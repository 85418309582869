import {
  FilterOptionType,
  isLookForFilter,
} from 'utils/filters/requests.filters';
import Chip from '../../Form/Filter/Chip';
import React from 'react';
import FiltersDefault, { FiltersDefaultPropsType } from './Filters.Default';

const FiltersLookFor = ({
  filterBy,
  filterOptions,
  handleDelete,
  option,
}: FiltersDefaultPropsType) => {
  if (isLookForFilter(filterBy)) {
    const lookFor = new Set<string>(filterBy.lookFor);

    return filterOptions
      .filter((filterOption: FilterOptionType) =>
        lookFor.has(filterOption.value),
      )
      .map(({ value, label }: FilterOptionType) => (
        <Chip
          key={`chip-${option}-${value}`}
          label={label}
          onDelete={() => handleDelete(option, value)}
        />
      ));
  }
  return (
    <FiltersDefault
      filterBy={filterBy}
      filterOptions={filterOptions}
      handleDelete={handleDelete}
      option={option}
    />
  );
};

export default FiltersLookFor;
