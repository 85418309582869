import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import update from 'immutability-helper';
import { RequestFindingType } from '../../../common/common.types';
import uuid from 'utils/uuid';
import { findIndex } from 'ramda';
import { put } from 'redux-saga/effects';
import { finishReportSaving } from './finishReportSaving';
import { EMPTY_ID } from '../../../common/common.constants';
import requestsApi from '../../../api/new/requestsApi';
import {
  PostRequestFindingsListResourceRequest,
  RequestFinding,
} from '../../../generated/new';

export type FormsAddFindingCallParamsType = PostRequestFindingsListResourceRequest;

export type FormsAddFindingFunctionType = (
  params: FormsAddFindingCallParamsType,
) => void;

export type FormsAddFindingActionType = OptimisticActionType<
  '@forms/API/ADD_FINDING',
  ApiCallActionPayloadType<
    PostRequestFindingsListResourceRequest,
    RequestFinding,
    never,
    FormsStateType
  >
>;

export const addFinding = (
  params: FormsAddFindingCallParamsType,
): FormsAddFindingActionType => {
  const optimisticId = uuid();
  return {
    type: '@forms/API/ADD_FINDING',
    payload: {
      callParams: params,
      endpointPath: p => requestsApi.postRequestFindingsListResource(p),
      expectedStateChange: {
        'forms.currentForm.request.findings': (
          findings: RequestFindingType[],
        ): RequestFindingType[] => {
          const {
            requestId,
            payload: {
              remediationPlan,
              remediationPlanNote,
              riskLevel,
              riskLevelNote,
            },
          } = params;

          return [
            ...findings,
            // Dummy optimistic item
            {
              createdAt: new Date(),
              updatedAt: new Date(),
              id: EMPTY_ID,
              optimisticId,
              ownerId: EMPTY_ID,
              remediationPlan,
              remediationPlanNote: remediationPlanNote || '',
              requestId,
              riskLevel,
              riskLevelNote: riskLevelNote || '',
              userId: EMPTY_ID,
            },
          ];
        },
      },
      selector: (finding, { forms }): FormsStateType => {
        if (
          !forms.currentForm ||
          !forms.currentForm.request ||
          !forms.currentForm.request.findings
        ) {
          return forms;
        }

        const index = findIndex(
          ({ optimisticId: id }) => id === optimisticId,
          forms.currentForm.request.findings,
        );
        if (index === -1) {
          return forms;
        }

        return update(forms, {
          currentForm: {
            request: {
              findings: {
                [index]: { $set: finding },
              },
            },
          },
        });
      },
      postActions: [() => put(finishReportSaving())],
    },
  };
};
