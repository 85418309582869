import React, { CSSProperties, SyntheticEvent } from 'react';
import { Button as ButtonElement } from './Button.styles';

export type ButtonColorType =
  | 'brightBlue'
  | 'gray'
  | 'green'
  | 'light'
  | 'lightGreen'
  | 'blue'
  | 'seaGreen'
  | 'teal'
  | 'transparent'
  | 'white';

export type ButtonSizeType =
  | 'mini'
  | 'small'
  | 'medium'
  | 'big'
  | 'large'
  | 'mlarge';

export interface ButtonType {
  children: any;
  color?: ButtonColorType;
  textColor?: string;
  disabled?: boolean;
  hasShadow?: boolean;
  id?: string;
  linkLike?: boolean;
  margin?: string;
  onClick?: (event?: SyntheticEvent<any>) => void;
  size?: ButtonSizeType;
  style?: CSSProperties;
  title?: string;
  dataTest?: string;
  /**
   * Use this so that parent container can handle mouse-leave event even when the button is disabled
   */
  pointerEventsNoneForDisabled?: boolean;
}

export const Button = ({
  children,
  color = 'teal',
  disabled,
  hasShadow,
  id,
  linkLike,
  margin,
  onClick = (event?: any) => null,
  size = 'small',
  style,
  title,
  textColor,
  pointerEventsNoneForDisabled,
  dataTest,
}: ButtonType) => (
  <ButtonElement
    data-test={dataTest}
    color={color || 'gray'}
    textColor={textColor}
    disabled={disabled || false}
    hasShadow={hasShadow || false}
    id={id}
    linkLike={linkLike}
    margin={margin}
    onClick={event => onClick(event)}
    size={size || 'medium'}
    style={style}
    title={title}
    pointerEventsNoneForDisabled={pointerEventsNoneForDisabled}
  >
    {children}
  </ButtonElement>
);
