import React from 'react';
import Tooltip from '../../common/Tooltip';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { Title as TitleWrapper } from './Request.styles';

interface TitlePropsType extends TranslatePropsType {
  revision: number;
  status: string;
  title: string;
}

const Title = ({ status, title, revision, t }: TitlePropsType) =>
  status === 'VENDOR_IN_PROGRESS' ? (
    <Tooltip
      overlay={
        t(`Requests.${revision > 1 ? 'revision' : 'inProgress'}`, {
          revision,
        }) as string
      }
    >
      <TitleWrapper data-cy="request-title">{title}</TitleWrapper>
    </Tooltip>
  ) : (
    <TitleWrapper data-cy="request-title">{title}</TitleWrapper>
  );

export default withTranslation()(Title);
