/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { CompanySimple } from "./CompanySimple";
// @ts-ignore
import { StandardSimple } from "./StandardSimple";
// @ts-ignore
import { UserSimple } from "./UserSimple";
// @ts-ignore
import { FormSimple } from "./FormSimple";
// @ts-ignore
import { TeamSimple } from "./TeamSimple";


/**
 *
 * @export
 * @interface RequestSimple
 */
export interface RequestSimple{
    source_team: TeamSimple;
    /**
     *
     * @type {TeamSimple}
     * @memberof RequestSimple
     */
     target_team: TeamSimple;
    /**
     *
     * @type {string}
     * @memberof RequestSimple
     */
    id: string;
    /**
     * When was the entity created
     * @type {Date}
     * @memberof RequestSummary
     */
    created_at: Date;
    /**
     * When was the entity created
     * @type {Date}
     * @memberof RequestSimple
     */
    updated_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof RequestSimple
     */
    updated_at_vendor: Date;
    /**
     *
     * @type {Date}
     * @memberof RequestSimple
     */
    can_view_data: boolean;
    /**
     *
     * @type {Date}
     * @memberof RequestSimple
     */
    due_date?: Date;
    /**
     * The day when the VRM accepted the request. This will be set internally and only when the VRM status changes
     * from in progress to accepted
     * @type {Date}
     * @memberof RequestSimple
     */
    accepted_date?: Date;
    /**
     *
     * @type {number}
     * @memberof RequestSimple
     */
    completion_days?: number;
    /**
     *
     * @type {boolean}
     * @memberof RequestSimple
     */
    is_internal: boolean;
    /**
     *
     * @type {Standard}
     * @memberof RequestSimple
     */
    standard: StandardSimple;
    /**
     * Current request status
     * @type {string}
     * @memberof RequestSimple
     */
    status: StatusEnum;
    /**
     *
     * @type {FormSimple}
     * @memberof RequestSimple
     */
    form: FormSimple;
    /**
     *
     * @type {CompanySimple}
     * @memberof RequestSimple
     */
    source_company: CompanySimple;
    /**
     *
     * @type {UserSimple}
     * @memberof RequestSimple
     */
    source_user: UserSimple;
    /**
     *
     * @type {CompanySimple}
     * @memberof RequestSimple
     */
    target_company: CompanySimple;
    /**
     *
     * @type {UserSimple}
     * @memberof RequestSimple
     */
    target_user: UserSimple;
    /**
     * Current vrm view status
     * @type {string}
     * @memberof RequestSimple
     */
    vrm_view_status: VrmViewStatusEnum;
    /**
     * Status of the request after it will be unarchived
     * @type {string}
     * @memberof RequestDetail
     */
    status_before_archived: StatusBeforeArchivedEnum;
    /**
     *
     * @type {boolean}
     * @memberof RequestSimple
     */
    has_new_attachments: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestSimple
     */
    has_new_comments: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestSimple
     */
    has_new_history: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestSimple
     */
    has_new_ssc_data: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestSimple
     */
    is_unread: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum FormRolesEnum {
    OWNER = 'OWNER',
    VENDOR = 'VENDOR',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY',
    OTHER = 'OTHER'
}

/**
 * @export
 * @enum {string}
 */
export enum StatusEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}
/**
 * @export
 * @enum {string}
 */
export enum VrmViewStatusEnum {
    VENDORAPPROVED = 'VENDOR_APPROVED',
    VENDORDECLINED = 'VENDOR_DECLINED',
    VENDORINITIAL = 'VENDOR_INITIAL',
    VRMREQUESTED = 'VRM_REQUESTED'
}

/**
 * @export
 * @enum {string}
 */
export enum StatusBeforeArchivedEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}
