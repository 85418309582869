/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface TagBase
 */
export interface TagBase {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof TagBase
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof TagBase
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof TagBase
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TagBase
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TagBase
     */
    ownerId: string;
    /**
     * 
     * @type {string}
     * @memberof TagBase
     */
    tagType: TagBaseTagTypeEnum;
}

export function TagBaseFromJSON(json: any): TagBase {
    return TagBaseFromJSONTyped(json, false);
}

export function TagBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'id': json['id'],
        'name': json['name'],
        'ownerId': json['owner_id'],
        'tagType': json['tag_type'],
    };
}

export function TagBaseToJSON(value?: TagBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'id': value.id,
        'name': value.name,
        'owner_id': value.ownerId,
        'tag_type': value.tagType,
    };
}

/**
* @export
* @enum {string}
*/
export enum TagBaseTagTypeEnum {
    NORMAL = 'NORMAL',
    VENDOR = 'VENDOR',
    VRM = 'VRM'
}


