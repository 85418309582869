import { ApiCallActionType } from '../../store.types';
import { parseCompanyDetail } from 'utils/parsers';
import { CompaniesStateType } from '../companies.reducer';
import { Uuid } from '../../../common/common.types';
import { CompanyDetail } from '../../../generated/models';

export interface CompaniesGetCompanyCallParamsType {
  id: Uuid;
}

export type CompaniesGetCompanyActionType = ApiCallActionType<
  '@companies/API/GET_COMPANY',
  CompaniesGetCompanyCallParamsType,
  CompanyDetail,
  never,
  CompaniesStateType
>;

export const getCompany = (id: Uuid): CompaniesGetCompanyActionType => ({
  type: '@companies/API/GET_COMPANY',
  payload: {
    endpointPath: ['companies', 'getCompany'],
    callParams: { id },
    selector: (data): Partial<CompaniesStateType> => ({
      details: parseCompanyDetail(data),
    }),
  },
});
