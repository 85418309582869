import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { CreatedAt, Content, Title, Wrapper } from './Audit.styles';
import { format } from 'date-fns';
import { DATE_TIME_FORMAT_BASED_ON_LOCALE } from 'common/common.constants';
import { TFunction } from 'i18next';
import { LanguageType, QuestionHistoryType } from 'common/common.types';
import getLocale from 'utils/getLocale';
import { StateType } from '../../../../../store/store.types';
import { getLanguagePreference } from '../../../../../store/settings/settings.selectors';
import { connect } from 'react-redux';

export interface AuditPropsType {
  date: Date;
  children?: any;
  title: string;
  language?: LanguageType;
}

export interface AuditInputPropsType {
  change: QuestionHistoryType;
  t: TFunction;
}

const Audit = ({ date, children, title, language }: AuditPropsType) => {
  return (
    <Wrapper>
      <Title>{ReactHtmlParser(title)}</Title>
      <CreatedAt>
        {language &&
          format(date, DATE_TIME_FORMAT_BASED_ON_LOCALE[language.value], {
            locale: getLocale(language),
          })}
      </CreatedAt>
      <Content>{children}</Content>
    </Wrapper>
  );
};
const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});

export default connect(mapStateToProps, null)(Audit);
