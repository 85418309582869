import React from 'react';
import {
  Author,
  CreatedAt,
  NoData,
  Record,
  Text,
  Wrapper,
} from './History.styles';
import {
  FormHistoryType,
  LanguageType,
  TranslatePropsType,
} from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  DATE_TIME_FORMAT_BASED_ON_LOCALE,
  STATUS_ORDERING,
} from 'common/common.constants';
import { FormHistoryActionEnum } from 'generated/models/FormHistory';
import getLocale from 'utils/getLocale';
import {
  FormHistoryDataFromEnum,
  FormHistoryDataToEnum,
} from 'generated/models/FormHistoryData';
import { connect } from 'react-redux';
import { StateType } from '../../../../store/store.types';
import { getLanguagePreference } from '../../../../store/settings/settings.selectors';

export interface HistoryPropsType extends TranslatePropsType {
  data: FormHistoryType[];
  isVRM: boolean;
  language: LanguageType;
}

const History = ({ data, isVRM, t, language }: HistoryPropsType) => {
  const renderText = (
    action: FormHistoryActionEnum,
    from?: FormHistoryDataFromEnum,
    to?: FormHistoryDataToEnum,
  ) => {
    if (action === FormHistoryActionEnum.CHANGEDSTATE) {
      if (!from || !to) {
        return <Text>{t(action) as string}</Text>;
      }

      const translationKey =
        STATUS_ORDERING[from] < STATUS_ORDERING[to]
          ? `forwardChange.${to}`
          : `backwardChange`;
      const translationOptions = { context: isVRM ? 'VRM' : 'Vendor' };

      return (
        <Text>
          {
            t(translationKey, {
              ...translationOptions,
              status: t(`status.${to}`, translationOptions),
            }) as string
          }
        </Text>
      );
    }
    return <Text>{t(action) as string}</Text>;
  };

  return (
    <Wrapper>
      {data.length === 0 && <NoData>{t('noData') as string}</NoData>}

      {data.length > 0 &&
        data.map(
          ({
            action,
            createdAt,
            from,
            to,
            user: { displayName: author },
          }: FormHistoryType) => (
            <Record key={`${action}-${createdAt}`} data-cy="historyRecord">
              <CreatedAt>
                {format(
                  createdAt,
                  DATE_TIME_FORMAT_BASED_ON_LOCALE[language.value],
                  { locale: getLocale(language) },
                )}
              </CreatedAt>
              <Author>{author}</Author>
              {renderText(action, from, to)}
            </Record>
          ),
        )}
    </Wrapper>
  );
};

const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation('FormHistory')(History));
