import * as R from 'ramda';
import { ApiCallActionType } from '../../store.types';
import { MailingListItemType, UserType } from '../../../common/common.types';
import { UserDetail } from '../../../generated/models/UserDetail';
import { parseUser } from 'utils/parsers';
import { UserStateType } from '../user.types';

export type UserGetMailingListActionType = ApiCallActionType<
  '@user/API/GET_MAILING_LIST',
  {},
  UserDetail[],
  never,
  UserStateType
>;

export const getMailingList = (list: string): UserGetMailingListActionType => ({
  type: '@user/API/GET_MAILING_LIST',
  payload: {
    endpointPath: [
      'user',
      list === 'request' ? 'getRequestMailingList' : 'getShareMailingList',
    ],
    callParams: {},
    selector: (data, state): Partial<UserStateType> =>
      R.assocPath<MailingListItemType[], UserStateType>(
        ['mailingList', list],
        data
          .map((item: UserDetail) => parseUser({ user: item }))
          .map(
            ({ email }: UserType): MailingListItemType => ({
              label: email,
              value: email,
            }),
          ),
      )(state.user),
  },
});
