import React from 'react';
import { ClosedValue } from './Value.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResponseType } from 'common/common.types';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { ResponseTypeDefinitionResponseTypeEnum } from 'generated/models/ResponseTypeDefinition';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ValuePropsType {
  copyToClipboard?: (text: string) => void;
  response: ResponseType;
}

const {
  MULTISELECT,
  PERCENTAGE,
  SINGLESELECT,
} = ResponseTypeDefinitionResponseTypeEnum;

const Value = ({
  copyToClipboard,
  response: {
    id,
    definition: { responseType },
    value,
  },
}: ValuePropsType) => {
  if (responseType === MULTISELECT) {
    return JSON.parse(value).map((v: string) => (
      <ClosedValue value={v} key={`response-${id}-${v}`}>
        {v}
      </ClosedValue>
    ));
  } else if (responseType === SINGLESELECT) {
    return <ClosedValue value={value}>{value}</ClosedValue>;
  } else {
    const valueFormatted = responseType === PERCENTAGE ? `${value} %` : value;

    if (copyToClipboard) {
      return (
        <div>
          {valueFormatted}

          <div onClick={() => copyToClipboard(value)}>
            <FontAwesomeIcon icon={faCopy as IconProp} />
          </div>
        </div>
      );
    }
    return <div>{valueFormatted}</div>;
  }
};

export default Value;
