import Modal from 'utils/Modal';
import Radio from '../../common/Radio';
import React, { useState } from 'react';
import { format } from 'date-fns';
import { Button } from '../../common/Button';
import { DATE_FORMAT_BASED_ON_LOCALE } from 'common/common.constants';
import { RequestDeleteScheduledFunctionType } from 'store/requests/actions';
import { withTranslation } from 'react-i18next';
import {
  DeleteIntervalEnum,
  LanguageType,
  ScheduledRequestType,
  TranslatePropsType,
} from 'common/common.types';
import {
  Content,
  Footer,
  Heading,
  RadioOptions,
} from './DeleteRequestModal.styles';
import { StateType } from '../../../store/store.types';
import { getLanguagePreference } from '../../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import getLocale from 'utils/getLocale';

interface DeleteRequestModalPropsType extends TranslatePropsType {
  closeModal: () => void;
  handleSubmit: RequestDeleteScheduledFunctionType;
  modalId: string;
  request: ScheduledRequestType;
  language: LanguageType;
}

const DeleteRequestModal = ({
  closeModal,
  handleSubmit,
  modalId,
  request: {
    id,
    nextSendDate,
    standard: { name: standardName },
  },
  language,
  t,
}: DeleteRequestModalPropsType) => {
  const [deleteOption, setDeleteOption] = useState<DeleteIntervalEnum | null>(
    null,
  );

  const onSubmit = () => {
    if (deleteOption) {
      handleSubmit(id, deleteOption);
    }
  };

  const modalProps = {
    modalId: modalId,
    title: <Heading>{t('ScheduledRequest.headingDelete') as string}</Heading>,
    onClose: closeModal,
  };

  return (
    <Modal {...modalProps}>
      <Content>
        <p>{standardName}</p>

        <RadioOptions>
          <Radio
            isSelected={deleteOption === DeleteIntervalEnum.NEXT}
            label={
              t('ScheduledRequest.deleteNext', {
                nextSendDate: format(
                  nextSendDate,
                  DATE_FORMAT_BASED_ON_LOCALE[language.value],
                  { locale: getLocale(language) },
                ),
              }) as string
            }
            handleChange={() => setDeleteOption(DeleteIntervalEnum.NEXT)}
          />

          <Radio
            isSelected={deleteOption === DeleteIntervalEnum.ALL}
            label={t('ScheduledRequest.deleteAll') as string}
            handleChange={() => setDeleteOption(DeleteIntervalEnum.ALL)}
          />
        </RadioOptions>
      </Content>

      <Footer>
        <Button color="transparent" onClick={closeModal} size="medium">
          {t('ScheduledRequest.cancel') as string}
        </Button>

        <Button disabled={!deleteOption} onClick={onSubmit} size="medium">
          {t('ScheduledRequest.delete') as string}
        </Button>
      </Footer>
    </Modal>
  );
};
const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});
export default connect(
  mapStateToProps,
  null,
)(withTranslation()(DeleteRequestModal));
