import React from 'react';
import { Footer, FooterItem } from './Layout.styles';
import { Horizontal, VerticallyCentered } from 'common/common.styles';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

const LayoutFooter = ({ t }: TranslatePropsType) => (
  <Footer>
    <VerticallyCentered>
      <Horizontal>
        <FooterItem>
          <a href="https://securityscorecard.com/company">
            {t('Footer.aboutUs') as string}
          </a>
        </FooterItem>

        <FooterItem>
          <a href="https://securityscorecard.com/privacy">
            {t('Footer.privacyPolicy') as string}
          </a>
        </FooterItem>

        <FooterItem>
          <a href="https://securityscorecard.com/msa">
            {t('Footer.termsOfUse') as string}
          </a>
        </FooterItem>
      </Horizontal>
    </VerticallyCentered>
  </Footer>
);

export default withTranslation()(LayoutFooter);
