import { ApiCallActionType } from '../../store.types';
import { put } from 'redux-saga/effects';
import { Uuid } from '../../../common/common.types';
import { getForm } from '../../forms/actions';

export type GetPreviewFunctionType = (params: GetPreviewParamsType) => void;

export type GetPreviewActionType = ApiCallActionType<
  '@formCreator/API/GET_PREVIEW',
  GetPreviewParamsType
>;

export interface GetPreviewParamsType {
  draftId: Uuid;
  previewFormId: Uuid;
}

export const getPreview = ({
  draftId,
  previewFormId,
}: GetPreviewParamsType): GetPreviewActionType => ({
  type: '@formCreator/API/GET_PREVIEW',
  payload: {
    callParams: { draftId, previewFormId },
    endpointPath: ['definitions', 'drafts', 'preview'],
    selector: null,
    postActions: [() => put(getForm({ id: previewFormId }))],
  },
});
