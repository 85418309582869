import truncateFilename from '../truncateFilename';
import { Attachment } from 'generated/models/Attachment';
import { AttachmentType, Uuid } from 'common/common.types';
import { EMPTY_ID } from 'common/common.constants';

export const parseAttachment = (
  attachment: Attachment,
  getResponseId?: (attachmentId: Uuid) => Uuid | undefined,
): AttachmentType => {
  const {
    id = EMPTY_ID,
    created_at = 0,
    file_size = 0,
    name = '',
  } = attachment;

  return {
    id,
    createdAt: new Date(created_at),
    displayName: truncateFilename(name, 26),
    fileSize: file_size,
    name,
    responseId: getResponseId ? getResponseId(id) : undefined,
  };
};

export const createDummyAttachment = (
  file: File,
  uploadingId?: Uuid,
): AttachmentType => ({
  id: EMPTY_ID,
  createdAt: new Date(),
  displayName: truncateFilename(file.name, 26),
  fileSize: file.size,
  name: file.name,
  uploadingId,
});
