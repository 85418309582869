import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import {
  CompanyGradeEnum,
  CompanyInternalStatusEnum,
} from 'generated/models/Company';
import { CreditsSummary } from 'generated/models/CreditsSummary';
import {
  FormDetailFillingTypeEnum,
  FormDetailInitializationStatusEnum,
  FormDetailPermissionsEnum,
} from 'generated/models/FormDetail';
import { ParsingMessageSeverityEnum } from 'generated/models/ParsingMessage';
import { QuestionDetailBaseVrmReviewStatusEnum } from 'generated/models/QuestionDetailBase';
import {
  RequestDetailFormRolesEnum,
  RequestDetailStatusEnum,
  RequestDetailVrmViewStatusEnum,
} from 'generated/models/RequestDetail';

import { StatusEnum, VrmViewStatusEnum } from 'generated/models/RequestSimple';

import { StandardStatusEnum } from 'generated/models/Standard';
import { TFunction, i18n } from 'i18next';
import {
  UserDetailInternalStatusEnum,
  UserDetailRolesEnum,
} from 'generated/models/UserDetail';
import {
  VRMReviewStatsScopeEnum,
  VRMReviewStatsStatusEnum,
} from 'generated/models/VRMReviewStats';
import {
  CategoryDelegationStatsQuantityEnum,
  CategoryDelegationStatsScopeEnum,
} from 'generated/models/CategoryDelegationStats';
import { ResponseTypeDefinitionResponseTypeEnum } from 'generated/models/ResponseTypeDefinition';
import { FormHistoryActionEnum } from 'generated/models/FormHistory';
import {
  FormHistoryDataFromEnum,
  FormHistoryDataToEnum,
} from 'generated/models/FormHistoryData';
import {
  AutocompleteExecution,
  RequestFinding,
  IssueType,
  Factor,
  Team,
} from 'generated/new/models';
import {
  FormDefinitionError,
  QuestionError,
  ResponseError,
} from 'store/formCreator/validationError.types';
import { ValidationStatus } from 'components/FormCreator/FormOutline/FormOutline.types';
import { QuestionConditionStructuredItemOperatorEnum } from 'generated/models/QuestionConditionStructuredItem';
import { QuestionConditionStructuredOperatorEnum } from 'generated/models/QuestionConditionStructured';
import {
  ProxyShortSummaryFormRoleEnum,
  QuestionDataBlocks,
  QuestionMetadataBlocks,
  RequestSummaryFormRolesEnum,
  RequestSummaryStatusEnum,
  RequestSummaryVrmViewStatusEnum,
  ScheduledRequestRecurrenceTypeEnum,
  ResponseFillingHistoryActionEnum,
} from 'generated/models';
import {
  CompanySimpleInternalStatusEnum,
  CompanySimpleGradeEnum,
} from '../generated/models/CompanySimple';

export interface AutocompleteProgressType {
  autocompleteId: Uuid;
  matchingQuestionsCount?: number;
  questionsProcessed: number;
  questionsTotal: number;
}

export type AutocompleteDialogStateType =
  | { type: 'CLOSED_IDLE' }
  | {
      type: 'CLOSED_AUTOCOMPLETE_IN_PROGRESS';
      progress: AutocompleteProgressType;
    }
  | { type: 'OPENED_MATCH_SEARCH_IN_PROGRESS' }
  | { type: 'OPENED_GLOBAL_MAPPING_IN_PROGRESS' }
  | { type: 'OPENED_MATCHES_FOUND'; matchingQuestionsCount: number }
  | { type: 'OPENED_MATCHES_NOT_FOUND' }
  | { type: 'OPENED_STARTING_AUTOCOMPLETE'; matchingQuestionsCount: number }
  | {
      type: 'OPENED_AUTOCOMPLETE_IN_PROGRESS';
      progress: AutocompleteProgressType;
    }
  | {
      type: 'OPENED_AUTOCOMPLETE_FINISHED';
      questionsAutofilledCount: number;
      questionsSuggestedCount: number;
    }
  | { type: 'OPENED_ALL_QUESTIONS_FILLED' };

export interface AttachmentModalType {
  isOpen: boolean;
  shouldSubmit: boolean;
}

export enum NavigationContextEnum {
  ANSWER = 'answer',
  SEND = 'send',
}

export enum FixableFieldEnum {
  CODE = 'code',
  TEXT = 'text',
  CONDITION = 'condition',
}

export type FactorWithIssues = Factor & { issues: IssueType[] };

export type StatusFlagType = 'success' | 'info' | 'warning' | 'error';

export type TagTypeType = 'VENDOR' | 'VRM' | 'NORMAL';

export type StandardBranchType = 'system' | 'custom' | 'all';

/**
 * type StandardBranchType is used in getStandards.ts but is also used in redux store
 * to create particular branches of state. Extending it breaks typing of old code.
 * That's why another type solely for status query param (when querying standards)
 * is needed as it can be extended with other values without breaking old code.
 * This is necessary because FormDefinitionsPage now also needs to fetch standards,
 * but the API has been extended to accept 'draft' as a value for this query param.
 */
export type StandardStatusType = 'system' | 'custom' | 'draft';

export type StandardSortType = 'all' | 'most_recent' | 'most_sent';

export type Uuid = string;

export interface QuestionDefinitionInvalidEntityErrorSummary {
  questionUuid: Uuid;
  errors: QuestionError[];
}

export interface ResponseDefinitionInvalidEntityErrorSummary {
  questionUuid: Uuid;
  responseUuid: Uuid;
  errors: ResponseError[];
}

export interface FormDefinitionValidationType {
  /**
   * Set of UUIDs of questions and responses that were changed since last
   * validation API call.
   *
   * Typically you will simply save `true` when the fix was attempted at the
   * entity, but in some cases you might want to store specific attempted
   * fields fixes. In this case you may want to save e.g.:
   * `{ text: true, code: true }`
   * to mark attempts to fix question text and code...
   */
  attemptedFixes: Record<Uuid, true | Record<FixableFieldEnum, true>>;
  formErrors: FormDefinitionError[];
  invalidQuestions: QuestionDefinitionInvalidEntityErrorSummary[];
  invalidResponses: ResponseDefinitionInvalidEntityErrorSummary[];
  isLoading: boolean;
  correct: boolean;
}

type functionMap = (...args: any[]) => void;

type objectOfFunctionMap = { [key: string]: functionMap | undefined };
export interface FunctionsMapType {
  [key: string]: functionMap | objectOfFunctionMap | undefined;
}

export interface EventRegisterSummaryType {
  nextEventToken: string;
}

export interface FlashType {
  id: Uuid;
  message: string;
  status: StatusFlagType;
}

export interface MappingType {
  isMapping: boolean;
  questionsUpdated?: number;
  hasError?: boolean;
  updatedQuestionsIds?: Uuid[];
}

export interface ParsingType {
  hasError: boolean;
  parsingMessages: ParsingMessageType[];
}

export interface UploadType {
  fileName: string;
  formId: Uuid | null;
  hasError: boolean;
  id: Uuid;
  isUploading: boolean;
  mapping: MappingType | null;
  parsing: ParsingType;
  questionsCount: number;
  status: UploadStatusEnum;
}

export enum UploadStatusEnum {
  ACCEPTED = 1,
  FINISHED,
  PARSING,
  PARSING_SUCCEEDED,
  PARSING_FAILED,
  PARSING_EMPTY_RESULT,
  MAPPING,
  MAPPING_SUCCEEDED,
  MAPPING_FAILED,
}

export interface CategoryDelegationType {
  assignee: UserType | undefined;
  quantity: CategoryDelegationStatsQuantityEnum;
  scope: CategoryDelegationStatsScopeEnum;
}

export interface CategoryReviewStatusType {
  scope: VRMReviewStatsScopeEnum;
  status?: VRMReviewStatsStatusEnum;
}

export interface CategoryType {
  delegation: CategoryDelegationType;
  name: string;
  pos: number;
  questionsCount: number;
  questionsFilledCount: number;
  questionsIgnoreFilterCount: number;
  questionsIgnoreVisibilityCount: number;
  questionsTotalVisibleCount: number;
  reviewStatus: CategoryReviewStatusType;
}

export interface PromptInviteDelegationType {
  category?: CategoryType;
  form?: FormType;
  question?: QuestionType;
}

export interface CompanyType {
  domain: string;
  grade: CompanyGradeEnum;
  gradeUrl: string;
  id: Uuid;
  industry: string;
  internalStatus: CompanyInternalStatusEnum;
  isVerified: boolean;
  logo: string;
  name: string;
  requestsEmail: string;
  score: number;
}

export interface CompanyWithTagsType {
  info: CompanyType;
  tags: TaggedType[];
}

export interface CompanySimpleType {
  grade: CompanySimpleGradeEnum;
  score: number;
  gradeUrl: string;
  id: Uuid;
  name: string;
  internalStatus: CompanySimpleInternalStatusEnum;
  isVerified: boolean;
}

export interface CurrentCompanyType extends CompanyType {
  /**
   * All teams in the company.
   * - value == null: List of teams was not downloaded yet.
   * - value == []: List of teams was downloaded and is empty.
   */
  teams: Team[] | null;
}

export type DueDateKindType =
  | 'okay'
  | 'close'
  | 'almost'
  | 'notice'
  | 'warning'
  | 'danger';

export interface FrameworkType {
  id: Uuid;
  name: string;
  description: string;
  displayName: string;
  questionsCount: number;
  questionsFilledCount: number;
  percentageCompleted: number;
  standard: StandardType;
  hasEvidenceLockerEntity?: boolean;
  ownerId: string;
}

export type FrameworkCategoryNameType = 'system' | 'custom';

export const FRAMEWORK_CATEGORIES_ORDER: FrameworkCategoryNameType[] = [
  'system',
  'custom',
];

export interface FrameworksDict {
  [key: string]: FrameworkType[];
}

export interface GroupedFrameworks {
  items: FrameworksDict;
  count: number;
}

export interface AttachmentType {
  id: Uuid;
  createdAt: Date;
  displayName: string;
  fileSize: number;
  name: string;
  responseId?: Uuid;
  uploadingId?: Uuid;
}

export interface MappingHistoryType {
  action: ResponseFillingHistoryActionEnum;
  createdAt: Date;
  message: string;
  score: number;
}

export interface MappingRequestType {
  createdAt: Date;
  updatedAt: Date;
  id: Uuid;
  parsedFiles: ParsedFileType[];
  status: number;
  updatedQuestionsIds: Uuid[];
  userId: Uuid;
}

export interface OptionsInputPropsType {
  attachments?: AttachmentsDict;
  areAttachmentsModifiable: boolean;
  hasBeenTouched?: boolean;
  formId: Uuid;
  id: Uuid;
  isEditable: boolean;
  isRequired?: boolean;
  label?: string;
  options?: string[];
  placeholder?: string;
  questionId: Uuid;
  responseType?: string;
  value: string;
}

export interface ResponseDefinitionType {
  id: Uuid;
  label: string;
  options: string[];
  placeholder: string;
  responseType: ResponseTypeDefinitionResponseTypeEnum;
}

export interface AttachmentsDict {
  [key: string]: AttachmentType;
}

export interface MessageType {
  id: Uuid;
  company: CompanyType;
  createdAt: Date;
  postingId?: Uuid;
  isMine: boolean;
  isInternal: boolean;
  text: string;
  updatedAt: Date;
  user: UserType;
}

export interface MessagesDict {
  [key: string]: MessageType;
}

export interface ResponseDefinitionSummaryType {
  definition: ResponseDefinitionType;
  id: Uuid;
  isRequired: boolean;
  pos: number;
  uniqueId: Uuid;
}

export interface ResponseType {
  attachments: AttachmentsDict;
  definition: ResponseDefinitionType;
  id: Uuid;
  isFilled: boolean;
  isRequired: boolean;
  mappingHistory?: MappingHistoryType;
  updatedAt: Date;
  value: string;
}

export interface ResponsesDict {
  [key: string]: ResponseType;
}

export enum HistoryTypeEnum {
  ATTACHMENT = 'ATTACHMENT',
  COMMENT = 'COMMENT',
  RESPONSE = 'RESPONSE',
  SSCDATA = 'SSC_DATA',
}

export interface AttachmentAuditType {
  id: Uuid;
  action: string;
  filename: string;
  size: number;
}

export interface CommentAuditType {
  id: Uuid;
  text: string;
}

export interface ResponseAuditType {
  id: Uuid;
  label: string;
  response: string;
}

export interface SscDataDiffType {
  addedFactors: string[];
  addedIssues: string[];
  removedFactors: string[];
  removedIssues: string[];
  updatedFactors: string[];
  updatedIssues: string[];
}

export interface SscDataAuditType {
  id?: Uuid;
  diff: SscDataDiffType;
  factors: SscDataFactorType[];
}

export type HistoryAuditType =
  | AttachmentAuditType
  | CommentAuditType
  | ResponseAuditType
  | SscDataAuditType;

export interface QuestionHistoryType {
  id: Uuid;
  createdAt: Date;
  type: HistoryTypeEnum;
  value: HistoryAuditType;
  user: UserType;
}

export enum SeverityTypeEnum {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  POSITIVE = 'positive',
  INFO = 'info',
  UNKNOWN = 'unknown',
}

export interface SscDataIssueType {
  detailUrl: string;
  issueCount: number;
  title: string;
  type: string;
  severity: SeverityTypeEnum;
}

export interface SscDataFactorType {
  key: string;
  description: string;
  grade: string;
  issues: SscDataIssueType[];
  score: number;
  title: string;
}

export type QuestionBlockType =
  | QuestionTabEnum.ATTACHMENTS
  | QuestionTabEnum.COMMENTS
  | QuestionTabEnum.HISTORY
  | QuestionTabEnum.SSC_DATA;

export enum QuestionTabEnum {
  ATTACHMENTS = 'attachments',
  COMMENTS = 'comments',
  HISTORY = 'history',
  LOOKUP_LENS = 'lookup_lens',
  SSC_DATA = 'ssc_data',
  REVIEWER_INSTRUCTIONS = 'reviewer_instructions',
}

export const QuestionExternalTabs = [
  QuestionTabEnum.SSC_DATA,
  QuestionTabEnum.LOOKUP_LENS,
];

export interface QuestionTabType {
  label: QuestionTabEnum;
  icon: IconDefinition | QuestionTabEnum.SSC_DATA;
  enabled: boolean;
}

export interface DelegationType {
  assignee: UserType;
  assignor: UserType;
  delegationId: Uuid;
  ownerId: Uuid;
  questionId: Uuid;
}

export interface MatchType {
  sourceId: Uuid;
  targetId: Uuid;
  matchCode: string;
  question: QuestionType;
  formNames: string[];
  value: string;
}

export interface QuestionMappingsType {
  id: Uuid;
  name: string;
  question: QuestionType;
  score: number;
  matches: MatchType[];
  isRated?: boolean;
}

export interface QuestionBlocksType {
  data: QuestionDataBlocks;
  meta: QuestionMetadataBlocks;
}

export interface QuestionType {
  id: Uuid;
  attachments: AttachmentsDict;
  areAttachmentsModifiable: boolean;
  blocks?: QuestionBlocksType;
  category: string;
  code: string;
  createdAt: Date;
  delegations: DelegationType[];
  formId: Uuid;
  isVisible: boolean;
  history: QuestionHistoryType[];
  mappings?: QuestionMappingsType[];
  messages: MessagesDict;
  meta: {
    areResponsesVisible: boolean;
    hasMessages: boolean;
  };
  pos: number;
  question: string;
  responses: ResponsesDict;
  responsesCount: number;
  responsesFilledCount: number;
  reviewStatus: QuestionDetailBaseVrmReviewStatusEnum;
  reviewInstructions?: string;
  selectedTab: QuestionTabEnum;
  sscData: SscDataFactorType[];
  updatedAt: Date;
}

export interface QuestionsDict {
  [key: string]: QuestionType;
}

export interface FormMetaType {
  hasCategories: boolean;
  isEditable: boolean;
  isUploadedFromUser: boolean;
  wasAlreadyOpenedByVendor: boolean;
  permissions: FormDetailPermissionsEnum[];
  questionsLeftCount: number;
}

export type FileType = {
  extension: string;
  fileSize: string;
  id: Uuid;
  inLocker: boolean;
  isUploading: boolean;
  name: string;
  ownerId: Uuid;
  updatedAt: Date;
  uploaderId: Uuid;
  url: string;
  hasEvidenceLockerEntity: boolean;
};

export type DelegationNotificationsDirectionType = 'backward' | 'forward';

export interface DelegationNotificationsSubType {
  count: number;
  users: UserType[];
}

export interface DelegationNotificationsType {
  backward: DelegationNotificationsSubType;
  forward: DelegationNotificationsSubType;
}

export interface FormAutofillStatsType {
  fillableQuestionsCount: number;
  fillableResponsesCount: number;
  updatedQuestionsCount: number;
  updatedQuestionsIds: Uuid[];
}

export interface FormDefinitionType {
  createdAt: Date;
  updatedAt: Date;
  description: string;
  id: Uuid;
  name: string;
  ownerId: Uuid;
  standardId: Uuid;
  withConditionCount: number;
}

export interface FormDefinitionDraftCategoryStatsType {
  name: string;
  pos: number;
  questionCount: number;
}

export interface FormCreatorValidatedResponseType
  extends ResponseDefinitionSummaryType {
  errors: ResponseError[];
  /** See FormDefinitionValidationType.attemptedFixes */
  fixAttempted: boolean | Record<FixableFieldEnum, true>;
}

export interface FormCreatorValidatedQuestionType
  extends QuestionDefinitionSummaryType {
  errors: QuestionError[];
  /** See FormDefinitionValidationType.attemptedFixes */
  fixAttempted: boolean | Record<FixableFieldEnum, true>;
  /** True if all question'responses with an ERROR were attempted to be fixed  */
  fixAttemptedInAllResponses: boolean;
  hasErrors: boolean;
  /** True if any of the question's responses has an error */
  hasResponseErrors: boolean;
  responses: FormCreatorValidatedResponseType[];
  summaryValidationStatus: ValidationStatus;
}

export interface FormCreatorRenderType
  extends FormDefinitionDraftCategoryStatsType {
  hasErrors: boolean;
  questions: FormCreatorValidatedQuestionType[];
}

export interface FormDefinitionSummaryDraftExtendedType {
  createdAt: Date;
  updatedAt: Date;
  description: string;
  id: Uuid;
  name: string;
  ownerId: Uuid;
  standard: StandardType;
  withConditionCount: number;
  categories: FormDefinitionDraftCategoryStatsType[];
  questions: QuestionDefinitionSummaryType[];
  nextEventToken: string;
  creationStatus:
    | 'FINISHED_SUCCESS'
    | 'FINISHED_FAIL'
    | 'IN_QUEUE'
    | 'IN_PROGRESS';
}

export interface FormPermissionsType {
  canSendDelegationInvitation: boolean;
  canInviteProxy: boolean;
  isDelegable: boolean;
  isFormAttachmentDeletable: boolean;
  isFormAttachmentReadable: boolean;
  isFormAttachmentWritable: boolean;
  isFormAutocompletable: boolean;
  isFormAutofillable: boolean;
  isFormCommentReadable: boolean;
  isFormCommentWritable: boolean;
  isFormReviewable: boolean;
  isFormSubmittable: boolean;
  isLookupLensesReadable: boolean;
  isQuestionAttachmentDeletable: boolean;
  isQuestionAttachmentReadable: boolean;
  isQuestionAttachmentWritable: boolean;
  isQuestionCommentReadable: boolean;
  isQuestionCommentWritable: boolean;
  isResponseEditable: boolean;
  isResponseReadAskable: boolean;
  isResponseReadApprovable: boolean;
  isResponseReadable: boolean;
  isResponseHistoryReadable: boolean;
  isReviewInstructionsReadable: boolean;
  isReviewReadable: boolean;
  isReviewWritable: boolean;
  isRiskReportReadable: boolean;
  isRiskReportWritable: boolean;
  isRiskReportDeletable: boolean;
  isSscScoreReadable: boolean;
  isVRM: boolean;
  isVendor: boolean;
}

export interface FormType {
  attachments: AttachmentsDict;
  autocompleteExecutions: AutocompleteExecution[];
  autofillStats: FormAutofillStatsType;
  categories?: CategoryType[];
  createdAt: Date;
  delegationNotifications: DelegationNotificationsType;
  description: string;
  displayName: string;
  fillingType: FormDetailFillingTypeEnum;
  id: Uuid;
  initializationStatus: FormDetailInitializationStatusEnum;
  isScoreVisible: boolean;
  isSubmittable: boolean;
  mappingRequests: MappingRequestType[];
  meta: FormMetaType;
  name: string;
  owner: CompanyType;
  percentageFilled: number;
  permissions: FormPermissionsType;
  questions: QuestionsDict;
  questionsCount: number;
  questionsFilledCount: number;
  questionsFilteredCount: number;
  questionsPartiallyFilledCount: number;
  request?: RequestType;
  responsesCount: number;
  responsesFilledCount: number;
  score: number;
  standard: StandardType;
  updatedAt: Date;
}

export interface FormHistoryType {
  action: FormHistoryActionEnum;
  createdAt: Date;
  from?: FormHistoryDataFromEnum;
  to?: FormHistoryDataToEnum;
  user: UserType;
}

export interface FormAssignee {
  createdAt: Date;
  updatedAt: Date;
  companyId: Uuid;
  displayName: string;
  domain: string;
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  id: Uuid;
  isActive: boolean;
  isProxy: boolean;
}

export interface AutocompleteCompanyType {
  label: string;
  name: string;
  value: Uuid;
}

export interface ListedCompanyType {
  company: CompanyWithTagsType;
  requestsReceivedCount: number;
  requestsSentCount: number;
  lastRequestSentDate: Date;
  lastRequestSubmittedDate: Date;
  nextRequestScheduledSend: Date;
}

export interface CreditsListItemType {
  expiresAt: Date;
  isExpired: boolean;
  remaining: number;
}

export interface CreditsHistoryItemType {
  amount: number;
  createdAt: Date;
}

export interface CompanyCreditsType {
  history: CreditsHistoryItemType[];
  list: CreditsListItemType[];
  summary: CreditsSummary | null;
}

export interface CompanyDetailType {
  company: CompanyWithTagsType;
  requests: {
    in: RequestType[];
    out: RequestType[];
  };
  completedStandards: CompletedStandardType[];
}

export interface CompanySettingType {
  ml_autocomplete_threshold?: string;
  ml_enabled?: boolean;
  public_standards?: boolean;
  requests_email?: string;
  logo?: string;
}

export interface CompanyLogoType {
  logo?: string;
}

export interface ValueLabelType<V, L = string> {
  value: V;
  label: L;
}

export type ParsingMessageSeverityType =
  | ParsingMessageSeverityEnum.ERROR
  | ParsingMessageSeverityEnum.WARNING
  | ParsingMessageSeverityEnum.INFO
  | 'SUCCESS'
  | 'PROCESSING';

export interface ParsingMessageType {
  id: Uuid;
  severity: ParsingMessageSeverityType;
  message: string;
  messageKey: string;
}

export interface ParsedFileType {
  fileName: string;
  formId: Uuid | null;
  hasError?: boolean;
  parsingMessages: ParsingMessageType[];
  status?: UploadStatusEnum;
}

export interface QuestionAttachmentType {
  id: Uuid;
  form_id: Uuid;
  name: string;
  owner_id: Uuid;
  question_id: Uuid;
  response_id?: Uuid;
}

export enum QuestionDefinitionSummaryTypeEnum {
  NO_RESPONSE = 'no_response',
  QUESTION = 'question',
}

export interface StructuredConditionLine {
  id: Uuid;
  operator: QuestionConditionStructuredItemOperatorEnum;
  questionUuid: Uuid;
  responseUuid: Uuid;
  value: string;
}

export interface StructuredCondition {
  operator: QuestionConditionStructuredOperatorEnum;
  items: StructuredConditionLine[];
}

export type Condition =
  | {
      type: 'structured';
      payload: StructuredCondition;
    }
  | {
      type: 'text';
      payload: string;
    };

export interface ConditionBackupType {
  structured: StructuredCondition;
  text: string;
}

export interface QuestionDefinitionSummaryType {
  // WARNING: After adding a new field, make sure you also check the
  //          duplicateQuestion action so that you still copy all fields you
  //          need.
  active: boolean;
  category: string;
  code: string;
  /**
   * Backup of the structured condition useful in scenarios when user swiches
   * from structured condition to text condition, makes no changes and switches
   * back to structured.
   */
  conditionBackup?: ConditionBackupType;
  condition?: Condition;
  pos: number;
  question: string;
  responses: ResponseDefinitionSummaryType[];
  sscIssues: string[];
  wizardId: Uuid;
}

export interface RequestFindingType extends RequestFinding {
  optimisticId?: Uuid;
}

export interface RequestControlsStatusType {
  canArchive: boolean;
  canClearNotifications: boolean;
  canDelete: boolean;
  canMarkRead: boolean;
  selectedIds: Uuid[];
  selectedRequests: RequestType[];
}

export interface FormDefinitionControlsStatusType {
  canDelete: boolean;
}

export type RequestDirectionType = 'received' | 'sent';

export type RequestVrmViewStatusEnum =
  | RequestDetailVrmViewStatusEnum
  | RequestSummaryVrmViewStatusEnum;
export type RequestFormRolesEnum =
  | RequestDetailFormRolesEnum
  | RequestSummaryFormRolesEnum;
export type RequestStatusEnum =
  | RequestDetailStatusEnum
  | RequestSummaryStatusEnum;

export interface RequestType {
  acceptedDate?: Date;
  completionDays: number | null;
  dueDate?: Date;
  findings?: RequestFindingType[];
  form: {
    filled: number;
    id: Uuid;
    isScoreVisible: boolean;
    name: string;
    partiallyFilled: number;
    percentageFilled: number;
    responseCount: number;
    responsesFilledCount: number;
    score: number;
    total: number;
  };
  id: Uuid;
  isInternal: boolean;
  isVrmStatusChangeable: boolean;
  message: string;
  meta: {
    canViewData: boolean;
    company: string;
    companyId?: Uuid;
    completedPercentage: number;
    email: string;
    grade?: string;
    gradeUrl?: string;
    isArchivable?: boolean;
    isProxy: boolean;
    isVerified: boolean;
    isVisible: boolean;
    isVendor: boolean;
    isVRM: boolean;
    questionsLeftCount: number;
    receiver: string;
    receiverCompanyOrTeam: string;
    sender: string;
    senderCompanyOrTeam: string;
    statusBeforeArchive: string;
  };
  requestedAt: Date;
  revisionCount: number;
  riskNote: string;
  source: CompanyType;
  sourceTeam?: Team;
  status: RequestStatusEnum;
  standard: StandardType;
  target: CompanyType;
  targetUser: UserType;
  targetTeam?: Team;
  updatedAt: Date;
  targetOpenedAt?: Date;
  vrmViewStatus: RequestVrmViewStatusEnum;
}

export interface RequestDetailType extends RequestType {
  hasNewAttachments: boolean;
  hasNewMessages: boolean;
  hasNewResponses: boolean;
  hasNewSscData: boolean;
  isRead: boolean;
}

export interface RequestSimpleType {
  id: Uuid;
  isInternal: boolean;
  completionDays: number | null;
  source: CompanySimpleType;
  status: StatusEnum;
  standard: StandardSimpleType;
  target: CompanySimpleType;
  targetUser: UserSimpleType;
  updatedAt: Date;
  dueDate?: Date;
  acceptedDate?: Date;
  vrmViewStatus: VrmViewStatusEnum;
  hasNewAttachments: boolean;
  hasNewMessages: boolean;
  hasNewResponses: boolean;
  hasNewSscData: boolean;
  isRead: boolean;
  isVrmStatusChangeable: boolean;
  requestedAt: Date;
  revisionCount: number;
  form: {
    filled: number;
    id: Uuid;
    isScoreVisible: boolean;
    name: string;
    partiallyFilled: number;
    percentageFilled: number;
    responseCount: number;
    responsesFilledCount: number;
    revisionCount: number;
    score: number;
    total: number;
  };
  meta: {
    companyId?: Uuid;
    canViewData: boolean;
    gradeUrl?: string;
    isVerified: boolean;
    isVisible: boolean;
    company: string;
    email: string;
    receiver: string;
    receiverCompanyOrTeam: string;
    sender: string;
    senderCompanyOrTeam: string;
    isArchivable?: boolean;
    statusBeforeArchive: string;
    completedPercentage: number;
    isVRM: boolean;
    isVendor: boolean;
  };
}

export interface LanguageType {
  label: string;
  value: string;
}

export interface ScheduledRequestType {
  emailBody: string;
  id: Uuid;
  isCancelled: boolean;
  isInternal: boolean;
  meta: {
    receiver: string;
    receiverCompanyOrTeam: string;
    sender: string;
  };
  nextSendDate: Date;
  occurrences?: number;
  receiver: string;
  recurrenceType?: ScheduledRequestRecurrenceTypeEnum | null;
  recurrenceUnits?: number | null;
  sender: string;
  source: {
    email: string;
    id: Uuid;
    name: string;
  };
  standard: StandardType;
  target: CompanyType;
}

export enum DeleteIntervalEnum {
  NEXT = 'NEXT',
  ALL = 'ALL',
}

export interface ScheduledRequestsDict {
  [key: string]: ScheduledRequestType[];
}

export interface GroupedScheduledRequests {
  count: number;
  items: ScheduledRequestsDict;
  companies: string[];
}

export interface SharedFormType {
  formName?: string;
  owner: CompanyType;
  sharedBy: UserType;
}

export enum DuplicateStateEnum {
  HAS_SCORING = 'scoring',
}

export enum UnpublishStateEnum {
  HAS_SCORING = 'scoring',
  IS_SENT = 'sent',
}

export interface StandardType {
  id: Uuid;
  code: string;
  deletable?: boolean;
  description: string;
  logo: string;
  name: string;
  previewFormId?: Uuid;
  definitionId?: Uuid;
  duplicateState?: DuplicateStateEnum;
  unpublishState?: UnpublishStateEnum;
  isDuplicable?: boolean;
  isProcessing?: boolean;
  isUnpublishable?: boolean;
  status: StandardStatusEnum;
  version: string;
  year: number;
  updatedAt: Date;
}

export interface CompletedStandardType extends StandardType {
  isAlreadyRequested: boolean;
}

export interface StandardSimpleType {
  version: string;
  year: number;
  name: string;
}

export interface TaggedType {
  id: Uuid;
  targetId: Uuid;
  tag: TagType;
}

export interface TagType {
  id: Uuid;
  name: string;
  type: TagTypeType;
}

export interface UploadedFileNamesType {
  error: string[];
  success: string[];
}

export interface UserStatsType {
  logins: number;
  isNewVendor: boolean;
  archivedRequests: {
    received: number;
    sent: number;
  };
  requests: {
    received: number;
    sent: number;
  };
  frameworks: {
    questions: {
      filledCount: number;
      partiallyFilledCount: number;
      totalCount: number;
      percentageCompleted: number;
    };
    responses: {
      filledCount: number;
      totalCount: number;
      percentageCompleted: number;
    };
  };
}

export interface UserType {
  company: CurrentCompanyType;
  credits?: CompanyCreditsType;
  displayName: string;
  domain: string;
  email: string;
  firstName: string;
  id: Uuid;
  internalStatus?: UserDetailInternalStatusEnum;
  isActive: boolean;
  lastName: string;
  roles: UserDetailRolesEnum[];
  stats: UserStatsType;
  teams: Team[];
  toggles: TogglesDict;
  ldToggles: TogglesDict;
}

export interface CurrentUserType extends UserType {
  isAuthenticated: boolean;
  isCustomerAdmin: boolean;
  isGuest: boolean;
  isNormal: boolean;
  isSales: boolean;
  isSystemAdmin: boolean;
  navigationContext: NavigationContextEnum;
  selectedTeam?: Team;
}

export interface UserSimpleType {
  email: string;
  id: Uuid;
}

export type TogglesDict = { [key in ToggleEnum]: boolean };

export enum ToggleEnum {
  CREDITS_CTA_MODAL = 'credits-cta-modal',
  EMAIL_COBRANDING = 'email-cobranding',
  FORM_CREATOR_CONDITIONS = 'form_creator_conditions',
  FORM_CREATOR_ISSUES = 'form-creator-issues',
  INTERNAL_USECASE = 'internal-usecase',
  INTERNAL_AUTOCOMPLETE = 'internal-autocomplete',
  REVIEWER_INSTRUCTIONS = 'reviewer-instructions',
  TEST_FLAG = 'test-flag',
  SPECIAL_FORM_ACCEPTANCE = 'special-form-acceptance',
  LANGUAGE_PREFERENCES = 'is-language-preference-required',
  EMAIL_FILE_UPLOAD = 'is-email-file-upload-required',
  HIDE_SSC_DATA = 'hide-ssc-data',
  GENERATE_LINK_WHILE_SENDING_QUESTIONNAIRES = 'generate-link-while-sending-questionnaires',
  SYSTEM_TEMPLATE_UPLOAD = 'UPLOAD_SYSTEM_TEMPLATE',
  ATLAS_UI_MIGRATION_FE = 'ATLAS_UI_MIGRATION_FE',
  ATLAS_MAINTENANCE_BANNER = 'ATLAS_MAINTENANCE_BANNER',
}

export enum NotificationEnum {
  ALMOST_OUT = 'almostOut',
  GRANTED = 'granted',
  NO_MORE_CREDITS = 'noMoreCredits',
  WELCOME = 'welcome',
  WILL_EXPIRE = 'willExpire',
  DISMISS_OPT_IN = 'dismissOptInPopUp',
}

export interface PricingType {
  label: string;
  price: number;
  discount: number;
  minQuantity: number;
}

export interface FormProxyDetailType {
  id: Uuid;
  canBeNotified: boolean;
  canBeRevoked: boolean;
  formRole: ProxyShortSummaryFormRoleEnum;
}

export interface FormCollaboratorType {
  id: Uuid;
  canAnswer: boolean;
  canReview: boolean;
  canBeRevoked: boolean;
  isProxy: boolean;
  proxies: FormProxyDetailType[];
  user: UserType;
}

export interface AverageChartRecordType {
  companies: Set<Uuid>;
  count: number;
  maximum: number;
  sum: number;
  total: number;
}

export type IdOrEmailType =
  | {
      type: 'id';
      payload: Uuid;
    }
  | {
      type: 'email';
      payload: string;
    }
  | {
      // This member will make sure you cannot read .payload directly without checking the type.
      type: 'never';
      payload: never;
    };

export interface MailingListItemType {
  label: string;
  value: string;
}

export interface RecurrenceItemType extends ValueLabelType<string> {
  type?: ScheduledRequestRecurrenceTypeEnum;
  occurrences?: number;
  unit?: number;
}

export interface TeamsItemType extends ValueLabelType<string> {
  members: string[];
}

export interface GroupedValueLabelType<V, L = string> {
  label: L;
  options: V;
}

export type GroupedQuestionItemType = GroupedValueLabelType<
  FormCreatorValidatedQuestionType[]
>;

export type ReminderItemType = ValueLabelType<number>;

export type TargetItemType = ValueLabelType<string>;

export type CompanyItemType = ValueLabelType<string>;

export type LanguagePreferenceType = ValueLabelType<string>;

export interface TranslatePropsType {
  i18n: i18n;
  t: TFunction;
  tReady: boolean;
}

export enum UpdateResponseSourceEnum {
  MANUAL = 'MANUAL',
  ANSWER_LOOKUP_LENSES = 'ANSWER_LOOKUP_LENSES',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  MERGE = 'MERGE',
}
