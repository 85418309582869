import app from './app/app.reducer';
import { downloads } from './downloadItems/downloadItems.reducer';
import optimisticReducer from '../utils/optimistic/optimistic.reducer';
import requests from './requests/requests.reducer';
import settings from './settings/settings.reducer';
import standard from './standard/reducer';
import stripe from './stripe/stripe.reducer';
import switchers from './switchers/switchers.reducer';
import ui from './ui/ui.reducer';
import user from './user/user.reducer';
import formDefinitions from './formDefinitions/formDefinitions.reducer';
import formNewFromExisting from './formNewFromExisting/formNewFromExisting.reducer';
import { ActionType, StateType } from './store.types';
import { ApiCallSuccessActionType } from './app/actions/apiCallSuccess';
import { ErrorActionType } from './app/actions/error';
import { combineReducers } from 'redux';
import { companies } from './companies/companies.reducer';
import { connectRouter } from 'connected-react-router';
import { flashes } from './flashes/flashes.reducer';
import { formCreator } from './formCreator/formCreator.reducer';
import { formProxy } from './formProxy/formProxy.reducer';
import { forms } from './forms/forms.reducer';
import { modals } from '../utils/Modal';
import {
  OptimisticUpdateActionType,
  RealityCheckActionType,
} from '../utils/optimistic/optimistic.types';

const appReducers = (history: any) =>
  combineReducers<StateType, ActionType>({
    app,
    downloads,
    companies,
    flashes,
    formCreator,
    forms,
    formDefinitions,
    formNewFromExisting,
    formProxy,
    modals,
    optimisticTransactions: (state = {}) => state,
    requests,
    router: connectRouter(history),
    settings,
    standard,
    stripe,
    switchers,
    ui,
    user,
  });

const createReducers = (history: any) => (
  state: StateType,
  action: ActionType,
): StateType => {
  if (action.type === '@app/API_CALL_SUCCESS' || action.type === '@app/ERROR') {
    const {
      payload: { data, domain, selector, meta },
    } = action as ErrorActionType | ApiCallSuccessActionType;

    return selector
      ? {
          ...state,
          [domain]: {
            ...state[domain],
            ...selector(data, state, meta),
          },
        }
      : state;
  }

  if (
    action.type === '@optimistic/OPTIMISTIC_UPDATE' ||
    action.type === '@optimistic/REALITY_CHECK'
  ) {
    return optimisticReducer(
      state,
      action as OptimisticUpdateActionType | RealityCheckActionType,
    );
  }

  return appReducers(history)(state, action);
};

export default createReducers;
