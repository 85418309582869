import {
  AnchorLeft,
  AnchorRight,
  ClearAll,
  Closer,
  Content,
} from './FilterBy.styles';
import {
  defaultFilter,
  FilterByType,
  FilterOptionsType,
  FilterType,
  isFilterEnabled,
} from 'utils/filters/requests.filters';
import { CloseIcon } from '../../common/Overlay';
import { Dropdown } from '../../common/Dropdown';
import { DropdownState } from '../../common/Dropdown/Dropdown';
import FilterCompanies from './FilterBy.Companies';
import FilterDate from './FilterBy.Date';
import FilterEmails from './FilterBy.Emails';
import FilterStandards from './FilterBy.Standards';
import FilterStatus from './FilterBy.Status';
import FilterInternal from './FilterBy.Internal';
import FilterNumberOfReviews from './FilterBy.NumberOfReviews';
import React, { useCallback } from 'react';
import { any } from 'ramda';
import { RequestsSetFilterFunctionType } from 'store/requests/actions';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

export interface FilterByPropsType extends TranslatePropsType {
  data: {
    allowedFilters: FilterByType[];
    anchor?: 'left' | 'right';
    columns?: number;
    filterBy: FilterType;
    filterOptions: FilterOptionsType;
    isClearable?: boolean;
    isSent: boolean;
  };
  actions: {
    setFilter: RequestsSetFilterFunctionType;
  };
  teamsEnabled?: boolean;
}

const optionTypes: {
  COMPANIES: any;
  DATE: any;
  EMAILS: any;
  STANDARDS: any;
  STATUS: any;
  INTERNAL?: any;
  WITH_REVISIONS: any;
} = {
  COMPANIES: FilterCompanies,
  DATE: FilterDate,
  EMAILS: FilterEmails,
  STANDARDS: FilterStandards,
  STATUS: FilterStatus,
  INTERNAL: FilterInternal,
  WITH_REVISIONS: FilterNumberOfReviews,
};

const FilterBy = ({
  data,
  data: {
    allowedFilters,
    anchor = 'left',
    columns = 2,
    filterBy,
    isClearable = false,
  },
  actions,
  actions: { setFilter },
  teamsEnabled,
  t,
}: FilterByPropsType) => {
  const isFilterSet = any(
    option => isFilterEnabled(filterBy[option], option),
    allowedFilters,
  );

  const clearFilters = useCallback(() => setFilter(defaultFilter), [setFilter]);

  // Temporary measure to remove the Internal filter option if the teams feature flag is disabled
  // This will be removed when the feature has a GA release
  if (!teamsEnabled) {
    delete optionTypes.INTERNAL;
  }

  return (
    <Dropdown
      color={isFilterSet ? 'teal' : 'transparent'}
      isRelative={anchor !== 'right'}
      size="medium"
      text={t('Requests.filterBy')}
      childrenWrapper={anchor === 'left' ? AnchorLeft : AnchorRight}
    >
      {({ close }: DropdownState) => (
        <>
          <Content columns={columns}>
            <Closer onClick={close}>
              <CloseIcon />
            </Closer>

            {allowedFilters
              .filter(fo => !!optionTypes[fo])
              .map(option => {
                const Component = optionTypes[option];
                return (
                  <div
                    data-test={`filter-by-${option}`.toLowerCase()}
                    key={option}
                  >
                    <Component key={option} data={data} actions={actions} />
                  </div>
                );
              })}
          </Content>
          {isClearable && (
            <ClearAll color="teal" onClick={clearFilters} size="small">
              {t('common:labels.clearAll') as string}
            </ClearAll>
          )}
        </>
      )}
    </Dropdown>
  );
};

export default withTranslation(['components', 'common'])(FilterBy);
