/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestStatusStats,
    RequestStatusStatsFromJSON,
    RequestStatusStatsFromJSONTyped,
    RequestStatusStatsToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface RequestStatusStatsResponse
 */
export interface RequestStatusStatsResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof RequestStatusStatsResponse
     */
    msg: string;
    /**
     * 
     * @type {RequestStatusStats}
     * @memberof RequestStatusStatsResponse
     */
    entry: RequestStatusStats;
}

export function RequestStatusStatsResponseFromJSON(json: any): RequestStatusStatsResponse {
    return RequestStatusStatsResponseFromJSONTyped(json, false);
}

export function RequestStatusStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestStatusStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': RequestStatusStatsFromJSON(json['entry']),
    };
}

export function RequestStatusStatsResponseToJSON(value?: RequestStatusStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': RequestStatusStatsToJSON(value.entry),
    };
}


