import styled from 'styled-components';
import Gauge from './Gauge';

import { HorizontallyJustified } from 'common/common.styles';

export const Gauges = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
`;

export const Metadata = styled.div`
  flex-basis: 100%;
  margin-bottom: 2%;
  padding: 0 45px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const ChartGauge = styled(Gauge)`
  flex-basis: 32%;
  padding: 28px;

  @media print {
    height: fit-content;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export const AverageGauge = styled(Gauge)`
  flex-basis: 32%;
  margin-bottom: 2px;
  padding: 28px;

  @media print {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }
`;

export const ExportDetails = styled.div`
  display: none;
  width: 100%;

  @media print {
    display: block;
  }
`;

export const ExportHeader = styled(HorizontallyJustified)`
  height: 80px;
  align-items: center;

  @media print {
    background: ${({ theme }) => theme.atlas.grey['50']};
    border-radius: 6px;
  }
`;

export const ExportTitle = styled.h2`
  margin-left: 29px;
`;

export const ExportDate = styled.div`
  margin-right: 16px;
  text-align: right;
`;

export const FilterBar = styled.div`
  background-color: ${({ theme }) => theme.basic.colors.light};
  border-top: 1px ${({ theme }) => theme.basic.colors.darkerBorder} solid;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 25px 10px;
`;

export const ExportFilters = styled.div`
  width: 100%;
  list-style: none;
  margin: 25px 15px;
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  color: #5d5d5d;
`;

export const ExportFilter = styled.div`
  display: flex;
`;

export const ExportFilterKey = styled.p`
  text-align: right;
  color: inherit;
  line-height: 20px;
  width: 100px;
`;

export const ExportFilterValue = styled.p`
  text-align: left;
  color: inherit;
  line-height: 20px;
  margin-left: 8px;
  flex: 1;
  ${({ theme }) => theme.basic.fontWeights.bold}
`;

export const FilterControls = styled.div`
  background-color: ${({ theme }) => theme.basic.colors.light};
  display: flex;
  justify-content: space-between;
  padding: 0 25px 15px;
`;
