import React, { useCallback } from 'react';
import { Content, ContextOption, Switch, Toggle } from './ContextSwitch.styles';
import { NavigationContextEnum, TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { DropdownState } from '../common/Dropdown/Dropdown';
import { CONTEXT_OPTIONS } from 'common/common.constants';

export interface ContextSwitchPropsType extends TranslatePropsType {
  context: NavigationContextEnum;
  switchContext: (context: NavigationContextEnum) => void;
}

const ContextSwitch = ({
  context,
  switchContext,
  t,
}: ContextSwitchPropsType) => {
  const onSwitchContext = useCallback(
    (newContext: NavigationContextEnum, close: () => void) => {
      switchContext(newContext);
      close();
    },
    [switchContext],
  );

  return (
    <Switch
      childrenWrapper={Content}
      data-cy="context-switch"
      text={t('context', { context })}
      toggle={Toggle}
    >
      {({ close }: DropdownState) =>
        CONTEXT_OPTIONS.map(option => (
          <ContextOption
            key={option}
            data-cy={`context-switch-${option}`}
            onClick={() => onSwitchContext(option, close)}
          >
            {t('context', { context: option }) as string}
          </ContextOption>
        ))
      }
    </Switch>
  );
};

export default withTranslation('navigation')(ContextSwitch);
