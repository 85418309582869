import { EventSequencerStateType } from './eventSequencer.types';

export const initialState: EventSequencerStateType = {
  isWaitingForResponse: false,
  error: undefined,
  waitingEvents: [],
};

// For now, remaining eventsSequencer reducers are in directly
// inside `formCreator.reducer.ts`
