/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";

/**
 * 
 * @export
 * @interface MappingExecution
 */
export interface MappingExecution {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof MappingExecution
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof MappingExecution
     */
    updated_at: Date;
    /**
     * Mapping algorithm
     * @type {string}
     * @memberof MappingExecution
     */
    algorithm: MappingExecutionAlgorithmEnum;
    /**
     * 
     * @type {string}
     * @memberof MappingExecution
     */
    id: string;
    /**
     * Owner's company ID
     * @type {string}
     * @memberof MappingExecution
     */
    owner_id: string;
    /**
     * Status for mapping execution
     * @type {string}
     * @memberof MappingExecution
     */
    status: MappingExecutionStatusEnum;
    /**
     * What has triggered the execution.
     * @type {string}
     * @memberof MappingExecution
     */
    trigger: MappingExecutionTriggerEnum;
    /**
     * Associated UploadAndMapRequest
     * @type {string}
     * @memberof MappingExecution
     */
    uamr_id?: string;
    /**
     * User who has triggered the mapping
     * @type {string}
     * @memberof MappingExecution
     */
    user_id?: string;
}




/**
* @export
* @enum {string}
*/
export enum MappingExecutionAlgorithmEnum {
    FUZZY = 'FUZZY',
    ML = 'ML'
}
/**
* @export
* @enum {string}
*/
export enum MappingExecutionStatusEnum {
    FINISHEDFAIL = 'FINISHED_FAIL',
    FINISHEDSUCCESS = 'FINISHED_SUCCESS',
    INPROGRESS = 'IN_PROGRESS'
}
/**
* @export
* @enum {string}
*/
export enum MappingExecutionTriggerEnum {
    INIT = 'INIT',
    MASTER = 'MASTER',
    NEWCOMPANY = 'NEW_COMPANY',
    PREVIEW = 'PREVIEW',
    SETTINGS = 'SETTINGS',
    UPLOAD = 'UPLOAD'
}


