/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanySearchSummary
 */
export interface CompanySearchSummary {
    /**
     * If `exist_in_atlas` is `True`, respective company summary will be provided in this property.
     * @type {Company}
     * @memberof CompanySearchSummary
     */
    companyFound?: Company;
    /**
     * Denotes if the company exist on Atlas
     * @type {boolean}
     * @memberof CompanySearchSummary
     */
    existInAtlas: boolean;
    /**
     * Number of requests received from the company that is provided on `include_history_with` query parameter
     * @type {number}
     * @memberof CompanySearchSummary
     */
    requestsReceived?: number;
    /**
     * Number of requests sent to the company that is provided on `include_history_with` query parameter
     * @type {number}
     * @memberof CompanySearchSummary
     */
    requestsSent?: number;
}

export function CompanySearchSummaryFromJSON(json: any): CompanySearchSummary {
    return CompanySearchSummaryFromJSONTyped(json, false);
}

export function CompanySearchSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySearchSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyFound': !exists(json, 'company_found') ? undefined : CompanyFromJSON(json['company_found']),
        'existInAtlas': json['exist_in_atlas'],
        'requestsReceived': !exists(json, 'requests_received') ? undefined : json['requests_received'],
        'requestsSent': !exists(json, 'requests_sent') ? undefined : json['requests_sent'],
    };
}

export function CompanySearchSummaryToJSON(value?: CompanySearchSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_found': CompanyToJSON(value.companyFound),
        'exist_in_atlas': value.existInAtlas,
        'requests_received': value.requestsReceived,
        'requests_sent': value.requestsSent,
    };
}


