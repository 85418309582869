import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TopRight = styled.div`
  position: absolute;
  right: 40px;
  top: 24px;

  p {
    ${({ theme }) => theme.fonts.regular.mini}
    position: absolute;
    right: 0;
    margin-top: 10px;
  }
`;

export const PlusIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  position: relative;
`;
