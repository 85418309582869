import React from 'react';
import { withTranslation } from 'react-i18next';

import { TranslatePropsType } from 'common/common.types';

import { FormNewFromExistingSetSortFunctionType } from 'store/formNewFromExisting/actions';
import {
  SortType,
  SortByType,
  SORT_OPTIONS,
  reverseDirection,
} from 'utils/filters/formNewFromExisting.sorters';

import { Column, Wrapper, SortIcon } from './FormNewFromExistingSorter.styles';

const columnWidths: { [key in SortByType]: number | undefined } = {
  NAME: undefined,
  UPDATED: 160,
};

interface FormNewFromExistingSorterPropsType extends TranslatePropsType {
  setSort: FormNewFromExistingSetSortFunctionType;
  sortBy: SortType;
}

const FormNewFromExistingSorter = ({
  setSort,
  sortBy,
  sortBy: { direction },
  t,
}: FormNewFromExistingSorterPropsType) => {
  return (
    <Wrapper>
      {SORT_OPTIONS.map((option: SortByType) => (
        <Column
          key={option}
          clickable
          width={columnWidths[option]}
          selected={sortBy.by === option}
          onClick={() =>
            setSort({
              by: option,
              direction:
                sortBy.by === option ? reverseDirection(direction) : 'ASC',
            })
          }
        >
          <span>{t(`sortOptions.${option}`) as string}</span>

          {sortBy.by === option && <SortIcon direction={direction} />}
        </Column>
      ))}
    </Wrapper>
  );
};

export default withTranslation('FormNewFromExisting')(
  FormNewFromExistingSorter,
);
