import { pathOr, map, path } from 'ramda';
import { DelegationType, Uuid } from 'common/common.types';
import { EMPTY_ID } from 'common/common.constants';

export const getHasAssignees = (delegations: DelegationType[]) =>
  delegations.length > 0;

export const getAssigneesIds = (delegations: DelegationType[]): Uuid[] =>
  map(pathOr(EMPTY_ID, ['assignee', 'id']))(delegations);

export const getDelegationId = (delegations: DelegationType[]) =>
  pathOr(false, ['0', 'delegationId'])(delegations);

export const getQuestionAssigneesTooltip = (delegations: DelegationType[]) =>
  map(path(['assignee', 'displayName']))(delegations).join('\n');
