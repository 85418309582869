/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvidenceDeleted
 */
export interface EvidenceDeleted {
    /**
     * 
     * @type {boolean}
     * @memberof EvidenceDeleted
     */
    deleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof EvidenceDeleted
     */
    id: string;
}

export function EvidenceDeletedFromJSON(json: any): EvidenceDeleted {
    return EvidenceDeletedFromJSONTyped(json, false);
}

export function EvidenceDeletedFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvidenceDeleted {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleted': json['deleted'],
        'id': json['id'],
    };
}

export function EvidenceDeletedToJSON(value?: EvidenceDeleted | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deleted': value.deleted,
        'id': value.id,
    };
}


