import Page, { PageBaseType, PagePropsType } from './Page';
import React from 'react';
import { NewVendorLanding } from '../components/NewVendorLanding';
import { StateType, ActionType } from 'store/store.types';
import { Dispatch } from 'redux';
import { getRequests } from 'store/requests/actions';
import { RequestsInOutType } from 'store/requests/requests.types';
import { CurrentUserType } from 'common/common.types';

export interface NewVendorLandingPageContext extends PageBaseType {
  data: { requests: RequestsInOutType; user: CurrentUserType };
}

const NewVendorLandingPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      requests: state.requests.received,
      user: state.user.currentUser,
    })}
    dispatches={(dispatch: Dispatch<ActionType>) => ({
      getData: () => {
        // Get the received requests first
        dispatch(getRequests('received'));
      },
      actions: {},
    })}
  >
    <NewVendorLanding />
  </Page>
);

export default NewVendorLandingPage;
