/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof Company
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof Company
     */
    updated_at: Date;
    /**
     * Domain of the company
     * @type {string}
     * @memberof Company
     */
    domain: string;
    /**
     * SSC Grade A-F
     * @type {string}
     * @memberof Company
     */
    grade: CompanyGradeEnum;
    /**
     * Grade picture URL
     * @type {string}
     * @memberof Company
     */
    grade_url: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    id: string;
    /**
     * Industry in which the company operates
     * @type {string}
     * @memberof Company
     */
    industry: string;
    /**
     * - `NORMAL` company has at least one user that signed in via platform.
     *   Companies of this type can be seen/searched in UI
     * - `SYSTEM` company is a special company that is used when system performs some actions
     *   There MUST be only one in the system
     *   Companies of this type should **not** be seen/searched in UI.
     * - `TEMPORARY` company has no user that has signed in via platform.
     *   When user sends request to new user they always belong to new TEMPORARY company.
     *   Companies of this type should **not** be seen/searched in UI.
     * - `DELETED` company is deleted :)
     *   Companies of this type should **not** be seen/searched in UI.
     *   Company can be deleted either by the script - `NORMAL` => `DELETED` or when user logs in for
     *   the first time and their company already exists and we merge it, then `TEMPORARY` =>
     *   `DELETED`.
     * @type {string}
     * @memberof Company
     */
    internal_status: CompanyInternalStatusEnum;
    /**
     * SSC score change in last 30 days
     * @type {number}
     * @memberof Company
     */
    last30day_score_change: number;
    /**
     * Company logo uri
     * @type {string}
     * @memberof Company
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * Address to which notification email is sent after sending a form-filling request to
     * a company domain instead of a specific email. The email is sent only if `notify=true`
     * is set when sending the request.
     * @type {string}
     * @memberof Company
     */
    requests_email: string;
    /**
     * SSC Score
     * @type {number}
     * @memberof Company
     */
    score: number;
    /**
     * Word description of the company size
     * @type {string}
     * @memberof Company
     */
    size: string;
}




/**
* @export
* @enum {string}
*/
export enum CompanyGradeEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    F = 'F',
    Empty = ''
}
/**
* @export
* @enum {string}
*/
export enum CompanyInternalStatusEnum {
    DELETED = 'DELETED',
    NORMAL = 'NORMAL',
    SYSTEM = 'SYSTEM',
    TEMPORARY = 'TEMPORARY'
}


