import React from 'react';
import { ProgressionWrapper, Wrapper } from './Autocomplete.styles';
import { withTranslation } from 'react-i18next';
import Spinner from './Spinner';
import MatchIntro from './MatchIntro';
import { TranslatePropsType } from 'common/common.types';

const MatchSearchInProgress = ({ t }: TranslatePropsType) => (
  <Wrapper>
    <MatchIntro />

    <ProgressionWrapper>
      <Spinner text={t('matchSearchInProgress') as string} />
    </ProgressionWrapper>
  </Wrapper>
);

export default withTranslation('Autocomplete')(MatchSearchInProgress);
