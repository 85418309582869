/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionConditionStructuredItem,
    QuestionConditionStructuredItemFromJSON,
    QuestionConditionStructuredItemFromJSONTyped,
    QuestionConditionStructuredItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionConditionStructured
 */
export interface QuestionConditionStructured {
    /**
     * 
     * @type {Array<QuestionConditionStructuredItem>}
     * @memberof QuestionConditionStructured
     */
    items: Array<QuestionConditionStructuredItem>;
    /**
     * 
     * @type {string}
     * @memberof QuestionConditionStructured
     */
    operator: QuestionConditionStructuredOperatorEnum;
}

export function QuestionConditionStructuredFromJSON(json: any): QuestionConditionStructured {
    return QuestionConditionStructuredFromJSONTyped(json, false);
}

export function QuestionConditionStructuredFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionConditionStructured {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(QuestionConditionStructuredItemFromJSON)),
        'operator': json['operator'],
    };
}

export function QuestionConditionStructuredToJSON(value?: QuestionConditionStructured | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(QuestionConditionStructuredItemToJSON)),
        'operator': value.operator,
    };
}

/**
* @export
* @enum {string}
*/
export enum QuestionConditionStructuredOperatorEnum {
    AND = 'AND',
    OR = 'OR'
}


