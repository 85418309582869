import { ApiCallActionType } from '../../store.types';
import { FormCreatorStateType } from '../formCreator.types';
import { FactorWithIssues } from '../../../common/common.types';
import update from 'immutability-helper';

export type GetIssuesActionType = ApiCallActionType<
  '@formCreator/API/GET_ISSUES',
  undefined,
  FactorWithIssues[],
  never,
  FormCreatorStateType
>;

export const getIssues = (): GetIssuesActionType => ({
  type: '@formCreator/API/GET_ISSUES',
  payload: {
    endpointPath: ['metadata', 'getFactorsWithIssues'],
    selector: (data: any, { formCreator }) =>
      update(formCreator, {
        factorsWithIssues: { $set: data },
      }),
  },
});
