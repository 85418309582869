/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    FormDetail,
    FormDetailFromJSON,
    FormDetailFromJSONTyped,
    FormDetailToJSON,
    FormMappingStats,
    FormMappingStatsFromJSON,
    FormMappingStatsFromJSONTyped,
    FormMappingStatsToJSON,
    FormUploadStats,
    FormUploadStatsFromJSON,
    FormUploadStatsFromJSONTyped,
    FormUploadStatsToJSON,
    ParsedFileDetails,
    ParsedFileDetailsFromJSON,
    ParsedFileDetailsFromJSONTyped,
    ParsedFileDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface MappingRequest
 */
export interface MappingRequest {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof MappingRequest
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof MappingRequest
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof MappingRequest
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof MappingRequest
     */
    id: string;
    /**
     * 
     * @type {FormMappingStats}
     * @memberof MappingRequest
     */
    mappingStats?: FormMappingStats;
    /**
     * List of files with answers that were successfully parsed
     * @type {Array<ParsedFileDetails>}
     * @memberof MappingRequest
     */
    parsedFiles: Array<ParsedFileDetails>;
    /**
     * 
     * @type {FormDetail}
     * @memberof MappingRequest
     */
    parsedForm?: FormDetail;
    /**
     * 
     * @type {string}
     * @memberof MappingRequest
     */
    parsedFormId: string;
    /**
     * 
     * @type {FormUploadStats}
     * @memberof MappingRequest
     */
    parsingStats?: FormUploadStats;
    /**
     * 
     * @type {string}
     * @memberof MappingRequest
     */
    resultFormId?: string;
    /**
     * - `1`: `ACCEPTED` - The parse & map request was created and accepted
     * - `2`: `FINISHED` - The end state: The parsing and mapping finished
     * - `3`: `PARSING` - The file is being parsed
     * - `4`: `PARSING_SUCCEEDED`
     * - `5`: `PARSING_FAILED` - File parsing failed
     * - `6`: `PARSING_EMPTY_RESULT` - File was successfully parsed but no answers were found in it
     * - `7`: `MAPPING` - Mapping of answers onto the specified form is in progress
     * - `8`: `MAPPING_SUCCEEDED`
     * - `9`: `MAPPING_FAILED` - Mapping of answers onto the specified form failed
     * @type {number}
     * @memberof MappingRequest
     */
    status: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MappingRequest
     */
    updatedQuestionsIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MappingRequest
     */
    userId: string;
}

export function MappingRequestFromJSON(json: any): MappingRequest {
    return MappingRequestFromJSONTyped(json, false);
}

export function MappingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MappingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'companyId': json['company_id'],
        'id': json['id'],
        'mappingStats': !exists(json, 'mapping_stats') ? undefined : FormMappingStatsFromJSON(json['mapping_stats']),
        'parsedFiles': ((json['parsed_files'] as Array<any>).map(ParsedFileDetailsFromJSON)),
        'parsedForm': !exists(json, 'parsed_form') ? undefined : FormDetailFromJSON(json['parsed_form']),
        'parsedFormId': json['parsed_form_id'],
        'parsingStats': !exists(json, 'parsing_stats') ? undefined : FormUploadStatsFromJSON(json['parsing_stats']),
        'resultFormId': !exists(json, 'result_form_id') ? undefined : json['result_form_id'],
        'status': json['status'],
        'updatedQuestionsIds': json['updated_questions_ids'],
        'userId': json['user_id'],
    };
}

export function MappingRequestToJSON(value?: MappingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'company_id': value.companyId,
        'id': value.id,
        'mapping_stats': FormMappingStatsToJSON(value.mappingStats),
        'parsed_files': ((value.parsedFiles as Array<any>).map(ParsedFileDetailsToJSON)),
        'parsed_form': FormDetailToJSON(value.parsedForm),
        'parsed_form_id': value.parsedFormId,
        'parsing_stats': FormUploadStatsToJSON(value.parsingStats),
        'result_form_id': value.resultFormId,
        'status': value.status,
        'updated_questions_ids': value.updatedQuestionsIds,
        'user_id': value.userId,
    };
}


