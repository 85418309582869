import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import qs from 'qs';
import route, { REQUESTS_SENT } from '../../../routes';
import { Bar, Link, Wrapper } from './StatusChart.styles';
import { contains, max, pickBy, reduce } from 'ramda';
import { TranslatePropsType } from 'common/common.types';
import { getBarWidth } from '../utils';
import { withTranslation } from 'react-i18next';
import {
  RequestsCategoryNameType,
  RequestsCategoryType,
  RequestsFilterType,
} from 'store/requests/requests.types';
import Gauge from '../Gauge';
import {
  FilterByType,
  FilterItemType,
  isFilterEnabled,
  REQUEST_FILTERS,
} from '../../../utils/filters/requests.filters';

const MAX_BAR_WIDTH = 137;

interface CategoryBar {
  key: RequestsCategoryNameType;
  color: 'green' | 'blue';
}

const BARS: CategoryBar[] = [
  { key: 'created', color: 'blue' },
  { key: 'inProgress', color: 'blue' },
  { key: 'reviewReady', color: 'green' },
  { key: 'closed', color: 'blue' },
];

interface StatusChartPropType extends TranslatePropsType {
  categories: RequestsCategoryType;
  filter: RequestsFilterType;
}

const StatusChart = ({
  categories,
  filter: { filterBy: currentFilterBy },
  t,
}: StatusChartPropType) => {
  const maxCount = reduce<number, number>(
    max,
    -Infinity,
    BARS.map(categoryBar => categories[categoryBar.key].count),
  );

  const filterBy = pickBy(
    (item: FilterItemType, key: FilterByType) =>
      isFilterEnabled(item, key) && contains(key, REQUEST_FILTERS),
    currentFilterBy,
  );

  return (
    <Gauge title={t('charts.totalStatus')}>
      <Wrapper>
        {BARS.map(({ key, color }) => (
          <Bar
            data-test="status-bar"
            key={key}
            color={color || 'blue'}
            width={getBarWidth(maxCount, categories[key].count, MAX_BAR_WIDTH)}
          >
            <Link
              href={`#${route(REQUESTS_SENT)}?${qs.stringify({
                categories: { [key]: true },
                filterBy,
              })}`}
            >
              {ReactHtmlParser(
                t(`statuses.${key}`, { count: categories[key].count || 0 }),
              )}
            </Link>
          </Bar>
        ))}
      </Wrapper>
    </Gauge>
  );
};

export default withTranslation('Insights')(StatusChart);
