import React from 'react';
import { Content } from 'common/common.styles';
import { Background } from './PageHeader.styles';

export interface PageHeaderPropsType {
  className?: string;
  contentPadding?: string;
  heading: React.ReactNode;
}

const PageHeader = ({
  className,
  contentPadding,
  heading,
}: PageHeaderPropsType) => (
  <Background className={className}>
    <Content padding={contentPadding}>{heading}</Content>
  </Background>
);

export default PageHeader;
