import { ApiCallActionType } from '../../store.types';
import { UserInfo } from '../../../generated/models/UserInfo';
import { put } from 'redux-saga/effects';
import { storeSettings } from '../../settings/actions/storeSettings';
import { getInitialState } from '../utils';
import { UserStateType } from '../user.types';

export type UserGetInfoActionType = ApiCallActionType<
  '@user/API/GET_INFO',
  never,
  UserInfo,
  never,
  UserStateType
>;

export const getInfo = (): UserGetInfoActionType => ({
  type: '@user/API/GET_INFO',
  payload: {
    endpointPath: ['user', 'getInfo'],
    selector: getInitialState,
    postActions: [data => put(storeSettings(data.settings))],
  },
});
