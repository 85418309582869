import styled from 'styled-components';

export const SvgWrapper = styled.svg`
  cursor: pointer;
  &:focus {
    outline: 1px dashed #ddd;
    outline-offset: 2px;
  }
`;

export const RoundRect = styled.rect<{ isChecked: boolean }>`
  transition: fill 0.3s, stroke 0.3s;
  fill: ${({ theme, isChecked }) =>
    isChecked ? theme.atlas.teal.primary : 'transparent'};
  stroke: ${({ theme, isChecked }) =>
    isChecked ? theme.atlas.teal.primary : '#cdcdcd'};
`;

export const Circle = styled.circle<{ isChecked: boolean }>`
  transition: fill 0.3s, transform 0.3s;
  fill: ${({ isChecked }) => (isChecked ? '#ffffff' : '#c4c4c4')};
  transform: translate(${({ isChecked }) => (isChecked ? '24px' : '10px')}, 0);
`;
