import { FormMetadata } from '../../../generated/models/FormMetadata';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { assocPath, pathOr, pipe } from 'ramda';
import update from 'immutability-helper';
import { getFiltersCallParam } from '../../../components/Form/utils';
import { parseFilterStats } from 'utils/parsers/forms.parsers';
import { parseForm } from 'utils/parsers';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { FormType } from '../../../common/common.types';
import { FormsGetFormCallParams } from './getForm';

export type FormsRemoveFilterActionType = OptimisticActionType<
  '@forms/API/REMOVE_FILTER',
  ApiCallActionPayloadType<
    FormsGetFormCallParams,
    FormMetadata,
    never,
    FormsStateType
  >
>;

export const removeFilter = (
  branch: string,
  filterName: string,
): FormsRemoveFilterActionType => ({
  type: '@forms/API/REMOVE_FILTER',
  payload: {
    callParams: state => ({
      id: pathOr(undefined, ['forms', 'currentForm', 'id'], state),
      ...getFiltersCallParam(
        update(state.forms.filters, {
          [branch]: { [filterName]: { isEnabled: { $set: false } } },
        }),
      ),
    }),
    expectedStateChange: {
      [`forms.filters.${branch}.${filterName}.isEnabled`]: () => false,
    },
    endpointPath: ['forms', 'get'],
    selector: (data, state) =>
      pipe(
        assocPath<FormType, FormsStateType>(
          ['currentForm'],
          parseForm(data, state),
        ),
        assocPath(
          ['filters'],
          parseFilterStats(data.filter_stats, data.assignee_stats, state.forms),
        ),
        assocPath(['filters', branch, filterName, 'isEnabled'], false),
      )(state.forms),
  },
});
