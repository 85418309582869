/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Attachment } from "./Attachment";
// @ts-ignore
import { Company } from "./Company";
// @ts-ignore
import { FormShortSummary } from "./FormShortSummary";
// @ts-ignore
import { QuestionDetailBase } from "./QuestionDetailBase";
// @ts-ignore
import { RequestSummary } from "./RequestSummary";
// @ts-ignore
import { Standard } from "./Standard";

/**
 * 
 * @export
 * @interface FormDetail
 */
export interface FormDetail {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof FormDetail
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof FormDetail
     */
    updated_at: Date;
    /**
     * Description of the form
     * @type {string}
     * @memberof FormDetail
     */
    description: string;
    /**
     * Name of the form displayed in the GUI (usually corresponds to the standard name)
     * @type {string}
     * @memberof FormDetail
     */
    display_name: string;
    /**
     * - `MASTER` form represents form with preferred responses to existing standards.
     *   It is considered as part of the knowledge base.
     *   Every company has exactly one MASTER form for:
     *   - every Standard - SYSTEM
     *   - every Standard - CUSTOM - if they have received request with it
     * 
     * - `UPLOAD` form represents uploaded file from which questions and responses have been
     *   extracted. It is considered as part of the knowledge base.
     * 
     * - `REQUEST` form represents form associated with Request.W
     *   It is always associated with some standard.
     * 
     * - `MAPPING_SPLIT` form is deprecated. **DO NOT USE.**
     *   It used to represent temporary form that was created for the purpose of mapping.
     * 
     * - `SNAPSHOT` form represents read only version of some other form.
     *   It is created when MASTER form is shared or for storing SSC Issues
     * 
     * - `PREVIEW` form is used in Send Request flow to see how the form will look like for Vendor
     *   Every company has exactly one PREVIEW form for:
     *   - every Standard - SYSTEM
     *   - every Standard - CUSTOM - they own
     * 
     * - `DELETED` form is deleted form.
     *   It should not be shown to users and not used for anything
     * 
     * - `UPLOAD_MERGED` form represents form that was uploaded and then was merged.
     *   It starts as form of type UPLOAD. If user uploaded in the past exactly the same form then
     *   responses from this form are used to update the first form. After that it changes its
     *   status to UPLOAD_MERGED
     * @type {string}
     * @memberof FormDetail
     */
    filling_type: FormDetailFillingTypeEnum;
    /**
     * Indicates an association with an evidence locker entity
     * @type {boolean}
     * @memberof FormDetail
     */
    has_evidence_locker_entity: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormDetail
     */
    id: string;
    /**
     * Name of the form
     * @type {string}
     * @memberof FormDetail
     */
    name: string;
    /**
     * ID of the owner company (usually the vendor company)
     * @type {string}
     * @memberof FormDetail
     */
    owner_id: string;
    /**
     * ID of the related standard
     * @type {string}
     * @memberof FormDetail
     */
    standard_id: string;
    /**
     * Number of form-level attachments
     * @type {number}
     * @memberof FormDetail
     */
    attachment_count: number;
    /**
     * True if the current user is allowed to add and remove form-level attachments.
     * 
     * **DEPRECATED:** Use `permissions.ATTACHMENT_FORM_*` permissions instead.
     * @type {boolean}
     * @memberof FormDetail
     */
    attachment_modifiable: boolean;
    /**
     * List of form-level attachments
     * @type {Array<Attachment>}
     * @memberof FormDetail
     */
    attachments: Array<Attachment>;
    /**
     * Status for form filling initialization (creation).
     * 
     * - `EMPTY` - The form_filling is currently not in our system yet and is waiting to be put into
     *   queue so a worker can take care of form_filling creation process.
     * - `FINISHED_SUCCESS` - The form_filling has been created with a successful status.
     * - `IN_QUEUE` - The form_filling is currently waiting in the queue to be taken by a worker.
     * - `IN_PROGRESS` - The form_filling is currently being worked on by a worker.
     * - `FINISHED_FAIL` - The form_filling has been finished but the creation process failed.
     * @type {string}
     * @memberof FormDetail
     */
    initialization_status: FormDetailInitializationStatusEnum;
    /**
     * True if the form can be edited and filled with answers by the current user
     * @type {boolean}
     * @memberof FormDetail
     */
    is_editable: boolean;
    /**
     * True if the current user's company is owner of this form
     * @type {boolean}
     * @memberof FormDetail
     */
    is_owner: boolean;
    /**
     * True if the form can be submitted (e.g. all required fields are filled)
     * @type {boolean}
     * @memberof FormDetail
     */
    is_submittable: boolean;
    /**
     * ID of form into which this form was merged.
     * @type {string}
     * @memberof FormDetail
     */
    merged_to_form_id?: string;
    /**
     * Company that owns the form (usually the vendor company)
     * @type {Company}
     * @memberof FormDetail
     */
    owner: Company;
    /**
     * Percentage of filled responses. It includes all required responses and all responses in
     * questions without a single required response. It doesn't include optional responses in
     * questions with one or more required responses.
     * @type {number}
     * @memberof FormDetail
     */
    percentage_filled: number;
    /**
     * List of permissions related to this form for the current user
     * @type {Array<string>}
     * @memberof FormDetail
     */
    permissions: Array<FormDetailPermissionsEnum>;
    /**
     * Number of questions in the form
     * @type {number}
     * @memberof FormDetail
     */
    question_count: number;
    /**
     * Number of questions in the form without taking visibility into account
     * @type {number}
     * @memberof FormDetail
     */
    question_count_ignore_visibility: number;
    /**
     * List of questions in the form (might be `null` depending from witch endpoint this model
     * is fetched)
     * @type {Array<QuestionDetailBase>}
     * @memberof FormDetail
     */
    questions?: Array<QuestionDetailBase>;
    /**
     * Number of questions that have all responses filled
     * @type {number}
     * @memberof FormDetail
     */
    questions_completely_filled: number;
    /**
     * Number of questions that are either completely or partially filled
     * @type {number}
     * @memberof FormDetail
     */
    questions_filled: number;
    /**
     * Number of questions that have at least one response and not all responses filled
     * @type {number}
     * @memberof FormDetail
     */
    questions_partially_filled: number;
    /**
     * Associated form filling request
     * @type {RequestSummary}
     * @memberof FormDetail
     */
    request?: RequestSummary;
    /**
     * ID of the associated form filling request
     * @type {string}
     * @memberof FormDetail
     */
    request_id?: string;
    /**
     * Number of responses in the form
     * @type {number}
     * @memberof FormDetail
     */
    response_count: number;
    /**
     * Number of responses in the form without taking visibility into account
     * @type {number}
     * @memberof FormDetail
     */
    response_count_ignore_visibility: number;
    /**
     * Number of responses filled in the form
     * @type {number}
     * @memberof FormDetail
     */
    responses_filled: number;
    /**
     * Normalized score between 0 and 100 computed for the current answers
     * @type {number}
     * @memberof FormDetail
     */
    score: number;
    /**
     * Maximal achievable score that corresponds to normalized score == 100
     * @type {number}
     * @memberof FormDetail
     */
    score_limit: number;
    /**
     * Current score that is used in computation of normalized score
     * @type {number}
     * @memberof FormDetail
     */
    score_raw: number;
    /**
     * True if the score should be visible (usually for VRM)
     * @type {boolean}
     * @memberof FormDetail
     */
    score_visible: boolean;
    /**
     * Standard related to the form
     * @type {Standard}
     * @memberof FormDetail
     */
    standard: Standard;
    /**
     * Number of questions with at least one attachment
     * @type {number}
     * @memberof FormDetail
     */
    with_attachment_count: number;
    /**
     * Number of questions with at least one comment
     * @type {number}
     * @memberof FormDetail
     */
    with_comment_count: number;
    /**
     * Number of questions with N/A answer
     * @type {number}
     * @memberof FormDetail
     */
    with_na_count: number;
    /**
     * Number of questions with NO answer
     * @type {number}
     * @memberof FormDetail
     */
    with_no_count: number;
    /**
     * Number of question with at least one required response (not necessarily answered)
     * @type {number}
     * @memberof FormDetail
     */
    with_required_count: number;
    /**
     * Number of questions with at least one required response that have all their required
     * responses answered
     * @type {number}
     * @memberof FormDetail
     */
    with_required_filled_count: number;
    /**
     * Number of questions with at least one SSC issue found
     * @type {number}
     * @memberof FormDetail
     */
    with_ssc_issue_count: number;
    /**
     * Number of questions with VRM review status == DONE
     * @type {number}
     * @memberof FormDetail
     */
    with_vrm_review_status_done_count: number;
    /**
     * Number of questions with VRM review status == FLAGGED
     * @type {number}
     * @memberof FormDetail
     */
    with_vrm_review_status_flagged_count: number;
    /**
     * Number of questions with VRM review status == PENDING
     * @type {number}
     * @memberof FormDetail
     */
    with_vrm_review_status_pending_count: number;
    /**
     * Number of questions with YES answer
     * @type {number}
     * @memberof FormDetail
     */
    with_yes_count: number;
}




/**
* @export
* @enum {string}
*/
export enum FormDetailFillingTypeEnum {
    DELETED = 'DELETED',
    MAPPINGSPLIT = 'MAPPING_SPLIT',
    MASTER = 'MASTER',
    PREVIEW = 'PREVIEW',
    REQUEST = 'REQUEST',
    SNAPSHOT = 'SNAPSHOT',
    UPLOAD = 'UPLOAD',
    UPLOADMERGED = 'UPLOAD_MERGED'
}
/**
* @export
* @enum {string}
*/
export enum FormDetailInitializationStatusEnum {
    EMPTY = 'EMPTY',
    FINISHEDFAIL = 'FINISHED_FAIL',
    FINISHEDSUCCESS = 'FINISHED_SUCCESS',
    INPROGRESS = 'IN_PROGRESS',
    INQUEUE = 'IN_QUEUE'
}
/**
* @export
* @enum {string}
*/
export enum FormDetailPermissionsEnum {
    ANSWERLOOKUPLENSESREAD = 'ANSWER_LOOKUP_LENSES_READ',
    ATTACHMENTFORMDELETE = 'ATTACHMENT_FORM_DELETE',
    ATTACHMENTFORMREAD = 'ATTACHMENT_FORM_READ',
    ATTACHMENTFORMWRITE = 'ATTACHMENT_FORM_WRITE',
    ATTACHMENTQUESTIONDELETE = 'ATTACHMENT_QUESTION_DELETE',
    ATTACHMENTQUESTIONREAD = 'ATTACHMENT_QUESTION_READ',
    ATTACHMENTQUESTIONWRITE = 'ATTACHMENT_QUESTION_WRITE',
    COMMENTFORMREAD = 'COMMENT_FORM_READ',
    COMMENTFORMWRITE = 'COMMENT_FORM_WRITE',
    COMMENTQUESTIONREAD = 'COMMENT_QUESTION_READ',
    COMMENTQUESTIONWRITE = 'COMMENT_QUESTION_WRITE',
    FORMACCEPT = 'FORM_ACCEPT',
    FORMAUTOCOMPLETE = 'FORM_AUTOCOMPLETE',
    FORMAUTOFILL = 'FORM_AUTOFILL',
    FORMREAD = 'FORM_READ',
    FORMSCOREREAD = 'FORM_SCORE_READ',
    FORMSUBMIT = 'FORM_SUBMIT',
    FORMUPDATESEEN = 'FORM_UPDATE_SEEN',
    OTHER = 'OTHER',
    OWNER = 'OWNER',
    PROXYDELETE = 'PROXY_DELETE',
    PROXYREAD = 'PROXY_READ',
    PROXYWRITE = 'PROXY_WRITE',
    RESPONSEHISTORYREAD = 'RESPONSE_HISTORY_READ',
    RESPONSEHISTORYREADSINCEFIRSTSUBMIT = 'RESPONSE_HISTORY_READ_SINCE_FIRST_SUBMIT',
    RESPONSEREAD = 'RESPONSE_READ',
    RESPONSEREADAPPROVE = 'RESPONSE_READ_APPROVE',
    RESPONSEREADASK = 'RESPONSE_READ_ASK',
    RESPONSEWRITE = 'RESPONSE_WRITE',
    REVIEWINSTRUCTIONSREAD = 'REVIEW_INSTRUCTIONS_READ',
    SSCSCORESREAD = 'SSC_SCORES_READ',
    UPDATEDUEDATE = 'UPDATE_DUE_DATE',
    VENDOR = 'VENDOR',
    VENDORDELEGATIONASSIGN = 'VENDOR_DELEGATION_ASSIGN',
    VENDORDELEGATIONINVITE = 'VENDOR_DELEGATION_INVITE',
    VENDORDELEGATIONREAD = 'VENDOR_DELEGATION_READ',
    VENDORDELEGATIONUNASSIGN = 'VENDOR_DELEGATION_UNASSIGN',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY',
    VRMREVIEWREAD = 'VRM_REVIEW_READ',
    VRMREVIEWWRITE = 'VRM_REVIEW_WRITE',
    VRMRISKREPORTDELETE = 'VRM_RISK_REPORT_DELETE',
    VRMRISKREPORTREAD = 'VRM_RISK_REPORT_READ',
    VRMRISKREPORTWRITE = 'VRM_RISK_REPORT_WRITE'
}


