import { ApiCallActionType } from '../../store.types';
import { getCredits } from './getCredits';
import { put } from 'redux-saga/effects';
import { Uuid } from '../../../common/common.types';

export interface CompaniesAddCreditsCallParamsType {
  amount: number;
  id: Uuid;
}

export type CompaniesAddCreditsFunctionType = (
  amount: number,
  id: Uuid,
) => void;

export type CompaniesAddCreditsActionType = ApiCallActionType<
  '@companies/API/ADD_CREDITS',
  CompaniesAddCreditsCallParamsType
>;

export const addCredits = (
  amount: number,
  id: Uuid,
): CompaniesAddCreditsActionType => ({
  type: '@companies/API/ADD_CREDITS',
  payload: {
    endpointPath: ['companies', 'addCredits'],
    callParams: { amount, id },
    selector: null,
    postActions: [() => put(getCredits(id))],
  },
});
