import { ApiCallActionType } from '../../store.types';
import { call, put } from 'redux-saga/effects';
import { getInfo } from '../../user/actions';
import { trackEvent } from 'utils/analytics';
import { RequestsFormType, RequestsStateType } from '../requests.types';
import { startLoading } from '../../app/actions';
import { SENT_POST_ACTIONS } from './sendRequestPostActions';
import {
  getCommonRequestCallParams,
  updateSendRequestStateError,
  getEffectiveSourceTeam,
} from '../utils';
import requestsApi from '../../../api/new/requestsApi';
import { RequestsInternalCreate, RequestDetail } from '../../../generated/new';
import { Uuid } from '../../../common/common.types';

export type RequestsSendInternalFormFunctionType = (
  form: RequestsFormType,
  standardIds: Uuid[],
) => void;

export type RequestsSendInternalFormActionType = ApiCallActionType<
  '@requests/API/SEND_INTERNAL_FORM',
  RequestsInternalCreate,
  RequestDetail[],
  any,
  RequestsStateType
>;

export const sendInternalForm = (
  form: RequestsFormType,
  standardIds: Uuid[],
): RequestsSendInternalFormActionType => ({
  type: '@requests/API/SEND_INTERNAL_FORM',
  payload: {
    endpointPath: callParams =>
      requestsApi.postRequestsInternalListResource({ payload: callParams }),
    callParams: state => ({
      ...getCommonRequestCallParams(form, standardIds),
      sourceTeam:
        getEffectiveSourceTeam(form.sourceTeam, state.user.currentUser) ||
        `SHOULDN'T HAPPEN: sending internal request without source team`,
      targetTeams: form.targetTeams.map(option => option.value),
    }),
    selector: null,
    errorSelector: updateSendRequestStateError,
    preActions: [put(startLoading(1))],
    postActions: [
      () => call(() => trackEvent('Internal Request', 'Create')),
      ...SENT_POST_ACTIONS,
      () => put(startLoading(1)),
      () => put(getInfo()),
    ],
  },
});
