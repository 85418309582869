import getGroupedStatus from 'utils/getGroupedStatus';
import { Status } from './Bar.styles';
import React from 'react';
import { RequestType, TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface RequestStatusPropsType extends TranslatePropsType {
  isVendor: boolean;
  request: RequestType;
}

const RequestStatus = ({
  isVendor,
  request: { revisionCount, status },
  t,
}: RequestStatusPropsType) => (
  <Status data-cy="questionnaireStatus">
    {getGroupedStatus(status, isVendor)}
    {revisionCount > 1 && (t('revisionRound', { revisionCount }) as string)}
  </Status>
);

export default withTranslation('FormHeader')(RequestStatus);
