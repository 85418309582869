import styled from 'styled-components';

export const Wrapper = styled.div``;

export const NoData = styled.div`
  text-align: center;
`;

export const Record = styled.div`
  & + & {
    margin-top: 12px;
    border-top: 1px solid ${({ theme }) => theme.basic.colors.darkerBorder};
    padding-top: 5px;
  }
`;

export const CreatedAt = styled.div`
  line-height: 20px;
  color: ${({ theme }) => theme.atlas.blue[700]};
  ${({ theme }) => theme.fonts.bold.mini}
`;

export const Author = styled.div`
  line-height: 20px;
  ${({ theme }) => theme.fonts.bold.small}
`;

export const Text = styled.div`
  margin-top: 6px;
  line-height: 20px;
  ${({ theme }) => theme.fonts.regular.normal}
`;
