import styled from 'styled-components';

export const Scroller = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 90vw;
  max-width: 970px;
  max-height: 550px;
`;

export const LoaderWrapper = styled.div`
  padding: 32px;
`;

export const Heading = styled.div`
  ${({ theme }) => theme.basic.fontWeights.bold};
`;

export const Caption = styled.div`
  ${({ theme }) => theme.fonts.regular.normal}
  padding: 12px 0 24px;

  a {
    ${({ theme }) => theme.basic.fontWeights.semibold};
    color: ${({ theme }) => theme.basic.colors.atlas};
  }
`;

export const InitializeFailedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  flex-direction: column;
  flex-wrap: nowrap;
`;
