import { Observable } from 'rxjs';
import { Enable } from '../reduxSubs';

const fromActionStream = <Action>(
  streamCreator: () => Observable<Action>,
): Enable<Action> => dispatch => {
  const subscription = streamCreator().subscribe(dispatch);
  return () => subscription.unsubscribe();
};

export default fromActionStream;
