import { FormCreatorStateType } from './formCreator.types';
import { FormCreatorActionType } from './actions';
import update from 'immutability-helper';
import { initialState as eventSequencerInitialState } from './eventSequencer/eventSequencer.reducer';
import { moveCategoryLocally } from './utils';
import { EventSequencerErrorType } from './eventSequencer/eventSequencer.types';
import { findIndex, propEq } from 'ramda';

export const initialState: FormCreatorStateType = {
  areOnlyInvalidQuestionsVisible: false,
  currentForm: undefined,
  eventSequencer: eventSequencerInitialState,
  factorsWithIssues: [],
  isPublishing: false,
  openedQuestion: undefined,
  validation: {
    attemptedFixes: {},
    formErrors: [],
    invalidQuestions: [],
    invalidResponses: [],
    isLoading: false,
    correct: false,
  },
};

export const formCreator = (
  state: FormCreatorStateType = initialState,
  action: FormCreatorActionType,
): FormCreatorStateType => {
  switch (action.type) {
    case '@formCreator/RESET':
      return initialState;

    case '@formCreator/MOVE_QUESTION': {
      const questionUuid = action.payload.source;
      const targetQuestionUuid = action.payload.destination;
      const questions = state.currentForm!.questions;

      const sourceIndex = findIndex(
        propEq('wizardId', questionUuid),
        questions,
      );
      const destinationIndex = findIndex(
        propEq('wizardId', targetQuestionUuid),
        questions,
      );
      const source = questions[sourceIndex];
      const updatedQuestion = {
        ...source,
        category: questions[destinationIndex].category,
      };

      const switchedQuestions = update(questions, {
        $splice: [
          [sourceIndex, 1],
          [destinationIndex, 0, updatedQuestion],
        ],
      });
      return update(state, {
        currentForm: {
          questions: {
            $set: switchedQuestions.map((item, i) => ({
              ...item,
              pos: i + 1,
            })),
          },
        },
        openedQuestion: {
          // Preserve the opened question if the opened question was moved
          // between categories.
          $apply: openedQuestion =>
            openedQuestion &&
            openedQuestion.categoryName === source.category &&
            openedQuestion.questionUuid &&
            openedQuestion.questionUuid === questionUuid &&
            source.category !== updatedQuestion.category
              ? update(openedQuestion, {
                  categoryName: { $set: updatedQuestion.category },
                })
              : openedQuestion,
        },
      });
    }

    case '@formCreator/MOVE_CATEGORY':
      return update(state, {
        currentForm: {
          categories: {
            $set: moveCategoryLocally(
              action.payload.source,
              action.payload.destination,
              state.currentForm!.categories,
            ),
          },
        },
      });

    case '@formCreator/EVENT_SEQ_ENQUEUE_AND_APPLY_EVENTS': {
      const { newState, newEvents } = action.payload;

      return update(newState, {
        eventSequencer: {
          waitingEvents: { $push: newEvents },
        },
      });
    }

    case '@formCreator/EVENT_SEQ_SENDING_STARTED':
      return update(state, {
        eventSequencer: {
          isWaitingForResponse: { $set: true },
          waitingEvents: { $set: [] },
        },
      });

    case '@formCreator/EVENT_SEQ_SEND_SUCCESS': {
      const {
        response: { next_event_token },
        time,
      } = action.payload;

      return update(state, {
        eventSequencer: {
          nextEventToken: { $set: next_event_token },
          lastSavedUpdate: { $set: time },
        },
      });
    }

    case '@formCreator/EVENT_SEQ_SEND_ERROR': {
      const { status, msg } = action.payload;

      const error: EventSequencerErrorType =
        status === 409
          ? {
              type: 'conflict',
            }
          : {
              type: 'other',
              msg,
            };

      return update(state, {
        eventSequencer: {
          error: { $set: error },
        },
      });
    }

    case '@formCreator/EVENT_SEQ_SENDING_FINISHED':
      return update(state, {
        eventSequencer: {
          isWaitingForResponse: { $set: false },
        },
      });

    case '@formCreator/CLEAN_VALIDATION':
      return update(state, {
        validation: { $set: initialState.validation },
      });

    case '@formCreator/SHOW_ONLY_INVALID_QUESTIONS':
      return update(state, {
        $toggle: ['areOnlyInvalidQuestionsVisible'],
      });

    case '@formCreator/API/PUBLISH_FORM':
      return update(state, {
        isPublishing: { $set: true },
      });

    case '@formCreator/SET_OPENED_QUESTION':
      return update(state, {
        openedQuestion: { $set: action.payload.openedQuestion },
      });

    default:
      return state;
  }
};
