import { Uuid, FixableFieldEnum } from '../../../common/common.types';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';
import update from 'immutability-helper';
import { map } from 'ramda';
import ifProblemAddToAttemptedFixes from '../utils/ifProblemAddToAttemptedFixes';

export type UpdateQuestionCodeFunctionType = (
  questionId: Uuid,
  code: string,
) => void;

export type UpdateQuestionCodeActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/UPDATE_QUESTION_CODE'
>;

export const updateQuestionCode = (
  questionId: Uuid,
  code: string,
): UpdateQuestionCodeActionType => ({
  type: '@formCreator/EVENTS/UPDATE_QUESTION_CODE',
  payload: {
    events: [
      {
        action: FormEditEventActionCodeEnum.UPDATEQUESTION,
        question_uuid: questionId,
        code,
      },
    ],
    selector: ({ formCreator }) =>
      update(formCreator, {
        currentForm: {
          questions: {
            $apply: map(q => (q.wizardId === questionId ? { ...q, code } : q)),
          },
        },
        validation: {
          $apply: ifProblemAddToAttemptedFixes(
            'question',
            questionId,
            FixableFieldEnum.CODE,
          ),
        },
      }),
  },
});
