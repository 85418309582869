import { css } from 'styled-components';

export type FontSizeType =
  | 'tiny'
  | 'micro'
  | 'mini'
  | 'xsmall'
  | 'small'
  | 'normal'
  | 'medium'
  | 'large'
  | 'big'
  | 'bigger'
  | 'huge'
  | 'massive';

const fontSizes = {
  tiny: 9,
  micro: 10,
  mini: 12,
  xsmall: 13,
  small: 14,
  normal: 16,
  medium: 18,
  large: 20,
  big: 24,
  bigger: 28,
  huge: 36,
  massive: 46,
};

export const addFontSize = (fontSize?: FontSizeType) => {
  if (fontSize) {
    return `font-size: ${fontSizes[fontSize]}px`;
  }
  return null;
};

export const getFontSize = (fontSize?: FontSizeType) => {
  if (fontSize) {
    return `${fontSizes[fontSize]}px`;
  }
  return null;
};

export const fontLight = (fontSize?: FontSizeType) => css`
  ${addFontSize(fontSize)};
  font-weight: 400;
  font-family: 'Proxima Nova Light';
`;

export const fontRegular = (fontSize?: FontSizeType) => css`
  ${addFontSize(fontSize)};
  font-weight: 400;
  font-family: 'Proxima Nova';
`;

export const fontSemibold = (fontSize?: FontSizeType) => css`
  ${addFontSize(fontSize)};
  font-weight: 400;
  font-family: 'Proxima Nova Semibold';
`;

export const fontBold = (fontSize?: FontSizeType) => css`
  ${addFontSize(fontSize)};
  font-weight: 400;
  font-family: 'Proxima Nova Bold';
`;

export const monospaceFontRegular = (fontSize?: FontSizeType) => css`
  ${addFontSize(fontSize)};
  /* Font stack that leverages modern monospace system fonts. Inspired by
     GitHub CSS */
  font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
`;
