import axios from 'axios';
import multipartHeaders from '../utils/url/multipartHeaders';
import withUrl from '../utils/url/withUrl';
import { DeleteFileParamsType } from '../store/user/actions/deleteFile';
import { UserLoginActionTypeApiCallParamsType } from '../store/user/actions/login';
import { UserShareFilesCallParamsType } from '../store/user/actions/shareFiles';
import { UserShareFrameworksCallParamsType } from '../store/user/actions/shareFrameworks';
import { UserUploadFileParamsType } from '../store/user/actions/uploadFile';
import { UserGetFilesParamsType } from '../store/user/actions/getFiles';
import urlize from '../utils/url/urlize';
import { UserGetDashboardParamsType } from '../store/user/actions/getDashboard';
import { UserGetDashboardUploadParamsType } from '../store/user/actions/getDashboardUpload';
import config from '../config';

export default {
  files: {
    delete: ({ id }: DeleteFileParamsType) =>
      axios.delete(withUrl('evidences', id)),
    get: (params: UserGetFilesParamsType) =>
      axios.get(withUrl(`evidences?${urlize(params)}`)),
    upload: ({ formData, team_id }: UserUploadFileParamsType) =>
      axios.post(
        withUrl(`evidences?${urlize({ team_id })}`),
        formData,
        multipartHeaders,
      ),
  },
  deleteForm: ({ formId }: any) => axios.delete(withUrl(`forms/${formId}`)),
  getDashboard: (params: UserGetDashboardParamsType) =>
    axios.get(withUrl(`companies/current/dashboard?${urlize(params)}`)),
  getDashboardUpload: (params: UserGetDashboardUploadParamsType) =>
    axios.get(
      withUrl(
        `companies/current/forms?${urlize({ ...params, type: 'UPLOAD' })}`,
      ),
    ),
  getInfo: () =>
    axios.get(withUrl('users/current/info?include_credit_history=true')),
  getRequestMailingList: () => axios.get(withUrl('suggestions/users/request')),
  getShareMailingList: () => axios.get(withUrl('suggestions/users/share')),
  login: (params: UserLoginActionTypeApiCallParamsType) =>
    axios.post(withUrl('session/login'), params),
  logout: () =>
    axios.post(`${config.url}/session-destroy`.replace('/atlas', ''), {}),
  shareFiles: (params: UserShareFilesCallParamsType) =>
    axios.post(withUrl('evidences/shares'), params),
  shareFrameworks: (params: UserShareFrameworksCallParamsType) =>
    axios.post(withUrl('forms/shares'), params),
  getMyself: () => axios.get(withUrl('myself', '', '/')),
};
