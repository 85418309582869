/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ScheduledRequestRecurrenceTypeEnum } from './';
import { exists, mapValues } from '../runtime';
import moment from 'moment';
/**
 *
 * @export
 * @interface ScheduledRequestCreate
 */
export interface ScheduledRequestCreate {
  /**
   *
   * @type {Date}
   * @memberof ScheduledRequestCreate
   */
  dueDate?: Date;
  /**
   *
   * @type {string}
   * @memberof ScheduledRequestCreate
   */
  emailBody?: string;
  /**
   *
   * @type {boolean}
   * @memberof ScheduledRequestCreate
   */
  notify?: boolean;
  /**
   *
   * @type {number}
   * @memberof ScheduledRequestCreate
   */
  occurrences?: number;
  /**
   *
   * @type {string}
   * @memberof ScheduledRequestCreate
   */
  recurrenceType?: ScheduledRequestRecurrenceTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ScheduledRequestCreate
   */
  recurrenceUnits?: number;
  /**
   *
   * @type {number}
   * @memberof ScheduledRequestCreate
   */
  reminderDays?: number;
  /**
   *
   * @type {Date}
   * @memberof ScheduledRequestCreate
   */
  sendDate: Date;
  /**
   * Source team UUID of the sender. This is an optional field if the sender does not
   * want to send the scheduled request from one of their teams. Note that, in this case,
   * the request becomes visible through out the company wide.
   * @type {string}
   * @memberof ScheduledRequestCreate
   */
  sourceTeam?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ScheduledRequestCreate
   */
  standardIds: Array<string>;
  /**
   * Target to who to share to.
   * @type {Array<string>}
   * @memberof ScheduledRequestCreate
   */
  targets: Array<string>;
}

export function ScheduledRequestCreateFromJSON(
  json: any,
): ScheduledRequestCreate {
  return ScheduledRequestCreateFromJSONTyped(json, false);
}

export function ScheduledRequestCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ScheduledRequestCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    dueDate: !exists(json, 'due_date') ? undefined : new Date(json['due_date']),
    emailBody: !exists(json, 'email_body') ? undefined : json['email_body'],
    notify: !exists(json, 'notify') ? undefined : json['notify'],
    occurrences: !exists(json, 'occurrences') ? undefined : json['occurrences'],
    recurrenceType: !exists(json, 'recurrence_type')
      ? undefined
      : json['recurrence_type'],
    recurrenceUnits: !exists(json, 'recurrence_units')
      ? undefined
      : json['recurrence_units'],
    reminderDays: !exists(json, 'reminder_days')
      ? undefined
      : json['reminder_days'],
    sendDate: new Date(json['send_date']),
    sourceTeam: !exists(json, 'source_team') ? undefined : json['source_team'],
    standardIds: json['standard_ids'],
    targets: json['targets'],
  };
}

export function ScheduledRequestCreateToJSON(
  value?: ScheduledRequestCreate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    due_date:
      value.dueDate === undefined ? undefined : value.dueDate.toISOString(),
    email_body: value.emailBody,
    notify: value.notify,
    occurrences: value.occurrences,
    recurrence_type: value.recurrenceType,
    recurrence_units: value.recurrenceUnits,
    reminder_days: value.reminderDays,
    send_date: moment(value.sendDate).format(),
    source_team: value.sourceTeam,
    standard_ids: value.standardIds,
    targets: value.targets,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ScheduledRequestCreateRecurrenceTypeEnum {
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  YEARLY = 'YEARLY',
}
