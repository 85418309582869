import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { PageHeading, Content } from 'common/common.styles';

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

export const ArchivedLink = styled(Link)`
  float: right;
  margin-top: 20px;
  letter-spacing: 0.25px;
`;

export const Heading = styled(PageHeading)`
  margin-bottom: 0;
  ${({ theme }) => theme.fonts.light.big}
`;

export const ReceivedContainer = styled(Content)`
  margin-top: 30px;
`;
