import List from './List';
import React, { useMemo, useCallback } from 'react';
import {
  ButtonMore,
  Heading,
  TitleLink,
  Subheading,
  ModalTitleWrapper,
} from './Standards.styles';
import { PreviewModal, StandardsModal } from '../Modals';
import { SetFormValueFunctionType } from 'store/requests/actions';
import { any, contains, pipe, propEq, sort, take } from 'ramda';
import { withTranslation } from 'react-i18next';
import {
  FormsGetPreviewFunctionType,
  FormsLoadQuestionsFunctionType,
  FormsPutQuestionFunctionType,
} from 'store/forms/actions';
import {
  FORM_PREVIEW_MODAL_ID,
  SYSTEM_FRAMEWORKS_MODAL_ID,
} from 'common/common.constants';
import {
  StandardsDeleteFunctionType,
  RenameStandardFunctionType,
  ToggleStandardSelectionFunctionType,
} from 'store/standard/actions';
import {
  FormType,
  LanguageType,
  StandardType,
  TranslatePropsType,
  UserType,
  Uuid,
} from 'common/common.types';
import { StandardStateType } from 'store/standard/reducer';
import { withHashUrl } from 'utils/url/withHashUrl';
import formatRoute, { FORM_DEFINITIONS } from '../../../routes';
import { compareNames } from 'store/standard/standard.selectors';

export const CUSTOM_STANDARDS_LIMIT = 3;
export const SYSTEM_STANDARDS_LIMIT = 5;
export const CUSTOM_AND_SYSTEM_STANDARDS_LIMIT = 10;

interface StandardsPropsType extends TranslatePropsType {
  deleteStandard: StandardsDeleteFunctionType;
  getPreview: FormsGetPreviewFunctionType;
  hideModal: (modalId: string) => void;
  loadQuestions: FormsLoadQuestionsFunctionType;
  previewForm: FormType;
  putQuestion: FormsPutQuestionFunctionType;
  renameStandard: RenameStandardFunctionType;
  setValue: SetFormValueFunctionType;
  showModal: (modalId: string) => void;
  standards: StandardStateType;
  toggleStandard: ToggleStandardSelectionFunctionType;
  user: UserType;
  language: LanguageType;
}

const appendMissingSelectedItems = (
  allItems: StandardType[],
  selectedIds: Uuid[],
) => (currentItems: StandardType[]) => [
  ...currentItems,
  ...allItems.filter(
    ({ id }) =>
      contains(id, selectedIds) && !any(propEq('id', id), currentItems),
  ),
];

const Standards = ({
  deleteStandard,
  getPreview,
  hideModal,
  loadQuestions,
  previewForm,
  putQuestion,
  renameStandard,
  showModal,
  standards: { all },
  t,
  toggleStandard,
  user,
  language,
}: StandardsPropsType) => {
  const handlePreviewClick = useCallback(
    (formId: Uuid) => {
      getPreview(formId);
      showModal(FORM_PREVIEW_MODAL_ID);
    },
    [getPreview, showModal],
  );

  // CUSTOM & SYSTEM

  const customAndSystem = useMemo(
    () => ({
      all: sort(compareNames, all.mostRecent),
      selectedIds: all.selected,
      mostRecent: pipe(
        take(CUSTOM_AND_SYSTEM_STANDARDS_LIMIT),
        appendMissingSelectedItems(all.mostRecent, all.selected),
      )(all.mostRecent),
    }),
    [all.mostRecent, all.selected],
  );

  const titleLink = (
    <TitleLink
      target="_blank"
      href={withHashUrl(formatRoute(FORM_DEFINITIONS))}
    >
      {t('templatesLink') as string}
    </TitleLink>
  );

  const modalTitle = (
    <ModalTitleWrapper>
      <div>{t('allStandardAndCustomTemplates') as string}</div>
      <div>{titleLink}</div>
    </ModalTitleWrapper>
  );

  return (
    <div>
      <PreviewModal
        loadQuestions={loadQuestions}
        modalId={FORM_PREVIEW_MODAL_ID}
        previewForm={previewForm}
        putQuestion={putQuestion}
        user={user}
        language={language}
      />
      <Heading>
        {t('selectFromTemplates') as string}
        {titleLink}
      </Heading>
      <Subheading>{t('onlyPublishedWillAppear') as string}</Subheading>
      <List
        data-cy="system-and-custom-standards-recent"
        deleteStandard={deleteStandard}
        handleToggle={toggleStandard}
        onPreview={handlePreviewClick}
        renameStandard={renameStandard}
        selectedStandards={customAndSystem.selectedIds}
        standards={customAndSystem.mostRecent}
      />
      <StandardsModal
        deleteStandard={deleteStandard}
        hideModal={() => hideModal(SYSTEM_FRAMEWORKS_MODAL_ID)}
        modalId={SYSTEM_FRAMEWORKS_MODAL_ID}
        onPreview={handlePreviewClick}
        renameStandard={renameStandard}
        selectedStandards={customAndSystem.selectedIds}
        standards={customAndSystem.all}
        title={modalTitle}
        toggleStandard={toggleStandard}
      />
      {customAndSystem.all.length > CUSTOM_AND_SYSTEM_STANDARDS_LIMIT && (
        <ButtonMore
          onClick={() => showModal(SYSTEM_FRAMEWORKS_MODAL_ID)}
          data-cy="button-show-more-standards"
        >
          {t('displayMore') as string}
        </ButtonMore>
      )}
    </div>
  );
};

export default withTranslation('SendRequest')(Standards);
