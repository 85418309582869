import { CompletedStandard } from 'generated/models/CompletedStandard';
import {
  CompletedStandardType,
  DuplicateStateEnum,
  StandardType,
  UnpublishStateEnum,
} from 'common/common.types';
import { Standard } from 'generated/models/Standard';
import { EMPTY_ID } from 'common/common.constants';

const getDuplicateState = ({ is_duplicable, has_custom_scoring }: Standard) => {
  if (!is_duplicable) {
    return has_custom_scoring ? DuplicateStateEnum.HAS_SCORING : undefined;
  }
  return undefined;
};

const getUnpublishState = ({
  is_unpublishable,
  is_duplicable,
  has_custom_scoring,
}: Standard) => {
  if (!is_unpublishable) {
    if (is_duplicable) {
      return UnpublishStateEnum.IS_SENT;
    } else {
      return has_custom_scoring ? UnpublishStateEnum.HAS_SCORING : undefined;
    }
  }
  return undefined;
};

export const parseStandard = (standard: Standard): StandardType => {
  const {
    id = EMPTY_ID,
    code = '',
    description = '',
    logo = '',
    name = '',
    preview_form_id = EMPTY_ID,
    definition_id = EMPTY_ID,
    is_duplicable = false,
    is_unpublishable = false,
    status,
    version = '',
    year = 0,
    updated_at = 0,
  } = standard;

  return {
    id,
    code,
    description,
    deletable: false,
    logo,
    name,
    previewFormId: preview_form_id,
    definitionId: definition_id,
    duplicateState: getDuplicateState(standard),
    unpublishState: getUnpublishState(standard),
    isDuplicable: is_duplicable,
    isUnpublishable: is_unpublishable,
    status,
    updatedAt: new Date(updated_at),
    version,
    year,
  };
};

export const parseCompletedStandard = (
  completedStandard: CompletedStandard,
): CompletedStandardType => {
  const { already_requested = false, standard } = completedStandard;

  return {
    ...parseStandard(standard),
    isAlreadyRequested: already_requested,
  };
};
