import React, { ChangeEvent } from 'react';
import { Uuid } from 'common/common.types';

export interface MultiOptionType {
  data: {
    checked: boolean;
    handleChange: (e: ChangeEvent<HTMLInputElement>, value?: string) => void;
    id: Uuid;
    isEditable: boolean;
    isRequired?: boolean;
    optionLabel: string;
  };
}

const MultiOption = ({
  data: { checked, handleChange, id, isEditable, isRequired, optionLabel },
}: MultiOptionType) => (
  <input
    data-checked={`${checked}`}
    data-cy="option"
    checked={checked}
    disabled={!isEditable}
    name={`option-${id}`}
    onChange={handleChange}
    required={isRequired || false}
    type={'checkbox'}
    value={optionLabel}
  />
);

export default MultiOption;
