import arrayToIdDict from 'utils/arrayToIdDict';
import toCamelCase from 'utils/toCamelCase';
import { FormsActionType } from './actions';
import {
  FormsAssigneesFilterDict,
  FormsFiltersInitialState,
  FormsSimpleFiltersType,
} from 'utils/filters/forms.filters';
import { assoc, evolve, mapObjIndexed, reduce, values } from 'ramda';
import {
  AttachmentModalType,
  FormHistoryType,
  FormType,
  QuestionTabEnum,
  QuestionExternalTabs,
  QuestionType,
  SharedFormType,
  UploadType,
  FormAssignee,
  Uuid,
  AutocompleteDialogStateType,
} from '../../common/common.types';
import update from 'immutability-helper';
import {
  autocompleteDialogReducer,
  initialState as autocompleteDialogInitialState,
} from './autocompleteDialog.reducer';
import { isAutocompleteDialogOpened } from './forms.selectors';

export interface FormsFiltersType {
  assignees: FormsAssigneesFilterDict;
  simple: FormsSimpleFiltersType;
}

export interface FormsResourceGoneStateType {
  company?: string;
  statusOfResource?: string;
}

export interface ReportSavingType {
  activeRequestCount: number;
  lastSave: Date;
}

export interface AutocompleteType {
  dialog: AutocompleteDialogStateType;
}

export enum DropdownsEnum {
  AUTOCOMPLETE = 'autocomplete',
  DETAILS = 'details',
  HISTORY = 'history',
  REPORT = 'report',
}

export interface FormsStateType {
  autocomplete: AutocompleteType;
  assignees: FormAssignee[];
  attachmentModal: AttachmentModalType;
  /** Flag where true represents that filter stats may need updating */
  canUpdateView: boolean;
  currentDropdown?: DropdownsEnum;
  currentForm: FormType | undefined;
  currentFormHistory: FormHistoryType[];
  /**
   * Represents the intention to fetch/have a fetched form with such ID.
   * Set it to `undefined` to cancel retry procedure for forms that are being
   * initialized.
   */
  fetchedFormId?: Uuid;
  findingsExporting: boolean;
  filters: FormsFiltersType;
  preferredTab: QuestionTabEnum;
  reportSaving: ReportSavingType;
  resourceGoneState: FormsResourceGoneStateType | undefined;
  sharedFormData?: SharedFormType;
  uploads: UploadType[];
}

const filtersInitialState = {
  assignees: {},
  simple: FormsFiltersInitialState,
};

const initialState: FormsStateType = {
  assignees: [],
  attachmentModal: {
    isOpen: false,
    shouldSubmit: false,
  },
  autocomplete: {
    dialog: autocompleteDialogInitialState,
  },
  canUpdateView: false,
  currentForm: undefined,
  currentFormHistory: [],
  fetchedFormId: undefined,
  findingsExporting: false,
  filters: filtersInitialState,
  preferredTab: QuestionTabEnum.ATTACHMENTS,
  reportSaving: {
    activeRequestCount: 0,
    lastSave: new Date(0),
  },
  resourceGoneState: undefined,
  sharedFormData: undefined,
  uploads: [],
};

export const forms = (
  originalState: FormsStateType = initialState,
  action: FormsActionType,
): FormsStateType => {
  const autocompleteDialogState = autocompleteDialogReducer(
    originalState,
    action,
  );
  let state = originalState;

  if (autocompleteDialogState !== originalState.autocomplete.dialog) {
    state = update(originalState, {
      autocomplete: { dialog: { $set: autocompleteDialogState } },
      // Make sure the autocomplete dropdown gets opened when we set it to some
      // OPENED_* state.
      currentDropdown: {
        $apply: d =>
          isAutocompleteDialogOpened(autocompleteDialogState)
            ? DropdownsEnum.AUTOCOMPLETE
            : d,
      },
    });
  }

  switch (action.type) {
    case '@forms/SET_CAN_UPDATE_VIEW':
      return assoc('canUpdateView', action.payload)(state);

    case '@forms/RESET': {
      const { fetchedFormId } = action.payload;
      return {
        ...state,
        assignees: [],
        currentForm: undefined,
        filters: filtersInitialState,
        fetchedFormId,
        findingsExporting: false,
        sharedFormData: undefined,
        preferredTab: initialState.preferredTab,
      };
    }

    case '@forms/CLEAR_VALUES':
      const form = state.currentForm;
      if (form) {
        const questions = values(form.questions).map(question => ({
          ...question,
          responses: values(question.responses).map(response => ({
            ...response,
            value: '',
          })),
        }));
        return {
          ...state,
          currentForm: {
            ...form,
            questions: arrayToIdDict(questions),
          },
        };
      }
      return state;

    case '@forms/CLEAR_QUESTIONS_CACHE':
      return evolve({
        canUpdateView: () => false,
        currentForm: { questions: () => ({}) },
      })(state);

    case '@forms/RESET_UPLOAD':
      return { ...state, uploads: [] };

    case '@forms/SET_CURRENT_DROPDOWN':
      return { ...state, currentDropdown: action.payload.dropdown };

    case '@forms/SET_RESOURCE_GONE': {
      const { company } = action.payload;
      const { statusOfResource } = action.payload;

      return {
        ...state,
        currentForm: undefined,
        resourceGoneState: {
          company,
          statusOfResource,
        },
      };
    }

    case '@forms/QUESTION_SWITCH_TAB': {
      const { questionId, tab } = action.payload;

      return questionId
        ? update(state, {
            currentForm: state.currentForm
              ? {
                  questions: {
                    [questionId]: {
                      selectedTab: { $set: tab },
                    },
                  },
                }
              : // Don't do anything on missing form
                {},
          })
        : update(state, {
            preferredTab: { $set: tab },
            currentForm: state.currentForm
              ? {
                  questions: {
                    $apply: mapObjIndexed(
                      (question: QuestionType): QuestionType => {
                        const questionBlocksContainTheTab = Boolean(
                          question.blocks &&
                            question.blocks.data[
                              toCamelCase(QuestionTabEnum[tab.toUpperCase()])
                            ],
                        );

                        const selectedTab =
                          questionBlocksContainTheTab ||
                          QuestionExternalTabs.includes(tab)
                            ? tab
                            : question.selectedTab;

                        return {
                          ...question,
                          selectedTab,
                        };
                      },
                    ),
                  },
                }
              : // Don't do anything on missing form
                {},
          });
    }

    case '@forms/API/UPDATE_RISK_NOTE':
    case '@forms/API/ADD_FINDING':
    case '@forms/API/DELETE_FINDING':
      return update(state, {
        reportSaving: {
          activeRequestCount: { $apply: count => count + 1 },
        },
      });

    case '@forms/FINISH_REPORT_SAVING': {
      const { lastSave } = action.payload;

      return update(state, {
        reportSaving: {
          activeRequestCount: { $apply: count => Math.max(count - 1, 0) },
          lastSave: { $set: lastSave },
        },
      });
    }

    case '@forms/SET_SHARED_DATA': {
      return update(state, {
        sharedFormData: { $set: action.payload.sharedFormData },
      });
    }

    case '@forms/RESET_REPORT_SAVING': {
      const { lastSave } = action.payload;

      return update(state, {
        reportSaving: {
          activeRequestCount: { $set: 0 },
          lastSave: { $set: lastSave },
        },
      });
    }

    case '@forms/ENABLE_FILTERS': {
      if (action.payload.filters.length === 0) {
        return state;
      }

      return update(state, {
        filters: {
          $apply: (filtersState: FormsFiltersType) =>
            reduce(
              (current, { branch, filterName }) =>
                update(current, {
                  [branch]: { [filterName]: { isEnabled: { $set: true } } },
                }),
              filtersState,
              action.payload.filters,
            ),
        },
      });
    }

    default:
      return state;
  }
};
