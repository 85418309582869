/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface ScheduledRequestInternalCreate
 */
export interface ScheduledRequestInternalCreate {
    /**
     * 
     * @type {Date}
     * @memberof ScheduledRequestInternalCreate
     */
    due_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof ScheduledRequestInternalCreate
     */
    email_body?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledRequestInternalCreate
     */
    notify?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ScheduledRequestInternalCreate
     */
    occurrences?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledRequestInternalCreate
     */
    recurrence_type?: ScheduledRequestInternalCreateRecurrenceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ScheduledRequestInternalCreate
     */
    recurrence_units?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledRequestInternalCreate
     */
    reminder_days?: number;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledRequestInternalCreate
     */
    send_date: Date;
    /**
     * Source team UUID of the actual sender. The source_team must belong to the same
     * company of the sender.
     * @type {string}
     * @memberof ScheduledRequestInternalCreate
     */
    source_team: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledRequestInternalCreate
     */
    standard_ids: Array<string>;
    /**
     * List of recepient teams UUID of the standards. All recepient teams must belong to the
     * same company of the sender.
     * @type {Array<string>}
     * @memberof ScheduledRequestInternalCreate
     */
    target_teams: Array<string>;
}




/**
* @export
* @enum {string}
*/
export enum ScheduledRequestInternalCreateRecurrenceTypeEnum {
    DAILY = 'DAILY',
    HOURLY = 'HOURLY',
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY',
    YEARLY = 'YEARLY'
}


