import { Name, Option, State, StateIcon } from './SortBy.styles';
import {
  SORT_OPTIONS,
  SortType,
  reverseDirection,
} from 'utils/filters/requests.sorters';
import {
  faSortAmountDown,
  faSortAmountUp,
} from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from '../common/Dropdown';
import React, { MouseEvent } from 'react';
import { RequestsSetSortFunctionType } from 'store/requests/actions';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface SortByPropsType extends TranslatePropsType {
  data: {
    isSent: boolean;
    sortBy: SortType;
  };
  actions: {
    setSort: RequestsSetSortFunctionType;
  };
}

const SortBy = ({
  data: {
    isSent,
    sortBy: { by, direction },
  },
  actions: { setSort },
  t,
}: SortByPropsType) => {
  const validOptions = SORT_OPTIONS.filter(
    option =>
      (option !== 'SCORE' || isSent) &&
      option !== 'REQUESTED' &&
      (option !== 'COMPANY_TARGET' || isSent) &&
      (option !== 'COMPANY_SOURCE' || !isSent),
  );

  const handleClick = (event: MouseEvent<HTMLElement>, index: number) => {
    const option = validOptions[index];

    setSort({
      by: option,
      direction: by === option ? reverseDirection(direction) : 'ASC',
    });
  };
  let stateIcon = faSortAmountDown as IconProp;
  if (direction === 'ASC') {
    stateIcon = faSortAmountUp as IconProp;
  }

  return (
    <Dropdown color="transparent" size="medium" text={t('Requests.sortBy')}>
      {() =>
        validOptions.map((option, index) => (
          <Option
            key={`sortBy-menu-${option}`}
            isSelected={by === option}
            onClick={event => handleClick(event, index)}
          >
            <State>{by === option && <StateIcon icon={stateIcon} />}</State>
            <Name data-test="dropdown-sort-name">
              {t(`Requests.sortOptions.${option}`) as string}
            </Name>
          </Option>
        ))
      }
    </Dropdown>
  );
};

export default withTranslation()(SortBy);
