import React, { memo, useState, useRef, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as R from 'ramda';

import { StandardType, TranslatePropsType, Uuid } from 'common/common.types';

import {
  StandardsDeleteFunctionType,
  RenameStandardFunctionType,
} from 'store/standard/actions';

import {
  Hoverable,
  UploadedStandardContent,
  UploadedStandardInput,
  UploadedStandardText,
  ActionButtons,
  TextButton,
} from './UploadedStandard.styles';

type ActionButtonType = { key: string; title: string; onClick: () => void };

export interface UploadedStandardPropsType
  extends RouteComponentProps,
    TranslatePropsType {
  deleteStandard: StandardsDeleteFunctionType;
  onPreview: (formId: Uuid) => void;
  renameStandard: RenameStandardFunctionType;
  showModal: (modalId: string) => void;
  standard: StandardType;
}

const UploadedStandard = ({
  deleteStandard,
  onPreview,
  renameStandard,
  showModal,
  standard,
  history: { push: navigate },
  t,
}: UploadedStandardPropsType) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [nameInputValue, setNameInputValue] = useState<string>(standard.name);

  const nameInputRef = useRef<HTMLDivElement>(null);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
    setNameInputValue(standard.name);
  }, [standard]);

  const handleTextChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setNameInputValue(value),
    [],
  );

  const handleSave = useCallback(() => {
    renameStandard({
      id: standard.id,
      name: nameInputValue,
    });
    setIsEditing(false);
  }, [renameStandard, nameInputValue, standard]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent) => {
      if (R.contains(e.key, ['Enter'])) {
        handleSave();
      }
    },
    [handleSave],
  );

  const handleOnRenameClick = useCallback(() => {
    setIsEditing(true);
    if (nameInputRef.current) {
      (nameInputRef.current as any).focus();
      (nameInputRef.current as any).select();
    }
  }, []);

  const previewForm = useCallback(() => {
    onPreview(standard.previewFormId!);
  }, [onPreview, standard.previewFormId]);

  let actionButtons: ActionButtonType[] = isEditing
    ? [
        {
          key: 'save',
          title: 'common:labels.save',
          onClick: handleSave,
        },
        {
          key: 'cancel',
          title: 'common:labels.cancel',
          onClick: handleCancelEdit,
        },
      ]
    : [
        {
          key: 'preview',
          title: 'common:labels.preview',
          onClick: previewForm,
        },
      ];

  if (!isEditing) {
    actionButtons = actionButtons.concat([
      {
        key: 'rename',
        title: 'rename',
        onClick: handleOnRenameClick,
      },
      {
        key: 'delete',
        title: 'delete',
        onClick: () => deleteStandard({ id: standard.id }),
      },
    ]);
  }

  return (
    <Hoverable key={standard.id}>
      <UploadedStandardContent>
        <UploadedStandardText isEditing={isEditing}>
          {standard.name}
        </UploadedStandardText>
        <UploadedStandardInput
          ref={nameInputRef as any}
          value={nameInputValue}
          isEditing={isEditing}
          onChange={handleTextChange}
          onKeyPress={handleKeyPress}
        />
      </UploadedStandardContent>
      <ActionButtons>
        {actionButtons.map(({ key, title, onClick }) => (
          <TextButton
            data-test={t(title)}
            key={key}
            size={'mini'}
            color={'atlas'}
            onClick={onClick}
          >
            {t(title) as string}
          </TextButton>
        ))}
      </ActionButtons>
    </Hoverable>
  );
};

export default memo(
  withRouter(withTranslation('UploadCustomStandard')(UploadedStandard)),
);
