import React from 'react';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import {
  BoxOverlay,
  BundleBoxContainer,
  CreditText,
  CurrentPriceContainer,
  DiscountRibbon,
  DiscountText,
  Label,
  PriceText,
  PriceTextContainer,
  PriceWrapper,
} from './Bundlebox.styles';

interface BundleBoxPropsType extends TranslatePropsType {
  discount: number;
  isCurrentPrice: boolean;
  label: string;
  minQuantity: number;
  onQuantityRefresh: (quantity: number) => void;
  price: number;
}

const BundleBox = ({
  discount,
  isCurrentPrice,
  label,
  minQuantity,
  onQuantityRefresh,
  price,
  t,
}: BundleBoxPropsType) => (
  <BundleBoxContainer price={price}>
    {discount > 0 ? (
      <DiscountRibbon price={price}>
        <DiscountText>{`-${discount}%`}</DiscountText>
      </DiscountRibbon>
    ) : null}

    <Label>{label}</Label>

    <PriceWrapper price={price}>
      <PriceTextContainer>
        <PriceText>{`$${price}`}</PriceText>

        <CreditText>{`/credit`}</CreditText>
      </PriceTextContainer>
    </PriceWrapper>

    <BoxOverlay
      price={price}
      isCurrentPrice={isCurrentPrice}
      onClick={() => onQuantityRefresh(minQuantity)}
    >
      <CurrentPriceContainer price={price}>
        {t('currentPriceIndicator') as string}
      </CurrentPriceContainer>
    </BoxOverlay>
  </BundleBoxContainer>
);

export default withTranslation('CreditsSelfCheckout')(BundleBox);
