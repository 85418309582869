import { ApiCallActionType } from '../../store.types';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { resolveTargets } from './utils';
import { trackEvent } from 'utils/analytics';
import { selectFramework } from './selectFramework';
import i18n from '../../../i18n';
import { Uuid } from '../../../common/common.types';

export interface UserShareFrameworksCallParamsType {
  form_filling_ids: Uuid[];
  targets: string[];
  expiration_date: Date | null;
}

export type UserShareFrameworksActionType = ApiCallActionType<
  '@user/API/SHARE_FRAMEWORKS',
  UserShareFrameworksCallParamsType
>;

export const shareFrameworks = (
  formIds: Uuid[],
  targets: string[],
  expirationDate: Date | null,
): UserShareFrameworksActionType => ({
  type: '@user/API/SHARE_FRAMEWORKS',
  payload: {
    endpointPath: ['user', 'shareFrameworks'],
    callParams: {
      form_filling_ids: formIds,
      targets,
      expiration_date: expirationDate,
    },
    preActions: [put(selectFramework(undefined))],
    selector: null,
    postActions: [
      () => call(() => trackEvent('Standard', 'Share')),
      () =>
        put(
          flash(
            i18n.t('common:responses.shareFrameworkSuccess', {
              target: resolveTargets(targets),
            }),
          ),
        ),
    ],
  },
});
