import styled from 'styled-components';

export const QuestionMark = styled.div<{ size?: number }>`
  align-items: center;
  background-color: ${({ theme }) => theme.questionMark.background};
  border-radius: 50%;
  color: ${({ theme }) => theme.basic.colors.light}
  display: flex;
  flex-direction: column;
  ${({ theme, size }) => theme.fonts.bold[size || 'micro']};
  font-size: ${({ size }) => (size || 12) / 1.2}px;
  height: ${({ size }) => size || 12}px;
  justify-content: center;
  width: ${({ size }) => size || 12}px;
`;

export const QuestionText = styled.div`
  max-width: 240px;
`;
