import HideOnScroll from './HideOnScroll';
import NavItem from './NavItem';
import React, { memo } from 'react';
import getNavItems, { NavItemType } from 'navigation/navigation';
import { Bar, BarInner, Left, LeftInner, Right } from './Header.styles';
import { ToggleHeaderFunctionType } from 'store/ui/actions/toggleHeader';
import {
  CurrentUserType,
  NavigationContextEnum,
  TogglesDict,
} from 'common/common.types';
import { StateType } from 'store/store.types';
import { getUserFeatureFlags } from 'store/user/user.selectors';
import { connect } from 'react-redux';

interface HeaderPropsType {
  appealCredits: () => void;
  hide?: boolean;
  logout: () => void;
  switchContext: (context: NavigationContextEnum) => void;
  toggleHeader: ToggleHeaderFunctionType;
  featureFlags: TogglesDict;
  user: CurrentUserType;
  setLanguagePreference: (value: { label: string; value: string }) => void;
  getLanguagePreference: {
    label: string;
    value: string;
  };
}

const Header = ({
  appealCredits,
  hide,
  logout,
  switchContext,
  toggleHeader,
  user,
  setLanguagePreference,
  getLanguagePreference,
  featureFlags,
}: HeaderPropsType) => {
  const { logo, left, right } = getNavItems({
    appealCredits,
    user,
    logout,
    switchContext,
    setLanguagePreference,
    getLanguagePreference,
    isOptInEnabled: featureFlags.ATLAS_UI_MIGRATION_FE,
  });

  const renderNavItem = (item: NavItemType) => (
    <NavItem {...item} user={user} />
  );

  const inner = (
    <Bar hide={hide}>
      <BarInner>
        <Left>
          {renderNavItem(logo)}
          <LeftInner>
            {left.filter(i => !i.isHidden).map(renderNavItem)}
          </LeftInner>
        </Left>

        <Right>{right.filter(i => !i.isHidden).map(renderNavItem)}</Right>
      </BarInner>
    </Bar>
  );

  return hide ? (
    <HideOnScroll toggleHeader={toggleHeader}>{inner}</HideOnScroll>
  ) : (
    inner
  );
};

const mapStateToProps = ({ user }: StateType) => ({
  featureFlags: getUserFeatureFlags(user),
});

export default connect(mapStateToProps)(memo(Header));
