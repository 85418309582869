import Page, { PageActionsBaseType, PageBaseType, PagePropsType } from './Page';
import React from 'react';
import Settings from 'components/Settings';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import { USERS_SETTINGS_LOAD_LIMIT } from 'common/common.constants';
import {
  CompanySettingType,
  CurrentUserType,
  UserType,
} from 'common/common.types';
import { startLoading } from 'store/app/actions';
import {
  CompaniesSaveSettingsFunctionType,
  findUsers,
  getSettings,
  saveSettings,
  uploadLogo,
  UploadLogoFunctionType,
  removeLogo,
} from 'store/companies/actions';
import { showModal, hideModal } from 'utils/Modal/';

export interface SettingsPageContextType extends PageBaseType {
  actions: PageActionsBaseType & {
    cobrandLogo: UploadLogoFunctionType;
    hideModal: (modalId: string) => void;
    saveSettings: CompaniesSaveSettingsFunctionType;
    showModal: (modalId: string) => void;
    removeLogo: () => void;
  };
  data: {
    companyName: string;
    emailables: UserType[];
    settings: CompanySettingType;
    user: CurrentUserType;
  };
}

const SettingsPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      companyName: state.user.currentUser.company.name,
      emailables: state.companies.users,
      settings: state.companies.settings,
      user: state.user.currentUser,
    })}
    dispatches={(dispatch: Dispatch<ActionType>) => ({
      getData: () => {
        dispatch(startLoading(2));
        dispatch(findUsers({ prefix: '', limit: USERS_SETTINGS_LOAD_LIMIT }));
        dispatch(getSettings());
      },
      actions: {
        saveSettings: (settings: any) => {
          dispatch(saveSettings(settings));
        },
        cobrandLogo: (file: any) => {
          dispatch(uploadLogo(file));
        },
        removeLogo: () => {
          dispatch(removeLogo());
          dispatch(startLoading(2));
        },
        showModal: (modalId: string) => dispatch(showModal(modalId)),
        hideModal: (modalId: string) => dispatch(hideModal(modalId)),
      },
    })}
  >
    <Settings />
  </Page>
);

export default SettingsPage;
