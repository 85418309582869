import styled from 'styled-components/macro';
import { Horizontal } from 'common/common.styles';

export const Wrapper = styled(Horizontal)`
  flex-wrap: wrap;

  > * {
    margin: 10px 10px 0 0;
  }
`;
