import Asset from '../Asset';
import React, { memo } from 'react';
import route, { FORM } from '../../../routes';
import { FrameworkType, Uuid, UserType } from 'common/common.types';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ActionType } from 'store/store.types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Completion,
  FrameworkBody,
  Graph,
  Percentage,
  Year,
  Questions,
  Label,
} from './Framework.styles';
import { textTruncate } from 'utils/multiLineEllipsis';
import { getMasterForm } from 'store/forms/actions';

interface FrameworkPropsType extends RouteComponentProps {
  deleteForm: (formId: Uuid) => void;
  framework: FrameworkType;
  hideFramework: (frameworkId: Uuid) => void;
  isHidden: boolean;
  selectFramework: (framework: FrameworkType) => void;
  unhideFramework: (frameworkId: Uuid) => void;
  showHidden: boolean;
  user: UserType;
  getFormAction: (id: string) => void;
}

const Framework = ({
  deleteForm,
  framework,
  framework: {
    displayName,
    id,
    percentageCompleted,
    questionsCount,
    questionsFilledCount,
    standard: { id: standardId, version, year, deletable },
    hasEvidenceLockerEntity,
  },
  hideFramework,
  isHidden,
  selectFramework,
  unhideFramework,
  showHidden,
  user: { company },
  getFormAction,
  history: { push: navigate },
}: FrameworkPropsType) => {
  const handleClickToViewForm = () => {
    if (framework.ownerId === company.id) {
      navigate(route(FORM, { id }));
    } else {
      getFormAction(standardId);
    }
  };
  const handleHideClick = () =>
    isHidden ? unhideFramework(id) : hideFramework(id);
  const handleShareClick = () => selectFramework(framework);

  const handleDeleteClick = () => deleteForm(id);
  return (
    <Asset
      showHidden={showHidden}
      isHidden={isHidden}
      hasEvidenceLockerEntity={hasEvidenceLockerEntity}
      body={
        <FrameworkBody data-cy={'framework'} percentage={percentageCompleted}>
          <Label onClick={handleClickToViewForm}>
            {textTruncate(`${displayName} ${version}`, 70)}
          </Label>
          <Year>{year}</Year>
        </FrameworkBody>
      }
      left={
        <Graph percentage={percentageCompleted}>
          <Completion percentage={percentageCompleted} />

          <Percentage>{percentageCompleted}%</Percentage>

          <Questions>
            {questionsFilledCount}/{questionsCount}
          </Questions>
        </Graph>
      }
      handleHide={handleHideClick}
      handleShare={handleShareClick}
      handleDelete={deletable ? handleDeleteClick : undefined}
    />
  );
};
const mapDispatchToProps = (dispatch: Dispatch<ActionType>) => ({
  getFormAction: (id: string): void => {
    dispatch(getMasterForm(id));
  },
});

export default connect(null, mapDispatchToProps)(withRouter(memo(Framework)));
