import React from 'react';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import {
  ColumnTitle,
  QuantityColumnWrapper,
  TextInput,
} from './CheckoutInput.styles';

export interface QuantityColumnType extends TranslatePropsType {
  quantity: number | undefined;
  onQuantityRefresh: (quantity: number) => void;
}

const QuantityInputColumn = ({
  quantity,
  onQuantityRefresh,
  t,
}: QuantityColumnType) => (
  <QuantityColumnWrapper>
    <ColumnTitle>{t('quantity') as string}</ColumnTitle>

    <TextInput
      type="text"
      value={(quantity || '').toString()}
      onChange={e => onQuantityRefresh(parseInt(e.target.value, 10))}
    />
  </QuantityColumnWrapper>
);

export default withTranslation('CreditsSelfCheckout')(QuantityInputColumn);
