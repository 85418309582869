import {
  Uuid,
  ResponseDefinitionSummaryType,
} from '../../../common/common.types';
import uuid from 'utils/uuid';
import { createResponse, getCountOfResponsesInQuestion } from '../utils';
import update from 'immutability-helper';
import { ResponseTypeDefinitionResponseTypeEnum } from '../../../generated/models/ResponseTypeDefinition';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';

export type AddResponseFunctionType = (params: AddResponseParamsType) => void;

export type AddResponseActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/ADD_RESPONSE'
>;

export interface AddResponseParamsType {
  responseType: ResponseTypeDefinitionResponseTypeEnum;
  options: string[];
  questionId: Uuid;
}

export const addResponse = ({
  responseType,
  options,
  questionId,
}: AddResponseParamsType): AddResponseActionType => {
  const responseUuid = uuid();
  return {
    type: '@formCreator/EVENTS/ADD_RESPONSE',
    payload: {
      events: [
        {
          action: FormEditEventActionCodeEnum.ADDRESPONSE,
          question_uuid: questionId,
          response_uuid: responseUuid,
        },
      ],
      selector: ({ formCreator, formCreator: { currentForm } }) => {
        if (!currentForm) {
          return formCreator;
        }

        const pos = getCountOfResponsesInQuestion(
          currentForm.questions,
          questionId,
        );
        const newResponse: ResponseDefinitionSummaryType = createResponse(
          responseType,
          responseUuid,
          pos,
          options,
        );
        const questionIndex = currentForm.questions.findIndex(
          ques => ques.wizardId === questionId,
        );

        return update(formCreator, {
          currentForm: {
            questions: {
              [questionIndex]: {
                responses: {
                  $push: [newResponse],
                },
              },
            },
          },
        });
      },
    },
  };
};

// TODO: Rewrite as optimisticAction
