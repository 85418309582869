import Notification from './Notification';
import React from 'react';
import getNotifications from './utils';
import { ActionType, StateType } from 'store/store.types';
import { DismissedNotifications } from 'store/settings/settings.reducer';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import {
  CurrentUserType,
  NotificationEnum,
  TranslatePropsType,
} from 'common/common.types';
import { Wrapper } from './Notifications.styles';
import { appealCredits as appealCreditsAction } from 'store/support/actions';
import { connect } from 'react-redux';
import { getDismissedNotifications } from 'store/settings/settings.selectors';
import { setSettings } from 'store/settings/actions';
import { withTranslation } from 'react-i18next';
import { getPricingInfo, openModal } from 'store/stripe/actions';
import { getUserFeatureFlags } from 'store/user/user.selectors';

export type DismissNotificationFunctionType = (
  dismissedNotifications: DismissedNotifications,
  notificationType: NotificationEnum,
) => void;

interface NotificationsPropsType
  extends TranslatePropsType,
    RouteComponentProps {
  appealCredits: () => void;
  user: CurrentUserType;
  dismissNotification: DismissNotificationFunctionType;
  dismissedNotifications: DismissedNotifications;
  withOffset: boolean;
  isOptInEnabled: boolean;
}

const Notifications = ({
  appealCredits,
  dismissNotification,
  dismissedNotifications,
  history: { push: navigate },
  location: { pathname },
  user,
  withOffset,
  isOptInEnabled,
  t,
}: NotificationsPropsType) => {
  if (!user.credits) {
    return null;
  }

  const notifications = getNotifications(
    appealCredits,
    dismissedNotifications,
    navigate,
    pathname,
    user,
    t,
    isOptInEnabled,
  );

  return (
    <Wrapper withOffset={withOffset}>
      {notifications.map((notification, key) => (
        <Notification
          key={key}
          dismiss={() =>
            dismissNotification(dismissedNotifications, notification.type)
          }
          {...notification}
        />
      ))}
    </Wrapper>
  );
};

const mapStateToProps = ({ user, settings }: StateType) => ({
  dismissedNotifications: getDismissedNotifications(settings),
  isOptInEnabled: getUserFeatureFlags(user).ATLAS_UI_MIGRATION_FE,
  user: user.currentUser,
});

const mapDispatchToProps = (dispatch: Dispatch<ActionType>) => ({
  appealCredits: () => {
    dispatch(getPricingInfo());
    dispatch(openModal());
    dispatch(appealCreditsAction());
  },
  dismissNotification: (
    dismissedNotifications: DismissedNotifications,
    notificationType: NotificationEnum,
  ) => {
    dispatch(
      setSettings({
        dismissedNotifications: {
          ...dismissedNotifications,
          [notificationType]: new Date().getTime(),
        },
      }),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation('Notifications')(Notifications)));
