import AdaptableSelect from '../AdaptableSelect';
import Evidence from '../Evidence';
import MultiSelect from '../MultiSelect';
import NoResponse from '../NoResponse';
import TextArea from '../Textarea';
import { Numeric, Percentage } from '../Numeric';
import { ResponseTypeDefinitionResponseTypeEnum as ResponseType } from 'generated/models/ResponseTypeDefinition';

export default (type: ResponseType) => {
  switch (type) {
    case ResponseType.EVIDENCE:
      return Evidence;
    case ResponseType.MULTISELECT:
      return MultiSelect;
    case ResponseType.NORESPONSE:
      return NoResponse;
    case ResponseType.NUMBER:
      return Numeric;
    case ResponseType.PERCENTAGE:
      return Percentage;
    case ResponseType.SINGLESELECT:
      return AdaptableSelect;
    case ResponseType.TEXT:
    default:
      return TextArea;
  }
};
