import styled from 'styled-components';
import { BACKGROUND_HEIGHT, HEADER_HEIGHT } from 'common/common.styles';

export const Wrapper = styled.div<{ scrolled: boolean }>`
  position: fixed;
  height: ${BACKGROUND_HEIGHT}px;
  top: ${({ scrolled }) => (scrolled ? `-${HEADER_HEIGHT}px` : '0')};
  left: 0;
  right: 0;
  z-index: 1004;
  transition: top 200ms linear;
`;

export const BackgroundPlaceholder = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: ${BACKGROUND_HEIGHT}px;
  z-index: 2;
  background: ${({ theme }) => theme.basic.colors.extraDark};
`;
