import { SortType } from 'utils/filters/formNewFromExisting.sorters';

export type FormNewFromExistingSetSortFunctionType = (sortBy: SortType) => void;

export interface FormNewFromExistingSetSortActionType {
  type: '@formNewFromExisting/SET_SORT';
  payload: {
    sortBy: SortType;
  };
}

export const setSort = (
  sortBy: SortType,
): FormNewFromExistingSetSortActionType => ({
  type: '@formNewFromExisting/SET_SORT',
  payload: {
    sortBy,
  },
});
