import EvidenceLocker from 'components/EvidenceLocker';
import React from 'react';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import { UserUploadFileFunctionType } from 'store/user/actions/uploadFile';
import { flash } from 'store/flashes/actions';
import {
  getParsedUploads,
  isUploadingOrMapping,
} from 'store/forms/forms.selectors';
import { resetModals } from '../utils/Modal/store/actions/resetModals';
import { resetUpload, uploadForm } from 'store/forms/actions';
import {
  setFileVisibility,
  setFrameworkVisibility,
  setSettings,
} from 'store/settings/actions';
import { startLoading } from 'store/app/actions';
import {
  deleteFile,
  deleteForm,
  getDashboard,
  getFiles,
  getMailingList,
  selectFile,
  uploadFile,
  shareFiles,
  selectFramework,
  shareFrameworks,
  selectTeam,
} from 'store/user/actions';
import {
  getHiddenFiles,
  getHiddenFrameworksIds,
} from 'store/settings/settings.selectors';
import {
  FileType,
  FrameworkType,
  GroupedFrameworks,
  MailingListItemType,
  ParsedFileType,
  UserType,
  Uuid,
} from 'common/common.types';
import {
  getTeams,
  groupedFrameworks,
  selectedFile,
  selectedFramework,
  selectedTeam,
} from 'store/user/user.selectors';
import Page, {
  PageActionsBaseType,
  PageBaseType,
  PageParamType,
  PagePropsType,
  UpdatePathType,
  UpdateQueryType,
} from './Page';
import { Team } from 'generated/new/models';

export interface EvidenceLockerContextType extends PageBaseType {
  data: {
    companyName: string;
    files: FileType[];
    frameworks: GroupedFrameworks;
    hiddenFiles: Uuid[];
    hiddenFrameworks: Uuid[];
    isUploadingForm: boolean;
    mailingList: MailingListItemType[];
    parsedForms: ParsedFileType[];
    selectedFile?: FileType;
    selectedFramework?: FrameworkType;
    selectedTeam?: Team;
    showHidden: boolean;
    teams: Team[];
    user: UserType;
  };
  actions: PageActionsBaseType & {
    closeFileShareModal: () => void;
    closeFrameworkShareModal: () => void;
    deleteFile: (fileId: Uuid) => void;
    deleteForm: (formId: Uuid) => void;
    getFiles: () => void;
    hideFile: (fileId: Uuid) => void;
    hideFramework: (frameworkId: Uuid) => void;
    selectFile: (file: FileType) => void;
    selectFramework: (framework: FrameworkType) => void;
    selectTeam: (team?: Team) => void;
    shareFrameworks: (
      formIds: Uuid[],
      targets: string[],
      expirationDate: Date | null,
    ) => void;
    switchTab: (tab: string) => void;
    toggleHiding: (showHiddenFrameworks: boolean) => void;
    unhideFile: (fileId: Uuid) => void;
    unhideFramework: (frameworkId: Uuid) => void;
    uploadForm: (formData: FormData, teamId?: Uuid) => void;
    uploadFile: UserUploadFileFunctionType;
    invalidFileSize: (text: string) => void;
  };
  routeParams: PageParamType & {
    tab?: string;
  };
}

const EvidenceLockerPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      companyName: state.user.currentUser.company.name,
      files: state.user.files,
      frameworks: groupedFrameworks(state.user),
      hiddenFiles: getHiddenFiles(state.settings),
      hiddenFrameworks: getHiddenFrameworksIds(state.settings),
      isUploadingForm: isUploadingOrMapping(state.forms),
      mailingList: state.user.mailingList.share,
      parsedForms: getParsedUploads(state.forms),
      selectedFile: selectedFile(state.user),
      selectedFramework: selectedFramework(state.user),
      selectedTeam: selectedTeam(state.user),
      showHidden: state.settings.showHidden,
      teams: getTeams(state.user),
      user: state.user.currentUser,
    })}
    dispatches={(
      dispatch: Dispatch<ActionType>,
      ownProps: PagePropsType,
      updateQuery: UpdateQueryType,
      updatePath: UpdatePathType,
    ) => ({
      getData: () => {
        dispatch(startLoading(5));
        dispatch(getMailingList('share'));
        dispatch(getDashboard());
        dispatch(getFiles());
      },
      reset: () => {
        dispatch(resetModals());
        dispatch(resetUpload());
        dispatch(selectTeam(undefined));
      },
      actions: {
        closeFileShareModal: () => dispatch(selectFile(undefined)),
        invalidFileSize: (errorMessage: string) =>
          dispatch(flash(errorMessage, 'error')),
        closeFrameworkShareModal: () => dispatch(selectFramework(undefined)),
        deleteFile: (fileId: Uuid) => dispatch(deleteFile(fileId)),
        deleteForm: (formId: Uuid) => dispatch(deleteForm(formId)),
        getFiles: (teamId?: Uuid) => dispatch(getFiles(teamId)),
        hideFile: (fileId: Uuid) => dispatch(setFileVisibility(fileId, false)),
        hideFramework: (frameworkId: Uuid) =>
          dispatch(setFrameworkVisibility(frameworkId, false)),
        selectFile: (file: FileType) => dispatch(selectFile(file)),
        selectFramework: (framework: FrameworkType) =>
          dispatch(selectFramework(framework)),
        selectTeam: (team?: Team) => {
          dispatch(selectTeam(team));
          dispatch(getDashboard(team ? team.id : undefined));
          dispatch(getFiles(team ? team.id : undefined));
        },
        shareFiles: (
          filesIds: Uuid[],
          targets: string[],
          expirationDate: Date | null,
        ) => dispatch(shareFiles(filesIds, targets, expirationDate)),
        shareFrameworks: (
          formIds: Uuid[],
          targets: string[],
          expirationDate: Date | null,
        ) => dispatch(shareFrameworks(formIds, targets, expirationDate)),
        switchTab: (tab: string) => updatePath({ tab }),
        toggleHiding: (showHidden: boolean) =>
          dispatch(setSettings({ showHidden })),
        unhideFile: (fileId: Uuid) => dispatch(setFileVisibility(fileId, true)),
        unhideFramework: (frameworkId: Uuid) =>
          dispatch(setFrameworkVisibility(frameworkId, true)),
        uploadForm: (formData: FormData, teamId?: Uuid) =>
          dispatch(uploadForm(formData, teamId)),
        uploadFile: (formData: FormData, teamId?: Uuid) =>
          dispatch(uploadFile(formData, teamId)),
      },
    })}
  >
    <EvidenceLocker />
  </Page>
);

export default EvidenceLockerPage;
