/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProxyCollaborationDetail,
    ProxyCollaborationDetailFromJSON,
    ProxyCollaborationDetailFromJSONTyped,
    ProxyCollaborationDetailToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Collaborator
 */
export interface Collaborator {
    /**
     * Can the user answer this form? Is the user VENDOR or VENDOR_PROXY?
     * @type {boolean}
     * @memberof Collaborator
     */
    canAnswer: boolean;
    /**
     * Can the user be removed from the collaborators?
     * @type {boolean}
     * @memberof Collaborator
     */
    canBeRevoked: boolean;
    /**
     * Can the user review this form? Is the user VRM or VRM_PROXY?
     * @type {boolean}
     * @memberof Collaborator
     */
    canReview: boolean;
    /**
     * 
     * @type {ProxyCollaborationDetail}
     * @memberof Collaborator
     */
    proxyDetail: ProxyCollaborationDetail;
    /**
     * 
     * @type {User}
     * @memberof Collaborator
     */
    user: User;
}

export function CollaboratorFromJSON(json: any): Collaborator {
    return CollaboratorFromJSONTyped(json, false);
}

export function CollaboratorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Collaborator {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canAnswer': json['can_answer'],
        'canBeRevoked': json['can_be_revoked'],
        'canReview': json['can_review'],
        'proxyDetail': ProxyCollaborationDetailFromJSON(json['proxy_detail']),
        'user': UserFromJSON(json['user']),
    };
}

export function CollaboratorToJSON(value?: Collaborator | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_answer': value.canAnswer,
        'can_be_revoked': value.canBeRevoked,
        'can_review': value.canReview,
        'proxy_detail': ProxyCollaborationDetailToJSON(value.proxyDetail),
        'user': UserToJSON(value.user),
    };
}


