import { FormsEnableFiltersItemType } from 'utils/filters/forms.filters';

export interface FormsEnableFiltersActionType {
  type: '@forms/ENABLE_FILTERS';
  payload: {
    filters: FormsEnableFiltersItemType[];
  };
}

export const enableFilters = (
  filters: FormsEnableFiltersItemType[],
): FormsEnableFiltersActionType => ({
  type: '@forms/ENABLE_FILTERS',
  payload: {
    filters,
  },
});
