import * as ca from '../store/companies/actions';
import axios from 'axios';
import withUrl from '../utils/url/withUrl';
import { CompanySettingType, Uuid } from '../common/common.types';

export default {
  addCredits: ({ amount, id }: ca.CompaniesAddCreditsCallParamsType) =>
    axios.post(withUrl(`companies/${id}/credits`), { amount }),
  getCompanies: (params: any) => axios.get(withUrl('companies'), { params }),
  getPartners: ({ limit }: ca.CompaniesGetCompaniesCallParamsType) =>
    axios.get(withUrl(`companies/current/partners?limit=${limit}`)),
  getUsers: (params: ca.CompaniesFindUsersParamsType) =>
    axios.get(withUrl('companies/current/users'), { params }),
  getCredits: ({ id }: ca.CompaniesGetCreditsCallParamsType) =>
    axios.get(withUrl(`companies/${id}/credits`)),
  getCompany: ({ id }: ca.CompaniesGetCompanyCallParamsType) =>
    axios.get(withUrl('companies', id)),
  getTeams: ({ id }: ca.CompaniesGetCompanyCallParamsType) =>
    axios.get(withUrl(`${id}/teams`, '', 'organizations')),
  settings: {
    get: () => axios.get(withUrl('companies/current/settings')),
    patch: (params: CompanySettingType) =>
      axios.put(withUrl('companies/current/settings'), { ...params }),
  },
  tags: {
    create: ({ companyId, tagName }: ca.CompaniesAssignTagCallParamsType) =>
      axios.post(withUrl(`companies/${companyId}/tags`), { name: tagName }),
    delete: ({ tagId }: { tagId: Uuid }) =>
      axios.delete(withUrl('tags', tagId)),
    get: () => axios.get(withUrl('tags')),
    remove: ({ tagId, companyId }: ca.CompaniesRemoveTagCallParamsType) =>
      axios.delete(withUrl(`companies/${companyId}/tags`, tagId)),
  },
};
