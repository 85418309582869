import { FormMetadata } from '../../../generated/models/FormMetadata';
import { SupportedFilterOptionType } from 'utils/filters/forms.filters';
import { parseDelegationNotifications, parseFilterStats } from 'utils/parsers';
import { ApiCallActionType } from '../../store.types';
import { Uuid } from '../../../common/common.types';

export type FormsHydrateFiltersActionType = ApiCallActionType<
  '@forms/API/HYDRATE_FILTERS',
  { id: Uuid; filters?: SupportedFilterOptionType[] },
  FormMetadata,
  never,
  any
>;

export const hydrateFilters = (id: Uuid): FormsHydrateFiltersActionType => ({
  type: '@forms/API/HYDRATE_FILTERS',
  payload: {
    callParams: { id },
    endpointPath: ['forms', 'get'],
    selector: (data, state) => ({
      currentForm: {
        ...state.forms.currentForm,
        delegationNotifications: parseDelegationNotifications(
          data.delegation_notification_stats || [],
        ),
      },
      filters: parseFilterStats(
        data.filter_stats,
        data.assignee_stats,
        state.forms,
      ),
    }),
  },
});
