import Company from '../../src/components/Companies/Company';
import Page, {
  PageActionsBaseType,
  PageBaseType,
  PagePropsType,
  UpdatePathType,
  UpdateQueryType,
  PropsType,
} from './Page';
import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import {
  RequestsFormType,
  SendRequestErrorType,
} from 'store/requests/requests.types';
import { StateType, ActionType } from 'store/store.types';
import { appealCredits } from 'store/support/actions';
import { getCurrentSwitcherOption } from 'store/switchers/switchers.selectors';
import { getDetail, hasData } from 'store/companies/companies.selectors';
import { getCompany } from 'store/companies/actions';
import { hideModal, showModal } from '../utils/Modal';
import { resetModals } from '../utils/Modal/store/actions/resetModals';
import { startLoading } from 'store/app/actions';
import { switchView as switchViewAction } from 'store/switchers/actions/switchView';
import {
  FormsGetPreviewFunctionType,
  FormsLoadQuestionsFunctionType,
  FormsPutQuestionFunctionType,
  getPreview,
  loadQuestions,
  putQuestion,
} from 'store/forms/actions';
import {
  resetRequests,
  sendForm,
  setFormValue,
  SetFormValueFunctionType,
} from 'store/requests/actions';
import {
  CompanyDetailType,
  CompanyWithTagsType,
  FormType,
  LanguageType,
  UserType,
  Uuid,
} from 'common/common.types';
import { getPricingInfo, openModal } from 'store/stripe/actions';

export interface CompanyPageContextType extends PageBaseType {
  data: {
    currentSwitcherOption: string;
    data: CompanyDetailType;
    hasData: boolean;
    error: SendRequestErrorType;
    form: any;
    previewForm: FormType;
    user: UserType;
    language: LanguageType;
  };
  actions: PageActionsBaseType & {
    appealCredits: () => void;
    initRequestsForm: (company: CompanyWithTagsType) => void;
    getPreview: FormsGetPreviewFunctionType;
    hideModal: (modalId: string) => void;
    loadQuestions: FormsLoadQuestionsFunctionType;
    putQuestion: FormsPutQuestionFunctionType;
    sendRequest: (data: RequestsFormType, standard: Uuid) => void;
    setValue: SetFormValueFunctionType;
    showModal: (modalId: string) => void;
    switchView: (view: string) => void;
  };
}

const CompanyPage = (props: PropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      currentSwitcherOption: getCurrentSwitcherOption(state.switchers),
      data: getDetail(state.companies),
      form: state.requests.form,
      hasData: hasData(state.companies),
      myTags: state.companies.myTags,
      previewForm: state.forms.currentForm,
      user: state.user.currentUser,
      language: state.settings.languagePreference,
    })}
    dispatches={(
      dispatch: Dispatch<ActionType>,
      ownProps: PagePropsType,
      updateQuery: UpdateQueryType,
      updatePath: UpdatePathType,
    ) => ({
      getData: ({
        routeParams: { id, tab },
      }: {
        routeParams: { id: Uuid; tab?: string };
      }) => {
        dispatch(startLoading(1));
        dispatch(getCompany(id));
        if (tab) {
          dispatch(switchViewAction(tab));
        }
      },
      reset: () => {
        dispatch(resetRequests());
        dispatch(resetModals());
      },
      actions: {
        ...bindActionCreators(
          {
            showModal,
            hideModal,
            getPreview,
            setValue: setFormValue,
            loadQuestions,
            putQuestion,
          },
          dispatch,
        ),
        appealCredits: () => {
          dispatch(getPricingInfo());
          dispatch(openModal());
          dispatch(appealCredits());
        },
        switchView: (view: string) => {
          dispatch(switchViewAction(view));
          updatePath({ tab: view });
        },
        sendRequest: (data: RequestsFormType, standard: Uuid) =>
          dispatch(sendForm(data, [standard])),
        initRequestsForm: (company: CompanyWithTagsType) => {
          const { name, requestsEmail } = company.info;
          dispatch(
            setFormValue('targets', [{ value: requestsEmail, label: name }]),
          );
          dispatch(setFormValue('emailBody', ''));
        },
      },
    })}
  >
    <Company />
  </Page>
);

export default CompanyPage;
