import {
  FormDefinitionSummaryDraftExtendedType,
  FormDefinitionDraftCategoryStatsType,
  FormDefinitionType,
  FormDefinitionValidationType,
  QuestionDefinitionInvalidEntityErrorSummary,
  ResponseDefinitionInvalidEntityErrorSummary,
} from 'common/common.types';
import { FormDefinitionDraftCategoryStats } from 'generated/models/FormDefinitionDraftCategoryStats';
import { parseQuestionDefinitionSummary } from './question.parsers';
import { FormDefinitionSummaryDraftExtended } from 'generated/models/FormDefinitionSummaryDraftExtended';
import { FormDefinition } from 'generated/models/FormDefinition';
import { parseStandard } from './standard.parsers';
import { FormDefinitionValidateActionSummary } from 'generated/models/FormDefinitionValidateActionSummary';
import {
  FormDefinitionError,
  ResponseError,
  QuestionError,
} from 'store/formCreator/validationError.types';
import unreachableReturn from 'utils/unreachableReturn';
import {
  FormDefinitionEntityError,
  FormDefinitionEntityErrorCodeEnum,
} from 'generated/models/FormDefinitionEntityError';
import {
  ResponseDefinitionEntityErrorCodeEnum,
  ResponseDefinitionEntityError,
} from 'generated/models/ResponseDefinitionEntityError';
import {
  QuestionDefinitionEntityErrorCodeEnum,
  QuestionDefinitionEntityError,
} from 'generated/models/QuestionDefinitionEntityError';
// tslint:disable-next-line:max-line-length
import { QuestionDefinitionInvalidEntityErrorSummary as QuestionErrorSummary } from 'generated/models/QuestionDefinitionInvalidEntityErrorSummary';
// tslint:disable-next-line:max-line-length
import { ResponseDefinitionInvalidEntityErrorSummary as ResponseErrorSummary } from 'generated/models/ResponseDefinitionInvalidEntityErrorSummary';
import { EMPTY_ID } from 'common/common.constants';

export const parseFormDefinition = (
  data: FormDefinition,
): FormDefinitionType => {
  const {
    created_at = 0,
    updated_at = 0,
    description = '',
    id = EMPTY_ID,
    name = '',
    owner_id = EMPTY_ID,
    standard_id = EMPTY_ID,
    with_condition_count = 0,
  } = data;

  return {
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    description,
    id,
    name,
    ownerId: owner_id,
    standardId: standard_id,
    withConditionCount: with_condition_count,
  };
};

export const parseFormDefinitionDraftCategories = (
  data: FormDefinitionDraftCategoryStats[],
): FormDefinitionDraftCategoryStatsType[] =>
  data.map(({ name = '', pos = 0, question_count = 0 }) => ({
    name,
    pos,
    questionCount: question_count,
  }));

export const parseFormDefinitionSummaryDraftExtended = (
  data: FormDefinitionSummaryDraftExtended,
): FormDefinitionSummaryDraftExtendedType => {
  const {
    created_at = 0,
    updated_at = 0,
    description = '',
    id = EMPTY_ID,
    name = '',
    owner_id = EMPTY_ID,
    standard,
    with_condition_count = 0,
    categories = [],
    questions = [],
    next_event_token = '',
    creation_status,
  } = data;

  return {
    createdAt: new Date(created_at),
    updatedAt: new Date(updated_at),
    description,
    id,
    name,
    ownerId: owner_id,
    standard: parseStandard(standard),
    withConditionCount: with_condition_count,
    categories: parseFormDefinitionDraftCategories(categories),
    questions: questions.map(parseQuestionDefinitionSummary),
    nextEventToken: next_event_token,
    creationStatus: creation_status,
  };
};

const parseFormDefinitionError = ({
  code,
}: FormDefinitionEntityError): FormDefinitionError => {
  switch (code) {
    case FormDefinitionEntityErrorCodeEnum.NOVALIDQUESTION:
      return { code };
    default:
      return unreachableReturn(code, {
        code: 'UNKNOWN_ERROR',
      });
  }
};

const parseQuestionError = ({
  code,
}: QuestionDefinitionEntityError): QuestionError => {
  switch (code) {
    case QuestionDefinitionEntityErrorCodeEnum.CONDITIONREFERENCEINVALIDQUESTION:
      return { code };
    case QuestionDefinitionEntityErrorCodeEnum.CONDITIONREFERENCELOWER:
      return { code };
    case QuestionDefinitionEntityErrorCodeEnum.CONDITIONREFERENCENOTFOUND:
      return { code };
    case QuestionDefinitionEntityErrorCodeEnum.CONDITIONREFERENCESELF:
      return { code };
    case QuestionDefinitionEntityErrorCodeEnum.CONDITIONSTRUCTUREDWRONGFORMAT:
      return { code };
    case QuestionDefinitionEntityErrorCodeEnum.CONDITIONSYNTAXERROR:
      return { code };
    case QuestionDefinitionEntityErrorCodeEnum.CONDITIONUUIDNOTFOUND:
      return { code };
    case QuestionDefinitionEntityErrorCodeEnum.CONDITIONWRONGVALUE:
      return { code };
    case QuestionDefinitionEntityErrorCodeEnum.DUPLICATEDQUESTIONCODE:
      return { code };
    case QuestionDefinitionEntityErrorCodeEnum.EMPTYQUESTION:
      return { code };
    case QuestionDefinitionEntityErrorCodeEnum.EMPTYQUESTIONCODE:
      return { code };
    case QuestionDefinitionEntityErrorCodeEnum.NORESPONSES:
      return { code };
    default:
      return unreachableReturn(code, {
        code: 'UNKNOWN_ERROR',
      });
  }
};

const parseResponseError = ({
  code,
}: ResponseDefinitionEntityError): ResponseError => {
  switch (code) {
    case ResponseDefinitionEntityErrorCodeEnum.DUPLICATEDOPTIONVALUE:
      return { code };
    case ResponseDefinitionEntityErrorCodeEnum.EMPTYOPTIONVALUE:
      return { code };
    case ResponseDefinitionEntityErrorCodeEnum.LESSTHANTWOOPTIONSINRESPONSE:
      return { code };
    default:
      return unreachableReturn(code, {
        code: 'UNKNOWN_ERROR',
      });
  }
};

const parseQuestionDefinitionInvalidEntityErrorSummary = ({
  question_uuid = EMPTY_ID,
  errors = [],
}: QuestionErrorSummary): QuestionDefinitionInvalidEntityErrorSummary => ({
  questionUuid: question_uuid,
  errors: errors.map(parseQuestionError),
});

const parseResponseDefinitionInvalidEntityErrorSummary = ({
  question_uuid = EMPTY_ID,
  response_uuid = EMPTY_ID,
  errors = [],
}: ResponseErrorSummary): ResponseDefinitionInvalidEntityErrorSummary => ({
  questionUuid: question_uuid,
  responseUuid: response_uuid,
  errors: errors.map(parseResponseError),
});

export const parseFormDefinitionValidateActionSummary = (
  data: FormDefinitionValidateActionSummary,
): FormDefinitionValidationType => {
  const {
    form_errors = [],
    invalid_questions = [],
    invalid_responses = [],
    correct = false,
  } = data;
  const formErrors = form_errors.map(parseFormDefinitionError);
  const invalidQuestions = invalid_questions.map(
    parseQuestionDefinitionInvalidEntityErrorSummary,
  );
  const invalidResponses = invalid_responses.map(
    parseResponseDefinitionInvalidEntityErrorSummary,
  );

  return {
    attemptedFixes: {},
    formErrors,
    invalidQuestions,
    invalidResponses,
    isLoading: false,
    correct,
  };
};
