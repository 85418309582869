import { StateType } from '../store.types';
import { assocPath, is, isNil } from 'ramda';
import { RequestsFormType } from './requests.types';
import {
  Uuid,
  ValueLabelType,
  ToggleEnum,
  CurrentUserType,
} from '../../common/common.types';
import { addMinutes, isAfter, startOfDay } from 'date-fns';
import { hasLdToggle } from 'utils/hasToggle';

export const updateSendRequestStateError = (
  { balance, cost }: any,
  state: StateType,
) =>
  !isNil(balance) && !isNil(cost) && is(Number, balance) && is(Number, cost)
    ? assocPath(
        ['sendRequest', 'error'],
        {
          balance,
          cost,
        },
        state.requests,
      )
    : null;

const normalizeSendDate = (sendDate: Date) => {
  let date = sendDate;
  if (isAfter(sendDate, new Date())) {
    date = addMinutes(
      // to make clear what 10th at midnight means
      startOfDay(sendDate), // shifted by 1 minute: clear it's 10th at 0:01
      1,
    );
  }
  return date;
};

export const getEffectiveSourceTeam = (
  chosenTeam: ValueLabelType<string> | undefined,
  currentUser: CurrentUserType,
): Uuid | undefined => {
  if (!hasLdToggle(currentUser, [ToggleEnum.INTERNAL_USECASE])) {
    return undefined;
  }
  if (chosenTeam && chosenTeam.value === 'me') {
    return undefined;
  }
  return chosenTeam
    ? chosenTeam.value
    : currentUser.teams.length === 1
    ? currentUser.teams[0].id
    : undefined;
};

export const getCommonRequestCallParams = (
  { dueDate, emailBody, notify, reminder, sourceTeam }: RequestsFormType,
  standardIds: Uuid[],
) => ({
  dueDate,
  emailBody,
  notify,
  reminder: dueDate ? reminder.value : undefined,
  standardIds,
});

export const getCommonScheduleRequestCallParams = (
  {
    dueDate,
    emailBody,
    notify,
    recurrence,
    reminder,
    sendDate,
    sourceTeam,
  }: RequestsFormType,
  standardIds: Uuid[],
) => ({
  dueDate,
  emailBody,
  occurrences: recurrence.occurrences || undefined,
  notify,
  recurrenceType: recurrence.type,
  recurrenceUnits: recurrence.unit,
  reminderDays: dueDate && reminder.value ? reminder.value : undefined,
  sendDate: normalizeSendDate(sendDate),
  standardIds,
});
