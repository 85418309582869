import { ApiCallActionType } from '../../store.types';
import { DownloadsStateType } from '../downloadItems.reducer';
import { Evidence } from 'generated/models/Evidence';
import { put } from 'redux-saga/effects';
import { setResourceGone } from '../../forms/actions/setResourceGone';

export type GetEvidenceParams = {
  id: string;
};

export interface ResourceGoneException {
  error: {
    sender: {
      name: string;
    };
    statusOfResource: string;
  };
}

export type DownloadsGetEvidenceActionType = ApiCallActionType<
  '@downloads/API/GET_EVIDENCE',
  GetEvidenceParams,
  Evidence,
  never,
  DownloadsStateType,
  never
>;

export const getEvidenceForDownload = (
  id: string,
): DownloadsGetEvidenceActionType => ({
  type: '@downloads/API/GET_EVIDENCE',
  payload: {
    callParams: { id },
    endpointPath: ['evidences', 'getEvidence'],
    selector: (data, state): DownloadsStateType => {
      const downloadState: DownloadsStateType = {
        id: data.id,
        entityType: 'evidence',
      };
      return downloadState;
    },
    resourceGoneHandlers: [
      ({ error }: ResourceGoneException) =>
        put(setResourceGone(error.sender.name, error.statusOfResource)),
    ],
  },
});
