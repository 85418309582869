import { NotificationEnum } from '../../../common/common.types';
import { setSettings } from './setSettings';

export const dismissAllNotifications = () => {
  const now = new Date().getTime();

  // Save to settings that we have just dismissed all types of notifications
  const dismissedNotifications = {};
  for (const notificationTypeKey in NotificationEnum) {
    if (isNaN(Number(notificationTypeKey))) {
      dismissedNotifications[NotificationEnum[notificationTypeKey]] = now;
    }
  }

  return setSettings({ dismissedNotifications });
};
