import { ApiCallActionType } from '../../store.types';
import { CompanyPartner } from '../../../generated/models';
import { parseListedCompany } from 'utils/parsers';
import { put } from 'redux-saga/effects';
import { refreshFilter } from './refreshFilter';
import { CompaniesStateType } from '../companies.reducer';
import { COMPANIES_LOAD_LIMIT } from '../../../common/common.constants';

export interface CompaniesGetCompaniesCallParamsType {
  limit: number;
  search_query?: string;
}

export type CompaniesGetCompaniesActionType = ApiCallActionType<
  '@companies/API/GET_COMPANIES',
  CompaniesGetCompaniesCallParamsType,
  CompanyPartner[],
  never,
  CompaniesStateType
>;

interface CompaniesGetActionParamsType {
  limit?: number;
  shouldRefreshFilter?: boolean;
}

export const getCompanies = ({
  limit = 0,
  shouldRefreshFilter,
}: CompaniesGetActionParamsType): CompaniesGetCompaniesActionType => ({
  type: '@companies/API/GET_COMPANIES',
  payload: {
    endpointPath: ['companies', 'getPartners'],
    callParams: { limit: limit + COMPANIES_LOAD_LIMIT },
    selector: (data): Partial<CompaniesStateType> => ({
      list: data.map(parseListedCompany),
      listLimit: limit + COMPANIES_LOAD_LIMIT,
    }),
    postActions: shouldRefreshFilter ? [() => put(refreshFilter())] : undefined,
  },
});
