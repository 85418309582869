/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutocompleteStats
 */
export interface AutocompleteStats {
    /**
     * Is there any MappingExecution in progress?
     * @type {boolean}
     * @memberof AutocompleteStats
     */
    mappingInProgress: boolean;
    /**
     * How many questions could have a match?
     * @type {number}
     * @memberof AutocompleteStats
     */
    matchingQuestionsCount?: number;
}

export function AutocompleteStatsFromJSON(json: any): AutocompleteStats {
    return AutocompleteStatsFromJSONTyped(json, false);
}

export function AutocompleteStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutocompleteStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mappingInProgress': json['mapping_in_progress'],
        'matchingQuestionsCount': !exists(json, 'matching_questions_count') ? undefined : json['matching_questions_count'],
    };
}

export function AutocompleteStatsToJSON(value?: AutocompleteStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mapping_in_progress': value.mappingInProgress,
        'matching_questions_count': value.matchingQuestionsCount,
    };
}


