/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreditsChangeSummary,
    CreditsChangeSummaryFromJSON,
    CreditsChangeSummaryFromJSONTyped,
    CreditsChangeSummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreditsChangeSummaryMeta
 */
export interface CreditsChangeSummaryMeta {
    /**
     * Data related to state changes
     * @type {CreditsChangeSummary}
     * @memberof CreditsChangeSummaryMeta
     */
    creditsChangeSummary?: CreditsChangeSummary;
}

export function CreditsChangeSummaryMetaFromJSON(json: any): CreditsChangeSummaryMeta {
    return CreditsChangeSummaryMetaFromJSONTyped(json, false);
}

export function CreditsChangeSummaryMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditsChangeSummaryMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creditsChangeSummary': !exists(json, 'credits_change_summary') ? undefined : CreditsChangeSummaryFromJSON(json['credits_change_summary']),
    };
}

export function CreditsChangeSummaryMetaToJSON(value?: CreditsChangeSummaryMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credits_change_summary': CreditsChangeSummaryToJSON(value.creditsChangeSummary),
    };
}


