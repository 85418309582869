export interface AddParsedFilesActionType {
  type: '@standard/ADD_PARSED_FILES';
  payload: {
    parsedFiles: any;
  };
}

export const addParsedFiles = (parsedFiles: any): AddParsedFilesActionType => ({
  type: '@standard/ADD_PARSED_FILES',
  payload: {
    parsedFiles,
  },
});
