import styled from 'styled-components';
import {
  CancelButton as OriginalCancelButton,
  Wrapper as OriginalWrapper,
  Paragraph as OriginalParagraph,
} from '../../common/ConfirmModal/ConfirmModal.styles';

const ISSUE_HEIGHT = 17;

export const Wrapper = styled(OriginalWrapper)`
  width: 524px;
  height: auto;
`;

export const CancelButton = styled(OriginalCancelButton)`
  max-width: 200px;
`;

export const List = styled.ul`
  list-style-position: inside;
  max-height: ${ISSUE_HEIGHT * 10}px;
  overflow-y: auto;

  > li {
    line-height: ${ISSUE_HEIGHT}px;
    ${({ theme }) => theme.fonts.regular.small}
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 110px;
`;

export const Paragraph = styled(OriginalParagraph)`
  width: 100%;
`;
