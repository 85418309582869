import Companies from '../../src/components/Companies/Companies';
import Page, { PageActionsBaseType, PageBaseType, PagePropsType } from './Page';
import React from 'react';
import { ActionType, StateType } from 'store/store.types';
import { CompaniesFilterType } from 'store/companies/companies.reducer';
import { CompanyGradeEnum } from 'generated/models/Company';
import { Dispatch } from 'redux';
import { clearAutocomplete, findCompany } from 'store/companies/actions';
import { getCurrentSwitcherOption } from 'store/switchers/switchers.selectors';
import { startLoading } from 'store/app/actions';
import {
  ListedCompanyType,
  TagType,
  AutocompleteCompanyType,
} from 'common/common.types';
import {
  addTagToFilter,
  getCompanies,
  getTags as getTagsAction,
  removeTagFromFilter,
  resetTagFilter,
  setFilterSelection,
} from 'store/companies/actions/index';

interface CompaniesFilterActionsType {
  addTag: (tag: TagType) => void;
  removeTag: (tag: TagType) => void;
  reset: () => void;
  setSelection: (
    tag?: string,
    grade?: CompanyGradeEnum,
    hover?: boolean,
  ) => void;
}

export interface CompaniesPageContextType extends PageBaseType {
  data: {
    autocomplete: AutocompleteCompanyType[];
    currentSwitcherOption: string;
    displayedCompanies: ListedCompanyType[];
    filter: CompaniesFilterType;
    limit: number;
    myTags: TagType[];
  };
  actions: PageActionsBaseType & {
    clearAutocomplete: () => void;
    findCompany: (prefix: string) => void;
    filterActions: CompaniesFilterActionsType;
    loadMore: (limit: number) => void;
    navigate: any;
  };
}

const CompaniesPage = (props: PagePropsType): any => (
  <Page
    dataSelector={(state: StateType) => ({
      autocomplete: state.companies.autocomplete,
      currentSwitcherOption: getCurrentSwitcherOption(state.switchers),
      displayedCompanies: state.companies.filter.selection.result,
      filter: state.companies.filter,
      limit: state.companies.listLimit,
      myTags: state.companies.myTags,
    })}
    dispatches={(dispatch: Dispatch<ActionType>, ownProps: PagePropsType) => ({
      getData: () => {
        dispatch(startLoading(2));
        dispatch(getCompanies({ shouldRefreshFilter: true }));
        dispatch(getTagsAction());
      },
      actions: {
        clearAutocomplete: () => dispatch(clearAutocomplete()),
        findCompany: (query: string) => dispatch(findCompany({ query })),
        filterActions: {
          addTag: (tag: TagType) => dispatch(addTagToFilter(tag)),
          removeTag: (tag: TagType) => dispatch(removeTagFromFilter(tag)),
          reset: () => dispatch(resetTagFilter()),
          setSelection: (
            tagName?: string,
            grade?: CompanyGradeEnum,
            hover?: boolean,
          ) => dispatch(setFilterSelection(tagName, grade, hover)),
        },
        loadMore: (limit: number) =>
          dispatch(getCompanies({ limit, shouldRefreshFilter: true })),
        navigate: ownProps.history.push,
      },
    })}
  >
    <Companies />
  </Page>
);

export default CompaniesPage;
