import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { RequestType, TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { Button } from '../../common/Button';
import { RequestSummaryStatusEnum } from 'generated/models';
import { FormsPutRequestFunctionType } from 'store/forms/actions';

const { VRMINPROGRESS } = RequestSummaryStatusEnum;

interface RequestEvaluationPropsType extends TranslatePropsType {
  isReviewable: boolean;
  isSubmittable: boolean;
  isValid?: boolean;
  putRequest: FormsPutRequestFunctionType;
  request: RequestType;
  showConfirm: (id: string) => void;
  showSendBack: (value: any) => void;
}

const RequestEvaluation = ({
  isReviewable,
  isSubmittable,
  isValid,
  putRequest,
  request: {
    id,
    meta: { senderCompanyOrTeam, receiverCompanyOrTeam },
  },
  showConfirm,
  showSendBack,
  t,
}: RequestEvaluationPropsType) => {
  const handleSubmit = () => {
    if (!isValid) {
      return;
    }

    putRequest(id, VRMINPROGRESS);
  };

  return (
    <>
      {isSubmittable && (
        <Button
          onClick={handleSubmit}
          color={isValid ? 'teal' : 'gray'}
          disabled={!isValid}
          title={!isValid ? t('common:labels.fillAll') : ''}
          id="form-submit"
        >
          {ReactHtmlParser(t('submitHTML', { name: senderCompanyOrTeam }))}
        </Button>
      )}

      {isReviewable && (
        <>
          <Button id="form-return" onClick={() => showSendBack({ id: id })}>
            {ReactHtmlParser(
              t('sendBackHTML', { name: receiverCompanyOrTeam }),
            )}
          </Button>

          <Button
            id="form-accept"
            onClick={() => showConfirm(id)}
            margin="0 0 0 24px"
          >
            {t('accept') as string}
          </Button>
        </>
      )}
    </>
  );
};

export default withTranslation(['FormFooter', 'common'])(RequestEvaluation);
