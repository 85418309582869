import { FormsGetFormActionType } from './getForm';
import { getFiltersCallParam } from '../../../components/Form/utils';
import { parseFilterStats } from 'utils/parsers/forms.parsers';
import { parseForm } from 'utils/parsers';
import { pathOr, dissoc } from 'ramda';
import { Uuid, QuestionTabEnum } from '../../../common/common.types';
import { FormsStateType } from '../forms.reducer';
import { put } from 'redux-saga/effects';
import { switchQuestionTab } from '.';

export type FormsGetFormWithoutResetPostActionsType = {
  thenSwitchQuestionTab?: QuestionTabEnum;
};

export type FormsGetFormWithoutResetFunctionType = (
  id?: Uuid,
  postActions?: FormsGetFormWithoutResetPostActionsType,
) => void;

export const getFormWithoutReset = (
  id?: Uuid,
  { thenSwitchQuestionTab }: FormsGetFormWithoutResetPostActionsType = {},
): FormsGetFormActionType => ({
  type: '@forms/API/FETCH',
  payload: {
    callParams: state => ({
      id: id ? id : pathOr(undefined, ['forms', 'currentForm', 'id'], state),
      ...getFiltersCallParam(state.forms.filters),
    }),
    endpointPath: ['forms', 'get'],
    selector: (data, state): Partial<FormsStateType> => ({
      canUpdateView: false,
      currentForm: {
        ...parseForm(data, state),
        ...dissoc('questions', parseForm(data, state)),
      },
      filters: {
        ...state.forms.filters,
        ...parseFilterStats(
          data.filter_stats,
          data.assignee_stats,
          state.forms,
        ),
      },
    }),
    postActions: thenSwitchQuestionTab
      ? [() => put(switchQuestionTab(thenSwitchQuestionTab))]
      : [],
  },
});
