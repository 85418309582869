import { contains, evolve, mapObjIndexed } from 'ramda';
import { RequestSimpleType, RequestDirectionType } from 'common/common.types';
import {
  RequestCategoryType,
  RequestsCategoryType,
  RequestsStateType,
} from 'store/requests/requests.types';
// the maximum valid getTime value supported by ECMAScript Date (Jan 25, 29349)
// http://ecma-international.org/ecma-262/5.1/#sec-15.9.1.1
const UNDEFINED_DUE_DATE_GET_TIME_VALUE = 8640000000000000;

export const SORT_OPTIONS: SortByType[] = [
  'COMPANY_SOURCE',
  'COMPANY_TARGET',
  'STANDARD',
  'COMPLETION',
  'SCORE',
  'DUE',
  'REQUESTED',
  'UPDATED',
];

export type SortByType =
  | 'COMPANY_SOURCE'
  | 'COMPANY_TARGET'
  | 'STANDARD'
  | 'COMPLETION'
  | 'SCORE'
  | 'DUE'
  | 'REQUESTED'
  | 'UPDATED';

export type SortDirectionType = 'ASC' | 'DESC';

export const reverseDirection = (direction: SortDirectionType) =>
  direction === 'ASC' ? 'DESC' : 'ASC';

export interface SortType {
  by: SortByType;
  direction: SortDirectionType;
}

const getComparableDueDate = (r: RequestSimpleType) =>
  typeof r.dueDate === 'undefined'
    ? UNDEFINED_DUE_DATE_GET_TIME_VALUE
    : r.dueDate.getTime();

const comparatorFunction = ({ by, direction }: SortType) => (
  a: RequestSimpleType,
  b: RequestSimpleType,
) => {
  const comparators = {
    COMPANY_SOURCE_ASC: a.source.name.localeCompare(b.source.name),
    COMPANY_SOURCE_DESC: b.source.name.localeCompare(a.source.name),
    COMPANY_TARGET_ASC: a.target.name.localeCompare(b.target.name),
    COMPANY_TARGET_DESC: b.target.name.localeCompare(a.target.name),
    STANDARD_ASC: a.standard.name.localeCompare(b.standard.name),
    STANDARD_DESC: b.standard.name.localeCompare(a.standard.name),
    DUE_ASC: getComparableDueDate(a) - getComparableDueDate(b),
    DUE_DESC: getComparableDueDate(b) - getComparableDueDate(a),
    UPDATED_ASC: a.updatedAt.getTime() - b.updatedAt.getTime(),
    UPDATED_DESC: b.updatedAt.getTime() - a.updatedAt.getTime(),
    REQUESTED_ASC: a.requestedAt.getTime() - b.requestedAt.getTime(),
    REQUESTED_DESC: b.requestedAt.getTime() - a.requestedAt.getTime(),
    COMPLETION_ASC: a.meta.completedPercentage - b.meta.completedPercentage,
    COMPLETION_DESC: b.meta.completedPercentage - a.meta.completedPercentage,
    SCORE_ASC: a.form.score - b.form.score,
    SCORE_DESC: b.form.score - a.form.score,
  };

  return comparators[`${by}_${direction}`] || 0;
};

export const sortRequestArray = (sortBy: SortType) => (
  requests: RequestSimpleType[],
) => requests.sort(comparatorFunction(sortBy));

export const sortRequestCategories = (sortBy: SortType) => (
  categories: RequestsCategoryType,
): RequestsCategoryType =>
  // @ts-ignore
  mapObjIndexed(
    (category: RequestCategoryType) => ({
      ...category,
      requests: sortRequestArray(sortBy)(category.requests),
    }),
    categories,
  );

const ensureCorrectFormat = ({
  by = 'UPDATED',
  direction = 'DESC',
}: SortType): SortType => ({
  by: contains(by, SORT_OPTIONS) ? by : 'UPDATED',
  direction: direction === 'DESC' ? 'DESC' : 'ASC',
});

export const sortRequests = (
  { by = 'UPDATED', direction = 'DESC' }: SortType,
  requestsDirection: RequestDirectionType,
) => (requests: RequestsStateType) => {
  const sortBy = ensureCorrectFormat({ by, direction });

  return evolve(
    {
      [requestsDirection]: {
        filter: {
          sortBy: () => sortBy,
        },
        categories: sortRequestCategories(sortBy),
      },
    },
    // @ts-ignore
    requests,
  );
};

export const sortArchivedRequests = ({
  by = 'UPDATED',
  direction = 'DESC',
}: SortType) => (requests: RequestsStateType) => {
  const sortBy = ensureCorrectFormat({ by, direction });

  return evolve(
    {
      archived: {
        filter: {
          sortBy: () => sortBy,
        },
        data: sortRequestArray(sortBy),
      },
    },
    // @ts-ignore
    requests,
  );
};
