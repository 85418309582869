import styled, { css } from 'styled-components/macro';
import hex2rgba from 'utils/hex2rgba';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faCopy,
  faFileUpload,
} from '@fortawesome/free-solid-svg-icons';

export const Heading = styled.div`
  ${({ theme }) => theme.basic.fontWeights.bold};
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 70px;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 20px;
  color: ${({ theme }) => theme.atlas.teal.primary};

  border: 1px solid ${({ theme }) => theme.atlas.teal.primary};
  border-radius: 6px;

  &:hover {
    background-color: ${({ theme }) => hex2rgba(theme.atlas.teal.primary, 0.1)};
    cursor: pointer;
  }
`;

export const OptionText = styled.div`
  font-size: ${({ theme }) => theme.basic.fontSizes.big};
  ${({ theme }) => theme.basic.fontWeights.bold};
  margin-left: 20px;
`;

const iconSize = css`
  font-size: 52px;
`;

export const UseExistingIcon: any = styled(FontAwesomeIcon).attrs(() => ({
  icon: faCopy,
}))`
  ${iconSize}
`;

export const StartFromScratchIcon: any = styled(FontAwesomeIcon).attrs(() => ({
  icon: faPlusCircle,
}))`
  ${iconSize}
`;

export const UploadQuestionnaireIcon: any = styled(FontAwesomeIcon).attrs(
  () => ({
    icon: faFileUpload,
  }),
)`
  ${iconSize}
`;
