/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface MappingExecution
 */
export interface MappingExecution {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof MappingExecution
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof MappingExecution
     */
    updatedAt: Date;
    /**
     * Mapping algorithm
     * @type {string}
     * @memberof MappingExecution
     */
    algorithm: MappingExecutionAlgorithmEnum;
    /**
     * 
     * @type {string}
     * @memberof MappingExecution
     */
    id: string;
    /**
     * Owner's company ID
     * @type {string}
     * @memberof MappingExecution
     */
    ownerId: string;
    /**
     * Status for mapping execution
     * @type {string}
     * @memberof MappingExecution
     */
    status: MappingExecutionStatusEnum;
    /**
     * What has triggered the execution.
     * @type {string}
     * @memberof MappingExecution
     */
    trigger: MappingExecutionTriggerEnum;
    /**
     * Associated UploadAndMapRequest
     * @type {string}
     * @memberof MappingExecution
     */
    uamrId?: string;
    /**
     * User who has triggered the mapping
     * @type {string}
     * @memberof MappingExecution
     */
    userId?: string;
}

export function MappingExecutionFromJSON(json: any): MappingExecution {
    return MappingExecutionFromJSONTyped(json, false);
}

export function MappingExecutionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MappingExecution {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'algorithm': json['algorithm'],
        'id': json['id'],
        'ownerId': json['owner_id'],
        'status': json['status'],
        'trigger': json['trigger'],
        'uamrId': !exists(json, 'uamr_id') ? undefined : json['uamr_id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
    };
}

export function MappingExecutionToJSON(value?: MappingExecution | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'algorithm': value.algorithm,
        'id': value.id,
        'owner_id': value.ownerId,
        'status': value.status,
        'trigger': value.trigger,
        'uamr_id': value.uamrId,
        'user_id': value.userId,
    };
}

/**
* @export
* @enum {string}
*/
export enum MappingExecutionAlgorithmEnum {
    FUZZY = 'FUZZY',
    ML = 'ML'
}
/**
* @export
* @enum {string}
*/
export enum MappingExecutionStatusEnum {
    FINISHEDFAIL = 'FINISHED_FAIL',
    FINISHEDSUCCESS = 'FINISHED_SUCCESS',
    INPROGRESS = 'IN_PROGRESS'
}
/**
* @export
* @enum {string}
*/
export enum MappingExecutionTriggerEnum {
    INIT = 'INIT',
    MASTER = 'MASTER',
    NEWCOMPANY = 'NEW_COMPANY',
    PREVIEW = 'PREVIEW',
    SETTINGS = 'SETTINGS',
    UPLOAD = 'UPLOAD'
}


