import {
  QuestionDefinitionSummaryType,
  StructuredCondition,
  Uuid,
  ResponseDefinitionSummaryType,
  GroupedQuestionItemType,
  ValueLabelType,
  StructuredConditionLine,
  FormCreatorValidatedQuestionType,
} from '../../../common/common.types';
import update from 'immutability-helper';
import uuid from 'utils/uuid';
import { QuestionConditionStructuredItemOperatorEnum } from '../../../generated/models/QuestionConditionStructuredItem';
import { ResponseTypeDefinitionResponseTypeEnum as ResponseType } from '../../../generated/models/ResponseTypeDefinition';
import { contains, findIndex, indexOf, groupBy } from 'ramda';
import { QuestionConditionStructuredOperatorEnum } from '../../../generated/models/QuestionConditionStructured';
import { TFunction } from 'i18next';
import {
  getOption,
  hasNoResponses,
  NUMBER,
  PERCENTAGE,
} from '../../../components/FormCreator/Response/Response.options';
import {
  ConditionLineStateType,
  OperatorItemType,
  PossibleQuestionType,
  ResponseItemType,
} from './structuredCondition.types';
import isInteger from 'utils/isInteger';
import isNumber from 'utils/isNumber';
import { EMPTY_ID } from '../../../common/common.constants';
import unreachableReturn from 'utils/unreachableReturn';

const DEFAULT_OPERATOR = QuestionConditionStructuredItemOperatorEnum.EQUALTO;

const TEXT_OPERATORS = [
  QuestionConditionStructuredItemOperatorEnum.EQUALTO,
  QuestionConditionStructuredItemOperatorEnum.NOTEQUALTO,
  QuestionConditionStructuredItemOperatorEnum.STARTSWITH,
  QuestionConditionStructuredItemOperatorEnum.ENDSWITH,
  QuestionConditionStructuredItemOperatorEnum.CONTAINS,
];

const NUM_PCT_OPERATORS = [
  QuestionConditionStructuredItemOperatorEnum.EQUALTO,
  QuestionConditionStructuredItemOperatorEnum.NOTEQUALTO,
  QuestionConditionStructuredItemOperatorEnum.LESSTHAN,
  QuestionConditionStructuredItemOperatorEnum.GREATERTHAN,
  QuestionConditionStructuredItemOperatorEnum.LESSTHANOREQUALTO,
  QuestionConditionStructuredItemOperatorEnum.GREATERTHANOREQUALTO,
];

const SELECT_OPERATORS = [
  QuestionConditionStructuredItemOperatorEnum.EQUALTO,
  QuestionConditionStructuredItemOperatorEnum.NOTEQUALTO,
];

const EVIDENCE_OPERATORS = TEXT_OPERATORS;

export const getPossibleOperatorsForResponse = ({
  definition: { responseType },
}: ResponseDefinitionSummaryType) => {
  switch (responseType) {
    case ResponseType.EVIDENCE:
      return EVIDENCE_OPERATORS;
    case ResponseType.MULTISELECT:
    case ResponseType.SINGLESELECT:
      return SELECT_OPERATORS;
    case ResponseType.NORESPONSE:
      return [];
    case ResponseType.NUMBER:
    case ResponseType.PERCENTAGE:
      return NUM_PCT_OPERATORS;
    case ResponseType.TEXT:
      return TEXT_OPERATORS;
    default:
      return unreachableReturn(responseType, []);
  }
};

export const isSelectResponse = (response: ResponseDefinitionSummaryType) =>
  getPossibleOperatorsForResponse(response) === SELECT_OPERATORS;

export const getDefaultOperatorForResponse = (
  response: ResponseDefinitionSummaryType,
) => DEFAULT_OPERATOR;

export const isPossibleValue = (
  response: ResponseDefinitionSummaryType,
  value: string,
) => {
  const {
    definition: { responseType, options },
  } = response;
  const isSelectable =
    responseType === ResponseType.SINGLESELECT ||
    responseType === ResponseType.MULTISELECT;
  return isSelectable && options.length > 0 ? contains(value, options) : true;
};

export const createEmpty = (): StructuredCondition => ({
  operator: QuestionConditionStructuredOperatorEnum.AND,
  items: [],
});

export const updateOperator = (
  condition: StructuredCondition,
  operator: QuestionConditionStructuredOperatorEnum,
) =>
  update(condition, {
    operator: { $set: operator },
  });

export const appendEmptyLine = (condition: StructuredCondition) =>
  update(condition, {
    items: {
      $push: [
        {
          id: uuid(),
          operator: QuestionConditionStructuredItemOperatorEnum.EQUALTO,
          questionUuid: '',
          responseUuid: '',
          value: '',
        },
      ],
    },
  });

export const updateQuestion = ({
  condition,
  conditionLineIndex,
  question,
}: {
  condition: StructuredCondition;
  conditionLineIndex: number;
  question: QuestionDefinitionSummaryType;
}): StructuredCondition =>
  update(condition, {
    items: {
      [conditionLineIndex]: {
        $apply: conditionLine => {
          let responseUuid = '';
          if (conditionLine.questionUuid === question.wizardId) {
            responseUuid = conditionLine.responseUuid;
          } else if (question.responses.length === 1) {
            responseUuid = question.responses[0].uniqueId;
          }

          return update(conditionLine, {
            operator: {
              $apply: operator => {
                if (responseUuid) {
                  const response = question.responses.find(
                    r => r.uniqueId === responseUuid,
                  );
                  if (!response) {
                    return operator;
                  }

                  return contains(
                    operator,
                    getPossibleOperatorsForResponse(response),
                  )
                    ? operator
                    : getDefaultOperatorForResponse(response);
                } else {
                  return DEFAULT_OPERATOR;
                }
              },
            },
            questionUuid: { $set: question.wizardId },
            responseUuid: { $set: responseUuid },
          });
        },
      },
    },
  });

export const updateResponse = (
  {
    condition,
    conditionLineIndex,
    questionUuid,
    response,
  }: {
    condition: StructuredCondition;
    conditionLineIndex: number;
    questionUuid: Uuid;
    response: ResponseDefinitionSummaryType;
  },
  d = false,
): StructuredCondition =>
  update(condition, {
    items: {
      [conditionLineIndex]: {
        operator: {
          $apply: operator =>
            contains(operator, getPossibleOperatorsForResponse(response))
              ? operator
              : getDefaultOperatorForResponse(response),
        },
        questionUuid: { $set: questionUuid },
        responseUuid: { $set: response.uniqueId },
      },
    },
  });

export const updateLineOperator = (
  condition: StructuredCondition,
  conditionLineIndex: number,
  operator: QuestionConditionStructuredItemOperatorEnum,
) =>
  update(condition, {
    items: {
      [conditionLineIndex]: {
        operator: { $set: operator },
      },
    },
  });

export const updateValue = (
  condition: StructuredCondition,
  conditionLineIndex: number,
  value: string,
) =>
  update(condition, {
    items: {
      [conditionLineIndex]: {
        value: { $set: value },
      },
    },
  });

export const removeLine = (
  condition: StructuredCondition,
  conditionLineIndex: number,
) =>
  condition.items.length > 1
    ? update(condition, {
        items: { $splice: [[conditionLineIndex, 1]] },
      })
    : null;

const createMissingQuestion = (
  wizardId: Uuid,
  code: string,
): QuestionDefinitionSummaryType => ({
  active: false,
  category: '',
  code,
  pos: -1,
  question: '',
  responses: [],
  sscIssues: [],
  wizardId,
});

const createMissingResponse = (
  uniqueId: Uuid,
  label: string,
): ResponseDefinitionSummaryType => ({
  definition: {
    id: EMPTY_ID,
    // We use undefined to detect that we should show "Missing ID"
    responseType: (undefined as unknown) as ResponseType,
    label: '',
    options: [],
    placeholder: '',
  },
  id: EMPTY_ID,
  isRequired: false,
  pos: 0,
  uniqueId,
});

const getOperatorValueLabel = (
  op: QuestionConditionStructuredItemOperatorEnum,
  t: TFunction,
) => ({
  label: t('conditionEditor.itemOperator', { context: op }),
  value: op,
});

const getPossibleValue = (option: string) => ({
  value: option,
  label: option,
});

const getResponseLabelKey = ({
  definition: { responseType },
}: ResponseDefinitionSummaryType): string =>
  responseType ? getOption(responseType).label : 'conditionEditor.missingId';

const mapResponseToItemType = (
  response: ResponseDefinitionSummaryType,
  groupedResponses: Record<string, ResponseDefinitionSummaryType[]>,
  t: TFunction,
): ResponseItemType => {
  const type = getResponseLabelKey(response);
  const index = indexOf(response, groupedResponses[type]) + 1;
  const showIndex = groupedResponses[type].length > 1;
  return {
    value: response.uniqueId,
    label: showIndex ? t('conditionEditor.response', { index, type }) : t(type),
    description: response.definition.label,
    response,
  };
};

const isValidNumericValue = (value: string) => value === '' || isNumber(value);

const isValidPercentageValue = (value: string) => {
  if (!(value === '' || isInteger(value))) {
    return false;
  }
  const valueInt = parseInt(value, 10);

  return valueInt >= 0 && valueInt <= 100;
};

export const findQuestionIndex = (
  conditionQuestion: QuestionDefinitionSummaryType,
  possibleQuestions: GroupedQuestionItemType[],
): number[] | null => {
  const categoryIndex = findIndex(
    category => category.label === conditionQuestion.category,
    possibleQuestions,
  );

  if (categoryIndex === -1) {
    return null;
  }

  const questionIndex = findIndex(
    possibleQuestion =>
      possibleQuestion.wizardId === conditionQuestion.wizardId,
    possibleQuestions[categoryIndex].options,
  );

  if (questionIndex === -1) {
    return null;
  }

  return [categoryIndex, questionIndex];
};

/**
 * @return [filteredPossibleQuestions, selectedQuestionIsAbove]
 */
export const filterPossibleQuestions = (
  conditionQuestion: QuestionDefinitionSummaryType,
  possibleQuestions: GroupedQuestionItemType[],
  selectedQuestion?: QuestionDefinitionSummaryType,
): [GroupedQuestionItemType[], boolean] => {
  let selectedQuestionIsAbove = true;
  const indices = findQuestionIndex(conditionQuestion, possibleQuestions);

  if (indices === null) {
    return [[], selectedQuestionIsAbove];
  }

  const [categoryIndex, questionIndex] = indices;

  const result = possibleQuestions.map(
    (
      category: GroupedQuestionItemType,
      cIndex: number,
    ): GroupedQuestionItemType =>
      update(category, {
        options: {
          $apply: (options: FormCreatorValidatedQuestionType[]) => {
            if (cIndex <= categoryIndex) {
              return options.filter(
                (question, qIndex) =>
                  !(
                    (cIndex === categoryIndex && qIndex >= questionIndex) ||
                    hasNoResponses(question.responses)
                  ),
                category.options,
              );
            }
            return [];
          },
        },
      }),
  );

  if (selectedQuestion) {
    const selectedIndices = findQuestionIndex(
      selectedQuestion,
      possibleQuestions,
    );

    if (selectedIndices === null) {
      return [result, selectedQuestionIsAbove];
    }
    const [selectedCategoryIndex, selectedQuestionIndex] = selectedIndices;

    // in case selected question's index is in below category or is same category,
    // but below then it should remain there
    if (
      (selectedCategoryIndex === categoryIndex &&
        selectedQuestionIndex >= questionIndex) ||
      selectedCategoryIndex > categoryIndex
    ) {
      result[selectedCategoryIndex].options = update(
        result[selectedCategoryIndex].options,
        {
          $push: [
            possibleQuestions[selectedCategoryIndex].options[
              selectedQuestionIndex
            ],
          ],
        },
      );

      selectedQuestionIsAbove = false;
    }
  }

  return [result, selectedQuestionIsAbove];
};

export const getConditionLineState = ({
  conditionQuestion,
  line: { operator, questionUuid, responseUuid, value },
  groupedQuestions,
  t,
}: {
  conditionQuestion: FormCreatorValidatedQuestionType;
  line: StructuredConditionLine;
  groupedQuestions: GroupedQuestionItemType[];
  t: TFunction;
}): ConditionLineStateType => {
  let selectedQuestion: undefined | QuestionDefinitionSummaryType;
  let possibleQuestions: PossibleQuestionType[] = [];
  let questionValid = true;
  let selectedResponse: undefined | ResponseItemType;
  let possibleResponses: ResponseItemType[] = [];
  let responseValid = true;
  let selectedOperator: undefined | OperatorItemType;
  let possibleOperators: OperatorItemType[] = [];
  let selectedValue: string | ValueLabelType<string> = value;
  let possibleValues: string | Array<ValueLabelType<string>> = [];
  let valueValid = true;
  let isSelectableValue = false;

  groupedQuestions.find(category => {
    selectedQuestion = category.options.find(
      question => question.wizardId === questionUuid,
    );
    return selectedQuestion !== undefined;
  });

  if (!selectedQuestion && questionUuid) {
    selectedQuestion = createMissingQuestion(
      questionUuid,
      t('conditionEditor.missingId'),
    );
    possibleQuestions = [
      selectedQuestion,
      ...filterPossibleQuestions(
        conditionQuestion,
        groupedQuestions,
        selectedQuestion,
      )[0],
    ];
    questionValid = false;
  } else {
    const [
      filteredPossibleQuestions,
      selectedQuestionIsAbove,
    ] = filterPossibleQuestions(
      conditionQuestion,
      groupedQuestions,
      selectedQuestion,
    );
    possibleQuestions = filteredPossibleQuestions;
    if (!selectedQuestionIsAbove) {
      questionValid = false;
    }
  }

  if (!questionUuid) {
    questionValid = false;
  }

  if (selectedQuestion) {
    let foundResponses = hasNoResponses(selectedQuestion.responses)
      ? []
      : selectedQuestion.responses;

    let foundResponse = foundResponses.find(
      response => response.uniqueId === responseUuid,
    );

    if (!foundResponse && responseUuid) {
      foundResponse = createMissingResponse(
        responseUuid,
        t('conditionEditor.missingId'),
      );
      foundResponses = [foundResponse, ...foundResponses];
      responseValid = false;

      selectedQuestion = update(selectedQuestion, {
        responses: { $push: [foundResponse] },
      });
    }

    const groupedResponses = groupBy(getResponseLabelKey, foundResponses);

    selectedResponse = foundResponse
      ? mapResponseToItemType(foundResponse, groupedResponses, t)
      : undefined;
    possibleResponses = foundResponses.map(r =>
      mapResponseToItemType(r, groupedResponses, t),
    );
    if (!foundResponse) {
      responseValid = false;
    }
  }

  if (!responseUuid) {
    responseValid = false;
  }

  if (selectedResponse) {
    selectedOperator = getOperatorValueLabel(operator, t);
    const operators = getPossibleOperatorsForResponse(
      selectedResponse.response,
    );
    possibleOperators = (contains(operator, operators)
      ? operators
      : [operator, ...operators]
    ).map(op => getOperatorValueLabel(op, t));

    isSelectableValue = isSelectResponse(selectedResponse.response);

    if (isSelectableValue) {
      selectedValue = getPossibleValue(value);
      valueValid = contains(
        value,
        selectedResponse.response.definition.options,
      );
      possibleValues = (valueValid
        ? selectedResponse.response.definition.options
        : [value, ...selectedResponse.response.definition.options]
      ).map(getPossibleValue);
    }

    const { responseType } = selectedResponse.response.definition;
    const simpleResponseType = getOption(responseType);

    if (simpleResponseType === NUMBER && !isValidNumericValue(value)) {
      valueValid = false;
    }

    if (simpleResponseType === PERCENTAGE && !isValidPercentageValue(value)) {
      valueValid = false;
    }
  }

  return {
    selectedQuestion,
    possibleQuestions,
    questionValid,
    selectedResponse,
    possibleResponses,
    responseValid,
    selectedOperator,
    possibleOperators,
    selectedValue,
    possibleValues,
    valueValid,
    isSelectableValue,
  };
};
