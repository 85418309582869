import styled, { css } from 'styled-components';
import { TextButton } from 'common/common.styles';
import { CheckboxContainer } from '../common/Checkbox/Checkbox.styles';

// hover & focus-within has to be duplicated because IE11 is piece of shit and shared style breaks both
export const Hoverable = styled.div`
  display: flex;
  flex-direction: row;
  background: transparent;
  justify-content: flex-start;
  width: 100%;
  min-height: 34px;
  align-items: left;
  padding: 2px 0;

  &:hover {
    background-color: ${({ theme }) => theme.basic.colors.atlasHover};

    > ${TextButton} {
      opacity: 1;
    }
  }

  > ${TextButton} {
    margin-left: 20px;
    opacity: 0;
  }

  &:focus-within {
    background-color: ${({ theme }) => theme.basic.colors.atlasHover};

    > ${TextButton} {
      opacity: 1;
    }
  }
`;

export const Selectable = styled.div<{ isSelected: boolean }>`
  display: flex;
  padding: 0 12px 0 8px;
  min-height: 30px;
  border-radius: 2px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.basic.colors.atlas : 'transparent'};

  ${CheckboxContainer} {
    ${({ theme, isSelected }) =>
      isSelected
        ? css`
            ${theme.fonts.semibold.normal}
            color: ${theme.basic.colors.light};
          `
        : null};
  }
`;

export const TextInput = styled.input<{ isEditing: boolean }>`
  ${({ theme }) => theme.fonts.regular.normal}
  height: 100%;
  background-color: ${({ theme }) => theme.basic.colors.light};
  width: ${({ isEditing }) => (isEditing ? 260 : 0)}px;
  border-radius: 3px;
  border: ${({ isEditing }) => (isEditing ? 1 : 0)}px
    ${({ theme }) => theme.basic.colors.darkerBorder} solid;
  padding: ${({ isEditing }) => (isEditing ? '6px 16px' : 0)};

  ::selection {
    background: ${({ theme }) => theme.basic.colors.atlasHover};
  }
`;

export const CheckboxWrapper = styled.div<{ isEditing: boolean }>`
  position: relative;
  opacity: ${({ isEditing }) => (isEditing ? 0 : 1)};
  width: ${({ isEditing }) => (isEditing ? 0 : 'auto')};
  display: ${({ isEditing }) => (isEditing ? 'none' : 'flex')};

  ${CheckboxContainer} {
    margin-bottom: 0;
  }
`;
