/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestsInternalCreate
 */
export interface RequestsInternalCreate {
    /**
     * Due date for filling the form. Omit to send questionnaire without a due date
     * @type {Date}
     * @memberof RequestsInternalCreate
     */
    dueDate?: Date;
    /**
     * Text of the email sent to the vendor
     * @type {string}
     * @memberof RequestsInternalCreate
     */
    emailBody?: string;
    /**
     * Set to true if recepients specified in `targets` should be notified via email.
     * This is usually useful for sending requests to individual users, in case of sending
     * to a company by domain, `company.requests_email` is used.
     * @type {boolean}
     * @memberof RequestsInternalCreate
     */
    notify?: boolean;
    /**
     * How many days before due date should the user be notified
     * @type {number}
     * @memberof RequestsInternalCreate
     */
    reminder?: number;
    /**
     * Source team UUID of the actual sender. The request sender must belong to this team,
     *  to be able to send an internal request.
     * @type {string}
     * @memberof RequestsInternalCreate
     */
    sourceTeam: string;
    /**
     * List of IDs of standards that should be sent to the vendor for filling
     * @type {Array<string>}
     * @memberof RequestsInternalCreate
     */
    standardIds: Array<string>;
    /**
     * List of recepient teams UUID of the standards. All recepient teams must belong to the
     * same company of the sender.
     * @type {Array<string>}
     * @memberof RequestsInternalCreate
     */
    targetTeams: Array<string>;
}

export function RequestsInternalCreateFromJSON(json: any): RequestsInternalCreate {
    return RequestsInternalCreateFromJSONTyped(json, false);
}

export function RequestsInternalCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestsInternalCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dueDate': !exists(json, 'due_date') ? undefined : (new Date(json['due_date'])),
        'emailBody': !exists(json, 'email_body') ? undefined : json['email_body'],
        'notify': !exists(json, 'notify') ? undefined : json['notify'],
        'reminder': !exists(json, 'reminder') ? undefined : json['reminder'],
        'sourceTeam': json['source_team'],
        'standardIds': json['standard_ids'],
        'targetTeams': json['target_teams'],
    };
}

export function RequestsInternalCreateToJSON(value?: RequestsInternalCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'due_date': value.dueDate === undefined ? undefined : (value.dueDate.toISOString()),
        'email_body': value.emailBody,
        'notify': value.notify,
        'reminder': value.reminder,
        'source_team': value.sourceTeam,
        'standard_ids': value.standardIds,
        'target_teams': value.targetTeams,
    };
}


