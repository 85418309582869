import { OpenedQuestionType } from '../formCreator.types';

export type SetOpenedQuestionFunctionType = (
  openedQuestion?: OpenedQuestionType,
) => void;

export interface SetOpenedQuestionPayloadType {
  openedQuestion?: OpenedQuestionType;
}

export interface SetOpenedQuestionActionType {
  type: '@formCreator/SET_OPENED_QUESTION';
  payload: SetOpenedQuestionPayloadType;
}

export const setOpenedQuestion = (
  openedQuestion?: OpenedQuestionType,
): SetOpenedQuestionActionType => ({
  type: '@formCreator/SET_OPENED_QUESTION',
  payload: {
    openedQuestion,
  },
});
