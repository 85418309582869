/*eslint no-unexpected-multiline: "off"*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Categories = styled.div`
  margin-top: -50px;
`;

export const TriggerWrapper = styled.div<{ count: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: ${({ theme }) => theme.basic.colors.light};
  padding: 0 20px;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  margin: 20px 0;
  position: relative;
  ${({ count }) =>
    count
      ? {
          opacity: 1,
          cursor: 'pointer',
        }
      : {
          opacity: 0.3,
          cursor: 'initial',
        }}
`;

export const CollapsibleContent = styled.div<{
  isOpen: boolean;
  withTopOffset?: boolean;
}>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  margin-top: -16px;
  margin-right: 0px;
  margin-left: 31px;
  padding-left: 10px;
  padding-right: 10px;
  ${({ withTopOffset }) => (withTopOffset ? 'padding-top: 18px' : null)}
`;

export const CountWrapper = styled.span`
  & > strong {
    color: ${({ theme }) => theme.basic.colors.teal};
  }
`;

export const ExpandIconWrapper = styled.span<{
  isOpen: boolean;
  count: number;
}>`
  margin: -20px;
  padding: 20px 28px;
  width: 24px;
  cursor: pointer;
  opacity: ${({ count }: { count: number }) => (count > 0 ? 1 : 0)};
  transition: rotate 0.2s, top 0.2s;
`;

export const Icon = styled(FontAwesomeIcon)`
  transform: rotate(${({ open }: { open: boolean }) => (open ? 90 : 0)}deg);
  transition: rotate 0.2s, top 0.2s;
`;

export const Title = styled.span<{ withOffset?: boolean }>`
  padding-left: ${({ withOffset }) => (withOffset ? '24px' : '11px')};
  ${({ theme }) => theme.basic.fontWeights.bold}
`;

export const Trigger = styled.div<{ count: number }>`
  cursor: ${({ count }) => (count > 0 ? 'pointer' : 'initial')};
  opacity: ${({ count }) => (count > 0 ? 1 : 0.3)};
`;
