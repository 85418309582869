import lightTheme from './light';
import aigTheme from './whitelabel/aig';
import betaTheme from './whitelabel/beta';
import bdTheme from './whitelabel/bd';
import lowesTheme from './whitelabel/lowes';
import royalBoC from './whitelabel/royalbank';
import sbTheme from './whitelabel/sb';

const THEMES = {
  default: lightTheme,
  'aig.atlas.securityscorecard.tech': aigTheme,
  'aig.atlas.securityscorecard.qa': aigTheme,
  'aig.atlas.securityscorecard.io': aigTheme,
  'beta.atlas.securityscorecard.tech': betaTheme,
  'beta.atlas.securityscorecard.qa': betaTheme,
  'beta.atlas.securityscorecard.io': betaTheme,
  'bectondickson.atlas.securityscorecard.tech': bdTheme,
  'bectondickson.atlas.securityscorecard.qa': bdTheme,
  'bectondickson.atlas.securityscorecard.io': bdTheme,
  'lowes.atlas.securityscorecard.tech': lowesTheme,
  'lowes.atlas.securityscorecard.qa': lowesTheme,
  'lowes.atlas.securityscorecard.io': lowesTheme,
  'rbc.atlas.securityscorecard.tech': royalBoC,
  'rbc.atlas.securityscorecard.qa': royalBoC,
  'rbc.atlas.securityscorecard.io': royalBoC,
  'sb.atlas.securityscorecard.tech': sbTheme,
  'sb.atlas.securityscorecard.qa': sbTheme,
  'sb.atlas.securityscorecard.io': sbTheme,
};

// Create typing for theme given the default theme, default theme always contains all the fields
export type ThemeType = typeof THEMES.default;

/**
 * the theme selector checks the current hostname and assigns a theme
 * this is used for whitelabeling purposes.
 *
 * @TODO: generate multiple builds that export a singular theme
 */
const themeSelector = (): ThemeType =>
  THEMES[window.location.hostname]
    ? THEMES[window.location.hostname]
    : THEMES.default;

export default themeSelector();
