import {
  ProxySummary,
  ProxyShortSummaryFormRoleEnum,
} from '../../../generated/models';
import { ApiCallActionType } from '../../store.types';
import { FormProxyStateType } from '../formProxy.reducer';
import { hideModal } from 'utils/Modal';
import { FORM_PROXY_MODAL_ID } from '../../../common/common.constants';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import i18n from '../../../i18n';
import { resetProxyModal } from '../../../store/formProxy/actions';
import { Uuid } from '../../../common/common.types';

export interface FormProxyGrantAccessCallParamsType {
  emailBody: string;
  formId: Uuid;
  userEmail: string;
  proxyRoles: ProxyShortSummaryFormRoleEnum[];
}

export type FormProxyGrantAccessFunctionType = (
  formId: Uuid,
  userEmail: string,
  emailBody: string,
  proxyRoles: ProxyShortSummaryFormRoleEnum[],
  standardName: string,
  skipNotification?: boolean,
  callback?: (userId: Uuid) => void,
) => void;

export type FormProxyGrantAccessActionType = ApiCallActionType<
  '@formProxy/API/GRANT_ACCESS',
  FormProxyGrantAccessCallParamsType,
  ProxySummary[],
  never,
  FormProxyStateType
>;

export const grantAccess = (
  formId: Uuid,
  userEmail: string,
  emailBody: string,
  proxyRoles: ProxyShortSummaryFormRoleEnum[],
  standardName: string,
  skipNotification?: boolean,
  callback?: (userId: Uuid) => void,
): FormProxyGrantAccessActionType => ({
  type: '@formProxy/API/GRANT_ACCESS',
  payload: {
    callParams: { formId, userEmail, emailBody, proxyRoles },
    endpointPath: ['forms', 'proxies', 'post'],
    preActions: [put(resetProxyModal()), put(hideModal(FORM_PROXY_MODAL_ID))],
    selector: null,
    postActions: [
      () =>
        !skipNotification
          ? put(
              flash(
                i18n.t('FormProxy:accessGranted', {
                  userEmail,
                  standardName,
                }),
              ),
            )
          : null,
      (data: ProxySummary[]) =>
        data.length > 0 && callback ? call(callback, data[0].user.id) : null,
    ],
  },
});
