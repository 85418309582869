import { AppActionType } from './actions';

export interface AppStateType {
  loading: {
    left: number;
    isLoading: boolean;
    text?: string;
  };
}

const initialState: AppStateType = {
  loading: {
    left: 0,
    isLoading: false,
    text: undefined,
  },
};

const app = (state = initialState, action: AppActionType): AppStateType => {
  if (action.type === '@app/LOADING_START') {
    return {
      ...state,
      loading: {
        left: action.payload.itemsToLoadCount,
        isLoading: true,
        text: action.payload.text,
      },
    };
  }

  if (action.type === '@app/LOADING_END') {
    const itemsLeft = state.loading.left <= 1 ? 0 : state.loading.left - 1;
    return {
      ...state,
      loading: {
        left: itemsLeft,
        isLoading: !(itemsLeft === 0),
        text: itemsLeft === 0 ? undefined : state.loading.text,
      },
    };
  }

  return state;
};

export default app;
