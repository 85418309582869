import styled from 'styled-components';

export const Details = styled.div`
  color: ${({ theme }) => theme.atlas.grey[400]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: italic;
  ${({ theme }) => theme.fonts.regular.small}
`;
