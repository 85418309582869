/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormHistoryData
 */
export interface FormHistoryData {
    /**
     * 
     * @type {string}
     * @memberof FormHistoryData
     */
    from?: FormHistoryDataFromEnum;
    /**
     * 
     * @type {string}
     * @memberof FormHistoryData
     */
    to?: FormHistoryDataToEnum;
}

export function FormHistoryDataFromJSON(json: any): FormHistoryData {
    return FormHistoryDataFromJSONTyped(json, false);
}

export function FormHistoryDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormHistoryData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
    };
}

export function FormHistoryDataToJSON(value?: FormHistoryData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'to': value.to,
    };
}

/**
* @export
* @enum {string}
*/
export enum FormHistoryDataFromEnum {
    CREATED = 'CREATED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS',
    ACCEPTED = 'ACCEPTED',
    CANCELED = 'CANCELED',
    ARCHIVED = 'ARCHIVED',
    UNARCHIVED = 'UNARCHIVED'
}
/**
* @export
* @enum {string}
*/
export enum FormHistoryDataToEnum {
    CREATED = 'CREATED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS',
    ACCEPTED = 'ACCEPTED',
    CANCELED = 'CANCELED',
    ARCHIVED = 'ARCHIVED',
    UNARCHIVED = 'UNARCHIVED'
}


