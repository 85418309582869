export default (str: string, length: number) => {
  const ext = str.substring(str.lastIndexOf('.') + 1, str.length).toLowerCase();
  let filename = str.replace('.' + ext, '');
  if (filename.length <= length) {
    return str;
  }

  filename = filename.substr(0, length) + (str.length > length ? '[...]' : '');
  return `${filename}.${ext}`;
};
