import i18n from '../../../i18n';
import { RequestDeleteScheduleCallParamsType } from './deleteScheduledRequest';
import { ScheduledRequest } from '../../../generated/models/ScheduledRequest';
import { assocPath, findIndex, propEq, update } from 'ramda';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { parseScheduledRequest } from 'utils/parsers/requests.parsers';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { RequestsStateType } from '../requests.types';
import { Uuid } from '../../../common/common.types';

export type RequestDeleteNextScheduledActionType = ApiCallActionType<
  '@requests/API/DELETE_NEXT_SCHEDULED_REQUEST',
  RequestDeleteScheduleCallParamsType,
  ScheduledRequest,
  never,
  RequestsStateType
>;

export const deleteNextScheduledRequest = (
  id: Uuid,
): RequestDeleteNextScheduledActionType => ({
  type: '@requests/API/DELETE_NEXT_SCHEDULED_REQUEST',
  payload: {
    callParams: { id },
    endpointPath: ['requests', 'scheduler', 'deleteNextRequest'],
    selector: (data, state) => {
      if (data.cancelled) {
        return assocPath(
          ['scheduled'],
          state.requests.scheduled.filter(r => r.id !== id),
          state.requests,
        );
      }
      const requestIndex = findIndex(propEq('id', id))(
        state.requests.scheduled,
      );
      return assocPath(
        ['scheduled'],
        update(
          requestIndex,
          parseScheduledRequest(data),
          state.requests.scheduled,
        ),
        state.requests,
      );
    },
    postActions: [
      () => call(() => trackEvent('Request', 'Deleted next scheduled request')),
      () => put(flash(i18n.t('common:responses.scheduledRequestDeletedNext'))),
    ],
  },
});
