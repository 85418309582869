import {
  StructuredCondition,
  StructuredConditionLine,
} from '../../../common/common.types';
import { QuestionConditionStructured } from '../../../generated/models/QuestionConditionStructured';
import { QuestionConditionStructuredItem } from '../../../generated/models/QuestionConditionStructuredItem';

const encodeConditionStructuredItem = ({
  operator,
  questionUuid,
  responseUuid,
  value,
}: StructuredConditionLine): QuestionConditionStructuredItem => ({
  operator,
  parent_question_uuid: questionUuid,
  response_uuid: responseUuid,
  value,
});

const encodeStructuredCondition = ({
  operator,
  items,
}: StructuredCondition): QuestionConditionStructured => ({
  operator,
  items: items.map(encodeConditionStructuredItem),
});

export default encodeStructuredCondition;
