export interface FormsResetReportSavingActionType {
  type: '@forms/RESET_REPORT_SAVING';
  payload: { lastSave: Date };
}

export const resetReportSaving = (): FormsResetReportSavingActionType => {
  const lastSave = new Date();
  return {
    type: '@forms/RESET_REPORT_SAVING',
    payload: { lastSave },
  };
};
