import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import route, {
  FORM_NEW_FROM_EXISTING,
  UPLOAD_CUSTOM_STANDARD,
} from '../../../routes';

import { TranslatePropsType } from 'common/common.types';

import { CreateEmptyFormFunctionType } from 'store/formCreator/actions';

import Modal from 'utils/Modal/Modal';

import {
  Heading,
  OptionsWrapper,
  Option,
  OptionText,
  UseExistingIcon,
  StartFromScratchIcon,
  UploadQuestionnaireIcon,
} from './AddNewModal.styles';

interface AddNewModalPropsType extends RouteComponentProps, TranslatePropsType {
  modalId: string;
  createEmptyForm: CreateEmptyFormFunctionType;
}

const AddNewModal = ({
  modalId,
  createEmptyForm,
  history: { push: navigate },
  t,
}: AddNewModalPropsType) => {
  const modalProps = {
    modalId: modalId,
    title: (
      <div>
        <Heading>{t('addNewModal.title') as string}</Heading>
      </div>
    ),
  };

  return (
    <Modal {...modalProps}>
      <OptionsWrapper>
        <Option
          data-test="create-template-from-scratch"
          onClick={() => createEmptyForm()}
        >
          <StartFromScratchIcon />
          <OptionText>
            {t('addNewModal.options.startFromScratch') as string}
          </OptionText>
        </Option>
        <Option onClick={() => navigate(route(FORM_NEW_FROM_EXISTING))}>
          <UseExistingIcon />
          <OptionText>
            {t('addNewModal.options.useExisting') as string}
          </OptionText>
        </Option>
        <Option
          data-test="upload-excel-template"
          onClick={() => navigate(route(UPLOAD_CUSTOM_STANDARD))}
        >
          <UploadQuestionnaireIcon />
          <OptionText>
            {t('addNewModal.options.uploadQuestionnaireFile') as string}
          </OptionText>
        </Option>
      </OptionsWrapper>
    </Modal>
  );
};

export default withRouter(withTranslation('FormDefinitions')(AddNewModal));
