import * as actions from 'store/forms/actions';
import * as headerActions from 'store/ui/actions/toggleHeader';
import * as selectors from 'store/forms/forms.selectors';
import FormContextWrapper from '../../src/components/Form/FormContextWrapper';
import Page, { PageActionsBaseType, PageBaseType, PagePropsType } from './Page';
import React from 'react';
import i18n from '../i18n';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch, bindActionCreators } from 'redux';
import { copyTextToClipboard } from '../utils/copyTextToClipboard';
import { flash } from 'store/flashes/actions';
import { startLoading } from 'store/app/actions';
import {
  AutocompleteType,
  DropdownsEnum,
  FormsFiltersType,
  FormsResourceGoneStateType,
  ReportSavingType,
} from 'store/forms/forms.reducer';
import {
  FormsAddRemoveFunctionType,
  SupportedFilterBranchType,
  validateFilters,
} from '../utils/filters/forms.filters';
import {
  AttachmentModalType,
  CompanySettingType,
  FormAssignee,
  FormType,
  FormHistoryType,
  FunctionsMapType,
  QuestionTabEnum,
  UploadedFileNamesType,
  UserType,
  UploadType,
  Uuid,
  UpdateResponseSourceEnum,
  SharedFormType,
  LanguageType,
} from 'common/common.types';

import {
  exportFindingsToCsvPath,
  ExportFindingsToCsvPathFunctionType,
} from 'store/requests/requests.selectors';
import {
  FormProxyShowProxyModalFunctionType,
  showProxyModal,
} from 'store/formProxy/actions';
import { hideModal, showModal } from '../utils/Modal';

export interface FilterFunctionsType {
  addFilter: FormsAddRemoveFunctionType;
  removeFilter: FormsAddRemoveFunctionType;
  clearFilters: () => void;
}

export interface DelegationActionsType {
  assignCategoryDelegation?: actions.FormsAssignCategoryDelegationFunctionType;
  assignFormDelegation?: actions.FormsAssignFormDelegationFunctionType;
  assignQuestionDelegation?: actions.FormsAssignQuestionDelegationFunctionType;
  unassignCategoryDelegation?: actions.FormsUnassignCategoryDelegationFunctionType;
  unassignFormDelegation?: actions.FormsUnassignFormDelegationFunctionType;
  unassignQuestionDelegation?: actions.FormsUnassignQuestionDelegationFunctionType;
  getAssigneesDelegation?: actions.FormsGetAssigneesFunctionType;
}

export interface FormPageContextActionsType
  extends FunctionsMapType,
    DelegationActionsType {
  addFilter: FormsAddRemoveFunctionType;
  addFinding: actions.FormsAddFindingFunctionType;
  clearAnswers: (id: Uuid) => void;
  clearDelegationNotifications: actions.FormsClearDelegationNotificationsFunctionType;
  clearFilters: () => void;
  copyToClipboard: (text: string) => void;
  createMessage?: actions.FormsCreateMessageFunctionType;
  createQuestionAttachment?: actions.FormsCreateQuestionAttachmentFunctionType;
  createResponseAttachment?: actions.FormsCreateResponseAttachmentFunctionType;
  deleteAttachment?: (id: Uuid) => void;
  deleteFinding: actions.FormsDeleteFindingFunctionType;
  deleteQuestionAttachment?: actions.FormsDeleteQuestionAttachmentFunctionType;
  exportFindingsToCsvPath: ExportFindingsToCsvPathFunctionType;
  exportFindingsToPdf: actions.FormsExportFindingsToPdfFunctionType;
  getFindings: actions.FormsGetFindingsFunctionType;
  getQuestionMappings: actions.FormsGetQuestionMappingsFunctionType;
  loadQuestions: (params: actions.FormGetQuestionsParamsType) => void;
  navigate: (url: string) => void;
  postAttachment?: (formData: FormData) => void;
  putMessage?: actions.FormsPutMessageFunctionType;
  putQuestion?: actions.FormsPutQuestionFunctionType;
  putRequest?: actions.FormsPutRequestFunctionType;
  rateMapping: actions.FormsRateMappingFunctionType;
  removeFilter: FormsAddRemoveFunctionType;
  renameForm: actions.FormsRenameFormFunctionType;
  sendDelegationNotifications: actions.FormsSendDelegationNotificationsFunctionType;
  setBlockSeen: actions.FormsSetBlockSeenFunctionType;
  setCurrentDropdown: actions.FormsSetCurrentDropdownFunctionType;
  showProxyModal: FormProxyShowProxyModalFunctionType;
  startAutocomplete: actions.FormsStartAutocompleteFunctionType;
  switchQuestionTab: actions.FormsSwitchQuestionFunctionType;
  updateResults: actions.FormsGetFormWithoutResetFunctionType;
  updateCategoryReviewStatus: actions.FormsUpdateCategoryReviewStatusFunctionType;
  updateQuestionReviewStatus: actions.FormsUpdateQuestionReviewStatusFunctionType;
  updateRiskNote: actions.FormsUpdateRiskNoteFunctionType;
  updateVrmViewStatus: actions.FormsUpdateVrmViewStatusFunctionType;
  updateDueDate: actions.FormsUpdateDueDateFunctionType;
  showModal: (modalId: string) => void;
  hideModal: (modalId: string) => void;
}

export interface FormRouteStateType {
  openAutocomplete?: true;
}

export interface FormDataPropsType {
  areFindingsExporting?: boolean;
  assignees: FormAssignee[];
  attachmentModal?: AttachmentModalType;
  autocomplete: AutocompleteType;
  canUpdateView: boolean;
  currentDropdown?: DropdownsEnum;
  currentForm: FormType;
  currentHistory: FormHistoryType[];
  filters: FormsFiltersType;
  lastFormStatusChangeDate: Date;
  isFormValid?: boolean;
  modalArray?: [];
  mapping?: any;
  reportSaving: ReportSavingType;
  resourceGone: FormsResourceGoneStateType;
  preferredTab: QuestionTabEnum;
  settings?: CompanySettingType;
  upload?: {
    fileNames: UploadedFileNamesType;
    nonFailedUploadingCount: number;
    updatedQuestionsIds: Uuid[];
    uploadedCount: number;
    uploadingCount: number;
    uploads: UploadType[];
  };
  user: UserType;
  sharedFormData?: SharedFormType;
  language: LanguageType;
}

export interface FormPageContextType extends PageBaseType {
  actions: PageActionsBaseType & FormPageContextActionsType;
  data: FormDataPropsType;
}

const FormPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => {
      const currentForm = selectors.getCurrentForm(state.forms);
      const currentHistory = selectors.getCurrentFormHistory(state.forms);

      return {
        areFindingsExporting: state.forms.findingsExporting,
        assignees: selectors.getAssignees(state.forms),
        attachmentModal: selectors.getAttachmentModalState(state.forms),
        autocomplete: state.forms.autocomplete,
        canUpdateView: state.forms.canUpdateView,
        currentDropdown: state.forms.currentDropdown,
        currentForm,
        currentHistory,
        filters: state.forms.filters,
        isFormValid: selectors.isFormValid(state.forms),
        lastFormStatusChangeDate:
          selectors.getHistoryLastFormStatusChangeDate(currentHistory) ||
          (currentForm ? currentForm.createdAt : new Date(0)),
        mapping: {
          allMapped: selectors.isAllMapped(state.forms),
          questionsUpdated: selectors.questionsUpdated(state.forms),
          uploadingOrMapping: selectors.isUploadingOrMapping(state.forms),
        },
        reportSaving: state.forms.reportSaving,
        resourceGone: state.forms.resourceGoneState,
        settings: state.companies.settings,
        upload: {
          fileNames: selectors.getUploadedFileNames(state.forms),
          nonFailedUploadingCount: selectors.getNonFailedUploadingCount(
            state.forms,
          ),
          updatedQuestionsIds: selectors.getUpdatedQuestionsIds(state.forms),
          uploadedCount: selectors.getUploadedCount(state.forms),
          uploadingCount: selectors.getUploadingCount(state.forms),
          uploads: state.forms.uploads,
        },
        preferredTab: state.forms.preferredTab,
        user: state.user.currentUser,
        sharedFormData: state.forms.sharedFormData,
        modalArray: state.modals.opened,
        language: state.settings.languagePreference,
      };
    }}
    dispatches={(dispatch: Dispatch<ActionType>, ownProps: PagePropsType) => ({
      getData: ({
        routeParams: { id },
        queryParams: {
          filters = [],
          assignee = null,
          toggleTab = 'attachments',
        },
      }: {
        routeParams: { id: string };
        queryParams: any;
      }) => {
        dispatch(startLoading(2));
        dispatch(
          actions.getForm({
            id,
            queryFilters: validateFilters(filters),
            queryAssignee: assignee,
            canSwitchNavContext: true,
          }),
        );

        if (toggleTab.toString()) {
          dispatch(actions.switchQuestionTab(toggleTab));
        }
      },
      reset: () => {
        dispatch(actions.resetForm());
        dispatch(actions.resetUpload());
        dispatch(headerActions.toggleHeader(true));
      },
      actions: {
        ...bindActionCreators(
          {
            addFinding: actions.addFinding,
            assignCategoryDelegation: actions.assignCategoryDelegation,
            assignFormDelegation: actions.assignFormDelegation,
            assignQuestionDelegation: actions.assignQuestionDelegation,
            clearAnswers: actions.clearAnswers,
            clearDelegationNotifications: actions.clearDelegationNotifications,
            deleteAttachment: actions.deleteAttachment,
            deleteFinding: actions.deleteFinding,
            exportFindingsToPdf: actions.exportFindingsToPdf,
            getAssigneesDelegation: actions.getAssignees,
            getFindings: actions.getFindings,
            getQuestionMappings: actions.getQuestionMappings,
            loadQuestions: actions.loadQuestions,
            postAttachment: actions.postAttachment,
            putMessage: actions.putMessage,
            updateDueDate: actions.updateDueDate,
            putRequest: actions.putRequest,
            rateMapping: actions.rateMapping,
            renameForm: actions.renameForm,
            sendDelegationNotifications: actions.sendDelegationNotifications,
            setBlockSeen: actions.setBlockSeen,
            setCurrentDropdown: actions.setCurrentDropdown,
            showProxyModal,
            startAutocomplete: actions.startAutocomplete,
            switchQuestionTab: actions.switchQuestionTab,
            unassignCategoryDelegation: actions.unassignCategoryDelegation,
            unassignFormDelegation: actions.unassignFormDelegation,
            unassignQuestionDelegation: actions.unassignQuestionDelegation,
            updateCategoryReviewStatus: actions.updateCategoryReviewStatus,
            updateQuestionReviewStatus: actions.updateQuestionReviewStatus,
            updateResults: actions.getFormWithoutReset,
            updateRiskNote: actions.updateRiskNote,
            updateVrmViewStatus: actions.updateVrmViewStatus,
          },
          dispatch,
        ),
        addFilter: (
          branch: SupportedFilterBranchType,
          filterName: string,
          formId?: Uuid,
          ids?: Uuid[],
        ) => {
          dispatch(actions.setCanUpdateView(false));
          dispatch(actions.addFilter(branch, filterName, formId, ids));
        },
        clearFilters: () => {
          dispatch(actions.setCanUpdateView(false));
          dispatch(actions.clearFilters());
        },
        copyToClipboard: (text: string) => {
          copyTextToClipboard(text);
          dispatch(flash(i18n.t('common:responses.commonCopySuccess')));
        },
        createMessage: (
          id: Uuid,
          text: string,
          postingId: string,
          author: UserType,
          formId: Uuid,
        ) => {
          dispatch(actions.setCanUpdateView(true));
          dispatch(actions.createMessage(id, text, postingId, author, formId));
        },
        createQuestionAttachment: (
          formData: FormData,
          formId: Uuid,
          questionId: Uuid,
        ) => {
          dispatch(actions.setCanUpdateView(true));
          dispatch(
            actions.createQuestionAttachment(formData, formId, questionId),
          );
        },
        createResponseAttachment: (
          formData: FormData,
          formId: Uuid,
          questionId: Uuid,
          responseId: Uuid,
        ) => {
          dispatch(actions.setCanUpdateView(true));
          dispatch(
            actions.createResponseAttachment(
              formData,
              formId,
              questionId,
              responseId,
            ),
          );
        },
        deleteQuestionAttachment: (
          id: Uuid,
          questionId: Uuid,
          formId: Uuid,
          responseId?: Uuid,
          category?: string,
        ) => {
          dispatch(actions.setCanUpdateView(true));
          dispatch(
            actions.deleteQuestionAttachment(
              id,
              questionId,
              formId,
              responseId,
              category,
            ),
          );
        },
        exportFindingsToCsvPath,
        navigate: ownProps.history.push,
        putQuestion: (
          formId: Uuid,
          questionId: Uuid,
          responseId: Uuid,
          value: string,
          category: string,
          sourceResponseId?: Uuid,
          sourceQuestionId?: Uuid,
          UpdateResponseSource?: UpdateResponseSourceEnum,
        ) => {
          dispatch(actions.setCanUpdateView(true));
          dispatch(
            actions.putQuestion(
              formId,
              questionId,
              responseId,
              value,
              category,
              sourceResponseId,
              sourceQuestionId,
              UpdateResponseSource,
            ),
          );
        },
        removeFilter: (branch: string, filterName: string) => {
          dispatch(actions.setCanUpdateView(false));
          dispatch(actions.removeFilter(branch, filterName));
        },
        showModal: (modalId: string) => dispatch(showModal(modalId)),
        hideModal: (modalId: string) => dispatch(hideModal(modalId)),
      },
    })}
  >
    <FormContextWrapper />
  </Page>
);

export default FormPage;
