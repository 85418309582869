import Input from '../../common/Input';
import React, { useState } from 'react';
import { ErrorNote, Wrapper } from './LinkReceivers.styles';
import { LabelSpaced } from '../Stepper.styles';
import { Note } from './EmailReceivers.styles';
import { SetFormValueFunctionType } from 'store/requests/actions';
import { isDomainValid } from 'utils/isDomainValid';
import { withTranslation } from 'react-i18next';
import { TargetItemType, TranslatePropsType } from 'common/common.types';

interface LinkReceiversPropsType extends TranslatePropsType {
  isClearable?: boolean;
  readOnly?: boolean;
  setValue: SetFormValueFunctionType;
  targets: TargetItemType[];
}

const LinkReceivers = ({
  isClearable = true,
  readOnly = false,
  setValue,
  targets,
  t,
}: LinkReceiversPropsType) => {
  const [isValid, setValid] = useState(true);

  const onReceiversBlur = (value: string) => {
    const domains = [];
    let validity = true;
    if (value) {
      const domain = value.toLowerCase();
      if (isDomainValid(value)) {
        domains.push({ value: domain, label: domain });
      } else {
        validity = false;
      }
    }
    setValid(validity);
    setValue('targets', domains);
  };

  const hasTargets = targets.length > 0;
  const currentValue = hasTargets ? targets[0].value : '';

  return (
    <Wrapper data-cy={'sq-link-receivers'}>
      <LabelSpaced>{t('companyDomain') as string}</LabelSpaced>

      <Input
        data-cy="sq-domain-input"
        isClearable={isClearable}
        isDisabled={readOnly}
        onBlur={onReceiversBlur}
        placeholder={t('typeCompanyDomain') as string}
        value={currentValue}
      />

      <Note>{t('companyDomainNote') as string}</Note>

      {!isValid && <ErrorNote>{t('invalidCompanyDomain') as string}</ErrorNote>}
    </Wrapper>
  );
};

export default withTranslation('SendRequest')(LinkReceivers);
