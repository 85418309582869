/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreditsChangeSummary
 */
export interface CreditsChangeSummary {
    /**
     * 
     * @type {number}
     * @memberof CreditsChangeSummary
     */
    balanceDiff: number;
    /**
     * 
     * @type {number}
     * @memberof CreditsChangeSummary
     */
    balanceNow: number;
    /**
     * 
     * @type {string}
     * @memberof CreditsChangeSummary
     */
    cause?: CreditsChangeSummaryCauseEnum;
}

export function CreditsChangeSummaryFromJSON(json: any): CreditsChangeSummary {
    return CreditsChangeSummaryFromJSONTyped(json, false);
}

export function CreditsChangeSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditsChangeSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'balanceDiff': json['balance_diff'],
        'balanceNow': json['balance_now'],
        'cause': !exists(json, 'cause') ? undefined : json['cause'],
    };
}

export function CreditsChangeSummaryToJSON(value?: CreditsChangeSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'balance_diff': value.balanceDiff,
        'balance_now': value.balanceNow,
        'cause': value.cause,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreditsChangeSummaryCauseEnum {
    ADDEDINITIAL = 'ADDED_INITIAL',
    ADDEDBYPURCHASE = 'ADDED_BY_PURCHASE',
    ADDEDMANUALLY = 'ADDED_MANUALLY',
    SPENTFORFFREQUEST = 'SPENT_FOR_FFREQUEST',
    REIMBURSEDFFREQUEST = 'REIMBURSED_FFREQUEST',
    ADDEDFORTESTING = 'ADDED_FOR_TESTING',
    SPENTFORTESTING = 'SPENT_FOR_TESTING'
}


