/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyFormsStats
 */
export interface CompanyFormsStats {
    /**
     * 
     * @type {number}
     * @memberof CompanyFormsStats
     */
    questionCount: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyFormsStats
     */
    questionsCompletelyFilled: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyFormsStats
     */
    questionsFilled: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyFormsStats
     */
    questionsPartiallyFilled: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyFormsStats
     */
    responseCount: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyFormsStats
     */
    responsesFilled: number;
}

export function CompanyFormsStatsFromJSON(json: any): CompanyFormsStats {
    return CompanyFormsStatsFromJSONTyped(json, false);
}

export function CompanyFormsStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyFormsStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionCount': json['question_count'],
        'questionsCompletelyFilled': json['questions_completely_filled'],
        'questionsFilled': json['questions_filled'],
        'questionsPartiallyFilled': json['questions_partially_filled'],
        'responseCount': json['response_count'],
        'responsesFilled': json['responses_filled'],
    };
}

export function CompanyFormsStatsToJSON(value?: CompanyFormsStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'question_count': value.questionCount,
        'questions_completely_filled': value.questionsCompletelyFilled,
        'questions_filled': value.questionsFilled,
        'questions_partially_filled': value.questionsPartiallyFilled,
        'response_count': value.responseCount,
        'responses_filled': value.responsesFilled,
    };
}


