import { ApiCallActionType } from '../../store.types';
import { Company } from '../../../generated/models/Company';
import { parseAutocompleteCompany } from 'utils/parsers';
import { CompaniesStateType } from '../companies.reducer';

export interface CompaniesFindParamsType {
  query: string;
}

export type CompaniesFindActionType = ApiCallActionType<
  '@companies/API/FIND',
  { search_query: string },
  Company[],
  never,
  CompaniesStateType
>;

export const findCompany = (
  params: CompaniesFindParamsType,
): CompaniesFindActionType => ({
  type: '@companies/API/FIND',
  payload: {
    endpointPath: ['companies', 'getCompanies'],
    callParams: { search_query: params.query },
    selector: (data: Company[]) => ({
      autocomplete: data.map((companyData: Company) =>
        parseAutocompleteCompany(companyData),
      ),
    }),
  },
});
