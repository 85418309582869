import styled from 'styled-components';
import { Flex } from 'common/common.styles';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: ${({ theme }) => theme.basic.colors.light};
  padding: 0 20px;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  margin: 20px 0;
  position: relative;
`;

export const Section = styled(Flex)`
  align-items: center;
  width: 550px;
`;

export const Name = styled.div`
  ${({ theme }) => theme.basic.fontWeights.bold}
  max-width: 500px;
  line-height: 1.3;
  padding-right: 15px;
`;

export const Filling = styled.div`
  display: flex;
  width: 240px;
  justify-content: space-between;

  p {
    font-size: ${({ theme }) => theme.basic.fontSizes.small};

    & > span {
      color: ${({ theme }) => theme.basic.colors.teal};
      ${({ theme }) => theme.fonts.bold.normal}
    }
  }
`;

export const Open = styled.div<{ isOpen?: boolean }>`
  margin-right: 20px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: ${({ theme: { basic } }) => basic.animations.normal};
`;

export const DelegatorWrapper = styled.div`
  width: 220px;

  & > div {
    position: relative;
  }
`;
