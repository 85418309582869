/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";
// @ts-ignore
import { QuestionConditionStructured } from "./QuestionConditionStructured";

/**
 * 
 * @export
 * @interface FormEditEvent
 */
export interface FormEditEvent {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof FormEditEvent
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof FormEditEvent
     */
    updated_at: Date;
    /**
     * 
     * @type {number}
     * @memberof FormEditEvent
     */
    action: number;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    action_code: FormEditEventActionCodeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FormEditEvent
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormEditEvent
     */
    before?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    code?: string;
    /**
     * 
     * @type {object}
     * @memberof FormEditEvent
     */
    definition?: any;
    /**
     * 
     * @type {number}
     * @memberof FormEditEvent
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof FormEditEvent
     */
    is_required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    moved_category?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    new_name?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    old_name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormEditEvent
     */
    options?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    question?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    question_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    response_type?: FormEditEventResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    response_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    review_instructions?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormEditEvent
     */
    ssc_issues?: Array<string>;
    /**
     * 
     * @type {QuestionConditionStructured}
     * @memberof FormEditEvent
     */
    structured?: QuestionConditionStructured;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    target_category?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    target_question?: string;
    /**
     * 
     * @type {string}
     * @memberof FormEditEvent
     */
    text?: string;
}




/**
* @export
* @enum {string}
*/
export enum FormEditEventActionCodeEnum {
    ADDRESPONSE = 'ADD_RESPONSE',
    UPDATERESPONSE = 'UPDATE_RESPONSE',
    DELETERESPONSE = 'DELETE_RESPONSE',
    ADDQUESTION = 'ADD_QUESTION',
    UPDATEQUESTION = 'UPDATE_QUESTION',
    MOVEQUESTION = 'MOVE_QUESTION',
    DELETEQUESTION = 'DELETE_QUESTION',
    UPDATECATEGORY = 'UPDATE_CATEGORY',
    MOVECATEGORY = 'MOVE_CATEGORY',
    DELETECATEGORY = 'DELETE_CATEGORY',
    UPDATECONDITIONTEXT = 'UPDATE_CONDITION_TEXT',
    UPDATECONDITIONSTRUCTURED = 'UPDATE_CONDITION_STRUCTURED'
}
/**
* @export
* @enum {string}
*/
export enum FormEditEventResponseTypeEnum {
    EVIDENCE = 'EVIDENCE',
    MULTISELECT = 'MULTI_SELECT',
    NORESPONSE = 'NO_RESPONSE',
    NUMBER = 'NUMBER',
    PERCENTAGE = 'PERCENTAGE',
    SINGLESELECT = 'SINGLE_SELECT',
    TEXT = 'TEXT'
}


