import React, { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Body,
  Cell,
  FlexedDiv,
  IconButton,
  Header,
  HeaderCell,
  RightCell,
  Row,
  RowActions,
  Wrapper,
} from './FindingsTable.styles';
import { Horizontal } from 'common/common.styles';
import { withTranslation } from 'react-i18next';
import {
  TranslatePropsType,
  RequestFindingType,
  Uuid,
} from 'common/common.types';
import { findLast } from 'ramda';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface FindingsTablePropsType extends TranslatePropsType {
  data: RequestFindingType[];
  deleteFinding: (id: Uuid) => void;
}

const getRowUniqueId = (optimisticId: Uuid) => `finding-row-${optimisticId}`;

const FindingsTable = ({ t, data, deleteFinding }: FindingsTablePropsType) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // Check if there are some optimistically added items that are not yet saved
    // onto the server and if yes, scroll to the last of them.
    const lastItem = findLast(v => Boolean(v.optimisticId), data);
    if (!lastItem || wrapperRef.current === null) {
      return;
    }
    const element = document.getElementById(
      getRowUniqueId(lastItem.optimisticId!),
    );

    if (!element) {
      return;
    }
    wrapperRef.current.scrollTop = element.offsetTop;
  }, [data]);

  return (
    <Wrapper data-cy="formReportFindingsTable" ref={wrapperRef}>
      <Header>
        <HeaderCell>{t('riskLevelAndFinding') as string}</HeaderCell>
        <HeaderCell>{t('planAndNotes') as string}</HeaderCell>
      </Header>
      <Body>
        {data.map(
          ({
            id,
            optimisticId,
            remediationPlan,
            remediationPlanNote,
            riskLevel,
            riskLevelNote,
          }) => {
            const isNotSavedOnServer = Boolean(optimisticId);
            return (
              <Row
                key={`${id}-${optimisticId}`}
                data-cy="findingsTableFinding"
                id={optimisticId ? getRowUniqueId(optimisticId) : undefined}
              >
                <FlexedDiv>
                  <Cell>
                    <div>
                      {t('riskLevel', { context: riskLevel }) as string}
                    </div>
                    <div>{riskLevelNote}</div>
                  </Cell>
                </FlexedDiv>
                <Horizontal>
                  <RightCell>
                    <div>
                      {
                        t('remediationPlan', {
                          context: remediationPlan,
                        }) as string
                      }
                    </div>
                    <div>{remediationPlanNote}</div>
                  </RightCell>
                  <RowActions>
                    <IconButton
                      data-cy="findingsTableDeleteFindingBtn"
                      disabled={isNotSavedOnServer}
                      onClick={() => deleteFinding(id)}
                    >
                      <FontAwesomeIcon icon={faTimes as IconProp} />
                    </IconButton>
                  </RowActions>
                </Horizontal>
              </Row>
            );
          },
        )}
      </Body>
    </Wrapper>
  );
};

export default withTranslation('FormReport')(FindingsTable);
