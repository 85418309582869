import Page, { PageActionsBaseType, PageBaseType, PagePropsType } from './Page';
import React from 'react';
import SendRequest from '../../src/components/SendRequest';
import i18n from '../i18n';
import { isAfter, differenceInDays } from 'date-fns';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch, bindActionCreators } from 'redux';
import { appealCredits } from 'store/support/actions';
import { copyTextToClipboard } from '../utils/copyTextToClipboard';
import { flash } from 'store/flashes/actions';
import {
  getInfo,
  getMailingList,
  getUserContactSummary,
} from 'store/user/actions';
import { getPricingInfo, openModal } from 'store/stripe/actions';
import { hideModal, showModal } from '../utils/Modal';
import { resetModals } from '../utils/Modal/store/actions/resetModals';
import { startLoading } from 'store/app/actions';
import { StandardStateType } from 'store/standard/reducer';
import {
  FormsGetPreviewFunctionType,
  FormsLoadQuestionsFunctionType,
  FormsPutQuestionFunctionType,
  getPreview,
  loadQuestions,
  putQuestion,
} from 'store/forms/actions';
import {
  CurrentUserType,
  FormType,
  LanguageType,
  MailingListItemType,
  Uuid,
} from 'common/common.types';
import {
  deleteStandard,
  getStandards,
  renameStandard,
  RenameStandardFunctionType,
  resetSelection,
  resetStandards,
  StandardsDeleteFunctionType,
  toggleStandardSelection,
  ToggleStandardSelectionFunctionType,
  ToggleStandardSelectionParamsType,
} from 'store/standard/actions';
import {
  RequestsFormType,
  SendRequestStateType,
  SendRequestViewEnum,
} from 'store/requests/requests.types';
import {
  clearSendRequestError,
  RequestsSendFormFunctionType,
  RequestsSendFormRedirectEnum,
  RequestsSwitchSendRequestViewFunctionType,
  resetRequestsForm,
  scheduleRequest,
  sendForm,
  setFormValue,
  switchSendRequestView,
  sendInternalForm,
  scheduleInternalRequest,
} from 'store/requests/actions';
import { isDomainValid } from '../utils/isDomainValid';
import { getCompanyCurrentTeams } from 'store/user/actions/getCompanyCurrentTeams';
import { Team } from 'generated/models';
import {
  setSettings,
  SetSettingsFunctionType,
} from 'store/settings/actions/setSettings';
import { SettingsStateType } from 'store/settings/settings.reducer';
import { uploadLogo, UploadLogoFunctionType } from 'store/companies/actions';

export interface SendRequestContextType extends PageBaseType {
  data: {
    form: RequestsFormType;
    mailingList: MailingListItemType[];
    previewForm: FormType;
    requestState: SendRequestStateType;
    standards: StandardStateType;
    settings: SettingsStateType;
    user: CurrentUserType;
    userTeams: Team[];
    language: LanguageType;
  };
  actions: PageActionsBaseType & {
    appealCredits: () => void;
    copyToClipboard: (text: string) => void;
    deleteStandard: StandardsDeleteFunctionType;
    getPreview: FormsGetPreviewFunctionType;
    hideModal: (modalId: string) => void;
    loadQuestions: FormsLoadQuestionsFunctionType;
    navigate: any;
    openModal: () => void;
    putQuestion: FormsPutQuestionFunctionType;
    renameStandard: RenameStandardFunctionType;
    resetForm: () => void;
    sendRequest: RequestsSendFormFunctionType;
    setValue: (field: string, value: any) => void;
    showModal: (modalId: string) => void;
    setSettings: SetSettingsFunctionType;
    switchView: RequestsSwitchSendRequestViewFunctionType;
    toggleStandardSelection: ToggleStandardSelectionFunctionType;
    getUserContactSummary: (email: string) => void;
    cobrandLogo: UploadLogoFunctionType;
  };
}

const SendRequestPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      form: state.requests.form,
      mailingList: state.user.mailingList.request,
      previewForm: state.forms.currentForm,
      requestState: state.requests.sendRequest,
      standards: state.standard,
      user: state.user.currentUser,
      settings: state.settings,
      language: state.settings.languagePreference,
    })}
    dispatches={(dispatch: Dispatch<ActionType>, ownProps: PagePropsType) => ({
      getData: ({
        queryParams: { domain },
      }: {
        queryParams: { domain?: string };
      }) => {
        if (domain) {
          const parsedDomain = decodeURIComponent(domain);

          dispatch(switchSendRequestView(SendRequestViewEnum.LINK));
          dispatch(
            setFormValue(
              'targets',
              isDomainValid(parsedDomain)
                ? [{ value: parsedDomain, label: parsedDomain }]
                : [],
            ),
          );
        }

        dispatch(startLoading(3));
        dispatch(getMailingList('request'));
        dispatch(getCompanyCurrentTeams());
        dispatch(
          getStandards({
            status: ['system', 'custom'],
            sort: 'most_recent',
          }),
        );
        dispatch(getInfo());
      },
      reset: () => {
        dispatch(resetStandards());
        dispatch(resetRequestsForm());
        dispatch(resetModals());
      },
      actions: {
        ...bindActionCreators(
          {
            deleteStandard,
            getPreview,
            hideModal,
            loadQuestions,
            putQuestion,
            renameStandard,
            sendInternalForm,
            setValue: setFormValue,
            showModal,
            switchView: switchSendRequestView,
            setSettings,
          },
          dispatch,
        ),
        appealCredits: () => {
          dispatch(getPricingInfo());
          dispatch(openModal());
          dispatch(appealCredits());
        },
        copyToClipboard: (text: string, firstContact?: boolean) => {
          copyTextToClipboard(text);
          if (firstContact) {
            dispatch(flash(i18n.t('common:responses.commonCopySuccess')));
          } else {
            dispatch(flash(i18n.t('common:responses.copySuccess')));
          }
        },
        getUserContactSummary: (email: string) => {
          dispatch(getUserContactSummary(email));
        },
        sendRequest: (
          data: RequestsFormType,
          standards: Uuid[],
          redirectType?: RequestsSendFormRedirectEnum,
        ) => {
          const { recurrence, sendDate } = data;

          if (
            (isAfter(sendDate, new Date()) &&
              differenceInDays(sendDate, new Date()) > 0) ||
            recurrence.unit ||
            recurrence.type
          ) {
            if (redirectType === RequestsSendFormRedirectEnum.INTERNAL) {
              dispatch(scheduleInternalRequest(data, standards));
            } else {
              dispatch(scheduleRequest(data, standards));
            }
          } else {
            if (redirectType === RequestsSendFormRedirectEnum.INTERNAL) {
              dispatch(sendInternalForm(data, standards));
            } else {
              dispatch(sendForm(data, standards, redirectType));
            }
          }
        },
        navigate: ownProps.history.push,
        resetForm: () => {
          dispatch(resetSelection());
          dispatch(resetRequestsForm());
          dispatch(resetModals());
        },
        toggleStandardSelection: (
          params: ToggleStandardSelectionParamsType,
        ) => {
          // @ts-ignore
          dispatch(toggleStandardSelection(params));
          dispatch(clearSendRequestError());
        },
        cobrandLogo: (file: any) => {
          dispatch(startLoading(1));
          dispatch(uploadLogo(file));
        },
      },
    })}
  >
    <SendRequest />
  </Page>
);

export default SendRequestPage;
