/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { FormHistoryData } from "./FormHistoryData";
// @ts-ignore
import { User } from "./User";

/**
 * 
 * @export
 * @interface FormHistory
 */
export interface FormHistory {
    /**
     * 
     * @type {string}
     * @memberof FormHistory
     */
    action: FormHistoryActionEnum;
    /**
     * Datetime of history creation
     * @type {Date}
     * @memberof FormHistory
     */
    created_at: Date;
    /**
     * 
     * @type {FormHistoryData}
     * @memberof FormHistory
     */
    data?: FormHistoryData;
    /**
     * 
     * @type {User}
     * @memberof FormHistory
     */
    user: User;
}




/**
* @export
* @enum {string}
*/
export enum FormHistoryActionEnum {
    CREATED = 'CREATED',
    FIRSTOPENEDVENDOR = 'FIRST_OPENED_VENDOR',
    CHANGEDSTATE = 'CHANGED_STATE'
}


