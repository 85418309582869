import styled from 'styled-components';

export const CobrandButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CobrandButton = styled.label`
  color: ${({ theme }) => theme.basic.colors.blue};
  font-size: ${({ theme }) => theme.basic.fontSizes.medium};
  cursor: pointer;
  margin-right: 150px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 10px;
  border: ${({ theme }) => `1px solid ${theme.basic.colors.blue}`};
  border-radius: 3px;

  input {
    display: none;
  }
`;

export const CobrandButtonDelete = styled(CobrandButton)`
  margin-right: 10px;
`;

export const Content = styled.div`
  margin: 25px 45px;
  min-width: 540px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.basic.colors.light};
`;
