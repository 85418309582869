import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { Uuid } from '../../../common/common.types';
import { PdfExportCheck } from '../../../generated/models/PdfExportCheck';
import update from 'immutability-helper';
import { call, put } from 'redux-saga/effects';
import delay from 'utils/delayPromise';
import withUrl from 'utils/url/withUrl';

export interface FormsDownloadFindingsPdfCallParamsType {
  request_id: Uuid;
  export_uuid: Uuid;
}

export type FormsDownloadFindingsPdfActionType = ApiCallActionType<
  '@forms/API/DOWNLOAD_FINDINGS_PDF',
  FormsDownloadFindingsPdfCallParamsType,
  PdfExportCheck,
  never,
  FormsStateType
>;

/**
 * Do not call the action directly, use exportFindingsToPdf.
 *
 * The action work only in co-operation with exportFindingsToPdf.
 */
export const downloadFindingsPdf = (
  formId: Uuid,
  requestId: Uuid,
  exportUuid: Uuid,
): FormsDownloadFindingsPdfActionType => ({
  type: '@forms/API/DOWNLOAD_FINDINGS_PDF',
  payload: {
    endpointPath: ['requests', 'findings', 'checkExportToPdfStatus'],
    callParams: {
      request_id: requestId,
      export_uuid: exportUuid,
    },
    selector: ({ export_finished }, { forms }): Partial<FormsStateType> => {
      if (
        !export_finished ||
        typeof forms.fetchedFormId === 'undefined' ||
        formId !== forms.fetchedFormId
      ) {
        // Not interested in the results anymore
        return forms;
      }
      return update(forms, {
        findingsExporting: { $set: false },
      });
    },
    postActions: ({ export_finished }: PdfExportCheck, { forms }) => {
      if (
        typeof forms.fetchedFormId === 'undefined' ||
        formId !== forms.fetchedFormId
      ) {
        return [];
      }
      if (export_finished) {
        window.location.href = withUrl(
          `requests/${requestId}/findings/downloads/pdf_export/${exportUuid}`,
        );
        return [];
      }
      return [
        () => call(delay, 2000),
        () => put(downloadFindingsPdf(formId, requestId, exportUuid)),
      ];
    },
  },
});
