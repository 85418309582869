import styled from 'styled-components';
import colors from 'common/common.colors';

export const Gradient = styled.div<{ flip?: boolean }>`
  background: linear-gradient(
      ${({ flip }: { flip?: boolean }) => (flip ? 0 : 180)}deg,
      #375ba1 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #48abdb;
  height: 100%;
  position: relative;
  width: 50%;
`;

export const CTAButton = styled.button`
  ${({ theme }) => theme.fonts.bold.big}
  background-color: ${colors.elements.button.whiteBlue.background};
  border-radius: 8px;
  border: none;
  box-shadow: 4px 4px 4px 0 ${colors.elements.button.whiteBlue.color};
  color: ${colors.elements.button.whiteBlue.color};
  cursor: pointer;
  height: 83px;
  margin-top: 24px;
  outline: none;
  transition: all 0.8s;
  width: 415px;

  &:hover {
    background-color: rgba(72, 171, 219, 0.5);
  }

  &:active {
    background-color: #48abdb;
    background-size: 100%;
  }
`;

export const Tip = styled.div`
  ${({ theme }) => theme.fonts.semibold.small}
  color: ${({ theme }) => theme.basic.colors.light};
  letter-spacing: 0.25px;
  padding-top: 21px;
  text-align: center;
`;

export const Question = styled.div`
  ${({ theme }) => theme.fonts.semibold.large}
  color: ${({ theme }) => theme.basic.colors.light};
  letter-spacing: 0.25px;
  padding-top: 46px;
  text-align: center;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
`;
