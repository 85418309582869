import Numeric from './Numeric';
import React from 'react';
import { ResponseInputPropsType } from '../Response';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

const Percentage = ({
  actions,
  data,
  t,
}: ResponseInputPropsType & TranslatePropsType) => (
  <Numeric
    actions={actions}
    data={data}
    isRound
    max={100}
    min={0}
    placeholder={t('percentage') as string}
    suffix="%"
    width={70}
  />
);

export default withTranslation('Numeric')(Percentage);
