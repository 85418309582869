import React, { useCallback, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../common/Button';
import {
  AddCategory,
  CategoryButton,
  PlusIcon,
  Wrapper,
} from './Categories.styles';
import Category from './Category';
import { FormCreatorContextActionsType } from 'pages/FormCreatorPage';
import {
  TranslatePropsType,
  FormCreatorRenderType,
  Uuid,
} from 'common/common.types';

import { ConfirmModal, useConfirmModal } from '../../common/ConfirmModal';
import { OpenedQuestionType } from 'store/formCreator/formCreator.types';
import { findQuestionByUuid } from 'store/formCreator/selectors/renderData';
import { CONFIRM_CONDITION_SWITCH_MODAL_ID } from 'common/common.constants';
import { FactorWithIssuesSelectOption } from 'store/formCreator/selectors/issues';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CategoriesPropsType extends TranslatePropsType {
  actions: FormCreatorContextActionsType;
  areQuestionsDeletable: boolean;
  factorsWithIssues: FactorWithIssuesSelectOption[];
  hasConditionsToggle: boolean;
  hasIssuesToggle: boolean;
  openedQuestion?: OpenedQuestionType;
  renderData: FormCreatorRenderType[];
  showOnlyErrors: boolean;
}

const Categories = ({
  t,
  actions,
  actions: {
    addCategory,
    deleteCategory,
    hideModal,
    showModal,
    switchQuestionConditionType,
  },
  areQuestionsDeletable,
  factorsWithIssues,
  hasConditionsToggle,
  hasIssuesToggle,
  openedQuestion,
  renderData,
  showOnlyErrors,
}: CategoriesPropsType) => {
  const { showConfirm, onCancel, onConfirm } = useConfirmModal(
    deleteCategory,
    showModal,
    hideModal,
  );

  const {
    onCancel: cancelTextToStructuredConfirm,
    onConfirm: confirmTextToStructuredConfirm,
    showConfirm: showConfirmTextToStructuredConfirm,
  } = useConfirmModal(
    switchQuestionConditionType,
    showModal,
    hideModal,
    CONFIRM_CONDITION_SWITCH_MODAL_ID,
  );

  const handleSwitchQuestionConditionType = useCallback(
    (questionId: Uuid, type: 'structured' | 'text') => {
      const question = findQuestionByUuid(renderData, questionId);
      if (
        question &&
        question.condition &&
        question.condition.type === 'text' &&
        type === 'structured' &&
        question.condition.payload.trim().length > 0 &&
        (!question.conditionBackup ||
          question.conditionBackup.text !== question.condition.payload)
      ) {
        showConfirmTextToStructuredConfirm(questionId, type);
      } else {
        switchQuestionConditionType(questionId, type);
      }
    },
    [
      renderData,
      showConfirmTextToStructuredConfirm,
      switchQuestionConditionType,
    ],
  );

  const filteredRenderData = useMemo(
    () => renderData.filter(category => category.hasErrors || !showOnlyErrors),
    [renderData, showOnlyErrors],
  );

  const openedCategory = openedQuestion
    ? openedQuestion.categoryName
    : undefined;

  const areCategoriesDeletable = renderData.length > 1;

  return (
    <Wrapper>
      <ConfirmModal
        confirmText={t('deleteCategory.yes')}
        text={t('deleteCategory.infoText')}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
      <ConfirmModal
        confirmText={t('conditionTextToStructuredConfirm.continue')}
        modalId={CONFIRM_CONDITION_SWITCH_MODAL_ID}
        text={t('conditionTextToStructuredConfirm.message')}
        title={t('conditionTextToStructuredConfirm.title')}
        onConfirm={confirmTextToStructuredConfirm}
        onCancel={cancelTextToStructuredConfirm}
      />

      {filteredRenderData.map(category => (
        <Category
          isOpen={category.name === openedCategory}
          actions={actions}
          areQuestionsDeletable={areQuestionsDeletable}
          category={category}
          factorsWithIssues={factorsWithIssues}
          isDeletable={areCategoriesDeletable}
          hasConditionsToggle={hasConditionsToggle}
          hasIssuesToggle={hasIssuesToggle}
          key={category.name}
          onClickDelete={showConfirm}
          openedQuestion={openedQuestion}
          renderData={renderData}
          showOnlyErrors={showOnlyErrors}
          switchQuestionConditionType={handleSwitchQuestionConditionType}
        />
      ))}
      <CategoryButton data-test="add-category">
        <Button style={{ border: 'none' }} color="transparent">
          <PlusIcon>
            <FontAwesomeIcon icon={faPlus as IconProp} />
          </PlusIcon>
          <AddCategory onClick={() => addCategory(renderData)}>
            {t('addCategory') as string}
          </AddCategory>
        </Button>
      </CategoryButton>
    </Wrapper>
  );
};
export default withTranslation('FormCreator')(Categories);
