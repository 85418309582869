import React, { MouseEvent, Fragment } from 'react';
import route, { FORM } from '../../../routes';
import { Arity1Fn } from 'ramda';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TranslatePropsType, Uuid } from 'common/common.types';
import { Wrapper } from './Notifications.styles';
import { stringify } from 'qs';
import { withTranslation } from 'react-i18next';

export interface NotificationsPropsType
  extends TranslatePropsType,
    RouteComponentProps {
  formId: Uuid;
  handleClick: (fn: Arity1Fn) => (fnArg: any) => (e: MouseEvent) => void;
  notifications: RequestNotificationType[];
}

type RequestNotificationType = {
  filterKey: string;
  titleKey: string;
};

const Notifications = ({
  formId,
  handleClick,
  notifications,
  history: { push: navigate },
  t,
}: NotificationsPropsType) => (
  <Wrapper data-test="notification">
    <strong>{`${t('Requests.new')} `}</strong>

    {notifications.map(({ filterKey, titleKey }, index) => (
      <Fragment key={`request-notification-${filterKey}`}>
        {index > 0 && ', '}
        <span
          data-test={`request-notification-${filterKey}`}
          onClick={handleClick(navigate)(
            `${route(FORM, { id: formId })}?${stringify({
              filters: [filterKey],
            })}`,
          )}
        >
          {t(`Requests.${titleKey}`) as string}
        </span>
      </Fragment>
    ))}
  </Wrapper>
);

export default withRouter(withTranslation()(Notifications));
