export interface FormsAutocompleteMatchSearchFinishedPayloadType {
  matchingQuestionsCount: number;
}

export interface FormsAutocompleteMatchSearchFinishedActionType {
  type: '@forms/AUTOCOMPLETE_MATCH_SEARCH_FINISHED';
  payload: FormsAutocompleteMatchSearchFinishedPayloadType;
}

export const autocompleteMatchSearchFinished = (
  payload: FormsAutocompleteMatchSearchFinishedPayloadType,
): FormsAutocompleteMatchSearchFinishedActionType => ({
  type: '@forms/AUTOCOMPLETE_MATCH_SEARCH_FINISHED',
  payload,
});
