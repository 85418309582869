/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormDefinition,
    FormDefinitionFromJSON,
    FormDefinitionFromJSONTyped,
    FormDefinitionToJSON,
    FormDefinitionDraftCategoryStats,
    FormDefinitionDraftCategoryStatsFromJSON,
    FormDefinitionDraftCategoryStatsFromJSONTyped,
    FormDefinitionDraftCategoryStatsToJSON,
    QuestionDefinitionSummary,
    QuestionDefinitionSummaryFromJSON,
    QuestionDefinitionSummaryFromJSONTyped,
    QuestionDefinitionSummaryToJSON,
    Standard,
    StandardFromJSON,
    StandardFromJSONTyped,
    StandardToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormDefinitionSummaryExtended
 */
export interface FormDefinitionSummaryExtended {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof FormDefinitionSummaryExtended
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof FormDefinitionSummaryExtended
     */
    updatedAt: Date;
    /**
     * Status for form definition initialization (creation).
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    creationStatus: FormDefinitionSummaryExtendedCreationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    ownerId: string;
    /**
     * ID of my own preview form (if available)
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    previewFormId?: string;
    /**
     * 
     * @type {string}
     * @memberof FormDefinitionSummaryExtended
     */
    standardId: string;
    /**
     * 
     * @type {number}
     * @memberof FormDefinitionSummaryExtended
     */
    withConditionCount: number;
    /**
     * 
     * @type {Array<FormDefinitionDraftCategoryStats>}
     * @memberof FormDefinitionSummaryExtended
     */
    categories?: Array<FormDefinitionDraftCategoryStats>;
    /**
     * 
     * @type {Array<QuestionDefinitionSummary>}
     * @memberof FormDefinitionSummaryExtended
     */
    questions?: Array<QuestionDefinitionSummary>;
    /**
     * 
     * @type {Standard}
     * @memberof FormDefinitionSummaryExtended
     */
    standard: Standard;
}

export function FormDefinitionSummaryExtendedFromJSON(json: any): FormDefinitionSummaryExtended {
    return FormDefinitionSummaryExtendedFromJSONTyped(json, false);
}

export function FormDefinitionSummaryExtendedFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormDefinitionSummaryExtended {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'creationStatus': json['creation_status'],
        'description': json['description'],
        'id': json['id'],
        'name': json['name'],
        'ownerId': json['owner_id'],
        'previewFormId': !exists(json, 'preview_form_id') ? undefined : json['preview_form_id'],
        'standardId': json['standard_id'],
        'withConditionCount': json['with_condition_count'],
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(FormDefinitionDraftCategoryStatsFromJSON)),
        'questions': !exists(json, 'questions') ? undefined : ((json['questions'] as Array<any>).map(QuestionDefinitionSummaryFromJSON)),
        'standard': StandardFromJSON(json['standard']),
    };
}

export function FormDefinitionSummaryExtendedToJSON(value?: FormDefinitionSummaryExtended | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'creation_status': value.creationStatus,
        'description': value.description,
        'id': value.id,
        'name': value.name,
        'owner_id': value.ownerId,
        'preview_form_id': value.previewFormId,
        'standard_id': value.standardId,
        'with_condition_count': value.withConditionCount,
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(FormDefinitionDraftCategoryStatsToJSON)),
        'questions': value.questions === undefined ? undefined : ((value.questions as Array<any>).map(QuestionDefinitionSummaryToJSON)),
        'standard': StandardToJSON(value.standard),
    };
}

/**
* @export
* @enum {string}
*/
export enum FormDefinitionSummaryExtendedCreationStatusEnum {
    FINISHEDFAIL = 'FINISHED_FAIL',
    FINISHEDSUCCESS = 'FINISHED_SUCCESS',
    INPROGRESS = 'IN_PROGRESS',
    INQUEUE = 'IN_QUEUE'
}


