export default ({ length }: string) => {
  if (length < 11) {
    return 42;
  } else if (length < 21) {
    return 38;
  } else if (length < 31) {
    return 30;
  } else if (length < 81) {
    return 24;
  } else {
    return 18;
  }
};
