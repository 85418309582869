import React, { useMemo } from 'react';
import { Donut, Label, LabelOrientation, WIDTH, Wrapper } from './Chart.styles';
import { useTranslation } from 'react-i18next';

const SIZE = 150;
const OFFSET = (WIDTH - SIZE) / 2 - 5;
const RADIUS = 50;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
const ANGLE_OFFSET = -90; // to start segments at 12:00
const CX = 75;
const CY = 75;

interface SegmentType {
  key: string;
  color: string;
  value: number;
}

interface ChartDataType extends SegmentType {
  dashOffset: number;
  labelOrientation: LabelOrientation;
  labelX: number;
  labelY: number;
  percentage: number;
  transform: string;
}

interface ChartPropsType {
  segments: SegmentType[];
}

const calculateLabelCoords = (percentageValue: number, angleOffset: number) => {
  const angle = (percentageValue * 360) / 2 + angleOffset;
  const radians = angle * (Math.PI / 180);
  // from 12:00 to 6:00
  const inRightHalf = ANGLE_OFFSET <= angle && angle <= ANGLE_OFFSET * -1;

  return {
    x: RADIUS * Math.cos(radians) + CX,
    y: RADIUS * Math.sin(radians) + CY,
    inRightHalf,
  };
};

const calculateChartData = (segments: SegmentType[], total: number) => {
  const chartData: ChartDataType[] = [];
  let currentAngleOffset = ANGLE_OFFSET;

  segments.forEach(segment => {
    const percentage = segment.value / total;
    const dashOffset = CIRCUMFERENCE * (1 - percentage);
    const { x, y, inRightHalf } = calculateLabelCoords(
      percentage,
      currentAngleOffset,
    );

    chartData.push({
      ...segment,
      dashOffset,
      percentage,
      labelOrientation: inRightHalf
        ? LabelOrientation.RIGHT
        : LabelOrientation.LEFT,
      labelX: x,
      labelY: y,
      transform: `rotate(${currentAngleOffset}, ${CX}, ${CY})`,
    });
    currentAngleOffset = percentage * 360 + currentAngleOffset;
  });

  return chartData;
};

const Chart = ({ segments: origSegments }: ChartPropsType) => {
  const { t } = useTranslation('CreditsOverview');
  const total = useMemo(
    () => origSegments.reduce((sum, c) => sum + c.value, 0),
    [origSegments],
  );
  const segments = useMemo(
    () =>
      total > 0
        ? calculateChartData(
            origSegments.filter(s => s.value > 0),
            total,
          )
        : [],
    [origSegments, total],
  );

  return (
    <Wrapper data-test="creditsChart">
      <svg
        data-test="creditsDiagram"
        width={SIZE}
        height={SIZE}
        viewBox={`0 0 ${SIZE} ${SIZE}`}
      >
        <g>
          <circle cx={CX} cy={CY} r={RADIUS} fill="#fff" />
          <Donut
            cx={CX}
            cy={CY}
            r={RADIUS}
            fill="transparent"
            strokeWidth="22"
          />
        </g>

        {segments.map(segment => (
          <circle
            key={segment.key}
            cx={CX}
            cy={CY}
            r={RADIUS}
            fill="transparent"
            stroke={segment.color}
            strokeWidth="30"
            strokeDasharray={CIRCUMFERENCE}
            strokeDashoffset={segment.dashOffset}
            transform={segment.transform}
          />
        ))}
      </svg>
      {segments.map(segment => (
        <Label
          key={segment.key}
          data-test={segment.key}
          background={segment.color}
          left={segment.labelX + OFFSET}
          top={segment.labelY}
          labelOrientation={segment.labelOrientation}
        >
          <strong>{segment.value}</strong> {t(segment.key) as string}
        </Label>
      ))}
    </Wrapper>
  );
};

export default Chart;
