/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface QuestionConditionStructuredItem
 */
export interface QuestionConditionStructuredItem {
    /**
     * 
     * @type {string}
     * @memberof QuestionConditionStructuredItem
     */
    operator: QuestionConditionStructuredItemOperatorEnum;
    /**
     * 
     * @type {string}
     * @memberof QuestionConditionStructuredItem
     */
    parent_question_uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionConditionStructuredItem
     */
    response_uuid: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionConditionStructuredItem
     */
    value: string;
}




/**
* @export
* @enum {string}
*/
export enum QuestionConditionStructuredItemOperatorEnum {
    CONTAINS = 'CONTAINS',
    ENDSWITH = 'ENDS_WITH',
    EQUALTO = 'EQUAL_TO',
    GREATERTHAN = 'GREATER_THAN',
    GREATERTHANOREQUALTO = 'GREATER_THAN_OR_EQUAL_TO',
    GREATERTHANORLESSTHAN = 'GREATER_THAN_OR_LESS_THAN',
    LESSTHAN = 'LESS_THAN',
    LESSTHANOREQUALTO = 'LESS_THAN_OR_EQUAL_TO',
    NOTCONTAINS = 'NOT_CONTAINS',
    NOTENDSWITH = 'NOT_ENDS_WITH',
    NOTEQUALTO = 'NOT_EQUAL_TO',
    NOTSTARTSWITH = 'NOT_STARTS_WITH',
    STARTSWITH = 'STARTS_WITH'
}


