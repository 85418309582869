import { ApiCallActionPayloadType } from '../../store.types';
import { equals, propEq, reject } from 'ramda';
import { getStandards } from './getStandards';
import { put } from 'redux-saga/effects';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { Uuid, StandardType } from '../../../common/common.types';

export type StandardsDeleteFunctionType = (
  params: StandardsDeleteParamsType,
) => void;

export interface StandardsDeleteParamsType {
  id: Uuid;
}

export type DeleteStandardActionType = OptimisticActionType<
  '@standard/API/DELETE',
  ApiCallActionPayloadType<StandardsDeleteParamsType>
>;

export const deleteStandard = (
  params: StandardsDeleteParamsType,
): DeleteStandardActionType => ({
  type: '@standard/API/DELETE',
  payload: {
    endpointPath: ['standards', 'delete'],
    callParams: params,
    expectedStateChange: {
      'standard.all.all': reject<StandardType>(propEq('id', params.id)),
      'standard.all.mostRecent': reject<StandardType>(propEq('id', params.id)),
      'standard.all.selected': reject<Uuid>(equals(params.id)),
    },
    selector: null,
    postActions: [
      (_, state) =>
        put(
          getStandards({
            status: state.standard.all.fetchedStatuses,
            sort: 'most_recent',
          }),
        ),
    ],
  },
});
