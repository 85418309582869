/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreditRecord
 */
export interface CreditRecord {
    /**
     * 
     * @type {Date}
     * @memberof CreditRecord
     */
    expiresAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CreditRecord
     */
    isExpired: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreditRecord
     */
    remaining: number;
}

export function CreditRecordFromJSON(json: any): CreditRecord {
    return CreditRecordFromJSONTyped(json, false);
}

export function CreditRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expiresAt': (new Date(json['expires_at'])),
        'isExpired': json['is_expired'],
        'remaining': json['remaining'],
    };
}

export function CreditRecordToJSON(value?: CreditRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expires_at': (value.expiresAt.toISOString()),
        'is_expired': value.isExpired,
        'remaining': value.remaining,
    };
}


