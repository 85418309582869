import { StateType, ActionType } from '../store.types';
import withErrorPopups from 'utils/subscriptions/withErrorPopups';
import fromActionStream from 'utils/subscriptions/fromActionStream';
import formsApi from '../../api/new/formsApi';
import { tap, delay, retryWhen } from 'rxjs/operators';
import {
  autocompleteGlobalMappingStarted,
  autocompleteMatchSearchFinished,
  autocompleteGlobalMappingFinished,
  autocompleteProgressUpdated,
  getFormWithoutReset,
} from './actions';
import { Uuid, QuestionTabEnum } from '../../common/common.types';
import {
  GetFormAutocompleteDetailResourceRequest,
  AutocompleteExecutionStatusEnum,
} from '../../generated/new';
import { Enable } from 'utils/reduxSubs';
import unreachableReturn from 'utils/unreachableReturn';

const AUTOCOMPLETE_FINISH_POLLING_INTERVAL = 6000;
const GLOBAL_MAPPING_POLLING_INTERVAL = 12000;

// Subscriptions IDs

const AUTOCOMPLETE_MATCH_SEARCH = 'formsAutocompleteMatchSearch';
const AUTOCOMPLETE_GLOBAL_MAPPING_START_MONITORING =
  'formsAutocompleteGlobalMappingStartMonitoring';
const AUTOCOMPLETE_FINISH_MONITORING = 'formsAutocompleteFinishMonitoring';

const matchSearchSub = (formId: Uuid) =>
  fromActionStream(() =>
    withErrorPopups(
      formsApi.getFormAutocompleteStatsResource({ formId, full: true }),
      ({ entry: { mappingInProgress, matchingQuestionsCount = 0 } }) =>
        mappingInProgress
          ? autocompleteGlobalMappingStarted()
          : autocompleteMatchSearchFinished({ matchingQuestionsCount }),
    ),
  );

/**
 * Subscription for detecting global mapping start or end via polling.
 *
 * @param fireOn When set to `'start'`, action is dispatched in the first
 *        occasion when the mapping is in progress. When set to `'end'`, action
 *        is dispatched on the first occasion when the mapping is not in
 *        progress.
 * @param action Action to be dispatched on mapping start or mapping end.
 */
const globalMappingFinishSub = (
  formId: Uuid,
  fireOn: 'start' | 'end',
  action: () => ActionType,
) =>
  fromActionStream(() =>
    withErrorPopups(
      formsApi.getFormAutocompleteStatsResource({ formId }).pipe(
        tap(({ entry, entry: { mappingInProgress } }) => {
          if (fireOn === 'end' ? mappingInProgress : !mappingInProgress) {
            throw entry;
          }
        }),
        retryWhen(errors =>
          errors.pipe(delay(GLOBAL_MAPPING_POLLING_INTERVAL)),
        ),
      ),
      () => action(),
    ),
  );

/**
 * Subscription for fetching autocomplete progress
 */
const autocompleteFinishSub = (
  params: GetFormAutocompleteDetailResourceRequest,
): Enable<ActionType> => dispatch =>
  fromActionStream(() =>
    withErrorPopups(
      formsApi.getFormAutocompleteDetailResource(params).pipe(
        tap(({ entry, entry: { status } }) => {
          dispatch(autocompleteProgressUpdated(entry));
          if (
            status !== AutocompleteExecutionStatusEnum.FINISHEDSUCCESS &&
            status !== AutocompleteExecutionStatusEnum.FINISHEDFAIL
          ) {
            throw entry;
          }
          // Autocomplete finished, update the form to load the filled questions
          dispatch(
            getFormWithoutReset(params.formId, {
              thenSwitchQuestionTab: QuestionTabEnum.LOOKUP_LENS,
            }),
          );
        }),
        retryWhen(errors =>
          errors.pipe(delay(AUTOCOMPLETE_FINISH_POLLING_INTERVAL)),
        ),
      ),
    ),
  )(dispatch);

const forms = (model: StateType) => {
  if (!model.forms.currentForm || !model.forms.currentForm.id) {
    return {};
  }

  const formId = model.forms.currentForm.id;

  // TODO: When it is required to add some subscriptions from a different domain
  //       than autocomplete, please extract this to a different file

  const dialog = model.forms.autocomplete.dialog;
  switch (dialog.type) {
    case 'OPENED_MATCH_SEARCH_IN_PROGRESS':
      return {
        [AUTOCOMPLETE_MATCH_SEARCH]: matchSearchSub(formId),
      };

    case 'OPENED_GLOBAL_MAPPING_IN_PROGRESS':
      return {
        formsAutocompleteGlobalMappingFinishMonitoring: globalMappingFinishSub(
          formId,
          'end',
          autocompleteGlobalMappingFinished,
        ),
      };

    case 'OPENED_STARTING_AUTOCOMPLETE':
      return {
        [AUTOCOMPLETE_GLOBAL_MAPPING_START_MONITORING]: globalMappingFinishSub(
          formId,
          'start',
          autocompleteGlobalMappingStarted,
        ),
        formsAutocompleteStarting: fromActionStream(() =>
          withErrorPopups(
            formsApi.postFormAutocompleteResource({ formId }),
            ({ entry }) => autocompleteProgressUpdated(entry),
          ),
        ),
      };

    case 'OPENED_MATCHES_NOT_FOUND':
    case 'OPENED_MATCHES_FOUND':
    case 'OPENED_AUTOCOMPLETE_FINISHED':
      return {
        [AUTOCOMPLETE_GLOBAL_MAPPING_START_MONITORING]: globalMappingFinishSub(
          formId,
          'start',
          autocompleteGlobalMappingStarted,
        ),
      };

    case 'OPENED_AUTOCOMPLETE_IN_PROGRESS':
      return {
        [AUTOCOMPLETE_GLOBAL_MAPPING_START_MONITORING]: globalMappingFinishSub(
          formId,
          'start',
          autocompleteGlobalMappingStarted,
        ),
        [AUTOCOMPLETE_FINISH_MONITORING]: autocompleteFinishSub({
          formId,
          aceId: dialog.progress.autocompleteId,
        }),
        [AUTOCOMPLETE_MATCH_SEARCH]:
          typeof dialog.progress.matchingQuestionsCount === 'undefined'
            ? // Fetching of the missing matches count
              matchSearchSub(formId)
            : undefined,
      };

    case 'CLOSED_AUTOCOMPLETE_IN_PROGRESS':
      return {
        [AUTOCOMPLETE_FINISH_MONITORING]: autocompleteFinishSub({
          formId,
          aceId: dialog.progress.autocompleteId,
        }),
      };

    case 'OPENED_ALL_QUESTIONS_FILLED':
    case 'CLOSED_IDLE':
      return {};

    default:
      return unreachableReturn(dialog, {});
  }
};

export default forms;
