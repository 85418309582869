import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Bar, Summary, Wrapper } from './StatusTimeSummary.styles';
import { Days } from './StatusTimeBar.styles';
import { TranslatePropsType } from 'common/common.types';
import { all, isNil, keys } from 'ramda';
import { withTranslation } from 'react-i18next';

const objToArray = (obj: any) => {
  const arr = [];

  for (const key of keys(obj)) {
    arr.push(obj[key]);
  }

  return arr;
};

interface StatusTimeSummaryPropsType extends TranslatePropsType {
  days: number;
  percentages: any;
}

const StatusTimeSummary = ({
  days,
  percentages,
  t,
}: StatusTimeSummaryPropsType) => {
  const percentagesArray = objToArray(percentages);

  return (
    <Wrapper>
      {all(({ value }) => !isNil(value))(percentagesArray) && (
        <>
          <Summary>
            {percentagesArray.map((p, i) => (
              <Bar key={i} percentage={p.percentage} color={p.color} />
            ))}
          </Summary>

          <Days>
            {days
              ? ReactHtmlParser(t('days', { count: days }) as string)
              : (t('na') as string)}
          </Days>
        </>
      )}
    </Wrapper>
  );
};

export default withTranslation('Insights')(StatusTimeSummary);
