import {
  RequestDetailType,
  RequestDirectionType,
  RequestType,
} from '../../../common/common.types';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { always, evolve } from 'ramda';
import { RequestModifyListCallParamsType } from './archiveRequest';
import updateRequestsStateChange from 'utils/updateRequestsStateChange';

export type RequestClearNotificationsFunctionType = (
  requests: RequestType[],
) => void;

export type RequestClearNotificationsActionType = OptimisticActionType<
  '@requests/API/CLEAR_NOTIFICATIONS',
  ApiCallActionPayloadType<RequestModifyListCallParamsType>
>;

export const clearNotifications = (
  requests: RequestDetailType[],
  direction: RequestDirectionType,
): RequestClearNotificationsActionType => ({
  type: '@requests/API/CLEAR_NOTIFICATIONS',
  payload: {
    callParams: {
      request_ids: requests.map(request => request.id),
      clear_notifications: true,
    },
    expectedStateChange: (() => {
      const requestIds = new Set(requests.map(r => r.id));
      const directionPath = `requests.${direction}`;

      const remapRequest = (request: RequestDetailType) =>
        requestIds.has(request.id)
          ? evolve({
              hasNewMessages: always(false),
              hasNewResponses: always(false),
              hasNewSscData: always(false),
            })(request)
          : request;

      return updateRequestsStateChange(directionPath, requests, remapRequest);
    })(),
    endpointPath: ['requests', 'modifyList'],
    selector: null,
  },
});
