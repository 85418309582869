/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SettingsDictionaryResponse
 */
export interface SettingsDictionaryResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof SettingsDictionaryResponse
     */
    msg: string;
    /**
     * 
     * @type {object}
     * @memberof SettingsDictionaryResponse
     */
    entry: object;
}

export function SettingsDictionaryResponseFromJSON(json: any): SettingsDictionaryResponse {
    return SettingsDictionaryResponseFromJSONTyped(json, false);
}

export function SettingsDictionaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettingsDictionaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': json['entry'],
    };
}

export function SettingsDictionaryResponseToJSON(value?: SettingsDictionaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': value.entry,
    };
}


