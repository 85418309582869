import React from 'react';
import Page, { PagePropsType, PageBaseType } from './Page';
import * as actions from 'store/forms/actions';
import * as selectors from 'store/forms/forms.selectors';
import { startLoading } from 'store/app/actions';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch, bindActionCreators } from 'redux';
import { SharedFormType } from 'common/common.types';
import { FormPageContextActionsType, FormDataPropsType } from './FormPage';
import FormContextWrapper from 'components/Form/FormContextWrapper';
import { validateFilters } from '../utils/filters/forms.filters';

export interface SharedFormDataPropsType extends FormDataPropsType {
  sharedFormData?: SharedFormType;
}

export interface SharedFormPageContextType extends PageBaseType {
  actions: FormPageContextActionsType;
  data: SharedFormDataPropsType;
}

const SharedFormPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      autocomplete: state.forms.autocomplete,
      currentDropdown: state.forms.currentDropdown,
      currentForm: selectors.getCurrentForm(state.forms),
      filters: state.forms.filters,
      preferredTab: state.forms.preferredTab,
      sharedFormData: state.forms.sharedFormData,
      user: state.user.currentUser,
    })}
    dispatches={(dispatch: Dispatch<ActionType>) => ({
      getData: ({
        routeParams: { code },
        queryParams: { filters = [], assignee = null },
      }: {
        routeParams: { code: string };
        queryParams: any;
      }) => {
        dispatch(startLoading(1));
        dispatch(
          actions.getForm({
            id: code,
            queryFilters: validateFilters(filters),
            queryAssignee: assignee,
          }),
        );
      },
      actions: bindActionCreators(
        {
          addFilter: actions.addFilter,
          clearFilters: actions.clearFilters,
          loadQuestions: actions.loadQuestions,
          removeFilter: actions.removeFilter,
          setCurrentDropdown: actions.setCurrentDropdown,
          switchQuestionTab: actions.switchQuestionTab,
        },
        dispatch,
      ),
    })}
  >
    <FormContextWrapper />
  </Page>
);

export default SharedFormPage;
