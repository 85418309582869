import { Observable, of, empty } from 'rxjs';
import { contains } from 'ramda';
import { catchError, mergeMap } from 'rxjs/operators';
import { getErrorMessage } from '../getErrorMessage';
import { flash } from 'store/flashes/actions/flash';
import { ActionType } from 'store/store.types';

const IGNORED_STATUSES = [401, 402, 403, 410];
// TODO: Make translatable
const DEFAULT_ERROR_MSG = 'An unexpected error occurred.';
const DEFAULT_ERROR_STATUS = 901;

export const withErrorPopups = <SuccDataType, ErrorDataType>(
  apiCall: Observable<SuccDataType>,
  succAction?: (data: SuccDataType) => ActionType,
  errorAction?: (data: ErrorDataType) => ActionType,
): Observable<ActionType> =>
  apiCall.pipe(
    mergeMap(data => (succAction ? of(succAction(data)) : empty())),
    catchError(err => {
      if (err.status === 0) {
        err.status = DEFAULT_ERROR_STATUS;
      }

      if (contains(err.status, IGNORED_STATUSES)) {
        return err.response && errorAction
          ? of(errorAction(err.response))
          : empty();
      }
      const errorMsg = getErrorMessage(
        err.status,
        'API call error',
        (err.response && err.response.msg) || DEFAULT_ERROR_MSG,
      );

      return of(flash(errorMsg, 'error'));
    }),
  );

export default withErrorPopups;
