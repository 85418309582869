import React from 'react';
import colors from 'common/common.colors';
import { SSC_HOME } from '../../../routes';
import { Tags } from '../Tags';
import { withTranslation } from 'react-i18next';
import { CompanyDetailType, TranslatePropsType } from 'common/common.types';
import {
  Banner,
  CompanyDetails,
  CompanyInfo,
  CompanyName,
  Domain,
  Hexagon,
  RacingStripe,
  Score,
  ScoreDetail,
  ScoreInfo,
  ScoreText,
  Unverified,
  Wrapper,
} from './CompanyHeader.styles';

interface CompanyHeaderPropsType extends TranslatePropsType {
  data: CompanyDetailType;
}

const CompanyHeader = ({
  data: {
    company,
    company: {
      info: { domain, grade, industry, isVerified, name, score },
    },
    requests,
  },
  t,
}: CompanyHeaderPropsType) => {
  const scoreLabel = t('CompanyHeader.score');
  const scoreDetailLabel = t('CompanyHeader.scoreDetail');

  return (
    <Wrapper>
      <Banner>
        <ScoreInfo data-cy="score-info">
          <RacingStripe />

          <Hexagon
            color={colors.grades[grade]}
            size={64}
            text={isVerified ? grade : '?'}
            textColor="#fff"
          />

          {isVerified ? (
            <>
              <Score data-cy="company-score">{score}</Score>

              <ScoreText>{scoreLabel}</ScoreText>

              <ScoreDetail
                href={`${SSC_HOME}/#/scorecard/${domain}/factors`}
                rel="noreferrer nofollow"
                target="_blank"
              >
                {scoreDetailLabel}
              </ScoreDetail>
            </>
          ) : (
            <Unverified receiver={name} />
          )}
        </ScoreInfo>

        <CompanyInfo>
          <CompanyName data-cy="companyName">{name}</CompanyName>

          <CompanyDetails>
            {isVerified && (
              <>
                <Domain
                  href={`http://${domain}`}
                  rel="noreferrer nofollow"
                  target="_blank"
                >
                  {domain}
                </Domain>{' '}
                | {industry.toUpperCase()}
              </>
            )}
          </CompanyDetails>

          <Tags company={company} editable fetchMyTags />
        </CompanyInfo>
      </Banner>
    </Wrapper>
  );
};

export default withTranslation()(CompanyHeader);
