import DefaultGrade from './DefaultGrade';
import React from 'react';
import defaultGrade from './defaultGrade.png';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface GradePropsType extends TranslatePropsType {
  url?: string;
}

// EventTarget don't have src so its any
const setFallbackSrc = (e: any) => {
  e.target.src = defaultGrade;
};

const Grade = ({ url, t }: GradePropsType) =>
  url ? (
    <img onError={setFallbackSrc} src={url} alt={t('alt') as string} />
  ) : (
    <DefaultGrade />
  );

export default withTranslation('Grade')(Grade);
