import React from 'react';
import { GeneralError } from './components/GeneralError';
import { captureError } from './utils/sentryConfig';

interface GlobalErrorBoundaryPropsType {
  children: React.ReactNode;
}

interface StateType {
  hasError: boolean;
  error?: Error | undefined;
  children?: React.ReactNode;
}

export class GlobalErrorBoundary extends React.PureComponent<
  GlobalErrorBoundaryPropsType,
  StateType
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, info: any) {
    captureError(error, info);
    this.setState({ hasError: true, error });
    console.warn({ error, info });
  }

  render() {
    if (this.state.hasError) {
      return <GeneralError />;
    }

    return this.props.children;
  }
}
