/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestFiltersStats,
    RequestFiltersStatsFromJSON,
    RequestFiltersStatsFromJSONTyped,
    RequestFiltersStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface RequestFiltersStatsMeta
 */
export interface RequestFiltersStatsMeta {
    /**
     * Data related to state changes
     * @type {RequestFiltersStats}
     * @memberof RequestFiltersStatsMeta
     */
    requestFiltersStats?: RequestFiltersStats;
}

export function RequestFiltersStatsMetaFromJSON(json: any): RequestFiltersStatsMeta {
    return RequestFiltersStatsMetaFromJSONTyped(json, false);
}

export function RequestFiltersStatsMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFiltersStatsMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestFiltersStats': !exists(json, 'request_filters_stats') ? undefined : RequestFiltersStatsFromJSON(json['request_filters_stats']),
    };
}

export function RequestFiltersStatsMetaToJSON(value?: RequestFiltersStatsMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request_filters_stats': RequestFiltersStatsToJSON(value.requestFiltersStats),
    };
}


