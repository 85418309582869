import React, { useContext } from 'react';
import EmailRecipient from './EmailRecipient';
import LinkRecipient from './LinkRecipient';
import InternalRecipient from './Internal/InternalRecipient';
import TabView from '../../common/Tabs';
import { RecipientWrapper } from './Recipient.styles';
import { SendRequestViewEnum } from 'store/requests/requests.types';
import useFlag from 'utils/hooks/useFlag';
import { withTranslation } from 'react-i18next';
import { RequestsSendFormRedirectEnum } from 'store/requests/actions';
import {
  TranslatePropsType,
  ToggleEnum,
  Uuid,
  LanguageType,
} from 'common/common.types';
import { PageContext } from 'pages/Page';
import { SendRequestContextType } from 'pages/SendRequestPage';
import unreachableReturn from 'utils/unreachableReturn';

const { LINK, SENT, INTERNAL } = RequestsSendFormRedirectEnum;

interface RecipientPropsType extends TranslatePropsType {
  isFormValid: boolean;
  selectedStandards: Uuid[];
  copyToClipboard: (text: string) => void;
  getUserContactSummary: (email: string) => void;
  language: LanguageType;
}

const currentStep = {
  Email: 0,
  Link: 1,
  Internal: 2,
};

const currentViewToRedirectType = (view: SendRequestViewEnum) => {
  switch (view) {
    case SendRequestViewEnum.LINK:
      return LINK;
    case SendRequestViewEnum.INTERNAL:
      return INTERNAL;
    case SendRequestViewEnum.EMAIL:
      return SENT;
    default:
      return unreachableReturn(view, SENT);
  }
};

const Recipient = ({
  copyToClipboard,
  isFormValid,
  selectedStandards,
  t,
  getUserContactSummary,
  language,
}: RecipientPropsType) => {
  const {
    actions: { appealCredits, sendRequest, setValue, switchView, setSettings },
    data: {
      form,
      form: {
        dueDate,
        emailBody,
        recurrence,
        reminder,
        sendDate,
        sourceTeam,
        targets,
        targetTeams,
      },
      mailingList,
      requestState: { error, view: viewOption },
      user,
      user: {
        credits,
        company: { teams: allTeams },
        teams: userTeams,
      },
      settings,
    },
  } = useContext(PageContext) as SendRequestContextType;

  const balance = (credits && credits.summary!.remaining) || 0;

  const handleSubmit = (
    overrideRedirectType?: RequestsSendFormRedirectEnum,
    sendNotification?: boolean,
  ) => {
    const redirectType = overrideRedirectType
      ? overrideRedirectType
      : currentViewToRedirectType(viewOption);
    if (sendNotification !== undefined) {
      form.notify = sendNotification;
    }
    sendRequest(form, selectedStandards, redirectType);
  };

  const submitButtonProps = {
    appealCredits,
    balance,
    handleSubmit,
    isFormValid,
    sendRequestError: error,
    selectedStandardsCount: selectedStandards.length,
  };

  const teamsEnabled = useFlag(user, [ToggleEnum.INTERNAL_USECASE]);
  const isSourceTeamSelectorVisible = teamsEnabled && userTeams.length > 0;
  return (
    <RecipientWrapper>
      <TabView
        initialState={currentStep[viewOption]}
        onTabSelect={switchView}
        tabs={[
          {
            key: SendRequestViewEnum.EMAIL,
            label: t('sendEmail') as string,
            component: (
              <EmailRecipient
                dueDate={dueDate}
                isSourceTeamSelectorVisible={isSourceTeamSelectorVisible}
                mailingList={mailingList}
                recurrence={recurrence}
                reminder={reminder}
                sendDate={sendDate}
                setValue={setValue}
                sourceTeam={sourceTeam}
                submitButtonProps={submitButtonProps}
                targets={targets}
                user={user}
                userTeams={userTeams}
                setSettings={setSettings}
                showCoBranding={settings.showCoBranding}
                copyToClipboard={copyToClipboard}
                getUserContactSummary={getUserContactSummary}
                language={language}
              />
            ),
          },
          {
            key: SendRequestViewEnum.LINK,
            label: t('createLink') as string,
            component: (
              <LinkRecipient
                dueDate={dueDate}
                isSourceTeamSelectorVisible={isSourceTeamSelectorVisible}
                setValue={setValue}
                sourceTeam={sourceTeam}
                submitButtonProps={submitButtonProps}
                targets={targets}
                userTeams={userTeams}
              />
            ),
          },
          {
            hidden: !teamsEnabled,
            key: SendRequestViewEnum.INTERNAL,
            label: t('sendInternal') as string,
            component: (
              <InternalRecipient
                allTeams={allTeams}
                dueDate={dueDate}
                emailBody={emailBody}
                isSourceTeamSelectorVisible={isSourceTeamSelectorVisible}
                recurrence={recurrence}
                reminder={reminder}
                selectedTeams={targetTeams}
                sendDate={sendDate}
                setValue={setValue}
                sourceTeam={sourceTeam}
                submitButtonProps={submitButtonProps}
                user={user}
                userTeams={userTeams}
                language={language}
              />
            ),
          },
        ]}
      />
    </RecipientWrapper>
  );
};

export default withTranslation('SendRequest')(Recipient);
