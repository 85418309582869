export const getActionDomain = (actionType: string): string => {
  const domain = actionType.substring(
    actionType.indexOf('@') + 1,
    actionType.indexOf('/'),
  );

  if (domain) {
    return domain;
  } else {
    throw new Error(
      'Action type does not contain a domain. The domain string should be preceded by "@" and' +
        ' followed by "/"',
    );
  }
};
