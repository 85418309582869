import LinkModal from './Modals/LinkModal';
import React, { useContext } from 'react';
import Recipient from './Recipient';
import Standards from './Standards';
import Stepper from './Stepper';
import { BarWrapper } from '../common/Bar/Bar.styles';
import { Content, Wrapper } from 'common/common.styles';
import { PageContext } from 'pages/Page';
import { PageHeader } from '../common/PageHeader';
import { REQUEST_LINK_MODAL_ID } from 'common/common.constants';
import { TranslatePropsType, ToggleEnum } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { ConfirmModal, useConfirmModal } from '../common/ConfirmModal';
import { SendRequestContextType } from 'pages/SendRequestPage';
import { SendRequestViewEnum } from 'store/requests/requests.types';
import useFlag from 'utils/hooks/useFlag';

const SendRequest = ({ t }: TranslatePropsType) => {
  const {
    actions: {
      copyToClipboard,
      deleteStandard,
      getPreview,
      hideModal,
      loadQuestions,
      putQuestion,
      renameStandard,
      resetForm,
      setValue,
      showModal,
      toggleStandardSelection,
      getUserContactSummary,
    },
    data: {
      form: { targets, targetTeams, sourceTeam },
      previewForm,
      requestState: { requests: sentRequests, view },
      standards,
      user,
      language,
    },
  } = useContext(PageContext) as SendRequestContextType;

  const { showConfirm, onCancel, onConfirm } = useConfirmModal(
    deleteStandard,
    showModal,
    hideModal,
  );

  const selectedStandards = standards.all.selected;
  const teamsEnabled = useFlag(user, [ToggleEnum.INTERNAL_USECASE]);

  const isFirstStepValid = selectedStandards.length > 0;

  const isTargetFilled =
    view === SendRequestViewEnum.INTERNAL
      ? targetTeams.length > 0
      : targets.length > 0;

  const isSourceTeamValid =
    !teamsEnabled || user.teams.length < 2 || Boolean(sourceTeam);

  const isSecondStepValid = isTargetFilled && isSourceTeamValid;

  const isFormValid = isFirstStepValid && isSecondStepValid;

  const steps = [
    {
      name: t('selectQuestionnaire') as string,
      component: (
        <Standards
          deleteStandard={showConfirm}
          getPreview={getPreview}
          hideModal={hideModal}
          loadQuestions={loadQuestions}
          previewForm={previewForm}
          putQuestion={putQuestion}
          renameStandard={renameStandard}
          setValue={setValue}
          showModal={showModal}
          standards={standards}
          toggleStandard={toggleStandardSelection}
          user={user}
          language={language}
        />
      ),
      isValid: isFirstStepValid,
    },
    {
      name: t('recipientsAndDates') as string,
      component: (
        <Recipient
          isFormValid={isFormValid}
          selectedStandards={selectedStandards}
          copyToClipboard={copyToClipboard}
          getUserContactSummary={getUserContactSummary}
          language={language}
        />
      ),
      isValid: isSecondStepValid,
    },
  ];

  return (
    <>
      <PageHeader
        heading={
          <BarWrapper>
            <h2>{t('sendQuestionnaire') as string}</h2>
          </BarWrapper>
        }
      />

      <Wrapper grey>
        <Content padding="0 15px">
          {sentRequests.length > 0 && (
            <LinkModal
              copyToClipboard={copyToClipboard}
              modalId={REQUEST_LINK_MODAL_ID}
              requests={sentRequests}
              onClose={resetForm}
            />
          )}

          <ConfirmModal
            confirmText={t('deleteConfirm') as string}
            text={t('deleteConfirmText') as string}
            onConfirm={onConfirm}
            onCancel={onCancel}
          />

          <Stepper steps={steps} />
        </Content>
      </Wrapper>
    </>
  );
};

export default withTranslation('SendRequest')(SendRequest);
