/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface IssueType
 */
export interface IssueType {
    /**
     *
     * @type {string}
     * @memberof IssueType
     */
    factor: string;
    /**
     *
     * @type {string}
     * @memberof IssueType
     */
    key: string;
    /**
     *
     * @type {string}
     * @memberof IssueType
     */
    severity: IssueTypeSeverityEnum;
    /**
     *
     * @type {string}
     * @memberof IssueType
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof IssueType
     */
    title: string;
}

export function IssueTypeFromJSON(json: any): IssueType {
    return IssueTypeFromJSONTyped(json, false);
}

export function IssueTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'factor': json['factor'],
        'key': json['key'],
        'severity': json['severity'],
        'description': json['description'],
        'title': json['title'],
    };
}

export function IssueTypeToJSON(value?: IssueType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'factor': value.factor,
        'key': value.key,
        'severity': value.severity,
        'description': value.description,
        'title': value.title,
    };
}

/**
* @export
* @enum {string}
*/
export enum IssueTypeSeverityEnum {
    High = 'high',
    Info = 'info',
    Low = 'low',
    Medium = 'medium',
    Positive = 'positive'
}


