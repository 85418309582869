import styled from 'styled-components';
import { HorizontallyCentered } from 'common/common.styles';

import { CloseButton as ImportedCloseButton } from 'utils/Modal/Modal.styles';

import { Button } from '../Button';

export const Wrapper = styled.div`
  position: relative;
  min-width: 534px;
  min-height: 210px;
  background-color: ${({ theme }) => theme.basic.colors.light};
  padding: 49px 70px;
`;

export const TextWrapper = styled.div`
  display: inline-block;
`;

export const Text = styled.div`
  display: flex;
`;

export const Title = styled.h1`
  line-height: 22px;
  margin-bottom: 12px;
  ${({ theme }) => theme.fonts.regular.medium}
  min-width: 450px;
  white-space: break-spaces;
`;

export const Paragraph = styled.p`
  flex-grow: 1;
  width: 0;
  line-height: 17px;
  margin-bottom: 5px;
  ${({ theme }) => theme.fonts.regular.small}
  white-space: break-spaces;
`;

export const Buttons = styled(HorizontallyCentered)`
  margin-top: 20px;
`;

export const ConfirmButton = styled(Button).attrs({
  size: 'medium',
})`
  line-height: 20px;
  letter-spacing: 0.25px;
  ${({ theme }) => theme.fonts.bold.medium}
`;

export const CancelButton = styled.div`
  text-decoration: underline;
  line-height: 17px;
  cursor: pointer;
  color: ${({ theme }) => theme.atlas.text.secondary};
  ${({ theme }) => theme.fonts.regular.small}
`;

export const CloseButton = styled(ImportedCloseButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
