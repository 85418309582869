import React, { useState, useRef, useEffect } from 'react';
import { CreditsOverview, CreditsCTA } from '../Upsell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LOGIN, SALES, SETTINGS, UTILS } from '../../routes';
import { Link } from 'react-router-dom';
import { NavIcon } from './NavItem.styles';
import {
  CurrentUserType,
  TranslatePropsType,
  ToggleEnum,
  LanguageType,
} from 'common/common.types';
import { faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons';
import { useOnClickOutside } from 'utils/useOnClickOutside';
import { withTranslation } from 'react-i18next';
import useFlag from 'utils/hooks/useFlag';
import {
  Email,
  Item,
  LanguageLabel,
  LanguageSelect,
  LogOut,
  UserDropdownWrapper,
  Wrapper,
} from './UserDropdown.styles';
import i18next from 'i18next';
import { languagePreference } from 'common/common.constants';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface UserDropdownPropsType extends TranslatePropsType {
  appealCredits: () => void;
  user: CurrentUserType;
  logout: () => void;
  setLanguagePreference: (value: { label: string; value: string }) => void;
  getLanguagePreference: {
    label: string;
    value: string;
  };
  language: LanguageType;
}

const UserDropdown = ({
  appealCredits,
  logout,
  user,
  user: { isAuthenticated, isNormal, isSales },
  t,
  setLanguagePreference,
  getLanguagePreference,
  language,
}: UserDropdownPropsType) => {
  const [isOpened, setOpened] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setOpened(false));

  const handleLogin = () => {
    window.location.href = LOGIN;
  };

  const hasCTAModal = useFlag(user, [ToggleEnum.CREDITS_CTA_MODAL]);
  const hasLanguagePreferenceEnabled = useFlag(user, [
    ToggleEnum.LANGUAGE_PREFERENCES,
  ]);

  useEffect(() => {
    i18next.changeLanguage(getLanguagePreference.value);
  }, [getLanguagePreference]);

  const handleLanguageChange = (value: { label: string; value: string }) => {
    setLanguagePreference(value);
  };

  return (
    <Wrapper ref={ref}>
      <NavIcon data-cy="header-userIcon" onClick={() => setOpened(!isOpened)}>
        <FontAwesomeIcon icon={faUser as IconProp} />
      </NavIcon>

      {isOpened && isAuthenticated && (
        <UserDropdownWrapper data-cy="header-userContextMenu">
          <Email>{user.email}</Email>

          {isNormal && (
            <>
              {user.credits && (
                <>
                  <CreditsOverview
                    companyName={user.company.name}
                    credits={user.credits}
                    language={language}
                  />

                  {hasCTAModal && (
                    <Item>
                      <CreditsCTA
                        credits={user.credits}
                        onClick={appealCredits}
                      />
                    </Item>
                  )}
                </>
              )}

              <Item>
                <Link data-pendo="user-account-settings" to={SETTINGS}>
                  {t('UserDropdown.settings') as string}
                </Link>
              </Item>

              {isSales && (
                <Item>
                  <Link to={SALES}>{t('UserDropdown.sales') as string}</Link>
                </Item>
              )}

              {isSales && (
                <Item>
                  <Link to={UTILS}>{t('UserDropdown.utils') as string}</Link>
                </Item>
              )}
            </>
          )}

          {hasLanguagePreferenceEnabled && (
            <Item>
              <LanguageLabel>
                {t('UserDropdown.languageText') as string}
              </LanguageLabel>
              <LanguageSelect
                value={getLanguagePreference}
                options={languagePreference}
                onChange={handleLanguageChange}
              />
            </Item>
          )}

          <Item>
            <LogOut onClick={logout} data-test="logOut">
              <FontAwesomeIcon icon={faPowerOff as IconProp} />

              {t('UserDropdown.signOut') as string}
            </LogOut>
          </Item>
        </UserDropdownWrapper>
      )}

      {isOpened && !isAuthenticated && (
        <UserDropdownWrapper>
          <Item>{t('UserDropdown.anonymous') as string}</Item>

          <Item>
            <button onClick={handleLogin}>
              {t('UserDropdown.logIn') as string}
            </button>
          </Item>
        </UserDropdownWrapper>
      )}
    </Wrapper>
  );
};

export default withTranslation()(UserDropdown);
