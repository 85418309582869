/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface ResponseDefinitionEntityError
 */
export interface ResponseDefinitionEntityError {
    /**
     * 
     * @type {string}
     * @memberof ResponseDefinitionEntityError
     */
    code: ResponseDefinitionEntityErrorCodeEnum;
}




/**
* @export
* @enum {string}
*/
export enum ResponseDefinitionEntityErrorCodeEnum {
    DUPLICATEDOPTIONVALUE = 'DUPLICATED_OPTION_VALUE',
    EMPTYOPTIONVALUE = 'EMPTY_OPTION_VALUE',
    LESSTHANTWOOPTIONSINRESPONSE = 'LESS_THAN_TWO_OPTIONS_IN_RESPONSE'
}


