import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Delete = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.atlas.teal.primary};
`;

export const Wrapper = styled.div`
  transition: ${({ theme }) => theme.basic.animations.normal};
  display: flex;
  height: 22px;
  padding: 1px 1px 1px 8px;
  border: 1px ${({ theme }) => theme.basic.colors.darkerBorder} solid;
  border-radius: 6px;
  justify-content: center;

  & span {
    ${({ theme }) => theme.fonts.light.mini}
    line-height: 20px;
    margin-right: 16px;
  }

  &:hover ${Delete} {
    color: ${({ theme }) => theme.atlas.teal[800]};
  }

  svg {
    position: relative;
    top: 1px;
    right: 2px;
  }
`;
