import React from 'react';
import { PossibleQuestionType } from 'store/formCreator/conditions/structuredCondition.types';
import {
  QuestionDefinitionSummaryType,
  TranslatePropsType,
} from 'common/common.types';
import {
  QuestionSelectWrapper,
  QuestionText,
  QuestionTextIcon,
  QuestionTextTooltip,
  StyledSelect,
} from './StructuredEditor.styles';
import Tooltip from '../../common/Tooltip';
import { withTranslation } from 'react-i18next';

interface StructuredQuestionSelectPropsType extends TranslatePropsType {
  options: PossibleQuestionType[];
  onChange: (value: QuestionDefinitionSummaryType) => void;
  placeholder: string;
  value?: QuestionDefinitionSummaryType;
  visualState: 'error' | 'normal';
}

const getQuestionLabel = (x: QuestionDefinitionSummaryType) => x.code;
const getQuestionValue = (x: QuestionDefinitionSummaryType) => x.wizardId;

const StructuredQuestionSelect = ({
  options,
  onChange,
  value,
  visualState,
  t,
}: StructuredQuestionSelectPropsType) => (
  <QuestionSelectWrapper isQuestionSelected={Boolean(value)}>
    <StyledSelect
      getOptionLabel={getQuestionLabel}
      getOptionValue={getQuestionValue}
      options={options}
      placeholder={t('conditionEditor.placeholders.questionId')}
      value={value}
      onChange={onChange}
      visualState={visualState}
    />
    {value && (
      <Tooltip
        placement="top"
        overlay={
          <QuestionTextTooltip>
            {value.question ||
              (t('conditionEditor.missingQuestionText') as string)}
          </QuestionTextTooltip>
        }
      >
        <QuestionText>
          <QuestionTextIcon />
        </QuestionText>
      </Tooltip>
    )}
  </QuestionSelectWrapper>
);

export default withTranslation('FormCreator')(StructuredQuestionSelect);
