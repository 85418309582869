import { STATUSES, GROUPED_STATUSES } from 'common/common.constants';
import i18n from 'i18next';

const getGroupedStatus = (key: string, isVendor: boolean): string => {
  key = GROUPED_STATUSES[key].id;
  if (key === STATUSES.CREATED.id) {
    key = isVendor ? 'RECEIVED' : 'SENT';
  }
  return i18n.t(`common:status.${key}`);
};

export default getGroupedStatus;
