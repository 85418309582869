/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionsCopyStats
 */
export interface QuestionsCopyStats {
    /**
     * 
     * @type {number}
     * @memberof QuestionsCopyStats
     */
    copiedAttachmentsCount: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionsCopyStats
     */
    copiedResponsesCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionsCopyStats
     */
    isFinished: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionsCopyStats
     */
    standardId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionsCopyStats
     */
    targetId: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionsCopyStats
     */
    updatedQuestionsCount: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionsCopyStats
     */
    updatedQuestionsIds: Array<string>;
}

export function QuestionsCopyStatsFromJSON(json: any): QuestionsCopyStats {
    return QuestionsCopyStatsFromJSONTyped(json, false);
}

export function QuestionsCopyStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionsCopyStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'copiedAttachmentsCount': json['copied_attachments_count'],
        'copiedResponsesCount': json['copied_responses_count'],
        'isFinished': json['is_finished'],
        'standardId': json['standard_id'],
        'targetId': json['target_id'],
        'updatedQuestionsCount': json['updated_questions_count'],
        'updatedQuestionsIds': json['updated_questions_ids'],
    };
}

export function QuestionsCopyStatsToJSON(value?: QuestionsCopyStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'copied_attachments_count': value.copiedAttachmentsCount,
        'copied_responses_count': value.copiedResponsesCount,
        'is_finished': value.isFinished,
        'standard_id': value.standardId,
        'target_id': value.targetId,
        'updated_questions_count': value.updatedQuestionsCount,
        'updated_questions_ids': value.updatedQuestionsIds,
    };
}


