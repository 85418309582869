import React, { Component } from 'react';
import { FormattedInput } from './NumberInput.styles';
import { isNil } from 'ramda';

interface NumberInputStateType {
  value: number | '';
}

interface NumberInputPropsType {
  disabled: boolean;
  handleChange: (newValue: string) => void;
  isRound?: boolean;
  max?: number;
  min?: number;
  placeholder?: string;
  value: string;
  width?: number;
}

class NumberInput extends Component<
  NumberInputPropsType,
  NumberInputStateType
> {
  constructor(props: NumberInputPropsType) {
    super(props);
    const { value } = props;

    this.state = { value: this.parseNumber(value) };
  }

  componentDidUpdate(prevProps: NumberInputPropsType) {
    const { value } = this.props;

    if (prevProps.value !== value) {
      this.setState({ value: this.parseNumber(value) });
    }
  }

  parseNumber = (str: string) => {
    const { isRound } = this.props;

    if (str === '') {
      return '';
    }

    return isRound ? parseInt(str, 10) : Number(str);
  };

  handleChange = (stringValue: string) => {
    const value = this.parseNumber(stringValue);
    const { min, max } = this.props;

    if (max && value > max) {
      this.setState({ value: max });
      return;
    }

    if (!isNil(min) && value < min) {
      this.setState({ value: min });
      return;
    }

    this.setState({ value });
  };

  handleBlur = () => {
    const { handleChange } = this.props;
    const { value } = this.state;

    handleChange(value !== '' ? value.toString() : '');
  };

  render() {
    const { disabled, placeholder, width } = this.props;
    const { value } = this.state;

    return (
      <FormattedInput
        disabled={disabled}
        onBlur={this.handleBlur}
        onValueChange={values => this.handleChange(values.value)}
        placeholder={placeholder}
        thousandSeparator={' '}
        value={value}
        width={width}
      />
    );
  }
}
export default NumberInput;
