/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestUpdate
 */
export interface RequestUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof RequestUpdate
     */
    clearNotifications?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof RequestUpdate
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RequestUpdate
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RequestUpdate
     */
    read?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestUpdate
     */
    riskNote?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUpdate
     */
    status?: RequestUpdateStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestUpdate
     */
    vrmViewStatus?: RequestUpdateVrmViewStatusEnum;
}

export function RequestUpdateFromJSON(json: any): RequestUpdate {
    return RequestUpdateFromJSONTyped(json, false);
}

export function RequestUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clearNotifications': !exists(json, 'clear_notifications') ? undefined : json['clear_notifications'],
        'dueDate': !exists(json, 'due_date') ? undefined : (new Date(json['due_date'])),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'riskNote': !exists(json, 'risk_note') ? undefined : json['risk_note'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'vrmViewStatus': !exists(json, 'vrm_view_status') ? undefined : json['vrm_view_status'],
    };
}

export function RequestUpdateToJSON(value?: RequestUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clear_notifications': value.clearNotifications,
        'due_date': value.dueDate === undefined ? undefined : (value.dueDate.toISOString()),
        'message': value.message,
        'read': value.read,
        'risk_note': value.riskNote,
        'status': value.status,
        'vrm_view_status': value.vrmViewStatus,
    };
}

/**
* @export
* @enum {string}
*/
export enum RequestUpdateStatusEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}
/**
* @export
* @enum {string}
*/
export enum RequestUpdateVrmViewStatusEnum {
    VENDORAPPROVED = 'VENDOR_APPROVED',
    VENDORDECLINED = 'VENDOR_DECLINED',
    VENDORINITIAL = 'VENDOR_INITIAL',
    VRMREQUESTED = 'VRM_REQUESTED'
}


