import React from 'react';
import { useTranslation } from 'react-i18next';

import { SetFormValueFunctionType } from 'store/requests/actions';
import {
  CurrentUserType,
  LanguageType,
  RecurrenceItemType,
  ReminderItemType,
  TeamsItemType,
  ValueLabelType,
} from 'common/common.types';
import { Flex } from 'common/common.styles';
import { Wrapper } from '../EmailRecipient.styles';
import { NoTeamsBody, NoTeamsLink } from './InternalRecipient.styles';
import { DueDateWrapper } from '../Recipient.styles';
import TeamSelector from './TeamSelector';
import { SubmitRequestButtonPartialProps } from '../sharedTypes';
import Submit from '../../Submit';
import TeamReceivers from './TeamReceivers';
import Schedule from '../Schedule';
import DueDate from '../DueDate';
import Reminder from '../Reminder';
import Message from '../Message';
import config from '../../../../config';
import { Team } from 'generated/new';

interface InternalRecipientPropsType {
  allTeams: Team[] | null;
  dueDate?: Date | null;
  emailBody: string;
  isSourceTeamSelectorVisible: boolean;
  recurrence: RecurrenceItemType;
  reminder: ReminderItemType;
  selectedTeams: TeamsItemType[];
  sendDate: Date;
  setValue: SetFormValueFunctionType;
  sourceTeam?: ValueLabelType<string>;
  submitButtonProps: SubmitRequestButtonPartialProps;
  user: CurrentUserType;
  userTeams: Team[];
  language: LanguageType;
}

const getErrorMesssage = ({
  companyHasTeams,
  isAdmin,
}: {
  companyHasTeams: boolean;
  isAdmin: boolean;
}) => {
  const noTeamsMessage = isAdmin
    ? 'internal.noTeamsAdmin'
    : 'internal.noTeamsUser';

  const notInATeamMessage = isAdmin
    ? 'internal.notInATeamAdmin'
    : 'internal.notInATeamUser';

  return !companyHasTeams ? noTeamsMessage : notInATeamMessage;
};

const InternalRecipient = ({
  allTeams,
  dueDate,
  emailBody,
  isSourceTeamSelectorVisible,
  recurrence,
  reminder,
  selectedTeams,
  sendDate,
  setValue,
  sourceTeam,
  submitButtonProps: {
    appealCredits,
    balance,
    handleSubmit,
    isFormValid,
    sendRequestError,
    selectedStandardsCount,
  },
  user,
  userTeams,
  language,
}: InternalRecipientPropsType) => {
  const { t } = useTranslation('SendRequest');
  const companyHasTeams =
    !allTeams /* teams are loading */ || allTeams.length > 0;

  const isUserInATeam = userTeams.length > 0;

  const teamsCount = selectedTeams.length;
  const cost = teamsCount * selectedStandardsCount;

  const errorMessageNamespace = getErrorMesssage({
    isAdmin: user.isCustomerAdmin,
    companyHasTeams,
  });

  return (
    <div>
      {companyHasTeams && isUserInATeam ? (
        <Wrapper>
          <Flex>
            <TeamReceivers
              allTeams={allTeams}
              setValue={setValue}
              selectedTeams={selectedTeams}
            />

            <Schedule
              recurrence={recurrence}
              sendDate={sendDate}
              setValue={setValue}
              language={language}
            />
          </Flex>

          <Flex>
            <DueDateWrapper>
              <DueDate dueDate={dueDate} setValue={setValue} />

              {dueDate && <Reminder reminder={reminder} setValue={setValue} />}
            </DueDateWrapper>
            {isSourceTeamSelectorVisible && (
              <TeamSelector
                selected={sourceTeam}
                setValue={setValue}
                teams={userTeams}
                disabled={userTeams.length === 1}
                internalSend={true}
              />
            )}
          </Flex>

          <Message
            setValue={setValue}
            placeholder={t('placeholder') as string}
          />

          <Submit
            appealCredits={appealCredits}
            balance={balance}
            cost={cost}
            error={sendRequestError}
            handleSubmit={handleSubmit}
            isFormValid={isFormValid}
            isGeneratingLink={false}
            itemsCount={selectedStandardsCount}
            id="internal"
          />
        </Wrapper>
      ) : (
        <div>
          <strong>{t(`${errorMessageNamespace}.header`) as string}</strong>
          <NoTeamsBody>
            {t(`${errorMessageNamespace}.body`) as string}{' '}
            <NoTeamsLink
              data-pendo="no-teams-link"
              href={`${config.platformUrl}/#/my-settings/my-profile`}
              target="_blank"
            >
              {t('internal.sscSettings') as string}
            </NoTeamsLink>
            .
          </NoTeamsBody>
        </div>
      )}
    </div>
  );
};

export default InternalRecipient;
