import styled from 'styled-components';
import OrigUnverified from 'components/Requests/Request/Unverified';

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  font-family: ${({ theme }) => theme.basic.fontWeights.light};
`;

export const TableRow = styled.tr`
  :nth-child(2n) {
    background-color: #f7f7f8;
  }

  :hover td {
    background-color: #e5f3f3;
  }

  td:first-child {
    border-left: 5px solid #e8e1c8;
  }
`;

export const TableRowClickable = styled(TableRow)`
  cursor: pointer;
`;

export const TableHead = styled.th`
  z-index: 1;
  background-color: #f7f7f7;
  line-height: 78px;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.basic.fontSizes.normal};
  padding: 0 16px;
  top: 0;
  text-align: left;

  & .label {
    margin-right: 10px;
  }

  & svg {
    color: gray;
    height: 20px;
    cursor: pointer;
  }

  & .add-response-column svg {
    width: 20px;
    cursor: pointer;
  }

  &.answer {
    width: 400px;
    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

export const TableHeadFixed = styled(TableHead)`
  min-width: 400px;
  max-width: 600px;
`;

export const TableCell = styled.td`
  padding: 20px;
  font-size: ${({ theme }) => theme.basic.fontSizes.mini};
  vertical-align: middle;
  position: relative;
  line-height: 1.2em;

  &.answer {
    padding: 0;
    height: 74px;
  }
`;

export const TableCellClickable = styled(TableCell)`
  cursor: pointer;
`;

export const GradeWrapper = styled.div`
  img {
    display: inline-block;
    height: 30px;
    margin-right: 7px;
    vertical-align: middle;
  }
`;

export const Unverified = styled(OrigUnverified)`
  display: inline-block;
  margin-left: 8px;
`;
