import { ApiCallActionType } from '../../store.types';
import { FormHistory } from '../../../generated/models/FormHistory';
import { parseFormHistory } from 'utils/parsers/forms.parsers';
import { FormsStateType } from '../forms.reducer';
import { Uuid } from '../../../common/common.types';

export interface FormsGetHistoryCallParamsType {
  formId: Uuid;
}

export type FormsGetHistoryFunctionType = (formId: Uuid) => void;

export type FormsGetHistoryActionType = ApiCallActionType<
  '@forms/API/GET_HISTORY',
  FormsGetHistoryCallParamsType,
  FormHistory[],
  never,
  FormsStateType
>;

export const getHistory = (formId: Uuid): FormsGetHistoryActionType => ({
  type: '@forms/API/GET_HISTORY',
  payload: {
    callParams: { formId },
    endpointPath: ['forms', 'history'],
    selector: (data): Partial<FormsStateType> => ({
      currentFormHistory: data.map(parseFormHistory),
    }),
  },
});
