import { FlashActionType } from './actions/flash';
import { delay } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { removeFlashAction } from './actions';

function* flashCall(action: FlashActionType) {
  const {
    payload: { id },
  } = action;

  yield delay(5000);

  yield put(removeFlashAction(id));
}

export function* watchFlashesCallSaga() {
  yield takeEvery('@flashes/FLASH', flashCall);
}
