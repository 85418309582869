/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";

/**
 *
 * @export
 * @interface CompanySimple
 */
export interface CompanySimple {
    /**
     * SSC Grade A-F
     * @type {string}
     * @memberof CompanySimple
     */
    grade: CompanySimpleGradeEnum;
    /**
     * Grade picture URL
     * @type {string}
     * @memberof CompanySimple
     */
    grade_url: string;
    /**
     *
     * @type {string}
     * @memberof CompanySimple
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof CompanySimple
     */
    name: string;
    /**
     * SSC Score
     * @type {number}
     * @memberof CompanySimple
     */
    score: number;
    /**
     * - `NORMAL` company has at least one user that signed in via platform.
     *   Companies of this type can be seen/searched in UI
     * - `SYSTEM` company is a special company that is used when system performs some actions
     *   There MUST be only one in the system
     *   Companies of this type should **not** be seen/searched in UI.
     * - `TEMPORARY` company has no user that has signed in via platform.
     *   When user sends request to new user they always belong to new TEMPORARY company.
     *   Companies of this type should **not** be seen/searched in UI.
     * - `DELETED` company is deleted :)
     *   Companies of this type should **not** be seen/searched in UI.
     *   Company can be deleted either by the script - `NORMAL` => `DELETED` or when user logs in for
     *   the first time and their company already exists and we merge it, then `TEMPORARY` =>
     *   `DELETED`.
     * @type {string}
     * @memberof Company
     */
    internal_status: CompanySimpleInternalStatusEnum;
}




/**
 * @export
 * @enum {string}
 */
export enum CompanySimpleGradeEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    F = 'F',
    Empty = ''
}

/**
 * @export
 * @enum {string}
 */
export enum CompanySimpleInternalStatusEnum {
    DELETED = 'DELETED',
    NORMAL = 'NORMAL',
    SYSTEM = 'SYSTEM',
    TEMPORARY = 'TEMPORARY'
}
