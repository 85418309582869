/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionDetailBase,
    QuestionDetailBaseFromJSON,
    QuestionDetailBaseFromJSONTyped,
    QuestionDetailBaseToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
    VRMReviewStatsMeta,
    VRMReviewStatsMetaFromJSON,
    VRMReviewStatsMetaFromJSONTyped,
    VRMReviewStatsMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionDetailBaseWithVRMReviewStatsResponse
 */
export interface QuestionDetailBaseWithVRMReviewStatsResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof QuestionDetailBaseWithVRMReviewStatsResponse
     */
    msg: string;
    /**
     * 
     * @type {QuestionDetailBase}
     * @memberof QuestionDetailBaseWithVRMReviewStatsResponse
     */
    entry: QuestionDetailBase;
    /**
     * 
     * @type {VRMReviewStatsMeta}
     * @memberof QuestionDetailBaseWithVRMReviewStatsResponse
     */
    meta: VRMReviewStatsMeta;
}

export function QuestionDetailBaseWithVRMReviewStatsResponseFromJSON(json: any): QuestionDetailBaseWithVRMReviewStatsResponse {
    return QuestionDetailBaseWithVRMReviewStatsResponseFromJSONTyped(json, false);
}

export function QuestionDetailBaseWithVRMReviewStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionDetailBaseWithVRMReviewStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': QuestionDetailBaseFromJSON(json['entry']),
        'meta': VRMReviewStatsMetaFromJSON(json['meta']),
    };
}

export function QuestionDetailBaseWithVRMReviewStatsResponseToJSON(value?: QuestionDetailBaseWithVRMReviewStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': QuestionDetailBaseToJSON(value.entry),
        'meta': VRMReviewStatsMetaToJSON(value.meta),
    };
}


