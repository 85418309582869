import React, { ChangeEvent } from 'react';
import { Label, RadioInput } from './Radio.styles';

export type RadioSizeType = 'small' | 'normal';

export type RadioVersionType = 'regular' | 'light';

interface RadioPropsType {
  isSelected: boolean;
  label: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: RadioSizeType;
  version?: RadioVersionType;
}

const Radio = ({
  isSelected,
  label,
  handleChange,
  size = 'normal',
  version = 'regular',
}: RadioPropsType) => (
  <Label isSelected={isSelected} size={size} version={version}>
    <RadioInput checked={isSelected} onChange={handleChange} />

    {label}
  </Label>
);

export default Radio;
