import React from 'react';
import { Marginator } from 'common/common.styles';
import { UserType } from 'common/common.types';

interface DelegationNotificationsPromptPropsType {
  users: UserType[];
}

const DelegationNotificationsPrompt = ({
  users,
}: DelegationNotificationsPromptPropsType) => (
  <>
    {users.map(({ displayName, email, id }) => (
      <Marginator margin={{ left: 30, right: 30, bottom: 15 }} key={id}>
        <div>
          <strong>{displayName}</strong>
        </div>
        <div>{email}</div>
      </Marginator>
    ))}
  </>
);

export default DelegationNotificationsPrompt;
