import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  transition: ${({ theme }) => theme.basic.animations.fast};
  color: ${({ theme }) => theme.atlas.teal.primary};
  padding: 0;

  > span {
    margin-left: 18px;
    ${({ theme }) => theme.fonts.regular.normal}
  }

  > div {
    display: flex;
    align-items: center;
  }
`;
