import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { assocPath, append, countBy, dissocPath, identity } from 'ramda';
import Input from 'components/common/Input';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { Button } from '../../../common/Button';
import {
  InputWrapper,
  SelectResponseWrapper,
  ButtonText,
  ButtonWrapper,
} from './SelectResponse.styles';
import {
  Action,
  ActionsWrapper,
  DeleteIcon,
} from '../../Actions/Actions.styles';
import { ResponseComponentPropsType } from '../Response';

interface SelectResponsePropsType
  extends TranslatePropsType,
    ResponseComponentPropsType {}

const SelectResponse = ({
  response: { definition, errors },
  updateDefinition,
  t,
}: SelectResponsePropsType) => {
  // We have a separate variable for the options that are actually displayed,
  // one reason is we don't want to show less than 2 options no matter what is
  // stored in the database...
  const [visibleOptions, setVisibleOptions] = useState(definition.options);

  // We basically do a simple FE validation here to know which options
  // to highlight in red.
  const problematicOptions = useMemo(() => {
    const optionsCounts = countBy(identity, visibleOptions);
    const gotSomeErrorsFromBackend = errors.length > 0;
    return visibleOptions.map(option => {
      const isDuplicated = optionsCounts[option] > 1;
      const isEmpty = option.trim() === '';
      // We don't want to highlight the errors before we have the errors
      // from the server.
      return gotSomeErrorsFromBackend && (isDuplicated || isEmpty);
    });
  }, [errors, visibleOptions]);

  const onAddOption = useCallback(() => {
    setVisibleOptions(append('', visibleOptions));
  }, [visibleOptions]);

  const updateOptions = useCallback(
    (options = visibleOptions) => updateDefinition({ options }),
    [updateDefinition, visibleOptions],
  );

  const onBlur = useCallback(() => updateOptions(), [updateOptions]);

  useEffect(() => {
    if (definition.options.length < 2) {
      setVisibleOptions(['', '']);
    }
  }, [definition]);

  const onSelectedInputChanged = useCallback(
    (index: number, value: string) => {
      setVisibleOptions(assocPath([index], value, visibleOptions));
    },
    [visibleOptions],
  );

  const onRemoveClick = useCallback(
    (index: number) => {
      if (visibleOptions.length > 2) {
        setVisibleOptions(dissocPath([index], visibleOptions));
        updateOptions(dissocPath([index], visibleOptions));
      }
    },
    [visibleOptions, updateOptions],
  );

  const opacity = visibleOptions.length < 3 ? 0.3 : 1;

  return (
    <SelectResponseWrapper>
      {visibleOptions.map((value, i) => (
        <InputWrapper key={i}>
          <Input
            height={30}
            onBlur={onBlur}
            onChange={val => onSelectedInputChanged(i, val)}
            value={value}
            visualState={problematicOptions[i] ? 'error' : 'normal'}
          />
          <ActionsWrapper>
            <Action
              onClick={() => onRemoveClick(i)}
              opacity={opacity}
              title={t('delete') as string}
            >
              <DeleteIcon />
            </Action>
          </ActionsWrapper>
        </InputWrapper>
      ))}
      <ButtonWrapper>
        <Button
          onClick={onAddOption}
          style={{ border: 'none' }}
          color="transparent"
        >
          <ButtonText>{t('addOptionPlus') as string}</ButtonText>
        </Button>
      </ButtonWrapper>
    </SelectResponseWrapper>
  );
};

export default withTranslation('FormCreator')(SelectResponse);
