import { initialState, SettingsStateType } from './settings.reducer';

export const getHiddenFrameworksIds = (state: SettingsStateType) =>
  state.hiddenFrameworks
    ? state.hiddenFrameworks
    : initialState.hiddenFrameworks;

export const getHiddenFiles = (state: SettingsStateType) =>
  state.hiddenFiles ? state.hiddenFiles : initialState.hiddenFiles;

export const getDismissedNotifications = (state: SettingsStateType) =>
  state.dismissedNotifications
    ? state.dismissedNotifications
    : initialState.dismissedNotifications;

export const getLanguagePreference = (state: SettingsStateType) =>
  state.languagePreference
    ? state.languagePreference
    : initialState.languagePreference;
