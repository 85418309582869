/* tslint:disable:max-line-length */

import { SeverityTypeEnum } from 'common/common.types';

export const severityStylesDict = {
  [SeverityTypeEnum.HIGH]: {
    fillColor: '#E6454F',
    points:
      'M16.021,41.88c0-1.68,1.439-3,3.12-3c1.68,0,3,1.44,3,3c0,1.681-1.32,3.12-3,3.12C17.46,45,16.021,43.561,16.021,41.88z M17.101,35.52L16.261,15h5.64l-0.84,20.52H17.101z M26.94,41.88c0-1.68,1.44-3,3.12-3s2.999,1.44,2.999,3c0,1.681-1.319,3.12-2.999,3.12S26.94,43.561,26.94,41.88z M28.021,35.52L27.181,15h5.64l-0.841,20.52H28.021zM37.86,41.88c0-1.68,1.439-3,3.119-3c1.681,0,3,1.44,3,3c0,1.681-1.319,3.12-3,3.12C39.3,45,37.86,43.561,37.86,41.88zM38.82,35.52L37.979,15h5.641L42.9,35.52H38.82z',
  },
  [SeverityTypeEnum.MEDIUM]: {
    fillColor: '#E79243',
    points:
      'M21.48,41.94c0-1.68,1.44-3,3.12-3c1.68,0,3,1.439,3,3c0,1.68-1.32,3.119-3,3.119C22.92,45.06,21.48,43.62,21.48,41.94z M22.56,35.46l-0.84-20.52h5.64l-0.84,20.52H22.56z M32.4,41.94c0-1.68,1.439-3,3.119-3c1.682,0,3,1.439,3,3c0,1.68-1.318,3.119-3,3.119C33.84,45.06,32.4,43.62,32.4,41.94z M33.48,35.46l-0.84-20.52h5.639l-0.84,20.52H33.48z',
  },
  [SeverityTypeEnum.LOW]: {
    fillColor: '#FFDA66',
    points:
      'M26.94,41.879c0-1.68,1.439-3,3.119-3s3,1.44,3,3c0,1.681-1.32,3.121-3,3.121S26.94,43.56,26.94,41.879zM28.021,35.52L27.18,15h5.64L32.1,35.52H28.021z',
  },
  [SeverityTypeEnum.POSITIVE]: {
    fillColor: '#19BC9C',
    points:
      'M25.714,34.114l-6-6c-0.343-0.343-1.372,0.343-2.4,1.372c-1.029,1.028-1.714,2.057-1.372,2.4l6.514,6.515c0,0,2.4,2.229,2.915,2.742c0.171,0.172,0.686,0.344,1.028,0l17.829-17.657c0.343-0.343-0.172-1.543-1.2-2.572c-1.028-1.028-2.229-1.542-2.571-1.2L25.714,34.114z',
  },
  [SeverityTypeEnum.INFO]: {
    fillColor: '#4082C3',
    points:
      'M32.221,41.52c0,3.12,2.159,1.08,2.159,3v0.841h-8.76V44.52c0-1.92,2.28,0,2.28-3v-14.04c0-3.12-2.28-1.08-2.28-3v-0.84l6.601-0.24V41.52z M32.46,17.4c0,1.56-1.319,2.76-2.76,2.76c-1.44,0-2.76-1.32-2.76-2.76c0-1.56,1.32-2.76,2.76-2.76C31.141,14.64,32.46,15.96,32.46,17.4z',
  },
};
