import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Wrapper as MetaActions } from './Actions.styles';
import { Link, LinkProps } from 'react-router-dom';
import OrigUnverified from './Unverified';

export const REQUEST_HEIGHT = 96;

type CellType = {
  background?: string;
  grow?: boolean;
  width?: number;
  addBorder?: boolean;
};

type WrapperType = {
  isClickable?: boolean;
  isRead?: boolean;
  isVisible?: boolean;
  marginTop?: number;
};

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.request.link};
  ${({ theme }) => theme.fonts.semibold.normal}
  margin-bottom: 2px;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  &:hover {
    text-decoration: none;
  }
`;

const CommonWrapper = css<WrapperType>`
  position: relative;
  cursor: ${({ isClickable = true }) => (isClickable ? 'pointer' : 'default')};
  background-color: ${({ theme, isRead }) =>
    isRead ? theme.basic.colors.gray200 : theme.basic.colors.light};
  border: 1px solid ${({ theme }) => theme.basic.colors.darkerBorder};
  border-bottom-width: 0;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: row;
  height: ${REQUEST_HEIGHT}px;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 'initial')}
  overflow: hidden;
  z-index: 0;
  text-decoration: none;

  .tooltip {
    margin-top: -10px;
    max-width: 130px;
    ${({ theme }) => theme.basic.fontWeights.regular}
  }

  ${MetaActions} {
    width: 0;
    flex: 0 0 0;
    opacity: 0;
  }

  ${Title} {
    text-decoration: ${({ isClickable = true }) =>
      isClickable ? 'underline' : 'none'};
  }

  &:hover ${MetaActions} {
    width: auto;
    flex: 0 0 118px;
    opacity: 1;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.basic.colors.teal};
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

   &:hover ${Title} {
     background-color: ${({ theme }) => theme.basic.colors.headingHover};
   }

   &:last-child {
     border-bottom-width: 1px;
   }
`;

export const Wrapper = styled.div<WrapperType>`
  ${CommonWrapper}
`;

export const WrapperLink = styled(
  ({
    isClickable,
    isRead,
    isVisible,
    marginTop,
    // prettier-ignore
    // tslint:disable-next-line:trailing-comma
    ...params
  }: LinkProps & WrapperType) => <Link draggable={false} {...params} />,
)`
  ${CommonWrapper};
  user-select: text;
`;

export const CaptionWrapper = styled.div<WrapperType>`
  display: flex;
  flex-direction: row;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 'initial')};
`;

export const CheckboxWrapper = styled.div`
  padding-top: 18px;
`;

export const Cell = styled.div<CellType>`
  background-color: ${({ background }) =>
    background ? background : 'transparent'}
  color: ${({ theme }) => theme.basic.colors.grayDark};
  display: flex;
  ${({ grow, width }) =>
    grow
      ? css`
          flex-grow: 1;
        `
      : css`
          flex: 0 0 ${width || 100}px;
        `};
  justify-content: space-between;
  flex-direction: column;
  height: ${REQUEST_HEIGHT}px;
  padding: 12px 18px;
  position: relative;
  ${({ theme }) => theme.fonts.bold.small}
  overflow: hidden;
  text-overflow: ellipsis;
  border-right: ${({ addBorder, theme }) =>
    addBorder && `1px solid ${theme.basic.colors.lightBorder}`};
`;

export const TitlePart = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-overflow: ellipsis;
  height: 100%;
  overflow: hidden;
  padding: 12px 0;

  > :first-child {
    width: min-content;
    margin-bottom: 4px;
  }
`;

export const Company = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 26px;
  color: ${({ theme }) => theme.basic.colors.grayDark};
  ${({ theme }) => theme.fonts.regular.normal}

  > * {
    margin-right: 8px;
  }

  > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-bottom: 4px;
  }
`;

export const TransceiverPart = styled.div<{ reverse?: boolean }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
`;

export const Transceiver = styled.div`
  color: ${({ theme }) => theme.basic.colors.grayDark};
  opacity: 0.6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => theme.fonts.regular.mini}
`;

export const Year = styled.div`
  color: ${({ theme }) => theme.basic.colors.grayDark};
  opacity: 0.6;
  line-height: 1.2;
  ${({ theme }) => theme.fonts.light.mini}
`;

export const NotSpecified = styled.div`
  color: ${({ theme }) => theme.request.noSpecified};
  ${({ theme }) => theme.fonts.regular.small}
`;

export const Spacer = styled.div<CellType>`
  background: ${({ background, theme }) =>
    background ? background : theme.basic.colors.light}
  height: 100%;
  width: ${({ width }) => `${width}px`};
`;

export const UpdatedOn = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Grade = styled.div`
  text-align: center;
  width: 100%;

  img {
    width: 18px;
  }
`;

export const Unverified = styled(OrigUnverified)`
  font-size: ${({ theme }) => theme.basic.fontSizes.mini};
  padding-bottom: 4px;
`;
