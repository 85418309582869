import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import reduxSubsMiddleware from '../utils/reduxSubs';
import history from '../utils/history';
import { optimisticMiddleware } from '../utils/optimistic';
import createReducers from './store.reducer';
import rootSaga from './store.saga';
import { ActionType, StateType } from './store.types';
import subscriptions from './store.subscriptions';

const sagaMiddleware = createSagaMiddleware();

const store: Store<StateType, ActionType> & {
  dispatch: {};
} = createStore(
  createReducers(history),
  {} as any,
  composeWithDevTools(
    applyMiddleware(
      optimisticMiddleware(),
      sagaMiddleware,
      routerMiddleware(history),
      reduxSubsMiddleware(subscriptions),
    ),
  ),
);

sagaMiddleware.run(rootSaga);

export default store;
