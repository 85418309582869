import styled from 'styled-components';
import Input from '../../../common/Input';

export const Wrapper = styled.div`
  margin-right: 85px;
`;

export const TextInput = styled(Input)`
  ${({ theme }) => theme.fonts.regular.small}
`;
