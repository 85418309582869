import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { VerticallyCentered } from 'common/common.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const ROW_HEIGHT = 69;

export const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
  line-height: 40px;
  background: ${({ theme }) => theme.atlas.grey['50']};
  text-align: center;
`;

export const UserIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faUser as IconProp,
}))`
  font-size: ${({ theme }) => theme.basic.fontSizes.medium};
`;

export const UserInfo = styled.div`
  flex: 1;
  padding: 0 14px;
  overflow: hidden;
  ${({ theme }) => theme.fonts.regular.small}

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Email = styled.div`
  color: ${({ theme }) => theme.atlas.grey['600']};
`;

export const Wrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  height: ${ROW_HEIGHT}px;
  border-top: 1px solid ${({ theme }) => theme.atlas.grey['200']};
  padding: 14px 0;
  align-items: center;

  ${UserIcon} {
    color: ${({ isActive, theme }) =>
      theme.atlas.grey[isActive ? '700' : '400']};
  }

  > ${UserInfo} {
    color: ${({ isActive, theme }) =>
      isActive ? theme.atlas.text.primary : theme.atlas.grey['400']};
  }
`;

export const Roles = styled(VerticallyCentered)`
  align-items: center;
  flex: 0 0 140px;
`;

export const Role = styled.span`
  color: ${({ theme }) => theme.atlas.grey['500']};
  ${({ theme }) => theme.fonts.regular.small}
`;

export const Revoke = styled.div`
  text-decoration: underline;
  cursor: pointer;
  ${({ theme }) => theme.fonts.semibold.small}
`;

export const ReInvite = styled(Revoke)`
  align-self: center;
`;
