import Loading from '../../src/components/common/Loading';
import React, { Component } from 'react';
import qs from 'qs';
import updateQueryParams from '../utils/updateQueryParams';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import { CurrentUserType, FunctionsMapType } from 'common/common.types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { equals } from 'ramda';
import updatePathParams from '../utils/updatePathParams';

const PageContext = React.createContext({
  data: {},
  actions: {},
  queryParams: {},
  routeParams: {},
});
const {
  Provider: PageContextProvider,
  Consumer: PageContextConsumer,
} = PageContext;

export { PageContext, PageContextProvider, PageContextConsumer };

export type PageActionsBaseType = FunctionsMapType;

export type PageParamType = { [key: string]: any };

export interface PageBaseType {
  actions: PageActionsBaseType;
  data: any | null;
  queryParams: PageParamType;
  routeParams: PageParamType;
}

export type UpdateQueryType = (params: object) => void;

export type UpdatePathType = (params: object) => void;

export interface PagePropsType extends RouteComponentProps {
  dataSelector: (state: StateType) => object | null;
  dispatches: (
    dispatch: Dispatch<ActionType>,
    ownProps: PagePropsType,
    updateQuery: UpdateQueryType,
    updatePath: UpdatePathType,
  ) => {
    getData?:
      | ((params: { routeParams?: any; data?: any; queryParams?: any }) => void)
      | undefined;
    actions: FunctionsMapType;
  };

  children: React.ReactElement;
}

export interface PropsType extends PagePropsType {
  actions: FunctionsMapType;
  currentUser: CurrentUserType;
  data: any;
  getData: (params: {
    routeParams: any;
    data: any;
    queryParams: any;
    currentUser: CurrentUserType;
  }) => void;
  reset?: () => void;
  state?: StateType;
  isLoading: boolean;
  loadingText: string;
}

class Page extends Component<PagePropsType & PropsType> {
  state = {
    shouldGetData: true,
  };

  componentDidMount() {
    const {
      currentUser,
      data,
      getData,
      location: { search },
      match: { params },
    } = this.props;

    const { shouldGetData } = this.state;

    if (shouldGetData && getData) {
      getData({
        routeParams: params,
        data,
        queryParams: qs.parse(search, { ignoreQueryPrefix: true }),
        currentUser,
      });

      this.setState({ shouldGetData: false });
      // Force a re-render of the component - setState() should have happened automatically
      // but it's often not, causing the Loading page to stay on indefinitely (ATL-2679)
      this.forceUpdate();
    }
  }

  getSnapshotBeforeUpdate(prevProps: PropsType) {
    if (window.pageYOffset > 0 && !prevProps.isLoading) {
      return window.pageYOffset;
    }

    return 0;
  }

  shouldComponentUpdate(nextProps: Readonly<PagePropsType>): boolean {
    return !equals(nextProps, this.props);
  }

  componentDidUpdate(
    prevProps: PagePropsType,
    prevState: any,
    snapshot: number,
  ) {
    window.scrollTo(0, snapshot);
  }

  componentWillUnmount() {
    const { reset } = this.props;
    if (reset) {
      reset();
    }
  }

  render() {
    const {
      actions,
      children,
      data,
      getData,
      isLoading,
      loadingText,
      location: { search },
      match: { params },
    } = this.props;

    const { shouldGetData } = this.state;

    return isLoading || (shouldGetData && getData) ? (
      <Loading scrollable text={loadingText} />
    ) : (
      <PageContextProvider
        value={{
          actions,
          data,
          queryParams: qs.parse(search, { ignoreQueryPrefix: true }),
          routeParams: params,
        }}
      >
        {children}
      </PageContextProvider>
    );
  }
}

const mapState = (state: StateType, ownProps: PagePropsType) => ({
  currentUser: state.user.currentUser,
  data: ownProps.dataSelector(state),
  isLoading: state.app.loading.isLoading,
  loadingText: state.app.loading.text,
});

const mapDispatch = (
  dispatch: Dispatch<ActionType>,
  ownProps: PagePropsType,
) => {
  const {
    dispatches,
    location: { pathname, search },
    match: { path: route, params: routeParams },
  } = ownProps;

  const updateQuery = (params: object) => {
    const { state, query } = updateQueryParams(params);

    window.history.replaceState(
      state,
      'ATLAS',
      `${window.location.search}#${pathname}?${query}`,
    );
  };

  const updatePath = (params: object) => {
    const { state, path } = updatePathParams(route, routeParams, params);
    window.history.replaceState(
      state,
      'ATLAS',
      `${window.location.search}#${path}${search}`,
    );
  };

  return dispatches(dispatch, ownProps, updateQuery, updatePath);
};

export default withRouter(connect(mapState, mapDispatch)(Page));
