import i18n from '../../../i18n';
import { CompaniesStateType } from '../companies.reducer';
import { put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { ApiCallActionType } from '../../store.types';
import { CompanySettingType } from '../../../common/common.types';
import { getSettings } from './getSettings';

export type CompaniesSaveSettingsFunctionType = (
  settings: CompanySettingType,
) => void;

export type CompaniesSaveSettingsActionType = ApiCallActionType<
  '@companies/API/PUT_SETTINGS',
  {
    [key: string]: string;
  },
  CompanySettingType,
  never,
  CompaniesStateType
>;

export const saveSettings = (
  settings: any,
): CompaniesSaveSettingsActionType => ({
  type: '@companies/API/PUT_SETTINGS',
  payload: {
    callParams: { ...settings },
    endpointPath: ['companies', 'settings', 'patch'],
    selector: data => ({ settings: data }),
    postActions: [
      () => put(flash(i18n.t('common:responses.success'))),
      () => put(getSettings()),
    ],
  },
});
