import { CheckboxGroup, Header, Section, Title } from './FilterBy.styles';
import { append, assocPath, contains, reject } from 'ramda';
import { FilterByPropsType } from './FilterBy';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  ACTIVE_REQUEST_STATUSES,
  STATUS_TO_CATEGORY,
} from 'common/common.constants';
import Checkbox from '../../common/Checkbox';

const OPTION = 'STATUS';

const FilterStatus = ({
  data: { filterBy },
  actions: { setFilter },
  t,
}: FilterByPropsType) => {
  const handleChange = (status: string) => () => {
    const lookFor = contains(status, filterBy.STATUS.lookFor || [])
      ? reject(item => item === status, filterBy.STATUS.lookFor)
      : append(status, filterBy.STATUS.lookFor);
    const isEnabled = lookFor.length > 0;

    setFilter(assocPath([OPTION], { isEnabled, lookFor }, filterBy));
  };

  return (
    <Section>
      <Header>
        <Title>{t(`Requests.filterOptions.${OPTION}`) as string}</Title>
      </Header>
      <CheckboxGroup>
        {ACTIVE_REQUEST_STATUSES.map(status => (
          <Checkbox
            key={status}
            isCompact
            isChecked={contains(status, filterBy.STATUS.lookFor)}
            label={t(`Requests.${STATUS_TO_CATEGORY[status]}`) as string}
            labelSize="small"
            secondaryColor="teal"
            onChange={handleChange(status)}
          />
        ))}
      </CheckboxGroup>
    </Section>
  );
};

export default withTranslation()(FilterStatus);
