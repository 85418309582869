import { RequestDetailType } from '../../../common/common.types';

export type RequestsSaveSentRequestsFunctionType = (
  requests: RequestDetailType[],
) => void;

export interface RequestsSaveSentRequestsActionType {
  type: '@requests/SAVE_SENT_REQUESTS';
  payload: RequestDetailType[];
}

export const saveSentRequests = (
  requests: RequestDetailType[],
): RequestsSaveSentRequestsActionType => ({
  type: '@requests/SAVE_SENT_REQUESTS',
  payload: requests,
});
