/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface ProxyShortSummary
 */
export interface ProxyShortSummary {
    /**
     * 
     * @type {string}
     * @memberof ProxyShortSummary
     */
    form_id: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyShortSummary
     */
    form_role: ProxyShortSummaryFormRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ProxyShortSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyShortSummary
     */
    status: ProxyShortSummaryStatusEnum;
}




/**
* @export
* @enum {string}
*/
export enum ProxyShortSummaryFormRoleEnum {
    OWNER = 'OWNER',
    VENDOR = 'VENDOR',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY',
    OTHER = 'OTHER'
}
/**
* @export
* @enum {string}
*/
export enum ProxyShortSummaryStatusEnum {
    ACTIVE = 'ACTIVE',
    REVOKED = 'REVOKED'
}


