export interface SwitchViewActionType {
  type: '@switchers/SWITCH_VIEW';
  payload: {
    view: string;
  };
}

export const switchView = (view: string): SwitchViewActionType => ({
  type: '@switchers/SWITCH_VIEW',
  payload: {
    view,
  },
});
