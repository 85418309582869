import { assocPath } from 'ramda';
import { parseStandard } from 'utils/parsers';
import {
  StandardStatusType,
  StandardSortType,
  Uuid,
} from '../../../common/common.types';
import { ApiCallActionType, StateType } from '../../store.types';
import { FormNewFromExistingStateType } from '../formNewFromExisting.types';
import { Standard } from '../../../generated/models/Standard';

import { sortStandardsArray } from 'utils/filters/formNewFromExisting.sorters';

export interface GetStandardsForFormNewFromExistingParamsType {
  limit?: Uuid;
  ownerId?: Uuid;
  status?: StandardStatusType[];
  sort?: StandardSortType;
}

export type GetStandardsForFormNewFromExistingFunctionType = (
  params: GetStandardsForFormNewFromExistingParamsType,
) => void;

export type GetStandardsForFormNewFromExistingActionType = ApiCallActionType<
  '@formNewFromExisting/API/GET_STANDARDS',
  GetStandardsForFormNewFromExistingParamsType,
  Standard[],
  never,
  FormNewFromExistingStateType
>;

export const getStandardsForFormNewFromExisting = (
  params: GetStandardsForFormNewFromExistingParamsType = {},
): GetStandardsForFormNewFromExistingActionType => ({
  type: '@formNewFromExisting/API/GET_STANDARDS',
  payload: {
    endpointPath: ['standards', 'list'],
    callParams: params,
    selector: (
      data: Standard[],
      state: StateType,
    ): Partial<FormNewFromExistingStateType> => {
      const standards = data.map(parseStandard);
      const sortedStandards = sortStandardsArray(
        state.formNewFromExisting.standards.filter.sortBy,
      )(standards);
      return assocPath(
        ['standards', 'data'],
        sortedStandards,
        state.formNewFromExisting,
      );
    },
  },
});
