import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Marginator } from 'common/common.styles';
import { Heading } from '../Sales.styles';
import { TranslatePropsType } from 'common/common.types';
import { DropUploadWrapper } from '../../common/DropUpload';
import { systemFileFormat } from './systemFileConstants';

import Input from '../../common/Input';
import TextArea from '../../common/Textarea';
import { Button } from '../../../components/common/Button';
import {
  SystemTemplateUploadDefinitionsFunctionType,
  SystemTemplateUploadDefinitionsCallParamsType,
} from '../../../store/formDefinitions/actions';

const TextBoxRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const UploadResult = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-right: 5px;
  margin-bottom: 20px;
`;

const TextBoxCell = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 5px;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  flex: 0;
  align-items: center;
  margin-top: 35px;
  margin-right: 20px;
`;

const CancelLink = styled.a`
  margin-left: 4px;
  color: teal;
  &:hover {
    cursor: pointer;
  }
`;

interface UploadSystemTemplateDefinitions extends TranslatePropsType {
  uploadSystemTemplate: SystemTemplateUploadDefinitionsFunctionType;
}

const UploadSystemTemplate = ({
  t,
  uploadSystemTemplate,
}: UploadSystemTemplateDefinitions) => {
  const [systemUploadData, setSystemUploadData] = useState<
    SystemTemplateUploadDefinitionsCallParamsType
  >({
    name: '',
    code: '',
    description: '',
    file: '',
    version: '',
    fileName: '',
  });

  const handleUploadSystemTemplate = () => {
    uploadSystemTemplate(systemUploadData);
    setSystemUploadData({
      name: '',
      code: '',
      description: '',
      file: '',
      version: '',
      fileName: '',
    });
  };

  const handleDropFiles = useCallback((acceptedFiles: File[]) => {
    return acceptedFiles.forEach((file: File) => {
      if (file && systemFileFormat.includes(file.type)) {
        setSystemUploadData(prevState => ({
          ...prevState,
          file: file,
          fileName: file.name,
        }));
      }
    });
  }, []);

  return (
    <Marginator margin={{ top: 50 }}>
      <Heading>{t('uploadSystems') as string}</Heading>

      <TextBoxRow>
        <TextBoxCell>
          <label>Name</label>
          <Input
            value={systemUploadData.name}
            onChange={value =>
              setSystemUploadData(prevState => ({ ...prevState, name: value }))
            }
          />
        </TextBoxCell>
        <TextBoxCell>
          <label>Version</label>
          <Input
            value={systemUploadData.version}
            onChange={value =>
              setSystemUploadData(prevState => ({
                ...prevState,
                version: value,
              }))
            }
          />
        </TextBoxCell>
        <TextBoxCell>
          <label>Code</label>
          <Input
            value={systemUploadData.code}
            onChange={value =>
              setSystemUploadData(prevState => ({ ...prevState, code: value }))
            }
          />
        </TextBoxCell>
      </TextBoxRow>
      <TextBoxRow>
        <TextBoxCell>
          <label>Description</label>
          <TextArea
            value={systemUploadData.description}
            onChange={value =>
              setSystemUploadData(prevState => ({
                ...prevState,
                description: value,
              }))
            }
          />
        </TextBoxCell>
      </TextBoxRow>
      <TextBoxRow>
        <TextBoxCell>
          <DropUploadWrapper onDrop={handleDropFiles} />
          <UploadResult>
            <label>{systemUploadData.fileName}</label>
            <CancelLink
              href="#"
              onClick={e => {
                e.preventDefault();
                setSystemUploadData(prevState => ({
                  ...prevState,
                  file: '',
                  fileName: '',
                }));
              }}
            >
              {systemUploadData.fileName ? 'Cancel' : ''}
            </CancelLink>
          </UploadResult>
        </TextBoxCell>
        <ButtonWrapper>
          <Button
            onClick={handleUploadSystemTemplate}
            size="medium"
            disabled={!Object.values(systemUploadData).every(curr => curr)}
          >
            {t('done') as string}
          </Button>
        </ButtonWrapper>
      </TextBoxRow>
    </Marginator>
  );
};

export default withTranslation('Sales')(UploadSystemTemplate);
