import React from 'react';
import { Progress, TitleWrapper, Wrapper } from './ProgressBar.styles';

interface ProgressBarProps {
  count: number;
  chunk: number;
  title: string;
}

const ProgressBar = ({ count, chunk, title }: ProgressBarProps) => (
  <Wrapper data-test="progress-bar">
    <TitleWrapper>{title}</TitleWrapper>

    <Progress count={count} chunk={chunk} />
  </Wrapper>
);

export default ProgressBar;
