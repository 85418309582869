import styled from 'styled-components';

export const Heading = styled.h2`
  font-size: ${({ theme }) => theme.basic.fontSizes.normal};
  padding-bottom: 15px;
`;

export const Subheading = styled.p`
  ${({ theme }) => theme.fonts.regular.small}
  color: ${({ theme }) => theme.atlas.grey[600]};
  margin-top: -8px;
  padding-bottom: 15px;
`;

export const TitleLink = styled.a`
  color: ${({ theme }) => theme.basic.colors.ssc};
  ${({ theme }) => theme.fonts.regular.normal}
`;

export const ListHeading = styled.h3`
  ${({ theme }) => theme.fonts.light.small}
  margin: 27px 0 9px 12px;
`;

export const ButtonMore = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.basic.colors.atlas};
  margin: 12px 0 0 20px;
  text-decoration: underline;
  ${({ theme }) => theme.fonts.semibold.small}

  &:hover {
    text-decoration: none;
  }
`;

export const ParsedFile = styled.div`
  padding: 5px 10px;
  border: 1px solid ${({ theme }) => theme.basic.colors.grayLight};
  border-radius: 10px;
  position: relative;
  margin: 30px 0;
  max-width: 400px;

  strong {
    position: absolute;
    top: -10px;
    left: 6px;
    ${({ theme }) => theme.fonts.semibold.normal}
    background-color: ${({ theme }) => theme.basic.colors.light};
    display: block;
    padding: 0 4px;
  }
`;

export const ModalTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
