/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";
// @ts-ignore
import { Standard } from "./Standard";

/**
 * 
 * @export
 * @interface RequestMeta
 */
export interface RequestMeta {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof RequestMeta
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof RequestMeta
     */
    updated_at: Date;
    /**
     * The day when the VRM accepted the request. This will be set internally and         only when the VRM status changes from in progress to accepted
     * @type {Date}
     * @memberof RequestMeta
     */
    accepted_date?: Date;
    /**
     * User can view form data
     * @type {boolean}
     * @memberof RequestMeta
     */
    can_view_data: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestMeta
     */
    code: string;
    /**
     * 
     * @type {Date}
     * @memberof RequestMeta
     */
    due_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof RequestMeta
     */
    form_id: string;
    /**
     * 
     * @type {string}
     * @memberof RequestMeta
     */
    form_name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestMeta
     */
    form_roles: Array<RequestMetaFormRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof RequestMeta
     */
    history_count: number;
    /**
     * 
     * @type {string}
     * @memberof RequestMeta
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof RequestMeta
     */
    is_internal: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestMeta
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof RequestMeta
     */
    revision_count: number;
    /**
     * 
     * @type {string}
     * @memberof RequestMeta
     */
    risk_note: string;
    /**
     * 
     * @type {Standard}
     * @memberof RequestMeta
     */
    standard: Standard;
    /**
     * Current request status
     * @type {string}
     * @memberof RequestMeta
     */
    status: RequestMetaStatusEnum;
    /**
     * Status of the request after it will be unarchived
     * @type {string}
     * @memberof RequestMeta
     */
    status_before_archived: RequestMetaStatusBeforeArchivedEnum;
    /**
     * When the request was first opened by target (vendor).
     * @type {Date}
     * @memberof RequestMeta
     */
    target_opened_at?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof RequestMeta
     */
    was_scheduled: boolean;
}




/**
* @export
* @enum {string}
*/
export enum RequestMetaFormRolesEnum {
    OWNER = 'OWNER',
    VENDOR = 'VENDOR',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY',
    OTHER = 'OTHER'
}
/**
* @export
* @enum {string}
*/
export enum RequestMetaStatusEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}
/**
* @export
* @enum {string}
*/
export enum RequestMetaStatusBeforeArchivedEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}


