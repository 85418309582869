import React from 'react';
import { Button, LearnMore, Wrapper } from './CookiesBanner.styles';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface CookiesBannerPropsType extends TranslatePropsType {
  agreeCookies: () => void;
}

const CookiesBanner = ({ agreeCookies, t }: CookiesBannerPropsType) => {
  const textLabel = t('text') as string;
  const moreLabel = t('more') as string;
  const okLabel = t('ok') as string;

  return (
    <Wrapper>
      <span>
        <>
          {textLabel}

          <LearnMore href="https://cookiesandyou.com/" target="_blank">
            {moreLabel}
          </LearnMore>
        </>
      </span>

      <Button data-cy={'agree-cookies'} onClick={agreeCookies}>
        {okLabel}
      </Button>
    </Wrapper>
  );
};

export default withTranslation('CookiesBanner')(CookiesBanner);
