import React, { useState, useEffect, useRef, useContext } from 'react';

import Checkbox from 'components/common/Checkbox';
import { Button } from 'components/common/Button/Button.styles';
import { Input } from 'components/Utils/SearchMappings.styles';
import {
  CompanySettingType,
  ToggleEnum,
  TranslatePropsType,
  UserType,
  CurrentUserType,
} from 'common/common.types';
import { parseEmail } from 'utils/parseEmail';
import { useOnClickOutside } from 'utils/useOnClickOutside';
import useFlag from 'utils/hooks/useFlag';

import { CompaniesSaveSettingsFunctionType } from 'store/companies/actions';
import { withTranslation } from 'react-i18next';
import {
  AssignableState,
  AssignedIcon,
} from '../Form/Delegator/Assignees.styles';
import {
  CenteredFlex,
  CobrandingWrapper,
  Emailable,
  EmailableWrapper,
  Key,
  Name,
  PaddedFlex,
  Value,
  UploadError,
} from './Settings.styles';
import SettingsCobranding from './SettingsCobranding';
import PreviewCobrandingModal from './PreviewCobrandingModal';
import { PREVIEW_COBRANDING_MODAL_ID } from 'common/common.constants';
import { SettingsPageContextType } from 'pages/SettingsPage';
import { PageContext } from 'pages/Page';

interface SettingsFormType extends TranslatePropsType {
  companyName: string;
  emailables: UserType[];
  initialState?: CompanySettingType;
  saveSettings: CompaniesSaveSettingsFunctionType;
  settings: CompanySettingType;
  user: CurrentUserType;
  removeLogo: () => void;
}

const SettingsForm = ({
  companyName,
  emailables,
  initialState = {
    requests_email: '',
    public_standards: false,
    ml_enabled: false,
    ml_autocomplete_threshold: '90',
  },
  saveSettings,
  removeLogo,
  settings: {
    requests_email,
    public_standards,
    ml_autocomplete_threshold,
    logo,
  },
  user,
  user: { isSystemAdmin },
  t,
}: SettingsFormType) => {
  const {
    actions: { cobrandLogo, showModal, hideModal },
  } = useContext(PageContext) as SettingsPageContextType;

  const hasCobrandingEnabled = useFlag(user, [ToggleEnum.EMAIL_COBRANDING]);
  const ref = useRef(null);
  const [isDelegatorOpened, setDelegatorOpened] = useState(false);
  const [state, setState] = useState(initialState);
  const [cobrandingError, updateCobrandingError] = useState(false);
  const [cobrandingFileURL, updateCobrandingFileURL] = useState(
    logo ? `data:image/png;base64, ${logo}` : '',
  );
  const [cobrandingFile, updateCobrandingFile] = useState(undefined);

  useOnClickOutside(ref, () => setDelegatorOpened(false));

  useEffect(() => {
    if (requests_email) {
      setState(prevState => ({ ...prevState, requests_email }));
    }

    if (public_standards) {
      setState(prevState => ({ ...prevState, public_standards }));
    }

    if (ml_autocomplete_threshold) {
      setState(prevState => ({ ...prevState, ml_autocomplete_threshold }));
    }
  }, [
    requests_email,
    public_standards,
    ml_autocomplete_threshold,
    isSystemAdmin,
  ]);

  const onShowCompletedChange = () => {
    setState(prevState => ({
      ...prevState,
      public_standards: !state.public_standards,
    }));
  };

  const handleEmailAssign = (email: string) => () => {
    setState(prevState => ({ ...prevState, requests_email: email }));
    setDelegatorOpened(false);
  };

  const handleFile = (file: any) => {
    updateCobrandingFile(file);
    updateCobrandingError(false);
    updateCobrandingFileURL(URL.createObjectURL(file));
  };

  const handleSubmit = () => {
    if (cobrandLogo && cobrandingFile) {
      // Upload the logo file
      cobrandLogo(cobrandingFile);
    }

    saveSettings(state);
  };
  return (
    <>
      <PreviewCobrandingModal
        onCancel={() => {
          hideModal(PREVIEW_COBRANDING_MODAL_ID);
        }}
        // we should be passing logo but for testing we are using cobrandingFileURL
        logo={cobrandingFileURL}
      />
      <CenteredFlex>
        <Key>{t('email') as string}</Key>

        <Value>
          <Input
            data-test="email-selector"
            onClick={() => setDelegatorOpened(true)}
            value={state.requests_email}
            readOnly
          />

          {emailables.length && isDelegatorOpened && (
            <EmailableWrapper ref={ref} data-test="email-dropdown">
              {emailables.map(({ id, displayName, email }) => (
                <Emailable
                  key={`emailable-${id}`}
                  onClick={handleEmailAssign(email)}
                >
                  <AssignableState>
                    {email === state.requests_email && <AssignedIcon />}
                  </AssignableState>

                  <Name data-test="single-email">
                    {email} ({displayName})
                  </Name>
                </Emailable>
              ))}
            </EmailableWrapper>
          )}
        </Value>
      </CenteredFlex>

      {hasCobrandingEnabled && (
        <>
          <h4>{t('cobrandingEmails') as string}</h4>
          <PaddedFlex>
            {hasCobrandingEnabled ? (
              <Key>
                {t('cobrandEnabled') as string}
                <br />
                {cobrandingError && (
                  <UploadError>{t('cobrandig.error') as string}</UploadError>
                )}
              </Key>
            ) : (
              <Key>{t('cobrandDisabled') as string}</Key>
            )}
            <CobrandingWrapper>
              <SettingsCobranding
                fileURL={cobrandingFileURL}
                handleFile={handleFile}
                showModal={showModal}
                logo={logo}
                removeLogo={removeLogo}
                hideModal={hideModal}
              />
            </CobrandingWrapper>
          </PaddedFlex>
        </>
      )}

      <h4>{t('privacy') as string}</h4>

      <CenteredFlex>
        <Key>
          {t('showCompleted') as string}
          <br />
          <small>{t('showCompletedDesc', { companyName }) as string}</small>
        </Key>

        <Value data-test="share-settings">
          <Checkbox
            isChecked={state.public_standards === true}
            label={t(state.public_standards ? 'enabled' : 'disabled') as string}
            onChange={onShowCompletedChange}
            secondaryColor={'teal'}
          />
        </Value>
      </CenteredFlex>

      <CenteredFlex>
        <Key />

        <Value>
          <Button
            data-test="submit-settings"
            onClick={handleSubmit}
            disabled={!parseEmail(state.requests_email || '')}
            color={'teal'}
          >
            <span>{t('submit') as string}</span>
          </Button>
        </Value>
      </CenteredFlex>
    </>
  );
};

export default withTranslation('Settings')(SettingsForm);
