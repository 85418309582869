/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormQuestionFeedbackCreate
 */
export interface FormQuestionFeedbackCreate {
    /**
     * 
     * @type {number}
     * @memberof FormQuestionFeedbackCreate
     */
    questionMappingPos: number;
    /**
     * Collection of feedback options for question mapping feature.
     * @type {string}
     * @memberof FormQuestionFeedbackCreate
     */
    rating: FormQuestionFeedbackCreateRatingEnum;
    /**
     * 
     * @type {number}
     * @memberof FormQuestionFeedbackCreate
     */
    responsePos?: number;
    /**
     * 
     * @type {string}
     * @memberof FormQuestionFeedbackCreate
     */
    responseValue?: string;
    /**
     * 
     * @type {string}
     * @memberof FormQuestionFeedbackCreate
     */
    sourceQuestionId: string;
}

export function FormQuestionFeedbackCreateFromJSON(json: any): FormQuestionFeedbackCreate {
    return FormQuestionFeedbackCreateFromJSONTyped(json, false);
}

export function FormQuestionFeedbackCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormQuestionFeedbackCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionMappingPos': json['question_mapping_pos'],
        'rating': json['rating'],
        'responsePos': !exists(json, 'response_pos') ? undefined : json['response_pos'],
        'responseValue': !exists(json, 'response_value') ? undefined : json['response_value'],
        'sourceQuestionId': json['source_question_id'],
    };
}

export function FormQuestionFeedbackCreateToJSON(value?: FormQuestionFeedbackCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'question_mapping_pos': value.questionMappingPos,
        'rating': value.rating,
        'response_pos': value.responsePos,
        'response_value': value.responseValue,
        'source_question_id': value.sourceQuestionId,
    };
}

/**
* @export
* @enum {string}
*/
export enum FormQuestionFeedbackCreateRatingEnum {
    RESPONSEAPPLIED = 'RESPONSE_APPLIED',
    THUMBSDOWN = 'THUMBS_DOWN',
    THUMBSUP = 'THUMBS_UP'
}


