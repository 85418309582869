/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    Standard,
    StandardFromJSON,
    StandardFromJSONTyped,
    StandardToJSON,
    Team,
    TeamFromJSON,
    TeamFromJSONTyped,
    TeamToJSON,
} from './';

/**
 * 
 * @export
 * @interface ScheduledRequest
 */
export interface ScheduledRequest {
    /**
     * Flag of whether the scheduled request has been cancelled
     * @type {boolean}
     * @memberof ScheduledRequest
     */
    cancelled: boolean;
    /**
     * Datetime in ms of next requests due date
     * @type {Date}
     * @memberof ScheduledRequest
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ScheduledRequest
     */
    emailBody: string;
    /**
     * The unique identifier of scheduled filling request
     * @type {string}
     * @memberof ScheduledRequest
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledRequest
     */
    isInternal: boolean;
    /**
     * Datetime in ms of next request send
     * @type {Date}
     * @memberof ScheduledRequest
     */
    nextSendDate: Date;
    /**
     * How many times a request will be sent. If not set, send indefinitely
     * @type {number}
     * @memberof ScheduledRequest
     */
    numOfOccurrences?: number;
    /**
     * Temporal type of scheduled recurrence (Monthly, Daily, etc.)
     * @type {string}
     * @memberof ScheduledRequest
     */
    recurrenceType?: ScheduledRequestRecurrenceTypeEnum;
    /**
     * Number of temporal units for recurrence (2Months, 12days, etc.)
     * @type {number}
     * @memberof ScheduledRequest
     */
    recurrenceUnits?: number;
    /**
     * 
     * @type {Company}
     * @memberof ScheduledRequest
     */
    source: Company;
    /**
     * 
     * @type {Team}
     * @memberof ScheduledRequest
     */
    sourceTeam?: Team;
    /**
     * 
     * @type {string}
     * @memberof ScheduledRequest
     */
    sourceUserEmail: string;
    /**
     * 
     * @type {Standard}
     * @memberof ScheduledRequest
     */
    standard: Standard;
    /**
     * 
     * @type {Company}
     * @memberof ScheduledRequest
     */
    target: Company;
    /**
     * 
     * @type {Team}
     * @memberof ScheduledRequest
     */
    targetTeam?: Team;
    /**
     * 
     * @type {string}
     * @memberof ScheduledRequest
     */
    targetUserEmail: string;
}

export function ScheduledRequestFromJSON(json: any): ScheduledRequest {
    return ScheduledRequestFromJSONTyped(json, false);
}

export function ScheduledRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduledRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cancelled': json['cancelled'],
        'dueDate': !exists(json, 'due_date') ? undefined : (new Date(json['due_date'])),
        'emailBody': json['email_body'],
        'id': json['id'],
        'isInternal': json['is_internal'],
        'nextSendDate': (new Date(json['next_send_date'])),
        'numOfOccurrences': !exists(json, 'num_of_occurrences') ? undefined : json['num_of_occurrences'],
        'recurrenceType': !exists(json, 'recurrence_type') ? undefined : json['recurrence_type'],
        'recurrenceUnits': !exists(json, 'recurrence_units') ? undefined : json['recurrence_units'],
        'source': CompanyFromJSON(json['source']),
        'sourceTeam': !exists(json, 'source_team') ? undefined : TeamFromJSON(json['source_team']),
        'sourceUserEmail': json['source_user_email'],
        'standard': StandardFromJSON(json['standard']),
        'target': CompanyFromJSON(json['target']),
        'targetTeam': !exists(json, 'target_team') ? undefined : TeamFromJSON(json['target_team']),
        'targetUserEmail': json['target_user_email'],
    };
}

export function ScheduledRequestToJSON(value?: ScheduledRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cancelled': value.cancelled,
        'due_date': value.dueDate === undefined ? undefined : (value.dueDate.toISOString()),
        'email_body': value.emailBody,
        'id': value.id,
        'is_internal': value.isInternal,
        'next_send_date': (value.nextSendDate.toISOString()),
        'num_of_occurrences': value.numOfOccurrences,
        'recurrence_type': value.recurrenceType,
        'recurrence_units': value.recurrenceUnits,
        'source': CompanyToJSON(value.source),
        'source_team': TeamToJSON(value.sourceTeam),
        'source_user_email': value.sourceUserEmail,
        'standard': StandardToJSON(value.standard),
        'target': CompanyToJSON(value.target),
        'target_team': TeamToJSON(value.targetTeam),
        'target_user_email': value.targetUserEmail,
    };
}

/**
* @export
* @enum {string}
*/
export enum ScheduledRequestRecurrenceTypeEnum {
    DAILY = 'DAILY',
    HOURLY = 'HOURLY',
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY',
    YEARLY = 'YEARLY'
}


