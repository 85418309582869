import { assoc, inc, none, propOr, reduce } from 'ramda';
import {
  QuestionDefinitionInvalidEntityErrorSummary,
  ResponseDefinitionInvalidEntityErrorSummary,
  Uuid,
} from 'common/common.types';
import ValidationError, {
  FormDefinitionError,
} from 'store/formCreator/validationError.types';
import { QuestionDefinitionEntityErrorCodeEnum } from 'generated/models/QuestionDefinitionEntityError';
import { FormDefinitionEntityErrorCodeEnum } from 'generated/models';

type FoundIssues = { [key: string]: number };

const {
  CONDITIONREFERENCEINVALIDQUESTION,
  CONDITIONREFERENCELOWER,
  CONDITIONREFERENCENOTFOUND,
  CONDITIONREFERENCESELF,
  CONDITIONUUIDNOTFOUND,
} = QuestionDefinitionEntityErrorCodeEnum;

/**
 * original code -> code used for grouping. No need to specify those that
 * have 1:1 mapping.
 */
const ISSUE_GROUPING: {
  [key in QuestionDefinitionEntityErrorCodeEnum]?: QuestionDefinitionEntityErrorCodeEnum;
} = {
  [CONDITIONREFERENCENOTFOUND]: CONDITIONREFERENCENOTFOUND,
  [CONDITIONREFERENCEINVALIDQUESTION]: CONDITIONREFERENCENOTFOUND,
  [CONDITIONREFERENCELOWER]: CONDITIONREFERENCENOTFOUND,
  [CONDITIONREFERENCESELF]: CONDITIONREFERENCENOTFOUND,
  [CONDITIONUUIDNOTFOUND]: CONDITIONREFERENCENOTFOUND,
};

interface CalculatedIssues {
  foundIssues: FoundIssues;
  questionCount: number;
}

export const calculateIssues = (
  elements: Array<
    | QuestionDefinitionInvalidEntityErrorSummary
    | ResponseDefinitionInvalidEntityErrorSummary
  >,
): CalculatedIssues => {
  const questionUuids = new Set<Uuid>();
  return {
    foundIssues: reduce(
      (acc, { questionUuid, errors }) => {
        questionUuids.add(questionUuid);

        return reduce<ValidationError, FoundIssues>(
          (errorAcc, { code }) => {
            const effectiveCode = ISSUE_GROUPING[code] || code;
            return assoc(
              effectiveCode,
              inc(propOr(0, effectiveCode)(errorAcc)),
              errorAcc,
            );
          },
          acc,
          errors,
        );
      },
      {},
      elements,
    ),
    questionCount: questionUuids.size,
  };
};

export const canDeleteQuestions = (formErrors: FormDefinitionError[]) =>
  none(
    ({ code }) => code === FormDefinitionEntityErrorCodeEnum.NOVALIDQUESTION,
    formErrors,
  );
