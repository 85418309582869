import React from 'react';
import { Wrapper, Item } from './TextToggle.styles';

export interface TextToggleItemType<K> {
  label: string;
  value: K;
}

export interface TextTogglePropsType<K> {
  items: Array<TextToggleItemType<K>>;
  onChange?: (value: K) => void;
  selectedItem: K;
}

const TextToggle = <K extends string | number>({
  items,
  onChange,
  selectedItem,
}: TextTogglePropsType<K>) => (
  <Wrapper>
    {items.map(item => (
      <Item
        key={item.value}
        onClick={() => {
          if (onChange && item.value !== selectedItem) {
            onChange(item.value);
          }
        }}
        selected={item.value === selectedItem}
      >
        {item.label}
      </Item>
    ))}
  </Wrapper>
);

export default TextToggle;
