/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProxyShortSummary,
    ProxyShortSummaryFromJSON,
    ProxyShortSummaryFromJSONTyped,
    ProxyShortSummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProxyWithFlags
 */
export interface ProxyWithFlags {
    /**
     * Can the user be notified from the collaborators?
     * @type {boolean}
     * @memberof ProxyWithFlags
     */
    canBeNotified?: boolean;
    /**
     * Can the user be removed from the collaborators?
     * @type {boolean}
     * @memberof ProxyWithFlags
     */
    canBeRevoked?: boolean;
    /**
     * Detail of proxies - their roles and ids.
     * @type {ProxyShortSummary}
     * @memberof ProxyWithFlags
     */
    proxy: ProxyShortSummary;
}

export function ProxyWithFlagsFromJSON(json: any): ProxyWithFlags {
    return ProxyWithFlagsFromJSONTyped(json, false);
}

export function ProxyWithFlagsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProxyWithFlags {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canBeNotified': !exists(json, 'can_be_notified') ? undefined : json['can_be_notified'],
        'canBeRevoked': !exists(json, 'can_be_revoked') ? undefined : json['can_be_revoked'],
        'proxy': ProxyShortSummaryFromJSON(json['proxy']),
    };
}

export function ProxyWithFlagsToJSON(value?: ProxyWithFlags | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'can_be_notified': value.canBeNotified,
        'can_be_revoked': value.canBeRevoked,
        'proxy': ProxyShortSummaryToJSON(value.proxy),
    };
}


