import { add, format } from 'date-fns';
import { TFunction } from 'i18next';
import { LanguageType, TargetItemType } from 'common/common.types';
import { ScheduledRequestRecurrenceTypeEnum } from 'generated/models';
import getLocale from 'utils/getLocale';

export const transformRecurrenceType = (
  type: ScheduledRequestRecurrenceTypeEnum,
) => {
  switch (type) {
    case ScheduledRequestRecurrenceTypeEnum.HOURLY:
      return 'hours';
    case ScheduledRequestRecurrenceTypeEnum.MONTHLY:
      return 'months';
    case ScheduledRequestRecurrenceTypeEnum.WEEKLY:
      return 'weeks';
    case ScheduledRequestRecurrenceTypeEnum.YEARLY:
      return 'years';
    default:
      return 'days';
  }
};

export const getLastValidDate = (
  date: Date,
  unit: number,
  type: ScheduledRequestRecurrenceTypeEnum,
): Date => add(date, { [transformRecurrenceType(type)]: unit });

export const getScheduleRecurrenceText = (
  sendDate: Date,
  unit: number,
  type: ScheduledRequestRecurrenceTypeEnum,
  language: LanguageType,
  t: TFunction,
) => {
  const suffix = unit > 1 ? '_plural' : '';
  return t(`recurrenceInterval${suffix}`, {
    count: unit,
    day: format(getLastValidDate(sendDate, unit, type), 'dd', {
      locale: getLocale(language),
    }),
    interval: t(`recurrenceUnits.${type}${suffix}`),
  });
};

export const getCommaSeparatedTargets = (targets: TargetItemType[]) =>
  targets.map(({ value }) => value).join(', ');
