import arrayToIdDict from 'utils/arrayToIdDict';
import { QuestionMetadata } from '../../../generated/models/QuestionMetadata';
import { assocPath, pathOr } from 'ramda';
import { getFiltersCallParam } from '../../../components/Form/utils';
import { getUpdatedQuestionsIds } from '../forms.selectors';
import { parseQuestion } from 'utils/parsers';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { QuestionBlockType, Uuid } from '../../../common/common.types';

export type FormsLoadQuestionsFunctionType = (
  params: FormGetQuestionsParamsType,
) => void;

export interface FormGetQuestionsParamsType {
  id?: Uuid;
  assigneeIds?: Uuid[];
  blocks?: QuestionBlockType[];
  category?: string;
  filters?: string[];
  ids?: Uuid[];
  limit?: number;
  offset?: number;
}

export type FormsLoadQuestionsActionType = ApiCallActionType<
  '@forms/API/LOAD_QUESTIONS',
  FormGetQuestionsParamsType,
  QuestionMetadata[],
  never,
  FormsStateType
>;

export const loadQuestions = (
  params: FormGetQuestionsParamsType,
): FormsLoadQuestionsActionType => ({
  type: '@forms/API/LOAD_QUESTIONS',
  payload: {
    callParams: state => ({
      ...params,
      ...getFiltersCallParam(state.forms.filters),
      ids: getUpdatedQuestionsIds(state.forms),
    }),
    endpointPath: ['forms', 'questions', 'list'],
    selector: (data = [], state): Partial<FormsStateType> =>
      assocPath(
        ['currentForm', 'questions'],
        {
          ...pathOr({}, ['currentForm', 'questions'], state.forms),
          ...arrayToIdDict(data.map(item => parseQuestion(item, state))),
        },
        state.forms,
      ),
  },
});
