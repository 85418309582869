import Audit, { AuditInputPropsType } from './Audit';
import React from 'react';
import { AttachmentAuditType } from 'common/common.types';

const Attachment = ({
  change: { createdAt, value, user },
  t,
}: AuditInputPropsType) => {
  const { action, filename } = value as AttachmentAuditType;

  return (
    <Audit
      title={t(`attachment.${action}`, { username: user.displayName })}
      date={createdAt}
    >
      {filename}
    </Audit>
  );
};

export default Attachment;
