import styled from 'styled-components';

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.basic.colors.notification};
  ${({ theme }) => theme.fonts.light.mini}
  line-height: 1.2;

  span {
    text-decoration: underline;
  }

  span:hover {
    background-color: ${({ theme }) => theme.basic.colors.notificationHover};
    text-decoration: none;
  }
`;
