import {
  all,
  compose,
  flatten,
  groupBy,
  keys,
  map,
  mapObjIndexed,
  pipe,
  sort,
  values,
} from 'ramda';
import {
  RequestCategoryType,
  RequestsInOutType,
  RequestsStateType,
} from './requests.types';
import {
  GroupedScheduledRequests,
  RequestDirectionType,
  RequestSimpleType,
  ScheduledRequestType,
  Uuid,
} from '../../common/common.types';
import withUrl from 'utils/url/withUrl';
import { getFilterCallParams } from './actions';
import { StateType } from '../store.types';
import urlize from 'utils/url/urlize';
import { StatusEnum } from '../../generated/models/RequestSimple';

const canDelete = ({ status }: RequestSimpleType) =>
  status === StatusEnum.CREATED;

export const getControlsStatus = ({
  categories,
  unsorted,
}: RequestsInOutType) => {
  const selectedRequestIds = new Set(
    pipe(
      values,
      map((x: RequestCategoryType) => x.selected),
      flatten,
    )(categories),
  );
  const hasSelected = selectedRequestIds.size > 0;
  const requests = unsorted.filter(request => {
    // @ts-ignore
    return selectedRequestIds.has(request.id);
  });

  return {
    canArchive: hasSelected,
    canClearNotifications: hasSelected,
    canDelete: hasSelected && all(canDelete, requests),
    canMarkRead: hasSelected,
    selectedIds: Array.from(selectedRequestIds),
    selectedRequests: requests,
  };
};

const sortByNextSendDate = (a: ScheduledRequestType, b: ScheduledRequestType) =>
  a.nextSendDate.getTime() - b.nextSendDate.getTime();

const groupByCompany = groupBy(
  (request: ScheduledRequestType) => request.target.name,
);

export const groupScheduledRequests = (
  state: RequestsStateType,
): GroupedScheduledRequests => {
  const groupedSorted = compose(
    mapObjIndexed(sort(sortByNextSendDate)),
    groupByCompany,
  )(state.scheduled);

  return {
    items: groupedSorted,
    count: state.scheduled.length,
    companies: compose(
      sort((a: string, b: string) => a.localeCompare(b)),
      keys,
    )(groupedSorted),
  };
};

export const exportToCsvPath = (
  state: StateType,
  direction: RequestDirectionType,
  includeProxyRequests: boolean = false,
) =>
  withUrl(
    `requests/downloads/csv_export?${urlize(
      getFilterCallParams(
        state,
        state.requests[direction].filter.filterBy,
        direction === 'sent',
        includeProxyRequests,
      ),
    )}`,
  );

export const hasSentRequests = ({
  user: {
    currentUser: { stats },
  },
}: StateType) => stats.requests.sent - stats.archivedRequests.sent > 0;

export type ExportFindingsToCsvPathFunctionType = (
  requestId: Uuid,
  includeRiskNote: boolean,
) => string;

export const exportFindingsToCsvPath = (
  requestId: Uuid,
  includeRiskNote: boolean,
) =>
  withUrl(
    `requests/${requestId}/findings/downloads/csv_export?include_risk_note=${includeRiskNote}`,
  );
