import styled from 'styled-components';

export const NextSendDate = styled.div`
  font-weight: ${({ theme }) => theme.basic.fontWeights.regular};
`;

export const ScheduledDate = styled.div`
  font-weight: ${({ theme }) => theme.basic.fontWeights.bold};
`;

export const NoCredits = styled.span`
  margin-left: 5px;
  color: ${({ theme }) => theme.basic.colors.error};
  font-weight: ${({ theme }) => theme.basic.fontWeights.regular};
`;
