import { DelegationDeleted } from '../../../generated/models/DelegationDeleted';
import { assocPath, pipe } from 'ramda';
import { call, put } from 'redux-saga/effects';
import { hydrateFilters } from './hydrateFilters';
import { remapCategories } from '../utils';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { Uuid } from '../../../common/common.types';

export interface FormsUnassignQuestionDelegationCallParamsType {
  formId: Uuid;
  params: {
    question_id: Uuid;
  };
}

export type FormsUnassignQuestionDelegationFunctionType = (
  questionId: Uuid,
  formId: Uuid,
) => void;

export type FormsUnassignQuestionDelegationActionType = ApiCallActionType<
  '@forms/API/UNASSIGN_QUESTION_DELEGATION',
  FormsUnassignQuestionDelegationCallParamsType,
  DelegationDeleted,
  never,
  FormsStateType
>;

export const unassignQuestionDelegation = (
  questionId: Uuid,
  formId: Uuid,
): FormsUnassignQuestionDelegationActionType => ({
  type: '@forms/API/UNASSIGN_QUESTION_DELEGATION',
  payload: {
    callParams: {
      formId,
      params: {
        question_id: questionId,
      },
    },
    endpointPath: ['forms', 'delegations', 'questions', 'delete'],
    selector: ({ category_stats }, { forms }) =>
      pipe(
        assocPath<never[], FormsStateType>(
          ['currentForm', 'questions', questionId, 'delegations'],
          [],
        ),
        assocPath(
          ['currentForm', 'categories'],
          remapCategories(category_stats, forms.currentForm!.categories!),
        ),
      )(forms),
    postActions: [
      () => put(hydrateFilters(formId)),
      () => call(() => trackEvent('Delegation', 'Remove - Form Question')),
    ],
  },
});
