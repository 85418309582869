import { ApiCallActionType } from '../../store.types';
import { UserStateType } from '../user.types';
import { put } from 'redux-saga/effects';

import update from 'immutability-helper';
import { loadAndInitInfo } from './loadAndInitInfo';

export type UserGetMyselfActionType = ApiCallActionType<
  '@user/API/GET_MYSELF',
  never,
  any, // type myself
  never,
  UserStateType
>;

export const getUserMyself = (): UserGetMyselfActionType => ({
  type: '@user/API/GET_MYSELF',
  payload: {
    endpointPath: ['user', 'getMyself'],
    selector: (data: any, { user }) =>
      update(user, {
        myself: { $set: data },
      }),
    postActions: () => [() => put(loadAndInitInfo())],
  },
});
