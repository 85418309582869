import React from 'react';
import { TeamsItemType } from 'common/common.types';
import { Details } from './RecipientOption.styles';
import { TFunction } from 'i18next';

const RecipientOption = (t: TFunction) => ({
  label,
  members,
}: TeamsItemType) => (
  <div>
    <div>{label}</div>

    <Details>
      {t('internal.members', { count: members.length }) as string}
      {members.length > 0 && `: ${members.map(member => ` ${member}`)}`}
    </Details>
  </div>
);

export default RecipientOption;
