import NumberFormat from 'react-number-format';
import styled from 'styled-components';

export const FormattedInput = styled(NumberFormat)`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.atlas.text.secondary};
  display: block;
  font-size: ${({ theme }) => theme.basic.fontSizes.small}
  height: 36px;
  padding: 11px;
  text-align: right;
  width: ${({ width }) => `${width ? width : 170}px`};

  &:focus {
    background-color: ${({ theme }) => theme.basic.colors.light};
    border-color: ${({ theme }) => theme.basic.colors.dark};
    outline: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
