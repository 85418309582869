/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreditHistoryRecord
 */
export interface CreditHistoryRecord {
    /**
     * Amount of credits added/removed by this charging
     * @type {number}
     * @memberof CreditHistoryRecord
     */
    amount: number;
    /**
     * When the charing happened
     * @type {Date}
     * @memberof CreditHistoryRecord
     */
    createdAt: Date;
    /**
     * When the credit added/removed by this charging becomes expired
     * @type {Date}
     * @memberof CreditHistoryRecord
     */
    expiresAt: Date;
    /**
     * True if this charging is irrelevant, because its credit is expired
     * @type {boolean}
     * @memberof CreditHistoryRecord
     */
    isExpired: boolean;
}

export function CreditHistoryRecordFromJSON(json: any): CreditHistoryRecord {
    return CreditHistoryRecordFromJSONTyped(json, false);
}

export function CreditHistoryRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditHistoryRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'createdAt': (new Date(json['created_at'])),
        'expiresAt': (new Date(json['expires_at'])),
        'isExpired': json['is_expired'],
    };
}

export function CreditHistoryRecordToJSON(value?: CreditHistoryRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'created_at': (value.createdAt.toISOString()),
        'expires_at': (value.expiresAt.toISOString()),
        'is_expired': value.isExpired,
    };
}


