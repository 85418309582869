import styled from 'styled-components/macro';
import Textarea from '../../../common/Textarea';
import { inputCssProps } from '../../Header/Header.styles';

export const Wrapper = styled.div`
  margin-right: 85px;
`;

export const TextInput = styled(Textarea)`
  height: 52px;
  ${({ theme }) => theme.fonts.regular.small};

  ${inputCssProps}
`;
