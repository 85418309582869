import * as R from 'ramda';
import i18n from '../../../i18n';
import { Attachment } from '../../../generated/models/Attachment';
import { call, put } from 'redux-saga/effects';
import { parseAttachment } from 'utils/parsers';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { Uuid } from '../../../common/common.types';

export interface FormsPostAttachmentCallParamsType {
  formData: FormData;
  formId: Uuid;
}

export type FormsPostAttachmentFunctionType = (
  formData: FormData,
  formId: Uuid,
) => void;

export type FormsPostAttachmentActionType = ApiCallActionType<
  '@forms/API/POST_ATTACHMENT',
  FormsPostAttachmentCallParamsType,
  Attachment,
  never,
  FormsStateType
>;

export const postAttachment = (
  formData: FormData,
  formId: Uuid,
): FormsPostAttachmentActionType => ({
  type: '@forms/API/POST_ATTACHMENT',
  payload: {
    callParams: { formData, formId },
    endpointPath: ['forms', 'attachments', 'post'],
    selector: (data, state): Partial<FormsStateType> =>
      R.assocPath(
        ['currentForm', 'attachments', data.id],
        parseAttachment(data),
        state.forms,
      ),
    postActions: [
      () => call(() => trackEvent('Attachment', 'Add - Form')),
      () => put(flash(i18n.t('common:responses.fileCreateSuccess'))),
    ],
  },
});
