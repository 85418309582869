import styled, { css } from 'styled-components';
import ReactSVG from 'react-svg';

export const Content = styled.table`
  @page {
    size: A4 portrait;
    margin: 18px 31px;
  }

  break-inside: avoid;
  break-after: page;
  counter-reset: page;
  color: ${({ theme }) => theme.atlas.text.primary};
  width: 100%;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;

  @media print {
    break-after: page;
  }
`;

export const FirstPage = styled(Page)`
  margin: 250px 40px 0px;
`;

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.semibold.huge}
`;

export const TitleSecond = styled(Title)`
  color: ${({ theme }) => theme.atlas.teal.primary};
`;

export const SubTitle = styled.h2`
  color: ${({ theme }) => theme.atlas.text.secondary};
  margin-top: 30px;
  ${({ theme }) => theme.fonts.regular.bigger}
`;

export const Generated = styled.h3`
  margin-top: 12px;
  ${({ theme }) => theme.fonts.regular.normal}
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  line-height: 20px;
  opacity: 0.2;
  ${({ theme }) => theme.fonts.regular.mini}
`;

const CustomLogo = (height: number) => css`
  svg {
    height: ${height}px;

    .st1 {
      fill: ${({ theme }) => theme.atlas.text.primary};
    }
  }
`;

export const HeaderLogo = styled(ReactSVG).attrs((props: any) => ({
  src: '/atlas-logo.svg',
}))`
  ${CustomLogo(27)};
`;

export const Logo = styled(ReactSVG).attrs((props: any) => ({
  src: '/atlas-logo.svg',
}))`
  margin-left: -18px;
  ${CustomLogo(70)};
`;

export const PageCount = styled.div``;

export const Sections = styled.div`
  @media print {
    break-inside: avoid;
    break-after: avoid;
  }

  display: flex;
  flex-direction: row;
  width: 100%;
  background: ${({ theme }) => theme.atlas.grey['50']};
  color: ${({ theme }) => theme.atlas.text.secondary};
  text-transform: uppercase;
  ${({ theme }) => theme.fonts.bold.mini}
`;

export const Section = styled.div<{ width?: number; grow?: boolean }>`
  flex-basis: ${({ width = 120 }) => width}px;
  flex-grow: ${({ grow }) => (grow ? 1 : 0)};
  flex-shrink: 0;
  padding: 12px 17px;
`;

export const Text = styled.p`
  margin: 17px;
`;
