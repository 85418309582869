import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const EmailWrapper = styled.li`
  list-style: none;

  a {
    color: ${({ theme }) => theme.basic.colors.ssc};
  }
`;

export const Divider = styled.div`
  background: ${({ theme }) => theme.atlas.grey[500]};
  width: 1px;
  height: calc(100% - 2px);
  margin: 0 3px 0 3px;
  display: inline-block;
  right: -6px;
  position: relative;
  top: 1px;
`;

export const QuestionIcon = styled(FontAwesomeIcon).attrs({
  icon: faInfoCircle as IconProp,
})`
  margin: 0 -3px 0 7px;
  color: ${({ theme }) => theme.atlas.grey[500]};
`;
