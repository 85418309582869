import React, { useContext } from 'react';
import { TranslatePropsType } from 'common/common.types';
import { PageContext } from 'pages/Page';
import { DownloadEvidencePageContext } from 'pages/EvidenceDownloadPage';
import { withTranslation } from 'react-i18next';
import config from 'config';
import { PageHeader } from 'components/common/PageHeader';
import { Content } from 'common/common.styles';
import { DownloadWrapper } from './Download.styles';
import { FormPage410 } from 'pages';

const downloadEntity = (id?: string) => {
  if (id !== undefined) {
    window.open(`${config.url}/atlas/evidences/download/${id}`);
  }
};

const Download = ({ t }: TranslatePropsType) => {
  const {
    data: { downloads, resourceGone },
  } = useContext(PageContext) as DownloadEvidencePageContext;
  return (
    <>
      {resourceGone ? (
        <FormPage410 resourceGone={resourceGone} />
      ) : (
        <>
          <PageHeader heading={<div />} />
          <Content>
            <DownloadWrapper>
              <>
                {t('heading') as string}
                {downloadEntity(downloads.id)}
              </>
            </DownloadWrapper>
          </Content>
        </>
      )}
    </>
  );
};

export default withTranslation('Download')(Download);
