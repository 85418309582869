import styled from 'styled-components';
import { BasicInput } from 'common/common.styles';

export const InputWrapper = styled.div`
  padding: 0 10px 0 0;
  width: 300px;

  a {
    margin-top: 24px;
  }
`;

export const Instructions = styled.div`
  opacity: 0.7;
  position: absolute;
  right: 0;
  top: -50px;
  width: 270px;
  ${({ theme }) => theme.fonts.regular.small}

  p {
    padding: 5px 0;
  }

  strong {
    ${({ theme }) => theme.fonts.semibold.normal}
  }
`;

export const SmallerInputWrapper = styled(InputWrapper)`
  width: 70px;
`;

export const Label = styled.label`
  display: block;
  ${({ theme }) => theme.fonts.semibold.normal}
`;

export const Input = styled(BasicInput)`
  width: 100%;
`;

export const Caption = styled.div`
  padding: 5px 15px;
`;
