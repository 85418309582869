import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 3;
  display: inline-block
  align-items: center;
  .react-datepicker__input-container {
    width: inherit;
  }

  .react-datepicker-wrapper {
    width: 100%;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: 50%;
      margin-top: -9px;
      left: 10px;
      height: 18px;
      width: 16px;
      background: url(${'./img/calendar.svg'});
      background-size: 16px;
      background-repeat: no-repeat;
      z-index: 1;
    }
  }

  .react-datepicker__week {
    height: calc(1.7rem + 0.332rem);
  }

  .react-datepicker__day {
    ${({ theme }) => theme.fonts.regular.small}
  }

  .react-datepicker__day:hover,
  .react-datepicker__day--keyboard-selected:hover {
    background-color: ${({ theme }) => theme.inputs.datepicker.hoverBackground};
    color: ${({ theme }) => theme.inputs.datepicker.hoverColor};
    ${({ theme }) => theme.fonts.semibold.small}
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover {
    background-color: ${({ theme }) =>
      theme.inputs.datepicker.selectedBackground};
    color: ${({ theme }) => theme.inputs.datepicker.selectedColor};
    ${({ theme }) => theme.fonts.semibold.small}
  }

  .datepicker-input {
    height: 38px;
    padding: 0 10px 0 34px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.inputs.datepicker.border};
    ${({ theme }) => theme.fonts.regular.small}

    &:hover {
      border-color: ${({ theme }) => theme.inputs.datepicker.borderHover};
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__day--disabled {
  cursor: not-allowed;
}
`;

export const Note = styled.p`
  font-size: ${({ theme }) => theme.basic.fontSizes.mini};
  margin-top: 5px;
`;
