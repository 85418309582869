import styled, { css } from 'styled-components';
import Input from '../../../common/Input';

import { inputCssProps } from '../../Header/Header.styles';
import Label from '../../../common/typography/Label';
import { Action } from '../../Actions/Actions.styles';

export const PaddingBelowWrapper = styled.div`
  padding-bottom: 6px;
`;

export const HeaderAction = styled(Action)<{ isOpen?: boolean }>`
  margin-left: 8px;
  ${({ isOpen, theme }) =>
    isOpen === false // we want to return null for isOpen === undefined
      ? css`
          color: ${theme.basic.colors.teal};
        `
      : null}
`;

export const Wrapper = styled.div<{ isOpen: boolean }>`
  background: ${({ theme }) => theme.atlas.white};
  border: 1px solid ${({ theme }) => theme.atlas.white};

  ${({ isOpen, theme }) =>
    !isOpen
      ? css`
          ${HeaderAction} {
            display: none;
          }
          &:hover {
            border-color: ${theme.basic.colors.teal};
            ${HeaderAction} {
              display: block;
            }
          }
        `
      : null}
`;

export const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 11px;
  cursor: pointer;
`;

export const TitleText = styled(Label)`
  line-height: 24px;
  flex: 1;
  ${({ theme }) => theme.fonts.bold.small}
`;

export const TitleInput = styled(Input)`
  flex: 1;

  & input {
    ${inputCssProps}
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 15px 15px 15px;
  & > * + * {
    margin-top: 6px;
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.atlas.text.secondary};
`;
