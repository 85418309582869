import Modal from 'utils/Modal';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as routes from '../../../routes';
import { RequestType, TranslatePropsType } from 'common/common.types';
import {
  FirstContactWrapper,
  Footer,
  Header,
  LinkModalActionElement,
  LinkModalContentWrapper,
  LinkModalWrapper,
  Request,
  RequestInfoWrapper,
  RequestLink,
  RequestsEmail,
  RequestTitle,
  Scroller,
  StyledLink,
  Title,
} from './LinkModal.styles';
import FirstContact from '../Recipient/FirstContact';
import { FirstContactEmailTemplateEnum } from '../Recipient/FirstContact/FirstContact';
import { generateLinkUrl } from '../../../utils/url/generateLinkUrl';
import { UserDetailInternalStatusEnum } from '../../../generated/models';

interface LinkModalPropsType extends TranslatePropsType {
  copyToClipboard: (text: string) => void;
  modalId: string;
  onClose: () => void;
  requests: RequestType[];
}

const LinkModal = ({
  copyToClipboard,
  modalId,
  onClose,
  requests,
  t,
}: LinkModalPropsType) => {
  const [showFirstContactSuggestion, updateFirstContactSuggestion] = useState(
    true, // This will be updated with an API call to determine if first contact is being made.
  );

  const closeFirstContactSuggestion = () => {
    updateFirstContactSuggestion(false);
  };

  const modalProps = {
    modalId: modalId,
    title: (
      <Header>
        {t('generateLinkTitle', { count: requests.length }) as string}
      </Header>
    ),
    onClose: onClose,
    custom: true,
  };

  return (
    <Modal {...modalProps}>
      <LinkModalWrapper>
        <Header>
          <Title>
            {t('generateLinkTitle', { count: requests.length }) as string}
          </Title>
        </Header>
        <LinkModalContentWrapper>
          <Scroller>
            {requests.map((eachRequest: RequestType) => {
              const { email, internalStatus } = eachRequest.targetUser;
              const isInvited =
                internalStatus === UserDetailInternalStatusEnum.INVITED;
              const url = generateLinkUrl(
                email,
                isInvited,
                eachRequest.form.id,
              );
              return (
                <Request key={`request-${eachRequest.standard.name}-${email}`}>
                  <RequestInfoWrapper>
                    {email && <RequestsEmail>{email}</RequestsEmail>}
                    <RequestTitle>{eachRequest.standard.name}</RequestTitle>
                    <RequestLink href={url} target={'_blank'}>
                      {url}
                    </RequestLink>
                  </RequestInfoWrapper>
                  <LinkModalActionElement onClick={() => copyToClipboard(url)}>
                    {t('copyToClipboard') as string}
                  </LinkModalActionElement>
                </Request>
              );
            })}
            {showFirstContactSuggestion && (
              <FirstContactWrapper>
                <FirstContact
                  emailTemplateType={
                    FirstContactEmailTemplateEnum.LINK_CREATED_EMAIL_TEMPLATE
                  }
                  requestLinks={requests.map((eachRequest: RequestType) => {
                    const { email, internalStatus } = eachRequest.targetUser;
                    const isInvited =
                      internalStatus === UserDetailInternalStatusEnum.INVITED;
                    const url = generateLinkUrl(
                      email,
                      isInvited,
                      eachRequest.form.id,
                    );
                    return [eachRequest.standard.name, url];
                  })}
                  closeDialog={closeFirstContactSuggestion}
                  copyToClipboard={copyToClipboard}
                />
              </FirstContactWrapper>
            )}
          </Scroller>
        </LinkModalContentWrapper>
        <Footer>
          <StyledLink to={routes.REQUESTS_SENT}>
            {t('returnToQueue') as string}
          </StyledLink>
        </Footer>
      </LinkModalWrapper>
    </Modal>
  );
};

export default withTranslation('SendRequest')(LinkModal);
