import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { scoreScale } from './ScoreChart.styles';
import { RequestSimpleType, TranslatePropsType } from 'common/common.types';
import { getBarWidth } from '../utils';
import { withTranslation } from 'react-i18next';
import ScoreRecord from './ScoreRecord';
import { ChartGauge } from '../Insights.styles';

export interface ScoreChartPropsType extends TranslatePropsType {
  requests: RequestSimpleType[];
}

const ScoreChart = ({ requests, t }: ScoreChartPropsType) => (
  <ChartGauge title={t('charts.validationScore')}>
    {requests.map(
      ({
        form: { id, isScoreVisible, score },
        meta: { companyId },
        target: { name },
        standard: { name: questionnaireName },
      }: RequestSimpleType) => (
        <ScoreRecord
          key={`score-bar-${id}`}
          color={scoreScale(score).hex()}
          companyId={companyId}
          formId={id}
          name={questionnaireName}
          title={name}
          width={getBarWidth(
            requests[0].form.score,
            isScoreVisible ? score : 0,
            90,
          )}
        >
          {isScoreVisible
            ? ReactHtmlParser(t('percentage', { count: score }))
            : (t('na') as string)}
        </ScoreRecord>
      ),
    )}
  </ChartGauge>
);

export default withTranslation('Insights')(ScoreChart);
