import Accordion, { AccordionSection } from '../../common/Accordion';
import FormPreviewCategoryHeader from './FormPreviewCategoryHeader';
import React, { RefObject } from 'react';
import { Category } from '../../Form/Category';
import { Question } from '../../Form/Question';
import { Wrapper } from './FormPreview.styles';
import { findDOMNode } from 'react-dom';
import {
  getSortedQuestionsForCategory,
  getTotalQuestionCount,
} from '../../Form/utils';
import {
  FormType,
  LanguageType,
  QuestionType,
  UserType,
} from 'common/common.types';
import {
  FormsLoadQuestionsFunctionType,
  FormsPutQuestionFunctionType,
} from 'store/forms/actions';

interface FormPreviewPropsType {
  forwardedRef: RefObject<HTMLDivElement>;
  loadQuestions: FormsLoadQuestionsFunctionType;
  previewForm: FormType;
  putQuestion: FormsPutQuestionFunctionType;
  user: UserType;
  language: LanguageType;
}

const FormPreview = ({
  forwardedRef,
  loadQuestions,
  previewForm: {
    categories,
    fillingType: formFillingType,
    id,
    meta,
    owner: { name: formOwner },
    request,
    permissions,
    questions,
  },
  putQuestion,
  user,
  language,
}: FormPreviewPropsType) => {
  const formSource = request && request.source.name;
  const getData = (categoryName: string, categoryTotalCount: number) => {
    const data = getSortedQuestionsForCategory(categoryName)(questions);
    const totalQuestionCount = getTotalQuestionCount(categoryName)(questions);
    return {
      data,
      hasMore: data.length < categoryTotalCount,
      totalQuestionCount,
    };
  };

  return (
    <Wrapper>
      {categories && (
        <Accordion>
          {categories.map(
            ({ name: category, questionsTotalVisibleCount }, index: number) => (
              <AccordionSection
                dataTest={'accordion'}
                heading={<FormPreviewCategoryHeader name={category} />}
                id={index}
                key={category}
                topOffset={20}
                wrapperElement={findDOMNode(forwardedRef.current) as Element}
              >
                <Category
                  data={getData(category, questionsTotalVisibleCount)}
                  actions={{
                    loadMore: (offset, limit) => {
                      loadQuestions({ id, category, offset, limit });
                    },
                    renderItem: (question: QuestionType) => (
                      <Question
                        key={`question-${question.id}`}
                        actions={{ putQuestion }}
                        data={{
                          formFillingType,
                          formOwner,
                          formSource,
                          permissions,
                          question,
                          meta,
                          user,
                          language,
                        }}
                      />
                    ),
                  }}
                />
              </AccordionSection>
            ),
          )}
        </Accordion>
      )}
    </Wrapper>
  );
};

export default FormPreview;
