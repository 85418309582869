/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Company } from "./Company";
// @ts-ignore
import { RequestSummary } from "./RequestSummary";
// @ts-ignore
import { Standard } from "./Standard";
// @ts-ignore
import { Team } from "./Team";
// @ts-ignore
import { User } from "./User";

/**
 *
 * @export
 * @interface RequestDetail
 */
export interface RequestDetail {
    /**
     *
     * @type {number}
     * @memberof RequestDetail
     */
    completion_days?: number;
    /**
     * Alias for form_questions_filled
     * @type {number}
     * @memberof RequestDetail
     */
    form_filled: number;
    /**
     * Portion of visible questions filled
     * @type {number}
     * @memberof RequestDetail
     */
    form_percentage_filled: number;
    /**
     * Number of questions in the form
     * @type {number}
     * @memberof RequestDetail
     */
    form_question_count: number;
    /**
     * Number of questions in the form without taking visibility into account
     * @type {number}
     * @memberof RequestDetail
     */
    form_question_count_ignore_visibility: number;
    /**
     * Number of questions that have all responses filled
     * @type {number}
     * @memberof RequestDetail
     */
    form_questions_completely_filled: number;
    /**
     * Number of questions that are either completely or partially filled
     * @type {number}
     * @memberof RequestDetail
     */
    form_questions_filled: number;
    /**
     * Number of questions that have at least one response and not all responses filled
     * @type {number}
     * @memberof RequestDetail
     */
    form_questions_partially_filled: number;
    /**
     * Number of responses in the form
     * @type {number}
     * @memberof RequestDetail
     */
    form_response_count: number;
    /**
     * Number of responses in the form without taking visibility into account
     * @type {number}
     * @memberof RequestDetail
     */
    form_response_count_ignore_visibility: number;
    /**
     * Number of responses filled in the form
     * @type {number}
     * @memberof RequestDetail
     */
    form_responses_filled: number;
    /**
     *
     * @type {number}
     * @memberof RequestDetail
     */
    form_score: number;
    /**
     * Visibility flag for form score
     * @type {boolean}
     * @memberof RequestDetail
     */
    form_score_visible: boolean;
    /**
     * Alias for form_question_count
     * @type {number}
     * @memberof RequestDetail
     */
    form_total: number;
    /**
     * When was the entity created
     * @type {Date}
     * @memberof RequestDetail
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof RequestDetail
     */
    updated_at: Date;
    /**
     * The day when the VRM accepted the request. This will be set internally and         only when the VRM status changes from in progress to accepted
     * @type {Date}
     * @memberof RequestDetail
     */
    accepted_date?: Date;
    /**
     * User can view form data
     * @type {boolean}
     * @memberof RequestDetail
     */
    can_view_data: boolean;
    /**
     *
     * @type {string}
     * @memberof RequestDetail
     */
    code: string;
    /**
     *
     * @type {Date}
     * @memberof RequestDetail
     */
    due_date?: Date;
    /**
     *
     * @type {string}
     * @memberof RequestDetail
     */
    form_id: string;
    /**
     *
     * @type {string}
     * @memberof RequestDetail
     */
    form_name: string;
    /**
     *
     * @type {Array<string>}
     * @memberof RequestDetail
     */
    form_roles: Array<RequestDetailFormRolesEnum>;
    /**
     *
     * @type {number}
     * @memberof RequestDetail
     */
    history_count: number;
    /**
     *
     * @type {string}
     * @memberof RequestDetail
     */
    id: string;
    /**
     *
     * @type {boolean}
     * @memberof RequestDetail
     */
    is_internal: boolean;
    /**
     *
     * @type {string}
     * @memberof RequestDetail
     */
    message: string;
    /**
     *
     * @type {number}
     * @memberof RequestDetail
     */
    revision_count: number;
    /**
     *
     * @type {string}
     * @memberof RequestDetail
     */
    risk_note: string;
    /**
     *
     * @type {Standard}
     * @memberof RequestDetail
     */
    standard: Standard;
    /**
     * Current request status
     * @type {string}
     * @memberof RequestDetail
     */
    status: RequestDetailStatusEnum;
    /**
     * Status of the request after it will be unarchived
     * @type {string}
     * @memberof RequestDetail
     */
    status_before_archived: RequestDetailStatusBeforeArchivedEnum;
    /**
     * When the request was first opened by target (vendor).
     * @type {Date}
     * @memberof RequestDetail
     */
    target_opened_at?: Date;
    /**
     *
     * @type {boolean}
     * @memberof RequestDetail
     */
    was_scheduled: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestDetail
     */
    is_vendor: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestDetail
     */
    is_vrm: boolean;
    /**
     *
     * @type {Company}
     * @memberof RequestDetail
     */
    source: Company;
    /**
     *
     * @type {Team}
     * @memberof RequestDetail
     */
    source_team?: Team;
    /**
     *
     * @type {User}
     * @memberof RequestDetail
     */
    source_user: User;
    /**
     *
     * @type {Company}
     * @memberof RequestDetail
     */
    target: Company;
    /**
     *
     * @type {Team}
     * @memberof RequestDetail
     */
    target_team?: Team;
    /**
     *
     * @type {User}
     * @memberof RequestDetail
     */
    target_user: User;
    /**
     * Current vrm view status
     * @type {string}
     * @memberof RequestDetail
     */
    vrm_view_status: RequestDetailVrmViewStatusEnum;
    /**
     *
     * @type {Date}
     * @memberof RequestDetail
     */
    form_updated_at: Date;
    /**
     *
     * @type {boolean}
     * @memberof RequestDetail
     */
    has_new_attachments: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestDetail
     */
    has_new_comments: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestDetail
     */
    has_new_history: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestDetail
     */
    has_new_ssc_data: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestDetail
     */
    is_unread: boolean;
    /**
     *
     * @type {string}
     * @memberof RequestDetail
     */
    standard_name: string;
}




/**
* @export
* @enum {string}
*/
export enum RequestDetailFormRolesEnum {
    OWNER = 'OWNER',
    VENDOR = 'VENDOR',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY',
    OTHER = 'OTHER'
}
/**
* @export
* @enum {string}
*/
export enum RequestDetailStatusEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}
/**
* @export
* @enum {string}
*/
export enum RequestDetailStatusBeforeArchivedEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}
/**
* @export
* @enum {string}
*/
export enum RequestDetailVrmViewStatusEnum {
    VENDORAPPROVED = 'VENDOR_APPROVED',
    VENDORDECLINED = 'VENDOR_DECLINED',
    VENDORINITIAL = 'VENDOR_INITIAL',
    VRMREQUESTED = 'VRM_REQUESTED'
}


