import { Uuid } from 'common/common.types';

export type ToggleSelectionsFormDefinitionFunctionType = (
  formDefinitionIds: Array<Uuid | undefined>,
) => void;

export interface ToggleSelectionFormDefinitionActionType {
  type: '@formDefinitions/TOGGLE_FORM_DEFINITION_SELECTION';
  payload: {
    formDefinitionIds: Uuid[];
  };
}

export type ClearSelectionFormDefinitionFunctionType = () => void;

export interface ClearSelectionFormDefinitionActionType {
  type: '@formDefinitions/CLEAR_FORM_DEFINITION_SELECTION';
}

export const toggleFormDefinitionSelection = (
  formDefinitionIds: Uuid[],
): ToggleSelectionFormDefinitionActionType => ({
  type: '@formDefinitions/TOGGLE_FORM_DEFINITION_SELECTION',
  payload: {
    formDefinitionIds,
  },
});

export const clearFormDefinitionSelection = (): ClearSelectionFormDefinitionActionType => ({
  type: '@formDefinitions/CLEAR_FORM_DEFINITION_SELECTION',
});
