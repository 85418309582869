/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface DelegationSummary
 */
export interface DelegationSummary {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof DelegationSummary
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof DelegationSummary
     */
    updatedAt: Date;
    /**
     * 
     * @type {User}
     * @memberof DelegationSummary
     */
    assignee: User;
    /**
     * 
     * @type {string}
     * @memberof DelegationSummary
     */
    assigneeId: string;
    /**
     * 
     * @type {User}
     * @memberof DelegationSummary
     */
    assignor: User;
    /**
     * 
     * @type {string}
     * @memberof DelegationSummary
     */
    assignorId: string;
    /**
     * 
     * @type {string}
     * @memberof DelegationSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DelegationSummary
     */
    ownerId: string;
    /**
     * 
     * @type {string}
     * @memberof DelegationSummary
     */
    questionId: string;
}

export function DelegationSummaryFromJSON(json: any): DelegationSummary {
    return DelegationSummaryFromJSONTyped(json, false);
}

export function DelegationSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DelegationSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'assignee': UserFromJSON(json['assignee']),
        'assigneeId': json['assignee_id'],
        'assignor': UserFromJSON(json['assignor']),
        'assignorId': json['assignor_id'],
        'id': json['id'],
        'ownerId': json['owner_id'],
        'questionId': json['question_id'],
    };
}

export function DelegationSummaryToJSON(value?: DelegationSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'assignee': UserToJSON(value.assignee),
        'assignee_id': value.assigneeId,
        'assignor': UserToJSON(value.assignor),
        'assignor_id': value.assignorId,
        'id': value.id,
        'owner_id': value.ownerId,
        'question_id': value.questionId,
    };
}


