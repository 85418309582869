import styled from 'styled-components';
import { Button } from '../common/Button/Button.styles';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { alwaysShowScrollbar } from 'common/common.styles';
import { ROW_HEIGHT } from './Recipient.styles';

import { Creatable } from '../common/Select';

export const CopyButton = styled(Button)`
  background: transparent;
  color: ${({ theme }) => theme.basic.colors.teal};
  text-decoration: underline !important;
  ${({ theme }) => theme.basic.fontWeights.semibold}
`;

export const CopyIcon: any = styled(FontAwesomeIcon).attrs(() => ({
  icon: faCopy,
}))`
  margin-right: 5px;
`;

export const Label = styled.div`
  line-height: 1.3;
  padding-bottom: 8px;
  margin-left: 4px;
  ${({ theme }) => theme.fonts.regular.small}
`;

export const Note = styled.p`
  margin: 4px 0 4px 4px;
  line-height: 15px;
  color: ${({ theme }) => theme.atlas.grey['400']};
  ${({ theme }) => theme.fonts.regular.xsmall}

  > span {
    color: ${({ theme }) => theme.atlas.text.primary};
    ${({ theme }) => theme.fonts.bold.xsmall}
  }

  > strong {
    color: ${({ theme }) => theme.atlas.grey['600']};
  }
`;

export const InviteWrapper = styled.div`
  padding: 20px;
  background: ${({ theme }) => theme.atlas.grey['50']};

  > ${Button} {
    margin-top: 4px;
  }
`;

export const InputLabel = styled.div`
  padding-left: 20px;
  ${({ theme }) => theme.fonts.semibold.small}
`;

export const ListWrapper = styled.div`
  max-height: ${3 * ROW_HEIGHT}px;
  overflow: hidden;
  overflow-y: auto;

  ${alwaysShowScrollbar}
`;

export const LinkWrapper = styled.div`
  margin-bottom: 20px;
`;

export const MultiEmail = styled(Creatable).attrs(() => ({
  noOptionsMessage: () => null,
  components: {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
  },
}))`
  width: 350px;
`;
