import baseTheme from '../light';

export default {
  ...baseTheme,
  nav: {
    ...baseTheme.nav,
    button: {
      bg: '#005DAA',
      text: 'white',
    },
    icon: {
      color: '#599CD3',
    },
    logo: {
      src: '/whitelabel/royalbankofcanada/RBC_Royal_Bank.svg',
      height: '40px',
      marginRight: '10px',
      marginLeft: '10px',
    },
  },
  header: {
    backgroundImage: '/whitelabel/royalbankofcanada/bg@2x.png',
  },
};
