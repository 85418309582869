import {
  FilterByType,
  FilterItemType,
  FilterOptionType,
} from 'utils/filters/requests.filters';
import Chip from '../../Form/Filter/Chip';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';

export interface FiltersDefaultPropsType extends TranslatePropsType {
  filterBy: FilterItemType;
  filterOptions: FilterOptionType[];
  handleDelete: (option: FilterByType, item?: string) => void;
  option: FilterByType;
  language?: { label: string; value: string };
}

const FiltersDefault = ({
  handleDelete,
  option,
  t,
}: FiltersDefaultPropsType) => (
  <Chip
    label={t(`Requests.filterOptions.${option}`)}
    onDelete={() => handleDelete(option)}
  />
);

export default withTranslation('components')(FiltersDefault);
