import React from 'react';
import Select from '../../common/Select';
import { LabelSpaced } from '../Stepper.styles';
import { SetFormValueFunctionType } from 'store/requests/actions';
import { Wrapper } from './Reminder.styles';
import { reminders } from 'common/common.constants';
import { withTranslation } from 'react-i18next';
import { ReminderItemType, TranslatePropsType } from 'common/common.types';

interface ReminderPropTypes extends TranslatePropsType {
  reminder: ReminderItemType;
  setValue: SetFormValueFunctionType;
}

const Reminder = ({ reminder, setValue, t }: ReminderPropTypes) => (
  <Wrapper>
    <LabelSpaced>{t('setReminder') as string}</LabelSpaced>

    <Select
      getOptionLabel={(option: ReminderItemType) => t(option.label)}
      onChange={(value: ReminderItemType) => setValue('reminder', value)}
      options={reminders}
      placeholder={t('selectReminder') as string}
      value={reminder}
    />
  </Wrapper>
);

export default withTranslation('SendRequest')(Reminder);
