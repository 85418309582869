/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";
// @ts-ignore
import { ProxyShortSummary } from "./ProxyShortSummary";
// @ts-ignore
import { UserDetail } from "./UserDetail";

/**
 * 
 * @export
 * @interface ProxySummary
 */
export interface ProxySummary {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof ProxySummary
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof ProxySummary
     */
    updated_at: Date;
    /**
     * 
     * @type {string}
     * @memberof ProxySummary
     */
    form_id: string;
    /**
     * 
     * @type {string}
     * @memberof ProxySummary
     */
    form_role: ProxySummaryFormRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ProxySummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProxySummary
     */
    status: ProxySummaryStatusEnum;
    /**
     * 
     * @type {UserDetail}
     * @memberof ProxySummary
     */
    user: UserDetail;
}




/**
* @export
* @enum {string}
*/
export enum ProxySummaryFormRoleEnum {
    OWNER = 'OWNER',
    VENDOR = 'VENDOR',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY',
    OTHER = 'OTHER'
}
/**
* @export
* @enum {string}
*/
export enum ProxySummaryStatusEnum {
    ACTIVE = 'ACTIVE',
    REVOKED = 'REVOKED'
}


