import styled, { css } from 'styled-components';

type WrapperType = {
  big?: boolean;
};

export const Wrapper = styled.div<WrapperType>`
  background: ${({ theme }) => theme.atlas.white};
  text-align: center;
  padding-top: ${({ big }) => (big ? '26px' : '0')};

  ${({ big }) =>
    big
      ? css`
          width: 310px;
        `
      : null}
`;

export const CompanyName = styled.h3`
  ${({ theme }) => theme.fonts.bold.normal}
  line-height: 1.315;
  margin-bottom: 16px;
`;

export const List = styled.ul`
  margin: 20px auto;
  list-style-type: none;

  li {
    margin-bottom: 21px;
    line-height: 1.34;

    &:last-of-type {
      margin: 0;
    }
  }
`;
