import React from 'react';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { Text, Wrapper } from './Unverified.styles';

export interface UnverifiedPropsType extends TranslatePropsType {
  className?: string;
  onClick?: () => void;
  showText?: boolean;
  receiver?: string;
}

const Unverified = ({
  className,
  onClick,
  receiver,
  showText = true,
  t,
}: UnverifiedPropsType) => (
  <Wrapper className={className} onClick={onClick} data-cy="unverified-company">
    {showText && (
      <Text data-test="company-name" title={receiver}>
        {receiver}
      </Text>
    )}
  </Wrapper>
);

export default withTranslation('common')(Unverified);
