import { EMPTY_ID } from 'common/common.constants';
import { CurrentUserType } from 'common/common.types';
import Loading from 'components/common/Loading';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { StateType } from '../store/store.types';
import formatRoute, * as R from '../routes';

interface HomeRedirectPageType {
  user: CurrentUserType;
}

export const HomeRedirectPage = ({ user }: HomeRedirectPageType) => {
  if (user.id === EMPTY_ID) {
    return <Loading />;
  }

  if (user.stats.isNewVendor) {
    return <Redirect to={R.NEW_VENDOR_LANDING_PAGE} />;
  }

  if (user.isGuest) {
    return <Redirect to={R.REQUESTS} />;
  }

  if (user.stats.logins > 1) {
    if (user.stats.requests.received + user.stats.requests.sent > 0) {
      return <Redirect to={R.REQUESTS} />;
    }

    if (user.stats.frameworks.responses.filledCount > 0) {
      return <Redirect to={formatRoute(R.FRAMEWORKS_DASHBOARD)} />;
    }
  }

  return <Redirect to={R.FIRST_RUN} />;
};

const mapStateToProps = (state: StateType) => ({
  user: state.user.currentUser,
});

export default connect(mapStateToProps)(HomeRedirectPage);
