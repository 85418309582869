import React from 'react';

interface TabListPropsType {
  className?: string;
  children: React.ReactNode;
  onChange: (value: number) => void;
  value: number;
}

export interface TabBasePropsType {
  isActive?: boolean;
  label: string;
  value: number;
  onClick?: () => void;
}

const TabList = ({
  className,
  children,
  value,
  onChange,
}: TabListPropsType) => (
  <div className={className}>
    {React.Children.map(
      children,
      (child: React.ReactElement<TabBasePropsType>, index: number) =>
        child &&
        React.cloneElement(child, {
          isActive: child.props.value === value,
          onClick: () => onChange(child.props.value),
        }),
    )}
  </div>
);

export default TabList;
