/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduledRequestUpdate
 */
export interface ScheduledRequestUpdate {
    /**
     * 
     * @type {number}
     * @memberof ScheduledRequestUpdate
     */
    occurrenceNumber: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledRequestUpdate
     */
    recurrenceType: ScheduledRequestUpdateRecurrenceTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ScheduledRequestUpdate
     */
    recurrenceUnits: number;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledRequestUpdate
     */
    sendDate: Date;
}

export function ScheduledRequestUpdateFromJSON(json: any): ScheduledRequestUpdate {
    return ScheduledRequestUpdateFromJSONTyped(json, false);
}

export function ScheduledRequestUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduledRequestUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'occurrenceNumber': json['occurrence_number'],
        'recurrenceType': json['recurrence_type'],
        'recurrenceUnits': json['recurrence_units'],
        'sendDate': (new Date(json['send_date'])),
    };
}

export function ScheduledRequestUpdateToJSON(value?: ScheduledRequestUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'occurrence_number': value.occurrenceNumber,
        'recurrence_type': value.recurrenceType,
        'recurrence_units': value.recurrenceUnits,
        'send_date': (value.sendDate.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum ScheduledRequestUpdateRecurrenceTypeEnum {
    DAILY = 'DAILY',
    HOURLY = 'HOURLY',
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY',
    YEARLY = 'YEARLY'
}


