import { Uuid } from 'common/common.types';
import { DownloadsActionType } from './actions';

export interface DownloadsStateType {
  entityType?: 'evidence' | 'form';
  id?: Uuid;
  resourceGone?: boolean;
}

export const initialState: DownloadsStateType = {};

export const downloads = (
  originalState: DownloadsStateType = initialState,
  action: DownloadsActionType,
): DownloadsStateType => {
  return originalState;
};
