import { ApiCallActionType } from '../../store.types';
import { VRMReviewStatsStatusEnum } from '../../../generated/models/VRMReviewStats';
import { getFormWithoutReset } from './getFormWithoutReset';
import { put } from 'redux-saga/effects';
import { Uuid } from '../../../common/common.types';

export type FormsUpdateCategoryReviewStatusFunctionType = (
  categoryName: string,
  formId: Uuid,
  status: VRMReviewStatsStatusEnum,
) => void;

export interface FormsUpdateCategoryReviewStatusCallParamsType {
  category: string;
  formId: Uuid;
  status: VRMReviewStatsStatusEnum;
}

export type FormsUpdateCategoryReviewStatusActionType = ApiCallActionType<
  '@forms/API/UPDATE_CATEGORY_REVIEW_STATUS',
  FormsUpdateCategoryReviewStatusCallParamsType
>;

export const updateCategoryReviewStatus = (
  categoryName: string,
  formId: Uuid,
  status: VRMReviewStatsStatusEnum,
): FormsUpdateCategoryReviewStatusActionType => ({
  type: '@forms/API/UPDATE_CATEGORY_REVIEW_STATUS',
  payload: {
    callParams: { category: categoryName, formId, status },
    endpointPath: ['forms', 'categories', 'setReviewStatus'],
    selector: null,
    postActions: [() => put(getFormWithoutReset(formId))],
  },
});
