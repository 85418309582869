import UploadLink from './UploadLink';
import React from 'react';
import { UploadFileLink } from './UploadLinkWrapper.styles';

interface UploadPropsType {
  onDrop: (acceptedFiles: File[]) => void;
  linkText: string;
  isMultiple: boolean;
  acceptFiles?: string;
}

const UploadLinkWrapper = ({
  onDrop,
  linkText,
  isMultiple,
  acceptFiles = '',
}: UploadPropsType) => (
  <UploadLink
    render={isDragActive => <UploadFileLink>{linkText}</UploadFileLink>}
    onDrop={onDrop}
    isMultiple={isMultiple}
    acceptFiles={acceptFiles}
  />
);

export default UploadLinkWrapper;
