import i18n from '../../../i18n';
import { call, put } from 'redux-saga/effects';
import update from 'immutability-helper';
import { hydrateFilters } from './hydrateFilters';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { AttachmentsDict, Uuid } from '../../../common/common.types';
import { putQuestion } from './putQuestion';
import {
  DeleteEvidenceDetailResourceRequest,
  EvidenceDeleted,
} from '../../../generated/new';
import evidencesApi from '../../../api/new/evidencesApi';
import formsApi from '../../../api/new/formsApi';

export type FormsDeleteQuestionAttachmentFunctionType = (
  id: Uuid,
  questionId: Uuid,
  formId: Uuid,
  responseId?: Uuid,
  category?: string,
) => void;

export type FormsDeleteQuestionAttachmentActionType = OptimisticActionType<
  '@forms/API/DELETE_QUESTION_ATTACHMENT',
  ApiCallActionPayloadType<
    DeleteEvidenceDetailResourceRequest,
    EvidenceDeleted,
    never,
    FormsStateType
  >
>;

export const deleteQuestionAttachment = (
  id: Uuid,
  questionId: Uuid,
  formId: Uuid,
  responseId?: Uuid,
  category?: string,
): FormsDeleteQuestionAttachmentActionType => ({
  type: '@forms/API/DELETE_QUESTION_ATTACHMENT',
  payload: {
    callParams: { evidenceId: id },
    endpointPath: params =>
      responseId
        ? // For the cases when the removed file was autofilled from other form,
          // we need to use a special endpoint for removing from a specific
          // response, so that we don't accidentally remove the attachment
          // even from the original autofill source form.
          formsApi.deleteFormResponseAttachmentDetailResource({
            evidenceId: id,
            responseId,
            formId,
          })
        : // Question-level attachments cannot be autofilled, so there is no
          // danger that other forms would also use this file and therefore we
          // can remove the evidence completely.
          evidencesApi.deleteEvidenceDetailResource(params),
    expectedStateChange: {
      [`forms.currentForm.questions.${questionId}.attachments`]: (
        attachments: AttachmentsDict,
      ) => update(attachments, { $unset: [id] }),
      ...(responseId
        ? {
            [`forms.currentForm.questions.${questionId}.responses.${responseId}.attachments`]: (
              attachments: AttachmentsDict,
            ) => update(attachments, { $unset: [id] }),
          }
        : {}),
    },
    selector: null,
    postActions: [
      (data, { forms }) =>
        category &&
        responseId &&
        forms.currentForm &&
        Object.keys(
          forms.currentForm.questions[questionId].responses[responseId]
            .attachments,
        ).length === 0
          ? put(putQuestion(formId, questionId, responseId, '', category))
          : null,
      () => put(hydrateFilters(formId)),
      () => call(() => trackEvent('Attachment', 'Delete - Question')),
      () => put(flash(i18n.t('common:responses.fileDeleteSuccess'))),
    ],
  },
});
