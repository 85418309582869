import { FlattenSimpleInterpolation } from 'styled-components';

let canvasCached: HTMLCanvasElement | null = null;
let context: CanvasRenderingContext2D | null = null;
const fontCached: Record<string, string> = {};

export const getTextWidth = (
  text: string,
  font: FlattenSimpleInterpolation,
): number => {
  if (!canvasCached) {
    canvasCached = document.createElement('canvas');
    context = canvasCached.getContext('2d');
  }

  if (context) {
    const fontKey = font.toString();

    if (!(fontKey in fontCached)) {
      const pairs = new Map<string, string>(
        fontKey
          .match(/font-(size|weight|family):.+;/gi)!
          .map((y: any) =>
            y.split(':').map((z: string) => z.replace(/[:,;]/g, '').trim()),
          ),
      );
      const fontParsed = `${pairs.get('font-weight') || 'normal'} ${pairs.get(
        'font-size',
      ) || 'normal'} ${pairs.get('font-family') || 'Arial'}`;

      fontCached[fontKey] = fontParsed;
    }

    context.font = fontCached[fontKey];
    const metrics = context.measureText(text);

    return metrics.width;
  }

  return 0;
};
