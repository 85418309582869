/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Acknowledge
 */
export interface Acknowledge {
    /**
     * 
     * @type {boolean}
     * @memberof Acknowledge
     */
    asynchronously: boolean;
    /**
     * 
     * @type {string}
     * @memberof Acknowledge
     */
    formId?: string;
    /**
     * 
     * @type {string}
     * @memberof Acknowledge
     */
    message: string;
}

export function AcknowledgeFromJSON(json: any): Acknowledge {
    return AcknowledgeFromJSONTyped(json, false);
}

export function AcknowledgeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Acknowledge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asynchronously': json['asynchronously'],
        'formId': !exists(json, 'form_id') ? undefined : json['form_id'],
        'message': json['message'],
    };
}

export function AcknowledgeToJSON(value?: Acknowledge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asynchronously': value.asynchronously,
        'form_id': value.formId,
        'message': value.message,
    };
}


