import styled from 'styled-components';
import { OPTION_NO, OPTION_YES } from '../../Response/utils/normalizeOption';

export const ClosedValue = styled.div<{ value: string }>`
  ${({
    value,
    theme: {
      fonts: {
        semibold: { huge, big },
      },
    },
  }) => (value.length <= 3 ? huge : big)}
  text-transform: lowercase;
  padding-right: 5px;

  color: ${({
    theme: {
      inputs: {
        certainty: {
          colors: { yes, no, na },
        },
      },
    },
    value,
  }) => (value === OPTION_YES ? yes : value === OPTION_NO ? no : na)};
`;
