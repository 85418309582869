import React from 'react';
import { values } from 'ramda';
import isGenericLabel from 'utils/isGenericLabel';
import { Label, Wrapper } from './Evidence.styles';
import { ResponseInputPropsType } from './Response';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { ScrollWrapper } from '../Question/QuestionTabs.styles';
import { Attachment } from '../Question/Attachments';
import AddAttachment from '../Question/Attachments/AddAttachment';

const Evidence = ({
  actions: {
    createResponseAttachment,
    deleteQuestionAttachment,
    handleChange,
    setBlockSeen,
  },
  data: { attachments, formId, id: responseId, isEditable, label, questionId },
  t,
}: ResponseInputPropsType & TranslatePropsType) => (
  <Wrapper>
    <div>
      {label && !isGenericLabel(label) && (
        <Label>{t('label', { label }) as string}</Label>
      )}

      <AddAttachment
        createAttachment={formData =>
          createResponseAttachment &&
          createResponseAttachment(formData, formId, questionId, responseId)
        }
        disabled={!isEditable}
        handleChange={handleChange}
      />
    </div>

    {attachments && (
      <div>
        <ScrollWrapper>
          {values(attachments).map(attachment => (
            <Attachment
              attachment={attachment}
              areAttachmentsModifiable={isEditable}
              deleteAttachment={deleteQuestionAttachment}
              formId={formId}
              hasNotifications={true}
              key={`attachment-${
                attachment.id ? attachment.id : attachment.uploadingId
              }`}
              questionId={questionId}
              setBlockSeen={setBlockSeen}
            />
          ))}
        </ScrollWrapper>
      </div>
    )}
  </Wrapper>
);

export default withTranslation('Attachments')(Evidence);
