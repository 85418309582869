import React from 'react';
import { CurrentUserType } from 'common/common.types';
import { NavAnchor, NavLink } from './NavItem.styles';

interface NavItemPropsType {
  Child: React.FunctionComponent;
  href?: string;
  isExternal?: boolean;
  noAuth?: boolean;
  noHover?: boolean;
  noPadding?: boolean;
  to?: string;
  user: CurrentUserType;
}

const NavItem = ({
  Child,
  href,
  isExternal,
  noAuth,
  noHover,
  noPadding,
  to,
  user,
}: NavItemPropsType) => {
  if (!user.isAuthenticated && !noAuth) {
    return null;
  }
  const target = isExternal ? '_blank' : undefined;
  const rel = isExternal ? 'noreferrer nofollow' : undefined;

  return to ? (
    <NavLink
      to={to}
      hover={noHover ? 0 : 1}
      padding={noPadding ? 0 : 1}
      rel={rel}
      target={target}
    >
      <Child />
    </NavLink>
  ) : href ? (
    <NavAnchor
      href={href}
      hover={noHover ? 0 : 1}
      padding={noPadding ? 0 : 1}
      rel={rel}
      target={target}
    >
      <Child />
    </NavAnchor>
  ) : (
    <Child />
  );
};

export default NavItem;
