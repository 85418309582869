import i18n from '../../../i18n';
import uuid from 'utils/uuid';
import { Evidence } from '../../../generated/models/Evidence';
import update from 'immutability-helper';
import { call, put } from 'redux-saga/effects';
import { parseFile } from 'utils/parsers';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { UserStateType } from '../user.types';
import { FileType, Uuid } from '../../../common/common.types';

export type UserUploadFileFunctionType = (
  formData: FormData,
  teamId?: Uuid,
) => void;

export interface UserUploadFileParamsType {
  formData: FormData;
  team_id?: Uuid;
}

export type UserUploadFileActionType = OptimisticActionType<
  '@user/API/UPLOAD_FILE',
  ApiCallActionPayloadType<
    UserUploadFileParamsType,
    Evidence,
    never,
    UserStateType
  >
>;

export const uploadFile = (
  formData: FormData,
  teamId?: Uuid,
): UserUploadFileActionType => {
  const postingId: Uuid = uuid();

  return {
    type: '@user/API/UPLOAD_FILE',
    payload: {
      callParams: { formData, team_id: teamId },
      endpointPath: ['user', 'files', 'upload'],
      expectedStateChange: {
        'user.files': (files: FileType[]) => [
          ...files,
          {
            id: postingId,
            name: (formData.get('file') as File).name,
            fileSize: 0,
            isUploading: true,
          },
        ],
      },
      selector: (data, state) => {
        const fileIndex = state.user.files.findIndex(
          file => file.id === postingId,
        );
        return update(state.user, {
          files: {
            [fileIndex]: {
              $set: parseFile({ ...data, is_uploading: false }),
            },
          },
        });
      },
      postActions: [
        () => call(() => trackEvent('Evidence Locker', 'Upload file')),
        () => put(flash(i18n.t('common:responses.fileCreateSuccess'))),
      ],
    },
  };
};
