import { Wrapper } from './LinkRecipient.styles';
import DueDate from './DueDate';
import LinkReceivers from './LinkReceivers';
import React from 'react';
import { Flex } from 'common/common.styles';
import { SetFormValueFunctionType } from 'store/requests/actions';
import { TargetItemType, ValueLabelType } from 'common/common.types';
import { SubmitRequestButtonPartialProps } from './sharedTypes';
import Submit from '../Submit';
import TeamSelector from './Internal/TeamSelector';
import { Team } from 'generated/new';
import { useTranslation } from 'react-i18next';

interface LinkRecipientPropsType {
  dueDate?: Date | null;
  isSourceTeamSelectorVisible: boolean;
  setValue: SetFormValueFunctionType;
  sourceTeam?: ValueLabelType<string>;
  submitButtonProps: SubmitRequestButtonPartialProps;
  targets: TargetItemType[];
  userTeams: Team[];
}

const LinkRecipient = ({
  dueDate,
  isSourceTeamSelectorVisible,
  setValue,
  sourceTeam,
  submitButtonProps: {
    appealCredits,
    balance,
    handleSubmit,
    isFormValid,
    sendRequestError,
    selectedStandardsCount,
  },
  targets,
  userTeams,
}: LinkRecipientPropsType) => {
  const cost = targets.length * selectedStandardsCount;
  const { t } = useTranslation('SendRequest');

  return (
    <Wrapper>
      <Flex>
        <LinkReceivers setValue={setValue} targets={targets} />
        <DueDate dueDate={dueDate} setValue={setValue} />
      </Flex>
      {isSourceTeamSelectorVisible && (
        <TeamSelector
          label={t('internal.createAs')}
          selected={sourceTeam}
          setValue={setValue}
          teams={userTeams}
        />
      )}
      <Submit
        appealCredits={appealCredits}
        balance={balance}
        cost={cost}
        error={sendRequestError}
        handleSubmit={handleSubmit}
        isFormValid={isFormValid}
        isGeneratingLink={true}
        itemsCount={selectedStandardsCount}
        isCompanyLink={true}
      />
    </Wrapper>
  );
};

export default LinkRecipient;
