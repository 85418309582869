/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionMetadataBlockStats
 */
export interface QuestionMetadataBlockStats {
    /**
     * 
     * @type {number}
     * @memberof QuestionMetadataBlockStats
     */
    newCount: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionMetadataBlockStats
     */
    otherCount: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionMetadataBlockStats
     */
    totalCount: number;
}

export function QuestionMetadataBlockStatsFromJSON(json: any): QuestionMetadataBlockStats {
    return QuestionMetadataBlockStatsFromJSONTyped(json, false);
}

export function QuestionMetadataBlockStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionMetadataBlockStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newCount': json['new_count'],
        'otherCount': json['other_count'],
        'totalCount': json['total_count'],
    };
}

export function QuestionMetadataBlockStatsToJSON(value?: QuestionMetadataBlockStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_count': value.newCount,
        'other_count': value.otherCount,
        'total_count': value.totalCount,
    };
}


