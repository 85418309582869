import React from 'react';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import {
  ColumnPrimaryText,
  ColumnTitle,
  ColumnWrapper,
  ColumnCrossedText,
  ContentRow,
} from './CheckoutInput.styles';

export interface UnitPriceColumnType extends TranslatePropsType {
  unitPrice: number;
  fullPrice: number;
}

const UnitPriceColumn = ({ t, unitPrice, fullPrice }: UnitPriceColumnType) => (
  <ColumnWrapper>
    <ColumnTitle>{t('unitPrice') as string}</ColumnTitle>

    <ContentRow>
      {fullPrice !== unitPrice ? (
        <ColumnCrossedText>${fullPrice}</ColumnCrossedText>
      ) : null}

      <ColumnPrimaryText>${unitPrice}</ColumnPrimaryText>
    </ContentRow>
  </ColumnWrapper>
);

export default withTranslation('CreditsSelfCheckout')(UnitPriceColumn);
