import { RequestDirectionType } from '../../../common/common.types';
import { RequestsViewType } from '../requests.types';

export type RequestsSwitchViewFunctionType = (
  nextView: RequestsViewType,
) => void;

export interface RequestsSwitchViewActionType {
  type: '@requests/SWITCH_VIEW';
  payload: {
    view: RequestsViewType;
    direction: RequestDirectionType;
  };
}

export const switchView = (
  view: RequestsViewType,
  direction: RequestDirectionType,
): RequestsSwitchViewActionType => ({
  type: '@requests/SWITCH_VIEW',
  payload: {
    view,
    direction,
  },
});
