import styled, { css } from 'styled-components';

const selectedStyle = css`
  &:after {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.atlas.text.secondary};
    border-radius: 50%;
    width: 10px;
    height: 10px;
    left: 5px;
    top: 5px;
  }
`;

export const Label = styled.label<{ isSelected: boolean }>`
  display: block;
  padding-left: 17px;
  position: relative;
  color: ${({ isSelected, theme: { atlas } }) =>
    isSelected ? atlas.text.primary : atlas.grey[600]};
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    ${selectedStyle}
  }

  &:before {
    position: absolute;
    background: ${({ theme }) => theme.atlas.white};
    border: 2px solid ${({ theme }) => theme.atlas.text.secondary};
    border-radius: 50%;
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
  }

  ${({ isSelected }) => isSelected && selectedStyle}
`;

export const RadioInput = styled.input.attrs({
  type: 'radio',
})`
  opacity: 0;
`;
