import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 35px;
  top: -47px;

  > * + * {
    margin-left: 20px;
  }
`;

export const Control = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ theme, disabled }) =>
    disabled
      ? `
    > * {
      color: ${theme.basic.colors.grayLight}
      }`
      : `> ${Icon} {
        color: ${theme.basic.colors.teal};
      }`}
`;

export const Icon = styled(FontAwesomeIcon)`
  line-height: 20px;
  margin-bottom: 5px;
  ${({ theme }) => theme.fonts.regular.large}
`;

export const Name = styled.div`
  text-transform: capitalize;
  ${({ theme }) => theme.fonts.regular.micro}}
`;

export const Separator = styled.div`
  width: 1px;
  height: 35px;
  background: ${({ theme }) => theme.basic.colors.grayLight};
`;
