import { Uuid } from '../../../common/common.types';

export interface RemoveFlashActionType {
  type: '@flashes/REMOVE';
  payload: {
    id: Uuid;
  };
}

export const removeFlashAction = (id: Uuid): RemoveFlashActionType => ({
  type: '@flashes/REMOVE',
  payload: {
    id,
  },
});
