import { StandardBranchType } from '../../../common/common.types';

export interface SetFetchedStatusesActionType {
  type: '@standard/SET_FETCHED_STATUSES';
  payload: {
    statuses: StandardBranchType[];
  };
}

export const setFetchedStatuses = (
  statuses: StandardBranchType[],
): SetFetchedStatusesActionType => ({
  type: '@standard/SET_FETCHED_STATUSES',
  payload: { statuses },
});
