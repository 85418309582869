import styled from 'styled-components';

export const Category = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
`;

export const CategoryName = styled.div<{ newData: boolean }>`
  ${({ theme }) => theme.fonts.bold.small}
  border-bottom: 1px black solid;
  margin-bottom: 12px;
  color: ${({ theme, newData }) =>
    theme.basic.colors[newData ? 'error' : 'dark']}
  width: 160px;
  display: flex;
  padding-bottom: 6px;
`;

export const Item = styled.div<{ fullWidth?: boolean }>``;

export const Spacer = styled.div`
  width: 100%;
  height: 24px;
`;
