import { ApiCallActionType } from '../../store.types';
import { put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import i18n from '../../../i18n';

export type SystemTemplateUploadDefinitionsFunctionType = (
  params: SystemTemplateUploadDefinitionsCallParamsType,
) => void;

export interface SystemTemplateUploadDefinitionsCallParamsType {
  name: string;
  code: string;
  file: Blob | string;
  description: string;
  version: string;
  fileName: string;
}

export type SystemTemplateUploadDefinitionsActionType = ApiCallActionType<
  '@formDefinitions/API/SYSTEM_TEMPLATE_UPLOAD',
  SystemTemplateUploadDefinitionsCallParamsType
>;

export const systemTemplateUploadDefinitions = (
  params: SystemTemplateUploadDefinitionsCallParamsType,
): SystemTemplateUploadDefinitionsActionType => ({
  type: '@formDefinitions/API/SYSTEM_TEMPLATE_UPLOAD',
  payload: {
    endpointPath: ['definitions', 'system', 'upload'],
    callParams: params,
    selector: null,
    postActions: [
      () => put(flash(i18n.t('common:responses.systemTemplateSuccess'))),
    ],
  },
});
