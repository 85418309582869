import { css } from 'styled-components';
import atlas from './themes/atlas-palette';
import { NotificationEnum } from './common.types';
import { CompanyGradeEnum } from 'generated/models/Company';
import hex2rgba from '../utils/hex2rgba';

const colors = {
  basic: {
    black: '#000',
    dark: '#6e6e6e',
    error: '#f77c7c',
    errorBackground: css`rgba(247, 124, 124, 0.1)`,
    errorBackgroundSolid: '#fef2f2',
    errorHoverBackground: css`rgba(247, 124, 124, 0.05)`,
    gray: '#bcbcbc',
    grayDark: '#5d5d5d',
    grayLight: '#d0d0d0',
    gray200: '#333333',
    green: '#89c8cb',
    info: '#87c8cc',
    lightBlue: '#1fbcd2',
    lightBlueHover: css`rgba(31, 188, 210, 0.7)`,
    red: '#f57d7e',
    success: '#59d088',
    warning: '#ee8e24',
    white: '#fff',
    teal: atlas.teal.primary,
    orange: '#fcab10',
    dodgerBlue: '#0091FF',
  },
  elements: {
    button: {
      blue: {
        background: '#52b4e0',
        color: '#fff',
      },
      brightBlue: {
        background: '#00bcd4',
        color: '#fff',
      },
      green: {
        background: '#59d088',
        color: '#fff',
      },
      lightGreen: {
        background: '#70eca9',
        color: '#fff',
      },
      seaGreen: {
        background: '#6cabae',
        color: '#fff',
      },
      gray: {
        background: '#f6f6f6',
        color: '#000',
      },
      orange: {
        background: '#fcab10',
        color: atlas.white,
        hover: '#E0980E',
      },
      teal: {
        background: atlas.teal.primary,
        color: atlas.white,
        hover: atlas.teal.a100,
      },
      tealInverted: {
        color: atlas.teal.primary,
        background: atlas.white,
        hover: atlas.teal.primary,
      },
      transparent: {
        background: 'transparent',
        color: '#000',
      },
      white: {
        background: '#fff',
        color: '#2d2d2d',
      },
      light: {
        background: '#e7e9e9',
        color: '#000',
      },
      whiteBlue: {
        background: '#fff',
        color: '#375BA1',
      },
    },
    completion: {
      incomplete: '#f77c7c',
      finished: '#87c8cc',
    },
    form: {
      required: '#e98380',
    },
    framework: {
      system: {
        color: '#69abaf',
      },
      custom: {
        color: '#6b88c9',
      },
    },
    header: {
      background: '#dbdee0',
      stripe: '#edefef',
    },
    layout: {
      headerAndFooter: '#222325',
    },
    loader: '#52bbbf',
    ribbon: {
      attached: {
        back: '#c9a522',
        front: '#e4bc2a',
      },
      empty: {
        back: '#e2e2d0',
        front: '#eeeedf',
      },
    },
    companiesVisualisation: {
      background: '#ffffff',
      inactiveBorder: '#737373',
    },
    checkbox: {
      questionnaire: {
        active: {
          background: '#d0d0d0',
          text: '#ffffff',
        },
        selected: {
          background: '#87C8CC',
          text: '#ffffff',
        },
      },
    },
    text: {
      action: {
        color: '#cccccc',
        hover: '#999999',
      },
      enabled: {
        background: '#ffffff',
        border: '#cccccc',
        borderHover: '#b3b3b3',
        color: atlas.text.secondary,
        placeholder: '#999999',
      },
      filled: {
        background: atlas.grey['50'],
        borderHover: '#e6e6e6',
      },
      disabled: {
        background: '#f2f2f2',
        border: '#e6e6e6',
        color: '#999999',
        placeholder: hex2rgba('#6E6E6E', 0.4),
      },
      disabledFilled: {
        background: atlas.grey['100'],
        border: atlas.grey['100'],
      },
    },
    textarea: {
      border: '#5d5d5d',
      placeholder: '#bbbbbb',
    },
    requests: {
      accepted: {
        color: 'green',
        borderColor: '#53a688',
      },
      closed: {
        color: 'green',
        borderColor: '#53a688',
      },
      created: {
        color: 'yellow',
        borderColor: '#e7c75c',
      },
      inProgress: {
        color: 'seaGreen',
        borderColor: '#69abaf',
      },
      reviewReady: {
        color: 'blue',
        borderColor: '#6b88c9',
      },
    },
    notifications: {
      [NotificationEnum.ALMOST_OUT]: '#DAB21E',
      [NotificationEnum.GRANTED]: '#549BA1',
      [NotificationEnum.NO_MORE_CREDITS]: '#E83D59',
      [NotificationEnum.WELCOME]: '#549BA1',
      [NotificationEnum.WILL_EXPIRE]: '#DAB21E',
      [NotificationEnum.DISMISS_OPT_IN]: '#509BA0',
    },
    score: {
      start: '#F58080',
      end: '#8AC9CD',
    },
  },
  grades: {
    [CompanyGradeEnum.A]: '#4fb820',
    [CompanyGradeEnum.B]: '#e4bc2a',
    [CompanyGradeEnum.C]: '#ee8e24',
    [CompanyGradeEnum.D]: '#ee4529',
    [CompanyGradeEnum.F]: '#b20610',
    [CompanyGradeEnum.Empty]: '#dedfe0',
  },
  requestDueDates: {
    okay: '#87c8cc',
    close: '#a7b4b6',
    almost: '#b5a9aa',
    notice: '#c59c9c',
    warning: '#d39191',
    danger: '#f77c7c',
  },
};

export default colors;
