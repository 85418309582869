import { PricingType } from 'common/common.types';
import { PriceInfo } from 'generated/models';

export const parsePricing = (pricing: PriceInfo): PricingType => {
  const {
    label = '',
    price = 0,
    discount_percentage = 0,
    min_quantity = 0,
  } = pricing;

  return {
    label,
    price,
    discount: discount_percentage,
    minQuantity: min_quantity,
  };
};
