import styled from 'styled-components';
import { getStatusColorStyle } from 'utils/statusColor';

type WrapperType = {
  color: string;
};

export const Wrapper = styled.div<WrapperType>`
  ${({ color }) => getStatusColorStyle(color)};
  top: 15px;
  right: 20px;
  color: ${({ theme }) => theme.basic.colors.light};
  height: 24px;
  padding: 0 7px;
  position: absolute;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  line-height: 1.7;
  border-radius: 23px;
`;
