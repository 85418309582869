import History from './History';
import React, { useState } from 'react';
import { Button, Heading } from '../Upsell.styles';
import { CompaniesAddCreditsFunctionType } from 'store/companies/actions';
import { Form, Hint, Input, Wrapper } from './CreditsManagement.styles';
import { withTranslation } from 'react-i18next';
import {
  CreditsHistoryItemType,
  LanguageType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';

interface CreditsManagementPropsType extends TranslatePropsType {
  addCredits: CompaniesAddCreditsFunctionType;
  companyId: Uuid;
  history: CreditsHistoryItemType[];
  initialState?: string;
  language: LanguageType;
}

const CreditsManagement = ({
  addCredits,
  companyId,
  history,
  initialState = '',
  language,
  t,
}: CreditsManagementPropsType) => {
  const [value, setValue] = useState(initialState);

  const handleSubmit = () => {
    addCredits(Number(value), companyId);
    setValue('');
  };

  return (
    <Wrapper>
      <Heading>{t('quantity') as string}</Heading>

      <Form>
        <Input
          placeholder={t('placeholder') as string}
          value={value}
          onChange={e => setValue(e.target.value)}
        />

        <Button onClick={handleSubmit} disabled={value === ''}>
          {t('add') as string}
        </Button>
      </Form>

      <Hint>{t('hint') as string}</Hint>

      <History history={history} language={language} />
    </Wrapper>
  );
};

export default withTranslation('CreditsManagement')(CreditsManagement);
