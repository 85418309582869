import { Condition } from 'common/common.types';
import { QuestionError } from 'store/formCreator/validationError.types';
import { QuestionDefinitionEntityErrorCodeEnum } from 'generated/models';

export const getConditionsCount = (condition?: Condition): number => {
  if (condition) {
    if (condition.type === 'text') {
      return condition.payload.length > 0 ? 1 : 0;
    }
    if (condition.type === 'structured') {
      return condition.payload.items.length;
    }
  }
  return 0;
};

export const groupQuestionErrors = (errors: QuestionError[]) => {
  const conditionErrors: QuestionError[] = [];
  const otherErrors: QuestionError[] = [];

  errors.forEach(error => {
    switch (error.code) {
      case QuestionDefinitionEntityErrorCodeEnum.CONDITIONREFERENCEINVALIDQUESTION:
      case QuestionDefinitionEntityErrorCodeEnum.CONDITIONREFERENCELOWER:
      case QuestionDefinitionEntityErrorCodeEnum.CONDITIONREFERENCENOTFOUND:
      case QuestionDefinitionEntityErrorCodeEnum.CONDITIONREFERENCESELF:
      case QuestionDefinitionEntityErrorCodeEnum.CONDITIONSTRUCTUREDWRONGFORMAT:
      case QuestionDefinitionEntityErrorCodeEnum.CONDITIONSYNTAXERROR:
      case QuestionDefinitionEntityErrorCodeEnum.CONDITIONUUIDNOTFOUND:
      case QuestionDefinitionEntityErrorCodeEnum.CONDITIONWRONGVALUE:
        conditionErrors.push(error);
        break;
      default:
        otherErrors.push(error);
        break;
    }
  });

  return [conditionErrors, otherErrors];
};
