/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { FormShortSummary } from "./FormShortSummary";
// @ts-ignore
import { QuestionDetailBase } from "./QuestionDetailBase";

/**
 * 
 * @export
 * @interface ResponsePropagationInstruction
 */
export interface ResponsePropagationInstruction {
    /**
     * 
     * @type {Array<FormShortSummary>}
     * @memberof ResponsePropagationInstruction
     */
    forms: Array<FormShortSummary>;
    /**
     * 
     * @type {number}
     * @memberof ResponsePropagationInstruction
     */
    match: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePropagationInstruction
     */
    match_code: ResponsePropagationInstructionMatchCodeEnum;
    /**
     * 
     * @type {QuestionDetailBase}
     * @memberof ResponsePropagationInstruction
     */
    question?: QuestionDetailBase;
    /**
     * 
     * @type {string}
     * @memberof ResponsePropagationInstruction
     */
    source_id: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePropagationInstruction
     */
    target_id: string;
    /**
     * 
     * @type {Date}
     * @memberof ResponsePropagationInstruction
     */
    updated_at: Date;
    /**
     * 
     * @type {string}
     * @memberof ResponsePropagationInstruction
     */
    value: string;
}




/**
* @export
* @enum {string}
*/
export enum ResponsePropagationInstructionMatchCodeEnum {
    FULL = 'FULL',
    PARTIAL = 'PARTIAL',
    NONE = 'NONE'
}


