import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { getStatusColor } from 'utils/statusColor';

type WrapperType = {
  color: string;
  isVisible?: boolean;
};

export const Wrapper = styled.div<WrapperType>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  flex: 1 0 200px;
  padding: 15px 20px;
  position: relative;
  box-shadow: 0 0 10px rgba(37, 38, 94, 0.1);
  border-left: 4px solid;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.basic.colors.light};
  border-color: ${({ color }) => getStatusColor(color)};
`;

export const Company = styled.div`
  padding: 0 40px 30px 0;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  word-break: break-all;
`;

export const Link = styled(RouterLink)`
  ${({ theme }) => theme.fonts.semibold.small}
  text-decoration: underline;
  text-transform: uppercase;
  margin-bottom: 2px;
  transition: ${({ theme }) => theme.basic.animations.normal};

  &:hover {
    opacity: 0.8;
  }
`;

export const Year = styled.div`
  color: ${({ theme }) => theme.request.subinfo};
  font-size: ${({ theme }) => theme.basic.fontSizes.micro};
  margin-bottom: 15px;
`;
