import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 340px;
  width: 100%;
  margin-bottom: 24px;
`;

export const TagFilterContainer = styled.div`
  flex: 1;
`;

export const VisualisationContainer = styled.div`
  flex: 4;
`;
