import i18n from '../../../i18n';
import { FormsModifyRequestCallParamsType } from './putRequest';
import { assocPath } from 'ramda';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { trackEvent } from 'utils/analytics';
import { RequestDetail } from '../../../generated/models/RequestDetail';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { RequestVrmViewStatusEnum, Uuid } from '../../../common/common.types';

export type FormsUpdateVrmViewStatusFunctionType = (
  requestId: Uuid,
  viewStatus: RequestVrmViewStatusEnum,
) => void;

export type FormsUpdateVrmViewStatusActionType = OptimisticActionType<
  '@forms/API/UPDATE_VRM_VIEW_STATUS',
  ApiCallActionPayloadType<
    FormsModifyRequestCallParamsType,
    RequestDetail,
    never,
    FormsStateType
  >
>;

export const updateVrmViewStatus = (
  requestId: Uuid,
  viewStatus: RequestVrmViewStatusEnum,
): FormsUpdateVrmViewStatusActionType => ({
  type: '@forms/API/UPDATE_VRM_VIEW_STATUS',
  payload: {
    callParams: {
      id: requestId,
      data: { vrm_view_status: viewStatus },
    },
    endpointPath: ['requests', 'modify'],
    expectedStateChange: {
      'forms.currentForm.request.vrmViewStatus': viewStatus,
    },
    selector: (data, state) =>
      assocPath<RequestVrmViewStatusEnum, FormsStateType>(
        ['currentForm', 'request', 'vrmViewStatus'],
        viewStatus,
      )(state.forms),
    postActions: [
      () => call(() => trackEvent('Request', 'Updated VRM status code')),
      ({ vrm_view_status }: RequestDetail) =>
        put(flash(i18n.t(`ViewStatus:responses.${vrm_view_status}`))),
    ],
  },
});
