import uuid from 'utils/uuid';
import { CategoryDelegationStats } from '../../generated/models/CategoryDelegationStats';
import {
  CategoryType,
  IdOrEmailType,
  QuestionTabEnum,
  UploadStatusEnum,
  UploadType,
  Uuid,
} from '../../common/common.types';
import { MappingRequest } from '../../generated/models/MappingRequest';
import { ParsingMessageSeverityEnum } from '../../generated/models/ParsingMessage';
import { StateType } from '../store.types';
import { VRMReviewStats } from '../../generated/models/VRMReviewStats';
import { assocPath, pathOr, prop } from 'ramda';
import { parseParsingMessage } from 'utils/parsers';
import {
  parseCategoryDelegation,
  parseCategoryReviewStatus,
} from 'utils/parsers/categories.parsers';
import { FormsStateType } from './forms.reducer';
import { UnreachableCaseError } from 'utils/errors';
import { DEFAULT_QUESTION_BLOCKS } from '../../common/common.constants';
import { FormsSimpleFiltersType } from 'utils/filters/forms.filters';

const {
  ACCEPTED,
  FINISHED,
  PARSING,
  PARSING_FAILED,
  PARSING_EMPTY_RESULT,
  MAPPING,
} = UploadStatusEnum;

const { ERROR } = ParsingMessageSeverityEnum;

export const remapCategories = (
  stats: CategoryDelegationStats | undefined,
  categories: CategoryType[],
) => {
  if (!stats) {
    return categories;
  }

  return categories.map(category => {
    if (category.name === stats.category) {
      return {
        ...category,
        delegation: parseCategoryDelegation(stats),
      };
    }

    return category;
  });
};

export const remapReviewedCategories = (
  stats: VRMReviewStats | undefined,
  categories: CategoryType[],
) => {
  if (!stats) {
    return categories;
  }

  return categories.map(category => {
    if (category.name === stats.category) {
      return {
        ...category,
        reviewStatus: parseCategoryReviewStatus(stats),
      };
    }

    return category;
  });
};

export const getAssigneeKey = (assignee: IdOrEmailType) => {
  switch (assignee.type) {
    case 'id':
      return 'assignee_id';
    case 'email':
      return 'assignee_email';
    default:
      throw new UnreachableCaseError(assignee.payload);
  }
};

export const isAssigneeKeyEmail = (assignee: IdOrEmailType) =>
  getAssigneeKey(assignee) === 'assignee_email';

export const updateUploadsState = (request: MappingRequest, state: StateType) =>
  assocPath<UploadType[], FormsStateType>(
    ['uploads'],
    [...pathOr([], ['uploads'], state.forms), pushMappingFile(request)],
  )(state.forms);

export const pushMappingFile = (request: MappingRequest): UploadType => ({
  fileName: request.parsed_files[0].file_name,
  formId: request.result_form_id || null,
  hasError: false,
  id: request.id,
  isUploading: true,
  questionsCount: 0,
  mapping: null,
  parsing: {
    hasError: false,
    parsingMessages: [],
  },
  status: request.status,
});

export const updateUploadsStateError = (formData: FormData) => (
  data: any,
  state: StateType,
) =>
  assocPath<UploadType[], FormsStateType>(
    ['uploads'],
    [
      ...pathOr([], ['uploads'], state.forms),
      {
        fileName: prop('name')(formData.get('file') as File),
        formId: null,
        hasError: true,
        id: uuid(),
        isUploading: false,
        questionsCount: 0,
        mapping: null,
        parsing: {
          hasError: true,
          parsingMessages: [
            {
              id: 0,
              severity: ParsingMessageSeverityEnum.ERROR,
              message: 'Unexpected error',
              messageKey: 0,
            },
          ],
        },
      },
    ],
  )(state.forms);

export const updateUploadStatus = (id: Uuid) => (
  data: MappingRequest,
  state: StateType,
) => {
  const {
    mapping_stats,
    parsed_files,
    parsing_stats,
    result_form_id,
    status,
  } = data;

  return assocPath(
    ['uploads'],
    state.forms.uploads.map(
      (upload): UploadType => {
        if (upload.id !== id) {
          return upload;
        }

        const { parsing_messages: messages } = parsed_files[0];

        const hasError =
          messages.filter(({ severity }) => severity === ERROR).length > 0;

        const parsing = {
          hasError,
          parsingMessages: messages.map(message =>
            parseParsingMessage(message),
          ),
        };

        switch (status) {
          case ACCEPTED:
          case PARSING:
            return { ...upload, formId: null, isUploading: false, status };

          case PARSING_FAILED:
          case PARSING_EMPTY_RESULT:
            return {
              ...upload,
              isUploading: false,
              hasError: true,
              questionsCount: 0,
              mapping: {
                ...upload.mapping,
                isMapping: false,
                questionsUpdated: 0,
              },
              parsing,
              status,
            };

          case MAPPING:
            return {
              ...upload,
              isUploading: false,
              questionsCount: parsing_stats!.questions_filled || 0,
              mapping: {
                ...upload.mapping,
                isMapping: true,
              },
              status,
            };

          case FINISHED:
            return {
              ...upload,
              formId: result_form_id || null,
              isUploading: false,
              questionsCount: parsing_stats!.questions_filled || 0,
              mapping: {
                ...upload.mapping,
                isMapping: false,
                questionsUpdated: mapping_stats!.questions_updated || 0,
                updatedQuestionsIds: data.updated_questions_ids,
              },
              parsing,
              status,
            };

          default:
            return {
              ...upload,
              status,
            };
        }
      },
    ),
    state.forms,
  );
};

export const containsTab = (filter: string): QuestionTabEnum | null => {
  for (const tab of DEFAULT_QUESTION_BLOCKS) {
    if (filter.startsWith(tab)) {
      return tab;
    }
  }
  return null;
};

export const getPreferredTab = (
  simpleFilters: FormsSimpleFiltersType,
  isVRM: boolean,
): QuestionTabEnum => {
  const possibleTabs = Object.keys(simpleFilters).filter(
    simple => simpleFilters[simple].isEnabled && containsTab(simple) !== null,
  );

  if (possibleTabs.length > 0) {
    return containsTab(possibleTabs[0])!; // cannot be null due filter above
  }

  return isVRM && simpleFilters.ssc_data.count > 0
    ? QuestionTabEnum.SSC_DATA
    : QuestionTabEnum.ATTACHMENTS;
};
