import React from 'react';
import Response from '../Response/Response';
import { FormCreatorValidatedResponseType, Uuid } from 'common/common.types';
import { FormCreatorContextActionsType } from 'pages/FormCreatorPage';
import { ResponseList } from './ResponsePanel.styles';

interface ResponsePanelPropsType {
  actions: FormCreatorContextActionsType;
  hasConditionsToggle: boolean;
  onAddNewResponse: () => void;
  questionId: Uuid;
  responses: FormCreatorValidatedResponseType[];
}

const ResponsePanel = ({
  actions,
  hasConditionsToggle,
  onAddNewResponse,
  questionId,
  responses,
}: ResponsePanelPropsType) => (
  <ResponseList hasConditionsToggle={hasConditionsToggle}>
    {responses.map((response, i) => (
      <Response
        key={response.uniqueId}
        actions={actions}
        addSecondary={i === 0 ? onAddNewResponse : undefined}
        isDeletable={i > 0}
        hasConditionsToggle={hasConditionsToggle}
        questionId={questionId}
        response={response}
      />
    ))}
  </ResponseList>
);

export default ResponsePanel;
