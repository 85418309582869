import styled from 'styled-components';
import { TileTypeEnum } from '../FormOutline/FormOutline';
import { numberFromPixels } from 'utils/multiLineEllipsis';
import { ValidationStatus } from '../FormOutline/FormOutline.types';

const WIDTH = 240;
export const TEXT_LENGTH = 55;

interface WrapperPropsType {
  type: TileTypeEnum;
  validationStatus: ValidationStatus;
}

export const RectsIcon = styled.span<{ validationStatus: ValidationStatus }>`
  margin-left: 6px;
  margin-top: 3px;
  opacity: 0;
  color: ${({ theme, validationStatus }) =>
    validationStatus === 'normal'
      ? theme.atlas.teal[400]
      : theme.atlas.grey[400]};
`;

export const ArrowIcon = styled.span<{ open: boolean | undefined }>`
  margin-left: 12px;
  margin-right: 12px;
  transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(-90deg)')};
  transition: transform 0.3s;
  color: ${({ theme }) => theme.atlas.text.secondary};
`;

export const Wrapper = styled.div<WrapperPropsType>`
  width: ${WIDTH}px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  background: ${({ type, theme, validationStatus }) => {
    if (validationStatus === 'invalid') {
      return theme.basic.colors.errorBackground;
    } else if (validationStatus === 'fixAttempted') {
      return theme.atlas.blue[100];
    } else if (type === TileTypeEnum.CATEGORY) {
      return theme.atlas.white;
    } else {
      return 'rgba(0, 0, 0, 0)';
    }
  }};

  &:hover {
    border: 1px solid
      ${({ theme, validationStatus }) => {
        switch (validationStatus) {
          case 'invalid':
            return theme.basic.colors.error;
          case 'fixAttempted':
            return theme.atlas.blue[600];
          default:
            return theme.atlas.teal[400];
        }
      }};
    background: ${({ type, theme, validationStatus }) => {
      if (validationStatus === 'invalid') {
        return theme.basic.colors.errorHoverBackground;
      } else if (validationStatus === 'fixAttempted') {
        return theme.atlas.blue[100];
      } else if (type === TileTypeEnum.CATEGORY) {
        return theme.atlas.teal[100];
      } else {
        return theme.atlas.white;
      }
    }};

    & ${RectsIcon} {
      opacity: 1;
    }
  }
`;

const QUESTION_TEXT_LINE_HEIGHT = '17px';

export const QuestionCode = styled.span`
  ${({ theme }) => theme.basic.fontWeights.bold}
`;

export const QuestionText = styled.p`
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  color: ${({ theme }) => theme.atlas.text.primary};
  width: ${WIDTH - 45}px;
  margin-left: 9px;
  overflow: hidden;
  text-align: left;
  line-height: ${QUESTION_TEXT_LINE_HEIGHT};
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  max-height: ${numberFromPixels(QUESTION_TEXT_LINE_HEIGHT) * 2}px;
`;
