import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin: -10px;
`;

export const CategoryWrapper = styled.div`
  & + & {
    margin-top: 46px;
  }
`;

export const CategoryName = styled.div<{ hasItems: boolean }>`
  padding: 0 0 10px 20px;
  display: block;
  position: relative;
  margin-bottom: 16px;
  opacity: ${({ hasItems }) => (hasItems ? 1 : 0.3)};
  ${({ theme }) => theme.fonts.bold.large}
`;

export const UploadSection = styled.div`
  margin: -30px 0 30px 0;
  background: white;
  padding: 30px;
`;
