/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProxyShortSummary
 */
export interface ProxyShortSummary {
    /**
     * 
     * @type {string}
     * @memberof ProxyShortSummary
     */
    formId: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyShortSummary
     */
    formRole: ProxyShortSummaryFormRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ProxyShortSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyShortSummary
     */
    status: ProxyShortSummaryStatusEnum;
}

export function ProxyShortSummaryFromJSON(json: any): ProxyShortSummary {
    return ProxyShortSummaryFromJSONTyped(json, false);
}

export function ProxyShortSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProxyShortSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formId': json['form_id'],
        'formRole': json['form_role'],
        'id': json['id'],
        'status': json['status'],
    };
}

export function ProxyShortSummaryToJSON(value?: ProxyShortSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_id': value.formId,
        'form_role': value.formRole,
        'id': value.id,
        'status': value.status,
    };
}

/**
* @export
* @enum {string}
*/
export enum ProxyShortSummaryFormRoleEnum {
    OWNER = 'OWNER',
    VENDOR = 'VENDOR',
    VENDORPROXY = 'VENDOR_PROXY',
    VRM = 'VRM',
    VRMPROXY = 'VRM_PROXY',
    OTHER = 'OTHER'
}
/**
* @export
* @enum {string}
*/
export enum ProxyShortSummaryStatusEnum {
    ACTIVE = 'ACTIVE',
    REVOKED = 'REVOKED'
}


