/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface FormDelegationNotify
 */
export interface FormDelegationNotify {
    /**
     * Direction in which delegation notification is sent
     * 
     * - `forward` - Assignor notifies assignee: "Please fill these questions."
     * - `backward` - Assignee notifies assignor: "These questions are now filled."
     * @type {string}
     * @memberof FormDelegationNotify
     */
    direction: FormDelegationNotifyDirectionEnum;
}




/**
* @export
* @enum {string}
*/
export enum FormDelegationNotifyDirectionEnum {
    Backward = 'backward',
    Forward = 'forward'
}


