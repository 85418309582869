import { filter, sort, reduce, uniqBy } from 'ramda';
import {
  AverageChartRecordType,
  RequestSimpleType,
  ValueLabelType,
} from 'common/common.types';
import { RequestsFilterType } from 'store/requests/requests.types';
import {
  FilterOptionsType,
  FilterDateIntervalType,
} from 'utils/filters/requests.filters';
import { DATE_FORMAT } from 'common/common.constants';
import { format, subDays } from 'date-fns';
import { TFunction } from 'i18next';

export const getBarWidth = (
  maxCount: number,
  count: number,
  maxWidth: number,
) =>
  Math.min(
    !maxCount || !count ? 0 : (count / maxCount) * (maxWidth || 100),
    maxWidth,
  );

export const averageValidationScore = (
  requests: RequestSimpleType[],
): AverageChartRecordType =>
  reduce(
    (
      acc: AverageChartRecordType,
      { target: { id }, form: { isScoreVisible, score } }: RequestSimpleType,
    ) => {
      acc.companies.add(id);

      if (isScoreVisible) {
        acc.count += 1;
        acc.maximum = Math.max(acc.maximum, score);
        acc.sum += score;
      }
      return acc;
    },
    {
      companies: new Set(),
      count: 0,
      maximum: 0,
      sum: 0,
      total: requests.length,
    },
    requests,
  );

export const sortByValidationScore = (
  requests: RequestSimpleType[],
): RequestSimpleType[] =>
  sort((a, b) => {
    if (a.form.isScoreVisible && b.form.isScoreVisible) {
      return b.form.score - a.form.score;
    }
    return b.form.isScoreVisible ? b.form.score : -1;
  }, requests);

export const averageSscGrade = (
  requests: RequestSimpleType[],
): AverageChartRecordType =>
  reduce(
    (
      acc: AverageChartRecordType,
      { target: { id, score } }: RequestSimpleType,
    ) => {
      acc.companies.add(id);

      if (score) {
        acc.count += 1;
        acc.maximum = Math.max(acc.maximum, score);
        acc.sum += score;
      }
      return acc;
    },
    {
      companies: new Set(),
      count: 0,
      maximum: 0,
      sum: 0,
      total: requests.length,
    },
    requests,
  );

export const sortBySscGrade = (
  requests: RequestSimpleType[],
): RequestSimpleType[] => {
  return sort(
    (a, b) => {
      const compared = b.target.score - a.target.score;
      return compared !== 0
        ? compared
        : a.target.name.localeCompare(b.target.name);
    },
    uniqBy(a => a.target.id, requests),
  );
};

export const averageCompletionTime = (
  requests: RequestSimpleType[],
): AverageChartRecordType =>
  reduce(
    (
      acc: AverageChartRecordType,
      { completionDays, target: { id } }: RequestSimpleType,
    ) => {
      acc.companies.add(id);

      if (completionDays !== null) {
        acc.count += 1;
        acc.maximum = Math.max(acc.maximum, completionDays);
        acc.sum += completionDays;
      }
      return acc;
    },
    {
      companies: new Set(),
      count: 0,
      maximum: 0,
      sum: 0,
      total: requests.length,
    },
    requests,
  );

export const sortByCompletionTime = (
  requests: RequestSimpleType[],
): RequestSimpleType[] =>
  sort(
    (a: RequestSimpleType, b: RequestSimpleType) => {
      const compared = a.completionDays! - b.completionDays!;
      return compared !== 0
        ? compared
        : a.target.name.localeCompare(b.target.name);
    },
    filter(
      (request: RequestSimpleType) => request.completionDays !== null,
      requests,
    ),
  );

export const getFiltersDescription = (
  { filterBy }: RequestsFilterType,
  filterOptions: FilterOptionsType,
  language: { label: string; value: string },
  t?: TFunction,
): Array<{ key?: string; value?: string }> => {
  const ommitedKey = 'DATE';
  const result = Object.keys(filterBy)
    .filter(key => key !== ommitedKey)
    .map(filterName => {
      if (filterBy[filterName].isEnabled && filterBy[filterName].lookFor) {
        const values = filterBy[filterName].lookFor.map((lookValue: string) => {
          return filterOptions[filterName].find(
            (item: ValueLabelType<string>) => item.value === lookValue,
          );
        });
        if (!values.some((val: ValueLabelType<string>) => val === undefined)) {
          if (filterName === 'STATUS') {
            return {
              key: filterName.toLowerCase(),
              value: values
                .map((val: ValueLabelType<string>) => t && t(val.label))
                .join(', '),
            };
          }
          return {
            key: filterName.toLowerCase(),
            value: values
              .map((val: ValueLabelType<string>) => val.label)
              .join(', '),
          };
        }
        return {};
      }
      return {};
    });
  if (filterBy[ommitedKey].isEnabled) {
    const dateTo = format(new Date(), DATE_FORMAT);
    let resultDate: string[] = [];
    switch (filterBy[ommitedKey].interval) {
      case FilterDateIntervalType.LAST7DAYS:
        resultDate = [format(subDays(new Date(), 7), DATE_FORMAT), dateTo];
        break;
      case FilterDateIntervalType.LAST30DAYS:
        resultDate = [format(subDays(new Date(), 30), DATE_FORMAT), dateTo];
        break;
      case FilterDateIntervalType.CUSTOM:
        const from = filterBy[ommitedKey].from
          ? format(new Date(filterBy[ommitedKey].from!), DATE_FORMAT)
          : undefined;
        const to = filterBy[ommitedKey].to
          ? format(new Date(filterBy[ommitedKey].to!), DATE_FORMAT)
          : undefined;
        if (from || to) {
          if (!to) {
            resultDate = t ? [t('from', { from })] : [];
          } else if (!from) {
            resultDate = t ? [t('to', { to })] : [];
          } else {
            resultDate = [from, to];
          }
        }
        break;
    }
    result.push({
      key: ommitedKey.toLowerCase(),
      value: resultDate.join(' - '),
    });
  }
  return result.filter(value => Object.keys(value).length > 0);
};
