import { SwitchViewActionType } from './actions/switchView';

export interface SwitchersStateType {
  currentSwitcherOption: string;
}

const initialState = {
  currentSwitcherOption: '',
};

const switchers = (
  state = initialState,
  action: SwitchViewActionType,
): SwitchersStateType => {
  if (action.type === '@switchers/SWITCH_VIEW') {
    return {
      ...state,
      currentSwitcherOption: action.payload.view,
    };
  }

  return state;
};

export default switchers;
