import { captureException, init, setUser, withScope } from '@sentry/browser';
import config from '../config';
import { UserInfo } from 'generated/models/UserInfo';

export const setupSentry = () => {
  const hostname = config.hostname && new RegExp(config.hostname, 'i');

  init({
    beforeBreadcrumb: hostname
      ? breadcrumb => {
          const { data } = breadcrumb;
          if (data && data.url) {
            return hostname.test(data.url) ? breadcrumb : null;
          }
          return breadcrumb;
        }
      : undefined,
    dsn: config.sentryDsn,
    enabled: !!config.sentryDsn,
    environment: config.env,
    whitelistUrls: hostname ? [hostname] : undefined,
  });
};

export const setSentryUser = ({ user: { id, email } }: UserInfo) => {
  setUser({
    id: `${id}`,
    email,
  });
};

export const removeSentryUser = () => {
  setUser(null);
};

export const captureError = (error: any, errorInfo: any = {}) => {
  withScope(scope => {
    scope.setExtras(errorInfo);
    captureException(error);
  });
};
