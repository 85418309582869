/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";
// @ts-ignore
import { EvidenceLockerEntity } from "./EvidenceLockerEntity";

/**
 * 
 * @export
 * @interface EvidenceLockerActivityLogsSummary
 */
export interface EvidenceLockerActivityLogsSummary {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    updated_at: Date;
    /**
     * Type of the activity
     * @type {string}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    activity_type: EvidenceLockerActivityLogsSummaryActivityTypeEnum;
    /**
     * Evidence locker entity associated with this activity log
     * @type {EvidenceLockerEntity}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    evidence_locker_entity: EvidenceLockerEntity;
    /**
     * ID of the evidence locker activity log
     * @type {string}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    id: string;
    /**
     * IP address of the user's machine who performed the activity
     * @type {string}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    ip_address: string;
    /**
     * Owner domain of the evidence locker entity
     * @type {string}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    owner_domain: string;
    /**
     * User domain of the person who triggered this activity
     * @type {string}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    user_domain: string;
    /**
     * User email of the person who triggered this activity
     * @type {string}
     * @memberof EvidenceLockerActivityLogsSummary
     */
    user_email: string;
}




/**
* @export
* @enum {string}
*/
export enum EvidenceLockerActivityLogsSummaryActivityTypeEnum {
    DELETED = 'DELETED',
    DOWNLOADED = 'DOWNLOADED',
    IMPORTED = 'IMPORTED',
    MODIFIED = 'MODIFIED',
    VIEWED = 'VIEWED'
}


