import styled from 'styled-components/macro';
import { Flex } from 'common/common.styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const Controls = styled.div`
  display: flex;
  padding-right: 20px;

  > * + * {
    margin-left: 10px;
  }

  > *:last-child {
    margin-left: 26px;
  }
`;

export const TitleWrapper = styled(Flex)`
  flex-direction: column;

  p {
    margin-top: 5px;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PlusIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faPlus as IconProp,
}))`
  font-size: ${({ theme }) => theme.basic.fontSizes.normal};
  margin-right: 10px;
  margin-bottom: 2px;
`;

export const BetaSignWrapper = styled.div`
  position: absolute;
  top: -10px;
  left: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterControls = styled.div`
  background-color: ${({ theme }) => theme.basic.colors.light};
  display: flex;
  justify-content: space-between;
`;

export const FilterBar = styled.div`
  background-color: ${({ theme }) => theme.basic.colors.light};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 25px 10px;
`;
