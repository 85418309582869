import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const [PDF, PNG] = ['pdf', 'png'];
// Downloading image file types needs adding a dummy element in the dom
// and then deleting it. Have excluded other file types, intentionally
const ALLOWED_FILE_TYPES = [PDF];

export const exportElement = (
  elementID: string,
  fileName?: string,
  fileType?: string,
) => {
  fileName = fileName || `download-${Date.parse(Date()) / 1000}`;
  fileType = fileType || PDF;
  const input = document.getElementById(elementID);

  if (input && ALLOWED_FILE_TYPES.includes(fileType)) {
    html2canvas(input).then(canvas => {
      const pdf = new jsPDF({ unit: 'px', format: 'a4' });
      const imgData = canvas.toDataURL('image/png');

      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      const widthRatio = width / canvas.width;
      const heightRatio = height / canvas.height;

      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
      pdf.addImage(
        imgData,
        PNG.toUpperCase(),
        0,
        0,
        canvas.width * ratio,
        canvas.height * ratio,
      );

      pdf.save(fileName);
    });
  }
};
