/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";

/**
 * 
 * @export
 * @interface Standard
 */
export interface Standard {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof Standard
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof Standard
     */
    updated_at: Date;
    /**
     * A unique code that identifies the specific standard
     * @type {string}
     * @memberof Standard
     */
    code: string;
    /**
     * ID of form definition
     * @type {string}
     * @memberof Standard
     */
    definition_id?: string;
    /**
     * Description field - sometimes used to supply additinal long name of the standard that
     * is too long for listings
     * @type {string}
     * @memberof Standard
     */
    description: string;
    /**
     * True if standard has conditions
     * @type {boolean}
     * @memberof Standard
     */
    has_conditions?: boolean;
    /**
     * True if standard has custom scoring
     * @type {boolean}
     * @memberof Standard
     */
    has_custom_scoring?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Standard
     */
    id: string;
    /**
     * True if standard could be duplicated
     * @type {boolean}
     * @memberof Standard
     */
    is_duplicable?: boolean;
    /**
     * True if standard could be unpublished (switched back to `DRAFT` status)
     * @type {boolean}
     * @memberof Standard
     */
    is_unpublishable?: boolean;
    /**
     * When was the standard sent last time
     * @type {Date}
     * @memberof Standard
     */
    last_sent_at?: Date;
    /**
     * Identifier of an Atlas built-in standard logo
     * @type {string}
     * @memberof Standard
     */
    logo: string;
    /**
     * Visible name of the standard, usually without version
     * @type {string}
     * @memberof Standard
     */
    name: string;
    /**
     * Owner's company ID
     * @type {string}
     * @memberof Standard
     */
    owner_id: string;
    /**
     * ID of my own preview form (if available)
     * @type {string}
     * @memberof Standard
     */
    preview_form_id?: string;
    /**
     * Should the standard be shown in evidence locker page?
     * @type {boolean}
     * @memberof Standard
     */
    show_on_hp: boolean;
    /**
     * Status of the standard:
     * 
     * - `SYSTEM` standards are accessible to all companies.
     *     They should be owned by Company with internal_status = SYSTEM.
     * - `CUSTOM` standards are created by individual companies.
     *     Only owner of the standard can send request for this standard.
     * - `TEMPORARY` standard is created when user uploads new form definition.
     *     When it is confirmed by the user it becomes CUSTOM standard.
     *     Standards of this type are not shown anywhere and requests for this type cannot
     *     be sent.
     * - `DEPRECATED` standard is a deleted standard. Standards of this type are not
     *     shown anywhere and requests for this type cannot be sent.
     * - `DRAFT` standard is a standard whose form definition is currently being created
     *     in the form creator. Request from this standard cannot be (yet) sent.
     * @type {string}
     * @memberof Standard
     */
    status: StandardStatusEnum;
    /**
     * Version of the standard displayed in GUI
     * @type {string}
     * @memberof Standard
     */
    version: string;
    /**
     * Year of release of the standard, usually displayed together with name and version
     * @type {number}
     * @memberof Standard
     */
    year?: number;
}




/**
* @export
* @enum {string}
*/
export enum StandardStatusEnum {
    CUSTOM = 'CUSTOM',
    DEPRECATED = 'DEPRECATED',
    DRAFT = 'DRAFT',
    SYSTEM = 'SYSTEM',
    TEMPORARY = 'TEMPORARY'
}


