import { UserType } from 'common/common.types';

const BASE_URL = 'https://securityscorecard.com/atlas-upgrade';

const createQueryString = (params: object) =>
  Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

export default ({
  email,
  firstName,
  lastName,
  company,
  credits,
  domain,
}: UserType) => {
  const queryString = createQueryString({
    email,
    firstName,
    lastName,
    domain,
    company: company.name,
  });

  return `${BASE_URL}?${queryString}`;
};
