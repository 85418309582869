import React from 'react';
import SscFactor from './SscFactor';
import { ContentContainer, ContentWrapper } from '../QuestionTabs.styles';
import { Factors } from './SscData.styles';
import { TabContentTitle } from '../';
import { withTranslation } from 'react-i18next';
import { SscDataFactorType, TranslatePropsType } from 'common/common.types';

interface SscDataPropsType extends TranslatePropsType {
  sscData?: SscDataFactorType[];
}

const SscData = ({ sscData, t }: SscDataPropsType) => {
  if (sscData) {
    return (
      <ContentContainer hasBackground={!!sscData.length}>
        <ContentWrapper>
          <TabContentTitle icon="ssc" title={t('title') as string} />

          {sscData.length > 0 ? (
            <Factors>
              {sscData.map(factor => (
                <SscFactor {...factor} />
              ))}
            </Factors>
          ) : (
            <p>{t('noDataAvailable') as string}</p>
          )}
        </ContentWrapper>
      </ContentContainer>
    );
  }
  return (
    <ContentContainer hasBackground={false}>
      <ContentWrapper>
        <TabContentTitle icon="ssc" title={t('title') as string} />
        <p>{t('noDataAvailable') as string}</p>
      </ContentWrapper>
    </ContentContainer>
  );
};

export default withTranslation('SscData')(SscData);
