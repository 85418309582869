import React from 'react';
import FileIcon, { defaultStyles } from 'react-file-icon';

const Icon = ({ extension }: { extension: string }) => (
  <FileIcon
    extension={extension}
    color={'#fff'}
    {...(defaultStyles[extension] || defaultStyles.txt)}
  />
);

export default Icon;
