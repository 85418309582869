import { ApiCallActionType } from '../../store.types';
import { call, put } from 'redux-saga/effects';
import { getTags } from './getTags';
import { trackEvent } from 'utils/analytics';
import { Uuid } from '../../../common/common.types';
import { refreshFilter } from './refreshFilter';
import { Tag } from '../../../generated/models';
import { CompaniesStateType } from '../companies.reducer';
import update from 'immutability-helper';
import { parseCompanyTag } from 'utils/parsers';

export interface CompaniesAssignTagCallParamsType {
  companyId: Uuid;
  tagName: string;
}

export type CompaniesAssignTagActionType = ApiCallActionType<
  '@companies/API/UPLOAD_LOGO',
  CompaniesAssignTagCallParamsType,
  Tag[],
  never,
  CompaniesStateType
>;

export const assignTag = (
  companyId: Uuid,
  tagName: string,
): CompaniesAssignTagActionType => ({
  type: '@companies/API/UPLOAD_LOGO',
  payload: {
    endpointPath: ['companies', 'tags', 'create'],
    callParams: { companyId, tagName },
    selector: (data, { companies }): Partial<CompaniesStateType> => {
      const companyIndex = companies.list.findIndex(
        company => company.company.info.id === companyId,
      );
      const parsedTags = data.map(tag => parseCompanyTag(tag, companyId));

      if (companyIndex !== -1) {
        return update(companies, {
          list: {
            [companyIndex]: { company: { tags: { $set: parsedTags } } },
          },
        });
      }

      if (companies.details.company.info.id === companyId) {
        return update(companies, {
          details: { company: { tags: { $set: parsedTags } } },
        });
      }

      return companies;
    },
    postActions: [
      () => call(() => trackEvent('Tag', 'Add')),
      () => put(getTags()),
      () => put(refreshFilter()),
    ],
  },
});
