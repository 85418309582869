/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagToCompany
 */
export interface TagToCompany {
    /**
     * Tag name to be added to the selected company
     * @type {string}
     * @memberof TagToCompany
     */
    name: string;
}

export function TagToCompanyFromJSON(json: any): TagToCompany {
    return TagToCompanyFromJSONTyped(json, false);
}

export function TagToCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagToCompany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function TagToCompanyToJSON(value?: TagToCompany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}


