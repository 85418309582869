import Grade from '../common/Grade';
import React, { useState, useEffect } from 'react';
import { COMPANIES } from '../../routes';
import { ClickableTitle } from 'common/common.styles';
import { Tags } from './Tags';
import { withTranslation } from 'react-i18next';
import {
  ListedCompanyType,
  TranslatePropsType,
  LanguageType,
} from 'common/common.types';
import { DATE_FORMAT_BASED_ON_LOCALE } from 'common/common.constants';
import { format } from 'date-fns';

import {
  GradeWrapper,
  Table,
  TableCell,
  TableCellClickable,
  TableHead,
  TableRow,
  Unverified,
} from './table.styles';
import TableHeaderCellSortable from './TableHeaderCellSortable';
import { getLanguagePreference } from 'store/settings/settings.selectors';
import { StateType } from 'store/store.types';
import { connect } from 'react-redux';
import getLocale from 'utils/getLocale';
import styled from 'styled-components';

const TableCellStyled = styled(TableCell)`
  span {
    width: 100%;
  }
`;

interface CompaniesTableType extends TranslatePropsType {
  data: ListedCompanyType[];
  navigate: any;
  language?: LanguageType;
}

function sortDataByLastSentDate(
  data: ListedCompanyType[],
  sortOrder: 'ASC' | 'DESC',
  fieldName: string,
) {
  // Sort the data based on lastRequestSentDate
  data.sort((a, b) => {
    const dateA: Date = new Date(a[fieldName]);
    const dateB: Date = new Date(b[fieldName]);
    if (sortOrder === 'ASC') {
      return dateA.getTime() - dateB.getTime();
    } else {
      return dateB.getTime() - dateA.getTime();
    }
  });

  return data;
}

const CompaniesTable = ({
  data,
  navigate,
  t,
  language,
}: CompaniesTableType) => {
  const nameLabel = t('components:CompaniesTable.name');
  const ratingLabel = t('components:CompaniesTable.rating');
  const sentLabel = t('components:CompaniesTable.sent');
  const receivedLabel = t('components:CompaniesTable.received');
  const tagsLabel = t('components:CompaniesTable.tags');
  const lastSentDateLabel = t('components:CompaniesTable.lastRequestSentDate');
  const lastSubmittedDateLabel = t(
    'components:CompaniesTable.lastRequestSubmittedDate',
  );
  const nextScheduledSendLabel = t(
    'components:CompaniesTable.nextRequestScheduledSend',
  );
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC' | null>('ASC');
  const [sortedColumn, setSortedColumn] = useState(lastSentDateLabel);

  const handleSort = (
    column: string,
    newSortOrder: 'ASC' | 'DESC',
    fieldName: string,
  ) => {
    setSortedColumn(column);
    setSortOrder(newSortOrder);
    sortDataByLastSentDate(data, newSortOrder, fieldName);
  };

  return (
    <Table>
      <thead>
        <TableRow>
          <TableHead style={{ maxWidth: 280, overflow: 'hidden' }}>
            {nameLabel}
          </TableHead>

          <TableHead>{ratingLabel}</TableHead>

          <TableHead>{sentLabel}</TableHead>

          <TableHead>{receivedLabel}</TableHead>

          <TableHeaderCellSortable
            label={lastSentDateLabel}
            sortOrder={sortedColumn === lastSentDateLabel ? sortOrder : null}
            onClick={_sortOrder =>
              handleSort(lastSentDateLabel, _sortOrder, 'lastRequestSentDate')
            }
            sortedColumn={sortedColumn}
          />

          <TableHeaderCellSortable
            label={lastSubmittedDateLabel}
            sortOrder={
              sortedColumn === lastSubmittedDateLabel ? sortOrder : null
            }
            onClick={_sortOrder =>
              handleSort(
                lastSubmittedDateLabel,
                _sortOrder,
                'lastRequestSubmittedDate',
              )
            }
            sortedColumn={sortedColumn}
          />

          <TableHeaderCellSortable
            label={nextScheduledSendLabel}
            sortOrder={
              sortedColumn === nextScheduledSendLabel ? sortOrder : null
            }
            onClick={_sortOrder =>
              handleSort(
                nextScheduledSendLabel,
                _sortOrder,
                'nextRequestScheduledSend',
              )
            }
            sortedColumn={sortedColumn}
          />

          <TableHead>{tagsLabel}</TableHead>
        </TableRow>
      </thead>

      <tbody>
        {data.map(
          ({
            company,
            company: {
              info: { id, isVerified, name, gradeUrl, score },
            },
            requestsReceivedCount,
            requestsSentCount,
            lastRequestSentDate,
            lastRequestSubmittedDate,
            nextRequestScheduledSend,
          }: ListedCompanyType) => (
            <TableRow
              key={company.info.id}
              data-cy={`company-${company.info.id}`}
            >
              <TableCellClickable
                data-cy="company-title"
                style={{
                  maxWidth: 280,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                onClick={() => navigate(`${COMPANIES}/${id}`)}
              >
                <ClickableTitle>{name}</ClickableTitle>
                {!isVerified && <Unverified showText={false} />}
              </TableCellClickable>

              <TableCell>
                <GradeWrapper>
                  <Grade url={gradeUrl} />

                  {score > 0 && score}
                </GradeWrapper>
              </TableCell>

              <TableCell>{requestsSentCount}</TableCell>

              <TableCell>{requestsReceivedCount}</TableCell>

              <TableCell>
                <div>
                  {language &&
                    lastRequestSentDate &&
                    format(
                      new Date(lastRequestSentDate),
                      DATE_FORMAT_BASED_ON_LOCALE[language.value],
                      {
                        locale: getLocale(language),
                      },
                    )}
                </div>
              </TableCell>

              <TableCell>
                <div>
                  {language &&
                    lastRequestSubmittedDate &&
                    format(
                      new Date(lastRequestSubmittedDate),
                      DATE_FORMAT_BASED_ON_LOCALE[language.value],
                      {
                        locale: getLocale(language),
                      },
                    )}
                </div>
              </TableCell>

              <TableCell>
                <div>
                  {language && nextRequestScheduledSend
                    ? format(
                        new Date(nextRequestScheduledSend),
                        DATE_FORMAT_BASED_ON_LOCALE[language.value],
                        {
                          locale: getLocale(language),
                        },
                      )
                    : 'None scheduled'}
                </div>
              </TableCell>

              <TableCellStyled>
                <Tags company={company} editable fetchMyTags={false} />
              </TableCellStyled>
            </TableRow>
          ),
        )}
      </tbody>
    </Table>
  );
};

const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation(['common', 'components'])(CompaniesTable));
