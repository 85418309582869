import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { createGlobalStyle, css } from 'styled-components/macro';
import { tooltip } from '../utils/rc-tooltip.styles';
import { FontSizeType } from './common.fonts';

interface WrapperPropsType {
  grey?: boolean;
  landscape?: boolean;
}

interface PageHeadingType {
  bold?: boolean;
}

interface HorizontallyCenteredType {
  noGrow?: boolean;
  spacingSize?: number;
}

interface MarginatorType {
  margin: { left?: number; right?: number; top?: number; bottom?: number };
}

export const HEADER_HEIGHT = 50;
export const BACKGROUND_HEIGHT = 80;

export const OpacitySetter = styled.div<{ opacity: number }>`
  transition: all 0.2s ease-in-out;
  opacity: ${({ opacity }: { opacity: number }) => opacity};
`;

export const PageHeading = styled.h1<PageHeadingType>`
  ${({ bold, theme: { fonts } }) => fonts[bold ? 'bold' : 'light'].bigger}
  margin-bottom: 20px;
  line-height: 40px;
`;

export const Wrapper = styled.div<WrapperPropsType>`
  background: ${({ grey, theme }) =>
    grey ? theme.basic.colors.background : theme.basic.colors.light};
  display: flex;
  flex-direction: row;

  @media print {
    background: transparent;
  }

  @page {
    size: A4 ${({ landscape }) => (landscape ? 'landscape' : 'portrait')};
    margin: 18px 31px;
  }
`;

export const Content = styled.div<{ padding?: string }>`
  width: 100%;
  padding: ${({ padding }) => (padding ? padding : '30px 15px')};
  max-width: 1170px;
  margin: 0 auto;
  position: relative;

  @media print {
    max-width: unset;
    padding: 0;
  }
`;

export const ClickableTitle = styled.a`
  ${({ theme }) => theme.fonts.bold.normal}
  text-decoration: underline;
`;

export const Flex = styled.div<{
  alignItems?: string;
  justifyContent?: string;
}>`
  display: flex;
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}
`;

export const HorizontallyJustified = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const VerticallyCentered = styled.div<{ grow?: boolean }>`
  height: 100%;
  width: ${({ grow }: { grow?: boolean }) => (grow ? '100%' : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

/**
 * @param spacing Spacing in px.
 * @param direction Direction, 'vertical' is default.
 */
export const spacingBetweenChildren = (
  spacing: number,
  direction: 'horizontal' | 'vertical' = 'vertical',
) => {
  const marginDirection = direction === 'horizontal' ? 'right' : 'bottom';
  return css`
    & > * {
      margin-${marginDirection}: ${spacing}px;
    }
    & > :last-child {
      margin-${marginDirection}: 0;
    }
  `;
};

export const allChildrenFlexed = css`
  & > * {
    flex: 1;
  }
`;

export const HorizontallyCentered = styled.div<HorizontallyCenteredType>`
  display: flex;
  align-items: center;
  flex-direction: row;

  ${({ noGrow }) =>
    noGrow
      ? null
      : css`
          width: 100%;
        `}

  ${({ spacingSize }) =>
    typeof spacingSize !== 'undefined'
      ? spacingBetweenChildren(spacingSize, 'horizontal')
      : css``}
`;

export const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Vertical = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Marginator = styled.div<MarginatorType>`
  ${({ margin: { right, left, top, bottom } }: MarginatorType) => ({
    marginRight: right || 0,
    marginLeft: left || 0,
    marginTop: top || 0,
    marginBottom: bottom || 0,
  })};
`;

export const Centered = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const RelativeBlock = styled.div`
  position: relative;
`;

export const HiddenInput = styled.div`
  input {
    display: none;
  }
`;

export const Truncate = styled.div<{ width: number }>`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${({ width }) => `${width}px`};
  height: 16px;
`;

export const FexWrapFixFF = styled.div`
  min-width: 0;
  min-height: 0;
`;

const faUploadProp = faUpload as IconProp;
export const UploadIcon = styled(FontAwesomeIcon).attrs({
  icon: faUploadProp,
})`
  color: ${({ theme }) => theme.basic.colors.light};
  width: 24px !important;
  height: 24px;
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Proxima Nova';
    src: url('/fonts/proximanova-regular.woff') format('woff'),
      url('/fonts/proximanova-regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Proxima Nova Light';
    src: url('/fonts/proximanova-light.otf') format('opentype');
  }

  @font-face {
    font-family: 'Proxima Nova Bold';
    src: url('/fonts/proximanova-bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Proxima Nova Semibold';
    src: url('/fonts/proximanova-semibold.otf') format('opentype');
  }

  .clearfix:before,
  .clearfix:after {
    content: ' ';
    display: table;
  }

  .clearfix:after {
    clear: both;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  b,
  strong {
    font-family: 'Proxima Nova Bold';
  }

  a {
    color: inherit;
    cursor: pointer;
  }

  html,
  body {
    min-height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    color: ${({ theme }) => theme.atlas.text.primary};
    font-family: 'Proxima Nova';
  }

  @media print {
    html, body {
      background: none;
    }

    .\_pendo-launcher-badge\_ {
      display: none;
    }
  }

  body.modal-open {
    overflow-y: hidden;
  }

  .ReactModal__Overlay {
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ReactModal__Content {
    position: relative !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;
    padding: 0 !important;
    max-height: calc(100vh - 200px);
  }

  /* slider */
  .rc-slider-disabled {
    background-color: transparent !important;
  }

  /* select */
  .Select {
    z-index: 2;
  }

  ${tooltip}
`;

export interface TextButtonPropsType {
  color?: string;
  disabled?: boolean;
  size?: FontSizeType;
}

export const TextButton = styled.span<TextButtonPropsType>`
  ${({ size, theme }) => theme.fonts.bold[size || 'normal']};
  color: ${({ theme, color }) =>
    color ? theme.basic.colors[color] || color : theme.basic.colors.dark};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  ${({ disabled, theme }) =>
    disabled
      ? css`
          opacity: 0.5;
          color: ${theme.atlas.blue['400']};
          text-decoration: none;
          cursor: inherit;
        `
      : null}
`;

export const BasicInput = styled.input`
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  min-height: 38px;
  padding: 2px 8px;
  margin: 5px 0;
  ${({ theme }) => theme.fonts.regular.normal}
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.atlas.grey[300]};
  }
`;

export const alwaysShowScrollbar = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
