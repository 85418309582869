import styled from 'styled-components/macro';
import Textarea from '../../common/Textarea';
import { Flex } from 'common/common.styles';
import { Dropdown } from '../../common/Dropdown';
import { Option } from '../../Requests/SortBy.styles';
import InputWithLabelWrapper from '../../common/Input/InputWithLabelWrapper';
import Input from '../../common/Input';
import { ActionsWrapper, Action } from '../Actions/Actions.styles';
import { TabList } from '../../common/Tabs';

export const QuestionCodeWrapper = styled(InputWithLabelWrapper)`
  align-self: flex-start;
  margin-left: 15px;
`;

export const QuestionCodeInput = styled(Input).attrs({
  height: 30,
  size: 6,
})`
  width: 86px;
`;

export const CollapsedWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.atlas.grey[100]};
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 13px;

  & > * + * {
    margin-left: 10px;
  }

  ${ActionsWrapper} {
    display: none;
  }
  ${Action} {
    color: ${({ theme }) => theme.basic.colors.teal};
  }
  &:hover {
    border-color: ${({ theme }) => theme.basic.colors.teal};
    ${ActionsWrapper} {
      display: flex;
    }
  }
`;

export const CollapsedQuestionText = styled.div`
  flex: 1;
  padding: 16px 0;
  ${({ theme }) => theme.fonts.regular.small};
`;

export const QuestionWrapper = styled(Flex)`
  padding: 15px 0;
  background: ${({ theme }) => theme.atlas.grey[50]};
`;

export const QuestionTextWrapper = styled.div`
  flex: 1;
`;

export const QuestionText = styled(Textarea)`
  height: 59px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.atlas.text.primary} !important;
  ${({ theme }) => theme.fonts.regular.small}
`;

export const TabsWrapper = styled.div`
  margin-top: 4px;
  margin-left: 62px;
  position: relative;
`;

export const Tabs = styled(TabList)`
  display: flex;
`;

export const QuestionType = styled(Dropdown)`
  width: 107px;
  min-width: 107px;
  padding: 0 15px 0 10px;
  line-height: 24px;
`;

export const QuestionTypeToggle = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.atlas.text.secondary};
  ${({ theme }) => theme.fonts.regular.small};
`;

export const QuestionTypeOption = Option;
