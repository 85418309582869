import { StandardStatusType } from '../../../common/common.types';

export type FormDefinitionsFilterByFunctionType = (
  query: StandardStatusType[],
) => void;

export type FormDefinitionsFilterByStatusFunctionType = (
  query: StandardStatusType[],
) => void;

export interface FormDefinitionsSetFilterByStatusActionType {
  type: '@formDefinitions/SET_FILTER_STATUS';
  payload: {
    query: StandardStatusType[];
  };
}

export const setFilterByStatus = (
  query: StandardStatusType[],
): FormDefinitionsSetFilterByStatusActionType => ({
  type: '@formDefinitions/SET_FILTER_STATUS',
  payload: {
    query,
  },
});
