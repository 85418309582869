import 'react-datepicker/dist/react-datepicker.css';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import ReactDatepicker, { ReactDatePickerProps } from 'react-datepicker';
import { Note, Wrapper } from './Datepicker.styles';
import { StateType } from 'store/store.types';

interface DatepickerProps extends ReactDatePickerProps {
  label?: string;
  note?: string;
  language: string;
}

const Datepicker = React.forwardRef(
  (
    { label, note, language, ...datepickerProps }: DatepickerProps,
    ref: any,
  ) => {
    const getDateLocale = useCallback((value: string) => {
      const getLocale = (langValue: string) =>
        require(`date-fns/locale/${langValue}/index.js`);
      return getLocale(value);
    }, []);

    return (
      <Wrapper>
        {label && <label>{label}</label>}

        <ReactDatepicker
          ref={ref}
          locale={getDateLocale(language === 'ja-JP' ? 'ja' : language)}
          {...datepickerProps}
        />

        {note && <Note>{note}</Note>}
      </Wrapper>
    );
  },
);

const mapState = (state: StateType) => ({
  language: state.settings.languagePreference
    ? state.settings.languagePreference.value
    : 'en-US',
});

export default connect(mapState)(Datepicker);
