export interface MoveQuestionLocallyActionType {
  type: '@formCreator/MOVE_QUESTION';
  payload: {
    source: string;
    destination: string;
  };
}

export type MoveQuestionLocallyFunctionType = (
  source: string,
  destination: string,
) => void;

export const moveQuestionLocally = (
  source: string,
  destination: string,
): MoveQuestionLocallyActionType => ({
  type: '@formCreator/MOVE_QUESTION',
  payload: {
    source,
    destination,
  },
});
