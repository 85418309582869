import styled from 'styled-components/macro';

import { TextButton as ImportedTextButton } from 'common/common.styles';

import { Hoverable as ImportedHoverable } from '../SendRequest/StandardListItem.styles';

export const TextButton = styled(ImportedTextButton)`
  margin-left: 20px;
  opacity: 1;
`;

export const Hoverable = styled(ImportedHoverable)`
  display: flex;
  justify-content: space-between;
`;

export const UploadedStandardContent = styled.div`
  display: flex;
  padding: 8px 12px;
  color: ${({ theme }) => theme.basic.colors.dark};
  ${({ theme }) => theme.fonts.light.normal}
`;

interface UploadedStandardTextProps {
  isEditing: boolean;
}

export const UploadedStandardText = styled.div<UploadedStandardTextProps>`
  display: ${({ isEditing }) => (isEditing ? 'none' : 'block')};
  line-height: 20px;
  max-width: 420px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

interface UploadedStandardInputProps {
  isEditing: boolean;
}

export const UploadedStandardInput = styled.input<UploadedStandardInputProps>`
  width: ${({ isEditing }) => (isEditing ? 260 : 0)}px;
  border-radius: 3px;
  border: ${({ isEditing }) => (isEditing ? 1 : 0)}px
    ${({ theme }) => theme.basic.colors.darkerBorder} solid;
  padding: ${({ isEditing }) => (isEditing ? '6px 16px' : 0)};
`;

export const ActionButtons = styled.div`
  display: flex;
  width: 170px;
  align-items: center;
  margin-right: 30px;
`;
