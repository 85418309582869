export interface SendErrorActionPayloadType {
  status: number;
  msg: string;
}

export interface SendErrorActionType {
  type: '@formCreator/EVENT_SEQ_SEND_ERROR';
  payload: SendErrorActionPayloadType;
}

export const sendError = (
  payload: SendErrorActionPayloadType,
): SendErrorActionType => ({
  type: '@formCreator/EVENT_SEQ_SEND_ERROR',
  payload,
});
