import React from 'react';
import Page, {
  PageBaseType,
  PageActionsBaseType,
  PagePropsType,
  UpdateQueryType,
} from './Page';
import { StateType, ActionType } from 'store/store.types';
import { Dispatch } from 'redux';
import { FormNewFromExisting } from 'components/FormNewFromExisting';

import { FormType, LanguageType, UserType, Uuid } from 'common/common.types';

import { SortType } from '../utils/filters/formNewFromExisting.sorters';

import { startLoading } from 'store/app/actions';
import { resetModals } from '../utils/Modal/store/actions/resetModals';

import { showModal } from '../utils/Modal';

import {
  FormGetQuestionsParamsType,
  FormsLoadQuestionsFunctionType,
  FormsPutQuestionFunctionType,
  getPreview as getPreviewForm,
  loadQuestions,
  putQuestion,
  resetForm as resetPreviewForm,
} from 'store/forms/actions';

import {
  createEmptyForm,
  CreateEmptyFormFunctionType,
  duplicateStandardAndRedirectToFormCreator,
  DuplicateStandardAndRedirectToFormCreatorFunctionType,
  DuplicateStandardAndRedirectToFormCreatorParamsType,
  getPreview,
  GetPreviewFunctionType,
  GetPreviewParamsType,
} from 'store/formCreator/actions';

import {
  getStandardsForFormNewFromExisting,
  resetStandardsForFormNewFromExisting,
  setSort,
  FormNewFromExistingSetSortFunctionType,
} from 'store/formNewFromExisting/actions';
import { FormNewFromExistingStateType } from 'store/formNewFromExisting/formNewFromExisting.types';
import { DEFAULT_STANDARDS_STATUS } from 'store/formDefinitions/defaultValues';

export interface FormNewFromExistingContextActionsType {
  setSort: FormNewFromExistingSetSortFunctionType;
  showModal: (modalId: string) => void;
  getPreview: GetPreviewFunctionType;
  getPreviewForm: (id: Uuid) => void;
  resetPreviewForm: () => void;
  loadQuestions: FormsLoadQuestionsFunctionType;
  putQuestion: FormsPutQuestionFunctionType;
  createEmptyForm: CreateEmptyFormFunctionType;
  duplicateStandardAndRedirectToFormCreator: DuplicateStandardAndRedirectToFormCreatorFunctionType;
}

export interface FormNewFromExistingPageContextType extends PageBaseType {
  data: {
    formNewFromExisting: FormNewFromExistingStateType;
    previewForm: FormType;
    user: UserType;
    language: LanguageType;
  };
  actions: PageActionsBaseType & FormNewFromExistingContextActionsType;
}

const FormNewFromExistingPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      formNewFromExisting: state.formNewFromExisting,
      previewForm: state.forms.currentForm,
      user: state.user.currentUser,
      language: state.settings.languagePreference,
    })}
    dispatches={(
      dispatch: Dispatch<ActionType>,
      ownProps: PagePropsType,
      updateQuery: UpdateQueryType,
    ) => ({
      getData: ({
        queryParams: { sortBy },
      }: {
        queryParams: { sortBy?: SortType };
      }) => {
        dispatch(startLoading(1));

        dispatch(
          getStandardsForFormNewFromExisting({
            status: DEFAULT_STANDARDS_STATUS,
          }),
        );

        if (sortBy) {
          dispatch(setSort(sortBy));
        }
      },
      reset: () => {
        dispatch(resetStandardsForFormNewFromExisting());
        dispatch(resetModals());
      },
      actions: {
        navigate: ownProps.history.push,
        setSort: (sortBy: SortType) => {
          updateQuery({ sortBy });
          dispatch(setSort(sortBy));
        },
        showModal: (modalId: string) => dispatch(showModal(modalId)),
        getPreview: (params: GetPreviewParamsType) =>
          dispatch(getPreview(params)),
        getPreviewForm: (id: Uuid) => dispatch(getPreviewForm(id)),
        resetPreviewForm: () => dispatch(resetPreviewForm()),
        loadQuestions: (params: FormGetQuestionsParamsType) =>
          dispatch(loadQuestions(params)),
        putQuestion: (
          formId: Uuid,
          questionId: Uuid,
          responseId: Uuid,
          value: string,
          category: string,
        ) => {
          dispatch(
            putQuestion(formId, questionId, responseId, value, category),
          );
        },
        createEmptyForm: (standardName?: string) => {
          dispatch(createEmptyForm(standardName));
        },
        duplicateStandardAndRedirectToFormCreator: (
          params: DuplicateStandardAndRedirectToFormCreatorParamsType,
        ) => {
          dispatch(duplicateStandardAndRedirectToFormCreator(params));
        },
      },
    })}
  >
    <FormNewFromExisting />
  </Page>
);

export default FormNewFromExistingPage;
