import { assocPath } from 'ramda';
import { parseRequestSimpleList } from 'utils/parsers/requests.parsers';
import { sortRequestArray } from 'utils/filters/requests.sorters';
import { ApiCallActionType } from '../../store.types';
import { GetRequestsCallParamsType } from './getRequests';
import { RequestsStateType } from '../requests.types';
import {
  RequestSimple,
  FormRolesEnum as FormRoleEnum,
} from '../../../generated/models/RequestSimple';
import { Uuid } from '../../../common/common.types';

export type GetArchivedRequestsActionType = ApiCallActionType<
  '@requests/API/GET_ARCHIVED',
  GetRequestsCallParamsType,
  RequestSimple[],
  never,
  RequestsStateType
>;

export const getArchivedRequests = (
  companyId: Uuid,
): GetArchivedRequestsActionType => ({
  type: '@requests/API/GET_ARCHIVED',
  payload: {
    endpointPath: ['requests', 'getRequests'],
    callParams: {
      status: ['ARCHIVED'],
      form_roles: [
        FormRoleEnum.VRM,
        FormRoleEnum.VRMPROXY,
        FormRoleEnum.VENDOR,
        FormRoleEnum.VENDORPROXY,
      ],
    },
    selector: (data, state) =>
      assocPath(
        ['archived', 'data'],
        sortRequestArray({ by: 'UPDATED', direction: 'DESC' })(
          parseRequestSimpleList(data, state),
        ),
        state.requests,
      ),
  },
});
