import * as R from 'ramda';

const combineArrays = (arrays: any[]): any[] => {
  if (arrays.length < 2) {
    return arrays;
  }

  return arrays.length === 2
    ? R.union(arrays[0], arrays[1])
    : R.union(arrays[0], combineArrays([...arrays].splice(1)));
};

export default combineArrays;
