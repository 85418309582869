import * as R from 'ramda';
import Autocomplete from '../../common/Autocomplete';
import React, { PureComponent } from 'react';
import Tag from '../../common/Tag';
import { AddButton, AutocompleteWrapper, TagsWrapper } from './Tags.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StateType } from 'store/store.types';
import { TaggedType, Uuid } from 'common/common.types';
import { TagsPropsType, TagsStateType } from './Tags.types';
import { connect } from 'react-redux';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { getTags, removeTag, assignTag } from 'store/companies/actions';
import { EMPTY_ID } from 'common/common.constants';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

class Tags extends PureComponent<TagsPropsType, TagsStateType> {
  input: Autocomplete | null = null;

  constructor(props: TagsPropsType) {
    super(props);

    this.state = { tags: [] };
  }

  clickAddTag = () => {
    if (this.input) {
      this.input.focus();
    }
  };

  hasTag = (tagName: string) =>
    R.any(
      (tagged: TaggedType) =>
        tagged.tag.name.toLocaleLowerCase() === tagName.toLocaleLowerCase(),
      this.state.tags,
    );

  // TODO: ATL-854 filter out repeated tags on backend
  filterTags = (tags: TaggedType[]) =>
    R.uniqWith(
      (tagA, tagB) =>
        tagA.tag.type === tagB.tag.type &&
        tagA.tag.type !== 'NORMAL' &&
        tagA.tag.name.toLocaleLowerCase() === tagB.tag.name.toLocaleLowerCase(),
      tags,
    );

  getTagSuggestions = () =>
    R.filter(tag => !this.hasTag(tag.name), this.props.myTags);

  onAdd = (name: string) => {
    const { tags } = this.state;

    const {
      company: {
        info: { id: companyId },
      },
      tagActions: { assign },
    } = this.props;

    if (!this.hasTag(name)) {
      this.setState({
        tags: [
          ...tags,
          {
            id: EMPTY_ID,
            targetId: companyId,
            tag: {
              id: EMPTY_ID,
              name,
              type: 'NORMAL',
            },
          },
        ],
      });

      assign(companyId, name);
    }
  };

  onRemove = (tagToRemove: TaggedType) => {
    const { tags } = this.state;

    const {
      company: {
        info: { id: companyId },
      },
      tagActions: { remove },
    } = this.props;

    this.setState({
      tags: R.reject(
        (item: TaggedType) => R.equals(tagToRemove.id, item.id),
        tags,
      ),
    });

    if (tagToRemove.id !== EMPTY_ID) {
      remove({ ...tagToRemove, targetId: companyId });
    }
  };

  componentDidMount() {
    if (this.props.fetchMyTags) {
      this.props.getTags();
    }

    this.setState({ tags: this.filterTags(this.props.company.tags) });
  }

  componentDidUpdate(prevProps: TagsPropsType) {
    if (
      this.props.company.tags.length !== prevProps.company.tags.length ||
      !R.all((tuple: any) => {
        return R.equals(tuple[0].id, tuple[1].id);
      }, R.zip(this.props.company.tags, prevProps.company.tags))
    ) {
      this.setState({ tags: this.filterTags(this.props.company.tags) });
    }
  }

  render() {
    const { editable, t } = this.props;

    const { tags } = this.state;

    const faPlusCircleProp = faPlusCircle as IconProp;
    return (
      <TagsWrapper>
        {tags.map((tag: TaggedType) => (
          <Tag
            editable={editable && tag.tag.type === 'NORMAL'}
            key={tag.id}
            tag={tag}
            remove={this.onRemove}
          />
        ))}

        {editable && (
          <AutocompleteWrapper>
            <AddButton className="tagAddButton" onClick={this.clickAddTag}>
              <FontAwesomeIcon icon={faPlusCircleProp} />
            </AddButton>

            <Autocomplete
              ref={ref => {
                this.input = ref;
              }}
              suggestions={this.getTagSuggestions()}
              onAdd={this.onAdd}
              placeholder={t('Tags.placeholder')}
            />
          </AutocompleteWrapper>
        )}
      </TagsWrapper>
    );
  }
}

const mapStateToProps = (state: StateType) => ({
  myTags: state.companies.myTags,
});

const mapDispatchToProps = (dispatch: any) => ({
  getTags: () => dispatch(getTags()),
  tagActions: {
    assign: (companyId: Uuid, name: string) =>
      dispatch(assignTag(companyId, name)),
    remove: (tagged: TaggedType) => dispatch(removeTag(tagged)),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Tags));
