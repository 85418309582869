import { ApiCallActionType } from '../../store.types';
import { assoc } from 'ramda';
import { parseScheduledRequests } from 'utils/parsers/requests.parsers';
import { RequestsStateType } from '../requests.types';

export type GetScheduledRequestsActionType = ApiCallActionType<
  '@requests/API/GET_SCHEDULED_REQUESTS',
  never,
  any[],
  never,
  RequestsStateType
>;

export const getScheduledRequests = (): GetScheduledRequestsActionType => ({
  type: '@requests/API/GET_SCHEDULED_REQUESTS',
  payload: {
    endpointPath: ['requests', 'scheduler', 'getRequests'],
    selector: (data, state) =>
      assoc('scheduled', parseScheduledRequests(data), state.requests),
  },
});
