import React from 'react';
import { Horizontal, VerticallyCentered } from 'common/common.styles';
import { Wrapper, Delete } from './Chip.styles';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ChipPropsType {
  label: string;
  onDelete: () => void;
}

const Chip = ({ label, onDelete }: ChipPropsType) => (
  <Wrapper>
    <VerticallyCentered>
      <Horizontal>
        <span>{label}</span>

        <div onClick={onDelete}>
          <Delete icon={faTimesCircle as IconProp} />
        </div>
      </Horizontal>
    </VerticallyCentered>
  </Wrapper>
);

export default Chip;
