import React, { PureComponent } from 'react';
import { Wrapper, Text, DropdownWrapper, Item } from './ViewOptions.styles';
import { withTranslation } from 'react-i18next';
import onClickOutside from 'react-onclickoutside';
import { TranslatePropsType } from 'common/common.types';

export interface ViewOptionsPropsType extends TranslatePropsType {
  options?: string[];
  selectedOption: string;
}

interface StateType {
  showOptions: boolean;
}

class ViewOptions extends PureComponent<ViewOptionsPropsType, StateType> {
  constructor(props: ViewOptionsPropsType) {
    super(props);

    this.state = {
      showOptions: false,
    };
  }

  toggleDropdown = () => {
    this.setState({ showOptions: !this.state.showOptions });
  };

  handleClickOutside = () => {
    this.setState({ showOptions: false });
  };

  render() {
    const { options, selectedOption, t } = this.props;

    return (
      <Wrapper>
        {this.state.showOptions && options && (
          <DropdownWrapper>
            {options.map(option => (
              <Item key={option} selected={option === selectedOption}>
                {option}
              </Item>
            ))}
          </DropdownWrapper>
        )}
        <Text onClick={this.toggleDropdown}>
          {t('Response.viewOptions') as string}
        </Text>
      </Wrapper>
    );
  }
}

export default withTranslation('components')(onClickOutside(ViewOptions));
