import React from 'react';
import Textarea from '../Response/Textarea';
import { FormsPutMessageFunctionType } from 'store/forms/actions';
import { TranslatePropsType, Uuid } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { EMPTY_ID } from 'common/common.constants';

interface AddCommentPropsType extends TranslatePropsType {
  formMessage: string;
  putMessage?: FormsPutMessageFunctionType;
  requestId: Uuid;
}

const AddComment = ({
  formMessage,
  putMessage,
  requestId,
  t,
}: AddCommentPropsType) => {
  const handleChange = (message: string) =>
    putMessage ? putMessage(requestId, message) : undefined;

  return (
    <div>
      <Textarea
        actions={{
          handleChange,
        }}
        data={{
          areAttachmentsModifiable: false,
          formId: EMPTY_ID,
          id: EMPTY_ID,
          isEditable: putMessage !== undefined,
          placeholder: t('textareaPlaceholder') as string,
          questionId: EMPTY_ID,
          value: formMessage,
        }}
      />
    </div>
  );
};

export default withTranslation('FormAttachments')(AddComment);
