import { EventRegisterSummary } from '../../../../generated/models/EventRegisterSummary';

export interface SendSuccessActionType {
  type: '@formCreator/EVENT_SEQ_SEND_SUCCESS';
  payload: {
    response: EventRegisterSummary;
    time: Date;
  };
}

export const sendSuccess = (
  response: EventRegisterSummary,
  time: Date,
): SendSuccessActionType => ({
  type: '@formCreator/EVENT_SEQ_SEND_SUCCESS',
  payload: {
    response,
    time,
  },
});
