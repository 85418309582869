import * as R from 'ramda';
import isCallable from 'is-callable';
import { StateType } from 'store/store.types';

export const setStateKeyFromPath = (
  keyPath: string,
  transformation: R.Arity1Fn,
  state: StateType,
): StateType => {
  const path = keyPath.split('.');
  return R.assocPath(
    path,
    isCallable(transformation)
      ? transformation(R.path(path, state))
      : transformation,
    state,
  );
};
