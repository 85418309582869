import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface TabWrapperType {
  active?: boolean;
  hasCount?: boolean;
}

export const TabWrapper = styled.div<TabWrapperType>`
  background-color: ${({ active, hasCount, theme }) =>
    active
      ? theme.atlas.teal.primary
      : hasCount
      ? theme.basic.colors.light
      : theme.basic.colors.gray};
  width: 38px;
  height: 32px;
  margin: 0 0 8px 10px;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
`;

export const TabIcon = styled(FontAwesomeIcon)`
  width: 14px !important;
  height: 14px !important;
  margin: 9px 12px 0;
`;

export const TabIconSvgWrapper = styled.div<{ color: string }>`
  display: inline-block;

  svg {
    width: 16px;
    height: 16px;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin: 8px 11px 0;

    .ssc-tab-svg-icon {
      fill: ${({ color }) => color};
    }
  }
`;

export const Count = styled.div`
  ${({ theme }) => theme.fonts.bold.micro}
  position: absolute;
  min-width: 14px;
  height: 14px;
  line-height: 9.5px;
  padding-top: 3px;
  border-radius: 50%;
  text-align: center;
  top: 1px;
  right: 1px;
  color: ${({ theme }) => theme.basic.colors.light};
  background-color: ${({ theme }) => theme.basic.colors.error};
`;
