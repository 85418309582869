import store from 'store';
import { dismissAllNotifications, setSettings } from 'store/settings/actions';
import { bindActionCreators } from 'redux';

declare global {
  interface Window {
    testingHelpers: TestingHelpersType;
    Cypress?: {};
  }
}

export type TestingHelpersType = {
  store: typeof store;
  actions: {
    dismissAllNotifications: () => void;
    agreeCookies: () => void;
  };
};

const setupTestingHelpers = () => {
  window.testingHelpers = {
    store,
    actions: bindActionCreators(
      {
        dismissAllNotifications,
        agreeCookies: () => setSettings({ hasAgreedCookies: true }),
      },
      store.dispatch,
    ),
  };
};

export default setupTestingHelpers;
