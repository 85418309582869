import styled from 'styled-components';

const discountColor = ({
  price,
  theme: {
    upsell: {
      creditsBundle: {
        price: { background },
      },
    },
  },
}: any) => {
  switch (price) {
    case 200:
      return background.steepDiscount;
    case 250:
      return background.smallDiscount;
    default:
      return background.normal;
  }
};

interface BundleBoxContainerType {
  price: number;
}

export const BundleBoxContainer = styled.div<BundleBoxContainerType>`
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
  min-height: 150px;
  position: relative;
  width: 238px;
  margin-bottom: 50px;
  border-radius: 6px;
`;

export const Label = styled.div`
  text-align: center;
  margin-top: 65px;
`;

interface PriceContainerType {
  price: number;
  isCurrentPrice?: boolean;
}

export const CurrentPriceContainer = styled.div<PriceContainerType>`
  ${({ theme }) => theme.fonts.bold.normal}
  background-color: ${props => discountColor(props)}
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -50px;
  width: 238px;
  margin-left: -3px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: ${({ theme }) => theme.upsell.creditsBundle.price.textColor}
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
`;

export const BoxOverlay = styled.div<PriceContainerType>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.upsell.creditsBundle.border.default}
  cursor: pointer;

  ${({ isCurrentPrice, theme, price }: any) => {
    return isCurrentPrice
      ? `border: 3px solid ${discountColor({ price, theme })};`
      : `& > div {
              display: none;
          }`;
  }}

  &:hover {
      border: 3px solid ${props => discountColor(props)};
      visibility: visible;
      ${({ isCurrentPrice }) =>
        isCurrentPrice
          ? `
          & > div {
              display: flex;
          }`
          : ''}
  }
`;

const discountWrapperColor = ({
  price,
  theme: {
    upsell: {
      creditsBundle: {
        priceWrapper: { background },
      },
    },
  },
}: any) => {
  switch (price) {
    case 200:
      return background.steepDiscount;
    case 250:
      return background.smallDiscount;
    default:
      return background.normal;
  }
};

export const PriceWrapper = styled.div<PriceContainerType>`
  background-color: ${props => discountWrapperColor(props)}
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 12px;
  width: 100%;
`;

export const PriceTextContainer = styled.div`
  display: flex;
  align-items flex-end;
`;

export const PriceText = styled.span`
  ${({ theme }) => theme.fonts.semibold.normal}
  font-size: 32px;
  line-height: 27px;
`;

export const CreditText = styled.span`
  ${({ theme }) => theme.fonts.regular.normal}
`;

export const DiscountRibbon = styled.div<PriceContainerType>`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.upsell.creditsBundle.price.textColor}
  width: 70px;
  height: 39px;
  z-index: 100;

  &:before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    border: 20px solid ${props => discountColor(props)};
    border-left: 15px solid transparent;
    width: 57px;
    margin-left: -22px;
    border-top-right-radius: 2px;
  }
`;

export const DiscountText = styled.div`
  position: absolute;
  top: 8px;
  right: 13px;
  ${({ theme }) => theme.fonts.bold.big}
`;
