import ReactSelect from 'react-select';
import styled from 'styled-components';

export const Select = styled(ReactSelect)`
  .Select__control {
    background-color: transparent;
    border-color: ${({ theme }) => theme.inputs.select.colors.border};
    font-size: 14px;
    border-radius: 8px;
    max-width: 200px;

    &.Select__control--is-disabled {
      background-color: ${({ theme }) => theme.inputs.select.colors.disabled};
      border-color: ${({ theme }) => theme.inputs.select.colors.disabled};

      .Select__single-value--is-disabled {
        color: ${({ theme }) => theme.basic.colors.light};
      }
    }

    &.Select__control--is-focused {
      border-color: ${({ theme }) => theme.inputs.select.colors.optionSelected};
      box-shadow: none;
    }
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__dropdown-indicator {
    color: ${({ theme }) => theme.inputs.select.colors.border};
  }

  .Select__menu {
    max-width: 200px;
    z-index: 2;
  }

  .Select__option--is-focused {
    background-color: ${({ theme }) => theme.inputs.select.colors.option};
  }

  .Select__option:active,
  .Select__option--is-selected {
    background-color: ${({ theme }) =>
      theme.inputs.select.colors.optionSelected};
  }
`;
