import styled, { css } from 'styled-components';
import { Wrapper as Response } from '../Response/Response.styles';

interface ResponseListProps {
  hasConditionsToggle: boolean;
}

export const ResponseList = styled.div<ResponseListProps>`
  ${Response} + ${Response} {

    ${({ hasConditionsToggle }) =>
      hasConditionsToggle
        ? css`
            padding-top: 3px;

            &::before {
              content: ' ';
              display: block;
              position: absolute;
              height: 1px;
              background: ${({ theme }) => theme.atlas.grey['300']};
              width: calc(100% - 65px);
              top: 1px;
              margin-left: 45px;
              margin-right: 20px;
            }
          `
        : css`
            margin-top: 2px;
          `}
  }
`;
