import React, { useMemo } from 'react';
import {
  FormDefinitionValidationType,
  TranslatePropsType,
} from 'common/common.types';
import { withTranslation } from 'react-i18next';
import {
  Buttons,
  CloseButton,
  ConfirmButton,
  Text,
  Title,
} from '../../common/ConfirmModal/ConfirmModal.styles';
import Modal from 'utils/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  CancelButton,
  List,
  Wrapper,
  TitleContainer,
  Paragraph,
} from './PublishModal.styles';
import { calculateIssues, canDeleteQuestions } from './utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface PublishModalPropsType extends TranslatePropsType {
  invalidCount: number;
  modalId: string;
  onCancelClick: () => void;
  onPublishClick: () => void;
  validation: FormDefinitionValidationType;
}

interface IssuesType {
  totalCount: number;
  questionCount: number;
  foundIssues: { [key: string]: number };
}

const PublishModal = ({
  modalId,
  onCancelClick,
  onPublishClick,
  validation,
  invalidCount,
  t,
}: PublishModalPropsType) => {
  const { totalCount, questionCount, foundIssues } = useMemo<IssuesType>(
    () => ({
      totalCount: invalidCount,
      ...calculateIssues([
        ...validation.invalidQuestions,
        ...validation.invalidResponses,
      ]),
    }),
    [validation, invalidCount],
  );
  const hasQuestionsLeft = useMemo(
    () => canDeleteQuestions(validation.formErrors),
    [validation.formErrors],
  );

  const modalProps = {
    modalId: modalId,
    custom: true,
  };
  return (
    <Modal {...modalProps}>
      <Wrapper>
        <CloseButton onClick={onCancelClick}>
          <FontAwesomeIcon icon={faTimes as IconProp} />
        </CloseButton>
        <Text>
          <TitleContainer>
            <Title>{t('publishModal.title') as string}</Title>
            <Paragraph>
              {t('publishModal.foundIssue', { count: totalCount }) as string}
            </Paragraph>
            <List>
              {Object.keys(foundIssues).map(code => (
                <li key={code}>
                  {
                    t('publishModal.issue', {
                      context: code,
                      count: foundIssues[code],
                    }) as string
                  }
                </li>
              ))}
            </List>
          </TitleContainer>
        </Text>
        <Buttons spacingSize={29}>
          <ConfirmButton onClick={onCancelClick}>
            {t('publishModal.reviewIssue', { count: totalCount })}
          </ConfirmButton>
          {hasQuestionsLeft && (
            <CancelButton onClick={onPublishClick}>
              {
                t('publishModal.removeAndPublish', {
                  count: questionCount,
                }) as string
              }
            </CancelButton>
          )}
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

export default withTranslation('FormCreator')(PublishModal);
