import React from 'react';
import Row from './Row';
import { CompaniesFilterFunctionsType } from '../Companies';
import {
  CompaniesFilterType,
  VisualisationRowDataType,
} from 'store/companies/companies.reducer';

export interface FilteredRowsType {
  data: VisualisationRowDataType[];
  filter: CompaniesFilterType;
  filterFunctions: CompaniesFilterFunctionsType;
  yRange: number;
}

const FilteredRows = ({
  data,
  filter,
  filterFunctions,
  yRange,
}: FilteredRowsType) => {
  const yStep = yRange / (data.length + 1);

  return (
    <div>
      {data.map(({ legend, byGrade }: VisualisationRowDataType, i: number) => (
        <Row
          y={(i + 1) * yStep}
          legend={legend}
          byGrade={byGrade}
          key={`row-${legend}`}
          filter={filter}
          filterFunctions={filterFunctions}
        />
      ))}
    </div>
  );
};

export default FilteredRows;
