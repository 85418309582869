/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionDetailBase,
    QuestionDetailBaseFromJSON,
    QuestionDetailBaseFromJSONTyped,
    QuestionDetailBaseToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
    VRMReviewStatsMeta,
    VRMReviewStatsMetaFromJSON,
    VRMReviewStatsMetaFromJSONTyped,
    VRMReviewStatsMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionDetailBasesListWithVRMReviewStatsResponse
 */
export interface QuestionDetailBasesListWithVRMReviewStatsResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof QuestionDetailBasesListWithVRMReviewStatsResponse
     */
    msg: string;
    /**
     * 
     * @type {Array<QuestionDetailBase>}
     * @memberof QuestionDetailBasesListWithVRMReviewStatsResponse
     */
    entries: Array<QuestionDetailBase>;
    /**
     * 
     * @type {VRMReviewStatsMeta}
     * @memberof QuestionDetailBasesListWithVRMReviewStatsResponse
     */
    meta: VRMReviewStatsMeta;
}

export function QuestionDetailBasesListWithVRMReviewStatsResponseFromJSON(json: any): QuestionDetailBasesListWithVRMReviewStatsResponse {
    return QuestionDetailBasesListWithVRMReviewStatsResponseFromJSONTyped(json, false);
}

export function QuestionDetailBasesListWithVRMReviewStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionDetailBasesListWithVRMReviewStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entries': ((json['entries'] as Array<any>).map(QuestionDetailBaseFromJSON)),
        'meta': VRMReviewStatsMetaFromJSON(json['meta']),
    };
}

export function QuestionDetailBasesListWithVRMReviewStatsResponseToJSON(value?: QuestionDetailBasesListWithVRMReviewStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entries': ((value.entries as Array<any>).map(QuestionDetailBaseToJSON)),
        'meta': VRMReviewStatsMetaToJSON(value.meta),
    };
}


