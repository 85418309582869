import { ResponseTypeDefinitionResponseTypeEnum } from '../../generated/models/ResponseTypeDefinition';
import {
  Uuid,
  ResponseDefinitionSummaryType,
  FormDefinitionSummaryDraftExtendedType,
  FormDefinitionDraftCategoryStatsType,
  QuestionDefinitionSummaryType,
  ResponseDefinitionType,
} from '../../common/common.types';
import { FormCreatorStateType } from './formCreator.types';
import { findIndex, propEq } from 'ramda';
import update from 'immutability-helper';
import { EMPTY_ID } from '../../common/common.constants';

export const createDefaultResponse = (
  responseId: Uuid,
  pos = 0,
): ResponseDefinitionSummaryType => ({
  definition: {
    id: EMPTY_ID,
    responseType: ResponseTypeDefinitionResponseTypeEnum.TEXT,
    label: '',
    options: [],
    placeholder: '',
  },
  id: EMPTY_ID,
  isRequired: false,
  pos,
  uniqueId: responseId,
});

export const setCurrentForm = (
  state: FormCreatorStateType,
  form: FormDefinitionSummaryDraftExtendedType,
): FormCreatorStateType =>
  update(state, {
    currentForm: { $set: form },
    eventSequencer: {
      nextEventToken: { $set: form.nextEventToken },
      lastSavedUpdate: { $set: form.updatedAt },
    },
    openedQuestion: {
      $set:
        form.categories.length > 0
          ? {
              categoryName: form.categories[0].name,
              questionUuid: undefined,
            }
          : undefined,
    },
  });

export const addCategoryIfNotExist = (
  categories: FormDefinitionDraftCategoryStatsType[],
  category: string,
  questionCount: number,
) => {
  const alreadyExists = categories.some(c => c.name === category);
  if (alreadyExists) {
    return categories;
  }
  return [
    ...categories,
    {
      name: category,
      pos: categories.length,
      questionCount,
    },
  ];
};

export const getCountOfQuestionsInCategory = (
  questions: QuestionDefinitionSummaryType[],
  category: string,
) => questions.filter(q => q.category === category).length;

export const getMaxQuestionPosInCategory = (
  questions: QuestionDefinitionSummaryType[],
  category: string,
) => {
  let maxPos = 0;
  questions.forEach(question => {
    if (question.category === category && question.pos > maxPos) {
      maxPos = question.pos;
    }
  });
  return maxPos;
};

export const createResponseDefinition = (
  responseType: ResponseTypeDefinitionResponseTypeEnum,
  options: string[] = [],
  label = '',
  placeholder = '',
): ResponseDefinitionType => ({
  id: EMPTY_ID,
  responseType,
  label: label,
  options: options,
  placeholder: placeholder,
});

export const createResponse = (
  responseType: ResponseTypeDefinitionResponseTypeEnum,
  responseId: Uuid,
  pos: number = 0,
  options: string[] = [],
): ResponseDefinitionSummaryType => ({
  id: EMPTY_ID,
  isRequired: false,
  pos,
  uniqueId: responseId,
  definition: createResponseDefinition(responseType, options),
});

export const getCountOfResponsesInQuestion = (
  questions: QuestionDefinitionSummaryType[],
  questionId: Uuid,
) => questions.filter(q => q.wizardId === questionId)[0].responses.length;

export const moveCategoryLocally = (
  categoryName: string,
  targetCategoryName: string,
  categories: FormDefinitionDraftCategoryStatsType[],
) => {
  const sourceIndex = findIndex(propEq('name', categoryName), categories);
  const destinationIndex = findIndex(
    propEq('name', targetCategoryName),
    categories,
  );
  const source = categories[sourceIndex];
  const switchedCategories = update(categories, {
    $splice: [
      [sourceIndex, 1],
      [destinationIndex, 0, source],
    ],
  });
  return switchedCategories.map((item, i) => ({
    ...item,
    pos: i + 1,
  }));
};
