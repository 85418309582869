import styled from 'styled-components';

export const Heading = styled.div`
  ${({ theme }) => theme.basic.fontWeights.bold};
`;

export const Scroller = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 90vw;
  max-width: 970px;
  max-height: 550px;
  padding: 0 42px 20px;
`;

export const Content = styled.div`
  padding-top: 22px;
`;

export const Footer = styled.div`
  padding: 0 42px 25px 42px;
`;
