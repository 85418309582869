import React, { useCallback } from 'react';
import { UpdateQuestionConditionFunctionType } from 'store/formCreator/actions';
import { ConditionTextBox, Wrapper } from './TextEditor.styles';
import debounce from 'lodash.debounce';
import { Uuid } from 'common/common.types';

export interface TextEditorPropsType {
  condition: string;
  questionId: Uuid;
  updateCondition: UpdateQuestionConditionFunctionType;
}

const TextEditor = ({
  condition,
  questionId,
  updateCondition,
}: TextEditorPropsType) => {
  const handleConditionChange = useCallback(
    debounce((value: string) => {
      updateCondition(questionId, {
        type: 'text',
        payload: value,
      });
    }, 1000),
    [questionId],
  );

  return (
    <Wrapper>
      <ConditionTextBox onChange={handleConditionChange} value={condition} />
    </Wrapper>
  );
};

export default TextEditor;
