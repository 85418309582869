import arrayToIdDict from '../arrayToIdDict';
import uuid from '../uuid';
import { QuestionMetadata } from 'generated/models/QuestionMetadata';
import { StateType } from 'store/store.types';
import { all, map, mapObjIndexed, mergeAll, pipe, values } from 'ramda';
import { parseHistoryData } from './history.parsers';
import { parseSScDataFactor } from './sscData.parsers';
import {
  QuestionTabEnum,
  QuestionType,
  ResponsesDict,
  ResponseType,
  SscDataFactorType,
  QuestionDefinitionSummaryType,
  Condition,
  StructuredConditionLine,
  Uuid,
  QuestionBlocksType,
  QuestionHistoryType,
} from 'common/common.types';
import {
  QuestionDetailBase,
  QuestionDetailBaseVrmReviewStatusEnum,
} from 'generated/models/QuestionDetailBase';
import {
  parseAttachment,
  parseDelegation,
  parseMessage,
  parseResponse,
} from './';
import { QuestionDefinitionSummary } from 'generated/models/QuestionDefinitionSummary';
import { parseResponseDefinitionSummary } from './response.parsers';
import { QuestionConditionStructuredItemOperatorEnum } from 'generated/models/QuestionConditionStructuredItem';
import { QuestionConditionStructuredOperatorEnum } from 'generated/models/QuestionConditionStructured';
import { EMPTY_ID } from 'common/common.constants';

type QuestionDetail = QuestionDetailBase & {
  messages?: any;
  sscData: SscDataFactorType[];
};

type AdditionalDataType = {
  blocks?: QuestionBlocksType;
  messages: any;
  history: QuestionHistoryType[];
  sscData: SscDataFactorType[];
};

const isQuestionFillingForMetadata = (
  data: QuestionMetadata | QuestionDetail,
): data is QuestionMetadata => {
  return (data as QuestionMetadata).blocks_data !== undefined;
};

const areResponsesVisible = (
  responses: ResponsesDict,
  responsesFilled: number,
) =>
  !(
    pipe(
      values,
      all((response: ResponseType) => !response.isFilled),
    )(responses) && responsesFilled > 0
  );

export const parseQuestion = (
  data: QuestionMetadata | QuestionDetail,
  state: StateType,
): QuestionType => {
  const questionData = isQuestionFillingForMetadata(data)
    ? data.question
    : data;

  const {
    id = EMPTY_ID,
    attachment_modifiable = false,
    attachments = [],
    category = '',
    code = '',
    comment_count = 0,
    created_at,
    delegations = [],
    is_visible = true,
    form_id = EMPTY_ID,
    pos = 0,
    question = '',
    responses = [],
    response_count = 0,
    responses_filled = 0,
    updated_at,
    vrm_review_status = QuestionDetailBaseVrmReviewStatusEnum.PENDING,
    review_instructions,
  } = questionData;

  const {
    user: { currentUser },
  } = state;

  let additionalData: AdditionalDataType;

  if (isQuestionFillingForMetadata(data)) {
    const {
      blocks_data,
      blocks_data: { comments, history, ssc_data },
      blocks_meta,
    } = data;

    const blocks: QuestionBlocksType = {
      data: blocks_data,
      meta: blocks_meta,
    };

    additionalData = {
      blocks,
      messages: comments
        ? arrayToIdDict(comments.data, parseMessage, currentUser)
        : [],
      history: history ? history.data.map(parseHistoryData) : [],
      sscData: ssc_data ? ssc_data.factors.map(parseSScDataFactor) : [],
    };
  } else {
    const { messages } = questionData as QuestionDetail;

    additionalData = {
      messages: arrayToIdDict(messages || [], parseMessage, currentUser),
      history: [],
      sscData: [],
    };
  }

  const parsedResponses: ResponsesDict = arrayToIdDict(
    responses,
    parseResponse,
  );

  const attachmentResponseIds: any = pipe(
    values,
    map((response: ResponseType) =>
      mapObjIndexed(() => response.id, response.attachments),
    ),
    mergeAll,
  )(parsedResponses);

  return {
    ...additionalData,
    id,
    attachments: arrayToIdDict(
      attachments,
      parseAttachment,
      (attachmentId: Uuid) => attachmentResponseIds[attachmentId],
    ),
    areAttachmentsModifiable: attachment_modifiable,
    category,
    code,
    createdAt: new Date(created_at),
    delegations: delegations
      ? delegations.map(delegationData => parseDelegation(delegationData))
      : [],
    formId: form_id,
    isVisible: is_visible,
    mappings: undefined,
    meta: {
      areResponsesVisible: areResponsesVisible(
        parsedResponses,
        responses_filled,
      ),
      hasMessages: comment_count > 0,
    },
    pos,
    question,
    responses: parsedResponses,
    responsesCount: response_count,
    responsesFilledCount: responses_filled,
    reviewStatus: vrm_review_status,
    reviewInstructions: review_instructions,
    selectedTab: state.forms.preferredTab || QuestionTabEnum.ATTACHMENTS,
    updatedAt: new Date(updated_at),
  };
};

const parseQuestionConditionStructuredItem = ({
  operator = QuestionConditionStructuredItemOperatorEnum.EQUALTO,
  parent_question_uuid = EMPTY_ID,
  response_uuid = EMPTY_ID,
  value = '',
}): StructuredConditionLine => ({
  id: uuid(),
  operator,
  questionUuid: parent_question_uuid,
  responseUuid: response_uuid,
  value,
});

export const parseQuestionDefinitionSummary = ({
  active = true,
  category = '',
  code = '',
  condition,
  pos = 0,
  question = '',
  responses = [],
  ssc_issues = [],
  wizard_uuid = EMPTY_ID,
}: QuestionDefinitionSummary): QuestionDefinitionSummaryType => {
  let parsedCondition: Condition | undefined;
  if (condition) {
    if (condition.structured) {
      const {
        operator = QuestionConditionStructuredOperatorEnum.AND,
        items = [],
      } = condition.structured;
      parsedCondition = {
        type: 'structured',
        payload: {
          operator,
          items: items.map(parseQuestionConditionStructuredItem),
        },
      };
    } else if (condition.text) {
      parsedCondition = {
        type: 'text',
        payload: condition.text,
      };
    }
  }

  return {
    active,
    category,
    code,
    condition: parsedCondition,
    pos,
    question,
    responses: responses.map(r => parseResponseDefinitionSummary(r)),
    sscIssues: ssc_issues,
    wizardId: wizard_uuid,
  };
};
