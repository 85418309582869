import styled, { css } from 'styled-components';
import hex2rgba from 'utils/hex2rgba';

export const Label = styled.span`
  line-height: 17px;
  color: ${({ theme }) => theme.atlas.text.secondary};
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
`;

export const Wrapper = styled.div<{ isActive: boolean }>`
  // TODO: when more tabs available, change Tab's width dynamically
  //  based on number of Tabs in parent component TabList
  flex-basis: 25%; // <--
  height: 45px;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
  border-bottom-width: 4px;
  border-bottom-style: solid;

  ${({ isActive, theme }) =>
    isActive
      ? css`
          border-bottom-color: ${theme.atlas.grey['50']};
          background: ${theme.atlas.grey['50']};

          > ${Label} {
            ${theme.basic.fontWeights.bold};
          }
        `
      : css`
          border-bottom-color: ${theme.atlas.white};
          background: ${hex2rgba(theme.atlas.grey['50'], 0.3)};

          > ${Label} {
            ${theme.basic.fontWeights.regular};
          }
        `}

  & + & {
    margin-left: 4px;
  }
`;

export const Count = styled.span`
  height: 17px;
  line-height: 17px;
  color: ${({ theme }) => theme.atlas.text.primary};
  background: ${({ theme }) => theme.atlas.blue['200']};
  border-radius: 4px;
  padding: 0 7px;
  margin-left: 5px;
  ${({ theme }) => theme.fonts.regular.small}
`;

export const IconWrapper = styled.span`
  margin-right: 19px;
`;
