import styled from 'styled-components';
import { BACKGROUND_HEIGHT, Content } from 'common/common.styles';

export interface TabPropsType {
  onClick?: () => void;
  isSelected?: boolean;
}

export const Background = styled.div`
  min-height: ${BACKGROUND_HEIGHT}px;
  position: relative;
  display: flex;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: ${BACKGROUND_HEIGHT}px;
    background: ${({ theme }) => theme.basic.colors.black};
    background: url(${({ theme }) => theme.header.backgroundImage}) no-repeat
      center center ${({ theme }) => theme.header.backgroundColor};
    background-size: cover;
  }

  @media print {
    display: none;
  }
`;

export const TabsContainer = styled(Content)`
  display: flex;
  padding: 0 15px;
  margin: -10px auto 0;
`;

export const Tab = styled.div<TabPropsType>`
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.basic.colors.light : theme.tabs.inactive}
  color: ${({ theme: { atlas }, isSelected }) =>
    isSelected ? atlas.text.primary : atlas.grey[500]};
  letter-spacing: 0.25px;
  padding: 12px 25px;
  position: relative;
  line-height: 16px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'initial')};
  ${({
    isSelected,
    theme: {
      fonts: { semibold, regular },
    },
  }) => (isSelected ? semibold.small : regular.small)}
`;
