import { ResponseTypeDefinitionResponseTypeEnum as ResponseType } from 'generated/models/ResponseTypeDefinition';

// TODO: ATL-311 will be completely removed
export const getResponseLabel = (type: ResponseType) => {
  switch (type) {
    case ResponseType.EVIDENCE:
      return 'Evidence';

    case ResponseType.MULTISELECT:
      return 'Multiple/Select';

    case ResponseType.NORESPONSE:
      return 'No response';

    case ResponseType.NUMBER:
      return 'Number';

    case ResponseType.PERCENTAGE:
      return 'Percentage';

    case ResponseType.SINGLESELECT:
      return 'Single/Select';

    default:
      return 'Text';
  }
};
