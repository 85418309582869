import { FormDefinitionsStateType } from '../../store/formDefinitions/formDefinitions.types';
import { evolve } from 'ramda';
import { StandardStatusType, StandardType } from '../../common/common.types';
import { StandardStatusEnum } from '../../generated/models';

export const filterStandardsArray = (query: StandardStatusType) => (
  standards: StandardType[],
) =>
  standards.filter(standard =>
    Object.values(query).includes(standard.status as StandardStatusEnum),
  );

export const filterStandardsByStatus = (query: StandardStatusType[] | []) => (
  formDefinitions: FormDefinitionsStateType,
) => {
  return evolve(
    {
      standards: {
        filter: {
          byStatus: () => query,
        },
      },
    },
    formDefinitions,
  );
};
