import React from 'react';
import styled, { css } from 'styled-components';
import {
  default as OrigSelect,
  Creatable as CreatableSelect,
} from 'react-select';

const PREFIX = 'Select';

const commonStyle = css<{ visualState?: 'error' | 'normal' }>`
  .${PREFIX}__menu {
    z-index: 2;
    ${({ theme }) => theme.fonts.regular.small}
  }

  .${PREFIX}__single-value,
  .${PREFIX}__option {
    color: ${({ theme }) => theme.inputs.creatableSelect.color};
  }

  .${PREFIX}__option:active,
  .${PREFIX}__option--is-focused {
    background: ${({ theme }) => theme.inputs.creatableSelect.hoverBackground};
    color: ${({ theme }) => theme.inputs.creatableSelect.hoverColor};
  }

  .${PREFIX}__option--is-selected {
    background: ${({ theme }) =>
      theme.inputs.creatableSelect.selectedBackground};
    color: ${({ theme }) => theme.inputs.creatableSelect.selectedColor};
  }

  .${PREFIX}__control--menu-is-open .${PREFIX}__dropdown-indicator {
    transform: rotate(180deg);
  }

  .${PREFIX}__control--is-focused {
    border-color: ${({ theme }) => theme.inputs.creatableSelect.borderHover};
    box-shadow: none;
  }

  .${PREFIX}__control {
    border-radius: 6px;
    ${({ theme }) => theme.fonts.regular.small}
    ${({ visualState, theme }) =>
      visualState === 'error'
        ? css`
            border-color: ${theme.basic.colors.error};
          `
        : null}
  }

  .${PREFIX}__control:hover {
    border-color: ${({ theme, visualState }) =>
      visualState === 'error'
        ? theme.basic.colors.error
        : theme.inputs.creatableSelect.borderHover};
  }

  .${PREFIX}__control--is-focused:not(.${PREFIX}__control--menu-is-open) {
    border-color: ${({ theme }) => theme.inputs.creatableSelect.focusBorder};
    box-shadow: 0 0 0 3px ${({ theme }) =>
      theme.inputs.creatableSelect.focusBoxShadow};
  }

  .${PREFIX}__placeholder {
    /* ensures the input is at the start of the container */
    position: absolute;

    /* disables text selection on the placeholder itself */
    user-select: none !important;
  }


  /* Only set full width when placeholder is visible (no value) */
  & div[class*='${PREFIX}__input'],
  & div[class*='${PREFIX}__value-container'] {
    width: ${(props: any) => (props.value?.length ? 'unset' : '100%')};
  }

  /* Targeted div encompasses ssc_input but doesn't have a defined css classname
  *  in bundled version. The rule excludes the placeholder and pills.
  */
  &
    div[class*='${PREFIX}__value-container']
    > div:not(.${PREFIX}__placeholder):not(.${PREFIX}__multi-value) {
    width: ${(props: any) => (props.value?.length ? 'unset' : '100%')};

    /* puts input in front of placeholder */
    z-index: 10;
  }

  .${PREFIX}__input input {
    /* overrides default width of 2px, which made it unclickable */
    width: 100% !important;
  }
`;

export const Creatable = styled(CreatableSelect).attrs({
  classNamePrefix: PREFIX,
})`
  ${commonStyle}
`;

export const Select = styled(OrigSelect).attrs({
  classNamePrefix: PREFIX,
})`
  ${commonStyle}
`;

export const CompactSelect = styled(({ bold, ...props }) => (
  <Select {...props} />
))<{ bold?: boolean }>`
  ${({ bold, theme }) =>
    bold
      ? css`
          // Increase specificity
          .${PREFIX}__value-container.${PREFIX}__value-container {
            ${theme.basic.fontWeights.bold}
          }
        `
      : null}

  .${PREFIX}__control {
    min-height: 32px;
    letter-spacing: 0.25px;
  }

  .${PREFIX}__indicator {
    padding: 5px;
  }

  .${PREFIX}__clear-indicator {
    padding-right: 0;
  }

  .${PREFIX}__indicator-separator {
    background-color: transparent;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export default Select;
