import {
  HistoryAuditType,
  HistoryTypeEnum,
  QuestionHistoryType,
} from 'common/common.types';
import { parseUser } from './user.parsers';
import { parseSscDataAudit } from './sscData.parsers';

export const parseHistoryValue = (
  type: HistoryTypeEnum,
  data: any,
): HistoryAuditType => {
  if (type === HistoryTypeEnum.SSCDATA) {
    return parseSscDataAudit(data);
  }
  return data;
};

export const parseHistoryData = (data: any): QuestionHistoryType => {
  const { created_at = 0, type = '', value = '', user } = data;

  return {
    id: `${created_at}-${type}-${value && value.id}`,
    createdAt: new Date(created_at),
    type,
    value: parseHistoryValue(type, value),
    user: parseUser({ user: user || {} }),
  };
};
