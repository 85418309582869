import React, { useMemo } from 'react';
import { Creatable } from '../../../common/Select';
import { LabelSpaced } from '../../Stepper.styles';
import { Note, Wrapper } from '../EmailReceivers.styles';
import { SetFormValueFunctionType } from 'store/requests/actions';
import { useTranslation } from 'react-i18next';

import { TeamsItemType } from 'common/common.types';
import RecipientLabel from './RecipientLabel';
import RecipientOption from './RecipientOption';
import { Team } from 'generated/new';

interface TeamReceiversPropsType {
  allTeams: Team[] | null;
  isMulti?: boolean;
  selectedTeams: TeamsItemType[];
  setValue: SetFormValueFunctionType;
  showNote?: boolean;
}

type FilterOption = {
  label: string;
  value: string;
  data: TeamsItemType;
};

const TeamReceivers = ({
  allTeams,
  setValue,
  selectedTeams,
  showNote = true,
  isMulti = true,
}: TeamReceiversPropsType) => {
  const { t } = useTranslation('SendRequest');

  const allTeamsAsOptions: TeamsItemType[] | null = useMemo(
    () =>
      allTeams
        ? allTeams.map(
            (team): TeamsItemType => ({
              value: team.id,
              label: team.name,
              members: team.userEmails,
            }),
          )
        : null,
    [allTeams],
  );

  const areTeamsLoaded = allTeamsAsOptions !== null;

  const onReceiversChange = (options: TeamsItemType[]) => {
    setValue('targetTeams', options || []);
  };

  // If neither the Team label or one of the member's email addresses
  // contain the searched string then filter that Team out
  const customFilter = (option: FilterOption, searchText: string) => {
    const formattedSearch = searchText.toLowerCase();

    return (
      (option.data.members &&
        option.data.members.some((member: string) =>
          member.toLowerCase().includes(formattedSearch),
        )) ||
      option.label.toLowerCase().includes(formattedSearch)
    );
  };

  return (
    <Wrapper>
      <LabelSpaced>{t('internal.sendTo') as string}</LabelSpaced>

      <Creatable
        onChange={onReceiversChange}
        options={allTeamsAsOptions}
        placeholder={
          t(
            areTeamsLoaded ? 'internal.placeholder' : 'internal.loadingTeams',
          ) as string
        }
        value={selectedTeams}
        isMulti={isMulti}
        filterOption={customFilter}
        isValidNewOption={() => false}
        isDisabled={!areTeamsLoaded}
        components={{ MultiValueLabel: RecipientLabel }}
        formatOptionLabel={RecipientOption(t)}
      />

      {showNote && <Note>{t('sendToNote') as string}</Note>}
    </Wrapper>
  );
};

export default TeamReceivers;
