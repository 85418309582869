import styled from 'styled-components/macro';

export const BetaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.basic.colors.black};
  border-radius: 6px;
  width: 49px;
  height: 21px;
`;

export const BetaText = styled.div`
  color: ${({ theme }) => theme.atlas.white};
  font-family: ${({ theme }) => theme.fonts.bold.small};
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  width: 44px;
  height: 19px;
`;
