/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParsingMessage
 */
export interface ParsingMessage {
    /**
     * Severity level
     * @type {string}
     * @memberof ParsingMessage
     */
    severity: ParsingMessageSeverityEnum;
    /**
     * Message text
     * @type {string}
     * @memberof ParsingMessage
     */
    text: string;
    /**
     * Message text as key to localization dictionary
     * @type {string}
     * @memberof ParsingMessage
     */
    textId: ParsingMessageTextIdEnum;
    /**
     * Dictionary of values for placeholders in localized text message
     * @type {object}
     * @memberof ParsingMessage
     */
    values?: object;
}

export function ParsingMessageFromJSON(json: any): ParsingMessage {
    return ParsingMessageFromJSONTyped(json, false);
}

export function ParsingMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParsingMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'severity': json['severity'],
        'text': json['text'],
        'textId': json['text_id'],
        'values': !exists(json, 'values') ? undefined : json['values'],
    };
}

export function ParsingMessageToJSON(value?: ParsingMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'severity': value.severity,
        'text': value.text,
        'text_id': value.textId,
        'values': value.values,
    };
}

/**
* @export
* @enum {string}
*/
export enum ParsingMessageSeverityEnum {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR'
}
/**
* @export
* @enum {string}
*/
export enum ParsingMessageTextIdEnum {
    CANNOTBEREQUIRED = 'CANNOT_BE_REQUIRED',
    CODETOOLONG = 'CODE_TOO_LONG',
    CONDITIONINVALIDRESPONSEVALUE = 'CONDITION_INVALID_RESPONSE_VALUE',
    CONDITIONPARSEERROR = 'CONDITION_PARSE_ERROR',
    CONDITIONREFERENCEERROR = 'CONDITION_REFERENCE_ERROR',
    CSVPARSERUSED = 'CSV_PARSER_USED',
    DUPLICATEDCATEGORYCOLUMN = 'DUPLICATED_CATEGORY_COLUMN',
    DUPLICATEDIDCOLUMN = 'DUPLICATED_ID_COLUMN',
    DUPLICATEDOPTIONINRESPONSE = 'DUPLICATED_OPTION_IN_RESPONSE',
    DUPLICATEDSCORINGCOLUMN = 'DUPLICATED_SCORING_COLUMN',
    EMPTYCODE = 'EMPTY_CODE',
    EMPTYOPTIONINRESPONSE = 'EMPTY_OPTION_IN_RESPONSE',
    EMPTYQUESTIONTEXT = 'EMPTY_QUESTION_TEXT',
    EMPTYRESPONSE = 'EMPTY_RESPONSE',
    IDNOTUNIQUE = 'ID_NOT_UNIQUE',
    INVALIDFILE = 'INVALID_FILE',
    INVALIDXLSXFILE = 'INVALID_XLSX_FILE',
    NONMATCHINGHEADERLENGTH = 'NON_MATCHING_HEADER_LENGTH',
    NOTENOUGHLABELS = 'NOT_ENOUGH_LABELS',
    NOCATEGORYCOLUMN = 'NO_CATEGORY_COLUMN',
    NOHEADER = 'NO_HEADER',
    NOIDCOLUMN = 'NO_ID_COLUMN',
    NOISREQUIREDCOLUMN = 'NO_ISREQUIRED_COLUMN',
    NONEWRESPONSES = 'NO_NEW_RESPONSES',
    NOQUESTIONSFOUND = 'NO_QUESTIONS_FOUND',
    NOQUESTIONCOLUMN = 'NO_QUESTION_COLUMN',
    NORESPONSECOLUMN = 'NO_RESPONSE_COLUMN',
    NORESPONSEINFILLABLEQUESTION = 'NO_RESPONSE_IN_FILLABLE_QUESTION',
    OTHERRESPONSEINNORESPONSEQUESTION = 'OTHER_RESPONSE_IN_NO_RESPONSE_QUESTION',
    SCORINGRULESPARSEERROR = 'SCORING_RULES_PARSE_ERROR',
    SHEETEMPTY = 'SHEET_EMPTY',
    SHEETONLYONEROW = 'SHEET_ONLY_ONE_ROW',
    SINGLEOPTIONINRESPONSE = 'SINGLE_OPTION_IN_RESPONSE',
    TOOMANYEMPTYLINES = 'TOO_MANY_EMPTY_LINES',
    UNKNOWNMAPPEDSTANDARD = 'UNKNOWN_MAPPED_STANDARD',
    UNKNOWNMULTISELECTSCORINGAGGREGATION = 'UNKNOWN_MULTISELECT_SCORING_AGGREGATION',
    UNKNOWNRESPONSEFORMAT = 'UNKNOWN_RESPONSE_FORMAT',
    UNKNOWNSCORINGRULE = 'UNKNOWN_SCORING_RULE',
    WRONGHEADER = 'WRONG_HEADER',
    WRONGHEADERROWS = 'WRONG_HEADER_ROWS'
}


