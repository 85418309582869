import React from 'react';
import { Circle, RoundRect, SvgWrapper } from './Switch.styles';

interface SwitchPropsType {
  isChecked: boolean;
  onChange?: (newValue: boolean) => void;
}

const Switch = ({ isChecked, onChange }: SwitchPropsType) => (
  <SvgWrapper
    data-test="switch"
    width="34"
    height="20"
    viewBox="0 0 34 20"
    tabIndex={0}
    onClick={() => (onChange ? onChange(!isChecked) : null)}
    onKeyDown={e => {
      if (e.key === ' ' /* spacebar */) {
        if (onChange) {
          onChange(!isChecked);
        }
        e.preventDefault();
      }
    }}
  >
    <RoundRect
      isChecked={isChecked}
      x=".5"
      y=".5"
      width="33"
      height="19"
      rx="10"
      ry="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Circle cy="10" r="7" isChecked={isChecked} />
  </SvgWrapper>
);

export default Switch;
