import React from 'react';
import atlas from '../../../common/themes/atlas-palette';
import StatusTimeBar from './StatusTimeBar';
import StatusTimeSummary from './StatusTimeSummary';
import { RequestSummaryStatusEnum } from 'generated/models/RequestSummary';
import { RequestsStatsType } from 'store/requests/requests.types';
import { sum, values, mapObjIndexed } from 'ramda';
import Gauge from '../Gauge';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';

const { CREATED, VENDORINPROGRESS, VRMINPROGRESS } = RequestSummaryStatusEnum;

const colors = {
  [CREATED]: atlas.blue['900'],
  [VENDORINPROGRESS]: atlas.blue.a100,
  [VRMINPROGRESS]: atlas.teal.primary,
};

const getPercentages = (days: RequestsStatsType, sumDays: number) => {
  return mapObjIndexed((value: number, key: string) => ({
    color: colors[key] as string,
    value,
    percentage: value ? value / sumDays : 0,
  }))(days);
};

interface StatusTimeChartPropsType extends TranslatePropsType {
  stats: RequestsStatsType;
}

export const StatusTimeChart = ({ stats, t }: StatusTimeChartPropsType) => {
  // @ts-ignore
  const sumDays = sum(values(stats));

  const percentages = getPercentages(stats, sumDays);

  return (
    <Gauge title={t('charts.totalCompletion')}>
      <StatusTimeBar
        color={percentages[CREATED].color}
        title={t('charts.open')}
        percentage={percentages[CREATED]}
        leftOffset={percentages[CREATED].value ? 0 : 1}
      />

      <StatusTimeBar
        color={percentages[VENDORINPROGRESS].color}
        title={t('charts.fillOut')}
        percentage={percentages[VENDORINPROGRESS]}
        leftOffset={
          percentages[VENDORINPROGRESS].value
            ? percentages[CREATED].percentage
            : 1
        }
      />

      <StatusTimeBar
        color={percentages[VRMINPROGRESS].color}
        title={t('charts.review')}
        percentage={percentages[VRMINPROGRESS]}
        leftOffset={
          percentages[VRMINPROGRESS].value
            ? percentages[CREATED].percentage +
              percentages[VENDORINPROGRESS].percentage
            : 1
        }
      />

      <StatusTimeSummary days={sumDays} percentages={percentages} />
    </Gauge>
  );
};

export default withTranslation('Insights')(StatusTimeChart);
