import React from 'react';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { ResponseStatus } from 'components/ResponseStatus';

const p404 = ({ t }: TranslatePropsType) => (
  <ResponseStatus
    heading={t('P404.heading')}
    caption={t('P404.caption')}
    goLink={t('P404.link')}
  />
);

export default withTranslation()(p404);
