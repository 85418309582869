import React from 'react';
import { Content, Header, Title, Wrapper, Subtitle } from './Gauge.styles';

interface GaugePropsType {
  className?: string;
  children?: React.ReactNode;
  subtitle?: string;
  title: string;
}

const Gauge = ({ className, children, subtitle, title }: GaugePropsType) => (
  <Wrapper className={className}>
    <Header>
      <Title>{title}</Title>

      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Header>

    <Content>{children}</Content>
  </Wrapper>
);

export default Gauge;
