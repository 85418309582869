import React from 'react';
import { Label, RadioInput } from './ReviewRadio.styles';
import { QuestionDetailBaseVrmReviewStatusEnum } from 'generated/models/QuestionDetailBase';

interface ReviewRadioPropsType {
  isSelected: boolean;
  label: string;
  status: QuestionDetailBaseVrmReviewStatusEnum;
  name: string;
  handleChange: (value: QuestionDetailBaseVrmReviewStatusEnum) => void;
}

const ReviewRadio = ({
  isSelected,
  label,
  name,
  status,
  handleChange,
}: ReviewRadioPropsType) => (
  <Label isSelected={isSelected}>
    <RadioInput
      name={name}
      checked={isSelected}
      onChange={() => handleChange(status)}
    />
    {label.toLowerCase()}
  </Label>
);

export default ReviewRadio;
