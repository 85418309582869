import update from 'immutability-helper';
import { ApiCallActionType } from '../../store.types';
import { UserDelegable } from '../../../generated/models/UserDelegable';
import { FormsStateType } from '../forms.reducer';
import { parseAssignee } from 'utils/parsers/delegation.parsers';
import { Uuid } from '../../../common/common.types';

export interface FormsGetAssigneesCallParamsType {
  formId: Uuid;
  limit?: number;
  searchQuery?: string;
}

export type FormsGetAssigneesFunctionType = (formId: Uuid) => void;

export type FormsGetAssigneesActionType = ApiCallActionType<
  '@forms/API/GET_ASSIGNEES',
  FormsGetAssigneesCallParamsType,
  UserDelegable[],
  never,
  FormsStateType
>;

export const getAssignees = (formId: Uuid): FormsGetAssigneesActionType => ({
  type: '@forms/API/GET_ASSIGNEES',
  payload: {
    callParams: { formId },
    endpointPath: ['forms', 'delegations', 'assignees'],
    selector: (data, { forms }): FormsStateType =>
      update(forms, {
        assignees: {
          $set: data.map(parseAssignee),
        },
      }),
  },
});
