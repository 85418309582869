import styled, { css } from 'styled-components';

export const Content = styled.div`
  background: ${({ theme }) => theme.atlas.white};
  padding: 25px;
  position: relative;
  width: 400px;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 15px 0;
`;

export const Button = styled.button<{ isPrimary?: boolean }>`
  border-radius: 6px;
  border: none;
  cursor: pointer;
  height: 40px;
  ${({ theme }) => theme.fonts.bold.small}

  ${({ isPrimary, theme }) =>
    isPrimary
      ? css`
          background: ${theme.atlas.teal.primary};
          color: ${theme.atlas.white};
          width: 100%;
        `
      : css`
          background: transparent;
          color: ${theme.atlas.teal.primary};
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        `}

  &:disabled {
    background: ${({ theme }) => theme.basic.colors.disabled};
    cursor: not-allowed;
  }
`;

export const Heading = styled.h2`
  ${({ theme }) => theme.fonts.bold.normal}
  margin-bottom: 20px;
  padding-right: 40px;
`;
