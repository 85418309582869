import config, { Env } from '../config';

/* tslint:disable:jsdoc-format */
/**
 * When called, at compile time thows error, at runtime simply returns the
 * value. (in DEV environment throws error even at runtime)
 *
 * Useful to force developer to handle all possible enum values in switch.
 *
 * Usage:
 * ```
enum MyEnum {
  AAA = 'a',
  BBB = 'b',
  CCC = 'c'
}

switch(someEnumValue) {
case MyEnum.AAA:
  return 'aaa';
case MyEnum.BBB:
  return 'bbb';
default:
  // `someEnumValue` has type `MyEnum.CCC` at this point, which doesn't match
  // `never` type.
  return unreachableReturn(someEnumValue, 'problematic value');
}
```
 *
 * The code above will not compile, because you didn't handle all possible
 * values of MyEnum. You need to rewrite it like
 *
 * ```
switch(someEnumValue) {
case MyEnum.AAA:
  return 'aaa';
case MyEnum.BBB:
  return 'bbb';
case MyEnum.CCC:
  return 'bbb';
default:
  // `someEnumValue` has type `never` at this point.
  return unreachableReturn(someEnumValue, 'problematic value');
}
```
 *
 * The code above will compile. If by accident you get value 'd' or something
 * else in someEnumValue at runtime, the `unreachableReturn` will simply return
 * the thing you pass as the second parameter - in this case 'problematic
 * value'.
 */
const unreachableReturn = <R>(neverValue: never, returnValue: R): R => {
  if (config.env === Env.DEV || config.env === Env.LOCAL) {
    throw new Error(
      'Unreachable return reached, got unexpected value that is not represented in our type model.',
    );
  }
  return returnValue;
};

export default unreachableReturn;
