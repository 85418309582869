import React from 'react';
import atlas from '../../../common/themes/atlas-palette';
import { Bar } from './ScoreChart.styles';
import { TranslatePropsType } from 'common/common.types';
import { Legend, Name, Wrapper } from './CompletionLegend.styles';
import { withTranslation } from 'react-i18next';

const CompletionLegend = ({ t }: TranslatePropsType) => (
  <Wrapper>
    <Legend>
      <Name>{t('closed') as string}</Name>
      <Bar color={atlas.grey['300']} width={26} />
    </Legend>
    <Legend>
      <Name>{t('open') as string}</Name>
      <Bar color={atlas.grey['300']} hasStripes width={26} />
    </Legend>
  </Wrapper>
);

export default withTranslation('Insights')(CompletionLegend);
