import React from 'react';
import { LabelSpaced } from '../Stepper.styles';
import { SetFormValueFunctionType } from 'store/requests/actions';
import { TextareaInput, Wrapper } from './Message.styles';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface MessagePropsType extends TranslatePropsType {
  placeholder?: string;
  setValue: SetFormValueFunctionType;
}

const Message = ({ placeholder, setValue, t }: MessagePropsType) => (
  <Wrapper>
    <LabelSpaced>{t('messageLabel') as string}</LabelSpaced>

    <TextareaInput
      data-cy={'sq-message'}
      onBlur={value => setValue('emailBody', value)}
      placeholder={placeholder}
    />
  </Wrapper>
);

export default withTranslation('SendRequest')(Message);
