import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  flex: 1;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
`;

export const CategoryButton = styled.div`
  background: ${({ theme }) => theme.atlas.white};
  padding: 8px 0 7px 0px;
`;

export const AddCategory = styled.span`
  line-height: 17px;
  color: ${({ theme }) => theme.atlas.teal.primary};
  ${({ theme }) => theme.fonts.bold.small}
`;

export const PlusIcon = styled.span`
  color: ${({ theme }) => theme.atlas.teal.primary};
  ${({ theme }) => theme.fonts.bold.small};
`;
