import styled, { css } from 'styled-components/macro';
import { VisualState } from '../Textarea';

interface ControlType {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isReadOnlySelectable?: boolean;
  height?: number;
  variant: string;
  charCountSameLine?: boolean;
  visualState?: VisualState;
}

export const TextField = styled.input`
  flex: 1;
  border: none;
  padding: 2px 8px;
  margin: 0 2px;
  position: relative;
  overflow: hidden;
  background-color: inherit;
  cursor: inherit;
  ${({ theme }) => theme.fonts.regular.small}
`;

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  flex: 1;
`;

interface RightFootNoteProps {
  showOnSameLine?: boolean;
}

export const RightFootNote = styled.div<RightFootNoteProps>`
  display: none;
  position: absolute;
  ${({ showOnSameLine }) =>
    showOnSameLine
      ? css`
          position: relative;
          display: flex;
          align-self: flex-end;
          margin-left: 5px;
          min-width: 40px;
        `
      : css`
          bottom: -16px;
          right: 0;
        `}
  color: ${({ theme }) => theme.atlas.text.secondary};
  ${({ theme }) => theme.fonts.regular.mini}
`;

interface ControlWrapperProps {
  charCountSameLine?: boolean;
}

export const ControlWrapper = styled.div<ControlWrapperProps>`
  ${({ charCountSameLine }) =>
    charCountSameLine
      ? css`
          display: flex;
          flex-direction: row;
        `
      : null}
`;

export const Control = styled.div<ControlType>`
  ${({ charCountSameLine }) =>
    charCountSameLine
      ? css`
          flex-grow: 1;
        `
      : null}
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  min-height: ${({ height }) => (height ? `${height}px` : '38px')};
  outline: 0;
  position: relative;
  transition: all 100ms;

  &:focus-within ${RightFootNote} {
    display: block;
  }

  ${({
    isDisabled,
    isReadOnly,
    theme: {
      basic: { colors },
      inputs: {
        text: { enabled, disabled },
      },
    },
    variant,
    visualState,
  }) =>
    isDisabled || isReadOnly
      ? css`
          background-color: ${disabled[variant].background};
          border-color: ${disabled[variant].border};
          cursor: default;

          > ${TextField} {
            color: ${disabled[variant].color};

            &::placeholder {
              color: ${disabled[variant].placeholder};
            }
          }

          ${Suffix} {
            color: ${disabled[variant].color};
          }
        `
      : css`
          background-color: ${enabled[variant].background};
          border-color: ${visualState === 'error'
            ? colors.error
            : enabled[variant].border};
          cursor: text;

          &:hover {
            border-color: ${visualState === 'error'
              ? colors.error
              : enabled[variant].borderHover};
          }

          > ${TextField} {
            color: ${enabled[variant].color};

            &::placeholder {
              color: ${enabled[variant].placeholder};
            }
          }

          ${Suffix} {
            color: ${enabled[variant].color};
          }
        `}
  ${({ isReadOnlySelectable }) =>
    isReadOnlySelectable
      ? css`
          > ${TextField} {
            cursor: text;
          }
        `
      : null}
`;

export const Action = styled.div`
  padding: 8px;
  color: ${({ theme }) => theme.inputs.text.action.color};
  transition: color 150ms;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.inputs.text.action.hover};
  }
`;

export const ClearIcon = styled.div`
  width: 20px;
  height: 20px;
  text-align: center;
`;

export const Suffix = styled.span`
  padding: 8px;
  margin-right: 2px;
  ${({ theme }) => theme.fonts.regular.normal}
`;

export const ActionWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;

  > ${Action} + ${Action}, > ${Action} + ${Suffix} {
    padding-left: 0;
  }
`;
