import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MenuRoot = styled.div`
  width: 240px;
  background-color: transparent;
  padding-right: 8px;
  ${({ theme }) => theme.fonts.light.normal}
`;

export const Option = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  padding: 0 30px 0 10px;
  display: flex;
  height: 34px;
  align-items: center;
  position: relative;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-decoration: none;

  ${({ theme, isSelected }) =>
    isSelected
      ? css`
          background: ${theme.inputs.dropdown.selectedBackground};
          color: ${theme.inputs.dropdown.selectedColor};
          ${theme.fonts.bold.small}
        `
      : css`
          background: ${theme.inputs.dropdown.background};
          color: ${theme.inputs.dropdown.color};
          ${theme.fonts.regular.small}

          &:hover {
            background: ${theme.inputs.dropdown.hoverBackground};
            color: ${theme.inputs.dropdown.hoverColor};
            ${theme.fonts.bold.small}
          }
        `}
`;

export const Separator = styled.div`
  background: ${({ theme }) => theme.inputs.dropdown.background};
  border-top: 1px solid ${({ theme }) => theme.inputs.dropdown.borderColor};
  margin: 5px 0;
`;

export const Name = styled.div`
  width: 188px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 34px;
`;

export const State = styled.div`
  width: 20px;

  svg {
    margin: 0;
  }
`;

export const StateIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.inputs.dropdown.selectedColor};
`;
