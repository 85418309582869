import React from 'react';
import Router from './Router';
import Theme from './common/themes';
import history from './utils/history';
import i18n from './i18n';
import store from './store';
import { ConnectedRouter } from 'connected-react-router';
import { GlobalErrorBoundary } from './GlobalErrorBoundary';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ScrollToTop } from './components/common/ScrollToTop';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './common/common.styles';

const App = () => {
  const props = {
    history: history,
  };
  return (
    <Provider store={store}>
      <ConnectedRouter {...props}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={Theme}>
            <>
              <GlobalStyle />
              <GlobalErrorBoundary>
                <ScrollToTop>
                  <Router />
                </ScrollToTop>
              </GlobalErrorBoundary>
            </>
          </ThemeProvider>
        </I18nextProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
