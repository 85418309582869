import styled, { css } from 'styled-components';

type OverlayType = {
  scrollable?: boolean;
};

export const Overlay = styled.div<OverlayType>`
  ${({ theme }) => theme.fonts.bold.big}
  display: flex;
  width: ${({ scrollable }) => (scrollable ? '100%' : '100vw')};
  height: ${({ scrollable }) => (scrollable ? '100%' : '100vh')};
  top: 0;
  left: 0;
  background: rgba(248, 248, 248, 0.9);
  position: ${({ scrollable }) => (scrollable ? 'absolute' : 'fixed')};
  line-height: 1.4;
  color: ${({ theme }) => theme.basic.colors.atlas};
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: ${({ scrollable }) => (scrollable ? 999 : 100900)};

  ${({ scrollable }) =>
    scrollable &&
    css`
      svg {
        margin-bottom: 30px;
      }
    `}
`;

export const Text = styled.h1`
  color: ${({ theme }) => theme.atlas.text.primary};
  ${({ theme }) => theme.fonts.light.big}
`;
