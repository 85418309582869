import {
  Uuid,
  QuestionDefinitionSummaryType,
} from '../../../common/common.types';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';
import update from 'immutability-helper';
import { map } from 'ramda';

export type UpdateQuestionSscIssuesFunctionType = (
  questionId: Uuid,
  sscIssues: string[],
) => void;

export type UpdateQuestionSscIssuesActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/UPDATE_QUESTION_SSC_ISSUES'
>;

export const updateQuestionSscIssues = (
  questionId: Uuid,
  sscIssues: string[],
): UpdateQuestionSscIssuesActionType => ({
  type: '@formCreator/EVENTS/UPDATE_QUESTION_SSC_ISSUES',
  payload: {
    events: [
      {
        action: FormEditEventActionCodeEnum.UPDATEQUESTION,
        question_uuid: questionId,
        ssc_issues: sscIssues,
      },
    ],
    selector: ({ formCreator }) =>
      update(formCreator, {
        currentForm: {
          questions: {
            $apply: map(
              (q): QuestionDefinitionSummaryType =>
                q.wizardId === questionId ? { ...q, sscIssues } : q,
            ),
          },
        },
      }),
  },
});
