/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyFormsStats,
    CompanyFormsStatsFromJSON,
    CompanyFormsStatsFromJSONTyped,
    CompanyFormsStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserInfoStats
 */
export interface UserInfoStats {
    /**
     * 
     * @type {CompanyFormsStats}
     * @memberof UserInfoStats
     */
    fillingStats: CompanyFormsStats;
    /**
     * The user is a first time logging Vendor.
     * @type {boolean}
     * @memberof UserInfoStats
     */
    isNewVendor: boolean;
    /**
     * Number of logins by given user
     * @type {number}
     * @memberof UserInfoStats
     */
    logins: number;
    /**
     * Number of requests sent to users company
     * @type {number}
     * @memberof UserInfoStats
     */
    requestsReceived: number;
    /**
     * Number of requests sent to users company that are archived
     * @type {number}
     * @memberof UserInfoStats
     */
    requestsReceivedArchived: number;
    /**
     * Number of requests sent by users company
     * @type {number}
     * @memberof UserInfoStats
     */
    requestsSent: number;
    /**
     * Number of requests sent by users company that are archived
     * @type {number}
     * @memberof UserInfoStats
     */
    requestsSentArchived: number;
}

export function UserInfoStatsFromJSON(json: any): UserInfoStats {
    return UserInfoStatsFromJSONTyped(json, false);
}

export function UserInfoStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfoStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fillingStats': CompanyFormsStatsFromJSON(json['filling_stats']),
        'isNewVendor': json['is_new_vendor'],
        'logins': json['logins'],
        'requestsReceived': json['requests_received'],
        'requestsReceivedArchived': json['requests_received_archived'],
        'requestsSent': json['requests_sent'],
        'requestsSentArchived': json['requests_sent_archived'],
    };
}

export function UserInfoStatsToJSON(value?: UserInfoStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filling_stats': CompanyFormsStatsToJSON(value.fillingStats),
        'is_new_vendor': value.isNewVendor,
        'logins': value.logins,
        'requests_received': value.requestsReceived,
        'requests_received_archived': value.requestsReceivedArchived,
        'requests_sent': value.requestsSent,
        'requests_sent_archived': value.requestsSentArchived,
    };
}


