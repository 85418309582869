import PageHeader from 'components/common/PageHeader';
import React, { useRef, useEffect, RefObject } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { Content, Wrapper } from 'common/common.styles';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

const FAQWrapper = styled.div`
  width: 60%;

  h2 {
    ${({ theme }) => theme.fonts.regular.big}
  }

  div {
    padding: 10px 0 40px;
  }

  ul {
    position: relative;
    padding: 8px;
    border-radius: 6px;
    margin-top: 20px;
    list-style-position: inside;
  }
`;

const scrollToRef = (ref: RefObject<HTMLDivElement>) =>
  window.scrollTo(0, ref.current!.offsetTop);

const useMountEffect = (effect: () => void) => useEffect(effect, []);

const FAQSection = ({ t }: TranslatePropsType) => {
  const ref = useRef(null);

  const shouldScroll = window.location.hash.includes('scrollToCredits');

  useMountEffect(() => (shouldScroll ? scrollToRef(ref) : undefined));

  return (
    <>
      <PageHeader title={t('heading') as string} />

      <Wrapper grey>
        <Content>
          <FAQWrapper>
            <h2>{t('headDocu') as string}</h2>
            <div>{ReactHtmlParser(t('parDocuHTML') as string)}</div>

            <h2>{t('headAPI') as string}</h2>
            <div>{ReactHtmlParser(t('parAPIHTML') as string)}</div>

            <h2>{t('headFixing') as string}</h2>
            <div>{ReactHtmlParser(t('parFixingHTML') as string)}</div>

            <h2>{t('headAtlasSecure') as string}</h2>
            <div>{ReactHtmlParser(t('parAtlasSecure') as string)}</div>

            <h2>{t('headFillOut') as string}</h2>
            <div>{ReactHtmlParser(t('parFillOutHTML') as string)}</div>

            <h2>{t('headShare') as string}</h2>
            <div>{ReactHtmlParser(t('parShareHTML') as string)}</div>

            <h2>{t('headTemplate') as string}</h2>
            <div>{ReactHtmlParser(t('parTemplateHTML') as string)}</div>

            <h2>{t('headView') as string}</h2>
            <div>{ReactHtmlParser(t('parViewHTML') as string)}</div>

            <h2>{t('headCredits') as string}</h2>
            <div ref={ref}>
              {ReactHtmlParser(t('parCreditsHTML') as string)}
            </div>

            <h2>{t('headAddCredits') as string}</h2>
            <div>{ReactHtmlParser(t('parAddCreditsHTML') as string)}</div>

            <h2>{t('headExpiredCredits') as string}</h2>
            <div>{ReactHtmlParser(t('parExpiredCreditsHTML') as string)}</div>

            {/*
              ATL-1855: Disabling new idea link since it is no longer supported.
              Keeping reference in case we get a new link
            */}
            {/* <h2>{t('headNewIdea') as string}</h2>
            <div>{ReactHtmlParser(t('parNewIdeaHTML') as string)}</div> */}

            <h2>{t('headWho') as string}</h2>
            <div>{ReactHtmlParser(t('parWhoHTML') as string)}</div>
          </FAQWrapper>
        </Content>
      </Wrapper>
    </>
  );
};

export default withTranslation('FAQ')(FAQSection);
