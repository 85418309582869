/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    Team,
    TeamFromJSON,
    TeamFromJSONTyped,
    TeamToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserDetail
 */
export interface UserDetail {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof UserDetail
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof UserDetail
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    id: string;
    /**
     * Has the user ever signed into the system?
     * @type {boolean}
     * @memberof UserDetail
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    lastName: string;
    /**
     * 
     * @type {Company}
     * @memberof UserDetail
     */
    company: Company;
    /**
     * - `NORMAL` indicates that user is fully initialized and can perform all actions
     * - `MERGING` indicates that entities that belong to the company where user was before are being
     *   merged into their current company
     * - `DELETED` indicates that the user was deleted - it is not used now
     * @type {string}
     * @memberof UserDetail
     */
    internalStatus: UserDetailInternalStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDetail
     */
    roles: Array<UserDetailRolesEnum>;
    /**
     * Teams this user belongs to
     * @type {Array<Team>}
     * @memberof UserDetail
     */
    teams: Array<Team>;
}

export function UserDetailFromJSON(json: any): UserDetail {
    return UserDetailFromJSONTyped(json, false);
}

export function UserDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'companyId': json['company_id'],
        'displayName': json['display_name'],
        'domain': json['domain'],
        'email': json['email'],
        'firstName': json['first_name'],
        'fullName': json['full_name'],
        'id': json['id'],
        'isActive': json['is_active'],
        'lastName': json['last_name'],
        'company': CompanyFromJSON(json['company']),
        'internalStatus': json['internal_status'],
        'roles': json['roles'],
        'teams': ((json['teams'] as Array<any>).map(TeamFromJSON)),
    };
}

export function UserDetailToJSON(value?: UserDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'company_id': value.companyId,
        'display_name': value.displayName,
        'domain': value.domain,
        'email': value.email,
        'first_name': value.firstName,
        'full_name': value.fullName,
        'id': value.id,
        'is_active': value.isActive,
        'last_name': value.lastName,
        'company': CompanyToJSON(value.company),
        'internal_status': value.internalStatus,
        'roles': value.roles,
        'teams': ((value.teams as Array<any>).map(TeamToJSON)),
    };
}

/**
* @export
* @enum {string}
*/
export enum UserDetailInternalStatusEnum {
    DELETED = 'DELETED',
    MERGING = 'MERGING',
    NORMAL = 'NORMAL',
    INVITED = 'INVITED'
}
/**
* @export
* @enum {string}
*/
export enum UserDetailRolesEnum {
    CUSTOMERADMIN = 'CUSTOMER_ADMIN',
    GUEST = 'GUEST',
    NORMAL = 'NORMAL',
    SALES = 'SALES',
    SYSTEMADMIN = 'SYSTEM_ADMIN'
}


