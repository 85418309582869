import { FormNewFromExistingStateType } from './formNewFromExisting.types';
import { FormNewFromExistingActionType } from './actions';
import {
  defaultSortBy,
  sortStandards,
} from 'utils/filters/formNewFromExisting.sorters';

export const initialState: FormNewFromExistingStateType = {
  standards: {
    data: [],
    filter: { sortBy: defaultSortBy },
  },
};

const formNewFromExistingReducer = (
  state: FormNewFromExistingStateType = initialState,
  action: FormNewFromExistingActionType,
): FormNewFromExistingStateType => {
  switch (action.type) {
    case '@formNewFromExisting/RESET_STANDARDS':
      return {
        ...state,
        standards: {
          data: [],
          filter: { sortBy: defaultSortBy },
        },
      };
    case '@formNewFromExisting/SET_SORT':
      return sortStandards(action.payload.sortBy)(state);
    default:
      return state;
  }
};

export default formNewFromExistingReducer;
