import { NavLink as NavLinkComponent } from 'react-router-dom';
import ReactSVG from 'react-svg';
import styled, { css } from 'styled-components';

type Bool = 0 | 1;

const navLinkStyle = (hover: Bool, padding: Bool) =>
  css`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none !important;
    padding: ${padding ? '0 12px' : 0};
    margin: ${padding ? 0 : '0 0 0 10px'};

    &:not(.active) span {
      margin-top: -1px;
    }

    span {
      ${({ theme }) => theme.fonts.regular.small}
    }

    ${hover === 0
      ? null
      : css`
          &:hover {
            background: ${({ theme }) => theme.basic.colors.grayDark};
          }

          &.active span {
            ${({ theme }) => theme.fonts.bold.small}
          }
        `}
  `;

type NavLinkType = {
  hover: Bool;
  padding: Bool;
};

export const NavLink = styled(NavLinkComponent)<NavLinkType>`
  ${({ hover, padding }) => navLinkStyle(hover, padding)}
`;

export const NavAnchor = styled.a<NavLinkType>`
  ${({ hover, padding }) => navLinkStyle(hover, padding)}
`;

export const Logo = styled(ReactSVG).attrs((props: any) => ({
  src: props.theme.nav.logo.src,
}))`
  svg {
    ${({
      theme: {
        nav: {
          logo: { width, height, marginRight, marginLeft },
        },
      },
    }) =>
      css`
        ${width && `width: ${width}`}
        ${height ? `height: ${height}` : 'height: 60px'}
        ${marginRight && `margin-right: ${marginRight}`}
        ${marginLeft && `margin-left: ${marginLeft}`}
      `};
  }
`;

export const NavIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  cursor: pointer;
  color: ${({ theme }) => theme.nav.icon.color};
  ${({ theme }) => theme.fonts.regular.large}
`;

export const NavSVGIcon = styled(ReactSVG)`
  height: 28px;

  svg {
    width: 28px;
    height: 28px;
    padding: 6px;
  }
`;

export const UserIcon = styled(NavSVGIcon)`
  svg {
    padding: 5px 3px 3px;
  }
`;

export const SendButton = styled.button`
  cursor: pointer;
  width: 84px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background: ${({ theme }) => theme.nav.button.bg};
  color: ${({ theme }) => theme.nav.button.text};
  border-radius: 3px;
  border: none;
  ${({ theme }) => theme.fonts.bold.small}
`;

export const MigrationOptIn = styled.div`
  min-width: 150px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #e5dffd;
  font-weight: 700;
  background-color: #222324;
  cursor: pointer;
`;
