import React from 'react';
import { Horizontal } from 'common/common.styles';
import { Item, Label, Wrapper } from './ToggleAllDropdown.styles';
import { Tab } from '../Question';
import { withTranslation } from 'react-i18next';
import {
  QuestionTabEnum,
  QuestionTabType,
  TranslatePropsType,
} from 'common/common.types';

interface ToggleAllDropdownPropsType extends TranslatePropsType {
  handleClick: (label: QuestionTabEnum) => void;
  isOpen: boolean;
  options: QuestionTabType[];
}

const ToggleAllDropdown = ({
  isOpen,
  handleClick,
  options,
  t,
}: ToggleAllDropdownPropsType) => (
  <Wrapper isOpen={isOpen}>
    {options.map(({ label, icon }) => (
      <Item key={`tab-toggle-${label}`} onClick={() => handleClick(label)}>
        <Horizontal>
          <Label>{t(label) as string}</Label>

          <Tab hasCount={false} icon={icon} title={t(label)} />
        </Horizontal>
      </Item>
    ))}
  </Wrapper>
);

export default withTranslation('ToggleAllDropdown')(ToggleAllDropdown);
