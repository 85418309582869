import { Company, CompanyGradeEnum } from 'generated/models/Company';
import {
  CompanySimple,
  CompanySimpleGradeEnum,
} from 'generated/models/CompanySimple';
import { CreditHistoryRecord } from 'generated/models/CreditHistoryRecord';
import { CreditRecord } from 'generated/models/CreditRecord';
import { CreditsDetail } from 'generated/models/CreditsDetail';
import { parseCompletedStandard } from './standard.parsers';
import { parseRequestSummaryList, parseCompanyTagDetails } from './';
import {
  AutocompleteCompanyType,
  CompanyCreditsType,
  CompanyDetailType,
  CompanySimpleType,
  CompanyType,
  CompanyWithTagsType,
  CompletedStandardType,
  CreditsHistoryItemType,
  CreditsListItemType,
  ListedCompanyType,
} from 'common/common.types';
import { isFullFledgedCompany } from 'components/Companies/utils';
import { EMPTY_ID } from 'common/common.constants';
import {
  CompanyDetail,
  CompanyPartner,
  CompletedStandard,
} from 'generated/models';

export const parseCompany = (data: Company): CompanyType => {
  const {
    id = EMPTY_ID,
    name = '',
    domain = '',
    industry = '',
    internal_status,
    logo = '',
    score = 0,
    grade = CompanyGradeEnum.Empty,
    grade_url = '',
    requests_email = '',
  } = data;

  return {
    id,
    name,
    domain,
    industry,
    internalStatus: internal_status,
    isVerified: isFullFledgedCompany(internal_status),
    logo,
    score,
    grade,
    gradeUrl: grade_url,
    requestsEmail: requests_email,
  };
};

export const parseCompanyWithTags = (data: any): CompanyWithTagsType => {
  const { company, tags = [] } = data;

  return {
    info: parseCompany(company),
    tags: parseCompanyTagDetails(tags),
  };
};

const parseCreditsList = (list: CreditRecord[]): CreditsListItemType[] =>
  list.map(({ expires_at, is_expired, remaining }) => ({
    expiresAt: new Date(expires_at),
    isExpired: is_expired,
    remaining,
  }));

const parseCreditsHistory = (list: CreditHistoryRecord[]) =>
  list.map(
    ({ amount, created_at }): CreditsHistoryItemType => ({
      amount,
      createdAt: new Date(created_at),
    }),
  );

export const parseCompanyCredits = ({
  active_list,
  charging_history = [],
  summary,
}: CreditsDetail): CompanyCreditsType => ({
  history: parseCreditsHistory(charging_history || []),
  list: parseCreditsList(active_list || []),
  summary,
});

export const parseCompanyCompletedStandards = (
  list: CompletedStandard[],
): CompletedStandardType[] => list.map(parseCompletedStandard);

export const parseCompanyDetail = (
  data: Partial<CompanyDetail>,
): CompanyDetailType => ({
  company: parseCompanyWithTags(
    data.company_with_tags || { company: {}, tags: [] },
  ),
  requests: {
    in: parseRequestSummaryList(data.requests_in || []),
    out: parseRequestSummaryList(data.requests_out || []),
  },
  completedStandards: data.completed_standards
    ? parseCompanyCompletedStandards(data.completed_standards.list || [])
    : [],
});

export const parseListedCompany = (
  data: CompanyPartner,
): ListedCompanyType => ({
  company: parseCompanyWithTags(
    data.company_with_tags || { company: {}, tags: [] },
  ),
  requestsReceivedCount: data.requests_received,
  requestsSentCount: data.requests_sent,
  lastRequestSentDate: data.last_request_sent_date,
  lastRequestSubmittedDate: data.last_request_submitted_date,
  nextRequestScheduledSend: data.next_request_scheduled_send,
});

export const parseAutocompleteCompany = ({
  domain,
  id,
  name,
}: Company): AutocompleteCompanyType => ({
  label: `${name} <${domain}>`,
  name,
  value: id,
});

export const parseCompanySimple = (data: CompanySimple): CompanySimpleType => {
  const {
    id = EMPTY_ID,
    name = '',
    grade = CompanySimpleGradeEnum.Empty,
    grade_url = '',
    score = 0,
    internal_status,
  } = data;

  return {
    id,
    name,
    grade,
    gradeUrl: grade_url,
    score,
    internalStatus: internal_status,
    isVerified: isFullFledgedCompany(internal_status),
  };
};
