import React from 'react';
import ReviewRadio from './ReviewRadio';
import { Box, Wrapper } from './Radios.styles';
import { QuestionDetailBaseVrmReviewStatusEnum } from 'generated/models/QuestionDetailBase';
import { VRMReviewStatsStatusEnum } from 'generated/models/VRMReviewStats';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from '../../../../common/common.types';

const { DONE, FLAGGED, PENDING } = QuestionDetailBaseVrmReviewStatusEnum;
/** Defines order in which status codes are rendered */
const DISPLAYED_STATUS_CODES: QuestionDetailBaseVrmReviewStatusEnum[] = [
  PENDING,
  FLAGGED,
  DONE,
];

interface RadiosPropsType extends TranslatePropsType {
  handleChange: (
    value: QuestionDetailBaseVrmReviewStatusEnum | VRMReviewStatsStatusEnum,
  ) => void;
  recordId: number | string;
  currentStatus?:
    | QuestionDetailBaseVrmReviewStatusEnum
    | VRMReviewStatsStatusEnum;
}

const Radios = ({
  recordId,
  currentStatus,
  handleChange,
  t,
}: RadiosPropsType) => (
  <Wrapper currentStatus={currentStatus}>
    <Box currentStatus={currentStatus} data-test="question-status">
      {DISPLAYED_STATUS_CODES.map(r => (
        <ReviewRadio
          isSelected={r === currentStatus}
          key={`review-${recordId}-${r}`}
          label={t(`filters.vrm_review_${r.toLowerCase()}`)}
          status={r}
          name={`review-${recordId}`}
          handleChange={handleChange}
        />
      ))}
    </Box>
  </Wrapper>
);

export default withTranslation('FilterBar')(Radios);
