import { FrameworkType } from '../../../common/common.types';

export interface UserSelectFrameworkActionType {
  type: '@user/SELECT_FRAMEWORK';
  payload: {
    framework?: FrameworkType;
  };
}

export const selectFramework = (
  framework?: FrameworkType,
): UserSelectFrameworkActionType => ({
  type: '@user/SELECT_FRAMEWORK',
  payload: {
    framework,
  },
});
