/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormShortSummary,
    FormShortSummaryFromJSON,
    FormShortSummaryFromJSONTyped,
    FormShortSummaryToJSON,
    QuestionDetailBase,
    QuestionDetailBaseFromJSON,
    QuestionDetailBaseFromJSONTyped,
    QuestionDetailBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResponsePropagationInstruction
 */
export interface ResponsePropagationInstruction {
    /**
     * 
     * @type {Array<FormShortSummary>}
     * @memberof ResponsePropagationInstruction
     */
    forms: Array<FormShortSummary>;
    /**
     * 
     * @type {number}
     * @memberof ResponsePropagationInstruction
     */
    match: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePropagationInstruction
     */
    matchCode: ResponsePropagationInstructionMatchCodeEnum;
    /**
     * 
     * @type {QuestionDetailBase}
     * @memberof ResponsePropagationInstruction
     */
    question?: QuestionDetailBase;
    /**
     * 
     * @type {string}
     * @memberof ResponsePropagationInstruction
     */
    sourceId: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePropagationInstruction
     */
    targetId: string;
    /**
     * 
     * @type {Date}
     * @memberof ResponsePropagationInstruction
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ResponsePropagationInstruction
     */
    value: string;
}

export function ResponsePropagationInstructionFromJSON(json: any): ResponsePropagationInstruction {
    return ResponsePropagationInstructionFromJSONTyped(json, false);
}

export function ResponsePropagationInstructionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponsePropagationInstruction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'forms': ((json['forms'] as Array<any>).map(FormShortSummaryFromJSON)),
        'match': json['match'],
        'matchCode': json['match_code'],
        'question': !exists(json, 'question') ? undefined : QuestionDetailBaseFromJSON(json['question']),
        'sourceId': json['source_id'],
        'targetId': json['target_id'],
        'updatedAt': (new Date(json['updated_at'])),
        'value': json['value'],
    };
}

export function ResponsePropagationInstructionToJSON(value?: ResponsePropagationInstruction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'forms': ((value.forms as Array<any>).map(FormShortSummaryToJSON)),
        'match': value.match,
        'match_code': value.matchCode,
        'question': QuestionDetailBaseToJSON(value.question),
        'source_id': value.sourceId,
        'target_id': value.targetId,
        'updated_at': (value.updatedAt.toISOString()),
        'value': value.value,
    };
}

/**
* @export
* @enum {string}
*/
export enum ResponsePropagationInstructionMatchCodeEnum {
    FULL = 'FULL',
    PARTIAL = 'PARTIAL',
    NONE = 'NONE'
}


