import React from 'react';
import { getBarWidth } from '../utils';
import { RequestSimpleType, TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import GradeRecord from './GradeRecord';
import { ChartGauge } from '../Insights.styles';

export interface GradeChartPropsType extends TranslatePropsType {
  requests: RequestSimpleType[];
}

const GradeChart = ({ requests, t }: GradeChartPropsType) => (
  <ChartGauge title={t('charts.sscScore')}>
    {requests.map(
      ({ id, meta: { companyId }, target: { score, name, grade } }) => (
        <GradeRecord
          key={`grade-bar-${id}`}
          companyId={companyId}
          grade={grade}
          name={name}
          width={getBarWidth(requests[0].target.score, score, 90)}
        >
          {score || (t('na') as string)}
        </GradeRecord>
      ),
    )}
  </ChartGauge>
);

export default withTranslation('Insights')(GradeChart);
