import React from 'react';
import ReactSVG from 'react-svg';
import { Count, TabWrapper, TabIcon, TabIconSvgWrapper } from './Tab.styles';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { always } from 'ramda';
import { QuestionTabEnum } from 'common/common.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface TabPropsType {
  active?: boolean;
  hasCount: boolean;
  hasNotifications?: boolean;
  icon: IconDefinition | QuestionTabEnum.SSC_DATA;
  id?: string;
  notificationsCount?: number;
  onClick?: () => void;
  title?: string;
}

const Tab = ({
  active,
  hasCount,
  hasNotifications,
  icon,
  id,
  notificationsCount,
  onClick = always(null),
  title = '',
}: TabPropsType) => (
  <TabWrapper
    data-cy={`question-tab${id ? '-' + id : ''}`}
    active={active}
    hasCount={hasCount}
    onClick={onClick}
    title={title}
  >
    {icon === QuestionTabEnum.SSC_DATA ? (
      <TabIconSvgWrapper color={hasCount && !active ? 'black' : 'white'}>
        <ReactSVG className="icon" src="ssc-tab.svg" />
      </TabIconSvgWrapper>
    ) : (
      <TabIcon
        icon={icon as IconProp}
        color={hasCount && !active ? 'black' : 'white'}
      />
    )}

    {hasNotifications && <Count>{notificationsCount}</Count>}
  </TabWrapper>
);

export default Tab;
