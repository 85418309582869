/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PdfExportSummary
 */
export interface PdfExportSummary {
    /**
     * 
     * @type {string}
     * @memberof PdfExportSummary
     */
    exportUuid: string;
}

export function PdfExportSummaryFromJSON(json: any): PdfExportSummary {
    return PdfExportSummaryFromJSONTyped(json, false);
}

export function PdfExportSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdfExportSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exportUuid': json['export_uuid'],
    };
}

export function PdfExportSummaryToJSON(value?: PdfExportSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'export_uuid': value.exportUuid,
    };
}


