import styled from 'styled-components';
import { StylesConfig } from 'react-select/lib/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const Wrapper = styled.div`
  padding: 22px 28px 22px 45px;
  background: ${({ theme }) => theme.atlas.grey[50]};
  > * + * {
    margin-top: 16px;
  }
`;

export const WrapperForLabelWithInputBelow = styled.div`
  > * + * {
    margin-top: 8px;
  }
`;

export const InfoIcon = styled(FontAwesomeIcon).attrs({
  icon: faInfoCircle as IconProp,
})`
  margin-left: 0.25em;
  color: rgba(0, 0, 0, 0.15);
`;

export const issuesSelectStyles: StylesConfig = {
  groupHeading: provided => ({
    ...provided,
    color: '#5d5d5d',
    marginBottom: 0,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: '95%',
  }),
  option: provided => ({
    ...provided,
    paddingLeft: 24,
  }),
};
