import { assocPath, keys } from 'ramda';
import { OptimisticUpdateActionType } from '../optimistic.types';
import { saveCurrentStatePartition } from './saveCurrentStatePartition';
import { StateType } from 'store/store.types';

export const addRollbackInfo = (
  action: OptimisticUpdateActionType,
  state: StateType,
): StateType =>
  assocPath(
    ['optimisticTransactions', action.transactionId],
    saveCurrentStatePartition(keys(action.payload.expectedStateChange), state),
    state,
  );
