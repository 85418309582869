import React, { useMemo } from 'react';
import {
  FormDefinitionValidationType,
  TranslatePropsType,
} from 'common/common.types';
import { withTranslation } from 'react-i18next';
import {
  Buttons,
  CloseButton,
  ConfirmButton,
  Text,
  Title,
} from '../../common/ConfirmModal/ConfirmModal.styles';
import Modal from 'utils/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  List,
  Wrapper,
  TitleContainer,
  Paragraph,
} from './PreviewErrorModal.styles';
import { calculateIssues } from '../PublishModal/utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface PreviewErrorModalPropsType extends TranslatePropsType {
  invalidCount: number;
  modalId: string;
  onCancelClick: () => void;
  validation: FormDefinitionValidationType;
  hideModal: (modalId: string) => void;
  showModal: (modalId: string) => void;
  handlePreviewForm: () => void;
  setIsPreviewErrorModal: (value: string) => void;
  isPreviewErrorModal: string;
}

interface IssuesType {
  totalCount: number;
  questionCount: number;
  foundIssues: { [key: string]: number };
}

const PreviewErrorModalPropsType = ({
  modalId,
  validation,
  invalidCount,
  t,
  hideModal,
  setIsPreviewErrorModal,
}: PreviewErrorModalPropsType) => {
  const { totalCount, foundIssues } = useMemo<IssuesType>(
    () => ({
      totalCount: invalidCount,
      ...calculateIssues([
        ...validation.invalidQuestions,
        ...validation.invalidResponses,
      ]),
    }),
    [validation, invalidCount],
  );
  const modalProps = {
    modalId: modalId,
    custom: true,
  };

  return (
    <Modal {...modalProps}>
      <Wrapper>
        <CloseButton
          onClick={() => {
            hideModal(modalId);
            setIsPreviewErrorModal('');
          }}
        >
          <FontAwesomeIcon icon={faTimes as IconProp} />
        </CloseButton>
        <Text>
          <TitleContainer>
            <Title>{t('previewErrorModal.title') as string}</Title>
            <Paragraph>
              {t('publishModal.foundIssue', { count: totalCount }) as string}
            </Paragraph>

            <List>
              {Object.keys(foundIssues).map(code => (
                <li key={code}>
                  {
                    t('publishModal.issue', {
                      context: code,
                      count: foundIssues[code],
                    }) as string
                  }
                </li>
              ))}
            </List>
          </TitleContainer>
        </Text>
        <Buttons spacingSize={29}>
          <ConfirmButton
            onClick={() => {
              hideModal(modalId);
              setIsPreviewErrorModal('');
            }}
          >
            {t('publishModal.reviewIssue', { count: totalCount }) as string}
          </ConfirmButton>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

export default withTranslation('FormCreator')(PreviewErrorModalPropsType);
