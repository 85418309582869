import React from 'react';
import ReactSVG from 'react-svg';
import { CTAButton, Gradient, Question, Tip, Wrapper } from './FirstRun.styles';
import { Centered } from 'common/common.styles';
import formatRoute, { FRAMEWORKS_DASHBOARD, SEND_REQUEST } from '../../routes';
import { Link } from 'react-router-dom';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

const FirstRun = ({ t }: TranslatePropsType) => {
  const areYouVRMLabel = t('FirstRun.areYouVRM');
  const sendYourFirstLabel = t('FirstRun.sendYourFirst');
  const tipVRMLabel = t('FirstRun.tipVRM');
  const areYouVendorLabel = t('FirstRun.areYouVendor');
  const buildAnswerBaseLabel = t('FirstRun.buildAnswerBase');

  return (
    <Wrapper>
      <Gradient data-test="left-part">
        <Centered>
          <ReactSVG
            style={{
              width: 109,
              height: 146,
            }}
            src="/new-document.svg"
          />

          <Question>{areYouVRMLabel}</Question>

          <Link to={formatRoute(SEND_REQUEST, {})}>
            <CTAButton>{sendYourFirstLabel}</CTAButton>
          </Link>

          <Tip>{tipVRMLabel}</Tip>
        </Centered>
      </Gradient>

      <Gradient flip data-test="right-part">
        <Centered>
          <ReactSVG
            style={{
              width: 275,
              height: 146,
            }}
            src="/answer-base.svg"
          />

          <Question>{areYouVendorLabel}</Question>

          <Link to={formatRoute(FRAMEWORKS_DASHBOARD, {})}>
            <CTAButton>{buildAnswerBaseLabel}</CTAButton>
          </Link>

          <Tip>
            <>
              {t('FirstRun.tipVendorP1')}

              <br />

              {t('FirstRun.tipVendorP2')}
            </>
          </Tip>
        </Centered>
      </Gradient>

      <ReactSVG
        style={{
          position: 'absolute',
          bottom: 111,
          width: 385,
          height: 210,
          left: '50%',
          marginLeft: -192.5,
        }}
        src="/triangles.svg"
      />
    </Wrapper>
  );
};

export default withTranslation()(FirstRun);
