/*eslint no-unexpected-multiline: "off"*/
import styled from 'styled-components';

const HORIZONTAL_LINE_LEFT_OFFSET = 10;
const HORIZONTAL_LINE_LENGTH = 75;
const LEGEND_TEXT_LEFT_OFFSET = 87;
const SSC_LEFT_OFFSET = 4;
const SSC_LINE_LENGTH = 78;
const LABEL_DIRECTION_BREAKPOINT = 50;

export const Grid = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

export const HorizontalLine = styled.div<{ y: number }>`
  border-bottom: 2px dotted #eaeaea;
  margin-bottom: -1px;
  position: absolute;
  top: ${({ y }: { y: number }) => y}%;
  left: ${HORIZONTAL_LINE_LEFT_OFFSET}%;
  width: ${HORIZONTAL_LINE_LENGTH}%;
`;

export const ThickHorizontalLine = styled.div<{ y: number }>`
  border-bottom: 2px solid #5d5d5d;
  margin-bottom: -1px;
  position: absolute;
  left: ${SSC_LEFT_OFFSET}%;
  top: ${({ y }: { y: number }) => y}%;
  width: ${SSC_LINE_LENGTH}%;
`;

export const VerticalLine = styled.div<{ x: number }>`
  border-left: 2px dotted #eaeaea;
  height: 100%;
  left: ${({ x }: { x: number }) => x}%;
  margin-left: -1px;
  position: absolute;
`;

export const HexagonPositioner = styled.div<{
  xy: { x: number; y: number };
  size: number;
}>`
  left: ${({ xy }: { xy: { x: number; y: number } }) => xy.x}%;
  margin-left: ${({ size }: { size: number }) => -size / 2}px;
  margin-top: ${({ size }: { size: number }) => -size / 2}px;
  position: absolute;
  top: ${({ xy }: { xy: { x: number; y: number } }) => xy.y}%;
`;

export const HexagonsWrapper = styled.div``;

export const SscGradeText = styled.div<{ y: number }>`
  ${({ theme }) => theme.fonts.bold.normal}
  color: #5d5d5d;
  text-align: right;
  position: absolute;
  margin-top: -15px;
  left: ${SSC_LEFT_OFFSET}%;
  top: ${({ y }: { y: number }) => y}%;
`;

export const LegendText = styled.div<{ y: number }>`
  ${({ theme }) => theme.fonts.bold.normal}
  color: #5d5d5d;
  text-align: left;
  position: absolute;
  margin-top: -7px;
  left: ${LEGEND_TEXT_LEFT_OFFSET}%;
  top: ${({ y }: { y: number }) => y}%;
`;

export const Result = styled.div<{ size: number; x: number }>`
  position: absolute;
  top: 50%;
  margin-top: -23px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  ${({ x }: { x: number }) => {
    if (x > LABEL_DIRECTION_BREAKPOINT) {
      return {
        textAlign: 'right',
        right: '100%',
        marginRight: 2,
      };
    }
    return null;
  }};

  ${({ x }: { x: number }) => {
    if (x < LABEL_DIRECTION_BREAKPOINT) {
      return {
        textAlign: 'left',
        left: '100%',
        marginLeft: 2,
      };
    }
    return null;
  }};

  ${HexagonPositioner}:hover & {
    opacity: 1;
  }
`;

export const ResultNumber = styled.span`
  ${({ theme }) => theme.fonts.semibold.huge}
  line-height: 20px;
  margin-bottom: -10px;
`;

export const BigText = styled.span`
  line-height: 9px;
  margin-top: -20px;
  ${({ theme }) => theme.fonts.semibold.normal}
`;
