import React from 'react';
import { ResponseInputPropsType } from '../../Response';
import { Select as StyledSelect } from './Select.styles';
import ViewOptions from '../ViewOptions';
import isGenericLabel from 'utils/isGenericLabel';
import { Label } from '../../../Form.styles';
import { ValueLabelType } from 'common/common.types';
import { OPTION_EMPTY } from '../../utils/normalizeOption';

type SelectOption = ValueLabelType<string>;

const Select = ({
  actions,
  data: { isEditable, label, options, placeholder, value },
}: ResponseInputPropsType) => {
  const handleChange = (selected: SelectOption) => {
    actions.handleChange(selected.label);
  };
  const possibleOptions: SelectOption[] = [
    OPTION_EMPTY,
    ...(options || []).filter(option => option !== OPTION_EMPTY),
  ].map(option => ({ value: option, label: option }));
  const selectedValue: SelectOption = { value: value, label: value };

  const viewOptionsProps = {
    options: options,
    selectedOption: value,
  };

  return (
    <div>
      {label && !isGenericLabel(label) && <Label>{label}</Label>}

      <StyledSelect
        classNamePrefix={'Select'}
        isDisabled={!isEditable}
        onChange={handleChange}
        options={possibleOptions}
        placeholder={placeholder}
        value={selectedValue}
      />
      {!isEditable && <ViewOptions {...viewOptionsProps} />}
    </div>
  );
};

export default Select;
