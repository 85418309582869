import styled from 'styled-components';

interface ItemType {
  selected: boolean;
}

export const Wrapper = styled.div`
  display: inline-block;
  margin-top: 5px;
`;

export const Text = styled.span`
  ${({ theme }) => theme.fonts.light.small};
  padding-left: 8px;
  text-decoration: underline;
  cursor: pointer;
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  max-width: 200px;
  min-width: 100px;
  max-height: 300px;
  background-color: ${({ theme }) => theme.basic.colors.light};
  box-shadow: 1px 6px 4px 0 ${({ theme }) => theme.basic.colors.lightBorder};
  overflow: hidden;
  overflow-y: auto;
  z-index: 10000;
  border-radius: 4px;
  margin-top: 5px;
  border: 1px solid ${({ theme }) => theme.basic.colors.darkerBorder};
`;

export const Item = styled.div<ItemType>`
  padding: 8px 12px;
  width: 100%;
  background-color: ${({ selected, theme }) =>
    selected ? theme.atlas.teal.primary : theme.atlas.white};
  color: ${({ selected, theme }) =>
    selected ? theme.atlas.white : theme.basic.colors.disabled};
`;
