/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionMappingFeedback,
    QuestionMappingFeedbackFromJSON,
    QuestionMappingFeedbackFromJSONTyped,
    QuestionMappingFeedbackToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionMappingFeedbackResponse
 */
export interface QuestionMappingFeedbackResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof QuestionMappingFeedbackResponse
     */
    msg: string;
    /**
     * 
     * @type {QuestionMappingFeedback}
     * @memberof QuestionMappingFeedbackResponse
     */
    entry: QuestionMappingFeedback;
}

export function QuestionMappingFeedbackResponseFromJSON(json: any): QuestionMappingFeedbackResponse {
    return QuestionMappingFeedbackResponseFromJSONTyped(json, false);
}

export function QuestionMappingFeedbackResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionMappingFeedbackResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': QuestionMappingFeedbackFromJSON(json['entry']),
    };
}

export function QuestionMappingFeedbackResponseToJSON(value?: QuestionMappingFeedbackResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': QuestionMappingFeedbackToJSON(value.entry),
    };
}


