import React from 'react';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { ResponseStatus } from 'components/ResponseStatus';

const NotAvailable = ({ t }: TranslatePropsType) => (
  <ResponseStatus
    heading={t('NotAvailable.caption')}
    caption={''}
    goLink={''}
  />
);

export default withTranslation()(NotAvailable);
