/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";
// @ts-ignore
import { EvidenceLockerAssociatedEntitySummary } from "./EvidenceLockerAssociatedEntitySummary";

/**
 * 
 * @export
 * @interface EvidenceLockerEntity
 */
export interface EvidenceLockerEntity {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof EvidenceLockerEntity
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof EvidenceLockerEntity
     */
    updated_at: Date;
    /**
     * Summary of the entity that's associated with this evidence entity.
     * @type {EvidenceLockerAssociatedEntitySummary}
     * @memberof EvidenceLockerEntity
     */
    associated_entity_data?: EvidenceLockerAssociatedEntitySummary;
    /**
     * Category of this entity
     * @type {string}
     * @memberof EvidenceLockerEntity
     */
    category: string;
    /**
     * ID of the evidence locker entity
     * @type {string}
     * @memberof EvidenceLockerEntity
     */
    id: string;
    /**
     * Import type of this evidence entity
     * @type {string}
     * @memberof EvidenceLockerEntity
     */
    import_type: EvidenceLockerEntityImportTypeEnum;
    /**
     * Domain of the user who imported this entity
     * @type {string}
     * @memberof EvidenceLockerEntity
     */
    importer_domain: string;
    /**
     * Email ID of the user who imported this entity
     * @type {string}
     * @memberof EvidenceLockerEntity
     */
    importer_email: string;
    /**
     * View permission of this entity
     * @type {string}
     * @memberof EvidenceLockerEntity
     */
    permission: EvidenceLockerEntityPermissionEnum;
    /**
     * Validity start date
     * @type {Date}
     * @memberof EvidenceLockerEntity
     */
    validity_end: Date;
    /**
     * Validity start date
     * @type {Date}
     * @memberof EvidenceLockerEntity
     */
    validity_start: Date;
}




/**
* @export
* @enum {string}
*/
export enum EvidenceLockerEntityImportTypeEnum {
    ATLAS = 'ATLAS',
    EXTERNALLINK = 'EXTERNAL_LINK',
    UPLOAD = 'UPLOAD'
}
/**
* @export
* @enum {string}
*/
export enum EvidenceLockerEntityPermissionEnum {
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC'
}


