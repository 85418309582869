import React from 'react';
import { Button, Wrapper } from './ShowHidden.styles';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface ShowHiddenPropsType extends TranslatePropsType {
  count: number;
  showHidden: boolean;
  toggleHiding: (showHiddenFrameworks: boolean) => void;
}

const ShowHidden = ({
  count,
  showHidden,
  toggleHiding,
  t,
}: ShowHiddenPropsType) => {
  const label = t(showHidden ? 'concealHidden' : 'showHidden', { count });
  return (
    <Wrapper>
      <Button onClick={() => count > 0 && toggleHiding(!showHidden)}>
        {label}
      </Button>
    </Wrapper>
  );
};

export default withTranslation('EvidenceLocker')(ShowHidden);
