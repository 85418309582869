import React, { useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from '../EmailReceivers.styles';
import { LabelSpaced } from '../../Stepper.styles';
import { Select } from '../../../common/Select';
import {
  TargetItemType,
  TeamsItemType,
  ValueLabelType,
} from 'common/common.types';
import { Team } from 'generated/new';
import { SetFormValueFunctionType } from 'store/requests/actions';

type TeamSelectorPropsType = {
  label?: string;
  teams: Team[] | null;
  selected: TeamsItemType | TargetItemType | undefined;
  setValue: SetFormValueFunctionType;
  disabled?: boolean;
  internalSend?: boolean;
};

const TeamSelector: React.FC<TeamSelectorPropsType> = ({
  label,
  teams,
  selected,
  setValue,
  disabled,
  internalSend,
}) => {
  const { t } = useTranslation('SendRequest');
  const teamsAsOptions: Array<ValueLabelType<string>> | null = useMemo(
    () =>
      teams ? teams.map(team => ({ value: team.id, label: team.name })) : null,
    [teams],
  );
  if (
    teamsAsOptions &&
    !teamsAsOptions.some(team => team.value === 'me') &&
    !internalSend
  ) {
    teamsAsOptions.unshift({ label: 'Send as myself', value: 'me' });
  }
  const onTeamChange = useCallback(
    (option: TargetItemType) => {
      setValue('sourceTeam', option || undefined);
    },
    [setValue],
  );
  const effectiveLabel = label || t('internal.sendAs');
  const areTeamsLoaded = teamsAsOptions !== null;
  const effectiveSelectedOption =
    !selected && teamsAsOptions && teamsAsOptions.length > 0
      ? teamsAsOptions[0]
      : selected;
  useEffect(() => {
    setValue('sourceTeam', effectiveSelectedOption);
  }, [effectiveSelectedOption, setValue]);

  return (
    <Wrapper>
      <LabelSpaced>{effectiveLabel}</LabelSpaced>
      <Select
        isDisabled={!areTeamsLoaded || disabled}
        onChange={onTeamChange}
        options={teamsAsOptions}
        placeholder={
          areTeamsLoaded ? t('internal.selectTeam') : t('internal.loadingTeams')
        }
        value={effectiveSelectedOption}
      />
    </Wrapper>
  );
};

export default TeamSelector;
