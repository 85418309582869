import { GetPreviewFunctionType } from './actions';

import { StandardType, Uuid } from '../../common/common.types';
import { StandardStatusEnum } from '../../generated/models/Standard';

export const getPreviewHelper = (
  standard: StandardType,
  definitionId: Uuid,
  {
    getPreview: getPreviewFunc,
    getPreviewForm,
  }: {
    getPreview: GetPreviewFunctionType;
    getPreviewForm: (id: Uuid) => void;
  },
) => {
  // TODO: Once we support conditional API calls, move this conditions
  //       into the action creator

  if (standard.status === StandardStatusEnum.DRAFT) {
    if (typeof standard.previewFormId === 'undefined') {
      console.warn('currentForm.standard.previewFormId is not defined');
      return;
    }
    getPreviewFunc({
      draftId: definitionId,
      previewFormId: standard.previewFormId,
    });
  } else {
    if (typeof standard.previewFormId === 'undefined') {
      console.warn('currentForm.standard.previewFormId is not defined');
      return;
    }
    getPreviewForm(standard.previewFormId);
  }
};
