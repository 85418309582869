import DropUpload, { DropUploadWrapper } from '../../common/DropUpload';
import Droppable from '../../common/Droppable';
import FileComponent from './File';
import React, { useCallback, useContext } from 'react';
import ShowHidden from '../ShowHidden';
import { EvidenceLockerContextType } from 'pages/EvidenceLockerPage';
import { FileShareModal, NoFiles } from './';
import { Instructions } from '../../common/DropUpload/DropUploadWrapper.styles';
import { PageContext } from 'pages/Page';
import { Pane } from '../EvidenceLocker.styles';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { FILE_SIZE_LIMIT } from '../../../common/common.constants';
import {
  Wrapper as FilesWrapper,
  UploadSection,
} from '../Frameworks/Frameworks.styles';

const Files = ({ t }: TranslatePropsType) => {
  const {
    actions: {
      closeFileShareModal,
      deleteFile,
      hideFile,
      selectFile,
      shareFiles,
      toggleHiding,
      unhideFile,
      uploadFile,
      invalidFileSize,
    },
    data: {
      files,
      hiddenFiles,
      hiddenFiles: { length: hiddenCount },
      mailingList,
      selectedFile,
      selectedTeam,
      showHidden,
    },
  } = useContext(PageContext) as EvidenceLockerContextType;

  const hasFiles = files.length;
  const hiddenFilesSet = new Set(hiddenFiles);
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const teamId = selectedTeam ? selectedTeam.id : undefined;
      acceptedFiles.forEach((file: File) => {
        if (file.size <= FILE_SIZE_LIMIT) {
          const formData = new FormData();
          formData.append('file', file);
          uploadFile(formData, teamId);
        } else {
          invalidFileSize('File exceeded the 50 MB limit');
        }
      });
    },
    [selectedTeam, uploadFile, invalidFileSize],
  );

  const hintLabel = t('filesUploadHint') as string;
  return (
    <>
      {selectedFile && (
        <FileShareModal
          mailingList={mailingList}
          selectedFile={selectedFile}
          shareFiles={shareFiles}
          toggleFileShareModal={closeFileShareModal}
        />
      )}

      {hiddenCount > 0 && (
        <ShowHidden
          count={hiddenCount}
          showHidden={showHidden}
          toggleHiding={toggleHiding}
        />
      )}

      {hasFiles && (
        <UploadSection>
          <DropUploadWrapper
            instructions={
              <Instructions>
                <li>{hintLabel}</li>
              </Instructions>
            }
            onDrop={handleDrop}
          />
        </UploadSection>
      )}

      <DropUpload
        render={isDragActive => (
          <Pane>
            {!hasFiles ? (
              <NoFiles />
            ) : (
              <FilesWrapper>
                {files.map(file => (
                  <FileComponent
                    key={file.name}
                    deleteFile={deleteFile}
                    file={file}
                    hideFile={hideFile}
                    isHidden={hiddenFilesSet.has(file.id)}
                    selectFile={selectFile}
                    showHidden={
                      hiddenFilesSet.has(file.id) ? showHidden : false
                    }
                    unhideFile={unhideFile}
                  />
                ))}
              </FilesWrapper>
            )}

            {isDragActive && <Droppable />}
          </Pane>
        )}
        onDrop={handleDrop}
      />
    </>
  );
};

export default withTranslation('EvidenceLocker')(Files);
