import React from 'react';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { BarWrapper } from '../common/Bar/Bar.styles';
import { Controls, TitleWrapper } from '../Requests/Header.styles';
import { RequestsSetFilterFunctionType } from 'store/requests/actions';
import { RequestsFilterType } from 'store/requests/requests.types';
import {
  FilterOptionsType,
  INSIGHTS_FILTERS as allowedFilters,
  isFilterEnabled,
} from 'utils/filters/requests.filters';
import ExportTo from './ExportTo';
import { FilterBar, FilterControls } from './Insights.styles';
import { FilterBy, Filters } from '../Requests/Filters';

export interface InsightsHeaderPropsType extends TranslatePropsType {
  exportToCsvPath: string;
  exportToPdf: () => void;
  filter: RequestsFilterType;
  filterOptions: FilterOptionsType;
  isExporting: boolean;
  setFilter: RequestsSetFilterFunctionType;
  showControls: boolean;
}

const InsightsHeader = ({
  exportToCsvPath,
  exportToPdf,
  filter: { filterBy },
  filterOptions,
  isExporting,
  setFilter,
  showControls,
  t,
}: InsightsHeaderPropsType) => {
  const showFilterBar =
    showControls &&
    allowedFilters.some(option => isFilterEnabled(filterBy[option], option));

  return (
    <>
      <BarWrapper>
        <TitleWrapper>
          <h2 data-test="page-header">{t('heading') as string}</h2>
        </TitleWrapper>
        {showControls && (
          <Controls>
            <ExportTo
              isExporting={isExporting}
              toCsvPath={exportToCsvPath}
              toPdf={exportToPdf}
            />
          </Controls>
        )}
      </BarWrapper>
      {showControls && (
        <FilterControls>
          <FilterBy
            data={{
              allowedFilters,
              anchor: 'left',
              filterBy,
              filterOptions,
              isClearable: true,
              isSent: true,
            }}
            actions={{ setFilter }}
          />
        </FilterControls>
      )}
      {showFilterBar && (
        <FilterBar>
          <Filters
            allowedFilters={allowedFilters}
            filterBy={filterBy}
            filterOptions={filterOptions}
            isClearable
            isGrouped={false}
            isSent
            setFilter={setFilter}
          />
        </FilterBar>
      )}
    </>
  );
};

export default withTranslation('Insights')(InsightsHeader);
