import React from 'react';
import { PageContextConsumer } from 'pages/Page';
import { withTranslation } from 'react-i18next';
import { Content } from 'common/common.styles';
import { TranslatePropsType } from 'common/common.types';
import { PageHeader } from '../common/PageHeader';
import { FormNewFromExistingPageContextType } from 'pages/FormNewFromExistingPage';

import FormNewFromExistingHeader from './FormNewFromExistingHeader';

import FormNewFromExistingList from './FormNewFromExistingList';

const FormNewFromExisting = ({ t }: TranslatePropsType) => (
  <PageContextConsumer>
    {({
      data: {
        formNewFromExisting: {
          standards: {
            data,
            filter: { sortBy },
          },
        },
        previewForm,
        user,
        language,
      },
      actions,
      actions: { createEmptyForm },
    }: FormNewFromExistingPageContextType) => {
      const HeaderComponent = () => (
        <FormNewFromExistingHeader createEmptyForm={createEmptyForm} />
      );

      return (
        <>
          <PageHeader heading={<HeaderComponent />} />
          <Content>
            <FormNewFromExistingList
              actions={actions}
              data={{
                standards: data,
                sortBy,
                previewForm,
                user,
                language,
              }}
            />
          </Content>
        </>
      );
    }}
  </PageContextConsumer>
);

export default withTranslation('FormNewFromExisting')(FormNewFromExisting);
