import React from 'react';
import { FormsUpdateVrmViewStatusFunctionType } from 'store/forms/actions';
import { NotificationText, NotificationWrapper } from './ViewStatus.styles';
import { RequestDetailVrmViewStatusEnum as ViewStatusEnum } from 'generated/models/RequestDetail';
import { TextButton } from 'common/common.styles';
import {
  RequestType,
  RequestVrmViewStatusEnum,
  TranslatePropsType,
} from 'common/common.types';
import { withTranslation } from 'react-i18next';

const { VENDORAPPROVED, VRMREQUESTED, VENDORINITIAL } = ViewStatusEnum;
const ALLOW_ACCESS: RequestVrmViewStatusEnum[] = [VENDORINITIAL, VRMREQUESTED];

interface ViewStatusNotificationProps extends TranslatePropsType {
  request: RequestType;
  updateStatus: FormsUpdateVrmViewStatusFunctionType;
}

const ViewStatusNotification = ({
  request: {
    id: requestId,
    isInternal,
    meta: { senderCompanyOrTeam: companyOrTeam },
    vrmViewStatus,
  },
  updateStatus,
  t,
}: ViewStatusNotificationProps) => {
  const handleClick = () => updateStatus(requestId, VENDORAPPROVED);
  const context = isInternal ? 'team' : undefined;

  return (
    <NotificationWrapper data-cy="questionnaireAllowAccess">
      <NotificationText>
        {
          t(`accessNotifications.${vrmViewStatus}`, {
            context,
            companyOrTeam,
          }) as string
        }
      </NotificationText>

      {ALLOW_ACCESS.includes(vrmViewStatus) && (
        <TextButton size="mini" color="teal" onClick={handleClick}>
          {t('allowAccess', { companyOrTeam }) as string}
        </TextButton>
      )}
    </NotificationWrapper>
  );
};

export default withTranslation('ViewStatus')(ViewStatusNotification);
