/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface UUIDConvResult
 */
export interface UUIDConvResult {
    /**
     * 
     * @type {string}
     * @memberof UUIDConvResult
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof UUIDConvResult
     */
    env: UUIDConvResultEnvEnum;
    /**
     * 
     * @type {number}
     * @memberof UUIDConvResult
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UUIDConvResult
     */
    uuid: string;
}




/**
* @export
* @enum {string}
*/
export enum UUIDConvResultEnvEnum {
    Demo = 'demo',
    Dev = 'dev',
    InitializationTest = 'initialization-test',
    Jenkins = 'jenkins',
    Local = 'local',
    Prod = 'prod',
    Production = 'production',
    Qa = 'qa',
    Test = 'test'
}


