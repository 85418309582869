/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserDelegable
 */
export interface UserDelegable {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof UserDelegable
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof UserDelegable
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof UserDelegable
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDelegable
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDelegable
     */
    domain: string;
    /**
     * 
     * @type {string}
     * @memberof UserDelegable
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserDelegable
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDelegable
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDelegable
     */
    id: string;
    /**
     * Has the user ever signed into the system?
     * @type {boolean}
     * @memberof UserDelegable
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDelegable
     */
    lastName: string;
    /**
     * Does the user have access to the form only via proxying?
     * @type {boolean}
     * @memberof UserDelegable
     */
    isProxy: boolean;
}

export function UserDelegableFromJSON(json: any): UserDelegable {
    return UserDelegableFromJSONTyped(json, false);
}

export function UserDelegableFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDelegable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'companyId': json['company_id'],
        'displayName': json['display_name'],
        'domain': json['domain'],
        'email': json['email'],
        'firstName': json['first_name'],
        'fullName': json['full_name'],
        'id': json['id'],
        'isActive': json['is_active'],
        'lastName': json['last_name'],
        'isProxy': json['is_proxy'],
    };
}

export function UserDelegableToJSON(value?: UserDelegable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'company_id': value.companyId,
        'display_name': value.displayName,
        'domain': value.domain,
        'email': value.email,
        'first_name': value.firstName,
        'full_name': value.fullName,
        'id': value.id,
        'is_active': value.isActive,
        'last_name': value.lastName,
        'is_proxy': value.isProxy,
    };
}


