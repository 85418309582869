import React from 'react';
import {
  AverageChartRecordType,
  TranslatePropsType,
} from 'common/common.types';
import { withTranslation } from 'react-i18next';
import GradeRecord from './GradeRecord';
import { getBarWidth } from '../utils';
import getCompanyGrade from '../../../utils/getCompanyGrade';
import { AverageGauge } from '../Insights.styles';

interface GradeAveragePropsType extends TranslatePropsType {
  average: AverageChartRecordType;
}

const GradeAverage = ({
  average: { companies, count, maximum, sum },
  t,
}: GradeAveragePropsType) => {
  const hasScore = count > 0;
  const score = Math.round(sum / count);

  return (
    <AverageGauge title={t('averageCharts.sscScore')}>
      <GradeRecord
        grade={getCompanyGrade(score)}
        name={t('averageSscName', { count: companies.size })}
        width={getBarWidth(maximum, score, 90)}
      >
        {hasScore ? score : (t('na') as string)}
      </GradeRecord>
    </AverageGauge>
  );
};

export default withTranslation('Insights')(GradeAverage);
