/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface QuestionComment
 */
export interface QuestionComment {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof QuestionComment
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof QuestionComment
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof QuestionComment
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionComment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionComment
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionComment
     */
    userId: string;
}

export function QuestionCommentFromJSON(json: any): QuestionComment {
    return QuestionCommentFromJSONTyped(json, false);
}

export function QuestionCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'companyId': json['company_id'],
        'id': json['id'],
        'text': json['text'],
        'userId': json['user_id'],
    };
}

export function QuestionCommentToJSON(value?: QuestionComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'company_id': value.companyId,
        'id': value.id,
        'text': value.text,
        'user_id': value.userId,
    };
}


