import { RequestDirectionType } from '../../../common/common.types';

export type RequestsToggleCategoriesFunctionType = (
  categories: RequestsToggleCategoriesValueType,
) => void;

export interface RequestsToggleCategoriesValueType {
  created?: boolean;
  inProgress?: boolean;
  reviewReady?: boolean;
  closed?: boolean;
}

export interface RequestsToggleCategoriesActionType {
  type: '@requests/TOGGLE_CATEGORIES';
  payload: {
    categories: RequestsToggleCategoriesValueType;
    direction: RequestDirectionType;
  };
}

export const toggleCategories = (
  categories: RequestsToggleCategoriesValueType,
  direction: RequestDirectionType,
): RequestsToggleCategoriesActionType => ({
  type: '@requests/TOGGLE_CATEGORIES',
  payload: {
    categories,
    direction,
  },
});
