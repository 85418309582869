/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface RequestUpdate
 */
export interface RequestUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof RequestUpdate
     */
    clear_notifications?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof RequestUpdate
     */
    due_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof RequestUpdate
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RequestUpdate
     */
    read?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestUpdate
     */
    risk_note?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUpdate
     */
    status?: RequestUpdateStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestUpdate
     */
    vrm_view_status?: RequestUpdateVrmViewStatusEnum;
}




/**
* @export
* @enum {string}
*/
export enum RequestUpdateStatusEnum {
    ACCEPTED = 'ACCEPTED',
    ARCHIVED = 'ARCHIVED',
    CANCELED = 'CANCELED',
    CREATED = 'CREATED',
    UNARCHIVED = 'UNARCHIVED',
    VENDORINPROGRESS = 'VENDOR_IN_PROGRESS',
    VRMINPROGRESS = 'VRM_IN_PROGRESS'
}
/**
* @export
* @enum {string}
*/
export enum RequestUpdateVrmViewStatusEnum {
    VENDORAPPROVED = 'VENDOR_APPROVED',
    VENDORDECLINED = 'VENDOR_DECLINED',
    VENDORINITIAL = 'VENDOR_INITIAL',
    VRMREQUESTED = 'VRM_REQUESTED'
}


