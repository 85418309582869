/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PdfExportSummary,
    PdfExportSummaryFromJSON,
    PdfExportSummaryFromJSONTyped,
    PdfExportSummaryToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface PdfExportSummaryResponse
 */
export interface PdfExportSummaryResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof PdfExportSummaryResponse
     */
    msg: string;
    /**
     * 
     * @type {PdfExportSummary}
     * @memberof PdfExportSummaryResponse
     */
    entry: PdfExportSummary;
}

export function PdfExportSummaryResponseFromJSON(json: any): PdfExportSummaryResponse {
    return PdfExportSummaryResponseFromJSONTyped(json, false);
}

export function PdfExportSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdfExportSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': PdfExportSummaryFromJSON(json['entry']),
    };
}

export function PdfExportSummaryResponseToJSON(value?: PdfExportSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': PdfExportSummaryToJSON(value.entry),
    };
}


