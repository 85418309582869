import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import {
  FormCreatorValidatedResponseType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import { HorizontallyCentered } from 'common/common.styles';
import {
  AddSecondaryResponse,
  DescriptionInput,
  DescriptionWrapper,
  ResponseComponentWrapper,
  StyledSelect,
  Wrapper,
  ResponseHeader,
  ValidationInfo,
} from './Response.styles';
import { ResponseTypeDefinitionResponseTypeEnum as ResponseType } from 'generated/models/ResponseTypeDefinition';
import { FormCreatorContextActionsType } from 'pages/FormCreatorPage';

import { Action, ActionsWrapper, DeleteIcon } from '../Actions/Actions.styles';
import Switch from '../../common/Switch';
import {
  getOption,
  getResponseComponent,
  hasResponseTypeOptions,
  RESPONSE_OPTIONS,
  ResponseOptionType,
} from './Response.options';
import { Button } from '../../common/Button';
import { ButtonText } from './SelectResponse/SelectResponse.styles';
import Label from '../../common/typography/Label';

export interface ResponseComponentPropsType {
  response: FormCreatorValidatedResponseType;
  updateDefinition: (payload: DefinitionPayloadType) => void;
}

interface ResponsePropsType extends TranslatePropsType {
  actions: FormCreatorContextActionsType;
  addSecondary?: () => void;
  isDeletable: boolean;
  hasConditionsToggle: boolean;
  questionId: Uuid;
  response: FormCreatorValidatedResponseType;
}

export interface DefinitionPayloadType {
  responseType?: ResponseType;
  options?: string[];
  placeholder?: string;
  label?: string;
}

const Response = ({
  actions: { deleteResponse, updateResponseDefinition, validateForm },
  addSecondary,
  isDeletable,
  hasConditionsToggle,
  questionId,
  response,
  response: {
    errors,
    fixAttempted,
    uniqueId: responseId,
    definition: { label, options, placeholder, responseType },
  },
  t,
}: ResponsePropsType) => {
  const responseTypeOption = getOption(responseType);

  const getResponseOptionLabel = useCallback(
    (option: ResponseOptionType) => t(option.label),
    [t],
  );

  const onUpdateResponseLabel = useCallback(
    (newLabel: string) => {
      if (label !== newLabel) {
        updateResponseDefinition({
          questionId,
          responseUuid: responseId,
          responseType,
          options,
          placeholder,
          label: newLabel,
        });
      }
    },
    [
      questionId,
      responseId,
      responseType,
      label,
      options,
      placeholder,
      updateResponseDefinition,
    ],
  );

  const onUpdateResponseIsRequired = useCallback(
    (isRequired: boolean) =>
      updateResponseDefinition({
        questionId,
        responseUuid: responseId,
        responseType,
        options,
        placeholder,
        label,
        isRequired,
      }),
    [
      questionId,
      responseId,
      responseType,
      options,
      placeholder,
      label,
      updateResponseDefinition,
    ],
  );

  const onDeleteResponse = useCallback(
    () => deleteResponse(response.uniqueId),
    [deleteResponse, response],
  );

  const onDefinitionChange = useCallback(
    (payload: DefinitionPayloadType) =>
      updateResponseDefinition({
        questionId,
        responseUuid: response.uniqueId,
        responseType: payload.responseType || responseType,
        options: payload.options || options,
        placeholder:
          payload.placeholder === ''
            ? payload.placeholder
            : payload.placeholder || placeholder,
        label: payload.label === '' ? payload.label : payload.label || label,
      }),
    [
      updateResponseDefinition,
      response,
      questionId,
      responseType,
      options,
      placeholder,
      label,
    ],
  );

  const onResponseOptionChange = useCallback(
    (newValue: ResponseOptionType) => {
      const shouldOptionsRemain =
        hasResponseTypeOptions(newValue) &&
        hasResponseTypeOptions(responseTypeOption);
      onDefinitionChange({
        responseType: newValue.responseType,
        options: shouldOptionsRemain ? options : [],
      });
    },
    [onDefinitionChange, options, responseTypeOption],
  );

  const ResponseComponent = getResponseComponent(responseTypeOption);

  const hasSecondaryOffset =
    addSecondary !== undefined && !hasResponseTypeOptions(responseTypeOption);

  return (
    <Wrapper hasSecondaryOffset={hasSecondaryOffset}>
      {errors.length > 0 && (
        <ValidationInfo
          errors={errors}
          fixAttempted={Boolean(fixAttempted)}
          onValidateClick={validateForm}
          showIcon={!hasConditionsToggle}
        />
      )}
      <ResponseHeader data-test="delete-secondary-response">
        <HorizontallyCentered spacingSize={8}>
          <StyledSelect
            getOptionLabel={getResponseOptionLabel}
            onChange={onResponseOptionChange}
            options={RESPONSE_OPTIONS}
            value={responseTypeOption}
          />

          <DescriptionWrapper data-test="description-input">
            <Label>{t('description') as string}</Label>
            <DescriptionInput
              charCountSameLine={true}
              maxLength={30}
              onBlur={onUpdateResponseLabel}
              // Size (=char count) set to a small value is required so that
              // Firefox doesn't make input forcefully wider. Tested in FF v71.0
              size={10}
              value={response.definition.label}
            />
          </DescriptionWrapper>

          <Switch
            isChecked={response.isRequired}
            onChange={onUpdateResponseIsRequired}
          />
          <Label>{t('required') as string}</Label>
        </HorizontallyCentered>
        <ActionsWrapper>
          {isDeletable && (
            <Action onClick={onDeleteResponse} title={t('delete') as string}>
              <DeleteIcon />
            </Action>
          )}
        </ActionsWrapper>
      </ResponseHeader>
      {ResponseComponent && (
        <ResponseComponentWrapper data-test="response-input">
          <ResponseComponent
            updateDefinition={onDefinitionChange}
            response={response}
          />
        </ResponseComponentWrapper>
      )}
      {addSecondary && (
        <AddSecondaryResponse data-test="add-secondary-response">
          <Button
            onClick={addSecondary}
            style={{ border: 'none' }}
            color="transparent"
          >
            <ButtonText>{t('addResponse') as string}</ButtonText>
          </Button>
        </AddSecondaryResponse>
      )}
    </Wrapper>
  );
};

export default withTranslation('FormCreator')(React.memo(Response));
