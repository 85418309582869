import styled, { css } from 'styled-components';
import colors from 'common/common.colors';
import { DueDateKindType } from 'common/common.types';

type ProgressStateType = 'positive' | 'negative';

export interface ProgressWrapperType {
  state: ProgressStateType;
}

type ProgressTextType = ProgressWrapperType;

export interface ProgressType {
  state: ProgressStateType;
  kind: DueDateKindType;
}

export const DueDateWrapper = styled.div`
  width: 170px;
  height: 30px;
  position: relative;
`;

export const Separator = styled.div`
  width: 2px;
  display: block;
  height: 100%;
  background-color: #787993;
  position: absolute;
  left: 50%;
  margin-left: -1px;
  border-radius: 5px;
  z-index: 1;
`;

export const ProgressWrapper = styled.div<ProgressWrapperType>`
  width: 25%;
  height: 20px;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  ${({ state }: { state: ProgressStateType }) => {
    switch (state) {
      case 'positive':
        return css`
          right: 50%;
          margin-right: 1px;
        `;
      default:
      case 'negative':
        return css`
          left: 50%;
          margin-left: 1px;
        `;
    }
  }};
`;

export const Progress = styled.div<ProgressType>`
  background-color: ${({ kind }: { kind: DueDateKindType }) =>
    colors.requestDueDates[kind]};
  height: 100%;
  position: absolute;

  ${({ state }: { state: ProgressStateType }) => {
    switch (state) {
      case 'positive':
        return css`
          right: 0;
          border-radius: 5px 0 0 5px;
        `;
      default:
      case 'negative':
        return css`
          left: 0;
          border-radius: 0 5px 5px 0;
        `;
    }
  }};

  ${({ kind }: { kind: DueDateKindType }) => {
    switch (kind) {
      case 'okay':
      case 'danger':
        return {
          width: '50%',
        };
      case 'warning':
      case 'close':
        return {
          width: '30%',
        };
      case 'almost':
      case 'notice':
      default:
        return {
          width: '15%',
        };
    }
  }};
`;

export const Text = styled.div<ProgressTextType>`
  ${({ theme }) => theme.fonts.light.small}
  width: 80px;
  position: absolute;
  color: #787993;

  ${({ state }: { state: ProgressStateType }) => {
    switch (state) {
      case 'positive':
        return css`
          left: 50%;
          margin-left: 5px;
        `;
      default:
      case 'negative':
        return css`
          left: 0;
          margin-left: 0;
          text-align: right;
        `;
    }
  }};
`;
