import { css } from 'styled-components';

export const numberFromPixels = (valueInPx: string): number =>
  parseInt(valueInPx.split('px')[0], 10);

// mixin, which for more lines add '...' at the end, if content exceed max-height
// equals to lineHeight * lineCount
// in google chrome when we use it with react-dnd, during dragging made 'ghost'
// for more then dragged element ;-(, but it firefox works fine ...
export const multiLineEllipsis = (bgColor: string) =>
  css`
    overflow: hidden;
    position: relative;
    text-align: justify;
    margin-right: 14px;
    padding-right: 14px;
    &:before {
      content: '...';
      position: absolute;
      right: 3px;
      bottom: 0;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 14px;
      height: 14px;
      margin-top: 0.2em;
      background: ${bgColor};
    }
  `;

export const textTruncate = (str: string, length: number, ending?: string) => {
  if (length == null) {
    length = 100;
  }
  if (str.length < length) {
    return str;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};
