import React from 'react';

import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import {
  ContentContainer,
  ContentWrapper,
  ScrollWrapper,
} from '../QuestionTabs.styles';
import { TabContentTitle } from '../';
import { Instructions } from './ReviewerInstructions.styles';

interface ReviewerInstructionsPropsType extends TranslatePropsType {
  instructions?: string;
}

const ReviewerInstructions = ({
  instructions,
  t,
}: ReviewerInstructionsPropsType) => (
  <ContentContainer hasBackground={Boolean(instructions)}>
    <ContentWrapper>
      <TabContentTitle icon={faInfoCircle} title={t('title') as string} />

      {instructions ? (
        <ScrollWrapper>
          <Instructions>{instructions}</Instructions>
        </ScrollWrapper>
      ) : (
        <p>{t('noDataAvailable') as string}</p>
      )}
    </ContentWrapper>
  </ContentContainer>
);

export default withTranslation('ReviewerInstructions')(ReviewerInstructions);
