import styled from 'styled-components';

export const Wrapper = styled.div`
  & + & {
    margin-top: 20px;
  }
`;

export const Title = styled.div``;

export const CreatedAt = styled.div`
  padding: 6px 0;
  ${({ theme }) => theme.fonts.regular.mini}
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.history.answerColor};
  ${({ theme }) => theme.fonts.bold.small}
`;
