import React from 'react';

import { IconWrapper } from './ActionsIcon.styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface FormDefinitionsActionsIconPropTypes {
  showActions: () => void;
  actionsVisible?: boolean;
}

const FormDefinitionsActionsIcon = ({
  showActions,
  actionsVisible,
}: FormDefinitionsActionsIconPropTypes) => {
  return (
    <IconWrapper
      data-test="form-definitions-actions"
      onClick={showActions}
      active={actionsVisible}
    >
      <FontAwesomeIcon icon={faEllipsisV as IconProp} color={'#579AA0'} />
    </IconWrapper>
  );
};

export default FormDefinitionsActionsIcon;
