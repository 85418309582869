import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import update from 'immutability-helper';
import { resetReportSaving } from './resetReportSaving';
import { put } from 'redux-saga/effects';
import { Uuid } from '../../../common/common.types';
import requestsApi from '../../../api/new/requestsApi';
import {
  GetRequestFindingsListResourceRequest,
  RequestFinding,
} from '../../../generated/new';

export type FormsGetFindingsFunctionType = (requestId: Uuid) => void;

export type FormsGetFindingsActionType = ApiCallActionType<
  '@forms/API/GET_FINDINGS',
  GetRequestFindingsListResourceRequest,
  RequestFinding[],
  never,
  FormsStateType
>;

export const getFindings = (requestId: Uuid): FormsGetFindingsActionType => ({
  type: '@forms/API/GET_FINDINGS',
  payload: {
    callParams: { requestId },
    endpointPath: params => requestsApi.getRequestFindingsListResource(params),
    selector: (data, { forms }): Partial<FormsStateType> => {
      if (!forms.currentForm) {
        return {};
      }
      return update(forms, {
        currentForm: {
          request: {
            findings: { $set: data },
          },
        },
      });
    },
    postActions: [() => put(resetReportSaving())],
  },
});
