import { FormCreatorStateType } from '../formCreator.types';
import { createSelector } from 'reselect';

export interface ValidationStats {
  invalidEntitiesCount: number;
  allErrorsCount: number;
}

export const getValidationStats = createSelector(
  [
    (formCreator: FormCreatorStateType) =>
      formCreator.validation.invalidQuestions,
    (formCreator: FormCreatorStateType) =>
      formCreator.validation.invalidResponses,
    (formCreator: FormCreatorStateType) =>
      formCreator.currentForm ? formCreator.currentForm.questions : [],
  ],
  (invalidQuestions, invalidResponses, questions): ValidationStats => {
    const stats: ValidationStats = {
      invalidEntitiesCount: 0,
      allErrorsCount: 0,
    };
    questions.forEach(q => {
      const invalidQuestion = invalidQuestions.find(
        iq => iq.questionUuid === q.wizardId,
      );

      if (invalidQuestion) {
        stats.invalidEntitiesCount++;
        stats.allErrorsCount += invalidQuestion.errors.length;
      }

      q.responses.forEach(r => {
        const invalidResponse = invalidResponses.find(
          ir => ir.responseUuid === r.uniqueId,
        );

        if (invalidResponse) {
          stats.invalidEntitiesCount++;
          stats.allErrorsCount += invalidResponse.errors.length;
        }
      });
    });
    return stats;
  },
);
