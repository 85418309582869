/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Attachment } from "./Attachment";
// @ts-ignore
import { Base } from "./Base";
// @ts-ignore
import { DelegationSummary } from "./DelegationSummary";
// @ts-ignore
import { ResponseFilling } from "./ResponseFilling";

/**
 * 
 * @export
 * @interface QuestionDetailBase
 */
export interface QuestionDetailBase {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof QuestionDetailBase
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof QuestionDetailBase
     */
    updated_at: Date;
    /**
     * Number of attachments for the question
     * @type {number}
     * @memberof QuestionDetailBase
     */
    attachment_count: number;
    /**
     * True if the current user is allowed to add or remove question attachments
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    attachment_modifiable: boolean;
    /**
     * List of files (evidence) attached to the question
     * @type {Array<Attachment>}
     * @memberof QuestionDetailBase
     */
    attachments: Array<Attachment>;
    /**
     * Name of the category to which the question belongs and by which the questions can be
     * grouped.
     * @type {string}
     * @memberof QuestionDetailBase
     */
    category: string;
    /**
     * Unique code of the question visible in the UI
     * @type {string}
     * @memberof QuestionDetailBase
     */
    code: string;
    /**
     * Number of comments for the question
     * @type {number}
     * @memberof QuestionDetailBase
     */
    comment_count: number;
    /**
     * 
     * @type {Array<DelegationSummary>}
     * @memberof QuestionDetailBase
     */
    delegations?: Array<DelegationSummary>;
    /**
     * ID of the form to which the question belongs
     * @type {string}
     * @memberof QuestionDetailBase
     */
    form_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    has_attachment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    has_comment: boolean;
    /**
     * True if the question contains at least one response with answer N/A
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    has_na: boolean;
    /**
     * True if the question contains at least one response with answer No
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    has_no: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    has_required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    has_required_filled: boolean;
    /**
     * True if the question contains at least one response with answer Yes
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    has_yes: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionDetailBase
     */
    id: string;
    /**
     * True if the question should be visible (e.g. depending on the visibility condition)
     * @type {boolean}
     * @memberof QuestionDetailBase
     */
    is_visible: boolean;
    /**
     * ID of the company that owns the question
     * @type {string}
     * @memberof QuestionDetailBase
     */
    owner_id: string;
    /**
     * Integer that can be used to sort questions to get their positions in category. Note
     * that positions of neighboring items don't have to follow immediately one after another
     * (positions can be 3, 6, 20 instead of 1, 2, 3).
     * @type {number}
     * @memberof QuestionDetailBase
     */
    pos: number;
    /**
     * Text of the question
     * @type {string}
     * @memberof QuestionDetailBase
     */
    question: string;
    /**
     * Number of responses in the question
     * @type {number}
     * @memberof QuestionDetailBase
     */
    response_count: number;
    /**
     * List of responses in the question
     * @type {Array<ResponseFilling>}
     * @memberof QuestionDetailBase
     */
    responses: Array<ResponseFilling>;
    /**
     * Number of filled responses in the question
     * @type {number}
     * @memberof QuestionDetailBase
     */
    responses_filled: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionDetailBase
     */
    review_instructions?: string;
    /**
     * 
     * @type {object}
     * @memberof QuestionDetailBase
     */
    seen_info?: any;
    /**
     * Current status of the request
     * @type {string}
     * @memberof QuestionDetailBase
     */
    vrm_review_status: QuestionDetailBaseVrmReviewStatusEnum;
}




/**
* @export
* @enum {string}
*/
export enum QuestionDetailBaseVrmReviewStatusEnum {
    DONE = 'DONE',
    FLAGGED = 'FLAGGED',
    PENDING = 'PENDING'
}


