import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Value from './Value';
import isGenericLabel from 'utils/isGenericLabel';
import { formatDistanceToNow } from 'date-fns';
import { Button } from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { values } from 'ramda';
import { withTranslation } from 'react-i18next';
import getLocale from 'utils/getLocale';
import {
  FormsPutQuestionFunctionType,
  FormsRateMappingFunctionType,
  FormsRateMappingFunctionParamsType,
} from 'store/forms/actions';
import {
  QuestionMappingsType,
  QuestionType,
  TranslatePropsType,
  MatchType,
  UpdateResponseSourceEnum,
  LanguageType,
} from 'common/common.types';
import {
  Controls,
  Info,
  Label,
  Latest,
  Rating,
  Response,
  ResponseHeader,
  ResponseWrapper,
  ValuesWrapper,
  Wrapper,
  Thanks,
} from './QuestionsMapping.styles';
import { FormQuestionFeedbackCreateRatingEnum } from 'generated/models/FormQuestionFeedbackCreate';
import { StateType } from '../../../../store/store.types';
import { getLanguagePreference } from '../../../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface QuestionMappingPropsType extends TranslatePropsType {
  copyToClipboard?: (text: string) => void;
  mapping: QuestionMappingsType;
  mappingPosition: number;
  putQuestion: FormsPutQuestionFunctionType;
  question: QuestionType;
  rateMapping: FormsRateMappingFunctionType;
  showLikeness: boolean;
  language: LanguageType;
}

const QuestionMapping = ({
  copyToClipboard,
  mapping: {
    id: mappingId,
    matches,
    question: { id: sourceId, question },
    score,
    isRated,
  },
  mappingPosition,
  putQuestion,
  question: { category, formId, id: targetId },
  rateMapping: rate,
  showLikeness,
  t,
  language,
}: QuestionMappingPropsType) => {
  const percentage = Math.round(score * 100);
  const [isOpened, setOpened] = useState(percentage >= 70);
  const [rating, setRating] = useState<
    FormQuestionFeedbackCreateRatingEnum | undefined
  >(undefined);
  const [isReviewed, setReviewed] = useState(false);

  const handleRate = (newRating: FormQuestionFeedbackCreateRatingEnum) => {
    setReviewed(true);
    if (newRating) {
      setRating(newRating);
      const rateMappingFunctionParams: FormsRateMappingFunctionParamsType = {
        sourceId: sourceId,
        targetId: targetId,
        rating: newRating,
        formId: formId,
        questionMappingPos: mappingPosition,
        id: mappingId,
      };
      rate(rateMappingFunctionParams);
    }
  };

  const handleApply = (match: MatchType, matchResponsePosition: number) => {
    const {
      question: { id },
    } = match;

    const rateMappingFunctionParams: FormsRateMappingFunctionParamsType = {
      sourceId: sourceId,
      targetId: targetId,
      rating: FormQuestionFeedbackCreateRatingEnum.RESPONSEAPPLIED,
      formId: formId,
      questionMappingPos: mappingPosition,
      id: mappingId,
      responseValue: match.value,
      responsePos: matchResponsePosition,
    };
    rate(rateMappingFunctionParams);

    putQuestion(
      formId,
      targetId,
      match.targetId,
      match.value,
      category,
      match.sourceId,
      id,
      UpdateResponseSourceEnum.ANSWER_LOOKUP_LENSES,
    );
  };

  useEffect(() => {
    if (isRated) {
      setTimeout(() => {
        setReviewed(false);
      }, 5000);
    }
  }, [isRated]);

  if (isReviewed && isOpened) {
    let faIcon = faThumbsDown as IconProp;
    if (rating === FormQuestionFeedbackCreateRatingEnum.THUMBSUP) {
      faIcon = faThumbsUp as IconProp;
    }

    return (
      <Wrapper>
        <Thanks>
          <p>{t('thanks') as string}</p>

          <FontAwesomeIcon icon={faIcon} />
        </Thanks>
      </Wrapper>
    );
  }

  let chevronIcon = faChevronDown as IconProp;
  if (isOpened) {
    chevronIcon = faChevronUp as IconProp;
  }

  return (
    <Wrapper>
      <Info onClick={() => setOpened(!isOpened)}>
        <div>{question}</div>

        <div>
          <FontAwesomeIcon icon={chevronIcon} />
        </div>
      </Info>

      {isOpened && (
        <>
          <Controls>
            {showLikeness && (
              <div>{ReactHtmlParser(t('likenessHTML', { percentage }))}</div>
            )}

            <Rating
              onClick={() =>
                handleRate(FormQuestionFeedbackCreateRatingEnum.THUMBSUP)
              }
            >
              <FontAwesomeIcon icon={faThumbsUp as IconProp} />
            </Rating>

            <Rating
              onClick={() =>
                handleRate(FormQuestionFeedbackCreateRatingEnum.THUMBSDOWN)
              }
            >
              <FontAwesomeIcon icon={faThumbsDown as IconProp} />
            </Rating>
          </Controls>

          {matches.map((match, i) =>
            values(match.question.responses)
              .filter(r => !!r.value && r.id === match.sourceId)
              .map(response => {
                const {
                  definition: { label },
                  id,
                  updatedAt,
                } = response;
                const lastResponse = formatDistanceToNow(updatedAt, {
                  locale: getLocale(language),
                });
                const uniqueFormNames = match.formNames.join(', ');
                const showApply = match.matchCode === 'FULL';

                return (
                  <ResponseWrapper key={`response-${id}`}>
                    <ResponseHeader>
                      <Label>
                        {t('answer', { uniqueFormNames }) as string}
                      </Label>

                      <Latest>
                        {updatedAt &&
                          (t('response', { lastResponse }) as string)}
                      </Latest>
                    </ResponseHeader>

                    {label && !isGenericLabel(label) && <Label>{label}</Label>}

                    <Response key={id}>
                      <ValuesWrapper>
                        <Value
                          copyToClipboard={copyToClipboard}
                          response={response}
                        />
                      </ValuesWrapper>

                      {showApply && (
                        <Button onClick={() => handleApply(match, i + 1)}>
                          {t('apply') as string}
                        </Button>
                      )}
                    </Response>
                  </ResponseWrapper>
                );
              }),
          )}
        </>
      )}
    </Wrapper>
  );
};

const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation('QuestionMappings')(QuestionMapping));
