import * as R from 'ramda';
import React from 'react';
import { Container, DeleteButton, Name } from './Tag.styles';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface TagPropsType {
  editable: boolean;
  remove: (tag: object) => void;
  tag: object;
}

const Tag = ({ editable, tag, remove }: TagPropsType) => {
  const tagName: string = R.or(
    R.path(['tag', 'name'], tag),
    R.pathOr('', ['name'], tag),
  ).toString();

  const faTimesCircleProp = faTimesCircle as IconProp;

  return (
    <Container name={tagName}>
      <Name editable={editable} className="tagName">
        {tagName}
      </Name>

      {editable && (
        <div onClick={() => remove(tag)}>
          <DeleteButton
            editable={editable ? 'yes' : 'no'}
            className="tagDeleteButton"
            icon={faTimesCircleProp}
            color="#fff"
          />
        </div>
      )}
    </Container>
  );
};

export default Tag;
