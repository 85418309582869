import React from 'react';
import colors from 'common/common.colors';
import { Badge } from '../common/Badge';
import { Column, ColumnHeading, ColumnsWrapper } from './Kanban.styles';
import { RequestCard } from './Request';
import { SortType } from 'utils/filters/requests.sorters';
import { getCategoryTitleTranslationKey } from 'utils/getCategoryTitleTranslationKey';
import { withTranslation } from 'react-i18next';
import {
  LanguageType,
  RequestDirectionType,
  RequestSimpleType,
  TranslatePropsType,
} from 'common/common.types';
import {
  REQUESTS_CATEGORIES_ORDER,
  RequestsInOutType,
} from 'store/requests/requests.types';

export interface KanbanPropsType extends TranslatePropsType {
  data: {
    requests: RequestsInOutType;
    direction: RequestDirectionType;
    sortBy: SortType;
    language: LanguageType;
  };
}

const Kanban = ({
  data: {
    requests: { categories },
    direction,
    language,
  },
  t,
}: KanbanPropsType) => (
  <ColumnsWrapper>
    {REQUESTS_CATEGORIES_ORDER.map(category => {
      const color = colors.elements.requests[category].color;
      const catString = category.toString();

      return (
        <Column key={`column-${category.toString()}`}>
          {categories[category].count > 0 && (
            <>
              <ColumnHeading>
                {
                  t(
                    getCategoryTitleTranslationKey(catString, direction),
                  ) as string
                }

                <Badge color={color}>{categories[category].count}</Badge>
              </ColumnHeading>

              {categories[category].requests.map(
                (request: RequestSimpleType) => (
                  <RequestCard
                    key={request.id}
                    data={{ request, color, language }}
                  />
                ),
              )}
            </>
          )}
        </Column>
      );
    })}
  </ColumnsWrapper>
);

export default withTranslation()(Kanban);
