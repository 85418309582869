import React from 'react';
import { withTranslation } from 'react-i18next';

import QuestionMarkTooltip from '../../../common/QuestionMarkTooltip';
import { Wrapper, TextInfo, Score } from './ValidationScore.styles';
import { TranslatePropsType } from 'common/common.types';

interface ValidationScorePropsType extends TranslatePropsType {
  average?: number;
  canViewData: boolean;
  score: number;
  showValidationScore: boolean;
}

const ValidationScore = ({
  canViewData,
  score,
  showValidationScore,
  t,
}: ValidationScorePropsType) => (
  <Wrapper>
    {canViewData ? (
      showValidationScore ? (
        <Score>{`${score}%`}</Score>
      ) : (
        <TextInfo>
          {t('components:ValidationScore.noAnswers') as string}
        </TextInfo>
      )
    ) : (
      <TextInfo>
        {t('components:ValidationScore.cannotViewData') as string}
      </TextInfo>
    )}

    {canViewData && showValidationScore && (
      <QuestionMarkTooltip text={t('common:help.validationScore') as string} />
    )}
  </Wrapper>
);

export default withTranslation(['common', 'components'])(ValidationScore);
