import React, { useCallback, useMemo } from 'react';
import {
  FormCreatorRenderType,
  FormCreatorValidatedQuestionType,
  GroupedQuestionItemType,
  StructuredCondition,
  TranslatePropsType,
} from 'common/common.types';
import { withTranslation } from 'react-i18next';
import { Button } from '../../common/Button';
import { ButtonText } from '../Response/SelectResponse/SelectResponse.styles';
import StructuredConditionLine from './StructuredConditionLine';
import { UpdateQuestionConditionFunctionType } from 'store/formCreator/actions';
import {
  appendEmptyLine,
  createEmpty,
} from 'store/formCreator/conditions/structuredCondition.utils';
import { Buttons } from './StructuredEditor.styles';

interface StructuredEditorPropsType extends TranslatePropsType {
  condition?: StructuredCondition;
  question: FormCreatorValidatedQuestionType;
  renderData: FormCreatorRenderType[];
  updateCondition: UpdateQuestionConditionFunctionType;
}

const StructuredEditor = ({
  condition,
  question,
  question: { wizardId: questionId },
  renderData,
  updateCondition,
  t,
}: StructuredEditorPropsType) => {
  const updateStructuredCondition = useCallback(
    (payload: StructuredCondition | null) =>
      updateCondition(
        questionId,
        payload ? { type: 'structured', payload } : null,
      ),
    [updateCondition, questionId],
  );

  const addCondition = useCallback(
    () =>
      updateStructuredCondition(
        appendEmptyLine(condition ? condition : createEmpty()),
      ),
    [condition, updateStructuredCondition],
  );

  const groupedQuestions = useMemo(
    () =>
      renderData.map(
        (category): GroupedQuestionItemType => ({
          label: category.name,
          options: category.questions,
        }),
      ),
    [renderData],
  );

  return (
    <div data-test="structured-condition">
      {condition &&
        condition.items.map((line, index) => (
          <StructuredConditionLine
            key={line.id}
            condition={condition}
            conditionQuestion={question}
            groupedQuestions={groupedQuestions}
            index={index}
            isLastLine={index === condition.items.length - 1}
            line={line}
            updateCondition={updateStructuredCondition}
          />
        ))}
      <Buttons>
        <Button
          dataTest="add-condition"
          style={{ border: 'none' }}
          color="transparent"
          onClick={addCondition}
        >
          <ButtonText>{t('conditionEditor.addCondition') as string}</ButtonText>
        </Button>
      </Buttons>
    </div>
  );
};

export default withTranslation('FormCreator')(React.memo(StructuredEditor));
