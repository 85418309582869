import { assoc, pipe } from 'ramda';
import { RequestDirectionType, Uuid } from '../../../common/common.types';
import { parseRequestsCategorized } from 'utils/parsers/requests.parsers';
import { ApiCallActionPayloadType, StateType } from '../../store.types';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import {
  correctFilters,
  FilterLookForStringType,
  FilterLookForType,
  FilterType,
  getDateFilters,
} from 'utils/filters/requests.filters';
import {
  RequestSimple,
  FormRolesEnum as FormRoleEnum,
} from '../../../generated/models/RequestSimple';
import { RequestFiltersStatsMeta } from '../../../generated/models';
import { RequestsStateType } from '../requests.types';
import { ACTIVE_REQUEST_STATUSES } from '../../../common/common.constants';

export interface GetRequestsCallParamsType {
  company_id?: Uuid[];
  form_roles?: string[];
  limit?: number;
  sent_time_from?: string;
  sent_time_to?: string;
  source_id?: Uuid[];
  standard_id?: Uuid[];
  status?: string[];
  target_id?: Uuid[];
  user_email?: string[];
}

export type GetRequestsActionType = OptimisticActionType<
  '@requests/API/GET',
  ApiCallActionPayloadType<
    GetRequestsCallParamsType,
    RequestSimple[],
    never,
    RequestsStateType,
    RequestFiltersStatsMeta
  >
>;

const getFilter = (
  parameter: string,
  { isEnabled, lookFor }: FilterLookForType | FilterLookForStringType,
) => {
  return isEnabled
    ? {
        [parameter]: lookFor,
      }
    : undefined;
};

const getFormRoles = (
  companyId: Uuid,
  includeProxy: boolean,
  isSent: boolean,
) => {
  if (includeProxy) {
    return {
      form_roles: isSent
        ? [FormRoleEnum.VRM, FormRoleEnum.VRMPROXY]
        : [FormRoleEnum.VENDOR, FormRoleEnum.VENDORPROXY],
    };
  }
  return { [isSent ? 'source_id' : 'target_id']: [companyId] };
};

export const getFilterCallParams = (
  state: StateType,
  filters: FilterType,
  isSent: boolean,
  includeProxy: boolean,
) => {
  const fetchedFilters = {
    status: ACTIVE_REQUEST_STATUSES,
    ...getFormRoles(state.user.currentUser.company.id, includeProxy, isSent),
    ...getDateFilters(filters.DATE),
    ...getFilter('status', filters.STATUS),
    ...getFilter(isSent ? 'target_id' : 'source_id', filters.COMPANIES),
    ...getFilter('standard_id', filters.STANDARDS),
    ...getFilter('user_email', filters.EMAILS),
    ...getFilter('with_revisions', filters.WITH_REVISIONS),
  };

  if (filters.INTERNAL.isEnabled) {
    return { ...fetchedFilters, only_internal: filters.INTERNAL.isEnabled };
  } else if (filters.EXTERNAL.isEnabled) {
    return { ...fetchedFilters, only_external: filters.EXTERNAL.isEnabled };
  } else {
    return fetchedFilters;
  }
};

export const getRequests = (
  direction: RequestDirectionType,
  filterBy?: FilterType,
  includeProxyRequests: boolean = true,
): GetRequestsActionType => {
  const isSent = direction === 'sent';
  const filters = correctFilters(filterBy);
  return {
    type: '@requests/API/GET',
    payload: {
      endpointPath: ['requests', 'getRequests'],
      callParams: state =>
        getFilterCallParams(state, filters, isSent, includeProxyRequests),
      expectedStateChange: {
        [`requests.${direction}.filter.filterBy`]: filters,
      },
      selector: (data, state) =>
        pipe(
          assoc(direction, parseRequestsCategorized(data, state, direction)),
        )(state.requests),
    },
  };
};
