import React from 'react';
import { TranslatePropsType } from 'common/common.types';
import { Wrapper } from './EmptyPlaceholder.styles';
import { withTranslation } from 'react-i18next';

interface EmptyPlaceholderPropsType extends TranslatePropsType {
  label?: string;
}

const EmptyPlaceholder = ({ label, t }: EmptyPlaceholderPropsType) => (
  <Wrapper>
    {
      t('EmptyPlaceholder.noAdded', {
        label: label ? label.toLowerCase() : t('EmptyPlaceholder.value'),
      }) as string
    }
  </Wrapper>
);

export default withTranslation()(EmptyPlaceholder);
