import styled from 'styled-components';
import { Flex } from 'common/common.styles';

export const Text = styled.span`
  opacity: 0.6;
  color: ${({ theme }) => theme.atlas.text.secondary};
  font-style: italic;
  ${({ theme }) => theme.basic.fontWeights.regular}
`;

export const Wrapper = styled(Flex)`
  align-items: center;

  ${Text} {
    margin-right: 8px;
  }
`;
