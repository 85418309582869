/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreditsChangeSummaryMeta,
    CreditsChangeSummaryMetaFromJSON,
    CreditsChangeSummaryMetaFromJSONTyped,
    CreditsChangeSummaryMetaToJSON,
    RequestDetail,
    RequestDetailFromJSON,
    RequestDetailFromJSONTyped,
    RequestDetailToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface RequestDetailWithCreditsChangeSummaryResponse
 */
export interface RequestDetailWithCreditsChangeSummaryResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof RequestDetailWithCreditsChangeSummaryResponse
     */
    msg: string;
    /**
     * 
     * @type {RequestDetail}
     * @memberof RequestDetailWithCreditsChangeSummaryResponse
     */
    entry: RequestDetail;
    /**
     * 
     * @type {CreditsChangeSummaryMeta}
     * @memberof RequestDetailWithCreditsChangeSummaryResponse
     */
    meta: CreditsChangeSummaryMeta;
}

export function RequestDetailWithCreditsChangeSummaryResponseFromJSON(json: any): RequestDetailWithCreditsChangeSummaryResponse {
    return RequestDetailWithCreditsChangeSummaryResponseFromJSONTyped(json, false);
}

export function RequestDetailWithCreditsChangeSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestDetailWithCreditsChangeSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': RequestDetailFromJSON(json['entry']),
        'meta': CreditsChangeSummaryMetaFromJSON(json['meta']),
    };
}

export function RequestDetailWithCreditsChangeSummaryResponseToJSON(value?: RequestDetailWithCreditsChangeSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': RequestDetailToJSON(value.entry),
        'meta': CreditsChangeSummaryMetaToJSON(value.meta),
    };
}


