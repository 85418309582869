import React from 'react';
import Page, { PageBaseType, PagePropsType } from './Page';
import { Dispatch } from 'redux';
import { ActionType, StateType } from 'store/store.types';
import { getEvidenceForDownload } from 'store/downloadItems/actions/downloadEvidence';
import { DownloadsStateType } from 'store/downloadItems/downloadItems.reducer';
import Download from '../components/Download/Download';
import { FormsResourceGoneStateType } from 'store/forms/forms.reducer';

export interface DownloadEvidencePageContext extends PageBaseType {
  data: {
    downloads: DownloadsStateType;
    resourceGone: FormsResourceGoneStateType;
  };
}

const EvidenceDownloadPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      downloads: state.downloads,
      resourceGone: state.forms.resourceGoneState,
    })}
    dispatches={(dispatch: Dispatch<ActionType>) => ({
      getData: ({ routeParams: { id } }: { routeParams: { id: string } }) => {
        dispatch(getEvidenceForDownload(id));
      },
      actions: {},
    })}
  >
    <Download />
  </Page>
);

export default EvidenceDownloadPage;
