import React, { PureComponent, FocusEvent } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button } from '../../common/Button';
import { Creatable } from '../../common/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SimpleModal } from '../../common/SimpleModal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { parseEmail } from 'utils/parseEmail';
import { withTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'common/common.constants';
import Datepicker from '../../common/Datepicker';
import {
  FileType,
  MailingListItemType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import {
  ButtonWrapper,
  Closer,
  Content,
  InputWrapper,
  Wrapper,
} from '../ShareModal.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface FileSharePropsModalType extends TranslatePropsType {
  mailingList: MailingListItemType[];
  selectedFile: FileType;
  shareFiles: (
    fileIds: Uuid[],
    targets: string[],
    expirationDate: Date | null,
  ) => void;
  toggleFileShareModal: () => void;
}

interface TargetType {
  value: string;
  label: string;
}

interface StateType {
  targets: any;
  expirationDate: Date | null;
}

class FileShareModal extends PureComponent<FileSharePropsModalType, StateType> {
  constructor(props: any) {
    super(props);

    this.state = {
      targets: [],
      expirationDate: null,
    };
  }

  extractEmails = (text: string) => parseEmail(text) || [];

  onChange = (values: MailingListItemType[]) => {
    const newTargets: TargetType[] = [];

    values.forEach((item: any) => {
      this.extractEmails(item.value).forEach(target =>
        newTargets.push({ value: target, label: target }),
      );
    });

    this.setState({ targets: newTargets });
  };

  onBlur = (val: string) => {
    const emails = [...this.state.targets];

    this.extractEmails(val).forEach((value: string) => {
      const target: TargetType = { value, label: value };
      emails.push(target);
    });

    this.setState({ targets: emails });
  };

  onChangeExpirationDate = (expirationDate: Date | null) => {
    this.setState({ expirationDate });
  };

  handleClick = () => {
    const {
      selectedFile: { id },
      shareFiles,
    } = this.props;
    const { targets, expirationDate } = this.state;
    const emails = targets.map((target: TargetType) => target.value);

    return shareFiles([id], emails, expirationDate);
  };

  render() {
    const {
      mailingList,
      selectedFile: { name },
      t,
      toggleFileShareModal,
    } = this.props;
    const { expirationDate, targets } = this.state;

    const toLabel = t('FileShareModal.to');
    const noteLabel = t('FileShareModal.note');

    const faTimesProp = faTimes as IconProp;
    return (
      <SimpleModal
        isOpen
        label={t('FileShareModal.share', { name })}
        overflow={'visible'}
      >
        <Wrapper>
          <h3>{ReactHtmlParser(t('FileShareModal.share', { name }))}</h3>

          <Content>
            <InputWrapper className={'to'}>
              <label htmlFor="">{toLabel}</label>

              <Creatable
                isMulti
                onChange={this.onChange}
                value={targets}
                options={mailingList}
                placeholder={t('FileShareModal.placeholder')}
                onBlur={(event: FocusEvent<HTMLInputElement>) =>
                  this.onBlur(event.target.value)
                }
                className={'Select'}
                formatCreateLabel={(value: string) =>
                  t('Creatable.add', { value })
                }
              />

              <p>{noteLabel}</p>
            </InputWrapper>

            <InputWrapper>
              <Datepicker
                dateFormat={DATE_FORMAT}
                className={'datepicker-input'}
                selected={expirationDate}
                onChange={this.onChangeExpirationDate}
                label={t('FrameworkShareModal.expirationDate')}
                note={t('FrameworkShareModal.expirationDateNote')}
                placeholderText={t(
                  'FrameworkShareModal.expirationDatePlaceholder',
                )}
              />
            </InputWrapper>

            <ButtonWrapper>
              <Button color={'seaGreen'} onClick={this.handleClick}>
                {t('FileShareModal.send')}
              </Button>
            </ButtonWrapper>

            <Closer onClick={() => toggleFileShareModal()}>
              <FontAwesomeIcon icon={faTimesProp} />
            </Closer>
          </Content>
        </Wrapper>
      </SimpleModal>
    );
  }
}

export default withTranslation()(FileShareModal);
