import delay from 'utils/delayPromise';
import { ApiCallActionType } from '../../store.types';
import { MappingRequest } from '../../../generated/models/MappingRequest';
import { call, put } from 'redux-saga/effects';
import { getDashboardUpload } from '../../user/actions';
import { startLoading } from '../../app/actions';
import { updateUploadStatus } from '../utils';
import { FormsStateType } from '../forms.reducer';
import { UnreachableCaseError } from 'utils/errors';
import { UploadStatusEnum, Uuid } from '../../../common/common.types';

export interface FormsGetUploadFormStatusCallParamsType {
  id: Uuid;
}

export type FormsGetUploadFormStatusActionType = ApiCallActionType<
  '@forms/API/GET_UPLOAD_AND_MAP_STATUS',
  FormsGetUploadFormStatusCallParamsType,
  MappingRequest,
  never,
  FormsStateType
>;

const {
  ACCEPTED,
  FINISHED,
  PARSING,
  PARSING_SUCCEEDED,
  PARSING_FAILED,
  PARSING_EMPTY_RESULT,
  MAPPING,
  MAPPING_SUCCEEDED,
  MAPPING_FAILED,
} = UploadStatusEnum;

export const getUploadFormStatus = (
  id: Uuid,
  teamId?: Uuid,
): FormsGetUploadFormStatusActionType => ({
  type: '@forms/API/GET_UPLOAD_AND_MAP_STATUS',
  payload: {
    callParams: { id },
    endpointPath: ['forms', 'uploadAndMap', 'get'],
    selector: updateUploadStatus(id),
    postActions: ({ status }: MappingRequest) => {
      switch (status) {
        case ACCEPTED:
        case PARSING:
        case PARSING_SUCCEEDED:
        case MAPPING:
        case MAPPING_SUCCEEDED:
          return [
            () => call(delay, 10000),
            () => put(getUploadFormStatus(id, teamId)),
          ];
        case PARSING_FAILED:
        case PARSING_EMPTY_RESULT:
        case MAPPING_FAILED:
          return [];
        case FINISHED:
          return [
            () => put(startLoading(1)),
            () => put(getDashboardUpload(teamId)),
          ];
        default:
          throw new UnreachableCaseError(status as never);
      }
    },
  },
});
