import Datepicker from '../../common/Datepicker';
import React, { PureComponent, FocusEvent } from 'react';
import { Button } from '../../common/Button';
import { Creatable } from '../../common/Select';
import { DATE_FORMAT } from 'common/common.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SimpleModal } from '../../common/SimpleModal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { parseEmail } from 'utils/parseEmail';
import { withTranslation } from 'react-i18next';
import {
  FrameworkType,
  MailingListItemType,
  TranslatePropsType,
  Uuid,
} from 'common/common.types';
import {
  ButtonWrapper,
  Closer,
  Content,
  InputWrapper,
  Wrapper,
} from '../ShareModal.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface FrameworkSharePropsModalType extends TranslatePropsType {
  mailingList: MailingListItemType[];
  selectedFramework: FrameworkType;
  shareFrameworks: (
    formIds: Uuid[],
    targets: string[],
    expirationDate: Date | null,
  ) => void;
  toggleFrameworkShareModal: () => void;
}

interface TargetType {
  value: string;
  label: string;
}

interface StateType {
  targets: any;
  expirationDate: Date | null;
}

class FrameworkShareModal extends PureComponent<
  FrameworkSharePropsModalType,
  StateType
> {
  constructor(props: any) {
    super(props);

    this.state = {
      targets: [],
      expirationDate: null,
    };
  }

  extractEmails = (text: string) => parseEmail(text) || [];

  onChange = (values: MailingListItemType[]) => {
    const newTargets: TargetType[] = [];

    values.forEach((item: any) => {
      this.extractEmails(item.value).forEach(target =>
        newTargets.push({ value: target, label: target }),
      );
    });

    this.setState({ targets: newTargets });
  };

  onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) => {
    const emails = [...this.state.targets];

    this.extractEmails(value).forEach((val: string) => {
      const target: TargetType = { value: val, label: val };
      emails.push(target);
    });

    this.setState({ targets: emails });
  };

  onChangeExpirationDate = (expirationDate: Date | null) => {
    this.setState({ expirationDate });
  };

  handleClick = () => {
    const {
      selectedFramework: { id },
      shareFrameworks,
    } = this.props;
    const { expirationDate, targets } = this.state;
    const emails = targets.map((target: TargetType) => target.value);

    shareFrameworks([id], emails, expirationDate);
  };

  render() {
    const {
      selectedFramework: {
        standard: { name, version, year },
      },
      t,
      toggleFrameworkShareModal,
      mailingList,
    } = this.props;
    const { expirationDate, targets } = this.state;
    const yearBrackets = year && ['(', year, ')'].join('');
    const standard = [name, version, yearBrackets].join(' ');

    const shareLabel = t('FrameworkShareModal.share', { standard });
    const toLabel = t('FrameworkShareModal.to');
    const noteLabel = t('FrameworkShareModal.note');
    const faTimesProp = faTimes as IconProp;

    return (
      <SimpleModal isOpen label={'Share Standard'} overflow={'visible'}>
        <Wrapper>
          <h3>{shareLabel}</h3>

          <Content>
            <InputWrapper className={'to'}>
              <label htmlFor="">{toLabel}</label>

              <Creatable
                isMulti
                onChange={this.onChange}
                value={targets}
                options={mailingList}
                placeholder={t('FrameworkShareModal.placeholder')}
                onBlur={this.onBlur}
                className={'Select'}
                formatCreateLabel={(value: string) =>
                  t('Creatable.add', { value })
                }
              />

              <p>{noteLabel}</p>
            </InputWrapper>

            <InputWrapper>
              <Datepicker
                dateFormat={DATE_FORMAT}
                className={'datepicker-input'}
                selected={expirationDate}
                onChange={this.onChangeExpirationDate}
                label={t('FrameworkShareModal.expirationDate')}
                note={t('FrameworkShareModal.expirationDateNote')}
                placeholderText={t(
                  'FrameworkShareModal.expirationDatePlaceholder',
                )}
              />
            </InputWrapper>

            <ButtonWrapper>
              <Button
                color={'seaGreen'}
                disabled={targets.length === 0}
                onClick={this.handleClick}
              >
                {t('FrameworkShareModal.send')}
              </Button>
            </ButtonWrapper>

            <Closer onClick={toggleFrameworkShareModal}>
              <FontAwesomeIcon icon={faTimesProp} />
            </Closer>
          </Content>
        </Wrapper>
      </SimpleModal>
    );
  }
}

export default withTranslation()(FrameworkShareModal);
