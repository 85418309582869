import React, { useMemo } from 'react';
import {
  FormDefinitionValidationType,
  TranslatePropsType,
} from 'common/common.types';
import { Trans, withTranslation } from 'react-i18next';
import {
  AllIssuesFixed,
  Issues,
  IssuesEdited,
  IssuesLeft,
  PublishButton,
  SpinnerWrapper,
  Status,
  SwitchWrapper,
  Wrapper,
} from './ValidationBar.styles';
import Switch from '../../common/Switch';
import Label from '../../common/typography/Label';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidateFormFunctionType } from 'store/formCreator/actions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface ValidationBarPropsType extends TranslatePropsType {
  areOnlyInvalidQuestionsVisible: boolean;
  invalidCount: number;
  publishForm: () => void;
  showOnlyInvalidQuestions: () => void;
  validateForm: ValidateFormFunctionType;
  validation: FormDefinitionValidationType;
}

const ValidationBar = ({
  areOnlyInvalidQuestionsVisible = false,
  invalidCount,
  publishForm,
  showOnlyInvalidQuestions,
  validateForm,
  validation: { attemptedFixes, isLoading, correct },
  t,
}: ValidationBarPropsType) => {
  const editedCount = useMemo(() => Object.values(attemptedFixes).length, [
    attemptedFixes,
  ]);
  const leftCount = invalidCount - editedCount;

  if (correct) {
    return (
      <Wrapper>
        <Trans
          i18nKey="validationBar.allIssuesFixed"
          parent={AllIssuesFixed}
          t={t}
        >
          You fixed the issues, you are ready to
          <PublishButton onClick={publishForm}>
            Publish the questionnaire!
          </PublishButton>
        </Trans>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Issues>
        <span>
          {t('publishModal.foundIssue', { count: invalidCount }) as string}
        </span>
        {leftCount > 0 ? (
          <Trans i18nKey="validationBar.issueReviewed" parent="span" t={t}>
            You{' '}
            <IssuesEdited>
              <>{{ editedCount }}</>
            </IssuesEdited>
            , and
            <IssuesLeft>
              <>{{ leftCount }} left</>
            </IssuesLeft>
            to review
          </Trans>
        ) : (
          <Trans
            i18nKey="validationBar.issueReviewedZeroLeft"
            parent="span"
            t={t}
          >
            You{' '}
            <IssuesEdited>
              <>{{ editedCount }}</>
            </IssuesEdited>
            , and
            <IssuesLeft>
              <>{{ leftCount }} left</>
            </IssuesLeft>
            to review. Please
            <PublishButton onClick={validateForm}>validate again</PublishButton>
            before publishing.
          </Trans>
        )}

        <SwitchWrapper>
          <Switch
            isChecked={areOnlyInvalidQuestionsVisible}
            onChange={showOnlyInvalidQuestions}
          />
          <Label>{t('validationBar.showOnlyInvalidQuestions') as string}</Label>
        </SwitchWrapper>
      </Issues>
      {isLoading && (
        <Status>
          <span>
            <SpinnerWrapper>
              <FontAwesomeIcon icon={faSpinner as IconProp} spin />
            </SpinnerWrapper>
            {t('validationBar.validating') as string}
          </span>
          <span>{t('validationBar.validationExplain') as string}</span>
        </Status>
      )}
    </Wrapper>
  );
};

export default withTranslation('FormCreator')(ValidationBar);
