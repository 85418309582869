import { ActionType, StateType } from 'store/store.types';
import { Dispatch, compose } from 'redux';
import Loading from '../common/Loading';
import React from 'react';
import { TranslatePropsType } from 'common/common.types';
import { UserDetailInternalStatusEnum } from 'generated/models/UserDetail';
import { connect } from 'react-redux';
import { getUserMyself } from 'store/user/actions';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import NotAvailable from '../../pages/NotAvailable';
import MaintenancePage from '../../pages/MaintenancePage';
import { getUserFeatureFlags } from 'store/user/user.selectors';

const { MERGING } = UserDetailInternalStatusEnum;

interface LoadUserInfoType extends TranslatePropsType {
  tryLoadAndInitInfo: () => void;
  internalStatus?: UserDetailInternalStatusEnum;
  isAtlasMaintenanceEnabled?: boolean;
  children?: React.ReactNode;
  location: {
    pathname: string;
  };
}

class LoadUserInfo extends React.Component<LoadUserInfoType, {}> {
  componentDidMount() {
    const { tryLoadAndInitInfo } = this.props;

    tryLoadAndInitInfo();
  }

  render() {
    const {
      children,
      internalStatus,
      t,
      isAtlasMaintenanceEnabled,
    } = this.props;

    if (this.props.location.pathname === '/403' && !internalStatus) {
      return <NotAvailable />;
    }

    return internalStatus === MERGING ? (
      <Loading text={t('Loading.mergingUser') as string} />
    ) : isAtlasMaintenanceEnabled ? (
      <MaintenancePage />
    ) : (
      children
    );
  }
}

const mapState = ({ user }: StateType) => {
  return {
    internalStatus: user.currentUser.internalStatus,
    isAtlasMaintenanceEnabled:
      getUserFeatureFlags(user)?.ATLAS_MAINTENANCE_BANNER || false,
  };
};

const mapActions = (dispatch: Dispatch<ActionType>) => ({
  tryLoadAndInitInfo: () => dispatch(getUserMyself()),
});

export default compose(
  connect(mapState, mapActions),
  withRouter,
  withTranslation(),
)(LoadUserInfo);
