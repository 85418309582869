import baseTheme from '../light';

export default {
  ...baseTheme,
  nav: {
    ...baseTheme.nav,
    button: {
      bg: '#357FFA',
      color: 'white',
    },
    icon: {
      color: '#A6C7FF',
    },
    logo: {
      src: '/whitelabel/beta/highlinebeta.svg',
      width: '200px',
      marginRight: '20px',
      marginLeft: '20px',
    },
  },
  header: {
    backgroundImage: '/whitelabel/beta/bg@2x.png',
  },
};
