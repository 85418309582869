import React from 'react';
import styled from 'styled-components';
import { Content as CommonContent, HEADER_HEIGHT } from 'common/common.styles';
import FormOutline from './FormOutline';
import { PageHeader } from '../common/PageHeader';
import { FormOutlinePropsType } from './FormOutline/FormOutline';
import withHeaderVisibility, {
  HeaderVisibilityProps,
} from '../Layout/HeaderVisibility';
import { TYPICAL_HEIGHT as VALIDATION_BAR_TYPICAL_HEIGHT } from './ValidationBar';

const EDITOR_HEADER_BASE_HEIGHT = 110;

export const Wrapper = styled.div``;

export const Content = styled(CommonContent).attrs({
  padding: '0 15px',
})`
  display: flex;
`;

const StyledFormOutline = styled(FormOutline)`
  overflow-y: auto;
  position: sticky;
`;

export interface ScrollableFormOutlinePropsType
  extends FormOutlinePropsType,
    HeaderVisibilityProps {
  isValidationBarVisible: boolean;
}

export const ScrollableFormOutline = withHeaderVisibility(
  ({
    isHeaderVisible,
    isValidationBarVisible,
    // tslint:disable-next-line: trailing-comma
    ...props
  }: ScrollableFormOutlinePropsType) => {
    const editorHeaderFullHeight =
      EDITOR_HEADER_BASE_HEIGHT +
      (isValidationBarVisible ? VALIDATION_BAR_TYPICAL_HEIGHT : 0);

    const topOffset = isHeaderVisible
      ? HEADER_HEIGHT + editorHeaderFullHeight
      : editorHeaderFullHeight;

    return (
      <StyledFormOutline
        style={{
          maxHeight: `calc(100vh - ${topOffset}px)`,
          top: editorHeaderFullHeight,
        }}
        {...props}
      />
    );
  },
);

export const StyledPageHeader = styled(PageHeader).attrs({
  contentPadding: '30px 15px 10px 15px',
})`
  flex-shrink: 0;
`;

export const ValidationOverlay = styled.div`
  width: calc(100% - 30px);
  position: absolute;
  height: 100%;
  background: ${({ theme }) => theme.formCreator.validationOverlay};
  opacity: 0.3;
  z-index: 3;
`;
