import { SendRequestViewEnum } from '../requests.types';

export type RequestsSwitchSendRequestViewFunctionType = (
  nextView: SendRequestViewEnum,
) => void;

export interface RequestsSwitchSendRequestViewActionType {
  type: '@requests/SWITCH_SEND_REQUEST_VIEW';
  payload: {
    view: SendRequestViewEnum;
  };
}

export const switchSendRequestView = (
  view: SendRequestViewEnum,
): RequestsSwitchSendRequestViewActionType => ({
  type: '@requests/SWITCH_SEND_REQUEST_VIEW',
  payload: {
    view,
  },
});
