import styled from 'styled-components/macro';

import {
  Heading,
  ListHeading,
} from '../SendRequest/Standards/Standards.styles';

export { Heading, ListHeading };

export const SectionHeading = styled.div`
  line-height: 12px;
  margin: 20px 35px;
  /* font-size: ${({ theme }) => theme.basic.fontSizes.xsmall}; */
  ${({ theme }) => theme.fonts.regular.xsmall}
  color: ${({ theme }) => theme.atlas.text.secondary}

`;

export const Section = styled.div`
  background: ${({ theme }) => theme.atlas.white};
  padding: 20px 45px;
  margin-bottom: 35px;
`;

export const Instructions = styled.ol`
  list-style-position: inside;
  li {
    padding: 6px 0;
    ${({ theme }) => theme.basic.fontSizes.small}
  }

  a {
    color: ${({ theme }) => theme.basic.colors.atlas};
    ${({ theme }) => theme.fonts.semibold.normal}

    &:hover {
      text-decoration: none;
    }
  }
`;

export const UploadedStandards = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 100px;
  cursor: default;
`;
