import Loading from './Loading';
import QuestionMapping from './QuestionMapping';
import React, { useEffect } from 'react';
import { ContentContainer, ContentWrapper } from '../QuestionTabs.styles';
import { TabContentTitle } from '../index';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  FormsPutQuestionFunctionType,
  FormsRateMappingFunctionType,
  FormsGetQuestionMappingsFunctionType,
} from 'store/forms/actions';
import {
  LanguageType,
  QuestionMappingsType,
  QuestionType,
  TranslatePropsType,
} from 'common/common.types';
import { Scrollable } from '../Messages/Messages.styles';

interface QuestionMappingsPropsType extends TranslatePropsType {
  copyToClipboard?: (text: string) => void;
  getQuestionMappings?: FormsGetQuestionMappingsFunctionType;
  mappings?: QuestionMappingsType[];
  putQuestion: FormsPutQuestionFunctionType;
  question: QuestionType;
  rateMapping: FormsRateMappingFunctionType;
  showLikeness: boolean;
  language: LanguageType;
}

const QuestionMappings = ({
  copyToClipboard,
  getQuestionMappings,
  mappings,
  putQuestion,
  question,
  rateMapping,
  showLikeness,
  language,
  t,
}: QuestionMappingsPropsType) => {
  useEffect(() => {
    if (getQuestionMappings && !mappings) {
      getQuestionMappings(question.id, question.formId);
    }
  }, [getQuestionMappings, mappings, question.id, question.formId]);

  return (
    <ContentContainer hasBackground={mappings && mappings.length > 0}>
      <ContentWrapper>
        {mappings ? (
          <>
            <TabContentTitle
              icon={faMagic}
              title={[
                t('title') as string,
                t(mappings.length > 0 ? 'caption' : 'captionNegative'),
              ].join('<br /><br />')}
            />

            <Scrollable hasContent={mappings && mappings.length > 0}>
              {mappings ? (
                mappings.map((mapping, i) => (
                  <QuestionMapping
                    copyToClipboard={copyToClipboard}
                    key={i}
                    mapping={mapping}
                    putQuestion={putQuestion}
                    question={question}
                    rateMapping={rateMapping}
                    showLikeness={showLikeness}
                    mappingPosition={i + 1}
                    language={language}
                  />
                ))
              ) : (
                <Loading />
              )}
            </Scrollable>
          </>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    </ContentContainer>
  );
};

export default withTranslation('QuestionMappings')(QuestionMappings);
