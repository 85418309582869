import styled from 'styled-components';
import OrigUnverified from '../../Requests/Request/Unverified';

export const RightWrapper = styled.div`
  display: flex;
  align-self: stretch;
`;

export const Action = styled.div<{ active?: boolean }>`
  background: ${({ active, theme }) =>
    active ? theme.atlas.teal[300] : 'transparent'};
  height: 100%;
  display: flex;
  align-items: center;
  padding: 20px 15px;
  border-left: 1px solid ${({ theme }) => theme.basic.colors.darkerBorder};
  text-decoration: underline;
  cursor: pointer;
  ${({ theme }) => theme.fonts.bold.mini}

  &:hover {
    background: ${({ theme }) => theme.atlas.teal[100]};
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-style: italic;
  ${({ theme }) => theme.fonts.regular.mini}
`;

export const Info = styled.div`
  line-height: 20px;

  div {
    margin-bottom: 4px;

    &:last-of-type {
      margin: 0;
    }
  }

  strong {
    ${({ theme }) => theme.fonts.bold.normal}
    margin-right: 10px;
  }

  img {
    height: 21px;
    margin-right: 11px;
    margin-bottom: -5px;
  }
`;

export const Email = styled.span`
  ${({ theme }) => theme.fonts.regular.small}
  padding-right: 5px;
`;

export const Version = styled.span`
  ${({ theme }) => theme.fonts.regular.mini}
`;

export const Clickable = styled.span`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const ClickerWrapper = styled.div`
  cursor: pointer;
`;

export const TextInput = styled.input`
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  min-height: 38px;
  padding: 2px 8px;
  margin: 0;
  width: 100%;
  ${({ theme }) => theme.fonts.regular.normal}

  &::placeholder {
    color: ${({ theme }) => theme.atlas.grey[300]};
  }
`;

export const Unverified = styled(OrigUnverified)`
  cursor: pointer;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  display: inline-flex;
  padding-right: 10px;
`;
