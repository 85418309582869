import React from 'react';
import { TranslatePropsType } from 'common/common.types';
import { formatPrice, getTotal, getSavings } from './utils';
import { withTranslation } from 'react-i18next';
import {
  ColumnSaveText,
  ColumnTitle,
  ColumnTotalText,
  ContentRow,
  TitleColumnWrapper,
} from './CheckoutInput.styles';

export interface TotalPriceColumnType extends TranslatePropsType {
  quantity: number | undefined;
  unitPrice: number;
  fullPrice: number;
}

const TotalPriceColumn = ({
  unitPrice,
  fullPrice,
  quantity = 0,
  t,
}: TotalPriceColumnType) => (
  <TitleColumnWrapper>
    <ColumnTitle>{t('total') as string}</ColumnTitle>

    <ContentRow>
      <ColumnTotalText>
        {formatPrice(getTotal(quantity, unitPrice))}
      </ColumnTotalText>

      {fullPrice !== unitPrice ? (
        <ColumnSaveText>
          {
            t('save', {
              price: formatPrice(getSavings(quantity, unitPrice, fullPrice)),
            }) as string
          }
        </ColumnSaveText>
      ) : null}
    </ContentRow>
  </TitleColumnWrapper>
);

export default withTranslation('CreditsSelfCheckout')(TotalPriceColumn);
