import { FormMetadata } from '../../../generated/models/FormMetadata';
import { Uuid } from '../../../common/common.types';
import { assocPath, pathOr, pipe } from 'ramda';
import { getFiltersCallParam } from '../../../components/Form/utils';
import { getUpdatedQuestionsIds } from '../forms.selectors';
import { parseFilterStats } from 'utils/parsers/forms.parsers';
import { parseForm } from 'utils/parsers';
import { put } from 'redux-saga/effects';
import { switchQuestionTab } from './switchQuestionTab';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { FormsGetFormCallParams } from './getForm';
import { SupportedFilterBranchType } from 'utils/filters/forms.filters';
import { enableFilters } from './enableFilters';
import { containsTab } from '../utils';

export type FormsAddFilterActionType = ApiCallActionType<
  '@forms/API/ADD_FILTER',
  FormsGetFormCallParams,
  FormMetadata,
  never,
  FormsStateType
>;

export const addFilter = (
  branch: SupportedFilterBranchType,
  filterName: string,
  formId?: Uuid,
  questionIds?: Uuid[],
): FormsAddFilterActionType => ({
  type: '@forms/API/ADD_FILTER',
  payload: {
    callParams: state => ({
      id: formId
        ? formId
        : pathOr(undefined, ['forms', 'currentForm', 'id'], state),
      ...getFiltersCallParam(state.forms.filters),
      questionIds: questionIds || getUpdatedQuestionsIds(state.forms),
    }),
    endpointPath: ['forms', 'get'],
    preActions: [put(enableFilters([{ branch, filterName }]))],
    selector: (data, state): any =>
      pipe(
        assocPath(['currentForm'], parseForm(data, state)),
        assocPath(
          ['filters'],
          parseFilterStats(data.filter_stats, data.assignee_stats, state.forms),
        ),
      )(state.forms),
    postActions: [
      () => {
        if (branch === 'simple') {
          const tab = containsTab(filterName);
          return tab ? put(switchQuestionTab(tab)) : null;
        }
        return null;
      },
    ],
  },
});
