import React from 'react';
import { scoreScale } from './ScoreChart.styles';
import { RequestSimpleType, TranslatePropsType } from 'common/common.types';
import { getBarWidth } from '../utils';
import ScoreRecord from './ScoreRecord';
import ReactHtmlParser from 'react-html-parser';
import { ChartGauge } from '../Insights.styles';
import { CompletionLegend } from './index';
import { withTranslation } from 'react-i18next';
import { StatusEnum } from '../../../generated/models/RequestSimple';

export interface CompletionChartPropsType extends TranslatePropsType {
  requests: RequestSimpleType[];
}

const CompletionChart = ({ requests, t }: CompletionChartPropsType) => (
  <ChartGauge title={ReactHtmlParser(t('charts.completionTime'))}>
    <CompletionLegend />
    <>
      {requests.map(
        (
          {
            completionDays,
            form: { id },
            meta: { companyId },
            target: { name },
            standard: { name: questionnaireName },
            status,
          }: RequestSimpleType,
          index: number,
        ) => (
          <ScoreRecord
            key={`completion-bar-${id}`}
            color={scoreScale(100 - completionDays!).hex()}
            companyId={companyId}
            formId={id}
            name={questionnaireName}
            title={name}
            hasStripes={status !== StatusEnum.ACCEPTED}
            width={getBarWidth(
              requests[requests.length - 1].completionDays!,
              completionDays!,
              90,
            )}
          >
            {index === 0
              ? ReactHtmlParser(t('days', { count: completionDays! }))
              : completionDays}
          </ScoreRecord>
        ),
      )}
    </>
  </ChartGauge>
);

export default withTranslation('Insights')(CompletionChart);
