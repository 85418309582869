/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreditsDetail,
    CreditsDetailFromJSON,
    CreditsDetailFromJSONTyped,
    CreditsDetailToJSON,
    UserDetail,
    UserDetailFromJSON,
    UserDetailFromJSONTyped,
    UserDetailToJSON,
    UserInfoStats,
    UserInfoStatsFromJSON,
    UserInfoStatsFromJSONTyped,
    UserInfoStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {CreditsDetail}
     * @memberof UserInfo
     */
    credits?: CreditsDetail;
    /**
     * List of LaunchDarkly feature toggles
     * @type {object}
     * @memberof UserInfo
     */
    ldToggles: object;
    /**
     * User-specific settings
     * @type {object}
     * @memberof UserInfo
     */
    settings: object;
    /**
     * User statistics
     * @type {UserInfoStats}
     * @memberof UserInfo
     */
    stats: UserInfoStats;
    /**
     * List of Atlas feature toggles
     * @type {object}
     * @memberof UserInfo
     */
    toggles: object;
    /**
     * 
     * @type {UserDetail}
     * @memberof UserInfo
     */
    user: UserDetail;
}

export function UserInfoFromJSON(json: any): UserInfo {
    return UserInfoFromJSONTyped(json, false);
}

export function UserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'credits': !exists(json, 'credits') ? undefined : CreditsDetailFromJSON(json['credits']),
        'ldToggles': json['ld_toggles'],
        'settings': json['settings'],
        'stats': UserInfoStatsFromJSON(json['stats']),
        'toggles': json['toggles'],
        'user': UserDetailFromJSON(json['user']),
    };
}

export function UserInfoToJSON(value?: UserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credits': CreditsDetailToJSON(value.credits),
        'ld_toggles': value.ldToggles,
        'settings': value.settings,
        'stats': UserInfoStatsToJSON(value.stats),
        'toggles': value.toggles,
        'user': UserDetailToJSON(value.user),
    };
}


