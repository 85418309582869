import styled from 'styled-components';
import { Bar } from './ScoreChart.styles';

export const Wrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 20px;
`;

export const Name = styled.div`
  padding-right: 5px;
  color: ${({ theme }) => theme.atlas.text.secondary};
  ${({ theme }) => theme.fonts.regular.mini}
`;

export const Legend = styled.div`
  display: flex;
  justify-content: flex-end;

  > ${Bar} {
    min-width: 26px;
  }

  & + & {
    padding-top: 5px;
  }
`;
