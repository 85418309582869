/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface Evidence
 */
export interface Evidence {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof Evidence
     */
    createdAt: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof Evidence
     */
    updatedAt: Date;
    /**
     * Size of the file in bytes
     * @type {number}
     * @memberof Evidence
     */
    fileSize: number;
    /**
     * Indicates an association with an evidence locker entity
     * @type {boolean}
     * @memberof Evidence
     */
    hasEvidenceLockerEntity: boolean;
    /**
     * 
     * @type {string}
     * @memberof Evidence
     */
    id: string;
    /**
     * Evidence should be displayed in the 'evidence locker' page
     * @type {boolean}
     * @memberof Evidence
     */
    inLocker: boolean;
    /**
     * Name of the file
     * @type {string}
     * @memberof Evidence
     */
    name: string;
    /**
     * Company ID of the uploader
     * @type {string}
     * @memberof Evidence
     */
    ownerId: string;
    /**
     * User ID of the uploader
     * @type {string}
     * @memberof Evidence
     */
    uploaderId: string;
}

export function EvidenceFromJSON(json: any): Evidence {
    return EvidenceFromJSONTyped(json, false);
}

export function EvidenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Evidence {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'fileSize': json['file_size'],
        'hasEvidenceLockerEntity': json['has_evidence_locker_entity'],
        'id': json['id'],
        'inLocker': json['in_locker'],
        'name': json['name'],
        'ownerId': json['owner_id'],
        'uploaderId': json['uploader_id'],
    };
}

export function EvidenceToJSON(value?: Evidence | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'file_size': value.fileSize,
        'has_evidence_locker_entity': value.hasEvidenceLockerEntity,
        'id': value.id,
        'in_locker': value.inLocker,
        'name': value.name,
        'owner_id': value.ownerId,
        'uploader_id': value.uploaderId,
    };
}


