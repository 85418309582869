import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { ApiCallActionPayloadType } from '../../store.types';
import { SettingsStateType } from '../settings.reducer';
import { reject } from 'ramda';
import update from 'immutability-helper';
import { Uuid } from '../../../common/common.types';

export type SettingsToggleFileVisibilityActionType = OptimisticActionType<
  '@settings/API/SET_FILE_VISIBILITY',
  ApiCallActionPayloadType<SettingsStateType>
>;

const updateVisibility = (
  fileId: Uuid,
  visible: boolean,
  hiddenFiles: Uuid[] = [],
) =>
  visible
    ? reject(it => it === fileId, hiddenFiles || [])
    : [...hiddenFiles, fileId];

export const setFileVisibility = (
  fileId: Uuid,
  visible: boolean,
): SettingsToggleFileVisibilityActionType => ({
  type: '@settings/API/SET_FILE_VISIBILITY',
  payload: {
    endpointPath: ['settings', 'set'],
    expectedStateChange: {
      settings: (settings: SettingsStateType) =>
        update(settings, {
          hiddenFiles: {
            $set: updateVisibility(fileId, visible, settings.hiddenFiles),
          },
        }),
    },
    // state is already updated by expectedStateChange
    callParams: ({ settings: { hiddenFiles } }) => ({
      hiddenFiles,
    }),
    selector: null,
  },
});
