import { ApiCallActionType } from '../../store.types';
import { FormCreatorStateType } from '../formCreator.types';
import i18n from 'i18next';
import { put } from 'redux-saga/effects';
import { FormDefinitionSummaryDraftExtended } from '../../../generated/models/FormDefinitionSummaryDraftExtended';
import { parseFormDefinitionSummaryDraftExtended } from 'utils/parsers/formDefinition.parsers';
import { push } from 'connected-react-router';
import formatRoute, { FORM_CREATOR } from '../../../routes';
import { startLoading } from '../../app/actions';
import { setCurrentForm } from '../utils';
import { getAvailableStandardName } from '../utils/getAvailableStandardName';
import { Uuid } from '../../../common/common.types';

export interface DuplicateStandardAndRedirectToFormCreatorParamsType {
  definition_id: Uuid;
  standard_name: string;
}

export type DuplicateStandardAndRedirectToFormCreatorFunctionType = (
  params: DuplicateStandardAndRedirectToFormCreatorParamsType,
) => void;

export type DuplicateStandardAndRedirectToFormCreatorActionType = ApiCallActionType<
  '@formCreator/API/DUPLICATE_STANDARD_AND_REDIRECT_TO_FORM_CREATOR',
  DuplicateStandardAndRedirectToFormCreatorParamsType,
  FormDefinitionSummaryDraftExtended,
  never,
  FormCreatorStateType
>;

export const duplicateStandardAndRedirectToFormCreator = (
  params: DuplicateStandardAndRedirectToFormCreatorParamsType,
): DuplicateStandardAndRedirectToFormCreatorActionType => ({
  type: '@formCreator/API/DUPLICATE_STANDARD_AND_REDIRECT_TO_FORM_CREATOR',
  payload: {
    endpointPath: ['definitions', 'drafts', 'post'],
    callParams: {
      ...params,
      standard_name: i18n.t(
        'FormDefinitions:duplicatedName',
        getAvailableStandardName(params.standard_name),
      ),
    },
    preActions: [
      put(
        startLoading(
          2,
          i18n.t('FormNewFromExisting:templateProcessing', {
            standard: params.standard_name,
          }),
        ),
      ),
    ],
    selector: (data, state) => {
      setCurrentForm(
        state.formCreator,
        parseFormDefinitionSummaryDraftExtended(data),
      );
      return null;
    },
    postActions: [
      data => put(push(formatRoute(FORM_CREATOR, { id: data.id }))),
    ],
  },
});
