import React, { ComponentType } from 'react';
import { RouteComponentProps } from 'react-router';
import { SHARED_FORM } from '../../routes';
import { connect } from 'react-redux';
import { StateType } from 'store/store.types';
import { CurrentUserType } from 'common/common.types';

interface AuthenticationPropsType extends RouteComponentProps {
  user: CurrentUserType;
}

export default (ComposedComponent: ComponentType<any>) => {
  class Authentication extends React.PureComponent<AuthenticationPropsType> {
    isGuestEnabled = () => {
      const {
        match: { path },
      } = this.props;

      return path === SHARED_FORM;
    };

    render() {
      const { user } = this.props;
      if (user.isAuthenticated || this.isGuestEnabled()) {
        return <ComposedComponent {...this.props} />;
      } else {
        return null;
      }
    }
  }

  const mapState = (state: StateType) => ({
    user: state.user.currentUser,
  });

  return connect(mapState)(Authentication);
};
