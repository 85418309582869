import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';
import { TextInput, Wrapper } from './TextResponse.styles';
import { withTranslation } from 'react-i18next';
import { TranslatePropsType } from 'common/common.types';
import { ResponseComponentPropsType } from '../Response';

interface TextResponsePropsType
  extends ResponseComponentPropsType,
    TranslatePropsType {}

const TextResponse = ({
  response: {
    definition: { placeholder },
  },
  updateDefinition,
  t,
}: TextResponsePropsType) => {
  const onPlaceholderChanged = useCallback(
    debounce((value: string) => {
      updateDefinition({ placeholder: value });
    }, 1000),
    [updateDefinition],
  );
  return (
    <Wrapper>
      <TextInput
        placeholder={t('placeholder') as string}
        onChange={onPlaceholderChanged}
        value={placeholder}
      />
    </Wrapper>
  );
};

export default withTranslation('FormCreator')(TextResponse);
