import colors from 'common/common.colors';
import styled from 'styled-components';
import { StatusFlagType } from 'common/common.types';

export const FlashContainer = styled.div`
  position: relative;
  z-index: 50000;
  border: 3px solid rgba(255, 255, 255, 0.6);
  border-radius: 12px;
  margin-bottom: 12px;
`;

export const FlashToast = styled.div`
  width: 280px;
  padding: 10px 30px 10px 12px;
  border-radius: 8px;
  background-color: ${({ status }: { status: StatusFlagType }) => {
    switch (status) {
      case 'success':
        return colors.basic.success;
      case 'error':
        return colors.basic.error;
      case 'warning':
        return colors.basic.warning;
      case 'info':
      default:
        return colors.basic.info;
    }
  }};
`;

export const Text = styled.span`
  ${({ theme }) => theme.fonts.bold.normal}
  color: ${colors.basic.white};
`;

export const Closer = styled.div`
  position: absolute;
  right: 12px;
  top: 11px;
  cursor: pointer;
`;
