import i18n from '../../../i18n';
import { Attachment } from '../../../generated/models/Attachment';
import update from 'immutability-helper';
import { call, put } from 'redux-saga/effects';
import { flash } from '../../flashes/actions';
import { hydrateFilters } from '.';
import { parseAttachment } from 'utils/parsers';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionPayloadType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { AttachmentsDict, Uuid } from '../../../common/common.types';
import { createDummyAttachment } from 'utils/parsers/attachment.parsers';

export type FormsCreateQuestionAttachmentFunctionType = (
  formData: FormData,
  formId: Uuid,
  questionId: Uuid,
) => void;

export interface FormsCreateQuestionAttachmentCallParamsType {
  formData: FormData;
  formId: Uuid;
  questionId: Uuid;
}

export type FormsCreateQuestionAttachmentActionType = OptimisticActionType<
  '@forms/API/CREATE_QUESTION_ATTACHMENT',
  ApiCallActionPayloadType<
    FormsCreateQuestionAttachmentCallParamsType,
    Attachment,
    never,
    FormsStateType
  >
>;

export const createQuestionAttachment = (
  formData: FormData,
  formId: Uuid,
  questionId: Uuid,
): FormsCreateQuestionAttachmentActionType => {
  const uploadingId = formData.get('uploadingId') as Uuid;

  return {
    type: '@forms/API/CREATE_QUESTION_ATTACHMENT',
    payload: {
      callParams: { formData, formId, questionId },
      endpointPath: ['forms', 'questions', 'addAttachment'],
      expectedStateChange: {
        [`forms.currentForm.questions.${questionId}.attachments`]: (
          attachments: AttachmentsDict,
        ) =>
          update(attachments, {
            $merge: {
              [uploadingId]: createDummyAttachment(
                formData.get('file') as File,
                uploadingId,
              ),
            },
          }),
      },
      selector: (data, { forms }) =>
        update(forms, {
          currentForm: {
            questions: {
              [questionId]: {
                attachments: {
                  $merge: {
                    [data.id]: parseAttachment(data),
                  },
                  $unset: [uploadingId],
                },
              },
            },
          },
        }),
      postActions: [
        () => put(hydrateFilters(formId)),
        () => call(() => trackEvent('Attachment', 'Add - Question')),
        () => put(flash(i18n.t('common:responses.fileCreateSuccess'))),
      ],
    },
  };
};
