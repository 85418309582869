import styled from 'styled-components/macro';
import { Dropdown } from '../common/Dropdown';
import { ChildrenWrapper } from '../common/Dropdown/Dropdown.styles';
import { Option } from '../Requests/SortBy.styles';

export const Switch = styled(Dropdown)`
  width: 89px;
  min-width: 89px;
  padding: 0 13px;
  line-height: 36px;
`;

export const Toggle = styled.div`
  position: relative;
  cursor: pointer;
  color: ${({ theme }) => theme.atlas.text.primary};
  background: ${({ theme }) => theme.atlas.teal[300]};
  ${({ theme }) => theme.fonts.bold.small};
  border-radius: 3px;
  margin-right: 20px;

  &:hover {
    background: ${({ theme }) => theme.atlas.teal[100]};
  }

  &:hover:after {
    border-left-color: ${({ theme }) => theme.atlas.teal[100]};
  }

  &:after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    border-left-color: ${({ theme }) => theme.atlas.teal[300]};
    border-width: 8px;
    margin-top: -8px;
  }
`;

export const Content = styled(ChildrenWrapper)`
  padding: 0;
  border-radius: 3px;
  margin-top: 1px;
  width: 89px;
`;

export const ContextOption = styled(Option)`
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  color: ${({ theme }) => theme.atlas.text.primary};

  &:first-of-type {
    border-radius: 3px 3px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 3px 3px;
  }
`;
