import Datepicker from '../../common/Datepicker/Datepicker';
import React from 'react';
import { addWeeks } from 'date-fns';
import { ClickableLabel, LabelSpaced } from '../Stepper.styles';
import { DATE_FORMAT } from 'common/common.constants';
import { SetFormValueFunctionType } from 'store/requests/actions';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface DueDatePropsType extends TranslatePropsType {
  dueDate?: Date | null;
  setValue: SetFormValueFunctionType;
}

const DueDate = ({ dueDate, setValue, t }: DueDatePropsType) => (
  <div data-cy="sq-due-date">
    <LabelSpaced>{t('dueDate') as string}</LabelSpaced>

    <ClickableLabel>
      <Datepicker
        data-pendo={'due-date-picker'}
        data-cy={'sq-due-date-picker'}
        className={'datepicker-input'}
        dateFormat={DATE_FORMAT}
        onChange={val => setValue('dueDate', val || undefined)}
        openToDate={addWeeks(new Date(), 2)}
        minDate={new Date()}
        selected={dueDate}
      />
    </ClickableLabel>
  </div>
);

export default withTranslation('SendRequest')(DueDate);
