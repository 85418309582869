const NORMALIZE_REGEXP = /(^[ .,\n\t]+)|([ .,\n\t]+$)/g;

export const OPTION_YES = 'YES';
export const OPTION_NO = 'NO';
export const OPTION_NA = 'N/A';
export const OPTION_EMPTY = '';

export const OPTIONS_YES_NO = [OPTION_YES, OPTION_NO, OPTION_EMPTY];

export const OPTIONS_YES_NO_NA = [
  OPTION_YES,
  OPTION_NO,
  OPTION_NA,
  OPTION_EMPTY,
];

export const normalizeOption = (option: string) =>
  option.replace(NORMALIZE_REGEXP, '').toUpperCase();
