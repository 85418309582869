import { ApiCallActionType } from '../../store.types';
import { FormProxyStateType } from '../formProxy.reducer';
import update from 'immutability-helper';
import { any, reject } from 'ramda';
import { FormCollaboratorType, Uuid } from '../../../common/common.types';
import { call } from 'redux-saga/effects';

export interface FormProxyRevokeAccessCallParamsType {
  formId: Uuid;
  proxyId: Uuid;
  callback?: () => void;
}

export type FormProxyRevokeAccessFunctionType = (
  formId: Uuid,
  proxyId: Uuid,
  callback?: () => void,
) => void;

export type FormProxyRevokeAccessActionType = ApiCallActionType<
  '@formProxy/API/REVOKE_ACCESS',
  FormProxyRevokeAccessCallParamsType,
  never,
  never,
  FormProxyStateType
>;

export const revokeAccess = (
  formId: Uuid,
  proxyId: Uuid,
  callback?: () => void,
): FormProxyRevokeAccessActionType => ({
  type: '@formProxy/API/REVOKE_ACCESS',
  payload: {
    callParams: { formId, proxyId },
    endpointPath: ['forms', 'proxies', 'delete'],
    selector: (data, state): Partial<FormProxyStateType> =>
      update(state.formProxy, {
        collaborators: {
          $apply: reject((collaborator: FormCollaboratorType) =>
            any(proxy => proxy.id === proxyId, collaborator.proxies),
          ),
        },
      }),
    postActions: callback ? [() => call(callback)] : undefined,
  },
});
