import DueDateKanban from './DueDate/DueDateKanban';
import React from 'react';
import StatusIcon from './StatusIcon';
import route, { FORM } from '../../../routes';
import { Company, Link, Wrapper, Year } from './RequestCard.styles';
import { LanguageType, RequestSimpleType } from 'common/common.types';

export interface RequestCardPropsType {
  data: {
    request: RequestSimpleType;
    color: string;
    language: LanguageType;
  };
}

const RequestCard = ({
  data: {
    request: {
      dueDate,
      form: { id },
      meta: { email, company, isVisible },
      requestedAt,
      acceptedDate,
      standard: { name, version },
      status,
    },
    color,
    language,
  },
}: RequestCardPropsType) => (
  <Wrapper color={color} isVisible={isVisible}>
    <Company>
      <div>{company}</div>
      <div>{email}</div>
    </Company>

    <Link to={route(FORM, { id })}>{name}</Link>

    <Year>{version}</Year>

    <DueDateKanban
      dueDate={dueDate}
      requestedAt={requestedAt}
      acceptedDate={acceptedDate}
      language={language}
    />

    <StatusIcon color={color} status={status} />
  </Wrapper>
);

export default RequestCard;
