/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Base } from "./Base";

/**
 * 
 * @export
 * @interface ResponseTypeDefinition
 */
export interface ResponseTypeDefinition {
    /**
     * When was the entity created
     * @type {Date}
     * @memberof ResponseTypeDefinition
     */
    created_at: Date;
    /**
     * When was the entity updated
     * @type {Date}
     * @memberof ResponseTypeDefinition
     */
    updated_at: Date;
    /**
     * 
     * @type {string}
     * @memberof ResponseTypeDefinition
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTypeDefinition
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTypeDefinition
     */
    label: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseTypeDefinition
     */
    options: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResponseTypeDefinition
     */
    placeholder: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTypeDefinition
     */
    response_type: ResponseTypeDefinitionResponseTypeEnum;
}




/**
* @export
* @enum {string}
*/
export enum ResponseTypeDefinitionResponseTypeEnum {
    EVIDENCE = 'EVIDENCE',
    MULTISELECT = 'MULTI_SELECT',
    NORESPONSE = 'NO_RESPONSE',
    NUMBER = 'NUMBER',
    PERCENTAGE = 'PERCENTAGE',
    SINGLESELECT = 'SINGLE_SELECT',
    TEXT = 'TEXT'
}


