import {
  QuestionDefinitionSummaryType,
  ResponseDefinitionSummaryType,
  Uuid,
} from '../../../common/common.types';
import { FormEditEventActionCodeEnum } from '../../../generated/models/FormEditEvent';
import { assoc, map, reject } from 'ramda';
import { EventSequencerActionBaseType } from '../eventSequencer/eventSequencer.types';
import update from 'immutability-helper';

export type DeleteResponseFunctionType = (responseId: Uuid) => void;

export type DeleteResponseActionType = EventSequencerActionBaseType<
  '@formCreator/EVENTS/DELETE_RESPONSE'
>;

export const deleteResponse = (responseId: Uuid): DeleteResponseActionType => ({
  type: '@formCreator/EVENTS/DELETE_RESPONSE',
  payload: {
    events: [
      {
        action: FormEditEventActionCodeEnum.DELETERESPONSE,
        response_uuid: responseId,
      },
    ],
    selector: ({ formCreator }) =>
      update(formCreator, {
        currentForm: {
          questions: {
            $apply: map((question: QuestionDefinitionSummaryType) => {
              const rejected = reject(
                (response: ResponseDefinitionSummaryType) =>
                  response.uniqueId === responseId,
                question.responses,
              );
              return rejected.length !== question.responses.length
                ? assoc('responses', rejected, question)
                : question;
            }),
          },
        },
        validation: {
          attemptedFixes: { $unset: [responseId] },
        },
      }),
  },
});
