import CookiesBanner from '../CookiesBanner';
import CreditsSelfCheckout from '../Upsell/CreditsSelfCheckout';
import FlashMessages from '../FlashMessages';
import Footer from './Footer';
import Header from './Header';
import Notifications from '../Notifications/Notifications';
import React, { ComponentType, PureComponent } from 'react';
import withAuth from '../Authentication';
import { ActionType, StateType } from 'store/store.types';
import { CurrentUserType, NavigationContextEnum } from 'common/common.types';
import { Main } from './Layout.styles';
import { appealCredits as appealCreditsAction } from 'store/support/actions';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { logout as logoutAction } from 'store/user/actions/logout';
import { setSettings } from 'store/settings/actions';
import { toggleHeader as toggleHeaderAction } from 'store/ui/actions/toggleHeader';
import {
  closeModal as closeModalAction,
  createSession,
  getPricingInfo,
  openModal,
} from 'store/stripe/actions';
import { switchContext as switchContextAction } from 'store/user/actions';
import { CONTEXT_OPTION_ROUTES } from 'common/common.constants';
import { push } from 'connected-react-router';
import { Omit } from 'ramda';

export interface LayoutPropsType {
  agreeCookies: () => void;
  appealCredits: () => void;
  closeModal: () => void;
  currentUser: CurrentUserType;
  hasAgreedCookies?: boolean;
  isCreditsModalOpened: boolean;
  logout: () => void;
  onCreditsCheckout: (price: number, quantity: number) => void;
  pricings?: any;
  switchContext: (
    context: NavigationContextEnum,
    canRedirect?: boolean,
  ) => void;
  toggleHeader: (isVisible: boolean) => void;
  getLanguagePreference: {
    value: string;
    label: string;
  };
  setLanguagePreference: (value: { label: string; value: string }) => void;
}

export interface LayoutOptionsType {
  navContext?: NavigationContextEnum;
  hasHeader?: boolean;
}

export const withHeader = (
  Child: ComponentType<any>,
  options: LayoutOptionsType = {},
  childProps: any = {},
) => {
  const { navContext, hasHeader = true } = options;

  class Layout extends PureComponent<LayoutPropsType> {
    componentDidMount() {
      const { currentUser, switchContext } = this.props;
      if (navContext && currentUser.navigationContext !== navContext) {
        switchContext(navContext, false);
      }
    }

    render() {
      const {
        agreeCookies,
        appealCredits,
        closeModal,
        currentUser,
        currentUser: { isNormal },
        hasAgreedCookies,
        isCreditsModalOpened,
        logout,
        onCreditsCheckout,
        pricings,
        switchContext,
        toggleHeader,
        getLanguagePreference,
        setLanguagePreference,
      } = this.props;

      return (
        <>
          {isNormal && (
            <>
              <Notifications withOffset={Boolean(hasHeader)} />

              <CreditsSelfCheckout
                appealCredits={appealCredits}
                closeModal={closeModal}
                isCreditsModalOpened={isCreditsModalOpened}
                onCreditsCheckout={onCreditsCheckout}
                pricings={pricings}
                user={currentUser}
              />
            </>
          )}

          <FlashMessages />

          <Header
            appealCredits={appealCredits}
            hide={childProps.hideHeader}
            logout={logout}
            switchContext={switchContext}
            toggleHeader={toggleHeader}
            user={currentUser}
            setLanguagePreference={setLanguagePreference}
            getLanguagePreference={getLanguagePreference}
          />

          <Main>
            <Child {...childProps} />
          </Main>

          <Footer />

          {!hasAgreedCookies && <CookiesBanner agreeCookies={agreeCookies} />}
        </>
      );
    }
  }

  const mapState = (state: StateType) => ({
    currentUser: state.user.currentUser,
    hasAgreedCookies: state.settings.hasAgreedCookies,
    isCreditsModalOpened: state.stripe.modal.isOpen,
    pricings: state.stripe.pricings,
    getLanguagePreference: state.settings.languagePreference,
  });

  const mapDispatch = (dispatch: Dispatch<ActionType>) => ({
    agreeCookies: () => dispatch(setSettings({ hasAgreedCookies: true })),
    setLanguagePreference: (value: any) =>
      dispatch(setSettings({ languagePreference: value })),
    closeModal: () => dispatch(closeModalAction()),
    appealCredits: () => {
      dispatch(getPricingInfo());
      dispatch(openModal());
      dispatch(appealCreditsAction());
    },
    logout: () => dispatch(logoutAction()),
    onCreditsCheckout: (price: number, quantity: number) => {
      dispatch(createSession(price, quantity));
    },
    switchContext: (
      context: NavigationContextEnum,
      canRedirect: boolean = true,
    ) => {
      if (canRedirect && context in CONTEXT_OPTION_ROUTES) {
        dispatch(push(CONTEXT_OPTION_ROUTES[context]));
      }
      dispatch(switchContextAction(context));
    },
    toggleHeader: (isVisible: boolean) =>
      dispatch(toggleHeaderAction(isVisible)),
  });

  return compose(connect(mapState, mapDispatch))(withAuth(Layout));
};

export const withoutHeader = (
  Child: ComponentType<any>,
  options: Omit<LayoutOptionsType, 'hasHeader'> = {},
  childProps: any = {},
) => withHeader(Child, { ...options, hasHeader: false }, childProps);
