import { insert, findIndex, update, evolve } from 'ramda';
import i18n from 'i18next';
import { ApiCallActionPayloadType, StateType } from '../../store.types';
import { OptimisticActionType } from 'utils/optimistic/optimistic.types';
import { StandardType, Uuid } from '../../../common/common.types';
import { StandardStatusEnum } from '../../../generated/models/Standard';

import { FormDefinitionsStateType } from '../formDefinitions.types';

import { FormDefinitionSummaryDraftExtended } from '../../../generated/models/FormDefinitionSummaryDraftExtended';
import { parseFormDefinitionSummaryDraftExtended } from 'utils/parsers/formDefinition.parsers';
import { getAvailableStandardName } from '../../formCreator/utils/getAvailableStandardName';
import { EMPTY_ID } from '../../../common/common.constants';

export type DuplicateStandardFromFormDefinitionsFunctionType = (
  params: DuplicateStandardFromFormDefinitionsParamsType,
) => void;

export interface DuplicateStandardFromFormDefinitionsParamsType {
  definition_id: Uuid;
  standard_name: string;
}

export type DuplicateStandardFromFormDefinitionsActionType = OptimisticActionType<
  '@formDefinitions/API/DUPLICATE_STANDARD',
  ApiCallActionPayloadType<
    DuplicateStandardFromFormDefinitionsParamsType,
    FormDefinitionSummaryDraftExtended,
    never,
    FormDefinitionsStateType
  >
>;

export const duplicateStandardFromFormDefinitions = (
  params: DuplicateStandardFromFormDefinitionsParamsType,
): DuplicateStandardFromFormDefinitionsActionType => {
  const duplicatedStandardName = i18n.t(
    'FormDefinitions:duplicatedName',
    getAvailableStandardName(params.standard_name),
  );

  return {
    type: '@formDefinitions/API/DUPLICATE_STANDARD',
    payload: {
      endpointPath: ['definitions', 'drafts', 'post'],
      callParams: {
        ...params,
        standard_name: duplicatedStandardName,
      },
      expectedStateChange: {
        'formDefinitions.standards.data': (standards: StandardType[]) => {
          const indexOfReferenceStandard = findIndex(
            ({ definitionId }) => definitionId === params.definition_id,
            standards,
          );

          const dummyStandard: StandardType = {
            id: EMPTY_ID,
            code: '',
            description: '',
            isProcessing: true,
            logo: '',
            name: duplicatedStandardName,
            status: StandardStatusEnum.DRAFT,
            version: '',
            year: 0,
            updatedAt: new Date(),
          };

          return insert(indexOfReferenceStandard + 1, dummyStandard, standards);
        },
      },
      selector: (
        data: FormDefinitionSummaryDraftExtended,
        state: StateType,
      ): Partial<FormDefinitionsStateType> => {
        const formdefinitionSummaryDraftExtended = parseFormDefinitionSummaryDraftExtended(
          data,
        );

        const newStandard: StandardType = {
          ...formdefinitionSummaryDraftExtended.standard,
          /**
           * set isDuplicable to true manually, duplicated standard is automatically
           * also duplicable but the API response for duplication currently returns
           * undefined value
           */
          isDuplicable: true,
          /**
           * set isProcessing to false to stop spinner
           */
          isProcessing: false,
          /**
           * set definitionId manually as the nested standard contains undefined,
           * but the response is actually its definition so we can set it like this
           */
          definitionId: formdefinitionSummaryDraftExtended.id,
        };

        const indexOfReferenceStandard = findIndex(
          ({ definitionId }) => definitionId === params.definition_id,
          state.formDefinitions.standards.data,
        );

        return evolve({
          // @ts-ignore
          standards: {
            data: update(indexOfReferenceStandard + 1, newStandard),
          },
        })(state.formDefinitions);
      },
    },
  };
};
