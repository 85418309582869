import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ItemWrapper = styled.div`
  margin-bottom: 6px;
  padding-right: 5px;
`;

export const Item = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.basic.colors.light};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.request.borderColor};
  box-shadow: ${({ theme }) => theme.request.boxShadow};
`;

const cell = css`
  padding: 15px 0px 10px 0px;
  line-height: 1.2em;
`;

export const Standard = styled.div<{ hasPreview: boolean }>`
  ${cell};
  flex: 1 1 auto;
  padding-left: 13px;
  margin: auto 0;
  ${({ theme }) => theme.fonts.semibold.mini}
  ${({ hasPreview }) =>
    hasPreview
      ? css`
          text-decoration: underline;
          cursor: pointer;
        `
      : ''}
`;

export const Date = styled.div`
  ${cell};
  flex: 0 0 270px;
  ${({ theme }) => theme.fonts.light.mini}
`;

export const Action = styled.div`
  ${cell};
  flex: 0 0 140px;
  padding-right: 13px;
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;
