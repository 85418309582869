import styled from 'styled-components';

export const Shelf = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
`;

export const ModalFrame = {
  maxWidth: 853,
  width: 853,
  height: 611,
  maxHeight: 611,
};

export const Wrapper = styled.div`
  padding: 30px;

  h3 {
    ${({ theme }) => theme.fonts.bold.large}
    margin-bottom: 10px;
  }
`;

export const Representative = styled.a`
  ${({ theme }) => theme.fonts.bold.normal}
  color: ${({ theme }) => theme.atlas.teal.primary};
  cursor: pointer;
  text-decoration: underline;
`;

export const CancelButton = styled.span`
  padding: 0 40px;
  text-decoration: underline;
  cursor: pointer;
`;

export const ModalAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CreditsDisclaimer = styled.div`
  ${({ theme }) => theme.fonts.regular.mini}
  color: ${({ theme }) => theme.basic.colors.notificationButton};
  width: 300px;
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;
`;
