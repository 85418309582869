/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @interface RequestFindingUpdateInsert
 */
export interface RequestFindingUpdateInsert {
    /**
     * 
     * @type {string}
     * @memberof RequestFindingUpdateInsert
     */
    remediation_plan?: RequestFindingUpdateInsertRemediationPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestFindingUpdateInsert
     */
    remediation_plan_note?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestFindingUpdateInsert
     */
    risk_level?: RequestFindingUpdateInsertRiskLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestFindingUpdateInsert
     */
    risk_level_note?: string;
}




/**
* @export
* @enum {string}
*/
export enum RequestFindingUpdateInsertRemediationPlanEnum {
    ACCEPT = 'ACCEPT',
    COMPLETE = 'COMPLETE',
    REMEDIATE = 'REMEDIATE'
}
/**
* @export
* @enum {string}
*/
export enum RequestFindingUpdateInsertRiskLevelEnum {
    CRITICAL = 'CRITICAL',
    HIGH = 'HIGH',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    NONE = 'NONE'
}


