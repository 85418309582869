import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Horizontal, Marginator } from 'common/common.styles';
import { Tab } from '../Question';
import { Text, Button } from './ToggleAllButton.styles';
import { any } from 'ramda';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  TranslatePropsType,
  QuestionType,
  QuestionTabType,
} from 'common/common.types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ToggleAllButtonPropsType extends TranslatePropsType {
  handleClick: () => void;
  isOpen: boolean;
  preferredTab: QuestionTabType;
  questions: QuestionType[];
}

const ToggleAllButton = ({
  handleClick,
  isOpen,
  preferredTab,
  questions,
  t,
}: ToggleAllButtonPropsType) => {
  const areAllToggled = !any(
    q => q.selectedTab !== preferredTab.label,
    questions,
  );
  const tabIcon = preferredTab.icon;
  let faIcon = faChevronDown as IconProp;
  if (isOpen) {
    faIcon = faChevronUp as IconProp;
  }

  return (
    <>
      <Text>{t('toggleAll') as string}</Text>

      <Button data-cy="toggleAll" onClick={handleClick}>
        <Horizontal>
          <Marginator margin={{ top: 5 }}>
            <Tab active={areAllToggled} hasCount={false} icon={tabIcon} />
          </Marginator>

          <Marginator margin={{ top: 14, left: 10, right: 10 }}>
            <FontAwesomeIcon icon={faIcon} />
          </Marginator>
        </Horizontal>
      </Button>
    </>
  );
};

export default withTranslation('ToggleAllButton')(ToggleAllButton);
