import Page, { PageBaseType, PagePropsType } from './Page';
import React from 'react';
import Utils from '../../src/components/Utils/Utilities';
import { ActionType, StateType } from 'store/store.types';
import { Dispatch } from 'redux';
import { CurrentUserType } from 'common/common.types';

export interface UtilsPageContextType extends PageBaseType {
  data: {
    user: CurrentUserType;
  };
  actions: {};
}

const UtilsPage = (props: PagePropsType) => (
  <Page
    dataSelector={(state: StateType) => ({
      user: state.user.currentUser,
    })}
    dispatches={(dispatch: Dispatch<ActionType>) => ({
      actions: {},
    })}
  >
    <Utils />
  </Page>
);

export default UtilsPage;
