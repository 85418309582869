import { DisabledEmpty, DisabledValue, Suffix } from './Numeric.styles';
import { Flex } from 'common/common.styles';
import { Label } from '../../Form.styles';
import NumberFormat from 'react-number-format';
import NumberInput from './NumberInput';
import React from 'react';
import { ResponseInputPropsType } from '../Response';
import { TranslatePropsType } from 'common/common.types';
import { withTranslation } from 'react-i18next';

interface NumericPropsType extends ResponseInputPropsType, TranslatePropsType {
  isRound?: boolean;
  max?: number;
  min?: number;
  placeholder?: string;
  suffix?: string;
  width?: number;
}

const Numeric = ({
  actions: { handleChange },
  data: { isEditable, label, value },
  isRound,
  max,
  min,
  placeholder,
  suffix,
  t,
  width,
}: NumericPropsType) => (
  <>
    <Label>{label}</Label>
    <Flex alignItems="center">
      {isEditable ? (
        <>
          <NumberInput
            disabled={!isEditable}
            handleChange={handleChange}
            isRound={isRound}
            max={max}
            min={min}
            placeholder={placeholder || (t('number') as string)}
            value={value}
            width={width}
          />

          {suffix && <Suffix>{suffix}</Suffix>}
        </>
      ) : value ? (
        <DisabledValue value={value}>
          <NumberFormat
            displayType={'text'}
            suffix={suffix}
            thousandSeparator={' '}
            value={value}
          />
        </DisabledValue>
      ) : (
        <DisabledEmpty>-</DisabledEmpty>
      )}
    </Flex>
  </>
);

export default withTranslation('Numeric')(Numeric);
