import { ApiCallActionType } from '../../store.types';
import { SupportedSettingsOptionType } from '../settings.reducer';

export type SettingsGetValueActionType = ApiCallActionType<
  '@settings/API/GET',
  { keys: string[] },
  Record<string, any>,
  never,
  Record<string, any>
>;

export const getSettings = (
  keys: SupportedSettingsOptionType[],
): SettingsGetValueActionType => ({
  type: '@settings/API/GET',
  payload: {
    endpointPath: ['settings', 'get'],
    callParams: { keys },
    selector: (data: any) => data,
  },
});
