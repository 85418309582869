import { Trans, withTranslation } from 'react-i18next';
import {
  ANSWER_BASE_FILE_NAME,
  ANSWER_BASE_FILE_PATH,
} from 'common/common.constants';
import React from 'react';
import { TranslatePropsType } from 'common/common.types';
import { Instructions } from './Instructions.styles';

const FrameworksInstructions = ({ t }: TranslatePropsType) => {
  const instructionsLabel = t('Framework.instructions1');
  return (
    <Instructions>
      <li>{instructionsLabel}</li>
      <li>
        <Trans i18nKey="Framework.instructions2" t={t}>
          Tips for better results: make sure your Excel file contains only one
          sheet and no macros or complicated formatting. Name the columns
          <em>Question</em> and <em>Response</em>.
        </Trans>
      </li>
      <li data-cy="download-answer-template">
        <Trans i18nKey="Framework.instructions3" t={t}>
          or
          <a href={ANSWER_BASE_FILE_PATH} download={ANSWER_BASE_FILE_NAME}>
            download
          </a>
        </Trans>
      </li>
    </Instructions>
  );
};

export default withTranslation('components')(FrameworksInstructions);
