import styled, { css } from 'styled-components';

export const Header = styled.div`
  width: 150px;
  text-align: right;
  border-right: 1px solid ${({ theme }) => theme.atlas.grey[200]};
  color: ${({ theme: { atlas } }) => atlas.text.secondary};
  padding: 4px 10px 4px 0;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  text-decoration: none;
  display: flex;
  flex-direction: column;
`;

const CommonLink = css`
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:not([href]) {
    cursor: default;
  }

  &[href]:hover {
    text-decoration: underline;
  }
`;

export const Title = styled.a`
  ${CommonLink}
  ${({ theme }) => theme.basic.fontWeights.bold};
`;

export const Name = styled.a`
  ${CommonLink}
  ${({ theme }) => theme.basic.fontWeights.regular};
`;
