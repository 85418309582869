import styled, { css } from 'styled-components';
import { CompactSelect } from '../../common/Select';
import Input from '../../common/Input';
import hex2rgba from 'utils/hex2rgba';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';

export const ITEM_SPACE = 8;
const WIDTH = 145;

export const LineTitle = styled.div`
  padding-left: 5px;
  line-height: 20px;
  width: ${WIDTH}px;
  color: ${({ theme }) => theme.atlas.text.secondary};
  ${({ theme }) => theme.fonts.regular.small}
`;

export const LineWrapper = styled.div`
  & + & {
    margin-top: 8px;
  }
`;

export const StyledSelect = styled(CompactSelect)`
  width: ${WIDTH}px;
`;

export const StyledInput = styled(Input).attrs({
  height: 32,
})`
  max-width: ${WIDTH}px;
`;

export const SingleValue = styled.div`
  width: ${WIDTH}px;
  line-height: 20px;
  padding: 6px 11px;
  color: ${({ theme }) => theme.atlas.text.secondary};
  ${({ theme }) => theme.fonts.regular.small}
`;

export const Operator = styled.span<{ isSelected: boolean }>`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.atlas.grey['200']};
  padding: 6px 12px;
  line-height: 18px;
  ${({ theme }) => theme.fonts.bold.small}

  &:hover {
    cursor: pointer;
  }

  ${({ isSelected, theme }) =>
    isSelected
      ? css`
          background: ${theme.atlas.teal['200']};
          color: ${theme.atlas.teal.primary};
        `
      : css`
          background: ${theme.atlas.white};
          color: ${hex2rgba(theme.atlas.teal.primary, 0.2)};
        `}
`;

export const OperatorText = styled.div`
  padding: 8px 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.atlas.text.secondary};
  ${({ theme }) => theme.fonts.regular.small}
`;

export const OperatorSelector = styled.div`
  ${Operator}:first-of-type {
    border-radius: 6px 0 0 6px;
  }

  ${Operator} + ${Operator} {
    border-left: hidden;
  }

  ${Operator}:last-of-type {
    border-radius: 0 6px 6px 0;
  }
`;

export const ResponseOptionDescription = styled.div<{ isSelected: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  height: 16px;
  line-height: 16px;
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.atlas.white : theme.atlas.grey['500']};
  ${({ theme }) => theme.fonts.regular.mini}
`;

export interface QuestionSelectWrapperType {
  isQuestionSelected: boolean;
}

export const QuestionSelectWrapper = styled.div<QuestionSelectWrapperType>`
  display: flex;
  width: ${WIDTH}px;

  > ${StyledSelect} {
    width: ${({ isQuestionSelected }) =>
      isQuestionSelected ? WIDTH - 19 : WIDTH}px;
  }
`;

export const QuestionText = styled.div`
  margin-left: 5px;
  width: 14px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  color: ${({ theme }) => theme.atlas.text.secondary};
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  opacity: 0.4;
`;

export const QuestionTextTooltip = styled.div`
  max-width: 240px;
`;

export const QuestionTextIcon: any = styled(FontAwesomeIcon).attrs(() => ({
  icon: faCommentAlt,
}))``;

export const Buttons = styled.div`
  margin-top: 10px;
`;
