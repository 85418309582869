import React from 'react';
import {
  Progress,
  ProgressionWrapper,
  ProgressText,
  Text,
  Wrapper,
} from './Autocomplete.styles';
import { Trans, withTranslation } from 'react-i18next';
import MatchIntro from './MatchIntro';
import { TranslatePropsType } from 'common/common.types';

interface AutocompleteInProgressPropsType extends TranslatePropsType {
  matchingQuestionsCount?: number;
  questionsProcessed: number;
  questionsTotal: number;
}

const AutocompleteInProgress = ({
  matchingQuestionsCount,
  questionsProcessed,
  questionsTotal,
  t,
}: AutocompleteInProgressPropsType) => (
  <Wrapper>
    <MatchIntro />

    <ProgressionWrapper>
      <Trans
        i18nKey="matchesFound"
        count={matchingQuestionsCount}
        parent={Text}
        t={t}
        style={{ opacity: Boolean(matchingQuestionsCount) ? 1 : 0 }}
      >
        We found matching answers for <strong>N question</strong>.
      </Trans>

      <ProgressText>{t('autocompleteInProgress') as string}</ProgressText>

      <Progress
        percentage={
          questionsTotal > 0
            ? Math.round((questionsProcessed / questionsTotal) * 100)
            : 0
        }
      />
    </ProgressionWrapper>
  </Wrapper>
);

export default withTranslation('Autocomplete')(AutocompleteInProgress);
