import React from 'react';
import { BottomBar, CloseButton, Filters, Wrapper } from './Dropdown.styles';
import { FilterCategory } from './index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormsFiltersType } from 'store/forms/forms.reducer';
import { TranslatePropsType } from 'common/common.types';
import { any, keys } from 'ramda';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getUiFilters, isFilterSupported } from '../utils';
import { withTranslation } from 'react-i18next';
import {
  HorizontallyJustified,
  OpacitySetter,
  TextButton,
} from 'common/common.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface DropdownPropsType extends TranslatePropsType {
  filters: FormsFiltersType;
  filtersCount: number;
  handleCheckboxChange: (branch: 'assignees' | 'simple', name: string) => void;
  handleClearAll: () => void;
  handleFilterOpen: () => void;
  hasNewData: boolean;
  hasNewlyAutofilled: boolean;
  hideSscData: boolean;
  isAutocompletable: boolean;
  isDelegable: boolean;
  isFilterDropdownOpen: boolean;
  isReviewReadable: boolean;
}

const FiltersDropdown = ({
  filters,
  filters: { simple: simpleFilters },
  filtersCount,
  handleCheckboxChange,
  handleClearAll,
  handleFilterOpen,
  hasNewData,
  hasNewlyAutofilled,
  isAutocompletable,
  isDelegable,
  isFilterDropdownOpen,
  isReviewReadable,
  hideSscData,
  t,
}: DropdownPropsType) => {
  const hasNoSscData = hideSscData || simpleFilters.ssc_data.count === 0;
  const uiFilters = getUiFilters(
    hasNoSscData,
    hasNewlyAutofilled,
    isDelegable,
    isReviewReadable,
    isAutocompletable,
  );

  return (
    <Wrapper isOpen={isFilterDropdownOpen}>
      <CloseButton className="close-button" onClick={handleFilterOpen}>
        <FontAwesomeIcon icon={faTimes as IconProp} />
      </CloseButton>
      <Filters data-test="answer-filter">
        {keys(uiFilters).map(
          category =>
            uiFilters[category].length > 0 &&
            any(isFilterSupported, uiFilters[category]) && (
              <FilterCategory
                category={category}
                filters={filters}
                handleCheckboxChange={handleCheckboxChange}
                hasNewData={hasNewData}
                key={`filter-category-${category}`}
                uiFilters={uiFilters}
              />
            ),
        )}
      </Filters>

      <BottomBar>
        <HorizontallyJustified>
          <div />

          <OpacitySetter opacity={!!filtersCount ? 1 : 0}>
            <TextButton color="teal" onClick={handleClearAll}>
              {t('clearAll') as string}
            </TextButton>
          </OpacitySetter>
        </HorizontallyJustified>
      </BottomBar>
    </Wrapper>
  );
};

export default withTranslation('FilterBar')(FiltersDropdown);
