import { format } from 'date-fns';
import React, { useCallback, useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT_BASED_ON_LOCALE } from 'common/common.constants';
import { Content, Wrapper } from 'common/common.styles';
import { TranslatePropsType } from 'common/common.types';
import { InsightsPageContextType } from 'pages/InsightsPage';
import { PageContext } from 'pages/Page';
import formatRoute, { SEND_REQUEST } from '../../routes';
import NoData from '../common/NoData';
import { PageHeader } from '../common/PageHeader';
import {
  CompletionAverage,
  CompletionChart,
  GradeAverage,
  GradeChart,
  ScoreAverage,
  ScoreChart,
  StatusChart,
  StatusTimeChart,
} from './Charts';
import {
  ExportDate,
  Gauges,
  ExportFilters,
  ExportHeader,
  ExportTitle,
  ExportFilterKey,
  ExportFilter,
  ExportFilterValue,
  ExportDetails,
} from './Insights.styles';
import InsightsHeader from './InsightsHeader';
import {
  averageCompletionTime,
  averageSscGrade,
  averageValidationScore,
  sortByCompletionTime,
  sortBySscGrade,
  sortByValidationScore,
  getFiltersDescription,
} from './utils';
import getLocale from '../../utils/getLocale';
import { StateType } from '../../store/store.types';
import { getLanguagePreference } from '../../store/settings/settings.selectors';
import { connect } from 'react-redux';
import { exportElement } from 'utils/export_element';

const Insights = ({ t }: TranslatePropsType) => {
  const {
    actions: { setFilter },
    data: {
      company,
      exportToCsvPath,
      hasData,
      isExporting,
      requests: { categories, filter, filterOptions, unsorted },
      stats,
      userStats: {
        archivedRequests: { sent: archivedSent },
      },
      language,
    },
  } = useContext(PageContext) as InsightsPageContextType;
  const onExportToPdf = useCallback(() => {
    return exportElement('report-id');
  }, []);
  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <PageHeader
        heading={
          <InsightsHeader
            exportToCsvPath={exportToCsvPath}
            exportToPdf={onExportToPdf}
            filter={filter}
            filterOptions={filterOptions}
            isExporting={isExporting}
            setFilter={setFilter}
            showControls={hasData}
          />
        }
      />

      <Wrapper grey landscape>
        <Content id="report-id">
          {hasData && (
            <>
              <ExportDetails>
                <ExportHeader>
                  <ExportTitle>
                    {t('stats', { name: company.name }) as string}
                  </ExportTitle>
                  <ExportDate>
                    {ReactHtmlParser(
                      t('exported_on_time', {
                        time: format(
                          new Date(),
                          DATE_TIME_FORMAT_BASED_ON_LOCALE[language.value],
                          { locale: getLocale(language) },
                        ),
                      }),
                    )}
                  </ExportDate>
                </ExportHeader>
                <ExportFilters>
                  {getFiltersDescription(
                    filter,
                    filterOptions,
                    language,
                    t,
                  ).map(item => (
                    <ExportFilter key={item.key}>
                      <ExportFilterKey>
                        {item.key && (t(item.key) as string)}
                      </ExportFilterKey>
                      <ExportFilterValue>
                        {ReactHtmlParser(item.value)}
                      </ExportFilterValue>
                    </ExportFilter>
                  ))}
                </ExportFilters>
              </ExportDetails>

              <Gauges>
                <StatusChart categories={categories} filter={filter} />
                <StatusTimeChart stats={stats} />

                <GradeAverage average={averageSscGrade(unsorted)} />
                <ScoreAverage average={averageValidationScore(unsorted)} />
                <CompletionAverage average={averageCompletionTime(unsorted)} />

                <GradeChart requests={sortBySscGrade(unsorted)} />
                <ScoreChart requests={sortByValidationScore(unsorted)} />
                <CompletionChart requests={sortByCompletionTime(unsorted)} />
              </Gauges>
            </>
          )}

          {!hasData && (
            <NoData
              buttonText={t('noDataButton') as string}
              text={t(archivedSent === 0 ? 'noSent' : 'onlyArchived')}
              path={formatRoute(SEND_REQUEST)}
            />
          )}
        </Content>
      </Wrapper>
    </>
  );
};

const mapStateToProps = ({ settings }: StateType) => ({
  language: getLanguagePreference(settings),
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation('Insights')(Insights));
