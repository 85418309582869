import { assocPath } from 'ramda';
import { StripeActionType } from './actions';

export interface StripeStateType {
  pricings?: any;
  modal: {
    isOpen: boolean;
  };
}

export const initialState: StripeStateType = {
  pricings: undefined,
  modal: {
    isOpen: false,
  },
};

const stripe = (
  state = initialState,
  action: StripeActionType,
): StripeStateType => {
  switch (action.type) {
    case '@stripe/OPEN_MODAL':
      return assocPath(['modal', 'isOpen'], true, state);

    case '@stripe/CLOSE_MODAL':
      return assocPath(['modal', 'isOpen'], false, state);

    default:
      return state;
  }
};

export default stripe;
