import { DelegationsCreated } from '../../../generated/models/DelegationsCreated';
import { assocPath, pipe } from 'ramda';
import { call, put } from 'redux-saga/effects';
import { getAssigneeKey, remapCategories } from '../utils';
import { hydrateFilters } from './hydrateFilters';
import { parseDelegation } from 'utils/parsers';
import { trackEvent } from 'utils/analytics';
import { ApiCallActionType } from '../../store.types';
import { FormsStateType } from '../forms.reducer';
import { getAssignees } from './getAssignees';
import {
  CategoryType,
  DelegationType,
  IdOrEmailType,
  Uuid,
} from '../../../common/common.types';

export interface FormsAssignQuestionDelegationCallParamsType {
  formId: Uuid;
  params: {
    question_id: Uuid;
    assignee_id?: Uuid;
    assignee_email?: string;
  };
}

export type FormsAssignQuestionDelegationFunctionType = (
  assignee: IdOrEmailType,
  questionId: Uuid,
  formId: Uuid,
) => void;

export type FormsAssignQuestionDelegationActionType = ApiCallActionType<
  '@forms/API/ASSIGN_QUESTION_DELEGATION',
  FormsAssignQuestionDelegationCallParamsType,
  DelegationsCreated,
  never,
  FormsStateType
>;

export const assignQuestionDelegation = (
  assignee: IdOrEmailType,
  questionId: Uuid,
  formId: Uuid,
): FormsAssignQuestionDelegationActionType => ({
  type: '@forms/API/ASSIGN_QUESTION_DELEGATION',
  payload: {
    callParams: {
      formId,
      params: {
        [getAssigneeKey(assignee)]: assignee.payload,
        question_id: questionId,
      },
    },
    endpointPath: ['forms', 'delegations', 'questions', 'put'],
    selector: ({ category_stats, created }, { forms }) =>
      pipe(
        assocPath<DelegationType[], FormsStateType>(
          ['currentForm', 'questions', questionId, 'delegations'],
          created.map(delegationData => parseDelegation(delegationData)),
        ),
        assocPath<CategoryType[], FormsStateType>(
          ['currentForm', 'categories'],
          remapCategories(category_stats, forms.currentForm!.categories!),
        ),
      )(forms),
    postActions: [
      () =>
        getAssigneeKey(assignee) === 'assignee_email'
          ? put(getAssignees(formId))
          : null,
      () => put(hydrateFilters(formId)),
      () => call(() => trackEvent('Delegation', 'Add - Form Question')),
    ],
  },
});
