import React from 'react';
import route, { FORM } from '../../routes';
import { DueDateList } from '../Requests/Request/DueDate/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { STATUSES, GROUPED_STATUSES } from 'common/common.constants';
import { faCheck, faFileAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  LanguageType,
  RequestType,
  TranslatePropsType,
  UserType,
} from 'common/common.types';
import {
  Company,
  Date,
  DueDateWrapper,
  Grade,
  GradeImage,
  GradeSpan,
  Item,
  ItemWrapper,
  Standard,
  Status,
  StatusIcon,
  Version,
} from './RequestsList.styles';
import {
  Transceiver,
  TransceiverPart,
} from '../Requests/Request/Request.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface RequestsListType extends TranslatePropsType {
  data: RequestType[];
  user: UserType;
  language: LanguageType;
}

const RequestsList = ({
  data,
  user: { company },
  language,
  t,
}: RequestsListType) => (
  <div>
    {data.map((request: RequestType) => {
      const {
        acceptedDate,
        dueDate,
        form,
        meta: { sender, receiver },
        requestedAt,
        source,
        standard,
        status,
        target,
      } = request;

      const isSource = company.id === source.id;
      const color = GROUPED_STATUSES[status].borderColor;

      const faStarProp = faStar as IconProp;
      const faFileAltProp = faFileAlt as IconProp;
      const faCheckProp = faCheck as IconProp;

      const receiverLabel = t('Requests.receiver', { receiver });
      const senderLabel = t('Requests.sender', { sender });

      return (
        <ItemWrapper key={request.id}>
          <Item color={color}>
            <Status>
              <StatusIcon color={color}>
                {status === STATUSES.CREATED.id && (
                  <FontAwesomeIcon icon={faStarProp} />
                )}

                {status === STATUSES.VENDOR_IN_PROGRESS.id && (
                  <FontAwesomeIcon icon={faStarProp} />
                )}

                {status === STATUSES.VRM_IN_PROGRESS.id && (
                  <FontAwesomeIcon icon={faFileAltProp} />
                )}

                {status === STATUSES.ACCEPTED_CANCELED.id && (
                  <FontAwesomeIcon icon={faCheckProp} />
                )}
              </StatusIcon>
            </Status>

            <Company>
              {isSource ? target.name : source.name}

              <TransceiverPart reverse={!isSource}>
                <Transceiver data-cy="request-receiver" title={receiver}>
                  {receiverLabel}
                </Transceiver>

                <Transceiver data-cy="request-sender" title={sender}>
                  {senderLabel}
                </Transceiver>
              </TransceiverPart>
            </Company>

            <Standard>
              <Link to={route(FORM, { id: form.id })}>{form.name}</Link>

              {isSource && target.gradeUrl && (
                <Grade>
                  <GradeImage src={target.gradeUrl} alt={target.grade} />

                  <GradeSpan>{target.score}</GradeSpan>
                </Grade>
              )}
              <Version>{standard.version}</Version>
            </Standard>

            <Date>
              <>
                {t('RequestsList.requestedAt')}

                <br />

                {requestedAt.toDateString()}
              </>
            </Date>

            <Date>
              <>
                {t('RequestsList.dueDate')}

                <br />

                {dueDate
                  ? dueDate.toDateString()
                  : t('common:dueDate.noDueDate')}
              </>
            </Date>

            <DueDateWrapper>
              {dueDate && (
                <DueDateList
                  dueDate={dueDate}
                  acceptedDate={acceptedDate}
                  language={language}
                />
              )}
            </DueDateWrapper>
          </Item>
        </ItemWrapper>
      );
    })}
  </div>
);

export default withTranslation(['components', 'common'])(RequestsList);
