/*eslint @typescript-eslint/no-unused-vars: "off"*/
/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Company } from "./Company";
// @ts-ignore
import { Team } from "./Team";
// @ts-ignore
import { User } from "./User";

/**
 *
 * @export
 * @interface RequestExternalEntityData
 */
export interface RequestExternalEntityData {
    /**
     *
     * @type {boolean}
     * @memberof RequestExternalEntityData
     */
    is_vendor: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RequestExternalEntityData
     */
    is_vrm: boolean;
    /**
     *
     * @type {Company}
     * @memberof RequestExternalEntityData
     */
    source: Company;
    /**
     *
     * @type {Team}
     * @memberof RequestExternalEntityData
     */
    source_team?: Team;
    /**
     *
     * @type {User}
     * @memberof RequestExternalEntityData
     */
    source_user: User;
    /**
     *
     * @type {Company}
     * @memberof RequestExternalEntityData
     */
    target: Company;
    /**
     *
     * @type {Team}
     * @memberof RequestExternalEntityData
     */
    target_team?: Team;
    /**
     *
     * @type {User}
     * @memberof RequestExternalEntityData
     */
    target_user: User;
    /**
     * Current vrm view status
     * @type {string}
     * @memberof RequestExternalEntityData
     */
    vrm_view_status: RequestExternalEntityDataVrmViewStatusEnum;
}




/**
* @export
* @enum {string}
*/
export enum RequestExternalEntityDataVrmViewStatusEnum {
    VENDORAPPROVED = 'VENDOR_APPROVED',
    VENDORDECLINED = 'VENDOR_DECLINED',
    VENDORINITIAL = 'VENDOR_INITIAL',
    VRMREQUESTED = 'VRM_REQUESTED'
}


