import React, { useState } from 'react';
import { Assignees } from '.';
import { contains } from 'ramda';
import { withTranslation } from 'react-i18next';
import { PageContextConsumer } from 'pages/Page';
import { SharedFormPageContextType } from 'pages/SharedFormPage';
import { FormPageContextType } from 'pages/FormPage';
import {
  CategoryDelegationStatsQuantityEnum,
  CategoryDelegationStatsScopeEnum,
} from 'generated/models/CategoryDelegationStats';
import {
  faUser,
  faPlus,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import {
  CategoryType,
  PromptInviteDelegationType,
  TranslatePropsType,
  FormAssignee,
  Uuid,
} from 'common/common.types';
import {
  AssignTo,
  Assignee,
  AssigneeRow,
  ChevronIcon,
  PlusIcon,
  RelativeContainer,
  Single,
  UserIcon,
  Wrapper,
} from './Delegator.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CategoryDelegatorPropsType extends TranslatePropsType {
  assignees: FormAssignee[];
  canInvite: boolean;
  category: CategoryType;
  formId: Uuid;
  onInviteUserClick: (
    e: React.MouseEvent<HTMLElement>,
    prompt: PromptInviteDelegationType,
  ) => void;
}

const CategoryDelegator = ({
  assignees,
  canInvite,
  category,
  category: {
    name,
    delegation: { assignee, scope, quantity },
  },
  formId,
  onInviteUserClick,
  t,
}: CategoryDelegatorPropsType) => {
  const [isDelegatorOpen, setDelegatorOpen] = useState(false);
  const { SOME, NONE } = CategoryDelegationStatsScopeEnum;
  const { MULTIPLEUSERS, NOUSER } = CategoryDelegationStatsQuantityEnum;
  const hasAssignees = quantity !== NOUSER;
  const assigneeIds = assignee ? [assignee.id] : [];

  return (
    <PageContextConsumer>
      {({
        actions: { assignCategoryDelegation, unassignCategoryDelegation },
      }: FormPageContextType | SharedFormPageContextType) => {
        const handleAssignClick = (
          e: React.MouseEvent<HTMLElement>,
          userId: Uuid,
        ) => {
          e.stopPropagation();

          if (
            assignCategoryDelegation &&
            name &&
            !contains(userId, assigneeIds)
          ) {
            assignCategoryDelegation(formId, name, {
              type: 'id',
              payload: userId,
            });
          }
        };

        const handleUnassignClick = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();

          if (unassignCategoryDelegation && hasAssignees) {
            unassignCategoryDelegation(formId, name);
          }
        };

        const handleRowClick = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();

          setDelegatorOpen(!isDelegatorOpen);
        };

        const handleInviteUserClick = (e: React.MouseEvent<HTMLElement>) => {
          onInviteUserClick(e, { category });

          setDelegatorOpen(false);
        };

        return (
          <Wrapper isOpened={isDelegatorOpen} data-cy="category-delegator">
            <RelativeContainer>
              <AssigneeRow
                hasAssignees={hasAssignees}
                onClick={handleRowClick}
                data-test="assign-to-category"
              >
                {scope !== NONE ? (
                  <Assignee>
                    {scope === SOME && (t('some') as string)}

                    {t('assignedTo') as string}

                    <br />

                    <Single title={assignee && assignee.displayName}>
                      {quantity === MULTIPLEUSERS
                        ? (t('multiple') as string)
                        : assignee && assignee.displayName}
                    </Single>
                  </Assignee>
                ) : (
                  <AssignTo>{t('assignTo') as string}</AssignTo>
                )}

                {scope !== NONE && (
                  <ChevronIcon icon={faChevronDown as IconProp} />
                )}

                <UserIcon icon={faUser as IconProp} />

                {scope === NONE && <PlusIcon icon={faPlus as IconProp} />}
              </AssigneeRow>

              {isDelegatorOpen && (
                <Assignees
                  assign={handleAssignClick}
                  assignees={assignees}
                  assigneesIds={assignee ? [assignee.id] : []}
                  canInvite={canInvite}
                  hasRemove={hasAssignees}
                  invite={handleInviteUserClick}
                  setDelegatorOpen={setDelegatorOpen}
                  unassign={handleUnassignClick}
                />
              )}
            </RelativeContainer>
          </Wrapper>
        );
      }}
    </PageContextConsumer>
  );
};

export default withTranslation('Delegator')(CategoryDelegator);
