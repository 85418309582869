/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormUploadStats
 */
export interface FormUploadStats {
    /**
     * ID of temporary form-filling created from the answers file
     * @type {string}
     * @memberof FormUploadStats
     */
    fillingId: string;
    /**
     * Name of the parsed file with answers including extension
     * @type {string}
     * @memberof FormUploadStats
     */
    originalName: string;
    /**
     * Number of questions parsed from the file
     * @type {number}
     * @memberof FormUploadStats
     */
    questionCount: number;
    /**
     * Number of questions where all responses were filled with parsed answers
     * 
     * *TODO: Check description correctness*
     * @type {number}
     * @memberof FormUploadStats
     */
    questionsCompletelyFilled: number;
    /**
     * Number of questions with at least one response filled with a parsed answer
     * 
     * *TODO: Check description correctness*
     * @type {number}
     * @memberof FormUploadStats
     */
    questionsFilled: number;
    /**
     * Number of questions with at least one response filled with a parsed answer but still
     * have some required responses empty
     * 
     * *TODO: Check description correctness*
     * @type {number}
     * @memberof FormUploadStats
     */
    questionsPartiallyFilled: number;
    /**
     * Total number of responses parsed from the file
     * @type {number}
     * @memberof FormUploadStats
     */
    responseCount: number;
    /**
     * Number of responses that were filled with answers from the parsed file
     * 
     * *TODO: Check description correctness*
     * @type {number}
     * @memberof FormUploadStats
     */
    responsesFilled: number;
}

export function FormUploadStatsFromJSON(json: any): FormUploadStats {
    return FormUploadStatsFromJSONTyped(json, false);
}

export function FormUploadStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormUploadStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fillingId': json['filling_id'],
        'originalName': json['original_name'],
        'questionCount': json['question_count'],
        'questionsCompletelyFilled': json['questions_completely_filled'],
        'questionsFilled': json['questions_filled'],
        'questionsPartiallyFilled': json['questions_partially_filled'],
        'responseCount': json['response_count'],
        'responsesFilled': json['responses_filled'],
    };
}

export function FormUploadStatsToJSON(value?: FormUploadStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filling_id': value.fillingId,
        'original_name': value.originalName,
        'question_count': value.questionCount,
        'questions_completely_filled': value.questionsCompletelyFilled,
        'questions_filled': value.questionsFilled,
        'questions_partially_filled': value.questionsPartiallyFilled,
        'response_count': value.responseCount,
        'responses_filled': value.responsesFilled,
    };
}


