import styled from 'styled-components';
import CommonBarHeader from './BarHeader';
import { Bar as CommonBar } from './ScoreChart.styles';

export const GradeWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.basic.colors.light};

  svg {
    position: absolute;
    left: -9px;
    top: -13px;
    z-index: 200;
  }
`;

export const Bar = styled(CommonBar)`
  margin-left: 15px;
`;

export const BarHeader = styled(CommonBarHeader)`
  padding-right: 15px;
`;
