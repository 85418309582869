import styled from 'styled-components';
import { Wrapper as Reminder } from './Reminder.styles';
import { Wrapper } from 'common/common.styles';
import { TabsContainer } from '../../common/PageHeader/PageHeader.styles';

export const Label = styled.label`
  ${({ theme }) => theme.fonts.bold.small}
`;

export const DueDateWrapper = styled.div`
  display: flex;
  margin-right: 50px;

  > ${Reminder} {
    width: 220px;
    max-width: 270px;
    flex-basis: auto;
  }
`;

export const RecipientWrapper = styled.div`
  background-color: transparent !important;
  margin: -15px 0 0;
  padding: 0 !important;

  ${TabsContainer} {
    padding: 0 !important;
    margin-top: 10px !important;
  }

  ${Wrapper} {
    background-color: white !important;
    margin: 0;
    padding: 0 20px;
  }
`;

export const LinkDisabledNotification = styled.div`
  background-color: ${({ theme }) => theme.basic.colors.lightBlueBackground};
  padding: 16px;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  border-radius: 8px;
  margin-left: auto;
  text-align: right;
  line-height: 18px;
  width: 80%;
`;
