import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.atlas.teal[100]};
  border: 1px solid ${({ theme }) => theme.atlas.grey[200]};
  height: 55px;
  margin-top: -1px;
  padding: 0 28px;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.basic.fontSizes.small};
  justify-content: space-between;
`;

export const CloseIcon = styled(FontAwesomeIcon).attrs({
  icon: faTimes as IconProp,
})`
  cursor: pointer;
  width: 13px;
  height: 13px;
`;
