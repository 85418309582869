import styled from 'styled-components';

export const Toggler = styled.span`
  cursor: pointer;
  position: relative;
  ${({ theme }) => theme.basic.fontWeights.bold}
`;

export const Open = styled.div<{ isOpen?: boolean }>`
  margin-right: 20px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  position: absolute;
  left: -26px;
  top: 1px;
`;

export const LeftSide = styled.div`
  flex: 0 0 400px;
  padding-right: 15px;
`;

export const RightSide = styled.div`
  flex: 1 1 auto;
`;

export const AttachmentsWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 190px;
`;
