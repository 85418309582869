/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPresentAtlas
 */
export interface UserPresentAtlas {
    /**
     * Company Id of the user found
     * @type {number}
     * @memberof UserPresentAtlas
     */
    companyId?: number;
    /**
     * Email id of the user 
     * @type {string}
     * @memberof UserPresentAtlas
     */
    email?: string;
    /**
     * Denotes if the company exist on Atlas
     * @type {boolean}
     * @memberof UserPresentAtlas
     */
    existInAtlas: boolean;
    /**
     * If `exist_in_atlas` is `True`, respective user id will be provided in this property.
     * @type {number}
     * @memberof UserPresentAtlas
     */
    id?: number;
}

export function UserPresentAtlasFromJSON(json: any): UserPresentAtlas {
    return UserPresentAtlasFromJSONTyped(json, false);
}

export function UserPresentAtlasFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPresentAtlas {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'existInAtlas': json['exist_in_atlas'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function UserPresentAtlasToJSON(value?: UserPresentAtlas | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'email': value.email,
        'exist_in_atlas': value.existInAtlas,
        'id': value.id,
    };
}


