/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormClearedInfo,
    FormClearedInfoFromJSON,
    FormClearedInfoFromJSONTyped,
    FormClearedInfoToJSON,
    ResponseBase,
    ResponseBaseFromJSON,
    ResponseBaseFromJSONTyped,
    ResponseBaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormClearedInfoResponse
 */
export interface FormClearedInfoResponse {
    /**
     * Message related to response
     * @type {string}
     * @memberof FormClearedInfoResponse
     */
    msg: string;
    /**
     * 
     * @type {FormClearedInfo}
     * @memberof FormClearedInfoResponse
     */
    entry: FormClearedInfo;
}

export function FormClearedInfoResponseFromJSON(json: any): FormClearedInfoResponse {
    return FormClearedInfoResponseFromJSONTyped(json, false);
}

export function FormClearedInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormClearedInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': json['msg'],
        'entry': FormClearedInfoFromJSON(json['entry']),
    };
}

export function FormClearedInfoResponseToJSON(value?: FormClearedInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'entry': FormClearedInfoToJSON(value.entry),
    };
}


