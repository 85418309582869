import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import Radios from '../Question/Review/Radios';
import { FormsUpdateCategoryReviewStatusFunctionType } from 'store/forms/actions';
import { ReviewWrapper, Wrapper, Status, ReviewFlex } from './Review.styles';
import { TranslatePropsType, Uuid } from 'common/common.types';
import { VRMReviewStatsStatusEnum } from 'generated/models/VRMReviewStats';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useOnClickOutside } from 'utils/useOnClickOutside';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ReviewPropsType extends TranslatePropsType {
  categoryName: string;
  formId: Uuid;
  currentStatus?: VRMReviewStatsStatusEnum;
  updateCategoryReviewStatus: FormsUpdateCategoryReviewStatusFunctionType;
}

const Review = ({
  categoryName,
  formId,
  currentStatus,
  updateCategoryReviewStatus,
  t,
}: ReviewPropsType) => {
  const [isOpened, setOpened] = useState(false);

  const ref = useRef(null);

  useOnClickOutside(ref, () => setOpened(false));

  const handleChange = (value: VRMReviewStatsStatusEnum) =>
    updateCategoryReviewStatus(categoryName, formId, value);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpened(!isOpened);
  };

  const handleWrapperClick = (e: React.MouseEvent<HTMLElement>) => {
    // We stop the event propogation generated from the <ReviewWrapper> component,
    // so that it doesn't mess up with the onClick handlers of <AccordionSection>
    // component.
    e.stopPropagation();
  };

  const faChevronDownProp = faChevronDown as IconProp;
  const statusLabel = currentStatus
    ? t(`${currentStatus.toLowerCase()}`)
    : (t('designate') as string);

  return (
    <Wrapper
      ref={ref}
      currentStatus={currentStatus}
      onClick={handleWrapperClick}
    >
      <ReviewFlex onClick={handleClick}>
        <Status>{statusLabel}</Status>
        <FontAwesomeIcon icon={faChevronDownProp} />
      </ReviewFlex>
      {isOpened && (
        <ReviewWrapper>
          <Radios
            handleChange={handleChange}
            recordId={categoryName}
            currentStatus={currentStatus}
          />
        </ReviewWrapper>
      )}
    </Wrapper>
  );
};

export default withTranslation('CategoryReview')(Review);
