/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VRMReviewStats,
    VRMReviewStatsFromJSON,
    VRMReviewStatsFromJSONTyped,
    VRMReviewStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface VRMReviewStatsMeta
 */
export interface VRMReviewStatsMeta {
    /**
     * Data related to state changes
     * @type {VRMReviewStats}
     * @memberof VRMReviewStatsMeta
     */
    categoryReviewStats?: VRMReviewStats;
}

export function VRMReviewStatsMetaFromJSON(json: any): VRMReviewStatsMeta {
    return VRMReviewStatsMetaFromJSONTyped(json, false);
}

export function VRMReviewStatsMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): VRMReviewStatsMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryReviewStats': !exists(json, 'category_review_stats') ? undefined : VRMReviewStatsFromJSON(json['category_review_stats']),
    };
}

export function VRMReviewStatsMetaToJSON(value?: VRMReviewStatsMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category_review_stats': VRMReviewStatsToJSON(value.categoryReviewStats),
    };
}


