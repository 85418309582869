import {
  RecurrenceItemType,
  ReminderItemType,
  RequestDetailType,
  RequestSimpleType,
  ScheduledRequestType,
  TargetItemType,
  TeamsItemType,
  Uuid,
  ValueLabelType,
} from '../../common/common.types';
import {
  FilterOptionsType,
  FilterType,
  FilterOptionsDescriptionType,
} from 'utils/filters/requests.filters';
import { RequestSummaryStatusEnum } from '../../generated/models/RequestSummary';
import { SortType } from 'utils/filters/requests.sorters';

export type RequestsViewType = 'List' | 'Kanban';

export type RequestsCategoryNameType =
  | 'created'
  | 'inProgress'
  | 'reviewReady'
  | 'closed';

export const REQUESTS_CATEGORIES_ORDER: RequestsCategoryNameType[] = [
  'created',
  'inProgress',
  'reviewReady',
  'closed',
];

export interface RequestCategoryType {
  count: number;
  requests: RequestSimpleType[];
  selected: Uuid[];
}

export type RequestsCategoryType = {
  [key in RequestsCategoryNameType]: RequestCategoryType;
};

export interface RequestsInOutType {
  count: number;
  categoriesOpened: {
    created: boolean;
    inProgress: boolean;
    reviewReady: boolean;
    closed: boolean;
  };
  filter: RequestsFilterType;
  filterOptions: FilterOptionsType;
  categories: RequestsCategoryType;
  unsorted: RequestSimpleType[];
  description: FilterOptionsDescriptionType;
}

export interface ArchivedRequestsType {
  data: RequestDetailType[];
  filter: ArchivedRequestsFilterType;
}

export interface ArchivedRequestsFilterType {
  sortBy: SortType;
}

export interface RequestsFilterType {
  filterBy: FilterType;
  sortBy: SortType;
  view: RequestsViewType;
}

export interface ArchivedRequestsSimpleType {
  data: RequestSimpleType[];
  filter: ArchivedRequestsFilterType;
}

/**
 * Object representing the state of send request screen for both sending
 * requests to emails and teams.
 */
export interface RequestsFormType {
  dueDate: Date | undefined;
  emailBody: string;
  isValid: boolean | undefined;
  notify: boolean;
  recurrence: RecurrenceItemType;
  reminder: ReminderItemType;
  sendDate: Date;
  sourceTeam?: ValueLabelType<string>;
  targets: TargetItemType[];
  targetTeams: TeamsItemType[];
}

export type SendRequestErrorType = {
  cost: number;
  balance: number;
} | null;

export enum SendRequestViewEnum {
  EMAIL = 'Email',
  LINK = 'Link',
  INTERNAL = 'Internal',
}

export interface SendRequestStateType {
  error: SendRequestErrorType;
  requests: RequestDetailType[];
  view: SendRequestViewEnum;
}

const { CREATED, VENDORINPROGRESS, VRMINPROGRESS } = RequestSummaryStatusEnum;

export interface RequestsStatsType {
  [CREATED]: number | null;
  [VENDORINPROGRESS]: number | null;
  [VRMINPROGRESS]: number | null;
}

export interface RequestsStateType {
  received: RequestsInOutType;
  sent: RequestsInOutType;
  archived: ArchivedRequestsType;
  customModal: {
    isOpen: boolean;
  };
  form: RequestsFormType;
  insightsExporting: boolean;
  scheduled: ScheduledRequestType[];
  sendRequest: SendRequestStateType;
  summary: RequestDetailType[];
  stats: RequestsStatsType | null;
}
