/* eslint-disable */
// tslint:disable
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    EvidenceDeletedResponse,
    EvidenceResponse,
    EvidenceShareResponse,
    EvidencesListResponse,
    ExceptionResponse,
    ShareEvidence,
    EvidenceDeletedResponseFromJSON,
    EvidenceResponseFromJSON,
    EvidenceShareResponseFromJSON,
    EvidencesListResponseFromJSON,
    ShareEvidenceToJSON,
} from '../models';

export interface DeleteEvidenceDetailResourceRequest {
    evidenceId: string;
}

export interface GetEvidenceDetailResourceRequest {
    evidenceId: string;
}

export interface GetEvidenceDownloadResourceRequest {
    evidenceId: string;
}

export interface GetEvidencesResourceRequest {
    teamId?: string;
    excludeTeamData?: boolean;
}

export interface PostEvidencesResourceRequest {
    file: Blob;
    teamId?: string;
}

export interface PostEvidencesShareResourceRequest {
    payload: ShareEvidence;
}

/**
 * no description
 */
export class EvidencesApi extends BaseAPI {

    /**
     * Delete single evidence file
     */
    deleteEvidenceDetailResource = ({ evidenceId }: DeleteEvidenceDetailResourceRequest): Observable<EvidenceDeletedResponse> => {
        throwIfNullOrUndefined(evidenceId, 'deleteEvidenceDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<EvidenceDeletedResponse>({
            parser: jsonValue => EvidenceDeletedResponseFromJSON(jsonValue),
            path: '/atlas/evidences/{evidence_id}'.replace('{evidence_id}', encodeURI(evidenceId)),
            method: 'DELETE',
            headers,
        });
    };

    /**
     * Get evidence file entity
     */
    getEvidenceDetailResource = ({ evidenceId }: GetEvidenceDetailResourceRequest): Observable<EvidenceResponse> => {
        throwIfNullOrUndefined(evidenceId, 'getEvidenceDetailResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<EvidenceResponse>({
            parser: jsonValue => EvidenceResponseFromJSON(jsonValue),
            path: '/atlas/evidences/{evidence_id}'.replace('{evidence_id}', encodeURI(evidenceId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Download the evidence file
     */
    getEvidenceDownloadResource = ({ evidenceId }: GetEvidenceDownloadResourceRequest): Observable<void> => {
        throwIfNullOrUndefined(evidenceId, 'getEvidenceDownloadResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<void>({
            path: '/atlas/evidences/download/{evidence_id}'.replace('{evidence_id}', encodeURI(evidenceId)),
            method: 'GET',
            headers,
        });
    };

    /**
     * Return list of accessible evidence
     */
    getEvidencesResource = ({ teamId, excludeTeamData }: GetEvidencesResourceRequest): Observable<EvidencesListResponse> => {

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (teamId != null) { query['team_id'] = teamId; }
        if (excludeTeamData != null) { query['exclude_team_data'] = excludeTeamData; }

        return this.request<EvidencesListResponse>({
            parser: jsonValue => EvidencesListResponseFromJSON(jsonValue),
            path: '/atlas/evidences',
            method: 'GET',
            headers,
            query,
        });
    };

    /**
     * Upload new evidence file
     */
    postEvidencesResource = ({ file, teamId }: PostEvidencesResourceRequest): Observable<EvidenceResponse> => {
        throwIfNullOrUndefined(file, 'postEvidencesResource');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        const query: HttpQuery = {};

        if (teamId != null) { query['team_id'] = teamId; }

        const formData = new FormData();
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<EvidenceResponse>({
            parser: jsonValue => EvidenceResponseFromJSON(jsonValue),
            path: '/atlas/evidences',
            method: 'POST',
            headers,
            query,
            body: formData,
        });
    };

    /**
     * Share one or more evidence files
     */
    postEvidencesShareResource = ({ payload }: PostEvidencesShareResourceRequest): Observable<EvidenceShareResponse> => {
        throwIfNullOrUndefined(payload, 'postEvidencesShareResource');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // PlatformKey authentication
        };

        return this.request<EvidenceShareResponse>({
            parser: jsonValue => EvidenceShareResponseFromJSON(jsonValue),
            path: '/atlas/evidences/shares',
            method: 'POST',
            headers,
            body: ShareEvidenceToJSON(payload),
        });
    };

}
