import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import UploadResult from './UploadResult';
import { DropUploadWrapper } from '../../common/DropUpload';
import { Instructions } from '../../common/DropUpload/DropUploadWrapper.styles';
import { UploadStandardFunctionType } from 'store/standard/actions';
import { withTranslation } from 'react-i18next';
import { ParsedFileType, TranslatePropsType } from 'common/common.types';
import {
  FRAMEWORK_TEMPLATE_FILE_NAME,
  FRAMEWORK_TEMPLATE_FILE_PATH,
} from 'common/common.constants';

interface UploadPropsType extends TranslatePropsType {
  hasParsingErrors: boolean;
  instructions?: any;
  isProcessing: boolean;
  onUpload?: UploadStandardFunctionType;
  parsedFiles: ParsedFileType[];
  showInstructions?: boolean;
  templatePath?: string;
}

const Upload = ({
  hasParsingErrors,
  instructions,
  isProcessing,
  onUpload,
  parsedFiles,
  showInstructions = true,
  templatePath = FRAMEWORK_TEMPLATE_FILE_PATH,
  t,
}: UploadPropsType) => {
  const handleDropFiles = (acceptedFiles: File[]) => {
    if (onUpload) {
      acceptedFiles.forEach((file: Blob) => {
        const formData = new FormData();
        formData.append('file', file);
        onUpload(formData);
      });
    }
  };

  return (
    <>
      <DropUploadWrapper
        instructions={
          showInstructions &&
          (instructions ? (
            instructions
          ) : (
            <Instructions>
              <li>
                {ReactHtmlParser(
                  t('instructions1HTML', {
                    link: templatePath,
                    filename: FRAMEWORK_TEMPLATE_FILE_NAME,
                  }),
                )}
              </li>

              <li>{t('instructions2') as string}</li>

              <li>{t('instructions3') as string}</li>
            </Instructions>
          ))
        }
        isProcessing={isProcessing}
        onDrop={handleDropFiles}
      />

      <UploadResult
        hasParsingErrors={hasParsingErrors}
        parsedFiles={parsedFiles}
      />
    </>
  );
};

export default withTranslation('SendRequest')(Upload);
