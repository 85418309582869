import { ApiCallActionType } from '../../store.types';
import { call, put } from 'redux-saga/effects';
import { RequestsFormType, RequestsStateType } from '../requests.types';
import { trackEvent } from 'utils/analytics';
import { startLoading } from '../../app/actions';
import { SCHEDULED_POST_ACTIONS } from './sendRequestPostActions';
import {
  getCommonScheduleRequestCallParams,
  updateSendRequestStateError,
  getEffectiveSourceTeam,
} from '../utils';
import { Uuid } from '../../../common/common.types';
import {
  ScheduledRequest,
  ScheduledRequestInternalCreate,
} from '../../../generated/new';
import scheduledRequestsApi from '../../../api/new/scheduledRequestsApi';

export type RequestsScheduleInternalActionType = ApiCallActionType<
  '@requests/API/SCHEDULE_INTERNAL_REQUEST',
  ScheduledRequestInternalCreate,
  ScheduledRequest[],
  any,
  RequestsStateType
>;

export const scheduleInternalRequest = (
  form: RequestsFormType,
  standardIds: Uuid[],
): RequestsScheduleInternalActionType => ({
  type: '@requests/API/SCHEDULE_INTERNAL_REQUEST',
  payload: {
    endpointPath: callParams =>
      scheduledRequestsApi.postScheduledRequestsInternalResource({
        payload: callParams,
      }),
    callParams: state => ({
      ...getCommonScheduleRequestCallParams(form, standardIds),
      sourceTeam:
        getEffectiveSourceTeam(form.sourceTeam, state.user.currentUser) ||
        `SHOULDN'T HAPPEN: sending internal request without source team`,
      targetTeams: form.targetTeams.map(option => option.value),
    }),
    selector: null,
    errorSelector: updateSendRequestStateError,
    preActions: [put(startLoading(1))],
    postActions: [
      () => call(() => trackEvent('Internal Request', 'Create')),
      () => call(() => trackEvent('Schedule Internal', 'Create')),
      ...SCHEDULED_POST_ACTIONS,
    ],
  },
});
