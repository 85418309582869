export const getNotificationTypes = (
  hasNewMessages: boolean,
  hasNewResponses: boolean,
  hasNewSscData: boolean,
) => [
  ...(hasNewMessages
    ? [
        {
          filterKey: 'comments_new',
          titleKey: 'messages',
        },
      ]
    : []),
  ...(hasNewSscData
    ? [
        {
          filterKey: 'ssc_data_new',
          titleKey: 'sscData',
        },
      ]
    : []),
  ...(hasNewResponses
    ? [
        {
          filterKey: 'responses_new',
          titleKey: 'responses',
        },
      ]
    : []),
];
