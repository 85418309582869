import { DropdownsEnum } from '../forms.reducer';

export type FormsSetCurrentDropdownFunctionType = (
  dropdown: DropdownsEnum | undefined,
) => void;

export interface FormsSetCurrentDropdownPayloadType {
  dropdown: DropdownsEnum | undefined;
}

export interface FormsSetCurrentDropdownActionType {
  type: '@forms/SET_CURRENT_DROPDOWN';
  payload: FormsSetCurrentDropdownPayloadType;
}

export const setCurrentDropdown = (
  dropdown: DropdownsEnum | undefined,
): FormsSetCurrentDropdownActionType => ({
  type: '@forms/SET_CURRENT_DROPDOWN',
  payload: {
    dropdown,
  },
});
