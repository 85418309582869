/* tslint:disable */
/* eslint-disable */
/**
 * Atlas API
 * Atlas API
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VRMReviewStats
 */
export interface VRMReviewStats {
    /**
     * 
     * @type {string}
     * @memberof VRMReviewStats
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof VRMReviewStats
     */
    scope: VRMReviewStatsScopeEnum;
    /**
     * 
     * @type {string}
     * @memberof VRMReviewStats
     */
    status?: VRMReviewStatsStatusEnum;
}

export function VRMReviewStatsFromJSON(json: any): VRMReviewStats {
    return VRMReviewStatsFromJSONTyped(json, false);
}

export function VRMReviewStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VRMReviewStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : json['category'],
        'scope': json['scope'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function VRMReviewStatsToJSON(value?: VRMReviewStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'scope': value.scope,
        'status': value.status,
    };
}

/**
* @export
* @enum {string}
*/
export enum VRMReviewStatsScopeEnum {
    SINGLESTATUS = 'SINGLE_STATUS',
    MULTIPLESTATUSES = 'MULTIPLE_STATUSES',
    NOQUESTIONS = 'NO_QUESTIONS'
}
/**
* @export
* @enum {string}
*/
export enum VRMReviewStatsStatusEnum {
    DONE = 'DONE',
    FLAGGED = 'FLAGGED',
    PENDING = 'PENDING'
}


