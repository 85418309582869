import { Header, Section, Title } from './FilterBy.styles';
import { assocPath, contains } from 'ramda';
import { FilterByPropsType } from './FilterBy';
import { FilterOptionType } from 'utils/filters/requests.filters';
import React from 'react';
import Select from '../../common/Select';
import { withTranslation } from 'react-i18next';

const OPTION = 'COMPANIES';

const FilterCompanies = ({
  data: {
    filterOptions: { COMPANIES },
    filterBy,
    isSent,
  },
  actions: { setFilter },
  t,
}: FilterByPropsType) => {
  const handleChange = (newCompanies: FilterOptionType[]) => {
    const lookFor = newCompanies.map(data => data.value);
    const isEnabled = lookFor.length > 0;

    setFilter(assocPath([OPTION], { isEnabled, lookFor }, filterBy));
  };

  const initialValue = COMPANIES!.filter(item =>
    contains(item.value, filterBy.COMPANIES.lookFor || []),
  );

  return (
    <Section>
      <Header>
        <Title>{t(`Requests.filterOptions.${OPTION}`) as string}</Title>
      </Header>
      <div>
        <Select
          defaultValue={initialValue}
          isMulti
          onChange={handleChange}
          options={COMPANIES}
          placeholder={t('Requests.addCompaniesToFilter')}
        />
      </div>
    </Section>
  );
};

export default withTranslation()(FilterCompanies);
