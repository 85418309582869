import React, { useCallback, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import {
  FormCreatorValidatedQuestionType,
  GroupedQuestionItemType,
  QuestionDefinitionSummaryType,
  StructuredCondition,
  StructuredConditionLine as ConditionLine,
  TranslatePropsType,
  ValueLabelType,
} from 'common/common.types';
// tslint:disable-next-line: max-line-length
import { QuestionConditionStructuredItemOperatorEnum as ItemOperator } from 'generated/models/QuestionConditionStructuredItem';
import { QuestionConditionStructuredOperatorEnum as OperatorEnum } from 'generated/models/QuestionConditionStructured';
import { HorizontallyCentered } from 'common/common.styles';
import {
  ITEM_SPACE,
  LineTitle,
  LineWrapper,
  Operator,
  OperatorSelector,
  OperatorText,
  SingleValue,
  StyledInput,
  StyledSelect,
} from './StructuredEditor.styles';
import { Action, DeleteIcon } from '../Actions/Actions.styles';
import {
  getConditionLineState,
  removeLine,
  updateLineOperator,
  updateOperator,
  updateQuestion,
  updateResponse,
  updateValue,
} from 'store/formCreator/conditions/structuredCondition.utils';
import StructuredQuestionSelect from './StructuredQuestionSelect';
import StructuredResponseOption from './StructuredResponseOption';
import { ResponseItemType } from 'store/formCreator/conditions/structuredCondition.types';

interface StructuredConditionLinePropsType extends TranslatePropsType {
  condition: StructuredCondition;
  conditionQuestion: FormCreatorValidatedQuestionType;
  groupedQuestions: GroupedQuestionItemType[];
  index: number;
  isLastLine: boolean;
  line: ConditionLine;
  updateCondition: (payload: StructuredCondition | null) => void;
}

const StructuredConditionLine = ({
  condition,
  conditionQuestion,
  conditionQuestion: { errors },
  groupedQuestions,
  index,
  isLastLine,
  line,
  line: { questionUuid },
  updateCondition,
  t,
}: StructuredConditionLinePropsType) => {
  /**
   * onChange event handlers
   */
  const onConditionOperatorChange = useCallback(
    (conditionOp: OperatorEnum) =>
      updateCondition(updateOperator(condition, conditionOp)),
    [condition, updateCondition],
  );
  const onSelectQuestion = useCallback(
    (question: QuestionDefinitionSummaryType) =>
      updateCondition(
        updateQuestion({ condition, conditionLineIndex: index, question }),
      ),
    [condition, index, updateCondition],
  );
  const onSelectResponse = useCallback(
    ({ response }: ResponseItemType) =>
      updateCondition(
        updateResponse({
          condition,
          conditionLineIndex: index,
          questionUuid: questionUuid,
          response,
        }),
      ),
    [condition, index, questionUuid, updateCondition],
  );
  const onChangeOperator = useCallback(
    (newOperator: ValueLabelType<ItemOperator>) =>
      updateCondition(updateLineOperator(condition, index, newOperator.value)),
    [condition, index, updateCondition],
  );
  const onChangeValue = useCallback(
    (newValue: string) =>
      updateCondition(updateValue(condition, index, newValue)),
    [condition, index, updateCondition],
  );

  const {
    selectedQuestion,
    possibleQuestions,
    questionValid,
    selectedResponse,
    possibleResponses,
    responseValid,
    selectedOperator,
    possibleOperators,
    selectedValue,
    possibleValues,
    valueValid,
    isSelectableValue,
  } = useMemo(
    () =>
      getConditionLineState({ conditionQuestion, groupedQuestions, line, t }),
    [conditionQuestion, groupedQuestions, line, t],
  );

  const gotSomeErrorsFromBackend = errors.length > 0;

  const isSingleResponse =
    selectedResponse &&
    selectedQuestion &&
    selectedQuestion.responses.length === 1;

  const isFirstLine = index === 0;
  const showOperatorSelector = isFirstLine && condition.items.length > 1;

  return (
    <LineWrapper data-test="condition-line">
      {isFirstLine && (
        <HorizontallyCentered spacingSize={ITEM_SPACE}>
          <LineTitle>{t('conditionEditor.questionId') as string}</LineTitle>
          <LineTitle>{t('conditionEditor.responseId') as string}</LineTitle>
        </HorizontallyCentered>
      )}
      <HorizontallyCentered noGrow spacingSize={ITEM_SPACE}>
        <StructuredQuestionSelect
          options={possibleQuestions}
          placeholder={t('conditionEditor.placeholders.questionId')}
          value={selectedQuestion}
          onChange={onSelectQuestion}
          visualState={
            gotSomeErrorsFromBackend && !questionValid ? 'error' : 'normal'
          }
        />
        {isSingleResponse ? (
          <SingleValue>{selectedResponse!.label}</SingleValue>
        ) : (
          <StyledSelect
            isDisabled={selectedQuestion === undefined}
            components={{ Option: StructuredResponseOption }}
            options={possibleResponses}
            onChange={onSelectResponse}
            value={selectedResponse}
            visualState={
              gotSomeErrorsFromBackend && !responseValid ? 'error' : 'normal'
            }
          />
        )}
        <StyledSelect
          isDisabled={selectedResponse === undefined}
          options={possibleOperators}
          onChange={onChangeOperator}
          value={selectedOperator}
        />
        {isSelectableValue ? (
          <StyledSelect
            isDisabled={selectedResponse === undefined}
            options={possibleValues}
            onChange={(newValue: ValueLabelType<string>) =>
              onChangeValue(newValue.value)
            }
            value={selectedValue}
            visualState={
              gotSomeErrorsFromBackend && !valueValid ? 'error' : 'normal'
            }
          />
        ) : (
          <StyledInput
            isDisabled={selectedResponse === undefined}
            onBlur={onChangeValue}
            value={selectedValue as string}
            visualState={
              gotSomeErrorsFromBackend && !valueValid ? 'error' : 'normal'
            }
          />
        )}
        <Action onClick={() => updateCondition(removeLine(condition, index))}>
          <DeleteIcon />
        </Action>
        {showOperatorSelector && (
          <OperatorSelector>
            <Operator
              isSelected={condition.operator === OperatorEnum.AND}
              onClick={() => onConditionOperatorChange(OperatorEnum.AND)}
            >
              {
                t('conditionEditor.operator', {
                  context: OperatorEnum.AND,
                }) as string
              }
            </Operator>
            <Operator
              isSelected={condition.operator === OperatorEnum.OR}
              onClick={() => onConditionOperatorChange(OperatorEnum.OR)}
            >
              {
                t('conditionEditor.operator', {
                  context: OperatorEnum.OR,
                }) as string
              }
            </Operator>
          </OperatorSelector>
        )}
        {!(isFirstLine || isLastLine) && (
          <OperatorText>
            {
              t('conditionEditor.operator', {
                context: condition.operator,
              }) as string
            }
          </OperatorText>
        )}
      </HorizontallyCentered>
    </LineWrapper>
  );
};

export default withTranslation('FormCreator')(
  React.memo(StructuredConditionLine),
);
