import React from 'react';

import styled from 'styled-components';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface HeaderWarningIconProps {
  visible?: boolean;
}

const HeaderWarningIcon = styled(
  ({ visible, ...props }: HeaderWarningIconProps) => (
    <FontAwesomeIcon icon={faExclamationTriangle as IconProp} {...props} />
  ),
)`
  && {
    width: 16px;
    height: 16px;
  }
  color: ${({ visible = true, theme }) =>
    visible ? theme.basic.colors.error : 'transparent'};
`;

export default HeaderWarningIcon;
