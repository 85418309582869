import React from 'react';
import { AutocompleteDialogStateType } from 'common/common.types';
import AutocompleteInProgress from './AutocompleteInProgress';
import AutocompleteFinish from './AutocompleteFinish';
import GlobalMappingInProgress from './GlobalMappingInProgress';
import MatchesFound from './MatchesFound';
import MatchesNotFound from './MatchesNotFound';
import MatchSearchInProgress from './MatchSeachInProgress';
import { FormsStartAutocompleteFunctionType } from 'store/forms/actions';
import AllQuestionsFilled from './AllQuestionsFilled';

interface AutocompletePropsType {
  handleClose: () => void;
  state: AutocompleteDialogStateType;
  startAutocomplete: FormsStartAutocompleteFunctionType;
}

const Autocomplete = ({
  handleClose,
  state,
  startAutocomplete,
}: AutocompletePropsType) => {
  switch (state.type) {
    case 'OPENED_MATCH_SEARCH_IN_PROGRESS':
      return <MatchSearchInProgress />;
    case 'OPENED_GLOBAL_MAPPING_IN_PROGRESS':
      return <GlobalMappingInProgress />;
    case 'OPENED_MATCHES_FOUND':
      return (
        <MatchesFound
          matchingQuestionsCount={state.matchingQuestionsCount}
          startAutocomplete={startAutocomplete}
        />
      );
    case 'OPENED_MATCHES_NOT_FOUND':
      return <MatchesNotFound />;
    case 'OPENED_STARTING_AUTOCOMPLETE':
      return (
        <AutocompleteInProgress
          matchingQuestionsCount={state.matchingQuestionsCount}
          questionsProcessed={0}
          questionsTotal={100}
        />
      );
    case 'OPENED_AUTOCOMPLETE_IN_PROGRESS':
      return (
        <AutocompleteInProgress
          matchingQuestionsCount={state.progress.matchingQuestionsCount}
          questionsProcessed={state.progress.questionsProcessed}
          questionsTotal={state.progress.questionsTotal}
        />
      );
    case 'OPENED_AUTOCOMPLETE_FINISHED':
      return (
        <AutocompleteFinish
          questionsAutofilledCount={state.questionsAutofilledCount}
          questionsSuggestedCount={state.questionsSuggestedCount}
          handleClose={handleClose}
        />
      );
    case 'OPENED_ALL_QUESTIONS_FILLED':
      return <AllQuestionsFilled />;
    default:
      return null;
  }
};

export default Autocomplete;
