import { ResponseDefinitionType } from 'common/common.types';
import { ResponseTypeDefinition } from 'generated/models/ResponseTypeDefinition';
import { EMPTY_ID } from 'common/common.constants';

export const parseResponseDefinition = (
  definition: ResponseTypeDefinition,
): ResponseDefinitionType => {
  const {
    id = EMPTY_ID,
    label = '',
    options = [],
    placeholder = '',
    response_type,
  } = definition;

  return {
    id,
    responseType: response_type,
    label,
    options,
    placeholder,
  };
};
